import React from 'react'
import './nota-entrada-form.scss'
import { Card } from 'primereact/card'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import { enums } from '../../../constants'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'
import NotaEntradaFormTitle from './nota-entarda-form-title'
import NotaEntradaFormSubtitle from './nota-entrada-form-subtitle'
import LtsInputText from '../../../components/lottus/lts-input-text'
import _ from 'lodash'
import LtsLookupResponsavel from '../../../components/lottus/lts-lookup-responsavel'
import LtsSelectProdutos from '../../../components/lottus/lts-select-produtos'

export const NotaEntradaForm = (props) => {
  const record = props.record

  return (
    <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
      <TabPanel header="Dados Gerais">{getDadosGerais()}</TabPanel>
      <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
    </TabView>
  )

  function getDadosGerais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <Card
            title={<NotaEntradaFormTitle status={record.status} oid={record.oid} />}
            subTitle={
              <NotaEntradaFormSubtitle
                oidLancamentoFinanceiro={props.record.oidLancamentoFinanceiro}
                history={props.history}
                dataImportacao={props.record.dataImportacao}
                dataEntrada={props.record.dataEntrada}
              />
            }
          >
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                <LtsDropdownUnidade
                  name={'unidade'}
                  label={'Unidade'}
                  value={record.unidade}
                  onChange={props.onChangeUnit}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-7 p-lg-6 p-xl-5">
                <LtsInputText
                  value={record.chave}
                  onChange={(e) => props.setField('chave', e)}
                  name="chave"
                  maxLength={255}
                  label={'Chave'}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsInputText
                  value={record.numero}
                  onChange={(e) => props.setField('numero', e)}
                  name="numero"
                  maxLength={255}
                  label={'Número'}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsInputText
                  value={record.serie}
                  onChange={(e) => props.setField('serie', e)}
                  name="serie"
                  maxLength={5}
                  label={'Série'}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsCalendar
                  value={record.dataEmissao}
                  name={'dataEmissao'}
                  label={'Emissão'}
                  onChange={(e) => props.setField('dataEmissao', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-6">
                <LtsTextArea
                  value={record.observacao}
                  onChange={(e) => props.setField('observacao', e)}
                  name={'observacao'}
                  label={'Observação'}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsCalendar
                  value={record.dataVencimento}
                  name={'dataVencimento'}
                  label={'Vencimento'}
                  onChange={(e) => props.setField('dataVencimento', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <LtsLookupResponsavel
                  name={'fornecedor'}
                  field={'nome'}
                  label={'Fornecedor'}
                  value={_.get(record, 'fornecedor.value', '')}
                  oidRecord={_.get(record, 'fornecedor.id', '')}
                  history={props.history}
                  onChange={props.setFornecedor}
                  statePessoaFisica={props.statePessoaFisica}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                <LtsCurrency
                  value={record.valorNota}
                  name={'valorNota'}
                  label={'Total da Nota'}
                  onChange={(e) => props.setField('valorNota', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12">
                <LtsSelectProdutos
                  history={props.history}
                  values={_.get(props, 'record.itens', [])}
                  onChange={(e) => props.setItens(_.get(e, 'values', []))}
                  title={'Itens'}
                  labelLookup={'Item'}
                  filtroTipoProduto={enums.TipoProduto[0].id}
                  showColumnsValores={true}
                  editProduct={true}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    )
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.NOTA_ENTRADA}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
