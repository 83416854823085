import utils from '../utils'

const TOKEN_KEY = 'TOKEN'
const COMPANY_KEY = 'COMPANY'
const COMPANIES_KEY = 'COMPANIES'
const UNIDADE_USUARIO_UNICO_KEY = 'UNIDADE_USUARIO_UNICO'
const ALTERA_UNIDADE_KEY = 'ALTERA_UNIDADE'
const UNIT_KEY = 'UNIT'
const UNIDADES_USUARIO_KEY = 'UNIDADES_USUARIO'
const USER_KEY = 'USER'
const APP_LABELS_KEY = 'APP_LABELS'
const OID_CURRENT_MENU_KEY = 'OID_CURRENT_MENU'
const MENU_PATH_KEY = 'MENU_PATH_KEY'
const CUSTOM_ROUTES_KEY = 'CUSTOM_ROUTES'
const OID_DELETED_MENU_KEY = 'OID_DELETED_MENU_KEY'
const LOCALIZACAO_REFERENCIA = 'LOCALIZACAO_REFERENCIA'
const MENUS_PERMISSOES = 'MENUS'
const ACOES_PERFIL_KEY = 'ACOES_PERFIL'
const VERSAO_SISTEMA_KEY = 'VERSAO_SISTEMA'
const MENU_LAYOUT_KEY = 'MENU_LAYOUT'
const PORTAL_CLIENTE_KEY = 'PORTAL_CLIENTE'
const NOME_USUARIO_KEY = 'NOME_USUARIO'
const internalStorage = window.localStorage

const setToken = (token) => {
  internalStorage.setItem(TOKEN_KEY, token)
}

const getToken = () => {
  return internalStorage.getItem(TOKEN_KEY)
}

const setOidCurrentMenu = (oidMenu) => {
  internalStorage.setItem(OID_CURRENT_MENU_KEY, oidMenu)
}

const getOidCurrentMenu = () => {
  const result = internalStorage.getItem(OID_CURRENT_MENU_KEY)
  if (result) {
    return Number(result)
  }
  return null
}

const setMenuPath = (menuPath) => {
  internalStorage.setItem(MENU_PATH_KEY, menuPath)
}

const getMenuPath = () => {
  return internalStorage.getItem(MENU_PATH_KEY)
}

const setOidDeletedMenu = (oidMenu) => {
  internalStorage.setItem(OID_DELETED_MENU_KEY, oidMenu)
}

const getOidDeletedMenu = () => {
  const result = internalStorage.getItem(OID_DELETED_MENU_KEY)
  if (result) {
    return Number(result)
  }
  return null
}

const addLocalizacaoReferencia = (localizacao) => {
  const value = internalStorage.getItem(LOCALIZACAO_REFERENCIA)
  if (value) {
    const parsedValue = JSON.parse(value)
    if (parsedValue && parsedValue.length > 0) {
      parsedValue.push(localizacao)
      internalStorage.setItem(LOCALIZACAO_REFERENCIA, JSON.stringify(parsedValue))
    }
  } else {
    const array = []
    array.push(localizacao)
    internalStorage.setItem(LOCALIZACAO_REFERENCIA, JSON.stringify(array))
  }
}

const getLocalizacaoReferencia = () => {
  const value = internalStorage.getItem(LOCALIZACAO_REFERENCIA)
  const parsedValue = JSON.parse(value)
  if (value) {
    internalStorage.removeItem(LOCALIZACAO_REFERENCIA)
    return parsedValue
  }
  return []
}

const getInputsPorMascaraLocalizacao = (parametroLocalizacao) => {
  let localizacoes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  const appLabelsLocalizacao = getAppLabels('localizacao')
  let ocorrencias = parametroLocalizacao.split('$').length - 1
  let resultado = []
  for (let i = 0; i < ocorrencias; i++) {
    resultado.push({
      name: 'localizacao' + localizacoes[i],
      header: utils.getMessage(appLabelsLocalizacao, 'localizacao.label.localizacao' + localizacoes[i]),
    })
  }
  return resultado
}

const setCompany = (empresa) => {
  if (empresa) {
    internalStorage.setItem(COMPANY_KEY, JSON.stringify(empresa))
  } else {
    internalStorage.removeItem(COMPANY_KEY)
  }
}

const getCompany = () => {
  const company = internalStorage.getItem(COMPANY_KEY)
  if (company) {
    return JSON.parse(company)
  }
  return null
}

const setUnit = (unit) => {
  if (unit) {
    internalStorage.setItem(UNIT_KEY, JSON.stringify(unit))
  } else {
    internalStorage.removeItem(UNIT_KEY)
  }
}

const getUnit = () => {
  const unit = internalStorage.getItem(UNIT_KEY)
  if (unit) {
    return JSON.parse(unit)
  }
  return null
}

const setUnidadesUsuario = (units) => {
  if (units) {
    internalStorage.setItem(UNIDADES_USUARIO_KEY, JSON.stringify(units))
  } else {
    internalStorage.removeItem(UNIDADES_USUARIO_KEY)
  }
}

const getUnidadesUsuario = () => {
  const units = internalStorage.getItem(UNIDADES_USUARIO_KEY)
  if (units) {
    return JSON.parse(units)
  }
  return null
}

const setMenus = (menus) => {
  if (menus) {
    internalStorage.setItem(MENUS_PERMISSOES, JSON.stringify(menus))
  } else {
    internalStorage.removeItem(MENUS_PERMISSOES)
  }
}

const getMenus = () => {
  const menus = internalStorage.getItem(MENUS_PERMISSOES)
  if (menus) {
    return JSON.parse(menus)
  }
  return null
}

const setCompanies = (companies) => {
  if (companies) {
    internalStorage.setItem(COMPANIES_KEY, JSON.stringify(companies))
  } else {
    internalStorage.removeItem(COMPANIES_KEY)
  }
}

const getCompanies = () => {
  const companies = internalStorage.getItem(COMPANIES_KEY)
  if (companies) {
    return JSON.parse(companies)
  }
  return null
}

const setUsuarioUnidadeUnica = (indicador) => {
  internalStorage.setItem(UNIDADE_USUARIO_UNICO_KEY, JSON.stringify(indicador))
}

const getUsuarioUnidadeUnica = () => {
  const indicador = internalStorage.getItem(UNIDADE_USUARIO_UNICO_KEY)
  return JSON.parse(indicador)
}

const setAlteraUnidade = (indicador) => {
  internalStorage.setItem(ALTERA_UNIDADE_KEY, JSON.stringify(indicador))
}

const getAlteraUnidade = () => {
  let indicador = internalStorage.getItem(ALTERA_UNIDADE_KEY)
  return indicador === 'true'
}

const setUser = (value) => {
  internalStorage.setItem(USER_KEY, value)
}

const getUser = () => {
  return internalStorage.getItem(USER_KEY)
}

const setVersaoSistema = (value) => {
  internalStorage.setItem(VERSAO_SISTEMA_KEY, value)
}

const getVersaoSistema = () => {
  return internalStorage.getItem(VERSAO_SISTEMA_KEY)
  // return getSecure(VERSAO_SISTEMA_KEY)
}

const setMenuLayout = (value) => {
  internalStorage.setItem(MENU_LAYOUT_KEY, value)
}

const getMenuLayout = () => {
  return internalStorage.getItem(MENU_LAYOUT_KEY)
}

const setPortalCliente = (value) => {
  internalStorage.setItem(PORTAL_CLIENTE_KEY, value)
}

const getPortalCliente = () => {
  const value = internalStorage.getItem(PORTAL_CLIENTE_KEY)
  return value === 'true'
}

const setNomeUsuario = (value) => {
  internalStorage.setItem(NOME_USUARIO_KEY, value)
}

const getNomeUsuario = () => {
  const result = internalStorage.getItem(NOME_USUARIO_KEY)
  if (result) {
    return result
  }
  return ''
}

const setAppLabels = (appLabels) => {
  if (appLabels) {
    internalStorage.setItem(APP_LABELS_KEY, JSON.stringify(appLabels))
  } else {
    internalStorage.removeItem(APP_LABELS_KEY)
  }
}

const getAppLabels = (key) => {
  if (!key) {
    return null
  }

  const appLabels = internalStorage.getItem(APP_LABELS_KEY)
  if (appLabels) {
    const obj = JSON.parse(appLabels)
    return obj[key]
  }
  return null
}

const setAcoes = (acoes) => {
  if (acoes) {
    internalStorage.setItem(ACOES_PERFIL_KEY, JSON.stringify(acoes))
  } else {
    internalStorage.removeItem(ACOES_PERFIL_KEY)
  }
}

const getAcao = (key) => {
  if (!key) {
    return false
  }

  const acao = internalStorage.getItem(ACOES_PERFIL_KEY)
  if (acao) {
    const obj = JSON.parse(acao)
    return obj[key]
  }
  return false
}

const getUnits = () => {
  const company = internalStorage.getItem(COMPANY_KEY)
  if (company) {
    const companyObj = JSON.parse(company)
    if (companyObj.unidades.length > 0) {
      return companyObj.unidades
    }
  }
  return null
}

const setCustomRoutes = (routes) => {
  if (routes) {
    internalStorage.setItem(CUSTOM_ROUTES_KEY, JSON.stringify(routes))
  } else {
    internalStorage.removeItem(CUSTOM_ROUTES_KEY)
  }
}

const getCustomRoutes = () => {
  const routes = internalStorage.getItem(CUSTOM_ROUTES_KEY)
  if (routes) {
    return JSON.parse(routes)
  }
  return []
}

const isCustomRoute = () => {
  const oidCurrentMenu = getOidCurrentMenu()
  const customRoutes = getCustomRoutes()
  if (customRoutes && customRoutes.length > 0) {
    const customRoute = customRoutes.find((route) => {
      return route.oidMenu === oidCurrentMenu
    })

    return !!customRoute
  }
  return false
}

const clearStorage = () => {
  internalStorage.removeItem(MENUS_PERMISSOES)
  internalStorage.clear()
}

const isCustomRoute2 = (customRoutes) => {
  const oidCurrentMenu = getOidCurrentMenu()
  if (customRoutes && customRoutes.length > 0) {
    const customRoute = customRoutes.find((route) => {
      return route.oidMenu === oidCurrentMenu
    })

    return !!customRoute
  }
  return false
}

export default {
  setToken,
  getToken,
  setCompany,
  getCompany,
  setUser,
  getUser,
  setAppLabels,
  getAppLabels,
  setOidCurrentMenu,
  getOidCurrentMenu,
  setCustomRoutes,
  getCustomRoutes,
  isCustomRoute,
  clearStorage,
  setOidDeletedMenu,
  getOidDeletedMenu,
  addLocalizacaoReferencia,
  getLocalizacaoReferencia,
  setUnit,
  getUnit,
  getUnits,
  setMenus,
  getMenus,
  setCompanies,
  getCompanies,
  setUsuarioUnidadeUnica,
  getUsuarioUnidadeUnica,
  setAlteraUnidade,
  getAlteraUnidade,
  setAcoes,
  getAcao,
  getInputsPorMascaraLocalizacao,
  // getUnidadeMemorial,
  getVersaoSistema,
  setVersaoSistema,
  getMenuLayout,
  setMenuLayout,
  getPortalCliente,
  setPortalCliente,
  getNomeUsuario,
  setNomeUsuario,
  isCustomRoute2,
  setUnidadesUsuario,
  getUnidadesUsuario,
  setMenuPath,
  getMenuPath,
}
