import React from 'react';

import ReligiaoFormPageContainer from './DocumentoFormPageContainer';

export class DocumentoFormPage extends React.Component{

    
    render(){
        return (
           <ReligiaoFormPageContainer {...this.props} />
        )
    }
}
