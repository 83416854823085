import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { getFiltroJoinUnidade } from '../../utils/filter-utils'
import { InputText } from 'primereact/inputtext'

export default function LtsLookupContrato(props) {
  const [filters, setFilters] = useState([])

  const columns = [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'numero',
      header: 'Código',
      columnKey: 'numero',
      sortable: true,
      filter: true,
      columnSortField: 'numero',
      visible: true,
    },
    {
      field: 'localizacao_identificacao',
      header: 'Localização',
      columnKey: 'localizacao_identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      relacionamento: ['localizacao'],
      fieldJoin: 'identificacao',
      visible: true,
    },
    {
      field: 'responsavel_oid',
      header: '',
      columnKey: 'responsavel_oid',
      columnSortField: 'responsavel.oid',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'responsavel_nome',
      header: 'Responsável',
      columnKey: 'responsavel_nome',
      columnSortField: 'responsavel.nome',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'responsavel_numeroDocumento',
      header: 'Num. Documento',
      columnKey: 'responsavel_numeroDocumento',
      columnSortField: 'responsavel.numeroDocumento',
      sortable: false,
      filter: false,
      relacionamento: ['responsavel'],
      fieldJoin: 'numeroDocumento',
      visible: false,
    },
    {
      field: 'conspaga',
      header: 'Conspaga',
      columnKey: 'conspaga',
      columnSortField: 'conspaga',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'unidade_oid',
      header: '',
      columnKey: 'unidade_oid',
      columnSortField: 'unidade.oid',
      sortable: true,
      filter: true,
      visible: false,
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade_sigla',
      columnSortField: 'unidade.sigla',
      sortable: true,
      filter: true,
      visible: false,
    },
    {
      field: 'tipoSepultura_descricao',
      header: '',
      columnKey: 'tipoSepultura_descricao',
      columnSortField: 'tipoSepultura_descricao',
      sortable: true,
      filter: true,
      visible: false,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/contratos/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('localizacao_identificacao', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        localizacao_identificacao: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const resolveValue = (data) => {
    const value = _.get(props, 'value', null)
    const oid = _.get(props, 'oidRecord', null)
    if (value) {
      return value
    } else if (oid) {
      return `#${oid} - sem localização`
    }
  }

  const autoCompleteTemplate = (data) => {
    const oid = _.get(data, 'oid', null)
    const numero = _.get(data, 'numero', null)
    const identificacao = _.get(data, 'localizacao_identificacao', null)
    if (identificacao) {
      return `${identificacao}`
    }

    var descricao = ''
    const tipoSepultura = _.get(data, 'tipoSepultura_descricao', null)
    if (tipoSepultura) {
      descricao = tipoSepultura
    } else {
      descricao = 'sem localização'
    }

    return `#${numero || oid} - ${descricao}`
  }

  const onResolvingColumn = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'numero') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'localizacao_identificacao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-localizacao-body-width"
            filterHeaderClassName="column-localizacao-filter-header-width"
            headerClassName="column-localizacao-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'responsavel_nome') {
        return <Column {...col} key={col.field} filterElement={columnFilterElement(col)} />
      }
      if (col.field === 'conspaga') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-conspaga-body-width"
            filterHeaderClassName="column-conspaga-filter-header-width"
            headerClassName="column-conspaga-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (props.showColumnUnidade && col.field === 'unidade_sigla') {
        return <Column {...col} key={col.field} filterElement={columnFilterElement(col)} />
      }

      return null
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    const filtroUnidade = getFiltroJoinUnidade(_.get(props, 'idUnidade', null))
    if (filtroUnidade) {
      filters.push(filtroUnidade)
    }
    return filters
  }

  const pathOnEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    if (oid) {
      return `${routes.PATH_CONTRATO}/${oid}`
    }
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={resolveValue()}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEditFn={pathOnEditFn}
        header={'Contrato'}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        addFiltroDistint={props.addFiltroDistint}
      />
    </React.Fragment>
  )
}
