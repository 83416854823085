import { types } from './localizacao.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils'
import { getUltimoCampoPesquisaLocalizacaoGrid } from '../../utils/tipo-sepultura-config-utils'
import _ from 'lodash'

const initialState = {
  id: 'localizacaoGrid',
  booleanFilter: {
    ativo: '',
  },
  tiposLocalizacao: [
    'localizacaoA',
    'localizacaoB',
    'localizacaoC',
    'localizacaoD',
    'localizacaoE',
    'localizacaoF',
    'localizacaoG',
    'localizacaoH',
    'localizacaoI',
    'localizacaoJ',
  ],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Identificação',
      value: {
        field: 'identificacao',
        header: 'Identificação',
        columnKey: 'identificacao',
        sortable: true,
        filter: true,
        columnSortField: 'identificacao',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade.value',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
      },
    },
    {
      label: 'Disponível',
      value: {
        field: 'disponivel',
        header: 'Disponível',
        columnKey: 'disponivel',
        sortable: false,
        columnSortField: 'disponivel',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        filter: true,
        columnSortField: 'observacao',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoA',
        columnKey: 'idA',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idA',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoB',
        columnKey: 'idB',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idB',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoC',
        columnKey: 'idC',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idC',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoD',
        columnKey: 'idD',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idD',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoE',
        columnKey: 'idE',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idE',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoF',
        columnKey: 'idF',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idF',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoG',
        columnKey: 'idG',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idG',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoH',
        columnKey: 'idH',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idH',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoI',
        columnKey: 'idI',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idI',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoJ',
        columnKey: 'idJ',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'idJ',
        visible: false,
      },
    },
  ],
  columns: [],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificacao',
      header: 'Identificação',
      columnKey: 'identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'identificacao',
      visible: true,
    },
  ],
  currentRecord: {
    oid: '',
    tipoSepultura: '',
    localizacaoA: '',
    localizacaoB: '',
    localizacaoC: '',
    localizacaoD: '',
    localizacaoE: '',
    localizacaoF: '',
    localizacaoG: '',
    localizacaoH: '',
    localizacaoI: '',
    localizacaoJ: '',
    identificacao: '',
    observacao: '',
    unidade: { id: '', value: '' },
    ativo: true,
    disponivel: true,
    filtros: [],
  },
  selectedFilters: [],
  messages: null,
  unidade: { id: '', value: '' },
}

const reducer = new Reducer(initialState, types)

export function CRUDLocalizacaoGridReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('localizacao')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_FILTER_LOCALIZACAO_GRID:
      return setFilterLocalizacaoGrid(state, action)
    case types.SET_UNIDADE:
      return setUnidade(state, action)
    case types.WILL_UNMOUNT:
      return willUnmount(state, action)
    case types.SET_MATRIZ_LOCALIZACAO:
      return setMatrizLocalizacao(state, action)
    case types.SET_OPCOES_PESQUISA:
      return setOpcoesPesquisa(state, action)
    default:
      return result
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setFilterLocalizacaoGrid(state, action) {
  const selectedFilters = Object.assign([], state.selectedFilters)
  const field = action.field
  const value = action.value

  if (utils.isArrayNotEmpty(selectedFilters)) {
    selectedFilters.forEach((f) => {
      if (field.indexOf(f.campoPesquisa.key) > 0) {
        f.valorSelecionado = value
      }
    })

    const empresa = storage.getCompany()
    const oidUnidade = state.unidade ? state.unidade.id : null
    const oidTipoSepultura = state.currentRecord.tipoSepultura.id
    let ultimoCampoPesquisa = getUltimoCampoPesquisaLocalizacaoGrid(
      _.get(state, 'currentRecord.tipoSepultura.object', null)
    )
    if (_.isEmpty(ultimoCampoPesquisa)) {
      ultimoCampoPesquisa = utils.getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura)
    }
    if (field.indexOf(ultimoCampoPesquisa) === -1) {
      const filtroUltimoCampo = selectedFilters.find((f) => f.campoPesquisa.key === ultimoCampoPesquisa)
      if (filtroUltimoCampo) {
        const index = selectedFilters.indexOf(filtroUltimoCampo)
        selectedFilters.splice(index, 1)
        state = Object.assign({}, state, { records: [] })
        state = Object.assign({}, state, { matriz: [] })
      }
    }
  }

  return Object.assign({}, state, { selectedFilters })
}

function setOpcoesPesquisa(state, action) {
  const selectedFilters = Object.assign([], state.selectedFilters)
  const payload = action.payload

  if (utils.isArrayNullOrEmpty(selectedFilters) && !utils.isEmptyObject(payload)) {
    selectedFilters.push(payload)
  } else if (utils.nonNull(payload.campoPesquisa) && utils.nonNull(payload.campoPesquisa.key)) {
    const existente = selectedFilters.find((f) => f.campoPesquisa.key === payload.campoPesquisa.key)
    if (existente) {
      const index = selectedFilters.indexOf(existente)
      selectedFilters.splice(index, 1, payload)
    } else {
      selectedFilters.push(payload)
    }
  }

  return Object.assign({}, state, { selectedFilters })
}

function setUnidade(state, action) {
  const unidade = action.unidade
  const tipoSepultura = ''
  state.currentRecord = Object.assign({}, state.currentRecord, { tipoSepultura })
  state = Object.assign({}, state, { selectedFilters: [] })
  state = Object.assign({}, state, { records: [] })
  state = Object.assign({}, state, { matriz: [] })
  return Object.assign({}, state, { unidade })
}

function willUnmount(state, action) {
  const selectedFilters = []
  const records = []
  const matriz = []
  const unidade = { id: '', value: '' }
  const tipoSepultura = ''
  state = Object.assign({}, state, { unidade })
  state = Object.assign({}, state, { records })
  state = Object.assign({}, state, { matriz })
  state = Object.assign({}, state, { matriz })
  state.currentRecord = Object.assign({}, state.currentRecord, { tipoSepultura })
  return Object.assign({}, state, { selectedFilters })
}

function setMatrizLocalizacao(state, action) {
  const matriz = action.payload && action.payload.alternativeRecords ? action.payload.alternativeRecords : null
  return Object.assign({}, state, { matriz })
}
