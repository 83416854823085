import { types } from "./parametro.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "parametrosunidade",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Chave",
      value: {
        field: "id",
        header: "Chave",
        columnKey: "id",
        sortable: true,
        filter: false,
        columnSortField: "id",
        visible: true
      }
    },
    {
      label: "Valor",
      value: {
        field: "value",
        header: "Valor",
        columnKey: "value",
        sortable: true,
        columnSortField: "value",
        filter: false,
        visible: true
      }
    }
  ],
  columns: [],
  currentRecord: {
    id:"",
    value:""
  },
  initialState: {},
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDParametroReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("parametrosunidade");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}
