import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { acoes, icons, routes } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../localizacao.actions'
import { LocalizacaoList } from './LocalizacaoList'
import { Button } from 'primereact/button'
import _ from 'lodash'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import storage from '../../../services/stoage.service'

const mapStateToProps = (state) => {
  let messages = state.crudLocalizacaoState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  let showHideColumns = state.crudLocalizacaoState.showHideColumns
  showHideColumns = showHideColumns.filter((col) => {
    if (!col.label || col.label === '') {
      const label = utils.getMessage(messages, `localizacao.label.${col.value.field}`)
      col.label = label
      return (col.value.header = label)
    }
    return col
  })
  return {
    actions,
    showHideColumns,
    messages,
    messagesDialog,
    columns: state.crudLocalizacaoState.columns,
    records: state.crudLocalizacaoState.records,
    first: state.crudLocalizacaoState.index,
    rows: state.crudLocalizacaoState.max,
    totalRecords: state.crudLocalizacaoState.count,
    sortField: state.crudLocalizacaoState.sortField,
    order: state.crudLocalizacaoState.sortOrder,
    selectedFilters: state.crudLocalizacaoState.selectedFilters,
    selectedRecords: state.crudLocalizacaoState.selectedRecords,
    booleanFilter: state.crudLocalizacaoState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'SIM', value: true },
      { label: 'NÃO', value: false },
    ],
    conservacaoTypes: [
      { label: 'TODOS', value: '' },
      { label: 'ÓTIMO', value: 1 },
      { label: 'BOM', value: 2 },
      { label: 'REGULAR', value: 3 },
      { label: 'PRECÁRIO', value: 4 },
    ],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      // tratamentos para nomes dos filtros boolean
      if ('disponivel' === target.name || 'temporaria' === target.name) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      } else if ('tipoConservacao' === target.name) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/localizacoes/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    loadAll: () => {
      dispatch(actions.executeFilter(`/lottustech/localizacoes/all`))
    },
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),

    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
    removerLocalizacoes: (oids) => {
      dispatch(actions.removerLocalizacoes(oids))
    },
  }
}

class LocalizacaoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      showSelectArquivoImportacao: false,
      showConfirmarExclusaoLote: false,
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)

    actions.afterRemoveList = () => {
      this.props.loadAll()
    }
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  // getTeamplateImportacao() {
  //   this.props.getTeamplateImportacao()
  // }

  hideSelectArquivoImportacao() {
    this.setState({ showSelectArquivoImportacao: false })
  }

  showSelectArquivoImportacao() {
    this.setState({ showSelectArquivoImportacao: true })
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_LOCALIZACAO}/novo`)
  }
  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }
  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypes}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result) {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} />{' '}
        </div>
      )
    } else {
      return (
        <div style={{ color: 'grey', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-times-circle" style={{ fontSize: '1.8rem' }} />
        </div>
      )
    }
  }

  tipoConservacaoFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.conservacaoTypes}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'localizacao.label.localizacoes')}
      />
    )
  }

  oidTemplate = (rowData, column) => {
    return <Link to={`${routes.PATH_LOCALIZACAO}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  idValueTemplate = (rowData, column) => {
    if (rowData[column.field] && rowData[column.field].value) {
      return <div>{rowData[column.field].value}</div>
    }
    return null
  }

  tipoConservacaoTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      return <div>{utils.getTipoConservacaoFromInt(rowData[column.field])}</div>
    }
    return null
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  onConfirmExclusaoLote = () => {
    const selectedRecords = _.get(this, 'props.selectedRecords', [])
    const oids = selectedRecords.map((o) => o.oid)
    if (oids.length > 0) {
      this.props.removerLocalizacoes(oids)
    }
  }

  confirmarExclusaoLote = () => {
    this.setState({ showConfirmarExclusaoLote: true })
  }

  getDialogConfirmarExclusaoLote() {
    if (this.state.showConfirmarExclusaoLote) {
      const msg = utils.getMessage(this.props.messagesDialog, 'localizacao.dialog.message.confirmar.remover')
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExclusaoLote}
          content={msg}
          confirm={this.onConfirmExclusaoLote}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  hideDialog = () => {
    this.setState({ showConfirmarExclusaoLote: false })
  }

  getCustomButtons = () => {
    if (_.defaultTo(storage.getAcao(acoes.EXCLUIR_LOCALIZACAO), false)) {
      return (
        <div>
          <Button
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            label={utils.getMessage(this.props.messages, 'label.remover')}
            onClick={(e) => this.confirmarExclusaoLote()}
            style={{ marginLeft: '.25em' }}
            disabled={_.get(this, 'props.selectedRecords', []).length === 0}
          />
        </div>
      )
    }
    return null
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '2.5em' }} />
      }
      if (col.header) {
        if (col.field === 'oid') {
          return (
            <Column
              {...col}
              key={col.field}
              body={this.oidTemplate}
              sortField={col.columnSortField}
              filterElement={this.fieldFilter(col)}
              bodyClassName="column-oid-body-width"
              filterHeaderClassName="column-oid-filter-header-width"
              headerClassName="column-oid-header-width"
            />
          )
        } else if (col.field === 'tipoSepultura') {
          return (
            <Column
              {...col}
              key={col.field}
              body={this.idValueTemplate}
              sortField={col.columnSortField}
              filterElement={this.fieldFilter(col)}
            />
          )
        } else if (
          ['disponivel'].find((field) => field === col.field) ||
          ['temporaria'].find((field) => field === col.field)
        ) {
          return (
            <Column
              {...col}
              key={col.field}
              sortField={col.columnSortField}
              body={this.booleanFieldsTemplate}
              filterHeaderStyle={{ overflow: 'visible' }}
              filterElement={this.booleanFilter(col)}
            />
          )
        } else if (col.field === 'tipoConservacao') {
          return (
            <Column
              {...col}
              key={col.field}
              sortField={col.columnSortField}
              body={this.tipoConservacaoTemplate}
              filterElement={this.tipoConservacaoFilter(col)}
            />
          )
        }
        return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
      }
    })
  }

  buildContent = () => {
    return (
      <div>
        {this.getDialogConfirmarExclusaoLote()}
        <LocalizacaoList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalizacaoListPageContainer)
