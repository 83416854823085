import React from 'react';
import _ from "lodash";
import utils from "../../utils";
import LtsDropdownIdValue from "./lts-dropdown-id-value";
import apiService from "../../services/api-id-value-service";

export default function LtsDropdownTipoSepultura(props) {

    const onAfterLoadDatFn = data => {
        const hideRecords = _.get(props, 'hideRecords', [])
        if (utils.isArrayNotEmpty(hideRecords) && utils.isArrayNotEmpty(data)) {
            let newData = []
            data.forEach(d => {
                const hide = hideRecords.find(h => h === d.id)
                if (!hide) {
                    newData.push(d)
                }
            })
            return newData
        }
        return data
    }

    return (
        <React.Fragment>
            <LtsDropdownIdValue
                name={props.name}
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                loadDataFn={apiService.getTiposSepultura}
                loadDataFnParam={props.loadDataFnParam}
                afterLoadDataFn={onAfterLoadDatFn}
            />
        </React.Fragment>
    )
}