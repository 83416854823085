import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import _ from 'lodash'
import { onlyNumbers } from '../../utils'
const BASE_PATH = '/lottustech/pessoas'
const crud = build(validate, BASE_PATH)
const prefix = 'crudPessoa'

const newTypes = {
  SET_LOOKUP_RELIGIAO_VISIBLE: `${prefix}setLookupReligiaoVisible`,
  SET_LOOKUP_CATEGORIA_VISIBLE: `${prefix}setLookupCategoriaVisible`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setLookupCategoriaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CATEGORIA_VISIBLE,
    visible,
  }
}

actions.setLookupReligiaoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_RELIGIAO_VISIBLE,
    visible,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.abrirWebWhatsApp = ({ oidPessoa, telefone }) => {
  return (dispatch, getState) => {
    const parsedTelefone = onlyNumbers(telefone)
    apiService
      .get(`${BASE_PATH}/${oidPessoa}/${parsedTelefone}/templateWebWhatsApp/`)
      .then((response) => {
        const link = _.get(response, 'data', null)
        if (link) {
          const win = window.open(link)
          if (win) {
            win.focus()
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
export { types, actions }
