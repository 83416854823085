import storage from '../services/stoage.service'
import { logarAcesso } from '../services/api-log-acesso'
const React = require('react')
const _ = require('lodash')
const { acoes } = require('../constants')

export function onColumnReorder(e, colunasAtuais, listPageContainer) {
  let result = []
  let colunasOrdenadas = []
  let colunasGrid = _.get(listPageContainer, 'props.columns', [])

  e.columns.forEach(function (col) {
    let found = false
    colunasAtuais = colunasAtuais.filter(function (item) {
      if (!found && item.value.columnKey === col.props.columnKey) {
        result.push(item)
        found = true
        return false
      }
      return true
    })

    let colunaGrid = colunasGrid.find((cg) => col.props.columnKey === cg.columnKey)
    if (colunaGrid) {
      colunasOrdenadas.push(colunaGrid)
    }
  })
  listPageContainer.orderedColumns = colunasOrdenadas
  listPageContainer.showHideColumns = result.concat(colunasAtuais)
}

export function resolveFieldData(data, field) {
  if (data && field) {
    return data[field]
  }
  return null
}

export function onCsvExport(records, listPageContainer, actions, excel = true) {
  // metodo para as listagens fazerem o processamento do dataset a ser exportado
  if (records) {
    listPageContainer.props.exportarDadosCSV(excel)
    actions.afterGetExportDataset = (dados, usaListDto) => {
      if (excel) {
        exportExcel(dados, listPageContainer, usaListDto)
      } else {
        exportCSV(dados, listPageContainer.props.columns, usaListDto)
      }
    }
  }
}

export function exportCSV(data, colunas, usaListDto) {
  let csv = '\uFEFF'
  const columns = colunas.filter((col) => col.visible && col.header !== '' && col.header !== 'Seleção')
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].header) {
      csv += '"' + columns[i].header + '"'

      if (i < columns.length - 1) {
        csv += ','
      }
    }
  }
  //body
  let dataArray = []
  if (Array.isArray(data)) {
    dataArray = data
  } else if (data.records) {
    dataArray = data.records
  }

  dataArray.forEach(function (record, i) {
    csv += '\n'

    for (let _i = 0; _i < columns.length; _i++) {
      if (columns[_i].columnKey) {
        if (usaListDto) {
          csv += '"' + resolveFieldData(record, columns[_i].columnKey) + '"'
        } else {
          csv += '"' + resolveFieldData(record, columns[_i].field) + '"'
        }

        if (_i < columns.length - 1) {
          csv += ','
        }
      }
    }
  })
  let blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;',
  })

  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, this.props.exportFilename + '.csv')
  } else {
    let link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)

    if (link.download !== undefined) {
      link.setAttribute('href', URL.createObjectURL(blob))
      link.setAttribute('download', 'relatorio.csv')
      link.click()
    } else {
      csv = 'data:text/csv;charset=utf-8,' + csv
      window.open(encodeURI(csv))
    }

    document.body.removeChild(link)
  }
}

export function onExcelExport(records, listPageContainer, actions) {
  // metodo para as listagens fazerem o processamento do dataset a ser exportado
  if (records) {
    listPageContainer.props.exportarDadosCSV(true)
    actions.afterExportExcel = (dados, usaListDto) => {
      exportExcel(dados, listPageContainer, usaListDto)
    }
  }
}

export function exportExcel(data, listPageContainer, usaListDto) {
  let dataArray = []
  if (Array.isArray(data)) {
    dataArray = data
  } else if (data.records) {
    dataArray = data.records
  }

  if (dataArray && dataArray.length > 0) {
    const colunas = listPageContainer.props.columns
    const columns = colunas.filter((col) => col.visible && col.header !== '' && col.header !== 'Seleção')
    const columnsDefinitions = []
    columns.forEach((c) => {
      if (usaListDto) {
        columnsDefinitions.push({ label: c.header, field: c.columnKey })
      } else {
        columnsDefinitions.push({ label: c.header, field: c.field })
      }
    })
    if (columnsDefinitions && columnsDefinitions.length > 0) {
      const excelDataset = {
        columnsDefinitions: columnsDefinitions,
        records: dataArray,
      }
      listPageContainer.setState({ excelDataset: excelDataset })
    } else {
      listPageContainer.setState({ excelDataset: null })
    }
  }
}

export function onColumnToggle(event, listPageContainer) {
  const value = event.value
  let result = []
  if (value && value.length > 0) {
    result = value
      .filter((col) => col.value)
      .filter((col) => {
        return (col.value.visible = true)
      })
      .map((col) => {
        return col.value
      })
    listPageContainer.props.setColumns(result)
  }
}

export function loadFavorito(listPageContainer, actions) {
  const history = listPageContainer.props.history
  if (history) {
    listPageContainer.props.loadFavorito(history.location.pathname)
  }
  actions.afterLoadFavorito = (data) => {
    updateInfoFavorito(listPageContainer, data)
  }
}

export function changeFavorito(listPageContainer, actions) {
  if (listPageContainer.state.favorito) {
    listPageContainer.props.removeFavorito(listPageContainer.state.dadosFavorito.oid)
    actions.afterRemoveFavorito = () => {
      updateInfoFavorito(listPageContainer, null)
    }
  } else {
    listPageContainer.props.adicionarFavorito(listPageContainer.props.history.location.pathname)
    actions.afterAdicionarFavorito = (data) => {
      updateInfoFavorito(listPageContainer, data)
    }
  }
}

export function updateInfoFavorito(listPageContainer, data) {
  if (data && data.oid) {
    listPageContainer.setState({ favorito: 1 })
    listPageContainer.setState({ dadosFavorito: data })
  } else {
    listPageContainer.setState({ favorito: 0 })
    listPageContainer.setState({ dadosFavorito: null })
  }
}

export function componentDidMount(listPageContainer) {
  logarAcesso().then()
  listPageContainer.props.clearSelectedFilters()
  if (listPageContainer.props.load) {
    listPageContainer.props.load()
  }
  listPageContainer.loadFavorito()
}

export function componentDidUpdate(listPageContainer, prevProps, prevState) {
  if (listPageContainer.props.history.location.pathname !== prevProps.location.pathname) {
    listPageContainer.props.clearSelectedFilters()
    listPageContainer.props.load()
    listPageContainer.showHideColumns = prevProps.showHideColumns
    listPageContainer.showFilter = false
  } else if (listPageContainer.showHideColumns.length === 0) {
    listPageContainer.showHideColumns = prevProps.showHideColumns
  }
}

// FIXME: Necessidade de definições de colunas das telas estar em um arquivo genérico
const defaultColumns = {
  pessoaFisica: [
    {
      field: 'numeroDocumento',
      header: 'CPF',
      columnKey: 'numeroDocumento',
      sortable: true,
      columnSortField: 'numeroDocumento',
      filter: true,
      visible: true,
      selected: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
      selected: true,
    },
    {
      field: 'religiao',
      header: 'Religião',
      columnKey: 'religiao',
      sortable: true,
      filter: true,
      columnSortField: 'religiao.nome',
      relacionamento: ['religiao'],
      fieldJoin: 'nome',
      visible: false,
    },
  ],
  pessoaJuridica: [
    {
      field: 'numeroDocumento',
      header: 'CNPJ',
      columnKey: 'numeroDocumento',
      sortable: true,
      columnSortField: 'numeroDocumento',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Razão Social',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
    {
      field: 'nomeFantasia',
      header: 'Nome Fantasia',
      columnKey: 'nomeFantasia',
      sortable: true,
      columnSortField: 'nomeFantasia',
      filter: true,
      visible: true,
    },
  ],
  sexo: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      columnSortField: 'oid',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      columnSortField: 'descricao',
      sortable: true,
      filter: true,
      visible: true,
    },
  ],
  religiao: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
  ],
  consultaFalecido: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: false,
      filter: false,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'nomeUnidade',
      header: 'Unidade',
      columnKey: 'nomeUnidade',
      columnSortField: 'nomeUnidade',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'localizacao',
      header: 'Localização',
      columnKey: 'localizacao',
      columnSortField: 'localizacao',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'falecido',
      header: 'Falecido',
      columnKey: 'falecido',
      columnSortField: 'falecido',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
      visible: true,
      filter: true,
      sortable: true,
      columnSortField: 'dataNascimento',
      fieldFormatter: 'formatarData',
    },
    {
      field: 'dataFalecimento',
      header: 'Falecimento',
      columnKey: 'dataFalecimento',
      fieldFormatter: 'formatarData',
      columnSortField: 'dataNascimento',
      visible: true,
      filter: true,
      sortable: true,
    },
  ],
  portalClienteContrato: [
    {
      field: 'numeroContrato',
      header: 'Código',
      columnKey: 'numeroContrato',
      sortable: true,
      filter: true,
      columnSortField: 'numeroContrato',
      visible: true,
      showFilterMenuOptions: false,
      showClearButton: false,
      showFilterMenu: false,
    },
    {
      field: 'tipoContrato',
      columnKey: 'tipoContrato',
      sortable: true,
      header: 'Tipo Contrato',
      filter: true,
      columnSortField: 'tipoContrato',
      visible: true,
    },
    {
      field: 'nomeContratoDocumento',
      columnKey: 'nomeContratoDocumento',
      sortable: true,
      header: 'Tipo do documento',
      filter: true,
      columnSortField: 'nomeContratoDocumento',
      visible: true,
    },
    {
      field: 'nomeEmail',
      columnKey: 'nomeEmail',
      sortable: true,
      header: 'Nome',
      filter: true,
      columnSortField: 'nomeEmail',
      visible: true,
    },
    {
      field: 'oidContratoDocumento',
      header: 'Ações',
      columnKey: 'oidContratoDocumento',
      sortable: false,
      filter: false,
      columnSortField: 'oidContratoDocumento',
      visible: true,
    },
  ],
  portalClienteCarteirinha: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: false,
      filter: false,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'cpf',
      header: 'CPF',
      columnKey: 'cpf',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
      sortable: true,
      filter: true,
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'oidContrato',
      header: 'Carteirinha',
      columnKey: 'oidContrato',
      sortable: false,
      filter: false,
      columnSortField: 'oidContrato',
      visible: true,
    },
  ],
  tipoSepultura: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
    {
      field: 'unidade',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
  ],
  categoria: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      columnSortField: 'descricao',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'categoriaPai.value',
      header: 'Especialidade',
      columnKey: 'categoriaPai',
      columnSortField: 'categoriaPai.descricao',
      sortable: true,
      filter: true,
      visible: true,
      relacionamento: ['categoriaPai'],
      fieldJoin: 'descricao',
    },
  ],
  orcamento: [
    // {
    //   field: "multiselect",
    //   header: "",
    //   columnKey: "multiselect",
    //   visible: true
    // },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'dataEmissao',
      header: 'Emissão',
      columnKey: 'dataEmissao',
      sortable: true,
      filter: true,
      columnSortField: 'dataEmissao',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'valorTotal',
      header: 'Valor',
      columnKey: 'valorTotal',
      sortable: true,
      filter: true,
      columnSortField: 'valorTotal',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'responsavel_oid',
      header: '',
      columnKey: 'responsavel',
      columnSortField: 'responsavel.oid',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'responsavel_nome',
      header: 'Responsável',
      columnKey: 'responsavel',
      columnSortField: 'responsavel.nome',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
    {
      field: 'tipoSepultura_descricao',
      columnKey: 'tipoSepultura.tipoSepultura',
      sortable: true,
      header: 'Tipo',
      filter: true,
      columnSortField: 'tipoSepultura.descricao',
      relacionamento: ['tipoSepultura'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'grupoFinanceiro_descricao',
      header: 'Serviço',
      columnKey: 'taxaPrincipal.grupoFinanceiro.descricao',
      sortable: true,
      filter: true,
      columnSortField: 'taxaPrincipal.grupoFinanceiro.descricao',
      relacionamento: ['taxaPrincipal'],
      fieldJoin: 'grupoFinanceiro.descricao',
      visible: true,
    },
    // {
    //   field: "contrato_oid",
    //   header: "",
    //   columnKey: "contrato",
    //   columnSortField: "contrato.oid",
    //   sortable: true,
    //   filter: true,
    //   relacionamento: ["contrato"],
    //   fieldJoin: "oid",
    //   visible: true
    // },
  ],
  notaEntrada: [
    {
      field: 'multiselect',
      header: '',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'chave',
      header: 'Chave',
      columnKey: 'chave',
      sortable: true,
      filter: true,
      columnSortField: 'chave',
      visible: true,
    },
    {
      field: 'numero',
      header: 'Número',
      columnKey: 'numero',
      sortable: true,
      filter: true,
      columnSortField: 'numero',
      visible: true,
    },
    {
      field: 'serie',
      header: 'Série',
      columnKey: 'serie',
      sortable: true,
      filter: true,
      columnSortField: 'serie',
      visible: true,
    },
    {
      field: 'fornecedor_nomeFantasia',
      columnKey: 'fornecedor.nomeFantasia',
      sortable: true,
      header: 'Nome fantasia',
      filter: true,
      columnSortField: 'fornecedor.nomeFantasia',
      relacionamento: ['fornecedor'],
      fieldJoin: 'nomeFantasia',
      visible: true,
    },
    {
      field: 'fornecedor_nome',
      columnKey: 'fornecedor.nome',
      sortable: true,
      header: 'Razão social',
      filter: true,
      columnSortField: 'fornecedor.nome',
      relacionamento: ['fornecedor'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'dataEmissao',
      header: 'Emissão',
      columnKey: 'dataEmissao',
      sortable: true,
      filter: true,
      columnSortField: 'dataEmissao',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'dataEntrada',
      header: 'Entrada',
      columnKey: 'dataEntrada',
      sortable: true,
      filter: true,
      columnSortField: 'dataEntrada',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'valorNota',
      header: 'Valor',
      columnKey: 'valorNota',
      sortable: true,
      filter: true,
      columnSortField: 'valorNota',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
  ],
  contrato: [
    {
      field: 'multiselect',
      header: '',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Oid',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'numero',
      header: 'Código',
      columnKey: 'numero',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'localizacao_oid',
      header: '',
      columnKey: 'localizacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.oid',
      visible: true,
      relacionamento: ['localizacao'],
      fieldJoin: 'oid',
    },
    {
      field: 'localizacao_identificacao',
      header: 'Localização',
      columnKey: 'localizacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      visible: true,
      relacionamento: ['localizacao'],
      fieldJoin: 'identificacao',
    },
    {
      field: 'falecido_oid',
      header: '',
      columnKey: 'falecido',
      columnSortField: 'falecido.oid',
      sortable: true,
      filter: true,
      relacionamento: ['falecido'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'falecido_nome',
      header: 'Falecido',
      columnKey: 'falecido',
      columnSortField: 'falecido.nome',
      sortable: true,
      filter: true,
      relacionamento: ['falecido'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'responsavel_oid',
      header: '',
      columnKey: 'responsavel',
      columnSortField: 'responsavel.oid',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'responsavel_nome',
      header: 'Responsável',
      columnKey: 'responsavel',
      columnSortField: 'responsavel.nome',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'vendedor_oid',
      header: '',
      columnKey: 'vendedor',
      columnSortField: 'vendedor.oid',
      sortable: true,
      filter: true,
      relacionamento: ['vendedor'],
      fieldJoin: 'oid',
      visible: false,
    },
    {
      field: 'vendedor_nome',
      header: 'Vendedor',
      columnKey: 'vendedor',
      columnSortField: 'vendedor.nome',
      sortable: true,
      filter: true,
      relacionamento: ['vendedor'],
      fieldJoin: 'nome',
      visible: false,
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
    {
      field: 'data',
      header: 'Data',
      columnKey: 'data',
      columnSortField: 'data',
      sortable: true,
      filter: true,
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'conspaga',
      header: 'Conspaga',
      columnKey: 'conspaga',
      sortable: true,
      filter: true,
      columnSortField: 'conspaga',
      visible: true,
    },
    {
      field: 'ativo',
      columnKey: 'ativo',
      sortable: true,
      header: 'Ativo',
      filter: true,
      columnSortField: 'ativo',
      visible: true,
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
      visible: false,
    },
    {
      field: 'tipoSepultura_descricao',
      columnKey: 'tipoSepultura.tipoSepultura',
      sortable: true,
      header: 'Tipo Contrato',
      filter: true,
      columnSortField: 'tipoSepultura.descricao',
      relacionamento: ['tipoSepultura'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'localizacao_numeroVagas',
      columnKey: 'localizacao.numeroVagas',
      sortable: true,
      header: 'Vagas',
      filter: true,
      columnSortField: 'localizacao.numeroVagas',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'numeroVagas',
    },
    {
      field: 'localizacao_numeroLugares',
      columnKey: 'localizacao.numeroLugares',
      sortable: true,
      header: 'Lugares',
      filter: true,
      columnSortField: 'localizacao.numeroLugares',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'numeroLugares',
    },
    {
      field: 'localizacao_localizacaoA',
      columnKey: 'localizacaoA',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.localizacaoA',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'localizacaoA',
    },
    {
      field: 'localizacao_localizacaoB',
      columnKey: 'localizacaoB',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.localizacaoB',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'localizacaoB',
    },
    {
      field: 'localizacao_localizacaoC',
      columnKey: 'localizacaoC',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.localizacaoC',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'localizacaoC',
    },
    {
      field: 'localizacao_localizacaoD',
      columnKey: 'localizacao.localizacaoD',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idD',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idD',
    },
    {
      field: 'localizacao_localizacaoE',
      columnKey: 'localizacao.idE',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idE',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idE',
    },
    {
      field: 'localizacao_localizacaoF',
      columnKey: 'localizacao.idF',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idF',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idF',
    },
    {
      field: 'localizacao_localizacaoG',
      columnKey: 'localizacao.idG',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idG',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idG',
    },
    {
      field: 'localizacao_localizacaoH',
      columnKey: 'localizacao.idH',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idH',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idH',
    },
    {
      field: 'localizacao_localizacaoI',
      columnKey: 'localizacao.idI',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idI',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idI',
    },
    {
      field: 'localizacao_localizacaoJ',
      columnKey: 'localizacao.idJ',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'localizacao.idJ',
      visible: false,
      relacionamento: ['localizacao'],
      fieldJoin: 'idJ',
    },
    {
      field: 'falecido_dataFalecimento',
      header: 'Data Falecimento',
      columnKey: 'falecido.dataFalecimento',
      columnSortField: 'falecido.dataFalecimento',
      sortable: true,
      filter: true,
      relacionamento: ['falecido'],
      fieldJoin: 'dataFalecimento',
      fieldFormatter: 'formatarData',
      visible: false,
    },
    {
      field: 'falecidos_dataPrevisaoExumacao',
      header: 'Data Previsão Exumação',
      columnKey: 'falecidos.dataPrevisaoExumacao',
      columnSortField: 'falecidos.dataPrevisaoExumacao',
      sortable: true,
      filter: true,
      relacionamento: ['falecidos'],
      fieldJoin: 'dataPrevisaoExumacao',
      fieldFormatter: 'formatarData',
      visible: false,
    },
    {
      field: 'falecidos_dataExumacao',
      header: 'Data Exumação',
      columnKey: 'falecidos.dataExumacao',
      columnSortField: 'falecidos.dataExumacao',
      sortable: true,
      filter: true,
      relacionamento: ['falecidos'],
      fieldJoin: 'dataExumacao',
      fieldFormatter: 'formatarData',
      visible: false,
    },
    {
      field: 'sequencialCremacao',
      header: 'Código Cremação',
      columnKey: 'sequencialCremacao',
      columnSortField: 'sequencialCremacao',
      sortable: true,
      filter: true,
      visible: false,
    },
    {
      field: 'sequencialCremacaoFutura',
      header: 'Código Cremação Futura',
      columnKey: 'sequencialCremacaoFutura',
      columnSortField: 'sequencialCremacaoFutura',
      sortable: true,
      filter: true,
      visible: false,
    },
    {
      field: 'sequencialCremacaoFutura',
      header: 'Código Cremação Futura',
      columnKey: 'sequencialCremacaoFutura',
      columnSortField: 'sequencialCremacaoFutura',
      sortable: true,
      filter: true,
      visible: false,
    },
    {
      field: 'dataProximaRemessaCompetencia',
      header: 'Competência próxima remessa',
      columnKey: 'dataProximaRemessaCompetencia',
      columnSortField: 'dataProximaRemessaCompetencia',
      sortable: true,
      filter: true,
      visible: false,
    },
  ],
  localizacao: [
    {
      field: 'multiselect',
      header: '',
      columnKey: 'multiselect',
      visible: storage.getAcao(acoes.EXCLUIR_LOCALIZACAO),
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificacao',
      header: 'Identificação',
      columnKey: 'identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'identificacao',
      visible: true,
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
      visible: false,
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'tipoSepultura_descricao',
      header: 'Tipo de Sepultura',
      columnKey: 'tipoSepultura',
      sortable: true,
      filter: true,
      columnSortField: 'tipoSepultura.descricao',
      relacionamento: ['tipoSepultura'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'temporaria',
      header: 'Temporária',
      columnKey: 'temporaria',
      sortable: false,
      columnSortField: 'temporaria',
      filter: true,
      visible: true,
    },
    {
      field: 'disponivel',
      header: 'Disponível',
      columnKey: 'disponivel',
      sortable: false,
      columnSortField: 'disponivel',
      filter: true,
      visible: true,
    },
    {
      field: 'localizacaoA',
      columnKey: 'idA',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idA',
      visible: false,
    },
    {
      field: 'localizacaoB',
      columnKey: 'idB',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idB',
      visible: false,
    },
    {
      field: 'localizacaoC',
      columnKey: 'idC',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idC',
      visible: false,
    },
    {
      field: 'localizacaoD',
      columnKey: 'idD',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idD',
      visible: false,
    },
    {
      field: 'localizacaoE',
      columnKey: 'idE',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idE',
      visible: false,
    },
    {
      field: 'localizacaoF',
      columnKey: 'idF',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idF',
      visible: false,
    },
    {
      field: 'localizacaoG',
      columnKey: 'idG',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idG',
      visible: false,
    },
    {
      field: 'localizacaoH',
      columnKey: 'idH',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idH',
      visible: false,
    },
    {
      field: 'localizacaoI',
      columnKey: 'idI',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idI',
      visible: false,
    },
    {
      field: 'localizacaoJ',
      columnKey: 'idJ',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idJ',
      visible: false,
    },
  ],
  localizacaoGrid: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificacao',
      header: 'Identificação',
      columnKey: 'identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'identificacao',
      visible: true,
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
      visible: false,
    },
    {
      field: 'unidade.value',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'disponivel',
      header: 'Disponível',
      columnKey: 'disponivel',
      sortable: false,
      columnSortField: 'disponivel',
      filter: true,
      visible: true,
    },
    {
      field: 'localizacaoA',
      columnKey: 'idA',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idA',
      visible: false,
    },
    {
      field: 'localizacaoB',
      columnKey: 'idB',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idB',
      visible: false,
    },
    {
      field: 'localizacaoC',
      columnKey: 'idC',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idC',
      visible: false,
    },
    {
      field: 'localizacaoD',
      columnKey: 'idD',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idD',
      visible: false,
    },
    {
      field: 'localizacaoE',
      columnKey: 'idE',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idE',
      visible: false,
    },
    {
      field: 'localizacaoF',
      columnKey: 'idF',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idF',
      visible: false,
    },
    {
      field: 'localizacaoG',
      columnKey: 'idG',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idG',
      visible: false,
    },
    {
      field: 'localizacaoH',
      columnKey: 'idH',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idH',
      visible: false,
    },
    {
      field: 'localizacaoI',
      columnKey: 'idI',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idI',
      visible: false,
    },
    {
      field: 'localizacaoJ',
      columnKey: 'idJ',
      sortable: true,
      header: '',
      filter: true,
      columnSortField: 'idJ',
      visible: false,
    },
  ],
  taxaFinanceira: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'grupoFinanceiro_descricao',
      header: 'Grupo',
      columnKey: 'grupoFinanceiro',
      sortable: true,
      filter: true,
      columnSortField: 'grupoFinanceiro.descricao',
      relacionamento: ['grupoFinanceiro'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
      fieldFormatter: 'formatarDinheiro',
    },
    {
      field: 'vigenciaInicial',
      header: 'Data Inicial',
      columnKey: 'vigenciaInicial',
      sortable: true,
      filter: true,
      columnSortField: 'vigenciaInicial',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'vigenciaFinal',
      header: 'Data Final',
      columnKey: 'vigenciaFinal',
      sortable: true,
      filter: true,
      columnSortField: 'vigenciaFinal',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
    {
      field: 'taxaContrapartidaGrupo_descricao',
      columnKey: 'taxaContrapartidaGrupo.descricao',
      sortable: true,
      header: 'Contrapartida',
      filter: true,
      columnSortField: 'taxaContrapartidaGrupo.descricao',
      relacionamento: ['taxasContrapartidas'],
      fieldJoin: 'taxaAlvo.grupoFinanceiro.descricao',
      visible: true,
    },
    {
      field: 'taxaContrapartida_oid',
      columnKey: 'taxaContrapartida.oid',
      sortable: true,
      header: 'Cód. Contrapart.',
      filter: true,
      columnSortField: 'taxaContrapartida.oid',
      relacionamento: ['taxaContrapartida'],
      fieldJoin: 'oid',
      visible: true,
    },
  ],
  grupoFinanceiro: [
    {
      field: 'multiselect',
      header: '',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'codigoMascara',
      header: 'Cód. Masc.',
      columnKey: 'codigoMascara',
      sortable: true,
      filter: true,
      columnSortField: 'codigoMascara',
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      visible: true,
    },
    {
      field: 'tipo',
      header: 'Tipo',
      columnKey: 'tipo',
      sortable: true,
      filter: true,
      columnSortField: 'tipo',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'grupoFinanceiroPai.value',
      header: 'Grupo Pai',
      columnKey: 'grupoFinanceiroPai',
      sortable: true,
      columnSortField: 'grupoFinanceiroPai.descricao',
      filter: true,
      relacionamento: ['grupoFinanceiroPai'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'unidade.value',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      columnSortField: 'unidade.sigla',
      filter: true,
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'grupoFinanceiroPai.id',
      header: 'Cód. Grupo Pai',
      columnKey: 'grupoFinanceiroPai',
      sortable: true,
      columnSortField: 'grupoFinanceiroPai.oid',
      filter: true,
      relacionamento: ['grupoFinanceiroPai'],
      fieldJoin: 'oid',
      visible: false,
    },
  ],
  lancamentoFinanceiro: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'grupoItemPrincipal_descricao',
      header: 'Grupo',
      columnKey: 'grupoItemPrincipal',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      relacionamento: ['grupoItemPrincipal'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'valorPago',
      header: 'Valor pago',
      columnKey: 'valorPago',
      sortable: true,
      filter: true,
      columnSortField: 'valorPago',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'oidContrato',
      header: 'Contrato',
      columnKey: 'contrato',
      sortable: true,
      filter: true,
      columnSortField: 'oidContrato',
      visible: true,
    },
    {
      field: 'contrato_numero',
      header: 'Núm. Contrato',
      columnKey: 'numeroContrato',
      sortable: true,
      filter: true,
      columnSortField: 'contrato',
      visible: true,
      relacionamento: ['contrato'],
      fieldJoin: 'numero',
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'emissao',
      header: 'Emissão',
      columnKey: 'emissao',
      sortable: true,
      filter: true,
      columnSortField: 'emissao',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'vencimento',
      header: 'Vencto',
      columnKey: 'vencimento',
      sortable: true,
      filter: true,
      columnSortField: 'vencimento',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'dataPagamento',
      header: 'Pagto',
      columnKey: 'dataPagamento',
      sortable: true,
      filter: true,
      columnSortField: 'dataPagamento',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'tipoFormaPagamento',
      header: 'Forma Pagto',
      columnKey: 'tipoFormaPagamento',
      sortable: true,
      filter: true,
      columnSortField: 'tipoFormaPagamento',
      visible: true,
    },
    {
      field: 'pagador_nome',
      header: 'Pagador',
      columnKey: 'pagador',
      sortable: true,
      filter: true,
      columnSortField: 'pagador.nome',
      relacionamento: ['pagador'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
      visible: true,
    },
    {
      field: 'grupoItemPrincipal_tipo',
      header: 'Tipo',
      columnKey: 'tipo',
      sortable: true,
      filter: true,
      columnSortField: 'tipo',
      relacionamento: ['grupoItemPrincipal'],
      fieldJoin: 'tipo',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'localizacao_identificacao',
      header: 'Localização',
      columnKey: 'localizacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      visible: true,
      relacionamento: ['localizacao'],
      fieldJoin: 'identificacao',
    },
    {
      field: 'boleto_oid',
      header: 'Boleto',
      columnKey: 'oidBoleto',
      sortable: true,
      filter: true,
      columnSortField: 'boleto.oid',
      visible: true,
    },
    {
      field: 'dataImportacao',
      header: 'Importação',
      columnKey: 'dataImportacao',
      fieldFormatter: 'formatarData',
      sortable: true,
      filter: true,
      columnSortField: 'dataImportacao',
      visible: false,
    },
    {
      field: 'contaCorrente_descricao',
      header: 'Conta corrente',
      columnKey: 'contaCorrente',
      sortable: true,
      filter: true,
      columnSortField: 'contaCorrente.descricao',
      relacionamento: ['contaCorrente'],
      fieldJoin: 'descricao',
      visible: true,
    },
  ],
  portalClienteFinanceiro: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'localizacao_identificacao',
      header: 'Localização',
      columnKey: 'localizacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      visible: true,
    },
    {
      field: 'emissao',
      header: 'Emissão',
      columnKey: 'emissao',
      sortable: true,
      filter: true,
      columnSortField: 'emissao',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'vencimento',
      header: 'Vencimento',
      columnKey: 'vencimento',
      sortable: true,
      filter: true,
      columnSortField: 'vencimento',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'dataPagamento',
      header: 'Pagamento',
      columnKey: 'dataPagamento',
      sortable: true,
      filter: true,
      columnSortField: 'dataPagamento',
      fieldFormatter: 'formatarData',
      visible: true,
    },
    {
      field: 'tipoFormaPagamento',
      header: 'Forma de Pag.',
      columnKey: 'tipoFormaPagamento',
      sortable: true,
      filter: true,
      columnSortField: 'tipoFormaPagamento',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
      fieldFormatter: 'getValue',
    },
    {
      field: 'boleto_oid',
      header: 'Boleto',
      columnKey: 'oidBoleto',
      sortable: true,
      filter: true,
      columnSortField: 'boleto.oid',
      visible: true,
    },
    {
      field: 'notas_oid',
      header: 'NFSe',
      columnKey: 'oidNotaFiscalServico',
      sortable: true,
      filter: true,
      columnSortField: 'notas.oid',
      visible: true,
    },
  ],
  botaoDinamico: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'textoBotao',
      header: 'Nome',
      columnKey: 'textoBotao',
      sortable: true,
      columnSortField: 'textoBotao',
      filter: true,
      visible: true,
    },
    {
      field: 'idTela',
      header: 'Tela',
      columnKey: 'idTela',
      sortable: true,
      columnSortField: 'idTela',
      filter: true,
      visible: true,
    },
  ],
  itenslancamentofinanceiro: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],
  // CONFIGURACAO //
  empresa: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'codigoEmpresa',
      header: 'Sigla',
      columnKey: 'codigoEmpresa',
      sortable: true,
      filter: true,
      columnSortField: 'codigoEmpresa',
      visible: true,
    },
    {
      field: 'nomeEmpresa',
      header: 'Nome',
      columnKey: 'nomeEmpresa',
      sortable: true,
      filter: true,
      columnSortField: 'nomeEmpresa',
      visible: true,
    },
    {
      field: 'nomePessoa',
      header: 'Pessoa',
      columnKey: 'pessoa',
      columnSortField: 'pessoa.nome',
      relacionamento: ['pessoa'],
      fieldJoin: 'nome',
      visible: true,
      sortable: true,
      filter: true,
    },
  ],
  usuario: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'login',
      header: 'Login',
      columnKey: 'login',
      sortable: true,
      columnSortField: 'login',
      filter: true,
      visible: true,
    },
    {
      field: 'email',
      header: 'E-mail',
      columnKey: 'email',
      sortable: true,
      columnSortField: 'email',
      filter: true,
      visible: true,
    },
    {
      field: 'pessoa',
      header: 'Pessoa',
      columnKey: 'pessoa',
      sortable: true,
      columnSortField: 'pessoa.nome',
      filter: true,
      relacionamento: ['pessoa'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'ativo',
      header: 'Ativo',
      columnKey: 'ativo',
      sortable: true,
      columnSortField: 'ativo',
      filter: true,
      visible: true,
    },
  ],
  perfil: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nomePerfil',
      header: 'Nome',
      columnKey: 'nomePerfil',
      sortable: true,
      columnSortField: 'nomePerfil',
      filter: true,
      visible: true,
    },
    {
      field: 'administrador',
      header: 'Administrador',
      columnKey: 'administrador',
      sortable: true,
      columnSortField: 'administrador',
      filter: true,
      visible: true,
    },
    {
      field: 'alteraUnidade',
      header: 'Altera unidade',
      columnKey: 'alteraUnidade',
      sortable: true,
      columnSortField: 'alteraUnidade',
      filter: true,
      visible: true,
    },
    {
      field: 'criaUsuariosWebService',
      header: 'Cria usuários webservice',
      columnKey: 'criaUsuariosWebService',
      sortable: true,
      columnSortField: 'criaUsuariosWebService',
      filter: true,
      visible: true,
    },
    {
      field: 'indicadorPersonalizado',
      header: 'Indicador personalizado',
      columnKey: 'indicadorPersonalizado',
      sortable: true,
      columnSortField: 'indicadorPersonalizado',
      filter: true,
      visible: true,
    },
  ],
  permissao: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'menu.value',
      header: 'Menu',
      columnKey: 'menu',
      sortable: true,
      columnSortField: 'menu.nome',
      filter: true,
      relacionamento: ['menu'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'perfil.value',
      header: 'Perfil',
      columnKey: 'perfil',
      sortable: true,
      columnSortField: 'perfil.nomePerfil',
      filter: true,
      relacionamento: ['perfil'],
      fieldJoin: 'nomePerfil',
      visible: true,
    },
    {
      field: 'listar',
      header: 'Listar',
      columnKey: 'listar',
      sortable: true,
      columnSortField: 'listar',
      filter: true,
      visible: true,
    },
    {
      field: 'inserir',
      header: 'Inserir',
      columnKey: 'inserir',
      sortable: true,
      columnSortField: 'inserir',
      filter: true,
      visible: true,
    },
    {
      field: 'alterar',
      header: 'Alterar',
      columnKey: 'alterar',
      sortable: true,
      columnSortField: 'alterar',
      filter: true,
      visible: true,
    },
    {
      field: 'excluir',
      header: 'Remover',
      columnKey: 'excluir',
      sortable: true,
      columnSortField: 'excluir',
      filter: true,
      visible: true,
    },
  ],
  unidade: [
    {
      field: 'oid',
      header: 'ID',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'pessoa.value',
      header: 'Pessoa',
      columnKey: 'pessoa',
      sortable: true,
      columnSortField: 'pessoa.nome',
      filter: true,
      relacionamento: ['pessoa'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'empresa.value',
      header: 'Empresa',
      columnKey: 'empresa',
      sortable: true,
      columnSortField: 'empresa.nomeEmpresa',
      filter: true,
      relacionamento: ['empresa'],
      fieldJoin: 'nomeEmpresa',
      visible: true,
    },
    {
      field: 'codigo',
      header: 'Código',
      columnKey: 'codigo',
      sortable: true,
      columnSortField: 'codigo',
      filter: true,
      visible: true,
    },
    {
      field: 'sigla',
      header: 'Sigla',
      columnKey: 'sigla',
      sortable: true,
      columnSortField: 'sigla',
      filter: true,
      visible: true,
    },
    {
      field: 'ativo',
      header: 'Ativo',
      columnKey: 'ativo',
      sortable: true,
      columnSortField: 'ativo',
      filter: true,
      visible: true,
    },
  ],
  menu: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
    {
      field: 'caminho',
      header: 'Caminho',
      columnKey: 'caminho',
      sortable: true,
      columnSortField: 'caminho',
      filter: true,
      visible: true,
    },
    {
      field: 'ordem',
      header: 'Ordem',
      columnKey: 'ordem',
      columnSortField: 'ordem',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'parent.value',
      header: 'Menu Superior',
      columnKey: 'parent',
      sortable: true,
      columnSortField: 'parent.nome',
      filter: true,
      relacionamento: ['parent'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'linkIcone',
      header: 'Ícone',
      columnKey: 'linkIcone',
      columnSortField: 'linkIcone',
      sortable: true,
      filter: true,
      visible: false,
    },
  ],
  acao: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
  ],
  parametrosempresa: [
    {
      field: 'id',
      header: 'Chave',
      columnKey: 'id',
      sortable: true,
      filter: true,
      columnSortField: 'id',
      visible: true,
    },
    {
      field: 'value',
      header: 'Valor',
      columnKey: 'value',
      sortable: true,
      columnSortField: 'value',
      filter: true,
      visible: true,
    },
  ],
  parametrosunidade: [
    {
      field: 'id',
      header: 'Chave',
      columnKey: 'id',
      sortable: true,
      filter: true,
      columnSortField: 'id',
      visible: true,
    },
    {
      field: 'value',
      header: 'Valor',
      columnKey: 'value',
      sortable: true,
      columnSortField: 'value',
      filter: true,
      visible: true,
    },
  ],
  relatorioDinamico: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
    {
      field: 'entidadeBase',
      header: 'Entidade base',
      columnKey: 'entidadeBase',
      sortable: true,
      columnSortField: 'entidadeBase',
      filter: true,
      visible: true,
    },
  ],
  funcaoDinamica: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nomeFuncao',
      header: 'Nome',
      columnKey: 'nomeFuncao',
      columnSortField: 'nomeFuncao',
      sortable: true,
      filter: true,
      visible: true,
    },
  ],
  configuracaoBoleto: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      columnSortField: 'nome',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'numeroConvenioCobranca',
      header: 'Convênio',
      columnKey: 'numeroConvenioCobranca',
      columnSortField: 'numeroConvenioCobranca',
      sortable: true,
      filter: true,
      visible: true,
    },
  ],
  controleFinanceiro: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'localizacao',
      header: 'Localização',
      columnKey: 'localizacao',
      columnSortField: 'localizacao',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'numeroContrato',
      header: 'Contrato',
      columnKey: 'numeroContrato',
      sortable: true,
      filter: true,
      columnSortField: 'numeroContrato',
      visible: true,
    },
    {
      field: 'oidContrato',
      columnKey: 'oidContrato',
      sortable: true,
      filter: true,
      columnSortField: 'oidContrato',
      visible: true,
    },
    {
      field: 'responsavel',
      header: 'Responsável',
      columnKey: 'responsavel',
      sortable: true,
      filter: true,
      columnSortField: 'responsavel',
      visible: true,
    },
    // {
    //   field: "oidLancamentoFinanceiro",
    //   header: "oidLancamentoFinanceiro",
    //   columnKey: "oidLancamentoFinanceiro",
    //   sortable: true,
    //   filter: true,
    //   columnSortField: "oidLancamentoFinanceiro",
    //   visible: true
    // },
    {
      field: 'oidUnidade',
      header: 'oidUnidade',
      columnKey: 'oidUnidade',
      sortable: true,
      filter: true,
      columnSortField: 'oidUnidade',
      visible: true,
    },
    {
      field: 'oidResponsavel',
      header: 'oidResponsavel',
      columnKey: 'oidResponsavel',
      sortable: true,
      filter: true,
      columnSortField: 'oidResponsavel',
      visible: true,
    },
    // {
    //   field: "taxa",
    //   header: "Taxa",
    //   columnKey: "taxa",
    //   sortable: true,
    //   filter: true,
    //   columnSortField: "taxa",
    //   visible: true
    // },
    // {
    //   field: "valor",
    //   header: "Valor",
    //   columnKey: "valor",
    //   sortable: true,
    //   filter: true,
    //   columnSortField: "valor",
    //   fieldFormatter: "formatarDinheiro",
    //   visible: true
    // },
    // {
    //   field: "oidBoleto",
    //   header: "Boleto",
    //   columnKey: "oidBoleto",
    //   sortable: true,
    //   filter: true,
    //   columnSortField: "oidBoleto",
    //   visible: true
    // },
    {
      field: 'conspaga',
      header: 'Conspaga',
      columnKey: 'conspaga',
      sortable: true,
      filter: true,
      columnSortField: 'conspaga',
      visible: true,
    },
  ],
  nfse: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'lancamentos_oid',
      header: 'Lançto',
      columnKey: 'lancamentos_oid',
      columnSortField: 'lancamentos.oid',
      sortable: true,
      filter: true,
      relacionamento: ['lancamentos'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'statusNFSe',
      header: 'Status',
      columnKey: 'statusNFSe',
      sortable: true,
      filter: true,
      columnSortField: 'statusNFSe',
      visible: true,
    },
    {
      field: 'numeroRPS',
      header: 'RPS',
      columnKey: 'numeroRPS',
      sortable: true,
      filter: true,
      columnSortField: 'numeroRPS',
      visible: true,
    },
    {
      field: 'numeroNFSe',
      header: 'NFSe',
      columnKey: 'numeroNFSe',
      sortable: true,
      filter: true,
      columnSortField: 'numeroNFSe',
      visible: true,
    },
    {
      field: 'dataHoraEmissaoRPS',
      header: 'Emissão',
      columnKey: 'dataHoraEmissaoRPS',
      sortable: true,
      filter: true,
      columnSortField: 'dataHoraEmissaoRPS',
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'cpfCnpjTomadorServico',
      header: 'Doc. Tomador',
      columnKey: 'cpfCnpjTomadorServico',
      sortable: true,
      filter: true,
      columnSortField: 'cpfCnpjTomadorServico',
      visible: true,
    },
    {
      field: 'razaoSocialTomadorServico',
      header: 'Tomador',
      columnKey: 'razaoSocialTomadorServico',
      sortable: true,
      filter: true,
      columnSortField: 'razaoSocialTomadorServico',
      visible: true,
    },
    {
      field: 'valorServico',
      header: 'Valor',
      columnKey: 'valorServico',
      sortable: true,
      filter: true,
      columnSortField: 'valorServico',
      fieldFormatter: 'formatarDinheiro',
      visible: true,
    },
    {
      field: 'cnae',
      header: 'CNAE',
      columnKey: 'cnae',
      sortable: true,
      filter: true,
      columnSortField: 'cnae',
      visible: true,
    },
    {
      field: 'descricaoServico',
      header: 'Serviço',
      columnKey: 'descricaoServico',
      sortable: true,
      filter: true,
      columnSortField: 'descricaoServico',
      visible: true,
    },
    {
      field: 'serieRPS',
      header: 'Série RPS',
      columnKey: 'serieRPS',
      sortable: true,
      filter: true,
      columnSortField: 'serieRPS',
      visible: false,
    },
    {
      field: 'tipoRPS',
      header: 'Tipo RPS',
      columnKey: 'tipoRPS',
      sortable: true,
      filter: true,
      columnSortField: 'tipoRPS',
      visible: false,
    },
    {
      field: 'situacaoRPS',
      header: 'Situação RPS',
      columnKey: 'situacaoRPS',
      sortable: true,
      filter: true,
      columnSortField: 'situacaoRPS',
      visible: false,
    },
    {
      field: 'chaveAcesso',
      header: 'Chave de Acesso',
      columnKey: 'chaveAcesso',
      sortable: true,
      filter: true,
      columnSortField: 'chaveAcesso',
      visible: false,
    },
  ],
  consultaBaixa: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'sequencial',
      header: 'Sequencial',
      columnKey: 'sequencial',
      sortable: true,
      filter: true,
      columnSortField: 'sequencial',
      visible: true,
    },
    {
      field: 'nome',
      header: 'Arquivo',
      columnKey: 'nome',
      sortable: true,
      filter: true,
      columnSortField: 'nome',
      visible: true,
    },
    {
      field: 'tipoRemessa',
      header: 'Tipo',
      columnKey: 'tipoRemessa',
      sortable: true,
      filter: true,
      columnSortField: 'tipoRemessa',
      visible: true,
    },
    {
      field: 'dataProcessamento',
      header: 'Data Processamento',
      columnKey: 'dataProcessamento',
      sortable: true,
      filter: true,
      columnSortField: 'dataProcessamento',
      visible: true,
      fieldFormatter: 'formatarDataHora',
    },
    {
      field: 'usuario_login',
      header: 'Usuário',
      columnKey: 'usuario',
      sortable: true,
      filter: true,
      columnSortField: 'usuario.login',
      relacionamento: ['usuario'],
      fieldJoin: 'login',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
      fieldFormatter: 'formatarDinheiro',
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
    },
  ],
  mensageriaLog: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'dataEnvio',
      header: 'Data',
      columnKey: 'dataEnvio',
      sortable: true,
      filter: true,
      columnSortField: 'dataEnvio',
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'assunto',
      header: 'Assunto',
      columnKey: 'assunto',
      sortable: true,
      filter: true,
      columnSortField: 'assunto',
      visible: true,
    },
    {
      field: 'mensagem',
      header: 'Mensagem',
      columnKey: 'mensagem',
      sortable: true,
      filter: true,
      columnSortField: 'mensagem',
      visible: true,
    },
    {
      field: 'usuario_login',
      header: 'Usuário',
      columnKey: 'usuario',
      sortable: true,
      filter: true,
      columnSortField: 'usuario.login',
      relacionamento: ['usuario'],
      fieldJoin: 'login',
      visible: true,
    },
    {
      field: 'tipoMensagem',
      header: 'Forma de Envio',
      columnKey: 'tipoMensagem',
      sortable: true,
      filter: true,
      columnSortField: 'tipoMensagem',
      visible: true,
    },
  ],
  consultaBaixaItem: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'oidContrato',
      header: 'Contrato',
      columnKey: 'oidContrato',
      sortable: true,
      filter: true,
      columnSortField: 'oidContrato',
      visible: true,
    },
    {
      field: 'oidLancamento',
      header: 'Lançamento',
      columnKey: 'oidLancamento',
      sortable: true,
      filter: true,
      columnSortField: 'oidLancamento',
      visible: true,
    },
    {
      field: 'pagador',
      header: 'Pagador',
      columnKey: 'pagador',
      sortable: true,
      filter: true,
      columnSortField: 'pagador',
      visible: true,
    },
    {
      field: 'mensagem',
      header: 'Mensagem',
      columnKey: 'mensagem',
      sortable: true,
      filter: true,
      columnSortField: 'mensagem',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
      fieldFormatter: 'formatarDinheiro',
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
    },
  ],
  consultaRemessa: [
    {
      field: 'multiselect',
      header: '',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificador',
      header: 'Identificador',
      columnKey: 'identificador',
      sortable: true,
      filter: true,
      columnSortField: 'identificador',
      visible: true,
    },
    {
      field: 'tipo',
      header: 'Tipo',
      columnKey: 'tipo',
      sortable: true,
      filter: true,
      columnSortField: 'tipo',
      visible: true,
    },
    {
      field: 'dataProcessamento',
      header: 'Data Processamento',
      columnKey: 'dataProcessamento',
      sortable: true,
      filter: true,
      columnSortField: 'dataProcessamento',
      visible: true,
      fieldFormatter: 'formatarDataHora',
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'usuarioGravacao',
      header: 'Usuário',
      columnKey: 'usuarioGravacao',
      sortable: true,
      filter: true,
      columnSortField: 'usuarioGravacao',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
    },
  ],
  documento: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
    {
      field: 'entidadeBase',
      header: 'Entidade Base',
      columnKey: 'entidadeBase',
      sortable: true,
      columnSortField: 'entidadeBase',
      filter: true,
      visible: true,
    },
    {
      field: 'nomeRelatorio',
      header: 'Modelo Base',
      columnKey: 'nomeRelatorio',
      sortable: true,
      columnSortField: 'nomeRelatorio',
      filter: true,
      visible: true,
    },
  ],

  agenda: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'dataFinal',
      header: 'Data Final',
      columnKey: 'dataFinal',
      sortable: true,
      filter: true,
      columnSortField: 'dataFinal',
      fieldFormatter: 'formatarDataHora',
      visible: true,
    },
    {
      field: 'dataInicial',
      header: 'Data Inicial',
      columnKey: 'dataInicial',
      sortable: true,
      filter: true,
      columnSortField: 'dataInicial',
      fieldFormatter: 'formatarDataHora',
      visible: true,
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
      visible: true,
    },
    {
      field: 'unidade_sigla',
      header: 'Unidade',
      columnKey: 'unidade',
      sortable: true,
      filter: true,
      columnSortField: 'unidade',
      visible: true,
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
    },
    {
      field: 'localizacao_identificacao',
      header: 'Capela',
      columnKey: 'Capela',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      visible: true,
      relacionamento: ['localizacao'],
      fieldJoin: 'identificacao',
    },
    {
      field: 'pessoa_nome',
      header: 'Pessoa',
      columnKey: 'pessoa',
      sortable: true,
      filter: true,
      columnSortField: 'pessoa.nome',
      relacionamento: ['pessoa'],
      fieldJoin: 'nome',
      visible: true,
    },
  ],
  produto: [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'codigo',
      header: 'Código',
      columnKey: 'codigo',
      sortable: true,
      columnSortField: 'codigo',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
    {
      field: 'tipoProduto',
      header: 'Tipo de Produto',
      columnKey: 'tipoProduto',
      sortable: true,
      columnSortField: 'tipoProduto',
      filter: true,
      visible: true,
    },
    {
      field: 'quantidadeMinima',
      header: 'Quantidade mínima',
      columnKey: 'quantidadeMinima',
      sortable: true,
      columnSortField: 'quantidadeMinima',
      filter: true,
      visible: true,
    },
    {
      field: 'saldo',
      header: 'Saldo',
      columnKey: 'saldo',
      sortable: true,
      columnSortField: 'saldo',
      filter: true,
      visible: true,
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
  ],

  emprestimo: [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'contrato_oid',
      columnKey: 'contrato.oid',
      sortable: true,
      header: 'Contrato',
      filter: true,
      columnSortField: 'contrato.oid',
      relacionamento: ['contrato'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'responsavel_oid',
      header: 'Responsável - Id',
      columnKey: 'responsavel.id',
      columnSortField: 'responsavel.oid',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'oid',
      visible: false,
    },
    {
      field: 'responsavel_nome',
      header: 'Responsável',
      columnKey: 'responsavel.nome',
      columnSortField: 'responsavel.nome',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'dataEmissao',
      header: 'Data de emissão',
      columnKey: 'dataEmissao',
      sortable: true,
      columnSortField: 'dataEmissao',
      filter: true,
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'dataSolicitacao',
      header: 'Data solicitação',
      columnKey: 'dataSolicitacao',
      sortable: true,
      columnSortField: 'dataSolicitacao',
      filter: true,
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'dataVencimento',
      header: 'Data de vencimento',
      columnKey: 'dataVencimento',
      sortable: true,
      columnSortField: 'dataVencimento',
      filter: true,
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'dataDevolucao',
      header: 'Data de devolução',
      columnKey: 'dataDevolucao',
      sortable: true,
      columnSortField: 'dataDevolucao',
      filter: true,
      visible: true,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'unidade_sigla',
      columnKey: 'unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'unidade.sigla',
      relacionamento: ['unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
    {
      field: 'produto_descricao',
      columnKey: 'produto.descricao',
      sortable: true,
      header: 'Equipamento',
      filter: true,
      columnSortField: 'produto_descricao',
      relacionamento: ['produto'],
      fieldJoin: 'descricao',
      visible: true,
    },
  ],

  endereco: [
    {
      field: 'oid',
      header: 'Oid',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
    {
      field: 'cep',
      header: 'Cep',
      columnKey: 'cep',
      sortable: true,
      columnSortField: 'cep',
      filter: true,
      visible: true,
    },
    {
      field: 'endereco',
      header: 'Endereço',
      columnKey: 'endereco',
      sortable: true,
      columnSortField: 'endereco',
      filter: true,
      visible: true,
    },
    {
      field: 'numero',
      header: 'Número',
      columnKey: 'numero',
      sortable: true,
      columnSortField: 'numero',
      filter: true,
      visible: true,
    },
    {
      field: 'complemento',
      header: 'Complemento',
      columnKey: 'complemento',
      sortable: true,
      columnSortField: 'complemento',
      filter: true,
      visible: false,
    },
    {
      field: 'bairro',
      header: 'Bairro',
      columnKey: 'bairro',
      sortable: true,
      columnSortField: 'bairro',
      filter: true,
      visible: false,
    },
    {
      field: 'cidade',
      header: 'Cidade',
      columnKey: 'cidade',
      sortable: true,
      columnSortField: 'cidade',
      filter: true,
      visible: false,
    },
    {
      field: 'uf',
      header: 'Uf',
      columnKey: 'uf',
      sortable: true,
      columnSortField: 'uf',
      filter: true,
      visible: false,
    },
  ],
  contacorrente: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
    {
      field: 'numero',
      header: 'Número',
      columnKey: 'numero',
      sortable: true,
      columnSortField: 'numero',
      filter: true,
      visible: true,
    },
    {
      field: 'agencia',
      header: 'Agência',
      columnKey: 'agencia',
      sortable: true,
      columnSortField: 'agencia',
      filter: true,
      visible: true,
    },
    {
      field: 'banco',
      header: 'Banco',
      columnKey: 'banco',
      sortable: true,
      columnSortField: 'banco',
      filter: true,
      visible: true,
    },
  ],
  mapa: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],
  mapaCoordenada: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'contrato_numero',
      columnKey: 'contrato.numero',
      sortable: true,
      header: 'Contrato - Número',
      filter: true,
      columnSortField: 'contrato.numero',
      relacionamento: ['contrato'],
      fieldJoin: 'numero',
      visible: true,
    },
    {
      field: 'mapa_descricao',
      columnKey: 'mapa.descricao',
      sortable: true,
      header: 'Mapa - Nome',
      filter: true,
      columnSortField: 'mapa.descricao',
      relacionamento: ['mapa'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'mapa_unidade_sigla',
      columnKey: 'mapa.unidade.sigla',
      sortable: true,
      header: 'Unidade',
      filter: true,
      columnSortField: 'mapa.unidade.sigla',
      relacionamento: ['mapa.unidade'],
      fieldJoin: 'sigla',
      visible: true,
    },
    {
      field: 'posicaoX',
      header: 'Posição X',
      columnKey: 'posicaoX',
      sortable: true,
      columnSortField: 'posicaoX',
      filter: true,
      visible: true,
    },
    {
      field: 'posicaoY',
      header: 'Posição Y',
      columnKey: 'posicaoY',
      sortable: true,
      columnSortField: 'posicaoY',
      filter: true,
      visible: true,
    },
    {
      field: 'mapa_oid',
      columnKey: 'mapa.oid',
      sortable: true,
      header: 'Mapa - Código',
      filter: true,
      columnSortField: 'mapa.oid',
      relacionamento: ['mapa'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'contrato_oid',
      columnKey: 'contrato.oid',
      sortable: true,
      header: 'Contrato - ID',
      filter: true,
      columnSortField: 'contrato.oid',
      relacionamento: ['contrato'],
      fieldJoin: 'oid',
      visible: true,
    },
  ],
  agendamentoMensageria: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'templateMensagem',
      header: 'Template Mensagem',
      columnKey: 'templateMensagem',
      sortable: true,
      filter: true,
      columnSortField: 'templateMensagem',
      visible: true,
    },
    {
      field: 'cron',
      header: 'CRON',
      columnKey: 'cron',
      sortable: true,
      filter: true,
      columnSortField: 'cron',
      visible: true,
    },
  ],
}

export function getDefaultColumnsFor(key) {
  return defaultColumns[key]
}

export function onPage(listPageContainer, e) {
  listPageContainer.props.paginate({
    max: e.rows,
    index: e.first,
  })
}

export function onSort(listPageContainer, e) {
  listPageContainer.props.sortOrder({
    sortOrder: e.sortOrder,
    sortField: e.sortField,
  })
}

export function getValuesForListBox(listPageContainer) {
  let columns = listPageContainer.props && listPageContainer.props.columns ? listPageContainer.props.columns : []
  if (columns.length === 0) {
    return columns
  }
  return listPageContainer.props.showHideColumns.filter((shCol) => {
    const result = columns.find((col) => {
      return col.columnKey === shCol.value.columnKey && col.visible
    })
    return !!result
  })
}

export function isRecordSelected(listPageContainer) {
  if (listPageContainer && listPageContainer.props.records && listPageContainer.props.records.length > 0) {
  }
}

export function bodyDivWithTooltip(rowData, column) {
  return <div title={rowData[column.field]}>{rowData[column.field]}</div>
}

export function uploadCsvImportacao(listPageContainer, arquivo) {
  const upload = _.get(listPageContainer, 'props.uploadCsvImportacao', null)
  if (upload) {
    upload(arquivo)
  }
}

export function getTemplateImportacao(listPageContainer) {
  const props = _.get(listPageContainer, 'props.getTemplateImportacao.props', null)
  const fn = _.get(props, 'getTemplateImportacao', null)
  if (fn) {
    fn()
  }
}

export default {
  onColumnReorder,
  onColumnToggle,
  changeFavorito,
  loadFavorito,
  componentDidMount,
  componentDidUpdate,
  onPage,
  onSort,
  getDefaultColumnsFor,
  getValuesForListBox,
  exportCSV,
  onCsvExport,
  isRecordSelected,
  onExcelExport,
  bodyDivWithTooltip,
  uploadCsvImportacao,
  getTemplateImportacao,
}
