import { types } from "./itenslancamento.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "itenslancamentofinanceiro",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        columnSortField: "oid",
        visible: true
      }
    },
    {
      label: "Descrição",
      value: {
        field: "descricao",
        header: "Descrição",
        columnKey: "descricao",
        sortable: true,
        filter: true,
        columnSortField: "descricao",
        visible: true
      }
    }
  ],
  columns: [],
  currentRecord: {
    oid: "",
    descricao: "",
    unidade: {},
    valor: ""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDItensLancamentoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("itenslancamentofinanceiro");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}
