import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { icons, routes } from '../../../constants'
import { Link } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Paginator } from 'primereact/paginator'
import utils from '../../../utils'

import './DashboardList.scss'
import { DataScroller } from 'primereact/datascroller'
import storage from '../../../services/stoage.service'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'

export const DashboardList = (props) => {
  let tasksSelecionadasOrnenado = props.tasksSelecionadas
  let tasksNaoSelecionadasOrnenado = props.tasksNaoSelecionadas
  const contItemAFazer = props.record.fazer ? props.record.fazer : 0
  const contItemFeita = props.record.concluida ? props.record.concluida : 0
  const contTotal = contItemAFazer + contItemFeita
  let allTasksOrdenadas = tasksNaoSelecionadasOrnenado.concat(tasksSelecionadasOrnenado)

  function resolveData(task) {
    if (task && task.data) {
      const formatted = utils.formatarDataHora(task.data)
      return (
        <div className="p-col-6 p-sm-4 p-md-4 p-lg-3 p-xl-2" style={{ textAlign: 'left' }}>
          {task.ativo ? <del>{formatted}</del> : formatted}
        </div>
      )
    }
    return null
  }

  function resolveTagDescricao(task) {
    if (task && task.descricao) {
      if (task.ativo) {
        return (
          <div className="p-col-12 p-sm-8 p-md-4 p-lg-5 p-xl-7" style={{ textAlign: 'left' }}>
            {resolveTag(task)}
            {task.oidContrato ? (
              <Link to={`${routes.PATH_CONTRATO}/${task.oidContrato}`}>{task.descricao}</Link>
            ) : (
              <del>{task.descricao}</del>
            )}
          </div>
        )
      }
      return (
        <div className="p-col-12 p-sm-8 p-md-4 p-lg-5 p-xl-7" style={{ textAlign: 'left' }}>
          {resolveTag(task)}
          <span>
            <font color={window.moment().diff(window.moment(task.data), 'days') >= 1 ? '#ff4d4d' : '#000000'}>
              {task.oidContrato ? (
                <Link to={`${routes.PATH_CONTRATO}/${task.oidContrato}`}>{task.descricao}</Link>
              ) : (
                task.descricao
              )}
            </font>
          </span>
        </div>
      )
    }
    return null
  }

  function resolveTag(task) {
    if (task && task.tag) {
      return (
        <div>
          <div style={{ textAlign: 'left' }}>
            <span className="tag">{task.ativo ? <del>{task.tag.value}</del> : task.tag.value}</span>
          </div>
          <br />
        </div>
      )
    }
    return <div />
  }

  function resolveBotoes(task) {
    return (
      <div className="p-col-6 p-sm-6 p-md-4 p-lg-4 p-xl-3">
        <Button
          tooltip={resolveToolTipLockUnlock(task)}
          type="p-button-icon"
          icon={task.ativo ? icons.LOCK_ICON : icons.UNLOCK_ICON}
          className="p-button-primary"
          onClick={(e) => props.onTaskChange(task)}
        />
        <Button
          tooltip="Remover atividade"
          type="p-button-icon"
          icon={icons.TRASH_ICON}
          className="p-button-danger"
          onClick={(e) => props.removeTask(task.oid)}
        />
      </div>
    )
  }

  function resolveToolTipLockUnlock(task) {
    if (task) {
      return task.ativo ? 'Reabrir atividade' : 'Fechar atividade'
    }
    return ''
  }

  function resolveHeader() {
    return (
      <div>
        {utils.getMessage(props.messages, 'dashboard.label.atividadesfazer') +
          ' ' +
          contItemAFazer +
          ' / ' +
          utils.getMessage(props.messages, 'dashboard.label.atividadesconcluidas') +
          ' ' +
          contItemFeita}
      </div>
    )
  }

  function resolveFooter() {
    return <Paginator rows={props.rows} totalRecords={contTotal} first={props.first} onPageChange={props.onPage} />
  }

  function resolveLinkFavorito() {
    if (utils.isArrayNullOrEmpty(props.record.favoritos)) {
      return null
    }

    return props.record.favoritos.map((favorito) => (
      <div className="p-col-12">
        <Link to={resolveLink(favorito)} onClick={() => storage.setOidCurrentMenu(favorito.oidMenu)}>
          {favorito.nomeMenu}
        </Link>
      </div>
    ))
  }

  function resolveLink(favorito) {
    return `${favorito.caminhoMenu}`
  }

  function atividadeRecenteTemplate(data) {
    if (data.hasOwnProperty('separador')) {
      return (
        <Card className="card-timeline-separator">
          <span>
            <label className="card-timeline-separator-label">{data.data}</label>
          </span>
        </Card>
      )
    }
    return (
      <Card>
        <div className="atividade-recente-text-align">
          <span>
            <b>{data.usuarioGravacao}</b>
          </span>
          <span> {data.descricao}</span>
          {resolveLinkAtividadesRecentes(data)}
          <div>
            <label className="atividade-recente-label-hora">{utils.formatarHora(data.data)}</label>
          </div>
        </div>
      </Card>
    )
  }

  function resolveLinkAtividadesRecentes(data) {
    if (data.operacaoLog === 'REMOVER_CONTRATO') {
      return null
    }

    let path = ''
    if (data.tipoLog === 1) {
      //PESSOA FISICA
      path = `${routes.PATH_PESSOA_FISICA}/${data.oidEntidadeRaiz}`
    }
    if (data.tipoLog === 2) {
      //PESSOA JURIDICA
      path = `${routes.PATH_PESSOA_JURIDICA}/${data.oidEntidadeRaiz}`
    }
    if (data.tipoLog === 3) {
      //LANCAMENTO
      path = `${routes.PATH_LANCAMENTO_FINANCEIRO}/${data.oidEntidadeRaiz}`
    }
    if (data.tipoLog === 4) {
      //CONTRATO
      path = `${routes.PATH_CONTRATO}/${data.oidEntidadeRaiz}`
    }
    if (data.tipoLog === 5) {
      //AGENDA
      path = `${routes.PATH_AGENDA}/${data.oidEntidadeRaiz}`
    }
    if (data.tipoLog === 6) {
      //ORCAMENTO
      path = `${routes.PATH_ORCAMENTO}/${data.oidEntidadeRaiz}`
    }
    return (
      <span>
        <Link to={path}> ({data.oidEntidadeRaiz})</Link>
      </span>
    )
  }

  function getMaisAcessados() {
    const maisAcessados = props.record.maisAcessados
    if (utils.isArrayNullOrEmpty(maisAcessados)) {
      return null
    }
    return maisAcessados.map((chaveValor) => (
      <div className="p-col-12">
        <Link to={chaveValor.id} onClick={() => storage.setOidCurrentMenu(chaveValor.value.id)}>
          {chaveValor.value.value}
        </Link>
      </div>
    ))
  }

  function getUltimosAcessados() {
    const ultimosAcessados = props.record.ultimosAcessados
    if (utils.isArrayNullOrEmpty(ultimosAcessados)) {
      return null
    }
    return ultimosAcessados.map((chaveValor) => (
      <div className="p-col-12">
        <Link to={chaveValor.id} onClick={() => storage.setOidCurrentMenu(chaveValor.value.id)}>
          {chaveValor.value.value}
        </Link>
      </div>
    ))
  }

  let novoItem = (
    <div className="card p-fluid">
      <div className="formgrid grid">
        <div className="field col">
          <span className="p-float-label">
            <InputText value={props.record.descricaoAtividade} onChange={props.onChange} name="descricaoAtividade" />
            <label>{utils.getMessage(props.messages, 'dashboard.label.atividade')}</label>
          </span>
        </div>
        <br />
        <div className="field col">
          <span className="p-float-label">
            <LtsLookupMarcador
              label={utils.getMessage(props.messages, 'dashboard.label.tag')}
              value={props.record.tagAtividade}
              history={props.history}
              editHidden={true}
              field={'codigo'}
              multiple={false}
              onChange={props.onChangeTag}
              showAddButton={true}
              stateMarcador={props.stateMarcador}
              tipo={'HISTORICO'}
            />
          </span>
        </div>
      </div>
    </div>
  )

  function getAtividadesTemplate() {
    const title = (
      <div>
        {utils.getMessage(props.messages, 'dashboard.label.atividades')}
        <Button
          style={{ marginBottom: '-5px' }}
          icon="pi pi-plus"
          className="mr-2 mb-2 secondary-btn"
          onClick={props.addTask}
        />
      </div>
    )
    return (
      <div className="p-col-12 p-md-12 p-lg-12 p-sm-12 p-xl-12">
        <Card title={title} subTitle={resolveHeader()} style={{ minHeight: '20em' }}>
          <div className="p-grid" style={{ marginTop: '15px' }}>
            <div className="p-col-12">
              {props.record.newTask ? novoItem : null}
              <Card footer={resolveFooter()}>
                {allTasksOrdenadas.map((task) => (
                  <Card>
                    <div className="p-grid">
                      {resolveData(task)}
                      {resolveTagDescricao(task)}
                      {resolveBotoes(task)}
                    </div>
                  </Card>
                ))}
              </Card>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  function getMaisAcessadosTemplate() {
    return (
      <div className="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4">
        <Card title={utils.getMessage(props.messages, 'dashboard.label.maisacessados')} style={{ minHeight: '18em' }}>
          <div className="p-grid">
            <ul className="task-list-container" style={{ textAlign: 'left' }}>
              {getMaisAcessados()}
            </ul>
          </div>
        </Card>
      </div>
    )
  }

  function getUltimosAcessadosTemplate() {
    return (
      <div className="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4">
        <Card
          title={utils.getMessage(props.messages, 'dashboard.label.ultimosacessados')}
          style={{ minHeight: '18em' }}
        >
          <div className="p-grid">
            <ul className="task-list-container" style={{ textAlign: 'left' }}>
              {getUltimosAcessados()}
            </ul>
          </div>
        </Card>
      </div>
    )
  }

  function getFavoritosTemplate() {
    return (
      <div className="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4">
        <Card title={utils.getMessage(props.messages, 'dashboard.label.favoritos')} style={{ minHeight: '18em' }}>
          <div className="p-grid">
            <ul className="task-list-container" style={{ textAlign: 'left' }}>
              {resolveLinkFavorito()}
            </ul>
          </div>
        </Card>
      </div>
    )
  }

  function getAtividadesRecentesTemplate() {
    if (props.ACAO_EXIBIR_TIMELINE) {
      return (
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
          <Card title="Atividades Recentes">
            <DataScroller
              className="remover-borda"
              value={props.atividadesRecentes}
              itemTemplate={atividadeRecenteTemplate}
              rows={20}
              lazy={true}
              onLazyLoad={props.onLoadAtividadesRecentes}
              scrollHeight="800px"
              inline={true}
            />
          </Card>
        </div>
      )
    }
  }

  return (
    <div className="p-grid">
      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
        <div className="p-grid">
          {getAtividadesTemplate()}
          {getMaisAcessadosTemplate()}
          {getUltimosAcessadosTemplate()}
          {getFavoritosTemplate()}
        </div>
      </div>
      {getAtividadesRecentesTemplate()}
    </div>
  )
}
