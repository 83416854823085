import { types } from "./controleFinanceiro.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";
import utils from "../../utils/index";

const initialState = {
  id: "controleFinanceiro",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Seleção",
      value: {
        field: "multiselect",
        header: "Seleção",
        columnKey: "multiselect",
        visible: true
      }
    },
    {
      label: "Localização",
      value: {
        field: "localizacao",
        header: "Localização",
        columnKey: "localizacao",
        columnSortField: "localizacao",
        sortable: true,
        filter: true,
        visible: true
      }
    },
    {
      label: "Contrato",
      value: {
        field: "numeroContrato",
        header: "Contrato",
        columnKey: "numeroContrato",
        sortable: true,
        filter: true,
        columnSortField: "numeroContrato",
        visible: true
      }
    },
    {
      label: "Responsável",
      value: {
        field: "responsavel",
        header: "Responsável",
        columnKey: "responsavel",
        sortable: true,
        filter: true,
        columnSortField: "responsavel",
        visible: true
      }
    },
    {
      label: "Código responsável",
      value: {
        field: "oidResponsavel",
        header: "Código responsável",
        columnKey: "oidResponsavel",
        sortable: true,
        filter: true,
        columnSortField: "oidResponsavel",
        visible: true
      }
    },
    {
      label: "Unidade",
      value: {
        field: "oidUnidade",
        header: "Unidade",
        columnKey: "oidUnidade",
        sortable: true,
        filter: true,
        columnSortField: "oidUnidade",
        visible: true
      }
    },
    {
      label: "Conspaga",
      value: {
        field: "conspaga",
        header: "Conspaga",
        columnKey: "conspaga",
        sortable: true,
        filter: true,
        columnSortField: "conspaga",
        visible: true
      }
    }
  ],
  columns: [
    {
      field: "multiselect",
      header: "",
      columnKey: "multiselect",
      visible: true
    },
    {
      field: "localizacao",
      header: "Localização",
      columnKey: "localizacao",
      columnSortField: "localizacao",
      sortable: true,
      filter: true,
      visible: true
    },
    {
      field: "oidUnidade",
      header: "oidUnidade",
      columnKey: "oidUnidade",
      sortable: true,
      filter: true,
      columnSortField: "oidUnidade",
      visible: true
    },
    {
      field: "numeroContrato",
      header: "Contrato",
      columnKey: "numeroContrato",
      sortable: true,
      filter: true,
      columnSortField: "numeroContrato",
      visible: true
    },
    {
      field: "responsavel",
      header: "Responsável",
      columnKey: "responsavel",
      sortable: true,
      filter: true,
      columnSortField: "responsavel",
      visible: true
    },
    {
      field: "oidResponsavel",
      header: "oidResponsavel",
      columnKey: "oidResponsavel",
      sortable: true,
      filter: true,
      columnSortField: "oidResponsavel",
      visible: true
    },
    {
      field: "conspaga",
      header: "Conspaga",
      columnKey: "conspaga",
      sortable: true,
      filter: true,
      columnSortField: "conspaga",
      visible: true
    }
  ],
  colunasTaxa: [
    {
      field: "id",
      header: "Código",
      columnKey: "id"
    },
    {
      field: "value",
      header: "Grupo",
      columnKey: "value"
    },
    {
      field: "valor",
      header: "Valor",
      columnKey: "valor"
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes"
    }
  ],
  currentRecord: {
    oid: "",
    configuracaoBoleto: "",
    conspagaPagarAte: "",
    vencimento: "",
    parcelas: 1,
    taxa: "",
    taxas: [],
    gerarBoletosIndividual: false,
    competencia: "",
    incluirContratosComParcelamento: false,
    tipoFormaPagamento: { id: 5, value: "Boleto" }
  },
  lookupConfiguracaoBoleto: {
    visible: false,
    modal: true,
    header: "Configuração Boleto"
  },
  lookupTaxa: {
    visible: false,
    modal: true,
    header: "Taxa"
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDControleFinanceiroReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("controlefinanceiro");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ADVANCED_FILTER:
      return setAdvancedFilter(state, action);
    case types.SET_LOOKUP_TAXA_VISIBLE:
      return setLookupTaxaVisible(state, action);
    case types.SET_LOOKUP_TIPO_SEPULTURA_VISIBLE:
      return setLookupTipoSepulturaVisible(state, action);
    case types.SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE:
      return setLookupConfiguracaoBoletoVisible(state, action);
    case types.SET_CONFIGURACAO_BOLETO:
      return setConfiguracaoBoleto(state, action);
    case types.SET_DATA_VENCIMENTO:
      return setDataVencimento(state, action);
    case types.SHOW_ERROS:
      return showErros(state, action);
    case types.UNMOUNT:
      return unmount(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return result;
  }
}

function unmount(state, action) {
  const currentRecord = initialState.currentRecord;
  const records = [];
  const count = 0;
  const selectedRecords = [];
  state = Object.assign({}, state, {currentRecord});
  state = Object.assign({}, state, {records});
  state = Object.assign({}, state, {count});
  state = Object.assign({}, state, {selectedRecords});
  return state;
}

function setAdvancedFilter(state, action) {
  const selectedFilters = state.selectedFilters;
  //pelo 'hash' pegamos o filtro alterado
  const arrFilter = selectedFilters.filter(e => e.hash === action.hash);
  if (utils.isArrayNotEmpty(arrFilter)) {
    let filter = arrFilter[0];
    const index = selectedFilters.indexOf(filter);
    // e atualizamos a propriedade alterada
    filter[action.field] = action.value;

    selectedFilters.splice(index, 1, filter); // remove o item antigo e adiciona o novo
  } else {
    const filter = { field: action.field, hash: action.hash };
    filter[action.field] = action.value;
    selectedFilters.push(filter);
  }
  return Object.assign({}, state, { selectedFilters: selectedFilters });
}

function setLookupTaxaVisible(state, action) {
  let lookupTaxa = state.lookupTaxa;
  lookupTaxa = Object.assign({}, lookupTaxa, { visible: action.visible });
  return Object.assign({}, state, { lookupTaxa });
}

function setLookupTipoSepulturaVisible(state, action) {
  let lookupTipoSepultura = state.lookupTipoSepultura;
  lookupTipoSepultura = Object.assign({}, lookupTipoSepultura, { visible: action.visible });
  return Object.assign({}, state, { lookupTipoSepultura });
}

function setLookupConfiguracaoBoletoVisible(state, action) {
  let lookupConfiguracaoBoleto = state.lookupConfiguracaoBoleto;
  lookupConfiguracaoBoleto = Object.assign({}, lookupConfiguracaoBoleto, { visible: action.visible });
  return Object.assign({}, state, { lookupConfiguracaoBoleto });
}

function setConfiguracaoBoleto(state, action) {
  let configuracaoBoleto = "";
  if (action.payload) {
    configuracaoBoleto = { id: action.payload.oid, value: action.payload.nome };
  }
  const currentRecord = state.currentRecord;
  currentRecord.configuracaoBoleto = configuracaoBoleto;

  return Object.assign({}, state, { currentRecord });
}

function setDataVencimento(state, action) {
  let vencimento = "";
  if (action.payload) {
    vencimento = action.payload;
  }
  const currentRecord = state.currentRecord;
  currentRecord.vencimento = vencimento;

  return Object.assign({}, state, { currentRecord });
}

function showErros(state, action) {
  const erros = action.payload;
  Object.assign(state, { records: erros });
  return Object.assign({}, state);
}

function reset(state, action) {
  return {...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: []}
}

