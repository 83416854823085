import React from "react";
import { DataTable } from "primereact/datatable";
import { enums } from "../../../constants";
import { Card } from "primereact/card";

export const ContratoList = props => {
  return (
    <div>
      <DataTable
        ref={props.dataTableRef}
        resizableColumns={true}
        columnResizeMode="expand"
        selection={props.selectedRecords}
        onSelectionChange={props.onSelectionChange}
        reorderableColumns={true}
        onColReorder={props.onColReorder}
        onSort={props.onSort}
        onPage={props.onPage}
        value={props.records}
        lazy={true}
        totalRecords={props.totalRecords}
        sortOrder={props.order}
        sortField={props.sortField}
        paginator={true}
        first={props.first}
        rows={props.rows}
        emptyMessage="Não foram encontrados registros"
        rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
        // V7 properties //
        responsiveLayout="scroll"
        filterDisplay="row"
        size={"small"}
        stripedRows={true}
      >
        {props.columns}
      </DataTable>
      <br />
        <Card style={{ margin: "5px" }}>
        <div style={{ textAlign: "right", margin: "7px" }}>
          <strong>Total Listagem: </strong> {props.totalRecords}
        </div>
        <div style={{ textAlign: "right", margin: "7px" }}>
          <strong>Total Contratos: </strong> {props.totalRecordsDistinct}
        </div>
      </Card>
    </div>
  );
};
