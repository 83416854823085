import React from 'react'
import _ from 'lodash'
import LtsMultiselectGrupoFinanceiro from '../../components/lottus/lts-multiselect-grupo-financeiro'

const getFnParam = ({ record, componentInfo }) => {
  // const campo = _.get(componentInfo, 'campo', null)
  // if (_.isEmpty(campo)) {
  //   return []
  // }
  const values = record['oid_unidade'] //TODO criar config de dependencia na config 'componentInfo'
  if (_.isEmpty(values)) {
    return []
  }
  return values.map((v) => v.id)
}

export const formBuildMultiselectGrupoFinanceiro = ({ componentInfo, messages, record, onChange, label }) => {
  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
        <LtsMultiselectGrupoFinanceiro
          name={_.get(componentInfo, 'campo', '_undef_')}
          label={label}
          value={record[_.get(componentInfo, 'campo', '_undef_')]}
          onChange={(e) => onChange(_.get(componentInfo, 'campo', null), _.get(e, 'target.value', null))}
          loadDataFnParam={getFnParam({ record, componentInfo })}
        />
      </div>
    </div>
  )
}
