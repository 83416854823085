import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Filtro } from '../../../utils/Filtro.js'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, icons } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../taxa.actions'
import { TaxaList } from './TaxaList'
import '../../../index.scss'
import _ from 'lodash'

const mapStateToProps = (state) => {
  let perfis = []
  if (state.crudPerfilState.records) {
    perfis = state.crudPerfilState.records.filter((perfil) => {
      let listIdPerfis = state.crudUsuarioState.currentRecord.perfis.map((perfilAcao) => perfilAcao.oid)
      return !listIdPerfis.includes(perfil.oid)
    })
  }

  return {
    perfis: perfis,
    showHideColumns: state.crudTaxaState.showHideColumns,
    messages: state.crudTaxaState.messages,
    columns: state.crudTaxaState.columns,
    records: state.crudTaxaState.records,
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    selectedFilters: state.crudTaxaState.selectedFilters,
    filterValues: state.crudTaxaState.filterValues,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/taxas/all`))
      }, 400)
    },
    pesquisaAvancada: (state, props) => {
      dispatch(actions.clearSelectedFilters())

      const valoresFiltros = props.filterValues
      const filtroTaxasVigentes = _.get(valoresFiltros, ['taxasVigentes', 'valor'], true)
      if (filtroTaxasVigentes) {
        const filtroMaiorIgual = new Filtro('vigenciaFinal', new Date(), 'MAIOR_IGUAL')
        const filtroNulo = new Filtro('vigenciaFinal', null, 'NULO')
        const filtros = []
        filtros.push(filtroMaiorIgual)
        filtros.push(filtroNulo)
        const filtroOu = {
          campo: 'filtro',
          valor: filtros,
          tipoFiltro: 'OU',
          chaveComponente: 'taxasVigentes',
        }
        dispatch(actions.setFiltroAvancado(filtroOu))
      } else {
        const filtroNaoNulo = new Filtro('vigenciaFinal', null, 'NAO_NULO')
        const filtroNulo = new Filtro('vigenciaFinal', null, 'NULO')
        const filtros = []
        filtros.push(filtroNaoNulo)
        filtros.push(filtroNulo)
        const filtroOu = {
          campo: 'filtro',
          valor: filtros,
          tipoFiltro: 'OU',
        }
        dispatch(actions.setFiltroAvancado(filtroOu))
      }
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/taxas/all`))
      }, 400)
    },
    loadTaxasValidas: () => dispatch(actions.loadTaxasValidas()),
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    salvarState: (state, filters) => {
      dispatch(actions.saveState(state, filters))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class TaxaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.visaoRef = React.createRef()
    this.showHideColumns = []
    this.showDialogVisao = false
    this.showFilter = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  salvarState = (dados, state) => {
    let arrayColumns = this.showHideColumns.map((col) => {
      return col.value
    })
    arrayColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })
    state['columns'] = arrayColumns
    state['menu'] = { id: 1, value: this.props.history.location.pathname }
    this.props.salvarState(state, dados.filters)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}/novo`)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
    // já realiza a filtragem por taxas validas
    this.props.pesquisaAvancada(this.state, this.props)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state, this.props)
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div className="p-col-12 p-justify-start">
          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
              <Checkbox
                inputId="chkTaxasVigentes"
                style={{ marginLeft: '5px' }}
                checked={_.get(this.props.filterValues, ['taxasVigentes', 'valor'], true)}
                onChange={(e) =>
                  _.set(
                    this.props.filterValues,
                    'taxasVigentes.valor',
                    !_.get(this.props.filterValues, ['taxasVigentes', 'valor'], true)
                  )
                }
              />
              <label htmlFor="chkTaxasVigentes" className="label">
                Somente taxas vigentes
              </label>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title={utils.getMessage(this.props.messages, 'taxa.label.taxas')} />
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  oidTemplate = (rowData) => {
    return <Link to={`${routes.PATH_TAXA_FINANCEIRO}/${rowData.oid}`}>{rowData.oid}</Link>
  }
  taxaContrapartidaTemplate = (rowData) => {
    return (
      <Link to={`${routes.PATH_TAXA_FINANCEIRO}/${rowData.taxaContrapartida_oid}`}>
        {rowData.taxaContrapartida_oid}
      </Link>
    )
  }

  unidadeTemplate = (rowData) => {
    if (rowData.unidade_sigla && rowData.unidade_sigla !== 'UND-01') {
      return <div>{rowData.unidade_sigla}</div>
    }
  }

  fieldCalendarFilter = (column) => {
    let dataSelecionada = ''
    let filtro = utils.findObjectInArray(this.props.selectedFilters, 'campo', column.field)
    if (filtro && filtro.valor) {
      dataSelecionada = filtro.valor
    }
    return (
      <Calendar
        value={dataSelecionada}
        yearNavigator={true}
        monthNavigator={true}
        yearRange={utils.getYearRangeFromDate('1900')}
        appendTo={document.body}
        showButtonBar={true}
        onChange={(e) => this.props.changeFilter(column, e)}
        name={column.field}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'vigenciaInicial' || col.field === 'vigenciaFinal') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.unidadeTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-unidade-body-width"
            filterHeaderClassName="column-unidade-filter-header-width"
            headerClassName="column-unidade-header-width"
          />
        )
      }
      if (col.field === 'taxaContrapartidaGrupo_descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-unidade-body-width"
            filterHeaderClassName="column-unidade-filter-header-width"
            headerClassName="column-unidade-header-width"
          />
        )
      }

      if (col.field === 'taxaContrapartida_oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.taxaContrapartidaTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'valor') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            bodyClassName="column-valor-body-width"
            filterHeaderClassName="column-valor-filter-header-width"
            headerClassName="column-valor-header-width"
          />
        )
      }

      if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }
  buildContent = () => {
    return (
      <TaxaList
        {...this.props}
        onSelectionChange={this.onSelectionChange}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxaListPageContainer)
