import React from "react";
import "./ConfigAilosForm.scss";
import {Dropdown} from "primereact/dropdown";

export const ConfigAilosForm = props => {
  const unidade = props.unidade;
  const onChangeUnit = props.onChangeUnit;
  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                <label
                    className="label">Unidade</label>
                <Dropdown
                    disabled={props.disableUnit}
                    autoWidth={false}
                    value={unidade}
                    options={props.unidades}
                    onChange={onChangeUnit}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name="unidade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
