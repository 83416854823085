import React from 'react';

import IsentosRelatorioFormPageContainer from './IsentosRelatorioFormPageContainer';

export class IsentosRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <IsentosRelatorioFormPageContainer {...this.props} />
        )
    }
}
