import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import {messageActions} from "../../store/message";

const BASE_PATH = '/lottustech/pessoasfisicas'
const crud = build(validate, BASE_PATH)
const prefix = 'crudPessoaFisica'

const newTypes = {
  SET_LOOKUP_RELIGIAO_VISIBLE: `${prefix}setLookupReligiaoVisible`,
  SET_LOOKUP_CATEGORIA_VISIBLE: `${prefix}setLookupCategoriaVisible`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.setLookupCategoriaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CATEGORIA_VISIBLE,
    visible,
  }
}

actions.setLookupReligiaoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_RELIGIAO_VISIBLE,
    visible,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.showWarningMessage = (message) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
  }
}

const makeMessages = (messages, type) => {
  let sticky = type === 'error' || type === 'warn'
  return messages.map((message) => {
    return {
      sticky,
      severity: type,
      detail: message,
    }
  })
}

actions.enviarWhatsApp = (telefone) => {
  return (dispatch, getState) => {
    const state = getState()[`crudPessoaFisicaState`]
    const oidPessoa = state.currentRecord.oid
    if (!telefone) {
      telefone = ' '
    }
    apiService.post(`${BASE_PATH}/enviarwhatsapp/${oidPessoa}/${telefone}/`).then((response) => {
      if (actions.carregarMensageria) {
        actions.carregarMensageria(response.data)
      }
    })
  }
}

actions.enviarEmail = (telefone) => {
  return (dispatch, getState) => {
    const state = getState()[`crudPessoaFisicaState`]
    const oidPessoa = state.currentRecord.oid
    apiService.post(`${BASE_PATH}/enviaremail/${oidPessoa}/`).then((response) => {
      if (actions.carregarMensageria) {
        actions.carregarMensageria(response.data)
      }
    })
  }
}

actions.loadByCpf = (cpf) => {
  return (dispatch, getState) => {
    if (cpf && cpf !== '00000000000') {
      return apiService.post(`${BASE_PATH}/loadbycpf/${cpf}`).then((response) => {
        const pessoa = response.data
        if (pessoa.numeroDocumento) {
          dispatch(actions.setRecord(pessoa))
          if (actions.afterLoad) {
            actions.afterLoad()
          }
        } else {
          dispatch(actions.showWarningMessage("Nenhuma pessoa localizada para o cpf informado"))
        }
      })
    }
  }
}

export { types, actions }
