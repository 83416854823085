import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../configuracaoBoleto.actions'
import { ConfiguracaoBoletoForm } from './ConfiguracaoBoletoForm'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudConfiguracaoBoletoState.messages
  const record = state.crudConfiguracaoBoletoState.currentRecord
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())

  return {
    record,
    messages,
    disableUnit,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),

    changeHandler: (event) => {
      const target = _.defaultTo(event.target, _.get(event, 'originalEvent.target', null))
      if (target) {
        const field = target.name
        let valor = target.value
        dispatch(actions.removeErrors([{ field }]))
        if (
          field === 'enviarAvisoVencimentoSmsAntesVencimento' ||
          field === 'enviarAvisoVencimentoSmsDiaVencimento' ||
          field === 'enviarAvisoVencimentoSmsAposVencimento' ||
          field === 'formaEmissao'
        ) {
          valor = target.checked
        } else if (
          field === 'valorMinimoPagamentoDivergente' ||
          field === 'valorDesconto' ||
          field === 'valorMulta' ||
          field === 'valorJurosMora'
        ) {
          valor = valor.replace('R$', '')
          if (typeof valor === 'string' && valor.length > 0) {
            valor = valor.trim()
          }
        }
        dispatch(actions.setField(field, valor))
      }
    },

    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => {
      dispatch(actions.loadById(oid))
    },
  }
}

class ConfiguracaoBoletoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.beforeFetchRecordSuccess = (data) => {
      if (data && data.valorMinimoPagamentoDivergente) {
        data.valorMinimoPagamentoDivergente = utils.formatCurrency(data.valorMinimoPagamentoDivergente)
      }
      if (data && data.valorDesconto) {
        data.valorDesconto = utils.formatCurrency(data.valorDesconto)
      }
      if (data && data.valorMulta) {
        data.valorMulta = utils.formatCurrency(data.valorMulta)
      }
      if (data && data.valorJurosMora) {
        data.valorJurosMora = utils.formatCurrency(data.valorJurosMora)
      }
      if (data && data.percentualDesconto) {
        data.percentualDesconto = utils.formatCurrency(data.percentualDesconto)
      }
      if (data && data.percentualMulta) {
        data.percentualMulta = utils.formatCurrency(data.percentualMulta)
      }
      if (data && data.percentualJurosMora) {
        data.percentualJurosMora = utils.formatCurrency(data.percentualJurosMora)
      }
      return data
    }

    actions.afterSave = (dados) => {
      this.props.history.replace(`${routes.PATH_CONFIGURACAO_BOLETO}/${dados.oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_CONFIGURACAO_BOLETO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CONFIGURACAO_BOLETO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CONFIGURACAO_BOLETO}`)
  }

  onBlurValor = (e) => {
    let field = e.target.name
    let valor = e.target.value

    if (typeof valor === 'number') {
      valor = String(valor)
    }
    this.props.setField(field, utils.formatCurrency(valor.replace(',', '.')))
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <ConfiguracaoBoletoForm
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        onComplete={this.props.onComplete}
        record={this.props.record}
        onBlurValor={this.onBlurValor}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracaoBoletoFormPageContainer)
