import React from 'react';
import RelatorioDinamicoListPageContainer from './RelatorioDinamicoListPageContainer';

export class RelatorioDinamicoListPage extends React.Component{
    
    render(){
      return (
        <RelatorioDinamicoListPageContainer {...this.props}/>
      )
    }
}

