import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import { messageActions } from "../../store/message/index";
import utils from "../../utils";
const BASE_PATH = "/lottustech/mensageria";
const crud = build(validate, BASE_PATH);
const prefix = "crudMensageria";

const newTypes = {
  SET_LOOKUP_DESTINATARIO_VISIBLE: `${prefix}setLookupDestinatarioVisible`,
  SET_DESTINATARIO: `${prefix}setDestinatario`,
  ADICIONAR_NOME_DESTINATARIO: `${prefix}adicionarNomeDestinatario`,
  ADD_ANEXO: `${prefix}addAnexo`,
  SHOW_ANEXO: `${prefix}showAnexo`,
  REMOVE_ANEXO: `${prefix}removeAnexo`,
  RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.addAnexo = payload => {
  return {
    type: types.ADD_ANEXO,
    payload
  };
};

actions.removeAnexo = payload => {
  return {
    type: types.REMOVE_ANEXO,
    payload
  };
};

actions.showAnexo = payload => {
  return {
    type: types.SHOW_ANEXO,
    payload
  };
};

actions.processaAnexo = data => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        //TODO criar metodo utils
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        const anexo = { filename: file.name, base64: encoded };
        dispatch(actions.addAnexo(anexo));
      };

      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    }
  };
};

actions.downloadAnexo = anexo => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then(response => {
        const arrayBuffer = base64ToArrayBuffer(response.data);
        createAndDownloadBlobFile(arrayBuffer, anexo.filename);
      });
    }
  };
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

actions.adicionarNomeDestinatario = (field, value) => {
  return {
    type: types.ADICIONAR_NOME_DESTINATARIO,
    field,
    value
  };
};

actions.setDestinatario = (field, value) => {
  return {
    type: types.SET_DESTINATARIO,
    field,
    value
  };
};

actions.carregarTiposSepulturas = unidade => {
  let params = {
    unidades: unidade
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/tipossepulturas/`;
    const state = getState()[`crudMensageriaState`];
    return apiService.post(path, params).then(response => {
      if (state.currentRecord.tiposSepulturas.length > 0) {
        let itensRemover = [];
        state.currentRecord.tiposSepulturas.forEach(tipo => {
          const obj = utils.findObjectInArray(response.data, "value", tipo.value);
          if (!obj) {
            itensRemover.push(tipo.value);
          }
        });
        itensRemover.forEach(itemRemover => {
          utils.removeObjectFromArray(state.currentRecord.tiposSepulturas, "value", itemRemover);
        });
      }
      dispatch(actions.setField("opcoesTiposSepulturas", response.data));
    });
  };
};

actions.carregarCategorias = () => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/categorias/`;
    return apiService.get(path).then(response => {
      dispatch(actions.setField("opcoesCategorias", response.data));
    });
  };
};

actions.filtrarResultados = filtros => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/pesquisar/`;
    return apiService.post(path, filtros).then(response => {
      if (response.data && response.data.length <= 0) {
        dispatch(messageActions.messageShowMessages(makeMessages(["A consulta não obteve resultados!"], "warn")));
      } else {
        dispatch(messageActions.messageShowMessages(makeMessages(["Consulta efetuada com sucesso!"], "success")));
      }
      if (response.data && response.data.length > 0) {
        let resultOrdenado = utils.orderArrayByProperty(response.data, "nome");
        dispatch(actions.setField("destinatarios", resultOrdenado));
      }
    });
  };
};

actions.enviarMensagem = params => {
  return (dispatch, getState) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const path = BASE_PATH + `/enviarMensagens/`;
    return apiService
      .post(path, params)
      .then(response => {
        dispatch(
          messageActions.messageShowMessages(makeMessages(["Solicitação de envio feita com sucesso!"], "success"))
        );
      })
      .catch(err => {
        if (err.response) {
          const messages = err.response.data;
          dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        }
      });
  };
};

actions.setLookupDestinatarioVisible = visible => {
  return {
    type: types.SET_LOOKUP_DESTINATARIO_VISIBLE,
    visible
  };
};

actions.loadLookupDestinatario = (params, filtroUnidade) => {
  return (dispatch, getState) => {
    if (params) {
      if (params.max) {
        dispatch(this.setIndex(params.index));
        dispatch(this.setMax(params.max));
      } else if (params.sortField && params.sortOrder) {
        dispatch(this.setSortOrder(params.sortOrder));
        dispatch(this.setSortField(params.sortField));
      }
    }
    const state = getState()[`${prefix}State`];
    const columns = state.columnsLookupDestinatario;
    if (columns && columns.length > 0) {
      let sortFields = [];
      if (state.sortField) {
        sortFields = [
          {
            fieldName: state.sortField,
            order: state.sortOrder > -1 ? `ASC` : `DESC`
          }
        ];
      }
      const projectionFieldsColumns = columns.map(col => col.columnKey);
      projectionFieldsColumns.push("oid");
      const params = {
        index: state.index,
        max: state.max,
        filters: [...state.selectedFilters],
        projectionFields: projectionFieldsColumns,
        sortFields
      };

      if (filtroUnidade) {
        params.filters.push(filtroUnidade);
      }
      const path = `/lottustech/pessoas/all`;
      return apiService.post(path, params).then(response => {
        dispatch(actions.setField("opcoesDestinatariosLookup", response.data.records));
      });
    }
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  }
  return messages.map(message => {
    return { sticky: sticky, summary: summary, severity: type, detail: message };
  });
};

export { types, actions };
