import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as menuActions, types as typeActions } from '../../menu/menu.actions'
import { actions } from '../menu.actions'
import { MenuForm } from './MenuForm'
import { Filtro } from '../../../utils/Filtro.js'
import utils from '../../../utils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudMenuState.messages
  const record = state.crudMenuState.currentRecord
  const lookupMenuSuperiorBase = {
    records: state.crudMenuState.records,
    columns: state.crudMenuState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome' || col.field === 'caminho')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudMenuState.index,
    rows: state.crudMenuState.max,
    totalRecords: state.crudMenuState.count,
    sortField: state.crudMenuState.sortField,
    order: state.crudMenuState.sortOrder,
    editDisabled: false,
  }

  let lookupMenuSuperior = Object.assign({}, state.crudMenuState.lookupMenuSuperior, lookupMenuSuperiorBase)

  return {
    record,
    lookupMenuSuperior,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lookupMenuSuperiorSetVisible: (visible) =>
      dispatch(actions.setLookupMenuVisible(visible, typeActions.SET_LOOKUP_MENU_SUPERIOR_VISIBLE)),
    lookupMenuSuperiorPaginate: (paginationParams) => dispatch(menuActions.paginate(paginationParams)),
    lookupMenuSuperiorSortOrder: (sortParams) => dispatch(menuActions.sortOrder(sortParams)),
    lookupMenuSuperiorApplyFilter: (filterParams) => {
      dispatch(menuActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(menuActions.applyFilter(filterParams))
    },
    lookupMenuSuperiorLoad: () => dispatch(menuActions.loadLookup()),
    setMenuSuperior: (oidMenuParent, nomeMenuParent) => {
      dispatch(actions.setField('parent.id', oidMenuParent))
      dispatch(actions.setField('parent.value', nomeMenuParent))
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      if (field === 'indicadorPersonalizado') {
        value = target.checked
      }
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    clearRecords: () => dispatch(menuActions.setRecords([])),
    loadDocumento: (oidDocumento) => dispatch(menuActions.loadDocumentoByOid(oidDocumento)),
  }
}

class MenuFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }
    actions.afterLoad = () => {
      this.loadDocumento()
    }
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_MENU}/${oid}`)
      this.loadDocumento()
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_MENU}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_MENU}`)
    }
  }

  loadDocumento() {
    const oidDocumento = _.get(this, 'props.record.oidDocumento', null)
    if (oidDocumento) {
      this.props.loadDocumento(oidDocumento)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.newHandler()
    this.props.history.push(`${routes.PATH_MENU}`)
  }

  lookupMenuSuperiorOnPage = (e) => {
    this.props.lookupMenuSuperiorPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupMenuSuperiorOnSort = (e) => {
    this.props.lookupMenuSuperiorSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }

  lookupMenuSuperiorOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupMenuSuperiorApplyFilter(filter)
    })
  }
  lookupMenuSuperiorOnClick = (e) => {
    this.props.lookupMenuSuperiorLoad()
    this.props.lookupMenuSuperiorSetVisible(true)
  }
  lookupMenuSuperiorOnHide = (e) => {
    this.props.lookupMenuSuperiorSetVisible(false)
  }
  lookupMenuSuperiorOnConfirm = (e) => {
    this.props.setMenuSuperior(e.selection.oid, e.selection.nome)
    this.props.lookupMenuSuperiorSetVisible(false)
  }
  lookupMenuSuperiorOnCancel = (e) => {
    this.props.setMenuSuperior(null, null)
    this.props.lookupMenuSuperiorSetVisible(false)
  }

  lookupMenuSuperiorOnEdit = () => {
    if (this.props.record.parent && this.props.record.parent.id) {
      this.props.loadById(this.props.record.parent.id)
      return `${routes.PATH_MENU}/${this.props.record.parent.id}`
    } else {
      return `${routes.PATH_MENU}`
    }
  }
  lookupMenuSuperiorOnComplete = (e) => {}

  lookupMenuSuperiorOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('parent.value', e.value)
      this.props.setField('parent.id', null)
      this.props.clearRecords()
    } else {
      this.props.setField('parent.value', e.value.nome)
      this.props.setField('parent.id', value.oid)
    }
  }
  lookupMenuSuperiorOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.parent) && utils.nonNull(this.props.record.parent.value)) {
      const filtro = new Filtro('nome', this.props.record.parent.value, 'CONTEM')
      this.props.lookupMenuSuperiorApplyFilter(filtro)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  setDocumento = (data) => {
    const oid = _.get(data, 'oid', null)
    const nome = _.get(data, 'nome', '')

    this.props.setField('oidDocumento', oid)
    this.props.setField('documento.id', oid)
    this.props.setField('documento.value', nome)
  }

  render() {
    const content = (
      <MenuForm
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        history={this.props.history}
        //-----
        lookupMenuSuperior={this.props.lookupMenuSuperior}
        lookupMenuSuperiorOnKeyPress={this.lookupMenuSuperiorOnKeyPress}
        lookupMenuSuperiorOnHide={this.lookupMenuSuperiorOnHide}
        lookupMenuSuperiorOnConfirm={this.lookupMenuSuperiorOnConfirm}
        lookupMenuSuperiorOnCancel={this.lookupMenuSuperiorOnCancel}
        lookupMenuSuperiorOnPage={this.lookupMenuSuperiorOnPage}
        lookupMenuSuperiorOnSort={this.lookupMenuSuperiorOnSort}
        lookupMenuSuperiorOnFilter={this.lookupMenuSuperiorOnFilter}
        lookupMenuSuperiorOnClick={this.lookupMenuSuperiorOnClick}
        lookupMenuSuperiorOnEdit={this.lookupMenuSuperiorOnEdit}
        lookupMenuSuperiorOnComplete={this.lookupMenuSuperiorOnComplete}
        lookupMenuSuperiorOnInputFilter={this.lookupMenuSuperiorOnInputFilter}
        setDocumento={this.setDocumento}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuFormPageContainer)
