import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { actions as grupoActions } from '../grupo/grupo.actions'
import utils from '../../utils'
const BASE_PATH = '/lottustech/gruposfinanceiros'
const crud = build(validate, BASE_PATH)
const prefix = 'crudGrupo'

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_LOOKUP_GRUPO_VISIBLE: `${prefix}setLookupGrupoVisible`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.setLookupGrupoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_GRUPO_VISIBLE,
    visible,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.aplicarFiltrosDadosGrupo = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudTaxaState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(grupoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }

    if (record.unidade && record.unidade.id) {
      const filtro = {
        campo: 'oid',
        valor: record.unidade.id,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['unidade'],
      }
      dispatch(grupoActions.setFiltroAvancado(filtro))
    }
    dispatch(grupoActions.loadLookup())
  }
}

export { types, actions }
