import React from "react";
import { InputText } from "primereact/inputtext";
import utils from "../../../utils/index";
import { LookupField } from "../../../components/lookup/LookupField";
import "./CategoriaForm.scss";

export const CategoriaForm = props => (
  <div className="p-grid p-fluid">
    <div className="p-col-12">
      <div className="card card-w-title">
        <h3>
          {utils.getMessage(props.messages, "categoria.label.categoria")} {props.record.oid || ""}
        </h3>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <span className="p-float-label">
              <InputText value={props.record.descricao} onChange={props.changeHandler} name="descricao" />
              <label>{utils.getMessage(props.messages, "categoria.label.descricao")}</label>
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 form-field-container">
            <LookupField
              label={utils.getMessage(props.messages, "categoria.label.categoriapai")}
              value={props.record.categoriaPai ? props.record.categoriaPai.value : ""}
              name="descricaoCategoria"
              field="descricao"
              history={props.history}
              onComplete={props.lookupCategoriaOnComplete}
              onInputFilter={props.lookupCategoriaOnInputFilter}
              editDisabled={props.lookupCategoria.editDisabled}
              onEditField={props.lookupCategoriaOnEdit}
              onClick={props.lookupCategoriaOnClick}
              visible={props.lookupCategoria.visible}
              modal={props.lookupCategoria.modal}
              header={props.lookupCategoria.header}
              onHide={props.lookupCategoriaOnHide}
              onConfirm={props.lookupCategoriaOnConfirm}
              onCancel={props.lookupCategoriaOnCancel}
              records={props.lookupCategoria.records}
              columns={props.lookupCategoria.columns}
              totalRecords={props.lookupCategoria.totalRecords}
              sortOrder={props.lookupCategoria.sortOrder}
              sortField={props.lookupCategoria.sortField}
              first={props.lookupCategoria.first}
              rows={props.lookupCategoria.rows}
              onPage={props.lookupCategoriaOnPage}
              onFilter={props.lookupCategoriaOnFilter}
              onSort={props.lookupCategoriaOnSort}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
