import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import utils from '../../../utils/index'
import './LivroCaixaForm.scss'

export const LivroCaixaForm = (props) => {
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler

  function resolveUsuariosBaixa() {
    if (props.filtraUsuarioBaixa) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
            <label className="label">{utils.getMessage(messages, 'relatorio.livro.caixa.label.usuario.baixa')}</label>
            <MultiSelect
              value={record.usuariosBaixa}
              options={utils.convertArrayIdValueToIdLabel(record.opcoesUsuarioBaixa)}
              name="usuariosBaixa"
              optionLabel="label"
              dataKey="id"
              selectedItemsLabel="{0} itens selecionados"
              onChange={props.changeHandler}
            />
          </div>
        </div>
      )
    }
    return null
  }

  function getContas() {
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
          <label className="label">{utils.getMessage(messages, 'relatorio.livro.caixa.label.contas')}</label>
          <MultiSelect
            value={record.contasCorrentes}
            options={utils.convertArrayIdValueToIdLabel(record.opcoesContasCorrentes)}
            name="contasCorrentes"
            optionLabel="label"
            dataKey="id"
            selectedItemsLabel="{0} itens selecionados"
            onChange={changeHandler}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <label className="label">{utils.getMessage(messages, 'relatorio.livro.caixa.label.unidade')}</label>
                <MultiSelect
                  value={record.unidade}
                  options={utils.convertArrayIdValueToIdLabel(props.unidades)}
                  name="unidade"
                  optionLabel="label"
                  dataKey="id"
                  selectedItemsLabel="{0} itens selecionados"
                  onChange={props.onChangeUnit}
                  disabled={props.disableUnit}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <label className="label">
                  {utils.getMessage(messages, 'relatorio.livro.caixa.label.forma.pagamento')}
                </label>
                <MultiSelect
                  value={record.tipoFormaPagamento}
                  options={utils.convertArrayIdValueToIdLabel(props.formasPagamento)}
                  name="tipoFormaPagamento"
                  optionLabel="label"
                  dataKey="id"
                  selectedItemsLabel="{0} itens selecionados"
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <label className="label">
                  {utils.getMessage(messages, 'relatorio.livro.caixa.label.tipo.sepultura')}
                </label>
                <MultiSelect
                  value={record.tiposSepulturas}
                  options={utils.convertArrayIdValueToIdLabel(record.opcoesTiposSepulturas)}
                  name="tiposSepulturas"
                  optionLabel="label"
                  dataKey="id"
                  selectedItemsLabel="{0} itens selecionados"
                  onChange={props.changeHandler}
                />
              </div>
            </div>
            {getContas()}
            {resolveUsuariosBaixa()}
            <div className="p-grid form-group">
              <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                <label className="label">
                  {utils.getMessage(messages, 'relatorio.livro.caixa.label.pagamento.de')}
                </label>
                <Calendar
                  yearNavigator={true}
                  yearRange={utils.getYearRangeFromDate('1970')}
                  monthNavigator={true}
                  value={record.dataInicial}
                  onChange={changeHandler}
                  name="dataInicial"
                />
              </div>
              <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                <label className="label">
                  {utils.getMessage(messages, 'relatorio.livro.caixa.label.pagamento.ate')}
                </label>
                <Calendar
                  yearNavigator={true}
                  yearRange={utils.getYearRangeFromDate('1970')}
                  monthNavigator={true}
                  value={record.dataFinal}
                  onChange={changeHandler}
                  name="dataFinal"
                />
              </div>
            </div>
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-2 p-xl-2">
              <Checkbox
                checked={record.isDetalhado}
                onChange={changeHandler}
                name="isDetalhado"
                style={{ marginLeft: '5px' }}
              />
              <label className="label">{utils.getMessage(messages, 'relatorio.livro.caixa.label.detalhado')}</label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-2 p-xl-2">
              <Checkbox
                checked={record.hasTotalizadores}
                onChange={changeHandler}
                name="hasTotalizadores"
                style={{ marginLeft: '5px' }}
              />
              <label className="label">{utils.getMessage(messages, 'relatorio.livro.caixa.label.totalizadores')}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
