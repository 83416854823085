import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onCsvExport,
  onPage,
  onSort,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../mensagens.actions'
import { AgendamentoMensageriaList } from './AgendamentoMensageriaList'
import './agemdamento-mensageria-list.scss'
import utils, { getMessage } from '../../../utils'
import { CRUDAgendamentoMensageriaReducer } from '../agendamento-mensageria.reducer'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudAgendamentoMensageriaState.showHideColumns,
    messages: state.crudAgendamentoMensageriaState.messages,
    columns: state.crudAgendamentoMensageriaState.columns,
    records: state.crudAgendamentoMensageriaState.records,
    first: state.crudAgendamentoMensageriaState.index,
    rows: state.crudAgendamentoMensageriaState.max,
    totalRecords: state.crudAgendamentoMensageriaState.count,
    sortField: state.crudAgendamentoMensageriaState.sortField,
    order: state.crudAgendamentoMensageriaState.sortOrder,
    selectedFilters: state.crudAgendamentoMensageriaState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      const campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/mensagens/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class AgendamentoMensageriaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_AGENDAMENTO_MENSAGERIA}/novo`)
  }

  componentDidMount() {
    componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={getMessage(this.props.messages, 'agendamentoMensageria.label.agendamentosMensageria')}
      />
    )
  }

  oid = (rowData) => {
    if (rowData) {
      return <Link to={`${routes.PATH_AGENDAMENTO_MENSAGERIA}/${rowData.oid}`}>{rowData.oid}</Link>
    }
    return null
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return (
          <Column
            selectionMode="multiple"
            bodyClassName="table-column-multiselect-body-width"
            filterHeaderClassName="table-column-multiselect-filter-header-width"
            headerClassName="table-column-multiselect-header-width"
          />
        )
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oid}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-oid-body-width"
            filterHeaderClassName="table-column-oid-filter-header-width"
            headerClassName="table-column-oid-header-width"
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <div>
        <AgendamentoMensageriaList
          {...this.props}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoMensageriaListPageContainer)
