import React, { useEffect } from 'react'
import { Card } from 'primereact/card'
import _ from 'lodash'
import './ContratoForm.scss'
import '../../../index.scss'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsInputNumber from '../../../components/lottus/lts-input-number'
import LtsLookupResponsavel from '../../../components/lottus/lts-lookup-responsavel'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import InformacoesCelesc from './informacoes-celesc'
import InformacoesCartao from './informacoes-cartao'
import PlanoCobertura from './plano-cobertura'
import utils from '../../../utils'
import { enums } from '../../../constants'
import InformacoesDebitoAutomatico from './informacoes-debito-automatico'
import InformacoesCergral from './informacoes-cergral'

export default function ContratoPlano(props) {
  useEffect(() => {
    resolveInformacoesPagamento()
  }, [props.plano])

  const setField = (field, value) => {
    const plano = _.get(props, 'plano', {})
    plano[field] = value
    props.setField('plano', plano)
  }

  const setPagador = (data) => {
    const value = {
      id: _.get(data, 'oid', ''),
      value: { id: _.get(data, 'numeroDocumento', ''), value: _.get(data, 'nome', '') },
    }
    setField('pagador', value)
  }

  const resolveInformacoesPagamento = () => {
    const formaPagamento = _.get(props, 'plano.formaPagamento.id', -1)
    if (formaPagamento === enums.ID_CELESC) {
      return <InformacoesCelesc plano={props.plano} setField={setField} />
    }
    if (formaPagamento === enums.ID_CARTAO_CREDITO_VINDI) {
      return <InformacoesCartao plano={props.plano} setField={setField} />
    }
    if (formaPagamento === enums.ID_DEBITO_AUTOMATICO) {
      return <InformacoesDebitoAutomatico plano={props.plano} setField={setField} />
    }
    if (formaPagamento === enums.ID_CERGRAL) {
      return <InformacoesCergral plano={props.plano} setField={setField} />
    }
    return null
  }

  const onChangeCoberturas = (data) => {
    let total = 0

    const coberturasAtivas = data.values.filter((d) => d.ativo)
    for (const value of coberturasAtivas) {
      const quantidade = _.get(value, 'quantidade', 0)
      const valorCobertura = _.get(value, 'valor', 0)
      total += utils.multiply(quantidade, valorCobertura, 2)

      const dataAdesao = _.get(value, 'dataAdesao', null)
      const diasCarencia = _.get(value, 'diasCarencia', 0)
      if (dataAdesao) {
        value.dataCarencia = window.moment(dataAdesao).add(diasCarencia, 'days').toDate()
      }
    }

    const coberturas = data.values
    _.set(props, 'plano.coberturas', coberturas)

    if (data.recordRemoved && data.recordRemoved.oid) {
      const coberturaRemovida = coberturas.find((c) => c.oid === data.recordRemoved.oid)
      if (coberturaRemovida) {
        coberturaRemovida.ativo = false
      }
    }

    setTaxaPrincipal(data)
    setField('coberturas', coberturas)
    setField('valor', total)
  }

  const setTaxaPrincipal = (data) => {
    const values = _.get(data, 'values', [])
    if (utils.isArrayNullOrEmpty(values)) {
      setField('oidItemPrincipal', null)
      setField('tipoItemPrincipal', null)
      return
    }

    const oidItemPrincipalAtual = _.get(props, 'plano.oidItemPrincipal', null)
    const recordRemoved = _.get(data, 'recordRemoved', null)
    const recordAdded = _.get(data, 'recordAdded', null)
    if (recordAdded && !oidItemPrincipalAtual) {
      setField('oidItemPrincipal', _.get(recordAdded, 'itemLancamento.oidTipo', null))
      setField('tipoItemPrincipal', _.get(recordAdded, 'itemLancamento.tipo', null))
      return
    }

    if (recordRemoved) {
      const oidItemRemoved = _.get(recordRemoved, 'itemLancamento.oidTipo', null)
      if (oidItemRemoved === oidItemPrincipalAtual) {
        setField('oidItemPrincipal', _.get(values[0], 'itemLancamento.oidTipo', null))
        setField('tipoItemPrincipal', _.get(values[0], 'itemLancamento.tipo', null))
      }
    }
  }

  return (
    <React.Fragment>
      <PlanoCobertura
        values={_.get(props, 'plano.coberturas', []).filter((d) => d.ativo)}
        onChange={onChangeCoberturas}
        idUnidade={props.idUnidade}
        diasPadraoCarenciaCoberturaPlano={props.diasPadraoCarenciaCoberturaPlano}
        podeCriarCoberturaPlano={props.podeCriarCoberturaPlano}
        podeAlterarCoberturaPlano={props.podeAlterarCoberturaPlano}
        podeRemoverCoberturaPlano={props.podeRemoverCoberturaPlano}
        messages={props.messages}
      />
      <br />
      <Card title={utils.getMessage(props.messages, 'contrato.label.plano.informacoes.pagamento')}>
        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              value={_.get(props, 'plano.valor', 0)}
              name={'valor'}
              label={utils.getMessage(props.messages, 'contrato.label.plano.valor')}
              onChange={(e) => setField('valor', e)}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
            <LtsInputNumber
              value={_.get(props, 'plano.diaVencimento', 1)}
              label={utils.getMessage(props.messages, 'contrato.label.plano.diaVencimento')}
              onChange={(e) => setField('diaVencimento', e)}
              min={1}
              max={31}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupResponsavel
              name={'pagador'}
              field={'nome'}
              label={utils.getMessage(props.messages, 'contrato.label.plano.pagador')}
              value={_.get(props, 'plano.pagador.value', '')}
              oidRecord={_.get(props, 'plano.pagador.id', '')}
              history={props.history}
              onChange={setPagador}
              statePessoaFisica={props.statePessoaFisica}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
            <LtsDropdownIdValue
              name={'formaPagamento'}
              value={_.get(props, 'plano.formaPagamento', '')}
              onChange={(e) => setField('formaPagamento', e)}
              loadDataFn={apiService.getFormasPagamentoPlano}
              label={utils.getMessage(props.messages, 'contrato.label.plano.formaPagamento')}
              style={{width: '200px'}}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
            <LtsDropdownIdValue
              name={'formaPagamento'}
              value={_.get(props, 'plano.frequenciaFaturamento', '')}
              onChange={(e) => setField('frequenciaFaturamento', e)}
              loadDataFn={apiService.getFrequenciasFaturamento}
              label={utils.getMessage(props.messages, 'contrato.label.plano.frequenciaFaturamento')}
              style={{width: '200px'}}
            />
          </div>
        </div>
        {resolveInformacoesPagamento()}
      </Card>
    </React.Fragment>
  )
}
