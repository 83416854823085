import utils from '../../../utils'
import storage from '../../../services/stoage.service'

export function validate(record) {
  const LIMITE_MAXIMO_RESPONSAVEIS = 3
  const errors = []

  const isTipoSepulturaColumbario = utils.isTipoSepulturaColumbario(
    storage.getUnits(),
    record.unidade.id,
    record.tipoSepultura.id
  )

  if (!isTipoSepulturaColumbario) {
    if (record.falecidos.length > 0) {
      record.falecidos.forEach((falecido) => {
        if (falecido.dataFalecimento) {
          if (window.moment().isBefore(window.moment(falecido.dataFalecimento, 'x'))) {
            errors.push({
              field: 'dataFalecimento',
              message: 'O campo data de falecimento não pode ser maior que a data atual!',
            })
          } else if (
            falecido.dataNascimento &&
            window.moment(falecido.dataNascimento).isAfter(window.moment(falecido.dataFalecimento, 'x'))
          ) {
            errors.push({
              field: 'dataFalecimento',
              message: 'A data de falecimento não pode ser anterior a data de nascimento!',
            })
          }
          if (falecido.dataExumacao) {
            if (window.moment(falecido.dataFalecimento, 'x').isAfter(window.moment(falecido.dataExumacao, 'x'))) {
              errors.push({
                field: 'dataExumacao',
                message: 'A data de exumação não pode ser anterior a data de falecimento!',
              })
            }
          }
        }
        if (falecido.peso) {
          falecido.peso = utils.parseStringToNumber(falecido.peso)
        }
        if (falecido.altura) {
          falecido.altura = utils.parseStringToNumber(falecido.altura)
        }

        if (falecido.tanatopraxia && falecido.tanatopraxia.necropsiado && falecido.tanatopraxia.necropsiado.value) {
          if (!falecido.tanatopraxia.dataInicialNecropsia) {
            errors.push({
              field: 'dataInicialNecropsia',
              message: 'A data inicial da necropsia precisa ser informada para o falecido "' + falecido.falecido + '"!',
            })
          }
          if (!falecido.tanatopraxia.dataFinalNecropsia) {
            errors.push({
              field: 'dataFinalNecropsia',
              message: 'A data final da necropsia precisa ser informadapara o falecido "' + falecido.falecido + '"!',
            })
          }
        }
      })
    }


    if (record.responsaveis.length <= 0) {
      errors.push({ field: 'responsaveis', message: 'É obrigatório informar ao menos um responsável!' })
    } else if (record.responsaveis.length > LIMITE_MAXIMO_RESPONSAVEIS) {
      errors.push({ field: 'responsaveis', message: 'É permitido somente três responsáveis!' })
    } else {
      if (record.falecidos.length > 0) {
        let falecido = null
        record.responsaveis.forEach((responsavel) => {
          falecido = utils.findObjectInArray(record.falecidos, 'oidFalecido', responsavel.oidResponsavel)
          if (falecido) {
            errors.push({
              field: 'responsaveis',
              message: 'Há um responsável que também está na listagem de falecidos. Por favor verifique.',
            })
          }
        })
      }
    }

    if (record.dependentes.length > 0) {
      record.dependentes.forEach((dependente) => {
        if (!dependente.parentesco) {
          errors.push({ field: 'parentesco', message: 'O campo parentesco é obrigatório para o dependente!' })
        }
      })
    }
  }

  if (record.historicos && record.historicos.length > 0) {
    record.historicos.forEach((historico) => {
      delete historico.hash
    })
  }

  delete record.lancamentosFinanceiros

  utils.blankToNull(record)

  const result = {
    ok: errors.length === 0,
    errors,
    record,
  }

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}
