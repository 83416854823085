import React from "react";
import {LookupField} from "../../../components/lookup/LookupField";
import {Accordion, AccordionTab} from "primereact/accordion";
import {getTemplateDropDownPessoa} from "../../../utils/Template";
import {Button} from "primereact/button";
import {Editor} from "primereact/editor";
import {Card} from "primereact/card";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {enums, icons} from "../../../constants";
import utils from "../../../utils";
import "./MensageriaForm.scss";
import {InputTextarea} from "primereact/inputtextarea";

function getContent(props) {
    const anexos = props.anexos;
    if (utils.isArrayNullOrEmpty(anexos)) {
        return null;
    }
    return anexos.map(anexo => (
        <div className="p-grid">
            <div className="p-col-12 p-sm-11 p-md-11 p-lg-11 p-xl-11">
                <Button
                    type="p-button"
                    label={anexo.filename}
                    onClick={e => props.downloadAnexo(anexo)}
                    icon={icons.DOWNLOAD}
                    className="p-button-secondary"
                    disabled={isNaN(anexo.oid)}
                />
            </div>
            <div className="p-col-12 p-sm-1 p-md-1 p-lg-1 p-xl-1">
                <Button
                    type="p-button-icon"
                    icon={icons.REMOVE_ICON}
                    onClick={e => props.removeAnexo(anexo)}
                    className="p-button-danger"
                />
            </div>
        </div>
    ));
}

function getAnexoWrapper(props) {
    return (
        <div className="card card-w-title">
            <div>
                <h3>{utils.getMessage(props.messages, "mensageria.label.anexos")}</h3>
                <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo">
                    {utils.getMessage(props.messages, "mensageria.label.adicionar")}
                </label>
                <input id="selecao-arquivo" type="file" onChange={props.onSelectAnexo}/>
            </div>
            <br/>
            {getContent(props)}
        </div>
    );
}

function getAssuntoEmail(record, changeHandler, messages) {
    if (record.tipoMensagem === 1) {
        return (
            <div className="p-grid form-group">
                <div className="p-col-3">
                    <label className="label">{utils.getMessage(messages, "mensageria.label.assunto")}</label>
                    <InputText value={record.assunto} onChange={changeHandler} name="assunto"/>
                </div>
            </div>
        );
    }
}

export const MensageriaForm = props => {
    const record = props.record;
    const messages = props.messages;
    return (
        <div>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <Accordion>
                            <AccordionTab header={utils.getMessage(messages, "mensageria.label.destinatarios")}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <div className="card card-w-title">
                                            <div className="p-grid form-group">
                                                <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8">
                                                    <label
                                                        className="label">{utils.getMessage(messages, "mensageria.label.destinatarios.separados.por.ponto.e.virgula")}</label>
                                                    <InputTextarea
                                                        value={record.destinatariosString}
                                                        onChange={props.changeHandler}
                                                        name="destinatariosString"
                                                        rows={1}
                                                        cols={30}
                                                        autoResize={true}
                                                        style={{marginBottom: '10px;'}}
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                            <br/>

                                            <div className="p-grid form-group">
                                                <Button className="p-button-success"
                                                        icon="pi pi-plus"
                                                        onClick={props.addNovoDestinatario}
                                                        style={{height: "35px", marginTop: "25px"}}
                                                />

                                                <div className="p-col-12 p-sm-11 p-md-8 p-lg-6 p-xl-5">
                                                    <label className="label">
                                                        {utils.getMessage(messages, "mensageria.label.destinatarios")}
                                                    </label>
                                                    <LookupField
                                                        value={props.destinatario}
                                                        name="destinatario"
                                                        field="destinatario"
                                                        history={props.history}
                                                        itemTemplate={getTemplateDropDownPessoa}
                                                        onKeyPress={props.lookupDestinatarioOnKeyPress}
                                                        onComplete={props.lookupDestinatarioOnComplete}
                                                        onInputFilter={props.lookupDestinatarioOnInputFilter}
                                                        editDisabled={props.lookupDestinatario.editDisabled}
                                                        onEditField={props.lookupDestinatarioOnEdit}
                                                        onClick={props.lookupDestinatarioOnClick}
                                                        visible={props.lookupDestinatario.visible}
                                                        modal={props.lookupDestinatario.modal}
                                                        header={props.lookupDestinatario.header}
                                                        onHide={props.lookupDestinatarioOnHide}
                                                        onConfirm={props.lookupDestinatarioOnConfirm}
                                                        onCancel={props.lookupDestinatarioOnCancel}
                                                        records={props.lookupDestinatario.records}
                                                        columns={props.lookupDestinatario.columns}
                                                        totalRecords={props.lookupDestinatario.totalRecords}
                                                        sortOrder={props.lookupDestinatario.order}
                                                        sortField={props.lookupDestinatario.sortField}
                                                        first={props.lookupDestinatario.first}
                                                        rows={props.lookupDestinatario.rows}
                                                        onPage={props.lookupDestinatarioOnPage}
                                                        onFilter={props.lookupDestinatarioOnFilter}
                                                        onSort={props.lookupDestinatarioOnSort}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-grid form-group">
                                                <div className="p-col-12">
                                                    <DataTable
                                                        autoLayout={true}
                                                        ref={props.dataTablePessoas}
                                                        editable={true}
                                                        value={record.destinatarios}
                                                        paginator={true}
                                                        emptyMessage="Não foram encontrados registros"
                                                        rows={enums.ROWS}
                                                        rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                                                    >
                                                        {props.colunasTPessoa}
                                                    </DataTable>
                                                </div>
                                            </div>
                                            <div className="p-grid form-group p-justify-end">
                                                <div>
                                                    <Card>
                                                        <strong>{utils.getMessage(messages, "mensageria.label.totalDestinatarios")}</strong>
                                                        {record.destinatarios.length}
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <div>{getAssuntoEmail(record, props.changeHandler, messages)}</div>
                                    <br/>
                                    <div>
                                        <Editor
                                            style={{height: "320px"}}
                                            name="mensagem"
                                            value={record.mensagem}
                                            onTextChange={props.changeHandlerEditor}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">{getAnexoWrapper(props)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
