const validateState = require("./../../../utils").validateState;
const blankToNull = require("./../../../utils").blankToNull;

export function validate(record) {
  const errors = [];
  let result = {};
  // se tem um menu informado é uma visao (state)
  if (record.menu) {
    result = validateState(record);
  } else {
    if (!record.valor) {
      errors.push({ field: "valor", message: "O campo valor é obrigatório" });
    } else if (typeof record.valor === "string") {
      record.valor = record.valor
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }

    if (!record.descricao) {
      errors.push({ field: "descricao", message: "O campo descrição é obrigatório" });
    }

    blankToNull(record);

    result = {
      ok: errors.length === 0,
      errors,
      record
    };
  }

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
