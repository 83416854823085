export const FONTE_DE_DADOS = {
  EXCEL: 1,
  SQL: 2,
}

export const TIPO_MENSAGEM = {
  SMS: 0,
  WHATSAPP: 1,
  EMAIL: 2,
}

export const ID_COLUNA_ANEXO = {
  ANEXO_BOLETO: 'anexo_boleto',
  ANEXO_CARNE: 'anexo_carne',
}
