const blankToNull = require("./../../../utils").blankToNull;
const isValorValidoMaiorQueZero = require("./../../../utils").isValorValidoMaiorQueZero;
const isIdValueIdValido = require("./../../../utils").isIdValueIdValido;
const parseCurrency = require("./../../../utils").parseCurrency;

export function validate(record) {
  const errors = [];

  if (isValorValidoMaiorQueZero(record.valor)) {
    record.valor = parseCurrency(record.valor);
  } else {
    record.valor = null;
  }

  if (!isIdValueIdValido(record.grupoFinanceiro)) {
    errors.push({ field: "grupoFinanceiro", message: "O campo Grupo Financeiro é obrigatório" });
  }

  if (!record.vigenciaInicial) {
    errors.push({ field: "vigenciaInicial", message: "O campo de vigência inicial é obrigatório" });
  }
  if (record.vigenciaFinal) {
    if (window.moment(record.vigenciaInicial, "x").isAfter(window.moment(record.vigenciaFinal, "x"), "day")) {
      errors.push({ field: "vigenciaFinal", message: "A vigência final não pode ser anterior a data inicial!" });
    }
  }



  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
