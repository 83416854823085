export function validate(record) {
  const result = {
    ok: true,
    errors: [],
    record
  };

  return new Promise((resolve, reject) => {
    return resolve(result);
  });
}
