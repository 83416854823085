import React from "react";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import logo_phoenix from "../../assets/images/logo/phoenix-logo-design-11-510x510.png";

const RedefinirSenhaForm = props => (
  <div className="login-panel p-fluid">
    <div className="login-panel-header">
      <img src={logo_phoenix} alt="phoenix" />
    </div>
    <div className="login-panel-content">
      <div className="p-grid">
        <div className="p-col-12">
          <h1>Redefinir senha</h1>
        </div>
        <div className="p-col-12">
          <span className="md-inputfield">
            <label>Nova Senha</label>
            <InputText value={props.state.password} name="password" type="password" onChange={props.changeValue} />
          </span>
        </div>
        <div className="p-col-12">
          <span className="md-inputfield">
            <label>Confirmar senha</label>
            <InputText
              value={props.state.passwordConfirm}
              name="passwordConfirm"
              type="password"
              onChange={props.changeValue}
              onKeyDown={props.onKeyDown}
            />
          </span>
        </div>
        <div className="p-col-12">
          <Button label="Alterar" icon={"pi pi-create"} onClick={props.redefinirSenha} />
        </div>
        <div className="p-col-12">
          <Link to={`/login`}>
            Voltar para tela de login
          </Link>
        </div>             
      </div>
    </div>
  </div>
);

export default RedefinirSenhaForm;
