import { types } from './endereco.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'endereco',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Id',
      value: {
        field: 'oid',
        header: 'Id',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Descrição',
      value: {
        field: 'descricao',
        header: 'Descrição',
        columnKey: 'descricao',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Cep',
      value: {
        field: 'cep',
        header: 'Cep',
        columnKey: 'cep',
        sortable: true,
        columnSortField: 'cep',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Endereço',
      value: {
        field: 'endereco',
        header: 'Endereço',
        columnKey: 'endereco',
        sortable: true,
        columnSortField: 'endereco',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Número',
      value: {
        field: 'numero',
        header: 'Número',
        columnKey: 'numero',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Complemento',
      value: {
        field: 'complemento',
        header: 'Complemento',
        columnKey: 'complemento',
        sortable: true,
        columnSortField: 'complemento',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Bairro',
      value: {
        field: 'bairro',
        header: 'Bairro',
        columnKey: 'bairro',
        sortable: true,
        columnSortField: 'bairro',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Cidade',
      value: {
        field: 'cidade',
        columnKey: 'cidade',
        sortable: true,
        header: 'Cidade',
        filter: true,
        columnSortField: 'cidade',
        visible: false,
      },
    },
    {
      label: 'Uf',
      value: {
        field: 'uf',
        columnKey: 'uf',
        sortable: true,
        header: 'Uf',
        filter: true,
        columnSortField: 'uf',
        visible: false,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Oid',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],

  columns: [],
  currentRecord: {
    oid: '',
    descricao: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
  },
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDEnderecoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('endereco')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    case types.MAKE_NEW:
      return initialize(state)
    default:
      return state
  }
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
  }
}

const initialize = (state) => {
  const currentRecord = state.currentRecord
  return Object.assign({}, state, { currentRecord: currentRecord })
}
