import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { routes } from '../../../constants'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { actions } from '../tipossepulturasrelatorio.actions'
import { Dropdown } from 'primereact/dropdown'
import { TiposSepulturasRelatorioForm } from './TiposSepulturasRelatorioForm'
import { Button } from 'primereact/button'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudTiposSepulturasRelatorioState.messages
  const record = state.crudTiposSepulturasRelatorioState.currentRecord

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidades: [unidade] })
    disableUnit = true
  }

  const currentCompany = storage.getCompany()
  let unidades = []
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(currentCompany)
  unidades = unidades.concat(unidadesEmpresa)
  return {
    currentCompany,
    record,
    messages,
    selectedFilters: state.crudTiposSepulturasRelatorioState.selectedFilters,
    selectedLocalizacoes: state.crudTiposSepulturasRelatorioState.selectedLocalizacoes,
    unidades,
    disableUnit,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field.indexOf('filtroLocalizacao__') > -1) {
        dispatch(actions.setFilterLocalizacaoGrid(field, valor))
        dispatch(actions.resolvePesquisaLocalizacaoGrid())
      } else {
        dispatch(actions.setField(field, valor))
      }
    },
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    newHandler: (event) => dispatch(actions.makeNew()),
    abrirRelatorio: () => dispatch(actions.abrirRelatorio()),
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    getOpcoesPesquisa: () => dispatch(actions.getOpcoesPesquisa()),
  }
}

class TiposSepulturasRelatorioFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showPesquisarButton = false
    this.showCSVButton = false
    this.showColumnButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_TIPOS_SEPULTURAS_RELATORIO}`)
      this.props.loadFavorito(this.props.history.location.pathname)
    }
    actions.afterLoadFavorito = (data) => {
      if (data && data.oid) {
        this.setState({ favorito: 1 })
        this.setState({ dadosFavorito: data })
      } else {
        this.setState({ favorito: 0 })
        this.setState({ dadosFavorito: null })
      }
    }
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.newHandler()
  }
  componentWillUnmount() {}

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button
          label="Visualizar"
          icon="pi pi-check"
          className="p-button-success"
          onClick={this.props.abrirRelatorio}
        />
      </div>
    )
  }

  getFiltrosPesquisaLocalizacao = (tipoSepultura) => {
    if (utils.isArrayNotEmpty(this.props.selectedLocalizacoes[tipoSepultura])) {
      return (
        <div className="p-col-8 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          {this.props.selectedLocalizacoes[tipoSepultura].map((filtro) => (
            <div className="p-grid form-group p-align-start">
              <div className="p-col-12">
                <div className="p-grid form-group" style={{ marginTop: '1px' }}>
                  <label>{filtro.chaveDicionario}</label>
                </div>
                <div className="p-grid form-group">
                  <Dropdown
                    autoWidth={false}
                    value={filtro.valorSelecionado.valor}
                    options={filtro.campoPesquisa.value}
                    onChange={(e) => this.props.changeHandler(e)}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name={'filtroLocalizacao__' + filtro.campoPesquisa.key}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
    return <div />
  }

  onChangeUnidade = (event) => {
    this.props.setField('unidadeSelecionada', event)
    this.props.clearSelectedFilters()
  }
  onChangeTipoSepultura = (event) => {
    this.props.setField('tipoSepulturaSelecionada', event)
    this.props.clearSelectedFilters()

    const oidTipoSepultura = _.get(event, 'id', null)
    const oidUnidade = _.get(this, 'props.record.unidadeSelecionada.id', null)
    const empresa = _.get(this, 'props.currentCompany', null)

    const unidade = _.get(empresa, 'unidades', []).find((f) => f.oid === oidUnidade)
    const semFiltro = utils.isTipoSepulturaLocalizacaoGridSemFiltro(empresa, unidade, oidTipoSepultura)
    if (!semFiltro) {
      this.props.getOpcoesPesquisa()
    }
  }

  buildHeader = () => {
    return (
      <div>
        <DefaultToolBar listPageContainer={this} title={'Relatório de Tipos de Sepultura'} />
      </div>
    )
  }

  render() {
    const content = (
      <TiposSepulturasRelatorioForm
        abrirRelatorio={this.props.abrirRelatorio}
        currentUser={this.props.currentUser}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        getFiltrosPesquisaLocalizacao={this.getFiltrosPesquisaLocalizacao}
        onChangeTipoSepultura={this.onChangeTipoSepultura}
        onChangeUnidade={this.onChangeUnidade}
        setField={this.props.setField}
        selectedFilters={this.props.selectedFilters}
      />
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TiposSepulturasRelatorioFormPageContainer)
