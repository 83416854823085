import React from 'react';
import ConsultaRemessaFormPageContainer from './ConsultaRemessaFormPageContainer';

export class ConsultaRemessaFormPage extends React.Component{
    
    render(){
      return (
        <ConsultaRemessaFormPageContainer {...this.props}/>
      )
    }
}

