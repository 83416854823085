import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { Filtro } from '../../../utils/Filtro.js'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { Column } from 'primereact/column'
import { routes, enums } from '../../../constants'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { actions } from '../grupo.actions'
import { actions as grupoActions } from '../../grupo/grupo.actions'
import { GrupoForm } from './GrupoForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudGrupoState.messages
  const record = state.crudGrupoState.currentRecord
  const currentUser = state.appState.currentUser

  const lookupGrupoBase = {
    records: state.crudGrupoState.records,
    columns: state.crudGrupoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'descricao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudGrupoState.index,
    rows: state.crudGrupoState.max,
    totalRecords: state.crudGrupoState.count,
    sortField: state.crudGrupoState.sortField,
    order: state.crudGrupoState.sortOrder,
    editDisabled: false,
  }
  let lookupGrupo = Object.assign({}, state.crudGrupoState.lookupGrupo, lookupGrupoBase)

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  //adicionando a unidade nula, pois caso não tenha unidade, a taxa deve valer para todas unidades
  let unidades = [{ id: '', value: 'Selecione' }]
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)
  return {
    currentUser,
    record,
    messages,
    unidades,
    disableUnit,
    lookupGrupo,
    tipos: [
      { label: 'Entrada', value: 1 },
      { label: 'Saída', value: 2 },
    ],
    first: state.crudGrupoState.index,
    rows: state.crudGrupoState.max,
    totalRecords: state.crudGrupoState.count,
    sortField: state.crudGrupoState.sortField,
    order: state.crudGrupoState.sortOrder,
    idTela: state.crudGrupoState.id,
    botoesDinamicos: state.crudGrupoState.botoesDinamicos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'unidade') {
        dispatch(actions.setField('oidUnidade', valor.oidUnidade))
        dispatch(actions.setField('descricaoUnidade', valor.name))
      } else if (field === 'tipo') {
        dispatch(actions.setField(field, valor.value))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },
    //REFERENTE AO LOOKUP DE GRUPO
    lookupGrupoSetVisible: (visible) => dispatch(actions.setLookupGrupoVisible(visible)),
    lookupGrupoPaginate: (paginationParams) => dispatch(grupoActions.loadLookup(paginationParams)),
    lookupGrupoSortOrder: (sortParams) => dispatch(grupoActions.loadLookup(sortParams)),
    lookupGrupoApplyFilter: (filterParams) => {
      dispatch(actions.aplicarFiltrosDadosGrupo(filterParams))
    },
    lookupGrupoLoad: () => dispatch(actions.aplicarFiltrosDadosGrupo()),
    setGrupo: (grupo) => {
      dispatch(actions.setField('grupoFinanceiroPai.id', grupo != null ? grupo.id : null))
      dispatch(actions.setField('grupoFinanceiroPai.value', grupo != null ? grupo.descricao : ''))
    },
    //
    onClickBotaoDinamico: (listId, oidBotao) => dispatch(actions.executarFuncaoDinamica(listId, oidBotao)),
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.FORMULARIO)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
  }
}

class GrupoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.beforeFetchRecordSuccess = (data) => {
      if (data) {
        if (data.aliquotaPis) {
          data.aliquotaPis = utils.formatCurrency(data.aliquotaPis)
        }
        if (data.aliquotaIss) {
          data.aliquotaIss = utils.formatCurrency(data.aliquotaIss)
        }
      }
      return data
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_GRUPO_FINANCEIRO}/${oid}`)
      // colocando um loadById para realizar o carregamento do novo cód. mascarado caso alterado
      this.props.loadById(oid)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_GRUPO_FINANCEIRO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_GRUPO_FINANCEIRO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    const valor = target.value
    this.props.setField(field, valor)
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_GRUPO_FINANCEIRO}`)
  }

  onBlurValor = (e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    if (typeof valor === 'string') {
      this.props.setField(field, utils.formatCurrency(valor.replace(',', '.')))
    }
  }

  // REFERENTE AO LOOKUP PARA GRUPO
  lookupGrupoOnPage = (e) => {
    this.props.lookupGrupoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupGrupoOnSort = (e) => {
    this.props.lookupGrupoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupGrupoOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupGrupoApplyFilter(filter)
    })
    this.props.lookupGrupoLoad()
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupGrupoLoad()
    }
  }

  lookupGrupoOnClick = (e) => {
    this.props.lookupGrupoLoad()
    this.props.lookupGrupoSetVisible(true)
  }
  lookupGrupoOnHide = (e) => {
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnConfirm = (e) => {
    this.props.setGrupo({ descricao: e.selection.descricao, id: e.selection.oid })
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnCancel = (e) => {
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnEdit = () => {
    if (this.props.record.grupoFinanceiroPai && this.props.record.grupoFinanceiroPai.id) {
      this.props.loadById(this.props.record.grupoFinanceiroPai.id)
      return `${routes.PATH_GRUPO_FINANCEIRO}/${this.props.record.grupoFinanceiroPai.id}`
    } else {
      return `${routes.PATH_GRUPO_FINANCEIRO}`
    }
  }
  lookupGrupoOnComplete = (e) => {}

  lookupGrupoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let grupo = { descricao: e.value, id: null }
      this.props.setGrupo(grupo)
      this.props.clearRecords()
    } else {
      this.props.setGrupo({ descricao: value.descricao, id: value.oid })
      const filters = {
        campo: 'descricao',
        valor: value.descricao,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupGrupoApplyFilter(filters)
    }
  }

  lookupGrupoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.grupoFinanceiro) && utils.nonNull(this.props.record.grupoFinanceiro.value)) {
      const filtroGrupo = new Filtro('descricao', this.props.record.grupoFinanceiro.value, 'CONTEM')
      this.props.lookupGrupoApplyFilter(filtroGrupo)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <GrupoForm
        currentUser={this.props.currentUser}
        messages={this.props.messages}
        tipos={this.props.tipos}
        changeHandler={this.props.changeHandler}
        onComplete={this.props.onComplete}
        record={this.props.record}
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        onChangeUnit={this.onChangeUnit}
        onBlurValor={this.onBlurValor}
        //----
        history={this.props.history}
        lookupGrupo={this.props.lookupGrupo}
        lookupGrupoOnKeyPress={this.lookupGrupoOnKeyPress}
        lookupGrupoOnHide={this.lookupGrupoOnHide}
        lookupGrupoOnConfirm={this.lookupGrupoOnConfirm}
        lookupGrupoOnCancel={this.lookupGrupoOnCancel}
        lookupGrupoOnPage={this.lookupGrupoOnPage}
        lookupGrupoOnSort={this.lookupGrupoOnSort}
        lookupGrupoOnFilter={this.lookupGrupoOnFilter}
        lookupGrupoOnClick={this.lookupGrupoOnClick}
        lookupGrupoOnEdit={this.lookupGrupoOnEdit}
        lookupGrupoOnComplete={this.lookupGrupoOnComplete}
        lookupGrupoOnInputFilter={this.lookupGrupoOnInputFilter}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrupoFormPageContainer)
