import {types} from "./redefinicaosenha.actions";

const initialState = {
  credential: {
    login: "",
    password: ""
  },
  token: "",
  login: "",
  message: ""
};

export function redefinicaoSenhaReducer(state = initialState, action) {
  if (action.type === types.LOGIN_SET_CREDENTIAL) {
    const credential = Object.assign({}, state.credential, action.credential);
    return Object.assign({}, state, { credential });
  }
  if (action.type === types.LOGIN_CLEAR_CREDENTIAL) {
    const errorMessage = "";
    return Object.assign({}, state, { errorMessage });
  }

  if (action.type === types.LOGIN_SET_ERROR_MESSAGE) {
    const { errorMessage } = action;
    return Object.assign({}, state, { errorMessage });
  }

  if (action.type === types.SET_SUCCESS_MESSAGE) {
    const { msg } = action;
    return Object.assign({}, state, { msg });
  }

  if (action.type === types.LOGIN_CLEAR_ERROR_MESSAGE) {
    const errorMessage = "";
    return Object.assign({}, state, { errorMessage });
  }

  if (action.type === types.LOGIN_SET_TOKEN) {
    const { token } = action;
    return Object.assign({}, state, { token });
  }

  if (action.type === types.LOGIN_CLEAR_TOKEN) {
    const token = "";
    return Object.assign({}, state, { token });
  }

  if (action.type === types.LOGIN_SET_LOGIN) {
    const { login } = action;
    return Object.assign({}, state, { login });
  }

  if (action.type === types.LOGIN_CLEAR_LOGIN) {
    const login = "";
    return Object.assign({}, state, { login });
  }

  if (action.type === types.LOGIN_SET_OID_COMPANY) {
    const { oidCompany } = action;
    return Object.assign({}, state, { oidCompany });
  }

  if (action.type === types.LOGIN_CLEAR_OID_COMPANY) {
    const oidCompany = "";
    return Object.assign({}, state, { oidCompany });
  }

  if (action.type === types.LOGIN_CLEAR_ALL) {
    const data = {
      errorMessage: ""
    };
    return Object.assign({}, state, data);
  }
  return state;
}
