import React from "react";

import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {LookupField} from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";
import {getTemplateDropDownPessoa} from "../../../utils/Template";
import { enums} from "../../../constants/index";
import {TabView, TabPanel} from "primereact/tabview";
import {DataTableLog} from "../../../components/log/DataTableLog";
import "./AgendaForm.scss";
import _ from "lodash";

function getLookupPessoa(props, messages) {
    return (
        <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4">
                <LookupField
                    label={utils.getMessage(messages, "agenda.label.pessoa")}
                    value={
                        utils.nonNull(props.record.pessoa) && utils.nonNull(props.record.pessoa.value) ? props.record.pessoa.value : ""
                    }
                    name="pessoa"
                    field="nome"
                    history={props.history}
                    itemTemplate={getTemplateDropDownPessoa}
                    onKeyPress={props.lookupPessoaOnKeyPress}
                    onComplete={props.lookupPessoaOnComplete}
                    onInputFilter={props.lookupPessoaOnInputFilter}
                    editDisabled={props.lookupPessoa.editDisabled}
                    onEditField={props.lookupPessoaOnEdit}
                    onClick={props.lookupPessoaOnClick}
                    visible={props.lookupPessoa.visible}
                    modal={props.lookupPessoa.modal}
                    header={props.lookupPessoa.header}
                    onHide={props.lookupPessoaOnHide}
                    onConfirm={props.lookupPessoaOnConfirm}
                    onCancel={props.lookupPessoaOnCancel}
                    records={props.lookupPessoa.records}
                    columns={props.lookupPessoa.columns}
                    totalRecords={props.lookupPessoa.totalRecords}
                    sortOrder={props.lookupPessoa.order}
                    sortField={props.lookupPessoa.sortField}
                    first={props.lookupPessoa.first}
                    rows={props.lookupPessoa.rows}
                    onPage={props.lookupPessoaOnPage}
                    onFilter={props.lookupPessoaOnFilter}
                    onSort={props.lookupPessoaOnSort}
                />
            </div>
        </div>
    )
}
function getLookupLocalizacao(props, messages) {
    return (
        <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-4">
                <LookupField
                    label={utils.getMessage(messages, "agenda.label.capela")}
                    value={props.record.localizacao ? props.record.localizacao.value : ""}
                    name="localizacao"
                    field="identificacao"
                    history={props.history}
                    unidade={props.record.unidade}
                    unidades={props.unidades}
                    showFiltroUnidade={false}
                    showFiltroTemporaria={false}
                    onChangeUnit={props.onChangeUnitLocalizacao}
                    onKeyPress={props.lookupLocalizacaoOnKeyPress}
                    onComplete={props.lookupLocalizacaoOnComplete}
                    onInputFilter={props.lookupLocalizacaoOnInputFilter}
                    editDisabled={props.lookupLocalizacao.editDisabled}
                    onEditField={props.lookupLocalizacaoOnEdit}
                    onClick={props.lookupLocalizacaoOnClick}
                    visible={props.lookupLocalizacao.visible}
                    modal={props.lookupLocalizacao.modal}
                    header={props.lookupLocalizacao.header}
                    onHide={props.lookupLocalizacaoOnHide}
                    onConfirm={props.lookupLocalizacaoOnConfirm}
                    onCancel={props.lookupLocalizacaoOnCancel}
                    records={props.lookupLocalizacao.records}
                    columns={props.lookupLocalizacao.columns}
                    totalRecords={props.lookupLocalizacao.totalRecords}
                    sortOrder={props.lookupLocalizacao.order}
                    sortField={props.lookupLocalizacao.sortField}
                    first={props.lookupLocalizacao.first}
                    rows={props.lookupLocalizacao.rows}
                    onPage={props.lookupLocalizacaoOnPage}
                    onFilter={props.lookupLocalizacaoOnFilter}
                    onSort={props.lookupLocalizacaoOnSort}
                />
            </div>
        </div>
    );
}

export const AgendaForm = props => {
    const messages = props.messages;
    const record = props.record;
    const changeHandler = props.changeHandler;
    const idUnidadeRecord = _.get(record, 'unidade.id', null);
    let unidade = {};
    if (idUnidadeRecord) {
        unidade = {id: record.unidade.id, value: record.unidade.value};
    }

    let dataInicial = record.dataInicial;
    let dataFinal = record.dataFinal;

    return (
        <TabView activeIndex={props.state.activeIndex} onTabChange={e => props.changeActiveIndex(e.index)}>
            <TabPanel header="Dados Gerais">
                {getDadosPrincipais()}
            </TabPanel>
            {/*<TabPanel header="Logs">{getDadosLogs()}</TabPanel>*/}
        </TabView>
    );

    function getDadosPrincipais() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <div className="p-col-12">
                            <h3>
                                {utils.getMessage(messages, "agenda.label.agendamento")} {record.oid || ""}
                            </h3>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2">
                                <span className="p-float-label">
                                <Dropdown
                                    autoWidth={false}
                                    disabled={props.disableUnit}
                                    value={unidade}
                                    options={props.unidades}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="unidade"
                                />
                                <label>{utils.getMessage(messages, "agenda.label.unidade")}</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2">
                                <span className="p-float-label">
                                <Calendar
                                    showTime={true}
                                    yearNavigator={true}
                                    yearRange={utils.getYearRangeFromDate("1970")}
                                    monthNavigator={true}
                                    value={dataInicial}
                                    onChange={changeHandler}
                                    name="dataInicial"
                                />
                                    <label>{utils.getMessage(messages, "agenda.label.dataInicial")}</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2">
                                <span className="p-float-label">
                                <Calendar
                                    showTime={true}
                                    yearNavigator={true}
                                    yearRange={utils.getYearRangeFromDate("1970")}
                                    monthNavigator={true}
                                    value={dataFinal}
                                    onChange={changeHandler}
                                    name="dataFinal"
                                />
                                    <label>{utils.getMessage(messages, "agenda.label.dataFinal")}</label>
                                </span>
                            </div>
                        </div>
                        {getLookupLocalizacao(props, messages)}
                        {getLookupPessoa(props, messages)}
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2">
                                <span className="p-float-label">
                                <InputTextarea
                                    value={record.observacao}
                                    onChange={changeHandler}
                                    name="observacao"
                                    rows={1}
                                    cols={30}
                                    autoResize={true}
                                />
                                    <label>{utils.getMessage(messages, "agenda.label.observacao")}</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function getDadosLogs() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <div className="card card-w-title">
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                <DataTableLog record={record} tipoLog={enums.TipoLog.AGENDA}></DataTableLog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
