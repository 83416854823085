import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { actions as taxaActions } from '../../taxa/taxa.actions'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { enums, icons, mascaras, routes } from '../../../constants'
import utils from '../../../utils/index'
import { LookupField } from '../../../components/lookup/LookupField'
import storage from '../../../services/stoage.service'
import { actions as configuracaoBoletoActions } from '../../configuracaoBoleto/configuracaoBoleto.actions'
import { actions } from '../controleFinanceiro.actions'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { ControleFinanceiroList } from './ControleFinanceiroList'
import { Filtro } from '../../../utils/Filtro.js'
import _ from 'lodash'
import apiService from '../../../services/api-id-value-service'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  let tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))
  let tiposFiltrosContrato = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  let tiposFiltrosFormaPagamento = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  let tiposFiltrosFrequenciaFaturamento = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  const empresa = storage.getCompany()
  const unidade = storage.getUnit()
  const units = storage.getUnits()
  let disableUnit = unidade
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(empresa)
  const taxaConservacao = state.crudTaxaState.taxaConservacao

  //LOOKUP DE TAXA
  const lookupTaxaBase = {
    records: state.crudTaxaState.records,
    columns: state.crudTaxaState.columnsLookup
      .filter(
        (col) =>
          col.field === 'oid' ||
          col.field === 'valor' ||
          col.field === 'grupoFinanceiro_oid' ||
          col.field === 'grupoFinanceiro_descricao'
      )
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    editDisabled: true,
  }
  let lookupTaxa = Object.assign({}, state.crudControleFinanceiroState.lookupTaxa, lookupTaxaBase)
  let lookupCfTaxa = Object.assign({}, state.crudControleFinanceiroState.lookupTaxa, lookupTaxaBase)

  // REFERENTE AO DROPDOWN DE TIPOS DE SEPULTURA
  let tiposSepultura = []
  if (state.crudTipoSepulturaState.records && state.crudTipoSepulturaState.records.length > 0) {
    state.crudTipoSepulturaState.records.forEach((tipoSepultura) => {
      tiposSepultura.push({ value: tipoSepultura.descricao, id: tipoSepultura.oid })
    })
  }

  // REFERENTE AO LOOKUP DE CONFIGURACAO DE BOLETO
  const lookupConfiguracaoBoletoBase = {
    records: state.crudConfiguracaoBoletoState.records,
    columns: state.crudConfiguracaoBoletoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudConfiguracaoBoletoState.index,
    rows: state.crudConfiguracaoBoletoState.max,
    totalRecords: state.crudConfiguracaoBoletoState.count,
    sortField: state.crudConfiguracaoBoletoState.sortField,
    order: state.crudConfiguracaoBoletoState.sortOrder,
    editDisabled: true,
  }
  let lookupConfiguracaoBoleto = Object.assign(
    {},
    state.crudControleFinanceiroState.lookupConfiguracaoBoleto,
    lookupConfiguracaoBoletoBase
  )

  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(empresa, unidade)
  const formasPagamentoBasica = utils.getFormasPagamento(enums.FormasPagamentoRemessa, configFormasPagamento)

  const isIntegracaoVindi = utils.isIntegracaoVindi(empresa, unidade)

  return {
    empresa,
    unidade,
    units,
    formasPagamentoBasica,
    taxaConservacao,
    tiposFiltros,
    tiposFiltrosContrato,
    tiposFiltrosFormaPagamento,
    tiposFiltrosFrequenciaFaturamento,
    disableUnit,
    unidades,
    lookupTaxa,
    lookupCfTaxa,
    tiposSepultura,
    lookupConfiguracaoBoleto,
    colunasTaxa: state.crudControleFinanceiroState.colunasTaxa,
    showHideColumns: state.crudControleFinanceiroState.showHideColumns,
    messages: state.crudControleFinanceiroState.messages,
    columns: state.crudControleFinanceiroState.columns,
    records: state.crudControleFinanceiroState.records,
    record: state.crudControleFinanceiroState.currentRecord,
    first: state.crudControleFinanceiroState.index,
    rows: state.crudControleFinanceiroState.max,
    totalRecords: state.crudControleFinanceiroState.count,
    sortField: state.crudControleFinanceiroState.sortField,
    order: state.crudControleFinanceiroState.sortOrder,
    selectedFilters: state.crudControleFinanceiroState.selectedFilters,
    selectedRecords: state.crudControleFinanceiroState.selectedRecords,
    isIntegracaoVindi,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      const value = target.value
      const hash = target.id
      if (field === 'unidade') {
      }
      dispatch(actions.setAdvancedFilter(field, value, hash))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/remessafinanceira/all`))
      }, 400)
    },
    loadTiposSepulturas: (oidUnidade) => dispatch(actions.loadTiposSepulturas(oidUnidade)),
    load: () => {
      dispatch(actions.load())
    },
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (favorito) => {
      dispatch(actions.removeFavorito(favorito))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },

    lookupTaxaSetVisible: (visible) => dispatch(actions.setLookupTaxaVisible(visible)),
    lookupTaxaPaginate: (paginationParams, oidUnidade) => {
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade))
    },
    lookupTaxaSortOrder: (paginationParams, oidUnidade) =>
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade)),
    lookupTaxaApplyFilter: (filterParams, oidUnidade) => {
      dispatch(taxaActions.setFiltroAvancado(filterParams))
      dispatch(taxaActions.loadTaxasValidas(null, oidUnidade))
    },
    lookupTaxaLoad: (oidUnidade) => dispatch(taxaActions.loadTaxasValidas(null, oidUnidade)),

    lookupConfiguracaoBoletoSetVisible: (visible) => dispatch(actions.setLookupConfiguracaoBoletoVisible(visible)),
    lookupConfiguracaoBoletoPaginate: (paginationParams) =>
      dispatch(configuracaoBoletoActions.paginate(paginationParams)),
    lookupConfiguracaoBoletoSortOrder: (sortParams) => dispatch(configuracaoBoletoActions.sortOrder(sortParams)),
    lookupConfiguracaoBoletoApplyFilter: (filterParams) => {
      dispatch(configuracaoBoletoActions.setType(null))
      dispatch(configuracaoBoletoActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(configuracaoBoletoActions.applyFilter(filterParams))
    },
    lookupConfiguracaoBoletoLoad: () => dispatch(configuracaoBoletoActions.loadLookup()),

    lookupCfTaxaSetVisible: (visible) => dispatch(actions.setLookupTaxaVisible(visible)),
    lookupCfTaxaPaginate: (paginationParams, oidUnidade) => {
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade))
    },
    lookupCfTaxaSortOrder: (paginationParams, oidUnidade) =>
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade)),
    lookupCfTaxaApplyFilter: (filterParams, oidUnidade) => {
      dispatch(taxaActions.setFiltroAvancado(filterParams))
      dispatch(taxaActions.loadTaxasValidas(null, oidUnidade))
    },
    lookupCfTaxaLoad: (oidUnidade) => dispatch(taxaActions.loadTaxasValidas(null, oidUnidade)),

    loadConfiguracaoBoleto: (oid) => dispatch(actions.loadConfiguracaoBoletoPadrao(oid)),
    setDataVencimento: (value) => dispatch(actions.setDataVencimento(value)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    pesquisaAvancada: (state) => {
      dispatch(actions.clearSelectedFilters())
      if (state.filtroVencimento.tipoFiltro && state.filtroVencimento.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'vencimento',
          valor: state.filtroVencimento.dataInicial,
          valorComplementar: state.filtroVencimento.dataFinal,
          tipoFiltro: state.filtroVencimento.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroEmissao.tipoFiltro && state.filtroEmissao.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'emissao',
          valor: state.filtroEmissao.dataInicial,
          valorComplementar: state.filtroEmissao.dataFinal,
          tipoFiltro: state.filtroEmissao.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroUnidade && state.filtroUnidade.id !== null) {
        const filtro = {
          campo: 'oidUnidade',
          valor: state.filtroUnidade.id,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroDiaVencimento.tipoFiltro && state.filtroDiaVencimento.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'diaVencimento',
          valor: state.filtroDiaVencimento.diaVencimentoInicial,
          valorComplementar: state.filtroDiaVencimento.diaVencimentoFinal,
          tipoFiltro: state.filtroDiaVencimento.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroTipoSepultura.tipoFiltro && state.filtroTipoSepultura.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'oidTipoSepultura',
          valor: state.filtroTipoSepultura.tipoSepultura.id,
          tipoFiltro: state.filtroTipoSepultura.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroTipoFormaPagamento.tipoFiltro && state.filtroTipoFormaPagamento.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'formaPagamento',
          valor: state.filtroTipoFormaPagamento.valor.id,
          tipoFiltro: state.filtroTipoFormaPagamento.tipoFiltro.id,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroFrequenciaFaturamento.tipoFiltro && state.filtroFrequenciaFaturamento.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'frequenciaFaturamento',
          valor: state.filtroFrequenciaFaturamento.valor.id,
          tipoFiltro: state.filtroFrequenciaFaturamento.tipoFiltro.id,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (
        state.filtroDataProximaRemessaCompetencia.tipoFiltro &&
        state.filtroDataProximaRemessaCompetencia.tipoFiltro.value !== null
      ) {
        const filtro = {
          campo: 'dataProximaRemessaCompetencia',
          valor: state.filtroDataProximaRemessaCompetencia.inicial,
          valorComplementar: state.filtroDataProximaRemessaCompetencia.final,
          tipoFiltro: state.filtroDataProximaRemessaCompetencia.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (state.filtroConspaga.tipoFiltro && state.filtroConspaga.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'conspaga',
          valor: state.filtroConspaga.anoInicial,
          valorComplementar: state.filtroConspaga.anoFinal,
          tipoFiltro: state.filtroConspaga.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }
      if (
        state.filtroStatusLancamento &&
        state.filtroStatusLancamento.id !== null &&
        state.filtroStatusLancamento.id !== 0
      ) {
        const filtro = {
          campo: 'status',
          valor: state.filtroStatusLancamento.id,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      if (state.filtroTaxa.tipoFiltro && state.filtroTaxa.tipoFiltro.value !== null) {
        const filtro = {
          campo: 'oidTaxa',
          valor: state.filtroTaxa.taxa.id,
          tipoFiltro: state.filtroTaxa.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      if (state.tipoFiltro) {
        const filtro = {
          campo: 'tipoFiltro',
          valor: state.tipoFiltro,
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      setTimeout(() => {
        dispatch(actions.filter(`/lottustech/remessafinanceira/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearRecords: () => {
      dispatch(taxaActions.setRecords([]))
      dispatch(actions.setRecords([]))
    },
    // getTaxaConservacao: oidUnidade => {
    //     dispatch(taxaActions.getTaxaConservacao(oidUnidade));
    // },
    gerarLancamentos: (filtros, filtroUnidade) => {
      dispatch(actions.gerarLancamentos(filtros, filtroUnidade))
    },
    gerarBoletos: (filtros, filtroUnidade) => {
      dispatch(actions.gerarBoletos(filtros, filtroUnidade))
    },
    gerarBoletoLancamento: (lancamento) => {
      dispatch(actions.gerarBoletoLancamento(lancamento))
    },
  }
}

class ControleFinanceiroListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showFilter = false
    this.showCSVButton = false
    this.showCustomFilters = false
    this.state = {
      tipoFiltroIgualA: props.tiposFiltros[1], //IGUAL A
      dadosFavorito: null,
      favorito: 0,
      tipoFiltro: 1,
      selecionarTodos: false,
      filtroVencimento: {
        tipoFiltro: props.tiposFiltros[3], //ENTRE
        dataInicial: utils.getStartOfMonth(),
        dataFinal: utils.getEndOfMonth(),
        mostrarDataInicial: true,
        mostrarDataFinal: true,
      },
      filtroEmissao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroTaxa: {
        tipoFiltro: '',
        taxa: '',
      },
      filtroTipoSepultura: {
        tipoFiltro: '',
        tipoSepultura: '',
        mostrarFiltro: false,
      },
      filtroTipoFormaPagamento: {
        tipoFiltro: '',
        valor: '',
        mostrarFiltro: false,
      },
      filtroFrequenciaFaturamento: {
        tipoFiltro: '',
        valor: '',
        mostrarFiltro: false,
      },
      filtroDataProximaRemessaCompetencia: {
        tipoFiltro: props.tiposFiltros[0], //Selecione
        inicial: '',
        final: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroDiaVencimento: {
        tipoFiltro: props.tiposFiltros[0], //Selecione
        diaVencimentoInicial: null,
        diaVencimentoFinal: null,
        mostrarDiaVencimentoInicial: false,
        mostrarDiaVencimentoFinal: false,
      },
      filtroUnidade: null,
      filtroStatusLancamento: enums.ArrayStatusLancamento[1], // Em aberto
      filtroConspaga: {
        tipoFiltro: props.tiposFiltros[1], //IGUAL A
        anoInicial: window.moment().year(),
        anoFinal: window.moment().year(),
        mostrarAnoInicial: true,
        mostrarAnoFinal: false,
      },

      filtroSomenteContrato: false,
      filtroIncluirContratosComParcelamento: true,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  // MÉTODO EXPORTAÇÃO DE CSV
  exportar() {
    listPageUtils.onCsvExport(this.props.records, this, actions)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  componentDidMount() {
    logarAcesso().then()
    this.configFiltrosPadroes()
    listPageUtils.loadFavorito(this, actions)
    const empresa = _.get(this, 'props.empresa', null)
    //SUGERIR CONFIGURACAO DE BOLETO PADRAO DA EMPRESA
    const oidConfigPadraoBoleto = utils.getParametroEmpresaCorrente('oidConfiguracaoPadraoBoleto', empresa)
    if (oidConfigPadraoBoleto && oidConfigPadraoBoleto.value) {
      this.props.loadConfiguracaoBoleto(parseInt(oidConfigPadraoBoleto.value))
    }
  }

  setFlagHabilitarCamposConspaga = (taxas) => {
    //seta a flag que vai habilitar os campos 'conspagaUltimoPagamento' e 'conspagaPagarAte'
    let result = false

    if (utils.isArrayNotEmpty(taxas)) {
      const units = _.get(this, 'props.units', null)
      taxas.forEach((taxa) => {
        const isTaxaConservacao = utils.isTaxaConservacao(units, this.resolveOidUnidade(), taxa.oidGrupo)
        if (isTaxaConservacao) {
          result = true
        }
      })
    }
    this.props.setField('habilitarCamposConspaga', result)
    return result
  }

  configFiltrosPadroes() {
    let currentUnit = _.get(this, 'props.unidade', null)
    let empresa = _.get(this, 'props.empresa', null)
    if (currentUnit) {
      currentUnit = utils.getIdValueUnidade(currentUnit)
    } else {
      currentUnit = utils.getIdValueUnidadeMatriz(empresa)
    }
    if (currentUnit) {
      this.changeUnidade(currentUnit)
      // this.props.getTaxaConservacao(currentUnit.id);
      if (currentUnit && currentUnit.id) {
        this.props.loadTiposSepulturas(currentUnit.id)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
    if (prevProps.taxaConservacao && !this.state.filtroTaxa.taxa) {
      this.state.filtroTaxa.tipoFiltro = prevProps.tiposFiltros[1] //IGUAL A
      this.state.filtroTaxa.taxa = {
        id: prevProps.taxaConservacao.oid,
        value: prevProps.taxaConservacao.grupoFinanceiro.value,
      }
      this.pesquisaAvancada()
    }
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  buildCustomFilters = () => {
    if (this.showCustomFilters) {
      return (
        <div>
          <br />
          {this.buildCustomFilterFormaPagamento()}
          {this.buildCustomFilterQuantidadeParcelas()}
          {this.buildCustomFilterVencimento()}
          {this.buildCustomFilterCompetencia()}
          {this.buildCustomFilterConfiguracaoBoleto()}
          {this.buildCustomFilterTaxas()}
          {this.buildCustomFilterDatatableTaxas()}
          {this.buildCustomFilterPagarConspagaAte()}
          {this.buildCustomFilterIncluirContratosComParcelamento()}
          {this.buildCustomFilterGerarBoletosIndividualmente()}
          <br />
          <div className="p-grid form-group">
            <div className="p-col-4">
              {/*{// SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO*/}
              {/*    this.state.tipoFiltro === 1 ? (*/}
              {/*        <Button*/}
              {/*            disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}*/}
              {/*            onClick={this.gerarLancamentos}*/}
              {/*            label={utils.getMessage(this.props.messages, "controlefinanceiro.label.gerarlancamentos")}*/}
              {/*            icon={icons.PLUS_ICON}*/}
              {/*            style={{marginRight: ".25em"}}*/}
              {/*            className="p-button-success"*/}
              {/*        />*/}
              {/*    ) : (*/}
              {/*        ""*/}
              {/*    )}*/}

              <Button
                disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}
                onClick={this.gerarBoletos}
                // label={utils.getMessage(this.props.messages, "controlefinanceiro.label.gerarboletos")}
                label="Gerar Remessa"
                icon={icons.PLUS_ICON}
                style={{ marginRight: '.25em' }}
                className="p-button-success"
              />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  buildCustomFilterVencimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.vencimento')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <Calendar
            value={this.props.record.vencimento}
            dateFormat={mascaras.MASCARA_DATA_DD_MM_YY}
            yearNavigator={true}
            yearRange={utils.getYearRangeFromDate('1900')}
            monthNavigator={true}
            name="cfVencimento"
            onChange={(e) => this.props.setDataVencimento(e.value)}
          />
        </div>
      </div>
    )
  }

  buildCustomFilterCompetencia = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.competencia')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <Calendar
            value={this.props.record.competencia}
            dateFormat={mascaras.MASCARA_DATA_MM_YY}
            yearRange={utils.getYearRangeFromDate('1900')}
            view="month"
            name="cfCompetencia"
            onChange={(e) => this.props.setField('competencia', e.value)}
          />
        </div>
      </div>
    )
  }

  buildCustomFilterPagarConspagaAte = () => {
    if (this.state.tipoFiltro === 1) {
      return (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <label className="label">
              {utils.getMessage(this.props.messages, 'controlefinanceiro.label.pagarconspagaate')}
            </label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <InputNumber
              name="conspagaPagarAte"
              value={this.props.record.conspagaPagarAte}
              onChange={(e) => this.props.setField('conspagaPagarAte', e.value)}
              disabled={!this.props.record.habilitarCamposConspaga}
              min={0}
            />
          </div>
        </div>
      )
    }
  }

  buildCustomFilterIncluirContratosComParcelamento = () => {
    if (this.state.tipoFiltro === 1) {
      return (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <label className="label">
              {utils.getMessage(this.props.messages, 'controlefinanceiro.label.incluirContratosComParcelasEmAberto')}
            </label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <Checkbox
              style={{ marginLeft: '5px' }}
              checked={this.props.record.incluirContratosComParcelamento}
              onChange={(e) => this.props.setField('incluirContratosComParcelamento', e.checked)}
            />
          </div>
        </div>
      )
    }
  }

  onChangeGerarBoletosIndividualmente = (value) => {
    this.props.setField('gerarBoletosIndividual', value)
  }

  buildCustomFilterGerarBoletosIndividualmente = () => {
    return (
      this.isBoletoOuDefinir(this.props.record.tipoFormaPagamento) && (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <label className="label">
              {utils.getMessage(this.props.messages, 'controlefinanceiro.label.gerarBoletosIndividualmente')}
            </label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <Checkbox
              style={{ marginLeft: '5px' }}
              checked={this.props.record.gerarBoletosIndividual}
              onChange={(e) => this.onChangeGerarBoletosIndividualmente(e.checked)}
            />
          </div>
        </div>
      )
    )
  }

  buildCustomFilterConfiguracaoBoleto = () => {
    return (
      this.isBoletoOuDefinir(this.props.record.tipoFormaPagamento) && (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <label className="label">
              {utils.getMessage(this.props.messages, 'controlefinanceiro.label.configuracaoboleto')}
            </label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-7 p-lg-6 p-xl-4">
            <LookupField
              value={
                this.props.record.configuracaoBoleto && this.props.record.configuracaoBoleto.value
                  ? this.props.record.configuracaoBoleto.value
                  : ''
              }
              name="configuracaoBoleto"
              field="value"
              history={this.props.history}
              appendTo={document.body}
              onKeyPress={this.lookupConfiguracaoBoletoOnKeyPress}
              onComplete={this.lookupConfiguracaoBoletoOnComplete}
              onInputFilter={this.lookupConfiguracaoBoletoOnInputFilter}
              editDisabled={this.props.lookupConfiguracaoBoleto.editDisabled}
              onEditField={this.lookupConfiguracaoBoletoOnEdit}
              onClick={this.lookupConfiguracaoBoletoOnClick}
              visible={this.props.lookupConfiguracaoBoleto.visible}
              modal={this.props.lookupConfiguracaoBoleto.modal}
              header={this.props.lookupConfiguracaoBoleto.header}
              onHide={this.lookupConfiguracaoBoletoOnHide}
              onConfirm={this.lookupConfiguracaoBoletoOnConfirm}
              onCancel={this.lookupConfiguracaoBoletoOnCancel}
              records={this.props.lookupConfiguracaoBoleto.records}
              columns={this.props.lookupConfiguracaoBoleto.columns}
              totalRecords={this.props.lookupConfiguracaoBoleto.totalRecords}
              sortOrder={this.props.lookupConfiguracaoBoleto.order}
              sortField={this.props.lookupConfiguracaoBoleto.sortField}
              first={this.props.lookupConfiguracaoBoleto.first}
              rows={this.props.lookupConfiguracaoBoleto.rows}
              onPage={this.lookupConfiguracaoBoletoOnPage}
              onFilter={this.lookupConfiguracaoBoletoOnFilter}
              onSort={this.lookupConfiguracaoBoletoOnSort}
            />
          </div>
        </div>
      )
    )
  }

  removerTaxa = (taxaRemovida) => {
    let taxasRestantes = this.props.record.taxas.filter((taxa) => {
      return taxa.id !== taxaRemovida.id
    })
    this.props.setField('taxas', taxasRestantes)
    const habilitar = this.setFlagHabilitarCamposConspaga(taxasRestantes)
    this.updateConspagaPagarAte(habilitar)
  }

  buildCustomFilterTaxas = () => {
    if (this.state.tipoFiltro === 1 && this.isBoletoOuDefinir(this.props.record.tipoFormaPagamento)) {
      return (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
            <label className="label">{utils.getMessage(this.props.messages, 'controlefinanceiro.label.taxa')}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-7 p-lg-6 p-xl-4">
            <LookupField
              value={
                this.props.record.taxa && this.props.record.taxa.hasOwnProperty('value')
                  ? this.props.record.taxa.value
                  : ''
              }
              name="cfTaxa"
              field="grupoFinanceiro_descricao"
              history={this.props.history}
              appendTo={document.body}
              itemTemplate={this.taxaDropDownTemplate}
              onKeyPress={this.lookupTaxaCfOnKeyPress}
              onComplete={this.lookupCfTaxaOnComplete}
              onInputFilter={this.lookupCfTaxaOnInputFilter}
              editDisabled={this.props.lookupCfTaxa.editDisabled}
              onEditField={this.lookupCfTaxaOnEdit}
              onClick={this.lookupCfTaxaOnClick}
              visible={this.props.lookupCfTaxa.visible}
              modal={this.props.lookupCfTaxa.modal}
              header={this.props.lookupCfTaxa.header}
              onHide={this.lookupCfTaxaOnHide}
              onConfirm={this.lookupCfTaxaOnConfirm}
              onCancel={this.lookupCfTaxaOnHide}
              records={this.props.lookupCfTaxa.records}
              columns={this.props.lookupCfTaxa.columns}
              totalRecords={this.props.lookupCfTaxa.totalRecords}
              sortOrder={this.props.lookupCfTaxa.order}
              sortField={this.props.lookupCfTaxa.sortField}
              first={this.props.lookupCfTaxa.first}
              rows={this.props.lookupCfTaxa.rows}
              onPage={this.lookupCfTaxaOnPage}
              onFilter={this.lookupCfTaxaOnFilter}
              onSort={this.lookupCfTaxaOnSort}
            />
          </div>
        </div>
      )
    }
    return null
  }

  buildCustomFilterDatatableTaxas = () => {
    if (this.state.tipoFiltro === 1 && this.isBoletoOuDefinir(this.props.record.tipoFormaPagamento)) {
      const actionTaxa = (rowData) => {
        return (
          <div>
            <Button
              type="button"
              icon={icons.REMOVE_ICON}
              className="p-button-danger"
              onClick={(e) => this.removerTaxa(rowData)}
            />
          </div>
        )
      }

      const columnDataTaxa = (rowData, column) => {
        if (column) {
          if (column.field === 'valor' && rowData[column.field] && rowData[column.field] !== '') {
            if (utils.isNumber(rowData.valor)) {
              return 'R$ ' + utils.formatCurrency(rowData.valor)
            } else {
              return 'R$ ' + rowData.valor
            }
          }
        }
      }

      const colunasTaxa = this.props.colunasTaxa.map((col) => {
        if (col.field === 'acoes') {
          return <Column {...col} key={col.field} body={actionTaxa} style={{ width: '10em', textAlign: 'center' }} />
        }
        if (col.field === 'valor') {
          return <Column {...col} key={col.field} body={columnDataTaxa} header={col.header} />
        }
        return <Column {...col} key={col.field} field={col.field} header={col.header} />
      })

      return (
        <div className="p-grid form-group p-align-center">
          <div className="p-col-6">
            <div className="card card-w-title">
              <label className="label">
                {utils.getMessage(this.props.messages, 'controlefinanceiro.label.taxasSelecionadas')}
              </label>
              <DataTable value={this.props.record.taxas} autoLayout={true}>
                {colunasTaxa}
              </DataTable>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  buildCustomFilterFormaPagamento = () => {
    if (!Array.isArray(this.props.formasPagamentoBasica) || this.props.formasPagamentoBasica.length === 0) {
      return null
    }
    return (
      <div className="p-grid form-group p-align-center">
        <br />
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">Forma de Pagamento</label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
          {this.props.formasPagamentoBasica.map((tipoFormaPagamento) => (
            <>
              <RadioButton
                style={{ marginLeft: '5px' }}
                id={'rb' + tipoFormaPagamento.id}
                value={tipoFormaPagamento}
                checked={this.props.record.tipoFormaPagamento.id === tipoFormaPagamento.id}
                onChange={(e) => this.changeFormaPagamento(e.value)}
              />
              <label className="p-radiobutton-label" htmlFor={'rb' + tipoFormaPagamento.id}>
                {tipoFormaPagamento.value}
              </label>
            </>
          ))}
        </div>
      </div>
    )
  }

  buildCustomFilterQuantidadeParcelas = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.parcelas')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <InputNumber
            name="parcelas"
            value={this.props.record.parcelas}
            onChange={(e) => this.props.setField('parcelas', e.value)}
            min={1}
          />
        </div>
      </div>
    )
  }

  buildOpcoesFiltros = () => {
    //Filtro de Contratos
    if (this.state.tipoFiltro === 1) {
      return (
        <div>
          {this.buildFiltroTipoContrato()}
          {this.buildFiltroTipoFormaPagamento()}
          {this.buildFiltroTipoFrequenciaFaturamento()}
          {this.buildFiltroProximaCompetencia()}
          {this.buildFiltroDiaVencimento()}
          {this.buildFiltroConspaga()}
        </div>
      )
    }
    //Filtro de Lancamentos
    // return (
    //     <div>
    //         {this.buildFiltroStatusPagamento()}
    //         {this.buildFiltroDataVencimento()}
    //         {this.buildFiltroDataEmissao()}
    //         {this.buildFiltroTaxa()}
    //     </div>
    // );
  }

  buildAdvancedFilters = () => {
    if (this.showFilter && !this.showCustomFilters) {
      return (
        <div>
          <br />
          {this.buildFiltroUnidade()}
          {/*NAO TEREMOS MAIS OPCAO FILTRO LANCAMENTO 22/04/2020*/}
          {/*{this.buildOpcoesTipoFiltro()}*/}
          {this.buildOpcoesFiltros()}
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  buildFiltroUnidade = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">{utils.getMessage(this.props.messages, 'controlefinanceiro.label.unidade')}</label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-1 p-lg-2 p-xl-10">
          <Dropdown
            autoWidth={false}
            disabled={this.props.disableUnit}
            value={this.state.filtroUnidade}
            options={this.props.unidades}
            onChange={(e) => this.changeUnidade(e.value)}
            optionLabel="value"
            optionValue={(v) => v}
            dataKey="value"
            name="unidade"
          />
        </div>
      </div>
    )
  }

  onChangeTipoFiltro = (e) => {
    const filtroConspaga = {
      tipoFiltro: null,
      anoInicial: null,
      anoFinal: null,
      mostrarAnoInicial: true,
      mostrarAnoFinal: false,
    }

    const field = e.target.name
    if (field === 'filtrarContratos') {
      this.setState({ tipoFiltro: 1 })
      filtroConspaga.tipoFiltro = this.state.tipoFiltroIgualA
      filtroConspaga.anoInicial = window.moment().year()
      filtroConspaga.anoFinal = window.moment().year()
    } else {
      this.setState({ tipoFiltro: 2 })
    }
    this.setState({ filtroConspaga })
    this.props.clearRecords()
  }

  buildOpcoesTipoFiltro = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.tipoFiltro')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
          <RadioButton
            inputId="filtrarContratos"
            name="filtrarContratos"
            value={this.state.tipoFiltro}
            checked={this.state.tipoFiltro === 1}
            onChange={(e) => this.onChangeTipoFiltro(e)}
          />
          <label htmlFor="filtrarContratos" className="p-radiobutton-label">
            Filtrar contratos
          </label>

          <RadioButton
            inputId="filtrarLancamentos"
            name="filtrarLancamentos"
            value={this.state.tipoFiltro}
            checked={this.state.tipoFiltro === 2}
            onChange={(e) => this.onChangeTipoFiltro(e)}
            style={{ marginLeft: '10px' }}
          />
          <label htmlFor="filtrarLancamentos" className="p-radiobutton-label">
            Filtrar lançamentos
          </label>
        </div>
      </div>
    )
  }

  // buildFiltroDataVencimento = () => {
  //     return (
  //         <div className="p-grid form-group p-align-center">
  //             <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
  //                 <label className="label">
  //                     {utils.getMessage(this.props.messages, "controlefinanceiro.label.vencimento")}
  //                 </label>
  //             </div>
  //             <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                 <Dropdown
  //                     autoWidth={false}
  //                     value={this.state.filtroVencimento.tipoFiltro}
  //                     options={this.props.tiposFiltros}
  //                     onChange={e => this.changeTipoFiltro(this.state.filtroVencimento, e.value)}
  //                     optionLabel="label"
  //                     name="tipo"
  //                 />
  //             </div>
  //
  //             {// SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
  //                 this.state.filtroVencimento.mostrarDataInicial ? (
  //                     <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                         <Calendar
  //                             locale={utils.getCalendar()}
  //                             value={this.state.filtroVencimento.dataInicial}
  //                             dateFormat={mascaras.MASCARA_DATA_DD_MM_YY}
  //                             yearNavigator={true}
  //                             yearRange={utils.getYearRangeFromDate("1970")}
  //                             monthNavigator={true}
  //                             readOnlyInput={true}
  //                             name="vencimento"
  //                             onChange={e => this.changeDataFiltro(this.state.filtroVencimento, "dataInicial", e.value)}
  //                         />
  //                     </div>
  //                 ) : (
  //                     ""
  //                 )}
  //
  //             {// SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
  //                 this.state.filtroVencimento.mostrarDataFinal ? (
  //                     <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-2">
  //                         <Calendar
  //                             locale={utils.getCalendar()}
  //                             value={this.state.filtroVencimento.dataFinal}
  //                             dateFormat={mascaras.MASCARA_DATA_DD_MM_YY}
  //                             yearNavigator={true}
  //                             yearRange={utils.getYearRangeFromDate("1970")}
  //                             monthNavigator={true}
  //                             readOnlyInput={true}
  //                             name="vencimento"
  //                             onChange={e => this.changeDataFiltro(this.state.filtroVencimento, "dataFinal", e.value)}
  //                         />
  //                     </div>
  //                 ) : (
  //                     ""
  //                 )}
  //         </div>
  //     );
  // };

  // buildFiltroDataEmissao = () => {
  //     return (
  //         <div className="p-grid form-group p-align-center">
  //             <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
  //                 <label
  //                     className="label">{utils.getMessage(this.props.messages, "controlefinanceiro.label.emissao")}</label>
  //             </div>
  //             <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                 <Dropdown
  //                     autoWidth={false}
  //                     value={this.state.filtroEmissao.tipoFiltro}
  //                     options={this.props.tiposFiltros}
  //                     onChange={e => this.changeTipoFiltro(this.state.filtroEmissao, e.value)}
  //                     optionLabel="label"
  //                     name="tipo"
  //                 />
  //             </div>
  //
  //             {// SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
  //                 this.state.filtroEmissao.mostrarDataInicial ? (
  //                     <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                         <Calendar
  //                             locale={utils.getCalendar()}
  //                             value={this.state.filtroEmissao.dataInicial}
  //                             dateFormat={mascaras.MASCARA_DATA_DD_MM_YY}
  //                             yearNavigator={true}
  //                             yearRange={utils.getYearRangeFromDate("1970")}
  //                             monthNavigator={true}
  //                             readOnlyInput={true}
  //                             name="emissao"
  //                             onChange={e => this.changeDataFiltro(this.state.filtroEmissao, "dataInicial", e.value)}
  //                         />
  //                     </div>
  //                 ) : (
  //                     ""
  //                 )}
  //
  //             {// SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
  //                 this.state.filtroEmissao.mostrarDataFinal ? (
  //                     <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                         <Calendar
  //                             locale={utils.getCalendar()}
  //                             value={this.state.filtroEmissao.dataFinal}
  //                             dateFormat={mascaras.MASCARA_DATA_DD_MM_YY}
  //                             yearNavigator={true}
  //                             yearRange={utils.getYearRangeFromDate("1970")}
  //                             monthNavigator={true}
  //                             readOnlyInput={true}
  //                             name="emissao"
  //                             onChange={e => this.changeDataFiltro(this.state.filtroEmissao, "dataFinal", e.value)}
  //                         />
  //                     </div>
  //                 ) : (
  //                     ""
  //                 )}
  //         </div>
  //     );
  // };

  buildFiltroDiaVencimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.diaVencimento')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={this.state.filtroDiaVencimento.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroDiaVencimento(e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
              style={{ maxWidth: '150px' }}
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.conspaga')}</label>
          </span>
        </div>

        {this.state.filtroDiaVencimento.mostrarDiaVencimentoInicial ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <InputNumber
              name="diaVencimentoInicial"
              value={this.state.filtroDiaVencimento.diaVencimentoInicial}
              onChange={(e) => this.changeDataFiltro(this.state.filtroDiaVencimento, 'diaVencimentoInicial', e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}

        {this.state.filtroDiaVencimento.mostrarDiaVencimentoFinal ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <InputNumber
              name="diaVencimentoFinal"
              value={this.state.filtroDiaVencimento.diaVencimentoFinal}
              onChange={(e) => this.changeDataFiltro(this.state.filtroDiaVencimento, 'diaVencimentoFinal', e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  buildFiltroTipoContrato = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.tipoContrato')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Dropdown
            autoWidth={false}
            value={this.state.filtroTipoSepultura.tipoFiltro}
            options={this.props.tiposFiltrosContrato}
            onChange={(e) => this.changeFiltroTipoCadastro(e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipo"
          />
        </div>

        {this.state.filtroTipoSepultura.mostrarFiltro ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
            <Dropdown
              autoWidth={false}
              value={this.state.filtroTipoSepultura.tipoSepultura}
              options={this.props.tiposSepultura}
              onChange={(e) => (this.state.filtroTipoSepultura.tipoSepultura = e.value)}
              optionLabel="value"
              optionValue={(v) => v}
              dataKey="id"
              name="tipoSepultura"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  buildFiltroTipoFormaPagamento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.formaPagamento')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Dropdown
            autoWidth={false}
            value={this.state.filtroTipoFormaPagamento.tipoFiltro}
            options={this.props.tiposFiltrosFormaPagamento}
            onChange={(e) => this.changeFiltroTipoFormaPagamento(e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipo"
            style={{ maxWidth: '150px' }}
          />
        </div>

        {this.state.filtroTipoFormaPagamento.mostrarFiltro ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
            <LtsDropdownIdValue
              name={'formaPagamento'}
              value={_.get(this.state, 'filtroTipoFormaPagamento.valor', '')}
              onChange={(e) => (this.state.filtroTipoFormaPagamento.valor = e)}
              loadDataFn={apiService.getFormasPagamentoPlano}
              style={{ width: '250px' }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }


  buildFiltroTipoFrequenciaFaturamento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.frequenciaFaturamento')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Dropdown
            autoWidth={false}
            value={this.state.filtroFrequenciaFaturamento.tipoFiltro}
            options={this.props.tiposFiltrosFrequenciaFaturamento}
            onChange={(e) => this.changeFiltroFrequenciaFaturamento(e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipo"
            style={{ maxWidth: '150px' }}
          />
        </div>

        {this.state.filtroFrequenciaFaturamento.mostrarFiltro ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
            <LtsDropdownIdValue
              name={'frequenciaPagamento'}
              value={_.get(this.state, 'filtroFrequenciaFaturamento.valor', '')}
              onChange={(e) => (this.state.filtroFrequenciaFaturamento.valor = e)}
              loadDataFn={apiService.getFrequenciasFaturamento}
              style={{ width: '250px' }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }


  buildFiltroProximaCompetencia = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.dataProximaRemessaCompetencia')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Dropdown
            className={'width-15-rem'}
            value={this.state.filtroDataProximaRemessaCompetencia.tipoFiltro}
            options={this.props.tiposFiltros}
            onChange={(e) => this.changeTipoFiltroDataProximaRemessaCompetencia(e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipoDataProximaRemessaCompetencia"
            style={{ maxWidth: '150px' }}
          />
        </div>

        {this.state.filtroDataProximaRemessaCompetencia.mostrarDataInicial ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <Calendar
              value={this.state.filtroDataProximaRemessaCompetencia.inicial}
              yearNavigator={true}
              yearRange={utils.getYearRangeFromDate('1900')}
              monthNavigator={true}
              dateFormat={mascaras.MASCARA_DATA_MM_YY}
              view="month"
              name="dataProximaRemessaCompetenciaIni"
              onChange={(e) =>
                this.changeDataFiltro(this.state.filtroDataProximaRemessaCompetencia, 'inicial', e.value)
              }
            />
          </div>
        ) : (
          ''
        )}

        {this.state.filtroDataProximaRemessaCompetencia.mostrarDataFinal ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <Calendar
              value={this.state.filtroDataProximaRemessaCompetencia.final}
              yearNavigator={true}
              yearRange={utils.getYearRangeFromDate('1900')}
              monthNavigator={true}
              dateFormat={mascaras.MASCARA_DATA_MM_YY}
              name="dataProximaRemessaCompetenciaFim"
              view="month"
              onChange={(e) => this.changeDataFiltro(this.state.filtroDataProximaRemessaCompetencia, 'final', e.value)}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  // buildFiltroTaxa = () => {
  //     return (
  //         <div className="p-grid form-group p-align-baseline">
  //             <div className="p-col-12 p-sm-12 p-md-1 p-lg-2 p-xl-2">
  //                 <label
  //                     className="label">{utils.getMessage(this.props.messages, "controlefinanceiro.label.taxa")}</label>
  //             </div>
  //             <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
  //                 <Dropdown
  //                     autoWidth={false}
  //                     value={this.state.filtroTaxa.tipoFiltro}
  //                     options={this.props.tiposFiltros}
  //                     onChange={e => (this.state.filtroTaxa.tipoFiltro = e.value)}
  //                     optionLabel="label"
  //                     name="tipo"
  //                 />
  //             </div>
  //             <div className="p-col-12 p-sm-12 p-md-7 p-lg-6 p-xl-4">
  //                 <LookupField
  //                     value={this.state.filtroTaxa.taxa ? this.state.filtroTaxa.taxa.value : ""}
  //                     name="taxa"
  //                     field="grupoFinanceiro_descricao"
  //                     history={this.props.history}
  //                     appendTo={document.body}
  //                     itemTemplate={this.taxaDropDownTemplate}
  //                     onKeyPress={this.lookupTaxaOnKeyPress}
  //                     onComplete={this.lookupTaxaOnComplete}
  //                     onInputFilter={this.lookupTaxaOnInputFilter}
  //                     editDisabled={this.props.lookupTaxa.editDisabled}
  //                     onEditField={this.lookupTaxaOnEdit}
  //                     onClick={this.lookupTaxaOnClick}
  //                     visible={this.props.lookupTaxa.visible}
  //                     modal={this.props.lookupTaxa.modal}
  //                     header={this.props.lookupTaxa.header}
  //                     onHide={this.lookupTaxaOnHide}
  //                     onConfirm={this.lookupTaxaOnConfirm}
  //                     onCancel={this.lookupTaxaOnCancel}
  //                     records={this.props.lookupTaxa.records}
  //                     columns={this.props.lookupTaxa.columns}
  //                     totalRecords={this.props.lookupTaxa.totalRecords}
  //                     sortOrder={this.props.lookupTaxa.order}
  //                     sortField={this.props.lookupTaxa.sortField}
  //                     first={this.props.lookupTaxa.first}
  //                     rows={this.props.lookupTaxa.rows}
  //                     onPage={this.lookupTaxaOnPage}
  //                     onFilter={this.lookupTaxaOnFilter}
  //                     onSort={this.lookupTaxaOnSort}
  //                 />
  //             </div>
  //         </div>
  //     );
  // };

  // LOOKUP TAXA
  lookupTaxaOnPage = (e) => {
    this.props.lookupTaxaPaginate(
      {
        max: e.rows,
        index: e.first,
      },
      this.resolveOidUnidade()
    )
  }

  lookupTaxaOnSort = (e) => {
    this.props.lookupTaxaSortOrder(
      {
        sortOrder: e.sortOrder,
        sortField: e.sortField,
      },
      this.resolveOidUnidade()
    )
  }
  lookupTaxaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter
        // é necessário este tratamento devido a taxa ter um grupo financeiro.
        if (key === 'grupoFinanceiro.value') {
          filter = {
            campo: 'descricao',
            valor: value,
            tipoFiltro: 'CONTEM',
            relacionamento: ['grupoFinanceiro'],
          }
        } else {
          filter = {
            campo: key,
            valor: value,
            tipoFiltro: 'CONTEM',
          }
        }
        this.props.lookupTaxaApplyFilter(filter, this.resolveOidUnidade())
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupTaxaLoad(this.resolveOidUnidade())
    }
  }

  resolveOidUnidade = () => {
    return this.state.filtroUnidade && parseInt(this.state.filtroUnidade.id)
      ? parseInt(this.state.filtroUnidade.id)
      : null
  }

  lookupTaxaOnClick = (e) => {
    this.props.lookupTaxaLoad(this.resolveOidUnidade())
    this.props.lookupTaxaSetVisible(true)
  }
  lookupTaxaOnHide = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnConfirm = (e) => {
    const taxa = e.selection
    const value = utils.formatTaxaValueForLookup(taxa)
    if (taxa) {
      this.state.filtroTaxa.taxa = { id: taxa.oid, value: value }
    } else {
      this.state.filtroTaxa.taxa = null
    }
    this.props.lookupTaxaSetVisible(false)
  }

  lookupTaxaOnCancel = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnEdit = () => {
    if (this.props.record.taxa && this.props.record.taxa.id) {
      return `${routes.PATH_TAXA_FINANCEIRO}/${this.props.record.taxa.id}`
    } else {
      return `${routes.PATH_TAXA_FINANCEIRO}`
    }
  }
  lookupTaxaOnComplete = (e) => {}

  lookupTaxaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.state.filtroTaxa.taxa = { id: null, value: e.value }
      this.props.clearRecords()
    } else {
      this.state.filtroTaxa.taxa = { id: e.value.oid, value: e.value.grupoFinanceiro_descricao }
      const filters = [{}]
      this.props.lookupTaxaApplyFilter(filters, this.resolveOidUnidade())
    }
  }
  lookupTaxaOnKeyPress = (e) => {
    if (
      utils.nonNull(this.state.filtroTaxa) &&
      utils.nonNull(this.state.filtroTaxa.taxa) &&
      utils.nonNull(this.state.filtroTaxa.taxa.value)
    ) {
      const filtroTaxa = new Filtro('descricao', this.state.filtroTaxa.taxa.value, 'CONTEM', ['grupoFinanceiro'])
      this.props.lookupTaxaApplyFilter(filtroTaxa, this.resolveOidUnidade())
    }
  }
  taxaDropDownTemplate = (taxa) => {
    return taxa.grupoFinanceiro_descricao
  }
  // ---------------

  lookupCfTaxaOnPage = (e) => {
    this.props.lookupCfTaxaPaginate(
      {
        max: e.rows,
        index: e.first,
      },
      this.resolveOidUnidade()
    )
  }

  lookupCfTaxaOnSort = (e) => {
    this.props.lookupCfTaxaSortOrder(
      {
        sortOrder: e.sortOrder,
        sortField: e.sortField,
      },
      this.resolveOidUnidade()
    )
  }

  lookupCfTaxaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter
        // é necessário este tratamento devido a taxa ter um grupo financeiro.
        if (key === 'grupoFinanceiro.value') {
          filter = {
            campo: 'descricao',
            valor: value,
            tipoFiltro: 'CONTEM',
            relacionamento: ['grupoFinanceiro'],
          }
        } else {
          filter = {
            campo: key,
            valor: value,
            tipoFiltro: 'CONTEM',
          }
        }
        this.props.lookupCfTaxaApplyFilter(filter, this.resolveOidUnidade())
        isFilterApplied = true
      }
    })

    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupCfTaxaLoad(this.resolveOidUnidade())
    }
  }

  lookupCfTaxaOnClick = (e) => {
    this.props.lookupCfTaxaLoad(this.resolveOidUnidade())
    this.props.lookupCfTaxaSetVisible(true)
  }

  lookupCfTaxaOnHide = (e) => {
    this.props.lookupCfTaxaSetVisible(false)
  }

  lookupCfTaxaOnConfirm = (e) => {
    const taxaSelecionada = e.selection
    this.addTaxa(taxaSelecionada)
    this.props.lookupCfTaxaSetVisible(false)
  }

  isTaxaConservacaoJaSelecionada = (taxaSelecionada) => {
    console.log()
    const units = _.get(this, 'props.units', null)
    const isTaxaConservacao = utils.isTaxaConservacao(
      units,
      this.state.filtroUnidade.id,
      taxaSelecionada.grupoFinanceiro_oid
    )
    if (isTaxaConservacao) {
      const taxas = this.props.record.taxas
      if (taxas && taxas.length > 0) {
        for (const t of taxas) {
          const isTaxaConservacaoJaSelecionada = utils.isTaxaConservacao(units, this.state.filtroUnidade.id, t.oidGrupo)
          if (isTaxaConservacaoJaSelecionada) {
            // this.props.showMessageTaxaConservacaoJaSelecionada();
            return true
          }
        }
      }
    }
    return false
  }

  lookupTaxaOnCancel = (e) => {
    this.props.lookupCfTaxaSetVisible(false)
  }

  lookupCfTaxaOnEdit = () => {
    if (this.props.record.taxa && this.props.record.taxa.id) {
      return `${routes.PATH_TAXA_FINANCEIRO}/${this.props.record.taxa.id}`
    } else {
      return `${routes.PATH_TAXA_FINANCEIRO}`
    }
  }
  lookupCfTaxaOnComplete = (e) => {}

  lookupCfTaxaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('taxa.id', null)
      this.props.setField('taxa.value', e.value)
    } else {
      const taxaSelecionada = e.value
      this.addTaxa(taxaSelecionada)
      const filters = [{}]
      this.props.lookupCfTaxaApplyFilter(filters, this.resolveOidUnidade())
    }
  }

  addTaxa = (taxaSelecionada) => {
    // procura se a Taxa selecionada já está na datatable
    let taxaExistente = utils.findObjectInArray(this.props.record.taxas, 'id', taxaSelecionada.oid)
    if (taxaExistente === null) {
      taxaExistente = this.isTaxaConservacaoJaSelecionada(taxaSelecionada)
    }
    if (taxaExistente === null || !taxaExistente) {
      let taxasAtuais = this.props.record.taxas
      let taxa = {
        id: taxaSelecionada.oid,
        value: taxaSelecionada.grupoFinanceiro_descricao,
        valor: taxaSelecionada.valor,
        tipo: taxaSelecionada.grupoFinanceiro_tipo,
        oidGrupo: taxaSelecionada.grupoFinanceiro_oid,
      }
      taxasAtuais.push(taxa)
      this.props.setField('taxas', taxasAtuais)
      this.props.setField('taxa', '')
      const habilitar = this.setFlagHabilitarCamposConspaga(taxasAtuais)
      this.updateConspagaPagarAte(habilitar)
    }
  }

  updateConspagaPagarAte = (habilitarValor) => {
    if (habilitarValor) {
      this.props.setField('conspagaPagarAte', window.moment().year())
    } else {
      this.props.setField('conspagaPagarAte', '')
    }
  }
  lookupTaxaCfOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.taxa) && utils.nonNull(this.props.record.taxa.value)) {
      const filtroTaxa = new Filtro('descricao', this.props.record.taxa.value, 'CONTEM', ['grupoFinanceiro'])
      this.props.lookupCfTaxaApplyFilter(filtroTaxa, this.resolveOidUnidade())
    }
  }

  // REFERENTE AO LOOKUP PARA CONFIGURACAO DE BOLETO
  lookupConfiguracaoBoletoOnPage = (e) => {
    this.props.lookupConfiguracaoBoletoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupConfiguracaoBoletoOnSort = (e) => {
    this.props.lookupConfiguracaoBoletoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupConfiguracaoBoletoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        const filter = new Filtro(key, value, 'CONTEM')
        this.props.lookupConfiguracaoBoletoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupConfiguracaoBoletoLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupConfiguracaoBoletoLoad()
    }
  }

  lookupConfiguracaoBoletoOnClick = (e) => {
    this.props.lookupConfiguracaoBoletoLoad()
    this.props.lookupConfiguracaoBoletoSetVisible(true)
  }
  lookupConfiguracaoBoletoOnHide = (e) => {
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnConfirm = (e) => {
    this.props.setField('configuracaoBoleto', { value: e.selection.nome, id: e.selection.oid })
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnCancel = (e) => {
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnEdit = () => {
    if (this.props.record.configuracaoBoleto && this.props.record.configuracaoBoleto.id) {
      return `${routes.PATH_CONFIGURACAO_BOLETO}/${this.props.record.configuracaoBoleto.id}`
    } else {
      return `${routes.PATH_CONFIGURACAO_BOLETO}`
    }
  }
  lookupConfiguracaoBoletoOnComplete = (e) => {}

  lookupConfiguracaoBoletoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let grupo = { value: e.value, id: null }
      this.props.setField('configuracaoBoleto', grupo)
    } else {
      this.props.setField('configuracaoBoleto', { value: value.descricao, id: value.oid })
      const filtroNome = new Filtro('nome', '', 'CONTEM')
      this.props.lookupConfiguracaoBoletoApplyFilter(filtroNome)
    }
  }

  lookupConfiguracaoBoletoOnKeyPress = (e) => {
    if (
      utils.nonNull(this.props.record.configuracaoBoleto) &&
      utils.nonNull(this.props.record.configuracaoBoleto.value)
    ) {
      const filtroTaxa = new Filtro('nome', this.props.record.configuracaoBoleto.value, 'CONTEM')
      this.props.lookupConfiguracaoBoletoApplyFilter(filtroTaxa)
    }
  }

  changeFiltroSelecionarTodosRegistros = () => {
    let result = !this.state.selecionarTodos
    this.setState({ selecionarTodos: result })
    if (result) {
      this.props.setSelectedRecords(this.props.records)
    } else {
      this.props.setSelectedRecords([])
    }
  }

  // buildFiltroStatusPagamento = () => {
  //     return (
  //         <div className="p-grid form-group p-align-center">
  //             <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
  //                 <label className="label">
  //                     {utils.getMessage(this.props.messages, "controlefinanceiro.label.statuspagamento")}
  //                 </label>
  //             </div>
  //             <div className="p-col-12 p-sm-12 p-md-1 p-lg-2 p-xl-10">
  //                 <Dropdown
  //                     autoWidth={false}
  //                     value={this.state.filtroStatusLancamento}
  //                     options={enums.ArrayStatusLancamento}
  //                     onChange={e => (this.state.filtroStatusLancamento = e.value)}
  //                     optionLabel="value"
  //                     dataKey="value"
  //                     name="statusLancamento"
  //                 />
  //             </div>
  //         </div>
  //     );
  // };

  buildFiltroSomenteContrato = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
          <label className="label">
            {utils.getMessage(this.props.messages, 'controlefinanceiro.label.somentecontrato')}
          </label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Checkbox
            style={{ marginLeft: '5px' }}
            checked={this.state.filtroSomenteContrato}
            onChange={(e) => this.setState({ filtroSomenteContrato: !this.state.filtroSomenteContrato })}
          />
        </div>
      </div>
    )
  }

  buildFiltroConspaga = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
          <label className="label">{utils.getMessage(this.props.messages, 'controlefinanceiro.label.conspaga')}</label>
        </div>
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          <Dropdown
            autoWidth={false}
            value={this.state.filtroConspaga.tipoFiltro}
            options={this.props.tiposFiltros}
            onChange={(e) => this.changeTipoFiltroConspaga(e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipo"
          />
        </div>

        {this.state.filtroConspaga.mostrarAnoInicial ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <InputNumber
              name="conspagaInicial"
              value={this.state.filtroConspaga.anoInicial}
              onChange={(e) => (this.state.filtroConspaga.anoInicial = e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}

        {this.state.filtroConspaga.mostrarAnoFinal ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3">
            <InputNumber
              name="conspagaFinal"
              value={this.state.filtroConspaga.anoFinal}
              onChange={(e) => (this.state.filtroConspaga.anoFinal = e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  changeTipoFiltroConspaga = (valor) => {
    let filtroAtual = this.state.filtroConspaga
    filtroAtual.tipoFiltro = valor
    filtroAtual.mostrarAnoInicial = valor.dataInicial
    filtroAtual.mostrarAnoFinal = valor.dataFinal
    this.setState({ filtroConspaga: filtroAtual })
  }

  changeTipoFiltroDiaVencimento = (valor) => {
    let filtroAtual = this.state.filtroDiaVencimento
    filtroAtual.tipoFiltro = valor
    filtroAtual.diaVencimentoInicial = null
    filtroAtual.diaVencimentoFinal = null
    filtroAtual.mostrarDiaVencimentoInicial = valor.dataInicial
    filtroAtual.mostrarDiaVencimentoFinal = valor.dataFinal
    this.setState({ filtroDiaVencimento: filtroAtual })
  }

  changeTipoFiltro = (filtro, valor) => {
    let filtroAtual = filtro
    filtroAtual.tipoFiltro = valor
    filtroAtual.dataFinal = null
    filtroAtual.dataInicial = null
    filtroAtual.mostrarDataInicial = valor.dataInicial
    filtroAtual.mostrarDataFinal = valor.dataFinal
  }

  changeFiltroTipoCadastro = (valor) => {
    let filtroAtual = this.state.filtroTipoSepultura
    filtroAtual.tipoFiltro = valor
    filtroAtual.mostrarFiltro = valor.dataInicial
    this.setState({ filtroTipoSepultura: filtroAtual })
  }

  changeFiltroTipoFormaPagamento = (valor) => {
    let filtroAtual = this.state.filtroTipoFormaPagamento
    filtroAtual.tipoFiltro = valor
    filtroAtual.mostrarFiltro = valor
    this.setState({ filtroTipoFormaPagamento: filtroAtual })
  }

  changeFiltroFrequenciaFaturamento = (valor) => {
    let filtroAtual = this.state.filtroFrequenciaFaturamento
    filtroAtual.tipoFiltro = valor
    filtroAtual.mostrarFiltro = valor
    this.setState({ filtroFrequenciaFaturamento: filtroAtual })
  }

  changeTipoFiltroDataProximaRemessaCompetencia = (valor) => {
    let filtro = this.state.filtroDataProximaRemessaCompetencia
    filtro.tipoFiltro = valor
    filtro.mostrarDataInicial = valor.dataInicial
    filtro.mostrarDataFinal = valor.dataFinal
    this.setState({ filtroDataProximaRemessaCompetencia: filtro })
  }

  changeDataFiltro = (filtro, campo, valor) => {
    let filtroAtual = filtro
    filtroAtual[campo] = valor
  }

  changeUnidade = (value) => {
    this.setState({ filtroUnidade: value })
    if (value && utils.nonNull(value)) {
      this.props.loadTiposSepulturas(value.id)
    }
  }

  isBoletoOuDefinir = (value) => {
    return !value || value.id === 5 || value.id === 99 // 5 = Boleto | 99 = A definir
  }

  changeFormaPagamento = (value) => {
    this.props.setField('tipoFormaPagamento', value)
    const isBoletoOuDefinir = this.isBoletoOuDefinir(value)
    if (!isBoletoOuDefinir) {
      this.props.setField('gerarBoletosIndividual', false)
      this.props.setField('taxas', [])
      this.props.setField('taxa', '')
    }
  }

  pesquisaAvancada = () => {
    this.showCustomFilters = false
    this.showFilter = false
    this.props.pesquisaAvancada(this.state)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  showOpcoesBoletosLancamentos = () => {
    this.showCustomFilters = !this.showCustomFilters
  }

  gerarLancamentos = () => {
    const filtros = this.generateFilters()
    this.props.gerarLancamentos(filtros, this.state.filtroUnidade)
    this.showCustomFilters = false
  }

  gerarBoletos = () => {
    const filtros = this.generateFilters()
    this.props.gerarBoletos(filtros, this.state.filtroUnidade)
    this.showCustomFilters = false
  }

  generateFilters = () => {
    const filtroContratos = this.state.tipoFiltro === 1
    const filtros = {
      filtroContratos,
      selecionarTodos: this.state.selecionarTodos,
      filtrosPesquisa: this.props.selectedFilters,
    }
    return filtros
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button
          disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}
          onClick={(e) => this.showOpcoesBoletosLancamentos()}
          label={utils.getMessage(this.props.messages, 'controlefinanceiro.label.boletoslancamentos')}
          icon={icons.PLUS_ICON}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
      </div>
    )
  }

  formatColumnBoleto = (rowData, column) => {
    if (column.field === 'oidBoleto' && utils.nonNull(rowData[column.field])) {
      return <Button type="button" onClick={(e) => this.props.gerarBoletoLancamento(rowData)} icon={icons.DOWNLOAD} />
    }
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'controlefinanceiro.label.controlefinanceiro')}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'numeroContrato' || col.field === 'responsavel') {
        const width = col.field === 'numeroContrato' ? '7em' : '25em'
        return (
          <Column
            style={{ width: width }}
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      if (col.field === 'conspaga' || col.field === 'localizacao') {
        return (
          <Column
            style={{ width: '8em' }}
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      // if (col.field !== "oidLancamentoFinanceiro" && col.field !== "oidResponsavel" && col.field !== "oidUnidade") {
      //     return (
      //         <Column
      //             {...col}
      //             key={col.field}
      //             sortField={col.columnSortField}
      //             filterElement={this.fieldFilter(col)}
      //         />
      //     );
      // }
    })
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'numeroContrato' && utils.nonNull(rowData.oidContrato)) {
      return <Link to={`${routes.PATH_CONTRATO}/${rowData.oidContrato}`}>{rowData.numeroContrato}</Link>
    } else if (column.field === 'responsavel' && utils.nonNull(rowData.oidResponsavel)) {
      return <Link to={`${routes.PATH_PESSOA_FISICA}/${rowData.oidResponsavel}`}>{rowData.responsavel}</Link>
    } else if (column.field !== 'responsavel' && column.field !== 'oidContrato') {
      return utils.format(rowData, column)
    }
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildContent = () => {
    return (
      <ControleFinanceiroList
        {...this.props}
        state={this.state}
        changeFiltroSelecionarTodosRegistros={this.changeFiltroSelecionarTodosRegistros}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onSelectionChange={this.onSelectionChange}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ControleFinanceiroListPageContainer)
