import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/relatorios";
const crud = build(validate, BASE_PATH);
const prefix = "crudConsultaBaixaRelatorio";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.abrirRelatorio = (dataIni, dataFin, unidade) => {
  let params = {
    dataInicial: dataIni,
    dataFinal: dataFin,
    unidades: unidade
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/consultabaixa`;
    return apiService.post(path, params).then(response => {
      const win = window.open(response.data);
      if (win) {
        win.focus();
      }
    });
  };
};

export { types, actions };
