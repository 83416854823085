import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../itenslancamento.actions'
import { ItensLancamentoForm } from './ItensLancamentoForm'
import utils from '../../../utils/index'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudItensLancamentoState.messages
  const record = state.crudItensLancamentoState.currentRecord
  const currentUser = state.appState.currentUser

  // REFERENTE A COLOCAR A UNIDADE CORRENTE NO CAMPO
  if (state.appState.currentUnit) {
    let unidade = {
      id: state.appState.currentUnit.oid,
      value: state.appState.currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
  }

  return {
    currentUser,
    record,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),

    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'unidade') {
        dispatch(actions.setField('oidUnidade', valor.oidUnidade))
        dispatch(actions.setField('descricaoUnidade', valor.name))
      } else if (field === 'valor') {
        valor = valor.replace('R$', '')
        if (typeof valor === 'string' && valor.length > 0) {
          valor = valor.trim()
        }
        dispatch(actions.setField(field, valor))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },

    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => {
      dispatch(actions.loadById(oid))
    },
  }
}

class ItensLancamentoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showDialogRemove = false

    actions.beforeFetchRecordSuccess = (data) => {
      if (data && data.valor) {
        data.valor = utils.formatCurrency(data.valor)
      }
      return data
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}`)
    }
  }
  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  componentDidUpdate(anteriorProps) {
    if (anteriorProps.record.oidUnidade === '' && this.props.record.unidade) {
      Object.assign(this.props.record, { oidUnidade: this.props.record.unidade.oid })
      Object.assign(this.props.record, { descricaoUnidade: this.props.record.unidade.descricao })
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}`)
  }

  onBlurValor = (e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    this.props.setField(field, utils.formatCurrency(valor.replace(',', '.').replace('R$', '')))
  }

  hideDialog = () => {
    this.showDialogRemove = false
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <ItensLancamentoForm
        currentUser={this.props.currentUser}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        onBlurValor={this.onBlurValor}
        record={this.props.record}
      />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItensLancamentoFormPageContainer)
