import React from 'react';

import ReligiaoFormPageContainer from './ReligiaoFormPageContainer';

export class ReligiaoFormPage extends React.Component{

    
    render(){
        return (
           <ReligiaoFormPageContainer {...this.props} />
        )
    }
}
