import React from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Lookup } from './Lookup'
import './LookupField.scss'
import _ from 'lodash'
import { icons } from '../../constants'

export class LookupField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clearInputField: true,
      link: '',
    }
  }
  onBlurInputFilter = (e) => {}

  onSelectSuggestionInputFilter = (e) => {}

  executeClick = (e, props) => {
    const customEditFn_ = _.get(this, 'props.customEditFn', null)
    if (customEditFn_) {
      customEditFn_(e)
    } else {
      const url = props.onEditField()
      //if (e.ctrlKey || e.metaKey || isMobile()) {
      //abre em uma nova aba
      window.open('/phoenix' + url)
      //} else {
      //abre na mesma aba
      //props.history.push(url)
      //}
    }
  }

  onClickAddButton = () => {
    const onClickAddButton_ = _.get(this, 'props.onClickAddButton', null)
    if (onClickAddButton_) {
      onClickAddButton_()
    }
  }

  onKeyPress = (e) => {
    // para funcionar em qualqur browser
    if (e.keyCode === 13 || (e.which === 13 && this.props.onKeyPress)) {
      this.props.onKeyPress(e)
    }
  }

  resolveEditButton = () => {
    if (this.props.editHidden) {
      return null
    }
    return (
      <Button
        icon="pi pi-pencil"
        className="p-link"
        onClick={(e) => this.executeClick(e, this.props)}
        style={{ backgroundColor: '#7CB342', marginLeft: '3px' }}
        disabled={this.props.editDisabled || this.props.disabled}
        hidden={this.props.editHidden}
      />
    )
  }

  resolveAddButton = () => {
    const showAddButton = _.get(this, 'props.showAddButton', false)
    if (showAddButton) {
      return (
        <Button
          icon={icons.PLUS_ICON}
          className="p-link"
          onClick={(e) => this.onClickAddButton()}
          style={{ backgroundColor: '#7CB342', marginLeft: '3px' }}
          disabled={this.props.disabled}
        />
      )
    }
    return null
  }

  render() {
    const props = this.props
    return (
      <React.Fragment>
        <div className="p-inputgroup lookup-field-complete" style={{ width: '100%', display: 'flex' }}>
          <span className="p-float-label">
            <AutoComplete
              style={{ flexGrow: '1' }}
              completeMethod={props.noSuggestions ? null : props.onComplete}
              itemTemplate={props.noSuggestions ? null : props.itemTemplate}
              onChange={props.onInputFilter}
              onSelect={this.onSelectSuggestionInputFilter}
              onBlur={this.onBlurInputFilter}
              suggestions={props.noSuggestions ? null : props.records}
              placeholder={props.placeholder}
              value={props.value}
              name={props.name}
              field={props.field}
              onKeyUp={this.onKeyPress}
              disabled={props.disabled}
              multiple={props.multiple}
            />
            {props.label ? <label>{props.label}</label> : null}
          </span>
          <Button
            icon="pi pi-search"
            style={{ color: '#fff' }}
            onClick={props.onClick}
            disabled={props.searchDisabled || props.disabled}
          />
          {this.resolveEditButton()}
          {this.resolveAddButton()}
        </div>

        <Lookup
          unidade={props.unidade}
          unidades={props.unidades}
          showFiltroUnidade={props.showFiltroUnidade}
          showFiltroTemporaria={props.showFiltroTemporaria}
          disabled={props.disabled}
          visible={props.visible}
          appendTo={props.appendTo ? props.appendTo : null}
          modal={props.modal}
          header={props.header}
          onHide={props.onHide}
          onConfirm={props.onConfirm}
          onCancel={props.onCancel}
          records={props.records}
          columns={props.columns}
          totalRecords={props.totalRecords}
          sortOrder={props.order}
          sortField={props.sortField}
          first={props.first}
          rows={props.rows}
          onPage={props.onPage}
          onFilter={props.onFilter}
          onSort={props.onSort}
          onChangeUnit={props.onChangeUnit}
          onChangeTemporaria={props.onChangeTemporaria}
          apenasTemporaria={props.apenasTemporaria}
          multiple={props.multiple}
          dialogWidth={props.dialogWidth}
          selection={props.multiple ? props.value : null}
        />
      </React.Fragment>
    )
  }
}
