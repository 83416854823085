import React from "react";
import { Checkbox } from "primereact/checkbox";
import { LookupField } from "../../../components/lookup/LookupField";
import { getMessage } from "../../../utils/index";

export const PermissaoForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;
  const messages = props.messages;
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {getMessage(messages, "permissao.label.permissao")} {record.oid || ""}
          </h3>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
              <LookupField
               label={getMessage(messages, "permissao.label.Menu")}
                value={record.menu ? record.menu.value : ""}
                name="nomeMenu"
                field="nome"
                history={props.history}
                onKeyPress={props.lookupMenuOnKeyPress}
                onComplete={props.lookupMenuOnComplete}
                onInputFilter={props.lookupMenuOnInputFilter}
                editDisabled={props.lookupMenu.editDisabled}
                onEditField={props.lookupMenuOnEdit}
                onClick={props.lookupMenuOnClick}
                visible={props.lookupMenu.visible}
                modal={props.lookupMenu.modal}
                header={props.lookupMenu.header}
                onHide={props.lookupMenuOnHide}
                onConfirm={props.lookupMenuOnConfirm}
                onCancel={props.lookupMenuOnCancel}
                records={props.lookupMenu.records}
                columns={props.lookupMenu.columns}
                totalRecords={props.lookupMenu.totalRecords}
                sortOrder={props.lookupMenu.order}
                sortField={props.lookupMenu.sortField}
                first={props.lookupMenu.first}
                rows={props.lookupMenu.rows}
                onPage={props.lookupMenuOnPage}
                onFilter={props.lookupMenuOnFilter}
                onSort={props.lookupMenuOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
              <LookupField
                label={getMessage(messages, "permissao.label.perfil")}
                value={record.perfil ? record.perfil.value : ""}
                name="nomePerfil"
                field="nomePerfil"
                onKeyPress={props.lookupPerfilOnKeyPress}
                onComplete={props.lookupPerfilOnComplete}
                onInputFilter={props.lookupPerfilOnInputFilter}
                editDisabled={props.lookupPerfil.editDisabled}
                onEditField={props.lookupPerfilOnEdit}
                onClick={props.lookupPerfilOnClick}
                visible={props.lookupPerfil.visible}
                modal={props.lookupPerfil.modal}
                header={props.lookupPerfil.header}
                onHide={props.lookupPerfilOnHide}
                onConfirm={props.lookupPerfilOnConfirm}
                onCancel={props.lookupPerfilOnCancel}
                records={props.lookupPerfil.records}
                columns={props.lookupPerfil.columns}
                totalRecords={props.lookupPerfil.totalRecords}
                sortOrder={props.lookupPerfil.order}
                sortField={props.lookupPerfil.sortField}
                first={props.lookupPerfil.first}
                rows={props.lookupPerfil.rows}
                onPage={props.lookupPerfilOnPage}
                onFilter={props.lookupPerfilOnFilter}
                onSort={props.lookupPerfilOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chklistar" checked={record.listar} onChange={changeHandler} name="listar" style={{marginRight: "5px"}}/>
              <label htmlFor="chklistar" className="label">
                {getMessage(messages, "permissao.label.listar")}
              </label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chkinserir" checked={record.inserir} onChange={changeHandler} name="inserir" style={{marginRight: "5px"}}/>
              <label htmlFor="chkinserir" className="label">
                {getMessage(messages, "permissao.label.inserir")}
              </label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chkalterar" checked={record.alterar} onChange={changeHandler} name="alterar" style={{marginRight: "5px"}}/>
              <label htmlFor="chkalterar" className="label">
                {getMessage(messages, "permissao.label.alterar")}
              </label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chkexcluir" checked={record.excluir} onChange={changeHandler} name="excluir" style={{marginRight: "5px"}}/>
              <label htmlFor="chkexcluir" className="label">
                {getMessage(messages, "permissao.label.remover")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
