import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
const BASE_PATH = '/lottustech/usuarios'
const prefix = 'crudUsuario'
const crud = build(validate, BASE_PATH)

const newTypes = {
  SET_LOOKUP_PESSOA_VISIBLE: `${prefix}setLookupPessoaVisible`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  PICKLIST_PERFIS_MOVE_TO_TARGET: `${prefix}pickListPerfisMoveToTarget`,
  PICKLIST_PERFIS_MOVE_TO_SOURCE: `${prefix}pickListPerfisMoveToSource`,
  PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE: `${prefix}pickListPerfisMoveAllToSource`,
  PICKLIST_PERFIS_MOVE_ALL_TO_TARGET: `${prefix}pickListPerfisMoveAllToTarget`,
  PICKLIST_UNIDADES_MOVE_TO_TARGET: `${prefix}pickListUnidadesMoveToTarget`,
  PICKLIST_UNIDADES_MOVE_TO_SOURCE: `${prefix}pickListUnidadesMoveToSource`,
  PICKLIST_UNIDADES_MOVE_ALL_TO_SOURCE: `${prefix}pickListUnidadesMoveAllToSource`,
  PICKLIST_UNIDADES_MOVE_ALL_TO_TARGET: `${prefix}pickListUnidadesMoveAllToTarget`,
  SET_UNIDADE: `${prefix}setUnidade`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)

const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.setLookupPessoaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_PESSOA_VISIBLE,
    visible,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.pickListPerfisMoveToSource = (perfisAcaoRemoved) => {
  return {
    type: types.PICKLIST_PERFIS_MOVE_TO_SOURCE,
    perfisAcaoRemoved,
  }
}

actions.pickListPerfisMoveAllToSource = (perfisAcaoRemoved) => {
  return {
    type: types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE,
  }
}

actions.pickListPerfisMoveToTarget = (perfisAdd, oidAcao) => {
  return {
    type: types.PICKLIST_PERFIS_MOVE_TO_TARGET,
    perfisAdd,
    oidAcao,
  }
}

actions.pickListPerfisMoveAllToTarget = (allPerfis, oidAcao) => {
  return actions.pickListPerfisMoveToTarget(allPerfis, oidAcao)
}

actions.pickListUnidadesMoveToSource = (payload) => {
  return {
    type: types.PICKLIST_UNIDADES_MOVE_TO_SOURCE,
    payload,
  }
}

actions.pickListUnidadesMoveAllToSource = () => {
  return {
    type: types.PICKLIST_UNIDADES_MOVE_ALL_TO_SOURCE,
  }
}

actions.pickListUnidadesMoveToTarget = (unidadesAdd, oidUsuario) => {
  return {
    type: types.PICKLIST_UNIDADES_MOVE_TO_TARGET,
    unidadesAdd,
    oidUsuario,
  }
}

actions.pickListUnidadesMoveAllToTarget = (allUnidades, oidAcao) => {
  return actions.pickListUnidadesMoveToTarget(allUnidades, oidAcao)
}

actions.setUnidade = (payload) => {
  return {
    type: types.SET_UNIDADE,
    payload,
  }
}

export { types, actions }
