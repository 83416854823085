import { types } from "./mensageria.actions";
import { Reducer } from "../../services/reducer.factory";
import utils from "../../utils";
import storage from "../../services/stoage.service";

const initialState = {
  id: "mensageria",
  columns: [],
  currentRecord: {
    tipoMensagem: 2,
    tiposPessoas: [{ id: 3, label: "1º Responsável" }],
    tiposSepulturas: [],
    opcoesDestinatariosLookup: [],
    opcoesTiposSepulturas: [],
    destinatarios: [
      {
        oid: new Date().getTime(),
        manual: true
      }
    ],
    anexos: [],
    destinatariosString: ""
  },
  colunasTPessoa: [
    {
      field: "contato",
      header: "Contato",
      columnKey: "contato"
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome"
    },
    {
      field: "oidContrato",
      header: "Contrato",
      columnKey: "oidContrato"
    },
    {
      field: "acaoRemocao"
    }
  ],
  columnsLookupDestinatario: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: true,
      columnSortField: "oid",
      filter: true,
      visible: false
    },
    {
      field: "numeroDocumento",
      header: "CPF",
      columnKey: "numeroDocumento",
      sortable: true,
      columnSortField: "numeroDocumento",
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      columnSortField: "nome",
      filter: true
    },
    {
      field: "email",
      header: "E-mail",
      columnKey: "email",
      sortable: true,
      columnSortField: "email",
      filter: true
    },
    {
      field: "telefone",
      header: "Telefone",
      columnKey: "telefone",
      sortable: true,
      columnSortField: "telefone",
      filter: true
    }
  ],
  lookupDestinatario: {
    visible: false,
    modal: true,
    header: "Destinatário"
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false
};

const reducer = new Reducer(initialState, types);

export function CRUDMensageriaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("mensageria");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_DESTINATARIO_VISIBLE:
      return setLookupDestinatarioVisible(state, action);
    case types.SET_DESTINATARIO:
      return setDestinatario(state, action);
    case types.ADD_ANEXO:
      return addAnexo(state, action);
    case types.REMOVE_ANEXO:
      return removeAnexo(state, action);
    case types.ADICIONAR_NOME_DESTINATARIO:
      return adicionarNomeDestinatario(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function addAnexo(state, action) {
  let currentRecord = state.currentRecord;
  const anexo = action.payload;
  let novosAnexos = [];
  if (anexo) {
    novosAnexos.push(anexo);

    const anexos = currentRecord.anexos;
    if (utils.isArrayNotEmpty(anexos)) {
      anexos.forEach(a => {
        const temp = novosAnexos.find(f => f.filename === a.filename);
        if (!temp) {
          a.oidContainer = currentRecord.oid;
          novosAnexos.push(a);
        }
      });
    }
    currentRecord.anexos = novosAnexos;
    return Object.assign({}, state, { currentRecord: currentRecord });
  }

  return state;
}

function removeAnexo(state, action) {
  let currentRecord = state.currentRecord;
  const anexoRemover = action.payload;
  if (anexoRemover) {
    const anexos = currentRecord.anexos;
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find(f => f.filename === anexoRemover.filename);
      if (temp) {
        const index = anexos.indexOf(temp);
        anexos.splice(index, 1);
      }
    }
    return Object.assign({}, state, { currentRecord: currentRecord });
  }
  return state;
}

function setLookupDestinatarioVisible(state, action) {
  let lookupDestinatario = state.lookupDestinatario;
  lookupDestinatario = Object.assign({}, lookupDestinatario, { visible: action.visible });
  return Object.assign({}, state, { lookupDestinatario });
}

function adicionarNomeDestinatario(state, action) {
  let destinatario = Object.assign({}, state.destinatario);
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = destinatario;
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current;
      current = current[fields[index]];
      if (!current) {
        current = {};
        owner[fields[index]] = current;
      }
    }
  }
  current[lastField] = value;
  return Object.assign({}, state, { destinatario });
}

function setDestinatario(state, resp) {
  let destinatario = resp.value;
  let destinatarios = state.currentRecord.destinatarios;
  destinatarios.push(destinatario);
  Object.assign(state.currentRecord.destinatarios, destinatarios);
  return Object.assign({}, state);
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}

