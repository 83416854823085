import { types } from "./dinamica.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "viewdinamica",
  selectedFilters: [],
  showHideColumns: [],
  columns: [],
  currentRecord: {},
  messages: null,
  title: ""
};

const reducer = new Reducer(initialState, types);

export function CRUDDinamicaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("viewdinamica");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ADVANCED_FILTER:
      return setAdvancedFilter(state, action);
    case types.CLEAR_SORT_FIELDS:
      Object.assign(state, { sortField: "" });
      return Object.assign(state, { sortOrder: 0 });
    default:
      return result;
  }
}

function setAdvancedFilter(state, action) {
  const selectedFilters = state.selectedFilters;
  //pelo 'hash' pegamos o filtro alterado
  const arrFilter = selectedFilters.filter(e => e.hash === action.hash);
  if (arrFilter && arrFilter.length > 0) {
    let filter = arrFilter[0];
    const index = selectedFilters.indexOf(filter);
    // e atualizamos a propriedade alterada
    filter[action.field] = action.value;

    selectedFilters.splice(index, 1, filter); // remove o item antigo e adiciona o novo
  }
  return Object.assign({}, state, { selectedFilters: selectedFilters });
}
