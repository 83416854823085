import React from 'react'
import { getMessage } from '../../../utils/index'
import './DocumentoForm.scss'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import LtsInputText from '../../../components/lottus/lts-input-text'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import _ from 'lodash'
import apiIdValueService from '../../../services/api-id-value-service'

export const DocumentoForm = ({ record, changeHandler, messages, setField }) => (
  <div className="p-grid p-fluid">
    <div className="p-col-12">
      <div className="card card-w-title">
        <h3>
          {getMessage(messages, 'documento.label.documento')} {record.oid || ''}
        </h3>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
            <span className="p-float-label">
              <LtsInputText
                label={getMessage(messages, 'documento.label.nome')}
                value={record.nome}
                onChange={(e) => setField('nome', e)}
                name="nome"
              />
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-7 p-xl-6">
            <span className="p-float-label">
              <LtsTextArea
                label={getMessage(messages, 'documento.label.template')}
                value={record.template}
                onChange={(e) => setField('template', e)}
                name="template"
              />
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
            <span className="p-float-label">
              <LtsInputText
                label={getMessage(messages, 'documento.label.entidadeBase')}
                value={record.entidadeBase}
                onChange={(e) => setField('entidadeBase', e)}
                name="entidadeBase"
              />
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
            <span className="p-float-label">
              <LtsInputText
                label={getMessage(messages, 'documento.label.modeloBase')}
                value={record.nomeRelatorio}
                onChange={(e) => setField('nomeRelatorio', e)}
                name="modeloBase"
              />
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
            <LtsDropdownIdValue
              name={'tipoTela'}
              value={_.get(record, 'tipoTela', '')}
              onChange={(e) => setField('tipoTela', e)}
              label={getMessage(messages, 'documento.label.tipoTela')}
              style={{ width: '200px' }}
              loadDataFn={apiIdValueService.getTipoTela}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-7 p-xl-6">
            <span className="p-float-label">
              <LtsTextArea
                disabled={_.get(record, 'tipoTela.id', null) !== 999}
                label={getMessage(messages, 'documento.label.parametros')}
                value={record.parametros}
                onChange={(e) => setField('parametros', e)}
                name="parametros"
              />
            </span>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-7 p-xl-6">
            <span className="p-float-label">
              <LtsTextArea
                label={getMessage(messages, 'documento.label.query')}
                value={record.query}
                onChange={(e) => setField('query', e)}
                name="query"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
