import React from 'react';

import MensageriaFormPageContainer from './MensageriaFormPageContainer';

export class MensageriaFormPage extends React.Component{

    
    render(){
        return (
           <MensageriaFormPageContainer {...this.props} />
        )
    }
}
