import React from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { LookupField } from "../../../components/lookup/LookupField";
import { getMessage } from "../../../utils/index";

export const UnidadeForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;
  const messages = props.messages;
  let nomePessoa = "";
  if (record.pessoa.value) {
    nomePessoa = record.pessoa.value;
  }
  let nomeEmpresa = "";
  if (record.empresa.value) {
    nomeEmpresa = record.empresa.value;
  }
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {getMessage(messages, "unidade.label.unidade")} {record.oid || ""}
          </h3>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-6 p-md-4 p-lg-2 p-xl-2">
              <label className="label">{getMessage(messages, "unidade.label.sigla")}</label>
              <InputText value={record.sigla} onChange={changeHandler} name="sigla" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-6 p-md-4 p-lg-2 p-xl-2">
              <label className="label">{getMessage(messages, "unidade.label.codigo")}</label>
              <InputText value={record.codigo} onChange={changeHandler} name="codigo" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, "unidade.label.pessoa")}</label>
              <LookupField
                value={nomePessoa}
                name="nomePessoa"
                field="nome"
                history={props.history}
                onComplete={props.lookupPessoaOnComplete}
                onInputFilter={props.lookupPessoaOnInputFilter}
                editDisabled={props.lookupPessoa.editDisabled}
                onEditField={props.lookupPessoaOnEdit}
                onClick={props.lookupPessoaOnClick}
                visible={props.lookupPessoa.visible}
                modal={props.lookupPessoa.modal}
                header={props.lookupPessoa.header}
                onHide={props.lookupPessoaOnHide}
                onConfirm={props.lookupPessoaOnConfirm}
                onCancel={props.lookupPessoaOnCancel}
                records={props.lookupPessoa.records}
                columns={props.lookupPessoa.columns}
                totalRecords={props.lookupPessoa.totalRecords}
                sortOrder={props.lookupPessoa.order}
                sortField={props.lookupPessoa.sortField}
                first={props.lookupPessoa.first}
                rows={props.lookupPessoa.rows}
                onPage={props.lookupPessoaOnPage}
                onFilter={props.lookupPessoaOnFilter}
                onSort={props.lookupPessoaOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, "unidade.label.empresa")}</label>
              <LookupField
                value={nomeEmpresa}
                name="nomeEmpresa"
                field="nomeEmpresa"
                history={props.history}
                onComplete={props.lookupEmpresaOnComplete}
                onInputFilter={props.lookupEmpresaOnInputFilter}
                editDisabled={props.lookupEmpresa.editDisabled}
                onEditField={props.lookupEmpresaOnEdit}
                onClick={props.lookupEmpresaOnClick}
                visible={props.lookupEmpresa.visible}
                modal={props.lookupEmpresa.modal}
                header={props.lookupEmpresa.header}
                onHide={props.lookupEmpresaOnHide}
                onConfirm={props.lookupEmpresaOnConfirm}
                onCancel={props.lookupEmpresaOnCancel}
                records={props.lookupEmpresa.records}
                columns={props.lookupEmpresa.columns}
                totalRecords={props.lookupEmpresa.totalRecords}
                sortOrder={props.lookupEmpresa.order}
                sortField={props.lookupEmpresa.sortField}
                first={props.lookupEmpresa.first}
                rows={props.lookupEmpresa.rows}
                onPage={props.lookupEmpresaOnPage}
                onFilter={props.lookupEmpresaOnFilter}
                onSort={props.lookupEmpresaOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chkMatriz" checked={record.matriz} onChange={changeHandler} name="matriz" />
              <label htmlFor="chkMatriz" className="label">
                {getMessage(messages, "unidade.label.matriz")}
              </label>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-3">
              <Checkbox inputId="chkativo" checked={record.ativo} onChange={changeHandler} name="ativo" />
              <label htmlFor="chkativo" className="label">
                {getMessage(messages, "unidade.label.ativo")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
