import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import { messageActions } from "../../store/message/index";
import utils from "../../utils";
const BASE_PATH = "/lottustech/portalCliente";

const crud = build(validate, BASE_PATH);
const prefix = "crudPortalClienteFinanceiro";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);


actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.gerarBoletoLancamento = lancamento => {
  return (dispatch, getState) => {
    if (lancamento.oid) {
      apiService
        .get(`/lottustech/ailos/gerar/boleto/${lancamento.oid}`)
        .then(response => {
          const win = window.open(response.data);
          win.focus();
          dispatch(actions.loadFinanceiro());
        })
        .catch(err => {
          const messages = err.response.data;
          dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        });
    }
  };
};

actions.emitirBoletos = lancamentos => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/ailos/gerar/boletos/`, lancamentos)
      .then(response => {
        const result = response.data;
        if (utils.isArrayNotEmpty(result)) {
          result.forEach(f => {
            const win = window.open(f);
            win.focus();    
          });
          dispatch(actions.loadFinanceiro());
        }
      })
      .catch(err => {
        const messages = err.response.data;
        dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
      });
  };
};

actions.openUrl = url => {
  return (dispatch, getState) => {
    if (url) {
      const win = window.open(url);
      if (win) {
        win.focus();
      }
    }
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  } else if (type === "warn") {
    summary = "Aviso!";
    sticky = true;
  }
  return messages.map(message => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message
    };
  });
};

actions.loadFinanceiro = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudPortalClienteFinanceiroState`];
    actions.setDefaultColumns(state);
    let sortFields = [];
    if (state.sortField) {
      sortFields = [
        {
          fieldName: state.sortField,
          order: state.sortOrder > -1 ? `ASC` : `DESC`
        }
      ];
    }
    const stateColumns = state.columns || [];
    let columns = [];
    if (state.usaListDto) {
      columns = stateColumns.filter(col => col.visible).map(col => col.columnKey);
      columns.push("oid");
    } else {
      columns = state.showHideColumns.filter(col => col.value.visible).map(col => col.value.field.replace("_", "."));
    }

    const filters = utils.parseTipoFiltro(state.selectedFilters);
    const params = {
      index: state.index,
      max: state.max,
      filters: filters,
      projectionFields: columns,
      sortFields,
      oidMenu: null
    };
    apiService.post(`${BASE_PATH}/financeiro`, params).then(response => {
      dispatch(actions.fetchRecordsSuccess(response.data));
    });
  };
}

export { types, actions };
