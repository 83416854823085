import React from 'react';

import DREFormPageContainer from './DREFormPageContainer';

export class DREFormPage extends React.Component{


    render(){
        return (
           <DREFormPageContainer {...this.props} />
        )
    }
}
