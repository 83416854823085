import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { routes } from '../../../constants'
import storage from '../../../services/stoage.service'
import { actions } from '../teladinamica.actions'
import { TelaDinamicaForm } from './TelaDinamicaForm'
import { Button } from 'primereact/button'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import _ from 'lodash'

const mapStateToProps = (state) => {
  const messages = state.crudTelaDinamicaState.messages
  const record = state.crudTelaDinamicaState.currentRecord
  const documento = state.crudTelaDinamicaState.documento

  return {
    messages,
    record,
    documento,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    loadDocumento: (oidDocumento) => dispatch(actions.loadDocumentoByOidMenu(oidDocumento)),
    executarRelatorio: ({ params, oidDocumento }) => dispatch(actions.executarRelatorio({ params, oidDocumento })),
    reset: () => dispatch(actions.reset()),
  }
}

class TelaDinamicaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showPesquisarButton = false
    this.showCSVButton = false
    this.showColumnButton = false
    this.hideBtnImportacao = true
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_TELA_DINAMICA_RELATORIO}`)
    }
    actions.afterLoadFavorito = (data) => {
      if (data && data.oid) {
        this.setState({ favorito: 1 })
        this.setState({ dadosFavorito: data })
      } else {
        this.setState({ favorito: 0 })
        this.setState({ dadosFavorito: null })
      }
    }
  }

  componentDidMount() {
    const oidCurrentMenu = storage.getOidCurrentMenu()
    if (oidCurrentMenu) {
      this.props.loadDocumento(oidCurrentMenu)
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  executarRelatorio = () => {
    const params = {}
    const record = _.get(this.props, 'record', {})
    for (const [key, value] of Object.entries(record)) {
      if (_.startsWith(key, 'dt_')) {
        params[key] = value
      } else if (_.startsWith(key, 'oid_')) {
        params[key] = value.map((v) => v.id)
      }
    }
    const oidDocumento = _.get(this.props, 'documento.oid', null)
    this.props.executarRelatorio({ params, oidDocumento })
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button label="Visualizar" icon="pi pi-check" className="p-button-success" onClick={this.executarRelatorio} />
      </div>
    )
  }

  buildHeader = () => {
    return (
      <div>
        <DefaultToolBar listPageContainer={this} title={_.get(this.props, 'documento.nome', '')} />
      </div>
    )
  }

  render() {
    const content = (
      <TelaDinamicaForm
        messages={this.props.messages}
        record={this.props.record}
        setField={this.props.setField}
        history={this.props.history}
        documento={this.props.documento}
      />
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TelaDinamicaFormPageContainer)
