import React from 'react';

import MapaFormPageContainer from './MapaFormPageContainer';

export class MapaFormPage extends React.Component{

    
    render(){
        return (
           <MapaFormPageContainer {...this.props} />
        )
    }
}
