import React from 'react';

import MarcadorFormPageContainer from './MarcadorFormPageContainer';

export class MarcadorFormPage extends React.Component{

    
    render(){
        return (
           <MarcadorFormPageContainer {...this.props} />
        )
    }
}
