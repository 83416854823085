import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import utils from '../../utils'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { getFiltroJoinUnidade } from '../../utils/filter-utils'
import { InputText } from 'primereact/inputtext'

/**
 * Esse componente suporta tanto taxa quando produto (item lancamento).
 */
export default function LtsLookupItemLancamento(props) {
  const [filters, setFilters] = useState([])

  const columns = [
    {
      field: 'oidTipo',
      header: 'Código',
      columnKey: 'oidTipo',
      sortable: true,
      filter: true,
      columnSortField: 'oidTipo',
      visible: false,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
    },
    {
      field: 'tipo',
      header: 'Tipo',
      columnKey: 'tipo',
      visible: false,
    },
    {
      field: 'oidEmpresa',
      header: 'Empresa',
      columnKey: 'oidEmpresa',
      visible: false,
    },
    {
      field: 'oidUnidade',
      header: 'Unidade',
      columnKey: 'oidUnidade',
      visible: false,
    },
    {
      field: 'oidGrupo',
      header: 'Grupo',
      columnKey: 'oidGrupo',
      visible: false,
    },
    {
      field: 'entradaSaida',
      header: 'Entrada/Saída',
      columnKey: 'entradaSaida',
      visible: false,
    },
  ]

  const loadDataFn = (params) => {
    const pFilters = _.get(params, 'filters', [])
    params.filters = pFilters.filter((f) => f.campo !== 'ativo') // nao precisamos filtrar por ativo aqui
    return apiService.post('/lottustech/itemlancamento/all', params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('descricao', value, 'CONTEM', [])]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oidTipo: '',
        descricao: value,
        valor: '',
        tipo: '',
        oidEmpresa: '',
        oidUnidade: '',
      })
    } else {
      props.onChange(value)
    }
  }

  const autoCompleteTemplate = (taxa) => {
    const anoReferencia = _.get(taxa, 'anoReferencia', '')
    return `${taxa.descricao} ${anoReferencia}`
  }

  const onResolvingColumn = () => {
    return columns
      .filter((col) => col.field === 'descricao' || col.field === 'valor')
      .map((column) => {
        column.showFilterMenuOptions = false
        column.showClearButton = false
        column.showFilterMenu = false

        if (column.field === 'valor') {
          return (
            <Column
              {...column}
              key={column.field}
              body={(rowData) => utils.formatarDinheiro(rowData.valor)}
              style={{width: "10em"}}
              filterElement={columnFilterElement(column)}
            />
          )
        }
        if (column.field === 'descricao') {
          return <Column {...column} key={column.field} filterElement={columnFilterElement(column)} style={{width: "15em"}} />
        }
        return null
      })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'valor' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    /**
     * filtro tipo: 1 = ENTRADA | 2 = SAIDA
     */
    // const filtroTipoTaxa = _.get(props, 'filtroTipoTaxa', null)
    // if (filtroTipoTaxa) {
    //   filters.push({
    //     campo: 'grupoFinanceiro_tipo',
    //     valor: filtroTipoTaxa,
    //     tipoFiltro: 'IGUAL_A',
    //   })
    // }
    const filtroUnidade = getFiltroJoinUnidade(_.get(props, 'idUnidade', null))
    if (filtroUnidade) {
      filters.push(filtroUnidade)
    }
    return filters
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={props.value}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEdit={routes.PATH_TAXA_FINANCEIRO} //TODO temos que ter duas rotas
        header={props.header}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        disabled={props.disabled}
        editDisabled={props.editDisabled}
      />
    </React.Fragment>
  )
}
