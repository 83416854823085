import { types } from "./loteBoletos.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "loteBoletos",
  selectedFilters: [],
  columns: [
    {
      field: "oidLancamentoFinanceiro",
      header: "Lançamento",
      columnKey: "oidLancamentoFinanceiro",
      sortable: true,
      filter: true,
      columnSortField: "oidLancamentoFinanceiro",
      visible: true
    },
    {
      field: "oidParcela",
      header: "Parcela",
      columnKey: "oidParcela",
      sortable: true,
      filter: true,
      visible: true
    },
    {
      field: "motivo",
      header: "Causa do erro",
      columnKey: "motivo",
      sortable: true,
      filter: true,
      visible: true
    }
  ],
  currentRecord: {
    oid: "",
    configuracaoBoleto: "",
    unidade: {},
    vencimento: new Date(),
    taxa: "",
    contrato: "",
    periodoInicial: "",
    periodoFinal: ""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDLoteBoletosReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("boletolote");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_TAXA_VISIBLE:
      return setLookupTaxaVisible(state, action);
    case types.SET_LOOKUP_CONTRATO_VISIBLE:
      return setLookupContratoVisible(state, action);
    case types.SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE:
      return setLookupConfiguracaoBoletoVisible(state, action);
    case types.SET_CONFIGURACAO_BOLETO:
      return setConfiguracaoBoleto(state, action);
    case types.SHOW_ERROS:
      return showErros(state, action);
    default:
      return result;
  }
}

function setLookupTaxaVisible(state, action) {
  let lookupTaxa = state.lookupTaxa;
  lookupTaxa = Object.assign({}, lookupTaxa, { visible: action.visible });
  return Object.assign({}, state, { lookupTaxa });
}

function setLookupContratoVisible(state, action) {
  let lookupContrato = state.lookupContrato;
  lookupContrato = Object.assign({}, lookupContrato, {
    visible: action.visible
  });
  return Object.assign({}, state, { lookupContrato });
}

function setLookupConfiguracaoBoletoVisible(state, action) {
  let lookupConfiguracaoBoleto = state.lookupConfiguracaoBoleto;
  lookupConfiguracaoBoleto = Object.assign({}, lookupConfiguracaoBoleto, { visible: action.visible });
  return Object.assign({}, state, { lookupConfiguracaoBoleto });
}

function setConfiguracaoBoleto(state, action) {
  let configuracaoBoleto = "";
  if (action.payload) {
    configuracaoBoleto = { id: action.payload.oid, value: action.payload.nome };
  }
  const currentRecord = state.currentRecord;
  currentRecord.configuracaoBoleto = configuracaoBoleto;

  return Object.assign({}, state, { currentRecord });
}

function showErros(state, action) {  
  const erros = action.payload;
  Object.assign(state, { records: erros });
  return Object.assign({}, state);
}
