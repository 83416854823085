import React from "react";
import { InputText } from "primereact/inputtext";
import { getMessage } from "../../../utils/index";

import "./SexoForm.scss";

export const SexoForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;
  const messages = props.messages;
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {getMessage(messages, "sexo.label.sexo")} {record.oid || ""}
          </h3>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
              <InputText value={record.descricao} onChange={changeHandler} name="descricao" />
                <label>{getMessage(messages, "sexo.label.descricao")}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
