import React from 'react'
import apiService from '../../services/api-id-value-service'
import LtsMultiselectIdValue from './lts-multiselect-id-value'

export default function LtsMultiselectContaCorrente(props) {
  return (
    <React.Fragment>
      <LtsMultiselectIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        loadDataFn={apiService.getContasCorrentes}
      />
    </React.Fragment>
  )
}
