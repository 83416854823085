import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { Page } from '../../../components/page/Page'
import { routes, enums, icons } from '../../../constants'
import utils from '../../../utils/index'
import { actions } from '../consultaBaixa.actions'
import { ConsultaBaixaForm } from './ConsultaBaixaForm'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    colunasItens: state.crudConsultaBaixaState.colunasItens,
    messages: state.crudConsultaBaixaState.messages,
    record: state.crudConsultaBaixaState.currentRecord,
    first: state.crudConsultaBaixaState.index,
    rows: state.crudConsultaBaixaState.max,
    totalRecords: state.crudConsultaBaixaState.count,
    sortField: state.crudConsultaBaixaState.sortField,
    order: state.crudConsultaBaixaState.sortOrder,
    selectedFilters: state.crudConsultaBaixaState.selectedFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadById: (oid) => dispatch(actions.loadById(oid)),
    unmount: () => dispatch(actions.unmount()),
    downloadArquivo: (oidRemessa) => {
      dispatch(actions.downloadArquivo(oidRemessa))
    },
  }
}

class ConsultaBaixaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableItens = React.createRef()
    this.state = {}
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    }
  }

  componentWillUnmount() {
    this.props.unmount()
  }

  hideBotaoRemover = () => {
    return true
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CONSULTA_BAIXA}`)
  }

  onRowSelect = (e) => {
    this.setState({
      displayDialog: true,
      item: Object.assign({}, e.data),
    })
  }

  downloadArquivo = () => {
    this.props.downloadArquivo(this.props.record.oid)
  }

  getBotoesPersonalizados = () => {
    const botoes = []
    if (this.props.record.tipoRemessa === 4) {
      // 4 - Celesc
      botoes.push({
        type: 'button',
        label: utils.getMessage(this.props.messages, 'baixa.label.download'),
        onClick: this.downloadArquivo,
        icon: icons.DOWNLOAD,
      })
    }
    return botoes
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result === 1) {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} />{' '}
        </div>
      )
    } else if (result === 2) {
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-times-circle" style={{ fontSize: '1.8rem' }} />
        </div>
      )
    }
  }

  onStatusFilterChange = (event) => {
    this.dataTableItens.filter(event.value.id, 'status', 'equals')
    this.setState({ brand: event.value })
  }

  buildDialog = () => {
    return (
      <Dialog
        visible={this.state.displayDialog}
        style={{ width: '35%' }}
        header="Mensagem"
        modal={true}
        onHide={() => this.setState({ displayDialog: false })}
        contentStyle={{ 'max-height': '350px' }}
      >
        <div className="p-grid p-fluid">
          <div className="p-col-12" style={{ padding: '.5em' }}>
            <InputTextarea
              id="value"
              rows={100}
              cols={100}
              style={{ 'max-height': '300px' }}
              autoResize={true}
              readOnly={true}
              value={utils.nonNull(this.state.item) ? this.state.item.mensagem : ''}
            />
          </div>
        </div>
      </Dialog>
    )
  }

  render() {
    let statusFilter = (
      <Dropdown
        style={{ width: '100%' }}
        className="ui-column-filter"
        value={this.state.brand}
        options={enums.ArrayStatus}
        onChange={this.onStatusFilterChange}
        optionLabel="value"
        optionValue={(v) => v}
      />
    )

    const columnData = (rowData, column) => {
      if (column.field === 'status' && utils.nonNull(rowData[column.field])) {
        return <div>{utils.getValue(enums.Status, rowData[column.field])}</div>
      } else if (column.field === 'oidLancamento') {
        return <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oidLancamento}`}>{rowData.oidLancamento}</Link>
      } else if (column.field === 'oidContrato') {
        return <Link to={`${routes.PATH_CONTRATO}/${rowData.oidContrato}`}>{rowData.oidContrato}</Link>
      } else if (column.field === 'numeroNFSe' || column.field === 'numeroRPS') {
        return <Link to={`${routes.PATH_NFSE}/${rowData.oidNFSe}`}>{rowData[column.field]}</Link>
      } else if (column.field === 'valor') {
        return <div>R$ {utils.formatCurrency(rowData[column.field])}</div>
      }
    }

    const colunasItens = this.props.colunasItens
      .filter((col) => col.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false

        if (col.field === 'oidContrato') {
          return (
            <Column
              {...col}
              key={col.field}
              body={columnData}
              filter={true}
              sortField={col.columnSortField}
              style={{ width: '6%', textAlign: 'center' }}
              filterMatchMode="contains"
            />
          )
        } else if (col.field === 'oidLancamento' || col.field === 'numeroNFSe' || col.field === 'numeroRPS') {
          return (
            <Column
              {...col}
              key={col.field}
              body={columnData}
              filter={true}
              sortField={col.columnSortField}
              style={{ width: '7%', textAlign: 'center' }}
              filterMatchMode="contains"
            />
          )
        } else if (col.field === 'valor') {
          return (
            <Column
              {...col}
              key={col.field}
              body={columnData}
              filter={true}
              sortField={col.columnSortField}
              style={{ width: '10%', textAlign: 'center' }}
              filterMatchMode="contains"
            />
          )
        } else if (col.field === 'status') {
          return (
            <Column
              {...col}
              filterHeaderStyle={{ overflow: 'visible' }}
              key={col.field}
              body={this.booleanFieldsTemplate}
              filter={true}
              field={'status'}
              sortField={col.columnSortField}
              style={{ width: '8%', textAlign: 'center' }}
              filterElement={statusFilter}
            />
          )
        }
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '20%', textAlign: 'left' }}
          />
        )
      })

    const content = (
      <div>
        {this.buildDialog()}
        <ConsultaBaixaForm
          record={this.props.record}
          colunasItens={colunasItens}
          dataTableItens={(el) => (this.dataTableItens = el)}
          onRowSelect={this.onRowSelect}
          messages={this.props.messages}
        />
      </div>
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaBaixaFormPageContainer)
