import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'

const BASE_PATH = '/lottustech/dashboard'
const prefix = 'crudDashboard'
const crud = build(validate, BASE_PATH)

const newTypes = {
  CONCAT_ATIVIDADES_RECENTES: `${prefix}concatAtividadesRecentes`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.concatAtividadesRecentes = (data) => {
  return {
    type: types.CONCAT_ATIVIDADES_RECENTES,
    payload: data,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.loadAtividades = (paginationParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudDashboardState`]
    dispatch(actions.setIndex(paginationParams.index))
    dispatch(actions.setMax(paginationParams.max))
    let sortFields = []
    if (state.sortField) {
      sortFields = [
        {
          fieldName: state.sortField,
          order: state.sortOrder > -1 ? `ASC` : `DESC`,
        },
      ]
    }
    const params = {
      filters: [],
      sortFields,
      index: paginationParams.index,
      max: paginationParams.max,
      projectionFields: ['oid', 'data', 'descricao', 'tag', 'contrato', 'ativo'],
    }
    apiService.post(`/lottustech/atividades/all`, params).then((response) => {
      dispatch(actions.setField('records', response.data.records))
      dispatch(actions.setField('index', response.data.index))
      dispatch(actions.setField('page', response.data.page))
    })
  }
}

actions.loadMaisAcessados = () => {
  return (dispatch) => {
    apiService.get(`${BASE_PATH}/maisacessados`).then((response) => {
      dispatch(actions.setField('maisAcessados', response.data))
    })
  }
}

actions.loadUltimosAcessados = () => {
  return (dispatch) => {
    apiService.get(`${BASE_PATH}/ultimosacessados`).then((response) => {
      dispatch(actions.setField('ultimosAcessados', response.data))
    })
  }
}

actions.loadAllFavoritos = () => {
  return (dispatch) => {
    const params = {
      index: 0,
      max: 10,
      projectionFields: ['oid', 'menu'],
    }
    apiService.post(`/lottustech/favoritos/all`, params).then((response) => {
      dispatch(actions.setField('favoritos', response.data.records))
    })
  }
}

actions.getCount = () => {
  return (dispatch, getState) => {
    const params = {}
    return apiService.get(`/lottustech/atividades/count`, params).then((response) => {
      dispatch(actions.setField('concluida', response.data['concluida']))
      dispatch(actions.setField('fazer', response.data['fazer']))
    })
  }
}

actions.saveNewAtividade = (atividade) => {
  return (dispatch, getState) => {
    return apiService.post(`/lottustech/atividades/`, atividade).then((response) => {
      Object.assign(atividade, { oid: response.data.oid })
      dispatch(actions.getCount())
    })
  }
}

actions.changeAtividade = (atividade) => {
  return (dispatch, getState) => {
    return apiService.put(`/lottustech/atividades/${atividade.oid}`, atividade).then((response) => {
      dispatch(actions.getCount())
    })
  }
}

actions.removeTask = (oid) => {
  return (dispatch, getState) => {
    const params = {}
    return apiService.remove(`/lottustech/atividades/${oid}`, params).then((response) => {
      dispatch(actions.loadAtividades({ index: 0, max: 5 }))
      dispatch(actions.getCount())
    })
  }
}

actions.loadAtividadesRecentes = (event, atividadesRecentes) => {
  return (dispatch) => {
    const params = {
      index: event.first,
      max: event.rows,
      projectionFields: ['oid', 'data', 'oidEntidadeRaiz', 'descricao', 'usuarioGravacao', 'tipoLog', 'operacaoLog'],
    }
    apiService
      .post(`/lottustech/logs/atividadesRecentes`, params)
      .then((response) => {
        dispatch(actions.concatAtividadesRecentes(response.data.records))
      })
      .catch((err) => {
        dispatch(actions.concatAtividadesRecentes([]))
      })
  }
}

export { types, actions }
