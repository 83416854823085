import React from 'react';
import ConsultaBaixaListPageContainer from './ConsultaBaixaListPageContainer';

export class ConsultaBaixaListPage extends React.Component{
    
    render(){
      return (
        <ConsultaBaixaListPageContainer {...this.props}/>
      )
    }
}

