import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as perfilActions } from '../../perfil/perfil.actions'
import { actions } from '../acao.actions'
import AcaoForm from './AcaoForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    messages: state.crudAcaoState.messages,
    record: state.crudAcaoState.currentRecord,
    recordPerfis: state.crudPerfilState.records.filter((perfil) => {
      let listIdPerfis = state.crudAcaoState.currentRecord.perfisAcaoSelecionados.map(
        (perfilAcao) => perfilAcao.oidPerfil
      )
      return !listIdPerfis.includes(perfil.oid)
    }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //pickListPerfis
    pickListPerfisLoad: () => dispatch(perfilActions.load(true)),
    pickListPerfisMoveToSource: (perfisAcaoRemoved) => dispatch(actions.pickListPerfisMoveToSource(perfisAcaoRemoved)),
    pickListPerfisMoveAllToSource: () => dispatch(actions.pickListPerfisMoveAllToSource()),
    pickListPerfisMoveToTarget: (perfisAdd, oidAcao) =>
      dispatch(actions.pickListPerfisMoveToTarget(perfisAdd, oidAcao)),
    pickListPerfisMoveAllToTarget: (allPerfis, oidAcao) =>
      dispatch(actions.pickListPerfisMoveAllToTarget(allPerfis, oidAcao)),
    //Ação
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      if (field === 'desvincular') {
        value = target.checked
      }
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class AcaoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ACAO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_ACAO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ACAO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }

    this.props.pickListPerfisLoad()
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ACAO}`)
  }

  pickListPerfisMoveToSource = (perfisAcaoRemoved) => {
    this.props.pickListPerfisMoveToSource(perfisAcaoRemoved)
  }

  pickListPerfisMoveAllToSource = () => {
    this.props.pickListPerfisMoveAllToSource()
  }

  pickListPerfisMoveToTarget = (perfisAdd, oidAcao) => {
    this.props.pickListPerfisMoveToTarget(perfisAdd, oidAcao)
  }

  pickListPerfisMoveAllToTarget = (allPerfis, oidAcao) => {
    this.props.pickListPerfisMoveAllToTarget(allPerfis, oidAcao)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <AcaoForm
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        recordPerfis={this.props.recordPerfis}
        pickListPerfisMoveToSource={this.pickListPerfisMoveToSource}
        pickListPerfisMoveAllToSource={this.pickListPerfisMoveAllToSource}
        pickListPerfisMoveToTarget={this.pickListPerfisMoveToTarget}
        pickListPerfisMoveAllToTarget={this.pickListPerfisMoveAllToTarget}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcaoFormPageContainer)
