import React from 'react';

import ConsultaBaixaRelatorioFormPageContainer from './ConsultaBaixaRelatorioFormPageContainer';

export class ConsultaBaixaRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <ConsultaBaixaRelatorioFormPageContainer {...this.props} />
        )
    }
}
