import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import storage from '../../../services/stoage.service'
import utils, { checkStringConfig } from '../../../utils'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { actions } from '../mensagens.actions'
import { AgendamentoMensageriaForm } from './AgendamentoMensageriaForm'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import _ from 'lodash'
import { icons, routes } from '../../../constants'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { FONTE_DE_DADOS, ID_COLUNA_ANEXO } from '../constants'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudAgendamentoMensageriaState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  const record = state.crudAgendamentoMensageriaState.currentRecord

  return {
    record,
    messages,
    messagesDialog,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (favorito) => {
      dispatch(actions.removeFavorito(favorito))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    previewExcel(arquivo) {
      dispatch(actions.previewExcel(arquivo))
    },
    previewQuery(query) {
      dispatch(actions.previewQuery(query))
    },
    enviarMensagem: (params) => dispatch(actions.enviarMensagem(params)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class AgendamentoMensageriaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.datatablePreview = React.createRef()
    this.showColumnButton = false
    this.showCSVButton = false
    this.showPesquisarButton = false
    this.showFilter = false
    this.state = {
      showConfirmarEnviarMensagem: false,
      dadosFavorito: null,
      favorito: 0,
      dataset: [],
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_AGENDAMENTO_MENSAGERIA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_AGENDAMENTO_MENSAGERIA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_AGENDAMENTO_MENSAGERIA}`)
    }

    actions.afterLoad = (data) => {
      this.setState({ dataset: data })
      if (_.get(data, 'fonteDados', -1) === FONTE_DE_DADOS.SQL) {
        setTimeout(() => {
          this.onClickPreviewQuery()
        }, 350)
      }
    }
  }

  hideDialog = () => {
    this.setState({ showConfirmarEnviarMensagem: false })
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_AGENDAMENTO_MENSAGERIA}`)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  confirmarEnvioMensagem = () => {
    this.setState({ showConfirmarEnviarMensagem: true })
  }

  getDialogConfirmarEnvioMensagem() {
    if (this.state.showConfirmarEnviarMensagem) {
      const msg = utils.getMessage(this.props.messagesDialog, 'agendamentoMensageria.dialog.message.confirmar.envio')
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarEnviarMensagem}
          content={msg}
          confirm={this.onConfirmEnviarMensagem}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  onConfirmEnviarMensagem = () => {
    const empresa = storage.getCompany()
    const unidade = storage.getUnit()
    const configKey = 'campoObrigatorioMensageriaV2'
    const defaultValue = 'contato'
    const idDestinatario = checkStringConfig({ empresa, unidade, configKey, defaultValue })
    const tipoMensagem = _.get(this.props, 'record.tipoMensagem', null)
    const assunto = _.get(this.props, 'record.assunto', null)
    const fonteDadosPreview = _.get(this.props, 'record.fonteDadosPreview', [])
    const templateMensagem = _.get(this.props, 'record.templateMensagem', '')
    const mensagens = []
    if (!_.isEmpty(templateMensagem) && fonteDadosPreview.length > 0) {
      const fonteDadosPreviewColumns = _.get(this.props, 'record.fonteDadosPreviewColumns', [])
      for (let i = 0; i < fonteDadosPreview.length; i++) {
        const registro = fonteDadosPreview[i]
        const infoAnexo = this.resolveInfoAnexo(registro)
        const mensagem = this.parseMensagem(templateMensagem, registro, fonteDadosPreviewColumns)
        mensagens.push({ mensagem, contato: registro[idDestinatario], assunto, tipoMensagem, ...infoAnexo })
      }
    }
    this.props.enviarMensagem(mensagens)
  }

  resolveInfoAnexo = (registro) => {
    let valoresAnexo = registro[ID_COLUNA_ANEXO.ANEXO_BOLETO]
    if (valoresAnexo && valoresAnexo > 0) {
      return { idAnexo: ID_COLUNA_ANEXO.ANEXO_BOLETO, valoresAnexo }
    }
    valoresAnexo = registro[ID_COLUNA_ANEXO.ANEXO_CARNE]
    if (valoresAnexo) {
      return { idAnexo: ID_COLUNA_ANEXO.ANEXO_CARNE, valoresAnexo }
    }
    return { idAnexo: null, valoresAnexo: null }
  }

  getBotoesPersonalizados = () => {
    const fonteDadosPreview = _.get(this.props, 'record.fonteDadosPreview', [])
    const arrayBotoes = []
    const botao = {
      type: 'button',
      label: utils.getMessage(this.props.messages, 'agendamentoMensageria.label.enviar'),
      onClick: (e) => this.confirmarEnvioMensagem(),
      disabled: fonteDadosPreview.length === 0,
    }
    arrayBotoes.push(botao)
    return arrayBotoes
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  onSelectArquivoExcel = (e) => {
    this.props.previewExcel(e)
  }

  actionRemover = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => this.removerRegistro(rowData)}
        />
      </div>
    )
  }

  defineColumns = () => {
    const fonteDadosPreviewColumns = _.get(this.props, 'record.fonteDadosPreviewColumns', [])
    if (utils.isArrayNullOrEmpty(fonteDadosPreviewColumns)) {
      return null
    }
    return fonteDadosPreviewColumns
      .filter((o) => o.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        if (col.field === 'acaoRemover') {
          return (
            <Column
              {...col}
              key={col.field}
              header={'Ação'}
              body={this.actionRemover}
              style={{ width: '5em', textAlign: 'center' }}
            />
          )
        }
        return <Column {...col} key={col.field} sortField={col.columnSortField} />
      })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  onChangeTemplateMensagem = (text) => {
    this.props.setField('templateMensagem', text)
    //Olá {{Nome}} apenas um lembrete que a parcela {{Parcela}} de valor {{Valor}} do seu contrato {{Contrato}} vencerá em breve.
    const fonteDadosPreview = _.get(this.props, 'record.fonteDadosPreview', [])
    if (utils.isArrayNotEmpty(fonteDadosPreview)) {
      const registro1 = fonteDadosPreview[0]
      const mensagem = this.parseMensagem(text, registro1, _.get(this.props, 'record.fonteDadosPreviewColumns', []))
      this.props.setField('mensagem', mensagem)
    }
  }

  parseMensagem = (text, registro, fonteDadosPreviewColumns) => {
    let mensagem = text
    for (const prop of fonteDadosPreviewColumns) {
      const idx = mensagem.indexOf(`{{${prop.header}}}`)
      if (idx > -1) {
        mensagem = mensagem.replaceAll(`{{${prop.header}}}`, registro[prop.header])
      }
    }
    return mensagem
  }

  removerRegistro = (selectedRecord) => {
    const fonteDadosPreview = _.get(this.props, 'record.fonteDadosPreview', [])
    const newFonteDadosPreview = fonteDadosPreview.filter((f) => f.discardProp !== selectedRecord.discardProp)
    this.props.setField('fonteDadosPreview', newFonteDadosPreview)
  }

  onClickPreviewQuery = () => {
    const query = _.get(this.props, 'record.query', '')
    this.props.previewQuery(query)
  }

  render() {
    const content = (
      <div>
        {this.getDialogConfirmarEnvioMensagem()}
        <AgendamentoMensageriaForm
          state={this.state}
          currentUser={this.props.currentUser}
          messages={this.props.messages}
          changeHandler={this.props.changeHandler}
          record={this.props.record}
          setField={this.props.setField}
          onSelectArquivoExcel={this.onSelectArquivoExcel}
          onClickPreviewQuery={this.onClickPreviewQuery}
          columns={this.defineColumns()}
          datatablePreview={(el) => (this.datatablePreview = el)}
          onChangeTemplateMensagem={this.onChangeTemplateMensagem}
          confirmarEnvioMensagem={this.confirmarEnvioMensagem}
        />
      </div>
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoMensageriaFormPageContainer)
