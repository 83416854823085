import React, { useEffect, useState } from 'react'
import LtsMultiselectIdValue from './lts-multiselect-id-value'
import storage from '../../services/stoage.service'
import { enums } from '../../constants'
import utils from '../../utils'

// const getFormasPagamento = () => {
//   const configFormasPagamento = utils.getConfiguracaoFormasPagamento(storage.getCompany(), null)
//   return utils.getFormasPagamento(enums.FormasPagamento, configFormasPagamento)
// }
export default function LtsMultiselectFormaPagamento(props) {
  // const [options, setOptions] = useState([])
  //
  // useEffect(() => {
  //   getOptions()
  // }, [])
  //
  // const getOptions = () => {
  //   setOptions(getFormasPagamento())
  // }

  return (
    <React.Fragment>
      <LtsMultiselectIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
      />
    </React.Fragment>
  )
}
