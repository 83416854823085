import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import utils from "../../utils";
const BASE_PATH = "/lottustech/portalCliente";
const prefix = "crudPortalClienteContrato";
const crud = build(validate, BASE_PATH);

const newTypes = {};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadAll = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudPortalClienteContratoState`];
    actions.setDefaultColumns(state);
    apiService.post(`${BASE_PATH}/contratos`, {}).then(response => {
      dispatch(actions.fetchRecordsSuccess(response.data));
    });
  };
}

export { types, actions };
