import React, { useState } from 'react'
import _ from 'lodash'
import { Card } from 'primereact/card'
import LtsLookupItemLancamento from '../../../components/lottus/lts-lookup-item-lancamento'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import LtsCurrency from '../../../components/lottus/lts-currency'
import apiService from '../../../services/api.service'
import utils from '../../../utils'
import '../../../index.scss'
import LtsInputNumber from '../../../components/lottus/lts-input-number'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import { Button } from 'primereact/button'
import { icons } from '../../../constants'

export default function LancamentoItens(props) {
  const [lookupValue, setLookupValue] = useState({
    itemLancamento: null,
    oidEmpresa: '',
    oidUnidade: '',
    valor: '',
    descricao: '',
    oidGrupo: '',
    entradaSaida: '',
    quantidade: '',
  })

  const columns = [
    {
      field: 'oidTipo',
      header: 'Código',
      columnKey: 'oidTipo',
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
    },
    {
      field: 'conspagaUltimoPagamento',
      header: 'Paga',
      columnKey: 'conspagaUltimoPagamento',
    },
    {
      field: 'conspagaPagarAte',
      header: 'Até',
      columnKey: 'conspagaPagarAte',
    },
    {
      field: 'quantidade',
      header: 'Quantidade',
      columnKey: 'quantidade',
    },
    {
      field: 'valorTotal',
      header: 'Valor Total',
      columnKey: 'valorTotal',
    },
    {
      field: 'descricaoServico',
      header: 'Descrição Serviço',
      columnKey: 'descricaoServico',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ]

  const resolveColumns = () => {
    let _columns = [...columns]
    if (!props.showConspaga) {
      _columns = columns.filter((f) => f.field !== 'conspagaPagarAte' && f.field !== 'conspagaUltimoPagamento')
    }
    return _columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'oidTipo') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-oid-body-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-descricao-body-width"
            headerClassName="column-descricao-header-width"
          />
        )
      }
      if (col.field === 'valor') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
          />
        )
      }
      if (col.field === 'valorTotal') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
          />
        )
      }
      if (col.field === 'conspagaPagarAte') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
          />
        )
      }
      if (col.field === 'quantidade') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }
      if (col.field === 'descricaoServico') {
        return (
          <Column
            {...col}
            key={col.field}
            body={onRenderColumnBody}
            bodyClassName="column-descricao-body-width"
            headerClassName="column-descricao-header-width"
          />
        )
      }
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionItem} style={{ width: '50px' }} />
      }
      return <Column key={col.field} {...col} style={{ width: '10em' }} />
    })
  }

  const onRenderColumnBody = (rowData, column) => {
    const field = _.get(column, 'field', '')
    if (field === 'descricao') {
      return _.get(rowData, 'descricao', '')
    }
    if (field === 'oidTipo') {
      return _.get(rowData, 'itemLancamento.oidTipo', null)
    }
    if (field === 'valor') {
      const valor = _.get(rowData, 'valor', '')
      return (
        <LtsCurrency
          value={valor}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          onBlur={(e) => onBlurValor(e, rowData)}
          name="valor"
          inputStyle={{ height: '30px', maxWidth: '10rem', minWidth: '10rem', width: '10rem' }}
          disabled={props.disabled}
        />
      )
    }
    if (field === 'valorTotal') {
      const valorTotal = _.get(rowData, 'valorTotal', '')
      return (
        <LtsCurrency
          value={valorTotal}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="valorTotal"
          inputStyle={{ height: '30px', maxWidth: '10rem', minWidth: '10rem', width: '10rem' }}
          disabled={props.disabled}
        />
      )
    }
    if (field === 'quantidade') {
      const quantidade = _.get(rowData, 'quantidade', null)
      return (
        <LtsCurrency
          mode={'decimal'}
          value={quantidade}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="quantidade"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
          disabled={props.disabled}
        />
      )
    }
    if (field === 'conspagaPagarAte') {
      const conspagaPagarAte = _.get(rowData, 'conspagaPagarAte', '')
      const isTaxaConservacao = _.get(rowData, 'isTaxaConservacao', false)
      if (isTaxaConservacao) {
        return (
          <LtsInputNumber
            value={conspagaPagarAte}
            onChange={(e) => onChangeTableValue(rowData, e, field)}
            onBlur={(e) => onBlurConspagaPagarAte(e, rowData)}
            name="conspagaPagarAte"
            inputStyle={{ height: '30px', maxWidth: '5rem', minWidth: '5rem', width: '5rem' }}
            disabled={props.disabled}
          />
        )
      }
      return null
    }
    if (field === 'descricaoServico') {
      const descricaoServico = _.get(rowData, 'descricaoServico', '')
      const status = _.get(props, 'record.status', -1)
      const oidBoletoAilos = _.get(props, 'record.oidBoletoAilos', null)
      return (
        <LtsTextArea
          value={descricaoServico}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="descricaoServico"
          rows={1}
          cols={30}
          disabled={status === 3 || utils.nonNull(oidBoletoAilos) || props.disabled}
        />
      )
    }
  }

  const onBlurValor = (e, item) => {
    if (item.isTaxaConservacao) {
      onBlurConspagaPagarAte(e, item)
    } else {
      const value = props.values.find((f) => f.itemLancamento === item.itemLancamento)
      if (value) {
        value.valorTotal = value.valor
        const result = {
          values: props.values,
          recordAdded: null,
          recordRemoved: null,
        }
        props.onChange(result)
      }
    }
  }

  const onBlurConspagaPagarAte = (e, item) => {
    const conspagaPagarAte = _.get(item, 'conspagaPagarAte', null)
    const conspagaUltimoPagamento = _.get(item, 'conspagaUltimoPagamento', null)
    const valor = _.get(item, 'valor', null)
    if (conspagaPagarAte && conspagaUltimoPagamento && valor) {
      const value = props.values.find((f) => f.itemLancamento === item.itemLancamento)
      if (value) {
        const result = {
          values: props.values,
          recordAdded: null,
          recordRemoved: null,
        }

        let valorTotal = utils.multiply(conspagaPagarAte - conspagaUltimoPagamento, valor, 2)
        if (valorTotal < 0) {
          valorTotal = 0
        }
        value.valorTotal = valorTotal

        // vamos buscar a descricao do servico - template caso seja Taxa
        if (isItemTaxa(item)) {
          const params = {
            oidContrato: _.get(props, 'record.contrato.id', null),
            oidsGrupos: [_.get(item, 'oidGrupo', 0)],
            ano: conspagaPagarAte,
          }
          apiService
            .post(`/lottustech/lancamentosfinanceiros/getTemplateObservacaoNota`, params)
            .then((resp) => {
              value.descricaoServico = _.get(resp, 'data', null)
              props.onChange(result)
            })
            .catch((error) => {
              console.log(`LancamentoItens#onBlurConspagaPagarAte - erro obtendo template observacao nota`)
            })
        } else {
          props.onChange(result)
        }
      }
    }
  }

  const isItemTaxa = (item) => {
    return _.get(item, 'itemLancamento.tipo', '') === 'TAXA'
  }
  const onChangeTableValue = (rowData, value, field) => {
    rowData[field] = value
    if (field === 'conspagaPagarAte') {
      const conspagaUltimoPagamento = _.get(rowData, 'conspagaUltimoPagamento', 0)
      if (conspagaUltimoPagamento && value) {
        rowData['quantidade'] = value - conspagaUltimoPagamento
      }
    }
    const quantidade = _.get(rowData, 'quantidade', 0)
    const valor = _.get(rowData, 'valor', 0)
    rowData['valorTotal'] = utils.multiply(quantidade, valor, 2)

    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const actionItem = (rowData) => {
    const lancamentoOrigem = _.get(props, 'record.lancamentoOrigem', null)
    const status = _.get(props, 'record.status', -1)
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => removeItem(e, rowData)}
          disabled={lancamentoOrigem || status === 3}
        />
      </div>
    )
  }

  const removeItem = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    const itemLancamento = _.get(data, 'itemLancamento', null)
    if (itemLancamento) {
      const itemRemoved = props.values.find((f) => f.itemLancamento === itemLancamento)
      const newItens = props.values.filter((f) => f !== itemRemoved)
      if (itemRemoved) {
        const result = {
          values: newItens,
          serviceAdded: null,
          serviceRemoved: itemRemoved,
        }
        props.onChange(result)
        resetLookupValue()
      }
    }
  }

  const onChangeLookup = (e) => {
    const oidTipo = _.get(e, 'oidTipo', '')
    const descricao = _.get(e, 'descricao', '')
    const quantidade = 0
    if (oidTipo) {
      const okEntradaSaida = checkEntradaSaida(e)
      const isAlreadySelected = isTaxaEntradaCaixaAlreadySelected(e)
      if (!isAlreadySelected && okEntradaSaida) {
        loadPacoteServicos(e)
        resetLookupValue()
      }
    } else {
      setLookupValue({
        itemLancamento: null,
        oidEmpresa: '',
        oidUnidade: '',
        valor: '',
        descricao,
        oidGrupo: '',
        entradaSaida: '',
        quantidade,
      })
    }
  }

  const checkEntradaSaida = (data) => {
    // 1 = Entarda | 2 = Saida
    const entradaSaida = _.get(data, 'entradaSaida', '-1')
    const values = _.get(props, 'values', [])
    const found = values.find((f) => f.entradaSaida !== entradaSaida)
    if (found) {
      props.showWarningMessage('Não é possível adicionar itens de Entrada e Saída no memso lançamento.')
      return false
    }
    return true
  }

  const isTaxaEntradaCaixaAlreadySelected = (data) => {
    const values = _.get(props, 'values', [])
    const tipo = _.get(data, 'tipo', '')
    const company = _.get(props, 'currentCompany', null)
    const units = _.get(props, 'units', [])
    const idUnidade = _.get(props, 'record.unidade.id', null)
    const oidGrupo = _.get(data, 'oidGrupo', null)

    let isEntradaCaixaAlreadySelected = false
    values.forEach((v) => {
      const ok = utils.isTaxaEntradaCaixa(units, idUnidade, v.oidGrupo, company)
      if (ok) {
        isEntradaCaixaAlreadySelected = true
      }
    })

    if (isEntradaCaixaAlreadySelected) {
      props.showWarningMessage(
        "Já foi selecionada uma taxa de 'entrada de caixa'. Não é possível adicionar mais taxas nesse lançamento."
      )
      return true
    }

    const isEntradaCaixa = utils.isTaxaEntradaCaixa(units, idUnidade, oidGrupo, company)
    if (isEntradaCaixa && utils.isArrayNotEmpty(values)) {
      props.showWarningMessage("Para taxa de 'entrada de caixa', crie um lançamento somente para ela.")
      return true
    }

    return isItemAlreadySelected({ tipo, oidTipo: _.get(data, 'oidTipo', null) })
  }

  const loadPacoteServicos = (data) => {
    const isTaxa = _.get(data, 'tipo', null)
    const oid = _.get(data, 'oidTipo', null)
    if (oid) {
      if (isTaxa === 'TAXA') {
        apiService
          .get(`/lottustech/itemlancamento/pacote/${oid}`)
          .then((resp) => onSuccessLoadPacoteServicos(data, resp))
          .catch(onErrorLoadPacoteServicos)
      } else {
        const resp = { data: [] }
        onSuccessLoadPacoteServicos(data, resp)
      }
    }
  }

  const isItemAlreadySelected = (item) => {
    const found = props.values.find(
      (f) =>
        _.get(f, 'itemLancamento.oidTipo', -1) === _.get(item, 'oidTipo', 0) &&
        _.get(f, 'itemLancamento.tipo', '') === _.get(item, 'tipo', '')
    )
    return !!found
  }

  const onSuccessLoadPacoteServicos = (itemSelected, response) => {
    const oidUnidade = _.get(props, 'record.unidade.id', null)
    const taxas = _.get(response, 'data', [])
    if (utils.isArrayNotEmpty(taxas)) {
      let totalPackageValue = 0
      let novosServicos = []
      for (const taxa of taxas) {
        const itemLancamento = {
          tipo: _.get(taxa, 'tipo', null),
          oidTipo: _.get(taxa, 'oidTipo', null),
        }
        const alreadySelected = isItemAlreadySelected(itemLancamento)
        if (!alreadySelected) {
          totalPackageValue += _.get(taxa, 'valor', 0)
          const oidGrupo = _.get(taxa, 'oidGrupo', '')
          const item = buildItem({ itemLancamento, oidGrupo, oidUnidade, taxa })
          novosServicos.push(item)
        }
      }
      const itemSelectedValue = _.get(itemSelected, 'valor', 0)
      let packageDiscount = 0
      if (itemSelectedValue < totalPackageValue) {
        packageDiscount = totalPackageValue - itemSelectedValue
      }

      const result = {
        values: [...props.values, ...novosServicos],
        serviceAdded: {
          ...itemSelected,
          itemLancamento: {
            tipo: _.get(itemSelected, 'tipo', null),
            oidTipo: _.get(itemSelected, 'oidTipo', null),
          },
        },
        serviceRemoved: null,
        packageDiscount,
      }

      props.onChange(result)
    } else {
      const itemLancamento = {
        tipo: _.get(itemSelected, 'tipo', null),
        oidTipo: _.get(itemSelected, 'oidTipo', null),
      }
      const alreadySelected = isItemAlreadySelected(itemLancamento)
      if (!alreadySelected) {
        const oidGrupo = _.get(itemSelected, 'oidGrupo', '')
        const item = buildItem({ itemLancamento, oidGrupo, oidUnidade, taxa: itemSelected })
        const result = {
          values: [...props.values, item],
          serviceAdded: item,
          serviceRemoved: null,
          packageDiscount: 0,
        }
        getTemplateObservacaoNota(item, result)
      }
    }
  }
  const buildItem = ({ itemLancamento, oidGrupo, oidUnidade, taxa }) => {
    const isTaxaConservacao = utils.isTaxaConservacao(props.units, oidUnidade, oidGrupo)
    const valor = _.get(taxa, 'valor', 0)
    const item = {
      oid: '',
      itemLancamento,
      valor,
      valorTotal: _.get(taxa, 'valorTotal', valor),
      descricao: _.get(taxa, 'descricao', ''),
      descricaoServico: _.get(taxa, 'descricao', ''),
      oidGrupo,
      entradaSaida: _.get(taxa, 'entradaSaida', ''),
      isTaxaConservacao,
      conspagaUltimoPagamento: '',
      conspagaPagarAte: '',
      quantidade: _.get(taxa, 'quantidade', 1),
    }

    if (isTaxaConservacao) {
      // FIXME melhorar isso aqui...
      updateConspagaAttributes(props.record, item)
    }
    return item
  }

  const getTemplateObservacaoNota = (item, resultObj) => {
    const params = {
      oidContrato: _.get(props, 'record.contrato.id', null),
      oidsGrupos: [_.get(item, 'oidGrupo', 0)],
      ano: _.get(item, 'conspagaPagarAte', null),
      isProduto: !isItemTaxa(item),
    }
    apiService
      .post(`/lottustech/lancamentosfinanceiros/getTemplateObservacaoNota`, params)
      .then((resp) => onSuccessGetTemplateObservacaoNota(resultObj, item, resp))
      .catch((error) => onErrorGetTemplateObservacaoNota(item, error))
  }

  const onSuccessGetTemplateObservacaoNota = (result, item, resp) => {
    item.descricaoServico = _.get(resp, 'data', null)
    if (result) {
      result.descricaoServico = item.descricaoServico
    }
    props.onChange(result)
  }

  const onErrorGetTemplateObservacaoNota = (item, error) => {
    console.error(`Erro obtendo template observacao nota para oidTipo: ${_.get(item, 'oidTipo', null)}`)
  }

  const updateConspagaAttributes = (record, item) => {
    let conspagaUltimoPagamento = ''
    let conspagaPagarAte = ''
    if (record && item) {
      const anoCorrente = window.moment().year()
      let conspaga
      if (!record.conspaga || parseInt(record.conspaga) === 0) {
        conspaga = anoCorrente
      } else {
        conspaga = parseInt(record.conspaga)
      }
      conspagaUltimoPagamento = conspaga
      if (conspaga >= anoCorrente) {
        props.showMessageConspagaEmDia(conspaga)
      }
      conspagaPagarAte = resolveConspagaPagarAte(conspaga, anoCorrente)
    }

    item.conspagaUltimoPagamento = conspagaUltimoPagamento
    item.conspagaPagarAte = conspagaPagarAte
    item.quantidade = conspagaPagarAte - conspagaUltimoPagamento
    item.valorTotal = executeCalculaTaxaConservacao(item.quantidade, item.valor)
  }

  const resolveConspagaPagarAte = (conspagaAtual, anoCorrente) => {
    if (conspagaAtual >= anoCorrente) {
      return conspagaAtual + props.fatorMultiplicadorConspaga
    }
    if (props.fatorMultiplicadorConspaga === 1) {
      return anoCorrente
    }
    let result = conspagaAtual
    while (result < anoCorrente) {
      result += props.fatorMultiplicadorConspaga
    }
    return result
  }

  const executeCalculaTaxaConservacao = (quantidade, valorTaxa) => {
    if (quantidade > 0) {
      if (props.fatorMultiplicadorConspaga === 1) {
        const valorConservacaoAtualizado = utils.multiply(quantidade, valorTaxa, 2)
        if (valorConservacaoAtualizado && valorConservacaoAtualizado > 0) {
          return valorConservacaoAtualizado
        }
      } else {
        const aux = quantidade / props.fatorMultiplicadorConspaga
        if (aux > 0) {
          const valorConservacaoAtualizado = valorTaxa * aux
          if (valorConservacaoAtualizado && valorConservacaoAtualizado > 0) {
            return valorConservacaoAtualizado
          }
        }
      }
    }
    return valorTaxa
  }

  const onErrorLoadPacoteServicos = (error) => {
    resetLookupValue()
  }

  const resetLookupValue = () => {
    setLookupValue({
      itemLancamento: null,
      oidEmpresa: '',
      oidUnidade: '',
      valor: '',
      descricao: '',
      quantidade: '',
    })
  }

  return (
    <React.Fragment>
      <Card title={'Itens'}>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupItemLancamento
              disabled={props.disabled}
              header={'Produtos/Serviços'}
              label={'Produto/Serviço'}
              value={_.get(lookupValue, 'descricao', '')}
              history={props.history}
              onChange={(e) => onChangeLookup(e)}
              idUnidade={_.get(props, 'record.unidade.id', null)}
            />
          </div>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12">
            <DataTable
              disabled={props.disabled}
              emptyMessage=" "
              value={props.values}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              stripedRows
              responsiveLayout="scroll"
              size={'small'}
            >
              {resolveColumns()}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
