import React from "react";
import {DataTable} from "primereact/datatable";
import {enums} from "../../../constants";
import utils from "../../../utils/index";
import {Card} from "primereact/card";

export const ConsultaBaixaForm = props => {
    const record = props.record;
    let arquivo = "";
    if (record.nome) {
        arquivo = " Arquivo: " + record.nome;
    }
    let total = "";
    if (record.valor) {
        total = " Total: R$ " + utils.formatCurrency(record.valor);
    }

    const totalRecords = utils.isArrayNullOrEmpty(record.itens) ? 0 : record.itens.length;

    return (
        <div className="p-grid p-fluid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>
                        {utils.getMessage(props.messages, "consultabaixa.label.consultaBaixas")} {record.oid || ""}
                    </h3>
                    <h4>{arquivo || ""}</h4>
                    <h4>{total || ""}</h4>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                            <DataTable
                                autoLayout={true}
                                reorderableColumns={true}
                                resizableColumns={true}
                                columnResizeMode="expand"
                                ref={props.dataTableItens}
                                paginator={true}
                                emptyMessage="Não foram encontrados registros"
                                rows={15}
                                rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                                value={record.itens}
                                onRowSelect={props.onRowSelect}
                                selectionMode="single"
                                totalRecords={totalRecords}
                            >
                                {props.colunasItens}
                            </DataTable>
                            <br/>
                            <Card style={{ height: '30px', margin: "5px" }} className="ui-card-shadow">
                                <div style={{textAlign: "right", margin: "7px"}}>
                                    <strong>Total: </strong> {totalRecords}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
