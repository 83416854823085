import React from 'react'
import './OrcamentoForm.scss'
import { Card } from 'primereact/card'
import LtsDropdownTipoSepultura from '../../../components/lottus/lts-dropdown-tipo-sepultura'
import LtsLookupResponsavel from '../../../components/lottus/lts-lookup-responsavel'
import _ from 'lodash'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import LtsHistorico from '../../../components/lottus/lts-historico'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'
import OrcamentoFormTitle from './orcamento-form-title'
import OrcamentoFormSubtitle from './orcamento-form-subtitle'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import { enums } from '../../../constants'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'
import LtsTableItensLancamento from '../../../components/lottus/lts-table-itens-lancamento'

export const OrcamentoForm = (props) => {
  const record = props.record

  return (
    <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
      <TabPanel header="Dados Gerais">{getDadosGerais()}</TabPanel>
      <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
    </TabView>
  )

  function getDadosGerais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <Card
            title={<OrcamentoFormTitle status={record.status} oid={record.oid} />}
            subTitle={<OrcamentoFormSubtitle oidContrato={record.oidContrato} history={props.history} />}
          >
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsCalendar
                  value={record.dataEmissao}
                  name={'dataEmissao'}
                  label={'Emissão'}
                  onChange={(e) => props.setField('dataEmissao', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsDropdownUnidade
                  name={'unidade'}
                  label={'Unidade'}
                  value={props.record.unidade}
                  onChange={props.onChangeUnit}
                  showOptionAll={true}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsDropdownTipoSepultura
                  name={'tipoSepultura'}
                  label={'Tipo'}
                  value={props.record.tipoSepultura}
                  onChange={(e) => props.setField('tipoSepultura', e)}
                  loadDataFnParam={_.get(props, 'record.unidade.id', null)}
                  hideRecords={props.tiposSepuluraToHide}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <LtsLookupResponsavel
                  name={'responsavel'}
                  field={'nome'}
                  label={'Responsável'}
                  value={_.get(record, 'responsavel.value', '')}
                  oidRecord={_.get(record, 'responsavel.id', '')}
                  history={props.history}
                  onChange={props.setResponsavel}
                  statePessoaFisica={props.statePessoaFisica}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-6">
                <LtsTextArea
                  value={record.observacao}
                  onChange={(e) => props.setField('observacao', e)}
                  name={'observacao'}
                  label={'Observação'}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                <LtsCurrency
                  value={record.valorTotal}
                  name={'valorTotal'}
                  label={'Total'}
                  onChange={(e) => props.setField('valorTotal', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12">
                <LtsTableItensLancamento
                  history={props.history}
                  idUnidade={_.get(props, 'record.unidade.id', null)}
                  values={_.get(props, 'record.taxas', [])}
                  onChange={(e) => props.onChangeTaxas(e)}
                  filtroTipoTaxa={enums.TipoTaxa.ENTRADA}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12">
                <LtsHistorico
                  history={props.history}
                  values={_.get(props, 'record.historicos', [])}
                  onChange={(e) => props.setField('historicos', e)}
                  currentUser={props.currentUser}
                  canEdit={props.canEditHistorico}
                  canRemove={props.canRemoveHistorico}
                  stateMarcador={props.stateMarcador}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    )
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.ORCAMENTO}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
