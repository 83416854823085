import { types } from "./religiao.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  id: 'religiao',
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value:
      {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true
      }
    }, {
      label: "Nome",
      value:
      {
        field: "nome",
        header: "Nome",
        columnKey: "nome",
        sortable: true,
        columnSortField: 'nome',
        filter: true,
        visible: true
      }
    }
  ],
  columnsLookup: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true
    }
  ],
  columns: [],
  currentRecord: {
    oid: "",
    nome: ""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDReligiaoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('religiao');
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action);
    default:
      return state
  }
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}
