import React from 'react';

import SepulturasDevedorasRelatorioFormPageContainer from './SepulturasDevedorasRelatorioFormPageContainer';

export class SepulturasDevedorasRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <SepulturasDevedorasRelatorioFormPageContainer {...this.props} />
        )
    }
}
