import utils from "../../../utils";

export function validate(record) {
  utils.blankToNull(record);
  const errors = [];
  if (!record.descricao) {
    errors.push({ field: "descricao", message: "O campo descrição é obrigatório!" });
  }
  if (!record.tipo) {
    errors.push({ field: "tipo", message: "O campo tipo é obrigatório!" });
  }
  if (record.aliquotaPis) {
    record.aliquotaPis = utils.parseStringToNumber(record.aliquotaPis);
  }
  if (record.aliquotaIss) {
    record.aliquotaIss = utils.parseStringToNumber(record.aliquotaIss);
  }
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
