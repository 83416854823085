import _ from 'lodash'
import {blankToNull, formatNumber} from '../../../utils'


export function validate(record) {
  const errors = [];
  if (record.valorTotal) {
    record.valorTotal = formatNumber(record.valorTotal);
  }

  const unidade = _.get(record, 'unidade.id', -1)
  if (unidade === -1) {
    errors.push({
      field: "unidade",
      message: "O campo unidade é obrigatório",
    })
  }

  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
