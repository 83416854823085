import React from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { LookupField } from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";
import { icons } from "../../../constants";
import "./LocalizacaoForm.scss";

export const LocalizacaoForm = props => {
  const messages = props.messages;
  const record = props.record;
  const changeHandler = props.changeHandler;
  const anexos = props.anexos;
  let unidade = {};
  if (props.record.unidade.id) {
    unidade = { id: props.record.unidade.id, value: props.record.unidade.value };
  }

  function getContent() {
    if (utils.isArrayNullOrEmpty(anexos)) {
      return null;
    }
    return anexos.map(anexo => (
        <div className="p-grid">
          <div className="p-col-12 p-sm-11 p-md-11 p-lg-11 p-xl-11">
            <Button
                type="p-button"
                label={anexo.filename}
                onClick={e => props.downloadAnexo(anexo)}
                icon={icons.DOWNLOAD}
                className="p-button-secondary"
                disabled={isNaN(anexo.oid)}
            />
          </div>
          <div className="p-col-12 p-sm-1 p-md-1 p-lg-1 p-xl-1">
            <Button
                type="p-button-icon"
                icon={icons.REMOVE_ICON}
                onClick={e => props.removeAnexo(anexo)}
                className="p-button-danger"
            />
          </div>
        </div>
    ));
  }

  function getAnexoWrapper() {
    return (
        <div className="card card-w-title">
          <div>
            <h3>{utils.getMessage(messages, "localizacao.label.anexos")}</h3>
            <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo">
              {utils.getMessage(messages, "localizacao.label.adicionar")}
            </label>
            <input id="selecao-arquivo" type="file" onChange={props.onSelectAnexo} />
          </div>
          <br />
          {getContent()}
        </div>
    );
  }

  function showHideCampos() {
    if (props.isCapela) {
      return null;
    }
    return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-1 p-md-2 p-lg-3 p-xl-3">
              <label htmlFor="chkTemporaria">
                {utils.getMessage(messages, "localizacao.label.temporaria")}
              </label>
              <Checkbox
                  inputId="chkTemporaria"
                  checked={record.temporaria}
                  style={{ marginLeft: "5px" }}
                  onChange={changeHandler}
                  name="temporaria"
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-2">
              <span className="p-float-label">
              <InputText
                  keyfilter="pint"
                  name={"numeroVagas"}
                  value={record.numeroVagas}
                  onChange={changeHandler}
              />
                <label>{utils.getMessage(messages, "localizacao.label.numerovagas")}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-2">
              <span className="p-float-label">
              <InputText
                  keyfilter="pint"
                  name={"numeroLugares"}
                  value={record.numeroLugares}
                  onChange={changeHandler}
              />
                <label>{utils.getMessage(messages, "localizacao.label.numerolugares")}</label>
              </span>
            </div>
          </div>

          {props.inputsLocalizacao && props.inputsLocalizacao.length > 0
              ? props.inputsLocalizacao.map(element => (
                  <div className="p-grid form-group">
                    <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                      <span className="p-float-label">
                      <InputText value={record[element["name"]]} onChange={changeHandler} name={element["name"]} />
                        <label>{element.header}</label>
                      </span>
                    </div>
                  </div>
              ))
              : null}
        </div>
    )
  }

  return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>
                {utils.getMessage(messages, "localizacao.label.localizacao")} {record.oid || ""}
              </h3>
              <div className="p-grid form-group">
                <div className="p-col-4">
                  <span className="p-float-label">
                  <InputText value={record.identificacao} onChange={changeHandler} name="identificacao" disabled={!props.isCapela} />
                    <label>{utils.getMessage(messages, "localizacao.label.identificacao")}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <span className="p-float-label">
                  <Dropdown
                      autoWidth={false}
                      disabled={props.disableUnit}
                      value={unidade}
                      options={props.unidades}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      dataKey="value"
                      name="unidade"
                  />
                    <label>{utils.getMessage(messages, "localizacao.label.unidade")}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <span className="p-float-label">
                  <Dropdown
                      autoWidth={false}
                      value={props.record.tipoSepultura}
                      options={props.tiposSepultura}
                      onChange={changeHandler}
                      disabled={!props.record.unidade.id}
                      optionLabel="value"
                      optionValue={(v) => v}
                      dataKey="value"
                      name="tipoSepultura"
                  />
                    <label>{utils.getMessage(messages, "localizacao.label.tiposepultura")}</label>
                  </span>
                </div>
              </div>

              {showHideCampos()}
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                  <span className="p-float-label">
                  <InputText value={record.observacao} onChange={changeHandler} name="observacao" />
                    <label>{utils.getMessage(messages, "localizacao.label.observacao")}</label>
                  </span>
                </div>
              </div>


              {record.oid && !props.isCapela ? (
                  record.disponivel ? (
                      <div className="p-grid form-group">
                        <div className="p-col-4 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                          <Button
                              onClick={props.onClickContratar}
                              label={utils.getMessage(messages, "localizacao.label.contratar")}
                              className="p-button p-component indigo-btn"
                          />
                        </div>
                      </div>
                  ) : (
                      <div className="p-grid form-group">
                        <div className="p-col-4">
                          <LookupField
                              label={utils.getMessage(messages, "localizacao.label.contrato")}
                              value={record}
                              name="contrato"
                              field="oidContrato"
                              history={props.history}
                              onKeyPress={props.lookupContratoOnKeyPress}
                              onComplete={props.lookupContratoOnComplete}
                              onInputFilter={props.lookupContratoOnInputFilter}
                              editDisabled={props.lookupContrato.editDisabled}
                              onEditField={props.lookupContratoOnEdit}
                              onClick={props.lookupContratoOnClick}
                              visible={props.lookupContrato.visible}
                              modal={props.lookupContrato.modal}
                              header={props.lookupContrato.header}
                              onHide={props.lookupContratoOnHide}
                              onConfirm={props.lookupContratoOnConfirm}
                              onCancel={props.lookupContratoOnCancel}
                              records={props.lookupContrato.records}
                              columns={props.lookupContrato.columns}
                              totalRecords={props.lookupContrato.totalRecords}
                              sortOrder={props.lookupContrato.order}
                              sortField={props.lookupContrato.sortField}
                              first={props.lookupContrato.first}
                              rows={props.lookupContrato.rows}
                              onPage={props.lookupContratoOnPage}
                              onFilter={props.lookupContratoOnFilter}
                              onSort={props.lookupContratoOnSort}
                          />
                        </div>
                      </div>
                  )
              ) : (
                  ""
              )}
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-1 p-md-2 p-lg-3 p-xl-3">
                  <label htmlFor="chkAtiva" className="label">
                    {utils.getMessage(messages, "localizacao.label.ativo")}
                  </label>
                  <Checkbox
                      inputId="chkAtiva"
                      checked={record.ativo}
                      style={{ marginLeft: "5px" }}
                      onChange={changeHandler}
                      name="ativo"
                  />
                </div>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12">{getAnexoWrapper()}</div>
            </div>
          </div>
        </div>
      </div>
  );
};
