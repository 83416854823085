import utils from '../../utils'
import React from 'react'
import _ from 'lodash'
import LtsMultiselectFormaPagamento from '../../components/lottus/lts-multiselect-forma-pagamento'
import storage from '../../services/stoage.service'
import { enums } from '../../constants'

const getOptions = ({ record, componentInfo }) => {
  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(storage.getCompany(), null)
  return utils.getFormasPagamento(enums.FormasPagamento, configFormasPagamento)
}

export const formBuildMultiselectFormaPagamento = ({ componentInfo, messages, record, onChange, label }) => {
  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
        <LtsMultiselectFormaPagamento
          name={_.get(componentInfo, 'campo', '_undef_')}
          label={label}
          value={record[_.get(componentInfo, 'campo', '_undef_')]}
          onChange={(e) => onChange(_.get(componentInfo, 'campo', null), _.get(e, 'target.value', null))}
          options={getOptions({ record, componentInfo })}
        />
      </div>
    </div>
  )
}
