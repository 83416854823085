import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lottustech/acoes';
const prefix = 'crudAcao';
const crud = build(validate, BASE_PATH);

const newTypes = {
    PICKLIST_PERFIS_MOVE_TO_TARGET : `${prefix}pickListPerfisMoveToTarget`,
    PICKLIST_PERFIS_MOVE_TO_SOURCE : `${prefix}pickListPerfisMoveToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE : `${prefix}pickListPerfisMoveAllToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_TARGET : `${prefix}pickListPerfisMoveAllToTarget`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.pickListPerfisMoveToSource = (perfisAcaoRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_SOURCE,
        perfisAcaoRemoved
    }
}

actions.pickListPerfisMoveAllToSource = (perfisAcaoRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE
    }
}

actions.pickListPerfisMoveToTarget = (perfisAdd, oidAcao) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_TARGET,
        perfisAdd,
        oidAcao
    }
}

actions.pickListPerfisMoveAllToTarget = (allPerfis, oidAcao) => {
    return actions.pickListPerfisMoveToTarget(allPerfis, oidAcao)
}

export { types, actions };