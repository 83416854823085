const blankToNull = require("./../../../utils").blankToNull;
const validateEmail = require("./../../../utils").validateEmail;

export function validate(record) {
  blankToNull(record);
  const errors = [];
  if (!record.login || record.login.trim().length < 4) {
    errors.push({ field: "login", message: "O campo login é obrigatório e deve ter mais de 3 caracteres" });
  }

  // So vamos obrigar a senha em cadastro novo
  if (!record.oid && (!record.senha || record.senha.trim().length < 4)) {
    errors.push({ field: "senha", message: "O campo senha é obrigatório e deve ter no mínimo 4 caracteres" });
  }

  if (record.email && record.email.trim().length > 0 && !validateEmail(record.email)) {
    errors.push({ field: "email", message: "O campo e-mail é inválido!" });
  }

  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
