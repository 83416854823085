import _ from 'lodash'
import utils from './index'

export function getUltimoCampoPesquisaLocalizacaoGrid(tipoSepultura, oidUnidade, empresa) {
  const campoPesquisa = _.get(tipoSepultura, 'campoPesquisa', null)
  if (_.isEmpty(campoPesquisa)) {
    const oidTipoSepultura = _.get(tipoSepultura, 'oid', null)
    return utils.getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura)
  }
  const split = campoPesquisa.split(' ')
  if (!split || split.length === 0) {
    return null
  }
  return split[split.length - 1]
}
