import React, { Component } from 'react'
import utils from '../../utils/index'
import { acoes, icons, SMALL_WINDOW_WIDTH } from '../../constants'
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { ListBox } from 'primereact/listbox'
import { Dialog } from 'primereact/dialog'
import { OverlayPanel } from 'primereact/overlaypanel'
import { getValuesForListBox } from '../../utils/listPageContainerUtils'
import { Rating } from 'primereact/rating'
import storage from '../../services/stoage.service'
import './defaultToolbar.css'

import { VisaoForm } from '../visao/VisaoForm'
import { DownloadExcel } from '../downloadExcel/DownloadExcel'
import { SplitButton } from 'primereact/splitbutton'
import { PrimeIcons } from 'primereact/api'
const _ = require('lodash')

export class DefaultToolBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissoes: utils.getPermissoesPorMenu(
        storage.getMenus(),
        storage.getOidCurrentMenu(),
        null,
        storage.getPortalCliente()
      ),
      showSelectArquivoImportacao: false,
    }
  }

  getLabelBotao(label) {
    return window.innerWidth > SMALL_WINDOW_WIDTH ? label : undefined
  }

  componentDidMount() {
    if (this.props.listPageContainer.props.getBotoesDinamicos) {
      this.props.listPageContainer.props.getBotoesDinamicos(this.props.listPageContainer.props.idTela)
    }
  }

  resolveAdvancedFilters() {
    if (this.props.listPageContainer.buildAdvancedFilters) {
      return this.props.listPageContainer.buildAdvancedFilters()
    }
    return null
  }

  resolveCustomFilters() {
    if (this.props.listPageContainer.buildCustomFilters) {
      return this.props.listPageContainer.buildCustomFilters()
    }
    return null
  }

  getFilterButton() {
    if (this.props.listPageContainer.buildAdvancedFilters) {
      return (
        <Button
          icon={icons.SEARCH_ICON}
          style={{ marginRight: '.25em' }}
          label={this.getLabelBotao(utils.getMessage(this.props.listPageContainer.props.messages, 'label.filtros'))}
          onClick={(e) => this.props.listPageContainer.showFilters()}
        />
      )
    }
    return null
  }

  getColumnsButton() {
    if (
      this.props.listPageContainer.showColumnButton === undefined ||
      (this.props.listPageContainer.showColumnButton && this.props.listPageContainer.showColumnButton === true)
    ) {
      return (
        <Button
          icon={PrimeIcons.BARS}
          style={{ marginRight: '.25em' }}
          label={this.getLabelBotao(utils.getMessage(this.props.listPageContainer.props.messages, 'label.colunas'))}
          onClick={(e) => this.props.listPageContainer.columnSelect.toggle(e)}
        />
      )
    }
    return null
  }

  getExportButtons() {
    if (
      this.props.listPageContainer.showCSVButton === undefined ||
      (this.props.listPageContainer.showCSVButton && this.props.listPageContainer.showCSVButton === true)
    ) {
      const buttons = [
        {
          label: 'CSV',
          icon: PrimeIcons.EXTERNAL_LINK,
          command: (e) => this.props.listPageContainer.exportar(false),
        },
      ]

      return (
        <div className="p-toolbar-group-left">
          <SplitButton
            label="Excel"
            icon={PrimeIcons.EXTERNAL_LINK}
            style={{ marginRight: '4px' }}
            model={buttons}
            onClick={() => this.props.listPageContainer.exportar(true)}
          />
        </div>
      )
    }
  }

  getImportButtons() {
    const acaoImportarCsv = storage.getAcao(acoes.ACAO_IMPORTAR_CSV)
    if (!acaoImportarCsv || _.get(this.props, 'listPageContainer.hideBtnImportacao', false)) {
      return null
    }
    const buttons = [
      {
        label: 'Baixar Template',
        icon: PrimeIcons.EXTERNAL_LINK,
        command: (e) => this.getTemplateImportacao(),
      },
      {
        label: 'Upload CSV',
        icon: PrimeIcons.EXTERNAL_LINK,
        command: (e) => this.showSelectArquivoImportacao(),
      },
    ]

    return (
      <div className="p-toolbar-group-left">
        <SplitButton
          label="Importação"
          icon={PrimeIcons.EXTERNAL_LINK}
          style={{ marginRight: '4px' }}
          model={buttons}
          onClick={() => this.props.listPageContainer.exportar(true)}
        />
      </div>
    )
  }

  selectArquivoImportacaoCSV() {
    const footer = (
      <div>
        <Button
          label={'Cancelar'}
          onClick={(e) => this.props.listPageContainer.hideSelectArquivoImportacao()}
          className="p-button-danger"
          style={{ marginTop: '20px' }}
        />
      </div>
    )

    return (
      <Dialog
        header={'Selecionar arquivo importação'}
        visible={this.state.showSelectArquivoImportacao}
        style={{ width: '15vw' }}
        onHide={() => this.hideSelectArquivoImportacao()}
        baseZIndex={1000000}
        modal={true}
        closable={true}
        footer={footer}
      >
        <div style={{ paddingTop: '20px' }}>
          <label className="label-btn-arquivo-retorno" htmlFor="selecao-arquivo-csv">
            {'Clique para selecionar o arquivo - CSV'}
          </label>
          <input id="selecao-arquivo-csv" type="file" onChange={(e) => this.uploadCsvImportacao(e)} accept="UTF-8" />
        </div>
      </Dialog>
    )
  }

  uploadCsvImportacao(e) {
    this.hideSelectArquivoImportacao()
    const props = _.get(this, 'props.listPageContainer.props', null)
    const fn = _.get(props, 'uploadCsvImportacao', null)
    if (fn) {
      fn(e)
    }
    // this.props.listPageContainer.uploadCsvImportacao(e)
  }

  hideSelectArquivoImportacao() {
    this.setState({ showSelectArquivoImportacao: false })
  }

  showSelectArquivoImportacao() {
    this.setState({ showSelectArquivoImportacao: true })
  }

  getTemplateImportacao() {
    const props = _.get(this, 'props.listPageContainer.props', null)
    const fn = _.get(props, 'getTemplateImportacao', null)
    if (fn) {
      fn()
    }
  }

  exportExcel() {
    const excelDataset = this.props.listPageContainer.state && this.props.listPageContainer.state.excelDataset
    if (
      excelDataset &&
      (this.props.listPageContainer.showCSVButton === undefined ||
        (this.props.listPageContainer.showCSVButton && this.props.listPageContainer.showCSVButton === true))
    ) {
      this.props.listPageContainer.setState({ excelDataset: null })
      return <DownloadExcel dataSet={excelDataset} />
    }

    return null
  }

  getVisaoButton() {
    if (this.props.listPageContainer.showFormVisao) {
      const isCustomRoute = this.props.listPageContainer.props.isCustomRoute
      if (isCustomRoute) {
        return (
          <Button
            type="button"
            iconPos="left"
            icon={icons.TRASH_ICON}
            label={this.getLabelBotao('Remover visão')}
            onClick={() => this.props.listPageContainer.showDialogRemoveVisao()}
            style={{ marginRight: '.25em' }}
            className="p-button-danger"
          />
        )
      } else {
        return (
          <Button
            type="button"
            icon={PrimeIcons.EYE}
            iconPos="left"
            label={this.getLabelBotao('Visão')}
            onClick={() => this.props.listPageContainer.showFormVisao()}
          />
        )
      }
    }
    return null
  }

  getPesquisarButton() {
    if (
      this.props.listPageContainer.showPesquisarButton === undefined ||
      (this.props.listPageContainer.showPesquisarButton && this.props.listPageContainer.showPesquisarButton === true)
    ) {
      return (
        <Button
          style={{ marginLeft: '5px' }}
          type="button"
          icon={PrimeIcons.SEARCH}
          iconPos="left"
          label={this.getLabelBotao('Pesquisar')}
          onClick={() => this.props.listPageContainer.props.applyFilter()}
        />
      )
    }
    return null
  }

  hasCustomButtons() {
    return !!this.props.listPageContainer.getCustomButtons
  }

  getCustomButtons() {
    if (this.props.listPageContainer.getCustomButtons) {
      return this.props.listPageContainer.getCustomButtons()
    }
    return null
  }

  getNovoButton() {
    if (!this.props.listPageContainer.newHandler) {
      return null
    }
    if ((this.state.permissoes && this.state.permissoes.inserir) || !this.state.permissoes) {
      return (
        <div className="p-toolbar-group-left">
          <Button
            onClick={this.props.listPageContainer.newHandler}
            label={this.getLabelBotao(utils.getMessage(this.props.listPageContainer.props.messages, 'label.novo'))}
            icon={icons.PLUS_ICON}
            style={{ marginRight: '.25em' }}
            className="p-button-success"
          />
        </div>
      )
    }
    return null
  }

  getRemoveButton() {
    if (
      this.props.listPageContainer.hideBotaoRemover ||
      (this.state.permissoes && !this.state.permissoes.excluir && !this.props.listPageContainer.removeHandler)
    ) {
      return null
    }
  }

  hasRemoveButton() {
    if (this.props.listPageContainer.hideBotaoRemover) {
      return false
    }

    return !!(this.state.permissoes && this.state.permissoes.excluir && this.props.listPageContainer.removeHandler)
  }

  getConfirmDialog() {
    if (this.props.listPageContainer.showDialogRemove) {
      return (
        <ConfirmDialog
          header={utils.getMessage(this.messages, 'dialog.titulo.confirmacao')}
          visible={this.props.listPageContainer.showDialogRemove}
          content={utils.getMessage(this.messages, 'dialog.mensagem.confirmaremoverregistro')}
          confirm={(e) => {
            this.props.listPageContainer.props.removeAll()
            this.props.listPageContainer.hideDialog()
          }}
          hideDialog={this.props.listPageContainer.hideDialog}
        />
      )
    }
    return null
  }

  getConfirmDialogRemoverVisao() {
    if (this.props.listPageContainer.state.showDialogRemoveVisao) {
      return (
        <ConfirmDialog
          header={utils.getMessage(this.messages, 'dialog.titulo.confirmacao')}
          visible={this.props.listPageContainer.state.showDialogRemoveVisao}
          content={utils.getMessage(this.messages, 'dialog.mensagem.confirmaremoverregistro')}
          confirm={(e) => {
            this.props.listPageContainer.removerState()
            this.props.listPageContainer.hideDialogRemoverVisao()
          }}
          hideDialog={this.props.listPageContainer.hideDialogRemoverVisao}
        />
      )
    }
    return null
  }

  getVisaoForm() {
    if (this.props.listPageContainer.state.showDialogVisao) {
      return (
        <VisaoForm
          perfis={this.props.listPageContainer.props.perfis}
          salvarState={this.props.listPageContainer.salvarState}
          defineDynamicFilters={this.props.listPageContainer.defineDynamicFilters}
          selectedFilters={this.props.listPageContainer.props.selectedFilters}
          filterValues={this.props.listPageContainer.props.filterValues}
          listPageContainer={this.props.listPageContainer}
        />
      )
    }
    return null
  }

  getSplitButton = () => {
    if (this.props.listPageContainer.getSplitButton) {
      return this.props.listPageContainer.getSplitButton()
    }
  }

  hasSplitButton = () => {
    return !!this.props.listPageContainer.getSplitButton
  }

  onClickBotaoDinamico = (botao) => {
    let listId = []
    let records = this.props.listPageContainer.props.selectedRecords
    if (records && records.length > 0) {
      records.forEach((record) => {
        listId.push(record.oid)
      })
    }
    let params = null
    if (this.props.listPageContainer.getParamsBotaoDinamico) {
      params = this.props.listPageContainer.getParamsBotaoDinamico()
    }
    this.props.listPageContainer.props.onClickBotaoDinamico(listId, botao, params)
  }

  resolveFavorito = () => {
    if (storage.getPortalCliente()) {
      return null
    }

    return (
      <Rating
        className="star"
        value={this.props.listPageContainer.state.favorito}
        onChange={(e) => this.props.listPageContainer.changeFavorito()}
        stars={1}
        cancel={false}
        style={{ marginBottom: '30px' }}
      />
    )
  }

  leftContents = () => {
    return (
      <React.Fragment>
        {this.getNovoButton()}
        {/*{this.hasRemoveButton() || this.hasCustomButtons() || this.hasSplitButton() ? <i className="pi pi-bars p-toolbar-separator mr-2" /> : ""}*/}
        {this.getCustomButtons()}
        {this.getRemoveButton()}
        {this.getSplitButton()}
        {this.props.listPageContainer.props.botoesDinamicos &&
        this.props.listPageContainer.props.botoesDinamicos.length > 0 &&
        !this.props.listPageContainer.getSplitButton
          ? this.props.listPageContainer.props.botoesDinamicos.map((botao) => {
              let styleBotao = utils.styleToObject(botao.style)
              return (
                <div className="p-toolbar-group-left">
                  <Button
                    onClick={(e) => this.onClickBotaoDinamico(botao)}
                    label={botao.textoBotao}
                    style={styleBotao}
                    className="p-button-success"
                  />
                </div>
              )
            })
          : null}
      </React.Fragment>
    )
  }

  rightContents = () => {
    return (
      <React.Fragment>
        {this.getFilterButton()}
        {this.getColumnsButton()}
        <OverlayPanel
          ref={(el) => {
            this.props.listPageContainer.columnSelect = el
          }}
          dismissable={true}
          appendTo={document.body}
        >
          <ListBox
            listClassName="max-height-25rem"
            value={getValuesForListBox(this.props.listPageContainer)}
            optionLabel="label"
            optionValue={(v) => v}
            options={this.props.listPageContainer.props.showHideColumns}
            onChange={this.props.listPageContainer.onColumnToggle}
            multiple={true}
          />
        </OverlayPanel>
        {this.getExportButtons()}
        {this.exportExcel()}
        {this.getImportButtons()}
        {this.getVisaoButton()}
        {this.getPesquisarButton()}
      </React.Fragment>
    )
  }

  // resolveCustomToolbar = () => {
  //     const leftContents = (
  //             <React.Fragment>
  //                 {this.getNovoButton()}
  //                 {this.getRemoveButton()}
  //                 {this.getSplitButton()}
  //                 {this.props.listPageContainer.props.botoesDinamicos &&
  //                 this.props.listPageContainer.props.botoesDinamicos.length > 0 &&
  //                 !this.props.listPageContainer.getSplitButton
  //                     ? this.props.listPageContainer.props.botoesDinamicos.map(botao => {
  //                         let styleBotao = utils.styleToObject(botao.style);
  //                         return (
  //                             <div className="p-toolbar-group-left">
  //                                 <Button
  //                                     onClick={e => this.onClickBotaoDinamico(botao)}
  //                                     label={botao.textoBotao}
  //                                     style={styleBotao}
  //                                     className="p-button-success"
  //                                 />
  //                             </div>
  //                         );
  //                     })
  //                     : null}
  //             </React.Fragment>
  //     )
  //     const customButtons = this.getCustomButtons()
  //     if (customButtons) {
  //
  //     }
  //
  // }

  resolveToolbarContent = (content) => {
    if (utils.isArrayNullOrEmpty(content)) {
      return null
    }
    return (
      <React.Fragment>
        {content.map((botao) => {
          if (!botao) {
            return <i className="pi pi-bars p-toolbar-separator mr-2" />
          }
          return <div>{botao}</div>
        })}
      </React.Fragment>
    )
  }

  buildToolbar = () => {
    if (this.props.somenteTitulo) {
      return null
    }

    const toolbars = [
      {
        leftContent: [
          <Button
            onClick={this.props.listPageContainer.newHandler}
            label={utils.getMessage(this.props.listPageContainer.props.messages, 'label.novo')}
            icon={icons.PLUS_ICON}
            style={{ marginRight: '.25em' }}
            className="p-button-success"
          />,
          null,
          <Button
            onClick={this.props.listPageContainer.newHandler}
            label="Botao 1.2"
            icon={icons.PLUS_ICON}
            style={{ marginRight: '.25em' }}
            className="p-button-warning"
          />,
        ],
        rightContent: [
          <Button
            onClick={this.props.listPageContainer.newHandler}
            label="Botao 2"
            icon={icons.PLUS_ICON}
            style={{ marginRight: '.25em' }}
            className="p-button-warning"
          />,
        ],
      },
      {
        leftContent: [
          <Button
            onClick={this.props.listPageContainer.newHandler}
            label="Botao 3"
            icon={icons.PLUS_ICON}
            style={{ marginRight: '.25em' }}
            className="p-button-danger"
          />,
        ],
      },
    ]

    // return (
    //     <div>
    //         {this.getConfirmDialog()}
    //         {toolbars.map(tb => {
    //             return <Toolbar
    //                         className={"margin-top-toolbar"}
    //                         left={this.resolveToolbarContent(tb.leftContent)}
    //                         right={this.resolveToolbarContent(tb.rightContent)}
    //                     />
    //         })}
    //         <div style={{ margin: "15px" }}>
    //             {this.resolveAdvancedFilters()}
    //         </div>
    //         {/* Caso necessite de ter um outro 'panel' de filtros. Vide Controle Financeiro */}
    //         <div style={{ margin: "15px" }}>
    //             {this.resolveCustomFilters()}
    //         </div>
    //         <Dialog
    //             header="Visão"
    //             visible={this.props.listPageContainer.state.showDialogVisao}
    //             style={{width: "50vw"}}
    //             modal={true}
    //             appendTo={document.body}
    //             onHide={() => (this.props.listPageContainer.setState({showDialogVisao: false}))}
    //         >
    //             {this.getVisaoForm()}
    //         </Dialog>
    //     </div>
    // );

    return (
      <div>
        {this.getConfirmDialog()}
        {this.getConfirmDialogRemoverVisao()}
        {this.selectArquivoImportacaoCSV()}
        <Toolbar
          style={{ paddingLeft: '2px', marginTop: '2px' }}
          left={this.leftContents()}
          right={this.rightContents()}
        />
        {/*{this.resolveCustomToolbar()}*/}
        <div style={{ margin: '15px' }}>{this.resolveAdvancedFilters()}</div>
        {/* Caso necessite de ter um outro 'panel' de filtros. Vide Controle Financeiro */}
        <div style={{ margin: '15px' }}>{this.resolveCustomFilters()}</div>
        <Dialog
          header="Visão"
          visible={this.props.listPageContainer.state.showDialogVisao}
          style={{ width: '50vw' }}
          modal={true}
          appendTo={document.body}
          onHide={() => this.props.listPageContainer.setState({ showDialogVisao: false })}
        >
          {this.getVisaoForm()}
        </Dialog>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div id="div-tittle" className="p-grid" style={{ marginBottom: '-40px' }}>
          <h4 className="title-label">{this.props.title}</h4>
          {this.resolveFavorito()}
        </div>
        {this.buildToolbar()}
      </div>
    )
  }
}
