import { types } from './documento.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'documento',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Nome',
      value: {
        field: 'nome',
        header: 'Nome',
        columnKey: 'nome',
        sortable: true,
        columnSortField: 'nome',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Entidade Base',
      value: {
        field: 'entidadeBase',
        header: 'Entidade Base',
        columnKey: 'entidadeBase',
        sortable: true,
        columnSortField: 'entidadeBase',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Modelo Base',
      value: {
        field: 'nomeRelatorio',
        header: 'Modelo Base',
        columnKey: 'nomeRelatorio',
        sortable: true,
        columnSortField: 'nomeRelatorio',
        filter: true,
        visible: true,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
    {
      field: 'tipoTela',
      header: 'Tipo Tela',
      columnKey: 'tipoTela',
      sortable: false,
      columnSortField: 'tipoTela',
      filter: false,
      visible: false,
    },
  ],
  columns: [
    // {
    //   field: 'oid',
    //   header: 'Código',
    //   columnKey: 'oid',
    //   sortable: true,
    //   columnSortField: 'oid',
    //   filter: true,
    //   visible: true,
    // },
    // {
    //   field: 'nome',
    //   header: 'Nome',
    //   columnKey: 'nome',
    //   sortable: true,
    //   columnSortField: 'nome',
    //   filter: true,
    //   visible: true,
    // },
  ],
  currentRecord: {
    oid: '',
    nome: '',
    template: '',
    entidadeBase: '',
    nomeRelatorio: '',
    tipoTela: '',
    parametros: '',
    query: '',
  },
  messages: null,
  lookupDocumento: {
    visible: false,
    modal: true,
    header: 'Documento',
  },
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDDocumentoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('documento')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
