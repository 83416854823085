import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import utils from '../../../utils'
import './AgendamentoMensageriaForm.scss'
import { RadioButton } from 'primereact/radiobutton'
import { FONTE_DE_DADOS, TIPO_MENSAGEM } from '../constants'
import { DataTable } from 'primereact/datatable'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import { Button } from 'primereact/button'
import { enums } from '../../../constants'
import LtsInputText from '../../../components/lottus/lts-input-text'
import { Divider } from 'primereact/divider'

export const AgendamentoMensageriaForm = (props) => {
  const record = props.record
  const messages = props.messages
  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="sms"
              name="sms"
              value={TIPO_MENSAGEM.SMS}
              checked={record.tipoMensagem === TIPO_MENSAGEM.SMS}
              onChange={(e) => props.setField('tipoMensagem', e.target.value)}
            />
            <label htmlFor="sms">{utils.getMessage(messages, 'agendamentoMensageria.label.sms')}</label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="email"
              name="email"
              value={TIPO_MENSAGEM.EMAIL}
              checked={record.tipoMensagem === TIPO_MENSAGEM.EMAIL}
              onChange={(e) => props.setField('tipoMensagem', e.target.value)}
            />
            <label htmlFor="email">{utils.getMessage(messages, 'agendamentoMensageria.label.email')}</label>
          </div>
          <Divider />
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="excel"
              name="excel"
              value={FONTE_DE_DADOS.EXCEL}
              checked={record.fonteDados === FONTE_DE_DADOS.EXCEL}
              onChange={(e) => props.setField('fonteDados', e.target.value)}
            />
            <label htmlFor="excel">{utils.getMessage(messages, 'agendamentoMensageria.label.excel')}</label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="sql"
              name="sql"
              value={FONTE_DE_DADOS.SQL}
              checked={record.fonteDados === FONTE_DE_DADOS.SQL}
              onChange={(e) => props.setField('fonteDados', e.target.value)}
            />
            <label htmlFor="sql">{utils.getMessage(messages, 'agendamentoMensageria.label.sql')}</label>
          </div>
          <Divider />
          <div className="p-col-4" hidden={record.fonteDados === FONTE_DE_DADOS.SQL}>
            <div>
              <label
                className="label-btn-arquivo-retorno"
                htmlFor="selecao-arquivo-excel"
                style={{ paddingTop: '15px' }}
              >
                {utils.getMessage(messages, 'agendamentoMensageria.label.selecionar.arquivo.excel')}
              </label>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="selecao-arquivo-excel"
                type="file"
                onChange={(e) => props.onSelectArquivoExcel(e)}
                style={{ marginTop: '.25em' }}
              />
            </div>
          </div>
          <div hidden={record.fonteDados === FONTE_DE_DADOS.EXCEL}>
            <LtsTextArea
              style={{ minHeight: '200px' }}
              autoFocus={true}
              value={record.query}
              onChange={(e) => props.setField('query', e)}
              name={'query'}
              label={utils.getMessage(messages, 'agendamentoMensageria.label.query')}
              cols={100}
            />
          </div>
          <div className="p-col-2" hidden={record.fonteDados === FONTE_DE_DADOS.EXCEL}>
            <br />
            <Button
              label={utils.getMessage(messages, 'agendamentoMensageria.label.previewQuery')}
              onClick={() => props.onClickPreviewQuery()}
            />
          </div>

          <br />
          <Accordion activeIndex={0}>
            <AccordionTab header={utils.getMessage(messages, 'agendamentoMensageria.label.preview')}>
              <DataTable
                emptyMessage="Não foram encontrados registros"
                scrollable={true}
                scrollHeight="700px"
                filterDisplay="row"
                size={'small'}
                stripedRows
                resizableColumns
                columnResizeMode="fit"
                showFilterMenu={false}
                autoLayout={true}
                value={record.fonteDadosPreview}
                rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                paginator={true}
                rows={enums.ROWS}
              >
                {props.columns}
              </DataTable>
            </AccordionTab>
          </Accordion>
          <br />
          <br />
          <LtsInputText
            hidden={record.tipoMensagem !== TIPO_MENSAGEM.EMAIL}
            value={record.assunto}
            name={'assunto'}
            onChange={(e) => props.setField('assunto', e)}
            label={utils.getMessage(messages, 'agendamentoMensageria.label.assunto')}
          />
          <br />
          <LtsTextArea
            value={record.templateMensagem}
            onChange={(e) => props.onChangeTemplateMensagem(e)}
            name={'templateMensagem'}
            label={utils.getMessage(messages, 'agendamentoMensageria.label.templateMensagem')}
          />
          <br />
          <LtsTextArea
            value={record.mensagem}
            readOnly={true}
            name={'mensagem'}
            label={utils.getMessage(messages, 'agendamentoMensageria.label.mensagemFinal')}
          />
          <br />
          <LtsInputText
            disabled={record.fonteDados === FONTE_DE_DADOS.EXCEL}
            value={record.cron}
            name={'cron'}
            onChange={(e) => props.setField('cron', e)}
            label={utils.getMessage(messages, 'agendamentoMensageria.label.cron')}
          />
        </div>
      </div>
    </div>
  )
}
