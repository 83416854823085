import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import listPageUtils, {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onCsvExport,
  onPage,
  onSort,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../nota-entrada.actions'
import { showMessages } from '../../../store/message/message.actions'
import { NotaEntradaList } from './nota-entrada-list'
import './nota-entrada-list.scss'
import utils from '../../../utils'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import apiIntegradorNotaFiscal from '../../../services/api-integrador-nota-fiscal'
import { Button } from 'primereact/button'
import LtsUploadFiles from '../../../components/lottus/lts-upload-files'
import { Toast } from 'primereact/toast'
import _ from 'lodash'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudNotaEntradaState.showHideColumns,
    messages: state.crudNotaEntradaState.messages,
    columns: state.crudNotaEntradaState.columns,
    records: state.crudNotaEntradaState.records,
    first: state.crudNotaEntradaState.index,
    rows: state.crudNotaEntradaState.max,
    totalRecords: state.crudNotaEntradaState.count,
    sortField: state.crudNotaEntradaState.sortField,
    order: state.crudNotaEntradaState.sortOrder,
    selectedFilters: state.crudNotaEntradaState.selectedFilters,
    selectedRecords: state.crudNotaEntradaState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearSelectedRecords: () => dispatch(actions.clearSelectedRecords()),
    changeFilter: (column, e) => {
      const target = e.target
      const campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/notaentrada/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    confirmar: (oids, onSuccess) => dispatch(actions.confirmar(oids, onSuccess)),
    cancelar: (oids, onSuccess) => dispatch(actions.cancelar(oids, onSuccess)),
    reabrir: (oids, onSuccess) => dispatch(actions.reabrir(oids, onSuccess)),
    showMessages: (messages, type) => dispatch(showMessages(messages, type)),
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class NotaEntradaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.toastRef = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      status: 0,
      filtroListagemEmissao: null,
      showUploadNotasDialog: false,
      showConfirmarDialog: false,
      showCancelarDialog: false,
      showReabrirDialog: false,
      notasConfirmar: [],
      notasCancelar: [],
      notasReabrir: [],
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.confirmar = props.confirmar
    this.cancelar = props.cancelar
    this.reabrir = props.reabrir
  }

  onSuccess = () => {
    this.props.load()
    this.props.clearSelectedRecords()
    this.setState({ notasConfirmar: [] })
    this.setState({ notasCancelar: [] })
    this.setState({ notasReabrir: [] })
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_NOTA_ENTRADA}/novo`)
  }

  componentDidMount() {
    componentDidMount(this)
  }

  componentWillUnmount() {
    this.props.clearSelectedRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  onSelectionChange = (e) => {
    const notasConfirmar = []
    const notasCancelar = []
    const notasReabrir = []
    const records = _.get(e, 'value', [])
    if (utils.isArrayNotEmpty(records)) {
      for (const record of records) {
        const status = _.get(record, 'status', '')
        if (status === 'GERADA') {
          notasConfirmar.push(record.oid)
        } else if (status === 'CONFIRMADA') {
          notasCancelar.push(record.oid)
        } else if (status === 'CANCELADA') {
          notasReabrir.push(record.oid)
        }
      }
    }
    this.setState({ notasConfirmar })
    this.setState({ notasCancelar })
    this.setState({ notasReabrir })
    this.props.setSelectedRecords(records)
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Notas de Entrada" />
  }

  chaveLinkBody = (rowData) => {
    return this.columnLinkBody(rowData, 'chave')
  }

  numeroLinkBody = (rowData) => {
    return this.columnLinkBody(rowData, 'numero')
  }

  columnLinkBody = (rowData, field) => {
    if (rowData) {
      return (
        <div title={rowData[field]}>
          <Link to={`${routes.PATH_NOTA_ENTRADA}/${rowData.oid}`}>{rowData[field]}</Link>
        </div>
      )
    }
    return null
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  fieldCalendarFilter = (column) => {
    return (
      <LtsCalendar
        value={this.state.filtroListagemEmissao}
        name={column.field}
        onChange={(e) => this.onChangeFiltroEmissao(e, column)}
      />
    )
  }

  onChangeFiltroEmissao = (e, column) => {
    this.setState({ filtroListagemEmissao: e })
    this.props.changeFilter(column, { target: { name: 'dataEmissao', value: e } })
  }

  statusColumnTemplate = (rowData, column) => {
    let color = 'grey'
    const value = rowData[column.field]
    if (value === 'CONFIRMADA') {
      color = 'green'
    } else if (value === 'CANCELADA') {
      color = 'red'
    }
    return (
      <div style={{ color: color, display: 'flex', justifyContent: 'center' }}>
        <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} title={value} />
      </div>
    )
  }

  statusFilter = (column) => (
    <LtsDropdownIdValue
      name={'status'}
      value={this.state.status}
      onChange={(e) => this.onChangeStatus(e, column)}
      loadDataFn={apiService.getStatusNotaEntrada}
      showOptionAll={true}
    />
  )

  onChangeStatus = (e, column) => {
    this.setState({ status: e })
    this.props.changeFilter(column, { target: { name: 'status', value: e.id } })
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return (
          <Column
            selectionMode="multiple"
            bodyClassName="table-column-multiselect-body-width"
            filterHeaderClassName="table-column-multiselect-filter-header-width"
            headerClassName="table-column-multiselect-header-width"
          />
        )
      }
      // if (col.field === 'oid') {
      //   return (
      //     <Column
      //       {...col}
      //       key={col.field}
      //       body={this.linkBody}
      //       sortField={col.columnSortField}
      //       filterElement={this.fieldFilter(col)}
      //       bodyClassName="table-column-oid-body-width"
      //       filterHeaderClassName="table-column-oid-filter-header-width"
      //       headerClassName="table-column-oid-header-width"
      //     />
      //   )
      // }
      if (col.field === 'dataEmissao' || col.field === 'dataEntrada') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
            bodyClassName="table-column-date-body-width"
            filterHeaderClassName="table-column-date-filter-header-width"
            headerClassName="table-column-date-header-width"
          />
        )
      }
      if (col.field === 'status') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            body={this.statusColumnTemplate}
            filterElement={this.statusFilter(col)}
            bodyClassName="table-column-status-body-width"
            filterHeaderClassName="table-column-status-filter-header-width"
            headerClassName="table-column-status-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-unidade-body-width"
            filterHeaderClassName="table-column-unidade-filter-header-width"
            headerClassName="table-column-unidade-header-width"
          />
        )
      }
      if (col.field === 'fornecedor_nomeFantasia') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-fornecedor-body-width"
            filterHeaderClassName="table-column-fornecedor-filter-header-width"
            headerClassName="table-column-fornecedor-header-width"
          />
        )
      }
      if (col.field === 'fornecedor_nome') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-fornecedor-body-width"
            filterHeaderClassName="table-column-fornecedor-filter-header-width"
            headerClassName="table-column-fornecedor-header-width"
          />
        )
      }
      if (col.field === 'valorNota') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-valor-body-width"
            filterHeaderClassName="table-column-valor-filter-header-width"
            headerClassName="table-column-valor-header-width"
          />
        )
      }
      if (col.field === 'numero') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-numero-body-width"
            filterHeaderClassName="table-column-numero-filter-header-width"
            headerClassName="table-column-numero-header-width"
            body={this.numeroLinkBody}
          />
        )
      }
      if (col.field === 'serie') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-serie-width"
            filterHeaderClassName="table-column-serie-filter-header-width"
            headerClassName="table-column-serie-header-width"
          />
        )
      }
      if (col.field === 'chave') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-chave-body-width"
            filterHeaderClassName="table-column-chave-filter-header-width"
            headerClassName="table-column-chave-header-width"
            body={this.chaveLinkBody}
          />
        )
      }
      return null
    })
  }

  getCustomButtons = () => {
    return (
      <div>
        <Button
          onClick={(e) => this.setState({ showUploadNotasDialog: true })}
          label={'Importar'}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
        <Button
          disabled={utils.isArrayNullOrEmpty(_.get(this, 'state.notasConfirmar', []))}
          onClick={this.showDialogConfirmar}
          label={'Confirmar'}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
        <Button
          disabled={utils.isArrayNullOrEmpty(_.get(this, 'state.notasCancelar', []))}
          onClick={this.showDialogCancelar}
          label={'Cancelar'}
          style={{ marginRight: '.25em' }}
          className="p-button-danger"
        />
        <Button
          disabled={utils.isArrayNullOrEmpty(_.get(this, 'state.notasReabrir', []))}
          onClick={this.showDialogReabrir}
          label={'Reabrir'}
          style={{ marginRight: '.25em' }}
        />
      </div>
    )
  }

  handlerConfirmar = () => {
    const notasConfirmar = _.get(this, 'state.notasConfirmar', [])
    if (utils.isArrayNotEmpty(notasConfirmar)) {
      this.confirmar(notasConfirmar, this.onSuccess)
    }
  }

  handlerCancelar = () => {
    const notasCancelar = _.get(this, 'state.notasCancelar', [])
    if (utils.isArrayNotEmpty(notasCancelar)) {
      this.cancelar(notasCancelar, this.onSuccess)
    }
  }

  handlerReabrir = () => {
    const notasReabrir = _.get(this, 'state.notasReabrir', [])
    if (utils.isArrayNotEmpty(notasReabrir)) {
      this.reabrir(notasReabrir, this.onSuccess)
    }
  }

  onUploadHandler = (files) => {
    if (utils.isArrayNullOrEmpty(files)) {
      this.toastRef.current.show({ severity: 'info', summary: '', detail: 'Nenhum arquivo adicionado' })
    } else {
      this.toastRef.current.show({ severity: 'info', summary: '', detail: 'Arquivo(s) enviado(s) para processamento' })
      apiIntegradorNotaFiscal
        .importNotas(files)
        .then((res) => this.onUploadSuccess(res))
        .catch((err) => this.onUploadError(err))
    }
  }

  onUploadSuccess = (res) => {
    const success = _.get(res, 'data.success', false)
    if (success) {
      const processados = _.get(res, 'data.data.arquivosProcessados', [])
      const naoProcessados = _.get(res, 'data.data.arquivosNaoProcessados', [])
      if (utils.isArrayNotEmpty(processados)) {
        const msgOK = []
        for (const ap of processados) {
          const notaEntrada_ = _.get(ap, 'notaEntrada.chave', null)
          if (notaEntrada_) {
            msgOK.push(`Criada nota ${notaEntrada_}`)
          }
        }
        this.props.showMessages(msgOK, 'success')
      }

      if (utils.isArrayNotEmpty(naoProcessados)) {
        const msgNOK = []
        for (const anp of naoProcessados) {
          const erro = _.get(anp, 'erro', null)
          if (erro) {
            msgNOK.push(erro)
          }
        }
        this.props.showMessages(msgNOK, 'warn')
      }
      this.props.load()
    } else {
      this.props.showMessages(['Ocorreu um erro processando arquivo(s)'], 'error')
    }
  }

  onUploadError = (err) => {
    this.toastRef.current.show({ severity: 'error', summary: '', detail: 'Ocorreu um erro processando arquivo(s)' })
  }

  showDialogConfirmar = () => {
    this.setState({ showConfirmarDialog: true })
  }

  showDialogCancelar = () => {
    this.setState({ showCancelarDialog: true })
  }

  showDialogReabrir = () => {
    this.setState({ showReabrirDialog: true })
  }

  hideDialog = () => {
    this.setState({ showConfirmarDialog: false })
    this.setState({ showCancelarDialog: false })
    this.setState({ showReabrirDialog: false })
  }

  getDialogConfirmar() {
    if (this.state.showConfirmarDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarDialog}
          content={'Confirmar nota(s) selecionada(s)?'}
          confirm={this.handlerConfirmar}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogCancelar() {
    if (this.state.showCancelarDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showCancelarDialog}
          content={'Cancelar nota(s) selecionada(s)?'}
          confirm={this.handlerCancelar}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogReabrir() {
    if (this.state.showReabrirDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showReabrirDialog}
          content={'Reabrir nota(s) selecionada(s)?'}
          confirm={this.handlerReabrir}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  buildContent = () => {
    return (
      <div>
        {this.getDialogConfirmar()}
        {this.getDialogCancelar()}
        {this.getDialogReabrir()}
        <Toast ref={this.toastRef}></Toast>
        <LtsUploadFiles
          visible={this.state.showUploadNotasDialog}
          onHide={(e) => this.setState({ showUploadNotasDialog: false })}
          dragAndDropMessage={'Arraste e solte arquivos aqui (.zip ou .xml)'}
          title={'Importar'}
          onUploadHandler={this.onUploadHandler}
        />
        <NotaEntradaList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotaEntradaListPageContainer)
