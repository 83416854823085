import React from 'react';

import ContasReceberRelatorioFormPageContainer from './ContasReceberRelatorioFormPageContainer';

export class ContasReceberRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <ContasReceberRelatorioFormPageContainer {...this.props} />
        )
    }
}
