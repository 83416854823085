import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import utils from "../../../utils/index";

export const ReceitasDespesasRelatorioForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                <MultiSelect
                    value={record.unidade}
                    options={utils.convertArrayIdValueToIdLabel(props.unidades)}
                    name="unidade"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={props.onChangeUnit}
                    disabled={props.disableUnit}
                  />
                  <label>Unidade</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.tipoFormaPagamento}
                    options={utils.convertArrayIdValueToIdLabel(props.formasPagamento)}
                    name="tipoFormaPagamento"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={changeHandler}
                  />
                  <label>Forma de Pagamento</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.gruposFinanceiros}
                    options={utils.convertArrayIdValueToIdLabel(record.gruposFinanceirosOpcoes)}
                    name="gruposFinanceiros"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={changeHandler}
                    filter={true}
                  />
                  <label>Grupo Financeiro</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.tiposSepulturas}
                    options={utils.convertArrayIdValueToIdLabel(record.opcoesTiposSepulturas)}
                    name="tiposSepulturas"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={props.changeHandler}
                  />
                  <label>Tipo de Sepultura</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate("1970")}
                    monthNavigator={true}
                    value={record.dataInicial}
                    onChange={changeHandler}
                    name="dataInicial"
                  />
                  <label>Período: De</label>
                </span>
              </div>
                <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                  <span className="p-float-label">
                    <Calendar
                      yearNavigator={true}
                      yearRange={utils.getYearRangeFromDate("1970")}
                      monthNavigator={true}
                      value={record.dataFinal}
                      onChange={changeHandler}
                      name="dataFinal"
                    />
                    <label>Até</label>
                  </span>
              </div>
            </div>
            <br/>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="tipoRelatorioReceitasAnaliticas"
                name="tipoRelatorio"
                value={1}
                checked={record.tipoRelatorio === 1}
                onChange={changeHandler}
              />
              <label htmlFor="tipoRelatorioReceitasAnaliticas">
                Receitas Análiticas
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="tipoRelatorioReceitasSintetico"
                name="tipoRelatorio"
                value={2}
                checked={record.tipoRelatorio === 2}
                onChange={changeHandler}
              />
              <label htmlFor="tipoRelatorioReceitasSintetico">
                Receitas Sintético
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="tipoRelatorioDespesasAnaliticas"
                name="tipoRelatorio"
                value={3}
                checked={record.tipoRelatorio === 3}
                onChange={changeHandler}
              />
              <label htmlFor="tipoRelatorioDespesasAnaliticas">
                Despesas Analíticas
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="tipoRelatorioDespesasSinteticas"
                name="tipoRelatorio"
                value={4}
                checked={record.tipoRelatorio === 4}
                onChange={changeHandler}
              />
              <label htmlFor="tipoRelatorioDespesasSinteticas">
                Despesas Sintético
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
