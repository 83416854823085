import { React, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { getMessage } from '../../../utils/index'

import './MapaForm.scss'
import { Dropdown } from 'primereact/dropdown'
import utils from '../../../utils'
import { Image, Layer, Stage } from 'react-konva'
import { Link } from 'react-router-dom'
import { routes } from '../../../constants'

import pin_icon from '../../../assets/images/pin.png'
import pin_icon_verde from '../../../assets/images/pin_verde.png'
import pin_icon_amarelo from '../../../assets/images/pin_amarelo.png'
import { Dialog } from 'primereact/dialog'
import _ from 'lodash'
import { Button } from 'primereact/button'
import { STATUS_COORDENADA } from '../status-coordenada'
import LtsLookupLocalizacao from '../../../components/lottus/lts-lookup-localizacao'

export const MapaForm = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const [coordenada, setCoordenada] = useState()

  const record = props.record
  const changeHandler = props.changeHandler
  const messages = props.messages

  const image = new window.Image()
  image.src = record.imagemSelecionada

  const imagePin = new window.Image()
  imagePin.src = pin_icon

  const imagePinDisp = new window.Image()
  imagePinDisp.src = pin_icon_verde

  const imagePinTemp = new window.Image()
  imagePinTemp.src = pin_icon_amarelo

  const handleImageClick = (e, coordenada) => {
    const statusCoordenada = _.get(coordenada, 'statusCoordenada', null)
    if (statusCoordenada === STATUS_COORDENADA.DISPONIVEL) {
      const params = {
        unidade: coordenada?.unidade,
        oidLocalizacao: _.get(coordenada, 'localizacao.id', null),
        identificacao: _.get(coordenada, 'localizacao.value', null),
        tipoSepultura: _.get(coordenada, 'tipoContrato', null),
        oidMapaCoordenada: _.get(coordenada, 'oid', null),
      }
      props.history.push(`${routes.PATH_CONTRATO}/novo`, params)
    } else {
      setShowDialog(true)
      setCoordenada(coordenada)
    }
  }

  const getImage = (coordenada) => {
    const statusCoordenada = _.get(coordenada, 'statusCoordenada', null)
    switch (statusCoordenada) {
      case STATUS_COORDENADA.TEMPORARIA:
        return imagePinTemp
      case STATUS_COORDENADA.DISPONIVEL:
        return imagePinDisp
      case STATUS_COORDENADA.INDISPONIVEL:
        return imagePin
      default:
        return imagePin
    }
  }

  return (
    <div>
      {record.oid === null ||
        (record.oid === '' && (
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h3>
                  {getMessage(messages, 'mapa.label.mapa')} {record.oid || ''}
                </h3>
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                    <span className="p-float-label">
                      <Dropdown
                        className={'width-20-rem'}
                        autoWidth={false}
                        value={record.unidade}
                        options={props.unidades}
                        onChange={(e) => props.setField('unidade', e.value)}
                        optionLabel="value"
                        optionValue={(v) => v}
                        dataKey="id"
                        name="unidade"
                      />
                      <label>{utils.getMessage(messages, 'mapa.label.unidade')}</label>
                    </span>
                  </div>
                </div>
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                    <span className="p-float-label">
                      <InputText value={record.descricao} onChange={changeHandler} name="descricao" />
                      <label>{getMessage(messages, 'mapa.label.descricao')}</label>
                    </span>
                  </div>
                </div>
                <div className="p-grid form-group" style={{ paddingTop: '5px' }}>
                  <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                    <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo">
                      {utils.getMessage(messages, 'mapa.label.adicionarImagem')}
                    </label>
                    <input id="selecao-arquivo" type="file" accept="image/*" onChange={props.handleImageChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      {record.imagemSelecionada && record.imagemSelecionada !== '' && record.largura !== '' && record.altura !== '' && (
        <div style={{ overflow: 'auto' }}>
          <Stage width={record.largura} height={record.altura}>
            <Layer>
              <Image
                image={image}
                onClick={(e) => {
                  if (props.state.modeAdicionarCoordenada) {
                    props.adicionarCoordenadaEvent(e)
                  }
                }}
                onMouseOver={(e) =>
                  props.state.modeAdicionarCoordenada ? (e.target.getStage().container().style.cursor = 'pointer') : ''
                }
                onMouseOut={(e) =>
                  props.state.modeAdicionarCoordenada ? (e.target.getStage().container().style.cursor = 'default') : ''
                }
              />
              {record.coordenadas.map((coordenada) => {
                return (
                  <div>
                    {
                      <Image
                        image={getImage(coordenada)}
                        x={coordenada.posicaoX}
                        y={coordenada.posicaoY}
                        width={20}
                        height={25}
                        onClick={(e) => handleImageClick(e, coordenada)}
                        onMouseOver={(e) => (e.target.getStage().container().style.cursor = 'pointer')}
                        onMouseOut={(e) => (e.target.getStage().container().style.cursor = 'default')}
                      />
                    }
                  </div>
                )
              })}
            </Layer>
          </Stage>
          <Dialog
            header={coordenada?.tipoContrato?.value || 'Sepultura'}
            visible={showDialog}
            onHide={() => setShowDialog(false)}
          >
            Contrato:{' '}
            <Link to={`${routes.PATH_CONTRATO}/${coordenada?.contrato?.id}`}>{coordenada?.contrato?.value}</Link>
            <br />
            Localização:{' '}
            <Link to={`${routes.PATH_LOCALIZACAO}/${coordenada?.localizacao?.id}`}>
              {coordenada?.localizacao?.value}
            </Link>
            <br />
          </Dialog>

          <Dialog
            header="Adicionar coordenada"
            visible={props.state.showDialogAdicionarCoordenada}
            onHide={() => props.hideDialogAddCoordenada()}
            footer={
              <div className="ui-dialog-buttonpane p-clearfix ">
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  className="p-button-danger"
                  onClick={() => props.hideDialogAddCoordenada()}
                />

                <Button
                  label={getMessage(props.messages, 'label.salvar')}
                  icon="pi pi-check"
                  onClick={() =>
                    props.salvarCoordenada(
                      props.state.coordenadaPosicaoX,
                      props.state.coordenadaPosicaoY,
                      _.get(props.state, 'coordenadaLocalizacao', '')
                    )
                  }
                />
              </div>
            }
          >
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                Coordenada: {props.state.coordenadaPosicaoX},{props.state.coordenadaPosicaoY}
              </div>
              <div className="p-col-12">
                <LtsLookupLocalizacao
                  label={'Localização'}
                  value={_.get(props.state, 'coordenadaLocalizacao.value', '')}
                  oidRecord={_.get(props.state, 'coordenadaLocalizacao.id', '')}
                  idUnidade={_.get(record, 'unidade.id', '')}
                  history={props.history}
                  onChange={props.setLocalizacao}
                  editHidden={false}
                  addFiltroDistint={true}
                  apenasTemporaria={_.get(props.state, 'apenasTemporaria', false)}
                  showFiltroTemporaria={true}
                />
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  )
}
