import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import {messageActions} from "../../store/message";
import utils from "../../utils/index";
const BASE_PATH = "/lottustech/remessasfinanceiras";
const crud = build(validate, BASE_PATH);
const prefix = "crudConsultaRemessa";

const actions = new Action(prefix, crud);
const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  UNMOUNT: `${prefix}unmount`,
  RESET: `${prefix}reset`
};

const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value)=>{
  return {
      type:types.SET_BOOLEAN_FILTER,
      field,
      value
  }
};

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.unmount = () =>{
  return {
      type:types.UNMOUNT
  }
};

actions.imprimirBoletos = itens => {
    return (dispatch, getState) => {
        apiService
            .post(`/lottustech/ailos/gerar/remessaBoletos/`, itens)
            .then(response => {
                const win = window.open(response.data);
                win.focus();
            })
            .catch(err => {
                const messages = err.response.data;
                dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
            });
    };
};

actions.sincronizarRemessa = oidRemessa => {
    return (dispatch, getState) => {
        apiService
            .post(`/lottustech/ailos/sincronizarRemessa`, oidRemessa)
            .then(response => {
                dispatch(messageActions.messageShowMessages(makeMessages(["Remessa sincronizada"], "success")));
            })
            .catch(err => {
                const messages = err.response.data;
                dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
            });
    };
};

actions.downloadArquivo = oidRemessa => {
    return (dispatch, getState) => {
        return apiService.get(`${BASE_PATH}/download/${oidRemessa}`).then(response => {
            let data = response.data;
            utils.createAndDownloadBlobFile(data.conteudo, data.nome);
        })
        .catch(err => {
            const messages = err.response.data;
            dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        });
    };
};

actions.downloadArquivos = oidsRemessa => {
    return (dispatch, getState) => {
        return apiService.post(`${BASE_PATH}/download`, oidsRemessa).then(response => {
            let data = response.data;
            utils.createAndDownloadBlobFile(data.conteudo, data.nome);
        })
        .catch(err => {
            const messages = err.response.data;
            dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        });
    };
};

const makeMessages = (messages, type) => {
    let sticky = false;
    let summary = "Sucesso!";
    if (type === "error") {
        summary = "Erro!";
        sticky = true;
    } else if (type === "warn") {
        summary = "Aviso!";
        sticky = true;
    }
    return messages.map(message => {
        return {
            sticky: sticky,
            summary: summary,
            severity: type,
            detail: message
        };
    });
};

export { types, actions };
