import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Page } from '../../../components/page/Page'
import { InputText } from 'primereact/inputtext'
import { routes } from '../../../constants'
import { actions as taxaActions } from '../../taxa/taxa.actions'
import { actions as contratoActions } from '../../contrato/contrato.actions'
import { actions as configuracaoBoletoActions } from '../../configuracaoBoleto/configuracaoBoleto.actions'
import { actions } from '../loteBoletos.actions'
import storage from '../../../services/stoage.service'
import { LoteBoletosForm } from './LoteBoletosForm'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import utils from '../../../utils/index'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudLoteBoletosState.messages
  const record = state.crudLoteBoletosState.currentRecord

  // REFERENTE AO LOOKUP DE CONFIGURACAO DE BOLETO
  const lookupConfiguracaoBoletoBase = {
    records: state.crudConfiguracaoBoletoState.records,
    columns: state.crudConfiguracaoBoletoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudConfiguracaoBoletoState.index,
    rows: state.crudConfiguracaoBoletoState.max,
    totalRecords: state.crudConfiguracaoBoletoState.count,
    sortField: state.crudConfiguracaoBoletoState.sortField,
    order: state.crudConfiguracaoBoletoState.sortOrder,
    editDisabled: false,
  }
  let lookupConfiguracaoBoleto = Object.assign(
    {},
    state.crudLoteBoletosState.lookupConfiguracaoBoleto,
    lookupConfiguracaoBoletoBase
  )

  //LOOKUP DE TAXA
  const lookupTaxaBase = {
    records: state.crudTaxaState.records,
    columns: state.crudTaxaState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'valor' || col.field === 'grupoFinanceiro.value')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    editDisabled: false,
  }

  let lookupTaxa = Object.assign({}, state.crudLoteBoletosState.lookupTaxa, lookupTaxaBase)

  //LOOKUP CONTRATO
  const lookupContratoBase = {
    records: state.crudContratoState.records,
    columns: state.crudContratoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'localizacao_identificacao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudContratoState.index,
    rows: state.crudContratoState.max,
    totalRecords: state.crudContratoState.count,
    sortField: state.crudContratoState.sortField,
    order: state.crudContratoState.sortOrder,
    editDisabled: false,
  }

  let lookupContrato = Object.assign({}, state.crudLoteBoletosState.lookupContrato, lookupContratoBase)

  // ------------------------------------------------
  // REFERENTE AO DROPDOWN DE UNIDADE
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    Object.assign(record.unidade, { value: currentUnit.sigla })
    Object.assign(record.unidade, { id: currentUnit.oid })
    disableUnit = true
  }
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  // -------------------------------------------------

  return {
    record,
    messages,
    lookupConfiguracaoBoleto,
    disableUnit,
    unidades,
    lookupTaxa,
    lookupContrato,
    /// para a DataTable
    columns: state.crudLoteBoletosState.columns,
    records: state.crudLoteBoletosState.records,
    first: state.crudLoteBoletosState.index,
    rows: state.crudLoteBoletosState.max,
    totalRecords: state.crudLoteBoletosState.count,
    sortField: state.crudLoteBoletosState.sortField,
    order: state.crudLoteBoletosState.sortOrder,
    selectedFilters: state.crudLoteBoletosState.selectedFilters,
    showHideColumns: state.crudLoteBoletosState.showHideColumns,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    lookupConfiguracaoBoletoSetVisible: (visible) => dispatch(actions.setLookupConfiguracaoBoletoVisible(visible)),
    lookupConfiguracaoBoletoPaginate: (paginationParams) =>
      dispatch(configuracaoBoletoActions.paginate(paginationParams)),
    lookupConfiguracaoBoletoSortOrder: (sortParams) => dispatch(configuracaoBoletoActions.sortOrder(sortParams)),
    lookupConfiguracaoBoletoApplyFilter: (filterParams) => {
      dispatch(configuracaoBoletoActions.setType(null))
      dispatch(configuracaoBoletoActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(configuracaoBoletoActions.applyFilter(filterParams))
    },
    lookupConfiguracaoBoletoLoad: () => dispatch(configuracaoBoletoActions.loadLookup()),

    lookupTaxaSetVisible: (visible) => dispatch(actions.setLookupTaxaVisible(visible)),
    lookupTaxaPaginate: (paginationParams) => dispatch(taxaActions.loadTaxasValidas(paginationParams)),
    lookupTaxaSortOrder: (paginationParams) => dispatch(taxaActions.loadTaxasValidas(paginationParams)),
    lookupTaxaApplyFilter: (filterParams) => {
      dispatch(taxaActions.setFiltroAvancado(filterParams))
      dispatch(taxaActions.loadLookup())
    },
    lookupTaxaLoad: () => dispatch(taxaActions.loadTaxasValidas()),

    lookupContratoSetVisible: (visible) => dispatch(actions.setLookupContratoVisible(visible)),
    lookupContratoPaginate: (paginationParams) => dispatch(contratoActions.loadLookup(paginationParams)),
    lookupContratoSortOrder: (sortParams) => dispatch(contratoActions.loadLookup(sortParams)),
    lookupContratoApplyFilter: (filterParams) => {
      dispatch(contratoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(contratoActions.loadLookup())
    },
    lookupContratoLoad: () => dispatch(contratoActions.loadLookup()),

    loadConfiguracaoBoleto: (oid) => dispatch(actions.loadConfiguracaoBoletoPadrao(oid)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'unidade') {
      }
      dispatch(actions.setField(field, valor))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      if (campo === 'valor') {
        target.value = target.value ? target.value.replace(',', '.') : target.value
      }
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        //dispatch(actions.executeFilter(`/lottustech/loteboletos/all`));
      }, 400)
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => {
      dispatch(actions.loadById(oid))
    },
    onClickGerar: () => {
      dispatch(actions.gerarBoletos())
    },
    clearSelectedFilters: () => dispatch(configuracaoBoletoActions.clearSelectedFilters()),
    limparDados: () => {
      dispatch(actions.makeNew())
      dispatch(actions.setRecords([]))
    },
  }
}

class LoteBoletosFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showDialogRemove = false
    this.showHideColumns = []
    actions.afterSave = (dados) => {}

    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CONTROLE_FINANCEIRO}`)
    }
  }

  componentDidMount() {
    logarAcesso().then()
    //SUGERIR CONFIGURACAO DE BOLETO PADRAO DA EMPRESA
    const oidConfigPadraoBoleto = utils.getParametroEmpresaCorrente('oidConfiguracaoPadraoBoleto', storage.getCompany())
    if (oidConfigPadraoBoleto && oidConfigPadraoBoleto.value) {
      this.props.loadConfiguracaoBoleto(parseInt(oidConfigPadraoBoleto.value))
    }
  }

  componentWillUnmount() {
    this.props.limparDados()
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  // REFERENTE AO LOOKUP PARA CONFIGURACAO DE BOLETO
  lookupConfiguracaoBoletoOnPage = (e) => {
    this.props.lookupConfiguracaoBoletoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupConfiguracaoBoletoOnSort = (e) => {
    this.props.lookupConfiguracaoBoletoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupConfiguracaoBoletoOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupConfiguracaoBoletoApplyFilter(filter)
    })
    this.props.lookupConfiguracaoBoletoLoad()
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupConfiguracaoBoletoLoad()
    }
  }

  lookupConfiguracaoBoletoOnClick = (e) => {
    this.props.lookupConfiguracaoBoletoLoad()
    this.props.lookupConfiguracaoBoletoSetVisible(true)
  }
  lookupConfiguracaoBoletoOnHide = (e) => {
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnConfirm = (e) => {
    this.props.setField('configuracaoBoleto', { value: e.selection.nome, id: e.selection.oid })
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnCancel = (e) => {
    this.props.lookupConfiguracaoBoletoSetVisible(false)
  }
  lookupConfiguracaoBoletoOnEdit = () => {
    if (this.props.record.configuracaoBoleto && this.props.record.configuracaoBoleto.id) {
      this.props.history.push(`${routes.PATH_CONFIGURACAO_BOLETO}/${this.props.record.configuracaoBoleto.id}`)
    } else {
      this.props.history.push(`${routes.PATH_CONFIGURACAO_BOLETO}`)
    }
  }
  lookupConfiguracaoBoletoOnComplete = (e) => {}

  lookupConfiguracaoBoletoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filters = {
        campo: 'nome',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      let grupo = { value: e.value, id: null }
      this.props.setField('configuracaoBoleto', grupo)
      this.props.lookupConfiguracaoBoletoApplyFilter(filters)
    } else {
      this.props.setField('configuracaoBoleto', { value: value.descricao, id: value.oid })
      const filters = {
        campo: 'nome',
        valor: '',
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupConfiguracaoBoletoApplyFilter(filters)
    }
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  pagadorTemplate = (rowData) => {
    if (rowData.pagador && rowData.pagador.entidadeLegal) {
      return <div>{rowData.pagador.entidadeLegal.nome}</div>
    }
  }

  vencimentoTemplate = (rowData) => {
    if (rowData.vencimento && rowData.vencimento.dataVencimento) {
      return <div>{utils.formatarData(rowData.vencimento.dataVencimento)}</div>
    }
  }

  valorTemplate = (rowData) => {
    if (rowData.valorBoleto && rowData.valorBoleto.valorTitulo) {
      return <div>{utils.formatarDinheiro(rowData.valorBoleto.valorTitulo)}</div>
    }
  }

  // LOOKUP TAXA
  lookupTaxaOnPage = (e) => {
    this.props.lookupTaxaPaginate({
      max: e.rows,
      index: e.first,
    })
  }

  lookupTaxaOnSort = (e) => {
    this.props.lookupTaxaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupTaxaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {}
      // é necessário este tratamento devido a taxa ter um grupo financeiro.
      if (key === 'grupoFinanceiro.value') {
        filter = {
          campo: 'descricao',
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
          relacionamento: ['grupoFinanceiro'],
        }
      } else {
        filter = {
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
        }
      }
      this.props.lookupTaxaApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupTaxaLoad()
    }
  }

  lookupTaxaOnClick = (e) => {
    this.props.lookupTaxaLoad()
    this.props.lookupTaxaSetVisible(true)
  }
  lookupTaxaOnHide = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnConfirm = (e) => {
    const taxa = e.selection
    const value = utils.formatTaxaValueForLookup(taxa)
    this.props.setField('taxa.id', taxa ? taxa.oid : null)
    this.props.setField('taxa.value', value)
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnCancel = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnEdit = () => {
    if (this.props.record.taxa && this.props.record.taxa.id) {
      this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}/${this.props.record.taxa.id}`)
    } else {
      this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}`)
    }
  }
  lookupTaxaOnComplete = (e) => {}

  lookupTaxaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filters = {
        campo: 'descricao',
        valor: e.value,
        tipoFiltro: 'CONTEM',
        relacionamento: ['grupoFinanceiro'],
      }
      this.props.setField('taxa.id', null)
      this.props.setField('taxa.value', e.value)
      this.props.lookupTaxaApplyFilter(filters)
    } else {
      this.props.setField('taxa.id', e.value.oid)
      this.props.setField('taxa.value', e.value.grupoFinanceiro.value)
      const filters = [{}]
      this.props.lookupTaxaApplyFilter(filters)
    }
  }
  // ---------------

  //LOOKUP CONTRATO
  // REFERENTE AO LOOKUP PARA CONTRATO
  lookupContratoOnPage = (e) => {
    this.props.lookupContratoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupContratoOnSort = (e) => {
    this.props.lookupContratoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupContratoOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupContratoApplyFilter(filter)
    })
    this.props.lookupContratoLoad()
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupContratoLoad()
    }
  }

  lookupContratoOnClick = (e) => {
    this.props.lookupContratoLoad()
    this.props.lookupContratoSetVisible(true)
  }
  lookupContratoOnHide = (e) => {
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnConfirm = (e) => {
    const contrato = e.selection
    this.props.setField('contrato.id', contrato ? contrato.oid : null)
    this.props.setField('contrato.value', contrato.localizacao_identificacao)
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnCancel = (e) => {
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnEdit = () => {
    if (this.props.record.contrato && this.props.record.contrato.id) {
      this.props.history.push(`${routes.PATH_CONTRATO}/${this.props.record.contrato.id}`)
    } else {
      this.props.history.push(`${routes.PATH_CONTRATO}`)
    }
  }
  lookupContratoOnComplete = (e) => {}

  lookupContratoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'localizacao_identificacao',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      this.props.setField('contrato.id', '-1')
      this.props.setField('contrato.value', e.value)
      this.props.lookupContratoApplyFilter(filter)
    } else {
      this.props.setField('contrato.id', e.value.oid)
      this.props.setField('contrato.value', e.value.localizacao_identificacao)
      const filters = [{}]
      this.props.lookupContratoApplyFilter(filters)
    }
  }
  // ---------------

  oidTemplateLancamento = (rowData) => {
    return (
      <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oidLancamentoFinanceiro}`}>
        {rowData.oidLancamentoFinanceiro}
      </Link>
    )
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      } else if (col.field === 'oidLancamentoFinanceiro') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplateLancamento}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  render() {
    const content = (
      <LoteBoletosForm
        records={this.props.records}
        totalRecords={this.props.totalRecords}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        onComplete={this.props.onComplete}
        record={this.props.record}
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
        first={this.props.first}
        rows={this.props.rows}
        order={this.props.order}
        sortField={this.props.sortField}
        onClickGerar={this.props.onClickGerar}
        lookupConfiguracaoBoleto={this.props.lookupConfiguracaoBoleto}
        lookupConfiguracaoBoletoOnHide={this.lookupConfiguracaoBoletoOnHide}
        lookupConfiguracaoBoletoOnConfirm={this.lookupConfiguracaoBoletoOnConfirm}
        lookupConfiguracaoBoletoOnCancel={this.lookupConfiguracaoBoletoOnCancel}
        lookupConfiguracaoBoletoOnPage={this.lookupConfiguracaoBoletoOnPage}
        lookupConfiguracaoBoletoOnSort={this.lookupConfiguracaoBoletoOnSort}
        lookupConfiguracaoBoletoOnFilter={this.lookupConfiguracaoBoletoOnFilter}
        lookupConfiguracaoBoletoOnClick={this.lookupConfiguracaoBoletoOnClick}
        lookupConfiguracaoBoletoOnEdit={this.lookupConfiguracaoBoletoOnEdit}
        lookupConfiguracaoBoletoOnComplete={this.lookupConfiguracaoBoletoOnComplete}
        lookupConfiguracaoBoletoOnInputFilter={this.lookupConfiguracaoBoletoOnInputFilter}
        lookupTaxa={this.props.lookupTaxa}
        lookupTaxaOnHide={this.lookupTaxaOnHide}
        lookupTaxaOnConfirm={this.lookupTaxaOnConfirm}
        lookupTaxaOnCancel={this.lookupTaxaOnCancel}
        lookupTaxaOnPage={this.lookupTaxaOnPage}
        lookupTaxaOnSort={this.lookupTaxaOnSort}
        lookupTaxaOnFilter={this.lookupTaxaOnFilter}
        lookupTaxaOnClick={this.lookupTaxaOnClick}
        lookupTaxaOnEdit={this.lookupTaxaOnEdit}
        lookupTaxaOnComplete={this.lookupTaxaOnComplete}
        lookupTaxaOnInputFilter={this.lookupTaxaOnInputFilter}
        lookupContrato={this.props.lookupContrato}
        lookupContratoOnHide={this.lookupContratoOnHide}
        lookupContratoOnConfirm={this.lookupContratoOnConfirm}
        lookupContratoOnCancel={this.lookupContratoOnCancel}
        lookupContratoOnPage={this.lookupContratoOnPage}
        lookupContratoOnSort={this.lookupContratoOnSort}
        lookupContratoOnFilter={this.lookupContratoOnFilter}
        lookupContratoOnClick={this.lookupContratoOnClick}
        lookupContratoOnEdit={this.lookupContratoOnEdit}
        lookupContratoOnComplete={this.lookupContratoOnComplete}
        lookupContratoOnInputFilter={this.lookupContratoOnInputFilter}
      />
    )

    return <Page content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoteBoletosFormPageContainer)
