import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

export class AppBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsBreadCrumb: props.itemsBreadCrumb
        }
        this.clickItemBreadCrumb = props.clickItemBreadCrumb
        this.history = props.history
    }

    static propTypes = {
        match: PropTypes.object
    }

    render() {
        if (this.state.itemsBreadCrumb.length > 0) {
            return <div className="layout-breadcrumb" style={{paddingTop: "30px"}}>
                <ul>
                    <li>
                        <button className="p-link" style={{marginBottom: "10px"}}
                                onClick={(e) => {
                                    this.clickItemBreadCrumb(e, this.props, this.state.itemsBreadCrumb[0], 0);
                                    this.history.push("/")
                                }}>
                            <i className="pi pi-home"/></button>
                    </li>

                    {this.state.itemsBreadCrumb.map((item, index) => (
                        <li style={{marginBottom: "10px"}} onClick={(e) =>
                            this.clickItemBreadCrumb(e, this.props, item, index)}>
                            <a style={{cursor: 'pointer'}}>{"/" + item.label}</a>
                        </li>))
                    }
                </ul>
            </div>;
        }
        return (
            <div className="layout-breadcrumb" style={{paddingTop: "30px"}}>
                <ul>
                    <li>
                        <button className="p-link" style={{marginBottom: "10px"}}><i className="pi pi-home"/></button>
                    </li>
                    <li onClick={this.clickItemBreadCrumb} style={{marginBottom: "10px"}}>{"/"} </li>
                </ul>
            </div>
        );

    }
}