import React from "react";
import {InputText} from "primereact/inputtext";
import utils from "../../../utils/index";
import "./MarcadorForm.scss";

export const MarcadorForm = props => (
    <div className="p-grid p-fluid">
        <div className="p-col-12">
            <h3>
                {utils.getMessage(props.messages, "marcador.label.marcador")} {props.record.oid || ""}
            </h3>
            <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                  <span className="p-float-label">
                    <InputText value={props.record.descricao} onChange={props.changeHandler} name="descricao"/>
                    <label>{utils.getMessage(props.messages, "marcador.label.descricao")}</label>
                  </span>
                </div>
            </div>
        </div>
    </div>
);
