import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import { getMessage } from '../../../utils/index'
import utils from '../../../utils/index'

import {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onPage,
  onSort,
  onCsvExport,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../contaCorrente.actions'
import { ContaCorrenteList } from './ContaCorrenteList'
import '../../../index.scss'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudContaCorrenteState.showHideColumns,
    messages: state.crudContaCorrenteState.messages,
    columns: state.crudContaCorrenteState.columns,
    records: state.crudContaCorrenteState.records,
    first: state.crudContaCorrenteState.index,
    rows: state.crudContaCorrenteState.max,
    totalRecords: state.crudContaCorrenteState.count,
    sortField: state.crudContaCorrenteState.sortField,
    order: state.crudContaCorrenteState.sortOrder,
    selectedFilters: state.crudContaCorrenteState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/contascorrente/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class ContaCorrenteListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CONTA_CORRENTE}/novo`)
  }
  componentDidMount() {
    componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={getMessage(this.props.messages, 'contacorrente.label.contascorrente')}
      />
    )
  }

  oid = (rowData) => {
    return <Link to={`${routes.PATH_CONTA_CORRENTE}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oid}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-oid-body-width"
            filterHeaderClassName="table-column-oid-filter-header-width"
            headerClassName="table-column-oid-header-width"
          />
        )
      }

      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.descricao}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-descricao-body-width"
            filterHeaderClassName="table-descricao-filter-header-width"
            headerClassName="table-column-descricao-header-width"
          />
        )
      }

      if (col.field === 'numero') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.numero}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-numero-conta-body-width"
            filterHeaderClassName="table-column-numero-conta-filter-header-width"
            headerClassName="table-column-numero-conta-header-width"
          />
        )
      }

      if (col.field === 'agencia') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.agencia}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-agencia-body-width"
            filterHeaderClassName="table-column-agencia-filter-header-width"
            headerClassName="table-column-agencia-header-width"
          />
        )
      }

      if (col.field === 'banco') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.banco}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-banco-body-width"
            filterHeaderClassName="table-column-banco-filter-header-width"
            headerClassName="table-column-banco-header-width"
          />
        )
      }

      if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }

      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }
  buildContent = () => {
    return (
      <ContaCorrenteList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContaCorrenteListPageContainer)
