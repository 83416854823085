import React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { connect } from 'react-redux'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { onCsvExport } from '../../../utils/listPageContainerUtils'
import utils from '../../../utils/index'
import storage from '../../../services/stoage.service'
import { actions } from '../dinamica.actions'
import { icons } from '../../../constants'
import { DinamicaList } from './DinamicaList'

const mapStateToProps = (state) => {
  const filterOptions = utils.getFiltersOptionsIdValue(storage.getAppLabels('filtros'))
  const tiposFiltros = utils.getFiltersIdValue(storage.getAppLabels('filtros'))
  const tiposFiltrosString = utils.getFiltersStringIdValue(storage.getAppLabels('filtros'))

  return {
    showHideColumns: state.crudDinamicaState.showHideColumns,
    messages: state.crudDinamicaState.messages,
    columns: state.crudDinamicaState.columns,
    records: state.crudDinamicaState.records,
    first: state.crudDinamicaState.index,
    rows: state.crudDinamicaState.max,
    totalRecords: state.crudDinamicaState.count,
    sortField: state.crudDinamicaState.sortField,
    order: state.crudDinamicaState.sortOrder,
    selectedFilters: state.crudDinamicaState.selectedFilters,
    title: state.crudDinamicaState.title,
    tiposFiltros,
    tiposFiltrosString,
    filterOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/dynamicdata/all`))
      }, 400)
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      const value = target.value
      const hash = target.id
      dispatch(actions.setAdvancedFilter(field, value, hash))
    },
    load: () => {
      dispatch(actions.load())
    },
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },

    // POR AGORA DESABILITADO PARA ROTA DINAMICA POIS PRECISAMOS DO oidMenu no ACTIONS.FACTORY
    // removeFavorito: menu => {
    //   dispatch(actions.removeFavorito(menu));
    // },
    // adicionarFavorito: menu => {
    //   dispatch(actions.adicionarFavorito(menu));
    // },
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    clearSortFields: () => dispatch(actions.clearSortFields()),
    pesquisaAvancada: (state) => {
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/dynamicdata/all`))
      }, 400)
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
  }
}

class DinamicaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showDialogVisao = false
    this.showColumnButton = false
    this.showFilter = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  // MÉTODO EXPORTAÇÃO DE CSV
  // exportar() {
  //   if (this.props.records) {
  //     this.props.exportarDadosCSV();
  //     actions.afterGetExportDataset = dados => {
  //       this.exportCSV(dados.records, this.props.columns);
  //     };
  //   }
  // }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  exportCSV(data, colunas) {
    var csv = '\uFEFF'
    const columns = colunas.filter((col) => col.visible)
    for (var i = 0; i < columns.length; i++) {
      if (columns[i].header) {
        csv += '"' + columns[i].header + '"'

        if (i < columns.length - 1) {
          csv += ','
        }
      }
    }

    //body
    data.forEach(function (record, i) {
      csv += '\n'

      for (var _i = 0; _i < columns.length; _i++) {
        if (columns[_i].columnKey) {
          csv += '"'

          if (record && columns[_i].columnKey) {
            csv += record[columns[_i].columnKey] + '"'
          } else {
            csv += ''
          }

          if (_i < columns.length - 1) {
            csv += ','
          }
        }
      }
    })

    var blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;',
    })

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, this.props.exportFilename + '.csv')
    } else {
      var link = document.createElement('a')
      link.style.display = 'none'
      document.body.appendChild(link)

      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob))
        link.setAttribute('download', 'relatorio.csv')
        link.click()
      } else {
        csv = 'data:text/csv;charset=utf-8,' + csv
        window.open(encodeURI(csv))
      }

      document.body.removeChild(link)
    }
  }

  loadFavorito() {
    //loadFavorito(this, actions);
  }

  changeFavorito() {
    //changeFavorito(this, actions);
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location && prevProps.location) {
      if (
        this.props.location.pathname &&
        prevProps.location.pathname &&
        this.props.location.pathname !== prevProps.location.pathname
      ) {
        this.props.clearSelectedFilters()
        this.props.clearSortFields()
      }
    }
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title={this.props.title} />
  }

  linkField = (rowData, column) => {
    if (rowData && rowData[column.idLink]) {
      return <Link to={`${column.linkField}/${rowData[column.idLink]}`}>{rowData[column.field]}</Link>
    }
    return null
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.linkField && col.idLink && utils.isStringNotBlank(col.linkField)) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.linkField}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }

      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  addHash(objeto) {
    if (objeto) {
      objeto.hash = objeto.campo + '__' + objeto.descricao
    }
  }

  parseFilter = (value) => {
    return utils.parseFilter(this.props.filterOptions, value)
  }

  getTiposFiltros = (filter) => {
    if (utils.isTipoDadoString(filter)) {
      return this.props.tiposFiltrosString
    }
    return this.props.tiposFiltros
  }

  getOpcaoValorComplementar = (filter) => {
    if (utils.isTipoDadoString(filter) || !utils.isFilterValorComplementar(this.props.filterOptions, filter)) {
      return ''
    }

    if (!filter.valorComplementar) {
      filter.valorComplementar = null
    }

    if (utils.isTipoDadoData(filter)) {
      return (
        <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
          <div className="p-grid form-group">
            <label className="label">Valor Complementar</label>
          </div>
          <div className="p-grid form-group">
            <Calendar
              value={utils.getDateFromNumber(filter.valorComplementar)}
              monthNavigator={true}
              onChange={this.props.changeHandler}
              name="valorComplementar"
              id={filter.hash}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
        <span className="md-inputfield">
          <InputText
            value={filter.valorComplementar}
            onChange={this.props.changeHandler}
            name="valorComplementar"
            id={filter.hash}
          />
          <label>Valor Complementar</label>
        </span>
      </div>
    )
  }

  getOpcaoValor = (filter) => {
    if (utils.isTipoDadoData(filter)) {
      return (
        <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
          <div className="p-grid form-group">
            <label className="label">Valor</label>
          </div>
          <div className="p-grid form-group">
            <Calendar
              value={utils.getDateFromNumber(filter.valor)}
              monthNavigator={true}
              onChange={this.props.changeHandler}
              name="valor"
              id={filter.hash}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
        <div className="p-grid form-group">
          <label className="label">Valor</label>
        </div>
        <div className="p-grid form-group">
          <InputText value={filter.valor} onChange={this.props.changeHandler} name="valor" id={filter.hash} />
        </div>
      </div>
    )
  }

  getTipoFiltro = (filter) => {
    return (
      <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
        <Dropdown
          autoWidth={false}
          value={this.parseFilter(filter.tipoFiltro)}
          options={this.getTiposFiltros(filter)}
          onChange={this.props.changeHandler}
          placeholder="Tipo"
          optionLabel="value"
          optionValue={(v) => v}
          name="tipoFiltro"
          id={filter.hash}
        />
      </div>
    )
  }

  getDescricao = (filter) => {
    return (
      <div className="p-col-12 p-sm-2 p-md-2 p-lg-2 p-xl-2">
        <span className="md-inputfield">
          <label>
            <b>{filter.descricao}</b>
          </label>
        </span>
      </div>
    )
  }

  templateFiltro = (filter) => {
    return (
      <div>
        <div className="p-grid form-group">
          {this.getDescricao(filter)}
          {this.getTipoFiltro(filter)}
          {this.getOpcaoValor(filter)}
          {this.getOpcaoValorComplementar(filter)}
        </div>
      </div>
    )
  }

  buildAdvancedFilters = () => {
    if (this.showFilter && utils.isArrayNotEmpty(this.props.selectedFilters)) {
      return (
        <div>
          <br />
          {this.props.selectedFilters
            .filter((f) => f.visivel)
            .map((filter) => {
              this.addHash(filter)
              return this.templateFiltro(filter)
            })}
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  buildContent = () => {
    return (
      <DinamicaList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DinamicaListPageContainer)
