import React from "react";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import utils from "../../utils";
import { OID_PERFIL_PORTAL_CLIENTE, OID_PERFIL_PORTAL_CONVENIADO } from "../../constants/index";
import _ from "lodash";

export class VisaoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calcularData: false,

      nomeVisao: "",

      inicialValor: 1,
      inicialModificador: "",
      inicialPeriodo: 1,
      inicialPreview: window.moment(),

      finalValor: 1,
      finalModificador: "",
      finalPeriodo: 1,
      finalPreview: window.moment(),

      filtrosValorVisao: [
        { label: "Data atual", value: 1 },
        { label: "Início mês", value: 2 },
        { label: "Final mês", value: 3 }
      ],
      filtrosPeriodoVisao: [{ label: "Dia", value: 1 }, { label: "Semana", value: 2 }, { label: "Mês", value: 3 }],
      perfisDisponiveis: props.perfis.filter(perfil => perfil.oid !== OID_PERFIL_PORTAL_CLIENTE && perfil.oid !== OID_PERFIL_PORTAL_CONVENIADO),
      perfisSelecionados: []
    };
  }

  onChangeCheckBox = event => {
    const target = event.target;
    const field = target.name;
    let valor = target.checked;
    this.setState({ [field]: valor });
  };

  onChangeData = event => {
    const target = _.defaultTo(event.target, _.get(event, 'originalEvent.target', null));
    if (target) {
      const field = target.name;
      let valor = target.value;
      this.setState({[field]: valor});
      this.setState({calcularData: true});
    }
  };

  calcularData = campo => {
    let dataPreview = null;
    if (this.state[`${campo}Valor`] === 1) {
      dataPreview = window.moment();
    } else if (this.state[`${campo}Valor`] === 2) {
      dataPreview = window.moment().startOf("month");
    } else if (this.state[`${campo}Valor`] === 3) {
      dataPreview = window.moment().endOf("month");
    }
    let typeadd = "";
    if (this.state[`${campo}Periodo`] === 1) {
      typeadd = "d";
    } else if (this.state[`${campo}Periodo`] === 2) {
      typeadd = "w";
    } else if (this.state[`${campo}Periodo`] === 3) {
      typeadd = "M";
    }
    dataPreview = dataPreview.add(this.state[`${campo}Modificador`], typeadd);
    this.setState({ [`${campo}Preview`]: dataPreview });
  };

  onChangePerfis = e => {
    this.setState({
      perfisDisponiveis: e.source,
      perfisSelecionados: e.target
    });
  };

  salvarState = () => {
    let perfis = [];
    if (this.state.perfisSelecionados) {
      this.state.perfisSelecionados.forEach(perfil => {
        perfis.push({ id: perfil.oid, value: perfil.nomePerfil });
      });
    }

    const filters = utils.parseTipoFiltro(this.props.selectedFilters);
    filters.forEach(f => {
      f.chaveComponente = f.chaveComponente || f.campo

      if (this.props.filterValues[f.chaveComponente]) {
        f.valorComponente = JSON.stringify(this.props.filterValues[f.chaveComponente].valor)
        f.valorComponenteComplementar = JSON.stringify(this.props.filterValues[f.chaveComponente].valorComplementar)
      }
    })

    const dados = {
      nome: this.state.nomeVisao,
      perfis: perfis,
      filters: filters
    };
    this.props.salvarState(dados, this.state);
    this.props.listPageContainer.setState({showDialogVisao: false})
  };

  render() {
    if (this.state.calcularData) {
      this.calcularData("inicial");
      this.calcularData("final");
      this.setState({ calcularData: false });
    }
    const perfisDisponiveis =
      this.state.perfisDisponiveis &&
      this.state.perfisDisponiveis.length === 0 &&
      this.state.perfisSelecionados.length === 0
        ? this.props.perfis
        : this.state.perfisDisponiveis;
    return (
      <div>
        <div className="p-grid form-group">
          <div className="p-col-8" style={{ marginTop: "5px" }}>
            <span className="p-float-label">
              <InputText
                value={this.state.nomeVisao}
                onChange={e => this.setState({ nomeVisao: e.target.value })}
                name="nomeVisao"
              />
              <label>Nome visão</label>
            </span>
          </div>
        </div>
        <br />
        <div className="p-grid form-group">
          <div className="p-col-8">
            <PickList
              responsive={true}
              source={perfisDisponiveis}
              target={this.state.perfisSelecionados}
              onChange={this.onChangePerfis}
              itemTemplate={perfil => (
                <label>
                  {perfil.oid} - {perfil.nomePerfil}
                </label>
              )}
              sourceHeader="Disponíveis"
              targetHeader="Selecionados"
              showSourceControls={false}
              showTargetControls={false}
            />
          </div>
        </div>
        <br />
        {this.props.defineDynamicFilters(this.state, this.onChangeData, this.onChangeCheckBox)}
        <div className="p-col-4">
          <Button onClick={e => this.salvarState()} label="Salvar" icon="pi pi-check" className="p-button-success" />
        </div>
      </div>
    );
  }
}
