import React from 'react';
import ConvenioListPageContainer from './ConvenioListPageContainer';

export class ConvenioListPage extends React.Component{
    
    render(){
      return (
        <ConvenioListPageContainer {...this.props}/>
      )
    }
}


