const blankToNull = require('./../../../utils').blankToNull

export function validate(record) {
  const errors = []
  if (!record.posicaoX) {
    errors.push({
      field: 'posicaoX',
      message: 'O campo posição X é obrigatório',
    })
  }
  if (!record.posicaoY) {
    errors.push({
      field: 'posicaoY',
      message: 'O campo posição Y é obrigatório',
    })
  }
  if (!record.mapa) {
    errors.push({
      field: 'mapa',
      message: 'O campo Mapa é obrigatório',
    })
  }
  if (!record.mapa) {
    errors.push({
      field: 'mapa',
      message: 'O campo Mapa é obrigatório',
    })
  }
  blankToNull(record)
  const result = {
    ok: errors.length === 0,
    errors,
    record,
  }
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}
