import React from "react";

export default function OrcamentoFormTitle(props) {

    let fontColor = "#ffffff"; // nao definido
    if (props.status === 1) {
        fontColor = "#607d8b" // criado
    } else if (props.status === 2) { // Confirmado
        fontColor = "#4CAF50"
    } else if (props.status === 3) { // Cancelado
        fontColor = "#ff4d4d"
    }

    const resolveLabelStatus = () => {
        if (props.status === 1) {
            return 'Criado'
        }
        if (props.status === 2) {
            return 'Confirmado'
        }
        if (props.status === 3) {
            return 'Cancelado'
        }
        return ''

    }

    return (
        <div>
            <h4>
                {`Orçamento ${props.oid || ""}`}
                <span className="tag" style={{marginLeft: "15px", backgroundColor: fontColor}}>
                    {resolveLabelStatus()}
                </span>
            </h4>
        </div>
    )
}