import { types } from './produto.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import { enums } from '../../constants'
import utils from '../../utils'

const initialState = {
  id: 'produto',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Id',
      value: {
        field: 'oid',
        header: 'Id',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'codigo',
        header: 'Código',
        columnKey: 'codigo',
        sortable: true,
        columnSortField: 'codigo',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Descrição',
      value: {
        field: 'descricao',
        header: 'Descrição',
        columnKey: 'descricao',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Tipo de produto',
      value: {
        field: 'tipoProduto',
        header: 'Tipo de produto',
        columnKey: 'tipoProduto',
        sortable: true,
        columnSortField: 'tipoProduto',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Quantidade mínima',
      value: {
        field: 'quantidadeMinima',
        header: 'Quantidade mínima',
        columnKey: 'quantidadeMinima',
        sortable: true,
        columnSortField: 'quantidadeMinima',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Saldo',
      value: {
        field: 'saldo',
        header: 'Saldo',
        columnKey: 'saldo',
        sortable: true,
        columnSortField: 'saldo',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        columnKey: 'unidade.sigla',
        sortable: true,
        header: 'Unidade',
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'codigo',
      header: 'Código',
      columnKey: 'codigo',
      sortable: true,
      columnSortField: 'codigo',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    codigo: '',
    descricao: '',
    detalhamento: '',
    tipoProduto: '',
    quantidadeMinima: '',
    saldo: '',
    valor: '',
    unidade: '',
    fornecedores: [],
  },
  messages: null,
  usaListDto: false,
  colunasFornecedores: [
    {
      field: 'oid',
      header: 'Oid',
      columnKey: 'oid',
    },
    {
      field: 'oidFornecedor',
      header: 'Código',
      columnKey: 'oidFornecedor',
    },
    {
      field: 'documentoFornecedor',
      header: 'CPF/CNPJ',
      columnKey: 'documentoFornecedor',
    },
    {
      field: 'nomeFornecedor',
      header: 'Nome',
      columnKey: 'nomeFornecedor',
    },
    {
      field: 'codigo',
      header: 'Código produto',
      columnKey: 'codigo',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  adicionandoAnexo: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDProdutoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('produto')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    case types.MAKE_NEW:
      return initialize(state)
    case types.SET_ADICIONANDO_ANEXO:
      return Object.assign({}, state, { adicionandoAnexo: action.payload })
    case types.ADD_ANEXO:
      return addAnexo(state, action)
    case types.REMOVE_ANEXO:
      return removeAnexo(state, action)
    default:
      return state
  }
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
  }
}

const initialize = (state) => {
  let newState = checkUnidadeCorrente(state)
  const currentRecord = newState.currentRecord
  currentRecord.tipoProduto = enums.TipoProduto[0] // produto
  return Object.assign({}, state, { currentRecord: currentRecord })
}

const checkUnidadeCorrente = (state) => {
  const currentRecord = state.currentRecord
  const currentUnit = storage.getUnit()
  let initValue = { id: -1, value: `<Todas>` }
  if (currentUnit) {
    initValue = { id: currentUnit.oid, value: currentUnit.sigla }
  }
  currentRecord.unidade = initValue
  return Object.assign({}, state, { currentRecord: currentRecord })
}

function addAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexos = action.payload
  if (anexos) {
    currentRecord.anexos.push(...anexos)
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}

function removeAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexoRemover = action.payload
  if (anexoRemover) {
    const anexos = currentRecord.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find((f) => f.filename === anexoRemover.filename)
      if (temp) {
        const index = anexos.indexOf(temp)
        anexos.splice(index, 1)
      }
    }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}
