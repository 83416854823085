import React from 'react';
import ContratoListPageContainer from './ContratoListPageContainer';

export class ContratoListPage extends React.Component{
    
    render(){
      return (
        <ContratoListPageContainer {...this.props}/>
      )
    }
}

