import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import _ from 'lodash'
import { messageActions } from '../../store/message'
import utils from '../../utils/index'

const BASE_PATH = '/lottustech/produtos'
const crud = build(validate, BASE_PATH)
const prefix = 'crudProduto'

const newTypes = {
  RESET: `${prefix}reset`,
  SET_ADICIONANDO_ANEXO: `${prefix}adicionandoAnexo`,
  ADD_ANEXO: `${prefix}addAnexo`,
  REMOVE_ANEXO: `${prefix}removeAnexo`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.addAnexo = (payload) => {
  return {
    type: types.ADD_ANEXO,
    payload,
  }
}

actions.removeAnexo = (payload) => {
  return {
    type: types.REMOVE_ANEXO,
    payload,
  }
}

actions.setAdicionandoAnexo = (payload) => {
  return {
    type: types.SET_ADICIONANDO_ANEXO,
    payload,
  }
}

actions.processaAnexo = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      dispatch(actions.setAdicionandoAnexo(true))
      const msgErro = 'Erro ao adicionar imagem'

      const state = getState()[`${prefix}State`]
      const record = state.currentRecord
      const oidProduto = record.oid

      const file = data.target.files[0]
      const form = new FormData()
      form.append('anexo', file)

      apiService
        .postFormData(`${BASE_PATH}/${oidProduto}/addImagem`, form, { nomeAnexo: file.name })
        .then((response) => {
          const anexos = _.get(response, 'data', null)
          dispatch(actions.addAnexo(anexos))
          dispatch(actions.setAdicionandoAnexo(false))
        })
        .catch((error) => {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
          dispatch(actions.setAdicionandoAnexo(false))
        })
    }
  }
}

actions.downloadAnexo = (anexo) => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then((response) => {
        utils.createAndDownloadBlobFile(response.data, anexo.filename)
      })
    }
  }
}

const makeMessages = (messages, type) => {
  let sticky = type === 'error' || type === 'warn'
  return messages.map((message) => {
    return {
      sticky,
      severity: type,
      detail: message,
    }
  })
}

export { types, actions }
