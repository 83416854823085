import React from 'react';

import CartaoCreditoRelatorioFormPageContainer from './CartaoCreditoRelatorioFormPageContainer';

export class CartaoCreditoRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <CartaoCreditoRelatorioFormPageContainer {...this.props} />
        )
    }
}
