import { types } from "./pessoaJuridica.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "pessoaJuridica",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        columnSortField: "oid",
        filter: true,
        visible: false
      }
    },
    {
      label: "CNPJ",
      value: {
        field: "numeroDocumento",
        header: "CNPJ",
        columnKey: "numeroDocumento",
        sortable: true,
        columnSortField: "numeroDocumento",
        filter: true,
        visible: true
      }
    },
    {
      label: "Razão Social",
      value: {
        field: "nome",
        header: "Razão Social",
        columnKey: "nome",
        sortable: true,
        columnSortField: "nome",
        filter: true,
        visible: true
      }
    },
    {
      label: "Nome Fantasia",
      value: {
        field: "nomeFantasia",
        header: "Nome Fantasia",
        columnKey: "nomeFantasia",
        sortable: true,
        columnSortField: "nomeFantasia",
        filter: true,
        visible: true
      }
    },
    {
      label: "Bairro",
      value: {
        field: "bairro",
        header: "Bairro",
        columnKey: "bairro",
        sortable: true,
        columnSortField: "bairro",
        filter: true,
        visible: false
      }
    },
    {
      label: "Cidade",
      value: {
        field: "cidade",
        header: "Cidade",
        columnKey: "cidade",
        sortable: true,
        columnSortField: "cidade",
        filter: true,
        visible: false
      }
    },
    {
      label: "Rua",
      value: {
        field: "nomeLogradouro",
        header: "Rua",
        columnKey: "nomeLogradouro",
        sortable: true,
        columnSortField: "nomeLogradouro",
        filter: true,
        visible: false
      }
    },
    {
      label: "UF",
      value: {
        field: "uf",
        header: "UF",
        columnKey: "uf",
        sortable: true,
        columnSortField: "uf",
        filter: true,
        visible: false
      }
    }
  ],
  columnsLookup: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      columnSortField: "oid",
      filter: true,
      visible: false
    },
    {
      field: "numeroDocumento",
      header: "CNPJ",
      columnKey: "numeroDocumento",
      sortable: true,
      columnSortField: "numeroDocumento",
      filter: true,
      visible: true
    },
    {
      field: "nome",
      header: "Razão Social",
      columnKey: "nome",
      sortable: true,
      columnSortField: "nome",
      filter: true,
      visible: true
    }
  ],
  columns: [],
  currentRecord: {
    oid: "",
    nome: "",
    nomeFantasia: "",
    numeroDocumento: "",
    numeroDocumentoRaiz: "",
    inscricaoEstadual: "",
    inscricaoMunicipal: "",
    optanteSimples: false,
    complemento: "",
    email: "",
    site: "",
    telefone: "",
    segundoTelefone: "",
    nomeLogradouro: "",
    numeroLogradouro: "",
    bairro: "",
    cidade: "",
    contato: "",
    cep: "",
    numeroPis: "",
    ativo: true,
    categorias: [],
    plainSenha: ""
  },
  lookupCategoria: {
    visible: false,
    modal: true,
    header: "Categoria"
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDPessoaJuridicaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("pessoajuridica");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_CATEGORIA_VISIBLE:
      return setLoockupCategoriaVisible(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function setLoockupCategoriaVisible(state, action) {
  let lookupCategoria = state.lookupCategoria;
  lookupCategoria = Object.assign({}, lookupCategoria, { visible: action.visible });
  return Object.assign({}, state, { lookupCategoria });
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: []}
}
