import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
const BASE_PATH = "/lottustech/configuracaoempresa";
const prefix = "crudParametroEmpresa";
const crud = build(validate, BASE_PATH);

const newTypes = {
    RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.insertItemConfiguracao = configuracaoItem => {
  return (dispatch) => {
    dispatch(actions.clearErrors());
    if (!configuracaoItem.key) {
      return new Promise((resolve, reject) => {
        api
          .post(BASE_PATH, configuracaoItem)
          .then(resolve)
          .catch(reject);
      });
    }
  };
};


actions.deleteKey = (key,executeAfterDelete) => {
    return (dispatch) => {
      dispatch(actions.clearErrors());
      if (key) {
        return new Promise((resolve, reject) => {
          api
            .remove(`${BASE_PATH}/deleteKey/${key}`)
            .then(()=>{
                //É necessário pois para atualizar uma key, primeiro é feito sua remoção
                // e depois no afterDelete é feito a inserção de uma nova key
                if(executeAfterDelete && actions.afterDelete){
                    actions.afterDelete();
                }
            })
            .catch(reject);
        });
      }
    };
  };

export { types, actions };
