import React from "react";

import "./PortalClienteConsultaCliente.scss";
import {Button} from "primereact/button";
import {InputMask} from "primereact/inputmask";

export const PortalClienteConsultaClienteForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;
  const consultarCliente = props.consultarCliente;
  const hideResult = props.hideResult;
  
  function displayResultConsulta(record) {
    let className = null;
    let label = null;
    if (record.valido === "VALIDO") {
      className = "card card-resultado-valido";
      label = "Válido";
    } else if (record.valido === "INVALIDO") {
      className = "card card-resultado-invalido";
      label = "Inativo"
    }
    if (className) {
      hideResult();
      return (
        <div className={className}>
          <label className="label-resultado-consulta">{label}</label>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-8">
                <label className="label label-consulta-cliente">
                  Digite o documento do cliente aqui
                </label>
                <InputMask className="input-consulta-cliente"
                  mask="999.999.999-99"
                  unmask={true}                
                  value={record.documento}
                  onChange={changeHandler}
                  name="documento"
                />
              </div>
              <div className="p-col-12 p-sm-8 p-md-6 p-lg-5 p-xl-4">
                <Button className="botao-consulta-cliente"
                  onClick={consultarCliente}
                  label="Consultar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {displayResultConsulta(record)}
    </div>
  );
};
