import React from 'react'
import _ from 'lodash'
import LtsCalendar from '../../components/lottus/lts-calendar'

export const formBuildCalendar = ({ componentInfo, messages, record, onChange, label }) => {
  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
        <LtsCalendar
          value={record[_.get(componentInfo, 'campo', '')]}
          label={label}
          onChange={(e) => onChange(_.get(componentInfo, 'campo', null), e)}
          style={{ width: '200px' }}
        />
      </div>
    </div>
  )
}
