import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/public/falecidos";
const prefix = "crudConvenio";
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_IMAGEM_LOCALIZACAO: `${prefix}setImagemLocalizacao`,
    CLEAR_IMAGEM_LOCALIZACAO: `${prefix}clearImagemLocalizacao`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setImagemLocalizacao = payload => {
    return {
        type: types.SET_IMAGEM_LOCALIZACAO,
        payload
    };
};

actions.clearImagemLocalizacao = () => {
    return {
        type: types.CLEAR_IMAGEM_LOCALIZACAO
    };
}

actions.getLocalizacao = (origem, oidContratoFalecido, localizacao) => {
    return (dispatch, getState) => {
        const params = {
            origem,
            oidContratoFalecido
        }
        apiService.post(`${BASE_PATH}/localizacao`, params).then(response => {
            const result = response.data;
            dispatch(actions.setImagemLocalizacao(result));
            // if (result.length > 0) {
            //     const url = result[0] || '';
            //     let local = '';
            //     if (result.length > 1) {
            //         local = result[1];
            //     }
            // }
        });
    };
};

actions.openExternalLink = (link) => {
    return (dispatch, getState) => {
        if (link && link.length > 0) {
            window.open(link, '_blank').focus();
        }
    };
};

export { types, actions };
