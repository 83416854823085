import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { enums, icons } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { actions } from '../portalClienteFinanceiro.actions'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import utils from '../../../utils/index'
import storage from '../../../services/stoage.service'
import { actions as nfseActions } from '../../nfse/nfse.actions'
import { PortalClienteFinanceiroList } from './PortalClienteFinanceiroList'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    tiposFiltros: utils.getFilters(storage.getAppLabels('filtros')),
    tiposFiltrosTaxas: utils.getFiltersForEntity(storage.getAppLabels('filtros')),
    booleanFilter: state.crudPortalClienteFinanceiroState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'ENTRADA', value: 1 },
      { label: 'SAÍDA', value: 2 },
    ],
    booleanTypesStatus: [
      { label: 'TODOS', value: '' },
      { label: 'EM ABERTO', value: 1 },
      { label: 'VENCIDO', value: 2 },
      { label: 'PAGO', value: 3 },
      { label: 'CANCELADO', value: 4 },
    ],
    booleanTypesFormaPagamento: utils.getBooleanOptionsFormaDePagamento(),
    record: state.crudPortalClienteFinanceiroState.currentRecord,
    messages: state.crudPortalClienteFinanceiroState.messages,
    messagesDialog: storage.getAppLabels('dialogs'),
    showHideColumns: state.crudPortalClienteFinanceiroState.showHideColumns,
    columns: state.crudPortalClienteFinanceiroState.columns,
    records: state.crudPortalClienteFinanceiroState.records,
    first: state.crudPortalClienteFinanceiroState.index,
    rows: state.crudPortalClienteFinanceiroState.max,
    totalRecords: state.crudPortalClienteFinanceiroState.count,
    sortField: state.crudPortalClienteFinanceiroState.sortField,
    order: state.crudPortalClienteFinanceiroState.sortOrder,
    selectedFilters: state.crudPortalClienteFinanceiroState.selectedFilters,
    selectedRecords: state.crudPortalClienteFinanceiroState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams, 'financeiro')),
    applyFilter: (filterParams) => {
      dispatch(actions.loadFinanceiro())
    },
    changeFilterCalendar: (campo, e) => {
      dispatch(actions.setSelectedFilters(campo.field, e.value))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros enum
      if (['tipoGrupoFinanceiro'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      if (['status'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      if (['tipoFormaPagamento'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/portalCliente/financeiro`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    load: () => dispatch(actions.loadFinanceiro()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    showForm(formName, props) {
      dispatch(actions.setDisplayForm(formName, props))
    },
    gerarBoletoLancamento: (lancamento) => dispatch(actions.gerarBoletoLancamento(lancamento)),
    clearSelectedRecords: () => dispatch(actions.clearSelectedRecords()),
    emitirBoletos: (lancamentos) => dispatch(actions.emitirBoletos(lancamentos)),
    downloadDanfe: (lancamento) => dispatch(nfseActions.downloadDanfSe(lancamento.notas_oid)),
  }
}

class PortalClienteFinanceiroListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.messagesFilters = storage.getAppLabels('filtros')
    this.showHideColumns = []
    this.showCSVButton = false
    this.showColumnButton = false
    this.state = {
      dataInicial: null,
      dataFinal: null,
      dadosFavorito: null,

      favorito: 0,
      filtroEmissao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroVencimento: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroBoleto: false,
      filtroUnidades: [],
      filtroDataPagamento: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroTaxa: {
        tipoFiltro: '',
        taxas: [],
        taxa: '',
      },
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }
  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  changeTipoFiltro = (filtro, valor) => {
    let filtroAtual = filtro
    filtroAtual.tipoFiltro = valor
    filtroAtual.dataFinal = null
    filtroAtual.mostrarDataInicial = valor.dataInicial
    filtroAtual.mostrarDataFinal = valor.dataFinal
  }

  changeDataFiltro = (filtro, campo, valor) => {
    let filtroAtual = filtro
    filtroAtual[campo] = valor
  }

  onChangeUnit = (e) => {
    this.setState({ filtroUnidades: e.target.value })
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.clearSelectedFilters()
    this.props.load()
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  componentWillUnmount() {
    this.props.clearSelectedRecords()
  }
  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  getDynamicFilters = (state) => {
    let result = []
    let valor = null
    let valorComplementar = null
    if (state.checkBoxEmissaoInicial) {
      valor = this.getFormulaForData(state.inicialValor, state.inicialModificador, state.inicialPeriodo)
    }

    if (state.checkBoxEmissaoFinal) {
      valorComplementar = this.getFormulaForData(state.finalValor, state.finalModificador, state.finalPeriodo)
    }

    const tipoFiltro = valorComplementar ? 'ENTRE' : 'MAIOR_IGUAL'
    result.push({
      campo: 'emissao',
      valor: valor,
      valorComplementar: valorComplementar,
      tipoFiltro: tipoFiltro,
    })

    return result
  }

  getCustomButtons = () => {
    return (
      <div>
        <div className="p-toolbar-group-left">
          <Button
            disabled={this.verificarHabilitadoBaixarBotao()}
            onClick={(e) => this.emitirBoletos()}
            label="Emitir boleto"
            style={{ marginRight: '.25em' }}
            className="p-button-success"
          />
        </div>
      </div>
    )
  }

  emitirBoletos = () => {
    const lancamentos = this.props.selectedRecords
    if (utils.isArrayNotEmpty(lancamentos)) {
      const oids = lancamentos.map((l) => l.oid)
      this.props.emitirBoletos(oids)
    }
  }

  verificarHabilitadoBaixarBotao = () => {
    const lancamentosSelecionados = this.props.selectedRecords
    if (utils.isArrayNotEmpty(lancamentosSelecionados)) {
      const boleto = lancamentosSelecionados.find((f) => f.tipoFormaPagamento === 'BOLETO')
      return boleto === null || boleto === undefined
    }
    return true
  }

  onChangeTipoFiltroTaxa = (e) => {
    let filtroTaxa = this.state.filtroTaxa
    filtroTaxa.tipoFiltro = e.value
    if (!filtroTaxa.tipoFiltro.value) {
      filtroTaxa.taxa = ''
    }
    this.setState({ filtroTaxa: filtroTaxa })
  }

  formatarColumnTaxa = (rowData, column) => {
    if (column && column.field === 'value' && rowData.value) {
      return rowData.value
    } else if (column && column.field === 'id' && rowData.id) {
      return rowData.id
    }
  }

  getColunasGridFiltroTaxa = () => {
    return this.props.colunasGridFiltroTaxa.map((col) => {
      if (col.field === 'acoes') {
        return (
          <Column {...col} key={col.field} body={this.getActionTaxa} style={{ width: '10%', textAlign: 'center' }} />
        )
      } else if (col.field === 'value') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatarColumnTaxa}
            style={{ width: '15%', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'id') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatarColumnTaxa}
            style={{ width: '5%', textAlign: 'center' }}
          />
        )
      }
      return <Column {...col} key={col.field} field={col.field} header={col.header} />
    })
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Financeiro" />
  }

  getBooleanFilterOptions = (column) => {
    if (column.field === 'grupoFinanceiro_tipo') {
      return this.props.booleanTypes
    } else if (column.field === 'tipoFormaPagamento') {
      return this.props.booleanTypesFormaPagamento
    }
    return this.props.booleanTypesStatus
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%', minWidth: '10px' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.getBooleanFilterOptions(column)}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      if (column.field === 'grupoFinanceiro_tipo') {
        return <div>{[rowData[column.field]]}</div>
      } else if (column.field === 'tipoFormaPagamento') {
        return <div>{enums.CONVERTER_FORMA_PAGAMENTO[rowData[column.field]]}</div>
      }
      if (column.field === 'status') {
        const result = rowData[column.field]
        if (result === 'PAGO') {
          return (
            <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
              <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} title="PAGO" />
            </div>
          )
        } else if (result === 'VENCIDO') {
          return (
            <div style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
              <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} title="VENCIDO" />
            </div>
          )
        } else if (result === 'EM_ABERTO') {
          return (
            <div style={{ color: 'gold', display: 'flex', justifyContent: 'center' }}>
              <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} title="EM ABERTO" />
            </div>
          )
        } else if (result === 'CANCELADO') {
          return (
            <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
              <span className="pi pi-times-circle" style={{ fontSize: '1.8rem' }} title="CANCELADO" />
            </div>
          )
        }
      }
    }
    return null
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'boleto_oid' && utils.nonNull(rowData[column.field])) {
      return <Button type="button" onClick={(e) => this.props.gerarBoletoLancamento(rowData)} icon={icons.DOWNLOAD} />
    }
    if (column.field === 'notas_oid' && utils.nonNull(rowData[column.field])) {
      return <Button type="button" onClick={(e) => this.props.downloadDanfe(rowData)} icon={icons.DOWNLOAD} />
    }
    return utils.format(rowData, column)
  }

  fieldCalendarFilter = (column) => {
    let dataSelecionada = ''
    let filtro = utils.findObjectInArray(this.props.selectedFilters, 'campo', column.field)
    if (filtro && filtro.valor) {
      dataSelecionada = filtro.valor
    }
    return (
      <Calendar
        value={dataSelecionada}
        yearNavigator={true}
        monthNavigator={true}
        yearRange={utils.getYearRangeFromDate('1900')}
        appendTo={document.body}
        showButtonBar={true}
        onChange={(e) => this.props.changeFilter(column, e)}
        name={column.field}
      />
    )
  }
  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      } else if (
        col.field === 'emissao' ||
        col.field === 'vencimento' ||
        col.field === 'dataPagamento' ||
        col.field === 'dataImportacao'
      ) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
          />
        )
      } else if (col.field === 'grupoFinanceiro_tipo' || col.field === 'status' || col.field === 'tipoFormaPagamento') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (col.field === 'boleto_oid' || col.field === 'notas_oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            style={{ textAlign: 'center' }}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <div>
        <PortalClienteFinanceiroList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalClienteFinanceiroListPageContainer)
