import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { enums } from '../../constants'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import './Lookup.scss'
import _ from 'lodash'

export class Lookup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selection: props.multiple ? _.defaultTo(props.selection, null) : null,
      filters: {},
      typingTimeout: 0,
    }
  }

  onHide = (e) => {
    if (this.props.onHide) {
      this.props.onHide(e)
    }
    this.resetState()
  }
  onConfirm = (e) => {
    if (this.props.onConfirm) {
      const event = {
        originalEvent: e,
        selection: this.state.selection,
      }
      this.props.onConfirm(event)
    }
    this.resetState()
  }
  onCancel = (e) => {
    if (this.props.onCancel) {
      this.props.onCancel(e)
    }
    this.resetState()
  }
  onSelectionChange = (e) => {
    if (e.value === this.state.selection) {
      this.setState({ selection: null })
    } else {
      this.setState({ selection: e.value })
    }
    if (this.props.onSelectionChange) {
      const event = {
        originalEvent: e,
        selection: this.state.selection,
      }
      this.props.onSelectionChange(event)
    }
  }

  rowClassName = (rowData) => {
    return { 'p-highlight': this.state.selection && this.state.selection === rowData }
  }

  onFilter = (e) => {
    if (this.props.onFilter) {
      if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout)
      }
      this.setState({
        typingTimeout: setTimeout(() => {
          this.props.onFilter(e)
        }, 300),
      })
      this.setState({ filters: e.filters })
    }
  }

  onSort = (e) => {
    if (this.props.onSort) {
      let order = e.sortOrder === -0 ? -1 : 1
      this.props.onSort({ sortOrder: order, sortField: e.sortField })
    }
  }
  onPage = (e) => {
    if (this.props.onPage) {
      this.props.onPage(e)
    }
  }
  onChangeUnit = (e) => {
    if (this.props.onChangeUnit) {
      this.props.onChangeUnit(e)
    }
  }
  onChangeTemporaria = (e) => {
    if (this.props.onChangeTemporaria) {
      this.props.onChangeTemporaria(e)
    }
  }
  resetState = () => {
    this.setState({ typingTimeout: 0 })
    this.setState({ filters: {} })
  }

  render() {
    const footer = this.props.footer || (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          className="p-button-success"
          onClick={this.onConfirm}
          disabled={!this.state.selection}
        />
        <Button label="Cancelar" icon="pi pi-times" className="p-button-danger" onClick={this.onCancel} />
      </div>
    )

    return (
      <div className="lookup">
        <Dialog
          header={this.props.header}
          footer={footer}
          visible={this.props.visible}
          style={{ width: this.props.dialogWidth }}
          appendTo={this.props.appendTo ? this.props.appendTo : null}
          modal={this.props.modal}
          onHide={this.onHide}
          className="lookup-dialog"
        >
          {this.props.showFiltroUnidade ? (
            <div className="p-grid form-group" style={{ marginTop: '10px' }}>
              <div className="p-col-3">
                <span className="p-float-label">
                  <Dropdown
                    autoWidth={false}
                    disabled={this.props.disableUnit}
                    value={this.props.unidade}
                    options={this.props.unidades}
                    onChange={this.onChangeUnit}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name="unidade"
                  />
                  <label>Unidade</label>
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.props.showFiltroTemporaria ? (
            <div className="p-grid form-group">
              <div className="p-col-3">
                <label className="label">Apenas temporária</label>
                <Checkbox
                  checked={this.props.apenasTemporaria}
                  onChange={this.onChangeTemporaria}
                  name="apenasTemporaria"
                  style={{ marginLeft: '5px' }}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <DataTable
            // autoLayout={true}
            // resizableColumns={true}
            onFilter={this.onFilter}
            // filters={this.state.filters} // ??
            filter={true}
            onPage={this.onPage}
            paginator={true}
            paginatorTemplate="RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            rows={this.props.rows}
            rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
            onSort={this.onSort}
            value={this.props.records}
            sortOrder={this.props.order}
            sortField={this.props.sortField}
            emptyMessage="Não foram encontrados registros"
            rowClassName={this.rowClassName}
            lazy={true}
            first={this.props.first}
            totalRecords={this.props.totalRecords}
            selectionMode={this.props.multiple ? undefined : 'single'}
            selection={this.state.selection}
            onSelectionChange={this.onSelectionChange}
            scrollable={true}
            scrollHeight="250px"
            // V7 properties //
            filterDisplay="row"
            size={'small'}
            stripedRows
            resizableColumns
            columnResizeMode="fit"
            responsiveLayout="scroll"
            showFilterMenu={false}
          >
            {this.props.multiple && <Column selectionMode="multiple" headerStyle={{ width: '2em' }}></Column>}
            {this.props.columns}
          </DataTable>
        </Dialog>
      </div>
    )
  }
}
