import React from 'react'
import utils from '../../../utils/index'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'
import _ from 'lodash'
import { Dropdown } from 'primereact/dropdown'
import LtsDropdownTipoSepulturaV2 from '../../../components/lottus/lts-dropdown-tipo-sepultura-v2'

export const TiposSepulturasRelatorioForm = (props) => {
  const record = props.record

  function getFiltrosPesquisa() {
    if (utils.isArrayNotEmpty(props.selectedFilters)) {
      return (
        <div className="p-col-8 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          {props.selectedFilters.map((filtro) => (
            <div className="p-grid form-group p-align-start" style={{ marginTop: '8px' }}>
              <span className="p-float-label">
                <Dropdown
                  autoWidth={false}
                  value={filtro.valorSelecionado}
                  options={filtro.campoPesquisa.value}
                  onChange={props.changeHandler}
                  disabled={!_.get(record, 'unidadeSelecionada.id', null)}
                  optionLabel="value"
                  optionValue={(v) => v}
                  dataKey="value"
                  name={'filtroLocalizacao__' + filtro.campoPesquisa.key}
                  inputId={'dpd_filtro_localizacao__' + filtro.campoPesquisa.key}
                />
                <label htmlFor={'dpd_filtro_localizacao__' + filtro.campoPesquisa.key}>{filtro.chaveDicionario}</label>
              </span>
            </div>
          ))}
        </div>
      )
    }
    return <div />
  }

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <LtsDropdownUnidade
                  name={'unidade'}
                  label={'Unidade'}
                  value={record.unidadeSelecionada}
                  onChange={props.onChangeUnidade}
                  disabled={props.disableUnit}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsDropdownTipoSepulturaV2
                  name={'tipoSepultura'}
                  label={'Tipo'}
                  value={record.tipoSepulturaSelecionada}
                  onChange={props.onChangeTipoSepultura}
                  loadDataFnParam={_.get(record, 'unidadeSelecionada.id', null)}
                />
              </div>
            </div>
            {getFiltrosPesquisa()}
          </div>
        </div>
      </div>
    </div>
  )
}
