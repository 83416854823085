import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import {messageActions} from "../../store/message";
import utils from "../../utils/index";
const BASE_PATH = "/lottustech/relatorios";
const crud = build(validate, BASE_PATH);
const prefix = "crudMovimentoCaixa";


const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.gerarMovimentoCaixa = (dataInicial, dataFinal, unidades) => {
  const params = {
    dataInicial,
    dataFinal,
    unidades
  };
  return (dispatch, getState) => {
    if (!params.dataInicial || !params.dataInicial) {
      const message = "Informe um intervalo de datas";
      dispatch(messageActions.messageShowMessages(makeMessages([message], "warn")));
      return
    }
    const message = "Solicitação enviada, aguarde a geração do arquivo.";
    dispatch(messageActions.messageShowMessages(makeMessages([message], "success")));
    const path = BASE_PATH + `/movimentocaixa`;
    return apiService.post(path, params)
        .then(response => {
          utils.createAndDownloadBlobFile(response.data, 'movimentoCaixa')
          dispatch(messageActions.messageShowMessages(makeMessages([""], "success")));
    }).catch(err => {
      if (err.response) {
        const messages = err.response.data;
        dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
      }
    });
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  } else if (type === "warn") {
    summary = "Aviso!";
    sticky = true;
  }
  return messages.map(message => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message
    };
  });
};


export { types, actions };
