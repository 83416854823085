import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import { messageActions } from "../../store/message/index";
import { actions as tipoSepulturaActions } from "../tipoSepultura/tipoSepultura.actions";
import apiService from "../../services/api.service";
import utils from "../../utils";

const BASE_PATH = "/lottustech/remessafinanceira";
const crud = build(validate, BASE_PATH);
const prefix = "crudControleFinanceiro";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE: `${prefix}setLookupConfiguracaoBoletoVisible`,
  SET_LOOKUP_TIPO_SEPULTURA_VISIBLE: `${prefix}setLookupTipoSepulturaVisible`,
  SET_LOOKUP_TAXA_VISIBLE: `${prefix}setLookupTaxaVisible`,
  SET_CONFIGURACAO_BOLETO: `${prefix}setConfiguracaoBoleto`,
  SET_DATA_VENCIMENTO: `${prefix}setDataVencimento`,
  SHOW_ERROS: `${prefix}showErros`,
  SET_ADVANCED_FILTER: `${prefix}setAdvancedFilter`,
  FILTER: `${prefix}filter`,
  UNMOUNT: `${prefix}unmount`,
  RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.unmount = () =>{
  return {
    type:types.UNMOUNT
  }
};

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.setLookupTaxaVisible = visible => {
  return {
    type: types.SET_LOOKUP_TAXA_VISIBLE,
    visible
  };
};

actions.setLookupTipoSepulturaVisible = visible => {
  return {
    type: types.SET_LOOKUP_TIPO_SEPULTURA_VISIBLE,
    visible
  };
};

actions.setLookupConfiguracaoBoletoVisible = visible => {
  return {
    type: types.SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE,
    visible
  };
};

actions.setConfiguracaoBoleto = payload => {
  return {
    type: types.SET_CONFIGURACAO_BOLETO,
    payload
  };
};

actions.setDataVencimento = payload => {
  return {
    type: types.SET_DATA_VENCIMENTO,
    payload
  };
};

actions.showErros = payload => {
  return {
    type: types.SHOW_ERROS,
    payload
  };
};

actions.setAdvancedFilter = (field, value, hash) => {
  return {
    type: types.SET_ADVANCED_FILTER,
    field,
    value,
    hash
  };
};

actions.loadTiposSepulturas = oidUnidade => {
  return (dispatch, getState) => {
    const params = {
      filters: [{ campo: "unidade", valor: oidUnidade, tipoFiltro: "IGUAL_A" }],
      index: 0,
      max: 100,
      projectionFields: ["oid", "descricao"]
    };
    apiService.post(`/lottustech/tipossepultura/all`, params).then(response => {
      dispatch(tipoSepulturaActions.setRecords(response.data.records));
    });
  };
};

actions.gerarBoletos = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLoteBoletosState`];
    const record = state.currentRecord;
    const param = {
      oidConfiguracao: record.configuracaoBoleto.id,
      mesVencimento: record.vencimento,
      oidUnidade: record.unidade && record.unidade.id ? record.unidade.id : null,
      oidTaxa: record.taxa && record.taxa.id ? record.taxa.id : null,
      oidContrato: record.contrato && record.contrato.id ? record.contrato.id : null,
      periodoInicial: record.periodoInicial,
      periodoFinal: record.periodoFinal
    };
    const path = `/lottustech/ailos/gerar/boleto/lote`;
    return apiService
      .post(path, param)
      .then(response => {
        dispatch(actions.showErros(response.data));
      })
      .catch(err => {
        const messages = err.response.data;
        dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
      });
  };
};

actions.loadConfiguracaoBoletoPadrao = oid => {
  return (dispatch, getState) => {
    const path = `/lottustech/configuracoesBoletos/${oid}`;
    return apiService.get(path).then(response => {
      dispatch(actions.setConfiguracaoBoleto(response.data));
    });
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  }
  return messages.map(message => {
    return { sticky: sticky, summary: summary, severity: type, detail: message };
  });
};

actions.filter = path => {
  return (dispatch, getState) => {
    const messages = "Filtro de taxa é obrigatório";
    const state = getState()[`crudControleFinanceiroState`];
    const selectedFilters = state.selectedFilters;
    if (selectedFilters) {
      if (state.columns.findIndex((c) => c.field === 'oidContrato') < 0) {
        const columns = state.columns;
        columns.push(
          {
            field: 'oidContrato',
            columnKey: 'oidContrato',
            sortable: true,
            filter: true,
            columnSortField: 'oidContrato',
            visible: true,
        })
        dispatch(actions.setColumns(columns))
      }

      dispatch(actions.executeFilter(path));
    } else {
      dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
    }
  };
};

actions.loadConfiguracaoBoletoPadrao = oid => {
  return (dispatch, getState) => {
    const path = `/lottustech/configuracoesBoletos/${oid}`;
    return apiService.get(path).then(response => {
      dispatch(actions.setConfiguracaoBoleto(response.data));
    });
  };
};

actions.gerarLancamentos = (filtros, filtroUnidade) => {
  return (dispatch, getState) => {
    const state = getState()[`crudControleFinanceiroState`];
    const selectedRecords = state.selectedRecords;
    const currentRecord = state.currentRecord;
    let taxas = [];
    if (utils.isArrayNotEmpty(currentRecord.taxas)) {
      taxas = currentRecord.taxas.map(e => e.id);
    }
    const oidUnidade = filtroUnidade && parseInt(filtroUnidade.id) ? parseInt(filtroUnidade.id) : null;
    if (utils.isArrayNotEmpty(selectedRecords)) {
      let baseParams = [];
      if (!filtros.selecionarTodos) {
        selectedRecords.forEach(e => {
          const baseParam = {
            oidContrato: e.oidContrato,
            oidResponsavel: e.oidResponsavel,
            oidLancamentoFinanceiro: e.oidLancamentoFinanceiro,
            oidUnidade: e.oidUnidade
          };
          baseParams.push(baseParam);
        });
      }
      const params = {
        oidUnidade,
        vencimento: currentRecord.vencimento,
        oidConfiguracaoBoleto: currentRecord.configuracaoBoleto.id,
        taxas: taxas,
        baseParams: baseParams,
        pagarConspagaAte: currentRecord.conspagaPagarAte,
        incluirContratosComParcelamento: currentRecord.incluirContratosComParcelamento,
        gerarBoletosIndividual: currentRecord.gerarBoletosIndividual,
        selecionarTodos: filtros.selecionarTodos,
        filtrosPesquisa: filtros.filtrosPesquisa,
        filtroContratos: filtros.filtroContratos,
        competencia: currentRecord.competencia
      };
      const path = `${BASE_PATH}/gerarLancamentos`;
      return apiService
        .post(path, params)
        .then(response => {
          dispatch(actions.clearSelectedRecords());
          dispatch(messageActions.messageShowMessages(makeMessages(["Lançamentos gerados com sucesso!"], "success")));
        })
        .catch(err => {
          const messages = err.response.data;
          dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        });
    }
  };
};

actions.gerarBoletos = (filtros, filtroUnidade) => {
  return (dispatch, getState) => {
    const state = getState()[`crudControleFinanceiroState`];
    const selectedRecords = state.selectedRecords;
    const currentRecord = state.currentRecord;
    let taxas = [];
    if (utils.isArrayNotEmpty(currentRecord.taxas)) {
      taxas = currentRecord.taxas.map(e => e.id);
    }
    const oidUnidade = filtroUnidade && parseInt(filtroUnidade.id) ? parseInt(filtroUnidade.id) : null;

    if (utils.isArrayNotEmpty(selectedRecords)) {
      let baseParams = [];
      if (!filtros.selecionarTodos) {
        selectedRecords.forEach(e => {
          const baseParam = {
            oidContrato: e.oidContrato,
            oidResponsavel: e.oidResponsavel,
            oidLancamentoFinanceiro: e.oidLancamentoFinanceiro,
            oidUnidade: e.oidUnidade
          };
          baseParams.push(baseParam);
        });
      }
      const params = {
        oidUnidade,
        vencimento: currentRecord.vencimento,
        parcelas: currentRecord.parcelas,
        oidConfiguracaoBoleto: currentRecord.configuracaoBoleto.id,
        taxas: taxas,
        baseParams: baseParams,
        pagarConspagaAte: currentRecord.conspagaPagarAte,
        selecionarTodos: filtros.selecionarTodos,
        filtrosPesquisa: filtros.filtrosPesquisa,
        filtroContratos: filtros.filtroContratos,
        gerarBoletosIndividual: currentRecord.gerarBoletosIndividual,
        incluirContratosComParcelamento: currentRecord.incluirContratosComParcelamento,
        tipoFormaPagamento: currentRecord.tipoFormaPagamento,
        competencia: currentRecord.competencia
      };

      const message = "Solicitação enviada. Esse processo leva alguns minutos. Consulte o resultado na tela de 'Consulta de Remessas'.";
      dispatch(messageActions.messageShowMessages(makeMessages([message], "warn")));

      const path = `${BASE_PATH}/gerarRemessa`;
      apiService.post(path, params)
      .catch(err => {
        const messages = err.response.data;
        dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
      });

      setTimeout(() => {
        dispatch(actions.clearSelectedFilters());
        dispatch(actions.clearSelectedRecords());
        dispatch(actions.unmount())
      }, 1000);
    }
  };
};

actions.setConfiguracaoBoleto = payload => {
  return {
    type: types.SET_CONFIGURACAO_BOLETO,
    payload
  };
};

export { types, actions };
