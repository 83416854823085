import authentication from '../../services/authentication.service'
import history from '../../history'
import { menuActions } from '../../store/menu'
import storage from '../../services/stoage.service'
import _ from 'lodash'

export const types = {
  LOGIN_SET_CREDENTIAL: 'login.setCrecential',
  LOGIN_SET_CONTA: 'login.setConta',
  LOGIN_CLEAR_CREDENTIAL: 'login.setCredential',
  LOGIN_SET_ERROR_MESSAGE: 'login.loginSetErrorMessage',
  LOGIN_CLEAR_ERROR_MESSAGE: 'login.clearErrorMessage',
  LOGIN_SET_TOKEN: 'login.setToken',
  LOGIN_CLEAR_TOKEN: 'login.clearToken',
  LOGIN_SET_LOGIN: 'login.setLogin',
  LOGIN_CLEAR_LOGIN: 'login.clearLogin',
  LOGIN_SET_OID_COMPANY: 'login.setOidCompany',
  LOGIN_CLEAR_OID_COMPANY: 'login.clearOidCompany',
  LOGIN_CLEAR_ALL: 'login.clearAll',
  LOGIN_FETCH_AUTHENTICATION: 'login.fetchAuthentication',
  LOGIN_FETCH_AUTHENTICATION_SUCCESS: 'login.fetchAuthenticationSuccess',
  LOGIN_FETCH_AUTHENTICATION_ERROR: 'login.fetchAuthenticationError',
}

export function loginSetCredential(credential) {
  return {
    type: types.LOGIN_SET_CREDENTIAL,
    credential,
  }
}

export function loginSetConta(conta) {
  return {
    type: types.LOGIN_SET_CONTA,
    conta,
  }
}

export function loginClearCredential() {
  return {
    type: types.LOGIN_CLEAR_CREDENTIAL,
  }
}

export function loginSetErrorMessage(errorMessage = '') {
  return {
    type: types.LOGIN_SET_ERROR_MESSAGE,
    errorMessage,
  }
}

export function loginClearErrorMessage() {
  return {
    type: types.LOGIN_CLEAR_ERROR_MESSAGE,
  }
}

export function loginSetToken(token = '') {
  return {
    type: types.LOGIN_SET_TOKEN,
    token,
  }
}

export function loginClearToken() {
  return {
    type: types.LOGIN_CLEAR_TOKEN,
  }
}

export function loginSetLogin(login = '') {
  return {
    type: types.LOGIN_SET_LOGIN,
    login,
  }
}

export function loginClearLogin() {
  return {
    type: types.LOGIN_CLEAR_LOGIN,
  }
}

export function loginSetOidCompany(oidCompany = '') {
  return {
    type: types.LOGIN_SET_OID_COMPANY,
    oidCompany,
  }
}

export function loginClearOidCompany() {
  return {
    type: types.LOGIN_CLEAR_OID_COMPANY,
  }
}

export function loginClearAll() {
  return {
    type: types.LOGIN_CLEAR_ALL,
  }
}
export function fetchAuthentication() {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION,
    async: true,
  }
}

function fetchSuccess() {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION_SUCCESS,
    async: false,
  }
}
export function fetchAuthenticationSucces(data) {
  const { token, login, empresa } = data
  return (dispatch) => {
    dispatch(loginSetToken(token))
    dispatch(loginSetLogin(login))
    dispatch(loginSetOidCompany(empresa.oid))
    dispatch(menuActions.menuFetch())
    dispatch(fetchSuccess())
    history.replace('/')
  }
}
export function fetchAuthenticationError(errorMessage) {
  return (dispatch) => {
    dispatch(loginSetErrorMessage(errorMessage))
    return {
      type: types.LOGIN_FETCH_AUTHENTICATION_ERROR,
      async: false,
    }
  }
}

export function authenticate() {
  return (dispatch, getState) => {
    let state = getState()[`loginState`]
    Object.assign(state, { showMessage: false })
    dispatch(fetchAuthentication())
    authentication
      .login(getState().loginState.credential)
      .then((response) => {
        const login = response.data
        const isAutenticado = _.get(login, 'autenticado', false)
        if (isAutenticado) {
          const { token, user, empresa, portalCliente, nome } = login
          const isUsuarioUnidadeUnico = _.get(login, 'usuarioUnidadeUnico', false)
          const unidadesUsuario = _.get(login, 'unidades', [])
          storage.setCompanies(_.get(login, 'companies', []))
          storage.setPortalCliente(portalCliente)
          storage.setNomeUsuario(nome)
          storage.setUnidadesUsuario(unidadesUsuario)
          storage.setUsuarioUnidadeUnica(isUsuarioUnidadeUnico)
          if (isUsuarioUnidadeUnico) {
            const unidadeUnica = unidadesUsuario && unidadesUsuario.length === 1 ? unidadesUsuario[0] : null
            storage.setUnit(unidadeUnica)
            storage.setCompany(_.get(login, 'empresa', null))
          }
          storage.setAcoes(_.get(login, 'acoes', null))
          dispatch(fetchAuthenticationSucces({ token, user, empresa }))
        } else {
          Object.assign(state, { showMessage: true })
          Object.assign(state, { message: login.message })
          Object.assign(state, { messageSeverity: 'error' })
          dispatch(fetchAuthenticationError(login.message))
        }
      })
      .catch((err) => {
        Object.assign(state, { showMessageError: true })
        if (err && err.response && err.response.data) {
          const errorMessage = err.response.data.message
          dispatch(fetchAuthenticationError(errorMessage))
        }
      })
  }
}

export function setShowMesage(value) {
  return (dispatch, getState) => {
    let state = getState()[`loginState`]
    Object.assign(state, { showMessage: value })
  }
}

export function reenviarSenha() {
  return (dispatch, getState) => {
    let state = getState()[`loginState`]
    const credential = state.credential
    Object.assign(state, { showMessage: false })
    //dispatch(loginClearErrorMessage());
    authentication
      .esquecisenha(credential.login)
      .then((response) => {
        state = Object.assign(state, { showMessage: true })
        Object.assign(state, { message: response.data.mensagem })
        Object.assign(state, { messageSeverity: response.data.tipoMensagem })
        dispatch(loginSetErrorMessage(response.data.mensagem))
      })
      .catch((err) => {
        state = Object.assign(state, { showMessage: true })
        if (err && err.response && err.response.data) {
          Object.assign(state, { message: err.response.data.message })
          Object.assign(state, { messageSeverity: 'error' })
          const errorMessage = err.response.data.message
          dispatch(fetchAuthenticationError(errorMessage))
        }
      })
  }
}

export function criarConta() {
  return (dispatch, getState) => {
    let state = getState()[`loginState`]
    const conta = state.conta
    authentication
      .criarConta(conta)
      .then((response) => {
        history.replace('/')

        state = Object.assign(state, { showMessage: true })
        Object.assign(state, { message: 'Cadastro efetuado com sucesso' })
        Object.assign(state, { messageSeverity: 'success' })
        dispatch(loginSetErrorMessage('Cadastro efetuado com sucesso'))
      })
      .catch((err) => {
        state = Object.assign(state, { showMessage: true })
        if (err && err.response && err.response.data) {
          Object.assign(state, { message: err.response.data })
          Object.assign(state, { messageSeverity: 'error' })
          const errorMessage = err.response.data
          dispatch(fetchAuthenticationError(errorMessage))
        }
      })
  }
}

export function redirectToDash() {
  history.replace('/')
}
