import React from "react";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import utils from "../../../utils";

export const ParametroForm = props => {
    return (


        <div className="dataTableParametros">

            <span className="p-float-label">
              <Dropdown
                className={'width-20-rem'}
                autoWidth={false}
                value={props.unidade}
                options={props.unidades}
                onChange={props.onChangeUnit}
                optionLabel="value"
                optionValue={(v) => v}
                dataKey="id"
                name="unidade"
              />
              <label>Unidade</label>
            </span>

            <DataTable
                autoLayout={true}
                selectionMode="single"
                value={props.records}
                totalRecords={props.totalRecords}
                first={props.first}
                rows={props.rows}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={[5, 10, 20, 50]}
                selection={props.parametroSelecionado}
                onSelectionChange={props.onSelectionChange}
                onRowSelect={props.onParametroSelecionado}
            >
                {props.columns}
            </DataTable>
        </div>
    );
};
