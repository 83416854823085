import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lottustech/botoesdinamicos';
const prefix = 'crudBotaoDinamico';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_LOOKUP_FUNCAO_DINAMICA_VISIBLE: `${prefix}setLookupFuncaoDinamicaVisible`,
    PICKLIST_PERFIS_MOVE_TO_TARGET : `${prefix}pickListPerfisMoveToTarget`,
    PICKLIST_PERFIS_MOVE_TO_SOURCE : `${prefix}pickListPerfisMoveToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE : `${prefix}pickListPerfisMoveAllToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_TARGET : `${prefix}pickListPerfisMoveAllToTarget`,
    RESET: `${prefix}reset`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.setLookupFuncaoDinamicaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_FUNCAO_DINAMICA_VISIBLE,
        visible
    }
}

actions.pickListPerfisMoveToSource = (perfisBotaoDinamicoRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_SOURCE,
        perfisBotaoDinamicoRemoved
    }
}

actions.pickListPerfisMoveAllToSource = (perfisBotaoDinamicoRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE
    }
}

actions.pickListPerfisMoveToTarget = (perfisAdd, oidBotaoDinamico) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_TARGET,
        perfisAdd,
        oidBotaoDinamico
    }
}

actions.pickListPerfisMoveAllToTarget = (allPerfis, oidBotaoDinamico) => {
    return actions.pickListPerfisMoveToTarget(allPerfis, oidBotaoDinamico)
}

export { types, actions };
