import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../contaCorrente.actions'
import { ContaCorrenteForm } from './ContaCorrenteForm'
import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())

  return {
    record: state.crudContaCorrenteState.currentRecord,
    messages: state.crudContaCorrenteState.messages,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class ContaCorrenteFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      descricaoChanged: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_CONTA_CORRENTE}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_CONTA_CORRENTE}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CONTA_CORRENTE}`)
    }

    actions.beforeFetchRecordSuccess = (data) => {
      return data
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
      this.setState({ descricaoChanged: true })
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CONTA_CORRENTE}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  onChangeDescricao = (e) => {
    this.props.setField('descricao', e)
    this.setState({ descricaoChanged: true })
  }

  onChangeField = (fieldName, e) => {
    this.props.setField(fieldName, e)

    const descricaoChanged = this.state.descricaoChanged
    if (!descricaoChanged) {
      let descricao = ''
      const banco = fieldName === 'banco' ? e : _.get(this.props.record, 'banco', '')
      const agencia = fieldName === 'agencia' ? e : _.get(this.props.record, 'agencia', '')
      const numero = fieldName === 'numero' ? e : _.get(this.props.record, 'numero', '')

      if (banco) {
        descricao = banco
      }
      if (agencia) {
        descricao += ' - ' + agencia
      }
      if (numero) {
        descricao += ' - ' + numero
      }

      this.props.setField('descricao', descricao)
    }
  }

  setConfiguracaoBoleto = (data) => {
    const oid = _.get(data, 'oid', null)
    const nome = _.get(data, 'nome', '')

    this.props.setField('configuracaoBoleto.id', oid)
    this.props.setField('configuracaoBoleto.value', nome)
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <ContaCorrenteForm
        record={this.props.record}
        messages={this.props.messages}
        onChangeDescricao={this.onChangeDescricao}
        onChangeField={this.onChangeField}
        setConfiguracaoBoleto={this.setConfiguracaoBoleto}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContaCorrenteFormPageContainer)
