import { types } from './menu.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import _ from 'lodash'

const initialState = {
  id: 'menu',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Nome',
      value: {
        field: 'nome',
        header: 'Nome',
        columnKey: 'nome',
        sortable: true,
        columnSortField: 'nome',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Caminho',
      value: {
        field: 'caminho',
        header: 'Caminho',
        columnKey: 'caminho',
        sortable: true,
        columnSortField: 'caminho',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Ordem',
      value: {
        field: 'ordem',
        header: 'Ordem',
        columnKey: 'ordem',
        columnSortField: 'ordem',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Menu Superior',
      value: {
        field: 'parent.value',
        header: 'Menu Superior',
        columnKey: 'parent',
        sortable: true,
        columnSortField: 'parent.nome',
        filter: true,
        relacionamento: ['parent'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Ícone',
      value: {
        field: 'linkIcone',
        header: 'Ícone',
        columnKey: 'linkIcone',
        columnSortField: 'linkIcone',
        sortable: true,
        filter: true,
        visible: false,
      },
    },
  ],
  columns: [],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
      visible: true,
    },
  ],
  initialState: {
    oid: '',
    nome: '',
    caminho: '',
    cor: '',
    indicadorPersonalizado: true,
    linkIcone: '',
    ordem: '',
    ordemDashboard: '',
    textoComplementar: '',
    parent: '',
    ativo: true,
  },
  currentRecord: {
    oid: '',
    nome: '',
    caminho: '',
    cor: '',
    indicadorPersonalizado: true,
    linkIcone: '',
    ordem: '',
    ordemDashboard: '',
    textoComplementar: '',
    parent: '',
    ativo: true,
    oidDocumento: '',
    documento: '', //IdValue apenas usado no front - lookup
  },
  lookupMenuSuperior: {
    visible: false,
    modal: true,
    header: 'Menus',
  },
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDMenuReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('menu')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_LOOKUP_MENU_SUPERIOR_VISIBLE:
      return setLoockupMenuSuperiorVisible(state, action)
    default:
      return result
  }
}

function setLoockupMenuSuperiorVisible(state, action) {
  let lookupMenuSuperior = state.lookupMenuSuperior
  lookupMenuSuperior = Object.assign({}, lookupMenuSuperior, { visible: action.visible })
  return Object.assign({}, state, { lookupMenuSuperior })
}
