import _ from 'lodash'
import React from 'react'
import { isArrayNullOrEmpty } from '../../utils'
import { buildMultiselectUnidade } from './form-build-multiselect-unidade'
import { formBuildMultiselectTipoContrato } from './form-build-multiselect-tipo-contrato'
import { formBuildMultiselectGrupoFinanceiro } from './form-build-multiselect-grupo-financeiro'
import { formBuildMultiselectFormaPagamento } from './form-build-multiselect-forma-pagamento'
import { formBuildMultiselectContaCorrente } from './form-build-multiselect-conta-corrente'
import { formBuildMultiselectUsuario } from './form-build-multiselect-usuario'
import { formBuildCalendar } from './form-build-calendar'

export const formBuilder = ({ parametros, record, messages, setField, history }) => {
  if (_.isEmpty(parametros)) {
    return null
  }
  const onChange = (fieldName, value) => {
    setField(fieldName, value)
    if (fieldName === 'oid_unidade') {
      //TODO criar config dependencia
      if (record.hasOwnProperty('oid_tipo_sepultura')) {
        setField('oid_tipo_sepultura', '')
      }
      if (record.hasOwnProperty('oid_grupo_financeiro')) {
        setField('oid_grupo_financeiro', '')
      }
    }
  }

  const resolveLabel = (componentInfo) => {
    const campo = _.get(componentInfo, 'campo', '')
    if (_.isEmpty(campo)) {
      return ''
    }
    let result = campo.replaceAll('oid_', '')
    result = result.replaceAll('dt', 'Data')
    result = result.replaceAll('_', ' ')
    const split = result.split(' ')
    return split
      .map((word) => {
        return _.capitalize(word)
      })
      .join(' ')
  }

  const resolveComponent = (componentInfo) => {
    const campo = _.get(componentInfo, 'campo', null)
    const componente = _.get(componentInfo, 'componente', null)
    const label = resolveLabel(componentInfo)

    // criar Map
    if (campo === 'oid_unidade') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return buildMultiselectUnidade({
          componentInfo,
          messages,
          record,
          onChange,
          label,
        })
      }
    }
    if (campo === 'oid_tipo_sepultura') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return formBuildMultiselectTipoContrato({ componentInfo, messages, record, onChange, label })
      }
    }
    if (campo === 'oid_grupo_financeiro') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return formBuildMultiselectGrupoFinanceiro({ componentInfo, messages, record, onChange, label })
      }
    }
    if (campo === 'oid_forma_pagamento') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return formBuildMultiselectFormaPagamento({ componentInfo, messages, record, onChange, label })
      }
    }
    if (campo === 'oid_conta_corrente') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return formBuildMultiselectContaCorrente({ componentInfo, messages, record, onChange, label })
      }
    }
    if (campo === 'oid_usuario') {
      if (componente === 'multiselect' || _.isEmpty(componente)) {
        return formBuildMultiselectUsuario({ componentInfo, messages, record, onChange, label })
      }
    }
    if (_.startsWith(campo, 'dt_')) {
      return formBuildCalendar({ componentInfo, messages, record, onChange, label })
    }
  }

  try {
    const array = JSON.parse(parametros)
    if (isArrayNullOrEmpty(array)) {
      return null
    }

    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            {array.map((componentInfo, index) => resolveComponent(componentInfo))}
          </div>
        </div>
      </div>
    )
  } catch (e) {
    return null
  }
}
