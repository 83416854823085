import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import {messageActions} from "../../store/message";
const BASE_PATH = "/lottustech/relatorios";
const crud = build(validate, BASE_PATH);
const prefix = "crudDRE";


const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.abrirRelatorio = (dataIni, dataFin, unidade) => {
  let params = {
    dataInicial: dataIni,
    dataFinal: dataFin,
    unidades: unidade
  };
  return (dispatch, getState) => {
    if (!params.dataInicial || !params.dataInicial) {
      const message = "Informe um intervalo de datas";
      dispatch(messageActions.messageShowMessages(makeMessages([message], "warn")));
      return
    }

    const path = BASE_PATH + `/dre`;
    return apiService.post(path, params).then(response => {
      const win = window.open(response.data);
      if (win) {
        win.focus();
      }
    });
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  } else if (type === "warn") {
    summary = "Aviso!";
    sticky = true;
  }
  return messages.map(message => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message
    };
  });
};


export { types, actions };
