import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { getDocumentoByOidMenu } from '../../services/api-documento'
import _ from 'lodash'
import { messageActions } from '../../store/message'
import apiService from '../../services/api.service'

const BASE_PATH = '/lottustech/teladinamica/relatorio'
const crud = build(validate, BASE_PATH)
const prefix = 'crudTelaDinamica'

const newTypes = {
  SET_DOCUMENTO: `${prefix}setDocumento`,
  RESET_STATE: `${prefix}resetState`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setDocumento = (payload) => {
  return {
    type: types.SET_DOCUMENTO,
    payload,
  }
}

actions.resetState = () => {
  return {
    type: types.RESET_STATE,
  }
}

actions.loadDocumentoByOidMenu = (oidDocumento) => {
  return (dispatch) => {
    getDocumentoByOidMenu(oidDocumento)
      .then((response) => {
        const data = _.get(response, 'data', null)
        if (data) {
          dispatch(actions.setDocumento(data))
        }
      })
      .catch((error) => {
        dispatch(messageActions.showMessages(`Erro obtendo documento`, 'error'))
      })
  }
}

actions.executarRelatorio = ({ params, oidDocumento }) => {
  return (dispatch, getState) => {
    const path = `${BASE_PATH}/${oidDocumento}`
    return apiService
      .post(path, params)
      .then((response) => {
        const url = _.get(response, 'data', null)
        if (url && url.includes('http')) {
          const win = window.open(url)
          if (win) {
            win.focus()
          }
        }
      })
      .catch((error) => {
        dispatch(messageActions.showMessages([error.response.data], 'error'))
      })
  }
}

actions.reset = () => {
  return (dispatch) => {
    dispatch(actions.resetState())
  }
}

export { types, actions }
