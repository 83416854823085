import React from 'react'
import { InputNumber } from 'primereact/inputnumber'

export default function LtsInputNumber(props) {
  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <InputNumber
          {...props}
          min={props.min}
          max={props.max}
          value={props.value}
          onChange={(e) => props.onChange(e.value)}
          style={props.style}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
