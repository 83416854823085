import React from 'react'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import { getMessage } from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../permissao.actions'
import { PermissaoList } from './PermissaoList'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudPermissaoState.showHideColumns,
    selectedRecords: state.crudPermissaoState.selectedRecords,
    messages: state.crudPermissaoState.messages,
    columns: state.crudPermissaoState.columns,
    records: state.crudPermissaoState.records,
    first: state.crudPermissaoState.index,
    rows: state.crudPermissaoState.max,
    totalRecords: state.crudPermissaoState.count,
    sortField: state.crudPermissaoState.sortField,
    order: state.crudPermissaoState.sortOrder,
    booleanFilter: state.crudPermissaoState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'SIM', value: true },
      { label: 'NÃO', value: false },
    ],
    selectedFilters: state.crudPermissaoState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros boolean
      if (['listar', 'inserir', 'alterar', 'excluir'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/permissoes/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class PermissaoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PERMISSAO}/novo`)
  }
  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar listPageContainer={this} title={getMessage(this.props.messages, 'permissao.label.permissoes')} />
    )
  }

  oidTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to={`${routes.PATH_PERMISSAO}/${rowData.oid}`}>{rowData.oid}</Link>
      </div>
    )
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypes}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result) {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} />{' '}
        </div>
      )
    } else {
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-times-circle" style={{ fontSize: '1.8rem' }} />
        </div>
      )
    }
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (['listar', 'inserir', 'alterar', 'excluir'].find((field) => field === col.field)) {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <PermissaoList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onSelectionChange={this.onSelectionChange}
        onPage={this.onPage}
        onSort={this.onSort}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissaoListPageContainer)
