const blankToNull = require('./../../../utils').blankToNull;

export function validate(record){
    
    blankToNull(record);
    const errors = [];
    

     const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}