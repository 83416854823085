import React from 'react'
import _ from 'lodash'
import LtsMultiselectContaCorrente from '../../components/lottus/lts-multiselect-conta-corrente'

export const formBuildMultiselectContaCorrente = ({ componentInfo, messages, record, onChange, label }) => {
  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
        <LtsMultiselectContaCorrente
          name={_.get(componentInfo, 'campo', '_undef_')}
          label={label}
          value={record[_.get(componentInfo, 'campo', '_undef_')]}
          onChange={(e) => onChange(_.get(componentInfo, 'campo', null), _.get(e, 'target.value', null))}
        />
      </div>
    </div>
  )
}
