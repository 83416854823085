import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { InputNumber } from 'primereact/inputnumber'
import { Dialog } from 'primereact/dialog'
import { enums, icons, routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { LancamentoList } from './LancamentoList'
import { actions } from '../lancamento.actions'
import { actions as taxaActions } from '../../taxa/taxa.actions'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import utils, {
  isTipoFiltroSelecionado,
  isTipoFiltroSelecionadoComplementar,
  parseCurrency,
} from '../../../utils/index'
import storage from '../../../services/stoage.service'
import { Filtro } from '../../../utils/Filtro.js'
import { LookupField } from '../../../components/lookup/LookupField'
import { DataTable } from 'primereact/datatable'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import '../../../index.scss'
import { RadioButton } from 'primereact/radiobutton'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import _ from 'lodash'
import LtsLookupContaCorrente from '../../../components/lottus/lts-lookup-conta-corrente'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const empresaAtual = storage.getCompany()
  let contasBancarias = []
  const contas = utils.getConfiguracaoContasBancarias(empresaAtual, null)
  if (utils.isArrayNotEmpty(contas)) {
    for (const c of contas) {
      const split = c.split('-')
      contasBancarias.push({ id: split[0].trim(), value: c })
    }
  }

  let displayForm = {
    pagamento: false,
    parcela: false,
    baixar: false,
    baixarParcelas: false,
  }
  if (state.crudLancamentoState.displayForm) {
    displayForm = state.crudLancamentoState.displayForm
  }

  //LOOKUP DE TAXA
  const lookupTaxaBase = {
    records: state.crudTaxaState.records,
    columns: state.crudTaxaState.columnsLookup
      .filter(
        (col) =>
          col.field === 'oid' ||
          col.field === 'valor' ||
          col.field === 'grupoFinanceiro_oid' ||
          col.field === 'grupoFinanceiro_descricao'
      )
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    editDisabled: true,
  }
  let lookupTaxa = Object.assign({}, state.crudLancamentoState.lookupTaxa, lookupTaxaBase)

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    disableUnit = true
  }

  let unidades = []
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(empresaAtual)
  unidades = unidades.concat(unidadesEmpresa)

  let isFormaPagamentoCelescDisponivel = utils.isFormaPagamentoCelescDisponivel()

  const isCustomRoute = storage.isCustomRoute2(state.appState.appCustomRoutes)
  const contasCorrentes = state.crudLancamentoState.contasCorrentes
  return {
    isCustomRoute,
    contasBancarias,
    displayForm,
    tiposFiltros: utils.getFilters(storage.getAppLabels('filtros')),
    tiposFiltrosTaxas: utils.getFiltersForEntity(storage.getAppLabels('filtros')),
    booleanFilter: state.crudLancamentoState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'ENTRADA', value: 1 },
      { label: 'SAÍDA', value: 2 },
    ],
    booleanTypesStatus: [
      { label: 'TODOS', value: '' },
      { label: 'EM ABERTO', value: 1 },
      { label: 'PAGO', value: 3 },
      { label: 'CANCELADO', value: 4 },
    ],
    booleanTypesFormaPagamento: utils.getBooleanOptionsFormaDePagamento(),
    record: state.crudLancamentoState.currentRecord,
    messages: state.crudLancamentoState.messages,
    messagesDialog: storage.getAppLabels('dialogs'),
    showHideColumns: state.crudLancamentoState.showHideColumns,
    columns: state.crudLancamentoState.columns,
    records: state.crudLancamentoState.records,
    first: state.crudLancamentoState.index,
    rows: state.crudLancamentoState.max,
    totalRecords: state.crudLancamentoState.count,
    sortField: state.crudLancamentoState.sortField,
    order: state.crudLancamentoState.sortOrder,
    selectedFilters: state.crudLancamentoState.selectedFilters,
    filterValues: state.crudLancamentoState.filterValues,
    perfis: state.crudPerfilState.records,
    selectedRecords: state.crudLancamentoState.selectedRecords,
    opcoesUnidades: unidades,
    disableUnit,
    lookupTaxa,
    colunasGridFiltroTaxa: state.crudLancamentoState.colunasGridFiltroTaxa,
    isFormaPagamentoCelescDisponivel,
    contasCorrentes,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilterCalendar: (campo, e) => {
      dispatch(actions.setSelectedFilters(campo.field, e.value))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros enum
      if (['tipoGrupoFinanceiro'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      if (['status'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      if (['tipoFormaPagamento'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      if (target.name === 'valor' || target.name === 'valorPago') {
        const newValue = parseCurrency(target.value)
        dispatch(actions.setSelectedFilters(campo, newValue, column.relacionamento, column.field))
      } else {
        dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      }
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/lancamentosfinanceiros/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    carregarLancamentos: () => {
      dispatch(actions.carregarLancamentos())
    },
    setFiltroAvancado: (filtro) => {
      dispatch(actions.setFiltroAvancado(filtro))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),

    pesquisaAvancada: (state, props) => {
      dispatch(actions.clearSelectedFilters())
      setTimeout(() => {
        // como NAO temos mais o status 'VENCIDO'
        // para tal vamos filtrar lancamento com vencimento anterior a data atual
        // e Status = ABERTO
        const valoresFiltros = props.filterValues
        const filtroVencido = _.get(valoresFiltros, ['vencido', 'valor'])
        if (filtroVencido) {
          let novoFiltro = {
            campo: 'vencimento',
            valor: new Date(),
            tipoFiltro: 'MENOR_QUE',
            chaveComponente: 'vencido',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))

          let filtroStatusAberto = {
            campo: 'status',
            valor: 1, // aberto
            tipoFiltro: 'IGUAL_A',
          }
          dispatch(actions.setFiltroAvancado(filtroStatusAberto))
        }
        const filtroMarcador = _.get(valoresFiltros, ['marcador', 'valor'])
        if (filtroMarcador && filtroMarcador.oid) {
          let novoFiltro = {
            campo: 'oid',
            tipoFiltro: 'IGUAL_A',
            relacionamento: ['marcadores'],
            valor: filtroMarcador.oid,
            chaveComponente: 'marcador',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }
        const filtroContaCorrente = _.get(valoresFiltros, ['contaCorrente', 'valor'])
        if (filtroContaCorrente && filtroContaCorrente.oid) {
          let novoFiltro = {
            campo: 'contaCorrente.oid',
            tipoFiltro: 'IGUAL_A',
            valor: filtroContaCorrente.oid,
            chaveComponente: 'contaCorrente',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }

        const tipoFiltroEmissao = _.get(valoresFiltros, ['emissao', 'tipoFiltro'])
        if (tipoFiltroEmissao && tipoFiltroEmissao?.value !== null) {
          const novoFiltroData = {
            campo: 'emissao',
            valor: _.get(valoresFiltros, ['emissao', 'valor']),
            valorComplementar: _.get(valoresFiltros, ['emissao', 'valorComplementar']),
            tipoFiltro: tipoFiltroEmissao.value,
            chaveComponente: 'emissao',
          }
          dispatch(actions.setFiltroAvancado(novoFiltroData))
        }

        const tipoFiltroVencimento = _.get(valoresFiltros, ['vencimento', 'tipoFiltro'])
        if (tipoFiltroVencimento && tipoFiltroVencimento?.value !== null) {
          const novoFiltro = {
            campo: 'vencimento',
            valor: _.get(valoresFiltros, ['vencimento', 'valor']),
            valorComplementar: _.get(valoresFiltros, ['vencimento', 'valorComplementar']),
            tipoFiltro: tipoFiltroVencimento.value,
            chaveComponente: 'vencimento',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }

        const tipoFiltroDataPagamento = _.get(valoresFiltros, ['dataPagamento', 'tipoFiltro'])
        if (tipoFiltroDataPagamento && tipoFiltroDataPagamento?.value !== null) {
          const novoFiltro = {
            campo: 'dataPagamento',
            valor: _.get(valoresFiltros, ['dataPagamento', 'valor']),
            valorComplementar: _.get(valoresFiltros, ['dataPagamento', 'valorComplementar']),
            tipoFiltro: tipoFiltroDataPagamento.value,
            chaveComponente: 'dataPagamento',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }

        const valorFiltroUnidade = _.get(valoresFiltros, ['unidade', 'valor'], [])
        if (utils.isArrayNotEmpty(valorFiltroUnidade)) {
          const filtro = {
            campo: 'unidade',
            valor: valorFiltroUnidade.map((f) => f.id),
            tipoFiltro: 'ESTA_CONTIDO_EM',
            chaveComponente: 'unidade',
          }
          dispatch(actions.setFiltroAvancado(filtro))
        }

        const tipoFiltroTaxa = _.get(valoresFiltros, ['taxa', 'tipoFiltro'])
        const valorFiltroTaxa = _.get(valoresFiltros, ['taxa', 'valor'], [])
        if (tipoFiltroTaxa && tipoFiltroTaxa?.value !== null) {
          const oidsTaxas = utils.isArrayNullOrEmpty(valorFiltroTaxa) ? [] : valorFiltroTaxa.map((t) => t.id)
          let valueTipoFiltro = tipoFiltroTaxa.value
          if (valueTipoFiltro === 0) {
            valueTipoFiltro = 11 //IGUAL A ==>> ESTA_CONTIDO_EM (11)
          } else if (valueTipoFiltro === 1) {
            valueTipoFiltro = 15 //DIFERENTE_DE ==>> NAO_ESTA_CONTIDO_EM (15)
          }
          const novoFiltro = {
            campo: 'taxa.oidTaxa',
            valor: oidsTaxas,
            tipoFiltro: valueTipoFiltro,
            chaveComponente: 'taxa',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }
        const filtroBoleto = _.get(valoresFiltros, ['boleto', 'valor'])
        if (filtroBoleto) {
          let novoFiltro = {
            campo: 'boleto',
            tipoFiltro: 'NAO_NULO',
            chaveComponente: 'boleto',
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }
        setTimeout(() => {
          dispatch(actions.executeFilter(`/lottustech/lancamentosfinanceiros/all`))
        }, 200)
      }, 250)
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    showForm(formName, props) {
      dispatch(actions.setDisplayForm(formName, props))
    },
    baixarLancamentos: (parcelas) => dispatch(actions.baixarLancamentosGrid(parcelas)),
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    gerarBoletoLancamento: (lancamento) => dispatch(actions.gerarBoletoLancamento(lancamento)),
    exportarDadosCSV: () => dispatch(actions.exportCSV()),
    salvarState: (state, filters) => dispatch(actions.saveState(state, filters)),
    removerState: (state) => dispatch(actions.removeState(state)),
    clearSelectedRecords: () => dispatch(actions.clearSelectedRecords()),
    addArquivoRetornoBoleto(arquivo, conta, oidContaCorrente) {
      dispatch(actions.processarArquivoRetornoBoleto(arquivo, conta, oidContaCorrente))
    },
    addArquivoRetornoCelesc(arquivo, conta) {
      dispatch(actions.processarArquivoRetornoCelesc(arquivo))
    },
    addArquivoRetornoCergral(arquivo, conta) {
      dispatch(actions.processarArquivoRetornoCergral(arquivo))
    },
    addArquivoRetornoDebitoAutomatico(arquivo, conta) {
      dispatch(actions.processarArquivoRetornoDebitoAutomatico(arquivo))
    },
    gerarDebitoAutomatico: (selectedLancamentos) => {
      if (utils.nonNull(selectedLancamentos) && utils.isArrayNotEmpty(selectedLancamentos)) {
        const oidsLancamentos = selectedLancamentos.map((l) => l.oid)
        dispatch(actions.gerarDebitoAutomatico(oidsLancamentos))
      }
    },
    cancelarLancamentos: (lancamentos) => dispatch(actions.cancelarLancamentos(lancamentos)),

    lookupTaxaSetVisible: (visible) => dispatch(actions.setLookupTaxaVisible(visible)),
    lookupTaxaPaginate: (paginationParams, oidUnidade) => {
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade))
    },
    lookupTaxaSortOrder: (paginationParams, oidUnidade) =>
      dispatch(taxaActions.loadTaxasValidas(paginationParams, oidUnidade)),
    lookupTaxaApplyFilter: (filterParams, oidUnidade) => {
      dispatch(taxaActions.setFiltroAvancado(filterParams))
      dispatch(taxaActions.loadTaxasValidas(null, oidUnidade))
    },
    lookupTaxaLoad: (oidUnidade) => dispatch(taxaActions.loadTaxasValidas(null, oidUnidade)),
    resetFiltroLookupTaxa: () => {
      dispatch(taxaActions.setFiltroAvancado({}))
      dispatch(actions.clearSelectedFilters())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
    loadContasCorrentes: () => dispatch(actions.getContasCorrentes()),
  }
}

class LancamentoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.messagesFilters = storage.getAppLabels('filtros')
    this.showHideColumns = []
    this.orderedColumns = []
    this.state = {
      showDialogVisao: false,
      showDialogRemoveVisao: false,
      showConfirmarCancelar: false,
      showProcessarArquivoRetorno: false,
      contaBancaria: props.contasBancarias.length === 1 ? props.contasBancarias[0] : null,
      contaCorrente: null,
      tipoImportacao: 'BOLETO',
      dataInicial: null,
      dataFinal: null,
      dadosFavorito: null,
      favorito: 0,
      filtroAvancado: {},
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.contasCorrentes?.length === 1) {
      prevState.contaCorrente = props.contasCorrentes[0]
    }

    return { ...prevState, ...props }
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  changeDataFiltro = (campo, valor) => {
    _.set(this.props.filterValues, campo, valor)
  }

  onChangeUnit = (e) => {
    _.set(this.props.filterValues, 'unidade.valor', e.target.value)
  }

  //* LOOKUP DE TAXAS */
  lookupTaxaOnPage = (e) => {
    this.props.lookupTaxaPaginate(
      {
        max: e.rows,
        index: e.first,
      },
      this.resolveOidUnidade()
    )
  }

  lookupTaxaOnSort = (e) => {
    this.props.lookupTaxaSortOrder(
      {
        sortOrder: e.sortOrder,
        sortField: e.sortField,
      },
      this.resolveOidUnidade()
    )
  }

  lookupTaxaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {}
      // é necessário este tratamento devido a taxa ter um grupo financeiro.
      if (key === 'grupoFinanceiro.value') {
        filter = {
          campo: 'descricao',
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
          relacionamento: ['grupoFinanceiro'],
        }
      } else {
        filter = {
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
        }
      }
      this.props.lookupTaxaApplyFilter(filter, this.resolveOidUnidade())
    })
    if (keys.length === 0) {
      this.props.resetFiltroLookupTaxa()
      this.props.lookupTaxaLoad(this.resolveOidUnidade())
    }
  }

  lookupTaxaOnClick = (e) => {
    this.props.resetFiltroLookupTaxa()
    this.props.lookupTaxaLoad(this.resolveOidUnidade())
    this.props.lookupTaxaSetVisible(true)
  }

  lookupTaxaOnHide = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }

  lookupTaxaOnConfirm = (e) => {
    const taxas = _.get(this.props.filterValues, ['taxa', 'valor'], [])
    const taxa = e.selection
    if (taxa && !this.isTaxaJaSelecionada(taxa)) {
      const value = utils.formatTaxaValueForLookup(taxa)
      taxas.push({ id: taxa.oid, value: value })
      _.set(this.props.filterValues, ['taxa', 'valor'], taxas)

      this.props.lookupTaxaSetVisible(false)
    }
  }

  lookupTaxaOnCancel = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }

  lookupTaxaOnEdit = () => {
    if (this.props.record.taxa && this.props.record.taxa.id) {
      return `${routes.PATH_TAXA_FINANCEIRO}/${this.props.record.taxa.id}`
    } else {
      return `${routes.PATH_TAXA_FINANCEIRO}`
    }
  }

  lookupTaxaOnComplete = (e) => {}

  lookupTaxaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      _.set(this.state.filtroAvancado, ['taxa', 'valor'], { id: null, value: e.value })
    } else {
      //FIXME ATENCAO NESSE TRECHO
      const taxa = {
        oid: e.value.oid,
        grupoFinanceiro_descricao: e.value.grupoFinanceiro_descricao,
        valor: e.value.valor,
      }
      if (taxa && !this.isTaxaJaSelecionada(taxa)) {
        const value = utils.formatTaxaValueForLookup(taxa)
        const taxas = _.get(this.props.filterValues, ['taxa', 'valor'], [])
        taxas.push({ id: taxa.oid, value: value })
        _.set(this.props.filterValues, ['taxa', 'valor'], taxas)
        this.props.lookupTaxaSetVisible(false)
      }
      this.props.resetFiltroLookupTaxa()
    }
  }

  lookupTaxaOnKeyPress = (e) => {
    const valorTaxa = _.get(this.state.filtroAvancado, ['taxa', 'lookup', 'value'])
    if (utils.nonNull(valorTaxa)) {
      const filtroTaxa = new Filtro('descricao', valorTaxa, 'CONTEM', ['grupoFinanceiro'])
      this.props.lookupTaxaApplyFilter(filtroTaxa, this.resolveOidUnidade())
    }
  }

  taxaDropDownTemplate = (taxa) => {
    return taxa.grupoFinanceiro_descricao
  }

  resolveOidUnidade = () => {
    const valorFiltroUnidade = _.get(this.state.filtroAvancado, ['unidade', 'valor'], [])
    if (utils.isArrayNullOrEmpty(valorFiltroUnidade)) {
      return null
    }
    return valorFiltroUnidade.map((u) => u.id)
  }

  isTaxaJaSelecionada = (taxaSelecionada) => {
    const taxas = _.get(this.props.filterValues, ['taxa', 'valor', 'value'], [])
    if (!taxaSelecionada || utils.isArrayNullOrEmpty(taxas)) {
      return false
    }

    let existente = false
    taxas.forEach((t) => {
      if (t && t.id == taxaSelecionada.oid) {
        existente = true
      }
    })
    return existente
  }

  //******** */

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state, this.props)
  }

  showFormVisao = () => {
    this.setState({ showDialogVisao: true })
  }

  showDialogRemoveVisao = () => {
    this.setState({ showDialogRemoveVisao: true })
  }

  removerState = () => {
    this.props.removerState()
  }

  salvarState = (dados, state) => {
    const dynamicFilters = this.getDynamicFilters(state)
    let orderedColumns = this.orderedColumns
    let columns = this.props.columns
    let arrayColumns = columns.filter((c) => c.visible)

    arrayColumns.forEach((col) => {
      let coluna = orderedColumns.find((co) => co.columnKey === col.columnKey)
      if (!coluna) {
        orderedColumns.push(col)
      }
    })

    orderedColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })

    dados.columns = orderedColumns
    dados.menu = { id: 1, value: this.props.history.location.pathname }
    dados.filters.push(...dynamicFilters)

    dados.filters.forEach((f) => {
      f.chaveComponente = f.chaveComponente || f.campo

      if (this.props.filterValues[f.chaveComponente]) {
        f.valorComponente = JSON.stringify(this.props.filterValues[f.chaveComponente].valor)
        f.valorComponenteComplementar = JSON.stringify(this.props.filterValues[f.chaveComponente].valorComplementar)
      }
    })

    this.props.salvarState(dados, dados.filters)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_LANCAMENTO_FINANCEIRO}/novo`)
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.loadContasCorrentes()
    this.props.clearSelectedFilters()
    this.props.carregarLancamentos()
    this.loadFavorito()

    const componenteFilterOptions = utils.getFilters(storage.getAppLabels('filtros'))

    setTimeout(() => {
      const filtroAvancado = {}
      for (const key of Object.keys(this.props.filterValues)) {
        filtroAvancado[key] = this.props.filterValues[key]

        if (key === 'taxa') {
          let tipoFiltro = this.props.filterValues[key].tipoFiltro
          if (tipoFiltro?.value === 11) {
            //IGUAL A ==>> ESTA_CONTIDO_EM (11)
            tipoFiltro = componenteFilterOptions.find((c) => c.value === 0)
          } else if (tipoFiltro?.value === 15) {
            //DIFERENTE_DE ==>> NAO_ESTA_CONTIDO_EM (15)
            tipoFiltro = componenteFilterOptions.find((c) => c.value === 1)
          }
          this.props.filterValues[key].tipoFiltro = tipoFiltro
        }
      }
      this.setState({ filtroAvancado: filtroAvancado })
    }, 500)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  componentWillUnmount() {
    this.props.clearSelectedRecords()
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  getDynamicFilters = (state) => {
    let result = []
    let valor = null
    let valorComplementar = null
    if (state.checkBoxEmissaoInicial) {
      valor = this.getFormulaForData(state.inicialValor, state.inicialModificador, state.inicialPeriodo)
    }

    if (state.checkBoxEmissaoFinal) {
      valorComplementar = this.getFormulaForData(state.finalValor, state.finalModificador, state.finalPeriodo)
    }

    if (valor) {
      const tipoFiltro = valorComplementar ? 'ENTRE' : 'MAIOR_IGUAL'
      result.push({
        campo: 'emissao',
        valor: valor,
        valorComplementar: valorComplementar,
        tipoFiltro: tipoFiltro,
      })
    }

    return result
  }

  getFormulaForData = (valor, modificador, periodo) => {
    let result = null
    if (valor === 1) {
      result = '$DATA_ATUAL'
    } else if (valor === 2) {
      result = '$INICIO_MES'
    } else if (valor === 3) {
      result = '$FINAL_MES'
    }

    if (typeof modificador !== 'string') {
      result = result + ';' + modificador
      if (periodo === 1) {
        result = result + ';d'
      } else if (periodo === 2) {
        result = result + ';s'
      } else if (periodo === 3) {
        result = result + ';m'
      }
    }
    return result
  }

  defineDynamicFilters = (state, onChangeData, onChangeCheckBox) => {
    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-md-12">
            <div class="p-grid p-justify-around">
              <span className="p-col-1 p-inputgroup-addon">
                <Checkbox
                  checked={state.checkBoxEmissaoInicial}
                  onChange={(e) => onChangeCheckBox(e)}
                  name="checkBoxEmissaoInicial"
                />
              </span>
              <span className="p-col-3 p-inputgroup-addon">
                <label>{utils.getMessage(this.messagesFilters, 'filtro.label.emissaoinicial')}</label>
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <Dropdown
                  value={state.inicialValor}
                  options={state.filtrosValorVisao}
                  onChange={(e) => onChangeData(e)}
                  name="inicialValor"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <InputNumber
                  value={state.inicialModificador}
                  onChange={(e) => onChangeData(e)}
                  min={-1000}
                  max={1000}
                  name="inicialModificador"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <Dropdown
                  value={state.inicialPeriodo}
                  options={state.filtrosPeriodoVisao}
                  onChange={(e) => {
                    onChangeData(e)
                  }}
                  disabled={!state.inicialModificador}
                  name="inicialPeriodo"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <label>{window.moment(state.inicialPreview).utc().format('DD/MM/YYYY')}</label>
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-md-12">
            <div class="p-grid p-justify-around">
              <span className="p-col-1 p-inputgroup-addon">
                <Checkbox
                  checked={state.checkBoxEmissaoFinal}
                  onChange={(e) => onChangeCheckBox(e)}
                  name="checkBoxEmissaoFinal"
                />
              </span>
              <span className="p-col-3 p-inputgroup-addon">
                <label>{utils.getMessage(this.messagesFilters, 'filtro.label.emissaofinal')}</label>
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <Dropdown
                  value={state.finalValor}
                  options={state.filtrosValorVisao}
                  onChange={(e) => onChangeData(e)}
                  name="finalValor"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <InputNumber
                  value={state.finalModificador}
                  onChange={(e) => onChangeData(e)}
                  min={-1000}
                  max={1000}
                  name="finalModificador"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <Dropdown
                  value={state.finalPeriodo}
                  options={state.filtrosPeriodoVisao}
                  disabled={!state.finalModificador}
                  onChange={(e) => onChangeData(e)}
                  name="finalPeriodo"
                />
              </span>
              <span className="p-col-2 p-inputgroup-addon">
                <label>{window.moment(state.finalPreview).utc().format('DD/MM/YYYY')}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getCustomButtons = () => {
    return (
      <div>
        {/*<div className="p-toolbar-group-left">*/}
        <Button
          disabled={this.verificarHabilitadoBaixarBotao()}
          onClick={(e) => this.props.showForm('baixarParcelas', true)}
          label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.baixar')}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />

        <Button
          disabled={this.verificarHabilitadoBaixarBotao()}
          onClick={(e) => this.props.gerarDebitoAutomatico(this.props.selectedRecords)}
          label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.debitoAutomatico')}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
        {/*</div>*/}

        {/*<div className="p-toolbar-group-left">*/}
        <Button
          disabled={this.verificarHabilitadoBaixarBotao()}
          onClick={this.cancelarLancamentos}
          label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.cancelar')}
          style={{ marginRight: '.25em' }}
          className="p-button-danger"
        />
        {/*</div>*/}
        {/*<div className="p-toolbar-group-left">*/}
        <Button
          onClick={this.processarArquivoRetorno}
          label={'Processar Arquivo Retorno'}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
        {/*</div>*/}

        {/*<div className="p-toolbar-group-left">*/}
        {/*  <label className="label-btn-arquivo-retorno" htmlFor="selecao-arquivo">*/}
        {/*    Processar Arquivo Retorno*/}
        {/*  </label>*/}
        {/*  <input*/}
        {/*    id="selecao-arquivo"*/}
        {/*    type="file"*/}
        {/*    onChange={e => this.onSelectArquivoViacredi(e)}*/}
        {/*    style={{ marginTop: ".25em" }}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* <div className="p-toolbar-group-left">
          <Button
            disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}
            onClick={e => this.props.showForm("baixarParcelas", true)}
            label={utils.getMessage(this.props.messages, "lancamentofinanceiro.label.estornar")}
            style={{ marginRight: ".25em" }}
            className="p-button-success"
          />
    </div> */}
      </div>
    )
  }

  processarArquivoRetorno = () => {
    if (this.props.contasBancarias.length > 1) {
      this.setState({ contaBancaria: null })
    }
    if (this.props.contasCorrentes.length > 1) {
      this.setState({ contaCorrente: null })
    }
    this.setState({ showProcessarArquivoRetorno: true })
  }

  cancelarLancamentos = () => {
    this.setState({ showConfirmarCancelar: true })
  }

  executarCancelamento = () => {
    this.props.cancelarLancamentos(this.props.selectedRecords)
  }

  onSelectArquivoRetorno = (e) => {
    if (
      ((this.state.contaBancaria && this.state.contaBancaria?.id) ||
        (this.state.contaCorrente && this.state.contaCorrente?.oid)) &&
      this.state.tipoImportacao === 'BOLETO'
    ) {
      this.hideDialog()
      this.props.addArquivoRetornoBoleto(e, this.state.contaBancaria?.id, this.state.contaCorrente?.oid)
    } else if (this.state.tipoImportacao === 'CELESC') {
      this.hideDialog()
      this.props.addArquivoRetornoCelesc(e)
    } else if (this.state.tipoImportacao === 'CERGRAL') {
      this.hideDialog()
      this.props.addArquivoRetornoCergral(e)
    } else if (this.state.tipoImportacao === 'DEBITO_AUTOMATICO') {
      this.hideDialog()
      this.props.addArquivoRetornoDebitoAutomatico(e)
    }
  }

  verificarHabilitadoBaixarBotao = () => {
    const lancamentosSelecionados = this.props.selectedRecords
    return !(utils.nonNull(lancamentosSelecionados) && utils.isArrayNotEmpty(lancamentosSelecionados))
  }

  getValorLancamentosSelecionados = () => {
    let valorTotalLancamentoSelecionados = 0
    if (this.props.selectedRecords && this.props.selectedRecords.length >= 1) {
      this.props.selectedRecords.forEach((lancamentoSelecionado) => {
        let valor = utils.parseStringToNumber(lancamentoSelecionado.valor)
        valorTotalLancamentoSelecionados += valor
      })
    }
    return valorTotalLancamentoSelecionados
  }

  resolveLabelProcessarArquivos() {
    if (!this.state.tipoImportacao || this.state.contaBancaria || this.state.contaCorrente) {
      return 'Selecionar arquivo'
    }
    return 'Selecionar arquivo - primeiro selecione uma conta'
  }

  onChangeContas = (e) => {
    this.setState({ contaBancaria: e.target.value })
  }

  onChangeContaCorrente = (e) => {
    this.setState({ contaCorrente: e.target.value })
  }

  hideDialog = () => {
    this.setState({ showConfirmarCancelar: false })
    this.setState({ showProcessarArquivoRetorno: false })
  }

  getDialogDadosBaixaParcela() {
    let lancamento = this.props.record
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.baixarparcelas')}
        visible={this.props.displayForm.baixarParcelas}
        style={{ width: '50vw' }}
        onHide={(e) => this.props.showForm('baixarParcelas', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.valor')}
              </label>
              <label className="label">{` R$ ${utils.formatCurrency(this.getValorLancamentosSelecionados())}`}</label>
            </div>

            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.databaixa')}
              </label>
              <Calendar
                yearNavigator={true}
                yearRange={utils.getYearRangeFromNow()}
                monthNavigator={true}
                value={lancamento.dataPagamentoParcela}
                onChange={this.props.changeHandler}
                name="dataPagamentoParcela"
              />
            </div>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Button
              label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.confirmar')}
              onClick={(e) => {
                this.realizarBaixaLancamentosFilhos()
                this.props.showForm('baixarParcelas', false)
              }}
              icon={icons.CHECK_ICON}
              className="p-button-success"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  getDialogArquivoRetorno() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.cancelar')}
          onClick={(e) => this.hideDialog()}
          className="p-button-danger"
          style={{ marginTop: '20px' }}
        />
      </div>
    )

    if (this.state.showProcessarArquivoRetorno) {
      return (
        <Dialog
          header={'Processar Arquivo Retorno'}
          visible={this.state.showProcessarArquivoRetorno}
          style={{ width: '30vw' }}
          onHide={(e) => this.hideDialog()}
          baseZIndex={1000000}
          modal={true}
          closable={true}
          footer={footer}
        >
          <div style={{ marginTop: '20px' }}>
            <RadioButton
              style={{ marginLeft: '5px' }}
              id="rbBanco"
              value={true}
              checked={this.state.tipoImportacao === 'BOLETO'}
              onChange={(e) => (this.state.tipoImportacao = 'BOLETO')}
            />
            <label className="p-radiobutton-label" htmlFor="rbBanco">
              Banco
            </label>
            <RadioButton
              style={{ marginLeft: '5px' }}
              id="rbCelesc"
              value={false}
              checked={this.state.tipoImportacao === 'CELESC'}
              onChange={(e) => (this.state.tipoImportacao = 'CELESC')}
            />
            <label className="p-radiobutton-label" htmlFor="rbCelesc">
              Celesc
            </label>
            <RadioButton
              style={{ marginLeft: '5px' }}
              id="rbCergral"
              value={false}
              checked={this.state.tipoImportacao === 'CERGRAL'}
              onChange={(e) => (this.state.tipoImportacao = 'CERGRAL')}
            />
            <label className="p-radiobutton-label" htmlFor="rbCergral">
              Cergral
            </label>
            <RadioButton
              style={{ marginLeft: '5px' }}
              id="rbDebitoAutomatico"
              value={false}
              checked={this.state.tipoImportacao === 'DEBITO_AUTOMATICO'}
              onChange={(e) => (this.state.tipoImportacao = 'DEBITO_AUTOMATICO')}
            />
            <label className="p-radiobutton-label" htmlFor="rbDebitoAutomatico">
              Débito automático
            </label>
            {this.getContaBancaria()}
            <div style={{ paddingTop: '20px' }}>
              <label className="label-btn-arquivo-retorno" htmlFor="selecao-arquivo">
                {this.resolveLabelProcessarArquivos()}
              </label>
              <input
                disabled={
                  !this.state.contaBancaria && !this.state.contaCorrente && this.state.tipoImportacao === 'BOLETO'
                }
                id="selecao-arquivo"
                type="file"
                onChange={(e) => this.onSelectArquivoRetorno(e)}
                style={{ marginTop: '.25em' }}
              />
            </div>
          </div>
        </Dialog>
      )
    }
    return null
  }

  getContaBancaria() {
    if (this.state.tipoImportacao === 'BOLETO') {
      if (this.props.contasCorrentes.length > 0) {
        return (
          <div style={{ paddingTop: '20px' }}>
            <span className="p-float-label">
              <Dropdown
                disabled={this.props.contasCorrentes.length === 1}
                autoWidth={false}
                value={this.state.contaCorrente}
                options={this.props.contasCorrentes}
                onChange={this.onChangeContaCorrente}
                optionLabel={(v) => v.numero + ' / ' + v.descricao}
                optionValue={(v) => v}
                dataKey="value"
                name="contas"
                style={{ width: '100%' }}
                inputId="conta_corrente"
              />
              <label htmlFor="conta_corrente">Conta Corrente</label>
            </span>
          </div>
        )
      } else {
        return (
          <div style={{ paddingTop: '20px' }}>
            <span className="p-float-label">
              <Dropdown
                disabled={this.props.contasBancarias.length === 1}
                autoWidth={false}
                value={this.state.contaBancaria}
                options={this.props.contasBancarias}
                onChange={this.onChangeContas}
                optionLabel="value"
                optionValue={(v) => v}
                dataKey="value"
                name="contas"
                style={{ width: '100%' }}
                inputId="conta_bancaria"
              />
              <label htmlFor="conta_bancaria">Conta</label>
            </span>
          </div>
        )
      }
    }
    return null
  }

  getDialogCancelar() {
    if (this.state.showConfirmarCancelar) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarCancelar}
          content={utils.getMessage(
            this.props.messagesDialog,
            'lancamentofinanceiro.dialog.message.confirmar.cancelamento'
          )}
          confirm={this.executarCancelamento}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  realizarBaixaLancamentosFilhos = () => {
    if (this.props.selectedRecords && this.props.selectedRecords.length > 0 && this.props.record.dataPagamentoParcela) {
      this.props.selectedRecords.forEach((lancamento) => {
        lancamento.dataPagamento = this.props.record.dataPagamentoParcela
        lancamento.valor = utils.parseStringToNumber(lancamento.valor)
        lancamento.valorPago = lancamento.valor
      })
      this.props.baixarLancamentos(this.props.selectedRecords)
    }
  }

  formatarColumnTaxa = (rowData, column) => {
    if (column && column.field === 'value' && rowData.value) {
      return rowData.value
    } else if (column && column.field === 'id' && rowData.id) {
      return rowData.id
    }
  }

  removerTaxa = (taxaRemovida) => {
    const taxas = _.get(this.props.filterValues, ['taxa', 'valor'], []).filter((t) => {
      return t && t.id !== taxaRemovida.id
    })
    _.set(this.props.filterValues, ['taxa', 'valor'], taxas)
  }

  getActionTaxa = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => this.removerTaxa(rowData)}
        />
      </div>
    )
  }

  getColunasGridFiltroTaxa = () => {
    return this.props.colunasGridFiltroTaxa.map((col) => {
      if (col.field === 'acoes') {
        return (
          <Column {...col} key={col.field} body={this.getActionTaxa} style={{ width: '10%', textAlign: 'center' }} />
        )
      } else if (col.field === 'value') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatarColumnTaxa}
            style={{ width: '15%', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'id') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatarColumnTaxa}
            style={{ width: '5%', textAlign: 'center' }}
          />
        )
      }
      return <Column {...col} key={col.field} field={col.field} header={col.header} />
    })
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div className="p-col-12 p-justify-start">
          <div className="p-grid form-group p-align-center">
            {/* UNIDADES */}
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <MultiSelect
                  className={'min-width-15-rem'}
                  value={_.get(this.props.filterValues, ['unidade', 'valor'])}
                  options={utils.convertArrayIdValueToIdLabel(this.props.opcoesUnidades)}
                  name="unidades"
                  optionLabel="label"
                  dataKey="id"
                  onChange={this.onChangeUnit}
                  disabled={this.props.disableUnit}
                  selectedItemsLabel="{0} itens selecionados"
                />
                <label>{utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.unidades')}</label>
              </span>
            </div>
          </div>
          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <Dropdown
                  className={'width-15-rem'}
                  value={_.get(this.props.filterValues, ['emissao', 'tipoFiltro'])}
                  options={this.props.tiposFiltros}
                  onChange={(e) => _.set(this.props.filterValues, 'emissao.tipoFiltro', e.value)}
                  optionLabel="label"
                  optionValue={(v) => v}
                  name="tipo"
                />
                <label>{utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.emissao')}</label>
              </span>
            </div>

            {isTipoFiltroSelecionado(_.get(this.props.filterValues, ['emissao', 'tipoFiltro'], false)) ? (
              <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                <span className="md-inputfield">
                  <Calendar
                    value={_.get(this.props.filterValues, ['emissao', 'valor'], null)}
                    name="dataInicial"
                    monthNavigator={true}
                    onChange={(e) => this.changeDataFiltro('emissao.valor', e.value)}
                  />
                </span>
              </div>
            ) : (
              ''
            )}
            {
              // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
              isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['emissao', 'tipoFiltro'])) ? (
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                  <span className="md-inputfield">
                    <Calendar
                      value={_.get(this.props.filterValues, ['emissao', 'valorComplementar'], null)}
                      name="dataFinal"
                      monthNavigator={true}
                      onChange={(e) => this.changeDataFiltro('emissao.valorComplementar', e.value)}
                    />
                  </span>
                </div>
              ) : (
                ''
              )
            }
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <Dropdown
                  className={'width-15-rem'}
                  value={_.get(this.props.filterValues, ['vencimento', 'tipoFiltro'])}
                  options={this.props.tiposFiltros}
                  onChange={(e) => _.set(this.props.filterValues, 'vencimento.tipoFiltro', e.value)}
                  optionLabel="label"
                  optionValue={(v) => v}
                  name="tipo"
                />
                <label>{utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.vencimento')}</label>
              </span>
            </div>
            {
              // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
              isTipoFiltroSelecionado(_.get(this.props.filterValues, ['vencimento', 'tipoFiltro'], false)) ? (
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                  <span className="md-inputfield">
                    <Calendar
                      value={_.get(this.props.filterValues, ['vencimento', 'valor'], null)}
                      name="vencimento"
                      monthNavigator={true}
                      onChange={(e) => this.changeDataFiltro('vencimento.valor', e.value)}
                    />
                  </span>
                </div>
              ) : (
                ''
              )
            }
            {
              // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
              isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['vencimento', 'tipoFiltro'])) ? (
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                  <span className="md-inputfield">
                    <Calendar
                      value={_.get(this.props.filterValues, ['vencimento', 'valorComplementar'], null)}
                      name="vencimento"
                      monthNavigator={true}
                      onChange={(e) => this.changeDataFiltro('vencimento.valorComplementar', e.value)}
                    />
                  </span>
                </div>
              ) : (
                ''
              )
            }
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <Dropdown
                  className={'width-15-rem'}
                  value={_.get(this.props.filterValues, ['dataPagamento', 'tipoFiltro'])}
                  options={this.props.tiposFiltros}
                  onChange={(e) => _.set(this.props.filterValues, 'dataPagamento.tipoFiltro', e.value)}
                  optionLabel="label"
                  optionValue={(v) => v}
                  name="tipo"
                />
                <label>{utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.pagamento')}</label>
              </span>
            </div>
            {
              // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
              isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataPagamento', 'tipoFiltro'], false)) ? (
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                  <span className="md-inputfield">
                    <Calendar
                      value={_.get(this.props.filterValues, ['dataPagamento', 'valor'])}
                      name="pagamento"
                      monthNavigator={true}
                      onChange={(e) => this.changeDataFiltro('dataPagamento.valor', e.value)}
                    />
                  </span>
                </div>
              ) : (
                ''
              )
            }
            {
              // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
              isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['dataPagamento', 'tipoFiltro'])) ? (
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                  <span className="md-inputfield">
                    <Calendar
                      value={_.get(this.props.filterValues, ['dataPagamento', 'valorComplementar'])}
                      name="pagamento"
                      monthNavigator={true}
                      onChange={(e) => this.changeDataFiltro('dataPagamento.valorComplementar', e.value)}
                    />
                  </span>
                </div>
              ) : (
                ''
              )
            }
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <LtsLookupMarcador
                  label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.marcador')}
                  value={_.get(this.state.filtroAvancado, ['marcador', 'valor', 'descricao'])}
                  history={this.props.history}
                  editHidden={true}
                  field={'codigo'}
                  multiple={false}
                  onChange={(e) => {
                    let data = { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'codigo', '') }
                    _.set(this.props.filterValues, 'marcador.valor', data)
                    _.set(this.state.filtroAvancado, 'marcador.valor', data)
                    this.setState({ filtroAvancado: this.state.filtroAvancado })
                  }}
                  showAddButton={false}
                  tipo={'LANCAMENTO'}
                />
              </span>
            </div>
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <LtsLookupContaCorrente
                  label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.contacorrente')}
                  value={_.get(this.state.filtroAvancado, ['contaCorrente', 'valor', 'descricao'])}
                  history={this.props.history}
                  onChange={(e) => {
                    let data = { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'descricao', '') }
                    _.set(this.props.filterValues, 'contaCorrente.valor', data)
                    _.set(this.state.filtroAvancado, 'contaCorrente.valor', data)
                    this.setState({ filtroAvancado: this.state.filtroAvancado })
                  }}
                  editHidden={true}
                />
              </span>
            </div>
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.boleto')}
              </label>
              <Checkbox
                style={{ marginLeft: '1rem' }}
                checked={_.get(this.props.filterValues, ['boleto', 'valor'])}
                onChange={(e) =>
                  _.set(this.props.filterValues, 'boleto.valor', !_.get(this.props.filterValues, ['boleto', 'valor']))
                }
              />
            </div>
          </div>

          <div className="p-grid form-group p-align-center">
            <div className="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.vencido')}
              </label>
              <Checkbox
                style={{ marginLeft: '0.25rem' }}
                checked={_.get(this.props.filterValues, ['vencido', 'valor'])}
                onChange={(e) =>
                  _.set(this.props.filterValues, 'vencido.valor', !_.get(this.props.filterValues, ['vencido', 'valor']))
                }
              />
            </div>
          </div>

          <div className="p-grid form-group p-align-baseline">
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <Dropdown
                  className={'width-15-rem'}
                  value={_.get(this.props.filterValues, ['taxa', 'tipoFiltro'])}
                  options={this.props.tiposFiltrosTaxas}
                  onChange={(e) => _.set(this.props.filterValues, 'taxa.tipoFiltro', e.value)}
                  optionLabel="label"
                  optionValue={(v) => v}
                  name="tipo"
                />
                <label>{utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.taxas')}</label>
              </span>
            </div>
            <div className="p-col-12 p-sm-12 p-md-7 p-lg-6 p-xl-4">
              <LookupField
                label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.taxa')}
                value={_.get(this.state.filtroAvancado, ['taxa', 'lookup', 'value'])}
                name="taxa"
                field="grupoFinanceiro_descricao"
                history={this.props.history}
                appendTo={document.body}
                itemTemplate={this.taxaDropDownTemplate}
                onKeyPress={this.lookupTaxaOnKeyPress}
                onComplete={this.lookupTaxaOnComplete}
                onInputFilter={this.lookupTaxaOnInputFilter}
                editDisabled={this.props.lookupTaxa.editDisabled}
                onEditField={this.lookupTaxaOnEdit}
                onClick={this.lookupTaxaOnClick}
                visible={this.props.lookupTaxa.visible}
                modal={this.props.lookupTaxa.modal}
                header={this.props.lookupTaxa.header}
                onHide={this.lookupTaxaOnHide}
                onConfirm={this.lookupTaxaOnConfirm}
                onCancel={this.lookupTaxaOnCancel}
                records={this.props.lookupTaxa.records}
                columns={this.props.lookupTaxa.columns}
                totalRecords={this.props.lookupTaxa.totalRecords}
                sortOrder={this.props.lookupTaxa.order}
                sortField={this.props.lookupTaxa.sortField}
                first={this.props.lookupTaxa.first}
                rows={this.props.lookupTaxa.rows}
                onPage={this.lookupTaxaOnPage}
                onFilter={this.lookupTaxaOnFilter}
                onSort={this.lookupTaxaOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12">
              <div className="card card-w-title">
                <label className="label">
                  {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.taxasSelecionadas')}
                </label>
                <DataTable
                  value={_.get(this.props.filterValues, ['taxa', 'valor'])}
                  autoLayout={true}
                  resizableColumns={true}
                  columnResizeMode="fit"
                  emptyMessage="  "
                >
                  {this.getColunasGridFiltroTaxa()}
                </DataTable>
              </div>
            </div>
          </div>

          <br />
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.lancamentos')}
      />
    )
  }

  getBooleanFilterOptions = (column) => {
    if (column.field === 'grupoItemPrincipal_tipo') {
      return this.props.booleanTypes
    } else if (column.field === 'tipoFormaPagamento') {
      return this.props.booleanTypesFormaPagamento
    }
    return this.props.booleanTypesStatus
  }

  statusTemplate = (rowData, column) => {
    if (column.field === 'status') {
      let color = 'grey'
      let title = 'Em aberto'
      const result = rowData[column.field]
      if (result === 'PAGO') {
        color = 'green'
        title = 'Pago'
      } else if (result === 'CANCELADO') {
        color = 'red'
        title = 'Cancelado'
      }
      return (
        <div style={{ color: `${color}`, display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} title={title} />
        </div>
      )
    }
  }

  tipoPagamentoFilter = (column) => {
    return (
      <MultiSelect
        style={{ width: '100%', minWidth: '10px' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.getBooleanFilterOptions(column)}
        onChange={(e) => {
          _.set(this.props.filterValues, [column.field, 'valor'], e.value)
          this.props.changeFilter(column, e)
        }}
      />
    )
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%', minWidth: '10px' }}
        name={column.field}
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        options={this.getBooleanFilterOptions(column)}
        onChange={(e) => {
          _.set(this.props.filterValues, [column.field, 'valor'], e.value)
          this.props.changeFilter(column, e)
        }}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      if (column.field === 'grupoItemPrincipal_tipo') {
        return <div>{[rowData[column.field]]}</div>
      } else if (column.field === 'tipoFormaPagamento') {
        return (
          <div title={enums.CONVERTER_FORMA_PAGAMENTO[rowData[column.field]]}>
            {enums.CONVERTER_FORMA_PAGAMENTO[rowData[column.field]]}
          </div>
        )
      }
    }
    return null
  }

  oidTemplate = (rowData) => {
    return <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'oidContrato' && rowData.oidContrato) {
      const defaultValue = _.defaultTo(rowData.contrato_numero, rowData.oidContrato)
      return <Link to={`${routes.PATH_CONTRATO}/${rowData.oidContrato}`}>{defaultValue}</Link>
    }
    if (column.field === 'boleto_oid' && utils.nonNull(rowData[column.field])) {
      return <Button type="button" onClick={(e) => this.props.gerarBoletoLancamento(rowData)} icon={icons.DOWNLOAD} />
    }
    return utils.format(rowData, column)
  }

  fieldCalendarFilter = (column) => {
    return (
      <Calendar
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        yearNavigator={true}
        monthNavigator={true}
        yearRange={utils.getYearRangeFromDate('1900')}
        appendTo={document.body}
        showButtonBar={true}
        onChange={(e) => this.props.changeFilter(column, e)}
        name={column.field}
      />
    )
  }
  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (
        col.field === 'emissao' ||
        col.field === 'vencimento' ||
        col.field === 'dataPagamento' ||
        col.field === 'dataImportacao'
      ) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      }
      if (col.field === 'grupoItemPrincipal_tipo') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
            bodyClassName="column-grupo-tipo-body-width"
            filterHeaderClassName="column-grupo-tipo-filter-header-width"
            headerClassName="column-grupo-tipo-header-width"
          />
        )
      }
      if (col.field === 'grupoItemPrincipal_descricao' || col.field === 'contaCorrente_descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            style={{ textAlign: 'left' }}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-grupo-body-width"
            filterHeaderClassName="column-grupo-filter-header-width"
            headerClassName="column-grupo-header-width"
            body={listPageUtils.bodyDivWithTooltip}
          />
        )
      }
      if (col.field === 'status') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.statusTemplate}
            filterElement={this.booleanFilter(col)}
            bodyClassName="column-status-body-width"
            filterHeaderClassName="column-status-filter-header-width"
            headerClassName="column-status-header-width"
          />
        )
      }
      if (col.field === 'tipoFormaPagamento') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.tipoPagamentoFilter(col)}
            bodyClassName="column-forma-pagamento-body-width"
            filterHeaderClassName="column-forma-pagamento-filter-header-width"
            headerClassName="column-forma-pagamento-header-width"
          />
        )
      }
      if (col.field === 'valor' || col.field === 'valorPago') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-valor-body-width"
            filterHeaderClassName="column-valor-filter-header-width"
            headerClassName="column-valor-header-width"
          />
        )
      }
      if (col.fieldFormatter || col.field === 'oidContrato') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'boleto_oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            style={{ textAlign: 'center' }}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'observacao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            style={{ textAlign: 'left' }}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-observacao-body-width"
            filterHeaderClassName="column-observacao-filter-header-width"
            headerClassName="column-observacao-header-width"
            body={listPageUtils.bodyDivWithTooltip}
          />
        )
      }
      if (col.field === 'localizacao_identificacao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-localizacao-body-width"
            filterHeaderClassName="column-localizacao-filter-header-width"
            headerClassName="column-localizacao-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-unidade-body-width"
            filterHeaderClassName="column-unidade-filter-header-width"
            headerClassName="column-unidade-header-width"
          />
        )
      }
      if (col.field === 'pagador_nome') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-nome-body-width"
            filterHeaderClassName="column-nome-filter-header-width"
            headerClassName="column-nome-header-width"
            body={listPageUtils.bodyDivWithTooltip}
          />
        )
      }
      if (col.field !== 'contrato_numero') {
        return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
      }
    })
  }

  hideDialogRemoverVisao = () => {
    this.setState({ showDialogRemoveVisao: false })
  }

  buildContent = () => {
    return (
      <div>
        {this.getDialogDadosBaixaParcela()}
        {this.getDialogCancelar()}
        {this.getDialogArquivoRetorno()}
        <LancamentoList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LancamentoListPageContainer)
