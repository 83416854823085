import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { enums, icons, routes } from '../../../constants'
import { actions } from '../nota-entrada.actions'
import _ from 'lodash'
import utils from '../../../utils'
import { NotaEntradaForm } from './nota-entrada-form'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudNotaEntradaState.currentRecord
  const isRegistroImportado = !!_.get(record, 'dataImportacao', null)
  const status = _.get(record, 'status', -1)

  return {
    messages: state.crudNotaEntradaState.messages,
    record,
    currentUser: state.appState.currentUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    confirmar: (oids, onSuccess) => dispatch(actions.confirmar(oids, onSuccess)),
    cancelar: (oids, onSuccess) => dispatch(actions.cancelar(oids, onSuccess)),
    reabrir: (oids, onSuccess) => dispatch(actions.reabrir(oids, onSuccess)),
  }
}

class NotaEntradaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      activeIndex: 0,
      showConfirmarDialog: false,
      showCancelarDialog: false,
      showReabrirDialog: false,
    }
    this.history = props.history

    actions.afterSave = ({ oid }) => {
      this.history.replace(`${routes.PATH_NOTA_ENTRADA}/${oid}`)
    }
    actions.afterNew = () => {
      this.history.push(`${routes.PATH_NOTA_ENTRADA}/novo`)
    }
    actions.afterRemove = () => {
      this.history.replace(`${routes.PATH_NOTA_ENTRADA}`)
    }

    this.setField = props.setField
    this.confirmar = props.confirmar
    this.cancelar = props.cancelar
    this.reabrir = props.reabrir
  }

  onSuccess = (data) => {
    const dataEntrada = _.get(data, 'dataEntrada', null)
    const status = _.get(data, 'status', null)
    this.setField('dataEntrada', dataEntrada)
    this.setField('status', status)
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_NOTA_ENTRADA}`)
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  onChangeUnit = (e) => {
    // Remover os produtos que sao de outra unidade
    this.setField('unidade', e)
    // alterou unidade,reset no tipo
  }

  setFornecedor = (data) => {
    const value = {
      id: _.get(data, 'oid', ''),
      value: { id: _.get(data, 'numeroDocumento', ''), value: _.get(data, 'nome', '') },
    }
    this.setField('fornecedor', value)
  }

  setProduto = (data) => {
    const value = {
      id: _.get(data, 'oid', ''),
      value: _.get(data, 'descricao', ''),
    }
    this.setField('produto', value)
  }

  onChangeItens = (data) => {
    this.setValorTotal(data)
    this.setField('itens', _.get(data, 'values', []))
  }

  setValorTotal = (data) => {
    let valorTotal = utils.formatNumber(_.get(this, 'props.record.valorNota', 0))
    const recordsRemoved = _.get(data, 'recordsRemoved', null)
    const recordsAdded = _.get(data, 'recordsAdded', null)
    if (recordsAdded) {
      valorTotal += _.get(recordsAdded, 'valor', 0)
    } else {
      valorTotal -= _.get(recordsRemoved, 'valor', 0)
    }

    if (valorTotal < 0) {
      valorTotal = 0
    }
    this.setField('valorNota', valorTotal)
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  getBotoesPersonalizados = () => {
    const oid = _.get(this, 'props.record.oid', null)
    const status = _.get(this, 'props.record.status', null)
    const disabledConfirmar =
      !oid || status === enums.StatusNotaEntrada.CANCELADA || status === enums.StatusNotaEntrada.CONFIRMADA
    const disabledCancelar =
      !oid || status === enums.StatusNotaEntrada.CANCELADA || status === enums.StatusNotaEntrada.GERADA
    const disabledReabrir =
      !oid || status === enums.StatusNotaEntrada.CONFIRMADA || status === enums.StatusNotaEntrada.GERADA
    return [
      {
        type: 'button',
        label: 'Confirmar',
        onClick: this.showDialogConfirmar,
        icon: icons.CHECK_ICON,
        disabled: disabledConfirmar,
        className: 'p-button-success',
      },
      {
        type: 'button',
        label: 'Cancelar',
        onClick: this.showDialogCancelar,
        icon: icons.TIMES_ICON,
        disabled: disabledCancelar,
        className: 'p-button-danger',
      },
      {
        type: 'button',
        label: 'Reabrir',
        onClick: this.showDialogReabrir,
        icon: icons.UNLOCK_ICON,
        disabled: disabledReabrir,
      },
    ]
  }

  showDialogConfirmar = () => {
    this.setState({ showConfirmarDialog: true })
  }

  showDialogCancelar = () => {
    this.setState({ showCancelarDialog: true })
  }

  showDialogReabrir = () => {
    this.setState({ showReabrirDialog: true })
  }

  hideDialog = () => {
    this.setState({ showConfirmarDialog: false })
    this.setState({ showCancelarDialog: false })
    this.setState({ showReabrirDialog: false })
    this.setState({ showDialogRemove: false })
  }

  getDialogConfirmar() {
    if (this.state.showConfirmarDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarDialog}
          content={'Confirmar essa nota?'}
          confirm={this.handlerConfirmar}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogCancelar() {
    if (this.state.showCancelarDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showCancelarDialog}
          content={'Cancelar essa nota?'}
          confirm={this.handlerCancelar}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogReabrir() {
    if (this.state.showReabrirDialog) {
      return (
        <ConfirmDialog
          visible={this.state.showReabrirDialog}
          content={'Reabrir essa nota?'}
          confirm={this.handlerReabrir}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  handlerConfirmar = () => {
    const oid = _.get(this, 'props.record.oid', null)
    if (oid) {
      this.confirmar([oid], this.onSuccess)
    }
  }

  handlerCancelar = () => {
    const oid = _.get(this, 'props.record.oid', null)
    if (oid) {
      this.cancelar([oid], this.onSuccess)
    }
  }

  handlerReabrir = () => {
    const oid = _.get(this, 'props.record.oid', null)
    if (oid) {
      this.reabrir([oid], this.onSuccess)
    }
  }

  setItens = (itens) => {
    let total = 0
    for (const item of itens) {
      let quantidade = _.get(item, 'quantidade', 0)
      if (quantidade === 0) {
        quantidade = 1.0
        item.quantidade = quantidade
      }
      const valor = _.get(item, 'valor', 0)
      if (valor > 0) {
        total += utils.multiply(quantidade, valor, 2)
      }
    }
    this.setField('valorNota', total)
    this.setField('itens', itens)
  }

  render() {
    const content = (
      <div>
        {this.getDialogConfirmar()}
        {this.getDialogCancelar()}
        {this.getDialogReabrir()}
        <NotaEntradaForm
          state={this.state}
          changeActiveIndex={this.changeActiveIndex}
          record={this.props.record}
          onChangeUnit={this.onChangeUnit}
          setFornecedor={this.setFornecedor}
          setProduto={this.setProduto}
          history={this.props.history}
          setField={this.setField}
          setItens={this.setItens}
          currentUser={this.props.currentUser}
          onChangeItens={this.onChangeItens}
        />
      </div>
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotaEntradaFormPageContainer)
