import React, {useEffect, useState} from 'react'

import _ from 'lodash'
import './contrato-falecido-epi.scss'
import {Card} from 'primereact/card'
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {PickList} from "primereact/picklist";

export default function ContratoFalecidoAdiau(props) {
  useEffect(() => {}, [props.integracaoAdiau])
  const [camerasAdiauDisponiveis, setCamerasAdiauDisponiveis] = useState(props.camerasAdiauDisponiveis)

  const setField = (field, value) => {
    const integracaoAdiau = _.get(props, 'integracaoAdiau', {})
    integracaoAdiau[field] = value
    props.setField('integracaoAdiau', integracaoAdiau)
  }

  const onChangeCamerasAdiau = e => {
    setCamerasAdiauDisponiveis(e.source)

    let cameras = _.get(props, 'cameras', [])
    cameras = e.target
    setField('cameras', cameras)
  };

  return (
    <React.Fragment>
      <Card>
        <div className="p-grid form-group">

          {_.get(props.integracaoAdiau, 'idTransmissao', null) ? (
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <span className="p-text-bold">Transmissão criada, código: {_.get(props.integracaoAdiau, 'idTransmissao', null)}</span>
            </div>
          ) : null}

          <div className="p-col-12">
            <Checkbox
              inputId="chkAtivar"
              name="chkAtivar"
              checked={_.get(props.integracaoAdiau, 'habilitado', false)}
              onChange={(e) => setField('habilitado', e.checked)}>
            </Checkbox>
            <label style={{marginLeft: '5px'}}
                   htmlFor="chkAtivar">Habilitar
            </label>
          </div>
          <div className="p-col-12">

            <Checkbox
              inputId="chkEventoPublico"
              name="chkEventoPublico"
              checked={_.get(props.integracaoAdiau, 'eventoPublico', false)}
              onChange={(e) => setField('eventoPublico', e.checked)}
              disabled={!_.get(props.integracaoAdiau, 'habilitado', false)}>
            </Checkbox>
            <label style={{marginLeft: '5px'}}
                   htmlFor="chkEventoPublico">Evento público
            </label>
          </div>
          <div className="p-col-12">

            <span className="p-float-label">
              <InputText
                value={_.get(props.integracaoAdiau, 'senha', null)}
                onChange={(e) => setField('senha', e.target.value)}
                name="password"
                id="password"
                disabled={!_.get(props.integracaoAdiau, 'habilitado', false)}
              />
              <label htmlFor="password">Senha</label>
            </span>
          </div>

          <div className="p-col-12">

            <label style={{marginLeft: '10px'}}
                   htmlFor="listCameras">Câmeras
            </label>
            <PickList
              id="listCameras"
              name="listCameras"
              responsive={true}
              source={camerasAdiauDisponiveis}
              target={_.get(props.integracaoAdiau, 'cameras', [])}
              onChange={e => onChangeCamerasAdiau(e)}
              itemTemplate={(camera) => (
                <label>
                  {camera.id} - {camera.title}
                </label>
              )}
              sourceHeader="Disponíveis"
              targetHeader="Selecionados"
              showSourceControls={false}
              showTargetControls={false}
              showSourceFilter={false}
              showTargetFilter={false}
              dataKey="id"

            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
