import React from 'react';
import ParametroFormPageContainer from './ParametroFormPageContainer';

export class ParametroFormPage extends React.Component{
    render(){
        return (
            <ParametroFormPageContainer {...this.props}/>
        )
    }
}

