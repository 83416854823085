import React, { useEffect, useState } from 'react'

import './lts-select-produtos.scss'
import _ from 'lodash'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { enums, icons } from '../../constants'
import LtsLookupProduto from './lts-lookup-produto'
import LtsCurrency from './lts-currency'
import { formatarDinheiro } from '../../utils/index'
import utils from '../../utils'

export default function LtsSelectProdutos(props) {
  const [lookupValue, setLookupValue] = useState({
    oid: '',
    oidProduto: '',
    quantidade: '',
    descricaoProduto: '',
  })

  useEffect(() => {}, [lookupValue.oidProduto, lookupValue.descricaoProduto, props.values])

  const columnsValores = [
    {
      field: 'valor',
      header: 'Valor item',
      columnKey: 'valor',
      sortable: false,
      filter: false,
    },
    {
      field: 'total',
      header: 'Total item',
      columnKey: 'total',
      sortable: false,
      filter: false,
    },
  ]

  const columnAcoes = [
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ]

  const columns = [
    {
      field: 'oidProduto',
      header: 'Id',
      columnKey: 'oidProduto',
      sortable: false,
      filter: false,
    },
    {
      field: 'codigoProduto',
      header: 'Código',
      columnKey: 'codigoProduto',
      sortable: false,
      filter: false,
    },
    {
      field: 'descricaoProduto',
      header: 'Descrição',
      columnKey: 'descricaoProduto',
      sortable: false,
      filter: false,
    },
    {
      field: 'quantidade',
      header: 'Quantidade',
      columnKey: 'quantidade',
      sortable: false,
      filter: false,
    },
  ]

  const resolveColumns = () => {
    let _columns = []
    if (props.showColumnsValores) {
      _columns = [...columns, ...columnsValores, ...columnAcoes]
    } else {
      _columns = [...columns, ...columnAcoes]
    }
    return _columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'codigoProduto') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-codigo-produto-body-width"
            headerClassName="column-codigo-produto-header-width"
            filterHeaderClassName="column-codigo-produto-filter-header-width"
          />
        )
      }
      if (col.field === 'descricaoProduto') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-descricao-produto-body-width"
            headerClassName="column-descricao-produto-header-width"
            filterHeaderClassName="column-descricao-filter-header-width"
          />
        )
      }

      if (col.field === 'quantidade') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }

      if (col.field === 'valor') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
            filterHeaderClassName="column-valor-filter-header-width"
          />
        )
      }

      if (col.field === 'total') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
            filterHeaderClassName="column-valor-filter-header-width"
            alignHeader={'right'}
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actions}
            bodyClassName="column-actions-produto-body-width"
            headerClassName="column-actions-produto-header-width"
            filterHeaderClassName="column-actions-produto-filter-header-width"
          />
        )
      }
      return null
    })
  }

  const actions = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => removeHandler(rowData)}
        />
      </div>
    )
  }

  const removeHandler = (e) => {
    const oidProduto = _.get(e, 'oidProduto', null)
    if (oidProduto) {
      const values = props.values.filter((f) => f.oidProduto !== oidProduto)
      const recordRemoved = {
        oid: _.get(e, 'oid', null),
        oidProduto: _.get(e, 'oidProduto', null),
        quantidade: _.get(e, 'quantidade', 0),
        codigoProduto: _.get(e, 'codigoProduto', ''),
        descricaoProduto: _.get(e, 'descricaoProduto', ''),
      }
      const result = {
        values,
        recordAdded: null,
        recordRemoved,
      }
      props.onChange(result)
      resetLookupValue()
    }
  }

  const onChangeQuantidade = (rowData, quantidade) => {
    rowData.quantidade = quantidade
    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const onChangeValor = (rowData, valor) => {
    rowData.valor = valor
    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const formatColumn = (rowData, column) => {
    const field = _.get(column, 'field', '')
    if (field === 'descricaoProduto' && !props.editProduct) {
      return _.get(rowData, 'descricaoProduto', '')
    }
    if (field === 'descricaoProduto' && props.editProduct) {
      return (
        <LtsLookupProduto
          value={_.get(rowData, 'descricaoProduto', null)}
          history={props.history}
          editDisabled={true}
          filtroTipoProduto={enums.TipoProduto[0].id}
          onChange={(e) => onEditProdutoLookup(rowData, e)}
        />
      )
    }
    if (field === 'codigoProduto') {
      return _.get(rowData, 'codigoProduto', '')
    }
    if (field === 'oidProduto') {
      return _.get(rowData, 'oidProduto', null)
    }
    if (field === 'quantidade') {
      const quantidade = _.get(rowData, 'quantidade', null)
      return (
        <LtsCurrency
          value={quantidade}
          onChange={(e) => onChangeQuantidade(rowData, e)}
          name="quantidade"
          mode={'decimal'}
          maxFracionDigits={2}
          style={{ height: '30px' }}
        />
      )
    }
    if (field === 'valor') {
      const valor = _.get(rowData, 'valor', '')
      return (
        <LtsCurrency
          value={valor}
          onChange={(e) => onChangeValor(rowData, e)}
          name="valor"
          style={{ height: '30px' }}
        />
      )
    }
    if (field === 'total') {
      let valor_ = _.get(rowData, 'valor', 0)
      let quantidade_ = _.get(rowData, 'quantidade', 0)
      let total_ = 0
      if (valor_ > 0 && quantidade_ > 0) {
        total_ = utils.multiply(quantidade_, valor_, 2)
      }
      return formatarDinheiro(total_)
    }
    return null
  }

  const resolveSubtitle = () => {
    return null
  }

  // lookup callback functions //

  const onChangeLookup = (e) => {
    const oidProduto = _.get(e, 'oid', '')
    const quantidade = _.get(e, 'quantidade', props.quantidadePadrao ? props.quantidadePadrao : 0)
    const codigoProduto = _.get(e, 'codigo', '')
    const descricaoProduto = _.get(e, 'descricao', '')

    if (oidProduto) {
      const find = props.values.find((f) => f.oidProduto === oidProduto)
      if (!find) {
        // Adicionar config se permite ou nao duplicar produto?
        const recordAdded = {
          oid: '',
          oidProduto,
          quantidade,
          codigoProduto,
          descricaoProduto,
        }
        const values = [...props.values, recordAdded]
        const result = {
          values,
          recordAdded,
          recordRemoved: null,
        }
        props.onChange(result)
      }
      resetLookupValue()
    } else {
      setLookupValue({
        oid: '',
        oidProduto,
        quantidade,
        codigoProduto,
        descricaoProduto,
      })
    }
  }

  const onEditProdutoLookup = (rowData, e) => {
    const oidProduto = _.get(e, 'oid', '')
    const codigoProduto = _.get(e, 'codigo', '')
    const descricaoProduto = _.get(e, 'descricao', '')

    rowData.oidProduto = oidProduto
    rowData.codigoProduto = codigoProduto
    rowData.descricaoProduto = descricaoProduto

    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const resetLookupValue = () => {
    setLookupValue({
      oid: '',
      oidProduto: '',
      quantidade: '',
      codigoProduto: '',
      descricaoProduto: '',
    })
  }

  return (
    <React.Fragment>
      <Card title={props.title} subTitle={resolveSubtitle()}>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupProduto
              label={props.labelLookup}
              idUnidade={props.idUnidade}
              value={_.get(lookupValue, 'descricaoProduto', '')}
              history={props.history}
              editHidden={true}
              filtroTipoProduto={props.filtroTipoProduto}
              onChange={(e) => onChangeLookup(e)}
            />
          </div>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12">
            <DataTable
              emptyMessage=" "
              value={props.values}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              size={'small'}
              stripedRows={true}
              responsiveLayout="scroll"
            >
              {resolveColumns()}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
