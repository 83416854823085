import { types } from './mensagens.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import { FONTE_DE_DADOS, TIPO_MENSAGEM } from './constants'
import utils, { isArrayNotEmpty } from '../../utils/index'

const initialState = {
  id: 'agendamentoMensageria',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        columnSortField: 'oid',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Template Mensagem',
      value: {
        field: 'templateMensagem',
        header: 'Template Mensagem',
        columnKey: 'templateMensagem',
        sortable: true,
        filter: true,
        columnSortField: 'templateMensagem',
        visible: true,
      },
    },
    {
      label: 'CRON',
      value: {
        field: 'cron',
        header: 'CRON',
        columnKey: 'cron',
        sortable: true,
        filter: true,
        columnSortField: 'cron',
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    fonteDados: FONTE_DE_DADOS.EXCEL,
    fonteDadosPreview: [],
    fonteDadosPreviewColumns: [],
    templateMensagem: '',
    mensagem: '',
    query: '',
    cron: '',
    assunto: '',
    tipoMensagem: TIPO_MENSAGEM.SMS,
  },
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDAgendamentoMensageriaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('agendamentoMensageria')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_FONTE_DE_DADOS_PREVIEW:
      return setFonteDeDadosPreview(state, action)
    default:
      return state
  }
}

function setFonteDeDadosPreview(state, action) {
  let newColumns = []
  const payload = action.payload
  if (utils.isArrayNotEmpty(payload)) {
    const arrPropNames = Object.getOwnPropertyNames(payload[0])
    for (let propName of arrPropNames) {
      newColumns.push({
        field: propName,
        columnKey: propName,
        columnSortField: propName,
        header: propName,
        sortable: true,
        visible: propName !== 'discardProp',
      })
    }
  }
  state.currentRecord.fonteDadosPreview = []
  state.currentRecord.fonteDadosPreviewColumns = []
  newColumns.push({ field: 'acaoRemover', visible: true })
  Object.assign(state.currentRecord.fonteDadosPreview, payload)
  Object.assign(state.currentRecord.fonteDadosPreviewColumns, newColumns)
  if (isArrayNotEmpty(payload)) {
    state.currentRecord.mensagem = parseMensagem(state.currentRecord.templateMensagem, payload[0], newColumns)
  }
  return Object.assign({}, state)
}

function parseMensagem(templateMensagem, registro, columns) {
  let mensagem = templateMensagem
  for (const prop of columns) {
    const idx = mensagem.indexOf(`{{${prop.header}}}`)
    if (idx > -1) {
      mensagem = mensagem.replaceAll(`{{${prop.header}}}`, registro[prop.header])
    }
  }
  return mensagem
}
