import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import utils from "../../utils";
const BASE_PATH = "/lottustech/relatorios";
const crud = build(validate, BASE_PATH);
const prefix = "crudIsentosRelatorio";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.carregarTiposSepulturas = unidade => {
  let params = {
    unidades: unidade
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/tipossepulturas/`;
    const state = getState()[`crudIsentosRelatorioState`];
    return apiService.post(path, params).then(response => {
      if (state.currentRecord.tiposSepulturas.length > 0) {
        let itensRemover = [];
        state.currentRecord.tiposSepulturas.forEach(tipo => {
          const obj = utils.findObjectInArray(response.data, "value", tipo.value);
          if (!obj) {
            itensRemover.push(tipo.value);
          }
        });
        itensRemover.forEach(itemRemover => {
          utils.removeObjectFromArray(state.currentRecord.tiposSepulturas, "value", itemRemover);
        });
      }
      dispatch(actions.setField("opcoesTiposSepulturas", response.data));
    });
  };
};

actions.abrirRelatorio = (unidade, tiposSepulturas) => {
  let params = {
    unidades: unidade,
    tiposSepulturas: tiposSepulturas
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/isentos`;
    return apiService.post(path, params).then(response => {
      const win = window.open(response.data);
      if (win) {
        win.focus();
      }
    });
  };
};

export { types, actions };
