import React from "react";
import {DataTable} from "primereact/datatable";
import {Card} from "primereact/card";

import "./ConsultaObituarioList.scss";
import {Dialog} from "primereact/dialog";
import Image from "material-ui-image";
import {Button} from "primereact/button";
import {icons} from "../../../constants";

export const ConsultaObituarioList = props => {

    // const buildHeader = () => {
    //     return (
    //         <div className="p-grid">
    //             <div className="p-col-9">
    //                 <div className="p-col-12">
    //                     <h6 style={{marginTop: "-10px"}}>Localização: {props.unidadeMapa}</h6>
    //                 </div>
    //                 <div className="p-col-12">
    //                     <h6 style={{marginTop: "-65px"}}>{props.localizacao1}</h6>
    //                 </div>
    //                 <div className="p-col-12">
    //                     <h6 style={{marginTop: "-70px"}}>{props.localizacao2}</h6>
    //                 </div>
    //                 <div className="p-col-12">
    //                     <h6 style={{marginTop: "-75px"}}>{props.localizacao3}</h6>
    //                 </div>
    //                 <div className="p-col-12">
    //                     <h6 style={{marginTop: "-80px"}}>{props.localizacao4}</h6>
    //                 </div>
    //             </div>
    //             <div className="p-col-3">
    //                 <Button
    //                     type="button"
    //                     icon={icons.TIMES_ICON}
    //                     className="secondary-btn"
    //                     onClick={() => props.clearImagemLocalizacao()}
    //                 />
    //             </div>
    //         </div>
    //     )
    // }

    const buildHeader = () => {
        return (
            <div className="p-grid">
                <div className="p-col-10">
                    <div className="p-col-12">
                        <p style={{fontSize:13, marginTop: "-10px"}}>Localização: {props.unidadeMapa}</p>
                        <p style={{fontSize:12, marginTop: "-15px"}}>{props.localizacao1}</p>
                        <p style={{fontSize:12, marginTop: "-15px"}}>{props.localizacao2}</p>
                        <p style={{fontSize:12, marginTop: "-15px"}}>{props.localizacao3}</p>
                        <p style={{fontSize:12, marginTop: "-15px"}}>{props.localizacao4}</p>
                    </div>
                </div>
                <div className="p-col-2">
                    <Button
                        type="button"
                        icon={icons.TIMES_ICON}
                        className="secondary-btn"
                        onClick={() => props.clearImagemLocalizacao()}
                    />
                </div>
            </div>
        )
    }
    return (
        <div>
            <Dialog
                id={"dialogLocalizacao"}
                header={buildHeader()}
                visible={props.imagemLocalizacao && props.imagemLocalizacao.length > 10}
                style={{width: "90%", height: "90%"}}
                onHide={() => props.clearImagemLocalizacao()}
                baseZIndex={1000000}
                modal={true}
                closable={false}
                contentStyle={{maxHeight: "90%", overflow: "auto"}}
            >

                <div className="p-fluid">
                    <Image
                        src={props.imagemLocalizacao}
                    />
                </div>

            </Dialog>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable
                            ref={props.dataTableRef}
                            reorderableColumns={true}
                            selection={props.selectedRecords}
                            resizableColumns={true}
                            columnResizeMode="expand"
                            onSelectionChange={props.onSelectionChange}
                            onColReorder={props.onColReorder}
                            onSort={props.onSort}
                            onPage={props.onPage}
                            value={props.records}
                            lazy={true}


                            totalRecords={props.totalRecords}
                            sortOrder={props.order}
                            sortField={props.sortField}
                            paginator={true}
                            first={props.first}
                            rows={props.rows}
                            emptyMessage="Não foram encontrados registros"
                            rowsPerPageOptions={[15, 50, 100, 1000]}
                            stripedRows={true}
                            filterDisplay="row"
                            //expandedRows={props.expandedRows}
                            //onRowToggle={(e) => props.setExpandedRows(e)}
                            // rowExpansionTemplate={props.rowExpansionTemplate}
                        >
                            {/*<Column expander style={{width: '3em'}}/>*/}
                            {props.columns}
                        </DataTable>
                        <br/>
                        <Card>
                            <div style={{textAlign: "right"}}>
                                <strong>Total: </strong> {props.totalRecords}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
