import apiService from './api.service'

const getDocumentoByTipo = (tipo) => {
  return apiService.get(`/lottustech/documentos/tipo/${tipo}`)
}

const getDocumentoByTipoTelaEntidadeBase = (tipoTela, entidadeBase) => {
  return apiService.get(`/lottustech/documentos/${tipoTela}/${entidadeBase}`)
}

const getDocumentoByOid = (oidDocumento) => {
  return apiService.get(`/lottustech/documentos/${oidDocumento}`)
}

const getDocumentoByOidMenu = (oidMenu) => {
  return apiService.get(`/lottustech/documentos/menu/${oidMenu}`)
}

export { getDocumentoByTipo, getDocumentoByTipoTelaEntidadeBase, getDocumentoByOid, getDocumentoByOidMenu }
