import React from 'react';
import NotaEntradaListPageContainer from "./nota-entrada-list-page-container";

export class NotaEntradaListPage extends React.Component{
    
    render(){
      return (
        <NotaEntradaListPageContainer {...this.props}/>
      )
    }
}

