import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { getMessage } from '../../../utils/index'
import { Card } from 'primereact/card'
import _ from 'lodash'
import SelecaoDocumentos from '../../documento/selecao-documentos'
import { Checkbox } from 'primereact/checkbox'

export const TipoSepulturaForm = (props) => {
  const resolveTitulo = () => {
    const titulo = getMessage(props.messages, 'tiposepultura.label.tiposepultura')
    const oid = _.get(props, 'record.oid', '')
    return `${titulo} ${oid}`
  }

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card title={resolveTitulo()}>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <Dropdown
                  autoWidth={false}
                  disabled={props.disableUnit}
                  value={props.record.unidade}
                  options={props.unidades}
                  onChange={props.changeHandler}
                  optionLabel="value"
                  optionValue={(v) => v}
                  dataKey="value"
                  name="unidade"
                />
                <label>{getMessage(props.messages, 'tiposepultura.label.unidade')}</label>
              </span>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <InputText value={props.record.descricao} onChange={props.changeHandler} name="descricao" />
                <label>{getMessage(props.messages, 'tiposepultura.label.descricao')}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.plano}
                onChange={props.changeHandler}
                name="plano"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkPlano">{getMessage(props.messages, 'tiposepultura.label.plano')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.funeral}
                onChange={props.changeHandler}
                name="funeral"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkFuneral">{getMessage(props.messages, 'tiposepultura.label.funeral')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.cremacao}
                onChange={props.changeHandler}
                name="cremacao"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkCremacao">{getMessage(props.messages, 'tiposepultura.label.cremacao')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.titulo}
                onChange={props.changeHandler}
                name="titulo"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkTitulo">{getMessage(props.messages, 'tiposepultura.label.titulo')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.jazigo}
                onChange={props.changeHandler}
                name="jazigo"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkJazigo">{getMessage(props.messages, 'tiposepultura.label.jazigo')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.urna}
                onChange={props.changeHandler}
                name="urna"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkUrna">{getMessage(props.messages, 'tiposepultura.label.urna')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.gaveta}
                onChange={props.changeHandler}
                name="gaveta"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkGaveta">{getMessage(props.messages, 'tiposepultura.label.gaveta')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.pet}
                onChange={props.changeHandler}
                name="pet"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkPet">{getMessage(props.messages, 'tiposepultura.label.pet')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.falecido}
                onChange={props.changeHandler}
                name="falecido"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkFalecido">{getMessage(props.messages, 'tiposepultura.label.falecido')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.dependente}
                onChange={props.changeHandler}
                name="dependente"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkDependente">{getMessage(props.messages, 'tiposepultura.label.dependente')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.columbario}
                onChange={props.changeHandler}
                name="columbario"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkColumbario">{getMessage(props.messages, 'tiposepultura.label.columbario')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.capela}
                onChange={props.changeHandler}
                name="capela"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkCapela">{getMessage(props.messages, 'tiposepultura.label.capela')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
              <Checkbox
                checked={props.record.grid}
                onChange={props.changeHandler}
                name="grid"
                style={{ marginLeft: '5px', marginTop: '10px', marginRight: '5px' }}
              />
              <label htmlFor="chkGrid">{getMessage(props.messages, 'tiposepultura.label.grid')}</label>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <InputText
                  value={props.record.mascaraIdLocalizacao}
                  onChange={props.changeHandler}
                  name="mascaraIdLocalizacao"
                />
                <label>{getMessage(props.messages, 'tiposepultura.label.mascaraIdLocalizacao')}</label>
              </span>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <InputText
                  value={props.record.labelsIdLocalizacao}
                  onChange={props.changeHandler}
                  name="labelsIdLocalizacao"
                />
                <label>{getMessage(props.messages, 'tiposepultura.label.labelsIdLocalizacao')}</label>
              </span>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <InputText value={props.record.campoPesquisa} onChange={props.changeHandler} name="campoPesquisa" />
                <label>{getMessage(props.messages, 'tiposepultura.label.camposPesquisa')}</label>
              </span>
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <span className="p-float-label">
                <InputText value={props.record.mascaraGrid} onChange={props.changeHandler} name="mascaraGrid" />
                <label>{getMessage(props.messages, 'tiposepultura.label.mascaragrid')}</label>
              </span>
            </div>
          </div>
        </Card>
      </div>
      <div className="p-col-12">
        <SelecaoDocumentos
          titulo={'Documentos padrões'}
          name={'documentoTempLookup'}
          documentoTempLookup={props.documentoTempLookup}
          history={props.history}
          onKeyPress={props.lookupDocumentoOnKeyPress}
          onComplete={props.lookupDocumentoOnComplete}
          onInputFilter={props.lookupDocumentoOnInputFilter}
          editDisabled={props.lookupDocumento.editDisabled}
          onEditField={props.lookupDocumentoOnEdit}
          onClick={props.lookupDocumentoOnClick}
          visible={props.lookupDocumento.visible}
          modal={props.lookupDocumento.modal}
          header={props.lookupDocumento.header}
          onHide={props.lookupDocumentoOnHide}
          onConfirm={props.lookupDocumentoOnConfirm}
          onCancel={props.lookupDocumentoOnCancel}
          records={props.lookupDocumento.records}
          columns={props.lookupDocumento.columns}
          totalRecords={props.lookupDocumento.totalRecords}
          sortOrder={props.lookupDocumento.order}
          sortField={props.lookupDocumento.sortField}
          first={props.lookupDocumento.first}
          rows={props.lookupDocumento.rows}
          onPage={props.lookupDocumentoOnPage}
          onFilter={props.lookupDocumentoOnFilter}
          onSort={props.lookupDocumentoOnSort}
          editHidden={true}
          documentos={props.documentos}
          onPageDatatable={props.onPage}
          colunasTDocumentos={props.colunasTDocumentos}
        ></SelecaoDocumentos>
      </div>
    </div>
  )
}
