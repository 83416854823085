import React from 'react'
import { getMessage } from '../../../utils/index'
import { Card } from 'primereact/card'
import { InputMask } from 'primereact/inputmask'

import './ContaCorrenteForm.scss'
import LtsInputText from '../../../components/lottus/lts-input-text'
import utils from '../../../utils'
import _ from "lodash";
import LtsLookupContaCorrente from "../../../components/lottus/lts-lookup-conta-corrente";
import LtsLookupConfiguracaoBoleto from "../../../components/lottus/lts-lookup-configuracao-boleto";

export const ContaCorrenteForm = (props) => {
  const record = props.record
  const messages = props.messages

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card title={`${getMessage(messages, 'contacorrente.label.contacorrente')} ${record.oid || ''}`}>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.descricao}
                onChange={(e) => props.onChangeDescricao(e)}
                name="descricao"
                autoFocus={true}
                maxLength={255}
                label={getMessage(messages, 'contacorrente.label.descricao')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsLookupConfiguracaoBoleto
                label={utils.getMessage(messages, 'contacorrente.label.configuracaoboleto')}
                value={_.get(record, 'configuracaoBoleto.value', '')}
                oidRecord={_.get(record, 'configuracaoBoleto.id', '')}
                history={props.history}
                onChange={(e) => props.setConfiguracaoBoleto(e)}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-2">
              <LtsInputText
                value={record.banco}
                onChange={(e) => props.onChangeField('banco', e)}
                name="banco"
                maxLength={50}
                label={getMessage(messages, 'contacorrente.label.banco')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-2">
              <LtsInputText
                value={record.agencia}
                onChange={(e) => props.onChangeField('agencia', e)}
                name="agencia"
                maxLength={50}
                label={getMessage(messages, 'contacorrente.label.agencia')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-2">
              <LtsInputText
                value={record.numero}
                onChange={(e) => props.onChangeField('numero', e)}
                name="numero"
                maxLength={50}
                label={getMessage(messages, 'contacorrente.label.numero')}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
