import React from 'react';
import ParametroEmpresaFormPageContainer from './ParametroEmpresaFormPageContainer';

export class ParametroEmpresaFormPage extends React.Component{
    render(){
        return (
            <ParametroEmpresaFormPageContainer {...this.props}/>
        )
    }
}

