import { types } from './usuario.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'usuario',
  booleanFilter: {
    ativo: '',
  },
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Login',
      value: {
        field: 'login',
        header: 'Login',
        columnKey: 'login',
        sortable: true,
        columnSortField: 'login',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'E-mail',
      value: {
        field: 'email',
        header: 'E-mail',
        columnKey: 'email',
        sortable: true,
        columnSortField: 'email',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Pessoa',
      value: {
        field: 'pessoa',
        header: 'Pessoa',
        columnKey: 'pessoa',
        sortable: true,
        columnSortField: 'pessoa.nome',
        filter: true,
        relacionamento: ['pessoa'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Ativo',
      value: {
        field: 'ativo',
        header: 'Ativo',
        columnKey: 'ativo',
        sortable: true,
        columnSortField: 'ativo',
        filter: true,
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    oidPessoa: '',
    login: '',
    senha: '',
    email: '',
    pessoa: '',
    perfis: [],
    token: '',
    dataCriacaoToken: '',
    ativo: true,
    unidade: {},
    unidades: [],
  },
  tipos: ['EMPRESA', 'CLIENTE', 'LOTTUSTECH'],
  lookupPessoa: {
    visible: false,
    modal: true,
    header: 'Pessoas',
  },
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDUsuarioReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('usuario')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLoockupPessoaVisible(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.PICKLIST_PERFIS_MOVE_TO_SOURCE:
      return pickListPerfisMoveToSource(state, action)
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE:
      return pickListPerfisMoveAllToSource(state)
    case types.PICKLIST_PERFIS_MOVE_TO_TARGET:
      return pickListPerfisMoveToTarget(state, action)
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_TARGET:
      return pickListPerfisMoveAllToTarget(state)
    case types.PICKLIST_UNIDADES_MOVE_TO_SOURCE:
      return pickListUnidadesMoveToSource(state, action)
    case types.PICKLIST_UNIDADES_MOVE_ALL_TO_SOURCE:
      return pickListUnidadesMoveAllToSource(state)
    case types.PICKLIST_UNIDADES_MOVE_TO_TARGET:
      return pickListUnidadesMoveToTarget(state, action)
    case types.PICKLIST_UNIDADES_MOVE_ALL_TO_TARGET:
      return pickListUnidadesMoveAllToTarget(state)
    case types.SET_UNIDADE:
      return setUnidade(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setLoockupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible })
  return Object.assign({}, state, { lookupPessoa })
}

function buildPerfilAcao(perfis = [], oidAcao) {
  let perfisAcao = perfis.map((perfil) => {
    return {
      oid: perfil.oid,
      nomePerfil: perfil.nomePerfil,
    }
  })
  return perfisAcao
}

function pickListPerfisMoveToSource(state, action) {
  let perfisAcaoDeselecionados = []

  action.perfisAcaoRemoved.forEach((perfilAcaoRemoved) => {
    perfisAcaoDeselecionados = state.currentRecord.perfis.filter(
      (perfilAcao) => perfilAcao.oid !== perfilAcaoRemoved.oid
    )
  })

  let currentRecord = Object.assign({}, state.currentRecord, { perfis: perfisAcaoDeselecionados })
  return Object.assign({}, state, { currentRecord })
}

function pickListPerfisMoveAllToSource(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord, { perfis: [] })
  return Object.assign({}, state, { currentRecord })
}

function filterOldPerfisAcao(perfisAdd, perfis) {
  return perfisAdd.filter((perfil) => {
    let existe = perfis.filter((perfilAcao) => perfilAcao.oidPerfil === perfil.oid)
    return existe.length > 0 ? false : true
  })
}

function pickListPerfisMoveToTarget(state, action) {
  let perfis = [...state.currentRecord.perfis]
  let perfisAdd = filterOldPerfisAcao(action.perfisAdd, perfis)
  let perfisAcao = buildPerfilAcao(perfisAdd, action.oidAcao)

  perfis = [...perfis, ...perfisAcao]

  let currentRecord = Object.assign({}, state.currentRecord, { perfis })
  return Object.assign({}, state, { currentRecord })
}

function pickListPerfisMoveAllToTarget(state, action) {
  return pickListPerfisMoveToTarget(state)
}

function pickListUnidadesMoveToSource(state, action) {
  let deselecionados = []

  action.payload.forEach((data) => {
    deselecionados = state.currentRecord.unidades.filter((unidade) => unidade.id !== data.id)
  })

  let currentRecord = Object.assign({}, state.currentRecord, { unidades: deselecionados })
  return Object.assign({}, state, { currentRecord })
}

function pickListUnidadesMoveAllToSource(state) {
  let currentRecord = Object.assign({}, state.currentRecord, { unidades: [] })
  return Object.assign({}, state, { currentRecord })
}

function pickListUnidadesMoveToTarget(state, action) {
  let unidades = [...state.currentRecord.unidades]
  let unidadesAdd = filterOldUnidades(action.unidadesAdd, unidades)
  // let unidadesBuild = buildUnidades(unidadesAdd)

  unidades = [...unidades, ...unidadesAdd]

  let currentRecord = Object.assign({}, state.currentRecord, { unidades })
  return Object.assign({}, state, { currentRecord })
}

function filterOldUnidades(unidadesAdd, unidades) {
  return unidadesAdd.filter((unidade) => {
    let existe = unidades.filter((und) => und.id === unidade.id)
    return existe.length <= 0
  })
}

function pickListUnidadesMoveAllToTarget(state) {
  return pickListUnidadesMoveToTarget(state)
}

function setUnidade(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.unidade = action.payload
  return Object.assign({}, state, { currentRecord })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
