import _ from 'lodash'
import {blankToNull} from '../../../utils'


export function validate(record) {
  const errors = [];
  if (!record.codigo) {
    errors.push({
      field: "codigo",
      message: "O campo código é obrigatório",
    })
  }

  if (!record.descricao) {
    errors.push({
      field: "descricao",
      message: "O campo descrição é obrigatório",
    })
  }

  if (!record.tipoProduto) {
    errors.push({
      field: "tipoProduto",
      message: "O campo tipo de produto é obrigatório",
    })
  }

  const unidade = _.get(record, 'unidade.id', -1)
  if (unidade === -1) {
    record.unidade = null
  }

  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record,
  };
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
