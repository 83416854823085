import { types } from './portalClienteContratos.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'portalClienteContrato',
  selectedFilters: [],
  selectedParcelas: [],
  booleanFilter: {  },
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'numeroContrato',
        header: 'Código',
        columnKey: 'numeroContrato',
        sortable: true,
        filter: true,
        columnSortField: 'numeroContrato',
        visible: true,
        showFilterMenuOptions: false,
        showClearButton: false,
        showFilterMenu: false,
      },
    },
    {
      label: 'Tipo Contrato',
      value: {
        field: 'tipoContrato',
        columnKey: 'tipoContrato',
        sortable: true,
        header: 'Tipo Contrato',
        filter: true,
        columnSortField: 'tipoContrato',
        visible: true,
      },
    },
    {
      label: 'Tipo do documento',
      value: {
        field: 'nomeContratoDocumento',
        columnKey: 'nomeContratoDocumento',
        sortable: true,
        header: 'Tipo do documento',
        filter: true,
        columnSortField: 'nomeContratoDocumento',
        visible: true,
      },
    },
    {
      label: 'Nome',
      value: {
        field: 'nomeEmail',
        columnKey: 'nomeEmail',
        sortable: true,
        header: 'Nome',
        filter: true,
        columnSortField: 'nomeEmail',
        visible: true,
      },
    },
    {
      label: "Ações",
      value: {
        field: "oidContratoDocumento",
        header: "Ações",
        columnKey: "oidContratoDocumento",
        sortable: false,
        filter: false,
        columnSortField: "oidContratoDocumento",
        visible: true
      }
    }
  ],
  columns: [],
  currentRecord: {},
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDPortalClienteContratoReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}