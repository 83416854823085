import React from 'react';

import NfseFormPageContainer from './NfseFormPageContainer';

export class NfseFormPage extends React.Component{

    
    render(){
        return (
           <NfseFormPageContainer {...this.props} />
        )
    }
}
