import React, {useState} from "react";
import {DataTable} from "primereact/datatable";
import {enums} from "../../../constants";
import {FilterMatchMode} from "primereact/api";


export const ParametroEmpresaForm = props => {
    return (
        <div className="dataTableParametros">
            <DataTable
                selectionMode="single"
                value={props.records}
                totalRecords={props.totalRecords}
                first={props.first}
                rows={props.rows}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                selection={props.parametroSelecionado}
                onSelectionChange={props.onSelectionChange}
                onRowSelect={props.onParametroSelecionado}
            >
                {props.columns}
            </DataTable>
        </div>
    );
};
