import { types } from "./consultaobituario.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "consultaFalecido",
  showHideColumns: [
    {
      label: "Unidade",
      value: {
        field: "nomeUnidade",
        header: "Unidade",
        columnKey: "nomeUnidade",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Localização",
      value: {
        field: "localizacao",
        header: "Localização",
        columnKey: "localizacao",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Falecido",
      value: {
        field: "falecido",
        header: "Falecido",
        columnKey: "falecido",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Data Nascimento",
      value: {
        field: "dataNascimento",
        header: "Data Nascimento",
        columnKey: "dataNascimento",
        visible: true,
        filter: true
      }
    }
  ],
  columns: [
    {
      field: "sobre",
      header: "Sobre",
      columnKey: "sobre",
      visible: false,
      filter: false,
      sortable: false
    },
    {
      field: "nomeUnidade",
      header: "Unidade",
      columnKey: "nomeUnidade",
      sortable: true,
      filter: true,
      visible: false
    },
    {
      field: "localizacao",
      header: "Localização",
      columnKey: "localizacao",
      sortable: true,
      filter: true,
      visible: true
    },
    {
      field: "falecido",
      header: "Falecido",
      columnKey: "falecido",
      sortable: true,
      filter: true,
      visible: true
    },
    {
      field: "dataNascimento",
      header: "Data Nascimento",
      columnKey: "dataNascimento",
      fieldFormatter: "formatarData",
      visible: false,
      filter: true,
      sortable: true
    },
    {
      field: "dataFalecimento",
      header: "Data Falecimento",
      columnKey: "dataFalecimento",
      fieldFormatter: "formatarData",
      visible: false,
      filter: true,
      sortable: true
    },
    {
      field: "oidContratoFalecido",
      header: "oidContratoFalecido",
      columnKey: "oidContratoFalecido",
      visible: true,
      filter: false,
      sortable: false
    },
    {
      field: "link",
      header: "link",
      columnKey: "link",
      visible: true,
      filter: false,
      sortable: false
    },
    {
      field: "exibeMapa",
      header: "exibeMapa",
      columnKey: "exibeMapa",
      visible: true,
      filter: false,
      sortable: false
    },
    {
      field: "acoes",
      header: "",
      columnKey: "acoes",
      visible: true
    }
  ],
  currentRecord: {
    dataInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dataFinal: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  },
  messages: null,
  imagemLocalizacao: ""
};

const reducer = new Reducer(initialState, types);

export function CRUDConsultaObituarioReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("consultafalecido");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_IMAGEM_LOCALIZACAO:
      return setImagemLocalizacao(state, action);
    case types.CLEAR_IMAGEM_LOCALIZACAO:
      return clearImagemLocalizacao(state, action);
    default:
      return state;
  }
}

function setImagemLocalizacao(state, action) {
  const imagemLocalizacao = action.payload.urlMapa;
  const localizacao1 = action.payload.localizacao1;
  const localizacao2 = action.payload.localizacao2;
  const localizacao3 = action.payload.localizacao3;
  const localizacao4 = action.payload.localizacao4;
  const unidadeMapa = action.payload.unidade;
  return Object.assign({}, state, { imagemLocalizacao: imagemLocalizacao, localizacao1, localizacao2, localizacao3, localizacao4, unidadeMapa});
}

function clearImagemLocalizacao(state, action) {
  return Object.assign({}, state, { imagemLocalizacao: '', localizacao1: '', localizacao2: '', localizacao3: '', localizacao4: '', unidadeMapa: '' });
}
