import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as pessoaActions } from '../../pessoa/pessoa.actions'
import { actions } from '../agenda.actions'
import { AgendaForm } from './AgendaForm'
import utils from '../../../utils/index'
import { Filtro } from '../../../utils/Filtro.js'
import storage from '../../../services/stoage.service'
import { actions as localizacaoActions } from '../../localizacao/localizacao.actions'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudAgendaState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  const record = state.crudAgendaState.currentRecord
  const currentUser = state.appState.currentUser

  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = { id: currentUnit.oid, value: currentUnit.sigla }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  const pessoa = state.crudAgendaState.currentRecord.pessoa
  const lookupPessoaBase = {
    records: state.crudPessoaState.records,
    columns: state.crudPessoaState.columnsLookup
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
    editDisabled: false,
  }
  let lookupPessoa = Object.assign({}, state.crudAgendaState.lookupPessoa, lookupPessoaBase)

  // REFERENTE AO LOOKUP DE LOCALIZACAO
  const lookupLocalizacaoBase = {
    records: state.crudLocalizacaoState.records,
    columns: state.crudLocalizacaoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'identificacao' || col.field === 'tipoSepultura_descricao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudLocalizacaoState.index,
    rows: state.crudLocalizacaoState.max,
    totalRecords: state.crudLocalizacaoState.count,
    sortField: state.crudLocalizacaoState.sortField,
    order: state.crudLocalizacaoState.sortOrder,
    editDisabled: false,
  }

  let lookupLocalizacao = Object.assign({}, state.crudAgendaState.lookupLocalizacao, lookupLocalizacaoBase)

  return {
    disableUnit,
    unidades,
    currentUser,
    record,
    pessoa,
    lookupPessoa,
    lookupLocalizacao,
    messages,
    messagesDialog,
    first: state.crudAgendaState.index,
    rows: state.crudAgendaState.max,
    totalRecords: state.crudAgendaState.count,
    sortField: state.crudAgendaState.sortField,
    order: state.crudAgendaState.sortOrder,
    idTela: state.crudAgendaState.id,
    botoesDinamicos: state.crudAgendaState.botoesDinamicos,
    isSaving: state.crudAgendaState.isSaving,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    setField: (field, value) => {
      dispatch(actions.setField(field, value))
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'unidade') {
        dispatch(actions.setField('unidade', valor))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },
    saveHandler: (event) => {
      dispatch(actions.beforeSave())
      dispatch(actions.save())
    },
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),

    lookupPessoaPaginate: (paginationParams) => dispatch(pessoaActions.paginate(paginationParams)),
    lookupPessoaSortOrder: (sortParams) => dispatch(pessoaActions.sortOrder(sortParams)),
    lookupPessoaApplyFilter: (filterParams) => {
      dispatch(pessoaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaActions.loadLookup())
    },
    lookupPessoaLoad: () => dispatch(pessoaActions.loadLookup()),
    lookupPessoaSetVisible: (visible) => dispatch(actions.setLookupPessoaVisible(visible)),

    lookupLocalizacaoSetVisible: (visible) => dispatch(actions.setLookupLocalizacaoVisible(visible)),
    lookupLocalizacaoPaginate: (paginationParams) => dispatch(localizacaoActions.loadLookup(paginationParams)),
    lookupLocalizacaoSortOrder: (sortParams) => dispatch(localizacaoActions.loadLookup(sortParams)),
    lookupLocalizacaoApplyFilter: (filterParams) => {
      dispatch(actions.aplicarFiltrosCarregarDadosLocalizacao(filterParams))
    },
    lookupLocalizacaoLoad: () => {
      dispatch(actions.aplicarFiltrosCarregarDadosLocalizacao())
    },

    clearSelectedFilters: () => {
      dispatch(pessoaActions.clearSelectedFilters())
    },
    clearRecords: () => {
      dispatch(pessoaActions.setRecords([]))
    },
  }
}

class AgendaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.state = {
      activeIndex: 0,
      showDialogRemove: false,
      screenWidth: null,
    }
    this.unidades = storage.getUnits()
    actions.beforeFetchRecordSuccess = (data) => {
      if (data) {
        if (data.dataInicial) {
          data.dataInicial = utils.getDateTimeFromNumber(data.dataInicial)
        }
        if (data.dataFinal) {
          data.dataFinal = utils.getDateTimeFromNumber(data.dataFinal)
        }
      }
      return data
    }

    actions.afterSave = (data) => {
      this.props.history.replace(`${routes.PATH_AGENDA}/${data.oid}`)
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_AGENDA}/novo`)
      this.props.clearSelectedFilters()
      this.resolveUnidadeMatriz()
    }

    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_AGENDA}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
      let unidade = storage.getUnit()
      if (utils.nonNull(this.props.location.state) && utils.nonNull(this.props.location.state.unidade)) {
        this.props.setField('unidade', this.props.location.state.unidade)
      } else if (!utils.nonNull(unidade)) {
        this.resolveUnidadeMatriz()
      }
    }
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth })
  }

  resolveUnidadeMatriz() {
    const unidade = utils.getIdValueUnidadeMatriz(storage.getCompany())
    this.props.setField('unidade', unidade)
  }

  componentDidUpdate(anteriorProps) {
    if (anteriorProps.record.oidUnidade === '' && this.props.record.unidade) {
      Object.assign(this.props.record, {
        oidUnidade: this.props.record.unidade.oid,
      })
      Object.assign(this.props.record, {
        descricaoUnidade: this.props.record.unidade.descricao,
      })
    }
  }

  searchHandler = () => {
    this.props.newHandler()
    this.props.history.push(`${routes.PATH_AGENDA}`)
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  resolveOidUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    return utils.isIdValueIdValido(idValueUnidade) ? idValueUnidade.id : null
  }

  resolveFiltroUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    if (utils.isIdValueIdValido(idValueUnidade)) {
      return {
        campo: 'unidade.oid',
        valor: idValueUnidade.id,
        tipoFiltro: 'IGUAL_A',
        relacionamento: ['unidade'],
      }
    }
    return null
  }

  //REFERENTE AO LOOKUP DE PESSOA
  lookupPessoaOnPage = (e) => {
    this.props.lookupPessoaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPessoaOnSort = (e) => {
    this.props.lookupPessoaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPessoaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupPessoaApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupPessoaLoad()
    }
  }

  lookupPessoaOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupPessoaLoad()
    this.props.lookupPessoaSetVisible(true)
  }
  lookupPessoaOnHide = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnConfirm = (e) => {
    this.setFieldPessoa(e.selection.oid, e.selection.nome)
    this.props.lookupPessoaSetVisible(false)
  }

  setFieldPessoa = (oid, nome) => {
    this.props.setField('pessoa.id', oid)
    this.props.setField('pessoa.value', nome)
  }

  lookupPessoaOnCancel = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }

  lookupPessoaOnEdit = () => {
    return `${routes.PATH_PESSOA_FISICA}`
  }
  lookupPessoaOnComplete = (e) => {}

  lookupPessoaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('pessoa.id', null)
      this.props.setField('pessoa.value', e.value)
      this.props.clearRecords()
    } else {
      this.setFieldPessoa(e.value.oid, e.value.nome)
      const filters = {}
      this.props.lookupPessoaApplyFilter(filters)
    }
  }

  lookupPessoaOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.pessoa) && utils.nonNull(this.props.record.pessoa.value)) {
      const filtroNome = new Filtro('nome', this.props.record.pessoa.value, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.record.pessoa.value, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupPessoaApplyFilter(filtroOu)
    }
  }

  // REFERENTE AO LOOKUP PARA LOCALIZAÇÃO
  lookupLocalizacaoOnPage = (e) => {
    this.props.lookupLocalizacaoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupLocalizacaoOnSort = (e) => {
    this.props.lookupLocalizacaoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupLocalizacaoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupLocalizacaoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupLocalizacaoLoad()
    }
  }

  onChangeUnitLocalizacao = (e) => {
    const target = e.target
    let valor = target.value
    this.props.setField('unidade', valor)
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoLoad()
  }

  lookupLocalizacaoOnClick = (e) => {
    if (this.props.record.unidade && this.props.record.unidade.id) {
      this.props.setField('unidade', this.props.record.unidade)
    }
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoLoad()
    this.props.lookupLocalizacaoSetVisible(true)
  }
  lookupLocalizacaoOnHide = (e) => {
    this.props.lookupLocalizacaoSetVisible(false)
    this.props.clearSelectedFilters()
  }
  lookupLocalizacaoOnConfirm = ({ selection }) => {
    this.props.setField('localizacao.id', selection.oid)
    this.props.setField('localizacao.value', this.resolveLocalizacaoDisplayLabel(selection))
    this.props.lookupLocalizacaoSetVisible(false)
    this.props.clearSelectedFilters()
  }
  resolveLocalizacaoDisplayLabel = (value) => {
    if (value.temporaria) {
      return value.identificacao + ' (Temp)'
    }
    return value.identificacao
  }
  lookupLocalizacaoOnCancel = (e) => {
    this.props.lookupLocalizacaoSetVisible(false)
  }
  lookupLocalizacaoOnEdit = () => {
    if (this.props.record.localizacao.id) {
      return `${routes.PATH_LOCALIZACAO}/${this.props.record.localizacao.id}`
    } else {
      return `${routes.PATH_LOCALIZACAO}/`
    }
  }
  lookupLocalizacaoOnComplete = (e) => {}

  lookupLocalizacaoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('localizacao.value', e.value)
      this.props.setField('localizacao.id', null)
      this.props.clearRecords()
    } else {
      this.props.setField('localizacao.id', value.oid)
      this.props.setField('localizacao.value', this.resolveLocalizacaoDisplayLabel(value))
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupLocalizacaoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.localizacao) && utils.nonNull(this.props.record.localizacao.value)) {
      const filtroIdentificacao = new Filtro('identificacao', this.props.record.localizacao.value, 'CONTEM')
      this.props.lookupLocalizacaoApplyFilter(filtroIdentificacao)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  hideBotaoRemover = () => {
    return true
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <div>
        <AgendaForm
          state={this.state}
          changeActiveIndex={this.changeActiveIndex}
          disableUnit={this.props.disableUnit}
          unidades={this.props.unidades}
          currentUser={this.props.currentUser}
          messages={this.props.messages}
          changeHandler={this.props.changeHandler}
          record={this.props.record}
          onSelectionChange={this.onSelectionChange}
          history={this.props.history}
          lookupPessoa={this.props.lookupPessoa}
          lookupPessoaOnKeyPress={this.lookupPessoaOnKeyPress}
          lookupPessoaOnHide={this.lookupPessoaOnHide}
          lookupPessoaOnConfirm={this.lookupPessoaOnConfirm}
          lookupPessoaOnCancel={this.lookupPessoaOnCancel}
          lookupPessoaOnPage={this.lookupPessoaOnPage}
          lookupPessoaOnSort={this.lookupPessoaOnSort}
          lookupPessoaOnFilter={this.lookupPessoaOnFilter}
          lookupPessoaOnClick={this.lookupPessoaOnClick}
          lookupPessoaOnEdit={this.lookupPessoaOnEdit}
          lookupPessoaOnComplete={this.lookupPessoaOnComplete}
          lookupPessoaOnInputFilter={this.lookupPessoaOnInputFilter}
          //--
          lookupLocalizacao={this.props.lookupLocalizacao}
          onChangeUnitLocalizacao={this.onChangeUnitLocalizacao}
          // onChangeApenasLocalizacaoTemporaria={this.onChangeApenasLocalizacaoTemporaria}
          lookupLocalizacaoOnKeyPress={this.lookupLocalizacaoOnKeyPress}
          lookupLocalizacaoOnHide={this.lookupLocalizacaoOnHide}
          lookupLocalizacaoOnConfirm={this.lookupLocalizacaoOnConfirm}
          lookupLocalizacaoOnCancel={this.lookupLocalizacaoOnCancel}
          lookupLocalizacaoOnPage={this.lookupLocalizacaoOnPage}
          lookupLocalizacaoOnSort={this.lookupLocalizacaoOnSort}
          lookupLocalizacaoOnFilter={this.lookupLocalizacaoOnFilter}
          lookupLocalizacaoOnClick={this.lookupLocalizacaoOnClick}
          lookupLocalizacaoOnEdit={this.lookupLocalizacaoOnEdit}
          lookupLocalizacaoOnComplete={this.lookupLocalizacaoOnComplete}
          lookupLocalizacaoOnInputFilter={this.lookupLocalizacaoOnInputFilter}
        />
      </div>
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaFormPageContainer)
