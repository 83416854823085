import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export const LoadingSpinner = () => (
  <div
    className="p-grid p-justify-center p-align-center"
    style={{
      position: "fixed",
      left: "0px",
      top: "0px",
      width: "100%",
      height: "100%",
      zIndex: "9999"
    }}
  >
    <ProgressSpinner style={{ height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
  </div>
);
