import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { icons } from '../../constants'
import utils, { formatarDinheiro } from '../../utils/index'
import apiService from '../../services/api.service'
import LtsLookupItemLancamento from './lts-lookup-item-lancamento'
import LtsCurrency from './lts-currency'
import LtsInputNumber from './lts-input-number'

export default function LtsTableItensLancamento(props) {
  const [lookupValue, setLookupValue] = useState({
    itemLancamento: null,
    oidEmpresa: '',
    oidUnidade: '',
    valor: '',
    descricao: '',
    quantidade: '',
  })

  useEffect(() => {}, [lookupValue.tipo, lookupValue.oidTipo, lookupValue.descricao, props.values])

  const columns = [
    {
      field: 'oidTipo',
      header: 'Código',
      columnKey: 'oidTipo',
      sortable: false,
      filter: false,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: false,
      filter: false,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: false,
      filter: false,
    },
    {
      field: 'quantidade',
      header: 'Quantidade',
      columnKey: 'quantidade',
      sortable: false,
      filter: false,
    },
    {
      field: 'valorTotal',
      header: 'Total',
      columnKey: 'valorTotal',
      sortable: false,
      filter: false,
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ]

  const resolveColumns = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'oidTipo') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatarColumnTaxa}
            bodyClassName="column-oid-taxa-body-width"
            headerClassName="column-oid-taxa-header-width"
          />
        )
      }
      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatarColumnTaxa}
            bodyClassName="column-descricao-taxa-body-width"
            headerClassName="column-descricao-taxa-header-width"
          />
        )
      }
      if (col.field === 'valor') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatarColumnTaxa}
            bodyClassName="column-valor-taxa-body-width"
            headerClassName="column-valor-taxa-header-width"
          />
        )
      }
      if (col.field === 'quantidade') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatarColumnTaxa}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }
      if (col.field === 'valorTotal') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatarColumnTaxa}
            bodyClassName="column-valor-body-width"
            headerClassName="column-valor-header-width"
            filterHeaderClassName="column-valor-filter-header-width"
            alignHeader={'right'}
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actionItem}
            bodyClassName="column-oid-taxa-body-width"
            headerClassName="column-oid-taxa-header-width"
          />
        )
      }
      return <Column key={col.field} {...col} />
    })
  }

  const actionItem = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => removeItem(e, rowData)}
        />
      </div>
    )
  }

  const removeItem = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    const itemLancamento = _.get(data, 'itemLancamento', null)
    if (itemLancamento) {
      const itemRemoved = props.values.find((f) => f.itemLancamento === itemLancamento)
      const newItens = props.values.filter((f) => f !== itemRemoved)
      if (itemRemoved) {
        const result = {
          values: newItens,
          serviceAdded: null,
          serviceRemoved: itemRemoved,
        }
        props.onChange(result)
        resetLookupValue()
      }
    }
  }

  const formatarColumnTaxa = (rowData, column) => {
    const field = _.get(column, 'field', '')
    if (field === 'descricao') {
      return _.get(rowData, 'descricao', '')
    }
    if (field === 'oidTipo') {
      return _.get(rowData, 'itemLancamento.oidTipo', null)
    }
    if (field === 'valor') {
      const valor = _.get(rowData, 'valor', '')
      return (
        <LtsCurrency
          value={valor}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="valor"
          inputStyle={{ height: '30px', maxWidth: '10rem', minWidth: '10rem', width: '10rem' }}
        />
      )
    }
    if (field === 'quantidade') {
      const quantidade = _.get(rowData, 'quantidade', null)
      return (
        <LtsInputNumber
          value={quantidade}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="quantidade"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
        />
      )
    }
    if (field === 'valorTotal') {
      let valor_ = _.get(rowData, 'valor', 0)
      let quantidade_ = _.get(rowData, 'quantidade', 0)
      let total_ = 0
      if (valor_ > 0 && quantidade_ > 0) {
        total_ = utils.multiply(quantidade_, valor_, 2)
      }
      return formatarDinheiro(total_)
    }
  }

  const onChangeTableValue = (rowData, value, field) => {
    rowData[field] = value
    const quantidade = _.get(rowData, 'quantidade', 0)
    const valor = _.get(rowData, 'valor', 0)
    rowData['valorTotal'] = utils.multiply(quantidade, valor, 2)
    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const resolveSubtitle = () => {
    return null
  }

  const resolveTitle = () => {
    const title = _.get(props, 'title', null)
    if (title) {
      return title
    }
    return 'Serviços'
  }
  // lookup taxa callback functions //

  const onChangeLookup = (e) => {
    const tipo = _.get(e, 'tipo', '')
    const oid = _.get(e, 'oidTipo', null)
    const quantidade = 0
    if (tipo === 'TAXA') {
      if (oid) {
        loadPacoteServicos(e)
        resetLookupValue()
      } else {
        setLookupValue({
          oid: '',
          itemLancamento: null,
          valor: '',
          descricao: _.get(e, 'descricao', ''),
          quantidade,
        })
      }
    } else {
      if (oid) {
        onSuccessLoadPacoteServicos(e, null)
        resetLookupValue()
      } else {
        setLookupValue({
          oid: '',
          itemLancamento: null,
          valor: '',
          descricao: _.get(e, 'descricao', ''),
          quantidade,
        })
      }
    }
  }

  const resetLookupValue = () => {
    setLookupValue({
      oid: '',
      itemLancamento: null,
      valor: '',
      descricao: '',
      quantidade: '',
    })
  }

  // -------------------------------//

  const loadPacoteServicos = (taxa) => {
    const oid = _.get(taxa, 'oidTipo', null)
    if (oid) {
      apiService
        .get(`/lottustech/itemlancamento/pacote/${oid}`)
        .then((resp) => onSuccessLoadPacoteServicos(taxa, resp))
        .catch(onErrorLoadPacoteServicos)
    }
  }

  const isItemAlreadySelected = (item) => {
    const found = props.values.find(
      (f) =>
        _.get(f, 'itemLancamento.oidTipo', -1) === _.get(item, 'oidTipo', 0) &&
        _.get(f, 'itemLancamento.tipo', '') === _.get(item, 'tipo', '')
    )
    return !!found
  }

  const onSuccessLoadPacoteServicos = (itemSelected, response) => {
    const taxas = _.get(response, 'data', [])
    if (utils.isArrayNotEmpty(taxas)) {
      let novosServicos = []
      for (const tx of taxas) {
        const itemLancamento = {
          tipo: _.get(tx, 'tipo', null),
          oidTipo: _.get(tx, 'oidTipo', null),
        }
        const alreadySelected = isItemAlreadySelected(itemLancamento)
        if (!alreadySelected) {
          const valor = _.get(tx, 'valor', 0)
          const item = {
            oid: '',
            itemLancamento,
            valor,
            descricao: _.get(tx, 'descricao', ''),
            quantidade: 1,
            valorTotal: valor,
          }
          novosServicos.push(item)
        }
      }

      const result = {
        values: [...props.values, ...novosServicos],
        serviceAdded: {
          ...itemSelected,
          itemLancamento: {
            tipo: _.get(itemSelected, 'tipo', null),
            oidTipo: _.get(itemSelected, 'oidTipo', null),
          },
        },
        serviceRemoved: null,
      }

      props.onChange(result)
    } else {
      const itemLancamento = {
        tipo: _.get(itemSelected, 'tipo', null),
        oidTipo: _.get(itemSelected, 'oidTipo', null),
      }
      const alreadySelected = isItemAlreadySelected(itemLancamento)
      if (!alreadySelected) {
        const valor = _.get(itemSelected, 'valor', 0)
        const item = {
          oid: '',
          itemLancamento,
          valor,
          descricao: _.get(itemSelected, 'descricao', ''),
          quantidade: 1,
          valorTotal: valor,
        }
        const result = {
          values: [...props.values, item],
          serviceAdded: item,
          serviceRemoved: null,
        }
        props.onChange(result)
      }
    }
  }

  const onErrorLoadPacoteServicos = (error) => {
    console.log(JSON.stringify(error))
    resetLookupValue()
  }

  return (
    <React.Fragment>
      <Card title={resolveTitle()} subTitle={resolveSubtitle()}>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupItemLancamento
              header={'Produtos/Serviços'}
              label={'Produto/Serviço'}
              value={_.get(lookupValue, 'descricao', '')}
              history={props.history}
              onChange={(e) => onChangeLookup(e)}
              idUnidade={_.get(props, 'idUnidade', null)}
              filtroTipoTaxa={props.filtroTipoTaxa}
            />
          </div>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12">
            <DataTable
              emptyMessage=" "
              value={props.values}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              stripedRows
              responsiveLayout="scroll"
              size={'small'}
            >
              {resolveColumns()}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
