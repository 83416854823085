import { types } from './contaCorrente.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'contacorrente',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Descrição',
      value: {
        field: 'descricao',
        header: 'Descrição',
        columnKey: 'descricao',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Número',
      value: {
        field: 'numero',
        header: 'Número',
        columnKey: 'numero',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Agência',
      value: {
        field: 'agencia',
        header: 'Agência',
        columnKey: 'agencia',
        sortable: true,
        columnSortField: 'agencia',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Banco',
      value: {
        field: 'banco',
        header: 'Banco',
        columnKey: 'banco',
        sortable: true,
        columnSortField: 'banco',
        filter: true,
        visible: true,
      },
    }
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],

  columns: [],
  currentRecord: {
    oid: '',
    descricao: '',
    numero: '',
    agencia: '',
    banco: '',
    configuracaoBoleto: ''
  },
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDContaCorrenteReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('contacorrente')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    case types.MAKE_NEW:
      return initialize(state)
    default:
      return state
  }
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
  }
}

const initialize = (state) => {
  const currentRecord = state.currentRecord
  return Object.assign({}, state, { currentRecord: currentRecord })
}
