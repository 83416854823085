import React, { useEffect, useRef, useState } from 'react'
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import { Tag } from 'primereact/tag'
import { Dialog } from 'primereact/dialog'
import _ from 'lodash'

export default function LtsUploadFiles(props) {
  const [totalSize, setTotalSize] = useState(0)
  const fileUploadRef = useRef(null)

  useEffect(() => {}, [props.visible])

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize
    if (e.files.length > 0) {
      for (const file of e.files) {
        _totalSize += file.size
      }
    }
    setTotalSize(_totalSize)
  }

  const onTemplateUpload = (e) => {
    let _totalSize = 0
    e.files.forEach((file) => {
      _totalSize += file.size || 0
    })
    setTotalSize(_totalSize)
  }

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size)
    callback()
  }

  const onTemplateClear = () => {
    setTotalSize(0)
  }

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options
    const value = totalSize / 10000
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B'

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <ProgressBar
          value={value}
          displayValueTemplate={() => `${formatedValue} / 1 MB`}
          style={{ width: '300px', height: '20px', marginLeft: 'auto' }}
        ></ProgressBar>
      </div>
    )
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: '40%' }}>
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-3 p-p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="p-my-5">
          {props.dragAndDropMessage}
        </span>
      </div>
    )
  }

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  }
  const uploadOptions = {
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
  }
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  }

  const uploadHandler = (e) => {
    const files = _.get(e, 'files', [])
    onTemplateClear()
    props.onUploadHandler(files)
    props.onHide(e)
  }

  return (
    <Dialog onHide={props.onHide} header={props.title} visible={props.visible} modal={true} style={{ width: '65%' }}>
      <div className="p-grid p-fluid margin-top-dialogs">
        <div className="p-col-12">
          <Tooltip target=".custom-choose-btn" content="Selecionar arquivo" position="bottom" />
          <Tooltip target=".custom-upload-btn" content="Enviar" position="bottom" />
          <Tooltip target=".custom-cancel-btn" content="Limpar" position="bottom" />

          <FileUpload
            ref={fileUploadRef}
            name="file"
            multiple
            accept=".xml,.zip"
            maxFileSize={1000000}
            customUpload={true}
            onUpload={onTemplateUpload}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            uploadHandler={uploadHandler}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
          />
        </div>
      </div>
    </Dialog>
  )
}
