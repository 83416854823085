import React from "react";

export default function NotaEntradaFormTitle(props) {

    let fontColor = "#ffffff"; // nao definido
    if (props.status === 1) {
        fontColor = "#607d8b" // Gerada
    } else if (props.status === 2) { // Confirmado
        fontColor = "#4CAF50"
    } else if (props.status === 3) { // Cancelado
        fontColor = "#ff4d4d"
    }

    const resolveLabelStatus = () => {
        if (props.status === 1) {
            return 'Gerada'
        }
        if (props.status === 2) {
            return 'Confirmada'
        }
        if (props.status === 3) {
            return 'Cancelada'
        }
        return ''

    }

    return (
        <div>
            <h4>
                {`Nota Entrada ${props.oid || ""}`}
                <span className="tag" style={{marginLeft: "15px", backgroundColor: fontColor}}>
                    {resolveLabelStatus()}
                </span>
            </h4>
        </div>
    )
}