import { types } from "./dashboard.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  currentRecord: {
    atividades: {
      oid: "",
      boolean: false,
      data: null,
      descricao: "",
      tag: { id: "", value: "" }
    },
    maisAcessados: [],
    ultimosAcessados: [],
    tasks: [],
    messages: null,
    tags: {}
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDDashboardPortalClienteReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("dashboard");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  return result;
}
