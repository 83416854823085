import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { AppTopbar } from '../../../components/portalClienteTopBar/AppTopbar'
import { routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { DashboardList } from './DashboardList'
import { actions } from '../dashboard.actions'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    record: state.crudDashboardPortalClienteState.currentRecord,
    messages: state.crudDashboardPortalClienteState.messages,
    columns: state.crudDashboardPortalClienteState.columns,
    records: state.crudDashboardPortalClienteState.records,
    first: state.crudDashboardPortalClienteState.index,
    rows: state.crudDashboardPortalClienteState.max,
    totalRecords: state.crudDashboardPortalClienteState.count,
    sortField: state.crudDashboardPortalClienteState.sortField,
    order: state.crudDashboardPortalClienteState.sortOrder,
    selectedFilters: state.crudDashboardPortalClienteState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/public/consulta/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    //paginate: paginationParams => dispatch(actions.loadAtividades(paginationParams)),
    loadLancamentos: () => dispatch(actions.loadLancamentos()),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
  }
}

class DashboardListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      atualizar: false,
    }
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.clearSelectedFilters()
    this.props.loadLancamentos()
  }

  onChange = (event) => {
    const target = event.target
    const field = target.name
    let valor = target.value
    if (valor) {
      if (valor && !valor.id) {
        this.props.setField(field, valor)
      } else if (!event.value.value) {
        this.props.setField('tagAtividade', event.value)
      } else if (event.value.value) {
        this.props.setField('tagAtividade', event.value.value)
        this.props.setField('oidTag', event.value.id)
      }
    } else if (field === 'descricaoAtividade') {
      this.props.setField(field, '')
    } else {
      this.props.setField('tagAtividade', null)
      this.props.setField('oidTag', null)
    }
  }
  loadData = (e) => {
    this.props.loadAtividades(e.first, e.rows)
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    })
  }

  onTaskChange = (e) => {
    this.props.onTaskChange(e, this.props.record.records)
  }

  buildContent = () => {
    const content = (
      <DashboardList {...this.props} messages={this.props.messages} onPage={this.onPage} onChange={this.onChange} />
    )
    return (
      <div className="layout-content">
        <Page header={null} content={content} />;
      </div>
    )
  }

  render() {
    let wrapperClass = classNames('layout-wrapper', {
      'layout-wrapper-static': this.state.layoutMode === 'static',
      'layout-wrapper-active': this.state.mobileMenuActive,
      'layout-menu-horizontal': this.state.layoutMode === 'horizontal',
    })
    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <div className="layout-main">
          <AppTopbar />
          {this.buildContent()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardListPageContainer)
