import React from 'react';

import LocalizacaoFormPageContainer from './LocalizacaoFormPageContainer';

export class LocalizacaoFormPage extends React.Component{

    
    render(){
        return (
           <LocalizacaoFormPageContainer {...this.props} />
        )
    }
}
