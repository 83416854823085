import { React, useState } from 'react'
import { getMessage } from '../../../utils/index'

import './MapaCoordenadaForm.scss'

import LtsDropdownMapas from '../../../components/lottus/lts-dropdown-mapas'
import LtsLookupContrato from '../../../components/lottus/lts-lookup-contrato'
import _ from 'lodash'
import LtsInputText from '../../../components/lottus/lts-input-text'

export const MapaCoordenadaForm = (props) => {
  const record = props.record
  const messages = props.messages

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>
              {getMessage(messages, 'mapaCoordenada.label.mapaCoordenadas')} {record.oid || ''}
            </h3>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                <LtsDropdownMapas
                  name={'mapa'}
                  label={'Mapa'}
                  value={props.record.mapa}
                  onChange={(e) => props.setField('mapa', e)}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                <LtsLookupContrato
                  label={getMessage(messages, 'mapaCoordenada.label.contrato')}
                  value={_.get(record, 'contrato.value', '')}
                  oidRecord={_.get(record, 'contrato.id', '')}
                  oidUnidade={_.get(record, 'contrato', '')}
                  history={props.history}
                  onChange={props.setContrato}
                  stateContrato={props.stateContrato}
                  editHidden={false}
                  addFiltroDistint={true}
                  showColumnUnidade={true}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
                <LtsInputText
                  value={record.posicaoX}
                  onChange={(e) => props.setField('posicaoX', e)}
                  name="posicaoX"
                  label={getMessage(messages, 'mapaCoordenada.label.posicaoX')}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
                <LtsInputText
                  value={record.posicaoY}
                  onChange={(e) => props.setField('posicaoY', e)}
                  name="posicaoY"
                  label={getMessage(messages, 'mapaCoordenada.label.posicaoY')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
