import React from "react";
import {Link} from "react-router-dom";
import {routes} from "../../../constants";
import _ from "lodash";

export default function OrcamentoFormSubtitle(props) {

    const getSubtitle = () => {
        const oid = _.get(props, 'oidContrato', null)
        if (oid) {
            return (
                <div>
                    {`Contrato: `}
                    <Link
                        to={`${routes.PATH_CONTRATO}/${oid}`}
                        onClick={e => props.history.push(`${routes.PATH_CONTRATO}/${oid}`)}
                    >
                        {oid}
                    </Link>
                </div>
            )
        }
        return null

    }

    return (getSubtitle())
}