import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../funcaoDinamica.actions'
import { FuncaoDinamicaForm } from './FuncaoDinamicaForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    record: state.crudFuncaoDinamicaState.currentRecord,
    messages: state.crudFuncaoDinamicaState.messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class FuncaoDinamicaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_FUNCAO_DINAMICA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_FUNCAO_DINAMICA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_FUNCAO_DINAMICA}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_FUNCAO_DINAMICA}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <FuncaoDinamicaForm
        record={this.props.record}
        changeHandler={this.props.changeHandler}
        messages={this.props.messages}
      />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FuncaoDinamicaFormPageContainer)
