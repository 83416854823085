import React from 'react';

import AgendaFormPageContainer from './AgendaFormPageContainer';

export class AgendaFormPage extends React.Component{

    
    render(){
        return (
           <AgendaFormPageContainer {...this.props} />
        )
    }
}
