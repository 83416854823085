import React from "react";
import {InputText} from "primereact/inputtext";
import utils from "../../../utils/index";
import {DataTable} from "primereact/datatable";
import {enums} from "../../../constants";

import "./NfseForm.scss";

function getColor(statusNFSe) {
    if (statusNFSe === 6) {
        return "green"; // AUTORIZADA = VERDE
    } else if (statusNFSe === 9) {
        return "gray"; // CANCELADA = AZUL
    } else if (statusNFSe === 10) {
        return "red"; // REJEITADA = VERMELHO
    } else if (statusNFSe === 3) {
        return "gold"; // CRITICADA = LARANJA
    }
}

function getStatus(statusNFSe) {
    if (statusNFSe === 6) {
        return "AUTORIZADA"; // AUTORIZADA = VERDE
    } else if (statusNFSe === 9) {
        return "CANCELADA"; // CANCELADA = AZUL
    } else if (statusNFSe === 10) {
        return "REJEITADA"; // REJEITADA = VERMELHO
    } else if (statusNFSe === 3) {
        return "CRITICADA"; // CRITICADA = LARANJA
    }
}

export const NfseForm = props => {
    const record = props.record;
    const messages = props.messages;
    return (
        <div className="p-grid p-fluid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>
                        {utils.getMessage(messages, "nfse.label.nfse")} {record.oid || ""}
                        <span class="tag" style={{marginLeft: "15px", backgroundColor: getColor(record.statusNFSe)}}>
              {getStatus(record.statusNFSe)}
            </span>
                    </h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.numeroNFSe} name="numeroNFSe" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.numeroNfse")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
                            <span className="p-float-label">
                                <InputText value={record.chaveAcesso} name="chaveAcesso" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.chaveAcesso")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-3 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.codigoNaturezaOperacao} name="codigoNaturezaOperacao" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.codigoNaturezaOperacao")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.simplesNacional === 1 ? "Sim" : "Não"} name="simplesNacional" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.simplesNacional")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.incentivoCultura ? "Sim" : "Não"} name="incentivoCultura" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.incentivoCultura")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.numeroRPS} name="numeroRPS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.numeroRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.serieRPS} name="serieRPS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.serieRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.tipoRPS} name="tipoRPS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.tipoRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.dataHoraEmissaoRPS} name="dataHoraEmissaoRPS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.dataHoraEmissaoRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                            <span className="p-float-label">
                                <InputText value={record.informacoesAdicionaisFisco} name="informacoesAdicionaisFisco" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.informacoesAdicionaisFisco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.situacaoRPS} name="situacaoRPS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.situacaoRPS")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* NOTA SUBSTITUIDA */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.notaSubstituida")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.numeroRPSNotaSubstituida} name="numeroRPSNotaSubstituida" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.numeroRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.serieRPSNotaSubstituida} name="serieRPSNotaSubstituida" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.serieRPS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-2 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.tipoRPSNotaSubstituida} name="tipoRPSNotaSubstituida" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.tipoRPS")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* PRESTADOR DE SERVICO */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.prestadorServico")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={record.inscricaoMunicipalPrestadorServico}
                                    name="inscricaoMunicipalPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.inscricaoMunicipal")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.razaoSocialPrestadorServico}
                                    name="razaoSocialPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.razaoSocial")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.nomeFantasiaPrestadorServico}
                                    name="nomeFantasiaPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.nomeFantasia")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.cpfCnpjPrestadorServico} name="cpfCnpjPrestadorServico" disabled="true"/>
                                <label>
                                    {utils.getMessage(
                                        messages,
                                        record.indicadorCpfCnpjPrestadorServico === "Cpf"
                                            ? "nfse.label.cpf"
                                            : record.indicadorCpfCnpjPrestadorServico === "Cnpj"
                                                ? "nfse.label.cnpj"
                                                : ""
                                    )}
                                </label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={record.codigoMunicipioIBGEPrestadorServico}
                                    name="codigoMunicipioIBGEPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.codigoMunicipioIBGE")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.cepPrestadorServico} name="cepPrestadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.cep")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.siglaUFPrestadorServico} name="siglaUFPrestadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.ufEndereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.cidadePrestadorServico} name="cidadePrestadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.cidade")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.bairroPrestadorServico} name="bairroPrestadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.bairro")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.enderecoPrestadorServico} name="enderecoPrestadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.endereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.complementoPrestadorServico}
                                    name="complementoPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.complementoEndereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.numeroEnderecoPrestadorServico}
                                    name="numeroEnderecoPrestadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.numeroEndereco")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* TOMADOR DE SERVICO */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.tomadorServico")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={record.inscricaoMunicipalTomadorServico}
                                    name="inscricaoMunicipalTomadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.inscricaoMunicipal")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.razaoSocialTomadorServico} name="razaoSocialTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.razaoSocial")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.nomeFantasiaTomadorServico} name="nomeFantasiaTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.nomeFantasia")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.cpfCnpjTomadorServico} name="cpfCnpjTomadorServico" disabled="true"/>
                                <label>
                                    {utils.getMessage(
                                        messages,
                                        record.indicadorCpfCnpjTomadorServico === "Cpf"
                                            ? "nfse.label.cpf"
                                            : record.indicadorCpfCnpjTomadorServico === "Cnpj"
                                                ? "nfse.label.cnpj"
                                                : ""
                                    )}
                                </label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={record.codigoMunicipioIBGETomadorServico}
                                    name="codigoMunicipioIBGETomadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.codigoMunicipioIBGE")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.cepTomadorServico} name="cepTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.cep")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
                            <span className="p-float-label">
                                <InputText value={record.siglaUFTomadorServico} name="siglaUFTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.ufEndereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.cidadeTomadorServico} name="cidadeTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.cidade")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.bairroTomadorServico} name="bairroTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.bairro")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.enderecoTomadorServico} name="enderecoTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.endereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.complementoTomadorServico} name="complementoTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.complementoEndereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.numeroEnderecoTomadorServico}
                                    name="numeroEnderecoTomadorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.numeroEndereco")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.codigoPaisTomadorServico} name="codigoPaisTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.codigoPais")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.emailTomadorServico} name="emailTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.email")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.telefoneTomadorServico} name="telefoneTomadorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.telefone")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* SERVIÇO PRESTADO */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.servicosPrestados")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorServico)} name="valorServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorServico")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorDeducoes)} name="valorDeducoes" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorDeducoes")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorPIS)} name="valorPIS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorPIS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatCurrency(record.aliquotaPIS)} name="aliquotaPIS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.aliquotaPIS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorCOFINS)} name="valorCOFINS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorCOFINS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorINSS)} name="valorINSS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorINSS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorIR)} name="valorIR" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorIR")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorCSLL)} name="valorCSLL" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorCSLL")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.itemListaServico} name="itemListaServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.itemListaServico")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.descricaoItemListaServico} name="descricaoItemListaServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.descricaoItemListaServico")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.cnae} name="cnae" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.cnae")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.descricaoCnae} name="descricaoCnae" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.descricaoCnae")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                            <span className="p-float-label">
                                <InputText value={record.tributacaoMunicipal} name="tributacaoMunicipal" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.tributacaoMunicipal")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                            <span className="p-float-label">
                                <InputText
                                    value={record.descricaoTributacaoMunicipal}
                                    name="descricaoTributacaoMunicipal"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.descricaoTributacaoMunicipal")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatCurrency(record.aliquotaServico)} name="aliquotaServico" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.aliquotaServico")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorIss)} name="valorIss" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorIss")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.valorOutrasRetencoes)}
                                    name="valorOutrasRetencoes"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.valorOutrasRetencoes")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.valorCredito)} name="valorCredito" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.valorCredito")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.issRetido ? "Sim" : "Não"} name="issRetido" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.issRetido")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.valorDescontoIncondicional)}
                                    name="valorDescontoIncondicional"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.valorDescontoIncondicional")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.valorDescontoCondicional)}
                                    name="valorDescontoCondicional"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.valorDescontoCondicional")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.municipioIncidencia} name="municipioIncidencia" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.municipioIncidencia")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={record.codigoMunicipioIBGEServicoPrestado}
                                    name="codigoMunicipioIBGEServicoPrestado"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.codigoMunicipioIBGE")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.codigoPaisServicoPrestado} name="codigoPaisServicoPrestado" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.codigoPais")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.numeroProcesso} name="numeroProcesso" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.numeroProcesso")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.responsavelRetencao} name="responsavelRetencao" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.responsavelRetencao")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* VALORES NOTA */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.valoresNota")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatCurrency(record.aliquota)} name="aliquota" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.aliquota")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorDeducoes)}
                                    name="totalValorDeducoes"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorDeducoes")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.totalValorISS)} name="totalValorISS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.totalValorISS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorDescontoIncondicional)}
                                    name="totalValorDescontoIncondicional"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorDescontoIncondicional")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorDescontoCondicional)}
                                    name="totalValorDescontoCondicional"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorDescontoCondicional")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorServico)}
                                    name="totalValorServico"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorServico")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorNominalNota)}
                                    name="totalValorNominalNota"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorNominalNota")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* RETENÇÕES */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.retencoes")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.issRetidoRetencoes ? "Sim" : "Não"} name="issRetidoRetencoes" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.issRetidoRetencoes")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.totalValorPIS)} name="totalValorPIS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.totalValorPIS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorCOFINS)}
                                    name="totalValorCOFINS"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorCOFINS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.totalValorINSS)} name="totalValorINSS" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.totalValorINSS")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.totalValorIR)} name="totalValorIR" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.totalValorIR")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={utils.formatarDinheiro(record.totalValorCSLL)} name="totalValorCSLL" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.totalValorCSLL")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText
                                    value={utils.formatarDinheiro(record.totalValorOutrasRetencoes)}
                                    name="totalValorOutrasRetencoes"
                                    disabled="true"
                                />
                                <label>{utils.getMessage(messages, "nfse.label.totalValorOutrasRetencoes")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* DISTRIBUIÇÃO */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.distribuicao")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.emailsDistribuicao} name="emailsDistribuicao" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.emailsDistribuicao")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-sm-6 p-md-4 p-lg-4 p-xl-3">
                            <span className="p-float-label">
                                <InputText value={record.regimeEspecialTributacao} name="regimeEspecialTributacao" disabled="true"/>
                                <label>{utils.getMessage(messages, "nfse.label.regimeEspecialTributacao")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* HISTORICOS */}
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>{utils.getMessage(messages, "nfse.label.historicos")}</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12">
                            <DataTable
                                autoLayout={true}
                                value={record.historicos}
                                onRowSelect={props.onRowSelect}
                                selectionMode="single"
                                resizableColumns={true}
                                columnResizeMode="fit"
                                filterDisplay="row"
                                size={"small"}
                                stripedRows={true}
                                responsiveLayout="scroll"
                            >
                                {props.colunasHistorico}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h3>Lançamentos</h3>
                    <div className="p-grid form-group">
                        <div className="p-col-12">
                            <DataTable
                                ref={props.dataTableLancamentos}
                                value={props.record.lancamentosFinanceiros}
                                paginator={true}
                                emptyMessage="Não foram encontrados registros"
                                rows={15}
                                rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                                filterDisplay="row"
                                size={"small"}
                                stripedRows={true}
                                responsiveLayout="scroll"
                            >
                                {props.colunasTLancamentoFinanceiro}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
