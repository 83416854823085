import React from 'react';
import ConsultaObituarioListPageContainer from './ConsultaObituarioListPageContainer';

export class ConsultaObituarioListPage extends React.Component{
    
    render(){
      return (
        <ConsultaObituarioListPageContainer {...this.props}/>
      )
    }
}


