import React from 'react';
import MenuListPageContainer from './MenuListPageContainer';

export class MenuListPage extends React.Component{
    
    render(){
      return (
        <MenuListPageContainer {...this.props}/>
      )
    }
}


