const blankToNull = require("./../../../utils").blankToNull;
const validateEmail = require("./../../../utils").validateEmail;

export function validate(record) {
  const errors = [];
  let VALIDAR_CPF = true;
  if (!record.nome || record.nome.trim().length < 3) {
    errors.push({ field: "nome", message: "O campo nome é obrigatório e deve ter mais de 3 caracteres" });
  }

  if (record.dataNascimento) {
    if (window.moment().isBefore(record.dataNascimento)) {
      errors.push({
        field: "dataNascimento",
        message: "O campo data de nascimento não pode ser maior que a data atual!"
      });
    }
    if (window.moment().diff(record.dataNascimento, "years") <= 4) {
      VALIDAR_CPF = false;
    }
  }
  if (record.dataFalecimento) {
    if (window.moment().isBefore(record.dataFalecimento)) {
      errors.push({
        field: "dataNascimento",
        message: "O campo data de falecimento não pode ser maior que a data atual!"
      });
    }
    if (window.moment().diff(record.dataFalecimento, "years") >= 5) {
      VALIDAR_CPF = false;
    }
  }
  if (record.email && !validateEmail(record.email)) {
    errors.push({ field: "email", message: "O campo e-mail é inválido!" });
  }

  if (VALIDAR_CPF) {
    if (!record.numeroDocumento) {
      errors.push({ field: "numeroDocumento", message: "O campo CPF é obrigatório" });
    } else if (!isValidCPF(record.numeroDocumento)) {
      errors.push({ field: "numeroDocumento", message: "CPF inválido!" });
    }
  }
  blankToNull(record);

  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}

function isValidCPF(strCPF) {
  var Soma;
  var Resto;
  Soma = 0;
  if (
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999"
  )
    return false;
  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}
