import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { Column } from 'primereact/column'
import { actions as perfilActions } from '../../perfil/perfil.actions'
import { actions as funcaoDinamicaActions } from '../../funcaoDinamica/funcaoDinamica.actions'
import { actions } from '../botaoDinamico.actions'
import utils from '../../../utils'
import BotaoDinamicoForm from './BotaoDinamicoForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const lookupFuncaoDinamicaBase = {
    records: state.crudFuncaoDinamicaState.records,
    columns: state.crudFuncaoDinamicaState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nomeFuncao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudFuncaoDinamicaState.index,
    rows: state.crudFuncaoDinamicaState.max,
    totalRecords: state.crudFuncaoDinamicaState.count,
    sortField: state.crudFuncaoDinamicaState.sortField,
    order: state.crudFuncaoDinamicaState.sortOrder,
    editDisabled: false,
  }

  let lookupFuncaoDinamica = Object.assign(
    {},
    state.crudBotaoDinamicoState.lookupFuncaoDinamica,
    lookupFuncaoDinamicaBase
  )

  return {
    lookupFuncaoDinamica,
    messages: state.crudBotaoDinamicoState.messages,
    record: state.crudBotaoDinamicoState.currentRecord,
    recordPerfis: state.crudPerfilState.records.filter((perfil) => {
      if (
        state.crudBotaoDinamicoState.currentRecord.perfis &&
        state.crudBotaoDinamicoState.currentRecord.perfis.length > 0
      ) {
        let listIdPerfis = state.crudBotaoDinamicoState.currentRecord.perfis.map(
          (perfilBotaoDinamico) => perfilBotaoDinamico.oid
        )
        return !listIdPerfis.includes(perfil.oid)
      }
      return []
    }),
    opcoesTipoTelas: utils.getOpcoesTipoTelas(),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //pickListPerfis
    pickListPerfisLoad: () => dispatch(perfilActions.load(true)),

    // Lookup de função dinâmica
    lookupFuncaoDinamicaSetVisible: (visible) => dispatch(actions.setLookupFuncaoDinamicaVisible(visible)),
    lookupFuncaoDinamicaPaginate: (paginationParams) => dispatch(funcaoDinamicaActions.paginate(paginationParams)),
    lookupFuncaoDinamicaSortOrder: (sortParams) => dispatch(funcaoDinamicaActions.sortOrder(sortParams)),
    lookupFuncaoDinamicaApplyFilter: (filterParams) => {
      dispatch(funcaoDinamicaActions.setType(null))
      dispatch(funcaoDinamicaActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(funcaoDinamicaActions.applyFilter(filterParams))
    },
    lookupFuncaoDinamicaLoad: () => dispatch(funcaoDinamicaActions.loadLookup()),
    setFuncaoDinamica: (funcao) => {
      dispatch(actions.setField('funcaoDinamica', funcao))
    },

    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value

      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
  }
}

class BotaoDinamicoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_BOTAO_DINAMICO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_BOTAO_DINAMICO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_BOTAO_DINAMICO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }

    this.props.pickListPerfisLoad()
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_BOTAO_DINAMICO}`)
  }
  onChangePerfis = (e) => {
    this.props.setField('perfis', e.target)
  }

  // REFERENTE AO LOOKUP PARA FUNÇÃO DINÂMICA
  lookupFuncaoDinamicaOnPage = (e) => {
    this.props.lookupFuncaoDinamicaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupFuncaoDinamicaOnSort = (e) => {
    this.props.lookupFuncaoDinamicaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupFuncaoDinamicaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupFuncaoDinamicaApplyFilter(filter)
    })
    this.props.lookupFuncaoDinamicaLoad()
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupFuncaoDinamicaLoad()
    }
  }

  lookupFuncaoDinamicaOnClick = (e) => {
    this.props.lookupFuncaoDinamicaLoad()
    this.props.lookupFuncaoDinamicaSetVisible(true)
  }
  lookupFuncaoDinamicaOnHide = (e) => {
    this.props.lookupFuncaoDinamicaSetVisible(false)
  }
  lookupFuncaoDinamicaOnConfirm = (e) => {
    this.props.setFuncaoDinamica({ value: e.selection.nomeFuncao, id: e.selection.oid })
    this.props.lookupFuncaoDinamicaSetVisible(false)
  }
  lookupFuncaoDinamicaOnCancel = (e) => {
    this.props.lookupFuncaoDinamicaSetVisible(false)
  }
  lookupFuncaoDinamicaOnEdit = () => {
    if (this.props.record.funcaoDinamica && this.props.record.funcaoDinamica.id) {
      return `${routes.PATH_FUNCAO_DINAMICA}/${this.props.record.funcaoDinamica.id}`
    } else {
      return `${routes.PATH_FUNCAO_DINAMICA}`
    }
  }
  lookupFuncaoDinamicaOnComplete = (e) => {}

  lookupFuncaoDinamicaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filters = {
        campo: 'descricao',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      let grupo = { descricao: e.value, id: null }
      this.props.setFuncaoDinamica(grupo)
      this.props.lookupFuncaoDinamicaApplyFilter(filters)
    } else {
      this.props.setFuncaoDinamica({ descricao: value.descricao, id: value.oid })
      const filters = {
        campo: 'descricao',
        valor: '',
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupFuncaoDinamicaApplyFilter(filters)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <BotaoDinamicoForm
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        recordPerfis={this.props.recordPerfis}
        onChangePerfis={this.onChangePerfis}
        opcoesTipoTelas={this.props.opcoesTipoTelas}
        history={this.props.history}
        lookupFuncaoDinamica={this.props.lookupFuncaoDinamica}
        lookupFuncaoDinamicaOnHide={this.lookupFuncaoDinamicaOnHide}
        lookupFuncaoDinamicaOnConfirm={this.lookupFuncaoDinamicaOnConfirm}
        lookupFuncaoDinamicaOnCancel={this.lookupFuncaoDinamicaOnCancel}
        lookupFuncaoDinamicaOnPage={this.lookupFuncaoDinamicaOnPage}
        lookupFuncaoDinamicaOnSort={this.lookupFuncaoDinamicaOnSort}
        lookupFuncaoDinamicaOnFilter={this.lookupFuncaoDinamicaOnFilter}
        lookupFuncaoDinamicaOnClick={this.lookupFuncaoDinamicaOnClick}
        lookupFuncaoDinamicaOnEdit={this.lookupFuncaoDinamicaOnEdit}
        lookupFuncaoDinamicaOnComplete={this.lookupFuncaoDinamicaOnComplete}
        lookupFuncaoDinamicaOnInputFilter={this.lookupFuncaoDinamicaOnInputFilter}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BotaoDinamicoFormPageContainer)
