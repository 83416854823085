import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
//import { loaderReducer, loaderMiddleware } from "./loader";
import { menuReducer } from './menu'
import { messageReducer } from './message'
import { loginReducer } from '../pages/login/login.reducer'
import { redefinicaoSenhaReducer } from '../pages/portalClienteRedefinicaoSenha/redefinicaosenha.reducer'
import { esqueciSenhaReducer } from '../pages/portalClienteEsqueciSenha/esquecisenha.reducer'
import { CRUDDashboardPortalClienteReducer } from '../pages/portalClienteDashboard'
import { CRUDAcaoReducer } from '../pages/acao'
import { CRUDDashboardReducer } from '../pages/dashboard'
import { CRUDPessoaReducer } from '../pages/pessoa'
import { CRUDPessoaFisicaReducer } from '../pages/pessoaFisica'
import { CRUDPessoaJuridicaReducer } from '../pages/pessoaJuridica'
import { CRUDEmpresaReducer } from '../pages/empresa'
import { CRUDUsuarioReducer } from '../pages/usuario'
import { CRUDReligiaoReducer } from '../pages/religiao'
import { CRUDTipoSepulturaReducer } from '../pages/tipoSepultura'
import { CRUDCategoriaReducer } from '../pages/categoria'
import { CRUDSexoReducer } from '../pages/sexo'
import { CRUDCepReducer } from '../pages/cep'
import { CRUDConvenioReducer } from '../pages/convenio'
import { CRUDContratoReducer } from '../pages/contrato'
import { CRUDGrupoReducer } from '../pages/grupo'
import { CRUDTaxaReducer } from '../pages/taxa'
import { CRUDLancamentoReducer } from '../pages/lancamentoFinanceiro'
import { CRUDItensLancamentoReducer } from '../pages/itensLancamentoFinanceiro'
import { CRUDFuncaoDinamicaReducer } from '../pages/funcaoDinamica'
import { CRUDParametroReducer } from '../pages/parametrosUnidade'
import { CRUDParametroEmpresaReducer } from '../pages/parametrosEmpresa'
import { CRUDLocalizacaoReducer } from '../pages/localizacao'
import { CRUDLocalizacaoGridReducer } from '../pages/localizacaoGrid'
import { CRUDBotaoDinamicoReducer } from '../pages/botaoDinamico'
import { CRUDConfiguracaoBoletoReducer } from '../pages/configuracaoBoleto'
import { CRUDLoteBoletosReducer } from '../pages/loteBoletos'
import { CRUDControleFinanceiroReducer } from '../pages/controleFinanceiro'
import { CRUDNfseReducer } from '../pages/nfse'
import { CRUDConsultaBaixaReducer } from '../pages/consultaBaixa'
import { CRUDProdutoReducer } from '../pages/produto'
import { CRUDEmprestimoReducer } from '../pages/emprestimo'
import { CRUDEnderecoReducer } from '../pages/endereco'

import { crudPerfilReducer } from '../pages/perfil'
import { CRUDContatoReducer } from '../pages/teste'
import { CRUDPermissaoReducer } from '../pages/permissao'
import { CRUDUnidadeReducer } from '../pages/unidade'
import { appReducer } from './app'
import { CRUDMenuReducer } from '../pages/menu'
import { CRUDRelatorioDinamicoReducer } from '../pages/relatorioDinamico'
import { CRUDLivroCaixaReducer } from '../pages/livroCaixa'
import { CRUDMensageriaReducer } from '../pages/mensageria'
import { CRUDAquisicoesRelatorioReducer } from '../pages/aquisicoesRelatorio'
import { CRUDCartaoCreditoRelatorioReducer } from '../pages/cartaoCreditoRelatorio'
import { CRUDReceitasDespesasRelatorioReducer } from '../pages/receitasDespesasRelatorio'
import { CRUDMensageriaLogReducer } from '../pages/mensageriaLog'
import { CRUDFalecidosRelatorioReducer } from '../pages/falecidosRelatorio'
import { CRUDExumacoesRelatorioReducer } from '../pages/exumacoesRelatorio'
import { CRUDIsentosRelatorioReducer } from '../pages/isentosRelatorio'
import { CRUDSepulturasDevedorasRelatorioReducer } from '../pages/sepulturasDevedorasRelatorio'
import { CRUDContasReceberRelatorioReducer } from '../pages/contasReceberRelatorio'
import { CRUDConsultaBaixaRelatorioReducer } from '../pages/consultaBaixaRelatorio'
import { CRUDTiposSepulturasRelatorioReducer } from '../pages/tiposSepulturasRelatorio'
import { CRUDAgendamentoMensageriaReducer } from '../pages/mensagens'
//import { loadingBarMiddleware } from 'react-redux-loading-bar'
//import { loadingBarReducer } from 'react-redux-loading-bar'
//Reducer para as telas de 'Relatorio Dinamico'
import { CRUDDinamicaReducer } from '../pages/dinamica'

import { CRUDPortalClienteFinanceiroReducer } from '../pages/portalClienteFinanceiro'
import { CRUDConsultaFalecidoReducer } from '../pages/consultaFalecido'
import { CRUDPortalClienteCarteirinhaReducer } from '../pages/portalClienteCarteirinhas'
import { CRUDPortalClienteConsultaClienteReducer } from '../pages/portalClienteConsultaCliente/portalClienteConsultaCliente.reducer'
import { CRUDConsultaRemessaReducer } from '../pages/consultaRemessa/consultaRemessa.reducer'
import { CRUDMovimentoCaixaReducer } from '../pages/movimentoCaixa'
import { CRUDDREReducer } from '../pages/dre'
import { CRUDConfigAilosReducer } from '../pages/configAilos'
import { CRUDAgendaReducer } from '../pages/agenda'
import { CRUDDocumentoReducer } from '../pages/documento'
import { CRUDOrcamentoReducer } from '../pages/orcamento'
import { CRUDNotaEntradaReducer } from '../pages/notaEntrada'
import { CRUDMarcadorReducer } from '../pages/marcador/marcador.reducer'
import { CRUDPortalClienteContratoReducer } from '../pages/portalClienteContratos'
import { CRUDConsultaObituarioReducer } from '../pages/consultaObituario'
import { CRUDContaCorrenteReducer } from '../pages/contaCorrente'
import { CRUDMapaReducer } from '../pages/mapa'
import { CRUDMapaCoordenadaReducer } from '../pages/mapaCoordenada'
import { CRUDTelaDinamicaReducer } from '../pages/telaDinamica'

const reducers = combineReducers({
  appState: appReducer,
  //loaderState: loaderReducer,
  // loadingBar: loadingBarReducer,
  loginState: loginReducer,
  redefinicaoSenhaState: redefinicaoSenhaReducer,
  crudDashboardPortalClienteState: CRUDDashboardPortalClienteReducer,
  esqueciSenhaState: esqueciSenhaReducer,
  menuState: menuReducer,
  messageState: messageReducer,
  crudPessoaState: CRUDPessoaReducer,
  crudPessoaFisicaState: CRUDPessoaFisicaReducer,
  crudPessoaJuridicaState: CRUDPessoaJuridicaReducer,
  crudContatoState: CRUDContatoReducer,
  crudAcaoState: CRUDAcaoReducer,
  crudReligiaoState: CRUDReligiaoReducer,
  crudTipoSepulturaState: CRUDTipoSepulturaReducer,
  crudCategoriaState: CRUDCategoriaReducer,
  crudMarcadorState: CRUDMarcadorReducer,
  crudCepState: CRUDCepReducer,
  crudSexoState: CRUDSexoReducer,
  crudEmpresaState: CRUDEmpresaReducer,
  crudUsuarioState: CRUDUsuarioReducer,
  crudPerfilState: crudPerfilReducer,
  contatoState: CRUDContatoReducer,
  crudPermissaoState: CRUDPermissaoReducer,
  crudUnidadeState: CRUDUnidadeReducer,
  crudMenuState: CRUDMenuReducer,
  crudConvenioState: CRUDConvenioReducer,
  crudDashboardState: CRUDDashboardReducer,
  crudContratoState: CRUDContratoReducer,
  crudLocalizacaoState: CRUDLocalizacaoReducer,
  crudLocalizacaoGridState: CRUDLocalizacaoGridReducer,
  crudItensLancamentoState: CRUDItensLancamentoReducer,
  crudBotaoDinamicoState: CRUDBotaoDinamicoReducer,
  crudConfiguracaoBoletoState: CRUDConfiguracaoBoletoReducer,
  crudLoteBoletosState: CRUDLoteBoletosReducer,
  crudControleFinanceiroState: CRUDControleFinanceiroReducer,
  crudProdutoState: CRUDProdutoReducer,
  crudEmprestimoState: CRUDEmprestimoReducer,
  crudEnderecoState: CRUDEnderecoReducer,

  crudGrupoState: CRUDGrupoReducer,
  crudTaxaState: CRUDTaxaReducer,
  crudLancamentoState: CRUDLancamentoReducer,

  crudFuncaoDinamicaState: CRUDFuncaoDinamicaReducer,
  crudParametroState: CRUDParametroReducer,
  crudParametroEmpresaState: CRUDParametroEmpresaReducer,
  crudRelatorioDinamicoState: CRUDRelatorioDinamicoReducer,

  crudDinamicaState: CRUDDinamicaReducer,
  crudNfseState: CRUDNfseReducer,
  crudLivroCaixaState: CRUDLivroCaixaReducer,
  crudAquisicoesRelatorioState: CRUDAquisicoesRelatorioReducer,
  crudCartaoCreditoRelatorioState: CRUDCartaoCreditoRelatorioReducer,
  crudReceitasDespesasRelatorioState: CRUDReceitasDespesasRelatorioReducer,
  crudFalecidosRelatorioState: CRUDFalecidosRelatorioReducer,
  crudExumacoesRelatorioState: CRUDExumacoesRelatorioReducer,
  crudIsentosRelatorioState: CRUDIsentosRelatorioReducer,
  crudSepulturasDevedorasRelatorioState: CRUDSepulturasDevedorasRelatorioReducer,
  crudContasReceberRelatorioState: CRUDContasReceberRelatorioReducer,
  crudConsultaBaixaRelatorioState: CRUDConsultaBaixaRelatorioReducer,
  crudTiposSepulturasRelatorioState: CRUDTiposSepulturasRelatorioReducer,
  crudMensageriaState: CRUDMensageriaReducer,
  crudMensageriaLogState: CRUDMensageriaLogReducer,
  crudConsultaBaixaState: CRUDConsultaBaixaReducer,
  crudPortalClienteFinanceiroState: CRUDPortalClienteFinanceiroReducer,
  crudPortalClienteContratoState: CRUDPortalClienteContratoReducer,
  crudConsultaFalecidoState: CRUDConsultaFalecidoReducer,
  crudConsultaObituarioState: CRUDConsultaObituarioReducer,
  crudPortalClienteCarteirinhaState: CRUDPortalClienteCarteirinhaReducer,
  crudPortalClienteConsultaClienteState: CRUDPortalClienteConsultaClienteReducer,
  crudConsultaRemessaState: CRUDConsultaRemessaReducer,
  crudMovimentoCaixaState: CRUDMovimentoCaixaReducer,
  crudDREState: CRUDDREReducer,
  crudConfigAilosState: CRUDConfigAilosReducer,
  crudAgendaState: CRUDAgendaReducer,
  crudDocumentoState: CRUDDocumentoReducer,
  crudOrcamentoState: CRUDOrcamentoReducer,
  crudNotaEntradaState: CRUDNotaEntradaReducer,
  crudContaCorrenteState: CRUDContaCorrenteReducer,
  crudMapaState: CRUDMapaReducer,
  crudMapaCoordenadaState: CRUDMapaCoordenadaReducer,
  crudAgendamentoMensageriaState: CRUDAgendamentoMensageriaReducer,
  crudTelaDinamicaState: CRUDTelaDinamicaReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, composeEnhancers(applyMiddleware(ReduxThunk)))
export default store
