import { blankToNull } from '../../../utils'

export function validate(record) {
  const errors = []
  if (!record.descricao && !record.numero && !record.banco && !record.agencia) {
    errors.push({
      field: 'descricao',
      message: 'Um dos campos deve ser informado: descrição, número, agência, banco',
    })
  }

  blankToNull(record)
  const result = {
    ok: errors.length === 0,
    errors,
    record,
  }
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}
