import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import _ from 'lodash'
import utils from '../../utils/index'

import { messageActions } from '../../store/message'

const BASE_PATH = '/lottustech/agendamentoMensageria'
const crud = build(validate, BASE_PATH)
const prefix = 'crudAgendamentoMensageria'

const newTypes = {
  SET_FONTE_DE_DADOS_PREVIEW: `${prefix}setFonteDeDadosPreview`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setFonteDeDadosPreview = (payload) => {
  return {
    type: types.SET_FONTE_DE_DADOS_PREVIEW,
    payload,
  }
}

actions.enviarMensagem = (params) => {
  return (dispatch, getState) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const path = BASE_PATH + `/enviar`
    return apiService
      .post(path, params)
      .then((response) => {
        dispatch(
          messageActions.messageShowMessages([
            { sticky: true, summary: '', severity: 'warn', detail: 'Solicitação de envio feita com sucesso!' },
          ])
        )
      })
      .catch((err) => {
        const message = _.get(err, 'response.data', null)
        if (!_.isEmpty(message)) {
          dispatch(
            messageActions.messageShowMessages([{ sticky: true, summary: '', severity: 'error', detail: message }])
          )
        }
      })
  }
}

// actions.previewExcel = (data) => {
//   return (dispatch, getState) => {
//     const files = _.get(data, 'target.files', [])
//     if (files.length > 0) {
//       const file = files[0]
//       const form = new FormData()
//       form.append('arquivo', file)
//
//       apiService
//         .postFormData(`${BASE_PATH}/preview/excel`, form, { nomeArquivo: file.name })
//         .then((response) => {
//           const list = _.get(response, 'data', null)
//           const toList = utils.parseMapToList(list)
//           dispatch(actions.setFonteDeDadosPreview(toList))
//         })
//         .catch((error) => {
//           const message = _.get(error, 'response.data', '')
//           dispatch(
//             messageActions.messageShowMessages([{ sticky: true, summary: '', severity: 'error', detail: message }])
//           )
//         })
//     }
//   }
// }

actions.previewExcel = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        messageActions.messageShowMessages([
          { sticky: true, summary: '', severity: 'warn', detail: 'Solicitação de envio feita com sucesso!' },
        ])
        const params = {
          nomeArquivo: file.name,
          base64Arquivo: encoded,
        }
        apiService
          .post(`${BASE_PATH}/preview/excel/base64`, params)
          .then((response) => {
            const list = _.get(response, 'data', null)
            const toList = utils.parseMapToList(list)
            dispatch(actions.setFonteDeDadosPreview(toList))
          })
          .catch((error) => {
            const message = _.get(error, 'response.data', '')
            dispatch(
              messageActions.messageShowMessages([{ sticky: true, summary: '', severity: 'error', detail: message }])
            )
          })
      }
    }
  }
}

actions.previewQuery = (data) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}/preview/query`, data)
      .then((response) => {
        const list = _.get(response, 'data', null)
        const toList = utils.parseMapToList(list)
        dispatch(actions.setFonteDeDadosPreview(toList))
      })
      .catch((error) => {
        const message = _.get(error, 'response.data', '')
        dispatch(
          messageActions.messageShowMessages([{ sticky: true, summary: '', severity: 'error', detail: message }])
        )
      })
  }
}

export { types, actions }
