import { types } from "./login.actions";
import storage from "../../services/stoage.service";

const initialState = {
  credential: {
    login: "",
    password: ""
  },
  conta: {
    tipoPessoa: "FISICA",
    documento: "",
    email: "",
    senha: "",
    confirmaSenha: ""
  },
  token: storage.getToken(),
  login: "",
  message: "",
  messageSeverity: ""
};

export function loginReducer(state = initialState, action) {
  if (action.type === types.LOGIN_SET_CREDENTIAL) {
    const credential = Object.assign({}, state.credential, action.credential);
    return Object.assign({}, state, { credential });
  }
  if (action.type === types.LOGIN_SET_CONTA) {
    const conta = Object.assign({}, state.conta, action.conta);
    return Object.assign({}, state, { conta });
  }
  if (action.type === types.LOGIN_CLEAR_CREDENTIAL) {
    const message = "";
    return Object.assign({}, state, { message });
  }

  if (action.type === types.LOGIN_SET_ERROR_MESSAGE) {
    const message = action.errorMessage;
    return Object.assign({}, state, { message: message });
  }
  if (action.type === types.LOGIN_CLEAR_ERROR_MESSAGE) {
    const message = "";
    const messageSeverity = "";
    return Object.assign({}, state, { message, messageSeverity });
  }

  if (action.type === types.LOGIN_SET_TOKEN) {
    const { token } = action;
    return Object.assign({}, state, { token });
  }

  if (action.type === types.LOGIN_CLEAR_TOKEN) {
    const token = "";
    return Object.assign({}, state, { token });
  }

  if (action.type === types.LOGIN_SET_LOGIN) {
    const { login } = action;
    return Object.assign({}, state, { login });
  }

  if (action.type === types.LOGIN_CLEAR_LOGIN) {
    const login = "";
    return Object.assign({}, state, { login });
  }

  if (action.type === types.LOGIN_SET_OID_COMPANY) {
    const { oidCompany } = action;
    return Object.assign({}, state, { oidCompany });
  }

  if (action.type === types.LOGIN_CLEAR_OID_COMPANY) {
    const oidCompany = "";
    return Object.assign({}, state, { oidCompany });
  }

  if (action.type === types.LOGIN_CLEAR_ALL) {
    // const data = {
    //   credential: {
    //     login: "",
    //     password: ""
    //   },
    //   token: "",
    //   login: "",
    //   message: "",
    //   oidCompany: ""
    // };
    return Object.assign({}, state, initialState);
  }
  return state;
}
