import { types } from './pessoaFisica.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'pessoaFisica',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'CPF',
      value: {
        field: 'numeroDocumento',
        header: 'CPF',
        columnKey: 'numeroDocumento',
        sortable: true,
        columnSortField: 'numeroDocumento',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Nome',
      value: {
        field: 'nome',
        header: 'Nome',
        columnKey: 'nome',
        sortable: true,
        columnSortField: 'nome',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'E-mail',
      value: {
        field: 'email',
        header: 'E-mail',
        columnKey: 'email',
        sortable: true,
        columnSortField: 'email',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Data Nascimento',
      value: {
        field: 'dataNascimento',
        header: 'Data nascimento',
        columnKey: 'dataNascimento',
        sortable: true,
        columnSortField: 'dataNascimento',
        filter: true,
        visible: false,
        fieldFormatter: 'formatarData',
      },
    },
    {
      label: 'RG',
      value: {
        field: 'rg',
        header: 'RG',
        columnKey: 'rg',
        sortable: true,
        columnSortField: 'rg',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Bairro',
      value: {
        field: 'bairro',
        header: 'Bairro',
        columnKey: 'bairro',
        sortable: true,
        columnSortField: 'bairro',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Cidade',
      value: {
        field: 'cidade',
        header: 'Cidade',
        columnKey: 'cidade',
        sortable: true,
        columnSortField: 'cidade',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Rua',
      value: {
        field: 'nomeLogradouro',
        header: 'Rua',
        columnKey: 'nomeLogradouro',
        sortable: true,
        columnSortField: 'nomeLogradouro',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'UF',
      value: {
        field: 'uf',
        header: 'UF',
        columnKey: 'uf',
        sortable: true,
        columnSortField: 'uf',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Telefone',
      value: {
        field: 'telefone',
        header: 'Telefone',
        columnKey: 'telefone',
        sortable: true,
        columnSortField: 'telefone',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Segundo Telefone',
      value: {
        field: 'segundoTelefone',
        header: 'Segundo Telefone',
        columnKey: 'segundoTelefone',
        sortable: true,
        columnSortField: 'segundoTelefone',
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Religião',
      value: {
        field: 'religiao',
        header: 'Religião',
        columnKey: 'religiao',
        sortable: true,
        columnSortField: 'religiao.nome',
        filter: true,
        relacionamento: ['religiao'],
        fieldJoin: 'nome',
        visible: false,
      },
    },
  ],
  columns: [],
  columnsLookup: [
    {
      field: 'oid',
      header: 'ID',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: false,
    },
    {
      field: 'numeroDocumento',
      header: 'CPF',
      columnKey: 'numeroDocumento',
      sortable: true,
      columnSortField: 'numeroDocumento',
      filter: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
    },
    {
      field: 'dataNascimento',
      header: 'Data Nascimento',
      columnKey: 'dataNascimento',
      sortable: true,
      columnSortField: 'dataNascimento',
      filter: true,
      visible: false,
      fieldFormatter: 'formatarData',
    },
    {
      field: 'dataFalecimento',
      header: 'Data Falecimento',
      columnKey: 'dataFalecimento',
      sortable: true,
      columnSortField: 'dataFalecimento',
      filter: true,
      visible: false,
      fieldFormatter: 'formatarData',
    },
  ],
  currentRecord: {
    oid: '',
    nome: '',
    rg: '',
    descricaoSexo: '',
    oidSexo: '',
    dataNascimento: '',
    dataFalecimento: '',
    nomeFantasia: '',
    numeroDocumento: '',
    numeroDocumentoRaiz: '',
    inscricaoEstadual: '',
    complemento: '',
    email: '',
    site: '',
    telefone: '',
    segundoTelefone: '',
    nomeLogradouro: '',
    numeroLogradouro: '',
    bairro: '',
    cidade: '',
    contato: '',
    cep: '',
    nomeMae: '',
    nomePai: '',
    ativo: true,
    foto: '',
    plainSenha: '',
    estadoCivil: '',
    nacionalidade: '',
    profissao: '',
  },
  lookupReligiao: {
    visible: false,
    modal: true,
    header: 'Religião',
  },
  sexos: {},
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDPessoaFisicaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('pessoafisica')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_RELIGIAO_VISIBLE:
      return setLookupReligiaoVisible(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setLookupReligiaoVisible(state, action) {
  let lookupReligiao = state.lookupReligiao
  lookupReligiao = Object.assign({}, lookupReligiao, { visible: action.visible })
  return Object.assign({}, state, { lookupReligiao })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], sexos: initialState.sexos }
}
