import apiService from '../../services/api.service'
import storage from '../../services/stoage.service'
import { actions as localizacaoActions } from '../../pages/localizacao/localizacao.actions'
import { parseTipoFiltro } from '../../utils/index'

export const types = {
  APP_SET_CURRENT_USER: 'appSetCurrentUser',
  APP_SET_CURRENT_COMPANY: 'appSetCurrentCompany',
  APP_SET_CURRENT_UNIT: 'appSetCurrentUnit',
  APP_SET_COMPANIES: 'appSetCurrentCompanies',
  APP_SET_UNITS: 'appSetUnits',
  APP_SET_USUARIO_UNIDADE_UNICO: 'setUsuarioUnidadeUnico',
  APP_FETCH_COMPANIES: 'appFetchCompanies',
  APP_FETCH_COMPANIES_SUCCESS: 'appFetchCompaniesSuccess',
  APP_FETCH_COMPANIES_ERROR: 'appFetchCompaniesError',
  APP_SET_DEFAULT_ROUTES: 'appSetDefaultRoutes',
  APP_SET_CUSTOM_ROUTES: 'appSetCustomRoutes',
  APP_SET_CONFIG_CONTA_AILOS: 'configurarContaAilos',
  APP_LIMPAR_DADOS: 'limparDados',
  APP_SET_IS_SAVING: 'appSetIsSaving',

  //LOOKUP PESSOAS
  APP_LOOKUP_PESSOA_ON_PAGINATE_RESULT: 'appLookupPessoaOnPaginateResult',
  APP_LOOKUP_PESSOA_SET_SORT_ORDER: 'appLookupPessoaSetSortOrder',
  APP_LOOKUP_PESSOA_SET_SORT_FIELD: 'appLookupPessoaSetSortField',
  APP_LOOKUP_PESSOA_SET_SELECTED_FILTERS: 'appLookupPessoaSetSelectedFilters',
  APP_LOOKUP_PESSOA_SET_FILTERS: 'appLookupPessoaSetFilters',
  APP_LOOKUP_PESSOA_SET_INDEX: 'appLookupPessoaSetIndex',
}

let timer = null

function fetchCompanies() {
  return {
    type: types.APP_FETCH_COMPANIES,
    async: true,
  }
}
function fetchCompaniesSuccess() {
  return {
    type: types.APP_FETCH_COMPANIES_SUCCESS,
    async: false,
  }
}
function fetchCompaniesError() {
  return {
    type: types.APP_FETCH_COMPANIES_ERROR,
    async: false,
  }
}

export function setIsSaving(payload) {
  return {
    type: types.APP_SET_IS_SAVING,
    payload,
  }
}

export function setCurrentUser(user) {
  return {
    type: types.APP_SET_CURRENT_USER,
    user,
  }
}

export function setCurrentCompany(company) {
  return {
    type: types.APP_SET_CURRENT_COMPANY,
    company,
  }
}
export function setCurrentUnit(unit) {
  return {
    type: types.APP_SET_CURRENT_UNIT,
    unit,
  }
}
export function setCompanies(companies) {
  return {
    type: types.APP_SET_COMPANIES,
    companies,
  }
}
export function setUnits(units) {
  return {
    type: types.APP_SET_UNITS,
    units,
  }
}

export function setUsuarioUnidadeUnico(indicador) {
  return {
    type: types.APP_SET_USUARIO_UNIDADE_UNICO,
    indicador,
  }
}

export function setDefaultRoutes() {
  return {
    type: types.APP_SET_DEFAULT_ROUTES,
  }
}

export function setCustomRoutes(menus) {
  return {
    type: types.APP_SET_CUSTOM_ROUTES,
    menus,
  }
}

export function limparDados() {
  return {
    type: types.APP_LIMPAR_DADOS,
  }
}

export function loadCompanies() {
  return (dispatch) => {
    dispatch(fetchCompanies())
    const params = {
      filters: [],
      sortFields: [],
      index: 0,
      max: 0,
    }
    apiService
      .post('/lottustech/empresas/all', params)
      .then(({ data }) => {
        const records = data.records
        dispatch(setCompanies(records))
        dispatch(fetchCompaniesSuccess())
      })
      .catch((err) => {
        dispatch(fetchCompaniesError())
      })
  }
}

export function loadLabels() {
  return (dispatch, getState) => {
    apiService
      .post('/lottustech/getlabels')
      .then(({ data }) => {
        storage.setAppLabels(data)
        dispatch(localizacaoActions.resetColumnsIdLocalizacao())
      })
      .catch((err) => {
        dispatch(fetchCompaniesError())
      })
  }
}

export function appLookupPessoaOnPaginateResult(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_ON_PAGINATE_RESULT,
    payload,
  }
}

export function appLookupPessoaSetSortOrder(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_SET_SORT_ORDER,
    payload,
  }
}

export function appLookupPessoaSetSortField(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_SET_SORT_FIELD,
    payload,
  }
}

export function appLookupPessoaSetSelectedFilters(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_SET_SELECTED_FILTERS,
    payload,
  }
}

export function appLookupPessoaSetFilters(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_SET_FILTERS,
    payload,
  }
}

export function appLookupPessoaSetIndex(payload) {
  return {
    type: types.APP_LOOKUP_PESSOA_SET_INDEX,
    payload,
  }
}

export function lookupPessoaPaginate(paginationParams) {
  return (dispatch) => {
    apiService
      .post('/lottustech/pessoas/all', paginationParams)
      .then(({ data }) => {
        dispatch(appLookupPessoaOnPaginateResult(data))
      })
      .catch((err) => {
        //FIXME tratar erros...
        dispatch(fetchCompaniesError())
      })
  }
}

export function configurarContaAilos() {
  return (dispatch) => {
    apiService
      .get('/lottustech/ailos/obterLinkLoginAilos')
      .then(({ data }) => {
        const win = window.open(data)
        win.focus()
      })
      .catch((err) => {})
  }
}

function lookupPessoaExecutePaginate() {
  return (dispatch, getState) => {
    const state = getState()['appState']
    let sortFields = []
    if (state.lookupPessoa.sortField) {
      sortFields = [
        {
          fieldName: state.lookupPessoa.sortField,
          order: state.lookupPessoa.sortOrder > -1 ? `ASC` : `DESC`,
        },
      ]
    }
    const params = {
      index: state.lookupPessoa.index,
      max: state.lookupPessoa.max,
      filters: parseTipoFiltro(state.lookupPessoa.selectedFilters),
      sortFields,
    }
    dispatch(lookupPessoaPaginate(params))
  }
}

export function lookupPessoaSortOrder(sortParams) {
  return (dispatch, getState) => {
    dispatch(appLookupPessoaSetSortOrder(sortParams.sortOrder))
    dispatch(appLookupPessoaSetSortField(sortParams.sortField))
  }
}

export function lookupPessoaSetSelectedFilters(value) {
  return (dispatch, getState) => {
    dispatch(appLookupPessoaSetSelectedFilters(value))
  }
}

export function lookupPessoaApplyFilter(filters) {
  return (dispatch, getState) => {
    dispatch(appLookupPessoaSetFilters(filters))
    if (timer) {
      window.clearTimeout(timer)
    }
    timer = setTimeout(() => {
      dispatch(appLookupPessoaSetIndex(0))
      dispatch(lookupPessoaExecutePaginate(dispatch, getState))
    }, 300)
  }
}

export function lookupPessoaLoad() {
  return (dispatch, getState) => {
    dispatch(lookupPessoaExecutePaginate(dispatch, getState))
  }
}

export function setUnidadeCorrente(unidade) {
  return (dispatch, getState) => {
    const oid = unidade ? unidade.oid : null
    apiService.post('/lottustech/configuracaoUsuario/setUnidadeCorrente', oid).then(({ data }) => {})
  }
}

export function setEmpresaUnidadeCorrente(empresa, unidade) {
  return (dispatch, getState) => {
    const params = {
      oidEmpresa: empresa ? empresa.oid : null,
      oidUnidade: unidade ? unidade.oid : null,
    }
    apiService.post('/lottustech/configuracaoUsuario/setEmpresaUnidadeCorrente', params).then(({ data }) => {})
  }
}

export function setMenuLayout(value) {
  return (dispatch, getState) => {
    apiService.post('/lottustech/configuracaoUsuario/setMenuLayout', value).then(({ data }) => {})
  }
}
