import React from 'react';

import LivroCaixaFormPageContainer from './LivroCaixaFormPageContainer';

export class LivroCaixaFormPage extends React.Component{

    
    render(){
        return (
           <LivroCaixaFormPageContainer {...this.props} />
        )
    }
}
