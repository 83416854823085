import React from 'react';
import PessoaFisicaListPageContainer from './PessoaFisicaListPageContainer';

export class PessoaFisicaListPage extends React.Component{
    
    render(){
      return (
        <PessoaFisicaListPageContainer {...this.props}/>
      )
    }
}

