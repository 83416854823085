import React from "react";
import { connect } from "react-redux";
import RedefinirSenhaForm from "./redefinicao-senha-form.component";
import * as redefinirSenhaActions from "./redefinicaosenha.actions";
import { Toast } from "primereact/toast";
import "./RedefinicaoSenhaPageContainer.scss";
import background from "../../assets/layout/images/login/login-bg.jpg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import storage from "../../services/stoage.service";

const mapStateToProps = state => {
  return {
    message: state.redefinicaoSenhaState.errorMessage,
    showMessage: state.redefinicaoSenhaState.showMessage,
    showMessageSuccess: state.redefinicaoSenhaState.showMessageSuccess
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setErrorMessage: valor => redefinirSenhaActions.loginSetErrorMessage(valor),
    redefinirSenha: dados => dispatch(redefinirSenhaActions.redefinirSenha(dados))
  };
};

class RedefinicaoSenhaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingSpinner: false,
      showMessage: false
    };
    this.showError = this.showUserMessage.bind(this);
  }

  onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.redefinirSenha();
    }
  };

  showUserMessage() {
    if (this.state.showLoadingSpinner && this.props.showMessage) {
      this.setState({ showLoadingSpinner: false });
      this.setState({ showMessage: false });
      this.setState({ showMessageSuccess: false });
      this.toast.show({ severity: "error", summary: "Erro!", detail: this.props.message, sticky: true });
    } else if (this.state.showLoadingSpinner && this.props.showMessageSuccess) {
      this.setState({ showLoadingSpinner: false });
      this.setState({ showMessage: false });
      this.setState({ showMessageSuccess: false });
      this.toast.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Sua senha foi redefinida. Você será redirecionado",
        sticky: true
      });
    }
  }

  componentDidMount() {
    storage.clearStorage();
    if (this.props.match.params.hash) {
      this.setState({ hash: this.props.match.params.hash });
    }
  }
  inputHandler = event => {
    const value = event.target.value;
    const field = event.target.name;
    this.setState({ [field]: value });
  };

  redefinirSenha = () => {
    const dados = {
      hash: this.state.hash,
      senha: this.state.password
    };
    if (!this.state.password) {
      this.toast.show({ severity: "error", summary: "Erro!", detail: "Informe uma senha!", sticky: true });
    } else if (
        this.state.password &&
        this.state.passwordConfirm &&
        this.state.password !== this.state.passwordConfirm
    ) {
      this.toast.show({ severity: "error", summary: "Erro!", detail: "As senhas diferem!", sticky: true });
    } else {
      this.setState({ showLoadingSpinner: true });
      this.props.redefinirSenha(dados);
    }
  };

  render() {
    if ((this.props.showMessage || this.props.showMessageSuccess) && this.state.showLoadingSpinner) {
      this.showUserMessage();
    }
    return (
        <div className="login-body">
          <RedefinirSenhaForm
              message={this.props.message}
              changeValue={this.inputHandler}
              login={this.login}
              onKeyDown={this.onKeyDown}
              redefinirSenha={this.redefinirSenha}
              state={this.state}
          />
          <Toast ref={el => (this.toast = el)} />
          {this.state.showLoadingSpinner ? <LoadingSpinner /> : null}
          <img src={background} alt="background" />
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedefinicaoSenhaContainer);
