import React from 'react';

import TaxaFormPageContainer from './TaxaFormPageContainer';

export class TaxaFormPage extends React.Component{

    
    render(){
        return (
           <TaxaFormPageContainer {...this.props} />
        )
    }
}
