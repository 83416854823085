import React from 'react';
import ConsultaBaixaFormPageContainer from './ConsultaBaixaFormPageContainer';

export class ConsultaBaixaFormPage extends React.Component{
    
    render(){
      return (
        <ConsultaBaixaFormPageContainer {...this.props}/>
      )
    }
}

