import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import _ from 'lodash'
import { messageActions } from '../../store/message'

const BASE_PATH = '/lottustech/notaentrada'
const crud = build(validate, BASE_PATH)
const prefix = 'crudNotaEntrada'

const newTypes = {
  RESET: `${prefix}reset`,
}
const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.confirmar = (oids, onSuccess) => {
  return (dispatch, getState) => {
    apiService
      .put(`${BASE_PATH}/confirmar`, { oids })
      .then((response) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'success',
          detail: _.get(response, 'data.message', `Nota(s) confirmada(s)`),
        }
        dispatch(messageActions.messageShowMessages([msg]))
        if (onSuccess) {
          onSuccess(_.get(response, 'data', {}))
        }
      })
      .catch((error) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'error',
          detail: `${_.get(error, 'data.message', 'Ocorreu um erro confirmando a(s) nota(s)')}`,
        }
        dispatch(messageActions.messageShowMessages([msg]))
      })
  }
}

actions.cancelar = (oids, onSuccess) => {
  return (dispatch, getState) => {
    apiService
      .put(`${BASE_PATH}/cancelar`, { oids })
      .then((response) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'success',
          detail: `${_.get(response, 'data.message', 'Nota(s) cancelada(s)')}`,
        }
        dispatch(messageActions.messageShowMessages([msg]))
        if (onSuccess) {
          onSuccess(_.get(response, 'data', {}))
        }
      })
      .catch((error) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'error',
          detail: `${_.get(error, 'data.message', 'Ocorreu um erro cancelando a(s) nota(s)')}`,
        }
        dispatch(messageActions.messageShowMessages([msg]))
        console.log(JSON.stringify(error))
      })
  }
}

actions.reabrir = (oids, onSuccess) => {
  return (dispatch, getState) => {
    apiService
      .put(`${BASE_PATH}/reabrir`, { oids })
      .then((response) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'success',
          detail: _.get(response, 'data.message', `Nota(s) reaberta(s)`),
        }
        dispatch(messageActions.messageShowMessages([msg]))
        if (onSuccess) {
          onSuccess(_.get(response, 'data', {}))
        }
      })
      .catch((error) => {
        const msg = {
          sticky: false,
          summary: '',
          severity: 'error',
          detail: `${_.get(error, 'data.message', 'Ocorreu um erro reabrindo a(s) nota(s)')}`,
        }
        dispatch(messageActions.messageShowMessages([msg]))
      })
  }
}

export { types, actions }
