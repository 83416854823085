import React from "react";
import { InputText } from "primereact/inputtext";
import { getMessage } from "../../../utils/index";
import "./ReligiaoForm.scss";

export const ReligiaoForm = ({ record, changeHandler, messages }) => (
  <div className="p-grid p-fluid">
    <div className="p-col-12">
      <div className="card card-w-title">
        <h3>
          {getMessage(messages, "religiao.label.religiao")} {record.oid || ""}
        </h3>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
            <span className="p-float-label">
              <InputText value={record.nome} onChange={changeHandler} name="nome" />
              <label>{getMessage(messages, "religiao.label.nome")}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
