import { types } from "./botaoDinamico.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  id:"botaoDinamico",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value:{
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible:true
      }
    },
    {
      label: "Nome",
      value:{
        field: "textoBotao",
        header: "Nome",
        columnKey: "textoBotao",
        sortable: true,
        columnSortField: 'textoBotao',
        filter: true,
        visible:true
      }
    },
    {
      label: "Tela",
      value:{
        field: "idTela",
        header: "Tela",
        columnKey: "idTela",
        sortable: true,
        columnSortField: 'idTela',
        filter: true,
        visible:true
      }
    }
  ],
  columns: [],
  currentRecord: {
    oid: "",
    textoBotao:"",
    idTela:"",
    style:"",
    entidadeBase:"",
    perfis: []
  },
  lookupFuncaoDinamica: {
    visible: false,
    modal: true,
    header: 'Função Dinâmica'
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDBotaoDinamicoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('botaodinamico');
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.PICKLIST_PERFIS_MOVE_TO_SOURCE:
      return pickListPerfisMoveToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE:
      return pickListPerfisMoveAllToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_TO_TARGET:
      return pickListPerfisMoveToTarget(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_TARGET:
      return pickListPerfisMoveAllToTarget(state, action);
    case types.SET_LOOKUP_FUNCAO_DINAMICA_VISIBLE:
      return setLookupFuncaoDinamicaVisible(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function setLookupFuncaoDinamicaVisible(state, action) {
  let lookupFuncaoDinamica = state.lookupFuncaoDinamica;
  lookupFuncaoDinamica = Object.assign({}, lookupFuncaoDinamica, { visible: action.visible });
  return Object.assign({}, state, { lookupFuncaoDinamica })
}

function buildPerfilBotaoDinamico(perfis = [], oidBotaoDinamico) {
  let perfisBotaoDinamico = perfis.map(perfil => {
    return {
      oidBotaoDinamico: oidBotaoDinamico,
      oidPerfil: perfil.oid,
      nomePerfil: perfil.nomePerfil,
      oid: null
    }
  });
  return perfisBotaoDinamico;
}

function pickListPerfisMoveToSource(state, action) {
  let perfisBotaoDinamicoDeselecionados = [];

  action.perfisBotaoDinamicoRemoved.forEach(perfilBotaoDinamicoRemoved => {
    perfisBotaoDinamicoDeselecionados = state.currentRecord.perfisBotaoDinamicoSelecionados.filter(perfilBotaoDinamico => perfilBotaoDinamico.oidPerfil !== perfilBotaoDinamicoRemoved.oidPerfil);
  });

  let currentRecord = Object.assign({}, state.currentRecord, { perfisBotaoDinamicoSelecionados: perfisBotaoDinamicoDeselecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToSource(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord, { perfisBotaoDinamicoSelecionados: [] });
  return Object.assign({}, state, { currentRecord });
}

function filterOldPerfisBotaoDinamico(perfisAdd, perfisBotaoDinamicoSelecionados) {
  return perfisAdd.filter(perfil => {
    let existe = perfisBotaoDinamicoSelecionados.filter(perfilBotaoDinamico => perfilBotaoDinamico.oidPerfil === perfil.oid);
    return existe.length > 0 ? false : true;
  });
}

function pickListPerfisMoveToTarget(state, action) {
  let perfisBotaoDinamicoSelecionados = [...state.currentRecord.perfisBotaoDinamicoSelecionados];
  let perfisAdd = filterOldPerfisBotaoDinamico(action.perfisAdd, perfisBotaoDinamicoSelecionados);
  let perfisBotaoDinamico = buildPerfilBotaoDinamico(perfisAdd, action.oidBotaoDinamico);

  perfisBotaoDinamicoSelecionados = [...perfisBotaoDinamicoSelecionados, ...perfisBotaoDinamico];

  let currentRecord = Object.assign({}, state.currentRecord, { perfisBotaoDinamicoSelecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToTarget(state, action) {
  return pickListPerfisMoveToTarget(state)
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}


