import React from 'react'
import { getMessage } from '../../../utils/index'
import { Card } from 'primereact/card'
import './EmprestimoForm.scss'
import _ from 'lodash'
import LtsLookupContrato from '../../../components/lottus/lts-lookup-contrato'
import LtsDropdownResponsavel from '../../../components/lottus/lts-dropdown-responsavel'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import LtsSelectProdutos from '../../../components/lottus/lts-select-produtos'
import { enums } from '../../../constants'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'

export const EmprestimoForm = (props) => {
  const record = props.record
  const messages = props.messages

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card title={getMessage(messages, 'emprestimo.label.emprestimo') + ' ' + record.oid || ''}>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <LtsLookupContrato
                label={'Contrato'}
                value={_.get(record, 'contrato.value', '')}
                oidRecord={_.get(record, 'contrato.id', '')}
                oidUnidade={_.get(record, 'contrato', '')}
                history={props.history}
                onChange={props.setContrato}
                stateContrato={props.stateContrato}
                editHidden={false}
                addFiltroDistint={true}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <LtsDropdownUnidade
                name={'unidade'}
                label={'Unidade'}
                value={props.record.unidade}
                onChange={(e) => props.setField('unidade', e)}
                showOptionAll={false}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <LtsDropdownResponsavel
                name={'responsavel'}
                label={getMessage(messages, 'emprestimo.label.responsavel')}
                value={props.record.responsavel}
                onChange={(e) => props.setField('responsavel', e)}
                loadDataFnParam={_.get(props, 'record.contrato.id', null)}
                autoSelectFirstRecord={true}
                responsaveisComDependentes={true}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsCalendar
                value={record.dataEmissao}
                name={'dataEmissao'}
                label={getMessage(messages, 'emprestimo.label.dataEmissao')}
                onChange={(e) => props.setField('dataEmissao', e)}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsCalendar
                value={record.dataSolicitacao}
                name={'dataSolicitacao'}
                label={getMessage(messages, 'emprestimo.label.dataSolicitacao')}
                onChange={(e) => props.setField('dataSolicitacao', e)}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsCalendar
                value={record.dataVencimento}
                name={'dataVencimento'}
                label={getMessage(messages, 'emprestimo.label.dataVencimento')}
                onChange={(e) => props.setField('dataVencimento', e)}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsCalendar
                value={record.dataDevolucao}
                name={'dataDevolucao'}
                label={getMessage(messages, 'emprestimo.label.dataDevolucao')}
                onChange={(e) => props.setField('dataDevolucao', e)}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsTextArea
                value={record.observacao}
                onChange={(e) => props.setField('observacao', e)}
                name={'observacao'}
                label={getMessage(messages, 'emprestimo.label.observacao')}
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12">
              {/*<LtsTableEmprestimos*/}
              {/*  history={props.history}*/}
              {/*  values={_.get(props, "record.produtos", [])}*/}
              {/*  onChange={(e) => props.onChangeProdutos(e)}*/}
              {/*/>*/}
              <LtsSelectProdutos
                history={props.history}
                values={_.get(props, 'record.produtos', [])}
                onChange={(e) => props.onChangeProdutos(e)}
                title={'Equipamentos'}
                labelLookup={'Equipamento'}
                quantidadePadrao={1}
                idUnidade={record.unidade.id}
                filtroTipoProduto={enums.TipoProduto[1].id}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
