import axios from 'axios'
import storage from './stoage.service'
import FormData from 'form-data'

const getHeaders = () => {
  const headers = {
    'Content-type': 'multipart/form-data',
    'X-Phoenix-Empresa': storage.getCompany() ? storage.getCompany().oid : -1,
    'X-Phoenix-Unidade': storage.getUnit() ? storage.getUnit().oid : -1,
  }
  const token = storage.getToken()
  if (token) {
    headers['X-Phoenix-Token'] = `Bearer ${token}`
  }

  return headers
}

const importNotas = (files) => {
  const formData = new FormData()
  for (const file of files) {
    formData.append('file', file)
  }

  //FIXME isso deve vir do BE
  //const host = 'http://18.211.149.146' // staging
  const host = 'https://srvc-nota-fiscal.lottustech.com.br' //   producao
  // const host = 'http://localhost'
  const path = `${host}/api/v1/lts-serv-nota-fiscal/import-xml`
  return axios.post(path, formData, { headers: getHeaders() })
}

export default {
  importNotas,
}
