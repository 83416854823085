import React from 'react';
import SexoListPageContainer from './SexoListPageContainer';

export class SexoListPage extends React.Component{
    
    render(){
      return (
        <SexoListPageContainer {...this.props}/>
      )
    }
}

