import { types } from "./esquecisenha.actions";
import storage from "../../services/stoage.service";

const initialState = {
  currentRecord: {
    cpf: ""
  },
  token: storage.getToken(),
  login: "",
  errorMessage: ""
};

export function esqueciSenhaReducer(state = initialState, action) {
  if (action.type === types.CHANGE_HANDLER) {
    let currentRecord = Object.assign({}, state.currentRecord);
    const { field, value } = action;
    const fields = field && field.split(".");
    const lastField = fields[fields.length - 1];
    let current = currentRecord;
    if (fields.length > 1) {
      for (let index = 0; index < fields.length - 1; index++) {
        const owner = current;
        current = current[fields[index]];
        if (!current) {
          current = {};
          owner[fields[index]] = current;
        }
      }
    }
    current[lastField] = value;
    return Object.assign({}, state, { currentRecord });
  }

  if (action.type === types.LOGIN_SET_ERROR_MESSAGE) {
    const { errorMessage } = action;
    return Object.assign({}, state, { errorMessage });
  }
  if (action.type === types.LOGIN_CLEAR_ERROR_MESSAGE) {
    const errorMessage = "";
    return Object.assign({}, state, { errorMessage });
  }

  return state;
}
