import React, { useEffect } from 'react'
import { Card } from 'primereact/card'
import _ from 'lodash'
import './ContratoForm.scss'
import '../../../index.scss'
import LtsInputText from '../../../components/lottus/lts-input-text'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'

export default function InformacoesCartao(props) {
  useEffect(() => {}, [props.plano.formaPagamento])

  return (
    <React.Fragment>
      <Card subTitle={'Informações Cartão de Crédito'}>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
            <LtsDropdownIdValue
              name={'tipoCartaoCredito'}
              value={_.get(props, 'plano.tipoCartaoCredito', '')}
              onChange={(e) => props.setField('tipoCartaoCredito', e)}
              loadDataFn={apiService.getTipoCartaoCredito}
              label={'Tipo cartão'}
              style={{ width: '200px' }}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <LtsInputText
              value={_.get(props, 'plano.cartaoNumero', '')}
              label={'Número do cartão'}
              onChange={(e) => props.setField('cartaoNumero', e)}
              name={'cartaoNumero'}
              style={{ minWidth: '300px', maxWidth: '300px' }}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <LtsInputText
              value={_.get(props, 'plano.cartaoNome', '')}
              label={'Nome impresso no cartão'}
              onChange={(e) => props.setField('cartaoNome', e)}
              style={{ minWidth: '300px', maxWidth: '300px' }}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <LtsCalendar
              value={_.get(props, 'plano.cartaoVencimento', '')}
              label={'Vencimento cartão'}
              onChange={(e) => props.setField('cartaoVencimento', e)}
              view={'month'}
              dateFormat="mm/yy"
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
