import React from 'react';
import ItensLancamentoListPageContainer from './ItensLancamentoListPageContainer';

export class ItensLancamentoListPage extends React.Component{
    
    render(){
      return (
        <ItensLancamentoListPageContainer {...this.props}/>
      )
    }
}

