import React from 'react';
import ProdutoListPageContainer from './ProdutoListPageContainer';

export class ProdutoListPage extends React.Component{
    
    render(){
      return (
        <ProdutoListPageContainer {...this.props}/>
      )
    }
}

