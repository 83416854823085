import React from 'react'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes, icons, enums } from '../../../constants'
import { actions as grupoActions } from '../../grupo/grupo.actions'
import { actions } from '../taxa.actions'
import { TaxaForm } from './TaxaForm'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { Filtro } from '../../../utils/Filtro.js'
import _ from 'lodash'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudTaxaState.messages
  const record = state.crudTaxaState.currentRecord
  const currentUser = state.appState.currentUser
  const contasCorrentes = state.crudTaxaState.contasCorrentes
  // REFERENTE AO LOOKUP DE GRUPO
  const grupo = state.crudTaxaState.currentRecord.grupoFinanceiro
  const lookupGrupoBase = {
    records: state.crudGrupoState.records,
    columns: state.crudGrupoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'descricao')
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        return <Column key={col.field} {...col} />
      }),
    first: state.crudGrupoState.index,
    rows: state.crudGrupoState.max,
    totalRecords: state.crudGrupoState.count,
    sortField: state.crudGrupoState.sortField,
    order: state.crudGrupoState.sortOrder,
    editDisabled: false,
  }
  let lookupGrupo = Object.assign({}, state.crudTaxaState.lookupGrupo, lookupGrupoBase)

  //REFERENTE AO LOOKUP DE TAXA
  const taxa = state.crudTaxaState.currentRecord.taxa
  const lookupTaxaBase = {
    records: state.crudTaxaState.records,
    columns: state.crudTaxaState.columnsLookup
      .filter(
        (col) =>
          col.field === 'oid' ||
          col.field === 'valor' ||
          col.field === 'grupoFinanceiro_oid' ||
          col.field === 'grupoFinanceiro_descricao'
      )
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        if (col.field === 'valor') {
          return (
            <Column
              {...col}
              key={col.field}
              body={(rowData) => utils.formatarDinheiro(rowData.valor)}
              style={{ textAlign: 'right' }}
            />
          )
        }
        return <Column key={col.field} {...col} />
      }),
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    editDisabled: true,
    searchDisabled: false,
  }

  let lookupTaxa = Object.assign({}, state.crudTaxaState.lookupTaxa, lookupTaxaBase)

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  //adicionando a unidade nula, pois caso não tenha unidade, a taxa deve valer para todas unidades
  let unidades = [{ id: '', value: 'Selecione' }]
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)

  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(storage.getCompany(), null)
  const formasPagamentoBasica = utils.getFormasPagamento(enums.FormasPagamentoBasica, configFormasPagamento)

  return {
    formasPagamentoBasica,
    currentUser,
    record,
    messages,
    unidades,
    disableUnit,
    taxa,
    lookupTaxa,
    lookupGrupo,
    grupo,
    colunasTaxa: state.crudTaxaState.colunasTaxa,
    colunasPacoteTaxas: state.crudTaxaState.colunasPacoteTaxas,
    first: state.crudTaxaState.index,
    rows: state.crudTaxaState.max,
    totalRecords: state.crudTaxaState.count,
    sortField: state.crudTaxaState.sortField,
    order: state.crudTaxaState.sortOrder,
    contasCorrentes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    //REFERENTE AO LOOKUP DE GRUPO
    lookupGrupoSetVisible: (visible) => dispatch(actions.setLookupGrupoVisible(visible)),
    lookupGrupoPaginate: (paginationParams) => dispatch(grupoActions.loadLookup(paginationParams)),
    lookupGrupoSortOrder: (sortParams) => dispatch(grupoActions.loadLookup(sortParams)),
    lookupGrupoApplyFilter: (filterParams) => {
      dispatch(actions.aplicarFiltrosDadosGrupo(filterParams))
    },
    lookupGrupoLoad: () => dispatch(actions.aplicarFiltrosDadosGrupo()),
    //REFERENTE AO LOOKUP DE TAXA
    lookupTaxaSetVisible: (visible) => dispatch(actions.setLookupTaxaVisible(visible)),
    lookupTaxaPaginate: (paginationParams, oidUnidade) => {
      dispatch(actions.loadTaxasValidas(paginationParams, oidUnidade))
    },
    // lookupPacoteTaxaPaginate: (paginationParams, oidUnidade) => {
    //   dispatch(actions.loadTaxasValidas(paginationParams, oidUnidade))
    // },
    lookupTaxaSortOrder: (paginationParams, oidUnidade) =>
      dispatch(actions.loadTaxasValidas(paginationParams, oidUnidade)),
    lookupTaxaApplyFilter: (filterParams, oidUnidade) => {
      dispatch(actions.setFiltroAvancado(filterParams))
      dispatch(actions.loadTaxasValidas(null, oidUnidade))
    },
    lookupTaxaLoad: (oidUnidade) => dispatch(actions.loadTaxasValidas(null, oidUnidade)),
    //
    setGrupo: (grupo) => {
      dispatch(actions.setField('grupoFinanceiro.id', grupo != null ? grupo.id : null))
      dispatch(actions.setField('grupoFinanceiro.value', grupo != null ? grupo.descricao : ''))
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    setPacoteTaxas: (pacoteTaxas) => dispatch(actions.setPacoteTaxas(pacoteTaxas)),

    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      // if (field === 'valor') {
      //   valor = valor.replace('R$', '')
      //   if (typeof valor === 'string' && valor.length > 0) {
      //     valor = valor.trim()
      //   }
      // }
      dispatch(actions.setField(field, valor))
    },

    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    clearSelectedFilters: () => dispatch(grupoActions.clearSelectedFilters()),
    clearRecords: () => {
      dispatch(grupoActions.setRecords([]))
      dispatch(actions.setRecords([]))
    },
    loadContasCorrentes: () => dispatch(actions.getContasCorrentes()),
  }
}

class TaxaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.beforeFetchRecordSuccess = (data) => {
      return data
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TAXA_FINANCEIRO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TAXA_FINANCEIRO}`)
    }
    this.setField = props.setField
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}`)
  }

  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    const valor = target.value
    this.props.setField(field, valor)
  }

  // REFERENTE AO LOOKUP PARA GRUPO
  lookupGrupoOnPage = (e) => {
    this.props.lookupGrupoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupGrupoOnSort = (e) => {
    this.props.lookupGrupoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupGrupoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupGrupoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupGrupoLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupGrupoLoad()
    }
  }

  lookupGrupoOnClick = (e) => {
    this.props.lookupGrupoLoad()
    this.props.lookupGrupoSetVisible(true)
  }
  lookupGrupoOnHide = (e) => {
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnConfirm = (e) => {
    this.props.setGrupo({ descricao: e.selection.descricao, id: e.selection.oid })
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnCancel = (e) => {
    this.props.lookupGrupoSetVisible(false)
  }
  lookupGrupoOnEdit = () => {
    if (this.props.record.grupoFinanceiro && this.props.record.grupoFinanceiro.id) {
      return `${routes.PATH_GRUPO_FINANCEIRO}/${this.props.record.grupoFinanceiro.id}`
    } else {
      return `${routes.PATH_GRUPO_FINANCEIRO}`
    }
  }
  lookupGrupoOnComplete = (e) => {}

  lookupGrupoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let grupo = { descricao: e.value, id: null }
      this.props.setGrupo(grupo)
      this.props.clearRecords()
    } else {
      this.props.setGrupo({ descricao: value.descricao, id: value.oid })
      const filters = {
        campo: 'descricao',
        valor: '',
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupGrupoApplyFilter(filters)
    }
  }

  lookupGrupoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.grupoFinanceiro) && utils.nonNull(this.props.record.grupoFinanceiro.value)) {
      const filtroGrupo = new Filtro('descricao', this.props.record.grupoFinanceiro.value, 'CONTEM')
      this.props.lookupGrupoApplyFilter(filtroGrupo)
    }
  }

  // REFERENTE AO LOOKUP DE TAXA
  lookupTaxaOnPage = (e) => {
    this.props.lookupTaxaPaginate(
      {
        max: e.rows,
        index: e.first,
      },
      this.resolveOidUnidade()
    )
  }

  //----- LOOKUP PACOTE TAXA -----//
  // lookupPacoteTaxaOnPage = (e) => {
  //   this.props.lookupPacoteTaxaPaginate(
  //     {
  //       max: e.rows,
  //       index: e.first,
  //     },
  //     this.resolveOidUnidade()
  //   )
  // }

  lookupTaxaOnSort = (e) => {
    this.props.lookupTaxaSortOrder(
      {
        sortOrder: e.sortOrder,
        sortField: e.sortField,
      },
      this.resolveOidUnidade()
    )
  }
  // lookupPacoteTaxaOnSort = (e) => {
  //   this.props.lookupTaxaSortOrder(
  //     {
  //       sortOrder: e.sortOrder,
  //       sortField: e.sortField,
  //     },
  //     this.resolveOidUnidade()
  //   )
  // }

  lookupTaxaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {}
      const value = e.filters[key].value
      if (value) {
        // é necessário este tratamento devido a taxa ter um grupo financeiro.
        if (key === 'grupoFinanceiro_descricao') {
          filter = {
            campo: 'descricao',
            valor: value,
            tipoFiltro: 'CONTEM',
            relacionamento: ['grupoFinanceiro'],
          }
        } else {
          filter = {
            campo: key,
            valor: value,
            tipoFiltro: 'CONTEM',
          }
        }
        this.props.lookupTaxaApplyFilter(filter, this.resolveOidUnidade())
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupTaxaLoad(this.resolveOidUnidade())
    }
  }

  // lookupPacoteTaxaOnFilter = (e) => {
  //   const keys = Object.keys(e.filters) || []
  //   keys.forEach((key) => {
  //     let filter = {}
  //     // é necessário este tratamento devido a taxa ter um grupo financeiro.
  //     if (key === 'grupoFinanceiro_descricao') {
  //       filter = {
  //         campo: 'descricao',
  //         valor: e.filters[key].value,
  //         tipoFiltro: 'CONTEM',
  //         relacionamento: ['grupoFinanceiro'],
  //       }
  //     } else {
  //       filter = {
  //         campo: key,
  //         valor: e.filters[key].value,
  //         tipoFiltro: 'CONTEM',
  //       }
  //     }
  //     this.props.lookupTaxaApplyFilter(filter, this.resolveOidUnidade())
  //   })
  //   if (keys.length === 0) {
  //     this.props.clearSelectedFilters()
  //     this.props.lookupTaxaLoad(this.resolveOidUnidade())
  //   }
  // }

  resolveOidUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    return utils.isIdValueIdValido(idValueUnidade) ? idValueUnidade.id : null
  }

  resolveFiltroUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    if (utils.isIdValueIdValido(idValueUnidade)) {
      return { campo: 'unidade.oid', valor: idValueUnidade.id, tipoFiltro: 'IGUAL_A', relacionamento: ['unidade'] }
    }
    return null
  }

  lookupTaxaOnClick = (e) => {
    this.props.lookupTaxaLoad(this.resolveOidUnidade())
    this.props.lookupTaxaSetVisible(true)
  }
  lookupTaxaOnHide = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }
  lookupTaxaOnConfirm = (e) => {
    const taxaSelecionada = e.selection
    // procura se a Taxa selecionada já está na datatable
    let taxaExistente = utils.findObjectInArray(
      this.props.record.taxasContrapartidas,
      'oidTaxaAlvo',
      taxaSelecionada.oid
    )
    if (taxaExistente === null) {
      let taxasAtuais = this.props.record.taxasContrapartidas
      let taxa = {
        oidTaxaAlvo: taxaSelecionada.oid,
        oidGrupo: taxaSelecionada.grupoFinanceiro_oid,
        value: taxaSelecionada.grupoFinanceiro_descricao,
        valor: taxaSelecionada.valor,
        tipo: taxaSelecionada.grupoFinanceiro_tipo,
      }
      taxasAtuais.push(taxa)
      this.props.setField('taxasContrapartidas', taxasAtuais)
    }
    this.props.lookupTaxaSetVisible(false)
  }

  onChangeItensPacote = (data) => {
    this.setField('pacoteTaxas', _.get(data, 'values', []))
    this.setValor(data)
  }

  setValor = (data) => {
    let itens = _.get(data, 'values', [])
    let total = 0
    for (const value of itens) {
      const quantidade = _.get(value, 'quantidade', 0)
      const valorItem = _.get(value, 'valor', 0)
      total += utils.multiply(quantidade, valorItem, 2)
    }
    this.setField('valor', total)
  }

  lookupTaxaOnCancel = (e) => {
    this.props.lookupTaxaSetVisible(false)
  }

  lookupTaxaOnEdit = () => {
    if (this.props.record.taxa && this.props.record.taxa.id) {
      return `${routes.PATH_TAXA_FINANCEIRO}/${this.props.record.taxa.id}`
    } else {
      return `${routes.PATH_TAXA_FINANCEIRO}`
    }
  }

  lookupTaxaOnComplete = (e) => {}

  lookupTaxaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('taxa.id', null)
      this.props.setField('taxa.value', e.value)
      this.props.clearRecords()
    } else {
      const taxaSelecionada = e.value
      // procura se a Taxa selecionada já está na datatable
      let taxaExistente = utils.findObjectInArray(
        this.props.record.taxasContrapartidas,
        'oidTaxaAlvo',
        taxaSelecionada.oid
      )
      if (taxaExistente === null) {
        let taxasAtuais = this.props.record.taxasContrapartidas
        let taxa = {
          oidTaxaAlvo: taxaSelecionada.oid,
          oidGrupo: taxaSelecionada.grupoFinanceiro_oid,
          value: taxaSelecionada.grupoFinanceiro_descricao,
          valor: taxaSelecionada.valor,
          tipo: taxaSelecionada.grupoFinanceiro_tipo,
        }
        taxasAtuais.push(taxa)
        this.props.setField('taxas', taxasAtuais)
        this.props.setField('taxa', '')
      }
      const filters = [{}]
      this.props.lookupTaxaApplyFilter(filters, this.resolveOidUnidade())
    }
  }

  lookupTaxaOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.taxa) && utils.nonNull(this.props.record.taxa.value)) {
      const filtroTaxa = new Filtro('descricao', this.props.record.taxa.value, 'CONTEM', ['grupoFinanceiro'])
      this.props.lookupTaxaApplyFilter(filtroTaxa, this.resolveOidUnidade())
    }
  }

  editarDadoTaxaContrapartida = (taxa, evento) => {
    const target = evento.target
    const field = target.name
    taxa[field] = target.value
    this.setState({ atualizar: true })
  }

  getBotoesPersonalizados = () => {
    let botoes = []
    if (this.props.record.oid && utils.nonNull(this.props.record.oid)) {
      let botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'taxa.label.clonar'),
        className: 'p-button-sucess',
        onClick: (e) => this.clonarDados(),
        icon: icons.COPY_ICON,
      }
      botoes.push(botao)
    }
    return botoes
  }

  removerTaxa = (taxaRemovida) => {
    const taxasRestantes = this.props.record.taxasContrapartidas.filter((taxa) => {
      return taxa.oidTaxaAlvo !== taxaRemovida.oidTaxaAlvo
    })
    this.props.setField('taxasContrapartidas', taxasRestantes)
  }

  removerTaxaPacote = (taxaRemovida) => {
    const taxasRestantes = this.props.record.pacoteTaxas.filter((taxa) => {
      return taxa.oidTaxaAlvo !== taxaRemovida.oidTaxaAlvo
    })
    this.props.setPacoteTaxas(taxasRestantes)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  clonarDados = () => {
    this.props.history.push(`${routes.PATH_TAXA_FINANCEIRO}/novo`)
    let vigenciaFinal = this.props.record.vigenciaFinal
    this.props.setField('oid', null)
    this.props.setField('vigenciaInicial', vigenciaFinal)
    this.props.setField('vigenciaFinal', null)
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  onChangeContaCorrente = (taxa, e) => {
    taxa['contaCorrente'] = e
    this.setState({ atualizar: true })
  }

  render() {
    const columnFormaPagamento = (rowData, column) => {
      if (column && column.field === 'formaPagamento') {
        return (
          <Dropdown
            autoWidth={false}
            value={rowData[column.field]}
            options={this.props.formasPagamentoBasica}
            onChange={(e) => this.editarDadoTaxaContrapartida(rowData, e)}
            optionLabel="value"
            optionValue={(v) => v}
            dataKey="value"
            name="formaPagamento"
            disabled={utils.nonNull(rowData.oidBoletoAilos)}
            appendTo={document.body}
          />
        )
      }
    }

    const columnContaCorrente = (rowData, column) => {
      if (column && column.field === 'contaCorrente') {
        return (
          <LtsDropdownIdValue
            name={'contaCorrente'}
            value={rowData[column.field]}
            onChange={(e) => this.onChangeContaCorrente(rowData, e)}
            loadDataFn={apiService.getContasCorrentes}
            style={{ width: '80%' }}
            loadDataFnParam={_.get(props, 'record.unidade.id', null)}
            appendTo={document.body}
          />
        )
      }
    }

    const actionTaxa = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.removerTaxa(rowData)}
          />
        </div>
      )
    }

    const actionTaxaPacote = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.removerTaxaPacote(rowData)}
          />
        </div>
      )
    }

    const colunasTaxa = _.get(this, 'props.colunasTaxa', []).map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionTaxa} style={{ width: '10em', textAlign: 'center' }} />
      } else if (col.field === 'formaPagamento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnFormaPagamento}
            style={{ textAlign: 'center', overflow: 'visible' }}
          />
        )
      } else if (col.field === 'contaCorrente') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnContaCorrente}
            style={{ textAlign: 'center', overflow: 'visible' }}
          />
        )
      }
      return <Column {...col} key={col.field} field={col.field} header={col.header} style={{ textAlign: 'left' }} />
    })

    const record = _.get(this, 'props.record', {})
    if (!record.vigenciaInicial) {
      record.vigenciaInicial = new Date()
    }

    const props = _.get(this, 'props', {})
    const content = (
      <TaxaForm
        setField={this.setField}
        currentUser={props.currentUser}
        messages={props.messages}
        changeHandler={props.changeHandler}
        onComplete={props.onComplete}
        record={props.record}
        disableUnit={props.disableUnit}
        unidades={props.unidades}
        onChangeUnit={this.onChangeUnit}
        history={props.history}
        colunasTaxa={colunasTaxa}
        //----
        lookupGrupo={props.lookupGrupo}
        lookupGrupoOnKeyPress={this.lookupGrupoOnKeyPress}
        lookupGrupoOnHide={this.lookupGrupoOnHide}
        lookupGrupoOnConfirm={this.lookupGrupoOnConfirm}
        lookupGrupoOnCancel={this.lookupGrupoOnCancel}
        lookupGrupoOnPage={this.lookupGrupoOnPage}
        lookupGrupoOnSort={this.lookupGrupoOnSort}
        lookupGrupoOnFilter={this.lookupGrupoOnFilter}
        lookupGrupoOnClick={this.lookupGrupoOnClick}
        lookupGrupoOnEdit={this.lookupGrupoOnEdit}
        lookupGrupoOnComplete={this.lookupGrupoOnComplete}
        lookupGrupoOnInputFilter={this.lookupGrupoOnInputFilter}
        //------
        lookupTaxa={props.lookupTaxa}
        taxaDropDownTemplate={this.taxaDropDownTemplate}
        lookupTaxaOnKeyPress={this.lookupTaxaOnKeyPress}
        lookupTaxaOnHide={this.lookupTaxaOnHide}
        lookupTaxaOnConfirm={this.lookupTaxaOnConfirm}
        lookupTaxaOnCancel={this.lookupTaxaOnCancel}
        lookupTaxaOnPage={this.lookupTaxaOnPage}
        lookupTaxaOnSort={this.lookupTaxaOnSort}
        lookupTaxaOnFilter={this.lookupTaxaOnFilter}
        lookupTaxaOnClick={this.lookupTaxaOnClick}
        lookupTaxaOnEdit={this.lookupTaxaOnEdit}
        lookupTaxaOnComplete={this.lookupTaxaOnComplete}
        lookupTaxaOnInputFilter={this.lookupTaxaOnInputFilter}
        onChangeItensPacote={this.onChangeItensPacote}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxaFormPageContainer)
