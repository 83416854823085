import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as pessoaActions } from '../../pessoaFisica/pessoaFisica.actions'
import { actions } from '../empresa.actions'
import { actions as appActions } from '../../../store/app'
import { EmpresaForm } from './EmpresaForm'
import { Filtro } from '../../../utils/Filtro.js'
import utils from '../../../utils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudEmpresaState.messages
  const record = state.crudEmpresaState.currentRecord
  const lookupPessoaBase = {
    records: state.appState.lookupPessoa.records,
    columns: state.appState.lookupPessoa.columns
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.appState.lookupPessoa.index,
    rows: state.appState.lookupPessoa.max,
    totalRecords: state.appState.lookupPessoa.count,
    sortField: state.appState.lookupPessoa.sortField,
    order: state.appState.lookupPessoa.sortOrder,
    editDisabled: false,
  }
  let lookupPessoa = Object.assign({}, state.crudEmpresaState.lookupPessoa, lookupPessoaBase) //FIXME - REVER ESSA CONFIG

  return {
    record,
    lookupPessoa,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // ----- metodos para lookup de pessoas -----
    lookupPessoaSetVisible: (visible) => dispatch(actions.setLookupPessoaVisible(visible)),
    lookupPessoaPaginate: (paginationParams) => dispatch(appActions.lookupPessoaPaginate(paginationParams)),
    lookupPessoaSortOrder: (sortParams) => dispatch(appActions.lookupPessoaSortOrder(sortParams)),
    lookupPessoaApplyFilter: (filterParams) => {
      const payload = { field: filterParams.campo, value: filterParams.valor, relacionamento: null }
      dispatch(appActions.lookupPessoaSetSelectedFilters(payload))
      dispatch(appActions.lookupPessoaApplyFilter(filterParams))
    },
    lookupPessoaLoad: () => dispatch(appActions.lookupPessoaLoad()),
    // -------------------------------------------I
    setPessoa: ({ oid, nome }) => {
      dispatch(actions.setField('nomePessoa', nome))
      dispatch(actions.setField('oidPessoa', oid))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const booleanFields = ['ativo']
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (booleanFields.find((current) => current === field)) {
        value = target.checked
      }
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    clearSelectedFilters: () => dispatch(pessoaActions.clearSelectedFilters()),
    clearRecords: () => dispatch(pessoaActions.setRecords([])),
  }
}

class EmpresaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_EMPRESA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_EMPRESA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_EMPRESA}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_EMPRESA}`)
  }

  hideBotaoRemover = () => {
    return true
  }

  lookupPessoaOnPage = (e) => {
    this.props.lookupPessoaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPessoaOnSort = (e) => {
    this.props.lookupPessoaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPessoaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupPessoaApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupPessoaLoad()
    }
  }
  lookupPessoaOnClick = (e) => {
    this.props.lookupPessoaLoad()
    this.props.lookupPessoaSetVisible(true)
  }
  lookupPessoaOnHide = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnConfirm = (e) => {
    this.props.setPessoa(e.selection)
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnCancel = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }

  lookupPessoaOnEdit = () => {
    if (this.props.record.oidPessoa) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.record.oidPessoa}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}/`
    }
  }

  lookupPessoaOnComplete = (e) => {
    console.log(e)
  }

  lookupPessoaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('nomePessoa', e.value)
      this.props.setField('oidPessoa', null)
      this.props.clearRecords()
    } else {
      let pessoa = { oid: value.oid, nome: value.nome }
      this.props.setPessoa(pessoa)
    }
  }

  lookupPessoaOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.nomePessoa)) {
      const filtroNome = new Filtro('nome', this.props.record.nomePessoa, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.record.nomePessoa, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupPessoaApplyFilter(filtroOu)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <EmpresaForm
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        history={this.props.history}
        //---
        lookupPessoa={this.props.lookupPessoa}
        lookupPessoaOnKeyPress={this.lookupPessoaOnKeyPress}
        lookupPessoaOnHide={this.lookupPessoaOnHide}
        lookupPessoaOnConfirm={this.lookupPessoaOnConfirm}
        lookupPessoaOnCancel={this.lookupPessoaOnCancel}
        lookupPessoaOnPage={this.lookupPessoaOnPage}
        lookupPessoaOnSort={this.lookupPessoaOnSort}
        lookupPessoaOnFilter={this.lookupPessoaOnFilter}
        lookupPessoaOnClick={this.lookupPessoaOnClick}
        lookupPessoaOnEdit={this.lookupPessoaOnEdit}
        lookupPessoaOnComplete={this.lookupPessoaOnComplete}
        lookupPessoaOnInputFilter={this.lookupPessoaOnInputFilter}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaFormPageContainer)
