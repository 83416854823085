import React from 'react';
import {Card} from "primereact/card";
import {LookupField} from "../../components/lookup/LookupField";
import {DataTable} from "primereact/datatable";

export default function SelecaoDocumentos(props) {


    return (
        <React.Fragment>
            <Card title={props.titulo}>
                <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                        <LookupField
                            label={"Selecione um documento"}
                            value={props.documentoTempLookup}
                            name={props.name}
                            field="nome"
                            history={props.history}
                            onKeyPress={props.onKeyPress}
                            onComplete={props.onComplete}
                            onInputFilter={props.onInputFilter}
                            editDisabled={props.editDisabled}
                            onEditField={props.onEditField}
                            onClick={props.onClick}
                            visible={props.visible}
                            modal={props.modal}
                            header={props.header}
                            onHide={props.onHide}
                            onConfirm={props.onConfirm}
                            onCancel={props.onCancel}
                            records={props.records}
                            columns={props.columns}
                            totalRecords={props.totalRecords}
                            sortOrder={props.sortOrder}
                            sortField={props.sortField}
                            first={props.first}
                            rows={props.rows}
                            onPage={props.onPage}
                            onFilter={props.onFilter}
                            onSort={props.onSort}
                            editHidden={props.editHidden}
                        />
                    </div>
                </div>

                <div className="p-grid form-group">
                    <div className="p-col-12">
                        <DataTable
                            autoLayout={true}
                            value={props.documentos}
                            selectionMode="single"
                            onPage={props.onPageDatatable}
                            totalRecords={props.documentos ? props.documentos.length : 0}
                            emptyMessage="  "
                            responsiveLayout="scroll"
                            filterDisplay="row"
                            size={"small"}
                            stripedRows
                        >
                            {props.colunasTDocumentos}
                        </DataTable>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}