import React from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import "./PerfilForm.scss";
import { getMessage } from "../../../utils/index";

export const PerfilForm = ({ record, changeHandler, messages }) => (
  <div className="p-grid p-fluid">
    <div className="p-col-12">
      <div className="card card-w-title">
        <h3>
          {getMessage(messages, "perfil.label.perfil")} {record.oid || ""}
        </h3>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3">
            <span className="p-float-label">
              <InputText value={record.nomePerfil} onChange={changeHandler} name="nomePerfil" />
              <label>{getMessage(messages, "perfil.label.nome")}</label>
            </span>
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3">
            <span className="p-float-label">
              <InputText value={record.caminhoDashboard} onChange={changeHandler} name="caminhoDashboard" />
              <label>{getMessage(messages, "perfil.label.caminhodashboard")}</label>
            </span>
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
            <Checkbox
              inputId="chkindperson"
              checked={record.indicadorPersonalizado}
              onChange={changeHandler}
              style={{ marginRight: "5px" }}
              name="indicadorPersonalizado"
            />
            <label htmlFor="chkindperson" className="label">
              {getMessage(messages, "perfil.label.indicadorpersonalizado")}
            </label>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
            <Checkbox
              inputId="chkalteraUnidade"
              checked={record.alteraUnidade}
              onChange={changeHandler}
              style={{ marginRight: "5px" }}
              name="alteraUnidade"
            />
            <label htmlFor="chkalteraUnidade" className="label">
              {getMessage(messages, "perfil.label.alteraunidade")}
            </label>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
            <Checkbox
              inputId="chkadministrador"
              checked={record.administrador}
              onChange={changeHandler}
              style={{ marginRight: "5px" }}
              name="administrador"
            />
            <label htmlFor="chkadministrador" className="label">
              {getMessage(messages, "perfil.label.administrador")}
            </label>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
            <Checkbox
              inputId="chkcriaUsuariosWebService"
              checked={record.criaUsuariosWebService}
              onChange={changeHandler}
              style={{ marginRight: "5px" }}
              name="criaUsuariosWebService"
            />
            <label htmlFor="chkcriaUsuariosWebService" className="label">
              {getMessage(messages, "perfil.label.criausuariowebservice")}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
);
