import React from "react";


import "./DashboardList.scss";

export const DashboardList = props => {
  return (
    <div className="p-grid">
    
    </div>
  );
};
