import apiService from './api.service'
import storage from './stoage.service'

const logarAcesso = (oidEntity = null) => {
  const path = storage.getMenuPath()
  const oidMenu = storage.getOidCurrentMenu()
  return apiService.post(`/lottustech/logacesso/logar`, { path, nomeEntity: null, oidEntity, oidMenu })
}

export { logarAcesso }
