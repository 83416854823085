import { blankToNull, formatNumber, isArrayNotEmpty, parseStringToNumber } from '../../../utils'
import _ from 'lodash'

export function validate(record) {
  const errors = []
  if (record.valorNota) {
    record.valorNota = formatNumber(record.valorNota)
  }
  if (isArrayNotEmpty(record.itens)) {
    record.itens.forEach((i) => (i.valor = parseStringToNumber(i.valor)))
  }

  const oidUnidade = _.get(record, 'unidade.id', -1)
  if (oidUnidade === -1) {
    errors.push({
      field: 'unidade',
      message: 'O campo unidade é obrigatório',
    })
  }

  const oidFornecedor = _.get(record, 'fornecedor.id', -1)
  if (oidFornecedor === -1) {
    errors.push({
      field: 'fornecedor',
      message: 'O campo fornecedor é obrigatório',
    })
  }

  const valorNota = _.get(record, 'valorNota', 0)
  if (valorNota === 0) {
    errors.push({
      field: 'valorNota',
      message: 'O campo total da nota não pode ser zero',
    })
  }

  blankToNull(record)
  const result = {
    ok: errors.length === 0,
    errors,
    record,
  }
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}
