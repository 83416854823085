import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/portalCliente";
const prefix = "crudPortalClienteCarteirinha";
const crud = build(validate, BASE_PATH);

const newTypes = {};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadAll = () => {
    return (dispatch, getState) => {
      const state = getState()[`crudPortalClienteCarteirinhaState`];
      actions.setDefaultColumns(state);
      const stateColumns = state.columns || [];
      let columns = [];
      if (state.usaListDto) {
        columns = stateColumns.filter(col => col.visible).map(col => col.columnKey);
        columns.push("oid");
      } else {
        columns = state.showHideColumns.filter(col => col.value.visible).map(col => col.value.field.replace("_", "."));
      }  
      apiService.post(`${BASE_PATH}/carteirinhas`, {}).then(response => {
        dispatch(actions.fetchRecordsSuccess(response.data));
      });
    };
  }
  
export { types, actions };
