import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../constants'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import './localizacao.scss'
import _ from 'lodash'
import { isArrayNullOrEmpty } from '../../../utils'

export const LocalizacaoGridList = (props) => {
  const records = props.matriz ? props.matriz : []
  const tiposSepultura = props.tiposSepultura ? props.tiposSepultura : []
  for (let record of records) {
    for (let localizacao of record) {
      const tipoSepultura = tiposSepultura.find((t) => t.id === _.get(localizacao, 'oidTipoSepultura', null))
      if (tipoSepultura) {
        localizacao.tipoSepultura = tipoSepultura.object
      }
    }
  }
  const verde = '#7CB342' // Localizacao NAO Temporaria Disponivel OU Localizacao "Inexistente" mas disponivel para ser criada
  const cinza = '#cacaca' // Localizacao NAO Temporaria Indisponivel
  const branco = '#ffffff' //"#000000" "Espaco em branco"
  const azul = '#0066ff' // Localizacao Temporaria Indisponivel
  const amarelo = '#ffff00' // Localizacao Temporaria Disponivel

  function getColor(localizacao) {
    if (!localizacao || localizacao.sinalizador) {
      return branco
    }
    if (parseInt(localizacao.oid)) {
      if (localizacao.temporaria) {
        return localizacao.disponivel ? amarelo : azul
      }
      return localizacao.disponivel ? verde : cinza
    }
    return verde
  }

  function getTitle(localizacao) {
    if (!localizacao) {
      return ''
    }

    if (localizacao.sinalizador) {
      return localizacao.identificacao
    }

    if (parseInt(localizacao.oid)) {
      let title = localizacao.disponivel ? 'Disponível: ' : 'Indisponível: '
      return title + localizacao.identificacao
    }
    return 'Disponível'
  }

  function getLink(localizacao) {
    return (
      <Link
        style={{
          backgroundColor: getColor(localizacao),
          padding: '8px 15px',
        }}
        to={resolveRota(localizacao)}
      >
        {_.get(localizacao, 'idDisplay', '')}
      </Link>
    )
  }

  function resolveRota(localizacao) {
    if (!localizacao || localizacao.sinalizador) {
      return {
        // pathname: `${routes.PATH_LOCALIZACAO}/novo`,
        // localizacaoRef: null
      }
    }
    if (props.onClickLocalizacaoSepultamento) {
      return {
        pathname: '',
        localizacaoRef: '',
      }
    }
    if (parseInt(localizacao.oid) && localizacao.disponivel) {
      return {
        pathname: `${routes.PATH_CONTRATO}/novo`,
        localizacaoRef: localizacao,
      }
    }
    if (parseInt(localizacao.oid) && parseInt(localizacao.oidContrato)) {
      return {
        pathname: `${routes.PATH_CONTRATO}/${localizacao.oidContrato}`,
        localizacaoRef: null,
      }
    } else if (parseInt(localizacao.oid) && parseInt(localizacao.oidContratoFalecido)) {
      return {
        pathname: `${routes.PATH_CONTRATO}/${localizacao.oidContratoFalecido}`,
        localizacaoRef: null,
      }
    }

    localizacao.oid = '__' + Math.random()
    return {
      pathname: `${routes.PATH_CONTRATO}/${localizacao.oid}`,
      localizacaoRef: localizacao,
    }
  }

  function renderMatriz(records) {
    return (
      <div id="scrollDiv" style={{ marginLeft: '10px' }}>
        {records.map((row, i) => (
          <div style={{ marginTop: '20px' }} key={i}>
            {row.map((col, j) => (
              <span
                style={{ paddingRight: '5px' }}
                title={getTitle(col)}
                onClick={(e) => (props.onClickLocalizacaoSepultamento ? props.onClickLocalizacaoSepultamento(col) : '')}
              >
                {getLink(col)}
              </span>
            ))}
          </div>
        ))}
      </div>
    )
  }

  if ((!records || records.length === 0) && !props.showSpinner) {
    return null
  } else if (props.showSpinner) {
    return <LoadingSpinner />
  } else {
    return renderMatriz(records)
  }
}
