import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { InputNumber } from 'primereact/inputnumber'

export default function LtsCurrency(props) {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const value = _.get(props, 'value', null)
    if (value && !initialized) {
      setInitialized(true)
    }
  }, [props.value])

  const resolveName = () => {
    return props.name || 'valor'
  }

  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label}</label>
    }
    return null
  }

  const resolveMode = () => {
    if (props.mode) {
      return props.mode
    }
    return 'currency'
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <InputNumber
          {...props}
          mode={resolveMode()}
          currency="BRL"
          minFractionDigits={2}
          value={props.value}
          onChange={(e) => props.onChange(e.value)}
          name={resolveName()}
          // style={props.style}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
