import { types } from './teladinamica.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'teladinamica',
  currentRecord: {},
  documento: '',
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDTelaDinamicaReducer(state, action) {
  if (state) {
    // state.messages = storage.getAppLabels('tela.dinamica')
    state.messages = storage.getAppLabels('relatorio.livro.caixa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_DOCUMENTO:
      return setDocumento(state, action)
    case types.RESET_STATE:
      return resetState(state)
    default:
      return state
  }
}

function setDocumento(state, action) {
  return Object.assign({}, state, { documento: action.payload })
}

function resetState(state) {
  return Object.assign({}, state, { documento: '', currentRecord: {} })
}
