import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { icons } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { MarcadorFormPage } from '../../pages/marcador'

const tipoMarcador = {
  HISTORICO: 0,
  CONTRATO: 1,
  FALECIDO: 2,
  LANCAMENTO: 3,
  PRODUTO: 4,
}

export default function LtsLookupMarcador(props) {
  const [filters, setFilters] = useState([])
  const [showDialogAddNew, setShowDialogAddNew] = useState(false)

  const columns = [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: false,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'codigo',
      header: 'Descrição',
      columnKey: 'codigo',
      columnSortField: 'codigo',
      sortable: true,
      filter: true,
      visible: true,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/tags/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('codigo', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onComplete = (event) => {
    const value = event.query
    if (value) {
      const filters = [new Filtro('codigo', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        codigo: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const autoCompleteTemplate = (data) => {
    return `${data.oid} - ${data.codigo}`
  }

  const onResolvingColumn = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'codigo') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-descricao-body-width"
            filterHeaderClassName="column-descricao-filter-header-width"
            headerClassName="column-descricao-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      return null
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    if (props.tipo) {
      filters.push(new Filtro('tipos', tipoMarcador[props.tipo], 'IGUAL_A', ['tipos']))
    }
    return filters
  }

  const onClickAddButton = () => {
    setShowDialogAddNew(true)
  }

  const onHideDialogAddNew = () => {
    setShowDialogAddNew(false)
  }

  const onConfirmAddNew = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let marcador = props.stateMarcador.currentRecord
    let url = '/lottustech/tags/'
    const descricao = _.get(marcador, 'descricao', null)
    const codigo = _.get(marcador, 'codigo', descricao)
    if (descricao) {
      marcador.codigo = codigo
      marcador.tipos = [tipoMarcador[props.tipo]]

      apiService
        .post(url, marcador)
        .then((response) => {
          const descricao = _.get(response, 'data.descricao', null)
          const codigo = _.get(response, 'data.codigo', descricao)
          const oid = _.get(response, 'data.oid', null)
          props.onChange([{ descricao, codigo, oid }])
        })
        .catch((error) => {})
    } else {
      // exibir mensagem
    }
    onHideDialogAddNew()
  }

  const getDialogAddNew = () => {
    const footer = (
      <div>
        <Button
          label={'Adicionar'}
          onClick={(e) => onConfirmAddNew(e)}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    return (
      <Dialog
        header={props.header}
        visible={showDialogAddNew}
        onHide={onHideDialogAddNew}
        modal={true}
        closable={true}
        style={{ width: '70%' }}
        contentStyle={{ maxHeight: '85%', overflow: 'auto' }}
        footer={footer}
      >
        <React.Fragment>
          <MarcadorFormPage renderToolbar={false} />
        </React.Fragment>
      </Dialog>
    )
  }

  return (
    <React.Fragment>
      {getDialogAddNew()}
      <LtsLookup
        label={props.label}
        value={props.value}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onComplete={onComplete}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        header={props.label}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        multiple={props.multiple}
        showAddButton={props.showAddButton}
        onClickAddButton={onClickAddButton}
      />
    </React.Fragment>
  )
}
