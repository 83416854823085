import { types } from "./configuracaoBoleto.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "configuracaoBoleto",
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        columnSortField: "oid",
        visible: true
      }
    },
    {
      label: "Nome",
      value: {
        field: "nome",
        header: "Nome",
        columnKey: "nome",
        sortable: true,
        filter: true,
        visible: true
      }
    },
    {
      label: "Convênio Cobrança",
      value: {
        field: "numeroConvenioCobranca",
        header: "Convênio",
        columnKey: "numeroConvenioCobranca",
        columnSortField: "numeroConvenioCobranca",
        sortable: true,
        filter: true,
        visible: true
      }
    }
  ],
  columnsLookup: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
      columnSortField: "oid",
      visible: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      filter: true,
      visible: true
    }
  ],
  columns: [],
  currentRecord: {
    oid: "",
    unidade: {},
    nome: "",
    numeroConvenioCobranca: "",
    codigoCarteiraCobranca: "",
    descricaoDocumento: "",
    especieDocumento: "",
    numeroParcelas: "",
    tipoPagamentoDivergente: "",
    valorMinimoPagamentoDivergente: "",
    enviarAvisoVencimentoSmsAntesVencimento: false,
    enviarAvisoVencimentoSmsDiaVencimento: false,
    enviarAvisoVencimentoSmsAposVencimento: false,
    tipoDesconto: "",
    valorDesconto: "",
    percentualDesconto: "",
    tipoMulta: "",
    valorMulta: "",
    percentualMulta: "",
    tipoJurosMora: "",
    valorJurosMora: "",
    percentualJurosMora: "",
    diasNegativacaoSerasa: "",
    diasProtesto: "",
    formaEmissao: false
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false
};

const reducer = new Reducer(initialState, types);

export function CRUDConfiguracaoBoletoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("configuracaoboleto");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}

