import React from 'react';

import LoteBoletosFormPageContainer from './LoteBoletosFormPageContainer';

export class LoteBoletosFormPage extends React.Component{

    
    render(){
        return (
           <LoteBoletosFormPageContainer {...this.props} />
        )
    }
}
