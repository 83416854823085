import { types } from "./funcaoDinamica.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "funcaoDinamica",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        columnSortField: "oid",
        filter: true,
        visible:true
      }
    },
    {
      label: "Nome",
      value: {
        field: "nomeFuncao",
        header: "Nome",
        columnKey: "nomeFuncao",
        columnSortField: "nomeFuncao",
        sortable: true,
        filter: true,
        visible:true
      }
    }
  ],
  columns:[],
  columnsLookup: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
      columnSortField: "oid",
      visible: true
    },
    {
      field: "nomeFuncao",
      header: "Nome",
      columnKey: "nomeFuncao",
      columnSortField: "nomeFuncao",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    nomeFuncao: "",
    descricao: "",
    funcao: "",
    ativo: true
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDFuncaoDinamicaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("funcaodinamica");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}

