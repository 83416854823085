import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import _ from 'lodash'

export default function LtsDropdownSimNao(props) {
  let options = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ]

  let className = props.className
  if (!className) {
    className = 'width-10-rem'
  }

  if (!props.required) {
    options.push({ label: '', value: '' })
  }

  const onChange = (e) => {
    props.onChange(e.value)
  }

  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <Dropdown
          className={className}
          style={props.style}
          autoWidth={props.autoWidth}
          value={props.value}
          options={options}
          onChange={onChange}
          optionLabel="label"
          optionValue={(v) => v}
          dataKey="value"
          name={props.name}
          disabled={props.disabled}
          itemTemplate={props.itemTemplate}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
