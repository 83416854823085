import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { routes, icons } from '../../../constants'
import { Column } from 'primereact/column'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { actions } from '../nfse.actions'
import { NfseForm } from './NfseForm'
import utils from '../../../utils'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import storage from '../../../services/stoage.service'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudNfseState.currentRecord
  const messages = state.crudNfseState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  return {
    record,
    messages,
    messagesDialog,
    colunasHistorico: state.crudNfseState.colunasHistorico,
    colunasTLancamentoFinanceiro: state.crudNfseState.colunasTLancamentoFinanceiro,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    reenviar: (oidNFS) => dispatch(actions.reenviarNFS(oidNFS)),
    cancelar: (oidNFS) => dispatch(actions.cancelarNFS(oidNFS)),
    downloadDanfe: (oidNFS) => dispatch(actions.downloadDanfSe(oidNFS)),
  }
}

class NfseFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showDialogRemove = false
    this.dataTableLancamentos = React.createRef()
    this.state = {
      showConfirmarCancelar: false,
    }
    actions.afterSave = ({ oid }) => {}
    actions.afterNew = () => {}
    actions.afterRemove = () => {}
    this.nfseCancelar = null
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_NFSE}`)
  }

  hideDialog = () => {
    this.showDialogRemove = false
  }
  hideBotaoRemover = () => {
    return true
  }

  onRowSelect = (e) => {
    this.setState({
      displayDialog: true,
      historico: Object.assign({}, e.data),
    })
  }

  getBotoesPersonalizados = () => {
    let botoes = []
    let botao = {
      type: 'button',
      label: utils.getMessage(this.props.messages, 'nfse.label.nfse'),
      onClick: (e) => this.props.downloadDanfe(this.props.record.oid),
      icon: icons.DOWNLOAD,
    }
    botoes.push(botao)
    if (this.props.record.statusNFSe === 3 || this.props.record.statusNFSe === 10) {
      let botaoReenviar = {
        type: 'button',
        label: 'Reenviar',
        onClick: (e) => this.props.reenviar(this.props.record.oid),
        icon: 'pi pi-arrow-right',
      }
      botoes.push(botaoReenviar)
    }
    if (this.props.record.statusNFSe === 6) {
      let botaoCancelar = {
        type: 'button',
        label: 'Cancelar',
        className: 'p-button-danger',
        // onClick: e => this.props.cancelar(this.props.record.oid),
        onClick: (e) => this.confirmarCancelar(this.props.record.oid),
        icon: icons.TIMES_ICON,
      }
      botoes.push(botaoCancelar)
    }

    return botoes
  }

  confirmarCancelar(oid) {
    this.setState({ showConfirmarCancelar: true })
    this.nfseCancelar = oid
  }

  cancelar = () => {
    if (this.nfseCancelar && utils.isNumber(this.nfseCancelar)) {
      this.props.cancelar(this.nfseCancelar)
    }
  }

  getDialogCancelar() {
    if (this.state.showConfirmarCancelar) {
      return (
        <ConfirmDialog
          header={utils.getMessage(this.props.messagesDialog, 'dialog.titulo.confirmacao')}
          visible={this.state.showConfirmarCancelar}
          content={`Confirma o cancelamento dessa Nota Fiscal? Essa operação não poderá ser desfeita!`}
          confirm={this.cancelar}
          hideDialog={this.hideDialogCancelar}
        />
      )
    }
    return null
  }

  hideDialogCancelar = () => {
    this.setState({ showConfirmarCancelar: false })
  }

  buildDialog = () => {
    return (
      <Dialog
        visible={this.state.displayDialog}
        style={{ width: '35%' }}
        header="Descrição do erro"
        modal={true}
        onHide={() => this.setState({ displayDialog: false })}
        contentStyle={{ 'max-height': '500px' }}
      >
        <div className="p-grid p-fluid">
          <div className="p-col-12" style={{ padding: '.5em' }}>
            <InputTextarea
              id="value"
              rows={1}
              cols={60}
              style={{ 'max-height': '800px' }}
              autoResize={true}
              readOnly={true}
              value={utils.nonNull(this.state.historico) ? this.state.historico.descricaoErro : ''}
            />
          </div>
        </div>
      </Dialog>
    )
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const columnData = (rowData, column) => {
      if ((column.field === 'emissao' || column.field === 'vencimento') && rowData[column.field] !== '') {
        return <div>{utils.formatarData(rowData[column.field])}</div>
      } else if (column.field === 'valor') {
        return <div>R$ {utils.formatCurrency(rowData[column.field])}</div>
      } else if (column.field === 'oid') {
        return <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oid}`}>{rowData.oid}</Link>
      }
    }

    const colunasHistorico = this.props.colunasHistorico.map((col) => {
      return <Column {...col} key={col.field} field={col.field} header={col.header} />
    })

    const colunasTLancamentoFinanceiro = this.props.colunasTLancamentoFinanceiro.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'valor' || col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            sortField={col.columnSortField}
            style={{ textAlign: 'center' }}
            filterMatchMode="contains"
          />
        )
      } else if (col.field === 'emissao' || col.field === 'vencimento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            sortField={col.columnSortField}
            style={{ textAlign: 'center' }}
          />
        )
      }
      return (
        <Column
          {...col}
          key={col.field}
          sortField={col.columnSortField}
          filter={true}
          filterMatchMode="contains"
          style={{ textAlign: 'center' }}
        />
      )
    })

    const content = (
      <div>
        {this.buildDialog()}
        {this.getDialogCancelar()}
        <NfseForm
          changeHandler={this.props.changeHandler}
          record={this.props.record}
          messages={this.props.messages}
          colunasHistorico={colunasHistorico}
          onRowSelect={this.onRowSelect}
          dataTableLancamentos={(el) => (this.dataTableLancamentos = el)}
          colunasTLancamentoFinanceiro={colunasTLancamentoFinanceiro}
        />
      </div>
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NfseFormPageContainer)
