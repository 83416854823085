import { types } from './agenda.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'agenda',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: '',
      value: {
        field: 'pessoa_oid',
        header: '',
        columnKey: 'pessoa',
        columnSortField: 'pessoa.oid',
        sortable: true,
        filter: true,
        relacionamento: ['pessoa'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'Pessoa',
      value: {
        field: 'pessoa_nome',
        header: 'Pessoa',
        columnKey: 'pessoa',
        sortable: true,
        filter: true,
        columnSortField: 'pessoa.nome',
        relacionamento: ['pessoa'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Capela',
      value: {
        field: 'localizacao_identificacao',
        header: 'Capela',
        columnKey: 'localizacao',
        sortable: true,
        filter: true,
        columnSortField: 'localizacao.identificacao',
        relacionamento: ['localizacao'],
        fieldJoin: 'identificacao',
        visible: true,
      },
    },
    {
      label: 'Data Inicial',
      value: {
        field: 'dataInicial',
        header: 'Data Inicial',
        columnKey: 'dataInicial',
        sortable: true,
        filter: true,
        columnSortField: 'dataInicial',
        fieldFormatter: 'formatarDataHora',
        visible: true,
      },
    },
    {
      label: 'Data Final',
      value: {
        field: 'dataFinal',
        header: 'Data Final',
        columnKey: 'dataFinal',
        sortable: true,
        filter: true,
        columnSortField: 'dataFinal',
        fieldFormatter: 'formatarDataHora',
        visible: true,
      },
    },
    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        filter: true,
        columnSortField: 'observacao',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    observacao: '',
    pessoa: '',
    unidade: {},
    dataInicial: new Date(),
    dataFinal: '',
    localizacao: '',
  },
  lookupPessoa: {
    visible: false,
    modal: true,
    header: 'Pessoa',
  },
  lookupLocalizacao: {
    visible: false,
    modal: true,
    header: 'Capela',
  },
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDAgendaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('agenda')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.BOFORE_SAVE:
      return beforeSave(state)
    case types.RESET:
      return reset(state, action)
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLookupPessoaVisible(state, action)
    case types.SET_LOOKUP_LOCALIZACAO_VISIBLE:
      return setLookupLocalizacaoVisible(state, action)
    default:
      return state
  }
}

function setLookupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible })
  return Object.assign({}, state, { lookupPessoa })
}

function setLookupLocalizacaoVisible(state, action) {
  let lookupLocalizacao = state.lookupLocalizacao
  lookupLocalizacao = Object.assign({}, lookupLocalizacao, { visible: action.visible })
  return Object.assign({}, state, { lookupLocalizacao })
}

function beforeSave(state) {
  return state
}

function reset(state, action) {}
