import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes, icons } from '../../../constants'
import { actions } from '../relatorioDinamico.actions'
import { RelatorioDinamicoForm } from './RelatorioDinamicoForm'
import { actions as menuActions } from '../../menu/menu.actions'
import { Button } from 'primereact/button'
import { menuActions as refreshMenuAction } from '../../../store/menu'

import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudRelatorioDinamicoState.currentRecord
  const messages = state.crudRelatorioDinamicoState.messages
  const lookupMenuBase = {
    records: state.crudMenuState.records,
    columns: state.crudMenuState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudMenuState.index,
    rows: state.crudMenuState.max,
    totalRecords: state.crudMenuState.count,
    sortField: state.crudMenuState.sortField,
    order: state.crudMenuState.sortOrder,
    editDisabled: false,
  }
  let lookupMenu = Object.assign({}, state.crudRelatorioDinamicoState.lookupMenu, lookupMenuBase)

  const relacionamentos = record.relacionamentos
  const relacionamento = state.crudRelatorioDinamicoState.relacionamento
  const colunasRelacionamentos = state.crudRelatorioDinamicoState.colunasRelacionamentos

  const colunas = record.grid.colunas
  const coluna = state.crudRelatorioDinamicoState.coluna
  const colunasColuna = state.crudRelatorioDinamicoState.colunasColuna

  const filtros = record.filtros
  const filtro = state.crudRelatorioDinamicoState.filtro
  const colunasFiltros = state.crudRelatorioDinamicoState.colunasFiltros

  const ordenacoes = record.grid.ordenacoes
  const ordenacao = state.crudRelatorioDinamicoState.ordenacao
  const colunasOrdenacao = state.crudRelatorioDinamicoState.colunasOrdenacao

  const perfisSelecionados = record.perfis

  let perfisDisponiveis = state.crudRelatorioDinamicoState.perfisDisponiveis
  if (utils.isArrayNullOrEmpty(perfisDisponiveis)) {
    perfisDisponiveis = [...state.crudPerfilState.records] //copiando valores
  }

  if (utils.isArrayNotEmpty(perfisSelecionados)) {
    let temp = []
    perfisSelecionados.forEach((d) => {
      const perfil = perfisDisponiveis.find((s) => d.oid === s.oid)
      if (perfil) {
        temp.push(perfil)
      }
    })
    temp.forEach((p) => {
      const i = perfisDisponiveis.indexOf(p)
      if (i > -1) {
        perfisDisponiveis.splice(i, 1)
      }
    })
  }

  let displayForm = {
    relacionamento: false,
    campo: false,
    filtro: false,
    ordenacao: false,
  }

  if (state.crudRelatorioDinamicoState.displayForm) {
    displayForm = state.crudRelatorioDinamicoState.displayForm
  }

  const tiposFiltros = utils.getFiltersIdValue(storage.getAppLabels('filtros'))
  const tiposFiltrosString = utils.getFiltersStringIdValue(storage.getAppLabels('filtros'))
  const tiposFiltrosBoolean = utils.getFiltersBooleanIdValue(storage.getAppLabels('filtros'))
  const filterOptions = utils.getFiltersOptionsIdValue(storage.getAppLabels('filtros'))
  const tiposDado = utils.getTiposDado()
  const joinType = utils.getJoinTypes()
  const tiposOrdenacoes = utils.getTiposOrdenacoes()

  return {
    displayForm,
    record: record,
    messages,
    lookupMenu,
    relacionamentos,
    relacionamento,
    colunasRelacionamentos,
    joinType,
    colunas,
    coluna,
    colunasColuna,
    filtros,
    filtro,
    colunasFiltros,
    tiposFiltros,
    ordenacoes,
    ordenacao,
    colunasOrdenacao,
    tiposOrdenacoes,
    perfisDisponiveis,
    perfisSelecionados,
    tiposDado,
    tiposFiltrosString,
    filterOptions,
    tiposFiltrosBoolean,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lookupMenuSetVisible: (visible) => dispatch(actions.setLookupMenuVisible(visible)),
    lookupMenuPaginate: (paginationParams) => dispatch(menuActions.paginate(paginationParams)),
    lookupMenuSortOrder: (sortParams) => dispatch(menuActions.sortOrder(sortParams)),
    lookupMenuApplyFilter: (filterParams) => {
      dispatch(menuActions.setType(null))
      dispatch(menuActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(menuActions.applyFilter(filterParams))
    },
    lookupMenuLoad: () => dispatch(menuActions.loadLookup()),
    setMenu: (menu) => {
      dispatch(actions.setField('menu.id', menu != null ? menu.oid : null))
      dispatch(actions.setField('menu.value', menu != null ? menu.nome : ''))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = _.defaultTo(event.target, _.get(event, 'originalEvent.target', null))
      if (target) {
        const field = target.name
        let value = target.value
        dispatch(actions.removeErrors([{ field }]))
        if (field === 'joinType') {
          dispatch(actions.setRelacionamento(field, value))
        } else if (field === 'tipoFiltro') {
          dispatch(actions.setFiltro(field, value))
        } else if (field === 'tipoDadoFiltro') {
          dispatch(actions.setFiltro('tipoDado', value))
        } else if (field === 'tipoDataTipo') {
          dispatch(actions.setFiltro('tipoDataTipo', value))
        } else if (field === 'tipoDataModificador') {
          dispatch(actions.setFiltro('tipoDataModificador', value))
        } else if (field === 'tipoDataPeriodo') {
          dispatch(actions.setFiltro('tipoDataPeriodo', value))
        } else if (field === 'tipoDataTipoComplementar') {
          dispatch(actions.setFiltro('tipoDataTipoComplementar', value))
        } else if (field === 'tipoDataModificadorComplementar') {
          dispatch(actions.setFiltro('tipoDataModificadorComplementar', value))
        } else if (field === 'tipoDataPeriodoComplementar') {
          dispatch(actions.setFiltro('tipoDataPeriodoComplementar', value))
        } else if (field === 'valorComplementar') {
          dispatch(actions.setFiltro(field, value))
        } else if (field === 'valorData') {
          dispatch(actions.setFiltro('valor', value))
        } else if (field === 'tipoDadoColuna') {
          dispatch(actions.setCampo('tipoDado', value))
        } else if (field === 'tipoOrdenacaoColuna') {
          dispatch(actions.setOrdenacao(field, value))
        } else {
          dispatch(actions.setField(field, value))
        }
      }
    },
    saveHandler: (event) => {
      dispatch(actions.beforeSave())
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
      dispatch(actions.clearFormRelacionamento())
      dispatch(actions.clearFormCampo())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    adicionarRelacionamento: (e) => {
      dispatch(actions.adicionarRelacionamento('relacionamentos', ''))
      dispatch(actions.clearFormRelacionamento())
    },
    editarDadoRelacionamento: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.setRelacionamento(field, valor))
    },
    editarRelacionamento: (relacionamento) => {
      Object.keys(relacionamento).forEach(function (key) {
        dispatch(actions.setRelacionamento(key, relacionamento[key]))
      })
    },
    removerRelacionamento: (relacionamentoRemovido) => {
      dispatch(actions.removerRelacionamento(relacionamentoRemovido))
    },
    adicionarCampo: (e) => {
      dispatch(actions.adicionarCampo('colunas', ''))
      dispatch(actions.clearFormCampo())
    },
    editarDadoCampo: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.setCampo(field, valor))
    },
    editarCampo: (campo) => {
      Object.keys(campo).forEach(function (key) {
        dispatch(actions.setCampo(key, campo[key]))
      })
    },
    removerCampo: (campoRemovido) => {
      dispatch(actions.removerCampo(campoRemovido))
    },
    //Filtros
    adicionarFiltro: (e) => {
      dispatch(actions.adicionarFiltro('filtros', ''))
      dispatch(actions.clearFormFiltro())
    },
    editarDadoFiltro: (event) => {
      const target = event.target
      const field = target.name
      let valor = field === 'visivel' ? target.checked : target.value
      dispatch(actions.setFiltro(field, valor))
    },
    editarFiltro: (campo) => {
      Object.keys(campo).forEach(function (key) {
        dispatch(actions.setFiltro(key, campo[key]))
      })
    },
    removerFiltro: (filtroRemovido) => {
      dispatch(actions.removerFiltro(filtroRemovido))
    },

    //Ordenacoes
    adicionarOrdenacao: (e) => {
      dispatch(actions.adicionarOrdenacao('ordenacoes', ''))
      dispatch(actions.clearFormOrdenacao())
    },
    editarDadoOrdenacao: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.setOrdenacao(field, valor))
    },
    editarOrdenacao: (ordenacao) => {
      Object.keys(ordenacao).forEach(function (key) {
        dispatch(actions.setOrdenacao(key, ordenacao[key]))
      })
    },
    removerOrdenacao: (ordenacaoRemovida) => {
      dispatch(actions.removerOrdenacao(ordenacaoRemovida))
    },
    clearForm: () => {
      dispatch(actions.clearFormRelacionamento())
      dispatch(actions.clearFormCampo())
      dispatch(actions.clearFormFiltro())
      dispatch(actions.clearFormOrdenacao())
    },

    //perfis
    onChangePerfis: (e) => {
      dispatch(actions.onChangePerfis(e.source, e.target))
    },

    showForm(formName, props) {
      dispatch(actions.setDisplayForm(formName, props))
    },

    refreshMenus: () => dispatch(refreshMenuAction.menuFetch()),
    willUnmount: () => {
      dispatch(actions.willUnmount())
    },
    clearSelectedFiltersMenu: () => dispatch(menuActions.clearSelectedFilters()),
  }
}

class RelatorioDinamicoPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.beforeFetchRecordSuccess = (data) => {
      if (utils.isArrayNotEmpty(data.filtros)) {
        data.filtros.forEach((f) => {
          if (utils.isTipoDadoData(f)) {
            this.addAttributes('tipoDataTipo', 'tipoDataModificador', 'tipoDataPeriodo', f, f.valor)
            this.addAttributes(
              'tipoDataTipoComplementar',
              'tipoDataModificadorComplementar',
              'tipoDataPeriodoComplementar',
              f,
              f.valorComplementar
            )
          }
        })
      }
      return data
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_RELATORIO_DINAMICO}/${oid}`)
      // setTimeout(() => {
      //   this.props.refreshMenus()
      // }, 500)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_RELATORIO_DINAMICO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_RELATORIO_DINAMICO}`)
      this.props.refreshMenus()
    }
    actions.afterLoad = () => {
      console.log()
    }
  }

  addAttributes(tipo, modificador, periodo, sourceObj, valor) {
    if (valor && typeof valor === 'string' && valor.indexOf('$') > -1) {
      const arrayExp = valor.split(';')
      if (utils.isArrayNotEmpty(arrayExp)) {
        if (arrayExp[0] === '$DATA_ATUAL') {
          sourceObj[tipo] = 1
        } else if (arrayExp[0] === '$INICIO_MES') {
          sourceObj[tipo] = 2
        } else if (arrayExp[0] === '$FINAL_MES') {
          sourceObj[tipo] = 3
        }

        sourceObj[modificador] = arrayExp[1]

        if (arrayExp[2] === 'd') {
          sourceObj[periodo] = 1
        } else if (arrayExp[2] === 'w') {
          sourceObj[periodo] = 2
        } else if (arrayExp[2] === 'm') {
          sourceObj[periodo] = 3
        }
      }
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  componentWillUnmount() {
    this.props.willUnmount()
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_RELATORIO_DINAMICO}`)
  }

  lookupMenuOnPage = (e) => {
    this.props.lookupMenuPaginate({
      max: e.rows,
      index: e.first,
    })
  }

  lookupMenuOnSort = (e) => {
    this.props.lookupMenuSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }

  lookupMenuOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
      }
      this.props.lookupMenuApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFiltersMenu()
      this.props.lookupMenuLoad()
    }
  }

  lookupMenuOnClick = (e) => {
    this.props.lookupMenuLoad()
    this.props.lookupMenuSetVisible(true)
  }

  lookupMenuOnHide = (e) => {
    this.props.lookupMenuSetVisible(false)
  }

  lookupMenuOnConfirm = (e) => {
    this.props.setMenu(e.selection)
    this.props.lookupMenuSetVisible(false)
  }

  lookupMenuOnCancel = (e) => {
    this.props.setMenu(null)
    this.props.lookupMenuSetVisible(false)
  }

  lookupMenuOnComplete = (e) => {}

  lookupMenuOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'nome',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      this.props.setField('menu.value', e.value)
      this.props.setField('menu.id', null)
      this.props.lookupMenuApplyFilter(filter)
    } else {
      this.props.setMenu(value)
    }
  }

  lookupMenuOnEdit = () => {
    if (this.props.record.menu && this.props.record.menu.id) {
      return `${routes.PATH_MENU}/${this.props.record.menu.id}`
    } else {
      return `${routes.PATH_MENU}/`
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  columnDataFiltro = (rowData, column) => {
    if (column.field === 'tipoFiltro') {
      const hasValue =
        rowData.tipoFiltro &&
        ((rowData.tipoFiltro.id && parseInt(rowData.tipoFiltro.id)) || rowData.tipoFiltro.id === 0)
      if (hasValue) {
        return <div>{utils.getFilterValueById(this.props.tiposFiltros, rowData.tipoFiltro.id)}</div>
      }
      return <div />
    }
  }

  columnDataTipoDado = (rowData, column) => {
    if (column.field === 'tipoDado') {
      const hasValue =
        rowData.tipoDado && ((rowData.tipoDado.id && parseInt(rowData.tipoDado.id)) || rowData.tipoDado.id === 0)
      if (hasValue) {
        return <div>{utils.getTipoDadoValueById(rowData.tipoDado.id)}</div>
      }
      return <div />
    }
  }

  columnValorComplementar = (rowData, column) => {
    if (column.field === 'valorComplementar') {
      if (utils.isTipoDadoData(rowData)) {
        if (rowData[column.field] && rowData[column.field].indexOf('$') > -1) {
          return <div>{rowData[column.field]}</div>
        }
        return <div>{utils.formatarData(rowData[column.field])}</div>
      }
      return <div>{rowData[column.field]}</div>
    }
    return <div />
  }

  columnValor = (rowData, column) => {
    if (column.field === 'valor') {
      if (utils.isTipoDadoData(rowData)) {
        if (rowData[column.field] && rowData[column.field].indexOf('$') > -1) {
          return <div>{rowData[column.field]}</div>
        }
        return <div>{utils.formatarData(rowData[column.field])}</div>
      }
      return <div>{rowData[column.field]}</div>
    }
    return <div />
  }

  parseFilter = (value) => {
    return utils.parseFilter(this.props.filterOptions, value)
  }

  parseTipoDado = (value) => {
    return utils.parseTipoDado(value)
  }

  getTiposFiltros = (filter) => {
    if (utils.isTipoDadoString(filter)) {
      return this.props.tiposFiltrosString
    } else if (utils.isTipoDadoBoolean(filter)) {
      return this.props.tiposFiltrosBoolean
    }
    return this.props.tiposFiltros
  }

  render() {
    const actionRelacionamento = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_SMALL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.editarRelacionamento(rowData)
              this.props.showForm('relacionamento', this.props.displayForm)
            }}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.props.removerRelacionamento(rowData, this.props.record.relacionamentos)}
          />
        </div>
      )
    }

    const columnData = (rowData, column) => {
      if (column.field === 'joinType') {
        const hasValue =
          rowData.joinType && ((rowData.joinType.id && parseInt(rowData.joinType.id)) || rowData.joinType.id === 0)
        if (hasValue) {
          return <div>{rowData.joinType.value}</div>
        }
        return <div />
      }
    }

    let colunasRelacionamentos = this.props.colunasRelacionamentos.map((col) => {
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionRelacionamento} style={{ width: '10em' }} />
      } else if (col.field === 'joinType') {
        return <Column {...col} key={col.field} body={columnData} style={{ textAlign: 'center' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'center' }} />
    })

    // Configuracao de Campos
    const actionCampo = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_SMALL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.editarCampo(rowData)
              this.props.showForm('campo', this.props.displayForm)
            }}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.props.removerCampo(rowData, this.props.record.colunas)}
          />
        </div>
      )
    }

    let colunasCampos = this.props.colunasColuna.map((col) => {
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionCampo} style={{ width: '10em' }} />
      } else if (col.field === 'tipoDado') {
        return <Column {...col} key={col.field} body={this.columnDataTipoDado} style={{ textAlign: 'center' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'center' }} />
    })

    //Configuracao de Filtros
    const actionFiltro = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_SMALL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.editarFiltro(rowData)
              this.props.showForm('filtro', this.props.displayForm)
            }}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.props.removerFiltro(rowData, this.props.record.filtros)}
          />
        </div>
      )
    }

    let colunasFiltros = this.props.colunasFiltros.map((col) => {
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionFiltro} style={{ width: '10em' }} />
      } else if (col.field === 'tipoFiltro') {
        return <Column {...col} key={col.field} body={this.columnDataFiltro} style={{ textAlign: 'center' }} />
      } else if (col.field === 'tipoDado') {
        return <Column {...col} key={col.field} body={this.columnDataTipoDado} style={{ textAlign: 'center' }} />
      } else if (col.field === 'valorComplementar') {
        return <Column {...col} key={col.field} body={this.columnValorComplementar} style={{ textAlign: 'center' }} />
      } else if (col.field === 'valor') {
        return <Column {...col} key={col.field} body={this.columnValor} style={{ textAlign: 'center' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'center' }} />
    })

    // Configuracoes de Ordenacoes
    const actionOrdenacao = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_SMALL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.editarOrdenacao(rowData)
              this.props.showForm('ordenacao', this.props.displayForm)
            }}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.props.removerOrdenacao(rowData, this.props.record.grid.ordenacoes)}
          />
        </div>
      )
    }

    const columnDataOrdenacao = (rowData, column) => {
      if (column.field === 'tipoOrdenacaoColuna') {
        const hasValue =
          rowData.tipoOrdenacaoColuna &&
          ((rowData.tipoOrdenacaoColuna.id && parseInt(rowData.tipoOrdenacaoColuna.id)) ||
            rowData.tipoOrdenacaoColuna.id === 0)
        if (hasValue) {
          return <div>{utils.getFilterValueById(this.props.tiposOrdenacoes, rowData.tipoOrdenacaoColuna.id)}</div>
        }
        return <div />
      }
    }

    let colunasOrdenacao = this.props.colunasOrdenacao.map((col) => {
      if (col.field === 'acoes') {
        return <Column {...col} key={col.field} body={actionOrdenacao} style={{ width: '10em' }} />
      } else if (col.field === 'tipoOrdenacaoColuna') {
        return <Column {...col} key={col.field} body={columnDataOrdenacao} style={{ textAlign: 'center' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'center' }} />
    })

    const content = (
      <RelatorioDinamicoForm
        showForm={this.props.showForm}
        displayForm={this.props.displayForm}
        clearForm={this.props.clearForm}
        colunasRelacionamentos={colunasRelacionamentos}
        relacionamentos={this.props.relacionamentos}
        relacionamento={this.props.relacionamento}
        adicionarRelacionamento={this.props.adicionarRelacionamento}
        editarDadoRelacionamento={this.props.editarDadoRelacionamento}
        joinType={this.props.joinType}
        colunasCampos={colunasCampos}
        colunas={this.props.colunas}
        coluna={this.props.coluna}
        adicionarCampo={this.props.adicionarCampo}
        editarDadoCampo={this.props.editarDadoCampo}
        colunasFiltros={colunasFiltros}
        filtros={this.props.filtros}
        filtro={this.props.filtro}
        adicionarFiltro={this.props.adicionarFiltro}
        editarDadoFiltro={this.props.editarDadoFiltro}
        tiposFiltros={this.props.tiposFiltros}
        colunasOrdenacao={colunasOrdenacao}
        ordenacoes={this.props.ordenacoes}
        ordenacao={this.props.ordenacao}
        adicionarOrdenacao={this.props.adicionarOrdenacao}
        editarDadoOrdenacao={this.props.editarDadoOrdenacao}
        tiposOrdenacoes={this.props.tiposOrdenacoes}
        perfisDisponiveis={this.props.perfisDisponiveis}
        perfisSelecionados={this.props.perfisSelecionados}
        onChangePerfis={this.props.onChangePerfis}
        record={this.props.record}
        changeHandler={this.props.changeHandler}
        messages={this.props.messages}
        history={this.props.history}
        lookupMenu={this.props.lookupMenu}
        lookupMenuOnHide={this.lookupMenuOnHide}
        lookupMenuOnConfirm={this.lookupMenuOnConfirm}
        lookupMenuOnCancel={this.lookupMenuOnCancel}
        lookupMenuOnPage={this.lookupMenuOnPage}
        lookupMenuOnSort={this.lookupMenuOnSort}
        lookupMenuOnFilter={this.lookupMenuOnFilter}
        lookupMenuOnClick={this.lookupMenuOnClick}
        lookupMenuOnEdit={this.lookupMenuOnEdit}
        lookupMenuOnComplete={this.lookupMenuOnComplete}
        lookupMenuOnInputFilter={this.lookupMenuOnInputFilter}
        parseFilter={this.parseFilter}
        parseTipoDado={this.parseTipoDado}
        tiposDado={this.props.tiposDado}
        getTiposFiltros={this.getTiposFiltros}
        filterOptions={this.props.filterOptions}
      />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioDinamicoPageContainer)
