import axios from 'axios'
import storage from './stoage.service'

const getHeaders = () => {
  const headers = {
    'Content-type': 'application/json',
    accept: 'application/json',
    companyId: storage.getCompany() ? storage.getCompany().oid : '-1',
    unitId: storage.getUnit() ? storage.getUnit().oid : '-1',
  }
  const token = storage.getToken()
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return headers
}

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
  withCredentials: false,
})

api.interceptors.response.use((response) => {
  const newToken = response.headers.refreshedtoken
  if (newToken) {
    storage.setToken(newToken)
  }
  return response
})

const get = (path) => {
  path = `/phoenix/api${path}`
  return api.get(path, { headers: getHeaders() })
}

const post = (path, data) => {
  path = `/phoenix/api${path}`
  return api.post(path, data, { headers: getHeaders() })
}

const postFormData = (path, form, additionalHeaders) => {
  path = `/phoenix/api${path}`
  const headers = getHeaders()
  headers['Content-type'] = `multipart/form-data; charset=utf-8; boundary=${form._boundary}`
  Object.assign(headers, additionalHeaders)

  return api.post(path, form, { headers: headers })
}

const put = (path, data) => {
  path = `/phoenix/api${path}`
  return api.put(path, data, { headers: getHeaders() })
}

const remove = (path, data) => {
  path = `/phoenix/api${path}`
  return api.delete(path, { headers: getHeaders() })
}

const removeByOids = (path, data) => {
  path = `/phoenix/api${path}`
  return api.put(path, data, { headers: getHeaders() })
}
const getDateTimeFromServer = () => {
  const path = `/phoenix/api/lottustech/public/data`
  return api.get(path, {})
}

export default {
  get,
  post,
  postFormData,
  put,
  remove,
  getDateTimeFromServer,
  removeByOids,
}
