import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lottustech/unidades';
const prefix = 'crudUnidade';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_LOOKUP_PESSOA_VISIBLE: `${prefix}setLookupPessoaVisible`,
    SET_LOOKUP_EMPRESA_VISIBLE: `${prefix}setLookupEmpresaVisible`,
    SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
    RESET: `${prefix}reset`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.setLookupPessoaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PESSOA_VISIBLE,
        visible
    }
}

actions.setLookupEmpresaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_EMPRESA_VISIBLE,
        visible
    }
}

actions.setBooleanFilter = (field, value) => {
    return {
        type: types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

export { types, actions };
