import React from 'react'
import { BiForm } from './BiForm'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import storage from '../../../services/stoage.service'
import { logarAcesso } from '../../../services/api-log-acesso'
import _ from 'lodash'

const mapStateToProps = (state) => {
  const currentUser = state.appState.currentUser
  const currentCompany = storage.getCompany()

  return {
    currentUser,
    currentCompany,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

class BiFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageId: undefined,
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageId = _.get(this, 'props.match.params[0]', null)
    if (!_.isEmpty(pageId) && pageId !== this.state.pageId) {
      this.setState((previousState) => ({
        pageId: previousState.pageId !== pageId ? pageId : previousState.pageId,
      }))
      logarAcesso().then()
    }
  }

  render() {
    const content = (
      <BiForm {...this.props} currentUser={this.props.currentUser} currentCompany={this.props.currentCompany} />
    )
    return <Page content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BiFormPageContainer)
