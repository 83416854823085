import React from 'react';
import PortalClienteContratoListPageContainer from './PortalClienteContratoListPageContainer';

export class PortalClienteContratoListPage extends React.Component{
    
    render(){
      return (
        <PortalClienteContratoListPageContainer {...this.props}/>
      )
    }
}

