import { types } from './localizacao.actions'
import { Reducer } from '../../services/reducer.factory'
import utils from '../../utils'
import storage from '../../services/stoage.service'
import _ from 'lodash'

const NUMERO_LUGARES_VAGAS_PADRAO_URNA = 4

const initialState = {
  id: 'localizacao',
  booleanFilter: {
    ativo: '',
  },
  tiposLocalizacao: [
    'localizacaoA',
    'localizacaoB',
    'localizacaoC',
    'localizacaoD',
    'localizacaoE',
    'localizacaoF',
    'localizacaoG',
    'localizacaoH',
    'localizacaoI',
    'localizacaoJ',
  ],
  showHideColumns: [...utils.getShowHideColumnsForLocalizacao()],
  columns: [],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificacao',
      header: 'Identificação',
      columnKey: 'identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'identificacao',
      visible: true,
    },
    {
      field: 'tipoSepultura_descricao',
      header: 'Tipo de Sepultura',
      columnKey: 'tipoSepultura.descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      relacionamento: ['tipoSepultura'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'numeroVagas',
      header: 'Vagas',
      columnKey: 'numeroVagas',
      sortable: true,
      filter: true,
      columnSortField: 'numeroVagas',
      visible: false,
    },
    {
      field: 'numeroLugares',
      header: 'Lugares',
      columnKey: 'numeroLugares',
      sortable: true,
      filter: true,
      columnSortField: 'numeroLugares',
      visible: false,
    },
    {
      field: 'temporaria',
      header: 'Temporária',
      columnKey: 'temporaria',
      sortable: true,
      filter: true,
      columnSortField: 'temporaria',
      visible: false,
    },
  ],
  currentRecord: {
    oid: '',
    localizacaoA: '',
    localizacaoB: '',
    localizacaoC: '',
    localizacaoD: '',
    localizacaoE: '',
    localizacaoF: '',
    localizacaoG: '',
    localizacaoH: '',
    localizacaoI: '',
    localizacaoJ: '',
    identificacao: '',
    observacao: '',
    oidContrato: '',
    unidade: {},
    tipoSepultura: {},
    numeroVagas: '',
    numeroLugares: '',
    ativo: true,
    disponivel: true,
    anexos: [],
    temporaria: false,
    // capela: false
  },
  inputs: [],
  selectedFilters: [],
  messages: null,
  lookupContrato: {
    visible: false,
    modal: true,
    header: 'Contrato',
  },
  usaListDto: false,
  numeroLugaresPrevious: '0',
}

const reducer = new Reducer(initialState, types)

export function CRUDLocalizacaoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('localizacao')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_LOOKUP_CONTRATO_VISIBLE:
      return setLookupContratoVisible(state, action)
    case types.SET_LOCALIZACAO_REF:
      return setLocalizacaoRef(state, action)
    case types.RESET_COLUMNS_ID_LOCALIZACAO:
      return resetColumnsIdLocalizacao(state, action)
    case types.ADD_ANEXO:
      return addAnexo(state, action)
    case types.REMOVE_ANEXO:
      return removeAnexo(state, action)
    case types.SET_INPUTS:
      return Object.assign({}, state, { inputs: action.inputs })
    case types.SET_TIPO_SEPULTURA:
      return setTipoSepultura(state, action)
    case types.SET_NUMERO_LUGARES:
      return setNumeroLugares(state, action)
    case types.UPDATE_NUMERO_VAGAS:
      return updateNumeroVagas(state)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}
function setLookupContratoVisible(state, action) {
  let lookupContrato = state.lookupContrato
  lookupContrato = Object.assign({}, lookupContrato, { visible: action.visible })
  return Object.assign({}, state, { lookupContrato })
}

function setLocalizacaoRef(state, action) {
  const currentRecord = state.currentRecord
  const localizacaoRef = action.payload
  currentRecord.unidade = { id: localizacaoRef.oidUnidade, value: localizacaoRef.siglaUnidade }
  currentRecord.tipoSepultura = { id: localizacaoRef.oidTipoSepultura, value: localizacaoRef.descricaoTipoSepultura }
  currentRecord.localizacaoA = localizacaoRef.localizacaoA
  currentRecord.localizacaoB = localizacaoRef.localizacaoB
  currentRecord.localizacaoC = localizacaoRef.localizacaoC
  currentRecord.localizacaoD = localizacaoRef.localizacaoD
  currentRecord.localizacaoE = localizacaoRef.localizacaoE
  currentRecord.localizacaoF = localizacaoRef.localizacaoF
  currentRecord.localizacaoG = localizacaoRef.localizacaoG
  currentRecord.localizacaoH = localizacaoRef.localizacaoH
  currentRecord.localizacaoI = localizacaoRef.localizacaoI
  currentRecord.localizacaoJ = localizacaoRef.localizacaoJ
  currentRecord.identificacao = localizacaoRef.identificacao
  currentRecord.disponivel = localizacaoRef.disponivel
  currentRecord.temporaria = localizacaoRef.temporaria
  return Object.assign({}, state, { currentRecord })
}

function resetColumnsIdLocalizacao(state, action) {
  const showHideColumns = [...utils.getShowHideColumnsForLocalizacao()]
  return Object.assign({}, state, { showHideColumns: showHideColumns })
}

function addAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexo = action.payload
  let novosAnexos = []
  if (anexo) {
    novosAnexos.push(anexo)

    const anexos = currentRecord.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      anexos.forEach((a) => {
        const temp = novosAnexos.find((f) => f.filename === a.filename)
        if (!temp) {
          a.oidContainer = currentRecord.oid
          novosAnexos.push(a)
        }
      })
    }
    currentRecord.anexos = novosAnexos
    return Object.assign({}, state, { currentRecord: currentRecord })
  }

  return state
}

function removeAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexoRemover = action.payload
  if (anexoRemover) {
    const anexos = currentRecord.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find((f) => f.filename === anexoRemover.filename)
      if (temp) {
        const index = anexos.indexOf(temp)
        anexos.splice(index, 1)
      }
    }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }

  return state
}

function setNumeroLugares(state, action) {
  let currentRecord = state.currentRecord
  const numeroLugares = action.payload
  const numeroLugaresCorrente = _.defaultTo(currentRecord.numeroLugares, 0)

  currentRecord.numeroLugares = numeroLugares
  const diff = numeroLugares - numeroLugaresCorrente
  currentRecord.numeroVagas = currentRecord.numeroVagas + diff
  if (currentRecord.numeroVagas < 0) {
    currentRecord.numeroVagas = 0
  }

  return Object.assign({}, state, { currentRecord: currentRecord, numeroLugaresPrevious: numeroLugaresCorrente })
}

function updateNumeroVagas(state) {
  let currentRecord = state.currentRecord
  const numeroLugaresCorrente = _.defaultTo(currentRecord.numeroLugares, 0)
  const numeroLugaresPrevious = _.defaultTo(state.numeroLugaresPrevious, 0)
  if (numeroLugaresPrevious !== numeroLugaresCorrente) {
    const diff = numeroLugaresCorrente - numeroLugaresPrevious
    currentRecord.numeroVagas = currentRecord.numeroVagas + diff
    if (currentRecord.numeroVagas < 0) {
      currentRecord.numeroVagas = 0
    }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
}

function setTipoSepultura(state, action) {
  let currentRecord = state.currentRecord
  const tipoSepultura = action.payload
  currentRecord.tipoSepultura = tipoSepultura

  const empresa = storage.getCompany()
  const unidade = currentRecord.unidade
  if (utils.isIdValueIdValido(unidade) && utils.nonNull(empresa)) {
    const oidTipoSepulturaUrna = utils.getParameterValueFromUnitOid(
      'oidTipoSepulturaUrna',
      unidade.id,
      empresa.unidades
    )
    if (utils.nonNull(oidTipoSepulturaUrna) && oidTipoSepulturaUrna.value == tipoSepultura.id) {
      if (!currentRecord.numeroLugares) {
        currentRecord.numeroLugares = NUMERO_LUGARES_VAGAS_PADRAO_URNA
      }
      if (!currentRecord.numeroVagas) {
        currentRecord.numeroVagas = NUMERO_LUGARES_VAGAS_PADRAO_URNA
      }
    }
  }
  return Object.assign({}, state, { currentRecord: currentRecord })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [], inputs: [] }
}
