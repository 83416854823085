import { types } from './emprestimo.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'emprestimo',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Id',
      value: {
        field: 'oid',
        header: 'Id',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },

    {
      label: 'Contrato',
      value: {
        field: 'contrato_oid',
        header: 'Contrato',
        columnKey: 'contrato.oid',
        sortable: true,
        columnSortField: 'contrato.oid',
        relacionamento: ['contrato'],
        fieldJoin: 'oid',
        filter: true,
        visible: true,
      },
    },

    {
      label: 'Responsável',
      value: {
        field: 'responsavel_nome',
        header: 'Responsável',
        columnKey: 'responsavel.nome',
        sortable: true,
        columnSortField: 'responsavel.nome',
        relacionamento: ['responsavel'],
        fieldJoin: 'oid',
        filter: true,
        visible: true,
      },
    },

    {
      label: 'Responsável - Id',
      value: {
        field: 'responsavel_oid',
        header: 'Responsável - Id',
        columnKey: 'responsavel.oid',
        columnSortField: 'responsavel.oid',
        sortable: true,
        filter: true,
        relacionamento: ['responsavel'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'Data de emissão',
      value: {
        field: 'dataEmissao',
        header: 'Data de emissão',
        columnKey: 'dataEmissao',
        sortable: true,
        columnSortField: 'dataEmissao',
        filter: true,
        visible: true,
        fieldFormatter: 'formatarData',
      },
    },
    {
      label: 'Data solicitação',
      value: {
        field: 'dataSolicitacao',
        header: 'Data solicitação',
        columnKey: 'dataSolicitacao',
        sortable: true,
        columnSortField: 'dataSolicitacao',
        filter: true,
        visible: true,
        fieldFormatter: 'formatarData',
      },
    },
    {
      label: 'Data de vencimento',
      value: {
        field: 'dataVencimento',
        header: 'Data de vencimento',
        columnKey: 'dataVencimento',
        sortable: true,
        columnSortField: 'dataVencimento',
        filter: true,
        visible: true,
        fieldFormatter: 'formatarData',
      },
    },

    {
      label: 'Data de devolução',
      value: {
        field: 'dataDevolucao',
        header: 'Data de devolução',
        columnKey: 'dataDevolucao',
        sortable: true,
        columnSortField: 'dataDevolucao',
        filter: true,
        visible: true,
        fieldFormatter: 'formatarData',
      },
    },

    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        columnSortField: 'observacao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        columnKey: 'unidade.sigla',
        sortable: true,
        header: 'Unidade',
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
    {
      label: 'Equipamento',
      value: {
        field: 'produto_descricao',
        header: 'Equipamento',
        columnKey: 'produto_descricao',
        columnSortField: 'produto_descricao',
        sortable: true,
        filter: true,
        relacionamento: ['produto'],
        fieldJoin: 'descricao',
        visible: true,
      },
    }
  ],

  columnsLookup: [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
  ],

  colunasProduto: [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
    },
    {
      field: 'codigo',
      header: 'Código',
      columnKey: 'codigo',
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],

  columns: [],
  currentRecord: {
    oid: '',
    contrato: { id: '', value: '' },
    responsavel: { id: '', value: '' },
    dataEmissao: new Date(),
    dataSolicitacao: new Date(),
    dataVencimento: '',
    dataDevolucao: '',
    unidade: {},
    produto: { id: '', value: '' },
    observacao: '',
  },
  messages: null,
  usaListDto: false,

  lookupContrato: {
    visible: false,
    modal: true,
    header: 'Contrato',
  },
}

const reducer = new Reducer(initialState, types)

export function CRUDEmprestimoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('emprestimo')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)

    default:
      return state
  }
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
  }
}
