import React from 'react';
import ControleFinanceiroListPageContainer from './ControleFinanceiroListPageContainer';

export class ControleFinanceiroListPage extends React.Component{
    
    render(){
      return (
        <ControleFinanceiroListPageContainer {...this.props}/>
      )
    }
}

