import storage from './stoage.service'
import apiService from './api.service'
import _ from 'lodash'

const getStatusOrcamento = () => {
  return apiService.get('/idValue/statusOrcamento')
}

const getUnidades = () => {
  const oidEmpresa = storage.getCompany() ? storage.getCompany().oid : -1
  if (oidEmpresa < 1) {
    return []
  }
  return apiService.get(`/idValue/unidades/${oidEmpresa}`)
}

const getUnidadesUsuario = () => {
  return apiService.get(`/idValue/unidades/usuario`)
}

/**
 * @param oidUnidade
 * @returns {Promise<AxiosResponse<any>|*[]>}
 * retorno é {id: oid, value: descricao}
 */
const getTiposSepultura = async (oidUnidade) => {
  if (!oidUnidade || oidUnidade < 1) {
    return []
  }
  return apiService.get(`/idValue/tiposSepultura/${oidUnidade}`)
}

/**
 * @param oidsUnidades - array de oids
 * @returns {Promise<AxiosResponse<any>|*[]>}
 * retorno é {id: oid, value: descricao}
 */
const getTiposSepulturaByUnidades = async (oidsUnidades) => {
  if (_.isEmpty(oidsUnidades)) {
    return []
  }
  return apiService.post(`/idValue/recurso/tiposSepultura/unidades`, oidsUnidades)
}

/**
 * @param oidUnidade
 * @returns {Promise<AxiosResponse<any>|*[]>}
 * retorno é {id: oid, value: descricao, object: tipoSepulturaDTO}
 */
const getTiposSepulturaV2 = async (oidUnidade) => {
  return getRecurso('tiposSepultura', oidUnidade)
}

const getTiposProduto = () => {
  return apiService.get(`/idValue/tiposProduto`)
}

const getResponsaveis = async (oidContrato) => {
  if (oidContrato) {
    return apiService.get(`/idValue/responsaveis/${oidContrato}`)
  }
}

const getDependentes = async (oidContrato) => {
  if (oidContrato) {
    return apiService.get(`/idValue/dependentes/${oidContrato}`)
  }
}

const getResponsaveisComDependentes = async (oidContrato) => {
  if (oidContrato) {
    return apiService.get(`/idValue/responsaveisComDependentes/${oidContrato}`)
  }
}

const getStatusNotaEntrada = () => {
  return apiService.get('/idValue/statusNotaEntrada')
}

const getFormasPagamentoPlano = (oidUnidade = -1) => {
  return getRecurso('formasPagamentoPlano', oidUnidade)
}

const getFrequenciasFaturamento = (oidUnidade = -1) => {
  return getRecurso('frequenciasFaturamento', oidUnidade)
}

const getTipoCartaoCredito = (oidUnidade = -1) => {
  return getRecurso('tipoCartaoCredito', oidUnidade)
}
const getParentesco = (oidUnidade = -1) => {
  return getRecurso('parentesco', oidUnidade)
}
const getTipoTela = (oidUnidade = -1) => {
  return getRecurso('tipoTela', oidUnidade)
}

const getMapas = (oidUnidade = -1) => {
  return getRecurso('mapas', oidUnidade)
}

const getContasCorrentes = (oidUnidade = -1) => {
  return getRecurso('contasCorrentes', oidUnidade)
}

const getUsuarios = (oidUnidade = -1) => {
  return getRecurso('usuarios', oidUnidade)
}

const getGruposFinanceiros = async (oidsUnidades = []) => {
  let filtroUnidade = null
  if (!_.isEmpty(oidsUnidades)) {
    filtroUnidade = {
      campo: 'unidade',
      valor: oidsUnidades,
      tipoFiltro: 'ESTA_CONTIDO_EM',
    }
  }
  const params = {
    index: 0,
    max: 1000,
    projectionFields: ['oid', 'descricao'],
    filters: filtroUnidade ? [filtroUnidade] : [],
  }
  const path = '/lottustech/gruposfinanceiros/all'
  return apiService.post(path, params)
}

const getRecurso = (recurso, oidUnidade = -1) => {
  if (isNaN(parseInt(oidUnidade))) {
    oidUnidade = -1
  }
  return apiService.get(`/idValue/recurso/${recurso}/${oidUnidade}`)
}

export default {
  getStatusOrcamento,
  getUnidades,
  getUnidadesUsuario,
  getTiposSepultura,
  getTiposSepulturaByUnidades,
  getTiposSepulturaV2,
  getTiposProduto,
  getResponsaveis,
  getDependentes,
  getResponsaveisComDependentes,
  getStatusNotaEntrada,
  getFormasPagamentoPlano,
  getFrequenciasFaturamento,
  getTipoCartaoCredito,
  getParentesco,
  getTipoTela,
  getMapas,
  getContasCorrentes,
  getGruposFinanceiros,
  getUsuarios,
}
