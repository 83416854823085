import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { Column } from 'primereact/column'
import { icons, routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { PessoaJuridicaList } from './PessoaJuridicaList'
import { actions } from '../pessoaJuridica.actions'
import utils from '../../../utils/index'
import { InputText } from 'primereact/inputtext'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import '../../../index.scss'
import LtsLookupCategoria from '../../../components/lottus/lts-lookup-categoria'
import { Button } from 'primereact/button'
import _ from 'lodash'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudPessoaJuridicaState.showHideColumns,
    messages: state.crudPessoaJuridicaState.messages,
    columns: state.crudPessoaJuridicaState.columns,
    records: state.crudPessoaJuridicaState.records,
    first: state.crudPessoaJuridicaState.index,
    rows: state.crudPessoaJuridicaState.max,
    totalRecords: state.crudPessoaJuridicaState.count,
    sortField: state.crudPessoaJuridicaState.sortField,
    order: state.crudPessoaJuridicaState.sortOrder,
    selectedFilters: state.crudPessoaJuridicaState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      if (target.name === 'numeroDocumento') {
        target.value = target.value.replace(/\D+/g, '')
      }
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/pessoasjuridicas/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    pesquisaAvancada: (state) => {
      if (state.filtroCategoria && state.filtroCategoria.oid) {
        let filtroCategoria = state.filtroCategoria
        let novoFiltro = {
          campo: 'oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['categorias'],
          valor: filtroCategoria.oid,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/pessoasjuridicas/all`))
      }, 400)
    },
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    clearCurrentRecord: () => dispatch(actions.clearCurrentRecord()),
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class PessoaJuridicaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showFilter = false
    this.state = {
      dadosFavorito: null,
      filtroCategoria: false,
      favorito: 0,
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }
  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA_JURIDICA}/novo`)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
    this.props.clearCurrentRecord()
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.clearSelectedFilters()
    this.props.pesquisaAvancada(this.state)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroCategoria()}
          <br />
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  buildFiltroCategoria = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <div className="p-col-4" style={{ padding: '6px', paddingTop: '0px' }}>
          <LtsLookupCategoria
            label={utils.getMessage(this.props.messages, 'pessoajuridica.label.categoria')}
            value={this.state.filtroCategoria}
            editHidden={true}
            field={'descricao'}
            multiple={false}
            onChange={(e) =>
              this.setState({ filtroCategoria: { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'descricao', '') } })
            }
          />
        </div>
      </div>
    )
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'pessoajuridica.label.empresas')}
      />
    )
  }

  cnpjTemplate = (rowData, column) => {
    return (
      <Link to={`${routes.PATH_PESSOA_JURIDICA}/${rowData.oid}`}>
        {rowData.numeroDocumento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
      </Link>
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'numeroDocumento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.cnpjTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-nr-documento-body-width"
            filterHeaderClassName="column-nr-documento-filter-header-width"
            headerClassName="column-nr-documento-header-width"
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <PessoaJuridicaList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaJuridicaListPageContainer)
