import { types } from "./consultafalecido.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "consultaFalecido",
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: false,
        filter: false,
        columnSortField: "oid",
        visible: false
      }
    },
    {
      label: "Unidade",
      value: {
        field: "nomeUnidade",
        header: "Unidade",
        columnKey: "nomeUnidade",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Localização",
      value: {
        field: "localizacao",
        header: "Localização",
        columnKey: "localizacao",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Falecido",
      value: {
        field: "falecido",
        header: "Falecido",
        columnKey: "falecido",
        sortable: true,
        filter: true
      }
    },
    {
      label: "Data Nascimento",
      value: {
        field: "dataNascimento",
        header: "Data Nascimento",
        columnKey: "dataNascimento",
        fieldFormatter: "formatarData",
        visible: true,
        filter: true
      }
    }
  ],
  columns: [],
  currentRecord: {},
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDConsultaFalecidoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("consultafalecido");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}
