import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, icons } from '../../../constants'
import utils from '../../../utils/index'
import storage from '../../../services/stoage.service'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../nfse.actions'
import { NfseList } from './NfseList'
import '../../../index.scss'

const mapStateToProps = (state) => {
  let perfis = []
  if (state.crudPerfilState.records) {
    perfis = state.crudPerfilState.records.filter((perfil) => {
      let listIdPerfis = state.crudUsuarioState.currentRecord.perfis.map((perfilAcao) => perfilAcao.oid)
      return !listIdPerfis.includes(perfil.oid)
    })
  }

  let tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))
  return {
    tiposFiltros,
    perfis: perfis,
    showHideColumns: state.crudNfseState.showHideColumns,
    messages: state.crudNfseState.messages,
    columns: state.crudNfseState.columns,
    records: state.crudNfseState.records,
    first: state.crudNfseState.index,
    rows: state.crudNfseState.max,
    totalRecords: state.crudNfseState.count,
    totalRecordsDistinct: state.crudNfseState.countDistinct,
    sortField: state.crudNfseState.sortField,
    order: state.crudNfseState.sortOrder,
    selectedFilters: state.crudNfseState.selectedFilters,
    booleanTypesStatus: [
      { label: 'TODOS', value: '' },
      { label: 'CRITICADA', value: 3 },
      { label: 'AUTORIZADA', value: 6 },
      { label: 'CANCELADA', value: 9 },
      { label: 'REJEITADA', value: 10 },
    ],
    booleanFilter: state.crudNfseState.booleanFilter,
    selectedRecords: state.crudNfseState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros enum
      if (['statusNFSe'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/notasfiscaisservico/all`))
      }, 400)
    },
    pesquisaAvancada: (state) => {
      if (state.filtroEmissao.tipoFiltro) {
        let filtroEmissao = state.filtroEmissao
        let novoFiltro = {
          campo: 'dataHoraEmissaoRPS',
          valor: filtroEmissao.dataInicial,
          valorComplementar: filtroEmissao.dataFinal,
          tipoFiltro: filtroEmissao.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/notasfiscaisservico/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    salvarState: (state, filters) => {
      dispatch(actions.saveState(state, filters))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    downloadDanf: (oidNFSe) => {
      dispatch(actions.downloadDanfSe(oidNFSe))
    },
  }
}

class NfseListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.visaoRef = React.createRef()
    this.showHideColumns = []
    this.showDialogVisao = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      filtroEmissao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state)
  }

  salvarState = (dados, state) => {
    let arrayColumns = this.showHideColumns.map((col) => {
      return col.value
    })
    arrayColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })
    state['columns'] = arrayColumns
    state['menu'] = { id: 1, value: this.props.history.location.pathname }
    this.props.salvarState(state, dados.filters)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }
  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  downloadDanfes = () => {
    let notasSelecionadas = this.props.selectedRecords
    if (utils.isArrayNotEmpty(notasSelecionadas)) {
      notasSelecionadas.forEach((nota) => {
        this.props.downloadDanf(nota.oid)
      })
    }
  }

  verificarHabilitarBotaoDownloadPDF = () => {
    if (utils.isArrayNullOrEmpty(this.props.selectedRecords)) {
      return true
    }
    return false
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button
          disabled={this.verificarHabilitarBotaoDownloadPDF()}
          onClick={(e) => this.downloadDanfes()}
          label={utils.getMessage(this.props.messages, 'nfse.label.nfse')}
          style={{ marginRight: '.25em' }}
          icon={icons.DOWNLOAD}
        />
      </div>
    )
  }

  changeTipoFiltro = (filtro, valor) => {
    let filtroAtual = filtro
    filtroAtual.tipoFiltro = valor
    filtroAtual.dataFinal = null
    filtroAtual.dataInicial = null
    filtroAtual.mostrarDataInicial = valor.dataInicial
    filtroAtual.mostrarDataFinal = valor.dataFinal
  }

  changeDataFiltro = (filtro, campo, valor) => {
    let filtroAtual = filtro
    filtroAtual[campo] = valor
  }

  buildFiltroDataEmissao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <label className="label"> {utils.getMessage(this.props.messages, 'nfse.label.emissao')}</label>
        <div className="p-col-2">
          <Dropdown
            autoWidth={false}
            value={this.state.filtroEmissao.tipoFiltro}
            options={this.props.tiposFiltros}
            onChange={(e) => this.changeTipoFiltro(this.state.filtroEmissao, e.value)}
            optionLabel="label"
            optionValue={(v) => v}
            name="tipo"
          />
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroEmissao.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroEmissao.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1970')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroEmissao, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroEmissao.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroEmissao.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1970')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroEmissao, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroDataEmissao()}
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title={utils.getMessage(this.props.messages, 'nfse.label.nfses')} />
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'lancamentos_oid' && rowData.lancamentos_oid) {
      return (
        <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.lancamentos_oid}`}>{rowData.lancamentos_oid}</Link>
      )
    }
    return utils.format(rowData, column)
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%', minWidth: '10px' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypesStatus}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      if (column.field === 'statusNFSe') {
        return <div>{rowData[column.field].replace('_', ' ')}</div>
      }
    }
    return null
  }

  oidTemplate = (rowData) => {
    return <Link to={`${routes.PATH_NFSE}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'statusNFSe') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
            bodyClassName="column-status-body-width"
            filterHeaderClassName="column-status-filter-header-width"
            headerClassName="column-status-header-width"
          />
        )
      }
      if (col.field === 'razaoSocialTomadorServico') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            body={listPageUtils.bodyDivWithTooltip}
            bodyClassName="column-nome-body-width"
            filterHeaderClassName="column-nome-filter-header-width"
            headerClassName="column-nome-header-width"
          />
        )
      }
      if (col.field === 'valorServico') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-valor-body-width"
            filterHeaderClassName="column-valor-filter-header-width"
            headerClassName="column-valor-header-width"
          />
        )
      }
      if (col.field === 'dataHoraEmissaoRPS') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      }
      if (col.field === 'numeroRPS' || col.field === 'numeroNFSe' || col.field === 'cnae') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.fieldFormatter || col.field === 'lancamentos_oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} style={{ width: '100px'}} />
    })
  }
  buildContent = () => {
    return (
      <NfseList
        {...this.props}
        onSelectionChange={this.onSelectionChange}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NfseListPageContainer)
