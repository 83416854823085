import React from 'react'
import utils from '../../utils'
import { Calendar } from 'primereact/calendar'

export default function LtsCalendar(props) {
  const onChange = (event) => {
    const target = event.target
    props.onChange(target.value)
  }

  const resolveName = () => {
    return props.name || 'data'
  }

  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label || 'Data'}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <Calendar
          {...props}
          className={props.className}
          value={utils.getDateFromNumber(props.value)}
          onChange={onChange}
          yearRange={utils.getYearRangeFromDate('1800')}
          name={resolveName()}
          showButtonBar={true}
          view={props.view || 'date'}
          dateFormat={props.dateFormat}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
