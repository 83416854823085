import React from 'react';

import GrupoFormPageContainer from './GrupoFormPageContainer';

export class GrupoFormPage extends React.Component{

    
    render(){
        return (
           <GrupoFormPageContainer {...this.props} />
        )
    }
}
