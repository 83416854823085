import React from 'react';

import PessoaFisicaFormPageContainer from './PessoaFisicaFormPageContainer';

export class PessoaFisicaFormPage extends React.Component{

    
    render(){
        return (
           <PessoaFisicaFormPageContainer {...this.props} />
        )
    }
}
