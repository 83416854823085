import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SplitButton } from 'primereact/splitbutton'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { routes, icons, enums, oidBotoesDinamicos, acoes, mascaras } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { ContratoList } from './ContratoList'
import { actions } from '../contrato.actions'
import { actions as pessoaFisicaActions } from '../../pessoaFisica/pessoaFisica.actions'
import { LookupField } from '../../../components/lookup/LookupField'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import utils, { isTipoFiltroSelecionado, isTipoFiltroSelecionadoComplementar } from '../../../utils/index'
import { Filtro } from '../../../utils/Filtro.js'
import { getTemplateDropDownPessoa } from '../../../utils/Template'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import storage from '../../../services/stoage.service'
import { InputNumber } from 'primereact/inputnumber'
import { MultiSelect } from 'primereact/multiselect'
import '../../../index.scss'
import _ from 'lodash'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'
import LtsLookupItemLancamento from '../../../components/lottus/lts-lookup-item-lancamento'
import { Dialog } from 'primereact/dialog'
import LtsCurrency from '../../../components/lottus/lts-currency'

const mapStateToProps = (state) => {
  let messages = state.crudContratoState.messages
  const lookupResponsavelBase = {
    records: state.crudPessoaFisicaState.records,
    columns: state.crudPessoaFisicaState.columnsLookup
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} showFilterMenu={false} />),
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    editDisabled: true,
  }

  let lookupResponsavel = Object.assign({}, state.crudContratoState.lookupResponsavel, lookupResponsavelBase)
  let showHideColumns = state.crudContratoState.showHideColumns
  showHideColumns = showHideColumns.filter((col) => {
    if (!col.label || col.label === '') {
      let field = col.value.field
      let index = field.indexOf('_')
      if (index > -1) {
        index++ //para nao trazer o '_'
        field = field.substring(index)
      }

      const label = utils.getMessage(messages, `contrato.label.${field}`)
      col.label = label
      return (col.value.header = label)
    }
    return col
  })
  const tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))

  const tiposFiltrosContrato = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  const tiposFiltrosFormaPagamentoPlano = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  const tiposFiltrosFrequenciaPagamentoPlano = utils.getFiltersIgualADiferenteDe(storage.getAppLabels('filtros'))
  let unidades = []
  const currentCompany = storage.getCompany()
  const currentUnit = storage.getUnit()
  const unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(currentCompany)
  unidades = unidades.concat(unidadesEmpresa)
  const utilizaAssinaturaDigitalDocumentos = utils.isUtilizaAssinaturaDigitalDocumentos(currentCompany, currentUnit)
  const relatotiosDocumentos = state.crudContratoState.relatotiosDocumentos
  const isCustomRoute = storage.isCustomRoute2(state.appState.appCustomRoutes)
  return {
    isCustomRoute,
    filtrosTiposSepultura: state.crudContratoState.filtrosTiposSepultura,
    filtrosFormaPagamentoPlano: state.crudContratoState.filtrosFormaPagamentoPlano,
    filtrosFrequenciaPagamentoPlano: state.crudContratoState.filtrosFrequenciaFaturamentoPlano,
    tiposFiltros,
    tiposFiltrosContrato,
    tiposFiltrosFormaPagamentoPlano,
    tiposFiltrosFrequenciaPagamentoPlano,
    lookupResponsavel,
    messages: messages,
    columns: state.crudContratoState.columns,
    records: state.crudContratoState.records,
    first: state.crudContratoState.index,
    rows: state.crudContratoState.max,
    totalRecords: state.crudContratoState.count,
    totalRecordsDistinct: state.crudContratoState.countDistinct,
    sortField: state.crudContratoState.sortField,
    order: state.crudContratoState.sortOrder,
    selectedFilters: state.crudContratoState.selectedFilters,
    filterValues: state.crudContratoState.filterValues,
    showHideColumns: showHideColumns,
    selectedRecords: state.crudContratoState.selectedRecords,
    idTela: state.crudContratoState.id,
    botoesDinamicos: state.crudContratoState.botoesDinamicos,
    booleanFilter: state.crudContratoState.booleanFilter,
    perfis: state.crudPerfilState.records,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'SIM', value: true },
      { label: 'NÃO', value: false },
    ],
    unidades,
    utilizaAssinaturaDigitalDocumentos,
    relatotiosDocumentos,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros boolean
      if (target.name === 'ativo') {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      let relacionamento = column.relacionamento
      if (!relacionamento) {
        relacionamento = column.relationships
      }
      dispatch(actions.setSelectedFilters(campo, target.value, relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/contratos/all`))
      }, 400)
    },
    setSelectedRecords: (e) => dispatch(actions.setSelectedRecords(e)),
    load: () => dispatch(actions.load()),
    loadStateData: () => dispatch(actions.loadStateData2()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),

    pesquisaAvancada: (state, props) => {
      dispatch(actions.clearSelectedFilters())
      const valoresFiltros = props.filterValues

      const valorFiltroResponsavel = _.get(valoresFiltros, ['responsavel', 'valor'])
      if (valorFiltroResponsavel && utils.nonNull(valorFiltroResponsavel.id)) {
        const responsavel = valorFiltroResponsavel
        const novoFiltro = {
          campo: 'responsavel.nome',
          valor: responsavel.value,
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['responsaveis'],
          chaveComponente: 'responsavel',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const valorFiltroVendedor = _.get(valoresFiltros, ['vendedor', 'valor'])
      if (valorFiltroVendedor && utils.nonNull(valorFiltroVendedor.oid)) {
        const vendedor = valorFiltroVendedor
        const novoFiltro = {
          campo: 'vendedor.oid',
          valor: vendedor.oid,
          tipoFiltro: 'IGUAL_A',
          chaveComponente: 'vendedor',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const valorFiltroAuxiliar = _.get(valoresFiltros, ['auxiliar', 'valor'])
      if (valorFiltroAuxiliar && utils.nonNull(valorFiltroAuxiliar.oid)) {
        const auxiliar = valorFiltroAuxiliar
        const novoFiltro = {
          campo: 'auxiliar.oid',
          valor: auxiliar.oid,
          tipoFiltro: 'IGUAL_A',
          chaveComponente: 'auxiliar',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const valorFiltroDependente = _.get(valoresFiltros, ['dependente', 'valor'])
      if (valorFiltroDependente && utils.nonNull(valorFiltroDependente.oid)) {
        const novoFiltro = {
          campo: 'dependente.oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['dependentes'],
          valor: valorFiltroDependente.oid,
          chaveComponente: 'dependente',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const tipoFiltroData = _.get(valoresFiltros, ['data', 'tipoFiltro'])
      if (tipoFiltroData && tipoFiltroData?.value !== null) {
        const novoFiltroData = {
          campo: 'data',
          valor: _.get(valoresFiltros, ['data', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['data', 'valorComplementar']),
          tipoFiltro: tipoFiltroData.value,
          chaveComponente: 'data',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroData))
      }

      const tipoFiltroDataTermino = _.get(valoresFiltros, ['dataTermino', 'tipoFiltro'])
      if (tipoFiltroDataTermino && tipoFiltroDataTermino?.value !== null) {
        const novoFiltroDataTermino = {
          campo: 'dataTermino',
          valor: _.get(valoresFiltros, ['dataTermino', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['dataTermino', 'valorComplementar']),
          tipoFiltro: tipoFiltroDataTermino?.value,
          chaveComponente: 'dataTermino',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroDataTermino))
      }

      const tipoFiltroDataProximaRemessaCompetencia = _.get(valoresFiltros, [
        'dataProximaRemessaCompetencia',
        'tipoFiltro',
      ])
      if (tipoFiltroDataProximaRemessaCompetencia && tipoFiltroDataProximaRemessaCompetencia?.value !== null) {
        const novoFiltroDataProximaRemessaCompetencia = {
          campo: 'dataProximaRemessaCompetencia',
          valor: _.get(valoresFiltros, ['dataProximaRemessaCompetencia', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['dataProximaRemessaCompetencia', 'valorComplementar']),
          tipoFiltro: tipoFiltroDataProximaRemessaCompetencia?.value,
          chaveComponente: 'dataProximaRemessaCompetencia',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroDataProximaRemessaCompetencia))
      }

      const tipoFiltroDataFalecimento = _.get(valoresFiltros, ['dataFalecimento', 'tipoFiltro'])
      if (tipoFiltroDataFalecimento && tipoFiltroDataFalecimento?.value !== null) {
        const novoFiltroData = {
          campo: 'falecido_dataFalecimento',
          valor: _.get(valoresFiltros, ['dataFalecimento', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['dataFalecimento', 'valorComplementar']),
          tipoFiltro: tipoFiltroDataFalecimento?.value,
          chaveComponente: 'dataFalecimento',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroData))
      }

      const tipoFiltroDataPrevisaoExumacao = _.get(valoresFiltros, ['dataPrevisaoExumacao', 'tipoFiltro'])
      if (tipoFiltroDataPrevisaoExumacao && tipoFiltroDataPrevisaoExumacao?.value !== null) {
        const novoFiltroData = {
          campo: 'falecidos_dataPrevisaoExumacao',
          valor: _.get(valoresFiltros, ['dataPrevisaoExumacao', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['dataPrevisaoExumacao', 'valorComplementar']),
          tipoFiltro: tipoFiltroDataPrevisaoExumacao.value,
          chaveComponente: 'dataPrevisaoExumacao',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroData))
      }

      const tipoFiltroDataExumacao = _.get(valoresFiltros, ['dataExumacao', 'tipoFiltro'])
      if (tipoFiltroDataExumacao && tipoFiltroDataExumacao?.value !== null) {
        const novoFiltroData = {
          campo: 'falecidos_dataExumacao',
          valor: _.get(valoresFiltros, ['dataExumacao', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['dataExumacao', 'valorComplementar']),
          tipoFiltro: tipoFiltroDataExumacao.value,
          chaveComponente: 'dataExumacao',
        }
        dispatch(actions.setFiltroAvancado(novoFiltroData))
      }

      const tipoFiltroConspaga = _.get(valoresFiltros, ['conspaga', 'tipoFiltro'])
      if (tipoFiltroConspaga && tipoFiltroConspaga?.value !== null) {
        const filtro = {
          campo: 'conspaga',
          valor: _.get(valoresFiltros, ['conspaga', 'valor']),
          valorComplementar: _.get(valoresFiltros, ['conspaga', 'valorComplementar']),
          tipoFiltro: tipoFiltroConspaga?.value,
          chaveComponente: 'conspaga',
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      const valorFiltroUnidade = _.get(valoresFiltros, ['unidade', 'valor'])
      if (utils.isArrayNotEmpty(valorFiltroUnidade)) {
        let tipoFiltro = 'ESTA_CONTIDO_EM'
        let valor = valorFiltroUnidade.map((f) => f.label)
        if (valorFiltroUnidade.length === 1) {
          tipoFiltro = 'IGUAL_A'
          valor = valorFiltroUnidade[0].label
        }
        const filtro = {
          campo: 'unidade_sigla',
          valor: valor,
          tipoFiltro,
          chaveComponente: 'unidade',
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      const tipoFiltroTipoSepultura = _.get(valoresFiltros, ['tipoSepultura', 'tipoFiltro'])
      if (tipoFiltroTipoSepultura && tipoFiltroTipoSepultura?.value !== null) {
        const filtro = {
          campo: 'tipoSepultura_descricao',
          valor: _.get(valoresFiltros, ['tipoSepultura', 'valor', 'value']),
          tipoFiltro: tipoFiltroTipoSepultura?.value,
          chaveComponente: 'tipoSepultura',
        }
        dispatch(actions.setFiltroAvancado(filtro))
      }

      const filtroMarcador = _.get(valoresFiltros, ['marcador', 'valor'])
      if (filtroMarcador && filtroMarcador.oid) {
        let novoFiltro = {
          campo: 'oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['marcadores'],
          valor: filtroMarcador.oid,
          chaveComponente: 'marcador',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const filtroFalecidoMarcador = _.get(valoresFiltros, ['marcadorFalecido', 'valor'])
      if (filtroFalecidoMarcador && filtroFalecidoMarcador.oid) {
        let novoFiltro = {
          campo: 'oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['falecidos', 'marcadores'],
          valor: filtroFalecidoMarcador.oid,
          chaveComponente: 'marcadorFalecido',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const filtroTipoCobertura = _.get(valoresFiltros, ['tipoCobertura', 'valor'])
      if (filtroTipoCobertura && filtroTipoCobertura.oidTipo) {
        const tipoRelacionamento = filtroTipoCobertura.tipo === 'TAXA' ? 'taxa' : 'produto'
        let novoFiltro = {
          campo: 'oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['plano', 'coberturas', 'itemLancamento', tipoRelacionamento],
          valor: filtroTipoCobertura.oidTipo,
          chaveComponente: 'tipoCobertura',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const tipoFiltroFormaPagamentoPlano = _.get(valoresFiltros, ['formaPagamentoPlano', 'tipoFiltro'])
      const valorFiltroFormaPagamentoPlano = _.get(valoresFiltros, ['formaPagamentoPlano', 'valor'])
      const idFormaPagamentoPlano = _.get(valorFiltroFormaPagamentoPlano, 'id', null)
      if (idFormaPagamentoPlano) {
        const novoFiltro = {
          campo: 'formaPagamento',
          valor: idFormaPagamentoPlano,
          tipoFiltro: tipoFiltroFormaPagamentoPlano?.value,
          relacionamento: ['plano'],
          chaveComponente: 'formaPagamento',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      const tipoFiltroFrequenciaPagamentoPlano = _.get(valoresFiltros, ['frequenciaPagamentoPlano', 'tipoFiltro'])
      const valorFiltroFrequenciaPagamentoPlano = _.get(valoresFiltros, ['frequenciaPagamentoPlano', 'valor'])
      const idFrequenciaPagamentoPlano = _.get(valorFiltroFrequenciaPagamentoPlano, 'id', null)
      if (idFrequenciaPagamentoPlano) {
        const novoFiltro = {
          campo: 'frequenciaFaturamento',
          valor: idFrequenciaPagamentoPlano,
          tipoFiltro: tipoFiltroFrequenciaPagamentoPlano?.value,
          relacionamento: ['plano'],
          chaveComponente: 'frequenciaFaturamento',
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/contratos/all`))
      }, 400)
    },
    lookupResponsavelPaginate: (paginationParams) => dispatch(pessoaFisicaActions.paginate(paginationParams)),
    lookupResponsavelSortOrder: (sortParams) => dispatch(pessoaFisicaActions.sortOrder(sortParams)),
    lookupResponsavelApplyFilter: (filterParams) => {
      dispatch(pessoaFisicaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaFisicaActions.applyFilter(filterParams))
    },
    lookupResponsavelLoad: () => dispatch(pessoaFisicaActions.load()),
    setLookupResponsavelVisible: (visible) => dispatch(actions.setLookupResponsavelVisible(visible)),
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    clearSelectedFiltersResponsavel: () => dispatch(pessoaFisicaActions.setRecords([])),
    exportarDadosCSV: () => dispatch(actions.exportCSV()),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    gerarCarteirinhaLote: (oidsContratos) => dispatch(actions.gerarCarteirinhaLote(oidsContratos)),
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.LISTAGEM)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
    salvarState: (state, filters) => dispatch(actions.saveState(state, filters)),
    removerState: (state) => dispatch(actions.removeState(state)),
    onClickBotaoDinamico: (listId, botao, params) => dispatch(actions.executarFuncaoDinamica(listId, botao, params)),
    loadFiltroTiposSepulturas: (oidsUnidades) => dispatch(actions.loadFiltroTiposSepulturas(oidsUnidades)),
    loadFiltroFormaPagamentoPlano: () => dispatch(actions.loadFiltroFormaPagamentoPlano()),
    loadFiltroFrequenciaFaturamentoPlano: () => dispatch(actions.loadFiltroFrequenciaFaturamentoPlano()),
    loadRelatoriosDocumentos: () => dispatch(actions.loadRelatoriosDocumentos()),
    requestExecutarRelatorioDocumentos: ({ oidDocumento, params }) =>
      dispatch(actions.requestExecutarRelatorioDocumentos({ oidDocumento, params })),
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
    reajustarContratos: (params) => {
      dispatch(actions.reajustarContratos(params))
    },
  }
}

class ContratoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.orderedColumns = []
    this.showFilter = false
    this.state = {
      showDialogVisao: false,
      showDialogRemoveVisao: false,
      dadosFavorito: null,
      favorito: 0,
      filtroAvancado: {},
      showReajustar: false,
      percentualReajuste: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.acaoRelatorioCarteirinha =
      utils.nonNull(storage.getAcao(acoes.ACAO_RELATORIO_CARTEIRINHA)) &&
      storage.getAcao(acoes.ACAO_RELATORIO_CARTEIRINHA)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state, this.props)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CONTRATO}/novo`)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
    setTimeout(() => {
      const filtroUnidades = _.get(this.props.filterValues, ['unidade', 'valor'], [])
      if (utils.isArrayNotEmpty(filtroUnidades)) {
        const oidsUnidades = filtroUnidades.map((f) => f.id)
        this.props.loadFiltroTiposSepulturas(oidsUnidades)
      } else {
        this.props.loadFiltroTiposSepulturas([])
      }

      this.props.loadFiltroFormaPagamentoPlano()
      this.props.loadFiltroFrequenciaFaturamentoPlano()

      const filtroAvancado = {}
      for (const key of Object.keys(this.props.filterValues)) {
        filtroAvancado[key] = this.props.filterValues[key]
      }
      this.setState({ filtroAvancado: filtroAvancado })
    }, 500)
    if (this.props.utilizaAssinaturaDigitalDocumentos) {
      setTimeout(() => {
        this.props.loadRelatoriosDocumentos()
      }, 500)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  showFormVisao = () => {
    this.setState({ showDialogVisao: true })
  }

  showDialogRemoveVisao = () => {
    this.setState({ showDialogRemoveVisao: true })
  }

  hideDialogRemoverVisao = () => {
    this.setState({ showDialogRemoveVisao: false })
  }

  removerState = () => {
    this.props.removerState()
  }

  defineDynamicFilters = (state, onChangeData, onChangeCheckBox) => {
    return <div></div>
  }

  salvarState = (dados, state) => {
    let orderedColumns = this.orderedColumns
    let columns = this.props.columns
    let arrayColumns = columns.filter((c) => c.visible)

    arrayColumns.forEach((col) => {
      let coluna = orderedColumns.find((co) => co.columnKey === col.columnKey)
      if (!coluna) {
        orderedColumns.push(col)
      }
    })

    orderedColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })

    dados.columns = orderedColumns
    dados.menu = { id: 1, value: this.props.history.location.pathname }
    this.props.salvarState(dados, dados.filters)
  }

  getParamsBotaoDinamico = () => {
    let params = {}
    let records = this.props.selectedRecords
    if (records && records.length > 0) {
      records.forEach((record) => {
        params.oidResponsavel = record.responsavel_oid
        params.oidFalecido = record.falecido_oid
        params.oidLocalizacao = record.localizacao_oid
      })
    }
    return params
  }
  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  lookupResponsavelOnPage = (e) => {
    this.props.lookupResponsavelPaginate({
      max: e.rows,
      index: e.first,
    })
  }

  lookupResponsavelOnSort = (e) => {
    this.props.lookupResponsavelSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }

  lookupResponsavelOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      if (e.filters[key].value !== null) {
        let filter = {
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupResponsavelApplyFilter(filter)
      }
    })
    this.props.lookupResponsavelLoad()
    if (keys.length === 0) {
      this.props.clearSelectedFiltersResponsavel()
      this.props.lookupResponsavelLoad()
    }
  }

  lookupResponsavelOnClick = (e) => {
    this.props.lookupResponsavelLoad()
    this.props.setLookupResponsavelVisible(true)
  }

  lookupResponsavelOnHide = (e) => {
    this.props.setLookupResponsavelVisible(false)
  }

  lookupResponsavelOnConfirm = (e) => {
    let responsavel = {}
    responsavel.id = e.selection.oid
    responsavel.value = e.selection.nome

    _.set(this.props.filterValues, 'responsavel.valor', responsavel)
    _.set(this.state.filtroAvancado, 'responsavel.valor', responsavel)
    this.setState({ filtroAvancado: this.state.filtroAvancado })

    this.props.setLookupResponsavelVisible(false)
  }

  lookupResponsavelOnCancel = (e) => {
    this.props.setLookupResponsavelVisible(false)
  }

  lookupResponsavelOnEdit = () => {
    if (this.props.record.oidResponsavel) {
      this.props.history.push(`${routes.PATH_PESSOA_FISICA}/${this.props.record.oidResponsavel}`)
    }
  }

  lookupResponsavelOnComplete = (e) => {}

  lookupResponsavelOnInputFilter = (e) => {
    let newValue
    const value = e.value
    if (typeof value === 'string') {
      newValue = value
    } else {
      newValue = value.nome
    }

    _.set(this.props.filterValues, 'responsavel.valor', newValue)
    _.set(this.state.filtroAvancado, 'responsavel.valor', newValue)
    this.setState({ filtroAvancado: this.state.filtroAvancado })
  }

  lookupResponsavelOnKeyPress = (e) => {
    if (utils.nonNull(_.get(this.state.filtroAvancado, 'responsavel.valor.value'))) {
      const filtroNome = new Filtro('nome', _.get(this.state.filtroAvancado, 'responsavel.valor.value', ''), 'CONTEM')
      const filtroCpf = new Filtro(
        'numeroDocumento',
        _.get(this.state.filtroAvancado, 'responsavel.valor.value', ''),
        'CONTEM'
      )
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupResponsavelApplyFilter(filtroOu)
      this.props.lookupResponsavelLoad()
    }
  }

  changeDataFiltro = (campo, valor) => {
    _.set(this.props.filterValues, campo, valor)
  }

  buildFiltroResponsavel = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-4">
          <LookupField
            label={utils.getMessage(this.props.messages, 'contrato.label.responsavel')}
            value={_.get(this.state.filtroAvancado, ['responsavel', 'valor', 'value'])}
            appendTo={document.body}
            name="responsavel"
            field="nome"
            itemTemplate={getTemplateDropDownPessoa}
            onKeyPress={this.lookupResponsavelOnKeyPress}
            onComplete={this.lookupResponsavelOnComplete}
            onInputFilter={this.lookupResponsavelOnInputFilter}
            editDisabled={this.props.lookupResponsavel.editDisabled}
            onEditField={this.lookupResponsavelOnEdit}
            onClick={this.lookupResponsavelOnClick}
            visible={this.props.lookupResponsavel.visible}
            modal={this.props.lookupResponsavel.modal}
            header={this.props.lookupResponsavel.header}
            onHide={this.lookupResponsavelOnHide}
            onConfirm={this.lookupResponsavelOnConfirm}
            onCancel={this.lookupResponsavelOnCancel}
            records={this.props.lookupResponsavel.records}
            columns={this.props.lookupResponsavel.columns}
            totalRecords={this.props.lookupResponsavel.totalRecords}
            sortOrder={this.props.lookupResponsavel.order}
            sortField={this.props.lookupResponsavel.sortField}
            first={this.props.lookupResponsavel.first}
            rows={this.props.lookupResponsavel.rows}
            onPage={this.lookupResponsavelOnPage}
            onFilter={this.lookupResponsavelOnFilter}
            onSort={this.lookupResponsavelOnSort}
          />
        </div>
      </div>
    )
  }

  resolveValueFiltroDependente = () => {
    const dependente = _.get(this.state.filtroAvancado, ['dependente', 'valor'])
    if (dependente?.hasOwnProperty('oid')) {
      return {
        id: _.get(this.state.filtroAvancado, ['dependente', 'valor', 'oid'], null),
        value: _.get(this.state.filtroAvancado, ['dependente', 'valor', 'value'], null),
      }
    }
    return {
      id: null,
      value: null,
    }
  }

  resolveValueFiltroVendedor = () => {
    const vendedor = _.get(this.state.filtroAvancado, ['vendedor', 'valor'], {})
    if (vendedor?.hasOwnProperty('oid')) {
      return {
        id: _.get(this.state.filtroAvancado, ['vendedor', 'valor', 'oid'], null),
        value: _.get(this.state.filtroAvancado, ['vendedor', 'valor', 'value'], null),
      }
    }
    return {
      id: null,
      value: null,
    }
  }

  resolveValueFiltroAuxiliar = () => {
    if (_.get(this.state.filtroAvancado, ['auxiliar', 'valor'], {}).hasOwnProperty('oid')) {
      return {
        id: _.get(this.state.filtroAvancado, ['auxiliar', 'valor', 'oid'], null),
        value: _.get(this.state.filtroAvancado, ['auxiliar', 'valor', 'value'], null),
      }
    }
    return {
      id: null,
      value: null,
    }
  }

  buildFiltroDependente = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-4">
          <LtsLookupPessoa
            name={'dependente'}
            field={'nome'}
            label={utils.getMessage(this.props.messages, 'contrato.label.dependente')}
            value={this.resolveValueFiltroDependente()}
            oidRecord={_.get(this.props, 'dependente.id', '')}
            history={this.props.history}
            editDisabled={true}
            onChange={(e) => {
              const data = { oid: _.get(e, 'oid', ''), value: _.get(e, 'nome', '') }
              _.set(this.props.filterValues, 'dependente.valor', data)
              _.set(this.state.filtroAvancado, 'dependente.valor', data)
              this.setState({ filtroAvancado: this.state.filtroAvancado })
            }}
          />
        </div>
      </div>
    )
  }

  buildFiltroVendedor = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-4">
          <LtsLookupPessoa
            name={'vendedor'}
            field={'nome'}
            label={utils.getMessage(this.props.messages, 'contrato.label.vendedor')}
            value={this.resolveValueFiltroVendedor()}
            oidRecord={_.get(this.props, 'vendedor.id', '')}
            history={this.props.history}
            editDisabled={true}
            showAddButton={false}
            tipoPessoa={enums.TipoPessoa.FISICA}
            onChange={(e) => {
              const data = { oid: _.get(e, 'oid', ''), value: _.get(e, 'nome', '') }
              _.set(this.props.filterValues, 'vendedor.valor', data)
              _.set(this.state.filtroAvancado, 'vendedor.valor', data)
              this.setState({ filtroAvancado: this.state.filtroAvancado })
            }}
          />
        </div>
      </div>
    )
  }

  buildFiltroAuxiliar = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-4">
          <LtsLookupPessoa
            name={'auxiliar'}
            field={'nome'}
            label={utils.getMessage(this.props.messages, 'contrato.label.auxiliar')}
            value={this.resolveValueFiltroAuxiliar()}
            oidRecord={_.get(this.props, 'auxiliar.id', '')}
            history={this.props.history}
            editDisabled={true}
            showAddButton={false}
            tipoPessoa={enums.TipoPessoa.FISICA}
            onChange={(e) => {
              const data = { oid: _.get(e, 'oid', ''), value: _.get(e, 'nome', '') }
              _.set(this.props.filterValues, 'auxiliar.valor', data)
              _.set(this.state.filtroAvancado, 'auxiliar.valor', data)
              this.setState({ filtroAvancado: this.state.filtroAvancado })
            }}
          />
        </div>
      </div>
    )
  }

  buildFiltroDataContrato = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['data', 'tipoFiltro'], null)}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'data.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataContrato"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.data')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['data', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['data', 'valor'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataContratoIni"
                onChange={(e) => this.changeDataFiltro('data.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['data', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['data', 'valorComplementar'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataContratoFim"
                onChange={(e) => this.changeDataFiltro('data.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataTerminoContrato = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['dataTermino', 'tipoFiltro'], null)}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'dataTermino.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataTerminoContrato"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.dataTermino')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataTermino', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataTermino', 'valor'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataTerminoContratoIni"
                onChange={(e) => this.changeDataFiltro('dataTermino.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['dataTermino', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataTermino', 'valorComplementar'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataTerminoContratoFim"
                onChange={(e) => this.changeDataFiltro('dataTermino.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }
  buildFiltroDataProximaRemessaCompetencia = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['dataProximaRemessaCompetencia', 'tipoFiltro'], null)}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'dataProximaRemessaCompetencia.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataProximaRemessaCompetencia"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.dataProximaRemessaCompetencia')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataProximaRemessaCompetencia', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataProximaRemessaCompetencia', 'valor'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                dateFormat={mascaras.MASCARA_DATA_MM_YY}
                view="month"
                name="dataProximaRemessaCompetenciaIni"
                onChange={(e) => this.changeDataFiltro('dataProximaRemessaCompetencia.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(
            _.get(this.props.filterValues, ['dataProximaRemessaCompetencia', 'tipoFiltro'])
          ) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataProximaRemessaCompetencia', 'valorComplementar'], null)}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                dateFormat={mascaras.MASCARA_DATA_MM_YY}
                name="dataProximaRemessaCompetenciaFim"
                view="month"
                onChange={(e) => this.changeDataFiltro('dataProximaRemessaCompetencia.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataFalecimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['dataFalecimento', 'tipoFiltro'])}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'dataFalecimento.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataFalecimento"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.falecimento')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataFalecimento', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataFalecimento', 'valor'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataFalecimentoIni"
                onChange={(e) => this.changeDataFiltro('dataFalecimento.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['dataFalecimento', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataFalecimento', 'valorComplementar'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataFalecimentoFim"
                onChange={(e) => this.changeDataFiltro('dataFalecimento.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataPrevisaoExumacao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['dataPrevisaoExumacao', 'tipoFiltro'])}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'dataPrevisaoExumacao.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataPrevisaoExumacao"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.previsaoExumacao')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataPrevisaoExumacao', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataPrevisaoExumacao', 'valor'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataPrevisaoExumacaoIni"
                onChange={(e) => this.changeDataFiltro('dataPrevisaoExumacao.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(
            _.get(this.props.filterValues, ['dataPrevisaoExumacao', 'tipoFiltro'])
          ) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataPrevisaoExumacao', 'valorComplementar'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataPrevisaoExumacaoFim"
                onChange={(e) => this.changeDataFiltro('dataPrevisaoExumacao.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataExumacao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['dataExumacao', 'tipoFiltro'])}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'dataExumacao.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoDataExumacao"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.exumacao')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionado(_.get(this.props.filterValues, ['dataExumacao', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataExumacao', 'valor'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataExumacaoIni"
                onChange={(e) => this.changeDataFiltro('dataExumacao.valor', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['dataExumacao', 'tipoFiltro'])) ? (
            <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
              <Calendar
                value={_.get(this.props.filterValues, ['dataExumacao', 'valorComplementar'])}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="dataExumacaoFim"
                onChange={(e) => this.changeDataFiltro('dataExumacao.valorComplementar', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroMarcador = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <LtsLookupMarcador
              label={utils.getMessage(this.props.messages, 'contrato.label.marcador')}
              value={_.get(this.state.filtroAvancado, ['marcador', 'valor', 'descricao'])}
              history={this.props.history}
              editHidden={true}
              field={'codigo'}
              multiple={false}
              onChange={(e) => {
                let data = { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'codigo', '') }
                _.set(this.props.filterValues, 'marcador.valor', data)
                _.set(this.state.filtroAvancado, 'marcador.valor', data)
                this.setState({ filtroAvancado: this.state.filtroAvancado })
              }}
              showAddButton={false}
              tipo={'CONTRATO'}
            />
          </span>
        </div>
      </div>
    )
  }

  buildFiltroMarcadorFalecido = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <LtsLookupMarcador
              label={utils.getMessage(this.props.messages, 'contrato.label.marcadorFalecido')}
              value={_.get(this.props.filterValues, ['marcadorFalecido', 'valor', 'descricao'])}
              history={this.props.history}
              editHidden={true}
              field={'codigo'}
              multiple={false}
              onChange={(e) => {
                const data = { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'codigo', '') }
                _.set(this.props.filterValues, 'marcadorFalecido.valor', data)
                _.set(this.state.filtroAvancado, 'marcadorFalecido.valor', data)
                this.setState({ filtroAvancado: this.state.filtroAvancado })
              }}
              showAddButton={false}
              tipo={'FALECIDO'}
            />
          </span>
        </div>
      </div>
    )
  }

  buildFiltroTipoCobertura = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <LtsLookupItemLancamento
              header={'Produtos/Serviços'}
              label={'Produto/Serviço'}
              value={_.get(this.state.filtroAvancado, ['tipoCobertura', 'valor', 'descricao'])}
              history={this.props.history}
              onChange={(e) => {
                const data = {
                  descricao: _.get(e, 'descricao', ''),
                  tipo: _.get(e, 'tipo', ''),
                  oidTipo: _.get(e, 'oidTipo', ''),
                }
                _.set(this.props.filterValues, 'tipoCobertura.valor', data)
                _.set(this.state.filtroAvancado, 'tipoCobertura.valor', data)
                this.setState({ filtroAvancado: this.state.filtroAvancado })
              }}
              idUnidade={_.get(this.props, 'idUnidade', null)}
              filtroTipoTaxa={enums.TipoTaxa.ENTRADA}
            />
          </span>
        </div>
      </div>
    )
  }

  buildFiltroConspaga = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['conspaga', 'tipoFiltro'])}
              options={this.props.tiposFiltros}
              onChange={(e) => _.set(this.props.filterValues, 'conspaga.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.conspaga')}</label>
          </span>
        </div>

        {isTipoFiltroSelecionado(_.get(this.props.filterValues, ['conspaga', 'tipoFiltro'])) ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
            <InputNumber
              name="conspagaInicial"
              value={_.get(this.props.filterValues, ['conspaga', 'valor'])}
              onChange={(e) => this.changeFiltroConspaga('valor', e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}

        {isTipoFiltroSelecionadoComplementar(_.get(this.props.filterValues, ['conspaga', 'tipoFiltro'])) ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
            <InputNumber
              name="conspagaFinal"
              value={_.get(this.props.filterValues, ['conspaga', 'valorComplementar'])}
              onChange={(e) => this.changeFiltroConspaga('valorComplementar', e.value)}
              min={0}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  changeFiltroConspaga = (campo, valor) => {
    _.set(this.props.filterValues, `conspaga.${campo}`, valor)
  }

  buildFiltroUnidades = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <MultiSelect
              className={'min-width-15-rem'}
              value={_.get(this.props.filterValues, ['unidade', 'valor'])}
              options={utils.convertArrayIdValueToIdLabel(this.props.unidades)}
              name="unidade"
              optionLabel="label"
              dataKey="id"
              selectedItemsLabel="{0} itens selecionados"
              onChange={(e) => this.changeTipoFiltroUnidades(e.value)}
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.unidade')}</label>
          </span>
        </div>
      </div>
    )
  }

  changeFiltroFormaPagamentoPlano = (valor) => {
    _.set(this.props.filterValues, 'formaPagamentoPlano.tipoFiltro', valor)
  }

  buildFiltroFormaPagamentoPlano = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-5">
          <span className="p-float-label">
            <Dropdown
              className={'width-20-rem'}
              value={_.get(this.props.filterValues, ['formaPagamentoPlano', 'tipoFiltro'])}
              options={this.props.tiposFiltrosFormaPagamentoPlano}
              onChange={(e) => this.changeFiltroFormaPagamentoPlano(e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoFiltroFormaPagamentoPlano"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.formaPagamentoPlano')}</label>
          </span>
        </div>

        {isTipoFiltroSelecionado(_.get(this.props.filterValues, ['formaPagamentoPlano', 'tipoFiltro'], false)) ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
            <Dropdown
              autoWidth={false}
              value={_.get(this.props.filterValues, ['formaPagamentoPlano', 'valor'])}
              options={this.props.filtrosFormaPagamentoPlano}
              onChange={(e) => _.set(this.props.filterValues, 'formaPagamentoPlano.valor', e.value)}
              optionLabel="value"
              optionValue={(v) => v}
              dataKey="id"
              name="formaPagamentoPlano"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  buildFiltroFrequenciaPagamentoPlano = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-5">
          <span className="p-float-label">
            <Dropdown
              className={'width-20-rem'}
              value={_.get(this.props.filterValues, ['frequenciaPagamentoPlano', 'tipoFiltro'])}
              options={this.props.tiposFiltrosFrequenciaPagamentoPlano}
              onChange={(e) => _.set(this.props.filterValues, 'frequenciaPagamentoPlano.tipoFiltro', e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipoFiltroFrequenciaPagamentoPlano"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.frequenciaFaturamentoPlano')}</label>
          </span>
        </div>

        {isTipoFiltroSelecionado(_.get(this.props.filterValues, ['frequenciaPagamentoPlano', 'tipoFiltro'], false)) ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
            <Dropdown
              autoWidth={false}
              value={_.get(this.props.filterValues, ['frequenciaPagamentoPlano', 'valor'])}
              options={this.props.filtrosFrequenciaPagamentoPlano}
              onChange={(e) => _.set(this.props.filterValues, 'frequenciaPagamentoPlano.valor', e.value)}
              optionLabel="value"
              optionValue={(v) => v}
              dataKey="id"
              name="frequenciaPagamentoPlano"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  changeTipoFiltroUnidades = (value) => {
    let filtroAvancado = this.props.filterValues
    let resetFiltroTipoSepultura = false
    if (value.length === 0) {
      this.props.loadFiltroTiposSepulturas([])
      resetFiltroTipoSepultura = true
    } else if (value.length !== filtroAvancado['unidade']?.length) {
      const oidsUnidades = value.map((f) => f.id)
      this.props.loadFiltroTiposSepulturas(oidsUnidades)
      resetFiltroTipoSepultura = true
    }

    if (resetFiltroTipoSepultura) {
      _.set(this.props.filterValues, 'filtroTipoSepultura.mostrarFiltro', false)
      _.set(this.props.filterValues, 'filtroTipoSepultura.tipoFiltro', null)
    }

    _.set(this.props.filterValues, 'unidade.valor', value)
  }

  buildFiltroTipoSepultura = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              value={_.get(this.props.filterValues, ['tipoSepultura', 'tipoFiltro'])}
              options={this.props.tiposFiltrosContrato}
              onChange={(e) => this.changeFiltroTipoSepultura(e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'contrato.label.tipoContrato')}</label>
          </span>
        </div>

        {isTipoFiltroSelecionado(_.get(this.props.filterValues, ['tipoSepultura', 'tipoFiltro'], false)) ? (
          <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
            <Dropdown
              autoWidth={false}
              value={_.get(this.props.filterValues, ['tipoSepultura', 'valor'])}
              options={this.props.filtrosTiposSepultura}
              onChange={(e) => _.set(this.props.filterValues, 'tipoSepultura.valor', e.value)}
              optionLabel="value"
              optionValue={(v) => v}
              dataKey="id"
              name="tipoSepultura"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  changeFiltroTipoSepultura = (valor) => {
    _.set(this.props.filterValues, 'tipoSepultura.tipoFiltro', valor)
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroUnidades()}
          {this.buildFiltroTipoSepultura()}
          {this.buildFiltroConspaga()}
          {this.buildFiltroResponsavel()}
          {this.buildFiltroDependente()}
          {this.buildFiltroVendedor()}
          {this.buildFiltroAuxiliar()}
          {this.buildFiltroDataContrato()}
          {this.buildFiltroDataTerminoContrato()}
          {this.buildFiltroDataProximaRemessaCompetencia()}
          {this.buildFiltroDataFalecimento()}
          {this.buildFiltroDataPrevisaoExumacao()}
          {this.buildFiltroDataExumacao()}
          {this.buildFiltroMarcador()}
          {this.buildFiltroMarcadorFalecido()}
          {this.buildFiltroTipoCobertura()}
          {this.buildFiltroFormaPagamentoPlano()}
          {this.buildFiltroFrequenciaPagamentoPlano()}
          <br></br>
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  gerarCarteirinhaLote = () => {
    let arrayoidsContratos = []
    if (this.props.selectedRecords && this.props.selectedRecords.length > 0) {
      this.props.selectedRecords.map((contrato) => {
        if (contrato.oid && contrato.ativo) {
          arrayoidsContratos.push(contrato.oid)
        }
      })
      this.props.gerarCarteirinhaLote(arrayoidsContratos)
    }
  }

  onClickBotaoDinamico = (botao) => {
    let listId = []
    let records = this.props.selectedRecords
    if (records && records.length > 0) {
      records.forEach((record) => {
        listId.push(record.oid)
      })
    }
    let params = this.getParamsBotaoDinamico()

    this.props.onClickBotaoDinamico(listId, botao, params)
  }

  getSplitButton = () => {
    let itensTransferenciaResponsabilidade = [
      {
        label: 'Assumir',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_ASSUMIR_RESPONSABILIDADE })
        },
      },
      {
        label: 'Assumir Paga 5 Anos',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_ASSUMIR_PAGA_CINCO_ANOS })
        },
      },
      {
        label: 'Outro',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_OUTRO })
        },
      },
      {
        label: 'Pedido Restos',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_PEDIDO_RESTO })
        },
      },
      {
        label: 'Túmulo',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_TUMULO })
        },
      },
      {
        label: 'Urna',
        command: (e) => {
          this.onClickBotaoDinamico({ oid: oidBotoesDinamicos.DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_URNA })
        },
      },
    ]
    if (this.props.botoesDinamicos && this.props.botoesDinamicos.length > 0) {
      return (
        <div className="p-toolbar-group-left">
          <SplitButton
            label="Transf. Responsabilidade"
            icon="pi pi-plus"
            style={{ marginLeft: '3px' }}
            model={itensTransferenciaResponsabilidade}
          ></SplitButton>
        </div>
      )
    }
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        {this.getButtonRelatorioCarteirinha()}
        {this.getButtonRelatorioDocumento()}
        {this.getBotaoReajustar()}
      </div>
    )
  }

  getButtonRelatorioCarteirinha = () => {
    if (this.acaoRelatorioCarteirinha) {
      return (
        <Button
          disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}
          onClick={(e) => this.gerarCarteirinhaLote()}
          label={utils.getMessage(this.props.messages, 'contrato.label.carteirinha')}
          icon={icons.PRINT}
          style={{ marginRight: '.25em' }}
          className="p-button-success"
        />
      )
    }
    return null
  }

  executarReajuste = () => {
    const selectedRecords = _.get(this.props, 'selectedRecords', [])
    if (!_.isEmpty(selectedRecords)) {
      const contratos = selectedRecords.filter((c) => c.ativo).map((c) => c.oid)
      this.props.reajustarContratos({ contratos, percentual: this.state.percentualReajuste })
      this.props.setSelectedRecords([])
      this.setState({ percentualReajuste: 0 })
    }
  }

  getBotaoReajustar = () => {
    return (
      <Button
        disabled={_.isEmpty(_.get(this.props, 'selectedRecords', []))}
        onClick={(e) => this.setState({ showReajustar: true })}
        label={utils.getMessage(this.props.messages, 'contrato.label.reajustar')}
        icon={icons.PLUS_ICON}
        style={{ marginLeft: '.25em', marginRight: '.25em' }}
        className="p-button-success"
      />
    )
  }

  executarRelatorioDocumento = (oidDocumento) => {
    const params = this.props.selectedRecords.map((r) => {
      return {
        oidContrato: _.get(r, 'oid', null),
        oidResponsavel: _.get(r, 'responsavel_oid', null),
        oidFalecido: _.get(r, 'falecido_oid', null),
        oidLocalizacao: _.get(r, 'localizacao_oid', null),
        oidContratoDocumento: null,
      }
    })
    this.props.requestExecutarRelatorioDocumentos({ oidDocumento, params })
  }

  getModelRelatorioDocumentos = () => {
    return this.props.relatotiosDocumentos.map((d) => {
      return {
        label: d.nome,
        oidDocumento: d.oid,
        command: (e) => {
          this.executarRelatorioDocumento(e.item.oidDocumento)
        },
      }
    })
  }
  getButtonRelatorioDocumento = () => {
    if (this.props.relatotiosDocumentos) {
      const model = this.getModelRelatorioDocumentos()
      if (utils.isArrayNotEmpty(model)) {
        return (
          <SplitButton
            disabled={!this.props.selectedRecords || this.props.selectedRecords.length === 0}
            label="Documentos"
            style={{ marginLeft: '3px' }}
            model={model}
            icon={icons.PRINT}
            className="p-button-success"
          ></SplitButton>
        )
      }
    }
    return null
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'contrato.label.contratos')}
      />
    )
  }

  oidTemplate = (rowData, column) => {
    return <Link to={`${routes.PATH_CONTRATO}/${rowData.oid}`}>{rowData.numero}</Link>
  }

  pessoaTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      let cpf = rowData[`${column.columnKey}`]
      return <Link to={`${routes.PATH_PESSOA_FISICA}/${cpf}`}>{rowData[column.field]}</Link>
    }
  }

  localizacaoTemplate = (rowData, column) => {
    if (
      rowData.localizacao_oid &&
      rowData.localizacao_identificacao !== undefined &&
      rowData.localizacao_identificacao !== ''
    ) {
      return (
        <Link to={`${routes.PATH_LOCALIZACAO}/${rowData.localizacao_oid}`}>{rowData.localizacao_identificacao}</Link>
      )
    }
    return ''
  }

  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result) {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-check-circle" style={{ fontSize: '1.0rem' }} />{' '}
        </div>
      )
    } else {
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-times-circle" style={{ fontSize: '1.0rem' }} />
        </div>
      )
    }
  }

  fieldCompetenciaFilter = (column) => {
    return (
      <Calendar
        view="month"
        dateFormat="mm/yy"
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        yearRange={utils.getYearRangeFromDate('1900')}
        appendTo={document.body}
        showButtonBar={true}
        onChange={(e) => this.props.changeFilter(column, e)}
        name={column.field}
      />
    )
  }
  fieldCalendarFilter = (column) => {
    return (
      <Calendar
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        yearNavigator={true}
        monthNavigator={true}
        yearRange={utils.getYearRangeFromDate('1900')}
        appendTo={document.body}
        showButtonBar={true}
        onChange={(e) => this.props.changeFilter(column, e)}
        name={column.field}
      />
    )
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%', minWidth: '10px' }}
        name={column.field}
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        options={this.props.booleanTypes}
        onChange={(e) => {
          _.set(this.props.filterValues, [column.field, 'valor'], e.value)
          this.props.changeFilter(column, e)
        }}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={_.get(this.props.filterValues, [column.field, 'valor'])}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    let visibleColumns = this.props.columns.filter((col) => col.visible)
    visibleColumns = visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '2.5em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'numero') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'data' || col.field === 'falecido_dataFalecimento' || col.field === 'falecidos_dataExumacao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      }
      if (col.field === 'dataProximaRemessaCompetencia') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCompetenciaFilter(col)}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      }
      if (col.field === 'conspaga') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-conspaga-body-width"
            filterHeaderClassName="column-conspaga-filter-header-width"
            headerClassName="column-conspaga-header-width"
          />
        )
      }
      if (col.field === 'falecido_nome' || col.field === 'responsavel_nome') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.pessoaTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-nome-body-width"
            filterHeaderClassName="column-nome-filter-header-width"
            headerClassName="column-nome-header-width"
          />
        )
      }
      if (col.field === 'localizacao_identificacao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.localizacaoTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-localizacao-body-width"
            filterHeaderClassName="column-localizacao-filter-header-width"
            headerClassName="column-localizacao-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-unidade-body-width"
            filterHeaderClassName="column-unidade-filter-header-width"
            headerClassName="column-unidade-header-width"
          />
        )
      }
      if (col.field === 'ativo') {
        return (
          <Column
            {...col}
            key={col.field}
            filterHeaderStyle={{ overflow: 'visible' }}
            sortField={col.columnSortField}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
            bodyClassName="column-ativo-body-width"
            filterHeaderClassName="column-ativo-filter-header-width"
            headerClassName="column-ativo-header-width"
          />
        )
      }
      if (col.field === 'tipoSepultura_descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            // filterHeaderStyle={{overflow: "visible"}}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-tipo-sepultura-body-width"
            filterHeaderClassName="column-tipo-sepultura-filter-header-width"
            headerClassName="column-tipo-sepultura-header-width"
          />
        )
      }
      // TODO COLOCAR ATRIBUTO PARA VISIBLE NA GRID
      if (
        col.field !== 'localizacao_oid' &&
        col.field !== 'falecido_oid' &&
        col.field !== 'responsavel_oid' &&
        col.field !== 'vendedor_oid' &&
        col.field !== 'auxiliar_oid'
      ) {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            style={{ width: '100px' }}
          />
        )
      }
    })
    return visibleColumns.filter((col) => {
      return col
    })
  }

  getDialogReajustar() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'contrato.label.confirmar')}
          onClick={(e) => {
            this.executarReajuste()
            this.setState({ showReajustar: false })
          }}
          icon={icons.CHECK_ICON}
          className="p-button-success"
        />
      </div>
    )

    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'contrato.label.reajustar')}
        visible={this.state.showReajustar}
        style={{ width: '25vw' }}
        baseZIndex={1000000}
        modal={true}
        closable={true}
        onHide={(e) => {
          this.setState({ showReajustar: false })
          this.props.setSelectedRecords([])
          this.setState({ percentualReajuste: 0 })
        }}
        footer={footer}
      >
        <div style={{ marginTop: '10px' }}>
          <LtsCurrency
            value={_.get(this.state, 'percentualReajuste', 0)}
            name={'percentualReajuste'}
            label={utils.getMessage(this.props.messages, 'contrato.label.percentualReajuste')}
            onChange={(e) => this.setState({ percentualReajuste: e })}
            mode={'decimal'}
            maxFracionDigits={2}
          />
        </div>
      </Dialog>
    )
  }

  buildContent = () => {
    return (
      <div>
        {this.getDialogReajustar()}
        <ContratoList
          {...this.props}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onSelectionChange={this.onSelectionChange}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    const columns = _.get(this, 'props.columns', [])
    if (columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContratoListPageContainer)
