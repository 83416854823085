import React from 'react';
import BotaoDinamicoFormPageContainer from './BotaoDinamicoFormPageContainer';

export class BotaoDinamicoFormPage extends React.Component{
    render(){
        return (
            <BotaoDinamicoFormPageContainer {...this.props}/>
        )
    }
}

