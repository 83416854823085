import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { Page } from '../../../components/page/Page'
import { routes } from '../../../constants'
import utils from '../../../utils/index'
import { actions } from '../mensageriaLog.actions'
import { MensageriaLogForm } from './MensageriaLogForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    colunasItens: state.crudMensageriaLogState.colunasItens,
    messages: state.crudMensageriaLogState.messages,
    record: state.crudMensageriaLogState.currentRecord,
    first: state.crudMensageriaLogState.index,
    rows: state.crudMensageriaLogState.max,
    totalRecords: state.crudMensageriaLogState.count,
    sortField: state.crudMensageriaLogState.sortField,
    order: state.crudMensageriaLogState.sortOrder,
    selectedFilters: state.crudMensageriaLogState.selectedFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadById: (oid) => dispatch(actions.loadById(oid)),
    downloadAnexo: (anexo) => dispatch(actions.downloadAnexo(anexo)),
  }
}

class MensageriaLogFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableItens = React.createRef()
    this.state = {}
    actions.beforeFetchRecordSuccess = (data) => {
      if (data.dataEnvio) {
        data.dataEnvio = utils.getUTCDate(data.dataEnvio)
      }
      return data
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    }
  }

  hideBotaoRemover = () => {
    return true
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_MENSAGERIA_LOG}`)
  }

  onRowSelect = (e) => {
    this.setState({
      displayDialog: true,
      item: Object.assign({}, e.data),
    })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const columnData = (rowData, column) => {
      if (column.field === 'oidDestinatario') {
        return <Link to={`${routes.PATH_PESSOA_FISICA}/${rowData.oidDestinatario}`}>{rowData.oidDestinatario}</Link>
      } else if (column.field === 'oidContrato') {
        return <Link to={`${routes.PATH_CONTRATO}/${rowData.oidContrato}`}>{rowData.oidContrato}</Link>
      }
    }

    const colunasItens = this.props.colunasItens.map((col) => {
      if (col.field === 'oidDestinatario' || col.field === 'oidContrato') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            sortField={col.columnSortField}
            style={{ width: '12%', textAlign: 'center' }}
            filterMatchMode="contains"
          />
        )
      }
      return (
        <Column
          {...col}
          key={col.field}
          sortField={col.columnSortField}
          filter={true}
          filterMatchMode="contains"
          style={{ width: '25%', textAlign: 'left' }}
        />
      )
    })

    const content = (
      <MensageriaLogForm
        record={this.props.record}
        colunasItens={colunasItens}
        dataTableItens={(el) => (this.dataTableItens = el)}
        messages={this.props.messages}
        anexos={this.props.record.anexos}
        downloadAnexo={this.props.downloadAnexo}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MensageriaLogFormPageContainer)
