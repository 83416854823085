const blankToNull = require('./../../../utils').blankToNull;

export function validate(record){

    const errors = [];
    if (!record.textoBotao || record.textoBotao.trim().length < 4) {
        errors.push({ field: 'textoBotao', message: 'O campo nome é obrigatório e deve ter mais de 3 caracteres' });
    }
    if (!record.idTela || record.idTela.trim().length < 4) {
        errors.push({ field: 'idTela', message: 'O campo tela é obrigatório e deve ter mais de 3 caracteres' });
    }
    
    blankToNull(record);
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}