import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { routes } from '../../../constants'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { actions } from '../sepulturasdevedorasrelatorio.actions'
import { SepulturasDevedorasRelatorioForm } from './SepulturasDevedorasRelatorioForm'
import { Button } from 'primereact/button'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudSepulturasDevedorasRelatorioState.messages
  const record = state.crudSepulturasDevedorasRelatorioState.currentRecord
  const tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidades: [unidade] })
    disableUnit = true
  }

  let unidades = []
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)
  return {
    record,
    messages,
    tiposFiltros,
    unidades,
    disableUnit,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))

      dispatch(actions.setField(field, valor))
    },
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    carregarTiposSepulturas: (unidade) => dispatch(actions.carregarTiposSepulturas(unidade)),
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    abrirRelatorio: (filtroConspaga, unidade, tiposSepulturas) =>
      dispatch(actions.abrirRelatorio(filtroConspaga, unidade, tiposSepulturas)),
  }
}

class SepulturasDevedorasRelatorioFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showPesquisarButton = false
    this.showCSVButton = false
    this.showColumnButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      filtroConspaga: {
        tipoFiltro: props.tiposFiltros[4], // MAIOR OU IGUAL QUE
        conspagaInicial: window.moment().year(),
        conspagaFinal: window.moment().year(),
        mostrarAnoInicial: true,
        mostrarAnoFinal: false,
      },
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_SEPULTURAS_DEVEDORAS}`)
      this.props.carregarTiposSepulturas()
      this.props.loadFavorito(this.props.history.location.pathname)
    }
    actions.afterLoadFavorito = (data) => {
      if (data && data.oid) {
        this.setState({ favorito: 1 })
        this.setState({ dadosFavorito: data })
      } else {
        this.setState({ favorito: 0 })
        this.setState({ dadosFavorito: null })
      }
    }
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button label="Visualizar" icon="pi pi-check" className="p-button-success" onClick={this.abrirRelatorio} />
      </div>
    )
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.newHandler()
  }

  abrirRelatorio = () => {
    let filtroConspaga = {
      tipoFiltro: this.state.filtroConspaga.tipoFiltro.value,
      campo: 'conspaga',
      valor: this.state.filtroConspaga.conspagaInicial,
      valorComplementar: this.state.filtroConspaga.conspagaFinal,
    }

    let tiposSepulturasSelecionadas = this.props.record.tiposSepulturas
    if (utils.isArrayNullOrEmpty(tiposSepulturasSelecionadas)) {
      tiposSepulturasSelecionadas = this.props.record.opcoesTiposSepulturas
    } else {
      tiposSepulturasSelecionadas = utils.convertArrayIdLabelToIdValue(tiposSepulturasSelecionadas)
    }
    const unidades = utils.convertArrayIdLabelToIdValue(this.props.record.unidade)
    this.props.abrirRelatorio(filtroConspaga, unidades, tiposSepulturasSelecionadas)
  }

  changeTipoFiltroConspaga = (event) => {
    let valor = event.value
    this.state.filtroConspaga.tipoFiltro = valor
    this.state.filtroConspaga.mostrarAnoInicial = valor.dataInicial
    this.state.filtroConspaga.mostrarAnoFinal = valor.dataFinal
  }

  changeAnoConspaga = (event) => {
    const target = event.target
    const field = target.name
    let filtroConspaga = this.state.filtroConspaga
    filtroConspaga[field] = _.defaultTo(parseInt(target.value), window.moment().year())
    this.setState({ filtroConspaga: filtroConspaga })
  }

  carregarTiposSepulturas = () => {
    this.props.carregarTiposSepulturas(this.props.record.unidade)
  }

  buildHeader = () => {
    return (
      <div>
        <DefaultToolBar listPageContainer={this} title={'Relatório de Sepulturas Devedoras'} />
      </div>
    )
  }

  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    const valor = target.value
    this.props.setField(field, valor)
    this.props.carregarTiposSepulturas(utils.convertArrayIdLabelToIdValue(valor))
  }

  render() {
    const content = (
      <SepulturasDevedorasRelatorioForm
        abrirRelatorio={this.abrirRelatorio}
        tiposFiltros={this.props.tiposFiltros}
        changeAnoConspaga={this.changeAnoConspaga}
        changeTipoFiltroConspaga={this.changeTipoFiltroConspaga}
        state={this.state}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        onChangeUnit={this.onChangeUnit}
      />
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SepulturasDevedorasRelatorioFormPageContainer)
