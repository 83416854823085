import React from 'react';
import FuncaoDinamicaListPageContainer from './FuncaoDinamicaListPageContainer';

export class FuncaoDinamicaListPage extends React.Component {

  render() {
    return (
      <FuncaoDinamicaListPageContainer {...this.props} />
    )
  }
}

