import { types } from "./movimentocaixa.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";
import utils from "../../utils";
import * as moment from 'moment';

const initialState = {
  id: "movimentocaixa",
  columns: [],
  currentRecord: {
    oid: "",
    dataInicial: moment().startOf("month").toDate(),
    dataFinal: moment().endOf("month").toDate()
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false
};

const reducer = new Reducer(initialState, types);

export function CRUDMovimentoCaixaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("movimentocaixa");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  return state;
}