import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../consultaBaixa.actions'
import { ConsultaBaixaList } from './ConsultaBaixaList'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudConsultaBaixaState.showHideColumns,
    messages: state.crudConsultaBaixaState.messages,
    columns: state.crudConsultaBaixaState.columns,
    records: state.crudConsultaBaixaState.records,
    first: state.crudConsultaBaixaState.index,
    rows: state.crudConsultaBaixaState.max,
    totalRecords: state.crudConsultaBaixaState.count,
    sortField: state.crudConsultaBaixaState.sortField,
    order: state.crudConsultaBaixaState.sortOrder,
    selectedFilters: state.crudConsultaBaixaState.selectedFilters,
    booleanFilter: state.crudConsultaBaixaState.booleanFilter,
    booleanTypesStatus: [
      { label: 'TODOS', value: '' },
      { label: 'SUCESSO', value: 1 },
      { label: 'ERRO', value: 2 },
    ],
    booleanTypesTipo: [
      { label: 'TODOS', value: '' },
      { label: 'Ailos - Boleto', value: 1 },
      { label: 'Vindi - Boleto', value: 2 },
      { label: 'Vindi - Cartão Crédito', value: 3 },
      { label: 'Celesc', value: 4 },
      { label: 'Débito automático', value: 5 },
      { label: 'Cancelamento débito automático', value: 6 },
      { label: 'Cergral', value: 7 },
    ],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      // tratamentos para nomes dos filtros boolean
      if (['status', 'tipoRemessa'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/arquivosRetornos/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    salvarState: (state, filters) => {
      dispatch(actions.saveState(state, filters))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
  }
}

class ConsultaBaixaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.visaoRef = React.createRef()
    this.showHideColumns = []
    this.showDialogVisao = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  salvarState = (dados, state) => {
    let arrayColumns = this.showHideColumns.map((col) => {
      return col.value
    })
    arrayColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })
    state['columns'] = arrayColumns
    state['menu'] = { id: 1, value: this.props.history.location.pathname }
    this.props.salvarState(state, dados.filters)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'consultabaixa.label.consultaBaixas')}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  oidTemplate = (rowData) => {
    return <Link to={`${routes.PATH_CONSULTA_BAIXA}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  booleanFilterStatus = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypesStatus}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  typeFilterTipo = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypesTipo}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result === 'SUCESSO') {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-check-circle" style={{ fontSize: '1.8rem' }} />{' '}
        </div>
      )
    } else if (result === 'ERRO') {
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-times-circle" style={{ fontSize: '1.8rem' }} />
        </div>
      )
    }
    return null
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (['status'].find((field) => field === col.field)) {
        return (
          <Column
            filterHeaderStyle={{ overflow: 'visible' }}
            {...col}
            key={col.field}
            body={this.booleanFieldsTemplate}
            sortField={col.columnSortField}
            filterElement={this.booleanFilterStatus(col)}
          />
        )
      } else if (['tipoRemessa'].find((field) => field === col.field)) {
        return (
          <Column
            filterHeaderStyle={{ overflow: 'visible' }}
            {...col}
            key={col.field}
            body={this.tipoFieldsTemplate}
            sortField={col.columnSortField}
            filterElement={this.typeFilterTipo(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  tipoFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result === 'BOLETO') {
      return 'Boleto'
    } else if (result === 'VINDI_BOLETO') {
      return 'Vindi - Boleto'
    } else if (result === 'VINDI_CARTAO_CREDITO') {
      return 'Vindi - Cartão Crédito'
    } else if (result === 'CELESC') {
      return 'Celesc'
    } else if (result === 'CERGRAL') {
      return 'Cergral'
    } else if (result === 'DEBITO_AUTOMATICO') {
      return 'Débito Automático'
    } else if (result === 'CANCELAMENTO_DEBITO_AUTOMATICO') {
      return 'Cancelamento Débito Automático'
    }
    return null
  }

  buildContent = () => {
    return (
      <ConsultaBaixaList
        {...this.props}
        onSelectionChange={this.onSelectionChange}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaBaixaListPageContainer)
