import React from 'react';

import LancamentoFormPageContainer from './LancamentoFormPageContainer';

export class LancamentoFormPage extends React.Component{

    
    render(){
        return (
           <LancamentoFormPageContainer {...this.props} />
        )
    }
}
