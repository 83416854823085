import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { LookupField } from '../../../components/lookup/LookupField'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { PickList } from 'primereact/picklist'
import { InputNumber } from 'primereact/inputnumber'
import { icons } from '../../../constants/index'
import utils from '../../../utils'

import './RelatorioDinamicoForm.scss'

export const RelatorioDinamicoForm = (props) => {
  let relacionamento = props.relacionamento
  let relacionamentos = props.relacionamento
  let coluna = props.coluna
  let colunas = props.colunas
  let filtro = props.filtro
  let filtros = props.filtros
  let ordenacao = props.ordenacao
  let ordenacoes = props.ordenacoes
  const optionsTipoDataTipo = [
    { label: 'Data atual', value: 1 },
    { label: 'Início mês', value: 2 },
    { label: 'Final mês', value: 3 },
  ]
  const optionsTipoDataPeriodo = [
    { label: 'Dia', value: 1 },
    { label: 'Semana', value: 2 },
    { label: 'Mês', value: 3 },
  ]

  const changeHandler = props.changeHandler

  const tipoDataPreview = utils.calculatePreview(
    filtro.tipoDataTipo,
    filtro.tipoDataPeriodo,
    filtro.tipoDataModificador
  )
  const tipoDataPreviewComplementar = utils.calculatePreview(
    filtro.tipoDataTipoComplementar,
    filtro.tipoDataPeriodoComplementar,
    filtro.tipoDataModificadorComplementar
  )

  function getOpcaoValor(filtro) {
    if (utils.isTipoDadoData(filtro)) {
      return (
        <div>
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Dropdown
              value={filtro.tipoDataTipo}
              options={optionsTipoDataTipo}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              name="tipoDataTipo"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <InputNumber
              value={filtro.tipoDataModificador}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              min={-1000}
              max={1000}
              name="tipoDataModificador"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Dropdown
              value={filtro.tipoDataPeriodo}
              options={optionsTipoDataPeriodo}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              disabled={!filtro.tipoDataModificador}
              name="tipoDataPeriodo"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <label>{window.moment(tipoDataPreview).format('DD/MM/YYYY')}</label>
          </div>
        </div>
      )
    }

    return (
      <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
        <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.valor')}</label>
        <InputText value={filtro.valor} onChange={props.editarDadoFiltro} name="valor" />
      </div>
    )
  }

  function getOpcaoValorComplementar(filtro) {
    if (utils.isTipoDadoString(filtro) || !utils.isFilterValorComplementar(props.filterOptions, filtro)) {
      return ''
    }

    if (!filtro.valorComplementar) {
      filtro.valorComplementar = null
    }

    if (utils.isTipoDadoData(filtro)) {
      return (
        <div>
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Dropdown
              value={filtro.tipoDataTipoComplementar}
              options={optionsTipoDataTipo}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              name="tipoDataTipoComplementar"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <InputNumber
              value={filtro.tipoDataModificadorComplementar}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              min={-1000}
              max={1000}
              name="tipoDataModificadorComplementar"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Dropdown
              value={filtro.tipoDataPeriodoComplementar}
              options={optionsTipoDataPeriodo}
              onChange={(e) => {
                props.changeHandler(e)
              }}
              disabled={!filtro.tipoDataModificadorComplementar}
              name="tipoDataPeriodoComplementar"
            />
          </div>

          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <label>{window.moment(tipoDataPreviewComplementar).format('DD/MM/YYYY')}</label>
          </div>
        </div>
      )
    }

    return (
      <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
        <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.valorComplementar')}</label>
        <InputText value={filtro.valorComplementar} onChange={props.editarDadoFiltro} name="valorComplementar" />
      </div>
    )
  }

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {utils.getMessage(props.messages, 'relatoriodinamico.label.relatoriodinamico')} {props.record.oid || ''}
          </h3>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.nome')}</label>
              <InputText value={props.record.nome} onChange={props.changeHandler} name="nome" autoFocus />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(props.messages, 'relatoriodinamico.label.entidadebase')}
              </label>
              <InputText value={props.record.entidadeBase} onChange={props.changeHandler} name="entidadeBase" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label"> {utils.getMessage(props.messages, 'relatoriodinamico.label.menu')}</label>
              <LookupField
                value={props.record.menu ? props.record.menu.value : ''}
                name="nomeMenu"
                field="nome"
                history={props.history}
                onComplete={props.lookupMenuOnComplete}
                onInputFilter={props.lookupMenuOnInputFilter}
                editDisabled={props.lookupMenu.editDisabled}
                onEditField={props.lookupMenuOnEdit}
                onClick={props.lookupMenuOnClick}
                visible={props.lookupMenu.visible}
                modal={props.lookupMenu.modal}
                header={props.lookupMenu.header}
                onHide={props.lookupMenuOnHide}
                onConfirm={props.lookupMenuOnConfirm}
                onCancel={props.lookupMenuOnCancel}
                records={props.lookupMenu.records}
                columns={props.lookupMenu.columns}
                totalRecords={props.lookupMenu.totalRecords}
                sortOrder={props.lookupMenu.order}
                sortField={props.lookupMenu.sortField}
                first={props.lookupMenu.first}
                rows={props.lookupMenu.rows}
                onPage={props.lookupMenuOnPage}
                onFilter={props.lookupMenuOnFilter}
                onSort={props.lookupMenuOnOnSort}
              />
            </div>
          </div>
        </div>

        {/* Relacionamentos */}

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card card-w-title">
              <div className="alinhar-componentes">
                <div>
                  <Button
                    onClick={(e) => props.showForm('relacionamento', true)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                  />
                </div>
                <h3>{utils.getMessage(props.messages, 'relatoriodinamico.label.relacionamentos')}</h3>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <DataTable
                    autoLayout={true}
                    selectionMode="single"
                    onFilter={props.onFilter}
                    onSort={props.onSort}
                    value={relacionamentos}
                    totalRecords={props.totalRecords}
                    sortOrder={props.order}
                    sortField={props.sortField}
                    emptyMessage=""
                  >
                    {props.colunasRelacionamentos}
                  </DataTable>
                </div>
              </div>

              <Dialog
                header={utils.getMessage(props.messages, 'relatoriodinamico.label.relacionamento')}
                visible={props.displayForm.relacionamento}
                style={{ width: '50vw' }}
                onHide={(e) => {
                  props.showForm('relacionamento', false)
                  props.clearForm()
                }}
                baseZIndex={1000000}
                modal={true}
              >
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.relacionamento')}
                    </label>
                    <InputText
                      value={relacionamento.relacionamento}
                      onChange={props.editarDadoRelacionamento}
                      name="relacionamento"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.alias')}</label>
                    <InputText value={relacionamento.alias} onChange={props.editarDadoRelacionamento} name="alias" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
                    <label className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.tipojoin')}
                    </label>
                    <Dropdown
                      autoWidth={false}
                      value={relacionamento.joinType}
                      options={props.joinType}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="joinType"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.ordem')}</label>
                    <InputText
                      keyfilter="pint"
                      value={relacionamento.ordem}
                      onChange={props.editarDadoRelacionamento}
                      name="ordem"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-4">
                    <Button
                      label={utils.getMessage(props.messages, 'relatoriodinamico.label.adicionar')}
                      onClick={(e) => {
                        props.adicionarRelacionamento()
                        props.showForm('relacionamento', false)
                      }}
                      icon={icons.PLUS_ICON}
                      className="p-button-success"
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>

        {/* Campos */}

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card card-w-title">
              <div className="alinhar-componentes">
                <div>
                  <Button
                    onClick={(e) => props.showForm('campo', true)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                  />
                </div>
                <h3>{utils.getMessage(props.messages, 'relatoriodinamico.label.campos')}</h3>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <DataTable
                    autoLayout={true}
                    selectionMode="single"
                    onFilter={props.onFilter}
                    onSort={props.onSort}
                    value={colunas}
                    totalRecords={props.totalRecords}
                    sortOrder={props.order}
                    sortField={props.sortField}
                    emptyMessage=""
                  >
                    {props.colunasCampos}
                  </DataTable>
                </div>
              </div>

              <Dialog
                header={utils.getMessage(props.messages, 'relatoriodinamico.label.campo')}
                visible={props.displayForm.campo}
                style={{ width: '50vw' }}
                onHide={(e) => {
                  props.showForm('campo', false)
                  props.clearForm()
                }}
                baseZIndex={1000000}
                modal={true}
              >
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.campo')}</label>
                    <InputText value={coluna.campo} onChange={props.editarDadoCampo} name="campo" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.ordem')}</label>
                    <InputText keyfilter="pint" value={coluna.ordem} onChange={props.editarDadoCampo} name="ordem" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.descricao')}
                    </label>
                    <InputText value={coluna.descricao} onChange={props.editarDadoCampo} name="descricao" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">Link</label>
                    <InputText value={coluna.linkField} onChange={props.editarDadoCampo} name="linkField" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">Id Link</label>
                    <InputText value={coluna.idLink} onChange={props.editarDadoCampo} name="idLink" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.tipo')}</label>
                    <Dropdown
                      autoWidth={false}
                      value={props.parseTipoDado(coluna.tipoDado)}
                      options={props.tiposDado}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="tipoDadoColuna"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-4">
                    <Button
                      label={utils.getMessage(props.messages, 'relatoriodinamico.label.adicionar')}
                      onClick={(e) => {
                        props.adicionarCampo()
                        props.showForm('campo', false)
                      }}
                      icon={icons.PLUS_ICON}
                      className="p-button-success"
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>

        {/* Filtros */}
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card card-w-title">
              <div className="alinhar-componentes">
                <div>
                  <Button
                    onClick={(e) => props.showForm('filtro', true)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                  />
                </div>
                <h3>{utils.getMessage(props.messages, 'relatoriodinamico.label.filtros')}</h3>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <DataTable
                    autoLayout={true}
                    selectionMode="single"
                    onFilter={props.onFilter}
                    onSort={props.onSort}
                    value={filtros}
                    totalRecords={props.totalRecords}
                    sortOrder={props.order}
                    sortField={props.sortField}
                    emptyMessage=""
                  >
                    {props.colunasFiltros}
                  </DataTable>
                </div>
              </div>

              {/* Dialog de Filtros  */}

              <Dialog
                header={utils.getMessage(props.messages, 'relatoriodinamico.label.filtro')}
                visible={props.displayForm.filtro}
                style={{ width: '50vw' }}
                onHide={(e) => {
                  props.showForm('filtro', false)
                  props.clearForm()
                }}
                baseZIndex={1000000}
                modal={true}
              >
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                    <label htmlFor="chkVisivel" className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.exibirfiltro')}
                    </label>
                    <Checkbox
                      inputId="chkVisivel"
                      checked={filtro.visivel}
                      onChange={props.editarDadoFiltro}
                      style={{ marginLeft: '5px' }}
                      name="visivel"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                    <label className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.descricao')}
                    </label>
                    <InputText value={filtro.descricao} onChange={props.editarDadoFiltro} name="descricao" autoFocus />
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.campo')}</label>
                    <InputText value={filtro.campo} onChange={props.editarDadoFiltro} name="campo" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">
                      {utils.getMessage(props.messages, 'relatoriodinamico.label.classeconversor')}
                    </label>
                    <InputText
                      value={filtro.converterFiltro}
                      onChange={props.editarDadoFiltro}
                      name="converterFiltro"
                      autoFocus
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.tipo')}</label>
                    <Dropdown
                      autoWidth={false}
                      value={props.parseTipoDado(filtro.tipoDado)}
                      options={props.tiposDado}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="tipoDadoFiltro"
                    />
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.tipo')}</label>
                    <Dropdown
                      autoWidth={false}
                      value={props.parseFilter(filtro.tipoFiltro)}
                      options={props.getTiposFiltros(filtro)}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="tipoFiltro"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  {getOpcaoValor(filtro)}
                  {getOpcaoValorComplementar(filtro)}
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-4">
                    <Button
                      label={utils.getMessage(props.messages, 'relatoriodinamico.label.adicionar')}
                      onClick={(e) => {
                        props.adicionarFiltro()
                        props.showForm('filtro', false)
                      }}
                      icon={icons.PLUS_ICON}
                      className="p-button-success"
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>

        {/* Ordenacoes */}

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card card-w-title">
              <div className="alinhar-componentes">
                <div>
                  <Button
                    onClick={(e) => props.showForm('ordenacao', true)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                  />
                </div>
                <h3>{utils.getMessage(props.messages, 'relatoriodinamico.label.ordenacoes')}</h3>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                  <DataTable
                    autoLayout={true}
                    selectionMode="single"
                    onFilter={props.onFilter}
                    onSort={props.onSort}
                    value={ordenacoes}
                    totalRecords={props.totalRecords}
                    sortOrder={props.order}
                    sortField={props.sortField}
                    emptyMessage=""
                  >
                    {props.colunasOrdenacao}
                  </DataTable>
                </div>
              </div>

              <Dialog
                header={utils.getMessage(props.messages, 'relatoriodinamico.label.ordenacao')}
                visible={props.displayForm.ordenacao}
                style={{ width: '50vw' }}
                onHide={(e) => {
                  props.showForm('ordenacao', false)
                  props.clearForm()
                }}
                baseZIndex={1000000}
                modal={true}
              >
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.campo')}</label>
                    <InputText value={ordenacao.campo} onChange={props.editarDadoOrdenacao} name="campo" />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.tipo')}</label>
                    <Dropdown
                      autoWidth={false}
                      value={ordenacao.tipoOrdenacaoColuna}
                      options={props.tiposOrdenacoes}
                      onChange={changeHandler}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="tipoOrdenacaoColuna"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                    <label className="label">{utils.getMessage(props.messages, 'relatoriodinamico.label.ordem')}</label>
                    <InputText
                      keyfilter="pint"
                      value={ordenacao.ordem}
                      onChange={props.editarDadoOrdenacao}
                      name="ordem"
                    />
                  </div>
                </div>

                <div className="p-grid form-group">
                  <div className="p-col-4">
                    <Button
                      label={utils.getMessage(props.messages, 'relatoriodinamico.label.adicionar')}
                      onClick={(e) => {
                        props.adicionarOrdenacao()
                        props.showForm('ordenacao', false)
                      }}
                      icon={icons.PLUS_ICON}
                      className="p-button-success"
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>

        {/* Perfis  */}

        <div className="p-grid p-fluid">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card card-w-title">
              <div className="p-col-2 alinhar-componentes">
                <h3>{utils.getMessage(props.messages, 'relatoriodinamico.label.perfis')}</h3>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
                  <PickList
                    responsive={true}
                    source={props.perfisDisponiveis}
                    target={props.perfisSelecionados}
                    onChange={props.onChangePerfis}
                    itemTemplate={(perfil) => (
                      <label>
                        {perfil.oid} - {perfil.nomePerfil}
                      </label>
                    )}
                    sourceHeader="Disponíveis"
                    targetHeader="Selecionados"
                    showSourceControls={false}
                    showTargetControls={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
