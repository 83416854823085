import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import _ from 'lodash'
import { messageActions } from '../../store/message'

const BASE_PATH = '/lottustech/orcamento'
const crud = build(validate, BASE_PATH)
const prefix = 'crudOrcamento'

const newTypes = {
  RESET: `${prefix}reset`,
}
const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.contratar = (oid) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}/contratar/${oid}`)
      .then((response) => {
        const status = _.get(response, 'data.statusOrcamento', null)
        const oidContrato = _.get(response, 'data.oidContrato', null)
        if (status) {
          dispatch(actions.setField('status', status))
          dispatch(actions.setField('oidContrato', oidContrato))
        }
        if (oidContrato) {
          const msg = {
            sticky: false,
            summary: '',
            severity: 'success',
            detail: `Foi criado o contrato #${oidContrato}`,
          }
          dispatch(messageActions.messageShowMessages([msg]))
        }
        console.log(JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(JSON.stringify(error))
        //exibir mensagem
      })
  }
}

actions.imprimir = (oid) => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/report/${oid}`
    return apiService.post(path).then((response) => {
      const win = window.open(response.data)
      if (win) {
        win.focus()
      }
    })
  }
}

export { types, actions }
