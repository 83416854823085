import React from 'react';
import PessoaJuridicaListPageContainer from './PessoaJuridicaListPageContainer';

export class PessoaJuridicaListPage extends React.Component{
    
    render(){
      return (
        <PessoaJuridicaListPageContainer {...this.props}/>
      )
    }
}

