import React from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { BreadCrumb } from 'primereact/breadcrumb'
import { AppBreadcrumb } from '../components/appBreadCrumb/AppBreadcrumb'
// import { withRouter } from "react-router";
import { AppMenu } from '../components/appMenu/AppMenu'
import { AppTopbar } from '../components/topBar/AppTopbar'
import { menuActions } from '../store/menu'
import { actions as perfilActions } from '../pages/perfil/perfil.actions'
import { actions as appActions } from '../store/app'
import PrivateRoute from '../components/PrivateRoute'
import utils from '../utils'

import logo from '../assets/images/logo/phoenix_logo_90x90.png'

import { DomHandler } from 'primereact/utils'
import authenticationService from '../services/authentication.service'
import storage from '../services/stoage.service'
import _ from 'lodash'
import { LoadingBar } from 'react-redux-loading-bar'

const mapStateToProps = (state) => {
  let unidadeCorrente = storage.getUnit()
  let empresaCorrente = storage.getCompany()
  return {
    items: state.menuState.items,
    companies: storage.getCompanies(),
    unidadesUsuario: storage.getUnidadesUsuario(),
    currentUnit: unidadeCorrente,
    currentCompany: empresaCorrente,
    companyOid: empresaCorrente ? empresaCorrente.oid : '',
    unitOid: unidadeCorrente ? unidadeCorrente.oid : '',
    currentUser: state.appState.currentUser,
    appDefaultRoutes: state.appState.appDefaultRoutes,
    appCustomRoutes: state.appState.appCustomRoutes,
    portalCliente: storage.getPortalCliente(),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      dispatch(menuActions.menuFetch())
      dispatch(perfilActions.load())
    },
    setCompany: (company) => {
      if (!storage.getUsuarioUnidadeUnica() && company) {
        storage.setCompany(company)
        storage.setUnit(null)
        dispatch(appActions.setEmpresaUnidadeCorrente(company, null))
        dispatch(appActions.loadLabels())
        dispatch(menuActions.menuFetch())
      }
    },
    logout: () => {
      storage.setCompany(null)
      storage.setUnit(null)
      storage.setUnidadesUsuario(null)
      storage.clearStorage()
      authenticationService.logout()
    },
    configurarContaAilos: () => {
      dispatch(appActions.configurarContaAilos())
    },
    setCurrentUser: (currenteUser) => dispatch(appActions.setCurrentUser(currenteUser)),
    setCurrentUnit: (unit) => {
      storage.setUnit(unit)
      dispatch(appActions.setUnidadeCorrente(unit))
      dispatch(appActions.loadLabels())
    },
    setDefaultRoutes: () => dispatch(appActions.setDefaultRoutes()),
    setMenuLayout: (value) => dispatch(appActions.setMenuLayout(value)),
  }
}

class AdminLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      itemsBreadCrumb: [],
      searchOptions: [],

      activeTopbarItem: null,
      layoutMode: 'overlay',
      mobileMenuActive: false,
      topbarMenuActive: null,
      currentRoute: null,
      menuActive: false,
      sideBarActive: false,
    }

    this.onTopbarItemClick = this.onTopbarItemClick.bind(this)
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this)
    this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(this)
    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this)
    this.onToggleMenuClick = this.onToggleMenuClick.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
    this.setCurrentUnit = props.setCurrentUnit
    this.setCompany = props.setCompany
  }

  onWrapperClick(event) {
    if (!this.menuClick && !this.menuButtonClick && this.state.mobileMenuActive) {
      this.setState({ mobileMenuActive: false })
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      })
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isOverlay()) {
        this.setState({
          menuActive: false,
        })
      }
    }

    this.menuClick = false
    this.menuButtonClick = false
    this.topbarMenuClick = false
    this.topbarMenuButtonClick = false
  }

  onTopbarItemClick(event) {
    this.topbarMenuClick = true
    if (this.state.activeTopbarItem === event.item) {
      this.setState({ activeTopbarItem: null })
    } else if (event.item === 'logout') {
      this.props.logout()
      this.props.history.push(`/login`)
    } else if (event.item === 'configurarAilos') {
      this.props.configurarContaAilos()
    } else {
      this.setState({ activeTopbarItem: event.item })
    }

    event.originalEvent.preventDefault()
  }

  onMenuButtonClick(event) {
    this.menuButtonClick = true

    if (this.isMobile()) {
      this.setState({ mobileMenuActive: !this.state.mobileMenuActive })
    }

    event.preventDefault()
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMenuButtonClick = true
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive })
    event.preventDefault()
  }

  onToggleMenuClick(event) {
    const mode = this.state.layoutMode !== 'static' ? 'static' : 'overlay'
    this.setState({ layoutMode: mode })
    this.props.setMenuLayout(mode)
  }

  onSidebarClick(event) {
    if (this.sidebarTimeout) {
      clearTimeout(this.sidebarTimeout)
    }
    this.setState({ sideBarActive: true })
    DomHandler.addClass(this.sidebar, 'layout-sidebar-active')
    this.menuClick = true
  }

  onSidebarMouseLeave(event) {
    this.sidebarTimeout = setTimeout(() => {
      DomHandler.removeClass(this.sidebar, 'layout-sidebar-active')
      this.setState({ sideBarActive: false })
    }, 250)
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    })
  }

  onMenuItemClick(event) {
    if (!event.item.items && this.isHorizontal()) {
      this.setState({
        menuActive: false,
      })
    } else if (!event.item.items) {
      // se o menu nao tiver items 'filhos' entao
      // realiza a limpeza dos items do breadCrumb apos clicar em um item unico do menu
      this.setState({
        itemsBreadCrumb: [],
      })
      storage.setOidCurrentMenu(event.item.oidMenu)
      storage.setMenuPath(event.item.caminho)
    }
  }

  isMobile() {
    return window.innerWidth <= 1024
  }

  isTablet() {
    let width = window.innerWidth
    return width <= 1024 && width > 640
  }

  isDesktop() {
    return window.innerWidth > 1024
  }

  isOverlay() {
    return this.state.layoutMode === 'overlay'
  }

  isHorizontal() {
    return this.state.layoutMode === 'horizontal'
  }

  componentDidMount() {
    this.props.load()
    this.props.setCompany(authenticationService.getDefaultCompany())
    this.props.setCurrentUser(authenticationService.getUserName())
    if (!this.props.appDefaultRoutes || this.props.appDefaultRoutes.length === 0) {
      this.props.setDefaultRoutes()
    }

    if (this.props.portalCliente) {
      this.setState({ layoutMode: 'static' })
    } else {
      const menuLayout = storage.getMenuLayout()
      if (menuLayout === 'overlay' || menuLayout === 'static') {
        this.setState({ layoutMode: menuLayout })
      }
    }
  }

  componentDidUpdate(prevProps) {
    //verificando caso for Reload da pÃ¡gina devido a mudanÃ§a de empresa/unidade nÃ£o computar o Reload.
    if (this.props.history.location.pathname !== '/reload') {
      let indexItemAnterior = null
      //Para nao repetir itens no BreadCrumb
      if (this.state.itemsBreadCrumb && this.state.itemsBreadCrumb.length > 1) {
        for (let i = 0; i < this.state.itemsBreadCrumb.length - 1; i++) {
          if (this.state.itemsBreadCrumb[i].url === this.props.history.location.pathname) {
            indexItemAnterior = i
          }
        }
        if (indexItemAnterior !== null) {
          let itensRestantes = this.state.itemsBreadCrumb.slice(0, indexItemAnterior + 1) //copiando todos os anteriores aos selecionados
          this.state.itemsBreadCrumb.splice(0, this.state.itemsBreadCrumb.length) //retirando todos os items do breadCrumb
          this.setState({ itemsBreadCrumb: itensRestantes }) //adicionando ositems ao breadCrumb
        }
      }

      //Para adicionar itens no BreadCrumb
      if (indexItemAnterior === null && this.props.history.location.pathname !== prevProps.location.pathname) {
        let obj = Object.assign({}, this.state)
        let separarUrl = this.props.history.location.pathname.split('/')
        let localMenu = separarUrl[separarUrl.length - 1]
        if (!isNaN(localMenu)) {
          localMenu = separarUrl[separarUrl.length - 2] + ' ' + localMenu
        }
        obj.itemsBreadCrumb.push({
          label: localMenu,
          url: this.props.history.location.pathname,
        })
        this.setState({ obj })
      }
    }
  }

  clickItemBreadCrumb(e, props, itemSelecionado, indexItemSelecionado) {
    let itensRestantes = props.itemsBreadCrumb.slice(0, indexItemSelecionado) //copiando todos os anteriores aos selecionados
    props.itemsBreadCrumb.splice(0, props.itemsBreadCrumb.length) //retirando todos os items do breadCrumb
    Object.assign(props.itemsBreadCrumb, itensRestantes) //adicionando os items ao breadCrumb
    props.history.replace(`${itemSelecionado.url}`)
  }

  clickHomeItemBreadCrumb = () => {
    this.setState({ itemsBreadCrumb: [] })
    if (storage.getPortalCliente()) {
      const rota = this.resolveRotaPadraoPortalCliente()
      this.props.history.replace(rota)
    } else {
      this.props.history.replace(`/`)
    }
  }

  resolveRotaPadraoPortalCliente = () => {
    const menus = storage.getMenus()
    if (utils.isArrayNullOrEmpty(menus)) {
      return `/`
    }

    const menu = menus.find((f) => f.caminho === '/portalCliente/consultaClientes')
    if (menu) {
      return `/portalCliente/consultaClientes`
    }
    return `/portalCliente/financeiro`
  }

  onChangeCompany = (event) => {
    const id = _.get(event, 'item.value', null)
    if (id) {
      const companies = _.get(this, 'props.companies', [])
      const company = companies.find((company) => company.oid === id)
      this.setCompany(company)
      this.backToDashboard()
      this.recarregarPagina()
    }
  }

  onChangeUnit = (event) => {
    const empresaAtual = storage.getCompany()
    if (empresaAtual) {
      const idUnidade = _.get(event, 'item.value', null)
      const unidades = _.get(empresaAtual, 'unidades', [])
      const unidade = unidades.find((u) => u.oid === idUnidade)
      this.setCurrentUnit(unidade)
    } else {
      this.setCurrentUnit(null)
    }
    this.backToDashboard()
  }

  backToDashboard = () => {
    this.props.history.replace('/')
    this.setState({
      itemsBreadCrumb: [],
    })
  }

  recarregarPagina() {
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  onSelectBuscaRapida = (e) => {
    this.setState({ itemsBreadCrumb: [] })
    if (e.value && e.value.command) {
      storage.setOidCurrentMenu(e.value.oidMenu)
      storage.setMenuPath(e.value.caminho)
      e.value.command(e)
    }
  }

  onSearchMenu = (event) => {
    let valor = event.query
    let opcoes = this.filterMenu(this.props.items, valor)
    utils.orderArrayByProperty(opcoes, 'labelBuscaRapida')
    this.setState({ searchOptions: opcoes })
  }

  filterMenu = (menus, textSearch) => {
    if (utils.isArrayNullOrEmpty(menus)) {
      return menus
    }
    let result = []
    menus.forEach((m) => {
      if (m) {
        if (Array.isArray(m)) {
          const items = this.filterMenu(m, textSearch)
          if (utils.isArrayNotEmpty(items)) {
            result = result.concat(items)
          }
        } else if (utils.isArrayNotEmpty(m.items)) {
          const items = this.filterMenu(m.items, textSearch)
          if (utils.isArrayNotEmpty(items)) {
            result = result.concat(items)
          }
        } else {
          if (
            (parseInt(textSearch) > -1 && m.oidMenu && m.oidMenu === parseInt(textSearch)) ||
            (m.tags && m.tags.toLowerCase().includes(textSearch.toLowerCase())) ||
            m.label.toLowerCase().includes(textSearch.toLowerCase())
          ) {
            result.push(m)
          }
        }
      }
    })
    return result
  }

  getAppRoutes() {
    if (this.props.appDefaultRoutes && this.props.appDefaultRoutes.length > 0) {
      let result = this.props.appDefaultRoutes
      if (this.props.appCustomRoutes && this.props.appCustomRoutes.length > 0) {
        result = this.props.appDefaultRoutes.concat(this.props.appCustomRoutes)
      }
      if (result && result.length > 0) {
        return result.map((route) => {
          return <PrivateRoute exact path={route.path} component={route.component} key={route} />
        })
      }
      return null
    }
    return null
  }

  buildContent = () => {
    return (
      <div className="layout-content">
        <Switch>{this.getAppRoutes()}</Switch>
      </div>
    )
  }

  resolveBreadCrumb = (props) => {
    if (props.portalCliente || (props.location.pathname && props.location.pathname.startsWith('/geral/bi/'))) {
      return <BreadCrumb model={this.state.itemsBreadCrumb} home={this.state.home} />
    }
    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb)
    return (
      <div>
        <BreadCrumb model={this.state.itemsBreadCrumb} home={this.state.home} />
        <AppBreadCrumbWithRouter
          itemsBreadCrumb={this.state.itemsBreadCrumb}
          clickItemBreadCrumb={this.clickItemBreadCrumb}
        />
      </div>
    )
  }

  getUnidadesDisponiveis(empresa) {
    const unidadesUsuario = _.get(this, 'props.unidadesUsuario', [])
    const unidadesEmpresa = _.get(empresa, 'unidades', [])
    if (unidadesUsuario.length === 0) {
      return unidadesEmpresa
    }
    const result = []
    unidadesEmpresa.forEach((ue) => {
      if (unidadesUsuario.find((uu) => uu.oid === ue.oid)) {
        result.push(ue)
      }
    })
    return result
  }

  render() {
    let wrapperClass = classNames('layout-wrapper', {
      'layout-wrapper-static': this.state.layoutMode === 'static',
      'layout-wrapper-active': this.state.mobileMenuActive,
      'layout-menu-horizontal': this.state.layoutMode === 'horizontal',
    })
    let sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.darkMenu,
    })

    const companies_ = _.get(this, 'props.companies', [])
    let companies = []
    if (utils.isArrayNotEmpty(companies_)) {
      companies = companies_.map((company) => {
        return {
          label: company.nomeEmpresa,
          value: company.oid,
          command: (e) => {
            this.onChangeCompany(e)
          },
        }
      })
      companies = companies.sort((a, b) => {
        return a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      })
    }

    let units = null
    if (utils.isArrayNotEmpty(companies_) && companies_[0]) {
      for (let i = 0; i < companies_.length; i++) {
        let oid_company = companies_[i].oid
        if (this.props.currentCompany && oid_company === this.props.currentCompany.oid) {
          units = this.getUnidadesDisponiveis(companies_[i]).map((unidade) => {
            return {
              label: unidade.sigla,
              value: unidade.oid,
              command: (e) => {
                this.onChangeUnit(e)
              },
            }
          })
        }
      }
      units.push({
        label: '',
        value: 0,
        command: (e) => {
          this.onChangeUnit(e)
        },
      })

      units = units.sort((a, b) => {
        return a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      })
    }
    // verificando caso sidebar inativo, colocando a imagem da fenix para o lado.
    let marginStyle = null
    if (this.state.sideBarActive || this.state.layoutMode === 'static') {
      marginStyle = '12px'
    } else {
      marginStyle = '185px'
    }

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
          onMouseLeave={this.onSidebarMouseLeave}
        >
          <div className="sidebar-logo">
            <button
              className="p-link"
              onClick={(e) => {
                this.clickHomeItemBreadCrumb()
              }}
            >
              <img alt="logo" src={logo} style={{ marginLeft: marginStyle }} />
              <span className="app-name">
                {this.state.sideBarActive || this.state.layoutMode === 'static' ? 'PHOENIX' : ''}
              </span>
            </button>
            <button className="p-link sidebar-anchor" title="Toggle Menu" onClick={this.onToggleMenuClick} />
          </div>

          <div className="layout-menu-container">
            <AppMenu
              model={this.props.items}
              onRootMenuItemClick={this.onRootMenuItemClick}
              layoutMode={this.state.layoutMode}
              active={this.state.menuActive}
              onMenuItemClick={this.onMenuItemClick}
            />
          </div>
        </div>

        <div className="layout-main">
          <LoadingBar />
          <AppTopbar
            companies={companies}
            units={units}
            searchOptions={this.state.searchOptions}
            companyOid={this.props.companyOid}
            unitOid={this.props.unitOid}
            layoutMode={this.state.layoutMode}
            activeTopbarItem={this.state.activeTopbarItem}
            onTopbarItemClick={this.onTopbarItemClick}
            onMenuButtonClick={this.onMenuButtonClick}
            onTopbarMobileMenuButtonClick={this.onTopbarMobileMenuButtonClick}
            topbarMenuActive={this.state.topbarMenuActive}
            onChangeCompany={this.onChangeCompany}
            onChangeUnit={this.onChangeUnit}
            onSearchMenu={this.onSearchMenu}
            onSelectBuscaRapida={this.onSelectBuscaRapida}
            portalCliente={this.props.portalCliente}
          />
          {this.resolveBreadCrumb(this.props)}
          {this.buildContent()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
