const blankToNull = require("./../../../utils").blankToNull;
const isIdValueNullOrEmpty = require("./../../../utils").isIdValueNullOrEmpty;

export function validate(record) {
  const errors = [];
  if (isIdValueNullOrEmpty(record.unidade)) {
    errors.push({ field: "unidade", message: "O campo unidade é obrigatório!" });
  }
  if (record.numeroVagas > record.numeroLugares) {
    errors.push({ field: "numeroVagas", message: "O campo 'Vagas' não pode ser maior do que 'Lugares'!" });
  }

  delete record.inputs; // apagando os inputs do formulario do objeto

  blankToNull(record);
  
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
