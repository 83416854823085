import React from 'react';
import CategoriaListPageContainer from './CategoriaListPageContainer';

export class CategoriaListPage extends React.Component{
    
    render(){
      return (
        <CategoriaListPageContainer {...this.props}/>
      )
    }
}

