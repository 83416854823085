import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as pessoaActions } from '../../pessoaFisica/pessoaFisica.actions'
import { actions } from '../unidade.actions'
import { actions as appActions } from '../../../store/app'
import { actions as empresaActions } from '../../empresa/empresa.actions'
import { UnidadeForm } from './UnidadeForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudUnidadeState.messages
  const record = state.crudUnidadeState.currentRecord

  const lookupPessoaBase = {
    records: state.appState.lookupPessoa.records,
    columns: state.appState.lookupPessoa.columns
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.appState.lookupPessoa.index,
    rows: state.appState.lookupPessoa.max,
    totalRecords: state.appState.lookupPessoa.count,
    sortField: state.appState.lookupPessoa.sortField,
    order: state.appState.lookupPessoa.sortOrder,
    editDisabled: false,
  }
  let lookupPessoa = Object.assign({}, state.crudUnidadeState.lookupPessoa, lookupPessoaBase) //FIXME - REVER ESSA CONFIG

  const lookupEmpresaBase = {
    records: state.crudEmpresaState.records,
    columns: state.crudEmpresaState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nomeEmpresa')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudEmpresaState.index,
    rows: state.crudEmpresaState.max,
    totalRecords: state.crudEmpresaState.count,
    sortField: state.crudEmpresaState.sortField,
    order: state.crudEmpresaState.sortOrder,
    editDisabled: false,
  }
  let lookupEmpresa = Object.assign({}, state.crudUnidadeState.lookupEmpresa, lookupEmpresaBase)

  return {
    record,
    lookupPessoa,
    lookupEmpresa,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // ----- metodos para lookup de pessoas -----
    lookupPessoaSetVisible: (visible) => dispatch(actions.setLookupPessoaVisible(visible)),
    lookupPessoaPaginate: (paginationParams) => dispatch(appActions.lookupPessoaPaginate(paginationParams)),
    lookupPessoaSortOrder: (sortParams) => dispatch(appActions.lookupPessoaSortOrder(sortParams)),
    lookupPessoaApplyFilter: (filterParams) => {
      const payload = { field: filterParams.campo, value: filterParams.valor, relacionamento: null }
      dispatch(appActions.lookupPessoaSetSelectedFilters(payload))
      dispatch(appActions.lookupPessoaApplyFilter(filterParams))
    },
    lookupPessoaLoad: () => dispatch(appActions.lookupPessoaLoad()),
    // -------------------------------------------

    lookupEmpresaSetVisible: (visible) => dispatch(actions.setLookupEmpresaVisible(visible)),
    lookupEmpresaPaginate: (paginationParams) => dispatch(empresaActions.paginate(paginationParams)),
    lookupEmpresaSortOrder: (sortParams) => dispatch(empresaActions.sortOrder(sortParams)),
    lookupEmpresaApplyFilter: (filterParams) => {
      dispatch(empresaActions.setType(null))
      dispatch(empresaActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(empresaActions.applyFilter(filterParams))
    },
    lookupEmpresaLoad: () => dispatch(empresaActions.loadLookup()),
    setEmpresa: (empresa) => {
      dispatch(actions.setField('empresa.id', empresa != null ? empresa.oid : null))
      dispatch(actions.setField('empresa.value', empresa != null ? empresa.nomeEmpresa : ''))
    },
    changeHandler: (event) => {
      const booleanFields = ['ativo', 'matriz']
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (booleanFields.find((current) => current === field)) {
        value = target.checked
        dispatch(actions.setField(field, value))
      }
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    clearForm: () => {
      dispatch(actions.makeNew())
      dispatch(actions.setField('empresa.id', null))
      dispatch(actions.setField('empresa.value', null))
      dispatch(actions.setField('pessoa.id', null))
      dispatch(actions.setField('pessoa.value', null))
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    clearSelectedFilters: () => {
      dispatch(pessoaActions.clearSelectedFilters())
      dispatch(empresaActions.clearSelectedFilters())
    },
  }
}

class UnidadeFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_UNIDADE}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_UNIDADE}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_UNIDADE}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.clearForm()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_UNIDADE}`)
  }

  lookupPessoaOnComplete = (e) => {}

  lookupPessoaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'nome',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }

      this.props.setField('pessoa', e.value)
      this.props.setField('oidPessoa', null)
      this.props.lookupPessoaApplyFilter(filter)
    } else {
      this.props.setPessoa(value)
    }
  }

  lookupPessoaOnPage = (e) => {
    this.props.lookupPessoaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPessoaOnSort = (e) => {
    this.props.lookupPessoaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPessoaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupPessoaApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupPessoaLoad()
    }
  }
  lookupPessoaOnClick = (e) => {
    this.props.lookupPessoaLoad()
    this.props.lookupPessoaSetVisible(true)
  }
  lookupPessoaOnHide = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnConfirm = (e) => {
    this.props.setField('pessoa.value', e.selection.nome)
    this.props.setField('pessoa.id', e.selection.oid)
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnCancel = (e) => {
    this.props.setField('pessoa', { id: null, value: null })
    this.props.lookupPessoaSetVisible(false)
  }

  lookupPessoaOnEdit = () => {
    if (this.props.record.oidPessoa) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.record.oidPessoa}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}/`
    }
  }
  lookupEmpresaOnPage = (e) => {
    this.props.lookupEmpresaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupEmpresaOnSort = (e) => {
    this.props.lookupEmpresaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupEmpresaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupEmpresaApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupEmpresaLoad()
    }
  }
  lookupEmpresaOnClick = (e) => {
    this.props.lookupEmpresaLoad()
    this.props.lookupEmpresaSetVisible(true)
  }
  lookupEmpresaOnHide = (e) => {
    this.props.lookupEmpresaSetVisible(false)
  }
  lookupEmpresaOnConfirm = (e) => {
    this.props.setEmpresa(e.selection)
    this.props.lookupEmpresaSetVisible(false)
  }
  lookupEmpresaOnCancel = (e) => {
    this.props.setEmpresa(null)
    this.props.lookupEmpresaSetVisible(false)
  }

  lookupPessoaOnEdit = () => {
    if (this.props.record.pessoa && this.props.record.pessoa.id) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.record.pessoa.id}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}`
    }
  }
  lookupPessoaOnComplete = (e) => {}

  lookupPessoaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'nome',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      this.props.setField('pessoa.value', e.value)
      this.props.setField('pessoa.id', null)
      this.props.lookupPessoaApplyFilter(filter)
    } else {
      this.props.setField('pessoa.value', e.value.nome)
      this.props.setField('pessoa.id', e.value.oid)
    }
  }

  lookupEmpresaOnEdit = () => {
    if (this.props.record.empresa && this.props.record.empresa.id) {
      return `${routes.PATH_EMPRESA}/${this.props.record.empresa.id}`
    } else {
      return `${routes.PATH_EMPRESA}`
    }
  }
  lookupEmpresaOnComplete = (e) => {}

  lookupEmpresaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'nomeEmpresa',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }
      this.props.setField('empresa.value', e.value)
      this.props.setField('empresa.id', null)
      this.props.lookupEmpresaApplyFilter(filter)
    } else {
      this.props.setEmpresa(value)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <UnidadeForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        messages={this.props.messages}
        history={this.props.history}
        lookupPessoa={this.props.lookupPessoa}
        lookupPessoaOnHide={this.lookupPessoaOnHide}
        lookupPessoaOnConfirm={this.lookupPessoaOnConfirm}
        lookupPessoaOnCancel={this.lookupPessoaOnCancel}
        lookupPessoaOnPage={this.lookupPessoaOnPage}
        lookupPessoaOnSort={this.lookupPessoaOnSort}
        lookupPessoaOnFilter={this.lookupPessoaOnFilter}
        lookupPessoaOnClick={this.lookupPessoaOnClick}
        lookupPessoaOnEdit={this.lookupPessoaOnEdit}
        lookupPessoaOnComplete={this.lookupPessoaOnComplete}
        lookupPessoaOnInputFilter={this.lookupPessoaOnInputFilter}
        lookupEmpresa={this.props.lookupEmpresa}
        lookupEmpresaOnHide={this.lookupEmpresaOnHide}
        lookupEmpresaOnConfirm={this.lookupEmpresaOnConfirm}
        lookupEmpresaOnCancel={this.lookupEmpresaOnCancel}
        lookupEmpresaOnPage={this.lookupEmpresaOnPage}
        lookupEmpresaOnSort={this.lookupEmpresaOnSort}
        lookupEmpresaOnFilter={this.lookupEmpresaOnFilter}
        lookupEmpresaOnClick={this.lookupEmpresaOnClick}
        lookupEmpresaOnEdit={this.lookupEmpresaOnEdit}
        lookupEmpresaOnComplete={this.lookupEmpresaOnComplete}
        lookupEmpresaOnInputFilter={this.lookupEmpresaOnInputFilter}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnidadeFormPageContainer)
