import React, {useEffect, useState} from 'react'
import utils from '../../utils'
import {Calendar} from 'primereact/calendar'
import apiService from "../../services/api.service";
import _ from "lodash";
import {InputText} from "primereact/inputtext";

export default function LtsCamposComplementares(props) {

  const onChange = (campo, event) => {
    const dadosComplementares = _.get(props.record, `dadosComplementares`, {})
    dadosComplementares[campo] = event.target.value
    props.setField(dadosComplementares)
  }

  return (
    <React.Fragment>
      <div className="p-grid form-group">
        {
          Object.keys(props.camposComplementares).map((nomeCampo) => (
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <span className="p-float-label">
              <InputText
                value={_.get(props.record, `dadosComplementares.${nomeCampo}`)}
                onChange={(e) => onChange(nomeCampo, e)}
                name={nomeCampo}
                className={'width-25-rem'}
              />
              <label>{props.camposComplementares[nomeCampo].label}</label>
            </span>
            </div>
          ))
        }
      </div>
    </React.Fragment>
  )
}
