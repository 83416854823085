import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import { messageActions } from '../../store/message/index'

const BASE_PATH = '/lottustech/pessoasjuridicas'
const crud = build(validate, BASE_PATH)
const prefix = 'crudPessoaJuridica'

const newTypes = {
  SET_LOOKUP_CATEGORIA_VISIBLE: `${prefix}setLookupCategoriaVisible`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.enviarWhatsApp = (telefone) => {
  return (dispatch, getState) => {
    const state = getState()[`crudPessoaJuridicaState`]
    const oidPessoa = state.currentRecord.oid
    if (!telefone) {
      telefone = ' '
    }
    apiService.post(`${BASE_PATH}/enviarwhatsapp/${oidPessoa}/${telefone}/`).then((response) => {
      if (actions.carregarMensageria) {
        actions.carregarMensageria(response.data)
      }
    })
  }
}

actions.enviarEmail = (telefone) => {
  return (dispatch, getState) => {
    const state = getState()[`crudPessoaJuridicaState`]
    const oidPessoa = state.currentRecord.oid
    apiService
      .post(`${BASE_PATH}/enviaremail/${oidPessoa}/`)
      .then((response) => {
        if (actions.carregarMensageria) {
          actions.carregarMensageria(response.data)
        }
      })
      .catch((err) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Erro na formação da mensagem!'], 'error')))
      })
  }
}

actions.setLookupCategoriaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CATEGORIA_VISIBLE,
    visible,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

const makeMessages = (messages, type) => {
  let sticky = false
  let summary = 'Sucesso!'
  if (type === 'error') {
    summary = 'Erro!'
    sticky = true
  }
  return messages.map((message) => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message,
    }
  })
}
export { types, actions }
