import { types } from './lancamento.actions'
import { Reducer } from '../../services/reducer.factory'
import utils from '../../utils/index'
import storage from '../../services/stoage.service'
import _ from 'lodash'

const initialState = {
  id: 'lancamentoFinanceiro',
  selectedFilters: [],
  selectedParcelas: [],
  booleanFilter: { tipoGrupoFinanceiro: '', status: '' },
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Emissão',
      value: {
        field: 'emissao',
        header: 'Emissão',
        columnKey: 'emissao',
        sortable: true,
        filter: true,
        columnSortField: 'emissao',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Vencimento',
      value: {
        field: 'vencimento',
        header: 'Vencimento',
        columnKey: 'vencimento',
        sortable: true,
        filter: true,
        columnSortField: 'vencimento',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Pagamento',
      value: {
        field: 'dataPagamento',
        header: 'Pagamento',
        columnKey: 'dataPagamento',
        sortable: true,
        filter: true,
        columnSortField: 'dataPagamento',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Forma de Pag.',
      value: {
        field: 'tipoFormaPagamento',
        header: 'Forma de Pag.',
        columnKey: 'tipoFormaPagamento',
        filter: true,
        sortable: true,
        columnSortField: 'tipoFormaPagamento',
        visible: true,
      },
    },
    {
      label: 'Grupo',
      value: {
        field: 'grupoItemPrincipal_descricao',
        header: 'Grupo',
        columnKey: 'grupoItemPrincipal',
        sortable: true,
        filter: true,
        columnSortField: 'descricao',
        relacionamento: ['grupoItemPrincipal'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        filter: true,
        columnSortField: 'observacao',
        visible: true,
      },
    },
    {
      label: 'Valor',
      value: {
        field: 'valor',
        header: 'Valor',
        columnKey: 'valor',
        sortable: true,
        filter: true,
        columnSortField: 'valor',
        fieldFormatter: 'formatarDinheiro',
        visible: true,
      },
    },
    {
      label: 'Valor pago',
      value: {
        field: 'valorPago',
        header: 'Valor pago',
        columnKey: 'valorPago',
        sortable: true,
        filter: true,
        columnSortField: 'valorPago',
        fieldFormatter: 'formatarDinheiro',
        visible: true,
      },
    },
    {
      label: 'Tipo',
      value: {
        field: 'grupoItemPrincipal_tipo',
        header: 'Tipo',
        columnKey: 'tipo',
        sortable: true,
        filter: true,
        columnSortField: 'tipo',
        relacionamento: ['grupoItemPrincipal'],
        fieldJoin: 'tipo',
        visible: true,
        fieldFormatter: 'getValue',
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
    {
      label: 'Contrato',
      value: {
        field: 'oidContrato',
        header: 'Contrato',
        columnKey: 'contrato',
        sortable: true,
        filter: true,
        columnSortField: 'oidContrato',
        visible: true,
      },
    },
    {
      label: 'Contrato',
      value: {
        field: 'contrato_numero',
        header: 'Núm. Contrato',
        columnKey: 'numeroContrato',
        sortable: true,
        filter: true,
        columnSortField: 'contrato',
        visible: true,
        relacionamento: ['contrato'],
        fieldJoin: 'numero',
      },
    },
    {
      label: 'Status',
      value: {
        field: 'status',
        header: 'Status',
        columnKey: 'status',
        sortable: true,
        filter: true,
        columnSortField: 'status',
        visible: true,
        fieldFormatter: 'getValue',
      },
    },
    {
      label: 'Boleto',
      value: {
        field: 'boleto_oid',
        header: 'Boleto',
        columnKey: 'oidBoleto',
        sortable: true,
        filter: true,
        columnSortField: 'boleto.oid',
        visible: true,
      },
    },
    {
      label: 'Pagador',
      value: {
        field: 'pagador_nome',
        header: 'Pagador',
        columnKey: 'pagador',
        sortable: true,
        filter: true,
        columnSortField: 'pagador.nome',
        relacionamento: ['pagador'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Importação',
      value: {
        field: 'dataImportacao',
        header: 'Importação',
        columnKey: 'dataImportacao',
        fieldFormatter: 'formatarData',
        sortable: true,
        filter: true,
        columnSortField: 'dataImportacao',
        visible: false,
      },
    },
    {
      label: 'Localização',
      value: {
        field: 'localizacao_identificacao',
        header: 'Localização',
        columnKey: 'localizacao',
        sortable: true,
        filter: true,
        columnSortField: 'localizacao.identificacao',
        visible: true,
        relacionamento: ['localizacao'],
        fieldJoin: 'identificacao',
      },
    },
    {
      label: 'Conta corrente',
      value: {
        field: 'contaCorrente_descricao',
        header: 'Conta corrente',
        columnKey: 'contaCorrente',
        columnSortField: 'contaCorrente.descricao',
        sortable: true,
        filter: true,
        relacionamento: ['contaCorrente'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    lancamentoOrigem: '',
    lancamentoContrapartida: '',
    observacao: '',
    pagador: '',
    taxas: [],
    unidade: {},
    contrato: '',
    notaEntrada: '',
    contaCorrente: '',
    valor: 0.0,
    valorBruto: 0.0,
    emissao: '',
    status: '',
    tipoFormaPagamento: { id: '', value: '' },
    lancamentos: [],
    valorPago: '',
    dataPagamento: '',
    dataImportacao: '',
    dataPagamentoParcela: new Date(),
    promissoria: false,
    registroImportado: false,
    conspagaUltimoPagamento: '',
    conspagaPagarAte: '',
    tipoDesconto: '',
    valorDesconto: '',
    percentualDesconto: '',
    tipoJuros: 2,
    valorJuros: '',
    percentualJuros: '',
    jurosPorDia: true,
    tipoMulta: '',
    valorMulta: '',
    percentualMulta: '',
    oidNfse: '',
    vencimento: '',
    conspaga: '',
    habilitarCamposConspaga: false,
    oidItemPrincipal: '',
    pessoaCartaoCredito: {},
    cpfResponsavelPJ: '',
    descricaoServicoNFSe: '',
    dataPrevisaoRecebimento: '',
    dataRecebimento: '',
    valorRecebido: '',
  },
  pagamentos: [],
  colunasPagamentos: [
    { field: 'multiselect' },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
    },
    {
      field: 'tipoFormaPagamento',
      header: 'Forma de Pagamento',
      columnKey: 'tipoFormaPagamento',
    },
    {
      field: 'vencimento',
      header: 'Vencimento',
      columnKey: 'vencimento',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
    },
    {
      field: 'unidade',
      header: 'Unidade',
      columnKey: 'unidade',
    },
    {
      field: 'contaCorrente',
      header: 'Conta',
      columnKey: 'contaCorrente',
    },
    {
      field: 'grupoItemPrincipal',
      header: 'Grupo',
      columnKey: 'grupoItemPrincipal',
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
    },
    {
      field: 'promissoria',
      header: 'Promissoria',
      columnKey: 'promissoria',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasTaxa: [
    {
      field: 'id',
      header: 'Código',
      columnKey: 'id',
    },
    {
      field: 'value',
      header: 'Grupo',
      columnKey: 'value',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
    },
    {
      field: 'conspagaUltimoPagamento',
      header: 'Paga',
      columnKey: 'conspagaUltimoPagamento',
    },
    {
      field: 'conspagaPagarAte',
      header: 'Até',
      columnKey: 'conspagaPagarAte',
    },
    {
      field: 'valorTotal',
      header: 'Valor Total',
      columnKey: 'valorTotal',
    },
    {
      field: 'descricaoServico',
      header: 'Descrição Serviço',
      columnKey: 'descricaoServico',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasGridFiltroTaxa: [
    {
      field: 'id',
      header: 'Código',
      columnKey: 'id',
    },
    {
      field: 'value',
      header: 'Grupo',
      columnKey: 'value',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  pagamento: {
    oid: '',
    formaPagamento: { id: null, value: null },
    valor: '',
    primeiroVencimento: new Date(),
    numeroParcelas: 1,
    promissoria: false,
    valorEntrada: '',
    formaPagamentoEntrada: { id: 6, value: 'Dinheiro' },
  },
  lookupTaxa: {
    visible: false,
    modal: true,
    header: 'Taxa',
  },
  lookupPagador: {
    visible: false,
    modal: true,
    header: 'Pagador',
  },
  lookupContrato: {
    visible: false,
    modal: true,
    header: 'Contrato',
  },
  cartoesPagador: [],
  formaPagamentoOriginal: '',
  contasCorrentes: [],
  lookupNotaEntrada: {
    visible: false,
    modal: true,
    header: 'Nota entrada',
  },
  messages: null,
  usaListDto: false,
  adicionandoAnexo: false,
  contasCorrentesParcelas: [],
}

const reducer = new Reducer(initialState, types)

export function CRUDLancamentoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('lancamentofinanceiro')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_LOOKUP_TAXA_VISIBLE:
      return setLookupTaxaVisible(state, action)
    case types.SET_LOOKUP_PAGADOR_VISIBLE:
      return setLookupPagadorVisible(state, action)
    case types.SET_LOOKUP_CONTRATO_VISIBLE:
      return setLookupContratoVisible(state, action)
    case types.SET_LOOKUP_NOTAENTRADA_VISIBLE:
      return setLookupNotaEntradaVisible(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_DISPLAY_FORM:
      return setDisplayForm(state, action)
    case types.SET_DADO_FORMA_PAGAMENTO:
      return setDadoFormaPagamento(state, action)
    case types.ADICIONAR_PAGAMENTO:
      return adicionarPagamento(state, action)
    case types.CLEAR_FORM_PAGAMENTO:
      return clearFormPagamento(state)
    case types.SET_SELECTED_PARCELAS:
      return Object.assign({}, state, { selectedParcelas: action.records })
    case types.CLEAR_SELECTED_PARCELAS:
      return Object.assign({}, state, { selectedParcelas: [] })
    case types.BOFORE_SAVE:
      return beforeSave(state)
    case types.RESET:
      return reset(state, action)
    case types.SET_OID_TAXA_PRINCIPAL:
      return setOidTaxaPrincipal(state, action)
    case types.SET_CARTOES_PAGADOR:
      return setCartoesPagador(state, action)
    case types.ADD_ITEM_LANCAMENTO:
      return addItemLancamento(state, action)
    case types.SET_FORMA_PAGAMENTO_ORIGINAL:
      return setFormaPagamentoOriginal(state, action)
    case types.ADD_ANEXO:
      return addAnexo(state, action)
    case types.SET_ADICIONANDO_ANEXO:
      return Object.assign({}, state, { adicionandoAnexo: action.payload })
    case types.REMOVE_ANEXO:
      return removeAnexo(state, action)
    case types.SET_CONTAS_CORRENTES:
      return setContasCorrentes(state, action)
    case types.SET_CONTAS_CORRENTES_PARCELAS:
      return setContasCorrentesParcelas(state, action)

    default:
      return result
  }
}

function setOidTaxaPrincipal(state, action) {
  return state
}

function setFormaPagamentoOriginal(state, action) {
  Object.assign(state, { formaPagamentoOriginal: action.payload })
  return state
}

function setContasCorrentes(state, action) {
  Object.assign(state, { contasCorrentes: action.payload })
  return state
}

function setContasCorrentesParcelas(state, action) {
  Object.assign(state, { contasCorrentesParcelas: action.payload })
  return state
}

function setCartoesPagador(state, action) {
  Object.assign(state, { cartoesPagador: action.payload })
  return state
}

function addItemLancamento(state, action) {
  const data = action.data
  const cobertura = action.cobertura
  const taxa = {
    itemLancamento: {
      tipo: data.tipo,
      oidTipo: data.oidTipo,
    },
    descricao: data.descricao,
    oidGrupo: data.oidGrupo,
    entradaSaida: data.entradaSaida,
    valor: cobertura.valor,
    valorTotal: cobertura.quantidade * cobertura.valor,
    quantidade: cobertura.quantidade,
  }

  let taxas = state.currentRecord.taxas || []
  taxas.push(taxa)

  Object.assign(state.currentRecord.taxas, taxas)

  if (!state.currentRecord.oidItemPrincipal) {
    state.currentRecord.oidItemPrincipal = _.get(data, 'oidTipo', null)
    state.currentRecord.tipoItemPrincipal = _.get(data, 'tipo', null)
  }
  return state
}

function setDisplayForm(state, action) {
  let displayForm = Object.assign({}, state.displayForm)
  let valor = action.value
  displayForm[action.field] = valor
  return Object.assign({}, state, { displayForm })
}

function setDadoFormaPagamento(state, action) {
  let pagamento = Object.assign({}, state.pagamento)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = pagamento
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  return Object.assign({}, state, { pagamento })
}

function adicionarPagamento(state, action) {
  let lancamentosAtuais = state.currentRecord.lancamentos
  lancamentosAtuais = lancamentosAtuais.concat(action.value)
  if (lancamentosAtuais.length > 0) {
    for (let i = 0; i < lancamentosAtuais.length; i++) {
      if (lancamentosAtuais[i].oid && lancamentosAtuais[i].oid !== '') {
        lancamentosAtuais[i].hash = lancamentosAtuais[i].oid
      } else {
        lancamentosAtuais[i].hash = i + 1
      }
      if (utils.isNumber(lancamentosAtuais[i].valor)) {
        lancamentosAtuais[i].valor = utils.formatCurrency(lancamentosAtuais[i].valor)
      }
    }
  }
  let lancamentosOrdenados = utils.orderArrayByProperty(lancamentosAtuais, 'vencimento')
  if (!utils.isArrayNullOrEmpty(lancamentosOrdenados)) {
    Object.assign(state.currentRecord.lancamentos, lancamentosOrdenados)
  } else {
    Object.assign(state.currentRecord.lancamentos, lancamentosAtuais)
  }

  state = clearFormPagamento(state)
  return state
}

function clearFormPagamento(state) {
  return Object.assign({}, state, {
    pagamento: Object.assign(state.pagamento, initialState.pagamento),
  })
}
function setLookupTaxaVisible(state, action) {
  let lookupTaxa = state.lookupTaxa
  lookupTaxa = Object.assign({}, lookupTaxa, { visible: action.visible })
  return Object.assign({}, state, { lookupTaxa })
}

function setLookupPagadorVisible(state, action) {
  let lookupPagador = state.lookupPagador
  lookupPagador = Object.assign({}, lookupPagador, { visible: action.visible })
  return Object.assign({}, state, { lookupPagador })
}

function setLookupContratoVisible(state, action) {
  let lookupContrato = state.lookupContrato
  lookupContrato = Object.assign({}, lookupContrato, {
    visible: action.visible,
  })
  return Object.assign({}, state, { lookupContrato })
}

function setLookupNotaEntradaVisible(state, action) {
  let lookupNotaEntrada = state.lookupNotaEntrada
  lookupNotaEntrada = Object.assign({}, lookupNotaEntrada, {
    visible: action.visible,
  })
  return Object.assign({}, state, { lookupNotaEntrada })
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function beforeSave(state) {
  const currentRecord = state.currentRecord
  currentRecord.valor = utils.parseStringToNumber(currentRecord.valor)
  return Object.assign({}, state, { currentRecord: currentRecord })
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    pagamentos: initialState.pagamentos,
    pagamento: initialState.pagamento,
  }
}

function addAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexos = action.payload
  if (anexos) {
    currentRecord.anexos.push(...anexos)
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}

function removeAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexoRemover = action.payload
  if (anexoRemover) {
    const anexos = currentRecord.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find((f) => f.filename === anexoRemover.filename)
      if (temp) {
        const index = anexos.indexOf(temp)
        anexos.splice(index, 1)
      }
    }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}
