import React from 'react';
import PortalClienteFinanceiroListPageContainer from './PortalClienteFinanceiroListPageContainer';

export class PortalClienteFinanceiroListPage extends React.Component{
    
    render(){
      return (
        <PortalClienteFinanceiroListPageContainer {...this.props}/>
      )
    }
}

