import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Page } from '../../../components/page/Page'
import { MultiSelect } from 'primereact/multiselect'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { Filtro } from '../../../utils/Filtro.js'
import { routes, icons } from '../../../constants'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { actions } from '../mensageria.actions'
import { MensageriaForm } from './MensageriaForm'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudMensageriaState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  const record = state.crudMensageriaState.currentRecord
  const tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidades: [unidade] })
    disableUnit = true
  }

  let unidades = []
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)

  // LOOKUP REFERENTE A ADIÇÃO DE DESTINATARIO
  const lookupDestinatarioBase = {
    records: state.crudMensageriaState.currentRecord.opcoesDestinatariosLookup,
    columns: state.crudMensageriaState.columnsLookupDestinatario
      .filter(
        (col) =>
          col.field === 'numeroDocumento' || col.field === 'nome' || col.field === 'email' || col.field === 'telefone'
      )
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    editDisabled: false,
  }

  let lookupDestinatario = Object.assign({}, state.crudMensageriaState.lookupDestinatario, lookupDestinatarioBase)

  return {
    record,
    messages,
    messagesDialog,
    opcoesUnidades: unidades,
    destinatario: state.crudMensageriaState.destinatario,
    opcoesTiposPessoas: [
      { id: 1, value: 'Responsáveis' },
      { id: 2, value: 'Dependentes' },
      { id: 3, value: '1º Responsável' },
      { id: 4, value: 'Convênio' },
    ],
    tiposFiltros,
    disableUnit,
    lookupDestinatario,
    colunasTPessoa: state.crudMensageriaState.colunasTPessoa,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    lookupDestinatarioSetVisible: (visible) => dispatch(actions.setLookupDestinatarioVisible(visible)),
    lookupDestinatarioPaginate: (paginationParams) => dispatch(actions.loadLookupDestinatario(paginationParams)),
    lookupDestinatarioSortOrder: (sortParams) => dispatch(actions.loadLookupDestinatario(sortParams)),
    lookupDestinatarioApplyFilter: (filterParams) => {
      dispatch(actions.setFiltroAvancado(filterParams))
      dispatch(actions.loadLookupDestinatario())
    },
    setNomeDestinatario: (destinatario) => {
      dispatch(actions.adicionarNomeDestinatario('oidDestinatario', destinatario != null ? destinatario.oid : null))
      dispatch(actions.adicionarNomeDestinatario('destinatario', destinatario != null ? destinatario.nome : ''))
    },
    setDestinatario: (destinatario) => dispatch(actions.setDestinatario('destinatario', destinatario)),
    lookupDestinatarioLoad: () => dispatch(actions.loadLookupDestinatario()),

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    changeHandlerEditor: (event) => dispatch(actions.setField('mensagem', event.htmlValue)),
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    carregarTiposSepulturas: (unidade) => dispatch(actions.carregarTiposSepulturas(unidade)),
    carregarCategorias: () => dispatch(actions.carregarCategorias()),
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    filtrarResultados: (params) => dispatch(actions.filtrarResultados(params)),
    enviarMensagem: (params) => dispatch(actions.enviarMensagem(params)),
    clearRecords: () => {
      dispatch(actions.setRecords([]))
      dispatch(actions.setField('opcoesDestinatariosLookup', []))
    },
    //--------- referente a favoritos
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (favorito) => {
      dispatch(actions.removeFavorito(favorito))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    //--------- referente a anexos
    addAnexo: (anexo) => dispatch(actions.processaAnexo(anexo)),
    downloadAnexo: (anexo) => dispatch(actions.downloadAnexo(anexo)),
    removeAnexo: (anexo) => dispatch(actions.removeAnexo(anexo)),
  }
}

class MensageriaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTablePessoas = React.createRef()
    this.fieldEditor = this.fieldEditor.bind(this)
    this.showColumnButton = false
    this.showCSVButton = false
    this.showPesquisarButton = false
    this.showFilter = false
    this.state = {
      showConfirmarEnviarMensagem: false,
      dadosFavorito: null,
      favorito: 0,
      filtroConspaga: {
        tipoFiltro: props.tiposFiltros[4], // MAIOR OU IGUAL QUE
        conspagaInicial: window.moment().year(),
        conspagaFinal: window.moment().year(),
        mostrarAnoInicial: true,
        mostrarAnoFinal: false,
      },
      filtroDataFalecimento: {
        tipoFiltro: props.tiposFiltros[0], // OPÇÃO SELECIONE
        dataFalecimentoInicial: new Date(),
        dataFalecimentoFinal: new Date(),
        mostrarAnoInicial: false,
        mostrarAnoFinal: false,
      },
    }

    actions.afterNew = () => {
      this.props.carregarTiposSepulturas()
      this.props.carregarCategorias()
      const destinatarioEmBranco = [
        {
          oid: new Date().getTime(),
          manual: true,
        },
      ]
      this.props.setField('destinatarios', destinatarioEmBranco)
    }
  }

  hideDialog = () => {
    this.setState({ showConfirmarEnviarMensagem: false })
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.carregarTiposSepulturas()
    this.props.carregarCategorias()
    listPageUtils.loadFavorito(this, actions)
    if (this.props.location.state && this.props.location.state.mensagem) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      const paramsMensagem = this.props.location.state.mensagem
      this.props.setField('tipoMensagem', paramsMensagem.tipoMensagem)
      this.props.setField('destinatarios', paramsMensagem.destinatarios)
      this.props.setField('mensagem', paramsMensagem.mensagem)
    }
  }

  componentWillUnmount() {
    this.props.newHandler()
  }

  carregarTiposSepulturas = () => {
    this.props.carregarTiposSepulturas(this.props.record.unidade)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }
  //-------------------
  setDestinatario = (destinatario) => {
    let result = {
      oid: destinatario.oid,
      nome: destinatario.nome,
      contato: this.props.record.tipoMensagem === 1 ? destinatario.email : destinatario.telefone,
    }
    this.props.setDestinatario(result)
  }

  lookupDestinatarioOnPage = (e) => {
    this.props.lookupDestinatarioPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupDestinatarioOnSort = (e) => {
    this.props.lookupDestinatarioSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupDestinatarioOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupDestinatarioApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupDestinatarioLoad()
    }
  }

  lookupDestinatarioOnClick = (e) => {
    this.props.lookupDestinatarioLoad()
    this.props.lookupDestinatarioSetVisible(true)
  }
  lookupDestinatarioOnHide = (e) => {
    this.props.lookupDestinatarioSetVisible(false)
  }
  lookupDestinatarioOnConfirm = (e) => {
    this.setDestinatario(e.selection)
    this.props.lookupDestinatarioSetVisible(false)
  }
  lookupDestinatarioOnCancel = (e) => {
    this.props.lookupDestinatarioSetVisible(false)
  }
  lookupDestinatarioOnEdit = () => {
    return `${routes.PATH_PESSOA_FISICA}/`
  }
  lookupDestinatarioOnComplete = (e) => {}

  lookupDestinatarioOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let destinatario = { nome: e.value, oid: null }
      this.props.setNomeDestinatario(destinatario)
      this.props.clearRecords()
    } else {
      this.setDestinatario(value)
      this.props.setNomeDestinatario({ nome: '', oid: null })
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupDestinatarioOnKeyPress = (e) => {
    if (utils.nonNull(this.props.destinatario) && utils.nonNull(this.props.destinatario.destinatario)) {
      const filtroNome = new Filtro('nome', this.props.destinatario.destinatario, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.destinatario.destinatario, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupDestinatarioApplyFilter(filtroOu)
    }
  }
  //-------------------

  onEditorValueChange(props, value) {
    let pessoasAtualizadas = [...props.value]
    pessoasAtualizadas[props.rowIndex][props.field] = value
    this.props.setField('destinatarios', pessoasAtualizadas)
  }
  fieldEditor(props) {
    return this.inputTextEditor(props)
  }
  inputTextEditor(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[props.columnKey]}
        onChange={(e) => this.onEditorValueChange(props, e.target.value)}
      />
    )
  }
  retirarTodosDestinatarios = () => {
    this.props.setField('destinatarios', [])
  }
  removerPessoa = (pessoaRemovida) => {
    let pessoas = this.props.record.destinatarios
    let pessoasRestantes = pessoas.filter((pessoa) => {
      return pessoa.oid && pessoa.oid !== pessoaRemovida.oid
    })
    this.props.setField('destinatarios', pessoasRestantes)
  }
  filtrarResultados = () => {
    let record = this.props.record
    let restFilterConspaga
    if (this.state.filtroConspaga) {
      restFilterConspaga = {
        campo: 'conspaga',
        valor: this.state.filtroConspaga.conspagaInicial,
        valorComplementar: this.state.filtroConspaga.conspagaFinal,
        tipoFiltro: this.state.filtroConspaga.tipoFiltro.value,
      }
    }
    let restFilterDataFalecimento
    if (this.state.filtroDataFalecimento) {
      restFilterDataFalecimento = {
        campo: 'dataFalecimento',
        valor: this.state.filtroDataFalecimento.dataFalecimentoInicial,
        valorComplementar: this.state.filtroDataFalecimento.dataFalecimentoFinal,
        tipoFiltro: this.state.filtroDataFalecimento.tipoFiltro.value,
      }
    }
    const params = {
      unidades: utils.convertArrayIdLabelToIdValue(record.unidades),
      tiposPessoas: utils.convertArrayIdLabelToIdValue(record.tiposPessoas),
      tiposSepulturas: utils.convertArrayIdLabelToIdValue(record.tiposSepulturas),
      categorias: utils.convertArrayIdLabelToIdValue(record.categorias),
      tipoMensagem: record.tipoMensagem,
      conspaga: restFilterConspaga,
      dataFalecimento: restFilterDataFalecimento,
    }
    this.props.filtrarResultados(params)
  }

  confirmarEnvioMensagem = () => {
    this.setState({ showConfirmarEnviarMensagem: true })
  }

  getDialogConfirmarEnvioMensagem() {
    if (this.state.showConfirmarEnviarMensagem) {
      const record = this.props.record
      let phones = utils.resolveContatosString(record.destinatariosString)

      let msgKey = 'mensageria.dialog.message.confirmar.envio.whatsapp'
      if (record.tipoMensagem === 1) {
        msgKey = 'mensageria.dialog.message.confirmar.envio.email'
      }
      let msg = utils.getMessage(this.props.messagesDialog, msgKey)
      const length = phones.length + record.destinatarios.length
      msg = msg.replace('%s', length)

      return (
        <ConfirmDialog
          visible={this.state.showConfirmarEnviarMensagem}
          content={msg}
          confirm={this.enviarMensagem}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  enviarMensagem = () => {
    let record = this.props.record
    let destinatarios = record.destinatarios
    const contatosManuais = utils.resolveContatosString(record.destinatariosString)
    if (utils.isArrayNotEmpty(contatosManuais)) {
      destinatarios = destinatarios.concat(contatosManuais)
    }
    const params = {
      assunto: record.assunto,
      mensagem: record.mensagem,
      anexos: record.anexos,
      destinatarios: destinatarios,
      tipoMensagem: record.tipoMensagem,
    }
    this.props.enviarMensagem(params)
  }

  changeTipoFiltroConspaga = (event) => {
    let valor = event.value
    this.state.filtroConspaga.tipoFiltro = valor
    this.state.filtroConspaga.mostrarAnoInicial = valor.dataInicial
    this.state.filtroConspaga.mostrarAnoFinal = valor.dataFinal
  }
  changeTipoFiltroDataFalecimento = (event) => {
    let valor = event.value
    this.state.filtroDataFalecimento.tipoFiltro = valor
    this.state.filtroDataFalecimento.mostrarAnoInicial = valor.dataInicial
    this.state.filtroDataFalecimento.mostrarAnoFinal = valor.dataFinal
  }
  changeAnoConspaga = (event) => {
    const target = event.target
    const field = target.name
    let filtroConspaga = this.state.filtroConspaga
    filtroConspaga[field] = _.defaultTo(parseInt(target.value), window.moment().year())
    this.setState({ filtroConspaga: filtroConspaga })
  }

  changeAnoFalecimento = (event) => {
    const target = event.target
    const field = target.name
    let filtroDataFalecimento = this.state.filtroDataFalecimento
    filtroDataFalecimento[field] = event.value
    this.setState({ filtroDataFalecimento: filtroDataFalecimento })
  }

  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    const valor = target.value
    this.props.setField(field, valor)
    this.props.carregarTiposSepulturas(utils.convertArrayIdLabelToIdValue(valor))
  }

  addNovoDestinatario = () => {
    let destinatariosAtuais = this.props.record.destinatarios
    let novoDestinatario = {
      oid: new Date().getTime(),
      manual: true,
    }
    destinatariosAtuais.unshift(novoDestinatario)
    this.props.setField('destinatarios', destinatariosAtuais)
  }

  getCustomButtons = () => {
    return (
      <Button
        label={utils.getMessage(this.props.messages, 'mensageria.label.enviar')}
        icon="pi pi-check"
        className="p-button-success"
        onClick={this.confirmarEnvioMensagem}
      />
    )
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  buildAdvancedFilters = () => {
    const messages = this.props.messages
    let record = this.props.record
    if (this.showFilter) {
      return (
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.unidades}
                    options={utils.convertArrayIdValueToIdLabel(this.props.opcoesUnidades)}
                    name="unidades"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={this.onChangeUnit}
                    disabled={this.props.disableUnit}
                  />
                  <label>{utils.getMessage(messages, 'mensageria.label.unidades')}</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.tiposPessoas}
                    options={utils.convertArrayIdValueToIdLabel(this.props.opcoesTiposPessoas)}
                    name="tiposPessoas"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={this.props.changeHandler}
                  />
                  <label>{utils.getMessage(messages, 'mensageria.label.tipoPessoa')}</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.tiposSepulturas}
                    options={utils.convertArrayIdValueToIdLabel(record.opcoesTiposSepulturas)}
                    name="tiposSepulturas"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={this.props.changeHandler}
                  />
                  <label>{utils.getMessage(messages, 'mensageria.label.tipoSepultura')}</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={record.categorias}
                    options={utils.convertArrayIdValueToIdLabel(record.opcoesCategorias)}
                    filter={true}
                    name="categorias"
                    optionLabel="label"
                    dataKey="id"
                    selectedItemsLabel="{0} itens selecionados"
                    onChange={this.props.changeHandler}
                  />
                  <label>{utils.getMessage(messages, 'mensageria.label.categorias')}</label>
                </span>
              </div>
              <div className="p-grid form-group p-align-center">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
                  <span className="p-float-label">
                    <Dropdown
                      autoWidth={false}
                      value={this.state.filtroConspaga.tipoFiltro}
                      options={this.props.tiposFiltros}
                      onChange={this.changeTipoFiltroConspaga}
                      optionLabel="label"
                      optionValue={(v) => v}
                      name="tipo"
                    />
                    <label>{utils.getMessage(messages, 'mensageria.label.conspaga')}</label>
                  </span>
                </div>
                {this.state.filtroConspaga.mostrarAnoInicial ? (
                  <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-2">
                    <InputText
                      keyfilter="pint"
                      name="conspagaInicial"
                      value={this.state.filtroConspaga.conspagaInicial}
                      onChange={this.changeAnoConspaga}
                      min={0}
                    />
                  </div>
                ) : (
                  ''
                )}

                {this.state.filtroConspaga.mostrarAnoFinal ? (
                  <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-2">
                    <InputText
                      keyfilter="pint"
                      name="conspagaFinal"
                      value={this.state.filtroConspaga.conspagaFinal}
                      onChange={this.changeAnoConspaga}
                      min={0}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="p-grid form-group p-align-center">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2">
                  <span className="p-float-label">
                    <Dropdown
                      autoWidth={false}
                      value={this.state.filtroDataFalecimento.tipoFiltro}
                      options={this.props.tiposFiltros}
                      onChange={this.changeTipoFiltroDataFalecimento}
                      optionLabel="label"
                      optionValue={(v) => v}
                      name="tipo"
                    />
                    <label className="label">Data de Falecimento</label>
                  </span>
                </div>

                {this.state.filtroDataFalecimento.mostrarAnoInicial ? (
                  <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-2">
                    <Calendar
                      value={this.state.filtroDataFalecimento.dataFalecimentoInicial}
                      yearNavigator={true}
                      yearRange={utils.getYearRangeFromDate('1900')}
                      monthNavigator={true}
                      name="dataFalecimentoInicial"
                      onChange={this.changeAnoFalecimento}
                    />
                  </div>
                ) : (
                  ''
                )}

                {this.state.filtroDataFalecimento.mostrarAnoFinal ? (
                  <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-2">
                    <Calendar
                      value={this.state.filtroDataFalecimento.dataFalecimentoFinal}
                      yearNavigator={true}
                      yearRange={utils.getYearRangeFromDate('1900')}
                      monthNavigator={true}
                      name="dataFalecimentoFinal"
                      onChange={this.changeAnoFalecimento}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-2 p-xl-2">
                  <RadioButton
                    value={2}
                    name="tipoMensagem"
                    onChange={this.props.changeHandler}
                    checked={record.tipoMensagem === 2}
                  />
                  <label className="label" style={{ marginLeft: '5px' }}>
                    {utils.getMessage(messages, 'mensageria.label.whatsapp')}
                  </label>
                </div>
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-2 p-xl-2">
                  <RadioButton
                    value={1}
                    name="tipoMensagem"
                    onChange={this.props.changeHandler}
                    checked={record.tipoMensagem === 1}
                  />
                  <label className="label" style={{ marginLeft: '5px' }}>
                    {utils.getMessage(messages, 'mensageria.label.email')}
                  </label>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-4 p-md-3 p-lg-3 p-xl-2">
                  <Button
                    label={utils.getMessage(messages, 'mensageria.label.pesquisar')}
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={(e) => {
                      this.filtrarResultados()
                      this.showFilters()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  buildHeader = () => {
    return (
      <div>
        <DefaultToolBar
          listPageContainer={this}
          title={utils.getMessage(this.props.messages, 'mensageria.label.mensageria')}
        />
      </div>
    )
  }

  render() {
    const actionPessoa = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.removerPessoa(rowData)}
          />
        </div>
      )
    }

    const headerAcaoRemoverDestinatarios = (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => this.retirarTodosDestinatarios()}
        />
      </div>
    )

    const columnData = (rowData, column) => {
      if (column.field === 'oidContrato' && rowData[column.field] !== '') {
        return <Link to={`${routes.PATH_CONTRATO}/${rowData.oidContrato}`}>{rowData.oidContrato}</Link>
      }
    }

    const colunasTPessoas = this.props.colunasTPessoa.map((col) => {
      if (col.field === 'acaoRemocao') {
        return (
          <Column
            {...col}
            key={col.field}
            header={headerAcaoRemoverDestinatarios}
            body={actionPessoa}
            style={{ width: '5em', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'oidContrato') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            sortField={col.field}
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ textAlign: 'center', width: '10%' }}
          />
        )
      }
      return (
        <Column
          {...col}
          key={col.field}
          sortField={col.field}
          sortable={true}
          editor={this.fieldEditor}
          filter={true}
          filterMatchMode="contains"
          style={{ textAlign: 'left' }}
        />
      )
    })
    const content = (
      <div>
        {this.getDialogConfirmarEnvioMensagem()}
        <MensageriaForm
          state={this.state}
          tiposFiltros={this.props.tiposFiltros}
          changeTipoFiltroConspaga={this.changeTipoFiltroConspaga}
          changeTipoFiltroDataFalecimento={this.changeTipoFiltroDataFalecimento}
          changeAnoConspaga={this.changeAnoConspaga}
          changeAnoFalecimento={this.changeAnoFalecimento}
          currentUser={this.props.currentUser}
          messages={this.props.messages}
          changeHandler={this.props.changeHandler}
          record={this.props.record}
          disableUnit={this.props.disableUnit}
          opcoesUnidades={this.props.opcoesUnidades}
          opcoesTiposPessoas={this.props.opcoesTiposPessoas}
          onChangeUnit={this.onChangeUnit}
          changeHandlerEditor={this.props.changeHandlerEditor}
          filtrarResultados={this.filtrarResultados}
          enviarMensagem={this.enviarMensagem}
          colunasTPessoa={colunasTPessoas}
          dataTablePessoas={(el) => (this.dataTablePessoas = el)}
          //----- Referente a anexos
          anexos={this.props.record.anexos}
          onSelectAnexo={this.props.addAnexo}
          downloadAnexo={this.props.downloadAnexo}
          removeAnexo={this.props.removeAnexo}
          //-------------------
          destinatario={this.props.destinatario}
          retirarTodosDestinatarios={this.retirarTodosDestinatarios}
          addNovoDestinatario={this.addNovoDestinatario}
          lookupDestinatario={this.props.lookupDestinatario}
          lookupDestinatarioOnKeyPress={this.lookupDestinatarioOnKeyPress}
          lookupDestinatarioOnHide={this.lookupDestinatarioOnHide}
          lookupDestinatarioOnConfirm={this.lookupDestinatarioOnConfirm}
          lookupDestinatarioOnCancel={this.lookupDestinatarioOnCancel}
          lookupDestinatarioOnPage={this.lookupDestinatarioOnPage}
          lookupDestinatarioOnSort={this.lookupDestinatarioOnSort}
          lookupDestinatarioOnFilter={this.lookupDestinatarioOnFilter}
          lookupDestinatarioOnClick={this.lookupDestinatarioOnClick}
          lookupDestinatarioOnEdit={this.lookupDestinatarioOnEdit}
          lookupDestinatarioOnComplete={this.lookupDestinatarioOnComplete}
          lookupDestinatarioOnInputFilter={this.lookupDestinatarioOnInputFilter}
        />
      </div>
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MensageriaFormPageContainer)
