import { types } from "./convenio.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  columns: [
    {
      field: "categoria",
      header: "Categoria",
      columnKey: "categoria",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Empresa",
      columnKey: "nome",
      sortable: true,
      filter: true
    },
    {
      field: "endereco",
      header: "Endereço",
      columnKey: "endereco",
      sortable: true,
      filter: true
    },
    {
      field: "localidade",
      header: "Localidade",
      columnKey: "localidade"
    }
  ],
  currentRecord: {

    empresas:[{}],
    idLocalidade:"",
    nomeLocalidade:"",
    idCategoria:"",
    nomeCategoria:"",
    idSubCategoria:"",
    nomeSubCategoria:"",
    localidades:[],
    categorias:[],
    subCategorias:[],
    hash:""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDConvenioReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('convenio'); 
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}