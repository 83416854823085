import React from 'react';

import TiposSepulturasRelatorioFormPageContainer from './TiposSepulturasRelatorioFormPageContainer';

export class TiposSepulturasRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <TiposSepulturasRelatorioFormPageContainer {...this.props} />
        )
    }
}
