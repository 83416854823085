import React from "react";
import { DataView } from "primereact/dataview";

import "./ConvenioList.scss";

export const ConvenioList = props => {
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card page-convenio-card">
          <div className="dataview-empresa">
            <DataView
              value={props.props.record.empresas}
              layout={"grid"}
              itemTemplate={props.itemTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
