import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import _ from "lodash";
import LtsLookupPessoa from "../../../components/lottus/lts-lookup-pessoa";
import {enums} from "../../../constants";

export default function FormSignatario(props) {

    const [tipoAssinatura, setTipoAssinatura] = useState(null)
    const [nomeEmail, setNomeEmail] = useState("")
    const [signatario, setSignatario] = useState("")

    useEffect(() => {
        const init = () => {
            const documentoAssinaturaEdit = _.get(props, 'documentoAssinaturaEdit', null)
            if (documentoAssinaturaEdit) {
                setNomeEmail(documentoAssinaturaEdit.nomeEmail)
                const tipoAssinatura = tiposAssinaturas.find(f => f.value === documentoAssinaturaEdit.tipoAssinatura)
                if (tipoAssinatura) {
                    setTipoAssinatura(tipoAssinatura)
                }
            }
        }
        init()
    }, [props.visible]);


    const tiposAssinaturas = [
        {id: 1, value: 'Responsável'},
        {id: 2, value: 'Testemunha'}
    ]

    const onChangeTipoAssinatura = e => {
        setTipoAssinatura(e.value)
    }

    const onClickAddSignatario = () => {
        const assinaturas = _.get(props, 'documento.assinaturas', [])
        let assinatura = null
        if (props.documentoAssinaturaEdit) {
            assinatura = assinaturas.find(a => a === props.documentoAssinaturaEdit)
        }

        if (assinatura) {
            assinatura.nomeEmail = nomeEmail
            assinatura.oidTestemunha = signatario?.oid
            assinatura.email = signatario?.email
            assinatura.tipoAssinatura = tipoAssinatura.value
        } else {
            assinaturas.push({
                oid: null,
                oidTestemunha: signatario?.oid,
                email: signatario?.email,
                nomeEmail,
                tipoAssinatura: tipoAssinatura.value,
                oidContratoDocumento: props.documento.oid,
                status: 'PENDENTE'
            })
            props.documento.assinaturas = assinaturas
        }
        props.onClickConfirmCallback()
        onHide()
    }

    const onHide = () => {
        setNomeEmail("")
        setSignatario({
            oid: '',
            nome: '',
            email: '',
        })
        setTipoAssinatura(null)
        props.onHide()
    }

    const onChangeSignatario = e => {
        const oid = _.get(e, 'oid', null)
        const nome = _.get(e, 'nome', null)
        const email = _.get(e, 'email', null)
        if (oid) {
            setSignatario({
                oid: oid,
                email: email,
                nome: '',
            })
            setNomeEmail(nome)
        } else {
            setSignatario({
                oid: '',
                nome: nome,
                email: '',
            })
        }
    }

    const footer = (
        <div>
            <Button
                onClick={onClickAddSignatario}
                label={"Confirmar"}
                className="p-button-success"
            />
        </div>
    )

    return (
        <Dialog
            onHide={props.onHide}
            header={"Adicionar/editar signatário"}
            visible={props.visible}
            modal={true}
            style={{width: "30%"}}
            footer={footer}>

            <div className="p-grid p-fluid margin-top-dialogs">

                <div className="p-col-12">
                    <LtsLookupPessoa
                      header={'Signatário'}
                      name={'signatario'}
                      field={'nome'}
                      label={'Pesquisar por signatário'}
                      value={{
                          id: _.get(signatario, 'oid', null),
                          value: _.get(signatario, 'nome', null),
                      }}
                      oidRecord={_.get(signatario, 'oid', '')}
                      history={props.history}
                      onChange={(e) => {
                          onChangeSignatario(e)
                      }}
                      showAddButton={false}
                      tipoPessoa={enums.TipoPessoa.FISICA}
                      editHidden={true}
                    />
                </div>

                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText
                            name="nomeEmail"
                            value={nomeEmail}
                            onChange={e => setNomeEmail(e.target.value)}
                        />
                        <label>Nome/E-mail</label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="p-float-label">
                        <Dropdown
                            autoWidth={true}
                            value={tipoAssinatura}
                            options={tiposAssinaturas}
                            onChange={onChangeTipoAssinatura}
                            optionLabel="value"
                            optionValue={(v) => v}
                            name="tipoAssinatura"
                        />
                        <label>Tipo Assinatura</label>
                    </span>
                </div>
            </div>
        </Dialog>
    )
}