import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, enums, icons } from '../../../constants'
import { getMessage } from '../../../utils/index'
import utils from '../../../utils/index'
import './produto-list.scss'

import {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onPage,
  onSort,
  onCsvExport,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../produto.actions'
import { ProdutoList } from './ProdutoList'
import '../../../index.scss'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import { Button } from 'primereact/button'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import _ from 'lodash'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudProdutoState.showHideColumns,
    messages: state.crudProdutoState.messages,
    columns: state.crudProdutoState.columns,
    records: state.crudProdutoState.records,
    first: state.crudProdutoState.index,
    rows: state.crudProdutoState.max,
    totalRecords: state.crudProdutoState.count,
    sortField: state.crudProdutoState.sortField,
    order: state.crudProdutoState.sortOrder,
    selectedFilters: state.crudProdutoState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/produtos/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    pesquisaAvancada: (state) => {
      dispatch(actions.clearSelectedFilters())
      setTimeout(() => {
        if (state.filtroMarcador && state.filtroMarcador.oid) {
          let filtroMarcador = state.filtroMarcador
          let novoFiltro = {
            campo: 'oid',
            tipoFiltro: 'IGUAL_A',
            relacionamento: ['marcadores'],
            valor: filtroMarcador.oid,
          }
          dispatch(actions.setFiltroAvancado(novoFiltro))
        }
        setTimeout(() => {
          dispatch(actions.executeFilter(`/lottustech/produtos/all`))
        }, 200)
      }, 250)
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class ProdutoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showFilter = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      tipoProduto: 0,
      filtroMarcador: false,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PRODUTO}/novo`)
  }
  componentDidMount() {
    componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title={getMessage(this.props.messages, 'produto.label.produtos')} />
  }

  oid = (rowData) => {
    return <Link to={`${routes.PATH_PRODUTO}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroMarcador()}
          <br></br>
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.pesquisaAvancada(this.state)
  }

  buildFiltroMarcador = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
          <span className="p-float-label">
            <LtsLookupMarcador
              label={utils.getMessage(this.props.messages, 'produto.label.marcador')}
              value={this.state.filtroMarcador.descricao}
              history={this.props.history}
              editHidden={true}
              field={'codigo'}
              multiple={false}
              onChange={(e) => {
                this.setState({ filtroMarcador: { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'codigo', '') } })
              }}
              showAddButton={false}
              tipo={'PRODUTO'}
            />
          </span>
        </div>
      </div>
    )
  }

  tipoProdutoColumnTemplate = (rowData, column) => {
    if (rowData[column.field]) {
      return (
        <div title={enums.CONVERTER_TIPO_PRODUTO[rowData[column.field]]}>
          {enums.CONVERTER_TIPO_PRODUTO[rowData[column.field]]}
        </div>
      )
    }
    return null
  }

  tipoProdutoFilter = (column) => (
    <LtsDropdownIdValue
      name={'tipoProduto'}
      value={this.state.tipoProduto}
      onChange={(e) => this.onChangeTipoProduto(e, column)}
      loadDataFn={apiService.getTiposProduto}
      showOptionAll={true}
    />
  )

  onChangeTipoProduto = (e, column) => {
    this.setState({ tipoProduto: e })
    this.props.changeFilter(column, { target: { name: 'tipoProduto', value: e.id } })
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oid}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-oid-body-width"
            filterHeaderClassName="table-column-oid-filter-header-width"
            headerClassName="table-column-oid-header-width"
          />
        )
      }
      if (col.field === 'codigo') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.codigo}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-codigo-body-width"
            filterHeaderClassName="table-column-codigo-filter-header-width"
            headerClassName="table-column-codigo-header-width"
          />
        )
      }
      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.descricao}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-descricao-body-width"
            filterHeaderClassName="table-descricao-codigo-filter-header-width"
            headerClassName="table-column-descricao-header-width"
          />
        )
      }
      if (col.field === 'quantidadeMinima') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.quantidadeMinima}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-quantidade-body-width"
            filterHeaderClassName="table-quantidade-codigo-filter-header-width"
            headerClassName="table-column-quantidade-header-width"
          />
        )
      }
      if (col.field === 'saldo') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.saldo}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-quantidade-body-width"
            filterHeaderClassName="table-quantidade-codigo-filter-header-width"
            headerClassName="table-column-quantidade-header-width"
          />
        )
      }

      if (col.field === 'unidade_sigla') {
        return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
      }

      if (col.field === 'tipoProduto') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            body={this.tipoProdutoColumnTemplate}
            filterElement={this.tipoProdutoFilter(col)}
            style={{ width: '130px' }}
          />
        )
      }

      if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }

      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }
  buildContent = () => {
    return (
      <ProdutoList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoListPageContainer)
