import React from 'react';

import ConfiguracaoBoletoFormPageContainer from './ConfiguracaoBoletoFormPageContainer';

export class ConfiguracaoBoletoFormPage extends React.Component{

    
    render(){
        return (
           <ConfiguracaoBoletoFormPageContainer {...this.props} />
        )
    }
}
