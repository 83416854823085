import React from 'react';
import AgendaListPageContainer from './AgendaListPageContainer';

export class AgendaListPage extends React.Component{
    
    render(){
      return (
        <AgendaListPageContainer {...this.props}/>
      )
    }
}

