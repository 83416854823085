import React from 'react';
import TipoSepulturaFormPageContainer from './TipoSepulturaFormPageContainer';

export class TipoSepulturaFormPage extends React.Component{

    
    render(){
        return (
           <TipoSepulturaFormPageContainer {...this.props} />
        )
    }
}
