import React from 'react'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { Page } from '../../../components/page/Page'
import { actions } from '../portalClienteCarteirinha.actions'
import { actions as contratoActions } from '../../contrato/contrato.actions'
import utils from '../../../utils/index'
import { onColumnReorder, onColumnToggle, onPage, onSort } from '../../../utils/listPageContainerUtils'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { PortalClienteCarteirinhaList } from './PortalClienteCarteirinhaList'
import { Button } from 'primereact/button'
import { icons } from '../../../constants'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    record: state.crudPortalClienteCarteirinhaState.currentRecord,
    records: state.crudPortalClienteCarteirinhaState.records,
    showHideColumns: state.crudPortalClienteCarteirinhaState.showHideColumns,
    columns: state.crudPortalClienteCarteirinhaState.columns,
    first: state.crudPortalClienteCarteirinhaState.index,
    rows: state.crudPortalClienteCarteirinhaState.max,
    totalRecords: state.crudPortalClienteCarteirinhaState.count,
    sortField: state.crudPortalClienteCarteirinhaState.sortField,
    order: state.crudPortalClienteCarteirinhaState.sortOrder,
    selectedFilters: state.crudPortalClienteCarteirinhaState.selectedFilters,
    selectedRecords: state.crudPortalClienteCarteirinhaState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.loadAll())
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.setField(field, value))
    },
    changeFilter: (column, e) => {},
    setSelectedFilters: (filtro) => dispatch(actions.setFiltroAvancado(filtro)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    load: () => dispatch(actions.loadAll()),
    emitirCarteirinhas: (oidsContratos) => dispatch(contratoActions.gerarCarteirinhaLotePDF(oidsContratos)),
    emitirCarteirinhaIndividual: (oidPessoa, oidContrato) =>
      dispatch(contratoActions.gerarCarteirinhaIndividualPDF(oidPessoa, oidContrato)),
    emitirCarteirinhasSelecionados: (params) => dispatch(contratoActions.gerarCarteirinhasSelecionadosPDF(params)),
  }
}

class PortalClienteCarteirinhaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.showColumnButton = false
    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.state = {
      showWarningConspagaAtraso: false,
      oidsContratosAtrasados: [],
    }
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.load()
  }

  changeHandler = (event) => {
    this.props.changeHandler(event)
  }

  onKeyDown = (e) => {
    this.props.onEnter()
  }

  onKeyDownSubCategoria = (e) => {
    this.props.onEnter()
  }

  hideDialog = () => {
    this.setState({ oidsContratosAtrasados: [] })
    this.setState({ showWarningConspagaAtraso: false })
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  cpfTemplate = (rowData) => {
    return utils.formatCPF(rowData.cpf)
  }

  validaEmissaoCarteirinhaIndividual(oid, oidContrato, conspaga) {
    this.setState({ oidsContratosAtrasados: [] })
    this.props.emitirCarteirinhaIndividual(oid, oidContrato)
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'oidContrato' && utils.nonNull(rowData[column.field])) {
      return (
        <Button
          type="button"
          onClick={(e) => this.validaEmissaoCarteirinhaIndividual(rowData.oid, rowData.oidContrato, rowData.conspaga)}
          icon={icons.PRINT}
        />
      )
    }
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      } else if (col.field === 'dataNascimento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            style={{ width: '16%' }}
            filter={false}
          />
        )
      } else if (col.field === 'cpf') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.cpfTemplate}
            sortField={col.columnSortField}
            style={{ width: '15%' }}
            filterMatchMode="contains"
            filter={true}
          />
        )
      } else if (col.field === 'oidContrato') {
        return <Column {...col} key={col.field} body={this.formatColumn} style={{ textAlign: 'center' }} />
      }
      return (
        <Column
          {...col}
          key={col.field}
          style={{ width: '55%' }}
          sortField={col.columnSortField}
          filterMatchMode="contains"
        />
      )
    })
  }

  getCustomButtons = () => {
    return (
      <div>
        <div className="p-toolbar-group-left">
          <Button
            onClick={(e) => this.emitirCarteirinhas()}
            label="Emitir carteirinhas"
            style={{ marginRight: '.25em' }}
            className="p-button-success"
          />
        </div>
      </div>
    )
  }

  emitirCarteirinhas = () => {
    const selectedRecords = this.props.selectedRecords
    if (utils.isArrayNotEmpty(selectedRecords)) {
      const todosSelecionados = selectedRecords.length === this.props.records.length
      //Se todos os registros foram selecionados
      // geramos as carteirinhas para todos os contratos
      if (todosSelecionados) {
        this.emitirCarteirinhasContratos(selectedRecords)
      } else {
        // caso contrario apenas para as pessoas selecionadas
        const params = []
        this.setState({ oidsContratosAtrasados: [] })
        selectedRecords.forEach((r) => {
          params.push({ id: r.oid, value: r.oidContrato })
        })
        this.props.emitirCarteirinhasSelecionados(params)
      }
    } else {
      // se nada foi selecionado, geramos para todos os contratos
      this.emitirCarteirinhasContratos(this.props.records)
    }
  }

  emitirCarteirinhasContratos = (array) => {
    this.setState({ oidsContratosAtrasados: [] })
    if (utils.isArrayNotEmpty(array)) {
      let oidsContratos = []
      array.forEach((r) => {
        if (!oidsContratos.includes(r.oidContrato)) {
          oidsContratos.push(r.oidContrato)
        }
      })
      this.props.emitirCarteirinhas(oidsContratos)
    }
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Carteirinhas" />
  }

  getTextWarningConspaga() {
    if (utils.isArrayNullOrEmpty(this.state.oidsContratosAtrasados)) {
      return null
    }
    const contratos = this.state.oidsContratosAtrasados.join()
    return (
      <div>
        <p>O(s) contrato(s) {contratos} esta(ão) com conspaga em atraso.</p>
        <p>
          Entre em contato com a administração pelo número{' '}
          <a href="https://api.whatsapp.com/send?phone=554733223950">3322-3950</a>
        </p>
      </div>
    )
  }

  buildContent() {
    return (
      <div>
        <PortalClienteCarteirinhaList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalClienteCarteirinhaListPageContainer)
