import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { actions as tipoSepulturaActions } from '../tipoSepultura/tipoSepultura.actions'
import storage from '../../services/stoage.service'
import apiService from '../../services/api.service'
import { messageActions } from '../../store/message/index'
import utils from '../../utils'
const BASE_PATH = '/lottustech/localizacoes'
const crud = build(validate, BASE_PATH)
const prefix = 'crudLocalizacao'

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_LOOKUP_CONTRATO_VISIBLE: `${prefix}setLookupContratoVisible`,
  SET_LOCALIZACAO_REF: `${prefix}setLocalizacaoRef`,
  RESET_COLUMNS_ID_LOCALIZACAO: `${prefix}resetColumnsIdLocalizacao`,
  SET_INPUTS: `${prefix}.setInputs`,
  ADD_ANEXO: `${prefix}addAnexo`,
  SHOW_ANEXO: `${prefix}showAnexo`,
  REMOVE_ANEXO: `${prefix}removeAnexo`,
  SET_TIPO_SEPULTURA: `${prefix}.setTipoSepultura`,
  SET_NUMERO_LUGARES: `${prefix}.setNumeroLogares`,
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.setLookupContratoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CONTRATO_VISIBLE,
    visible,
  }
}

actions.setLocalizacaoRef = (payload) => {
  return {
    type: types.SET_LOCALIZACAO_REF,
    payload,
  }
}

actions.resetColumnsIdLocalizacao = () => {
  return {
    type: types.RESET_COLUMNS_ID_LOCALIZACAO,
  }
}

actions.addAnexo = (payload) => {
  return {
    type: types.ADD_ANEXO,
    payload,
  }
}

actions.removeAnexo = (payload) => {
  return {
    type: types.REMOVE_ANEXO,
    payload,
  }
}

actions.showAnexo = (payload) => {
  return {
    type: types.SHOW_ANEXO,
    payload,
  }
}
actions.setInputs = (inputs) => {
  return {
    type: types.SET_INPUTS,
    inputs,
  }
}

actions.beforeSave = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLocalizacaoState`]
    const localizacao = state.currentRecord
    let msgErroLocalizacao = false
    let inputsLocalizacao = state.inputs
    const company = storage.getCompany()
    if (
      localizacao.unidade &&
      localizacao.unidade.id === 9 &&
      localizacao.tipoSepultura &&
      utils.isTipoSepulturaGaveta(company.unidades, localizacao.unidade.id, localizacao.tipoSepultura.id)
    ) {
      inputsLocalizacao = inputsLocalizacao.filter((t) => t.name !== 'localizacaoA')
    }

    if (utils.nonNull(inputsLocalizacao) && utils.isArrayNotEmpty(inputsLocalizacao)) {
      inputsLocalizacao.forEach((input) => {
        if (utils.isStringBlank(localizacao[input.name])) {
          msgErroLocalizacao = true
        }
      })
    }

    if (msgErroLocalizacao) {
      dispatch(
        messageActions.messageShowMessages(makeMessages(['Obrigatório estar completo as localizações!'], 'error'))
      )
    } else {
      dispatch(actions.save())
    }
  }
}

actions.processaAnexo = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        //TODO criar metodo utils
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const anexo = { filename: file.name, base64: encoded }
        dispatch(actions.addAnexo(anexo))
      }

      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}

actions.loadTiposSepulturas = (oidUnidade) => {
  return (dispatch, getState) => {
    const params = {
      filters: [{ campo: 'unidade', valor: oidUnidade, tipoFiltro: 'IGUAL_A' }],
      index: 0,
      max: 100,
      projectionFields: ['oid', 'descricao'],
    }
    apiService.post(`/lottustech/tipossepultura/all`, params).then((response) => {
      dispatch(tipoSepulturaActions.setRecords(response.data.records))
    })
  }
}

actions.getMascaraIdentificacaoLocalizacao = (oidUnidade, oidTipoSepultura) => {
  return (dispatch, getState) => {
    apiService.get(`/lottustech/localizacoes/getmascara/${oidUnidade}/${oidTipoSepultura}`).then((response) => {
      let inputs = response.data
      dispatch(actions.setInputs(inputs))
    })
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body])
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

actions.downloadAnexo = (anexo) => {
  //TODO passar essa validacao para o utils
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then((response) => {
        const arrayBuffer = base64ToArrayBuffer(response.data)
        createAndDownloadBlobFile(arrayBuffer, anexo.filename)
      })
    }
  }
}

const makeMessages = (messages, type) => {
  let sticky = false
  let summary = 'Sucesso!'
  if (type === 'error') {
    summary = 'Erro!'
    sticky = true
  }
  return messages.map((message) => {
    return { sticky: sticky, summary: summary, severity: type, detail: message }
  })
}

actions.setTipoSepultura = (payload) => {
  return {
    type: types.SET_TIPO_SEPULTURA,
    payload: payload,
  }
}

actions.setNumeroLugares = (payload) => {
  return {
    type: types.SET_NUMERO_LUGARES,
    payload: payload,
  }
}

actions.removerLocalizacoes = (oids) => {
  return (dispatch, getState) => {
    apiService.removeByOids(`/lottustech/localizacoes/removeList`, oids).then((response) => {
      if (actions.afterRemoveList) {
        actions.afterRemoveList()
      }
    })
  }
}

export { types, actions }
