import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { actions as tipoSepulturaActions } from '../tipoSepultura/tipoSepultura.actions'
import apiService from '../../services/api.service'
import utils from '../../utils/index'
import storage from '../../services/stoage.service'
import { messageActions } from '../../store/message'
import _ from 'lodash'
import { getUltimoCampoPesquisaLocalizacaoGrid } from '../../utils/tipo-sepultura-config-utils'
const BASE_PATH = '/lottustech/localizacoes'
const crud = build(validate, BASE_PATH)
const prefix = 'crudLocalizacaoGrid'

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_FILTER_LOCALIZACAO_GRID: `${prefix}setFilterLocalizacaoGrid`,
  EXECUTE_FILTER_LOCALIZACAO_GRID: `${prefix}executeFilterLocalizacaoGrid`,
  SET_UNIDADE: `${prefix}setUnidade`,
  WILL_UNMOUNT: `${prefix}willUnmount`,
  SET_MATRIZ_LOCALIZACAO: `${prefix}setMatrizLocalizacao`,
  GET_OPCOES_PESQUISA: `${prefix}getOpcoesPesquisa`,
  SET_OPCOES_PESQUISA: `${prefix}setOpcoesPesquisa`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.setFilterLocalizacaoGrid = (field, value) => {
  return {
    type: types.SET_FILTER_LOCALIZACAO_GRID,
    field,
    value,
  }
}

actions.setUnidade = (unidade) => {
  return {
    type: types.SET_UNIDADE,
    unidade,
  }
}

actions.setMatrizLocalizacao = (payload) => {
  return {
    type: types.SET_MATRIZ_LOCALIZACAO,
    payload,
  }
}

actions.setOpcoesPesquisa = (payload) => {
  return {
    type: types.SET_OPCOES_PESQUISA,
    payload,
  }
}

actions.resolvePesquisaLocalizacaoGrid = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLocalizacaoGridState`]
    const oidUnidade = state.unidade ? state.unidade.id : null
    const oidTipoSepultura = state.currentRecord.tipoSepultura.id
    const empresa = storage.getCompany()
    let ultimoCampoPesquisa = getUltimoCampoPesquisaLocalizacaoGrid(
      _.get(state, 'currentRecord.tipoSepultura.object', null)
    )
    // PHOENIX-603 - apos normalizacao dos cadastro de TipoSepultura podemos remover a linha abaixo
    if (_.isEmpty(ultimoCampoPesquisa)) {
      ultimoCampoPesquisa = utils.getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura)
    }
    const filtros = state.selectedFilters
    const ultimoCampoPesquisaSelecionado = filtros.find((f) => f.valorSelecionado.key === ultimoCampoPesquisa)
    if (ultimoCampoPesquisaSelecionado) {
      dispatch(actions.executeFilterLocalizacaoGrid())
    } else {
      dispatch(actions.getOpcoesPesquisa())
    }
  }
}

actions.getOpcoesPesquisa = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLocalizacaoGridState`]
    const oidUnidade = state.unidade ? state.unidade.id : null
    const oidTipoSepultura = state.currentRecord.tipoSepultura ? state.currentRecord.tipoSepultura.id : null
    const filtros = state.selectedFilters
    const filtrosPreenchidos = filtros.filter((f) => {
      return f.valorSelecionado && f.valorSelecionado.value // para permitir valor 'em branco' && f.valorSelecionado.value.trim().length > 0
    })
    const params = filtrosPreenchidos.map((f) => {
      return { key: f.campoPesquisa.key, value: f.valorSelecionado.value }
    })
    return apiService
      .post(`${BASE_PATH}/opcoespesquisatelacinema/${oidUnidade}/${oidTipoSepultura}`, params)
      .then((response) => {
        dispatch(actions.setOpcoesPesquisa(response.data))
      })
  }
}

actions.executeFilterLocalizacaoGrid = (utilizaFiltros = true) => {
  return (dispatch, getState) => {
    const state = getState()[`crudLocalizacaoGridState`]
    const oidUnidade = state.unidade ? state.unidade.id : null
    const oidTipoSepultura = state.currentRecord.tipoSepultura ? state.currentRecord.tipoSepultura.id : null
    const filtros = state.selectedFilters
    if (utilizaFiltros) {
      const message = 'Nem todos os filtros foram preenchidos'
      if (utils.isArrayNullOrEmpty(filtros)) {
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        return
      }
      const indexFiltroIncompleto = filtros.findIndex((f) => {
        return !f.valorSelecionado || !f.valorSelecionado.value
      })
      if (indexFiltroIncompleto > -1) {
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        return
      }
    }
    let params = []
    if (utilizaFiltros) {
      params = filtros.map((f) => {
        return { key: f.campoPesquisa.key, value: f.valorSelecionado.value }
      })
    }
    if (actions.afterFilterLocalizacaoGrid) {
      actions.afterFilterLocalizacaoGrid()
    }
    return apiService
      .post(`${BASE_PATH}/filterlocalizacaogrid/${oidUnidade}/${oidTipoSepultura}`, params)
      .then((response) => {
        dispatch(actions.setMatrizLocalizacao(response.data))
        dispatch(actions.fetchRecordsSuccess(response.data))
        // antes de iniciar o filtro de localização deve mostrar o Spinner
        if (actions.beforeFilterLocalizacaoGrid) {
          actions.beforeFilterLocalizacaoGrid()
        }
      })
  }
}

actions.loadTiposSepulturas = (oidUnidade) => {
  return (dispatch, getState) => {
    return apiService
      .get(`${BASE_PATH}/getTiposSepulturaLocalizacaoGrid/${oidUnidade}`, oidUnidade)
      .then((response) => {
        dispatch(tipoSepulturaActions.setRecords(response.data))
      })
  }
}

actions.loadFiltrosPesquisa = (oidUnidade, oidTipoSepultura) => {
  return (dispatch, getState) => {
    return apiService
      .get(`${BASE_PATH}/configuracaopesquisa/${oidUnidade}/${oidTipoSepultura}`, oidUnidade)
      .then((response) => {
        dispatch(actions.setFiltersLocalizacaoGrid(response.data))
      })
  }
}

actions.willUnmount = () => {
  return {
    type: types.WILL_UNMOUNT,
  }
}

const makeMessages = (messages, type) => {
  let sticky = false
  let summary = ''
  if (type === 'error') {
    summary = ''
    sticky = true
  } else if (type === 'warn') {
    summary = ''
    sticky = true
  }
  return messages.map((message) => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message,
    }
  })
}

export { types, actions }
