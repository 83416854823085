import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
const BASE_PATH = '/lottustech/perfis';
const prefix = 'crudPerfil';
const crud = build(validate,BASE_PATH);
const actions = new Action(prefix,crud);

const newTypes = {
    SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
    RESET: `${prefix}reset`
}
const types = Object.assign({},actions.types,newTypes);


actions.setBooleanFilter = (field, value)=>{
    return {
        type:types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

actions.reset = () => {
    return {
        type: types.RESET
    };
};

export {types, actions};
