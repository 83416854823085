import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
import {messageActions} from "../../store/message";
const BASE_PATH = "/lottustech/configuracaounidade";
const prefix = "crudParametro";
const crud = build(validate, BASE_PATH);

const newTypes = {
    RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.loadKeys = (oidUnidade) => {
  return (dispatch, getState) => {
    const state = getState()[`crudParametroState`];
    actions.setDefaultColumns(state)
    api.get(`${BASE_PATH}/${oidUnidade}/allKeys`)
      .then(({ data }) => {
        dispatch(actions.fetchRecordsSuccess(data))
      })
      .catch((err) => {
        dispatch(actions.fetchRecordsError(err))
      })
  }
}


actions.updateKey = (oidUnidade, key, value) => {
  return (dispatch) => {
    dispatch(actions.clearErrors());
    if (key) {
      api.post(`${BASE_PATH}/${oidUnidade}/updateKey`, {
          id: key,
          value: value
        })
        .catch((err) => {
          let messages = [err.response.data]
          dispatch(messageActions.messageShowMessages(messageActions.makeMessages(messages, 'error')))
        });
    }
  };
};


actions.deleteKey = (oidUnidade, key, executeAfterDelete) => {
  return (dispatch) => {
    dispatch(actions.clearErrors());
    if (key) {
      api
        .remove(`${BASE_PATH}/${oidUnidade}/deleteKey/${key}`)
        .then(()=>{
            //É necessário pois para atualizar uma key, primeiro é feito sua remoção
            // e depois no afterDelete é feito a inserção de uma nova key
            if(executeAfterDelete && actions.afterDelete){
                actions.afterDelete();
            }
        })
        .catch((err) => {
          let messages = [err.response.data]
          dispatch(messageActions.messageShowMessages(messageActions.makeMessages(messages, 'error')))
        });
    }
  };
};

export { types, actions };
