import { types } from './empresa.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'empresa',
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Sigla',
      value: {
        field: 'codigoEmpresa',
        header: 'Sigla',
        columnKey: 'codigoEmpresa',
        sortable: true,
        filter: true,
        columnSortField: 'codigoEmpresa',
        visible: true,
      },
    },
    {
      label: 'Nome',
      value: {
        field: 'nomeEmpresa',
        header: 'Nome',
        columnKey: 'nomeEmpresa',
        sortable: true,
        filter: true,
        columnSortField: 'nomeEmpresa',
        visible: true,
      },
    },
    {
      label: 'Pessoa',
      value: {
        field: 'nomePessoa',
        header: 'Pessoa',
        columnKey: 'pessoa',
        columnSortField: 'pessoa.nome',
        relacionamento: ['pessoa'],
        fieldJoin: 'nome',
        visible: true,
        sortable: true,
        filter: true,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'nomeEmpresa',
      header: 'Nome',
      columnKey: 'nomeEmpresa',
      sortable: true,
      filter: true,
      columnSortField: 'nomeEmpresa',
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    codigoEmpresa: '',
    nomeEmpresa: '',
    ativo: true,
  },
  lookupPessoa: {
    visible: false,
    modal: true,
    header: 'Pessoas',
  },
  selectedFilters: [],
  messsages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDEmpresaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('empresa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLoockupPessoaVisible(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setLoockupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible })
  return Object.assign({}, state, { lookupPessoa })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
