import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lottustech/itenslancamentosfinanceiros';

const crud = build(validate, BASE_PATH);
const prefix = 'crudItensLancamento';

const newTypes = {
    SET_LOOKUP_TAXA_VISIBLE: `${prefix}setLookupTaxaVisible`,
    SET_LOOKUP_PAGADOR_VISIBLE: `${prefix}setLookupPagadorVisible`,
    SET_LOOKUP_CONTRATO_VISIBLE: `${prefix}setLookupContratoVisible`,
    SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
}

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setLookupTaxaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_TAXA_VISIBLE,
        visible
    }
}

actions.setLookupPagadorVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PAGADOR_VISIBLE,
        visible
    }
}

actions.setLookupContratoVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CONTRATO_VISIBLE,
        visible
    }
}

actions.setBooleanFilter = (field, value) => {
    return {
        type: types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

export { types, actions };