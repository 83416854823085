import React from 'react';

import PessoaJuridicaFormPageContainer from './PessoaJuridicaFormPageContainer';

export class PessoaJuridicaFormPage extends React.Component{

    
    render(){
        return (
           <PessoaJuridicaFormPageContainer {...this.props} />
        )
    }
}
