import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Password } from 'primereact/password'
import { PickList } from 'primereact/picklist'
import { LookupField } from '../../../components/lookup/LookupField'
import utils from '../../../utils/index'

class UsuarioForm extends React.Component {
  handleMoveToTarget = (e) => {
    this.props.pickListPerfisMoveToTarget(e.value, this.props.record.oid)
  }
  handleMoveToSource = (e) => {
    this.props.pickListPerfisMoveToSource(e.value)
  }
  handleMoveAllToSource = (e) => {
    this.props.pickListPerfisMoveAllToSource()
  }

  handleMoveAllToTarget = (e) => {
    this.props.pickListPerfisMoveAllToTarget(this.props.perfis, this.props.record.oid)
  }

  handleMoveUnidadesToTarget = (e) => {
    this.props.pickListUnidadesMoveToTarget(e.value, this.props.record.oid)
  }
  handleMoveUnidadesToSource = (e) => {
    this.props.pickListUnidadesMoveToSource(e.value)
  }
  handleMoveAllUnidadesToSource = (e) => {
    this.props.pickListUnidadesMoveAllToSource()
  }

  handleMoveAllUnidadesToTarget = (e) => {
    this.props.pickListUnidadesMoveAllToTarget(this.props.unidadesDisponiveis, this.props.record.oid)
  }

  render() {
    const { props } = this
    const record = props.record
    const changeHandler = props.changeHandler
    const pessoa = record.pessoa || ''
    const messages = props.messages

    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>
                {utils.getMessage(messages, 'usuario.label.usuario')} {record.oid || ''}
              </h3>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
                  <LookupField
                    label={utils.getMessage(messages, 'usuario.label.pessoa')}
                    value={pessoa}
                    name="pessoa"
                    field="nome"
                    history={props.history}
                    onComplete={props.lookupPessoaOnComplete}
                    onInputFilter={props.lookupPessoaOnInputFilter}
                    editDisabled={props.lookupPessoa.editDisabled}
                    onEditField={props.lookupPessoaOnEdit}
                    onClick={props.lookupPessoaOnClick}
                    onEdit={props.lookupPessoaOnClick}
                    visible={props.lookupPessoa.visible}
                    modal={props.lookupPessoa.modal}
                    header={props.lookupPessoa.header}
                    onHide={props.lookupPessoaOnHide}
                    onConfirm={props.lookupPessoaOnConfirm}
                    onCancel={props.lookupPessoaOnCancel}
                    records={props.lookupPessoa.records}
                    columns={props.lookupPessoa.columns}
                    totalRecords={props.lookupPessoa.totalRecords}
                    sortOrder={props.lookupPessoa.order}
                    sortField={props.lookupPessoa.sortField}
                    first={props.lookupPessoa.first}
                    rows={props.lookupPessoa.rows}
                    onPage={props.lookupPessoaOnPage}
                    onFilter={props.lookupPessoaOnFilter}
                    onSort={props.lookupPessoaOnSort}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
                  <span className="p-float-label">
                    <InputText type="email" value={record.email} onChange={changeHandler} name="email" />
                    <label>{utils.getMessage(messages, 'usuario.label.email')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <span className="p-float-label">
                    <InputText type="text" value={record.login} onChange={changeHandler} name="login" />
                    <label>{utils.getMessage(messages, 'usuario.label.login')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <span className="p-float-label">
                    <Password type="text" value={record.senha} onChange={changeHandler} name="senha" />
                    <label>{utils.getMessage(messages, 'usuario.label.senha')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-3">
                  <label htmlFor="chkativo" className="label">
                    {utils.getMessage(messages, 'usuario.label.ativo')}
                  </label>
                  <Checkbox
                    inputId="chkativo"
                    checked={record.ativo}
                    style={{ marginLeft: '5px' }}
                    onChange={changeHandler}
                    name="ativo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, 'usuario.label.perfis')}</h3>
              <div className="p-grid form-group">
                <div className="p-col-6">
                  <PickList
                    className="p-col-12"
                    source={props.perfis}
                    target={record.perfis}
                    responsive={true}
                    onMoveToTarget={this.handleMoveToTarget}
                    onMoveToSource={this.handleMoveToSource}
                    onMoveAllToSource={this.handleMoveAllToSource}
                    onMoveAllToTarget={this.handleMoveAllToTarget}
                    itemTemplate={(perfil) => (
                      <label>
                        {perfil.oid} - {perfil.nomePerfil}
                      </label>
                    )}
                    sourceHeader={utils.getMessage(messages, 'usuario.label.disponiveis')}
                    targetHeader={utils.getMessage(messages, 'usuario.label.selecionados')}
                    showSourceControls={false}
                    showTargetControls={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, 'usuario.label.unidades')}</h3>
              <div className="p-grid form-group">
                <div className="p-col-6">
                  <PickList
                    className="p-col-12"
                    source={props.unidadesDisponiveis}
                    target={record.unidades}
                    responsive={true}
                    onMoveToTarget={this.handleMoveUnidadesToTarget}
                    onMoveToSource={this.handleMoveUnidadesToSource}
                    onMoveAllToSource={this.handleMoveAllUnidadesToSource}
                    onMoveAllToTarget={this.handleMoveAllUnidadesToTarget}
                    itemTemplate={(idValue) => (
                      <label>
                        {idValue.id} - {idValue.value}
                      </label>
                    )}
                    sourceHeader={utils.getMessage(messages, 'usuario.label.disponiveis')}
                    targetHeader={utils.getMessage(messages, 'usuario.label.selecionados')}
                    showSourceControls={false}
                    showTargetControls={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UsuarioForm
