import utils from '../utils'
import storage from '../services/stoage.service'
import _, {isNull} from "lodash";

function setField(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = currentRecord
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  return Object.assign({}, state, { currentRecord })
}

function clearErrors(state, actions) {
  let errors = {}
  return Object.assign({}, state, { errors })
}

function addErrors(state, action) {
  let err = action.errors.reduce((result, current) => {
    result[current.field] = current.message
    return result
  }, {})
  let errors = Object.assign({}, state.errors, err)
  return Object.assign({}, state, { errors })
}

function removeErrors(state, action) {
  let errors = Object.assign({}, state.errors)
  action.errors.forEach((err) => delete errors[err.field])
  return Object.assign({}, state, { errors })
}

function setSelectedFilters(state, action) {
  let filters = Object.assign([], state.selectedFilters)
  //removemos os filtros existentes (valor pode ter mudado)
  const newFilters = filters.filter((e) => e.campo !== action.field)
  const isBooleanFilter = typeof action.value === 'boolean' // ? 'IGUAL_A' : 'CONTEM';

  let tipoFiltro = 'CONTEM';
  if (isBooleanFilter) tipoFiltro = 'IGUAL_A'
  else if (Array.isArray(action.value)) tipoFiltro = 'ESTA_CONTIDO_EM'

  // so adicionamos filtro caso tenha valor valido
  if (isBooleanFilter || (action.value && action.value !== '' && !(Array.isArray(action.value) && action.value.length === 0))) {
    newFilters.push({
      campo: action.field,
      valor: action.value,
      tipoFiltro: tipoFiltro,
      relacionamento: action.relacionamento,
      chaveComponente: action.chaveComponente
    })
  }
  return Object.assign(state, { selectedFilters: newFilters })
}

function clearSelectedFilters(state) {
  return Object.assign(state, { selectedFilters: [] })
}

function clearBooleanFilters(state) {
  return Object.assign(state, { booleanFilter: [] })
}

function setFiltroAvancado(state, action) {
  let filters = Object.assign([], state.selectedFilters)
  filters = filters.filter(e => !isNull(e))

  //removemos os filtros existentes (valor pode ter mudado)
  const campo = _.get(action, 'field.campo', undefined);
  const relacionamento = _.get(action, 'field.relacionamento', undefined);
  const index = filters.findIndex((e) => campo && e.campo === campo && JSON.stringify(e.relacionamento || '') === JSON.stringify(relacionamento || ''))
  if (index >= 0) {
    delete filters[index]
  }
  // const newFilters = filters.filter((e) => action.field && action.field.campo && e.campo !== action.field.campo)
  const newFilters = filters
  // so adicionamos filtro caso tenha valor valido
  if (action.field && action.field.campo) {
    let novoFiltro = {
      campo: action.field.campo,
      campoComplementar: action.field.campoComplementar,
      valor: action.field.valor,
      valorComplementar: action.field.valorComplementar,
      tipoFiltro: action.field.tipoFiltro,
      relacionamento: action.field.relacionamento,
      chaveComponente: action.field.chaveComponente,
    }
    newFilters.push(novoFiltro)
  }

  return Object.assign(state, { selectedFilters: newFilters })
}

export class Reducer {
  constructor(initialState, types) {
    const baseState = {
      selectedFilters: [],
      booleanFilter: [],
      columns: [],
      currentRecord: {},
      records: [],
      tags: [],
      causasMortis: [],
      /*campos para paginação */
      filters: [],
      sortField: '',
      sortOrder: 0,
      index: 0,
      max: utils.getLinhasPadraoPorPagina(storage.getCompany(), storage.getUnit()),
      count: 0,
      countDistinct: 0,
      errors: {},
      usaListDto: true,
      isSaving: false, // indicativo de que esta em processo de save (request ainda nao finalizou)
      resourceForGetAll: '', // endpoint alternativo para ser executado em 'crudFactory.getAll'
      filterValues: {},
    }
    this.state = Object.assign({}, baseState, { ...initialState })
    this.emptyRecord = Object.assign({}, this.state.currentRecord)
    this.types = types
  }

  execute(state = this.state, action) {
    let result = state
    switch (action.type) {
      case this.types.MAKE_NEW:
        result = Object.assign({}, state, { currentRecord: Object.assign({}, this.emptyRecord) })
        Object.keys(result.currentRecord).forEach(function (obj) {
          if (Object.keys(result.currentRecord[obj]).length > 0) {
            result.currentRecord[obj] = []
            // Object.keys(result.currentRecord[obj]).map(element => {
            //   return (result.currentRecord[obj][element] = {});
            // });
          }
        })
        result = clearSelectedFilters(result, action)
        result = clearBooleanFilters(result, action)
        break
      case this.types.SET_RECORDS:
        result = Object.assign({}, state, { records: action.records })
        break
      case this.types.SET_BOTOES_DINAMICOS:
        result = Object.assign({}, state, { botoesDinamicos: action.records })
        break
      case this.types.SET_COLUMNS:
        result = Object.assign({}, state, { columns: action.columns })
        break
      case this.types.SET_RECORD:
        const merged = Object.assign({}, this.emptyRecord, action.record)
        result = Object.assign({}, state, { currentRecord: merged })
        break
      case this.types.FIND_BY_CEP:
        const comb = Object.assign({}, this.emptyRecord, action.record)
        result = Object.assign({}, state, { currentRecord: comb })
        break
      case this.types.SET_COUNT:
        result = Object.assign({}, state, { count: action.count })
        break
      case this.types.SET_COUNT_DISTINCT:
        result = Object.assign({}, state, { countDistinct: action.payload })
        break
      case this.types.SET_INDEX:
        result = Object.assign({}, state, { index: action.index })
        break
      case this.types.SET_MAX:
        result = Object.assign({}, state, { max: action.max })
        break
      case this.types.SET_SORT_FIELD:
        result = Object.assign({}, state, { sortField: action.sortField })
        break
      case this.types.SET_SORT_ORDER:
        result = Object.assign({}, state, { sortOrder: action.sortOrder })
        break
      case this.types.SET_FILTERS:
        result = Object.assign({}, state, { filters: action.filters })
        break
      case this.types.SET_TYPE:
        result = Object.assign({}, state, { type: action.filterType })
        break
      case this.types.SET_FIELD:
        result = setField(state, action)
        break
      case this.types.ADD_ERRORS:
        result = addErrors(state, action)
        break
      case this.types.REMOVE_ERRORS:
        result = removeErrors(state, action)
        break
      case this.types.CLEAR_ERRORS:
        result = clearErrors(state, action)
        break
      case this.types.SET_SELECTED_FILTERS:
        result = setSelectedFilters(state, action)
        break
      case this.types.CLEAR_SELECTED_FILTERS:
        result = clearSelectedFilters(state, action)
        break
      case this.types.CLEAR_BOOLEAN_FILTERS:
        result = clearBooleanFilters(state, action)
        break
      case this.types.SET_FILTRO_AVANCADO:
        result = setFiltroAvancado(state, action)
        break
      case this.types.SET_SELECTED_RECORDS:
        result = Object.assign({}, state, { selectedRecords: action.records })
        break
      case this.types.CLEAR_SELECTED_RECORDS:
        result = Object.assign({}, state, { selectedRecords: [] })
        break
      case this.types.SET_IS_SAVING:
        result = Object.assign({}, state, { isSaving: action.payload })
        break
      case this.types.SET_RESOURCE_FOR_GET_ALL:
        result = Object.assign({}, state, { resourceForGetAll: action.payload })
        break
      case this.types.SET_STATE_ATTRIBUTE:
        result = Object.assign({}, state, action.payload)
        break
      default:
        result = state
    }
    return result
  }
}
