import React, { useEffect, useState } from 'react'
import storage from '../../services/stoage.service'
import LtsDropdownIdValue from './lts-dropdown-id-value'
import _ from 'lodash'
import apiService from '../../services/api-id-value-service'

export default function LtsDropdownUnidade(props) {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    checkUnidade()
  }, [props.value, props.disabled])

  const checkUnidade = () => {
    if (props.disabled) {
      setDisabled(true)
    } else {
      const currentUnit = storage.getUnit()
      const unidade = _.get(props, 'value', null)
      setDisabled(currentUnit && unidade && unidade.id === currentUnit.oid)
    }
  }

  const afterLoadDataFn = (data) => {
    if (props.showOptionAll) {
      data.unshift({ id: -1, value: `<Todas>` })
    }
    return data
  }

  return (
    <React.Fragment>
      <LtsDropdownIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        loadDataFn={apiService.getUnidadesUsuario}
        afterLoadDataFn={afterLoadDataFn}
        disabled={disabled}
      />
    </React.Fragment>
  )
}
