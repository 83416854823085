import { types } from './nfse.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'nfse',
  booleanFilter: { statusNFSe: '' },
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Núm. RPS',
      value: {
        field: 'numeroRPS',
        header: 'Núm. RPS',
        columnKey: 'numeroRPS',
        sortable: true,
        filter: true,
        columnSortField: 'numeroRPS',
        visible: true,
      },
    },
    {
      label: 'Núm. NFSe',
      value: {
        field: 'numeroNFSe',
        header: 'Núm. NFSe',
        columnKey: 'numeroNFSe',
        sortable: true,
        filter: true,
        columnSortField: 'numeroNFSe',
        visible: true,
      },
    },
    {
      label: 'Status',
      value: {
        field: 'statusNFSe',
        header: 'Status',
        columnKey: 'statusNFSe',
        sortable: true,
        filter: true,
        columnSortField: 'statusNFSe',
        visible: true,
      },
    },
    {
      label: 'Emissão',
      value: {
        field: 'dataHoraEmissaoRPS',
        header: 'Emissão',
        columnKey: 'dataHoraEmissaoRPS',
        sortable: true,
        filter: true,
        columnSortField: 'dataHoraEmissaoRPS',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Doc. Tomador',
      value: {
        field: 'cpfCnpjTomadorServico',
        header: 'Doc. Tomador',
        columnKey: 'cpfCnpjTomadorServico',
        sortable: true,
        filter: true,
        columnSortField: 'cpfCnpjTomadorServico',
        visible: true,
      },
    },
    {
      label: 'Tomador',
      value: {
        field: 'razaoSocialTomadorServico',
        header: 'Tomador',
        columnKey: 'razaoSocialTomadorServico',
        sortable: true,
        filter: true,
        columnSortField: 'razaoSocialTomadorServico',
        visible: true,
      },
    },
    {
      label: 'Valor',
      value: {
        field: 'valorServico',
        header: 'Valor',
        columnKey: 'valorServico',
        sortable: true,
        filter: true,
        columnSortField: 'valorServico',
        fieldFormatter: 'formatarDinheiro',
        visible: true,
      },
    },
    {
      label: 'Lançamento',
      value: {
        field: 'lancamentos_oid',
        header: 'Lançamento',
        columnKey: 'lancamentos_oid',
        columnSortField: 'lancamentos.oid',
        sortable: true,
        filter: true,
        relacionamento: ['lancamentos'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'CNAE',
      value: {
        field: 'cnae',
        header: 'CNAE',
        columnKey: 'cnae',
        sortable: true,
        filter: true,
        columnSortField: 'cnae',
        visible: true,
      },
    },
    {
      label: 'Serviço',
      value: {
        field: 'descricaoServico',
        header: 'Serviço',
        columnKey: 'descricaoServico',
        sortable: true,
        filter: true,
        columnSortField: 'descricaoServico',
        visible: true,
      },
    },
    {
      label: 'Chave de Acesso',
      value: {
        field: 'chaveAcesso',
        header: 'Chave de Acesso',
        columnKey: 'chaveAcesso',
        sortable: true,
        filter: true,
        columnSortField: 'chaveAcesso',
        visible: false,
      },
    },
    {
      label: 'Série RPS',
      value: {
        field: 'serieRPS',
        header: 'Série RPS',
        columnKey: 'serieRPS',
        sortable: true,
        filter: true,
        columnSortField: 'serieRPS',
        visible: true,
      },
    },
    {
      label: 'Tipo RPS',
      value: {
        field: 'tipoRPS',
        header: 'Tipo RPS',
        columnKey: 'tipoRPS',
        sortable: true,
        filter: true,
        columnSortField: 'tipoRPS',
        visible: false,
      },
    },
    {
      label: 'Situação RPS',
      value: {
        field: 'situacaoRPS',
        header: 'Situação RPS',
        columnKey: 'situacaoRPS',
        sortable: true,
        filter: true,
        columnSortField: 'situacaoRPS',
        visible: false,
      },
    },
  ],
  colunasHistorico: [
    {
      field: 'statusNFSe',
      header: 'Status',
      columnKey: 'statusNFSe',
    },
    {
      field: 'numeroNfse',
      header: 'Número NFS-e',
      columnKey: 'numeroNfse',
    },
    {
      field: 'codigoVerificadorNfse',
      header: 'Cód. verificador NFS-e',
      columnKey: 'codigoVerificadorNfse',
    },
    {
      field: 'dataEmissaoNfse',
      header: 'Data emissão NFS-e',
      columnKey: 'dataEmissaoNfse',
    },
    {
      field: 'descricaoErro',
      header: 'Descrição do erro',
      columnKey: 'descricaoErro',
    },
  ],
  colunasTLancamentoFinanceiro: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
    },
    {
      field: 'emissao',
      header: 'Emissão',
      columnKey: 'emissao',
      sortable: true,
      filter: true,
      columnSortField: 'emissao',
    },
    {
      field: 'tipoFormaPagamento.value',
      header: 'Forma Pagamento',
      columnKey: 'tipoFormaPagamento.value',
      sortable: true,
      filter: true,
      columnSortField: 'tipoFormaPagamento.value',
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
    },
    {
      field: 'vencimento',
      header: 'Vencimento',
      columnKey: 'vencimento',
      sortable: true,
      filter: true,
      columnSortField: 'vencimento',
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDNfseReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('nfse')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
