import React from 'react';
import SexoListPageContainer from './MapaListPageContainer';

export class MapaListPage extends React.Component{
    
    render(){
      return (
        <SexoListPageContainer {...this.props}/>
      )
    }
}

