import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MainLayout from "./layouts/main.layout";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import {loaderActions} from './store/loader';
import {actions} from './store/app/index';
import * as moment from 'moment';
import storage from "./services/stoage.service"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel, faBarcode } from '@fortawesome/free-solid-svg-icons';

import './assets/theme/bluegrey/theme-light.css';
import './assets/layout/css/layout-moody.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';

// V7 CSS
// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/mdc-light-indigo/theme.css';
// import 'primereact/resources/primereact.css';
// import 'primeflex/primeflex.css';
// import './v7Index.css';

import {LocalePtBr} from "./LocalePtBr";

window.store = store;
window.loaderActions = loaderActions;
window.actions = actions;
window.moment = moment;

new LocalePtBr();

const Main = () => (
  <Provider store={store}>
    <MainLayout />
  </Provider>
);
library.add(faStroopwafel,faBarcode);
ReactDOM.render(<Main/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// axios.interceptors.request.use(config => {
//   return config;
// });

// axios.interceptors.response.use((response) => {
//   const newToken = response.headers.refreshedtoken;
//   if (newToken) {
//     storage.setToken(newToken);
//   }
//   return response;
// });