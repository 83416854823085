import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import LtsLookup from './lts-lookup'
import '../../index.scss'
import { InputText } from 'primereact/inputtext'
import { getFiltroJoinUnidade } from '../../utils/filter-utils'

export default function LtsLookupPessoaParent(props) {
  const [filters, setFilters] = useState([])

  const columnsPF = [
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
      visible: false,
    },
    {
      field: 'dataFalecimento',
      header: 'Falecimento',
      columnKey: 'dataFalecimento',
      visible: false,
    },
  ]

  const columns = [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: false,
      filter: true,
    },
    {
      field: 'numeroDocumento',
      header: 'CPF/CNPJ',
      columnKey: 'numeroDocumento',
      sortable: true,
      filter: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      filter: true,
    },
    {
      field: 'email',
      header: 'E-mail',
      columnKey: 'email',
      sortable: true,
      filter: true,
    },
    {
      field: 'telefone',
      header: 'Telefone',
      columnKey: 'telefone',
      sortable: true,
      filter: true,
    },
  ]

  const loadDataFn = (params) => {
    const url = '/lottustech/pessoasfisicas/all'
    return apiService.post(url, params)
  }

  const onKeyPress = (e) => {
    const idValue = _.get(props, 'value', null)
    if (idValue) {
      const value = typeof idValue === 'string' ? idValue : _.get(idValue, 'value', null)
      const filters = [new Filtro('nome', value, 'CONTEM'), new Filtro('numeroDocumento', value, 'CONTEM')]
      const filter = {
        campo: 'filtro',
        valor: filters,
        tipoFiltro: 'OU',
      }
      setFilters([filter])
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({ nome: e.value, oid: null })
    } else {
      props.onChange(value)
    }
  }

  const resolveColumns = () => {
    return [...columns, ...columnsPF]
  }

  const onResolvingColumn = () => {
    const columns_ = resolveColumns()
    return columns_
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false

        if (col.field === 'numeroDocumento') {
          return (
            <Column
              {...col}
              key={col.field}
              bodyClassName="column-nr-documento-body-width"
              filterHeaderClassName="column-nr-documento-filter-header-width"
              headerClassName="column-nr-documento-header-width"
              filterElement={columnFilterElement(col)}
            />
          )
        }
        if (col.field === 'nome') {
          return <Column {...col} key={col.field} filterElement={columnFilterElement(col)} style={{ width: '20em' }} />
        }
        return null
      })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    const filtroUnidade = getFiltroJoinUnidade(_.get(props, 'idUnidade', null))
    if (filtroUnidade) {
      filters.push(filtroUnidade)
    }
    return filters
  }

  const customEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    if (oid) {
      window.open(`/phoenix${routes.PATH_PESSOA_FISICA}/${oid}`)
    } else {
      window.open(`/phoenix${routes.PATH_PESSOA_FISICA}/novo`)
    }
  }

  const pathOnEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    const rota = routes.PATH_PESSOA_FISICA
    if (oid) {
      return `${rota}/${oid}`
    }
    return `${rota}/novo`
  }

  const resolveDisplayValue = () => {
    const idValue = _.get(props, 'value', null)
    return _.get(idValue, 'value', null)
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={resolveDisplayValue()}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={null}
        onKeyPress={onKeyPress}
        onComplete={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEditFn={pathOnEditFn}
        header={props.header}
        columns={resolveColumns()}
        onResolvingColumn={onResolvingColumn}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        showAddButton={false}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        editDisabled={false}
        disabled={props.disabled}
        editHidden={false}
        customEditFn={customEditFn}
        noSuggestions={true}
      />
    </React.Fragment>
  )
}
