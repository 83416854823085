import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import storage from '../../../services/stoage.service'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, icons } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../pessoaFisica.actions'
import { PessoaFisicaList } from './PessoaFisicaList'
import '../../../index.scss'
import LtsLookupCategoria from '../../../components/lottus/lts-lookup-categoria'
import _ from 'lodash'

const mapStateToProps = (state) => {
  let records = state.crudPessoaFisicaState.records
  let messages = state.crudPessoaFisicaState.messages
  let tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))
  return {
    tiposFiltros,
    messages,
    columns: state.crudPessoaFisicaState.columns,
    showHideColumns: state.crudPessoaFisicaState.showHideColumns,
    records,
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    selectedFilters: state.crudPessoaFisicaState.selectedFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      if (target.name === 'numeroDocumento') {
        target.value = target.value.replace(/\D+/g, '')
      }
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/pessoasfisicas/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    pesquisaAvancada: (state) => {
      if (state.filtroNascimento.tipoFiltro && state.filtroNascimento.tipoFiltro.value !== null) {
        let filtroNascimento = state.filtroNascimento
        let novoFiltro = {
          campo: 'dataNascimento',
          valor: filtroNascimento.dataInicial,
          valorComplementar: filtroNascimento.dataFinal,
          tipoFiltro: filtroNascimento.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroFalecimento.tipoFiltro && state.filtroFalecimento.tipoFiltro.value !== null) {
        let filtroFalecimento = state.filtroFalecimento
        let novoFiltro = {
          campo: 'dataFalecimento',
          valor: filtroFalecimento.dataInicial,
          valorComplementar: filtroFalecimento.dataFinal,
          tipoFiltro: filtroFalecimento.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroCategoria && state.filtroCategoria.oid) {
        let filtroCategoria = state.filtroCategoria
        let novoFiltro = {
          campo: 'oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['categorias'],
          valor: filtroCategoria.oid,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroFalecido) {
        let novoFiltro = {
          campo: 'falecido',
          tipoFiltro: 'NAO_NULO',
          relacionamento: ['contratosFalecido'],
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroResponsavel) {
        let novoFiltro = {
          campo: 'responsavel',
          tipoFiltro: 'NAO_NULO',
          relacionamento: ['contratosResponsavel'],
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroDependente) {
        let novoFiltro = {
          campo: 'dependente',
          tipoFiltro: 'NAO_NULO',
          relacionamento: ['contratosDependente'],
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/pessoasfisicas/all`))
      }, 400)
    },

    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    clearCurrentRecord: () => dispatch(actions.clearCurrentRecord()),
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class PessoaFisicaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showFilter = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      // referente a filtros avançados
      dataInicial: null,
      dataFinal: null,
      filtroResponsavel: false,
      filtroFalecido: false,
      filtroDependente: false,
      filtroCategoria: false,
      filtroNascimento: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroFalecimento: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }
  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  changeTipoFiltro = (filtro, valor) => {
    let filtroAtual = filtro
    filtroAtual.tipoFiltro = valor
    filtroAtual.dataFinal = null
    filtroAtual.dataInicial = null
    filtroAtual.mostrarDataInicial = valor.dataInicial
    filtroAtual.mostrarDataFinal = valor.dataFinal
    return filtroAtual
  }

  changeTipoFiltroNascimento = (filtro, valor) => {
    const filtroNascimento = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroNascimento })
  }

  changeTipoFiltroFalecimento = (filtro, valor) => {
    const filtroFalecimento = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroFalecimento })
  }

  changeDataFiltro = (filtro, campo, valor) => {
    let filtroAtual = filtro
    filtroAtual[campo] = valor
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA_FISICA}/novo`)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
    this.props.clearCurrentRecord()
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.clearSelectedFilters()
    this.props.pesquisaAvancada(this.state)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  buildFiltroDataNascimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroNascimento.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroNascimento(this.state.filtroNascimento, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              dataKey="value"
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'pessoafisica.label.datanascimento')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroNascimento.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroNascimento.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="nascimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroNascimento, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroNascimento.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroNascimento.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="nascimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroNascimento, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataFalecimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroFalecimento.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroFalecimento(this.state.filtroFalecimento, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'pessoafisica.label.falecimento')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroFalecimento.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroFalecimento.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="falecimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroFalecimento, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroFalecimento.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroFalecimento.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="falecimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroFalecimento, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroCategoria = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <div className="p-col-4" style={{ padding: '6px', paddingTop: '0px' }}>
          <LtsLookupCategoria
            label={utils.getMessage(this.props.messages, 'pessoafisica.label.categorias')}
            value={this.state.filtroCategoria}
            editHidden={true}
            field={'descricao'}
            multiple={false}
            onChange={(e) =>
              this.setState({ filtroCategoria: { oid: _.get(e, 'oid', ''), descricao: _.get(e, 'descricao', '') } })
            }
          />
        </div>
      </div>
    )
  }

  buildFiltroResponsavel = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <label className="label">{utils.getMessage(this.props.messages, 'pessoafisica.label.responsavel')}</label>
        <Checkbox
          style={{ marginLeft: '5px' }}
          checked={this.state.filtroResponsavel}
          onChange={(e) => this.setState({ filtroResponsavel: !this.state.filtroResponsavel })}
        />
      </div>
    )
  }

  buildFiltroFalecido = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <label className="label">{utils.getMessage(this.props.messages, 'pessoafisica.label.falecido')}</label>
        <Checkbox
          style={{ marginLeft: '31px' }}
          checked={this.state.filtroFalecido}
          onChange={(e) => this.setState({ filtroFalecido: !this.state.filtroFalecido })}
        />
      </div>
    )
  }

  buildFiltroDependente = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <label className="label">{utils.getMessage(this.props.messages, 'pessoafisica.label.dependente')}</label>
        <Checkbox
          style={{ marginLeft: '9px' }}
          checked={this.state.filtroDependente}
          onChange={(e) => this.setState({ filtroDependente: !this.state.filtroDependente })}
        />
      </div>
    )
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroDataNascimento()}

          {this.buildFiltroDataFalecimento()}
          <br />
          {this.buildFiltroCategoria()}
          <br />
          {this.buildFiltroResponsavel()}
          <br />
          {this.buildFiltroFalecido()}
          <br />
          {this.buildFiltroDependente()}
          <br />
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'pessoafisica.label.pessoasfisicas')}
      />
    )
  }

  cpfTemplate = (rowData) => {
    return <Link to={`${routes.PATH_PESSOA_FISICA}/${rowData.oid}`}>{utils.formatCPF(rowData.numeroDocumento)}</Link>
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'numeroDocumento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.cpfTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-nr-documento-body-width"
            filterHeaderClassName="column-nr-documento-filter-header-width"
            headerClassName="column-nr-documento-header-width"
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <PessoaFisicaList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaFisicaListPageContainer)
