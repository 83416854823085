import React, {useEffect, useState} from 'react';
import {Card} from "primereact/card";
import {InputTextarea} from "primereact/inputtextarea";
import {AutoComplete} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {icons} from "../../constants";
import LtsCalendar from "./lts-calendar";
import _ from "lodash"
import apiService from "../../services/api.service";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "../../index.scss";
import {Dialog} from "primereact/dialog";
import utils from "../../utils";
import LtsLookupMarcador from "./lts-lookup-marcador";
import {actions} from "../../pages/contrato/contrato.actions";

export default function LtsHistorico(props) {

    const [descricao, setDescricao] = useState("")
    const [tag, setTag] = useState({id: "", value: ""})
    const [lembrete, setLembrete] = useState("")
    const [tags, setTags] = useState([])
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [showFormHistorico, setShowFormHistorico] = useState(false)

    useEffect(() => {

    }, [props.values])

    const columns = [
        {
            field: "descricao",
            header: "Descrição",
            columnKey: "descricao"
        },
        {
            field: "tag",
            header: "Tag",
            columnKey: "tag"
        },
        {
            field: "data",
            header: "Data",
            columnKey: "data"
        },
        {
            field: "usuario",
            header: "Usuário",
            columnKey: "usuario"
        },
        {
            field: "acoes",
            header: "Ações",
            columnKey: "acoes"
        }
    ]

    const resolveColumns = () => {
        return columns
            .map(col => {
                col.showFilterMenuOptions = false
                col.showClearButton = false
                col.showFilterMenu = false

                if (col.field === "data") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            body={columnData}
                            bodyClassName="column-date-body-width"
                            filterHeaderClassName="column-date-filter-header-width"
                            headerClassName="column-date-header-width"
                        />
                    );
                }
                if (col.field === "descricao") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-nome-body-width"
                            filterHeaderClassName="column-nome-filter-header-width"
                            headerClassName="column-nome-header-width"
                        />
                    );
                }
                if (col.field === "acoes") {
                    return <Column
                        {...col}
                        key={col.field}
                        body={actions}
                    />;
                }
                return <Column key={col.field} {...col} />;
            })
    }

    const columnData = (rowData, column) => {
        return <div>{window.moment(Number(rowData.data)).format("DD/MM/YYYY HH:mm:ss")}</div>;
    }

    const actions = rowData => {
        return (
            <div>
                <Button
                    type="button"
                    icon={icons.PENCIL_ICON}
                    className="p-button-warning"
                    onClick={e => onClickEdit(e, rowData)}
                    disabled={!props.canEdit}
                />
                <Button
                    style={{marginLeft: "10px"}}
                    type="button"
                    icon={icons.REMOVE_ICON}
                    className="p-button-danger"
                    onClick={e => onClickRemove(e, rowData)}
                    disabled={!props.canRemove}
                />
            </div>
        );
    };

    const onClickEdit = (e, data) => {
        e.stopPropagation()
        e.preventDefault()

        setDescricao(_.get(data, 'descricao', ""))
        setLembrete(_.get(data, 'lembrete', ""))

        const t = {
            id: _.get(data, 'oidTag', ""),
            value: _.get(data, 'tag', "")
        }
        setTag(t)
        setSelectedRecord(data)
        setShowFormHistorico(true)
    }

    const onClickRemove = (e, data) => {
        e.stopPropagation()
        e.preventDefault()
        const newValues = []
        props.values.forEach(r => {
            if (r !== data) {
                newValues.push(r)
            }
        })
        props.onChange(newValues)
    }

    // const onAddClick = e => {
    //     e.stopPropagation()
    //     e.preventDefault()
    //     if (selectedRecord) {
    //         let r = props.values.find(f => f === selectedRecord)
    //         r.descricao = descricao
    //         r.tag = _.get(tag, 'value', null)
    //         r.oidTag = _.get(tag, 'id', null)
    //         r.lembrete = lembrete
    //         r.data = new Date()
    //         r.usuario = _.get(props, 'currentUser', '')
    //     } else {
    //         const historico = {
    //             descricao,
    //             tag: _.get(tag, 'value', ''),
    //             oidTag: _.get(tag, 'id', null),
    //             lembrete,
    //             data: new Date(),
    //             usuario: _.get(props, 'currentUser', '')
    //         }
    //         const values = [...props.values, historico]
    //         props.onChange(values)
    //     }
    //
    //     resetValues()
    // }

    const onAddClick = e => {
        e.stopPropagation()
        e.preventDefault()
        if (selectedRecord) {
            let r = props.values.find(f => f === selectedRecord)
            r.descricao = descricao
            r.tag = _.get(tag, 'value', null)
            r.oidTag = _.get(tag, 'id', null)
            r.lembrete = lembrete
            r.data = new Date()
            r.usuario = _.get(props, 'currentUser', '')
        } else {
            const historico = {
                descricao,
                tag: _.get(tag, 'value', ''),
                oidTag: _.get(tag, 'id', null),
                lembrete,
                data: new Date(),
                usuario: _.get(props, 'currentUser', '')
            }
            const values = [...props.values, historico]
            props.onChange(values)
        }

        setShowFormHistorico(false)
        resetValues()
    }


    const resetValues = () => {
        setSelectedRecord(null)
        setDescricao("")
        setTag({id: "", value: ""})
        setLembrete("")
    }

    const loadTags = e => {
        apiService.get(`/lottustech/tags/${e.query}`)
            .then(onLoadTagsSuccess)
            .catch(onLoadTagsError)
    }

    const onLoadTagsSuccess = response => {
        const data = _.get(response, 'data', [])
        setTags(data)
    }

    const onLoadTagsError = error => {
        setTags([])
        console.log(error)
    }

    const formHistorico = () => {
        const footer = (
            <div>
                <Button
                    onClick={e => onAddClick(e)}
                    label={"Confirmar"}
                    className="p-button-success"
                />
            </div>
        )

        return (
            <Dialog
                onHide={onHideFormHistorico}
                header={"Adicionar/editar histórico"}
                visible={showFormHistorico}
                modal={true}
                style={{width: "30%"}}
                footer={footer}
            >
                <div className="p-grid p-fluid margin-top-dialogs">
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputTextarea
                                value={descricao}
                                onChange={e => setDescricao(_.get(e, 'target.value', ""))}
                                name="descricao"
                                rows={2}
                                cols={30}
                                autoResize={true}
                            />
                            <label>Descrição</label>
                        </span>
                    </div>
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <LtsLookupMarcador
                                    label={'Tag'}
                                    value={tag.value}
                                    history={props.history}
                                    editHidden={true}
                                    field={'codigo'}
                                    multiple={false}
                                    onChange={event => {
                                        let object = event
                                        if (Array.isArray(event) && event.length > 0) {
                                            object = event[0]
                                        }
                                        if (object) {
                                            setTag({id: object.oid, value: object.codigo})
                                        }
                                    }}
                                    showAddButton={true}
                                    stateMarcador={props.stateMarcador}
                                    tipo={'HISTORICO'}
                            />
                        </span>
                    </div>
                    <div className="p-col-12">
                        <LtsCalendar
                            value={lembrete}
                            name={"lembrete"}
                            label={"Lembrete"}
                            onChange={e => setLembrete(e)}
                        />
                    </div>
                </div>
            </Dialog>
        )
    }

    const onHideFormHistorico = () => {
        resetValues()
        setShowFormHistorico(false)
    }

    const onAddNewClick = e => {
        e.stopPropagation()
        e.preventDefault()
        formHistorico()
        setShowFormHistorico(true)
    }

    const resolveTitle = () => (
        <div className="p-col-1" style={{display: "inline-flex"}}>
            <div>
                <Button
                    onClick={e => onAddNewClick(e)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                />
            </div>
            <h5 style={{marginLeft: "5px", marginTop: "5px"}}>Histórico</h5>
        </div>
    )


    return (
        <React.Fragment>
            {formHistorico()}
            <Card title={resolveTitle()}>
                {/*<div className="p-grid p-justify-between">*/}
                {/*    <div className="p-col-12 p-sm-6 p-md-6 p-lg-3 p-xl-3">*/}
                {/*        <span className="p-float-label">*/}
                {/*            <InputTextarea*/}
                {/*                value={descricao}*/}
                {/*                onChange={e => setDescricao(_.get(e, 'target.value', ""))}*/}
                {/*                name="descricao"*/}
                {/*                rows={1}*/}
                {/*                cols={30}*/}
                {/*                autoResize={true}*/}
                {/*            />*/}
                {/*            <label>Descrição</label>*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className="p-col-12 p-sm-6 p-md-6 p-lg-3 p-xl-3">*/}
                {/*        <span className="p-float-label">*/}
                {/*            <AutoComplete*/}
                {/*                completeMethod={loadTags}*/}
                {/*                delay={100}*/}
                {/*                name="tag"*/}
                {/*                field="value"*/}
                {/*                value={tag}*/}
                {/*                suggestions={tags}*/}
                {/*                onChange={e => setTag(e.value)}*/}
                {/*            />*/}
                {/*            <label>Tag</label>*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className="p-col-12 p-sm-6 p-md-6 p-lg-3 p-xl-3">*/}
                {/*        <LtsCalendar*/}
                {/*            value={lembrete}*/}
                {/*            name={"lembrete"}*/}
                {/*            label={"Lembrete"}*/}
                {/*            onChange={e => setLembrete(e)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="p-col-1 p-col-align-end">*/}
                {/*        <Button*/}
                {/*            onClick={onAddClick}*/}
                {/*            label={"Adicionar"}*/}
                {/*            className="p-button-success"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="p-grid form-group" style={{marginTop: "15px"}}>
                    <div className="p-col-12">
                        <DataTable
                            emptyMessage="Nenhum registro"
                            value={props.values}
                            resizableColumns={true}
                            columnResizeMode="expand"
                            filterDisplay="row"
                            stripedRows
                            responsiveLayout="scroll"
                            size={"small"}
                        >
                            {resolveColumns()}
                        </DataTable>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    )
}