import React, { useState, useEffect } from 'react'

import _, { isNull } from 'lodash'
import { LookupField } from '../lookup/LookupField'

export default function LtsLookup(props) {
  const [records, setRecords] = useState([])
  const [count, setCount] = useState(0)
  const [rows, setRows] = useState(15)
  const [index, setIndex] = useState(0)
  const [sortOrder, setSortOrder] = useState(0)
  const [sortField, setSortField] = useState('')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    loadData({ index, rows })
  }, [props.filters, sortField, sortOrder])

  const loadData = ({ index = 0, rows = 15 }) => {
    let sortFields = []
    if (sortField) {
      sortFields.push({ fieldName: sortField, order: sortOrder === 1 ? 'ASC' : 'DESC' })
    }
    const projectionFieldsColumns = props.columns.map((col) => col.columnKey)
    const addFiltroDistint = props.addFiltroDistint

    const filters = [...props.filters].filter((f) => !isNull(f))
    const params = {
      index,
      addFiltroDistint: addFiltroDistint,
      max: rows,
      filters: filters,
      projectionFields: projectionFieldsColumns,
      sortFields,
    }

    params.filters.push({ campo: 'ativo', valor: true, tipoFiltro: 'IGUAL_A' })
    const resolveFiltersFn = _.get(props, 'resolveFilters', null)
    if (resolveFiltersFn) {
      resolveFiltersFn.call(resolveFiltersFn, params.filters)
    }

    const loadDataFn = _.get(props, 'loadDataFn', null)
    if (loadDataFn) {
      loadDataFn.call(loadDataFn, params).then(onSuccessLoadData).catch(onErrorLoadData)
    }
  }

  const onSuccessLoadData = (response) => {
    const data = _.get(response, 'data', {})
    const records_ = _.get(data, 'records', [])
    const page_ = _.get(data, 'page', 1)
    const index_ = _.get(data, 'index', 0)
    const rows_ = _.get(data, 'size', 15)
    const count_ = _.get(data, 'count', 0)
    setRecords(records_)
    setCount(count_)
    setRows(rows_)
    setIndex(index_)
  }

  const onErrorLoadData = (error) => {
    setRecords([])
    console.log(error)
  }

  const onHide = (e) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    setVisible(false)
    setRecords([])
    setCount(0)
    setRows(15)
    setIndex(0)
  }

  const onCancel = (e) => {
    onHide(e)
  }

  const onConfirm = (e) => {
    props.onChange(e.selection)
    onHide(e.originalEvent)
  }

  const onPage = (e) => {
    loadData({
      index: _.get(e, 'first', 0),
      rows: _.get(e, 'rows', 15),
    })
  }

  const onSort = (e) => {
    setSortField(e.sortField)
    setSortOrder(e.sortOrder)
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.setFilters([])
    setVisible(true)
  }

  const onComplete = () => {}

  const onEdit = () => {
    const path = _.get(props, 'pathOnEdit', null)
    if (path) {
      return `${path}/`
    }
    const pathOnEditFn = _.get(props, 'pathOnEditFn', null)
    if (pathOnEditFn) {
      return pathOnEditFn.call(pathOnEditFn)
    }
  }

  const onFilter = (e) => {
    const filters = []
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        filters.push(filter)
      }
    })
    props.setFilters(filters)
  }

  return (
    <React.Fragment>
      <LookupField
        label={props.label}
        value={props.value}
        name={props.name}
        field={props.field}
        history={props.history}
        itemTemplate={props.autoCompleteTemplate}
        onKeyPress={props.onKeyPress}
        onComplete={props.onComplete || onComplete}
        onInputFilter={props.onInputFilter}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        onEditField={onEdit}
        onClick={onClick}
        visible={visible}
        modal={true}
        header={props.header}
        onHide={onHide}
        onConfirm={onConfirm}
        onCancel={onCancel}
        records={records}
        columns={props.onResolvingColumn()}
        totalRecords={count}
        order={sortOrder}
        sortField={sortField}
        first={index}
        rows={rows}
        onPage={onPage}
        onFilter={onFilter}
        onSort={onSort}
        showAddButton={props.showAddButton} //exibir botão '+'
        onClickAddButton={props.onClickAddButton} //
        multiple={props.multiple} //
        disabled={props.disabled} //
        dialogWidth={props.dialogWidth} //
        showFiltroTemporaria={props.showFiltroTemporaria}
        onChangeTemporaria={props.onChangeTemporaria}
        apenasTemporaria={props.apenasTemporaria}
        customEditFn={props.customEditFn}
        noSuggestions={props.noSuggestions}
      />
    </React.Fragment>
  )
}
