import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
const BASE_PATH = "/lottustech/categorias";
const crud = build(validate, BASE_PATH);
const prefix = "crudCategoria";

const newTypes = {
  SET_LOOKUP_CATEGORIA_VISIBLE: `${prefix}setLookupCategoriaVisible`,
  RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.setLookupCategoriaVisible = visible => {
  return {
    type: types.SET_LOOKUP_CATEGORIA_VISIBLE,
    visible
  };
};

export { types, actions };
