import authentication from "../../services/authentication.service";
import { loginSetCredential, authenticate } from "../login/login.actions";
export const types = {
  LOGIN_SET_ERROR_MESSAGE: "login.loginSetErrorMessage",
  SET_SUCCESS_MESSAGE: "setSuccessMessage",
  LOGIN_CLEAR_ERROR_MESSAGE: "login.clearErrorMessage",
  LOGIN_FETCH_AUTHENTICATION: "login.fetchAuthentication",
  LOGIN_FETCH_AUTHENTICATION_SUCCESS: "login.fetchAuthenticationSuccess",
  LOGIN_FETCH_AUTHENTICATION_ERROR: "login.fetchAuthenticationError"
};

export function loginSetErrorMessage(errorMessage = "") {
  return {
    type: types.LOGIN_SET_ERROR_MESSAGE,
    errorMessage
  };
}

export function setSuccessMessage(msg = "") {
  return {
    type: types.SET_SUCCESS_MESSAGE,
    msg
  };
}

export function loginClearErrorMessage() {
  return {
    type: types.LOGIN_CLEAR_ERROR_MESSAGE
  };
}

export function fetchAuthentication() {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION,
    async: true
  };
}

export function fetchAuthenticationError(errorMessage) {
  return dispatch => {
    dispatch(loginSetErrorMessage(errorMessage));
    return {
      type: types.LOGIN_FETCH_AUTHENTICATION_ERROR,
      async: false
    };
  };
}

export function redefinirSenha(dados) {
  return (dispatch, getState) => {
    let state = getState()[`redefinicaoSenhaState`];
    Object.assign(state, { showMessage: false });
    Object.assign(state, { showMessageSuccess: false });
    dispatch(fetchAuthentication());
    authentication
      .redefinirSenha(dados)
      .then(response => {
        Object.assign(state, { showMessageSuccess: true });
        dispatch(loginSetCredential({login: response.data, password: dados.senha}));
        dispatch(authenticate());
      })
      .catch(err => {
        Object.assign(state, { showMessage: true });
        if (err && err.response && err.response.data) {
          const errorMessage = err.response.data;
          dispatch(loginSetErrorMessage(errorMessage));
        }
      });
  };
}
