import { types } from './dashboard.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils'

const initialState = {
  currentRecord: {
    atividades: {
      oid: '',
      boolean: false,
      data: null,
      descricao: '',
      tag: { id: '', value: '' },
    },
    maisAcessados: [],
    ultimosAcessados: [],
    tasks: [],
    messages: null,
    tags: {},
    atividadesRecentes: [],
  },
}

const reducer = new Reducer(initialState, types)

export function CRUDDashboardReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('dashboard')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  if (action.type === types.CONCAT_ATIVIDADES_RECENTES) {
    return concatAtividadesRecentes(state, action)
  } else if (action.type === types.RESET) {
    return reset(state, action)
  }
  return state
}

function reset(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.atividadesRecentes = []
  currentRecord.maisAcessados = []
  currentRecord.ultimosAcessados = []
  currentRecord.tasks = []
  return { ...state, currentRecord: currentRecord }
}

function concatAtividadesRecentes(state, action) {
  const newValues = action.payload
  if (utils.isArrayNullOrEmpty(newValues)) {
    return state
  }

  let currentRecord = state.currentRecord
  const atividadesAtuais = currentRecord.atividadesRecentes || []
  currentRecord.atividadesRecentes = resolveTimeline(atividadesAtuais.concat(newValues))
  return { ...state, currentRecord: currentRecord }

  function resolveTimeline(values) {
    if (utils.isArrayNullOrEmpty(values)) {
      return []
    }

    let valids = values.filter((f) => !f.hasOwnProperty('separador'))
    if (utils.isArrayNullOrEmpty(valids)) {
      return []
    }

    valids = utils.removeDuplicatesFromArrayByProperty(valids, 'oid')
    valids = utils.orderArrayByProperty(valids, 'data')
    valids.reverse()

    let result = []
    let data = null
    valids.forEach((t) => {
      let currentData = window.moment(t.data)
      currentData.hours(0)
      currentData.minutes(0)
      currentData.seconds(0)
      currentData.milliseconds(0)
      if (
        utils.isArrayNullOrEmpty(result) ||
        (data && currentData && (currentData.isAfter(data) || currentData.isBefore(data)))
      ) {
        result.push({ separador: true, data: utils.formatarData(t.data) })
      }
      result.push(t)
      data = currentData
    })
    return result
  }
}
