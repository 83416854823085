import React from 'react'
import { getMessage } from '../../../utils/index'
import { Card } from 'primereact/card'
import { InputMask } from 'primereact/inputmask'

import './EnderecoForm.scss'
import LtsInputText from '../../../components/lottus/lts-input-text'
import utils from '../../../utils'
import LtsLookupEndereco from '../../../components/lottus/lts-lookup-endereco'

export const EnderecoForm = (props) => {
  const record = props.record
  const messages = props.messages

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card title={`${getMessage(messages, 'endereco.label.endereco')} ${record.oid || ''}`}>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.descricao}
                onChange={(e) => props.setField('descricao', e)}
                name="descricao"
                autoFocus={true}
                maxLength={255}
                label={getMessage(messages, 'endereco.label.descricao')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-1">
              <span className="p-float-label">
                <InputMask mask="99999-999" unmask={true} value={record.cep} onChange={props.cepOnChange} name="cep" />
                <label>{utils.getMessage(messages, 'endereco.label.cep')}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.endereco}
                onChange={(e) => props.setField('endereco', e)}
                name="endereco"
                maxLength={255}
                label={getMessage(messages, 'endereco.label.endereco')}
              />
            </div>

            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-1">
              <LtsInputText
                value={record.numero}
                onChange={(e) => props.setField('numero', e)}
                name="numero"
                maxLength={50}
                label={getMessage(messages, 'endereco.label.numero')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.complemento}
                onChange={(e) => props.setField('complemento', e)}
                name="complemento"
                maxLength={255}
                label={getMessage(messages, 'endereco.label.complemento')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.bairro}
                onChange={(e) => props.setField('bairro', e)}
                name="bairro"
                maxLength={255}
                label={getMessage(messages, 'endereco.label.bairro')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.cidade}
                onChange={(e) => props.setField('cidade', e)}
                name="cidade"
                maxLength={255}
                label={getMessage(messages, 'endereco.label.cidade')}
              />
            </div>

            <div className="p-col-6 p-sm-12 p-md-6 p-lg-5 p-xl-1">
              <LtsInputText
                value={record.uf}
                onChange={(e) => props.setField('uf', e)}
                name="uf"
                maxLength={2}
                label={getMessage(messages, 'endereco.label.uf')}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
