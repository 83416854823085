import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/portalCliente";
const crud = build(validate, BASE_PATH);
const prefix = "crudPortalClienteConsultaCliente";

const newTypes = {
}
const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.consultarCliente = (documento) => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/consultarCliente/`;
    return apiService.post(path, documento).then(response => {
      const result = response.data;
      dispatch(actions.setField("valido", result ? "VALIDO" : "INVALIDO"));
    });
  };
};

export { types, actions };
