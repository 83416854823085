import { types } from './tipossepulturasrelatorio.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils'
import { getUltimoCampoPesquisaLocalizacaoGrid } from '../../utils/tipo-sepultura-config-utils'
import _ from 'lodash'

const initialState = {
  id: 'livrocaixa',
  columns: [],
  currentRecord: {
    oid: '',
    tipoSepulturasSelecionadas: [],
    unidade: [],
    tipoSepulturaSelecionada: '',
    unidadeSelecionada: '',
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDTiposSepulturasRelatorioReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('livrocaixa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_OPCOES_PESQUISA:
      return setOpcoesPesquisa(state, action)
    case types.SET_FILTER_LOCALIZACAO_GRID:
      return setFilterLocalizacaoGrid(state, action)
    default:
      return result
  }
}

function setOpcoesPesquisa(state, action) {
  const selectedFilters = Object.assign([], state.selectedFilters)
  const payload = action.payload

  if (utils.isArrayNullOrEmpty(selectedFilters) && !utils.isEmptyObject(payload)) {
    selectedFilters.push(payload)
  } else if (utils.nonNull(payload.campoPesquisa) && utils.nonNull(payload.campoPesquisa.key)) {
    const existente = selectedFilters.find((f) => f.campoPesquisa.key === payload.campoPesquisa.key)
    if (existente) {
      const index = selectedFilters.indexOf(existente)
      selectedFilters.splice(index, 1, payload)
    } else {
      selectedFilters.push(payload)
    }
  }

  return Object.assign({}, state, { selectedFilters })
}

function setFilterLocalizacaoGrid(state, action) {
  const selectedFilters = Object.assign([], state.selectedFilters)
  const field = action.field
  const value = action.value

  if (utils.isArrayNotEmpty(selectedFilters)) {
    selectedFilters.forEach((f) => {
      if (field.indexOf(f.campoPesquisa.key) > 0) {
        f.valorSelecionado = value
      }
    })

    const empresa = storage.getCompany()
    const oidUnidade = _.get(state, 'currentRecord.unidadeSelecionada.id', null)
    const oidTipoSepultura = _.get(state, 'currentRecord.tipoSepulturaSelecionada.id', null)
    let ultimoCampoPesquisa = getUltimoCampoPesquisaLocalizacaoGrid(
      _.get(state, 'currentRecord.tipoSepultura.object', null)
    )
    if (_.isEmpty(ultimoCampoPesquisa)) {
      ultimoCampoPesquisa = utils.getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura)
    }
    if (field.indexOf(ultimoCampoPesquisa) === -1) {
      const filtroUltimoCampo = selectedFilters.find((f) => f.campoPesquisa.key === ultimoCampoPesquisa)
      if (filtroUltimoCampo) {
        const index = selectedFilters.indexOf(filtroUltimoCampo)
        selectedFilters.splice(index, 1)
        state = Object.assign({}, state, { records: [] })
        state = Object.assign({}, state, { matriz: [] })
      }
    }
  }

  return Object.assign({}, state, { selectedFilters })
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}
