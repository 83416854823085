import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { actions } from '../portalClienteConsultaCliente.actions'
import { PortalClienteConsultaClienteForm } from './PortalClienteConsultaClienteForm'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudPortalClienteConsultaClienteState.currentRecord
  const messages = state.crudPortalClienteConsultaClienteState.messages
  return {
    record,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    hideResult: () => dispatch(actions.setField('valido', '')),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    consultarCliente: (documento) => dispatch(actions.consultarCliente(documento)),
  }
}

class PortalClienteConsultaClienteFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showDialogRemove = false
  }

  componentDidMount() {
    logarAcesso().then()
  }

  hideDialog = () => {
    this.showDialogRemove = false
  }

  buildHeader = () => {
    return (
      <div className="p-grid p-justify-start p-align-center">
        <h1>Consulta de Cliente</h1>
      </div>
    )
  }

  consultarCliente = () => {
    const record = this.props.record
    if (record.documento) {
      this.props.consultarCliente(record.documento)
    }
  }

  hideResult = () => {
    setTimeout(() => {
      this.props.hideResult()
    }, 10000)
  }

  render() {
    const content = (
      <PortalClienteConsultaClienteForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        consultarCliente={this.consultarCliente}
        hideResult={this.hideResult}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalClienteConsultaClienteFormPageContainer)
