import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import storage from '../../../services/stoage.service'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { SplitButton } from 'primereact/splitbutton'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { acoes, enums, icons, oidBotoesDinamicos, routes, tipoDocumentoConst } from '../../../constants'
import { PessoaFisicaFormPage } from '../../pessoaFisica'
import { LocalizacaoGridListPage } from '../../localizacaoGrid'
import { actions as pessoaActions } from '../../pessoa/pessoa.actions'
import { actions as pessoaFisicaActions } from '../../pessoaFisica/pessoaFisica.actions'
import { actions as pessoaJuridicaActions } from '../../pessoaJuridica/pessoaJuridica.actions'
import { actions as localizacaoActions } from '../../localizacao/localizacao.actions'
import { actions as documentoActions } from '../../documento/documento.actions'
import { actions as lancamentoFinanceiroActions } from '../../lancamentoFinanceiro/lancamento.actions'
import { actions } from '../contrato.actions'
import { ContratoForm } from './ContratoForm'
import { Filtro } from '../../../utils/Filtro.js'
import utils from '../../../utils'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import _ from 'lodash'
import { Toolbar } from 'primereact/toolbar'
import '../../../index.scss'
import './ContratoForm.scss'
import {
  getColumnsLookupContrato,
  getColumnsLookupDocumento,
  getColumnsLookupLocalizacao,
  getColumnsLookupPessoa,
} from '../../../utils/lookupUtils'
import { StatusDocumento } from '../../documento/documento-utils'
import { getDocumentoByTipo } from '../../../services/api-documento'
import { InputTextarea } from 'primereact/inputtextarea'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import BaixarLancamentoModal from '../../lancamentoFinanceiro/baixar-lancamento-modal'
import { logarAcesso } from '../../../services/api-log-acesso'

const filtroTipoTela = new Filtro('tipoTela', 1, 'IGUAL_A')
const filtroEntidadeBase = new Filtro('entidadeBase', 'br.com.lottustech.phoenix.contrato.Contrato', 'IGUAL_A')

let self

const mapStateToProps = (state) => {
  const messages = state.crudContratoState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  const record = state.crudContratoState.currentRecord
  const currentUser = state.appState.currentUser
  const relatoriosFalecido = _.get(state.crudContratoState, 'relatoriosFalecido', [])
  let tags = state.crudContratoState.tags
  let causasMortis = state.crudContratoState.causasMortis
  let responsaveis = record.responsaveis
  //selecionando somente responsaveis com id
  //realizando a ordenação do array de responsaveis
  if (responsaveis) {
    responsaveis = responsaveis.filter((responsavel) => {
      return responsavel.responsavel
    })
    responsaveis.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem))
  }

  const responsavel = state.crudContratoState.responsavel
  let falecidos = record.falecidos
  falecidos = falecidos.filter((falecido) => {
    return falecido.falecido
  })
  const falecido = state.crudContratoState.falecido

  const dependente = state.crudContratoState.dependente
  let dependentes = record.dependentes
  dependentes = dependentes.filter((dependente) => {
    return dependente.dependente
  })

  const historico = state.crudContratoState.historico
  let historicos = record.historicos
  historicos = historicos.filter((historico) => {
    return historico.descricao
  })

  // REFERENTE AO LOOKUP DE LOCALIZACAO
  const lookupLocalizacaoBase = {
    records: state.crudLocalizacaoState.records,
    columns: getColumnsLookupLocalizacao(state.crudLocalizacaoState.columnsLookup),
    first: state.crudLocalizacaoState.index,
    rows: state.crudLocalizacaoState.max,
    totalRecords: state.crudLocalizacaoState.count,
    sortField: state.crudLocalizacaoState.sortField,
    order: state.crudLocalizacaoState.sortOrder,
    editDisabled: false,
  }

  let lookupLocalizacao = Object.assign({}, state.crudContratoState.lookupLocalizacao, lookupLocalizacaoBase)

  // REFERENTE AO LOOKUP DE LOCALIZAÇÃO DE DESTINO NO FALECIDO
  const lookupLocalizacaoDestinoBase = {
    records: state.crudLocalizacaoState.records,
    columns: getColumnsLookupLocalizacao(state.crudLocalizacaoState.columnsLookup),
    first: state.crudLocalizacaoState.index,
    rows: state.crudLocalizacaoState.max,
    totalRecords: state.crudLocalizacaoState.count,
    sortField: state.crudLocalizacaoState.sortField,
    order: state.crudLocalizacaoState.sortOrder,
    editDisabled: false,
  }

  let lookupLocalizacaoDestino = Object.assign(
    {},
    state.crudContratoState.lookupLocalizacaoDestino,
    lookupLocalizacaoDestinoBase
  )

  //LOOKUP REFERENTE AO RESPONSAVEL PELA CREMACAO DO FALECIDO
  // REFERENTE AO LOOKUP DE FALECIDO
  const lookupResponsavelCremacaoBase = {
    records: state.crudPessoaFisicaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaFisicaState.columnsLookup),
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    editDisabled: false,
  }

  let lookupResponsavelCremacao = Object.assign(
    {},
    state.crudContratoState.lookupResponsavelCremacao,
    lookupResponsavelCremacaoBase
  )

  // REFERENTE AO LOOKUP DE FALECIDO
  const lookupFalecidoBase = {
    records: state.crudPessoaFisicaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaFisicaState.columnsLookup),
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    editDisabled: false,
  }

  let lookupFalecido = Object.assign({}, state.crudContratoState.lookupFalecido, lookupFalecidoBase)
  // LOOKUP REFERENTE AO RESPONSAVEL DO CONTRATO
  const lookupResponsavelBase = {
    records: state.crudPessoaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaState.columnsLookup),
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
    editDisabled: false,
  }

  let lookupResponsavel = Object.assign({}, state.crudContratoState.lookupResponsavel, lookupResponsavelBase)
  // LOOKUP REFERENTE A FUNERARIA DO FALECIDO
  const lookupFunerariaBase = {
    records: state.crudPessoaJuridicaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaJuridicaState.columnsLookup),
    first: state.crudPessoaJuridicaState.index,
    rows: state.crudPessoaJuridicaState.max,
    totalRecords: state.crudPessoaJuridicaState.count,
    sortField: state.crudPessoaJuridicaState.sortField,
    order: state.crudPessoaJuridicaState.sortOrder,
    editDisabled: false,
  }
  let lookupFuneraria = Object.assign({}, state.crudContratoState.lookupFuneraria, lookupFunerariaBase)
  // LOOKUP REFERENTE AO DEPENDENTE
  const lookupDependenteBase = {
    records: state.crudPessoaFisicaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaFisicaState.columnsLookup),
    first: state.crudPessoaFisicaState.index,
    rows: state.crudPessoaFisicaState.max,
    totalRecords: state.crudPessoaFisicaState.count,
    sortField: state.crudPessoaFisicaState.sortField,
    order: state.crudPessoaFisicaState.sortOrder,
    editDisabled: false,
  }
  let lookupDependente = Object.assign({}, state.crudContratoState.lookupDependente, lookupDependenteBase)

  // LOOKUP REFERENTE AO CONTRATO DE CREMAÇÃO FUTURA
  const contratoCremacaoFutura = state.crudContratoState.currentRecord.contratoCremacaoFutura
  const lookupContratoCremacaoFuturaBase = {
    records: state.crudContratoState.records,
    columns: getColumnsLookupContrato(state.crudContratoState.columnsLookup),
    first: state.crudContratoState.index,
    rows: state.crudContratoState.max,
    totalRecords: state.crudContratoState.count,
    sortField: state.crudContratoState.sortField,
    order: state.crudContratoState.sortOrder,
    editDisabled: false,
  }

  let lookupContratoCremacaoFutura = Object.assign(
    {},
    state.crudContratoState.lookupContratoCremacaoFutura,
    lookupContratoCremacaoFuturaBase
  )

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  const currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = { id: currentUnit.oid, value: currentUnit.sigla }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  let displayForm = {
    falecido: false,
    dependente: false,
    historico: false,
    baixarLancamento: false,
    baixarlancamentos: false,
    dadosBoletoIndividual: false,
    lapide: false,
    responsavel: false,
    viewGavetas: false,
  }

  if (state.crudContratoState.displayForm) {
    displayForm = state.crudContratoState.displayForm
  }

  const currentCompany = storage.getCompany()
  const unidades = utils.getListIdValuesUnidadeDisponiveisUsuario()
  const units = storage.getUnits()

  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(currentCompany, record.unidade)
  const formasPagamentoBasica = utils.getFormasPagamento(enums.FormasPagamentoBasica, configFormasPagamento)

  const isTipoSepulturaCremacao = _.get(record, 'tipoSepultura.cremacao', false)
  const isTipoContratoColumbario = _.get(record, 'tipoSepultura.columbario', false)
  const isTipoContratoDependente = _.get(record, 'tipoSepultura.dependente', false)
  const isTipoContratoFuneral = _.get(record, 'tipoSepultura.funeral', false)
  const isTipoSepulturaPlano = _.get(record, 'tipoSepultura.plano', false)
  const isTipoSepulturaTitulo = _.get(record, 'tipoSepultura.titulo', false)
  const isTipoSepulturaPet = _.get(record, 'tipoSepultura.pet', false)
  const isTipoSepulturaFalecido = _.get(record, 'tipoSepultura.falecido', false)
  const isTipoSepulturaUrna = _.get(record, 'tipoSepultura.urna', false)
  const isTipoSepulturaGaveta = _.get(record, 'tipoSepultura.gaveta', false)
  const isTipoSepulturaCapela = _.get(record, 'tipoSepultura.capela', false)
  const isTipoSepulturaGrid = _.get(record, 'tipoSepultura.grid', false)

  let tiposSepultura = _.get(state, 'crudContratoState.tiposSepulturas', [])
  const oidTipoSepulturaCapela = utils.getOidTipoSepulturaCapela(units, record.unidade?.id)
  if (oidTipoSepulturaCapela) {
    const idx = tiposSepultura
      .map(function (e) {
        return e.id
      })
      .indexOf(oidTipoSepulturaCapela)
    if (idx > -1) {
      tiposSepultura.splice(idx, 1)
    }
  }

  //documento//
  const documentos = _.get(record, 'documentos', []).filter((f) => f.ativo)
  const documentoTempLookup = record.documentoTempLookup

  const lookupDocumentoBase = {
    records: state.crudDocumentoState.records,
    columns: getColumnsLookupDocumento(state.crudDocumentoState.columnsLookup),
    first: state.crudDocumentoState.index,
    rows: state.crudDocumentoState.max,
    totalRecords: state.crudDocumentoState.count,
    sortField: state.crudDocumentoState.sortField,
    order: state.crudDocumentoState.sortOrder,
    editDisabled: false,
  }
  const lookupDocumento = Object.assign({}, state.crudContratoState.lookupDocumento, lookupDocumentoBase)
  //TODO #assinatura - devemos validar se o contrato tem unidade.. nesse caso a unidade sera a do contrato
  const utilizaAssinaturaDigitalDocumentos = utils.isUtilizaAssinaturaDigitalDocumentos(currentCompany, currentUnit)
  const exibirConspaga =
    utils.isExibirConspaga(currentCompany, currentUnit) && !isTipoContratoColumbario && !isTipoSepulturaTitulo
  const integracaoAdiauHabilitada = state.crudContratoState.integracaoAdiauHabilitada
  const contratoFalecidoCamposComplementares = state.crudContratoState.contratoFalecidoCamposComplementares
  const camerasAdiauDisponiveis = _.get(state, 'crudContratoState.camerasAdiauDisponiveis', [])
  const exibirLocalizacaoCemiterio = utils.isExibirLocalizacaoCemiterio(currentCompany, currentUnit)
  const gerandoBoleto = state.crudContratoState.gerandoBoleto
  const adicionandoAnexo = state.crudContratoState.adicionandoAnexo
  const tipoSepulturaDestino = state.crudContratoState.tipoSepulturaDestino
  const isUsarWebWhatsapp = utils.isUsarWebWhatsapp(currentCompany, currentUnit)
  const templateObservacaoFalecido = utils.getObservacaoContratoFalecido(currentCompany, currentUnit)
  const diasPadraoCarenciaCoberturaPlano = utils.getDiasPadraoCarenciaCoberturaPlano(storage.getCompany(), null)

  return {
    gerandoBoleto,
    adicionandoAnexo,
    exibirConspaga,
    exibirLocalizacaoCemiterio,
    displayForm,
    currentUser,
    disableUnit,
    tiposSepultura,
    record,
    tags,
    causasMortis,
    contratoCremacaoFutura,
    lookupContratoCremacaoFutura,
    lookupLocalizacao,
    lookupLocalizacaoDestino,
    colunasTResponsavel: state.crudContratoState.colunasTResponsavel,
    colunasTDocumentos: state.crudContratoState.colunasTDocumentos,
    colunasTFalecido: state.crudContratoState.colunasTFalecido,
    colunasTDependente: state.crudContratoState.colunasTDependente,
    colunasTHistorico: state.crudContratoState.colunasTHistorico,
    colunasTLancamentoFinanceiro: state.crudContratoState.colunasTLancamentoFinanceiro,
    lookupFalecido,
    lookupFuneraria,
    lookupDependente,
    lookupResponsavel,
    lookupResponsavelCremacao,
    responsaveis,
    responsavel,
    falecidos,
    falecido,
    dependentes,
    dependente,
    unidades,
    historicos,
    historico,
    messages,
    messagesDialog,
    first: state.crudContratoState.index,
    rows: state.crudContratoState.max,
    totalRecords: state.crudContratoState.count,
    sortField: state.crudContratoState.sortField,
    order: state.crudContratoState.sortOrder,
    idTela: state.crudContratoState.id,
    botoesDinamicos: state.crudContratoState.botoesDinamicos,
    selectedLancamentos: state.crudContratoState.selectedLancamentos,
    isSaving: state.crudContratoState.isSaving,
    documentos,
    documentoTempLookup,
    lookupDocumento,
    utilizaAssinaturaDigitalDocumentos,
    statePessoaFisica: state.crudPessoaFisicaState,
    statePessoaJuridica: state.crudPessoaJuridicaState,
    isTipoContratoFuneral,
    isTipoContratoColumbario,
    isTipoContratoDependente,
    isTipoSepulturaPlano,
    isTipoSepulturaTitulo,
    isTipoSepulturaPet,
    isTipoSepulturaFalecido,
    isTipoSepulturaCremacao,
    isTipoSepulturaUrna,
    isTipoSepulturaGaveta,
    isTipoSepulturaCapela,
    isTipoSepulturaGrid,
    currentCompany,
    currentUnit,
    units,
    stateMarcador: state.crudMarcadorState,
    isUsarWebWhatsapp,
    templateObservacaoFalecido,
    integracaoAdiauHabilitada,
    contratoFalecidoCamposComplementares,
    camerasAdiauDisponiveis,
    tipoSepulturaDestino,
    formasPagamentoBasica,
    relatoriosFalecido,
    diasPadraoCarenciaCoberturaPlano,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },

    lookupLocalizacaoSetVisible: (visible) => dispatch(actions.setLookupLocalizacaoVisible(visible)),
    lookupLocalizacaoPaginate: (paginationParams) => dispatch(localizacaoActions.loadLookup(paginationParams)),
    lookupLocalizacaoSortOrder: (sortParams) => dispatch(localizacaoActions.loadLookup(sortParams)),
    lookupLocalizacaoApplyFilter: (filterParams) => {
      dispatch(actions.aplicarFiltrosCarregarDadosLocalizacao(filterParams))
    },
    lookupLocalizacaoLoad: () => {
      dispatch(actions.aplicarFiltrosCarregarDadosLocalizacao())
    },

    // REFERENTE AO LOOKUP DE CONTRATO CREMACAO FUTURA
    lookupContratoCremacaoFuturaSetVisible: (visible) =>
      dispatch(actions.setLookupContratoCremacaoFuturaVisible(visible)),
    lookupContratoCremacaoFuturaPaginate: (paginationParams) => dispatch(actions.loadLookup(paginationParams)),
    lookupContratoCremacaoFuturaSortOrder: (sortParams) => dispatch(actions.loadLookup(sortParams)),
    lookupContratoCremacaoFuturaApplyFilter: (filterParams) => {
      dispatch(actions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(actions.aplicarFiltrosCarregarDadosContratoCremacaoFutura())
    },
    lookupContratoCremacaoFuturaLoad: () => dispatch(actions.aplicarFiltrosCarregarDadosContratoCremacaoFutura()),

    // REFERENTE AO LOOKUP DE LOCALIZACAO DE DESTINO
    lookupLocalizacaoDestinoSetVisible: (visible) => dispatch(actions.setLookupLocalizacaoDestinoVisible(visible)),
    lookupLocalizacaoDestinoLoad: () => dispatch(actions.aplicarFiltrosCarregarDadosLocalizacaoDestino()),
    lookupLocalizacaoDestinoApplyFilter: (filterParams) => {
      dispatch(actions.aplicarFiltrosCarregarDadosLocalizacaoDestino(filterParams))
    },
    // REFERENTE AO LOOKUP DE LOCALIZACAO URNA
    lookupFalecidoSetVisible: (visible) => dispatch(actions.setLookupFalecidoVisible(visible)),
    lookupFalecidoPaginate: (paginationParams) => dispatch(pessoaFisicaActions.loadLookup(paginationParams)),
    lookupFalecidoSortOrder: (sortParams) => dispatch(pessoaFisicaActions.loadLookup(sortParams)),
    lookupFalecidoApplyFilter: (filterParams) => {
      dispatch(pessoaFisicaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaFisicaActions.loadLookup())
    },
    lookupFalecidoLoad: () => dispatch(pessoaFisicaActions.loadLookup()),
    gerarEtiqueta: (oidContratoFalecido) => dispatch(actions.gerarEtiqueta(oidContratoFalecido)),
    gerarNotaFalecimento: (oidContratoFalecido) => dispatch(actions.gerarNotaFalecimento(oidContratoFalecido)),
    gerarTanatopraxiaAta: (oidContratoFalecido) => dispatch(actions.gerarTanatopraxiaAta(oidContratoFalecido)),
    gerarTanatopraxiaAutorizacao: (oidContratoFalecido) =>
      dispatch(actions.gerarTanatopraxiaAutorizacao(oidContratoFalecido)),
    gerarTanatopraxiaRegistro: (oidContratoFalecido) =>
      dispatch(actions.gerarTanatopraxiaRegistro(oidContratoFalecido)),
    gerarEPIs: (oidContratoFalecido) => dispatch(actions.gerarEPIs(oidContratoFalecido)),
    gerarCarteirinhaIndividual: (oidPessoa, oidContrato) =>
      dispatch(actions.gerarCarteirinhaIndividual(oidPessoa, oidContrato)),
    gerarCarteirinhaColetiva: (oidContrato) => dispatch(actions.gerarCarteirinhaColetiva(oidContrato)),
    gerarRequerimentoCremacao: (oidContratoFalecido) =>
      dispatch(actions.gerarRequerimentoCremacao(oidContratoFalecido)),
    abrirAutorizacaoSepultamento: (oidContratoFalecido) =>
      dispatch(actions.abrirAutorizacaoSepultamento(oidContratoFalecido)),
    abrirAutorizacaoSepultamentoTumulo: (oidContratoFalecido) =>
      dispatch(actions.abrirAutorizacaoSepultamentoTumulo(oidContratoFalecido)),
    setFalecido: (falecido) => {
      if (utils.nonNull(falecido)) {
        dispatch(actions.setFalecido('oidFalecido', falecido.oid))
        dispatch(actions.setFalecido('falecido', falecido.nome))
        dispatch(actions.setFalecido('cpfFalecido', falecido.numeroDocumento))
        dispatch(actions.setFalecido('dataNascimento', falecido.dataNascimento))
        if (utils.nonNull(falecido.dataFalecimento)) {
          dispatch(actions.setFalecido('dataFalecimento', falecido.dataFalecimento))
        }
      } else {
        dispatch(actions.setFalecido('oidFalecido', null))
        dispatch(actions.setFalecido('falecido', null))
        dispatch(actions.setFalecido('cpfFalecido', null))
        dispatch(actions.setFalecido('dataNascimento', null))
        dispatch(actions.setFalecido('dataFalecimento', null))
      }
      dispatch(pessoaFisicaActions.clearSelectedFilters())
    },

    lookupResponsavelSetVisible: (visible) => dispatch(actions.setLookupResponsavelVisible(visible)),
    lookupResponsavelPaginate: (paginationParams) => dispatch(pessoaActions.paginate(paginationParams)),
    lookupResponsavelSortOrder: (sortParams) => dispatch(pessoaActions.sortOrder(sortParams)),
    lookupResponsavelApplyFilter: (filterParams) => {
      dispatch(pessoaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaActions.loadLookup())
    },
    lookupResponsavelLoad: () => dispatch(pessoaActions.loadLookup()),
    setResponsavel: (responsavel) => {
      const oid = _.get(responsavel, 'oid', null)
      let result = {
        responsavel: _.get(responsavel, 'nome', null),
      }
      if (oid) {
        result.oidResponsavel = oid
      }
      dispatch(actions.setResponsavel('responsavel', result))
    },
    setNomeResponsavel: (responsavel) => {
      dispatch(actions.adicionarNomeResponsavel('oidResponsavel', responsavel != null ? responsavel.oid : null))
      dispatch(actions.adicionarNomeResponsavel('responsavel', responsavel != null ? responsavel.nome : ''))
    },
    // Referente ao lookup de responsavel pela cremação
    lookupResponsavelCremacaoSetVisible: (visible) => dispatch(actions.setLookupResponsavelCremacaoVisible(visible)),
    // Referente ao lookup de funeraria
    lookupFunerariaSetVisible: (visible) => dispatch(actions.setLookupFunerariaVisible(visible)),
    lookupFunerariaPaginate: (paginationParams) => dispatch(pessoaJuridicaActions.loadLookup(paginationParams)),
    lookupFunerariaSortOrder: (sortParams) => dispatch(pessoaJuridicaActions.loadLookup(sortParams)),
    lookupFunerariaApplyFilter: (filterParams) => {
      dispatch(pessoaJuridicaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaJuridicaActions.loadLookup())
    },
    lookupFunerariaLoad: () => dispatch(pessoaJuridicaActions.loadLookup()),
    setFuneraria: (funeraria) => {
      dispatch(actions.setFalecido('oidFuneraria', funeraria != null ? funeraria.oid : null))
      dispatch(actions.setFalecido('funeraria', funeraria != null ? funeraria.nome : ''))
      dispatch(pessoaJuridicaActions.clearSelectedFilters())
    },

    lookupDependenteSetVisible: (visible) => dispatch(actions.setLookupDependenteVisible(visible)),
    lookupDependentePaginate: (paginationParams) => dispatch(pessoaFisicaActions.loadLookup(paginationParams)),
    lookupDependenteSortOrder: (sortParams) => dispatch(pessoaFisicaActions.loadLookup(sortParams)),
    lookupDependenteApplyFilter: (filterParams) => {
      dispatch(pessoaFisicaActions.setType(null))
      dispatch(pessoaFisicaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaFisicaActions.loadLookup())
    },
    lookupDependenteLoad: () => dispatch(pessoaFisicaActions.loadLookup()),
    setDependente: (dependente) => {
      dispatch(actions.setDependente('oidDependente', dependente != null ? dependente.oid : null))
      dispatch(actions.setDependente('dependente', dependente != null ? dependente.nome : ''))
      dispatch(actions.setDependente('dataNascimento', dependente != null ? dependente.dataNascimento : ''))
      dispatch(pessoaFisicaActions.clearSelectedFilters())
    },
    enviarMensagem: (oid, tipoMensagem) => dispatch(actions.enviarMensagem(oid, tipoMensagem)),
    abrirWebWhatsApp: ({ oidContrato }) => dispatch(actions.abrirWebWhatsApp({ oidContrato })),
    abrirWebWhatsAppNotaFalecimento: (oidContratoFalecido) =>
      dispatch(actions.abrirWebWhatsAppNotaFalecimento(oidContratoFalecido)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'isento' || field === 'ativo') {
        valor = target.checked
      } else if (field === 'tipoSepultura') {
        // caso mudar o tipo de sepultura limpa a cremacao futura
        dispatch(actions.setField('contratoCremacaoFutura', ''))
      }
      dispatch(actions.setField(field, valor))
    },
    adicionarFalecido: (e) => {
      dispatch(actions.adicionarFalecido('falecidos', ''))
    },
    editarFalecido: (falecido) => {
      Object.keys(falecido).forEach(function (key) {
        dispatch(actions.setFalecido(key, falecido[key]))
      })
    },
    editarDadoFalecido: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.setFalecido(field, valor))
    },
    editarCampoFalecido: (field, value) => {
      dispatch(actions.setFalecido(field, value))
    },
    removerFalecido: (falecidoRemovido, falecidos) => {
      let falecidosRestantes = falecidos.filter((falecido) => {
        return falecido.oidFalecido !== falecidoRemovido.oidFalecido
      })
      falecidosRestantes.forEach((falecido) => {
        if (falecido.ordem > falecidoRemovido.ordem) {
          falecido.ordem = --falecido.ordem
        }
      })
      dispatch(actions.setField('falecidos', falecidosRestantes))
    },
    downloadLapide: (params) => {
      dispatch(actions.downloadLapide(params))
    },
    editarDependente: (dependente) => {
      Object.keys(dependente).forEach(function (key) {
        dispatch(actions.setDependente(key, dependente[key]))
      })
      dispatch(pessoaFisicaActions.loadById(dependente.oidDependente))
    },
    editarDadoDependente: (event) => {
      dispatch(actions.setDependente('parentesco', event))
    },
    searchTag: (event) => {
      dispatch(actions.findTags(event.query))
    },
    searchCausaMortis: (event) => {
      const query = _.get(event, 'query', '')
      if (query.length > 2) {
        dispatch(actions.findCausasMortis(query))
      }
    },

    removerDependente: (dependenteRemovido, dependentes) => {
      let dependentesRestantes = dependentes.filter((dependente) => {
        return dependente.oidDependente !== dependenteRemovido.oidDependente
      })
      dispatch(actions.setField('dependentes', dependentesRestantes))
    },
    adicionarPessoaFisicaResponsavel: () => dispatch(actions.adicionarPessoaFisicaResponsavel()),
    removerResponsavel: (responsavelRemovido, responsaveis) => {
      let responsaveisRestantes = responsaveis.filter((responsavel) => {
        return responsavel.oidResponsavel && responsavel.oidResponsavel !== responsavelRemovido.oidResponsavel
      })
      responsaveisRestantes.forEach((responsavel) => {
        if (responsavel.ordem > responsavelRemovido.ordem) {
          responsavel.ordem = --responsavel.ordem
        }
      })
      dispatch(actions.setField('responsaveis', responsaveisRestantes))
    },
    adicionarDependente: (e) => {
      dispatch(actions.adicionarPessoaFisicaDependente())
      dispatch(actions.clearFormDependente())
    },
    adicionarDependenteCadastrado: (e) => {
      dispatch(actions.adicionarDependenteCadastrado(e))
    },

    adicionarHistorico: (currentUser) => {
      dispatch(actions.adicionarHistorico('historicos', currentUser))
    },

    removerHistorico: (historicoRemovido, historicos) => {
      let campo = 'hash'
      if (utils.nonNull(historicoRemovido.oid) && utils.isNumber(historicoRemovido.oid)) {
        campo = 'oid'
      }
      let historicosRestantes = historicos.filter((historico) => {
        return historico[campo] !== historicoRemovido[campo]
      })
      dispatch(actions.setField('historicos', historicosRestantes))
    },

    changeHandlerHistorico: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      if (valor && !valor.id) {
        dispatch(actions.setHistorico(field, valor))
      } else if (field === 'descricao') {
        dispatch(actions.setHistorico(field, valor))
      }
    },
    changeHandlerMarcadorHistorico: (event) => {
      let object = event
      if (Array.isArray(event) && event.length > 0) {
        object = event[0]
      }
      if (object) {
        dispatch(actions.setHistorico('tag', object.codigo))
        dispatch(actions.setHistorico('oidTag', object.oid))
      }
    },
    changeHandlerPessoaHistorico: (event) => {
      if (event) {
        dispatch(actions.setHistorico('pessoa', _.get(event, 'nome', null)))
        dispatch(actions.setHistorico('oidPessoa', _.get(event, 'oid', null)))
      }
    },
    changeHandlerValorHistorico: (event) => {
      dispatch(actions.setHistorico('valor', event))
    },

    setDadosHistorico: (historico) => {
      dispatch(actions.setHistorico('oid', historico.oid))
      dispatch(actions.setHistorico('oidUsuario', historico.oidUsuario))
      dispatch(actions.setHistorico('usuario', historico.usuario))
      let lembrete = historico.lembrete
      if (utils.isNumber(lembrete)) {
        dispatch(actions.setHistorico('lembrete', new Date(historico.lembrete)))
      } else {
        dispatch(actions.setHistorico('lembrete', historico.lembrete))
      }
      dispatch(actions.setHistorico('descricao', historico.descricao))
      dispatch(actions.setHistorico('oidTag', historico.oidTag))
      dispatch(actions.setHistorico('tag', historico.tag))
      dispatch(actions.setHistorico('hash', historico.hash))
      dispatch(actions.setHistorico('pessoa', historico.pessoa))
      dispatch(actions.setHistorico('oidPessoa', historico.oidPessoa))
      dispatch(actions.setHistorico('valor', historico.valor))
    },

    saveHandler: (event) => {
      dispatch(actions.beforeSave())
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
      dispatch(actions.clearFormFalecido())
      dispatch(actions.clearFormDependente())
      dispatch(actions.clearFormHistorico())
      dispatch(localizacaoActions.clearSelectedFilters())
      dispatch(pessoaFisicaActions.clearSelectedFilters())
      dispatch(pessoaJuridicaActions.clearSelectedFilters())
      dispatch(pessoaActions.clearSelectedFilters())
      dispatch(documentoActions.clearSelectedFilters())
    },
    clearForm: () => {
      dispatch(actions.clearFormFalecido())
      dispatch(actions.clearFormDependente())
      dispatch(actions.clearFormHistorico())
      dispatch(localizacaoActions.clearSelectedFilters())
      dispatch(pessoaFisicaActions.clearSelectedFilters())
      dispatch(pessoaJuridicaActions.clearSelectedFilters())
      dispatch(pessoaActions.clearSelectedFilters())
      dispatch(documentoActions.clearSelectedFilters())
    },
    clearRecords: () => {
      dispatch(localizacaoActions.setRecords([]))
      dispatch(pessoaFisicaActions.setRecords([]))
      dispatch(pessoaJuridicaActions.setRecords([]))
      dispatch(pessoaActions.setRecords([]))
      dispatch(documentoActions.setRecords([]))
    },
    changeOrderList: (event, listName, acaoOrdenarResponsavel) => {
      // ajustando a ordem da entidade, o ordem list realiza a ordenação do array
      // porém não ajusta o valor do atributo 'ordem' da entidade
      if (acaoOrdenarResponsavel) {
        event.value.map((entity, index) => {
          return (entity.ordem = ++index)
        })
        dispatch(actions.setField(listName, event.value))
      }
    },
    loadContrato: (oid, mensagemPersonalizada) => {
      dispatch(actions.loadContrato(oid, mensagemPersonalizada))
    },
    findTags: (codigo) => {
      dispatch(actions.findTags(codigo))
    },
    findCausasMortis: (descricao) => {
      dispatch(actions.findCausasMortis(descricao))
    },

    showForm(formName, props) {
      dispatch(actions.setDisplayForm(formName, props))
    },
    clearSelectedFilters() {
      dispatch(actions.clearSelectedFilters())
      dispatch(localizacaoActions.clearSelectedFilters())
      dispatch(pessoaFisicaActions.clearSelectedFilters())
      dispatch(pessoaJuridicaActions.clearSelectedFilters())
      dispatch(pessoaActions.clearSelectedFilters())
      dispatch(documentoActions.clearSelectedFilters())
    },
    willUnmount: () => {
      dispatch(actions.willUnmount())
    },
    downloadPDF(oidContrato) {
      dispatch(actions.downloadPDF(oidContrato))
    },
    onClickBotaoDinamico: (listId, oidBotao, params) => {
      dispatch(actions.executarFuncaoDinamica(listId, oidBotao, params))
    },
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.FORMULARIO)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
    addAnexo(anexo) {
      dispatch(actions.processaAnexo(anexo))
    },
    adicaoAnexoFalecido(anexo) {
      dispatch(actions.processaAnexoFalecido(anexo))
    },
    downloadAnexo(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.downloadAnexo(anexo))
    },
    downloadAnexoFalecido(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.downloadAnexoFalecido(anexo))
    },
    removeAnexo(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.removeAnexo(anexo))
    },
    removeAnexoFalecido(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.removeAnexoFalecido(anexo))
    },
    salvarLocalizacao: (localizacao) => dispatch(actions.salvarLocalizacao(localizacao)),
    salvarNovaCausaMortis: (descricao, props, aposSalvarNovaCausaMortis) =>
      dispatch(actions.salvarNovaCausaMortis(descricao, props, aposSalvarNovaCausaMortis)),

    salvarLocalizacaoDestino: (localizacao) => dispatch(actions.salvarLocalizacaoDestino(localizacao)),
    setSelectedLancamentos: (e) => dispatch(actions.setSelectedLancamentos(e)),
    baixarLancamentos: (parcelas) => dispatch(actions.baixarLancamentos(parcelas)),
    estornarLancamento: (lancamento) => dispatch(actions.estornarLancamento(lancamento)),
    setSelectedFilter: (filterParams) => dispatch(actions.setSelectedFilters(filterParams.campo, filterParams.valor)),
    loadTiposSepulturas: (oidUnidade) => dispatch(actions.loadTiposSepulturas(oidUnidade)),
    loadIntegracaoAdiauHabilitada: () => dispatch(actions.integracaoAdiauHabilitada()),
    loadContratoFalecidoCamposComplementares: () => dispatch(actions.loadContratoFalecidoCamposComplementares()),
    loadCamerasAdiau: () => dispatch(actions.loadCamerasAdiau()),
    getTipoSepulturaDestino: (oidTipoSepultura) => dispatch(actions.getTipoSepulturaDestino(oidTipoSepultura)),
    setTipoSepulturaConfiguracao: (oidTipoSepultura) =>
      dispatch(actions.setTipoSepulturaConfiguracao(oidTipoSepultura)),
    criarContrato: (oid) => dispatch(actions.criarContrato(oid)),
    criarContratoCremacaoFalecido: (currentRecord, contratoFalecido) =>
      dispatch(actions.criarContratoCremacaoFalecido({ currentRecord, contratoFalecido })),
    gerarCarneBoleto: (params) => dispatch(actions.gerarCarneBoleto(params)),
    showWarningMessage: (message) => dispatch(actions.showWarningMessage(message)),
    showMessage: (message, type) => dispatch(actions.showMessage(message, type)),
    //LOOKUP DOCUMENTO//
    lookupDocumentoSetVisible: (visible) => dispatch(actions.setLookupDocumentoVisible(visible)),
    lookupDocumentoPaginate: (paginationParams) => dispatch(documentoActions.paginate(paginationParams)),
    lookupDocumentoSortOrder: (sortParams) => dispatch(documentoActions.sortOrder(sortParams)),
    gerarCarne: (oidsLancamentos) => dispatch(lancamentoFinanceiroActions.gerarCarne(oidsLancamentos)),
    lookupDocumentoApplyFilter: (filterParams) => {
      //FIXME - criar lts-lookup-documento
      dispatch(documentoActions.setFiltroAvancado(filtroTipoTela))
      dispatch(documentoActions.setFiltroAvancado(filtroEntidadeBase))
      dispatch(documentoActions.setFiltroAvancado(filterParams))
      dispatch(documentoActions.loadLookup())
    },
    lookupDocumentoLoad: () => {
      dispatch(documentoActions.setFiltroAvancado(filtroTipoTela))
      dispatch(documentoActions.setFiltroAvancado(filtroEntidadeBase))
      dispatch(documentoActions.loadLookup())
    },
    addDocumento: (documento) => {
      const result = {
        oid: documento.oid,
        nome: documento.nome,
      }
      dispatch(actions.addDocumento('documento', result))
    },
    addDocumentoContrato: ({ nome, oid, filtros }) => {
      dispatch(actions.addDocumentoContrato('documento', { nome, oid, filtros }))
    },
    assinarDocumento: (oidContratoDocumento) => {
      dispatch(actions.assinarDocumento(oidContratoDocumento))
    },
    requestImprimirDocumento: (params) => {
      dispatch(actions.requestImprimirDocumento(params))
    },
    addDocumentosPadroes: (oidTipoSepultura) => {
      dispatch(actions.addDocumentosPadroes(oidTipoSepultura))
    },
    updateContrato: () => {
      dispatch(actions.updateContrato())
    },
    agruparLancamentos: (selectedLancamentos) => {
      if (utils.nonNull(selectedLancamentos) && utils.isArrayNotEmpty(selectedLancamentos)) {
        const oidsLancamentos = selectedLancamentos.map((l) => l.oid)
        dispatch(actions.agruparLancamentos(oidsLancamentos))
      }
    },
    gerarDebitoAutomatico: (selectedLancamentos) => {
      if (utils.nonNull(selectedLancamentos) && utils.isArrayNotEmpty(selectedLancamentos)) {
        const oidsLancamentos = selectedLancamentos.map((l) => l.oid)
        dispatch(lancamentoFinanceiroActions.gerarDebitoAutomatico(oidsLancamentos))
      }
    },
    cancelarLancamentos: (selectedLancamentos) => {
      if (utils.nonNull(selectedLancamentos) && utils.isArrayNotEmpty(selectedLancamentos)) {
        const oidsLancamentos = selectedLancamentos.map((l) => l.oid)
        dispatch(actions.cancelarLancamentos(oidsLancamentos))
      }
    },
    getDateTimeFromServer: () => dispatch(actions.getDateTimeFromServer()),
    loadRelatoriosFalecido: () => dispatch(actions.loadRelatoriosFalecido()),
  }
}

class ContratoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.toastRef = React.createRef()
    this.dataTableLancamentos = React.createRef()
    this.falecidoExcluir = null
    this.responsavelExcluir = null
    this.documentoExcluir = null
    this.documentoAssinar = null
    this.dependenteExcluir = null
    this.descricaoNovaCausaMortis = null
    const acaoOrdenarResponsavel =
      utils.nonNull(storage.getAcao(acoes.ACAO_ORDENAR_RESPONSAVEL)) && storage.getAcao(acoes.ACAO_ORDENAR_RESPONSAVEL)
    const acaoAcoesFalecido =
      utils.nonNull(storage.getAcao(acoes.ACAO_ACOES_FALECIDO)) && storage.getAcao(acoes.ACAO_ACOES_FALECIDO)
    const acaoCremarFalecido =
      utils.nonNull(storage.getAcao(acoes.ACAO_CREMAR_FALECIDO)) && storage.getAcao(acoes.ACAO_CREMAR_FALECIDO)
    const acaoRelatorioCarteirinha =
      utils.nonNull(storage.getAcao(acoes.ACAO_RELATORIO_CARTEIRINHA)) &&
      storage.getAcao(acoes.ACAO_RELATORIO_CARTEIRINHA)
    this.state = {
      visible: false,
      ACAO_REMOVER_HISTORICO: storage.getAcao(acoes.ACAO_REMOVER_HISTORICO),
      ACAO_ALTERAR_HISTORICO: storage.getAcao(acoes.ACAO_ALTERAR_HISTORICO),
      ACAO_EDITAR_CONSPAGA: storage.getAcao(acoes.ACAO_EDITAR_CONSPAGA),
      ACAO_REMOVER_FALECIDO: storage.getAcao(acoes.ACAO_REMOVER_FALECIDO),
      ACAO_REMOVER_RESPONSAVEL: storage.getAcao(acoes.ACAO_REMOVER_RESPONSAVEL),
      ACAO_EDITAR_DATA_EXUMACAO: storage.getAcao(acoes.ACAO_EDITAR_DATA_EXUMACAO),
      ACAO_INATIVAR_CONTRATO: storage.getAcao(acoes.ACAO_INATIVAR_CONTRATO),
      CRIAR_COBERTURA_PLANO: storage.getAcao(acoes.CRIAR_COBERTURA_PLANO),
      ALTERAR_COBERTURA_PLANO: storage.getAcao(acoes.ALTERAR_COBERTURA_PLANO),
      REMOVER_COBERTURA_PLANO: storage.getAcao(acoes.REMOVER_COBERTURA_PLANO),
      acaoOrdenarResponsavel,
      acaoAcoesFalecido,
      acaoCremarFalecido,
      acaoRelatorioCarteirinha,
      // units: storage.getUnits(),
      conspagaEmDia: false,
      // Referente a lapides
      tamanhoLapide: 'grande',
      modeloLapide: 'original',
      lapideComFoto: true,
      lapideComSimbolo: true,
      lapideComMensagem: false,
      msgLapide: '',
      lapideComQrCode: true,
      oidContratoFalecido: '',
      unidadeMemorial: '',
      activeIndex: 0,
      showDialogRemove: false,
      showConfirmarExcluirFalecido: false,
      showConfirmarExcluirResponsavel: false,
      showConfirmarExcluirDocumento: false,
      showConfirmarAssinarDocumento: false,
      showConfirmarExcluirDependente: false,
      showConfirmarEstornar: false,
      localizacaoSepultamentoInvalida: false,
      lancamentosBaixar: [],
    }

    this.showWarningMessage = props.showWarningMessage
    this.showMessage = props.showMessage
    this.requestImprimirDocumento = props.requestImprimirDocumento
    this.utilizaAssinaturaDigitalDocumentos = props.utilizaAssinaturaDigitalDocumentos

    actions.carregarMensageria = (mensagem) => {
      props.history.push('/geral/cadastros/mensageria', { mensagem })
    }

    actions.afterSave = (data) => {
      this.props.history.replace(`${routes.PATH_CONTRATO}/${data.oid}`)
      if (utils.nonNull(data.sequencialCremacaoFutura) && parseInt(data.sequencialCremacaoFutura) > 0) {
        this.props.setField('sequencialCremacaoFutura', data.sequencialCremacaoFutura)
      }
      if (data.numeroVagas < 0) {
        this.props.showWarningMessage('O número de vagas ultrapassou do limite!')
      }
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_CONTRATO}/novo`)
      if (this.props.location.state && this.props.location.state.oidLocalizacao) {
        this.props.setField('unidade.id', this.props.location.state.unidade.id)
        this.props.setField('unidade.value', this.props.location.state.unidade.value)
        this.props.setField('localizacao.value', this.props.location.state.identificacao)
        this.props.setField('localizacao.id', this.props.location.state.oidLocalizacao)
        this.props.setField('tipoSepultura.value', this.props.location.state.tipoSepultura.value)
        this.props.setField('tipoSepultura.id', this.props.location.state.tipoSepultura.id)
      }

      const localizacaoRef = this.props.location.localizacaoRef
      if (localizacaoRef) {
        const unidadeRef = { id: localizacaoRef.oidUnidade, value: localizacaoRef.siglaUnidade }
        const tipoSepulturaRef = localizacaoRef.tipoSepultura
        if (parseInt(localizacaoRef.oid)) {
          const localizacao = { id: localizacaoRef.oid, value: localizacaoRef.identificacao }
          this.props.setField('localizacao', localizacao)
          this.props.setField('unidade', unidadeRef)
          this.props.setField('tipoSepultura', tipoSepulturaRef)
        } else {
          localizacaoRef.oid = null
          let localizacaoDto = {
            tipoSepultura: tipoSepulturaRef,
            empresa: { id: localizacaoRef.oidEmpresa, value: localizacaoRef.nomeEmpresa },
            unidade: unidadeRef,
            localizacaoA: localizacaoRef.localizacaoA,
            localizacaoB: localizacaoRef.localizacaoB,
            localizacaoC: localizacaoRef.localizacaoC,
            localizacaoD: localizacaoRef.localizacaoD,
            localizacaoE: localizacaoRef.localizacaoE,
            localizacaoF: localizacaoRef.localizacaoF,
            localizacaoG: localizacaoRef.localizacaoG,
            localizacaoH: localizacaoRef.localizacaoH,
            localizacaoI: localizacaoRef.localizacaoI,
            localizacaoJ: localizacaoRef.localizacaoJ,
            temporaria: localizacaoRef.temporaria,
            numeroVagas: localizacaoRef.numeroVagas,
            numeroLugares: localizacaoRef.numeroLugares,
          }
          this.props.setField('unidade', unidadeRef)
          this.props.setField('tipoSepultura', tipoSepulturaRef)
          this.props.salvarLocalizacao(localizacaoDto)
        }
      }
      this.setState({ conspagaEmDia: true })
    }

    actions.afterLoad = (record) => {
      return this.executeAfterLoad(record)
    }

    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CONTRATO}`)
    }
    actions.afterFindTags = (tagss) => {
      props.tags.splice(0, props.tags.length)
      props.tags.push(tagss)
    }

    actions.afterFindCausasMortis = (pCausasMortis) => {
      props.causasMortis.splice(0, props.causasMortis.length)
      let newCausasMortis = []
      pCausasMortis.forEach((c) => {
        newCausasMortis.push({ oidCausaMortis: c.id, descricaoCausaMortis: c.value })
      })
      props.causasMortis.push(newCausasMortis)
    }

    actions.beforeFetchRecordSuccess = (data) => {
      return this.executeAfterLoad(data)
    }

    this.setField = props.setField
    this.addDocumentosPadroes = props.addDocumentosPadroes
    self = this
  }

  updateDocumentos = (documentos) => {
    this.setField('documentos', documentos)
    const oid = _.get(this, 'props.record.oid', null)
    if (oid && oid > 0) {
      setTimeout(this.props.updateContrato(), 200)
    }
  }

  executeAfterLoad = (record) => {
    if (record) {
      let falecidos = record.falecidos
      if (falecidos && falecidos.length > 0) {
        falecidos.forEach((falecido) => {
          if (falecido.dataNascimento && utils.isNumber(falecido.dataNascimento)) {
            falecido.dataNascimento = utils.getUTCDate(falecido.dataNascimento)
          }
          if (falecido.dataFalecimento && utils.isNumber(falecido.dataFalecimento)) {
            falecido.dataFalecimento = utils.getDateTimeFromNumber(falecido.dataFalecimento)
          }
          if (falecido.dataExumacao && utils.isNumber(falecido.dataExumacao)) {
            falecido.dataExumacao = utils.getUTCDate(falecido.dataExumacao)
          }
          if (falecido.dataPrevisaoExumacao && utils.isNumber(falecido.dataPrevisaoExumacao)) {
            falecido.dataPrevisaoExumacao = utils.getUTCDate(falecido.dataPrevisaoExumacao)
          }
          if (falecido.dataTranslado && utils.isNumber(falecido.dataTranslado)) {
            falecido.dataTranslado = utils.getUTCDate(falecido.dataTranslado)
          }
          if (falecido.dataCremacao && utils.isNumber(falecido.dataCremacao)) {
            falecido.dataCremacao = utils.getUTCDate(falecido.dataCremacao)
          }
        })
        this.props.setField('falecidos', falecidos)
      }
      let lancamentosFinanceiros = record.lancamentosFinanceiros
      if (lancamentosFinanceiros && lancamentosFinanceiros.length > 0) {
        const dataInicial = new Date(null).getTime()
        lancamentosFinanceiros.forEach((lancamento) => {
          // para os filtros de data funcionarem
          if (!lancamento.hasOwnProperty('vencimento')) {
            lancamento.vencimento = dataInicial
          }
          if (!lancamento.hasOwnProperty('dataPagamento')) {
            lancamento.dataPagamento = dataInicial
          }
          if (!lancamento.hasOwnProperty('emissao')) {
            lancamento.emissao = dataInicial
          }
          if (!lancamento.hasOwnProperty('dataCompetencia')) {
            lancamento.dataCompetencia = dataInicial
          }
        })
      }
      if (record.tipoSepultura) {
        const oidTipoSepultura = _.get(record, 'tipoSepultura.oid', null)
        this.props.getTipoSepulturaDestino(oidTipoSepultura)
        this.props.setTipoSepulturaConfiguracao(oidTipoSepultura)
      }
    }
    this.props.setSelectedLancamentos([])
    this.checkPeriodoCarencia(record)
    this.props.loadContratoFalecidoCamposComplementares()
    return record
  }

  checkPeriodoCarencia = (record) => {
    const coberturas = _.get(record, 'plano.coberturas', [])
    const periodoCarencia = coberturas.find((c) => _.get(c, 'periodoCarencia', false) === true)
    if (periodoCarencia) {
      this.showWarningMessage('Contrato possui cobertura(s) dentro do período de carência.')
    }
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    this.props.loadIntegracaoAdiauHabilitada()
    this.resolveTiposSepulturas()
    document.body.scrollTop = document.documentElement.scrollTop = 0
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadContrato(oidEntity)
      if (this.props.record.conspaga) {
        const conspagaEmDia = this.verificarConspagaEmDia()
        this.setState({ conspagaEmDia: conspagaEmDia })
      }
    } else {
      this.props.newHandler()
      if (this.props.location.state && this.props.location.state.oidLocalizacao) {
        this.props.setField('unidade.id', this.props.location.state.unidade.id)
        this.props.setField('unidade.value', this.props.location.state.unidade.value)
        this.props.setField('localizacao.value', this.props.location.state.identificacao)
        this.props.setField('localizacao.id', this.props.location.state.oidLocalizacao)
        this.props.setField('tipoSepultura.value', this.props.location.state.tipoSepultura.value)
        this.props.setField('tipoSepultura.id', this.props.location.state.tipoSepultura.id)
        this.props.setField('oidMapaCoordenada', this.props.location.state.oidMapaCoordenada)
        this.setState({ conspagaEmDia: true })
      }
      this.props.getDateTimeFromServer()
    }
    this.props.clearSelectedFilters()
    this.props.loadCamerasAdiau()
    this.props.loadRelatoriosFalecido()
    //this.props.loadContratoFalecidoCamposComplementares()
  }

  onChangeTipoContrato = (event) => {
    //Tipo Sepultura
    this.setField('contratoCremacaoFutura', '')
    const target = event.target
    const field = target.name
    const valor = target.value
    this.setField(field, valor)

    const oid = _.get(valor, 'oid', null)
    if (oid) {
      this.addDocumentosPadroes(oid)

      const oidRecord = _.get(this.props.record, 'oid', null)
      if (oidRecord) {
        this.props.getTipoSepulturaDestino(oid)
      }
      this.props.setTipoSepulturaConfiguracao(oid)
    }
  }

  resolveTiposSepulturas = () => {
    let unidade = _.get(this, 'props.currentUnit', storage.getUnit())
    if (!utils.nonNull(unidade)) {
      unidade = utils.getIdValueUnidadeMatriz(_.get(this, 'props.currentCompany', storage.getCompany()))
      this.setField('unidade', unidade)
    } else {
      this.props.loadTiposSepulturas(unidade.oid)
    }
  }

  abrirContrato = (oidContrato) => {
    this.props.history.push(`${routes.PATH_CONTRATO}/${oidContrato}`)
    this.props.loadContrato(oidContrato, 'Contrato aberto com sucesso!')
  }

  onClickCriarContrato = () => {
    this.props.criarContrato(this.props.record.oid)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  criarContratoCremacaoFalecido = (contratoFalecido) => {
    this.props.criarContratoCremacaoFalecido(this.props.record, contratoFalecido)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  confirmarExclusaoFalecido = (rowData) => {
    this.falecidoExcluir = rowData
    this.setState({ showConfirmarExcluirFalecido: true })
  }

  confirmarExclusaoResponsavel = (rowData) => {
    this.responsavelExcluir = rowData
    this.setState({ showConfirmarExcluirResponsavel: true })
  }

  confirmarExclusaoDocumento = (rowData) => {
    this.documentoExcluir = rowData
    this.setState({ showConfirmarExcluirDocumento: true })
  }

  confirmarAssinaturaDocumento = (rowData) => {
    const oidContrato = _.get(this, 'props.record.oid', null)
    const oid = _.get(rowData, 'oid', null)
    if (!oid || !oidContrato) {
      this.showMessage('O contrato deve estar salvo', 'warn')
      return
    }
    const serviceProviderDocumentId = _.get(rowData, 'serviceProviderDocumentId', null)
    if (serviceProviderDocumentId) {
      this.showMessage('Documento já assinado ou em processo de assinatura digital.', 'warn')
    } else {
      this.documentoAssinar = rowData
      this.setState({ showConfirmarAssinarDocumento: true })
    }
  }

  confirmarExclusaoDependente = (rowData) => {
    this.dependenteExcluir = rowData
    this.setState({ showConfirmarExcluirDependente: true })
  }

  visualizarDocumento = (rowData) => {
    //TODO #assinatura - emitir mensagem caso nao tenha ainda os links, tanto original quanto assinado
    const url = _.get(rowData, 'urlDocumentoAssinado', null)
    if (url) {
      const win = window.open(url)
      if (win) {
        win.focus()
      }
    }
  }

  imprimirDocumento = (rowData) => {
    const record = _.get(this, 'props.record', null)
    if (!record) {
      this.showMessage('Não foi possível obter parâmetros para o relatório.', 'error')
      return
    }

    const params = {}

    const responsaveis = _.get(record, 'responsaveis', [])
    const primeiroResponsavel = utils.findObjectInArray(responsaveis, 'ordem', 1)
    if (primeiroResponsavel) {
      params.oidResponsavel = primeiroResponsavel.oidResponsavel
    }

    const falecidos = _.get(record, 'falecidos', [])
    const primeiroFalecido = utils.findObjectInArray(falecidos, 'ordem', 1)
    if (primeiroFalecido) {
      params.oidFalecido = primeiroFalecido.oidFalecido
    }

    params.oidLocalizacao = _.get(record, 'localizacao.id', null)
    params.oidContrato = _.get(record, 'oid', null)
    params.oidContratoDocumento = _.get(rowData, 'oid', null)

    this.requestImprimirDocumento({ oidDocumento: rowData.oidDocumento, params })
  }

  assinarDocumento = () => {
    const oidContrato = _.get(this, 'props.record.oid', null)
    const oid = _.get(this.documentoAssinar, 'oid', null)
    if (!oid || !oidContrato) {
      this.showMessage('O contrato deve estar salvo', 'warn')
    } else {
      const funct = _.get(this, 'props.assinarDocumento', null)
      if (funct) {
        funct(oid)
      }
    }
  }

  getDialogConfirmarExclusaoDocumento() {
    if (this.state.showConfirmarExcluirDocumento) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExcluirDocumento}
          confirm={this.removerDocumento}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogConfirmarAssinarDocumento() {
    if (this.state.showConfirmarAssinarDocumento) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarAssinarDocumento}
          content={'Confirma a assinatura digital desse documento?'}
          confirm={this.assinarDocumento}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  componentDidUpdate(anteriorProps) {
    if (anteriorProps.record.oidUnidade === '' && this.props.record.unidade) {
      Object.assign(this.props.record, { oidUnidade: this.props.record.unidade.oid })
      Object.assign(this.props.record, { descricaoUnidade: this.props.record.unidade.descricao })
    }
    if (
      this.props.record.unidade &&
      (!anteriorProps.record.unidade || anteriorProps.record.unidade.id !== this.props.record.unidade.id)
    ) {
      this.props.loadTiposSepulturas(this.props.record.unidade.id)
    }
    if (anteriorProps.record.conspaga !== this.props.record.conspaga) {
      const conspagaEmDia = this.verificarConspagaEmDia()
      this.setState({ conspagaEmDia: conspagaEmDia })
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedFilters()
    this.props.willUnmount()
  }

  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    let valor = target.value
    this.props.setField(field, valor)
    // como trocou a unidade, é apagado o tipo de sepultura e localizacao selecionado
    this.props.setField('tipoSepultura', { id: '', value: '' })
    this.props.setField('localizacao', { id: '', value: '' })
    this.props.loadTiposSepulturas(valor.id)
    this.props.loadContratoFalecidoCamposComplementares()
  }

  addDocumentoFicha = ({ documento, oidContrato, tipoDocumento }) => {
    const filtros = [{ chave: 'oidContrato', valor: oidContrato, oid: null, oidContratoDocumento: null }]
    const params = {
      nome: documento.nome,
      oid: documento.oid,
      filtros,
    }
    this.props.addDocumentoContrato(params)
    const oid = _.get(this, 'props.record.oid', null)
    if (oid && oid > 0) {
      setTimeout(this.showMessageAndUpdateContrato(params.nome), 200)
    }
  }

  resolveRelatorioFicha = () => {
    if (this.utilizaAssinaturaDigitalDocumentos) {
      getDocumentoByTipo(tipoDocumentoConst.FICHA_CONTRATO)
        .then((res) => {
          const oid = _.get(res, 'data.oid', null)
          const nome = _.get(res, 'data.nome', null)
          const tipoDocumento = _.get(res, 'data.tipoDocumento', null)
          const params = {
            documento: { oid, nome, tipoDocumento },
            oidContrato: this.props.record.oid,
            tipoDocumento,
          }
          this.addDocumentoFicha(params)
        })
        .catch((e) => {
          console.error(`Erro obtendo documento pelo tipo ${tipoDocumentoConst.FICHA_CONTRATO}`)
          console.error(`Erro: ${JSON.stringify(e)}`)
        })
    } else {
      this.props.downloadPDF(this.props.record.oid)
    }
  }

  getBotaoPDF() {
    return (
      <Button
        onClick={() => this.resolveRelatorioFicha()}
        label={utils.getMessage(this.props.messages, 'contrato.label.ficha')}
        icon={icons.DOWNLOAD_TOOLBAR_ICON}
        style={{ marginRight: '.25em' }}
      />
    )
  }

  editarResponsavel = (responsavel) => {
    this.props.willUnmount()
    this.props.history.push(`${routes.PATH_PESSOA_FISICA}/${responsavel.oidResponsavel}`)
  }

  searchHandler = () => {
    this.props.willUnmount()
    this.props.history.push(`${routes.PATH_CONTRATO}`)
  }

  isLocalizacaoSepultamentoInvalida = () => {
    const falecido = this.props.falecido
    const localizacaoDestino = falecido ? falecido.localizacaoDestino : null
    const result = utils.isIdValueOnlyStringValue(localizacaoDestino)
    this.setState({ localizacaoSepultamentoInvalida: result })
    return result
  }

  adicionarFalecido = () => {
    if (this.isLocalizacaoSepultamentoInvalida()) {
      return
    }
    this.props.adicionarFalecido()
    this.props.showForm('falecido', false)
  }

  salvarNovaCausaMortis = () => {
    this.props.salvarNovaCausaMortis(this.descricaoNovaCausaMortis, this.props, this.aposSalvarNovaCausaMortis)
  }

  aposSalvarNovaCausaMortis(props, causaMortisSalva) {
    var falecido = props.falecido
    var causaMortisInserir = {
      oidCausaMortis: causaMortisSalva.id,
      descricaoCausaMortis: causaMortisSalva.value,
      oidContratoFalecido: falecido.oid,
    }
    if (!falecido.causasMortis) {
      falecido.causasMortis = {}
    }

    falecido.causasMortis.push(causaMortisInserir)
    props.setField('falecido', falecido)
  }

  setDescricaoNovaCausaMortis = (descricao) => {
    this.descricaoNovaCausaMortis = descricao
  }

  baixarPDF = () => {
    this.props.downloadPDF(this.props.record.oid)
  }

  // REFERENTE AO LOOKUP PARA CONTRATO CREMACAO FUTURA
  lookupContratoCremacaoFuturaOnPage = (e) => {
    this.props.lookupContratoCremacaoFuturaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupContratoCremacaoFuturaOnSort = (e) => {
    this.props.lookupContratoCremacaoFuturaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupContratoCremacaoFuturaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupContratoCremacaoFuturaApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupContratoCremacaoFuturaLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupContratoCremacaoFuturaLoad()
    }
  }

  lookupContratoCremacaoFuturaOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupContratoCremacaoFuturaLoad()
    this.props.lookupContratoCremacaoFuturaSetVisible(true)
  }
  lookupContratoCremacaoFuturaOnHide = (e) => {
    this.props.lookupContratoCremacaoFuturaSetVisible(false)
  }
  lookupContratoCremacaoFuturaOnConfirm = (e) => {
    const contrato = e.selection
    this.props.setField('contratoCremacaoFutura', contrato ? contrato.oid : null)
    this.props.lookupContratoCremacaoFuturaSetVisible(false)
  }
  lookupContratoCremacaoFuturaOnCancel = (e) => {
    this.props.lookupContratoCremacaoFuturaSetVisible(false)
  }
  lookupContratoCremacaoFuturaOnEdit = () => {
    if (this.props.record.contrato && this.props.record.contrato.id) {
      return `${routes.PATH_CONTRATO}/${this.props.record.contrato.id}`
    } else {
      return `${routes.PATH_CONTRATO}`
    }
  }
  lookupContratoCremacaoFuturaOnComplete = (e) => {}

  lookupContratoCremacaoFuturaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('contrato.id', '-1')
      this.props.setField('contrato.value', e.value)
      this.props.clearRecords()
    } else {
      let contrato = e.value
      this.props.setField('contrato.id', contrato.oid)
      this.props.setField('contrato.value', contrato.localizacao_identificacao)
      this.props.setField('pagador', { id: contrato.responsavel_oid, value: contrato.responsavel_nome })
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupContratoCremacaoFuturaOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.contrato) && utils.nonNull(this.props.record.contrato.value)) {
      const filtroContrato = new Filtro('localizacao_identificacao', this.props.record.contrato.value, 'CONTEM')
      this.props.lookupContratoCremacaoFuturaApplyFilter(filtroContrato)
    }
  }

  // REFERENTE AO LOOKUP PARA LOCALIZAÇÃO
  lookupLocalizacaoOnPage = (e) => {
    this.props.lookupLocalizacaoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupLocalizacaoOnSort = (e) => {
    this.props.lookupLocalizacaoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupLocalizacaoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupLocalizacaoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupLocalizacaoLoad()
    }
  }
  onChangeUnitLocalizacao = (e) => {
    const target = e.target
    let valor = target.value
    this.props.setField('unidadeLocalizacao', valor)
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoLoad()
  }
  onChangeApenasLocalizacaoTemporaria = (e) => {
    const target = e.target
    let valor = target.checked
    this.props.setField('apenasLocalizacaoTemporaria', valor)
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoLoad()
  }

  onChangeApenasLocalizacaoDestinoTemporaria = (e) => {
    const target = e.target
    let valor = target.checked
    this.props.setField('apenasLocalizacaoDestinoTemporaria', valor)
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoDestinoLoad()
  }

  lookupLocalizacaoOnClick = (e) => {
    this.props.setField('apenasLocalizacaoTemporaria', false)
    if (this.props.record.unidade && this.props.record.unidade.id) {
      this.props.setField('unidadeLocalizacao', this.props.record.unidade)
    }
    this.props.clearSelectedFilters()
    this.props.lookupLocalizacaoLoad()
    this.props.lookupLocalizacaoSetVisible(true)
  }
  lookupLocalizacaoOnHide = (e) => {
    this.props.lookupLocalizacaoSetVisible(false)
    this.props.clearSelectedFilters()
  }
  lookupLocalizacaoOnConfirm = ({ selection }) => {
    this.props.setField('localizacao.id', selection.oid)
    this.props.setField('localizacao.value', this.resolveLocalizacaoDisplayLabel(selection))
    this.props.setField('numeroVagas', selection.numeroVagas)
    this.props.setField('numeroLugares', selection.numeroLugares)
    this.props.lookupLocalizacaoSetVisible(false)
    this.props.clearSelectedFilters()
  }
  resolveLocalizacaoDisplayLabel = (value) => {
    if (value.temporaria) {
      return value.identificacao + ' (Temp)'
    }
    return value.identificacao
  }
  lookupLocalizacaoOnCancel = (e) => {
    this.props.lookupLocalizacaoSetVisible(false)
  }
  lookupLocalizacaoOnEdit = () => {
    if (this.props.record.localizacao.id) {
      return `${routes.PATH_LOCALIZACAO}/${this.props.record.localizacao.id}`
    } else {
      return `${routes.PATH_LOCALIZACAO}/`
    }
  }
  lookupLocalizacaoOnComplete = (e) => {}

  lookupLocalizacaoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('localizacao.value', e.value)
      this.props.setField('localizacao.id', null)
      this.props.setField('numeroVagas', 0)
      this.props.setField('numeroLugares', 0)
      this.props.clearRecords()
    } else {
      this.props.setField('localizacao.id', value.oid)
      this.props.setField('localizacao.value', this.resolveLocalizacaoDisplayLabel(value))
      this.props.setField('numeroVagas', value.numeroVagas)
      this.props.setField('numeroLugares', value.numeroLugares)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupLocalizacaoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.localizacao) && utils.nonNull(this.props.record.localizacao.value)) {
      const filtroIdentificacao = new Filtro('identificacao', this.props.record.localizacao.value, 'CONTEM')
      this.props.lookupLocalizacaoApplyFilter(filtroIdentificacao)
    }
  }

  // REFERENTE AO LOOKUP PARA LOCALIZAÇÃO DE DESTINO
  lookupLocalizacaoDestinoOnClick = (e) => {
    this.props.setField('apenasLocalizacaoDestinoTemporaria', false)
    if (
      this.props.record.unidade &&
      this.props.record.unidade.id &&
      this.props.record.tipoSepultura &&
      this.props.record.tipoSepultura.oid
    ) {
      if (_.get(this, 'props.isTipoSepulturaUrna', false)) {
        this.props.showForm('viewGavetas', true)
      } else {
        this.props.clearSelectedFilters()
        this.props.lookupLocalizacaoDestinoLoad()
        this.props.lookupLocalizacaoDestinoSetVisible(true)
      }
    } else {
      this.props.clearSelectedFilters()
      this.props.lookupLocalizacaoDestinoLoad()
      this.props.lookupLocalizacaoDestinoSetVisible(true)
    }
  }
  lookupLocalizacaoDestinoOnCancel = (e) => {
    this.props.lookupLocalizacaoDestinoSetVisible(false)
  }

  lookupLocalizacaoDestinoOnEdit = () => {
    if (this.props.falecido.localizacaoDestino && this.props.falecido.localizacaoDestino.id) {
      return `${routes.PATH_LOCALIZACAO}/${this.props.falecido.localizacaoDestino.id}`
    } else {
      return `${routes.PATH_LOCALIZACAO}/`
    }
  }

  lookupLocalizacaoDestinoOnConfirm = ({ selection }) => {
    this.props.editarCampoFalecido('localizacaoDestino.id', selection.oid)
    this.props.editarCampoFalecido('localizacaoDestino.value', this.resolveLocalizacaoDisplayLabel(selection))
    if (utils.isStringBlank(this.props.falecido.processoTranslado)) {
      this.props.editarCampoFalecido('processoTranslado', selection.identificacao)
    }
    this.props.lookupLocalizacaoDestinoSetVisible(false)
    this.props.clearSelectedFilters()
  }
  lookupLocalizacaoDestinoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.editarCampoFalecido('localizacaoDestino.value', e.value)
      this.props.editarCampoFalecido('localizacaoDestino.id', null)
    } else {
      this.props.editarCampoFalecido('localizacaoDestino.id', value.oid)
      this.props.editarCampoFalecido('localizacaoDestino.value', this.resolveLocalizacaoDisplayLabel(value))
      if (utils.isStringBlank(this.props.falecido.processoTranslado)) {
        this.props.editarCampoFalecido('processoTranslado', value.identificacao)
      }
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }
  lookupLocalizacaoDestinoOnKeyPress = (e) => {
    if (
      utils.nonNull(this.props.falecido.localizacaoDestino) &&
      utils.nonNull(this.props.falecido.localizacaoDestino.value)
    ) {
      const filtroIdentificacao = new Filtro('identificacao', this.props.falecido.localizacaoDestino.value, 'CONTEM')
      this.props.lookupLocalizacaoApplyFilter(filtroIdentificacao)
    }
  }

  // REFERENTE AO LOOKUP PARA FALECIDO
  lookupFalecidoOnPage = (e) => {
    this.props.lookupFalecidoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupFalecidoOnSort = (e) => {
    this.props.lookupFalecidoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupFalecidoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupFalecidoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupFalecidoLoad()
    }
  }

  lookupFalecidoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.falecido) && utils.nonNull(this.props.falecido.cpfFalecido)) {
      const filtroNome = new Filtro('nome', this.props.falecido.cpfFalecido, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.falecido.cpfFalecido, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupFalecidoApplyFilter(filtroOu)
    }
  }
  lookupFalecidoOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupFalecidoLoad()
    this.props.lookupFalecidoSetVisible(true)
  }
  lookupFalecidoOnHide = (e) => {
    this.props.lookupFalecidoSetVisible(false)
  }
  lookupFalecidoOnConfirm = (e) => {
    let falecido = e.selection
    if (falecido.dataNascimento && parseInt(falecido.dataNascimento)) {
      falecido.dataNascimento = new Date(falecido.dataNascimento)
    }
    if (falecido.dataFalecimento && parseInt(falecido.dataFalecimento)) {
      falecido.dataFalecimento = new Date(falecido.dataFalecimento)
    }
    this.props.setFalecido(falecido)
    this.props.lookupFalecidoSetVisible(false)
  }
  lookupFalecidoOnCancel = (e) => {
    this.props.lookupFalecidoSetVisible(false)
  }
  lookupFalecidoOnEdit = (e) => {
    this.props.showForm('falecido', false)
    if (this.props.falecido && this.props.falecido.oidFalecido) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.falecido.oidFalecido}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}/`
    }
  }

  lookupFalecidoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let dataNascimento = null
      if (this.props.falecido.dataNascimento && utils.isNumber(this.props.falecido.dataNascimento)) {
        dataNascimento = new Date(this.props.falecido.dataNascimento)
      }
      let falecido = { numeroDocumento: e.value, oid: null, dataNascimento: dataNascimento }
      this.props.setFalecido(falecido)
      this.props.clearRecords()
    } else {
      if (value.dataNascimento && parseInt(value.dataNascimento)) {
        value.dataNascimento = new Date(value.dataNascimento)
      }
      if (value.dataFalecimento && parseInt(value.dataFalecimento)) {
        value.dataFalecimento = new Date(value.dataFalecimento)
      }
      this.props.setFalecido(value)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }
  lookupFalecidoOnComplete = (e) => {}
  // REFERENTE AO LOOKUP DE RESPONSAVEL PELA CREMACAO DO FALECIDO
  lookupResponsavelCremacaoOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupResponsavelLoad()
    this.props.lookupResponsavelCremacaoSetVisible(true)
  }
  lookupResponsavelCremacaoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.falecido) && utils.nonNull(this.props.falecido.responsavelCremacao)) {
      const filtroNome = new Filtro('nome', this.props.falecido.responsavelCremacao, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.falecido.responsavelCremacao, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupResponsavelApplyFilter(filtroOu) //FIXME validar isso
    }
  }
  lookupResponsavelCremacaoOnHide = (e) => {
    this.props.lookupResponsavelCremacaoSetVisible(false)
  }
  lookupResponsavelCremacaoOnConfirm = (e) => {
    let responsavel = e.selection
    this.props.editarCampoFalecido('responsavelCremacao', responsavel.nome)
    this.props.editarCampoFalecido('oidResponsavelCremacao', responsavel.oid)
    this.props.lookupResponsavelCremacaoSetVisible(false)
  }
  lookupResponsavelCremacaoOnCancel = (e) => {
    this.props.lookupResponsavelCremacaoSetVisible(false)
  }

  lookupResponsavelCremacaoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.editarCampoFalecido('responsavelCremacao', e.value)
      this.props.editarCampoFalecido('oidResponsavelCremacao', null)
      this.props.clearRecords()
    } else {
      this.props.editarCampoFalecido('responsavelCremacao', value.nome)
      this.props.editarCampoFalecido('oidResponsavelCremacao', value.oid)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  // REFERENTE AO LOOKUP PARA RESPONSAVEL
  lookupResponsavelOnPage = (e) => {
    this.props.lookupResponsavelPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupResponsavelOnSort = (e) => {
    this.props.lookupResponsavelSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupResponsavelOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupResponsavelApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupResponsavelLoad()
    }
  }

  lookupResponsavelOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupResponsavelLoad()
    this.props.lookupResponsavelSetVisible(true)
  }
  lookupResponsavelOnHide = (e) => {
    this.props.lookupResponsavelSetVisible(false)
  }
  lookupResponsavelOnConfirm = (e) => {
    this.props.setResponsavel(e.selection)
    this.props.lookupResponsavelSetVisible(false)
  }
  lookupResponsavelOnCancel = (e) => {
    this.props.lookupResponsavelSetVisible(false)
  }
  lookupResponsavelOnEdit = () => {
    return `${routes.PATH_PESSOA_FISICA}/`
  }
  lookupResponsavelOnComplete = (e) => {}

  lookupResponsavelOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let responsavel = { nome: e.value, oid: null }
      this.props.setNomeResponsavel(responsavel)
      this.props.clearRecords()
    } else {
      this.props.setResponsavel(value)
      this.props.setNomeResponsavel({ nome: '', oid: null })
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupResponsavelOnKeyPress = (e) => {
    if (utils.nonNull(this.props.responsavel) && utils.nonNull(this.props.responsavel.responsavel)) {
      const filtroNome = new Filtro('nome', this.props.responsavel.responsavel, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.responsavel.responsavel, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupResponsavelApplyFilter(filtroOu)
    }
  }

  // REFERENTE AO LOOKUP PARA FUNERARIA
  lookupFunerariaOnPage = (e) => {
    this.props.lookupFunerariaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupFunerariaOnSort = (e) => {
    this.props.lookupFunerariaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupFunerariaOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupFunerariaApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupFunerariaLoad()
    }
  }

  lookupFunerariaOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupFunerariaLoad()
    this.props.lookupFunerariaSetVisible(true)
  }
  lookupFunerariaOnHide = (e) => {
    this.props.lookupFunerariaSetVisible(false)
  }
  lookupFunerariaOnConfirm = (e) => {
    this.props.setFuneraria(e.selection)
    this.props.lookupFunerariaSetVisible(false)
  }
  lookupFunerariaOnCancel = (e) => {
    this.props.lookupFunerariaSetVisible(false)
  }
  lookupFunerariaOnEdit = () => {
    this.props.showForm('falecido', false)
    if (this.props.funeraria && this.props.funeraria.oidFuneraria) {
      return `${routes.PATH_PESSOA_JURIDICA}/${this.props.funeraria.oidFuneraria}`
    } else {
      return `${routes.PATH_PESSOA_JURIDICA}/`
    }
  }
  lookupFunerariaOnComplete = (e) => {}

  lookupFunerariaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let funeraria = { nome: value, oid: null }
      this.props.setFuneraria(funeraria)
      this.props.clearRecords()
    } else {
      let funeraria = { nome: value.nome, oid: value.oid }
      this.props.setFuneraria(funeraria)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupFunerariaOnKeyPress = (e) => {
    if (utils.nonNull(this.props.falecido) && utils.nonNull(this.props.falecido.funeraria)) {
      const filtroNome = new Filtro('nome', this.props.falecido.funeraria, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.falecido.funeraria, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupFunerariaApplyFilter(filtroOu)
    }
  }

  // REFERENTE AO LOOKUP PARA DEPENDENTE
  lookupDependenteOnPage = (e) => {
    this.props.lookupDependentePaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupDependenteOnSort = (e) => {
    this.props.lookupDependenteSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupDependenteOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupDependenteApplyFilter(filter)
        isFilterApplied = true
      }
    })
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupDependenteLoad()
    }
  }

  lookupDependenteOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupDependenteLoad()
    this.props.lookupDependenteSetVisible(true)
  }
  lookupDependenteOnHide = (e) => {
    this.props.lookupDependenteSetVisible(false)
  }
  lookupDependenteOnConfirm = (e) => {
    this.props.setDependente(e.selection)
    this.props.lookupDependenteSetVisible(false)
  }
  lookupDependenteOnCancel = (e) => {
    this.props.lookupDependenteSetVisible(false)
  }
  lookupDependenteOnEdit = () => {
    if (this.props.dependente.oidDependente) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.dependente.oidDependente}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}/`
    }
  }
  lookupDependenteOnComplete = (e) => {}

  lookupDependenteOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      let dependente = { nome: e.value, oid: null }
      this.props.setDependente(dependente)
      this.props.clearRecords()
    } else {
      this.props.setDependente(value)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  lookupDependenteOnKeyPress = (e) => {
    if (utils.nonNull(this.props.dependente) && utils.nonNull(this.props.dependente.dependente)) {
      const filtroNome = new Filtro('nome', this.props.dependente.dependente, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.dependente.dependente, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupDependenteApplyFilter(filtroOu)
    }
  }

  onSelectionChange = (e) => {
    this.props.setSelectedLancamentos(e.value)
  }

  realizarBaixaLancamentos = () => {
    let params = []
    if (this.props.selectedLancamentos && this.props.selectedLancamentos.length > 0 && this.state.dataPagamentoBaixa) {
      this.props.selectedLancamentos.forEach((lancamento) => {
        let param = {
          oidLancamento: lancamento.oid,
          valorPago: lancamento.valor,
          dataPagamento: this.state.dataPagamentoBaixa,
        }
        params.push(param)
      })
      this.props.baixarLancamentos(params)
    }
  }

  showPageLancamentoFinanceiro = () => {
    let params = {}
    const localizacao = this.props.record.localizacao
    if (this.props.record.oid) {
      const contrato = { id: this.props.record.oid }
      if (utils.isIdValueIdValido(localizacao) && localizacao.value) {
        contrato.value = localizacao.value
      } else {
        contrato.value = this.props.record.oid
      }
      params.contrato = contrato
      params.conspaga = this.props.record.conspaga
    }
    const responsaveis = this.props.record.responsaveis
    if (responsaveis && utils.isArrayNotEmpty(responsaveis)) {
      const responsavelObj = utils.findObjectInArray(responsaveis, 'ordem', 1)
      if (utils.nonNull(responsavelObj)) {
        params.responsavel = { id: responsavelObj.oidResponsavel, value: responsavelObj.responsavel }
      }
    }

    const isTipoContratoPlano = _.get(this, 'props.isTipoSepulturaPlano', false)
    if (isTipoContratoPlano) {
      params.tipoFormaPagamento = _.get(this.props.record, 'plano.formaPagamento', null)
      params.valor = _.get(this.props.record, 'plano.valor', null)
      params.codigoAgencia = _.get(this.props.record, 'plano.codigoAgencia', null)
      params.codigoConta = _.get(this.props.record, 'plano.codigoConta', null)
      params.unidadeConsumidora = _.get(this.props.record, 'plano.unidadeConsumidora', null)
      params.dataCompetencia = _.get(this.props.record, 'dataProximaRemessaCompetencia', null)

      const coberturas = _.get(this.props.record, 'plano.coberturas', [])
      if (coberturas.length > 0) {
        params.coberturas = coberturas
      }
    }
    const unidade = this.props.record.unidade
    if (unidade && utils.isIdValueIdValido(unidade)) {
      params.unidade = unidade
    }
    this.props.history.push(`${routes.PATH_LANCAMENTO_FINANCEIRO}/novo`, params)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
    this.setState({ showConfirmarExcluirFalecido: false })
    this.setState({ showConfirmarExcluirResponsavel: false })
    this.setState({ showConfirmarExcluirDocumento: false })
    this.setState({ showConfirmarAssinarDocumento: false })
    this.setState({ showConfirmarExcluirDependente: false })
    this.setState({ showConfirmarEstornar: false })
  }

  showFormFalecido = () => {
    this.props.clearForm() // antes de mostrar a tela de adição de falecido, ele limpa ela.
    let ordem = 0
    if (this.props.falecidos.length > 0) {
      //função que retorna o valor máximo de ordem dos falecidos atuais
      let ordemMax = Math.max.apply(
        Math,
        this.props.falecidos.map(function (falecido) {
          return falecido.ordem
        })
      )
      ordem = ++ordemMax
    } else {
      ordem = 1
    }

    const observacaoFalecido = _.get(this, 'props.templateObservacaoFalecido', null)
    if (observacaoFalecido) {
      this.props.editarCampoFalecido('sobre', observacaoFalecido)
    }
    this.props.editarCampoFalecido('ordem', ordem)
    this.props.showForm('falecido', true)
    this.scrollTopFormFalecido()
  }

  scrollTopFormFalecido = () => {
    // código para scroll para cima do dialog de falecido

    let dialogFalecido = document.getElementById('dialogfalecido')
    if (dialogFalecido) {
      setTimeout(() => {
        dialogFalecido.children[0].scrollTop = 0
        dialogFalecido.children[1].scrollTop = 0
        dialogFalecido.getElementsByClassName('p-dialog-content')[0].scrollTop = 0
      }, 500)
    }
  }
  onBlurPeso = (falecido, e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    falecido[field] = utils.formatCurrency(valor.replace(',', '.'))
  }

  onBlurAltura = (falecido, e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    falecido[field] = utils.formatCurrency(valor.replace(',', '.'))
  }

  confirmacaoAdicaoFalecido = () => {
    this.setState({ visible: false })
    this.props.adicionarFalecido()
  }

  negacaoAdicaoFalecido = () => {
    this.setState({ visible: false })
    this.props.showForm('falecido', false)
  }

  onClickBaixarButton = (rowData) => {
    this.props.showForm('baixarLancamento', true)
    let valor = rowData.valor
    if (typeof valor === 'string') {
      valor = valor.replace(',', '.').replace('R$', '')
    }
    valor = utils.formatCurrency(valor)
    this.setState({ valorPago: valor })
    this.setState({ dataPagamento: new Date() })
    this.setState({ oidLancamento: rowData.oid })
  }

  disableBotaoBaixar = (rowData) => {
    return rowData && rowData.tipoFormaPagamento && rowData.tipoFormaPagamento.id === 99
  }

  onClickBtnBaixarLancamentos = (lancamentosSelecionados) => {
    this.setState({ lancamentosBaixar: [] })
    if (utils.isArrayNotEmpty(lancamentosSelecionados)) {
      const pago = lancamentosSelecionados.find((f) => f.status === enums.ID_STATUS_LANCAMENTO_PAGO)
      if (pago) {
        const message = `Foram selecionados lançamentos pagos, favor remover esses lançamentos.`
        this.props.showWarningMessage(message)
        return
      }

      this.setState({ dataPagamentoBaixa: new Date() })
      this.setState({ lancamentosBaixar: lancamentosSelecionados })
      this.props.showForm('baixarlancamentos', true)
    }
  }

  verificarConspagaEmDia = () => {
    const conspaga = this.props.record.conspaga
    if (conspaga && utils.isNumber(conspaga)) {
      const anoAtual = window.moment().year()
      if (conspaga >= anoAtual) {
        return true
      }
    }
    return false
  }

  realizarBaixaLancamento = () => {
    let lancamentoBaixado = {
      valorPago: utils.parseStringToNumber(this.state.valorPago),
      dataPagamento: this.state.dataPagamento,
      oidLancamento: this.state.oidLancamento,
    }
    this.props.baixarLancamentos([lancamentoBaixado])
  }

  onBlurValorPagoBaixa = (e) => {
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    let valorFormatado = utils.formatCurrency(valor.replace(',', '.').replace('R$', ''))
    this.setState({ valorPago: valorFormatado })
  }

  getDialogDadosBaixa() {
    return (
      <Dialog
        appendTo={document.body}
        header={utils.getMessage(this.props.messages, 'contrato.label.baixar')}
        visible={this.props.displayForm.baixarLancamento}
        onHide={(e) => this.props.showForm('baixarLancamento', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid margin-top-dialogs">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">{utils.getMessage(this.props.messages, 'contrato.label.valorbaixa')}</label>
              <InputText
                keyfilter={/^[0-9.,]+$/}
                value={this.state.valorPago}
                maxLength={10}
                onBlur={(e) => this.onBlurValorPagoBaixa(e)}
                onChange={(e) => this.setState({ valorPago: e.target.value })}
                name="valorPago"
              />
            </div>

            <div className="p-col-12">
              <label className="label">{utils.getMessage(this.props.messages, 'contrato.label.databaixa')}</label>
              <Calendar
                inline={true}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromNow()}
                monthNavigator={true}
                value={this.state.dataPagamento}
                onChange={(e) => this.setState({ dataPagamento: e.target.value })}
                name="dataPagamento"
              />
            </div>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Button
              label={utils.getMessage(this.props.messages, 'contrato.label.confirmar')}
              onClick={(e) => {
                this.realizarBaixaLancamento()
                this.props.showForm('baixarLancamento', false)
              }}
              icon={icons.CHECK_ICON}
              className="p-button-success"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  getDialogHistorico() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'contrato.label.confirmar')}
          onClick={(e) => {
            this.props.adicionarHistorico(this.props.currentUser)
            this.props.showForm('historico', false)
          }}
          icon={icons.CHECK_ICON}
          className="p-button-success"
        />
      </div>
    )

    return (
      <Dialog
        appendTo={document.body}
        header={utils.getMessage(this.props.messages, 'contrato.label.historico')}
        visible={this.props.displayForm.historico}
        onHide={(e) => this.props.showForm('historico', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
        style={{ maxWidth: '700px' }}
        footer={footer}
      >
        <div className="p-grid p-fluid margin-top-dialogs">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12">
              <span className="p-float-label">
                <InputTextarea
                  value={this.props.historico.descricao}
                  onChange={this.props.changeHandlerHistorico}
                  name="descricao"
                  rows={2}
                  cols={30}
                  autoResize={true}
                />
                <label>{utils.getMessage(this.props.messages, 'contrato.label.descricao')}</label>
              </span>
            </div>

            <div className="p-col-12">
              <span className="p-float-label">
                <LtsLookupMarcador
                  label={utils.getMessage(this.props.messages, 'contrato.label.tag')}
                  value={this.props.historico.tag}
                  history={this.props.history}
                  editHidden={true}
                  field={'codigo'}
                  multiple={false}
                  onChange={this.props.changeHandlerMarcadorHistorico}
                  showAddButton={true}
                  stateMarcador={this.props.stateMarcador}
                  tipo={'HISTORICO'}
                />
              </span>
            </div>
            <div className="p-col-12 p-sm-3 p-md-3">
              <span className="p-float-label">
                <Calendar
                  value={this.props.historico.lembrete}
                  onChange={this.props.changeHandlerHistorico}
                  showTime={true}
                  yearNavigator={true}
                  yearRange={utils.getYearRangeFromDate('1800')}
                  monthNavigator={true}
                  name="lembrete"
                />
                <label>{utils.getMessage(this.props.messages, 'contrato.label.lembrete')}</label>
              </span>
            </div>
            <div className="p-col-12">
              <span className="p-float-label">
                <LtsLookupPessoa
                  header={utils.getMessage(this.props.messages, 'contrato.label.pessoa')}
                  label={utils.getMessage(this.props.messages, 'contrato.label.pessoa')}
                  name={'pessoa'}
                  field={'nome'}
                  value={{
                    id: _.get(this.props.historico, 'oidPessoa', null),
                    value: _.get(this.props.historico, 'pessoa', null),
                  }}
                  oidRecord={_.get(this.props.historico, 'oidPessoa', '')}
                  history={this.props.history}
                  onChange={(e) => this.props.changeHandlerPessoaHistorico(e)}
                  showAddButton={false}
                  tipoPessoa={enums.TipoPessoa.FISICA}
                />
              </span>
            </div>
            <div className="p-col-12 p-sm-3 p-md-3 p-lg-3 p-xl-3">
              <LtsCurrency
                value={this.props.historico.valor}
                name={'valor'}
                label={'Valor'}
                onChange={(e) => this.props.changeHandlerValorHistorico(e)}
              />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  onHideModalBaixarLancamento() {
    self.props.showForm('baixarlancamentos', false)
  }

  confirmarBaixa({ valor, data, formaPagamento, lancamentos }) {
    const oidsLancamentos = lancamentos.map((l) => l.oid)
    const params = {
      oidsLancamentos,
      valor,
      data,
      formaPagamento,
    }
    self.props.baixarLancamentos(params)
    self.onHideModalBaixarLancamento()
  }

  getModalBaixarLancamento() {
    const oid = _.get(this, 'props.record.oid', null)
    if (!oid) {
      return null
    }
    return (
      <BaixarLancamentoModal
        messages={self.props.messages}
        visible={self.props.displayForm.baixarlancamentos}
        onHide={self.onHideModalBaixarLancamento}
        lancamentosBaixar={self.state.lancamentosBaixar}
        formasPagamento={self.props.formasPagamentoBasica}
        onConfirm={self.confirmarBaixa}
        labelBaixar={utils.getMessage(self.props.messages, 'contrato.label.baixar')}
        labelValorPago={utils.getMessage(self.props.messages, 'contrato.label.valorbaixa')}
        labelDataBaixa={utils.getMessage(self.props.messages, 'contrato.label.databaixa')}
        labelConfirmar={utils.getMessage(self.props.messages, 'contrato.label.confirmar')}
      />
    )
  }

  getDialogFormResponsavel = () => {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'contrato.label.adicionar')}
          onClick={(e) => {
            this.props.adicionarPessoaFisicaResponsavel()
            this.props.showForm('responsavel', false)
          }}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    if (this.props.displayForm.responsavel) {
      return (
        <Dialog
          header={utils.getMessage(this.props.messages, 'contrato.label.responsavel')}
          visible={this.props.displayForm.responsavel}
          onHide={(e) => this.props.showForm('responsavel', false)}
          modal={true}
          closable={true}
          contentStyle={{ maxHeight: '85%', overflow: 'auto' }}
          footer={footer}
          className="dialog-pessoa"
          contentClassName="dialog-content-pessoa"
        >
          <React.Fragment>
            <PessoaFisicaFormPage renderToolbar={false} renderTabView={false}></PessoaFisicaFormPage>
          </React.Fragment>
        </Dialog>
      )
    }
  }

  onClickAutorizacaoPublicidadeObito = (oidFalecido) => {
    let listId = []
    listId.push(this.props.record.oid)
    let params = {}
    const primeiroResponsavel = utils.findObjectInArray(this.props.record.responsaveis, 'ordem', 1)
    params.oidResponsavel = primeiroResponsavel.oidResponsavel
    params.oidFalecido = oidFalecido
    params.oidLocalizacao = this.props.record.localizacao.id

    this.props.onClickBotaoDinamico(
      listId,
      { oid: oidBotoesDinamicos.DECLARACAO_AUTORIZACAO_PUBLICIDADE_OBITO },
      params
    )
  }

  onClickBotaoDinamico = (botao) => {
    let listId = []
    listId.push(this.props.record.oid)
    let params = this.getParamsBotaoDinamico()

    this.props.onClickBotaoDinamico(listId, botao, params)
  }

  getParamsBotaoDinamico = () => {
    let params = {}
    const primeiroResponsavel = utils.findObjectInArray(this.props.record.responsaveis, 'ordem', 1)
    if (primeiroResponsavel) {
      params.oidResponsavel = primeiroResponsavel.oidResponsavel
    }
    const primeiroFalecido = utils.findObjectInArray(this.props.record.falecidos, 'ordem', 1)
    if (primeiroFalecido) {
      params.oidFalecido = primeiroFalecido.oidFalecido
    }
    params.oidLocalizacao = this.props.record.localizacao.id
    params.oidContrato = this.props.record.oid

    return params
  }

  getSplitButton = () => {
    if (!this.utilizaAssinaturaDigitalDocumentos) {
      const oidRecord = this.props.record.oid
      if (this.props.botoesDinamicos && this.props.botoesDinamicos.length > 0) {
        const relatorios = this.props.botoesDinamicos.map((botao) => {
          const funcaoDinamica = _.get(botao, 'funcaoDinamica', null)
          if (funcaoDinamica) {
            return {
              label: botao.textoBotao,
              command: (e) => {
                this.props.onClickBotaoDinamico([oidRecord], botao)
              },
            }
          }

          return {
            label: botao.textoBotao,
            command: (e) => {
              this.onClickBotaoDinamico({ oid: botao.oid })
            },
          }
        })
        return (
          <div>
            <SplitButton label="Relatórios" style={{ marginRight: '.25em' }} model={relatorios} />
          </div>
        )
      }
    }
  }

  getBotoesPersonalizados = () => {
    let contrato = this.props.record
    let botoes = []

    if (contrato.oid) {
      const botao = {
        type: 'button',
        label: 'WhatsApp',
        className: 'p-button-success',
        onClick: (e) => this.enviarWhatsApp(contrato.oid),
      }
      botoes.push(botao)
    }
    if (contrato.oid) {
      const botao = {
        type: 'button',
        label: 'E-mail',
        className: null,
        onClick: (e) => this.props.enviarMensagem(contrato.oid, enums.TipoMensagem.EMAIL),
      }
      botoes.push(botao)
    }

    if (
      this.state.conspagaEmDia &&
      this.state.acaoRelatorioCarteirinha &&
      utils.isNumber(contrato.oid) &&
      contrato.ativo
    ) {
      let botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'contrato.label.carteirinhas'),
        className: 'p-button-sucess',
        onClick: (e) => this.props.gerarCarteirinhaColetiva(contrato.oid),
      }
      botoes.push(botao)
    }
    return botoes
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  onStatusFilterChange = (event) => {
    this.dataTableLancamentos.filter(event.value.id, 'status', 'equals')
    this.setState({ brand: event.value })
  }

  verificarHabilitarBotaoCremarFalecido = (falecido) => {
    if (falecido.oidContratoDestino && parseInt(falecido.oidContratoDestino) > 0) {
      return true
    }
    return _.get(this, 'props.isTipoSepulturaCremacao', false)
  }

  gerarBoletoIndividual = () => {
    const result = this.state.lancamentosBoletoSelecionados
      .filter((f) => _.get(f, 'tipoFormaPagamento.id', 0) === enums.idFormaPagamentoBoleto)
      .map((f) => f.oid)
    const params = {
      oidsLancamentosFinanceiros: result,
      formaEmissaoEnvioCorreios: this.state.formaEmissaoEnvioCorreios,
      enviarSmsAntesVencimento: this.state.enviarSmsAntesVencimento,
      enviarSmsDiaVencimento: this.state.enviarSmsDiaVencimento,
      enviarSmsDepoisVencimento: this.state.enviarSmsDepoisVencimento,
    }
    this.props.gerarCarneBoleto(params)
    // limpando os dados do state
    this.setState({ formaEmissaoEnvioCorreios: false })
    this.setState({ enviarSmsAntesVencimento: false })
    this.setState({ enviarSmsDiaVencimento: false })
    this.setState({ enviarSmsDepoisVencimento: false })
  }

  clearFormLapide = () => {
    this.setState({ tamanhoLapide: 'grande' })
    this.setState({ lapideComFoto: true })
    this.setState({ lapideComSimbolo: true })
    this.setState({ lapideComMensagem: false })
    this.setState({ msgLapide: '' })
    this.setState({ lapideComQrCode: true })
    this.setState({ oidContratoFalecido: '' })
  }

  downloadLapide = () => {
    const params = {
      lapideGrande: this.state.tamanhoLapide === 'grande' ? true : false,
      lapideComQrCode: this.state.lapideComQrCode,
      lapideComFoto: this.state.lapideComFoto,
      lapideComSimbolo: this.state.lapideComSimbolo,
      lapideComMensagem: this.state.lapideComMensagem,
      mensagemLapide: this.state.msgLapide,
      oidContratoFalecido: this.state.oidContratoFalecido,
      modeloLapide: this.state.modeloLapide,
    }
    this.props.downloadLapide(params)
    this.clearFormLapide()
  }

  verificarGeracaoBoletoIndividual = (lancamento) => {
    this.setState({ oidLancamentoBoletoIndividual: lancamento.oid })
    // caso já tem boleto gerado não deve mostrar novamente os dados para envio do boleto
    if (lancamento.oidBoletoAilos) {
      this.props.gerarCarneBoleto({ oidsLancamentosFinanceiros: [lancamento.oid] })
    } else {
      this.props.showForm('dadosBoletoIndividual', true)
    }
  }

  // Botao 'Boleto' do card 'Lancamentos Financeiros'
  verificarGeracaoBoletosIndividuais = (lancamentos) => {
    if (utils.isArrayNullOrEmpty(lancamentos)) {
      this.setState({ lancamentosBoletoSelecionados: [] })
    } else {
      const props = _.get(this, 'props', {})
      const result = lancamentos.filter((f) => _.get(f, 'tipoFormaPagamento.id', 0) === enums.idFormaPagamentoBoleto)
      this.setState({ lancamentosBoletoSelecionados: result })
      const noOidBoletoAilos = result.find((f) => _.get(f, 'oidBoletoAilos', null) === null)
      if (noOidBoletoAilos) {
        props.showForm('dadosBoletoIndividual', true)
      } else {
        const oidsLancamentosFinanceiros = result.map((f) => f.oid)
        props.gerarCarneBoleto({ oidsLancamentosFinanceiros })
      }
    }
  }

  gerarCarne = (lancamentos) => {
    if (lancamentos && lancamentos.length > 0) {
      const carnes = lancamentos.filter((p) => p.tipoFormaPagamento.id === 17)
      let oidsLancamentos = carnes.map((b) => b.oid)
      this.props.gerarCarne(oidsLancamentos)
    }
  }

  onClickLocalizacaoSepultamento = (localizacao) => {
    if (localizacao.disponivel) {
      this.props.salvarLocalizacaoDestino(localizacao)
      this.props.showForm('viewGavetas', false)
    } else {
      alert('Localização indisponível!')
    }
  }

  removerFalecido = () => {
    this.props.removerFalecido(this.falecidoExcluir, this.props.record.falecidos)
    this.falecidoExcluir = null
  }

  removerResponsavel = () => {
    this.props.removerResponsavel(this.responsavelExcluir, this.props.record.responsaveis)
    this.responsavelExcluir = null
  }

  removerDocumento = () => {
    const documentos = _.get(this, 'props.record.documentos', [])
    const doctos = documentos.filter((f) => f.oid !== this.documentoExcluir.oid)
    this.setField('documentos', doctos)
    const oid = _.get(this, 'props.record.oid', null)
    if (oid && oid > 0) {
      setTimeout(this.props.updateContrato(), 200)
    }
  }

  removerDependente = () => {
    this.props.removerDependente(this.dependenteExcluir, this.props.record.dependentes)
    this.dependenteExcluir = null
  }

  getDialogConfirmarExclusaoFalecido() {
    if (this.state.showConfirmarExcluirFalecido) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExcluirFalecido}
          confirm={this.removerFalecido}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogConfirmarExclusaoResponsavel() {
    if (this.state.showConfirmarExcluirResponsavel) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExcluirResponsavel}
          confirm={this.removerResponsavel}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogConfirmarExclusaoDependente() {
    if (this.state.showConfirmarExcluirDependente) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExcluirDependente}
          confirm={this.removerDependente}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogConfirmarEstorno() {
    if (this.state.showConfirmarEstornar) {
      const msgKey = 'contrato.dialog.message.confirmar.estorno'
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarEstornar}
          content={utils.getMessage(this.props.messagesDialog, msgKey)}
          confirm={this.executarEstorno}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  //LOOKUP DOCUMENTO - INICIO//
  lookupDocumentoOnPage = (e) => {
    this.props.lookupDocumentoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupDocumentoOnSort = (e) => {
    this.props.lookupDocumentoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupDocumentoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupDocumentoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupDocumentoLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupDocumentoLoad()
    }
  }

  lookupDocumentoOnClick = (e) => {
    this.props.lookupDocumentoLoad()
    this.props.lookupDocumentoSetVisible(true)
  }
  lookupDocumentoOnHide = (e) => {
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnConfirm = (e) => {
    // this.props.addDocumento(e.selection);
    this.addDocumento(e.selection)
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnCancel = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnEdit = () => {
    if (this.props.record.oidDocumento) {
      return `${routes.PATH_DOCUMENTO}/${this.props.record.oidDocumento}`
    } else {
      return `${routes.PATH_DOCUMENTO}`
    }
  }

  lookupDocumentoOnComplete = (e) => {}

  lookupDocumentoOnKeyPress = (e) => {
    const documentoTempLookup = _.get(this, 'props.record.documentoTempLookup', null)
    if (documentoTempLookup) {
      const filtro = new Filtro('nome', documentoTempLookup.nome, 'CONTEM')
      this.props.lookupDocumentoApplyFilter(filtro)
    }
  }

  lookupDocumentoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const documentoTempLookup = { oid: null, nome: value }
      this.props.setField('documentoTempLookup', documentoTempLookup)
      this.props.clearRecords()
    } else {
      this.addDocumento(value)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  addDocumento = (value) => {
    this.props.addDocumento(value) //value => documento {nome, oid}
    const oid = _.get(this, 'props.record.oid', null)
    if (oid && oid > 0) {
      setTimeout(this.props.updateContrato(), 200)
    }
  }

  resolveDocumentoFalecido = ({ documento, oidContratoFalecido, oidFalecido, falecido, tipoDocumento }) => {
    if (this.utilizaAssinaturaDigitalDocumentos) {
      const params = {
        documento,
        oidContratoFalecido,
        oidFalecido,
        falecido,
        tipoDocumento,
      }
      this.addDocumentoFalecido(params)
      return
    }

    if (tipoDocumento === tipoDocumentoConst.AUTORIZACAO_SEPULTAMENTO_URNA) {
      this.props.abrirAutorizacaoSepultamento(oidContratoFalecido)
    } else if (tipoDocumento === tipoDocumentoConst.AUTORIZACAO_SEPULTAMENTO_TUMULO) {
      this.props.abrirAutorizacaoSepultamentoTumulo(oidContratoFalecido)
    } else if (tipoDocumento === tipoDocumentoConst.REQUERIMENTO_CREMACAO) {
      this.props.gerarRequerimentoCremacao(oidContratoFalecido)
    } else if (tipoDocumento === tipoDocumentoConst.AUTORIZACAO_PUBLICACAO_OBITO) {
      this.onClickAutorizacaoPublicidadeObito(oidFalecido)
    }
  }

  resolveFiltrosDocumentoFalecido = ({ oidContratoFalecido, oidFalecido, tipoDocumento }) => {
    const primeiroResponsavel = utils.findObjectInArray(this.props.record.responsaveis, 'ordem', 1)
    return [
      { chave: 'oidFalecido', valor: oidFalecido, oid: null, oidContratoDocumento: null },
      {
        chave: 'oidResponsavel',
        valor: _.get(primeiroResponsavel, 'oidResponsavel', null),
        oid: null,
        oidContratoDocumento: null,
      },
      {
        chave: 'oidLocalizacao',
        valor: _.get(this, 'props.record.localizacao.id', null),
        oid: null,
        oidContratoDocumento: null,
      },
      { chave: 'oidContratoFalecido', valor: oidContratoFalecido, oid: null, oidContratoDocumento: null },
    ]
  }
  addDocumentoFalecido = ({ documento, oidContratoFalecido, oidFalecido, falecido, tipoDocumento }) => {
    const filtros = this.resolveFiltrosDocumentoFalecido({ oidContratoFalecido, oidFalecido, tipoDocumento })
    const params = {
      nome: `${documento.nome} - ${falecido}`,
      oid: documento.oid,
      filtros,
    }
    this.props.addDocumentoContrato(params)
    const oid = _.get(this, 'props.record.oid', null)
    if (oid && oid > 0) {
      setTimeout(this.showMessageAndUpdateContrato(params.nome), 200)
    }
  }

  showMessageAndUpdateContrato = (nomeDocumento) => {
    this.props.updateContrato()
    this.showMessage(`O documento ${nomeDocumento} foi adicionado na aba Documentos`, 'info')
  }

  //LOOKUP DOCUMENTO - FIM//

  getDialogViewGavetas() {
    let unidade = null
    if (this.props.displayForm.viewGavetas) {
      unidade = this.state.unidadeMemorial
    }
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'contrato.label.localizacaoSepultamento')}
        visible={this.props.displayForm.viewGavetas}
        style={{ width: '80vw' }}
        onHide={(e) => this.props.showForm('viewGavetas', false)}
        modal={true}
        closable={true}
        baseZIndex={10000000}
        contentStyle={{ maxHeight: '600px', minHeight: '500px', overflow: 'auto' }}
      >
        <React.Fragment>
          <LocalizacaoGridListPage
            renderHeader={false}
            marginTop={'50px'}
            unidadeMemorial={unidade}
            onClickLocalizacaoSepultamento={this.onClickLocalizacaoSepultamento}
          ></LocalizacaoGridListPage>
        </React.Fragment>
      </Dialog>
    )
  }

  getDialogDadosBoletoIndividual() {
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'contrato.label.dadosenviocontrato')}
        visible={this.props.displayForm.dadosBoletoIndividual}
        style={{ width: '50vw' }}
        onHide={(e) => this.props.showForm('dadosBoletoIndividual', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid margin-top-dialogs">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkEnviarPorCorreios"
                checked={this.state.formaEmissaoEnvioCorreios}
                onChange={(e) => this.setState({ formaEmissaoEnvioCorreios: e.checked })}
                name="formaEmissaoEnvioCorreios"
              />
              <label htmlFor="chkEnviarPorCorreios" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'contrato.label.enviarporcorreios')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsAntesVencimento"
                checked={this.state.enviarSmsAntesVencimento}
                onChange={(e) => this.setState({ enviarSmsAntesVencimento: e.checked })}
                name="enviarSmsAntesVencimento"
              />
              <label htmlFor="chkenviarSmsAntesVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'contrato.label.enviarsmsantesvencimento')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsDiaVencimento"
                checked={this.state.enviarSmsDiaVencimento}
                onChange={(e) => this.setState({ enviarSmsDiaVencimento: e.checked })}
                name="enviarSmsDiaVencimento"
              />
              <label htmlFor="chkenviarSmsDiaVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'contrato.label.enviarsmsnodiavencimento')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsDepoisVencimento"
                checked={this.state.enviarSmsDepoisVencimento}
                onChange={(e) => this.setState({ enviarSmsDepoisVencimento: e.checked })}
                name="enviarSmsDepoisVencimento"
              />
              <label htmlFor="chkenviarSmsDepoisVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'contrato.label.enviarsmsdepoisvencimento')}
              </label>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <Button
                  label={utils.getMessage(this.props.messages, 'contrato.label.confirmar')}
                  onClick={(e) => {
                    this.gerarBoletoIndividual()
                    this.props.showForm('dadosBoletoIndividual', false)
                  }}
                  icon={icons.CHECK_ICON}
                  className="p-button-success"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  getDialogDadosLapide() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'contrato.label.confirmar')}
          onClick={(e) => {
            this.downloadLapide()
            this.props.showForm('lapide', false)
          }}
          icon={icons.CHECK_ICON}
          className="p-button-success"
        />
      </div>
    )
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'contrato.label.lapide')}
        visible={this.props.displayForm.lapide}
        style={{ width: '50vw' }}
        onHide={(e) => this.props.showForm('lapide', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
        footer={footer}
      >
        {/*MODELOS*/}
        <div className="p-grid p-fluid margin-top-dialogs">
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <RadioButton
                id="rbModeloOriginal"
                value="original"
                name="modeloLapide"
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'original'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloOriginal">
                Modelo original
              </label>
              <RadioButton
                id="rbModeloNovo1"
                value="novo01"
                name="modeloLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'novo01'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloNovo1">
                Modelo novo 01
              </label>
              <RadioButton
                id="rbModeloNovo2"
                value="novo02"
                name="modeloLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'novo02'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloNovo2">
                Modelo novo 02
              </label>
              <RadioButton
                id="rbModeloNovo3"
                value="novo03"
                name="modeloLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'novo03'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloNovo3">
                Modelo novo 03
              </label>
              <RadioButton
                id="rbModeloNovo4"
                value="novo04"
                name="modeloLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'novo04'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloNovo4">
                QR Code 1
              </label>
              <RadioButton
                id="rbModeloNovo5"
                value="novo05"
                name="modeloLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ modeloLapide: e.value })}
                checked={this.state.modeloLapide === 'novo05'}
              />
              <label className="p-radiobutton-label" htmlFor="rbModeloNovo5">
                QR Code 2
              </label>
            </div>
          </div>
        </div>
        <br />
        <div className="p-grid p-fluid">
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <RadioButton
                id="rbGrande"
                value="grande"
                name="tamanhoLapide"
                onChange={(e) => this.setState({ tamanhoLapide: e.value })}
                checked={this.state.tamanhoLapide === 'grande'}
              />
              <label className="p-radiobutton-label" htmlFor="rbGrande">
                Grande/Individual
              </label>
              <RadioButton
                id="rbPequena"
                value="pequena"
                name="tamanhoLapide"
                style={{ marginLeft: '7px' }}
                onChange={(e) => this.setState({ tamanhoLapide: e.value })}
                checked={this.state.tamanhoLapide === 'pequena'}
              />
              <label className="p-radiobutton-label" htmlFor="rbPequena">
                Pequena/Totem
              </label>
            </div>
          </div>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '5px' }}>
          <div className="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2">
            <label className="label">{'Foto'}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <Checkbox
              onChange={(e) => this.setState({ lapideComFoto: e.checked })}
              checked={this.state.lapideComFoto}
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2">
            <label className="label">{'Símbolo'}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <Checkbox
              onChange={(e) => this.setState({ lapideComSimbolo: e.checked })}
              checked={this.state.lapideComSimbolo}
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2">
            <label className="label">{'QRCode'}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <Checkbox
              onChange={(e) => this.setState({ lapideComQrCode: e.checked })}
              checked={this.state.lapideComQrCode}
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-2 p-lg-1 p-xl-2">
            <label className="label">{'Mensagem'}</label>
          </div>
          <div className="p-col-12 p-sm-2 p-md-2 p-lg-2 p-xl-2">
            <Checkbox
              onChange={(e) => this.setState({ lapideComMensagem: e.checked })}
              checked={this.state.lapideComMensagem}
              style={{ marginLeft: '5px' }}
            />
          </div>
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            {this.state.lapideComMensagem ? (
              <div>
                <span className="p-float-label">
                  <InputText
                    maxLength={76}
                    value={this.state.msgLapide}
                    onChange={(e) => this.setState({ msgLapide: e.target.value })}
                    style={{ width: '90%' }}
                  />
                  <label>{'Texto'}</label>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Dialog>
    )
  }

  estornarLancamento = (oidsLancamentos) => {
    this.lancamentosEstornar = oidsLancamentos
    this.setState({ showConfirmarEstornar: true })
  }

  executarEstorno = () => {
    this.props.estornarLancamento(this.lancamentosEstornar)
  }

  setEpis = (values) => {
    const falecido = _.get(this, 'props.falecido', null)
    if (falecido) {
      falecido.epis = values
      this.setField('falecido', falecido)
    }
  }

  setMarcadores = (data) => {
    const values = []
    data.forEach((object) => {
      values.push({
        id: _.get(object, 'oid', ''),
        value: _.get(object, 'codigo', ''),
      })
    })
    this.props.setField('marcadores', values)
  }

  enviarWhatsApp = (oidContrato) => {
    if (this.props.isUsarWebWhatsapp) {
      this.props.abrirWebWhatsApp({ oidContrato })
    } else {
      this.props.enviarMensagem(oidContrato, enums.TipoMensagem.WHATSAPP)
    }
  }

  enviarWhatsAppNotaFalecimento = (data) => {
    this.props.abrirWebWhatsAppNotaFalecimento(_.get(data, 'oid', null))
  }

  fieldCalendarFilter = (column) => {
    return <LtsCalendar name={column.field} onChange={(e) => this.onChangeFiltroCalendar(e, column)} />
  }

  onChangeFiltroCalendar = (e, column) => {
    const field = column.field
    let value = new Date(null).getTime() //1970-01-01T00:00:00.000Z
    let filter = 'gte'
    if (e) {
      value = e.getTime()
      filter = 'equals'
    }
    this.dataTableLancamentos.filter(value, field, filter)
  }

  render() {
    function resolveRotaResponsavel(rowData) {
      if (rowData.pessoaFisica) {
        return `${routes.PATH_PESSOA_FISICA}/${rowData.oidResponsavel}`
      }
      return `${routes.PATH_PESSOA_JURIDICA}/${rowData.oidResponsavel}`
    }

    const actionResponsavel = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_ICON}
            className="p-button-warning"
            onClick={(e) => this.props.history.push(resolveRotaResponsavel(rowData))}
          />
          <Button
            style={{ marginLeft: '10px' }}
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            disabled={!this.state.ACAO_REMOVER_RESPONSAVEL}
            onClick={(e) => this.confirmarExclusaoResponsavel(rowData)}
          />
          {utils.isNumber(this.props.record.oid) && this.state.acaoRelatorioCarteirinha && this.props.record.ativo ? (
            <Button
              type="button"
              icon="pi pi-plus"
              label={utils.getMessage(this.props.messages, 'contrato.label.carteirinha')}
              style={{ marginLeft: '10px', width: '55%' }}
              onClick={(e) => this.props.gerarCarteirinhaIndividual(rowData.oidResponsavel, this.props.record.oid)}
            />
          ) : (
            ''
          )}
        </div>
      )
    }

    const resolveBodyStatusColumn = (rowData) => {
      if (rowData.statusDocumento === StatusDocumento.NAO_INTEGRADO) {
        return (
          <div style={{ color: 'grey', display: 'flex', justifyContent: 'center' }}>
            <span title="Criado" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
          </div>
        )
      }
      if (rowData.statusDocumento === StatusDocumento.ASSINATURA_PENDENTE) {
        return (
          <div style={{ color: 'orange', display: 'flex', justifyContent: 'center' }}>
            <span title="Aguardando assinatura" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
          </div>
        )
      }
      if (rowData.statusDocumento === StatusDocumento.ASSINADO) {
        return (
          <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
            <span title="Assinado" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
          </div>
        )
      }
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span title="Erro" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
        </div>
      )
    }

    const actionDocumento = (rowData) => {
      return (
        <React.Fragment>
          <Button
            type="button"
            className="p-button-success"
            icon={icons.VIEW}
            tooltip={'Visualizar'}
            onClick={(e) => this.imprimirDocumento(rowData)}
          />
          {resolveBotoesAssinaturaDigital(rowData)}
          <Button
            style={{ marginLeft: '10px' }}
            type="button"
            className="p-button-danger"
            icon={icons.REMOVE_ICON}
            tooltip={'Remover'}
            onClick={(e) => this.confirmarExclusaoDocumento(rowData)}
          />
        </React.Fragment>
      )
    }

    const resolveBotoesAssinaturaDigital = (rowData) => {
      if (this.utilizaAssinaturaDigitalDocumentos) {
        return (
          <React.Fragment>
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              className="p-button-success"
              icon={icons.CHECK_ICON}
              tooltip={'Enviar para assinatura'}
              onClick={(e) => this.confirmarAssinaturaDocumento(rowData)}
              disabled={isBotaoAssinarDisabled(rowData)}
            />
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              icon={icons.DOWNLOAD}
              tooltip={'Baixar'}
              onClick={(e) => this.visualizarDocumento(rowData)}
            />
          </React.Fragment>
        )
      }
      return null
    }

    const isBotaoAssinarDisabled = (documento) => {
      return (
        documento.statusDocumento !== StatusDocumento.NAO_INTEGRADO &&
        documento.statusDocumento !== StatusDocumento.NAO_INTEGRADO
      )
    }

    const actionFalecido = (rowData) => {
      const leftContents = () => {
        return (
          <React.Fragment>
            <Button
              type="button"
              icon={icons.PENCIL_ICON}
              className="p-button-warning"
              onClick={(e) => {
                this.props.clearForm()
                this.scrollTopFormFalecido()
                this.props.editarFalecido(rowData)
                this.props.showForm('falecido', true)
              }}
            />
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              icon={icons.WHATSAPP}
              className="p-button-success"
              onClick={(e) => {
                this.enviarWhatsAppNotaFalecimento(rowData)
              }}
            />
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              icon={icons.REMOVE_ICON}
              className="p-button-danger"
              disabled={!this.state.ACAO_REMOVER_FALECIDO}
              onClick={(e) => this.confirmarExclusaoFalecido(rowData)}
            />
            {resolveBotaoCremarFalecido(rowData)}
            <i className="p-toolbar-separator p-mr-2" />
            {resolveBotaoRelatoriosFalecido(rowData)}
          </React.Fragment>
        )
      }
      return <Toolbar style={{ margin: '5px' }} left={leftContents()} />
    }

    const resolveBotaoCremarFalecido = (rowData) => {
      if (this.state.acaoCremarFalecido) {
        return (
          <Button
            style={{ marginLeft: '10px' }}
            type="button"
            icon={'pi pi-inbox'}
            tooltip={'Cremar'}
            className="p-button-danger"
            onClick={(e) => this.criarContratoCremacaoFalecido(rowData)}
            disabled={this.verificarHabilitarBotaoCremarFalecido(rowData)}
          />
        )
      }
      return ''
    }

    const resolveBotaoRelatoriosFalecido = (rowData) => {
      if (this.state.acaoAcoesFalecido) {
        const relatorios = _.get(this, 'props.relatoriosFalecido', [])
        let documentosFalecido = []
        for (const relatorio of relatorios) {
          documentosFalecido.push({
            label: _.get(relatorio, 'nome', ''),
            command: (e) => {
              this.resolveDocumentoFalecido({
                documento: relatorio,
                oidContratoFalecido: rowData.oid,
                oidFalecido: rowData.oidFalecido,
                falecido: rowData.falecido,
                tipoDocumento: _.get(relatorio, 'tipo', null),
              })
            },
          })
        }
        documentosFalecido.push(
          {
            label: utils.getMessage(this.props.messages, 'contrato.label.lapide'),
            command: (e) => {
              this.props.showForm('lapide', true)
              this.setState({ oidContratoFalecido: rowData.oid })
            },
          },
          {
            label: utils.getMessage(this.props.messages, 'contrato.label.etiqueta'),
            command: (e) => {
              this.props.gerarEtiqueta(rowData.oid)
            },
          },
          {
            label: utils.getMessage(this.props.messages, 'contrato.label.notaFalecimento'),
            command: (e) => {
              this.props.gerarNotaFalecimento(rowData.oid)
            },
          }
        )

        return (
          <SplitButton
            label={utils.getMessage(this.props.messages, 'contrato.label.relatorios')}
            style={{ width: '50%' }}
            model={documentosFalecido}
            appendTo={document.body}
          />
        )
      }
      return ''
    }

    const actionDependente = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.editarDependente(rowData)
              this.props.showForm('dependente', true)
            }}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.confirmarExclusaoDependente(rowData)}
          />
          {utils.isNumber(this.props.record.oid && this.state.acaoRelatorioCarteirinha) ? (
            <Button
              type="button"
              icon="pi pi-plus"
              label={utils.getMessage(this.props.messages, 'contrato.label.carteirinha')}
              style={{ width: '55%' }}
              onClick={(e) => this.props.gerarCarteirinhaIndividual(rowData.oidDependente, this.props.record.oid)}
            />
          ) : (
            ''
          )}
        </div>
      )
    }

    const actionHistorico = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            icon={icons.PENCIL_ICON}
            className="p-button-warning"
            onClick={(e) => {
              this.props.setDadosHistorico(rowData)
              this.props.showForm('historico', true)
            }}
            disabled={!this.state.ACAO_ALTERAR_HISTORICO}
          />
          <Button
            style={{ marginLeft: '10px' }}
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            onClick={(e) => this.props.removerHistorico(rowData, this.props.record.historicos)}
            disabled={!this.state.ACAO_REMOVER_HISTORICO}
          />
        </div>
      )
    }

    const actionsLancamentos = (lancamento) => {
      return (
        <div>
          {utils.nonNull(lancamento.status) && lancamento.status !== 3 && lancamento.status !== 4 ? (
            <Button
              type="button"
              icon={icons.BAIXAR_ICON}
              className="p-button-warning"
              onClick={(e) => this.onClickBaixarButton(lancamento)}
              tooltip={utils.getMessage(this.props.messages, 'contrato.label.baixar')}
              disabled={this.disableBotaoBaixar(lancamento)}
            />
          ) : null}
          {utils.isIdValueIdValido(lancamento.tipoFormaPagamento) && lancamento.tipoFormaPagamento.id === 5 ? (
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              className="p-button-warning"
              icon={icons.CREDIT_CARD_ICON}
              onClick={(e) => this.verificarGeracaoBoletoIndividual(lancamento)}
              tooltip={utils.getMessage(this.props.messages, 'contrato.label.boleto')}
            />
          ) : null}
          {utils.nonNull(lancamento.status) &&
          lancamento.status === 3 &&
          utils.isIdValueIdValido(lancamento.tipoFormaPagamento) &&
          lancamento.tipoFormaPagamento.id !== 2 ? (
            <Button
              style={{ marginLeft: '10px' }}
              type="button"
              icon={icons.REMOVE_ICON}
              className="p-button-danger"
              onClick={(e) => this.estornarLancamento([lancamento.oid])}
              tooltip={utils.getMessage(this.props.messages, 'contrato.label.estornar')}
            />
          ) : (
            ''
          )}
        </div>
      )
    }

    const columnParentesco = (rowData, column) => {
      const parentesco = _.get(rowData, column.field, null)
      if (parentesco) {
        return _.get(parentesco, 'value', null)
      }
      return null
    }

    const columnData = (rowData, column) => {
      if (
        (column.field === 'dataNascimento' ||
          column.field === 'dataTranslado' ||
          column.field === 'dataExumacao' ||
          column.field === 'dataPrevisaoExumacao' ||
          column.field === 'lembrete' ||
          column.field === 'emissao' ||
          column.field === 'dataPagamento' ||
          column.field === 'vencimento') &&
        rowData[column.field] !== ''
      ) {
        return <div>{utils.formatarData(rowData[column.field])}</div>
      }
      if (column.field === 'dataFalecimento' && rowData[column.field] !== '') {
        return <div>{utils.formatarDataHora(rowData[column.field])}</div>
      }
      if (column.field === 'data') {
        return <div>{window.moment(Number(rowData.data)).format('DD/MM/YYYY HH:mm:ss')}</div>
      }
      if (column.field === 'valor' || column.field === 'valorBruto' || column.field === 'valorPago') {
        return <div>R$ {utils.formatCurrency(rowData[column.field])}</div>
      }
      if (column.field === 'oid') {
        return <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oid}`}>{rowData.oid}</Link>
      }
      if (column.field === 'status' && utils.nonNull(rowData[column.field])) {
        return <div>{utils.getValue(enums.StatusLancamento, rowData[column.field])}</div>
      }
      if (column.field === 'oidContratoDestino') {
        if (rowData[column.field]) {
          return (
            <Link
              to={`${routes.PATH_CONTRATO}/${rowData[column.field]}`}
              onClick={(e) => {
                this.abrirContrato(rowData[column.field])
                this.props.showForm('falecido', false)
              }}
            >
              {rowData[column.field]}
            </Link>
          )
        }
        return <div>{_.get(rowData['localizacaoDestino'], 'value', '')}</div>
      }
    }

    let colunasTResponsavel = this.props.colunasTResponsavel.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actionResponsavel}
            alignHeader={'center'}
            style={{ width: '20em', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'rowReorder') {
        return <Column rowReorder={this.state.acaoOrdenarResponsavel} style={{ width: '3em' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'left', width: '20em' }} />
    })

    let colunasTDocumentos = this.props.colunasTDocumentos
      .filter((col) => col.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        if (col.field === 'nome') {
          return (
            <Column
              {...col}
              key={col.field}
              bodyClassName="column-nome-body-width"
              headerClassName="column-nome-header-width"
            />
          )
        }
        if (col.field === 'statusDocumento' && this.utilizaAssinaturaDigitalDocumentos) {
          return (
            <Column
              {...col}
              key={col.field}
              body={resolveBodyStatusColumn}
              bodyClassName="column-status-document-body-width"
              filterHeaderClassName="column-status-document-header-width"
              headerClassName="column-status-document-header-width"
            />
          )
        }
        if (col.field === 'acoes') {
          return (
            <Column
              {...col}
              key={col.field}
              body={actionDocumento}
              alignHeader={'center'}
              style={{ width: '20em', textAlign: 'center' }}
            />
          )
        }
        return null
      })

    let colunasTFalecido = this.props.colunasTFalecido.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actionFalecido}
            alignHeader={'center'}
            style={{ width: '12em', overflow: 'visible' }}
          />
        )
      }
      if (
        col.field === 'dataNascimento' ||
        col.field === 'dataFalecimento' ||
        col.field === 'dataExumacao' ||
        col.field === 'dataTranslado'
      ) {
        return <Column {...col} key={col.field} body={columnData} style={{ width: '8.5em' }} />
      }
      if (col.field === 'oidContratoDestino') {
        return <Column {...col} key={col.field} body={columnData} style={{ textAlign: 'center', width: '10em' }} />
      }
      if (col.field === 'rowReorder') {
        return <Column rowReorder={true} style={{ width: '1.5em' }} />
      }
      if (col.field === 'falecido') {
        return <Column {...col} key={col.field} style={{ width: '15em' }} />
      }
      if (col.field === 'numeroGaveta') {
        return <Column {...col} key={col.field} style={{ textAlign: 'left', width: '10em' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'center', width: '15em' }} />
    })

    const colunasTDependente = this.props.colunasTDependente.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actionDependente}
            alignHeader={'center'}
            style={{ textAlign: 'center', width: '15em' }}
          />
        )
      }
      if (col.field === 'dataNascimento') {
        return <Column {...col} key={col.field} body={columnData} style={{ width: '8.5em' }} />
      }
      if (col.field === 'parentesco') {
        return <Column {...col} key={col.field} body={columnParentesco} style={{ width: '10em' }} />
      }
      return <Column {...col} key={col.field} style={{ width: '15em' }} />
    })
    const colunasTHistorico = this.props.colunasTHistorico.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'data' || col.field === 'lembrete') {
        return <Column {...col} key={col.field} body={columnData} style={{ width: '7em' }} />
      } else if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actionHistorico}
            alignHeader={'center'}
            style={{ width: '4em', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            style={{ width: '15em', 'word-break': 'break-word', 'white-space': 'pre-wrap' }}
          />
        )
      } else if (col.field === 'valor') {
        return <Column {...col} key={col.field} body={columnData} style={{ width: '5.5em' }} />
      } else if (col.field === 'tag') {
        return <Column {...col} key={col.field} style={{ width: '7em' }} />
      }
      return <Column {...col} key={col.field} style={{ textAlign: 'left', width: '5em' }} />
    })

    let statusFilter = (
      <Dropdown
        style={{ width: '100%' }}
        className="ui-column-filter"
        value={this.state.brand}
        options={enums.ArrayStatusLancamento}
        onChange={this.onStatusFilterChange}
        optionLabel="value"
        optionValue={(v) => v}
      />
    )

    const colunasTLancamentoFinanceiro = this.props.colunasTLancamentoFinanceiro.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            sortField={col.columnSortField}
            filterMatchMode="contains"
            style={{ width: '4.5em' }}
          />
        )
      }
      if (col.field === 'valor' || col.field === 'valorBruto' || col.field === 'valorPago') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            sortField={col.columnSortField}
            filterMatchMode="contains"
            style={{ width: '6em' }}
          />
        )
      }
      if (col.field === 'emissao' || col.field === 'vencimento' || col.field === 'dataPagamento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            filterElement={this.fieldCalendarFilter(col)}
            sortField={col.columnSortField}
            style={{ width: '6em' }}
          />
        )
      }
      if (col.field === 'status') {
        return (
          <Column
            {...col}
            key={col.field}
            body={columnData}
            filter={true}
            field={'status'}
            sortField={col.columnSortField}
            style={{ textAlign: 'center', width: '6em' }}
            filterElement={statusFilter}
          />
        )
      }
      if (col.field === 'tipoFormaPagamento.value') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '8.5em' }}
          />
        )
      }
      if (col.field === 'observacao' || col.field === 'grupoFinanceiro') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filter={true}
            filterMatchMode="contains"
            bodyClassName="column-observacao-body-width"
            filterHeaderClassName="column-observacao-filter-header-width"
            headerClassName="column-observacao-header-width"
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column {...col} key={col.field} body={actionsLancamentos} style={{ width: '8em', textAlign: 'center' }} />
        )
      }
      return (
        <Column
          {...col}
          key={col.field}
          sortField={col.columnSortField}
          filter={true}
          filterMatchMode="contains"
          style={{ textAlign: 'center' }}
        />
      )
    })

    const content = (
      <div>
        {this.getDialogDadosLapide()}
        {this.getDialogDadosBaixa()}
        {this.getDialogDadosBoletoIndividual()}
        {this.getModalBaixarLancamento()}
        {this.getDialogFormResponsavel()}
        {this.getDialogViewGavetas()}
        {this.getDialogConfirmarExclusaoFalecido()}
        {this.getDialogConfirmarExclusaoResponsavel()}
        {this.getDialogConfirmarExclusaoDependente()}
        {this.getDialogConfirmarEstorno()}
        {this.getDialogConfirmarExclusaoDocumento()}
        {this.getDialogConfirmarAssinarDocumento()}
        {this.getDialogHistorico()}
        <ContratoForm
          updateDocumentos={this.updateDocumentos}
          setField={this.setField}
          state={this.state}
          changeActiveIndex={this.changeActiveIndex}
          showForm={this.props.showForm}
          displayForm={this.props.displayForm}
          clearForm={this.props.clearForm}
          anexos={this.props.record.anexos}
          onSelectAnexo={this.props.addAnexo}
          onSelectAnexoFalecido={this.props.adicaoAnexoFalecido}
          downloadAnexo={this.props.downloadAnexo}
          downloadAnexoFalecido={this.props.downloadAnexoFalecido}
          removeAnexo={this.props.removeAnexo}
          removeAnexoFalecido={this.props.removeAnexoFalecido}
          showPageLancamentoFinanceiro={this.showPageLancamentoFinanceiro}
          tags={this.props.tags}
          causasMortis={this.props.causasMortis}
          tiposSepultura={this.props.tiposSepultura}
          onChangeUnit={this.onChangeUnit}
          currentUser={this.props.currentUser}
          disableUnit={this.props.disableUnit}
          messages={this.props.messages}
          changeHandler={this.props.changeHandler}
          onChangeTipoContrato={this.onChangeTipoContrato}
          changeOrderList={this.props.changeOrderList}
          showFormFalecido={this.showFormFalecido}
          onComplete={this.props.onComplete}
          record={this.props.record}
          unidades={this.props.unidades}
          acaoOrdenarResponsavel={this.state.acaoOrdenarResponsavel}
          dataTableLancamentos={(el) => (this.dataTableLancamentos = el)}
          onSelectionChange={this.onSelectionChange}
          selectedLancamentos={this.props.selectedLancamentos}
          colunasTLancamentoFinanceiro={colunasTLancamentoFinanceiro}
          colunasTResponsavel={colunasTResponsavel}
          colunasTDocumentos={colunasTDocumentos}
          colunasTFalecido={colunasTFalecido}
          colunasTDependente={colunasTDependente}
          colunasTHistorico={colunasTHistorico}
          falecidos={this.props.falecidos}
          falecido={this.props.falecido}
          dependentes={this.props.dependentes}
          dependente={this.props.dependente}
          adicionarDependente={this.props.adicionarDependente}
          adicionarDependenteCadastrado={this.props.adicionarDependenteCadastrado}
          editarDadoDependente={this.props.editarDadoDependente}
          searchTag={this.props.searchTag}
          searchCausaMortis={this.props.searchCausaMortis}
          responsaveis={this.props.responsaveis}
          responsavel={this.props.responsavel}
          documentos={this.props.documentos}
          documentoTempLookup={this.props.documentoTempLookup}
          historicos={this.props.historicos}
          historico={this.props.historico}
          adicionarHistorico={this.props.adicionarHistorico}
          changeHandlerHistorico={this.props.changeHandlerHistorico}
          changeHandlerMarcadorHistorico={this.props.changeHandlerMarcadorHistorico}
          changeHandlerPessoaHistorico={this.props.changeHandlerPessoaHistorico}
          changeHandlerValorHistorico={this.props.changeHandlerValorHistorico}
          conservacaoTipos={this.props.conservacaoTipos}
          adicionarFalecido={this.adicionarFalecido}
          onBlurPeso={this.onBlurPeso}
          onBlurAltura={this.onBlurAltura}
          editarDadoFalecido={this.props.editarDadoFalecido}
          history={this.props.history}
          ACAO_EDITAR_CONSPAGA={this.state.ACAO_EDITAR_CONSPAGA}
          ACAO_EDITAR_DATA_EXUMACAO={this.state.ACAO_EDITAR_DATA_EXUMACAO}
          //-----
          lookupContratoCremacaoFutura={this.props.lookupContratoCremacaoFutura}
          lookupContratoCremacaoFuturaOnKeyPress={this.lookupContratoCremacaoFuturaOnKeyPress}
          lookupContratoCremacaoFuturaOnHide={this.lookupContratoCremacaoFuturaOnHide}
          lookupContratoCremacaoFuturaOnConfirm={this.lookupContratoCremacaoFuturaOnConfirm}
          lookupContratoCremacaoFuturaOnCancel={this.lookupContratoCremacaoFuturaOnCancel}
          lookupContratoCremacaoFuturaOnPage={this.lookupContratoCremacaoFuturaOnPage}
          lookupContratoCremacaoFuturaOnSort={this.lookupContratoCremacaoFuturaOnSort}
          lookupContratoCremacaoFuturaOnFilter={this.lookupContratoCremacaoFuturaOnFilter}
          lookupContratoCremacaoFuturaOnClick={this.lookupContratoCremacaoFuturaOnClick}
          lookupContratoCremacaoFuturaOnEdit={this.lookupContratoCremacaoFuturaOnEdit}
          lookupContratoCremacaoFuturaOnComplete={this.lookupContratoCremacaoFuturaOnComplete}
          lookupContratoCremacaoFuturaOnInputFilter={this.lookupContratoCremacaoFuturaOnInputFilter}
          //--
          lookupLocalizacao={this.props.lookupLocalizacao}
          onChangeUnitLocalizacao={this.onChangeUnitLocalizacao}
          onChangeApenasLocalizacaoTemporaria={this.onChangeApenasLocalizacaoTemporaria}
          lookupLocalizacaoOnKeyPress={this.lookupLocalizacaoOnKeyPress}
          lookupLocalizacaoOnHide={this.lookupLocalizacaoOnHide}
          lookupLocalizacaoOnConfirm={this.lookupLocalizacaoOnConfirm}
          lookupLocalizacaoOnCancel={this.lookupLocalizacaoOnCancel}
          lookupLocalizacaoOnPage={this.lookupLocalizacaoOnPage}
          lookupLocalizacaoOnSort={this.lookupLocalizacaoOnSort}
          lookupLocalizacaoOnFilter={this.lookupLocalizacaoOnFilter}
          lookupLocalizacaoOnClick={this.lookupLocalizacaoOnClick}
          lookupLocalizacaoOnEdit={this.lookupLocalizacaoOnEdit}
          lookupLocalizacaoOnComplete={this.lookupLocalizacaoOnComplete}
          lookupLocalizacaoOnInputFilter={this.lookupLocalizacaoOnInputFilter}
          //--
          lookupLocalizacaoDestino={this.props.lookupLocalizacaoDestino}
          onChangeApenasLocalizacaoDestinoTemporaria={this.onChangeApenasLocalizacaoDestinoTemporaria}
          lookupLocalizacaoDestinoOnClick={this.lookupLocalizacaoDestinoOnClick}
          lookupLocalizacaoDestinoOnCancel={this.lookupLocalizacaoDestinoOnCancel}
          lookupLocalizacaoDestinoOnHide={this.lookupLocalizacaoDestinoOnCancel}
          lookupLocalizacaoDestinoOnConfirm={this.lookupLocalizacaoDestinoOnConfirm}
          lookupLocalizacaoDestinoOnInputFilter={this.lookupLocalizacaoDestinoOnInputFilter}
          lookupLocalizacaoDestinoOnKeyPress={this.lookupLocalizacaoDestinoOnKeyPress}
          lookupLocalizacaoDestinoOnEdit={this.lookupLocalizacaoDestinoOnEdit}
          //--
          lookupResponsavelCremacao={this.props.lookupResponsavelCremacao}
          lookupResponsavelCremacaoOnKeyPress={this.lookupResponsavelCremacaoOnKeyPress}
          lookupResponsavelCremacaoOnHide={this.lookupResponsavelCremacaoOnHide}
          lookupResponsavelCremacaoOnConfirm={this.lookupResponsavelCremacaoOnConfirm}
          lookupResponsavelCremacaoOnCancel={this.lookupResponsavelCremacaoOnCancel}
          lookupResponsavelCremacaoOnPage={this.lookupResponsavelOnPage}
          lookupResponsavelCremacaoOnSort={this.lookupResponsavelOnSort}
          lookupResponsavelCremacaoOnFilter={this.lookupResponsavelOnFilter}
          lookupResponsavelCremacaoOnClick={this.lookupResponsavelCremacaoOnClick}
          lookupResponsavelCremacaoOnEdit={this.lookupResponsavelOnEdit}
          lookupResponsavelCremacaoOnComplete={this.lookupResponsavelOnComplete}
          lookupResponsavelCremacaoOnInputFilter={this.lookupResponsavelCremacaoOnInputFilter}
          //--
          // lookupResponsavel={this.props.lookupResponsavel}
          // lookupResponsavelOnKeyPress={this.lookupResponsavelOnKeyPress}
          // lookupResponsavelOnHide={this.lookupResponsavelOnHide}
          // lookupResponsavelOnConfirm={this.lookupResponsavelOnConfirm}
          // lookupResponsavelOnCancel={this.lookupResponsavelOnCancel}
          // lookupResponsavelOnPage={this.lookupResponsavelOnPage}
          // lookupResponsavelOnSort={this.lookupResponsavelOnSort}
          // lookupResponsavelOnFilter={this.lookupResponsavelOnFilter}
          // lookupResponsavelOnClick={this.lookupResponsavelOnClick}
          // lookupResponsavelOnEdit={this.lookupResponsavelOnEdit}
          // lookupResponsavelOnComplete={this.lookupResponsavelOnComplete}
          // lookupResponsavelOnInputFilter={this.lookupResponsavelOnInputFilter}
          //--
          lookupDependente={this.props.lookupDependente}
          lookupDependenteOnKeyPress={this.lookupDependenteOnKeyPress}
          lookupDependenteOnHide={this.lookupDependenteOnHide}
          lookupDependenteOnConfirm={this.lookupDependenteOnConfirm}
          lookupDependenteOnCancel={this.lookupDependenteOnCancel}
          lookupDependenteOnPage={this.lookupDependenteOnPage}
          lookupDependenteOnSort={this.lookupDependenteOnSort}
          lookupDependenteOnFilter={this.lookupDependenteOnFilter}
          lookupDependenteOnClick={this.lookupDependenteOnClick}
          lookupDependenteOnEdit={this.lookupDependenteOnEdit}
          lookupDependenteOnComplete={this.lookupDependenteOnComplete}
          lookupDependenteOnInputFilter={this.lookupDependenteOnInputFilter}
          //--
          abrirContrato={this.abrirContrato}
          onClickCriarContrato={this.onClickCriarContrato}
          isContratoCremacaoFutura={this.props.isContratoCremacaoFutura}
          localizacaoSepultamentoInvalida={this.state.localizacaoSepultamentoInvalida}
          isLocalizacaoSepultamentoInvalida={this.isLocalizacaoSepultamentoInvalida}
          //--
          disableBotaoBoleto={this.disableBotaoBoleto}
          verificarGeracaoBoletosIndividuais={this.verificarGeracaoBoletosIndividuais}
          gerarCarne={this.gerarCarne}
          // LOOKUP DOCUMENTO
          lookupDocumento={this.props.lookupDocumento}
          lookupDocumentoOnKeyPress={this.lookupDocumentoOnKeyPress}
          lookupDocumentoOnHide={this.lookupDocumentoOnHide}
          lookupDocumentoOnConfirm={this.lookupDocumentoOnConfirm}
          lookupDocumentoOnCancel={this.lookupDocumentoOnCancel}
          lookupDocumentoOnPage={this.lookupDocumentoOnPage}
          lookupDocumentoOnSort={this.lookupDocumentoOnSort}
          lookupDocumentoOnFilter={this.lookupDocumentoOnFilter}
          lookupDocumentoOnClick={this.lookupDocumentoOnClick}
          lookupDocumentoOnEdit={this.lookupDocumentoOnEdit}
          lookupDocumentoOnComplete={this.lookupDocumentoOnComplete}
          lookupDocumentoOnInputFilter={this.lookupDocumentoOnInputFilter}
          utilizaAssinaturaDigitalDocumentos={this.utilizaAssinaturaDigitalDocumentos}
          toastRef={this.toastRef}
          statePessoaFisica={this.props.statePessoaFisica}
          statePessoaJuridica={this.props.statePessoaJuridica}
          setResponsavel={this.props.setResponsavel}
          salvarNovaCausaMortis={this.salvarNovaCausaMortis}
          descricaoNovaCausaMortis={this.descricaoNovaCausaMortis}
          setDescricaoNovaCausaMortis={this.setDescricaoNovaCausaMortis}
          isTipoContratoFuneral={this.props.isTipoContratoFuneral}
          isTipoContratoColumbario={this.props.isTipoContratoColumbario}
          isTipoContratoDependente={this.props.isTipoContratoDependente}
          isTipoContratoPlano={this.props.isTipoSepulturaPlano}
          isTipoContratoTitulo={this.props.isTipoSepulturaTitulo}
          isTipoContratoPet={this.props.isTipoSepulturaPet}
          isTipoContratoFalecido={this.props.isTipoSepulturaFalecido}
          isTipoContratoCremacao={this.props.isTipoSepulturaCremacao}
          setEpis={this.setEpis}
          exibirConspaga={this.props.exibirConspaga}
          exibirLocalizacaoCemiterio={this.props.exibirLocalizacaoCemiterio}
          agruparLancamentos={this.props.agruparLancamentos}
          gerarDebitoAutomatico={this.props.gerarDebitoAutomatico}
          cancelarLancamentos={this.props.cancelarLancamentos}
          showWarningMessage={this.showWarningMessage}
          onClickBtnBaixarLancamentos={this.onClickBtnBaixarLancamentos}
          setMarcadores={this.setMarcadores}
          stateMarcador={this.props.stateMarcador}
          gerandoBoleto={this.props.gerandoBoleto}
          adicionandoAnexo={this.props.adicionandoAnexo}
          integracaoAdiauHabilitada={this.props.integracaoAdiauHabilitada}
          contratoFalecidoCamposComplementares={this.props.contratoFalecidoCamposComplementares}
          camerasAdiauDisponiveis={this.props.camerasAdiauDisponiveis}
          tipoSepulturaDestino={this.props.tipoSepulturaDestino}
          podeCriarCoberturaPlano={this.state.CRIAR_COBERTURA_PLANO}
          podeAlterarCoberturaPlano={this.state.ALTERAR_COBERTURA_PLANO}
          podeRemoverCoberturaPlano={this.state.REMOVER_COBERTURA_PLANO}
          diasPadraoCarenciaCoberturaPlano={this.props.diasPadraoCarenciaCoberturaPlano}
        />
      </div>
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContratoFormPageContainer)
