import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import { messageActions } from "../../store/message/index";
import apiService from "../../services/api.service";

const BASE_PATH = "/lottustech/loteboletos";
const crud = build(validate, BASE_PATH);
const prefix = "crudLoteBoletos";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE: `${prefix}setLookupConfiguracaoBoletoVisible`,
  SET_LOOKUP_CONTRATO_VISIBLE: `${prefix}setLookupContratoVisible`,
  SET_LOOKUP_TAXA_VISIBLE: `${prefix}setLookupTaxaVisible`,
  SET_CONFIGURACAO_BOLETO: `${prefix}setConfiguracaoBoleto`,
  SHOW_ERROS: `${prefix}showErros`,
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.setLookupTaxaVisible = visible => {
  return {
    type: types.SET_LOOKUP_TAXA_VISIBLE,
    visible
  };
};

actions.setLookupContratoVisible = visible => {
  return {
    type: types.SET_LOOKUP_CONTRATO_VISIBLE,
    visible
  };
};

actions.setLookupConfiguracaoBoletoVisible = visible => {
  return {
    type: types.SET_LOOKUP_CONFIGURACAO_BOLETO_VISIBLE,
    visible
  };
};

actions.setConfiguracaoBoleto = payload => {
  return {
    type: types.SET_CONFIGURACAO_BOLETO,
    payload
  };
};

actions.showErros = payload => {
  return {
    type: types.SHOW_ERROS,
    payload
  };
};

actions.gerarBoletos = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLoteBoletosState`];
    const record = state.currentRecord;
    const param = {
      oidConfiguracao: record.configuracaoBoleto.id,
      mesVencimento: record.vencimento,
      oidUnidade: record.unidade && record.unidade.id ? record.unidade.id : null,
      oidTaxa: record.taxa && record.taxa.id ? record.taxa.id : null,
      oidContrato: record.contrato && record.contrato.id ? record.contrato.id : null,
      periodoInicial: record.periodoInicial,
      periodoFinal: record.periodoFinal
    };
    const path = `/lottustech/ailos/gerar/boleto/lote`;
    return apiService.post(path, param)
    .then(response => {
      dispatch(actions.showErros(response.data));
    }).catch(err => {
      const messages = err.response.data;
      dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
    });;
  };
};

actions.loadConfiguracaoBoletoPadrao = oid => {
  return (dispatch, getState) => {
    const path = `/lottustech/configuracoesBoletos/${oid}`;
    return apiService.get(path).then(response => {
      dispatch(actions.setConfiguracaoBoleto(response.data));
    });
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  }
  return messages.map(message => {
    return { sticky: sticky, summary: summary, severity: type, detail: message };
  });
};

export { types, actions };
