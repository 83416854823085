import React from 'react';
import TaxaListPageContainer from './TaxaListPageContainer';

export class TaxaListPage extends React.Component{
    
    render(){
      return (
        <TaxaListPageContainer {...this.props}/>
      )
    }
}

