import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SplitButton } from "primereact/splitbutton";
import storage from "../../services/stoage.service";
import logo_slim from "../../assets/images/logo/phoenix_logo_90x90.png";
import { AutoComplete } from "primereact/autocomplete";
import utils from "../../utils/index";

export class AppTopbar extends Component {
  static defaultProps = {
    activeTopbarItem: null,
    topbarMenuActive: null,
    onMenuButtonClick: null,
    onTopbarItemClick: null,
    onTopbarMobileMenuButtonClick: null,
    layoutMode: "overlay"
  };

  static propTypes = {
    activeTopbarItem: PropTypes.string,
    topbarMenuActive: PropTypes.bool,
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    onTopbarMobileMenuButtonClick: PropTypes.func.isRequired,
    layoutMode: PropTypes.string
  };

  constructor() {
    super();
    this.state = {};
  }

  // getListItemForConfiguracaoAilos() {
  //   if (this.props.portalCliente) {
  //     return null;
  //   }
  //
  //   return (
  //     <li role="menuitem">
  //       <button
  //         className="p-link"
  //         onClick={e => this.onTopbarItemClick(e, "configurarAilos")}
  //       >
  //         <i className="material-icons">assignment_ind</i>
  //         <span>Config. Integração Ailos</span>
  //       </button>
  //     </li>
  //   );
  // }

  getBoasVindas() {
    if (this.props.portalCliente) {
      return (
        <li
          style={{ float: "left" }}          
        >
          <span>
            <h2 style={{marginTop: "0px", color:"#FFFFFF"}}>Bem vindo(a) {storage.getNomeUsuario()}</h2>
          </span>
        </li>
      );
    }
    return null;
  }

  getListItemForMenuSearch() {
    if (this.props.portalCliente) {
      return null;
    }

    return (
      <li
        style={{ float: "left" }}
        className={classNames("search-item", {
          "active-topmenuitem": this.props.activeTopbarItem === "search"
        })}
        onClick={e => this.onTopbarItemClick(e, "search")}
      >
        <span className="md-inputfield">
          <AutoComplete
            placeholder={"Pesquisar"}
            autoFocus={true}
            inputStyle={{ width: "25em" }}
            value={this.state.searchText}
            onChange={e => this.setState({ searchText: e.value })}
            completeMethod={this.props.onSearchMenu}
            suggestions={this.props.searchOptions}
            field="labelBuscaRapida"
            onSelect={e => this.onSelectBuscaRapida(e)}
            onKeyPress={e => this.onKeyPress(e)}
          />
        </span>
      </li>
    );
  }

  getListItemForEmpresaUnidade(
    nameCurrentUnit,
    nameCurrentCompany,
    companies,
    alteraUnidade
  ) {
    if (this.props.portalCliente) {
      return null;
    }
    const usuarioComUnicaUnidade = storage.getUsuarioUnidadeUnica();
    return (
      <li
        className={classNames({
          "active-topmenuitem": this.props.activeTopbarItem === "company_unit"
        })}
      >
        <SplitButton
          label={nameCurrentCompany ? nameCurrentCompany.label : "Empresa"}
          model={companies}
          onClick={this.props.onChangeCompany}
          disabled={usuarioComUnicaUnidade}
        />
        <SplitButton
          disabled={
            !this.props.units ||
            this.props.units.length === 0 ||
            usuarioComUnicaUnidade ||
            !alteraUnidade
          }
          label={nameCurrentUnit ? nameCurrentUnit.label : "Unidade"}
          model={this.props.units}
          onChange={this.props.onChangeUnit}
        />
      </li>
    );
  }

  onTopbarItemClick(event, item) {
    if (this.props.onTopbarItemClick) {
      this.props.onTopbarItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  onSelectBuscaRapida(e) {
    this.setState({ searchText: "" });
    this.props.onSelectBuscaRapida(e);
  }

  onKeyPress(e) {
    // if (e.key === "Enter") {
    //   const options = this.props.searchOptions;
    //   if (!utils.isArrayNullOrEmpty(options)) {
    //     const menu = options[0];
    //     e.value = menu;
    //     this.onSelectBuscaRapida(e);
    //   }
    // }
  }

  render() {
    let alteraUnidade = storage.getAlteraUnidade();
    const nameCurrentCompany = this.props.companies.find(
      company => company.value === this.props.companyOid
    );
    let nameCurrentUnit;
    if (this.props.units) {
      nameCurrentUnit = this.props.units.find(
        unit => unit.value === this.props.unitOid
      );
    }

    let topbarClass = classNames("topbar-menu fadeInDown", {
      "topbar-menu-active": this.props.topbarMenuActive
    });
    let horizontalIcon = this.props.layoutMode === "horizontal" && (
      <button className="p-link topbar-logo">
        <img alt="logo" src={logo_slim} />
        <span className="app-name">PHOENIX</span>
      </button>
    );
    let companies = removeDuplicated(this.props.companies, "value");

    function removeDuplicated(arr, key) {
      const map = new Map();
      arr.map(el => {
        if (!map.has(el[key])) {
          map.set(el[key], el);
        }
      });
      return [...map.values()];
    }

    return (
      <div className="layout-topbar">
        {horizontalIcon}
        <img alt="logo" src={logo_slim} className="mobile-logo" />

        <button
          className="p-link menu-btn"
          onClick={this.props.onMenuButtonClick}
        >
          <i className="pi pi-bars"/>
        </button>

        <button
          className="p-link topbar-menu-btn"
          onClick={this.props.onTopbarMobileMenuButtonClick}
        >
          <i className="pi pi-user"/>
        </button>

        <div className="layout-topbar-menu-wrapper">
          <ul className={topbarClass}>
            <li
              className={classNames({
                "active-topmenuitem": this.props.activeTopbarItem === "settings"
              })}
            >
              <button
                  type={"button"}
                  className="p-link"
                  onClick={e => this.onTopbarItemClick(e, "settings")}
              >
                <i className="pi pi-user"/>
                <span className="topbar-item-name">Settings</span>
              </button>
              <ul className="fadeInDown" style={{overflow: "visible"}}>
                <li role="menuitem">
                  <div className="card">
                    <div>
                      <p>Login: {storage.getUser()}</p>
                    </div>
                    <div>
                      <p>Usuário: {storage.getNomeUsuario()}</p>
                    </div>
                    <div>
                      <p>Versão:{storage.getVersaoSistema()}</p>
                    </div>
                    <div>
                      <p>Data: {utils.formatarData(new Date())}</p>
                    </div>
                  </div>
                </li>
                {/*{this.getListItemForConfiguracaoAilos()}*/}
                <li role="menuitem">
                  <button
                    className="p-link"
                    onClick={e => this.onTopbarItemClick(e, "logout")}
                  >
                    <i className="pi pi-sign-out"/>
                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </li>
            {this.getListItemForEmpresaUnidade(
              nameCurrentUnit,
              nameCurrentCompany,
              companies,
              alteraUnidade
            )}
            {this.getListItemForMenuSearch()}
            {this.getBoasVindas()}
            {/* <li>
              <span>                
                <p style={{color: "white", marginTop: "10px"}}>versão: 1.0.10</p>
              </span>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}
