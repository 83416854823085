import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { actions as grupoActions } from '../grupo/grupo.actions'
import apiService from '../../services/api.service'
import utils from '../../utils'

const BASE_PATH = '/lottustech/taxas'
const crud = build(validate, BASE_PATH)
const prefix = 'crudTaxa'

const newTypes = {
  SET_LOOKUP_TAXA_VISIBLE: `${prefix}setLookupTaxaVisible`,
  SET_LOOKUP_PACOTE_TAXA_VISIBLE: `${prefix}setLookupPacoteTaxaVisible`,
  SET_LOOKUP_GRUPO_VISIBLE: `${prefix}setLookupGrupoVisible`,
  GET_TAXA_CONSERVACAO: `${prefix}getTaxaConservacao`,
  SET_TAXA_CONSERVACAO: `${prefix}setTaxaConservacao`,
  SET_PACOTE_TAXAS: `${prefix}setPacoteTaxas`,
  RESET: `${prefix}reset`,
  SET_CONTAS_CORRENTES: `${prefix}setContasCorrentes`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.setLookupGrupoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_GRUPO_VISIBLE,
    visible,
  }
}

actions.setTaxaConservacao = (payload) => {
  return {
    type: types.SET_TAXA_CONSERVACAO,
    payload,
  }
}
actions.setLookupTaxaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_TAXA_VISIBLE,
    visible,
  }
}
actions.setLookupPacoteTaxaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_PACOTE_TAXA_VISIBLE,
    visible,
  }
}

actions.setContasCorrentes = (payload) => {
  return {
    type: types.SET_CONTAS_CORRENTES,
    payload,
  }
}

actions.aplicarFiltrosDadosGrupo = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudTaxaState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(grupoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }

    if (record.unidade && record.unidade.id) {
      const filtro = {
        campo: 'oid',
        valor: record.unidade.id,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['unidade'],
      }
      dispatch(grupoActions.setFiltroAvancado(filtro))
    }
    dispatch(grupoActions.loadLookup())
  }
}

actions.setPacoteTaxas = (pacoteTaxas) => {
  return {
    type: types.SET_PACOTE_TAXAS,
    payload: pacoteTaxas,
  }
}

actions.loadTaxasValidas = (paginationParams, oidUnidade) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    if (paginationParams && paginationParams.max) {
      dispatch(actions.setIndex(paginationParams.index))
      dispatch(actions.setMax(paginationParams.max))
    } else if (paginationParams && paginationParams.sortField) {
      dispatch(actions.setSortField(paginationParams.sortField))
      dispatch(actions.setSortOrder(paginationParams.sortOrder))
    }

    let sortFields = []
    if (paginationParams && paginationParams.sortField) {
      sortFields = [
        {
          fieldName: paginationParams.sortField,
          order: paginationParams.sortOrder > -1 ? `ASC` : `DESC`,
        },
      ]
    } else if (state.sortField) {
      sortFields = [
        {
          fieldName: state.sortField,
          order: state.sortOrder > -1 ? `ASC` : `DESC`,
        },
      ]
    }
    const stateColumns = state.columnsLookup || []
    const columns = stateColumns.map((col) => col.columnKey)
    columns.push('oid')

    if (oidUnidade) {
      let tipoFiltro = 'IGUAL_A'
      if (Array.isArray(oidUnidade)) {
        tipoFiltro = 'ESTA_CONTIDO_EM'
      }
      const filtro = {
        campo: 'oidUnidade',
        valor: oidUnidade,
        tipoFiltro: tipoFiltro,
        fieldJoin: 'oidUnidade',
        relacionamento: ['unidade'],
      }
      dispatch(actions.setFiltroAvancado(filtro))
    }

    const filters = utils.parseTipoFiltro(state.selectedFilters)
    const params = {
      index: paginationParams ? paginationParams.index : 0,
      max: paginationParams ? paginationParams.max : 15,
      filters: filters,
      projectionFields: columns,
      sortFields,
    }
    apiService.post(`${BASE_PATH}/validas`, params).then((response) => {
      let dados = response.data
      dispatch(actions.setRecords(dados.records))
      dispatch(actions.setCount(dados.count))
    })
  }
}
export { types, actions }
