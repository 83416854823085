import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { Page } from '../../../components/page/Page'
import { routes, enums, icons } from '../../../constants'
import utils from '../../../utils/index'
import { actions } from '../consultaRemessa.actions'
import { ConsultaRemessaForm } from './ConsultaRemessaForm'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import _ from 'lodash'
import '../../../index.scss'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudConsultaRemessaState.currentRecord
  return {
    colunasItens: state.crudConsultaRemessaState.colunasItens,
    colunasLogs: state.crudConsultaRemessaState.colunasLogs,
    messages: state.crudConsultaRemessaState.messages,
    record,
    first: state.crudConsultaRemessaState.index,
    rows: state.crudConsultaRemessaState.max,
    totalRecords: state.crudConsultaRemessaState.count,
    sortField: state.crudConsultaRemessaState.sortField,
    order: state.crudConsultaRemessaState.sortOrder,
    selectedFilters: state.crudConsultaRemessaState.selectedFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadById: (oid) => dispatch(actions.loadById(oid)),
    unmount: () => dispatch(actions.unmount()),
    imprimirBoletos: (itens) => {
      dispatch(actions.imprimirBoletos(itens))
    },
    sincronizarRemessa: (oidRemessa) => {
      dispatch(actions.sincronizarRemessa(oidRemessa))
    },
    downloadArquivo: (oidRemessa) => {
      dispatch(actions.downloadArquivo(oidRemessa))
    },
  }
}

class ConsultaRemessaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableItens = React.createRef()
    this.dataTableLogs = React.createRef()
    this.state = {
      selectedItens: [],
      activeIndex: 0,
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    }
  }

  componentWillUnmount() {
    this.props.unmount()
  }

  hideBotaoRemover = () => {
    return true
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CONSULTA_REMESSA}`)
  }

  onSelectionChange = (e) => {
    this.setState({ selectedItens: e.value })
  }

  // imprimirBoletos = () => {
  //     const itens = this.state.selectedItens
  //         .filter(f => f.status === 1)
  //         .map(i => i.oidLancamentoFinanceiro);
  //     if (utils.isArrayNotEmpty(itens)) {
  //         this.props.imprimirBoletos(itens);
  //     }
  // };
  // onRowSelect = e => {
  //     this.setState({
  //         displayDialog: true,
  //         item: Object.assign({}, e.data)
  //     });
  // };

  sincronzarRemessa = () => {
    this.props.sincronizarRemessa(this.props.record.oid)
  }

  downloadArquivo = () => {
    this.props.downloadArquivo(this.props.record.oid)
  }

  getBotoesPersonalizados = () => {
    /**
     * tipo:
     *
     * 1: Ailos - Boleto
     * 2: Vindi - Boleto
     * 3: Vindi - Cartao Credito
     * 4: Celesc
     */

    const botoes = []
    const tipo = _.get(this, 'props.record.tipo', -1)
    if (tipo !== 2 && tipo !== 3) {
      const botao = {
        type: 'button',
        label: 'Sincronzar',
        onClick: this.sincronzarRemessa,
      }
      botoes.push(botao)
    }

    if (tipo === 1 || tipo === 4 || tipo === 5 || tipo === 6 || tipo === 7) {
      // 4 - Celesc
      // 5 - Débito Automático
      // 6 - Cancelamento Débito Automático
      botoes.push({
        type: 'button',
        label: utils.getMessage(this.props.messages, 'remessa.label.download'),
        onClick: this.downloadArquivo,
        icon: icons.DOWNLOAD,
      })
    }

    return botoes
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  booleanFieldsTemplate = (rowData, column) => {
    let status = rowData[column.field]
    /**
     * 1 = SUCESSO
     * 2 = ERRO
     * 3 = PENDENTE
     * 4 = AGUARDANDO PAGAMENTO
     * 6 = APROVADA
     * 7 = CANCELADA
     * 24 = EM CONTESTACAO
     * 87 = EM MONITORAMENTO
     * 89 = REPROVADA
     */
    let color = 'grey'
    if (status === 1 || status === 6) {
      color = 'green'
    }
    if (status === 2 || status === 7 || status === 89) {
      color = 'red'
    }
    return (
      <div style={{ color: `${color}`, display: 'flex', justifyContent: 'center' }}>
        <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
      </div>
    )
  }

  onStatusFilterChange = (event) => {
    this.dataTableItens.filter(event.value.id, 'status', 'equals')
    this.setState({ brand: event.value })
  }

  buildDialog = () => {
    return (
      <Dialog
        visible={this.state.displayDialog}
        style={{ width: '35%' }}
        header="Mensagem"
        modal={true}
        onHide={() => this.setState({ displayDialog: false })}
        contentStyle={{ 'max-height': '350px' }}
      >
        <div className="p-grid p-fluid">
          <div className="p-col-12" style={{ padding: '.5em' }}>
            <InputTextarea
              id="value"
              rows={100}
              cols={100}
              style={{ 'max-height': '300px' }}
              autoResize={true}
              readOnly={true}
              value={utils.nonNull(this.state.item) ? this.state.item.mensagem : ''}
            />
          </div>
        </div>
      </Dialog>
    )
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  render() {
    let statusFilter = (
      <Dropdown
        style={{ width: '100%' }}
        className="ui-column-filter"
        value={this.state.brand}
        options={enums.ArrayStatus}
        onChange={this.onStatusFilterChange}
        optionLabel="value"
        optionValue={(v) => v}
      />
    )

    const columnData = (rowData, column) => {
      if (column.field === 'status' && utils.nonNull(rowData[column.field])) {
        return <div>{utils.getValue(enums.Status, rowData[column.field])}</div>
      } else if (column.field === 'oidLancamentoFinanceiro') {
        return (
          <Link to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oidLancamentoFinanceiro}`}>
            {rowData.oidLancamentoFinanceiro}
          </Link>
        )
      }
    }

    const colunasItens = this.props.colunasItens
      .filter((col) => col.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false

        if (col.field === 'multiselect') {
          return <Column selectionMode="multiple" style={{ width: '1em' }} />
        }
        if (col.field === 'oidLancamentoFinanceiro') {
          return (
            <Column
              {...col}
              key={col.field}
              body={columnData}
              filter={true}
              sortField={col.columnSortField}
              style={{ width: '5em', textAlign: 'center' }}
              filterMatchMode="contains"
            />
          )
        } else if (col.field === 'status') {
          return (
            <Column
              {...col}
              filterHeaderStyle={{ overflow: 'visible' }}
              key={col.field}
              body={this.booleanFieldsTemplate}
              filter={true}
              field={'status'}
              sortField={col.columnSortField}
              style={{ width: '5em', textAlign: 'center' }}
              filterElement={statusFilter}
            />
          )
        }
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '70%', textAlign: 'left' }}
          />
        )
      })

    const colunasLogs = this.props.colunasLogs
      .filter((col) => col.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        if (col.field === 'descricao') {
          return <Column {...col} key={col.field} filter={true} sortField={col.columnSortField} />
        }
        if (col.field === 'dataGravacao') {
          return (
            <Column
              {...col}
              key={col.field}
              body={this.formatColumn}
              sortField={col.columnSortField}
              bodyClassName="column-date-body-width"
              filterHeaderClassName="column-date-filter-header-width"
              headerClassName="column-date-header-width"
            />
          )
        }
        if (col.field === 'usuarioGravacao') {
          return (
            <Column
              {...col}
              key={col.field}
              filter={true}
              sortField={col.columnSortField}
              bodyClassName="column-date-body-width"
              filterHeaderClassName="column-date-filter-header-width"
              headerClassName="column-date-header-width"
            />
          )
        }
      })

    const content = (
      <div>
        {/*{this.buildDialog()}*/}
        <ConsultaRemessaForm
          selection={this.state.selectedItens}
          onSelectionChange={this.onSelectionChange}
          record={this.props.record}
          colunasItens={colunasItens}
          colunasLogs={colunasLogs}
          dataTableItens={(el) => (this.dataTableItens = el)}
          dataTableLogs={(el) => (this.dataTableLogs = el)}
          onRowSelect={this.onRowSelect}
          messages={this.props.messages}
          state={this.state}
          changeActiveIndex={this.changeActiveIndex}
        />
      </div>
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaRemessaFormPageContainer)
