import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
const BASE_PATH = "/lottustech/relatoriosdinamicos";
const crud = build(validate, BASE_PATH);
const prefix = "crudRelatorioDinamico";

const newTypes = {
  SET_LOOKUP_MENU_VISIBLE: `${prefix}setLookupMenuVisible`,
  SET_DISPLAY_FORM: `${prefix}setDisplayForm`,
  SET_RELACIONAMENTO: `${prefix}setRelacionamento`,
  ADICIONAR_RELACIONAMENTO: `${prefix}adicionarRelacionamento`,
  REMOVER_RELACIONAMENTO: `${prefix}removerRelacionamento`,
  CLEAR_FORM_RELACIONAMENTO: `${prefix}clearFormRelacionamento`,
  SET_CAMPO: `${prefix}setCampo`,
  ADICIONAR_CAMPO: `${prefix}adicionarCampo`,
  REMOVER_CAMPO: `${prefix}removerCampo`,
  CLEAR_FORM_CAMPO: `${prefix}clearFormCampo`,
  SET_FILTRO: `${prefix}setFiltro`,
  ADICIONAR_FILTRO: `${prefix}adicionarFiltro`,
  REMOVER_FILTRO: `${prefix}removerFiltro`,
  CLEAR_FORM_FILTRO: `${prefix}clearFormFiltro`,
  SET_ORDENACAO: `${prefix}setOrdenacao`,
  ADICIONAR_ORDENACAO: `${prefix}adicionarOrdenacao`,
  REMOVER_ORDENACAO: `${prefix}removerOrdenacao`,
  CLEAR_FORM_ORDENACAO: `${prefix}clearFormOrdenacao`,
  ON_CHANGE_PERFIS: `${prefix}onChangePerfis`,
  BEFORE_SAVE: `${prefix}beforeSave`,
  REMOVE_RELATORIO_DINAMICO: `${prefix}removeRelatorioDinamico`,
  WILL_UNMOUNT: `${prefix}willUnmount`,
  RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setDisplayForm = (field, value) => {
  return {
    type: types.SET_DISPLAY_FORM,
    field,
    value
  };
};

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.clearFormRelacionamento = () => {
  return {
    type: types.CLEAR_FORM_RELACIONAMENTO
  };
};

actions.setLookupMenuVisible = visible => {
  return {
    type: types.SET_LOOKUP_MENU_VISIBLE,
    visible
  };
};

actions.setRelacionamento = (field, value) => {
  return {
    type: types.SET_RELACIONAMENTO,
    field,
    value
  };
};

actions.adicionarRelacionamento = (field, value) => {
  return {
    type: types.ADICIONAR_RELACIONAMENTO,
    field,
    value
  };
};

actions.removerRelacionamento = value => {
  return {
    type: types.REMOVER_RELACIONAMENTO,
    value
  };
};

actions.setCampo = (field, value) => {
  return {
    type: types.SET_CAMPO,
    field,
    value
  };
};

actions.adicionarCampo = (field, value) => {
  return {
    type: types.ADICIONAR_CAMPO,
    field,
    value
  };
};

actions.removerCampo = value => {
  return {
    type: types.REMOVER_CAMPO,
    value
  };
};

actions.clearFormCampo = () => {
  return {
    type: types.CLEAR_FORM_CAMPO
  };
};

actions.setFiltro = (field, value) => {
  return {
    type: types.SET_FILTRO,
    field,
    value
  };
};

actions.adicionarFiltro = (field, value) => {
  return {
    type: types.ADICIONAR_FILTRO,
    field,
    value
  };
};

actions.removerFiltro = value => {
  return {
    type: types.REMOVER_FILTRO,
    value
  };
};

actions.clearFormFiltro = () => {
  return {
    type: types.CLEAR_FORM_FILTRO
  };
};

actions.setOrdenacao = (field, value) => {
  return {
    type: types.SET_ORDENACAO,
    field,
    value
  };
};

actions.adicionarOrdenacao = (field, value) => {
  return {
    type: types.ADICIONAR_ORDENACAO,
    field,
    value
  };
};

actions.removerOrdenacao = value => {
  return {
    type: types.REMOVER_ORDENACAO,
    value
  };
};

actions.clearFormOrdenacao = () => {
  return {
    type: types.CLEAR_FORM_ORDENACAO
  };
};

actions.onChangePerfis = (source, target) => {
  return {
    type: types.ON_CHANGE_PERFIS,
    source,
    target
  };
};

actions.beforeSave = () => {
  return {
    type: types.BEFORE_SAVE
  };
};

actions.removeRelatorioDinamico = () => {
  return {
    type: types.REMOVE_RELATORIO_DINAMICO
  };
};

actions.willUnmount = () => {
  return {
    type: types.WILL_UNMOUNT
  };
};

export { types, actions };
