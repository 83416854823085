import React from 'react';
import DocumentoListPageContainer from './DocumentoListPageContainer';

export class DocumentoListPage extends React.Component{
    
    render(){
      return (
        <DocumentoListPageContainer {...this.props}/>
      )
    }
}

