import React from "react";
import utils from "../../../utils/index";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { enums } from "../../../constants";
import { Card } from "primereact/card";

export const ControleFinanceiroList = props => {
  return (
    <div>
      <div className="p-grid form-group">
        <div className="p-col-12">
          <Checkbox
            style={{margin: "10px"}}
            inputId="chkPromissoria"
            checked={props.state.selecionarTodos}
            onChange={props.changeFiltroSelecionarTodosRegistros}
            name="enviarAvisoVencimentoSmsAposVencimento"
          />
          <label htmlFor="chkPromissoria" className="p-checkbox-label">
            {utils.getMessage(props.messages, "controlefinanceiro.label.selecionartodos")}
          </label>
        </div>
      </div>
      <DataTable
        style={{margin: "10px"}}
        ref={props.dataTableRef}
        selection={props.selectedRecords}
        onSelectionChange={props.onSelectionChange}
        reorderableColumns={true}
        resizableColumns={true}
        columnResizeMode="fit"
        onFilter={props.onFilter}
        onSort={props.onSort}
        onPage={props.onPage}
        onColReorder={props.onColReorder}
        value={props.records}
        lazy={true}
        totalRecords={props.totalRecords}
        sortOrder={props.order}
        sortField={props.sortField}
        paginator={true}
        first={props.first}
        rows={props.rows}
        emptyMessage="Não foram encontrados registros"
        rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
      >
        {props.columns}
      </DataTable>
      <br />
      <Card style={{ height: '30px', margin: "5px" }} className="ui-card-shadow">
        <div style={{ textAlign: "right", margin: "7px" }}>
          <strong>Total: </strong> {props.totalRecords}
        </div>
      </Card>
    </div>
  );
};
