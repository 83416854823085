import React from "react";

import "./Bi.scss";

export const BiForm = props => {

  return (
      <div className="container">
        <iframe className="responsive-iframe"
                src={ "https://bi.lottustech.com.br/" + props.match.params[0] + "?hash_empresa=" + props.currentCompany.hashEmpresa + "#hide_parameters=hash_empresa&bordered=false" }></iframe>
      </div>
  );
};
