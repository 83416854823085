import { types } from './contrato.actions'
import { Reducer } from '../../services/reducer.factory'
import utils from '../../utils'
import storage from '../../services/stoage.service'
import _ from 'lodash'
import { StatusDocumento } from '../documento/documento-utils'

function getFalecido() {
  return {
    oid: '',
    oidContrato: '',
    oidFuneraria: '',
    funeraria: '',
    oidFalecido: '',
    falecido: '',
    dataNascimento: '',
    dataFalecimento: '',
    dataExumacao: '',
    dataPrevisaoExumacao: '',
    localizacaoDestino: { id: '', value: '' },
    destinoExumacao: '',
    dataCremacao: '',
    atestadoObito: '',
    peso: '',
    altura: '',
    oidResponsavelCremacao: '',
    responsavelCremacao: '',
    dataTranslado: '',
    processoTranslado: '',
    link: '',
    observacao: '',
    sobre: '',
    ordem: '',
    oidContratoDestino: '',
    anexos: [],
    cpfFalecido: '',
    tanatopraxia: {},
    integracaoAdiau: {},
    localObito: {},
    localVelorio: {},
    dataVelorio: '',
    localCelebracao: {},
    dataCelebracao: '',
    localSepultamento: {},
    dataSepultamento: '',
    oidCelebrante: '',
    nomeCelebrante: '',
    oidCerimonialista: '',
    nomeCerimonialista: '',
    oidMedico: '',
    nomeMedico: '',
    crmMedico: '',
    oidMedico2: '',
    nomeMedico2: '',
    crmMedico2: '',
    oidJuiz: '',
    nomeJuiz: '',
    numeroDeclaracaoObito: '',
    causasMortis: [],
    marcadores: [],
  }
}

function getDependente() {
  return {
    oid: '',
    oidContrato: '',
    oidDependente: '',
    dependente: '',
    // oidParentesco: '',
    parentesco: '',
    dataNascimento: '',
  }
}

function getHistorico() {
  return {
    oid: '',
    oidContrato: '',
    oidUsuario: '',
    usuario: '',
    data: '',
    lembrete: '',
    descricao: '',
    oidTag: '',
    tag: '',
    oidPessoa: '',
    pessoa: '',
    valor: 0,
  }
}

function getResponsavel() {
  return {
    oid: '',
    oidContrato: '',
    oidResponsavel: '',
    responsavel: '',
    email: '',
    ordem: '',
    pessoaFisica: true,
  }
}

function getDocumento() {
  return {
    oid: '',
    nome: '',
  }
}

function getCurrentRecord() {
  return {
    oid: '',
    conspaga: new Date().getFullYear(),
    observacao: '',
    localizacao: '',
    numeroLugares: 0,
    numeroVagas: 0,
    unidade: { id: '', value: '' },
    tipoSepultura: { id: '', value: '' },
    isento: false,
    data: '',
    ativo: true,
    falecidos: [],
    dependentes: [],
    historicos: [],
    responsaveis: [],
    documentos: [],
    lancamentosFinanceiros: [],
    anexos: [],
    contratoOrigem: '',
    numeroContratoOrigem: '',
    contratoDestino: '',
    sequencialCremacaoFutura: '',
    dataProximaRemessaCompetencia: '',
    sequencialCremacao: '',
    contratoCremacaoFutura: '',
    plano: {},
    tanatopraxia: {},
    integracaoAdiau: {},
  }
}

const initialState = {
  id: 'contrato',
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'numero',
        header: 'Código',
        columnKey: 'numero',
        sortable: true,
        filter: true,
        columnSortField: 'numero',
        visible: true,
        showFilterMenuOptions: false,
        showClearButton: false,
        showFilterMenu: false,
      },
    },
    {
      label: 'Localização',
      value: {
        field: 'localizacao_identificacao',
        header: 'Localização',
        columnKey: 'localizacao',
        sortable: true,
        filter: true,
        columnSortField: 'localizacao.identificacao',
        visible: true,
        relacionamento: ['localizacao'],
        fieldJoin: 'identificacao',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_oid',
        header: '',
        columnKey: 'localizacao',
        sortable: true,
        filter: true,
        columnSortField: 'localizacao.oid',
        visible: true,
        relacionamento: ['localizacao'],
        fieldJoin: 'oid',
      },
    },
    {
      label: '',
      value: {
        field: 'falecido_oid',
        header: '',
        columnKey: 'falecido',
        columnSortField: 'falecido.oid',
        sortable: true,
        filter: true,
        relacionamento: ['falecido'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'Falecido',
      value: {
        field: 'falecido_nome',
        header: 'Falecido',
        columnKey: 'falecido',
        columnSortField: 'falecido.nome',
        sortable: true,
        filter: true,
        relacionamento: ['falecido'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: '',
      value: {
        field: 'responsavel_oid',
        header: '',
        columnKey: 'responsavel',
        columnSortField: 'responsavel.oid',
        sortable: true,
        filter: true,
        relacionamento: ['responsavel'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'Responsável',
      value: {
        field: 'responsavel_nome',
        header: 'Responsável',
        columnKey: 'responsavel',
        columnSortField: 'responsavel.nome',
        sortable: true,
        filter: true,
        relacionamento: ['responsavel'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: '',
      value: {
        field: 'vendedor_oid',
        header: '',
        columnKey: 'vendedor',
        columnSortField: 'vendedor.oid',
        sortable: true,
        filter: true,
        relacionamento: ['vendedor'],
        fieldJoin: 'oid',
        visible: false,
      },
    },
    {
      label: 'Vendedor',
      value: {
        field: 'vendedor_nome',
        header: 'Vendedor',
        columnKey: 'vendedor',
        columnSortField: 'vendedor.nome',
        sortable: true,
        filter: true,
        relacionamento: ['vendedor'],
        fieldJoin: 'nome',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'auxiliar_oid',
        header: '',
        columnKey: 'auxiliar',
        columnSortField: 'auxiliar.oid',
        sortable: true,
        filter: true,
        relacionamento: ['auxiliar'],
        fieldJoin: 'oid',
        visible: false,
      },
    },
    {
      label: 'Auxiliar',
      value: {
        field: 'auxiliar_nome',
        header: 'Auxiliar',
        columnKey: 'auxiliar',
        columnSortField: 'auxiliar.nome',
        sortable: true,
        filter: true,
        relacionamento: ['auxiliar'],
        fieldJoin: 'nome',
        visible: false,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        columnKey: 'unidade.sigla',
        sortable: true,
        header: 'Unidade',
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
    {
      label: 'Data',
      value: {
        field: 'data',
        header: 'Data',
        columnKey: 'data',
        columnSortField: 'data',
        sortable: true,
        filter: true,
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Conspaga',
      value: {
        field: 'conspaga',
        columnKey: 'conspaga',
        sortable: true,
        header: 'Conspaga',
        filter: true,
        columnSortField: 'conspaga',
        visible: true,
      },
    },
    {
      label: 'Ativo',
      value: {
        field: 'ativo',
        columnKey: 'ativo',
        sortable: true,
        header: 'Ativo',
        filter: true,
        columnSortField: 'ativo',
        visible: true,
      },
    },
    {
      label: 'Tipo Contrato',
      value: {
        field: 'tipoSepultura_descricao',
        columnKey: 'tipoSepultura.descricao',
        sortable: true,
        header: 'Tipo Contrato',
        filter: true,
        columnSortField: 'tipoSepultura.descricao',
        relacionamento: ['tipoSepultura'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        filter: true,
        columnSortField: 'observacao',
        visible: false,
      },
    },
    {
      label: 'Vagas',
      value: {
        field: 'localizacao_numeroVagas',
        columnKey: 'localizacao.numeroVagas',
        sortable: true,
        header: 'Vagas',
        filter: true,
        columnSortField: 'localizacao.numeroVagas',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'numeroVagas',
      },
    },
    {
      label: 'Lugares',
      value: {
        field: 'localizacao_numeroLugares',
        columnKey: 'localizacao.numeroLugares',
        sortable: true,
        header: 'Lugares',
        filter: true,
        columnSortField: 'localizacao.numeroLugares',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'numeroLugares',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoA',
        columnKey: 'localizacao.idA',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoA',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoA',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoB',
        columnKey: 'localizacao.idB',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoB',
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoB',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoC',
        columnKey: 'localizacao.idC',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoC',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoC',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoD',
        columnKey: 'localizacao.idD',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoD',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoD',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoE',
        columnKey: 'localizacao.idE',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoE',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoE',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoF',
        columnKey: 'localizacao.idF',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoF',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoF',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoG',
        columnKey: 'localizacao.idG',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoG',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoG',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoH',
        columnKey: 'localizacao.idH',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoH',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoH',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoI',
        columnKey: 'localizacao.idI',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoI',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoI',
      },
    },
    {
      label: '',
      value: {
        field: 'localizacao_localizacaoJ',
        columnKey: 'localizacao.idJ',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacao.localizacaoJ',
        visible: false,
        relacionamento: ['localizacao'],
        fieldJoin: 'localizacaoJ',
      },
    },
    {
      label: 'Data Falecimento',
      value: {
        field: 'falecido_dataFalecimento',
        header: 'Data Falecimento',
        columnKey: 'falecido.dataFalecimento',
        columnSortField: 'falecido.dataFalecimento',
        sortable: true,
        filter: true,
        relacionamento: ['falecido'],
        fieldJoin: 'dataFalecimento',
        fieldFormatter: 'formatarData',
        visible: false,
      },
    },
    {
      label: 'Data Previsão Exumação',
      value: {
        field: 'falecidos_dataPrevisaoExumacao',
        header: 'Data Previsão Exumação',
        columnKey: 'falecidos.dataPrevisaoExumacao',
        columnSortField: 'falecidos.dataPrevisaoExumacao',
        sortable: true,
        filter: true,
        relacionamento: ['falecidos'],
        fieldJoin: 'dataPrevisaoExumacao',
        fieldFormatter: 'formatarData',
        visible: false,
      },
    },
    {
      label: 'Data Exumação',
      value: {
        field: 'falecidos_dataExumacao',
        header: 'Data Exumação',
        columnKey: 'falecidos.dataExumacao',
        columnSortField: 'falecidos.dataExumacao',
        sortable: true,
        filter: true,
        relacionamento: ['falecidos'],
        fieldJoin: 'dataExumacao',
        fieldFormatter: 'formatarData',
        visible: false,
      },
    },
    {
      label: 'Código Cremação',
      value: {
        field: 'sequencialCremacao',
        header: 'Código Cremação',
        columnKey: 'sequencialCremacao',
        columnSortField: 'sequencialCremacao',
        sortable: true,
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Código Cremação Futura',
      value: {
        field: 'sequencialCremacaoFutura',
        header: 'Código Cremação Futura',
        columnKey: 'sequencialCremacaoFutura',
        columnSortField: 'sequencialCremacaoFutura',
        sortable: true,
        filter: true,
        visible: false,
      },
    },
    {
      label: 'Competência próxima remessa',
      value: {
        field: 'dataProximaRemessaCompetencia',
        header: 'Competência próxima remessa',
        columnKey: 'dataProximaRemessaCompetencia',
        columnSortField: 'dataProximaRemessaCompetencia',
        sortable: true,
        filter: true,
        visible: false,
        fieldFormatter: 'formatarDataCompetencia',
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'localizacao_identificacao',
      header: 'Localização',
      columnKey: 'localizacao_identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'localizacao.identificacao',
      relacionamento: ['localizacao'],
      fieldJoin: 'identificacao',
      visible: true,
    },
    {
      field: 'responsavel_oid',
      header: '',
      columnKey: 'responsavel_oid',
      columnSortField: 'responsavel.oid',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'responsavel_nome',
      header: 'Responsável',
      columnKey: 'responsavel_nome',
      columnSortField: 'responsavel.nome',
      sortable: true,
      filter: true,
      relacionamento: ['responsavel'],
      fieldJoin: 'nome',
      visible: true,
    },
    {
      field: 'responsavel_numeroDocumento',
      header: 'Num. Documento',
      columnKey: 'responsavel_numeroDocumento',
      columnSortField: 'responsavel.numeroDocumento',
      sortable: false,
      filter: false,
      relacionamento: ['responsavel'],
      fieldJoin: 'numeroDocumento',
      visible: false,
    },
    {
      field: 'conspaga',
      header: 'Conspaga',
      columnKey: 'conspaga',
      columnSortField: 'conspaga',
      sortable: true,
      filter: true,
      visible: true,
    },
  ],
  columns: [],
  colunasTFalecido: [
    {
      field: 'rowReorder',
    },
    {
      field: 'falecido',
      header: 'Nome',
      columnKey: 'falecido',
    },
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
    },
    {
      field: 'dataFalecimento',
      header: 'Falecimento',
      columnKey: 'dataFalecimento',
    },
    {
      field: 'dataExumacao',
      header: 'Exumação',
      columnKey: 'dataExumacao',
    },
    {
      field: 'oidContratoDestino',
      header: 'Loc. Destino',
      columnKey: 'oidContratoDestino',
    },
    {
      field: 'numeroGaveta',
      header: 'Gaveta',
      columnKey: 'numeroGaveta',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasTDependente: [
    {
      field: 'dependente',
      header: 'Dependente',
      columnKey: 'dependente',
    },
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
    },
    {
      field: 'parentesco',
      header: 'Parentesco',
      columnKey: 'parentesco',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasTHistorico: [
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
    },
    {
      field: 'tag',
      header: 'Tag',
      columnKey: 'tag',
    },
    {
      field: 'data',
      header: 'Data',
      columnKey: 'data',
    },
    {
      field: 'usuario',
      header: 'Usuário',
      columnKey: 'usuario',
    },
    {
      field: 'pessoa',
      header: 'Pessoa',
      columnKey: 'pessoa',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasTResponsavel: [
    {
      field: 'rowReorder',
    },
    {
      field: 'responsavel',
      header: 'Nome',
      columnKey: 'responsavel',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasTDocumentos: [
    {
      field: 'statusDocumento',
      header: 'Status',
      columnKey: 'statusDocumento',
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      visible: true,
    },
    {
      field: 'tipoDocumento',
      header: 'Tipo',
      columnKey: 'tipoDocumento',
      visible: false,
    },
    {
      field: 'urlDocumentoOriginal',
      header: 'URL Original',
      columnKey: 'urlDocumentoOriginal',
      visible: false,
    },
    {
      field: 'urlDocumentoAssinado',
      header: 'URL Assinado',
      columnKey: 'urlDocumentoAssinado',
      visible: false,
    },
    {
      field: 'ativo',
      header: 'Ativo',
      columnKey: 'ativo',
      visible: false,
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
      visible: true,
    },
  ],
  colunasTLancamentoFinanceiro: [
    { field: 'multiselect', header: 'Seleção', columnKey: 'multiselect', visible: true },
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
    },
    {
      field: 'emissao',
      header: 'Emissão',
      columnKey: 'emissao',
      sortable: true,
      filter: true,
      columnSortField: 'emissao',
    },
    {
      field: 'tipoFormaPagamento.value',
      header: 'Forma Pagamento',
      columnKey: 'tipoFormaPagamento.value',
      sortable: true,
      filter: true,
      columnSortField: 'tipoFormaPagamento.value',
    },
    {
      field: 'grupoFinanceiro',
      header: 'Grupo Financeiro',
      columnKey: 'grupoFinanceiro',
      sortable: true,
      filter: true,
      columnSortField: 'grupoFinanceiro',
    },
    {
      field: 'observacao',
      header: 'Observação',
      columnKey: 'observacao',
      sortable: true,
      filter: true,
      columnSortField: 'observacao',
    },
    {
      field: 'valorBruto',
      header: 'Valor',
      columnKey: 'valorBruto',
      sortable: true,
      filter: true,
      columnSortField: 'valorBruto',
    },
    {
      field: 'valor',
      header: 'Valor Atualizado',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
    },
    {
      field: 'valorPago',
      header: 'Valor pago',
      columnKey: 'valorPago',
      sortable: true,
      filter: true,
      columnSortField: 'valorPago',
    },
    {
      field: 'vencimento',
      header: 'Vencimento',
      columnKey: 'vencimento',
      sortable: true,
      filter: true,
      columnSortField: 'vencimento',
    },
    {
      field: 'dataPagamento',
      header: 'Pagamento',
      columnKey: 'dataPagamento',
      sortable: true,
      filter: true,
      columnSortField: 'dataPagamento',
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasCausasMortis: [
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
    },
    {
      field: 'tag',
      header: 'Tag',
      columnKey: 'tag',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],

  selectedLancamentos: [],
  currentRecord: getCurrentRecord(),
  lookupLocalizacao: {
    visible: false,
    modal: true,
    header: 'Localização',
  },
  lookupLocalizacaoDestino: {
    visible: false,
    modal: true,
    header: 'Localização de Destino',
  },
  lookupResponsavel: {
    visible: false,
    modal: true,
    header: 'Responsável',
  },
  lookupDocumento: {
    visible: false,
    modal: true,
    header: 'Documento',
  },
  lookupResponsavelCremacao: {
    visible: false,
    modal: true,
    header: 'Responsável',
  },
  lookupFalecido: {
    visible: false,
    modal: true,
    header: 'Falecido',
  },
  lookupFuneraria: {
    visible: false,
    modal: true,
    header: 'Funerária',
  },
  lookupDependente: {
    visible: false,
    modal: true,
    header: 'Dependente',
  },
  falecido: getFalecido(),
  dependente: getDependente(),
  historico: getHistorico(),
  responsavel: getResponsavel(),
  documento: getDocumento(),
  documentoTempLookup: { oid: '', nome: '' },
  historicos: [],
  dependentes: [],
  responsaveis: [],
  falecidos: [],
  unidades: {},
  booleanFilter: {
    ativo: '',
  },
  messages: null,
  usaListDto: false,
  tiposSepultura: [],
  filtrosTiposSepultura: [],
  filtrosFormaPagamentoPlano: [],
  filtrosFrequenciaFaturamentoPlano: [],
  isTipoContratoPlano: false,
  gerandoBoleto: false,
  integracaoAdiauHabilitada: false,
  contratoFalecidoCamposComplementares: {},
  camerasAdiauDisponiveis: [],
  adicionandoAnexo: false,
  relatotiosDocumentos: [],
  tipoSepulturaDestino: false,
  tiposSepulturasConfiguracoes: {},
  isTipoSepulturaPlano: false,
  isTipoSepulturaFuneral: false,
  isTipoSepulturaCremacao: false,
  isTipoSepulturaTitulo: false,
  isTipoSepulturaJazigo: false,
  isTipoSepulturaPet: false,
  isTipoSepulturaFalecido: false,
  relatoriosFalecido: [],
}

const reducer = new Reducer(initialState, types)

export function CRUDContratoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('contrato')
    const column = initialState.colunasTFalecido.find((c) => c.field === 'dataExumacao')
    if (column) {
      column.header = utils.getMessage(state.messages, 'contrato.label.exumacao')
    }
  }
  state = reducer.execute(state, action)

  // O metodo MAKE_NEW tem problemas com atributos do tipo 'Array'.
  if (action.type === 'crudContrato.new') {
    state = willUnmount(state, action)
    state = clearSelectedLancamentos(state, action)
  }
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_LOOKUP_CONTRATO_CREMACAO_FUTURA_VISIBLE:
      return setLookupContratoCremacaoFuturaVisible(state, action)
    case types.SET_LOOKUP_RESPONSAVEL_VISIBLE:
      return setLookupResponsavelVisible(state, action)
    case types.SET_LOOKUP_DOCUMENTO_VISIBLE:
      return setLookupDocumentoVisible(state, action)
    case types.SET_LOOKUP_RESPONSAVEL_CREMACAO_VISIBLE:
      return setLookupResponsavelCremacaoVisible(state, action)
    case types.SET_LOOKUP_FALECIDO_VISIBLE:
      return setLookupFalecidoVisible(state, action)
    case types.SET_LOOKUP_FUNERARIA_VISIBLE:
      return setLookupFunerariaVisible(state, action)
    case types.SET_LOOKUP_DEPENDENTE_VISIBLE:
      return setLookupDependenteVisible(state, action)
    case types.SET_LOOKUP_LOCALIZACAO_VISIBLE:
      return setLookupLocalizacaoVisible(state, action)
    case types.SET_LOOKUP_LOCALIZACAO_DESTINO_VISIBLE:
      return setLookupLocalizacaoDestinoVisible(state, action)
    case types.SET_RESPONSAVEL:
      return setResponsavel(state, action)
    case types.ADICIONAR_NOME_RESPONSAVEL:
      return adicionarNomeResponsavel(state, action)
    case types.SET_FALECIDO:
      return setFalecido(state, action)
    case types.SET_DEPENDENTE:
      return setDependente(state, action)
    case types.ADICIONAR_DEPENDENTE:
      return adicionarDependente(state, action)
    case types.ADICIONAR_HISTORICO:
      return adicionarHistorico(state, action)
    case types.ADICIONAR_CAUSA_MORTIS:
      return adicionarCausaMortis(state, action)
    case types.SET_HISTORICO:
      return setHistorico(state, action)
    case types.SET_CAUSA_MORTIS:
      return setCausaMortis(state, action)
    case types.CLEAR_FORM_HISTORICO:
      return clearFormHistorico(state)
    case types.CLEAR_FORM_DEPENDENTE:
      return clearFormDependente(state)
    case types.CLEAR_FORM_FALECIDO:
      return clearFormFalecido(state)
    case types.CLEAR_FORM_RESPONSAVEL:
      return clearFormFalecido(state)
    case types.BEFORE_SAVE:
      return beforeSave(state)
    case types.SET_DISPLAY_FORM:
      return setDisplayForm(state, action)
    case types.WILL_UNMOUNT:
      return willUnmount(state, action)
    case types.ADD_ANEXO:
      return addAnexo(state, action)
    case types.REMOVE_ANEXO:
      return removeAnexo(state, action)
    case types.REMOVE_ANEXO_FALECIDO:
      return removeAnexoFalecido(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_SELECTED_LANCAMENTOS:
      return Object.assign({}, state, { selectedLancamentos: action.records })
    case types.CLEAR_SELECTED_LANCAMENTOS:
      return clearSelectedLancamentos(state, action)
    // case types.CRIAR_CONTRATO_CREMACAO:
    //   return criarContratoCremacao(state, action);
    case types.CRIAR_CONTRATO_CREMACAO_FALECIDO:
      return criarContratoCremacaoFalecido(state, action)
    case types.UPDATE_OID_FALECIDO:
      return updateOidFalecido(state, action)
    case types.SET_TIPOS_SEPULTURAS:
      return Object.assign({}, state, { tiposSepulturas: action.records })
    case types.SET_TIPOS_SEPULTURAS_CONFIGURACOES:
      return Object.assign({}, state, { tiposSepulturasConfiguracoes: action.records })
    case types.SET_INTEGRACAO_ADIAU_HABILITADA:
      return Object.assign({}, state, { integracaoAdiauHabilitada: action.integracaoAdiauHabilitada })
    case types.SET_CONTRATO_FALECIDO_CAMPOS_COMPLEMENTARES:
      return Object.assign({}, state, {
        contratoFalecidoCamposComplementares: action.contratoFalecidoCamposComplementares,
      })
    case types.SET_CAMERAS_ADIAU:
      return Object.assign({}, state, { camerasAdiauDisponiveis: action.payload })
    case types.SET_TIPO_SEPULTURA_DESTINO:
      return Object.assign({}, state, { tipoSepulturaDestino: action.payload })
    case types.SET_FILTRO_TIPOS_SEPULTURAS:
      return Object.assign({}, state, { filtrosTiposSepultura: action.payload })
    case types.SET_FILTRO_FORMA_PAGAMENTO_PLANO:
      return Object.assign({}, state, { filtrosFormaPagamentoPlano: action.payload })
    case types.SET_FILTRO_FREQUENCIA_FATURAMENTO_PLANO:
      return Object.assign({}, state, { filtrosFrequenciaFaturamentoPlano: action.payload })
    case types.MAKE_NEW:
      return makeNew(state, action)
    case types.RESET:
      return reset(state, action)
    case types.ADD_DOCUMENTO:
      return addDocumento(state, action)
    case types.ADD_DOCUMENTO_CONTRATO:
      return addDocumentoContrato(state, action)
    case types.UPDATE_DOCUMENTO:
      return updateDocumento(state, action)
    case types.SET_GERANDO_BOLETO:
      return Object.assign({}, state, { gerandoBoleto: action.payload })
    case types.SET_ADICIONANDO_ANEXO:
      return Object.assign({}, state, { adicionandoAnexo: action.payload })
    case types.SET_RELATORIOS_DOCUMENTOS:
      return Object.assign({}, state, { relatotiosDocumentos: action.payload })
    case types.SET_RELATORIOS_FALECIDO:
      return Object.assign({}, state, { relatoriosFalecido: action.payload })
    default:
      return result
  }
}

function makeNew(state, action) {
  state = willUnmount(state, action)
  return state
}

function clearSelectedLancamentos(state, action) {
  return Object.assign({}, state, { selectedLancamentos: [] })
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setLookupContratoCremacaoFuturaVisible(state, action) {
  let lookupContratoCremacaoFutura = state.lookupContratoCremacaoFutura
  lookupContratoCremacaoFutura = Object.assign({}, lookupContratoCremacaoFutura, {
    visible: action.visible,
  })
  return Object.assign({}, state, { lookupContratoCremacaoFutura })
}

function setLookupResponsavelVisible(state, action) {
  let lookupResponsavel = state.lookupResponsavel
  lookupResponsavel = Object.assign({}, lookupResponsavel, { visible: action.visible })
  return Object.assign({}, state, { lookupResponsavel })
}

function setLookupDocumentoVisible(state, action) {
  let lookupDocumento = state.lookupDocumento
  lookupDocumento = Object.assign({}, lookupDocumento, { visible: action.visible })
  return Object.assign({}, state, { lookupDocumento })
}

function setLookupLocalizacaoVisible(state, action) {
  let lookupLocalizacao = state.lookupLocalizacao
  lookupLocalizacao = Object.assign({}, lookupLocalizacao, { visible: action.visible })
  return Object.assign({}, state, { lookupLocalizacao })
}

function setLookupLocalizacaoDestinoVisible(state, action) {
  let lookupLocalizacaoDestino = state.lookupLocalizacaoDestino
  lookupLocalizacaoDestino = Object.assign({}, lookupLocalizacaoDestino, { visible: action.visible })
  return Object.assign({}, state, { lookupLocalizacaoDestino })
}

function setLookupResponsavelCremacaoVisible(state, action) {
  let lookupResponsavelCremacao = state.lookupResponsavelCremacao
  lookupResponsavelCremacao = Object.assign({}, lookupResponsavelCremacao, { visible: action.visible })
  return Object.assign({}, state, { lookupResponsavelCremacao })
}

function setLookupFalecidoVisible(state, action) {
  let lookupFalecido = state.lookupFalecido
  lookupFalecido = Object.assign({}, lookupFalecido, { visible: action.visible })
  return Object.assign({}, state, { lookupFalecido })
}

function setLookupFunerariaVisible(state, action) {
  let lookupFuneraria = state.lookupFuneraria
  lookupFuneraria = Object.assign({}, lookupFuneraria, { visible: action.visible })
  return Object.assign({}, state, { lookupFuneraria })
}

function setLookupDependenteVisible(state, action) {
  let lookupDependente = state.lookupDependente
  lookupDependente = Object.assign({}, lookupDependente, { visible: action.visible })
  return Object.assign({}, state, { lookupDependente })
}

function setResponsavel(state, resp) {
  let responsavel = resp.value
  let responsaveis = _.get(state, 'currentRecord.responsaveis', [])
  const existe = responsaveis.find((r) => r.oidResponsavel === responsavel.oidResponsavel)
  if (!existe) {
    responsavel.ordem = state.currentRecord.responsaveis.length + 1
    responsaveis.push(responsavel)
    Object.assign(state.currentRecord.responsaveis, responsaveis)
  }
  Object.assign(state.responsavel, getResponsavel())
  return Object.assign({}, state)
}

function addDocumento(state, payload) {
  const documento = payload.value
  const documentos = state.currentRecord.documentos
  const docto = documentos.find((f) => f.oidDocumento === documento.oid && f.ativo)
  if (!docto) {
    const newDocumento = {
      oidDocumento: documento.oid,
      oidContrato: state.currentRecord.oid,
      nome: documento.nome,
      oid: null,
      statusDocumento: StatusDocumento.NAO_INTEGRADO,
      ativo: true,
    }

    const signatario = addSignatarioByResponsavel(state)
    if (signatario) {
      newDocumento.assinaturas = [signatario]
    }

    documentos.push(newDocumento)
    Object.assign(state.currentRecord.documentos, documentos)
    if (_.get(state, 'currentRecord.documentoTempLookup', null)) {
      Object.assign(state.currentRecord.documentoTempLookup, { oid: '', nome: '' })
    }
  }
  return Object.assign({}, state)
}

function addDocumentoContrato(state, payload) {
  const { nome, oid, filtros } = payload.value
  const documentos = state.currentRecord.documentos
  const docto = documentos.find((f) => f.nome === nome && f.ativo)
  if (!docto) {
    const newDocumento = {
      oidDocumento: oid,
      oidContrato: state.currentRecord.oid,
      nome: nome,
      oid: null,
      statusDocumento: StatusDocumento.NAO_INTEGRADO,
      ativo: true,
      filtros,
    }

    const signatario = addSignatarioByResponsavel(state)
    if (signatario) {
      newDocumento.assinaturas = [signatario]
    }

    documentos.push(newDocumento)
    Object.assign(state.currentRecord.documentos, documentos)
    if (_.get(state, 'currentRecord.documentoTempLookup', null)) {
      Object.assign(state.currentRecord.documentoTempLookup, { oid: '', nome: '' })
    }
  }
  return Object.assign({}, state)
}
function addSignatarioByResponsavel(state) {
  const responsaveis = _.get(state, 'currentRecord.responsaveis', [])
  const responsavel = responsaveis.find((f) => f.ordem === 1)
  if (responsavel) {
    let nomeEmail = responsavel.responsavel
    if (utils.validateEmail(responsavel.email)) {
      nomeEmail = responsavel.email
    }
    return {
      oid: null,
      nomeEmail,
      tipoAssinatura: 'Responsável',
      oidContratoDocumento: null,
      status: 'PENDENTE',
    }
  }
  return null
}

function updateDocumento(state, payload) {
  const oid = _.get(payload, 'payload.oid', null)
  const status = _.get(payload, 'payload.status', null)
  const serviceProviderDocumentId = _.get(payload, 'payload.serviceProviderDocumentId', null)
  const linksAssinaturas = _.get(payload, 'payload.linksAssinaturas', [])
  if (oid && status) {
    const documentos = state.currentRecord.documentos
    for (const documento of documentos) {
      if (documento.oid === oid) {
        documento.statusDocumento = status
        documento.serviceProviderDocumentId = serviceProviderDocumentId
        if (linksAssinaturas.length > 0) {
          const assinaturas = documento.assinaturas
          linksAssinaturas.forEach((la) => {
            const assinatura = assinaturas.find((a) => a.oid === la.id)
            if (assinatura) {
              assinatura.link = la.value
            }
          })
        }
      }
    }
    Object.assign(state.currentRecord.documentos, documentos)
  }
  return Object.assign({}, state)
}

const resolvePrevisaoExumacao = ({ field, current, empresa, state }) => {
  if (utils.isDate(current.dataPrevisaoExumacao)) {
    return
  }
  const idUnidade = _.get(state, 'currentRecord.unidade.id', null)
  const unidades = _.get(empresa, 'unidades', [])
  const unidade = unidades.find((u) => u.oid === idUnidade)
  const configJson = utils.getAnosExumacao(empresa, unidade)
  if (!configJson) {
    return
  }

  const campo = _.get(configJson, 'campo', 'dataFalecimento')
  if (field !== campo) {
    return
  }

  const campoCurrent = _.get(current, campo, null)
  if (!campoCurrent || !utils.isDate(campoCurrent)) {
    return
  }

  const tempo = _.get(configJson, 'tempo', '3')
  const newCampoCurrent = new Date(campoCurrent.getTime())
  current.dataPrevisaoExumacao = new Date(newCampoCurrent.setFullYear(newCampoCurrent.getFullYear() + parseInt(tempo)))
}

const sugereDataTranslado = ({ field, current }) => {
  if (field !== 'dataExumacao' || utils.isDate(current.dataTranslado)) {
    return
  }
  if (!current.dataExumacao || !utils.isDate(current.dataExumacao)) {
    return
  }
  current.dataTranslado = new Date(current.dataExumacao.getTime())
}

function setFalecido(state, action) {
  let falecido = Object.assign({}, state.falecido)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = falecido
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  const empresa = storage.getCompany()
  resolvePrevisaoExumacao({ field, current, empresa, state })
  sugereDataTranslado({ field, current })

  return Object.assign({}, state, { falecido })
}

function updateOidFalecido(state, action) {
  const idValue = action.payload
  const falecidos = state.currentRecord.falecidos
  if (utils.isArrayNotEmpty(falecidos)) {
    const result = falecidos.filter((f) => !f.oidFalecido || f.oidFalecido === null || f.oidFalecido === '')
    if (utils.isArrayNotEmpty(result)) {
      let falecido = result[0]
      falecido.oidFalecido = idValue.id
    }
  }
  Object.assign(state.currentRecord.falecidos, falecidos)
  return Object.assign({}, state)
}

function setDependente(state, action) {
  let dependente = Object.assign({}, state.dependente)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = dependente
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  return Object.assign({}, state, { dependente })
}

function adicionarNomeResponsavel(state, action) {
  let responsavel = Object.assign({}, state.responsavel)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = responsavel
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  return Object.assign({}, state, { responsavel })
}

function adicionarDependente(state, action) {
  let dependentess = state.currentRecord.dependentes
  let dependenteForm = Object.assign({}, action.value) // pegando os dados preenchidos do dependente no Form
  let dependenteSelecionado = null
  if (dependentess.length > 0) {
    dependenteSelecionado = dependentess.filter((dependente) => {
      return dependente.oidDependente === dependenteForm.oidDependente
    })
    if (dependenteSelecionado.length > 0) {
      // se ele já existia
      dependentess.map((dependente) => {
        if (dependenteSelecionado[0].oidDependente === dependente.oidDependente) {
          Object.assign(dependente, dependenteForm)
          return (dependente = dependenteForm)
        }
      })
    } else {
      dependentess.push(dependenteForm)
    }
  } else {
    dependentess.push(dependenteForm)
  }
  Object.assign(state.currentRecord.dependentes, dependentess)
  return Object.assign({}, state)
}

function adicionarCausaMortis(state, falecido) {
  console.log(falecido)
  let causasMortisAtuais = falecido.causasMortis
}

function adicionarHistorico(state, currentUser) {
  let historicosAtuais = state.currentRecord.historicos
  let historicoForm = Object.assign({}, state.historico) // pegando os dados preenchidos do falecido no Form
  historicoForm.data = new Date()
  historicoForm.usuario = currentUser.value
  let historicoSelecionado = null
  if (utils.isNumber(historicoForm.oid) && utils.nonNull(historicoForm.oid)) {
    historicoForm.hash = historicoForm.oid
  } else if (!utils.nonNull(historicoForm.hash)) {
    historicoForm.hash = historicosAtuais.length + 1
  }
  if (historicosAtuais.length > 0) {
    for (let i = 0; i < historicosAtuais.length; i++) {
      let historico = historicosAtuais[i]
      if (utils.isNumber(historico.oid) && utils.nonNull(historico.oid)) {
        historico.hash = historico.oid
      } else {
        historico.hash = i + 1
      }
    }
  }
  if (historicosAtuais.length > 0) {
    historicoSelecionado = historicosAtuais.filter((historico) => {
      return historico.hash === historicoForm.hash
    })
    if (historicoSelecionado.length > 0) {
      // se ele já existia
      historicosAtuais.map((historico) => {
        if (historicoSelecionado[0].hash === historico.hash) {
          Object.assign(historico, historicoForm)
          return (historico = historicoForm)
        }
      })
    } else {
      historicosAtuais.push(historicoForm)
    }
  } else {
    historicosAtuais.push(historicoForm)
  }
  Object.assign(state.currentRecord.historicos, historicosAtuais)
  state = clearFormHistorico(state)
  return Object.assign({}, state)
}

function setHistorico(state, action) {
  let historico = Object.assign({}, state.historico)
  const { field, value } = action
  const fields = field && field.split('.')
  const lastField = fields[fields.length - 1]
  let current = historico
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current
      current = current[fields[index]]
      if (!current) {
        current = {}
        owner[fields[index]] = current
      }
    }
  }
  current[lastField] = value
  return Object.assign({}, state, { historico })
}

function setCausaMortis(state, action) {
  console.log('setCausaMortis')

  let historico = Object.assign({}, state.historico)
  return historico
}

function clearFormHistorico(state) {
  return Object.assign({}, state, { historico: Object.assign(state.historico, getHistorico()) })
}

function clearFormFalecido(state) {
  return Object.assign({}, state, { falecido: Object.assign(state.falecido, getFalecido()) })
}

function clearFormDependente(state) {
  return Object.assign({}, state, { dependente: Object.assign(state.dependente, getDependente()) })
}

function beforeSave(state) {
  const record = state.currentRecord
  let historicos = record.historicos
  historicos.map((historico) => {
    return (historico.data = new Date(historico.data))
  })

  record.data = new Date(record.data)
  return Object.assign({}, state, { currentRecord: record })
}

function setDisplayForm(state, action) {
  let displayForm = Object.assign({}, state.displayForm)
  displayForm[action.field] = action.value
  return Object.assign({}, state, { displayForm })
}

function willUnmount(state, action) {
  const currentRecord = getCurrentRecord()
  const falecido = getFalecido()
  const dependente = getDependente()
  const historico = getHistorico()
  const responsavel = getResponsavel()
  const documento = getDocumento()
  const historicos = []
  const dependentes = []
  const responsaveis = []
  const documentos = []
  const falecidos = []
  const unidades = {}

  state = Object.assign({}, state, { falecido })
  state = Object.assign({}, state, { dependente })
  state = Object.assign({}, state, { historico })
  state = Object.assign({}, state, { responsavel })
  state = Object.assign({}, state, { documento })
  state = Object.assign({}, state, { historicos })
  state = Object.assign({}, state, { dependentes })
  state = Object.assign({}, state, { responsaveis })
  state = Object.assign({}, state, { documentos })
  state = Object.assign({}, state, { falecidos })
  state = Object.assign({}, state, { unidades })
  state = clearSelectedLancamentos(state, action)
  return Object.assign({}, state, { currentRecord })
}

function addAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexos = action.payload
  if (anexos) {
    currentRecord.anexos.push(...anexos)
    return Object.assign({}, state, { currentRecord: currentRecord })
  }

  return state
}

function removeAnexo(state, action) {
  let currentRecord = state.currentRecord
  const anexoRemover = action.payload
  if (anexoRemover) {
    const anexos = currentRecord.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find((f) => f.filename === anexoRemover.filename)
      if (temp) {
        const index = anexos.indexOf(temp)
        anexos.splice(index, 1)
      }
    }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}

function removeAnexoFalecido(state, action) {
  let falecido = state.falecido
  const anexoRemover = action.payload
  if (anexoRemover) {
    const anexos = falecido.anexos
    if (utils.isArrayNotEmpty(anexos)) {
      const temp = anexos.find((f) => f.filename === anexoRemover.filename)
      if (temp) {
        const index = anexos.indexOf(temp)
        anexos.splice(index, 1)
      }
    }
    return Object.assign({}, state, { falecido: falecido })
  }
  return state
}

function criarContratoCremacaoFalecido(state, action) {
  willUnmount(state, action)
  let currentRecord = state.currentRecord
  const currentRecordContratoFalecido = action.payload.currentRecord
  const contratoFalecido = action.payload.contratoFalecido
  let oidUnidadeCrematorio = utils.getParametroEmpresaCorrente('oidUnidadeCrematorio', storage.getCompany())
  if (oidUnidadeCrematorio && oidUnidadeCrematorio.value) {
    oidUnidadeCrematorio = parseInt(oidUnidadeCrematorio.value)
    const unidades = storage.getUnits()
    const unidadeCrematorio = utils.findObjectInArray(unidades, 'oid', oidUnidadeCrematorio)
    const oidTipoSepulturaCremacao = utils.getOidTipoSepulturaCremacao(unidades, oidUnidadeCrematorio)
    if (oidTipoSepulturaCremacao) {
      const oidEmpresaBkp = currentRecord.oidEmpresa
      const unidadeBkp = { id: oidUnidadeCrematorio, value: unidadeCrematorio.sigla }
      const responsaveisBkp = currentRecord.responsaveis
      currentRecord = getCurrentRecord()
      currentRecord.contratoOrigem = currentRecordContratoFalecido.oid
      currentRecord.tipoSepultura = { id: oidTipoSepulturaCremacao, value: 'Cremação' }
      currentRecord.data = new Date()
      currentRecord.oidEmpresa = oidEmpresaBkp
      currentRecord.unidade = unidadeBkp
      if (utils.isArrayNotEmpty(responsaveisBkp)) {
        responsaveisBkp.forEach((e) => {
          e.oidContrato = null
          e.oid = null
        })
        currentRecord.responsaveis = responsaveisBkp
      }
      contratoFalecido.oidContrato = null
      contratoFalecido.oid = null
      currentRecord.falecidos.push(contratoFalecido)
    }
  }

  return Object.assign({}, state, { currentRecord: currentRecord })
}

function reset(state, action) {
  const stt = willUnmount(state, action)
  return {
    ...stt,
    records: [],
    tiposSepultura: initialState.tiposSepultura,
    filtrosTiposSepultura: initialState.filtrosTiposSepultura,
  }
}
