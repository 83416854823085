import { types } from './mapa-coordenada.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'mapaCoordenada',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        columnSortField: 'oid',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Posição X',
      value: {
        field: 'posicaoX',
        header: 'posição X',
        columnKey: 'posicaoX',
        columnSortField: 'posicaoX',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Posição Y',
      value: {
        field: 'posicaoY',
        header: 'posição Y',
        columnKey: 'posicaoY',
        columnSortField: 'posicaoY',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    posicaoX: '',
    posicaoY: '',
  },
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDMapaCoordenadaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('mapaCoordenada')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
