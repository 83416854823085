import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { InputText } from 'primereact/inputtext'
import './lts-lookup-produto.scss'

export default function LtsLookupProduto(props) {
  const [filters, setFilters] = useState([])

  const columns = [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: false,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'codigo',
      header: 'Código',
      columnKey: 'codigo',
      sortable: true,
      filter: true,
      columnSortField: 'codigo',
      relacionamento: ['codigo'],
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      columnSortField: 'descricao',
      sortable: true,
      filter: true,
      visible: true,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/produtos/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('descricao', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        descricao: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const autoCompleteTemplate = (data) => {
    return `${data.oid} - ${data.descricao}`
  }

  const onResolvingColumn = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      // if (col.field === "oid") {
      //   return (
      //     <Column
      //       {...col}
      //       key={col.field}
      //       bodyClassName="column-oid-body-width"
      //       filterHeaderClassName="column-oid-filter-header-width"
      //       headerClassName="column-oid-header-width"
      //       filterElement={columnFilterElement(col)}
      //     />
      //   );
      // }
      if (col.field === 'codigo') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-codigo-body-width"
            filterHeaderClassName="column-codigo-filter-header-width"
            headerClassName="column-codigo-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-descricao-body-width"
            filterHeaderClassName="column-descricao-filter-header-width"
            headerClassName="column-descricao-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }

      return null
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    const filtroTipoProduto = _.get(props, 'filtroTipoProduto', null)
    if (filtroTipoProduto) {
      filters.push({ campo: 'tipoProduto', valor: filtroTipoProduto, tipoFiltro: 'IGUAL_A' })
    }

    const idUnidade = _.get(props, 'idUnidade', null)
    if (idUnidade) {
      const filtroUnidadeIgual = new Filtro('unidade.oid', idUnidade, 'IGUAL_A')
      const filtroUnidadeNulo = new Filtro('unidade.oid', null, 'NULO')
      const juncaoDosFiltros = [filtroUnidadeIgual, filtroUnidadeNulo]
      const filterUnidade = {
        campo: 'filtro',
        valor: juncaoDosFiltros,
        tipoFiltro: 'OU',
      }
      filters.push(filterUnidade)
    }

    return filters
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={props.value}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEdit={routes.PATH_PRODUTO}
        header={props.label}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
      />
    </React.Fragment>
  )
}
