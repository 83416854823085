import React from 'react'

import ContaCorrenteFormPageContainer from './ContaCorrenteFormPageContainer'

export class ContaCorrenteFormPage extends React.Component {
  render() {
    return <ContaCorrenteFormPageContainer {...this.props} />
  }
}

