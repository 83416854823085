import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import { messageActions } from "../../store/message/index";
const BASE_PATH = "/lottustech/notasfiscaisservico";
const crud = build(validate, BASE_PATH);
const prefix = "crudNfse";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  RESET: `${prefix}reset`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.downloadDanfSe = oidNFSe => {
  return (dispatch, getState) => {
    if (oidNFSe) {
      return apiService.get(`${BASE_PATH}/downloadDanfSe/${oidNFSe}`).then(response => {
        const arrayBuffer = base64ToArrayBuffer(response.data);
        createAndDownloadBlobFile(arrayBuffer, "DanfSe - " + oidNFSe + ".pdf");
      }).catch(err => {
        if (err.response) {
          const messages = err.response.data;
          dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
        }
      });
    }
  };
};

actions.downloadDanfSePorLancamento = oidLancamento => {
  return (dispatch, getState) => {
    if (oidLancamento) {
      dispatch(messageActions.messageShowMessages(makeMessages(["Solicitando nota fiscal!"], "warn")));
      return apiService
        .get(`${BASE_PATH}/downloadDanfSePorLancamento/${oidLancamento}`)
        .then(response => {
          const arrayBase64Notas = response.data;
          let cont = 1;
          arrayBase64Notas.forEach(base64 => {
            let arrayBuffer = base64ToArrayBuffer(base64);
            createAndDownloadBlobFile(arrayBuffer, "DanfSe - " + oidLancamento + cont + ".pdf");
            cont++;
          });
          dispatch(
            messageActions.messageShowMessages(makeMessages(["Sucesso no download da nota fiscal!"], "success"))
          );
        })
        .catch(err => {
          if (err.response) {
            const messages = err.response.data;
            dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
          }
        });
    }
  };
};
actions.reenviarNFS = oidNFSe => {
  return (dispatch, getState) => {
    if (oidNFSe) {
      return apiService.post(`${BASE_PATH}/reenviarnfs/${oidNFSe}`).then(response => {
        // AO REENVIAR, REALIZAR ALGUMA OPERAÇÃO?
        dispatch(messageActions.messageShowMessages(makeMessages(["Nota reenviada com sucesso!"], "success")));
      });
    }
  };
};

actions.cancelarNFS = oidNFSe => {
  return (dispatch, getState) => {
    if (oidNFSe) {
      return apiService
        .post(`${BASE_PATH}/cancelarNFS/${oidNFSe}`)
        .then(response => {
          dispatch(messageActions.messageShowMessages(makeMessages(["Nota cancelada com sucesso!"], "success")));
          dispatch(actions.loadById(oidNFSe));
        })
        .catch(err => {
          if (err.response) {
            const messages = err.response.data;
            dispatch(messageActions.messageShowMessages(makeMessages([messages], "error")));
          }
        });
    }
  };
};

const makeMessages = (messages, type) => {
  let sticky = false;
  let summary = "Sucesso!";
  if (type === "error") {
    summary = "Erro!";
    sticky = true;
  }
  return messages.map(message => {
    return { sticky: sticky, summary: summary, severity: type, detail: message };
  });
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export { types, actions };
