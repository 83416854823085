import { types } from "./relatorioDinamico.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";
import utils from "../../utils";

const initialState = {
  id: "relatorioDinamico",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        columnSortField: "oid",
        filter: true,
        visible: true
      }
    },
    {
      label: "Nome",
      value: {
        field: "nome",
        header: "Nome",
        columnKey: "nome",
        sortable: true,
        columnSortField: "nome",
        filter: true,
        visible: true
      }
    },
    {
      label: "Entidade base",
      value: {
        field: "entidadeBase",
        header: "Entidade base",
        columnKey: "entidadeBase",
        sortable: true,
        columnSortField: "entidadeBase",
        filter: true,
        visible: true
      }
    }
  ],
  columns: [],
  colunasRelacionamentos: [
    {
      field: "relacionamento",
      header: "Relacionamento",
      columnKey: "relacionamento"
    },
    {
      field: "alias",
      header: "Alias",
      columnKey: "alias"
    },
    {
      field: "joinType",
      header: "Tipo Join",
      columnKey: "joinType"
    },
    {
      field: "ordem",
      header: "Ordem",
      columnKey: "ordem"
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes"
    }
  ],
  relacionamento: {
    oid: "",
    oidRelatorioDinamico: "",
    relacionamento: "",
    alias: "",
    joinType: { id: "", value: "" },
    ordem: "",
    hash: "" // para identificar um relacionamento quando ele ainda nao tem um OID
  },
  colunasColuna: [
    {
      field: "campo",
      header: "Campo",
      columnKey: "campo"
    },
    {
      field: "ordem",
      header: "Ordem",
      columnKey: "ordem"
    },
    {
      field: "descricao",
      header: "Descrição",
      columnKey: "descricao"
    },
    {
      field: "tipoDado",
      header: "Tipo",
      columnKey: "tipoDado"
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes"
    }
  ],
  coluna: {
    oid: "",
    campo: "",
    descricao: "",
    ordem: "",
    linkField: "",
    idLink: "",
    tipoDado: { id: "", value: "" },
    hash: "" // para identificar uma coluna quando ela ainda nao tem um OID
  },
  colunasFiltros: [
    {
      field: "descricao",
      header: "Descrição",
      columnKey: "descricao"
    },
    {
      field: "campo",
      header: "Campo",
      columnKey: "campo"
    },
    {
      field: "tipoFiltro",
      header: "Tipo",
      columnKey: "tipoFiltro"
    },
    {
      field: "valor",
      header: "Valor",
      columnKey: "valor"
    },
    {
      field: "valorComplementar",
      header: "Valor complementar",
      columnKey: "valorComplementar"
    },
    {
      field: "tipoDado",
      header: "Tipo",
      columnKey: "tipoDado"
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes"
    }
  ],
  filtro: {
    oid: "",
    descricao: "",
    campo: "",
    valor: "",
    valorComplementar: "",
    tipoFiltro: { id: "", value: "" },
    tipoDado: { id: "", value: "" },
    tipoDataTipo: 1,
    tipoDataModificador: 0,
    tipoDataPeriodo: 1,
    tipoDataTipoComplementar: 1,
    tipoDataModificadorComplementar: 0,
    tipoDataPeriodoComplementar: 1,
    converterFiltro: "",
    visivel: true,
    hash: "" // para identificar um filtro quando ele ainda nao tem um OID
  },
  colunasOrdenacao: [
    {
      field: "campo",
      header: "Campo",
      columnKey: "campo"
    },
    {
      field: "tipoOrdenacaoColuna",
      header: "Tipo",
      columnKey: "tipoOrdenacaoColuna"
    },
    {
      field: "ordem",
      header: "Ordem",
      columnKey: "ordem"
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes"
    }
  ],
  ordenacao: {
    oid: "",
    campo: "",
    tipoOrdenacaoColuna: { id: "", value: "" },
    ordem: "",
    hash: "" // para identificar uma coluna quando ela ainda nao tem um OID
  },
  currentRecord: {
    oid: "",
    nome: "",
    entidadeBase: "",
    menu: "",
    relacionamentos: [],
    grid: {},
    filtros: [],
    perfis: []
  },
  lookupMenu: {
    visible: false,
    modal: true,
    header: "Menus"
  },
  relacionamentos: [],
  colunas: [],
  filtros: [],
  ordenacoes: [],
  perfisDisponiveis: [],
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDRelatorioDinamicoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("relatoriodinamico");
  }
  state = reducer.execute(state, action);

  // O metodo MAKE_NEW tem problemas com atributos do tipo 'Array'.
  if (action.type === "crudRelatorioDinamico.new") {
    state = willUnmount(state, action);
  }

  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_MENU_VISIBLE:
      return setLookupMenuVisible(state, action);
    case types.SET_RELACIONAMENTO:
      return setRelacionamento(state, action);
    case types.ADICIONAR_RELACIONAMENTO:
      return adicionarRelacionamento(state, action);
    case types.REMOVER_RELACIONAMENTO:
      return removerRelacionamento(state, action);
    case types.CLEAR_FORM_RELACIONAMENTO:
      return clearFormRelacionamento(state);
    case types.SET_CAMPO:
      return setCampo(state, action);
    case types.ADICIONAR_CAMPO:
      return adicionarCampo(state, action);
    case types.REMOVER_CAMPO:
      return removerCampo(state, action);
    case types.CLEAR_FORM_CAMPO:
      return clearFormCampo(state);
    case types.SET_FILTRO:
      return setFiltro(state, action);
    case types.ADICIONAR_FILTRO:
      return adicionarFiltro(state, action);
    case types.REMOVER_FILTRO:
      return removerFiltro(state, action);
    case types.CLEAR_FORM_FILTRO:
      return clearFormFiltro(state);
    case types.SET_ORDENACAO:
      return setOrdenacao(state, action);
    case types.ADICIONAR_ORDENACAO:
      return adicionarOrdenacao(state, action);
    case types.REMOVER_ORDENACAO:
      return removerOrdenacao(state, action);
    case types.CLEAR_FORM_ORDENACAO:
      return clearFormOrdenacao(state);
    case types.ON_CHANGE_PERFIS:
      return onChangePerfis(state, action);
    case types.SET_DISPLAY_FORM:
      return setDisplayForm(state, action);
    case types.BEFORE_SAVE:
      return beforeSave(state);
    case types.REMOVE_RELATORIO_DINAMICO:
      return removeRelatorioDinamico(state);
    case types.WILL_UNMOUNT:
      return willUnmount(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return state;
  }
}

function setLookupMenuVisible(state, action) {
  let lookupMenu = state.lookupMenu;
  lookupMenu = Object.assign({}, lookupMenu, { visible: action.visible });
  return Object.assign({}, state, { lookupMenu });
}

function adicionarRelacionamento(state, action) {
  let relacionamentos = state.currentRecord.relacionamentos;
  const relacionamentoForm = Object.assign({}, state.relacionamento);
  const idx = getIndex(relacionamentos, relacionamentoForm);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    relacionamentos.splice(idx, 1);
  }

  resolveHash(relacionamentoForm);

  // adicionamos o novo
  relacionamentos.push(relacionamentoForm);

  utils.orderArrayByProperty(relacionamentos, "ordem");

  Object.assign(state.currentRecord.relacionamentos, relacionamentos);
  return Object.assign({}, state);
}

function getIndex(array, objeto) {
  // por qual propriedade vamos verificar a existencia:
  // hash ou oid (oid NAO teremos quando for um novo objeto)
  let property = "hash";
  if (objeto.oid || parseInt(objeto.oid)) {
    property = "oid";
  }

  const original = array.filter(f => f[property] === objeto[property]);
  if (original && original.length > 0) {
    return array.indexOf(original[0]);
  }
  return -1;
}

function resolveHash(objeto) {
  if (objeto && (!objeto.hash || objeto.hash === "")) {
    objeto.hash = new Date().getTime();
  }
}

function removerRelacionamento(state, action) {
  const relacionamentoRemovido = action.value;
  let relacionamentos = state.currentRecord.relacionamentos;
  const idx = getIndex(relacionamentos, relacionamentoRemovido);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    relacionamentos.splice(idx, 1);

    utils.orderArrayByProperty(relacionamentos, "ordem");
  }

  return Object.assign({}, state);
}

function setRelacionamento(state, action) {
  let relacionamento = Object.assign({}, state.relacionamento);
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = relacionamento;
  current[lastField] = value;
  return Object.assign({}, state, { relacionamento });
}

function clearFormRelacionamento(state) {
  return Object.assign({}, state, { relacionamento: Object.assign(state.relacionamento, initialState.relacionamento) });
}

function adicionarCampo(state, action) {
  let isColunas = true;
  let colunas = state.currentRecord.grid.colunas;
  if (!colunas) {
    colunas = [];
    isColunas = false;
  }

  const colunaForm = Object.assign({}, state.coluna);
  const idx = getIndex(colunas, colunaForm);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    colunas.splice(idx, 1);
  }

  resolveHash(colunaForm);

  // adicionamos o novo
  colunas.push(colunaForm);

  utils.orderArrayByProperty(colunas, "ordem");

  if (!isColunas) {
    Object.assign(state.currentRecord.grid, { colunas: colunas });
  }
  return Object.assign({}, state);
}

function removerCampo(state, action) {
  const campoRemovido = action.value;
  let colunas = state.currentRecord.grid.colunas;
  const idx = getIndex(colunas, campoRemovido);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    colunas.splice(idx, 1);
  }

  utils.orderArrayByProperty(colunas, "ordem");

  return Object.assign({}, state);
}

function setCampo(state, action) {
  let coluna = Object.assign({}, state.coluna);
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = coluna;
  current[lastField] = value;
  return Object.assign({}, state, { coluna });
}

function clearFormCampo(state) {
  return Object.assign({}, state, { coluna: Object.assign(state.coluna, initialState.coluna) });
}

function adicionarFiltro(state, action) {
  let filtros = state.currentRecord.filtros;
  const filtroForm = Object.assign({}, state.filtro);
  const idx = getIndex(filtros, filtroForm);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    filtros.splice(idx, 1);
  }

  resolveHash(filtroForm);

  if (filtroForm.tipoDado && filtroForm.tipoDado.id === 3) {
    let valor = "";
    if (filtroForm.tipoDataTipo === 1) {
      valor = "$DATA_ATUAL";
    } else if (filtroForm.tipoDataTipo === 2) {
      valor = "$INICIO_MES";
    } else if (filtroForm.tipoDataTipo === 3) {
      valor = "$FINAL_MES";
    }

    if (filtroForm.tipoDataModificador) {
      valor += ";" + filtroForm.tipoDataModificador;
    } else {
      valor += ";0";
    }

    if (filtroForm.tipoDataPeriodo === 1) {
      valor += ";d";
    } else if (filtroForm.tipoDataPeriodo === 2) {
      valor += ";W";
    } else if (filtroForm.tipoDataPeriodo === 3) {
      valor += ";m";
    }

    filtroForm.valor = valor;

    // complementar

    let valorComplementar = "";
    if (filtroForm.tipoDataTipoComplementar === 1) {
      valorComplementar = "$DATA_ATUAL";
    } else if (filtroForm.tipoDataTipoComplementar === 2) {
      valorComplementar = "$INICIO_MES";
    } else if (filtroForm.tipoDataTipoComplementar === 3) {
      valorComplementar = "$FINAL_MES";
    }

    if (filtroForm.tipoDataModificadorComplementar) {
      valorComplementar += ";" + filtroForm.tipoDataModificadorComplementar;
    } else {
      valorComplementar += ";0";
    }

    if (filtroForm.tipoDataPeriodoComplementar === 1) {
      valorComplementar += ";d";
    } else if (filtroForm.tipoDataPeriodoComplementar === 2) {
      valorComplementar += ";W";
    } else if (filtroForm.tipoDataPeriodoComplementar === 3) {
      valorComplementar += ";m";
    }

    filtroForm.valorComplementar = valorComplementar;
  }

  // adicionamos o novo
  filtros.push(filtroForm);
  return Object.assign({}, state);
}

function removerFiltro(state, action) {
  const filtroRemovido = action.value;
  let filtros = state.currentRecord.filtros;
  const idx = getIndex(filtros, filtroRemovido);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    filtros.splice(idx, 1);
  }

  return Object.assign({}, state);
}

function setFiltro(state, action) {
  let filtro = Object.assign({}, state.filtro);
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = filtro;
  current[lastField] = value;
  return Object.assign({}, state, { filtro: filtro });
}

function clearFormFiltro(state) {
  return Object.assign({}, state, { filtro: Object.assign(state.filtro, initialState.filtro) });
}

function adicionarOrdenacao(state, action) {
  let exists = true;
  let ordenacoes = state.currentRecord.grid.ordenacoes;
  if (!ordenacoes) {
    ordenacoes = [];
    exists = false;
  }

  const ordenacaoForm = Object.assign({}, state.ordenacao);
  const idx = getIndex(ordenacoes, ordenacaoForm);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    ordenacoes.splice(idx, 1);
  }

  resolveHash(ordenacaoForm);

  // adicionamos o novo
  ordenacoes.push(ordenacaoForm);

  utils.orderArrayByProperty(ordenacoes, "ordem");

  if (!exists) {
    Object.assign(state.currentRecord.grid, { ordenacoes: ordenacoes });
  }
  return Object.assign({}, state);
}

function removerOrdenacao(state, action) {
  const ordenacaoRemovida = action.value;
  let ordenacoes = state.currentRecord.grid.ordenacoes;
  const idx = getIndex(ordenacoes, ordenacaoRemovida);
  if (idx > -1) {
    // caso ja exista objeto na lista, removemos
    ordenacoes.splice(idx, 1);

    utils.orderArrayByProperty(ordenacoes, "ordem");
  }
  return Object.assign({}, state);
}

function setOrdenacao(state, action) {
  let ordenacao = Object.assign({}, state.ordenacao);
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = ordenacao;
  current[lastField] = value;
  return Object.assign({}, state, { ordenacao: ordenacao });
}

function clearFormOrdenacao(state) {
  return Object.assign({}, state, { ordenacao: Object.assign(state.ordenacao, initialState.ordenacao) });
}

function setDisplayForm(state, action) {
  let displayForm = Object.assign({}, state.displayForm);
  let valor = action.value;
  displayForm[action.field] = valor;
  return Object.assign({}, state, { displayForm });
}

function onChangePerfis(state, action) {
  const perfisDisponiveis = action.source;
  const perfisSelecionados = action.target;

  Object.assign(state, { perfisDisponiveis: perfisDisponiveis });
  Object.assign(state.currentRecord, { perfis: perfisSelecionados });
  return Object.assign({}, state);
}

function beforeSave(state) {
  const record = state.currentRecord;
  removeHash(record.relacionamentos);
  removeHash(record.grid.colunas);
  removeConstantFromColunas(record.grid.colunas);
  removeHash(record.filtros);
  parseFiltros(record.filtros);
  removeHash(record.grid.ordenacoes);
  Object.assign(state, { perfisDisponiveis: [] });
  return Object.assign({}, state, { currentRecord: record });
}

function removeHash(array) {
  if (array && array.length > 0) {
    array.forEach(o => {
      delete o.hash;
    });
  }
}

function parseFiltros(array) {
  if (array && array.length > 0) {
    array.forEach(o => {
      if (o.tipoFiltro) {
        delete o.tipoFiltro.constant;
      }
      if (o.tipoDado) {
        delete o.tipoDado.constant;
      }
      delete o.tipoDataModificador;
      delete o.tipoDataPeriodo;
      delete o.tipoDataTipo;
      delete o.tipoDataModificadorComplementar;
      delete o.tipoDataPeriodoComplementar;
      delete o.tipoDataTipoComplementar;
    });
  }
}

function removeConstantFromColunas(array) {
  if (array && array.length > 0) {
    array.forEach(o => {
      if (o.tipoDado) {
        delete o.tipoDado.constant;
      }
    });
  }
}

function removeRelatorioDinamico() {}

function willUnmount(state, action) {
  const currentRecord = initialState.currentRecord;
  const relacionamento = initialState.relacionamento;
  const coluna = initialState.coluna;
  const relacionamentos = initialState.relacionamentos;
  const colunas = initialState.colunas;
  const filtros = initialState.filtros;
  const ordenacoes = initialState.ordenacoes;
  const perfisDisponiveis = initialState.perfisDisponiveis;

  state = Object.assign({}, state, { relacionamento });
  state = Object.assign({}, state, { coluna });
  state = Object.assign({}, state, { relacionamentos });
  state = Object.assign({}, state, { colunas });
  state = Object.assign({}, state, { filtros });
  state = Object.assign({}, state, { ordenacoes });
  state = Object.assign({}, state, { perfisDisponiveis });
  return Object.assign({}, state, { currentRecord });
}

function reset(state, action) {
  const stt = willUnmount(state, action);
  return {...stt, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}
