import { types } from "./acao.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  id:"acao",
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value:{
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible:true
      }
    },
    {
      label: "Nome",
      value:{
        field: "nome",
        header: "Nome",
        columnKey: "nome",
        sortable: true,
        columnSortField: 'nome',
        filter: true,
        visible:true
      }
    },
  ],
  columns: [],
  currentRecord: {
    oid: "",
    nome: "",
    perfisAcaoSelecionados: [],
    ativo: true
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDAcaoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('acao');
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.PICKLIST_PERFIS_MOVE_TO_SOURCE:
      return pickListPerfisMoveToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE:
      return pickListPerfisMoveAllToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_TO_TARGET:
      return pickListPerfisMoveToTarget(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_TARGET:
      return pickListPerfisMoveAllToTarget(state, action);
    default:
      return result;
  }
}

function buildPerfilAcao(perfis = [], oidAcao) {
  let perfisAcao = perfis.map(perfil => {
    return {
      oidAcao: oidAcao,
      oidPerfil: perfil.oid,
      nomePerfil: perfil.nomePerfil,
      oid: null
    }
  });
  return perfisAcao;
}

function pickListPerfisMoveToSource(state, action) {
  let perfisAcaoDeselecionados = [];

  action.perfisAcaoRemoved.forEach(perfilAcaoRemoved => {
    perfisAcaoDeselecionados = state.currentRecord.perfisAcaoSelecionados.filter(perfilAcao => perfilAcao.oidPerfil !== perfilAcaoRemoved.oidPerfil);
  });

  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados: perfisAcaoDeselecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToSource(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados: [] });
  return Object.assign({}, state, { currentRecord });
}

function filterOldPerfisAcao(perfisAdd, perfisAcaoSelecionados) {
  return perfisAdd.filter(perfil => {
    let existe = perfisAcaoSelecionados.filter(perfilAcao => perfilAcao.oidPerfil === perfil.oid);
    return existe.length > 0 ? false : true;
  });
}

function pickListPerfisMoveToTarget(state, action) {
  let perfisAcaoSelecionados = [...state.currentRecord.perfisAcaoSelecionados];
  let perfisAdd = filterOldPerfisAcao(action.perfisAdd, perfisAcaoSelecionados);
  let perfisAcao = buildPerfilAcao(perfisAdd, action.oidAcao);

  perfisAcaoSelecionados = [...perfisAcaoSelecionados, ...perfisAcao];

  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToTarget(state, action) {
  return pickListPerfisMoveToTarget(state)
}


