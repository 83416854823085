import React from "react";

export function getTemplateDropDownPessoa(pessoa) {
  return (
    <div className="p-grid form-group">
      <div className="p-col-4">
        <span>{formatCnpjCpf(pessoa.numeroDocumento)}</span>
      </div>
      <div className="p-col-6">
        <span>{pessoa.nome} {pessoa.nomeFantasia && <span>({pessoa.nomeFantasia})</span>}</span>
      </div>
    </div>
  );
}

function formatCnpjCpf(value) {
  if (value !== null && value !== undefined && typeof value === "string") {
    const cnpjCpf = value.replace(/\D/g, "");

    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else if (cnpjCpf.length === 14) {
      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }
  return value;
}
