import { types } from "./aquisicoesrelatorio.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "aquisicoesrelatorio",
  columns: [],
  currentRecord: {
    oid: "",
    dataInicial: new Date(),
    dataFinal: new Date(),
    tiposSepulturas: []
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false
};

const reducer = new Reducer(initialState, types);

export function CRUDAquisicoesRelatorioReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("aquisicoesrelatorio");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action);
    default:
      return result;
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}
