import { types } from './grupo.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'grupoFinanceiro',
  booleanFilter: { tipo: '' },
  showHideColumns: [
    {
      label: ' ',
      value: {
        field: 'multiselect',
        header: '',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Cód. Mascarado',
      value: {
        field: 'codigoMascara',
        header: 'Cód. Mascarado',
        columnKey: 'codigoMascara',
        sortable: true,
        filter: true,
        columnSortField: 'codigoMascara',
        visible: true,
      },
    },
    {
      label: 'Descrição',
      value: {
        field: 'descricao',
        header: 'Descrição',
        columnKey: 'descricao',
        sortable: true,
        filter: true,
        columnSortField: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Tipo',
      value: {
        field: 'tipo',
        header: 'Tipo',
        columnKey: 'tipo',
        sortable: true,
        filter: true,
        columnSortField: 'tipo',
        visible: true,
        fieldFormatter: 'getValue',
      },
    },
    {
      label: 'Grupo Pai',
      value: {
        field: 'grupoFinanceiroPai.value',
        header: 'Grupo Pai',
        columnKey: 'grupoFinanceiroPai',
        sortable: true,
        columnSortField: 'grupoFinanceiroPai.descricao',
        filter: true,
        relacionamento: ['grupoFinanceiroPai'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade.value',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        columnSortField: 'unidade.sigla',
        filter: true,
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
    {
      label: 'Cód. Grupo Pai',
      value: {
        field: 'grupoFinanceiroPai.id',
        header: 'Cód. Grupo Pai',
        columnKey: 'grupoFinanceiroPai',
        sortable: true,
        columnSortField: 'grupoFinanceiroPai.oid',
        filter: true,
        relacionamento: ['grupoFinanceiroPai'],
        fieldJoin: 'oid',
        visible: false,
      },
    },
  ],
  columns: [],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      visible: true,
    },
  ],
  currentRecord: {
    oid: '',
    descricao: '',
    unidade: '',
    codigo: '',
    grupoFinanceiroPai: '',
    codigoMascara: '',
    tipo: '',
    cnae: '',
    aliquotaPis: '',
    itemListaServico: '',
    descricaoItemListaServico: '',
    discriminacaoServicoNfse: '',
  },
  lookupGrupo: {
    visible: false,
    modal: true,
    header: 'Grupo',
  },
  selectedFilters: [],
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDGrupoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('grupofinanceiro')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.SET_LOOKUP_GRUPO_VISIBLE:
      return setLookupGrupoVisible(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setLookupGrupoVisible(state, action) {
  let lookupGrupo = state.lookupGrupo
  lookupGrupo = Object.assign({}, lookupGrupo, { visible: action.visible })
  return Object.assign({}, state, { lookupGrupo })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
