import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import authentication from '../services/authentication.service'
import storage from '../services/stoage.service'
import utils from '../utils/index'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => resolveAcesso(props, Component)} />
)

const resolveAcesso = (props, Component) => {
  if (authentication.isAuthenticated()) {
    if (props.location.pathname !== '/') {
      const permissoes = utils.getPermissoesPorMenu(
        storage.getMenus(),
        storage.getOidCurrentMenu(),
        props.location.pathname,
        storage.getPortalCliente()
      )

      if (!permissoes.alterar && !permissoes.inserir && !permissoes.listar && !permissoes.excluir) {
        props.location.pathname = '/'
        props.location.search = ''
        props.location.state = undefined
        props.history.location = props.location
        props.history.push('/')
      }
    }
    return <Component {...props} />
  }
  return <Redirect to="/login" />
}

export default PrivateRoute
