import React from "react";
import { InputText } from "primereact/inputtext";
import { PickList } from "primereact/picklist";
import { getMessage } from "../../../utils/index";

class AcaoForm extends React.Component {
  handleTamplatePerfil = objeto => {
    let oid = objeto.oidPerfil ? objeto.oidPerfil : objeto.oid;
    let nome = objeto.nomePerfil ? objeto.nomePerfil : objeto.nome;

    return `${oid} - ${nome}`;
  };

  handleMoveToTarget = e => {
    this.props.pickListPerfisMoveToTarget(e.value, this.props.record.oid);
  };
  handleMoveToSource = e => {
    this.props.pickListPerfisMoveToSource(e.value);
  };
  handleMoveAllToSource = e => {
    this.props.pickListPerfisMoveAllToSource();
  };

  handleMoveAllToTarget = e => {
    this.props.pickListPerfisMoveAllToTarget(this.props.recordPerfis, this.props.record.oid);
  };
  render() {
    const { props } = this;
    const record = props.record;
    const recordPerfis = props.recordPerfis;
    const messages = props.messages;
    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>
                {getMessage(messages, "acao.label.acao")} {record.oid || ""}
              </h3>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
                  <label className="label">{getMessage(messages, "acao.label.nome")}</label>
                  <InputText value={record.nome} onChange={props.changeHandler} name="nome" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{getMessage(messages, "acao.label.perfis")}</h3>
              <div className="p-grid form-group">
                <div className="p-col-6">
                  <PickList
                    className="p-col-12"
                    source={[...recordPerfis]}
                    itemTemplate={this.handleTamplatePerfil}
                    responsive={true}
                    target={props.record.perfisAcaoSelecionados}
                    onMoveToTarget={this.handleMoveToTarget}
                    onMoveToSource={this.handleMoveToSource}
                    onMoveAllToSource={this.handleMoveAllToSource}
                    onMoveAllToTarget={this.handleMoveAllToTarget}
                    sourceHeader={getMessage(messages, "acao.label.disponiveis")}
                    targetHeader={getMessage(messages, "acao.label.selecionados")}
                    showSourceControls={false}
                    showTargetControls={false}
                  ></PickList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcaoForm;
