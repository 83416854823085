import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import utils from '../../utils/index'
import storage from '../../services/stoage.service'
import { actions as tipoSepulturaActions } from '../tipoSepultura/tipoSepultura.actions'
import _ from 'lodash'

const BASE_PATH = '/lottustech/relatorios'
const crud = build(validate, BASE_PATH)
const prefix = 'crudTiposSepulturasRelatorio'

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  GET_OPCOES_PESQUISA: `${prefix}getOpcoesPesquisa`,
  SET_OPCOES_PESQUISA: `${prefix}setOpcoesPesquisa`,
  SET_FILTER_LOCALIZACAO_GRID: `${prefix}setFilterLocalizacaoGrid`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.setOpcoesPesquisa = (payload) => {
  return {
    type: types.SET_OPCOES_PESQUISA,
    payload,
  }
}
actions.setFilterLocalizacaoGrid = (field, value) => {
  return {
    type: types.SET_FILTER_LOCALIZACAO_GRID,
    field,
    value,
  }
}

actions.abrirRelatorio = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudTiposSepulturasRelatorioState`]
    const oidUnidade = _.get(state, 'currentRecord.unidadeSelecionada.id', null)
    const oidTipoSepultura = _.get(state, 'currentRecord.tipoSepulturaSelecionada.id', null)
    const selectedFilters = _.get(state, 'selectedFilters', [])
    const filtrosLocalizacao = selectedFilters.map((f) => {
      return { key: f.campoPesquisa.key, value: f.valorSelecionado.value }
    })
    const newParams = {
      oidUnidade,
      oidTipoSepultura,
      filtrosLocalizacao,
    }
    const path = BASE_PATH + `/relatoriotipossepulturas`
    return apiService.post(path, newParams).then((response) => {
      const win = window.open(response.data)
      if (win) {
        win.focus()
      }
    })
  }
}

actions.getOpcoesPesquisa = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudTiposSepulturasRelatorioState`]
    const oidUnidade = _.get(state, 'currentRecord.unidadeSelecionada.id', null)
    const oidTipoSepultura = _.get(state, 'currentRecord.tipoSepulturaSelecionada.id', null)
    const filtros = state.selectedFilters
    const filtrosPreenchidos = filtros.filter((f) => {
      return f.valorSelecionado && f.valorSelecionado.value // para permitir valor 'em branco' && f.valorSelecionado.value.trim().length > 0
    })
    const params = filtrosPreenchidos.map((f) => {
      return { key: f.campoPesquisa.key, value: f.valorSelecionado.value }
    })
    return apiService
      .post(`/lottustech/localizacoes/opcoespesquisatelacinema/${oidUnidade}/${oidTipoSepultura}`, params)
      .then((response) => {
        dispatch(actions.setOpcoesPesquisa(response.data))
      })
  }
}

actions.resolvePesquisaLocalizacaoGrid = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudTiposSepulturasRelatorioState`]
    const oidUnidade = _.get(state, 'currentRecord.unidadeSelecionada.id', null)
    const oidTipoSepultura = _.get(state, 'currentRecord.tipoSepulturaSelecionada.id', null)
    const empresa = storage.getCompany()
    const ultimoCampoPesquisa = utils.getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura)
    const filtros = state.selectedFilters
    const ultimoCampoPesquisaSelecionado = filtros.find((f) => f.valorSelecionado.key === ultimoCampoPesquisa)
    if (!ultimoCampoPesquisaSelecionado) {
      dispatch(actions.getOpcoesPesquisa())
    }
  }
}

actions.carregarTiposSepultura = (oidUnidade) => {
  return (dispatch, getState) => {
    return apiService
      .get(`${BASE_PATH}/getTiposSepulturaLocalizacaoGrid/${oidUnidade}`, oidUnidade)
      .then((response) => {
        dispatch(tipoSepulturaActions.setRecords(response.data))
      })
  }
}

export { types, actions }
