import React from "react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import utils from "../../../utils/index";
import {InputText} from "primereact/inputtext";

export const SepulturasDevedorasRelatorioForm = props => {
  const record = props.record;

  return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                  <label className="label">Unidade</label>
                  <MultiSelect
                      value={record.unidade}
                      options={utils.convertArrayIdValueToIdLabel(props.unidades)}
                      name="unidade"
                      optionLabel="label"
                      dataKey="id"
                      selectedItemsLabel="{0} itens selecionados"
                      onChange={props.onChangeUnit}
                      disabled={props.disableUnit}
                  />
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                  <label className="label">Tipo de Sepultura</label>
                  <MultiSelect
                      value={record.tiposSepulturas}
                      options={utils.convertArrayIdValueToIdLabel(record.opcoesTiposSepulturas)}
                      name="tiposSepulturas"
                      optionLabel="label"
                      dataKey="id"
                      selectedItemsLabel="{0} itens selecionados"
                      onChange={props.changeHandler}
                  />
                </div>
              </div>
              <div className="p-grid form-group p-align-center">
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-1">
                  <label className="label">Conspaga</label>
                </div>
                <div className="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-2">
                  <Dropdown
                      autoWidth={false}
                      value={props.state.filtroConspaga.tipoFiltro}
                      options={props.tiposFiltros}
                      onChange={props.changeTipoFiltroConspaga}
                      optionLabel="label"
                      optionValue={(v) => v}
                      name="tipo"
                  />
                </div>

                {props.state.filtroConspaga.mostrarAnoInicial ? (
                    <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-1">
                      <InputText
                          keyfilter="pint"
                          name="conspagaInicial"
                          value={props.state.filtroConspaga.conspagaInicial}
                          onChange={props.changeAnoConspaga}
                          min={0}
                      />
                    </div>
                ) : (
                    ""
                )}

                {props.state.filtroConspaga.mostrarAnoFinal ? (
                    <div className="p-col-12 p-sm-12 p-md-4 p-lg-2 p-xl-1">
                      <InputText
                          keyfilter="pint"
                          name="conspagaFinal"
                          value={props.state.filtroConspaga.conspagaFinal}
                          onChange={props.changeAnoConspaga}
                          min={0}
                      />
                    </div>
                ) : (
                    ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
