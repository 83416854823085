import React from 'react';

import FuncaoDinamicaFormPageContainer from './FuncaoDinamicaFormPageContainer';

export class FuncaoDinamicaFormPage extends React.Component {

    render() {
        return (
            <FuncaoDinamicaFormPageContainer {...this.props} />
        )
    }
}
