import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { MultiSelect } from 'primereact/multiselect'
import utils from '../../utils'

export default function LtsMultiselectIdValue(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    loadData()
  }, [props.loadDataFnParam])

  const loadData = () => {
    const loadDataFn = _.get(props, 'loadDataFn', null)
    if (loadDataFn) {
      if (props.hasOwnProperty('loadDataFnParam')) {
        loadDataFn.call(loadDataFn, props.loadDataFnParam).then(onSuccessLoadData).catch(onErrorLoadData)
      } else {
        loadDataFn.call(loadDataFn).then(onSuccessLoadData).catch(onErrorLoadData)
      }
    } else {
      const options_ = _.get(props, 'options', [])
      if (options_.length > 0) {
        setOptions(options_)
      }
    }
  }

  const onSuccessLoadData = (response) => {
    const data = _.get(response, 'data', [])

    // hook para componentes que usam internamente esse componente possam
    // manipular os dados antes de serem renderizados
    if (props.hasOwnProperty('afterLoadDataFn')) {
      setOptions(props.afterLoadDataFn(data))
    } else {
      setOptions(data)
    }
  }

  const onErrorLoadData = (error) => {
    console.log(error)
  }

  const onChange = (e) => {
    props.onChange(e)
  }

  const resolveName = () => {
    return props.name
  }

  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <MultiSelect
          value={props.value}
          options={utils.convertArrayIdValueToIdLabel(options)}
          name={resolveName()}
          optionLabel="label"
          dataKey="id"
          selectedItemsLabel="{0} itens selecionados"
          onChange={onChange}
          style={props.style}
          filter={true}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
