import React from 'react';
import DinamicaListPageContainer from './DinamicaListPageContainer';

export class DinamicaListPage extends React.Component{
    
    render(){
      return (
        <DinamicaListPageContainer {...this.props}/>
      )
    }
}

