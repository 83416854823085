import { types } from './permissao.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'permissao',
  booleanFilter: {
    listar: '',
    inserir: '',
    alterar: '',
    excluir: '',
  },
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Menu',
      value: {
        field: 'menu.value',
        header: 'Menu',
        columnKey: 'menu',
        sortable: true,
        columnSortField: 'menu.nome',
        filter: true,
        relacionamento: ['menu'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Perfil',
      value: {
        field: 'perfil.value',
        header: 'Perfil',
        columnKey: 'perfil',
        sortable: true,
        columnSortField: 'perfil.nomePerfil',
        filter: true,
        relacionamento: ['perfil'],
        fieldJoin: 'nomePerfil',
        visible: true,
      },
    },
    {
      label: 'Listar',
      value: {
        field: 'listar',
        header: 'Listar',
        columnKey: 'listar',
        sortable: true,
        columnSortField: 'listar',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Inserir',
      value: {
        field: 'inserir',
        header: 'Inserir',
        columnKey: 'inserir',
        sortable: true,
        columnSortField: 'inserir',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Alterar',
      value: {
        field: 'alterar',
        header: 'Alterar',
        columnKey: 'alterar',
        sortable: true,
        columnSortField: 'alterar',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Remover',
      value: {
        field: 'excluir',
        header: 'Remover',
        columnKey: 'excluir',
        sortable: true,
        columnSortField: 'excluir',
        filter: true,
        visible: true,
      },
    },
  ],

  columns: [],
  currentRecord: {
    oid: '',
    menu: '',
    perfil: '',
    listar: false,
    inserir: false,
    alterar: false,
    excluir: false,
  },
  lookupMenu: {
    visible: false,
    modal: true,
    header: 'Menus',
  },
  lookupPerfil: {
    visible: false,
    modal: true,
    header: 'Perfis',
  },
  messages: null,
  selectedRecords: [],
}

const reducer = new Reducer(initialState, types)

export function CRUDPermissaoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('permissao')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_LOOKUP_MENU_VISIBLE:
      return setLoockupMenuVisible(state, action)
    case types.SET_LOOKUP_PERFIL_VISIBLE:
      return setLoockupPerfilVisible(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    default:
      return result
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setLoockupMenuVisible(state, action) {
  let lookupMenu = state.lookupMenu
  lookupMenu = Object.assign({}, lookupMenu, { visible: action.visible })
  return Object.assign({}, state, { lookupMenu })
}

function setLoockupPerfilVisible(state, action) {
  let lookupPerfil = state.lookupPerfil
  lookupPerfil = Object.assign({}, lookupPerfil, { visible: action.visible })
  return Object.assign({}, state, { lookupPerfil })
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
    booleanFilter: initialState.booleanFilter,
    selectedRecords: [],
  }
}
