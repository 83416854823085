import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
const BASE_PATH = "/lottustech/dynamicdata";
const crud = build(validate, BASE_PATH);
const prefix = "crudDinamica";

const newTypes = {
  SET_ADVANCED_FILTER: `${prefix}setAdvancedFilter`,
  CLEAR_SORT_FIELDS: `${prefix}clearSortFields`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setAdvancedFilter = (field, value, hash) => {
  return {
    type: types.SET_ADVANCED_FILTER,
    field,
    value,
    hash
  };
};

actions.clearSortFields = () => {
  return {
    type: types.CLEAR_SORT_FIELDS
  };
};

export { types, actions };
