export const types = {
  MESSAGE_SHOW_MESSAGES: 'message.showMessages',
  MESSAGE_CLEAR_MESSAGES: 'message.clearMessages',
}

export function messageShowMessages(messages) {
  return {
    type: types.MESSAGE_SHOW_MESSAGES,
    show: true,
    messages,
  }
}

export function messageClearMessages() {
  return {
    type: types.MESSAGE_CLEAR_MESSAGES,
  }
}

export function showMessages(messages, type) {
  return messageShowMessages(makeMessages(messages, type))
}

const makeMessages = (messages, type) => {
  return messages.map((message) => {
    return {
      sticky: '',
      severity: type,
      detail: message,
    }
  })
}
