import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import utils from "../../utils";
const BASE_PATH = "/lottustech/relatorios";
const crud = build(validate, BASE_PATH);
const prefix = "crudContasReceberRelatorio";

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.loadOpcoesGruposFinanceiros = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudContasReceberRelatorioState`];
    let filtroUnidade = null;
    if (state && state.currentRecord && utils.isArrayNotEmpty(state.currentRecord.unidade)) {
      filtroUnidade = {
        campo: "unidade",
        valor: state.currentRecord.unidade.map(u => u.id),
        tipoFiltro: "ESTA_CONTIDO_EM"
      };
    }

    let params = {
      index: 0,
      max: 1000,
      projectionFields: ["oid", "descricao"],
      filters: filtroUnidade ? [filtroUnidade] : []
    };
    const path = "/lottustech/gruposfinanceiros/all";
    return apiService.post(path, params).then(response => {
      if (response.data && response.data.records && response.data.records.length >= 0) {
        let formatResult = response.data.records.map(grupo => {
          return { id: grupo.oid, value: `${grupo.oid} - ${grupo.descricao}` };
        });

        dispatch(actions.setField("gruposFinanceirosOpcoes", formatResult));
      }
    });
  };
};

actions.carregarTiposSepulturas = unidade => {
  let params = {
    unidades: unidade
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/tipossepulturas/`;
    const state = getState()[`crudContasReceberRelatorioState`];
    return apiService.post(path, params).then(response => {
      if (state.currentRecord.tiposSepulturas.length > 0) {
        let itensRemover = [];
        state.currentRecord.tiposSepulturas.forEach(tipo => {
          const obj = utils.findObjectInArray(response.data, "value", tipo.value);
          if (!obj) {
            itensRemover.push(tipo.value);
          }
        });
        itensRemover.forEach(itemRemover => {
          utils.removeObjectFromArray(state.currentRecord.tiposSepulturas, "value", itemRemover);
        });
      }
      dispatch(actions.setField("opcoesTiposSepulturas", response.data));
    });
  };
};

actions.abrirRelatorio = (
  dataIni,
  dataFin,
  dataVencimentoInicial,
  dataVencimentoFinal,
  unidade,
  formasPagamento,
  gruposFinanceiros,
  tiposSepulturas,
  tipoRelatorio
) => {
  let params = {
    dataInicial: dataIni,
    dataFinal: dataFin,
    dataVencimentoInicial,
    dataVencimentoFinal,
    unidades: unidade,
    formasPagamentos: formasPagamento,
    gruposFinanceiros: gruposFinanceiros,
    tiposSepulturas: tiposSepulturas,
    tipoRelatorio: tipoRelatorio
  };
  return (dispatch, getState) => {
    const path = BASE_PATH + `/contasreceber`;
    return apiService.post(path, params).then(response => {
      const win = window.open(response.data);
      if (win) {
        win.focus();
      }
    });
  };
};

export { types, actions };
