export const routes = {
  PATH_ESQUECI_SENHA_PORTAL_CLIENTE: '/portalcliente/esquecisenha',
  PATH_PESSOA: '/geral/configuracoes/pessoas',
  PATH_PESSOA_FISICA: '/geral/cadastros/pessoasFisicas',
  PATH_PESSOA_JURIDICA: '/geral/cadastros/pessoasJuridicas',
  PATH_EMPRESA: '/geral/configuracoes/empresas',
  PATH_USUARIO: '/geral/configuracoes/usuarios',
  PATH_PERFIL: '/geral/configuracoes/perfis',
  PATH_MODULO: '/geral/configuracoes/modulos',
  PATH_ACAO: '/geral/configuracoes/acoes',
  PATH_PERMISSAO: '/geral/configuracoes/permissoes',
  PATH_UNIDADE: '/geral/configuracoes/unidades',
  PATH_MENU: '/geral/configuracoes/menus',
  PATH_RELATORIO_DINAMICO: '/geral/configuracoes/relatoriosDinamicos',

  PATH_RELIGIAO: '/geral/cadastros/religioes',
  PATH_TIPO_SEPULTURA: '/geral/cadastros/tipoSepultura',
  PATH_CATEGORIA: '/geral/cadastros/categorias',
  PATH_MARCADOR: '/geral/cadastros/marcadores',
  PATH_SEXO: '/geral/cadastros/sexos',
  PATH_CONVENIO: '/geral/consulta/convenios',
  PATH_CONSULTA_OBITUARIO: '/geral/consulta/obituario',
  PATH_CONTRATO: '/geral/cadastros/contratos',
  PATH_GRUPO_FINANCEIRO: '/geral/cadastros/financeiro/grupos',
  PATH_TAXA_FINANCEIRO: '/geral/cadastros/financeiro/taxas',
  PATH_LANCAMENTO_FINANCEIRO: '/geral/cadastros/financeiro/lancamentosFinanceiros',
  PATH_DASHBOARD: '/',
  PATH_FUNCAO_DINAMICA: '/geral/configuracoes/funcoesdinamicas',
  PATH_PARAMETROS_UNIDADE: '/geral/configuracoes/parametrosUnidade',
  PATH_PARAMETROS_EMPRESA: '/geral/configuracoes/parametrosEmpresa',
  PATH_LOCALIZACAO: '/geral/cadastros/localizacoes',
  PATH_LOCALIZACAO_GRID: '/geral/cadastros/localizacaoGrid',
  PATH_ITENS_LANCAMENTO_FINANCEIRO: '/geral/cadastros/financeiro/itenslancamentofinanceiro',
  PATH_BOTAO_DINAMICO: '/geral/configuracoes/botaodinamico',
  PATH_CONFIGURACAO_BOLETO: '/geral/configuracoes/configuracoesboletos',
  PATH_CONTROLE_FINANCEIRO: '/geral/cadastros/financeiro/remessaFinanceira',
  PATH_NFSE: '/geral/cadastros/financeiro/nfse',
  PATH_LIVRO_CAIXA: '/geral/relatorios/livrocaixa',
  PATH_MOVIMENTO_CAIXA: '/geral/relatorios/movimentocaixa',
  PATH_DRE: '/geral/relatorios/dre',
  PATH_AQUISICOES_RELATORIO: '/geral/relatorios/aquisicoes',
  PATH_CARTAO_CREDITO_RELATORIO: '/geral/relatorios/cartaocredito',
  PATH_CONTAS_RECEBER: '/geral/relatorios/contasreceber',
  PATH_RECEITAS_DESPESAS_RELATORIO: '/geral/relatorios/receitasdespesas',
  PATH_FALECIDOS_RELATORIO: '/geral/relatorios/falecidos',
  PATH_EXUMACOES_RELATORIO: '/geral/relatorios/exumacoes',
  PATH_ISENTOS_RELATORIO: '/geral/relatorios/isentos',
  PATH_TIPOS_SEPULTURAS_RELATORIO: '/geral/relatorios/tipossepulturas',
  PATH_SEPULTURAS_DEVEDORAS: '/geral/relatorios/sepulturasdevedoras',
  PATH_CONSULTA_BAIXA_RELATORIO: '/geral/relatorios/consultabaixa',
  PATH_CONSULTA_BAIXA: '/geral/cadastros/financeiro/consultaBaixa',
  PATH_MENSAGERIA: '/geral/cadastros/mensageria',
  PATH_AGENDAMENTO_MENSAGERIA: '/geral/cadastros/envioMensagens',
  PATH_MENSAGERIA_LOG: '/geral/configuracoes/mensagerialog',
  PATH_PORTAL_CLIENTE_FINANCEIRO: '/portalCliente/financeiro',
  PATH_PORTAL_CLIENTE_CONTRATO: '/portalCliente/contrato',
  PATH_PORTAL_CLIENTE_FALECIDO: '/portalCliente/falecidos',
  PATH_PORTAL_CLIENTE_CARTEIRINHA: '/portalCliente/carteirinhas',
  PATH_PORTAL_CLIENTE_CONSULTA_CLIENTE: '/portalCliente/consultaCliente',
  PATH_CONSULTA_REMESSA: '/geral/cadastros/financeiro/consultaRemessa',
  PATH_CONFIG_AILOS: '/geral/configuracoes/configAilos',
  PATH_AGENDA: '/geral/cadastros/agenda',
  PATH_DOCUMENTO: '/geral/cadastros/documentos',
  PATH_ORCAMENTO: '/geral/cadastros/financeiro/orcamento',
  PATH_PRODUTO: '/geral/cadastros/produtos',
  PATH_EMPRESTIMO: '/geral/cadastros/emprestimos',
  PATH_NOTA_ENTRADA: '/geral/cadastros/financeiro/notaEntrada',
  PATH_ENDERECO: '/geral/cadastros/enderecos',
  PATH_CONTA_CORRENTE: '/geral/cadastros/contasCorrente',
  PATH_MAPA: '/geral/cadastros/mapas',
  PATH_MAPA_COORDENADA: '/geral/cadastros/mapaCoordenada',
  PATH_BI: '/geral/bi',
  PATH_TELA_DINAMICA_RELATORIO: '/geral/relatorios/teladinamica',
}

export const enums = {
  ROWS: 15,
  ROWSPERPAGEOPTIONS: [15, 50, 100, 500, 1000],
  CONVERTER_FORMA_PAGAMENTO: {
    BAIXA: 'À vista',
    SUBSTITUIDO: 'Substituído',
    CARTAO_DE_CREDITO: 'Cartão de Crédito',
    CAIXA: 'Caixa',
    BOLETO: 'Boleto',
    DINHEIRO: 'Dinheiro',
    CARTAO_CREDITO: 'Cartão de Crédito',
    CARTAO_DEBITO: 'Cartão de Débito',
    CHEQUE: 'Cheque',
    TRANSFERENCIA: 'Transferência Bancária',
    SEGURADORA: 'Seguradora',
    DEBITO_AUTOMATICO: 'Débito Automático',
    LINK_PAGAMENTO: 'Link de pagamento',
    CARTAO_CREDITO_VINDI: 'Cartão de Crédito - Vindi',
    CARNE: 'Carnê',
    A_DEFINIR: 'A Definir',
  },

  CONVERTER_TIPO_PRODUTO: {
    PRODUTO: 'Produto',
    EQUIPAMENTO: 'Equipamento',
    ECOMMERCE: 'Ecommerce',
  },

  TipoLancamento: {
    1: 'Entrada',
    2: 'Saída',
  },

  TipoTaxa: {
    ENTRADA: 1,
    SAIDA: 2,
  },

  TipoLog: {
    PESSOA_FISICA: 1,
    PESSOA_JURIDICA: 2,
    LANCAMENTO: 3,
    CONTRATO: 4,
    AGENDA: 5,
    ORCAMENTO: 6,
    NOTA_ENTRADA: 7,
  },

  StatusLancamento: {
    1: 'Em Aberto',
    3: 'Pago',
    4: 'Cancelado',
  },
  ID_STATUS_LANCAMENTO_PAGO: 3,

  Status: {
    0: 'TODOS',
    1: 'SUCESSO',
    2: 'ERRO',
  },

  ArrayStatusLancamento: [
    { id: null, value: 'Todos' },
    { id: 1, value: 'Em Aberto' },
    { id: 3, value: 'Pago' },
    { id: 4, value: 'Cancelado' },
  ],

  ArrayStatus: [
    { id: null, value: 'TODOS' },
    { id: 1, value: 'SUCESSO' },
    { id: 2, value: 'ERRO' },
  ],

  TipoMensagem: {
    EMAIL: 1,
    WHATSAPP: 2,
  },
  TipoTela: {
    FORMULARIO: 1,
    LISTAGEM: 2,
    FORM_SPLIT_BUTTON: 3,
  },
  EntidadeBase: {
    CONTRATO: 'br.com.lottustech.phoenix.contrato.Contrato',
  },

  EspecieDocumento: [
    { id: 1, value: 'DM – Duplicata Mercantil' },
    { id: 2, value: 'DS – Duplicata de Serviço' },
    { id: 3, value: 'NP – Nota Promissória' },
    { id: 4, value: 'MENS – Mensalidade' },
    { id: 5, value: 'NF – Nota Fiscal' },
    { id: 6, value: 'RECI - Recibo' },
    { id: 7, value: 'OUTR – Outros' },
  ],

  TiposPagamentoDivergente: [
    { id: 0, value: 'Não autorizar' },
    { id: 1, value: 'Autorizar com valor mínimo' },
    { id: 2, value: 'Qualquer valor maior que zero' },
  ],

  TipoInstrucaoValor: [
    { id: 1, value: 'Valor em Reais' },
    { id: 2, value: 'Percentual' },
    { id: 3, value: 'Isento' },
  ],

  TipoValor: [
    { id: 1, value: 'Valor em Reais' },
    { id: 2, value: 'Percentual' },
  ],
  ID_CELESC: 12,
  ID_CARTAO_CREDITO_VINDI: 13,
  ID_DEBITO_AUTOMATICO: 14,
  ID_CERGRAL: 15,
  ID_FORMA_PAGTO_A_DEFINIR: 99,
  ID_FORMA_PAGTO_SEGURADORA: 11,
  FormasPagamento: [
    { id: 2, value: 'Substituído' },
    { id: 5, value: 'Boleto' },
    { id: 6, value: 'Dinheiro' },
    { id: 7, value: 'Cartão de Crédito' },
    { id: 8, value: 'Cartão de Débito' },
    { id: 9, value: 'Cheque' },
    { id: 10, value: 'Transferência Bancária' },
    { id: 11, value: 'Seguradora' },
    { id: 12, value: 'Celesc' },
    { id: 13, value: 'Cartão de Crédito - Vindi' },
    { id: 14, value: 'Débito automático' },
    { id: 15, value: 'Cergral' },
    { id: 16, value: 'Link de pagamento' },
    { id: 17, value: 'Carnê' },
    { id: 99, value: 'A Definir' },
  ],
  FormasPagamentoBasica: [
    { id: 5, value: 'Boleto' },
    { id: 6, value: 'Dinheiro' },
    { id: 7, value: 'Cartão de Crédito' },
    { id: 8, value: 'Cartão de Débito' },
    { id: 9, value: 'Cheque' },
    { id: 10, value: 'Transferência Bancária' },
    { id: 11, value: 'Seguradora' },
    { id: 12, value: 'Celesc' },
    { id: 14, value: 'Débito automático' },
    { id: 15, value: 'Cergral' },
    { id: 16, value: 'Link de pagamento' },
    { id: 17, value: 'Carnê' },
    { id: 99, value: 'A Definir' },
  ],
  FormasPagamentoRemessa: [
    { id: 5, value: 'Boleto' },
    { id: 13, value: 'Cartão de Crédito - Vindi' },
    { id: 12, value: 'Celesc' },
    { id: 14, value: 'Débito automático' },
    { id: 15, value: 'Cergral' },
    { id: 99, value: 'A Definir' },
  ],
  TipoProduto: [
    { id: 1, value: 'Produto' },
    { id: 2, value: 'Equipamento' },
  ],

  StatusNotaEntrada: {
    GERADA: 1,
    CONFIRMADA: 2,
    CANCELADA: 3,
  },

  idFormaPagamentoBoleto: 5,

  TipoPessoa: {
    JURIDICA: 1,
    FISICA: 2,
    AMBAS: 3,
  },
}

export const icons = {
  REMOVE_ICON: 'pi pi-trash',
  UNLOCK_ICON: 'pi pi-lock-open',
  LOCK_ICON: 'pi pi-lock',
  DOWNLOAD: 'pi pi-cloud-download',
  COPY_ICON: 'pi-md-content-copy',
  SAVE_ICON: 'pi pi-save',
  SAVE_LARGE_ICON: 'pi pi-cloud-upload',
  PLUS_ICON: 'pi pi-plus',
  TRASH_ICON: 'pi pi-trash',
  TRASH_LARGE_ICON: 'pi-md-trash',
  SEARCH_ICON: 'pi pi-search',
  DOWNLOAD_TOOLBAR_ICON: 'pi pi-download',
  PENCIL_ICON: 'pi pi-pencil',
  PENCIL_SMALL_ICON: 'pi pi-pencil',
  CHECK_ICON: 'pi pi-check',
  TIMES_ICON: 'pi pi-times',
  PERSON_ICON: 'pi-md-person',
  VIEW_LIST: 'pi-md-view-list',
  RECEIPT_ICON: 'pi pi-file',
  CREDIT_CARD_ICON: 'pi pi-credit-card',
  BAIXAR_ICON: 'pi pi-dollar',
  UNDO: 'pi-md-undo',
  PRINT: 'pi pi-print',
  WHATSAPP: 'pi pi-whatsapp',
  VIEW: 'pi pi-eye',
  PI_BAN: 'pi pi-ban',
  MAP_MARKER: 'pi pi-map-marker',
  EXTERNAL_LINK: 'pi pi-external-link',
}

export const mascaras = {
  MASCARA_DATA_DD_MM_YY: 'dd/mm/yy',
  MASCARA_DATA_MM_YY: 'mm/yy',
}

export const acoes = {
  ACAO_ORDENAR_RESPONSAVEL: 'ACAO_ORDENAR_RESPONSAVEL',
  ACAO_ALTERAR_HISTORICO: 'ACAO_ALTERAR_HISTORICO',
  ACAO_REMOVER_HISTORICO: 'ACAO_REMOVER_HISTORICO',
  ACAO_ALTERAR_CAUSA_MORTIS: 'ACAO_ALTERAR_CAUSA_MORTIS',
  ACAO_REMOVER_CAUSA_MORTIS: 'ACAO_REMOVER_CAUSA_MORTIS',
  ACAO_EDITAR_CONSPAGA: 'ACAO_EDITAR_CONSPAGA',
  ACAO_REMOVER_RESPONSAVEL: 'ACAO_REMOVER_RESPONSAVEL',
  ACAO_REMOVER_FALECIDO: 'ACAO_REMOVER_FALECIDO',
  ACAO_CANCELAR_LANCAMENTOS: 'ACAO_CANCELAR_LANCAMENTOS',
  ACAO_EDITAR_DATA_EXUMACAO: 'ACAO_EDITAR_DATA_EXUMACAO',
  ACAO_UNIR_LANCAMENTOS: 'ACAO_UNIR_LANCAMENTOS',
  ACAO_ACOES_FALECIDO: 'ACAO_ACOES_FALECIDO',
  ACAO_CREMAR_FALECIDO: 'ACAO_CREMAR_FALECIDO',
  ACAO_RELATORIO_CARTEIRINHA: 'ACAO_RELATORIO_CARTEIRINHA',
  ACAO_EXIBIR_TIMELINE: 'ACAO_EXIBIR_TIMELINE',
  ACAO_INATIVAR_CONTRATO: 'ACAO_INATIVAR_CONTRATO',
  ACAO_IMPORTAR_CSV: 'ACAO_IMPORTAR_CSV',
  CRIAR_COBERTURA_PLANO: 'CRIAR_COBERTURA_PLANO',
  ALTERAR_COBERTURA_PLANO: 'ALTERAR_COBERTURA_PLANO',
  REMOVER_COBERTURA_PLANO: 'REMOVER_COBERTURA_PLANO',
  CAIXA_USUARIO: 'CAIXA_USUARIO',
  BLOQUEAR_LANCAMENTO: 'BLOQUEAR_LANCAMENTO',
  EXCLUIR_LOCALIZACAO: 'EXCLUIR_LOCALIZACAO',
}

export const oidBotoesDinamicos = {
  DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_URNA: 16,
  DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_PEDIDO_RESTO: 18,
  DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_OUTRO: 17,
  DECLARACAO_TRANSFERENCIA_RESPONSABILIDADE_TUMULO: 19,
  DECLARACAO_ASSUMIR_RESPONSABILIDADE: 15,
  DECLARACAO_ASSUMIR_PAGA_CINCO_ANOS: 14,
  DECLARACAO_DESISTENCIA_URNA_PEDIDO_RESTOS_MORTAIS: 6,
  DECLARACAO_AUTORIZACAO_PUBLICIDADE_OBITO: 7,
  DECLARACAO_REQUERIMENTO_CREMACAO: 3,
  DECLARACAO_TROCA_URNA: 21,
  DECLARACAO_TROCA_TUMULO: 22,
  DECLARACAO_TRANSLADO_PARA_OUTRO_JAZIGO: 23,
  DECLARACAO_COMUNICADO_DESAPROPRIACAO: 20,
}

export const tipoDocumentoConst = {
  AUTORIZACAO_SEPULTAMENTO_URNA: 'AUTORIZACAO_SEPULTAMENTO_URNA',
  AUTORIZACAO_SEPULTAMENTO_TUMULO: 'AUTORIZACAO_SEPULTAMENTO_TUMULO',
  REQUERIMENTO_CREMACAO: 'REQUERIMENTO_CREMACAO',
  AUTORIZACAO_PUBLICACAO_OBITO: 'AUTORIZACAO_PUBLICACAO_OBITO',
  FICHA_CONTRATO: 'FICHA_CONTRATO',
}

export const OID_PERFIL_PORTAL_CLIENTE = 30
export const OID_PERFIL_PORTAL_CONVENIADO = 31
export const LANCAMENTO_EM_ABERTO = 1
export const LANCAMENTO_PAGO = 3
export const LANCAMENTO_CANCELADO = 4
export const SMALL_WINDOW_WIDTH = 700
