import React from "react";
import { connect } from "react-redux";
import { Messages } from "primereact/messages";

const mapStateToProps = state => {
  return {
    messages: state.messageState.messages
  };
};

class GrowlMessenger extends React.Component {


  componentDidUpdate(prevProps) {
    if (prevProps.messages !== this.props.messages) {
      if (this.props.messages && this.props.messages.length > 0) {
        this.messages.show(this.props.messages);
      } else {
        this.messages.clear();
      }
    }
  }
  
  render() {   

    const callback = (e) => {
        if (e.callbackParams) {
          this.messages.clear();
          e.callbackParams.actionsFactoryRef.loadRecord(e.callbackParams.oid, e.callbackParams.dispatch);
        }
    };

    let screen = null;
    if (this.props.messages) {      
      screen = (
        <div>
          <Messages 
            ref={el => (this.messages = el)}
            onClick={callback}
            style={{ marginLeft: "20px", marginRight: "20px" }} />
        </div>
      );
    }
    return screen;
  }
}

export default connect(mapStateToProps)(GrowlMessenger);
