import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { icons, routes } from '../../../constants'
import { actions } from '../produto.actions'
import { ProdutoForm } from './ProdutoForm'
import utils from '../../../utils'
import _ from 'lodash'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import LtsInputText from '../../../components/lottus/lts-input-text'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const unidades = utils.getListIdValuesUnidadeDisponiveisUsuario()
  const adicionandoAnexo = state.crudProdutoState.adicionandoAnexo
  return {
    record: state.crudProdutoState.currentRecord,
    messages: state.crudProdutoState.messages,
    unidades,
    colunasFornecedores: state.crudProdutoState.colunasFornecedores,
    stateMarcador: state.crudMarcadorState,
    adicionandoAnexo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    addAnexo(anexo) {
      dispatch(actions.processaAnexo(anexo))
    },
    removeAnexo(anexo) {
      dispatch(actions.removeAnexo(anexo))
    },
    downloadAnexo(anexo) {
      dispatch(actions.downloadAnexo(anexo))
    },
  }
}

class ProdutoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PRODUTO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_PRODUTO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PRODUTO}`)
    }

    actions.beforeFetchRecordSuccess = (data) => {
      if (!data.unidade) {
        data.unidade = { id: -1, value: `<Todas>` }
      }
      return data
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PRODUTO}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  removerFornecedor = (fornecedorRemovido) => {
    const fornecedoresRestantes = this.props.record.fornecedores.filter((fornecedor) => {
      return fornecedor.oid !== fornecedorRemovido.oid
    })
    this.props.setField('fornecedores', fornecedoresRestantes)
  }

  onChangeFornecedor = (data) => {
    const fornecedores = this.props.record.fornecedores || []
    fornecedores.push({
      oidFornecedor: data.oid,
      nomeFornecedor: data.nome,
      documentoFornecedor: data.numeroDocumento,
    })
    this.props.setField('fornecedores', fornecedores)
  }

  onInputFilterFornecedor = (data) => {
    const value = data.value
    if (typeof value === 'string') {
      this.props.setField('fornecedor', { nome: data, oid: null })
    } else {
      const fornecedores = this.props.record.fornecedores || []
      fornecedores.push({
        oidFornecedor: value.oid,
        nomeFornecedor: value.nome,
        documentoFornecedor: value.numeroDocumento,
      })
      this.props.setField('fornecedores', fornecedores)
      this.props.setField('fornecedor', { nome: null, oid: null })
    }
  }

  onChangeTableValue = (rowData, value, field) => {
    rowData[field] = value
    this.props.setField('fornecedores', this.props.record.fornecedores)
  }

  actionFornecedor = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => this.removerFornecedor(rowData)}
        />
      </div>
    )
  }

  setMarcadores = (data) => {
    const values = []
    data.forEach((object) => {
      values.push({
        id: _.get(object, 'oid', ''),
        value: _.get(object, 'codigo', ''),
      })
    })
    this.props.setField('marcadores', values)
  }

  render() {
    const columnCodigoFornecedor = (rowData, column) => {
      if (column && column.field === 'codigo') {
        const value = _.get(rowData, column.field, '')
        return (
          <LtsInputText
            value={value}
            onChange={(e) => this.onChangeTableValue(rowData, e, column.field)}
            name="codigo"
            maxLength={50}
            style={{ height: '30px' }}
          />
        )
      }
    }

    const colunasFornecedor = _.get(this, 'props.colunasFornecedores', []).map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.actionFornecedor}
            style={{ width: '10em', textAlign: 'center' }}
          />
        )
      } else if (col.field === 'codigo') {
        return <Column {...col} key={col.field} body={columnCodigoFornecedor} />
      } else if (col.field !== 'oid') {
        return <Column {...col} key={col.field} field={col.field} header={col.header} style={{ textAlign: 'left' }} />
      }
    })

    const content = (
      <ProdutoForm
        record={this.props.record}
        messages={this.props.messages}
        unidades={this.props.unidades}
        setField={this.props.setField}
        colunasFornecedor={colunasFornecedor}
        onChangeFornecedor={this.onChangeFornecedor}
        onInputFilterFornecedor={this.onInputFilterFornecedor}
        setMarcadores={this.setMarcadores}
        stateMarcador={this.props.stateMarcador}
        removeAnexo={this.props.removeAnexo}
        anexos={this.props.record.anexos}
        onSelectAnexo={this.props.addAnexo}
        downloadAnexo={this.props.downloadAnexo}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoFormPageContainer)
