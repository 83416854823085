import React from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { LookupField } from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";
import { getTemplateDropDownPessoa } from "../../../utils/Template";

export const EmpresaForm = props => {
  const record = props.record;
  const pessoa = record && record.nomePessoa ? record.nomePessoa : "";
  const messages = props.messages;
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {utils.getMessage(messages, "empresa.label.empresa")} {record.oid || ""}
          </h3>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <span className="p-float-label">
                <InputText value={props.record.nomeEmpresa} onChange={props.changeHandler} name="nomeEmpresa" />
                <label>{utils.getMessage(messages, "empresa.label.nome")}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-6 p-md-4 p-lg-2 p-xl-2">
              <span className="p-float-label">
                <InputText value={props.record.codigoEmpresa} onChange={props.changeHandler} name="codigoEmpresa" />
                <label>{utils.getMessage(messages, "empresa.label.sigla")}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <LookupField
                label={utils.getMessage(messages, "empresa.label.pessoa")}
                value={pessoa}
                name="pessoa"
                field="nome"
                history={props.history}
                itemTemplate={getTemplateDropDownPessoa}
                onKeyPress={props.lookupPessoaOnKeyPress}
                onComplete={props.lookupPessoaOnComplete}
                onInputFilter={props.lookupPessoaOnInputFilter}
                editDisabled={props.lookupPessoa.editDisabled}
                onEditField={props.lookupPessoaOnEdit}
                onEdit={props.lookupPessoaOnClick}
                onClick={props.lookupPessoaOnClick}
                visible={props.lookupPessoa.visible}
                modal={props.lookupPessoa.modal}
                header={props.lookupPessoa.header}
                onHide={props.lookupPessoaOnHide}
                onConfirm={props.lookupPessoaOnConfirm}
                onCancel={props.lookupPessoaOnCancel}
                records={props.lookupPessoa.records}
                columns={props.lookupPessoa.columns}
                totalRecords={props.lookupPessoa.totalRecords}
                sortOrder={props.lookupPessoa.order}
                sortField={props.lookupPessoa.sortField}
                first={props.lookupPessoa.first}
                rows={props.lookupPessoa.rows}
                onPage={props.lookupPessoaOnPage}
                onFilter={props.lookupPessoaOnFilter}
                onSort={props.lookupPessoaOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-1 p-md-2 p-lg-3 p-xl-3">
              <label htmlFor="chkisento" className="label">
                {utils.getMessage(messages, "empresa.label.ativa")}
              </label>
              <Checkbox
                inputId="chkAtiva"
                checked={record.ativo}
                onChange={props.changeHandler}
                name="ativo"
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
