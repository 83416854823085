import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as menuActions } from '../../menu/menu.actions'
import { actions as perfilActions } from '../../perfil/perfil.actions'
import { actions } from '../permissao.actions'
import { PermissaoForm } from './PermissaoForm'
import { Filtro } from '../../../utils/Filtro.js'
import utils from '../../../utils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudPermissaoState.messages
  const record = state.crudPermissaoState.currentRecord
  const lookupMenuBase = {
    records: state.crudMenuState.records,
    columns: state.crudMenuState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudMenuState.index,
    rows: state.crudMenuState.max,
    totalRecords: state.crudMenuState.count,
    sortField: state.crudMenuState.sortField,
    order: state.crudMenuState.sortOrder,
    editDisabled: false,
  }
  const lookupPerfilBase = {
    records: state.crudPerfilState.records,
    columns: state.crudPerfilState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nomePerfil')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudPerfilState.index,
    rows: state.crudPerfilState.max,
    totalRecords: state.crudPerfilState.count,
    sortField: state.crudPerfilState.sortField,
    order: state.crudPerfilState.sortOrder,
    editDisabled: false,
  }
  let lookupMenu = Object.assign({}, state.crudPermissaoState.lookupMenu, lookupMenuBase)
  let lookupPerfil = Object.assign({}, state.crudPermissaoState.lookupPerfil, lookupPerfilBase)

  return {
    record,
    lookupMenu,
    lookupPerfil,
    messages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lookupMenuSetVisible: (visible) => dispatch(actions.setLookupMenuVisible(visible)),
    lookupMenuPaginate: (paginationParams) => dispatch(menuActions.paginate(paginationParams)),
    lookupMenuSortOrder: (sortParams) => dispatch(menuActions.sortOrder(sortParams)),
    lookupMenuApplyFilter: (filterParams) => {
      dispatch(menuActions.setType(null))
      dispatch(menuActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(menuActions.applyFilter(filterParams))
    },
    lookupMenuLoad: () => dispatch(menuActions.loadLookup()),
    setMenu: (menu) => {
      dispatch(actions.setField('menu.id', menu != null ? menu.oid : null))
      dispatch(actions.setField('menu.value', menu != null ? menu.nome : ''))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    lookupPerfilSetVisible: (visible) => dispatch(actions.setLookupPerfilVisible(visible)),
    lookupPerfilPaginate: (paginationParams) => dispatch(perfilActions.paginate(paginationParams)),
    lookupPerfilSortOrder: (sortParams) => dispatch(perfilActions.sortOrder(sortParams)),
    lookupPerfilApplyFilter: (filterParams) => {
      dispatch(perfilActions.setType(null))
      dispatch(perfilActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
      dispatch(perfilActions.applyFilter(filterParams))
    },
    lookupPerfilLoad: () => dispatch(perfilActions.loadLookup()),
    setPerfil: (perfil) => {
      dispatch(actions.setField('perfil.id', perfil != null ? perfil.oid : null))
      dispatch(actions.setField('perfil.value', perfil != null ? perfil.nomePerfil : ''))
    },
    changeHandler: (event) => {
      const booleanFields = ['listar', 'inserir', 'alterar', 'excluir']
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (booleanFields.find((current) => current === field)) {
        value = target.checked
      }
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    clearSelectedFilters: () => {
      dispatch(menuActions.clearSelectedFilters())
      dispatch(perfilActions.clearSelectedFilters())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    clearRecords: () => {
      dispatch(menuActions.setRecords([]))
      dispatch(perfilActions.setRecords([]))
    },
  }
}

class PermissaoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PERMISSAO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_PERMISSAO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PERMISSAO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PERMISSAO}`)
  }

  lookupMenuOnPage = (e) => {
    this.props.lookupMenuPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupMenuOnSort = (e) => {
    this.props.lookupMenuSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupMenuOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupMenuApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupMenuLoad()
    }
  }
  lookupMenuOnClick = (e) => {
    this.props.lookupMenuLoad()
    this.props.lookupMenuSetVisible(true)
  }
  lookupMenuOnHide = (e) => {
    this.props.lookupMenuSetVisible(false)
  }
  lookupMenuOnConfirm = (e) => {
    this.props.setMenu(e.selection)
    this.props.lookupMenuSetVisible(false)
  }
  lookupMenuOnCancel = (e) => {
    this.props.setMenu(null)
    this.props.lookupMenuSetVisible(false)
  }

  lookupMenuOnComplete = (e) => {}

  lookupMenuOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('menu.value', e.value)
      this.props.setField('menu.id', null)
      this.props.clearRecords()
    } else {
      this.props.setMenu(value)
    }
  }
  lookupMenuOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.menu) && utils.nonNull(this.props.record.menu.value)) {
      const filtro = new Filtro('nome', this.props.record.menu.value, 'CONTEM')
      this.props.lookupMenuApplyFilter(filtro)
    }
  }

  lookupMenuOnEdit = () => {
    if (this.props.record.menu && this.props.record.menu.id) {
      return `${routes.PATH_MENU}/${this.props.record.menu.id}`
    } else {
      return `${routes.PATH_MENU}`
    }
  }

  lookupPerfilOnPage = (e) => {
    this.props.lookupPerfilPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPerfilOnSort = (e) => {
    this.props.lookupPerfilSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPerfilOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupPerfilApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupPerfilLoad()
    }
  }
  lookupPerfilOnClick = (e) => {
    this.props.lookupPerfilLoad()
    this.props.lookupPerfilSetVisible(true)
  }
  lookupPerfilOnHide = (e) => {
    this.props.lookupPerfilSetVisible(false)
  }
  lookupPerfilOnConfirm = (e) => {
    this.props.setPerfil(e.selection)
    this.props.lookupPerfilSetVisible(false)
  }
  lookupPerfilOnCancel = (e) => {
    this.props.setPerfil(null)
    this.props.lookupPerfilSetVisible(false)
  }

  lookupPerfilOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('perfil.value', e.value)
      this.props.setField('perfil.id', null)
      this.props.clearRecords()
    } else {
      this.props.setPerfil(value)
    }
  }

  lookupPerfilOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.perfil) && utils.nonNull(this.props.record.perfil.value)) {
      const filtro = new Filtro('nomePerfil', this.props.record.perfil.value, 'CONTEM')
      this.props.lookupPerfilApplyFilter(filtro)
    }
  }

  lookupPerfilOnEdit = () => {
    if (this.props.record.perfil && this.props.record.perfil.id) {
      return `${routes.PATH_PERFIL}/${this.props.record.perfil.id}`
    } else {
      return `${routes.PATH_PERFIL}`
    }
  }

  lookupPerfilOnComplete = (e) => {}

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <PermissaoForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        messages={this.props.messages}
        history={this.props.history}
        //-----
        lookupMenu={this.props.lookupMenu}
        lookupMenuOnKeyPress={this.lookupMenuOnKeyPress}
        lookupMenuOnHide={this.lookupMenuOnHide}
        lookupMenuOnConfirm={this.lookupMenuOnConfirm}
        lookupMenuOnCancel={this.lookupMenuOnCancel}
        lookupMenuOnPage={this.lookupMenuOnPage}
        lookupMenuOnSort={this.lookupMenuOnSort}
        lookupMenuOnFilter={this.lookupMenuOnFilter}
        lookupMenuOnClick={this.lookupMenuOnClick}
        lookupMenuOnEdit={this.lookupMenuOnEdit}
        lookupMenuOnComplete={this.lookupMenuOnComplete}
        lookupMenuOnInputFilter={this.lookupMenuOnInputFilter}
        //------
        lookupPerfil={this.props.lookupPerfil}
        lookupPerfilOnKeyPress={this.lookupPerfilOnKeyPress}
        lookupPerfilOnHide={this.lookupPerfilOnHide}
        lookupPerfilOnConfirm={this.lookupPerfilOnConfirm}
        lookupPerfilOnCancel={this.lookupPerfilOnCancel}
        lookupPerfilOnPage={this.lookupPerfilOnPage}
        lookupPerfilOnSort={this.lookupPerfilOnSort}
        lookupPerfilOnFilter={this.lookupPerfilOnFilter}
        lookupPerfilOnClick={this.lookupPerfilOnClick}
        lookupPerfilOnEdit={this.lookupPerfilOnEdit}
        lookupPerfilOnComplete={this.lookupPerfilOnComplete}
        lookupPerfilOnInputFilter={this.lookupPerfilOnInputFilter}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissaoFormPageContainer)
