import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import utils from "../../../utils/index";

export const ExumacoesRelatorioForm = props => {
  const record = props.record;
  const changeHandler = props.changeHandler;

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <label className="label">Unidade</label>
                <MultiSelect
                  value={record.unidade}
                  options={utils.convertArrayIdValueToIdLabel(props.unidades)}
                  name="unidade"
                  optionLabel="label"
                  dataKey="id"
                  selectedItemsLabel="{0} itens selecionados"
                  onChange={props.onChangeUnit}
                  disabled={props.disableUnit}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
                <label className="label">Tipo de Sepultura</label>
                <MultiSelect
                  value={record.tiposSepulturas}
                  options={utils.convertArrayIdValueToIdLabel(record.opcoesTiposSepulturas)}
                  name="tiposSepulturas"
                  optionLabel="label"
                  dataKey="id"
                  selectedItemsLabel="{0} itens selecionados"
                  onChange={props.changeHandler}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                <label className="label">Período: De</label>
                <Calendar
                  yearNavigator={true}
                  yearRange={utils.getYearRangeFromDate("1970")}
                  monthNavigator={true}
                  value={record.dataInicial}
                  onChange={changeHandler}
                  name="dataInicial"
                />
              </div>
              <div className="p-col-5 p-sm-4 p-md-2 p-lg-2 p-xl-2">
                <label className="label">Até</label>
                <Calendar
                  yearNavigator={true}
                  yearRange={utils.getYearRangeFromDate("1970")}
                  monthNavigator={true}
                  value={record.dataFinal}
                  onChange={changeHandler}
                  name="dataFinal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
