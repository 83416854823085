import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../mapa-coordenada.actions'
import { MapaCoordenadaForm } from './MapaCoordenadaForm'
import _ from 'lodash'
import { showMessages } from '../../../store/message/message.actions'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudMapaCoordenadaState.currentRecord
  const messages = state.crudMapaCoordenadaState.messages

  return {
    record,
    messages,
    stateContrato: state.crudContratoState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    showMessages: (messages, type) => dispatch(showMessages(messages, type)),
  }
}

class MapaCoordenadaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_MAPA_COORDENADA}/${oid}`)
      this.props.loadById(oid)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_MAPA_COORDENADA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_MAPA_COORDENADA}`)
    }

    actions.afterLoad = (record) => {
      return this.executeAfterLoad(record)
    }
    this.setField = props.setField
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_MAPA_COORDENADA}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  executeAfterLoad = (record) => {}

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  setContrato = (data) => {
    const oid = _.get(data, 'oid', '')
    const numero = _.get(data, 'numero', '')
    let valor = _.get(data, 'localizacao_identificacao', '')
    if (!valor) {
      valor = '#' + (numero || oid) + ' - ' + _.get(data, 'tipoSepultura_descricao', '')
    }

    const contrato = {
      id: oid,
      value: valor,
    }
    this.props.setField('contrato', contrato)
  }

  render() {
    const content = (
      <MapaCoordenadaForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        messages={this.props.messages}
        setField={this.setField}
        stateContrato={this.props.stateContrato}
        setContrato={this.setContrato}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapaCoordenadaFormPageContainer)
