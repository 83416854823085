import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../constants'
import _ from 'lodash'
import utils from '../../../utils/index'

export default function NotaEntradaFormSubtitle(props) {
  const resolveImportacao = () => {
    let dataImportacao = _.get(props, 'dataImportacao', null)
    if (dataImportacao) {
      return <h6>{`Importada em: ${utils.formatarData(dataImportacao)}`}</h6>
    }
    return null
  }

  const resolveDataEntrada = () => {
    let dataEntrada = _.get(props, 'dataEntrada', null)
    if (dataEntrada) {
      return <h6>{`Data entrada: ${utils.formatarData(dataEntrada)}`}</h6>
    }
    return null
  }

  const resolveLancamento = () => {
    const oid = _.get(props, 'oidLancamentoFinanceiro', null)
    if (oid) {
      return (
        <div>
          {`Lançamento: `}
          <Link
            to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${oid}`}
            onClick={(e) => props.history.push(`${routes.PATH_CONTRATO}/${oid}`)}
          >
            {oid}
          </Link>
        </div>
      )
    }
    return null
  }

  const getSubtitle = () => {
    return (
      <div>
        {resolveImportacao()}
        {resolveDataEntrada()}
        {resolveLancamento()}
      </div>
    )
  }

  return getSubtitle()
}
