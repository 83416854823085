export function getFiltroJoinUnidade(oidUnidade) {
  if (oidUnidade) {
    return {
      campo: 'unidade.oid',
      valor: oidUnidade,
      tipoFiltro: 'IGUAL_A',
      relacionamento: ['unidade'],
    }
  }
  return null
}
