import React from "react";
import {DataTable} from "primereact/datatable";
import storage from "../../services/stoage.service";
import {Card} from "primereact/card";
import utils from "../../utils";

export class DefaultDataTable extends React.Component {

    render() {
        let props = this.props.data;
        const empresaCorrente = storage.getCompany();
        const unidadeCorrente = storage.getUnit();

        return (
            <div>
                <DataTable
                    // style={{margin: "10px"}}
                    ref={props.dataTableRef}
                    reorderableColumns={true}
                    selection={props.selectedRecords}
                    resizableColumns={true}
                    columnResizeMode="expand"
                    onSelectionChange={props.onSelectionChange}
                    onColReorder={props.onColReorder}
                    onSort={props.onSort}
                    onPage={props.onPage}
                    value={props.records}
                    lazy={true}
                    totalRecords={props.totalRecords}
                    sortOrder={props.order}
                    sortField={props.sortField}
                    paginator={true}
                    first={props.first}
                    rows={props.rows}
                    emptyMessage="Não foram encontrados registros"
                    rowsPerPageOptions={utils.getLinhasPorPaginaListagem(empresaCorrente, unidadeCorrente)} //{enums.ROWSPERPAGEOPTIONS}
                    // V7 properties //
                    filterDisplay="row"
                    size={"small"}
                    stripedRows={true}
                    responsiveLayout="scroll"
                >
                    {props.columns}
                </DataTable>
                <br/>
                <Card style={{ margin: "5px" }}>
                    <div style={{textAlign: "right", margin: "7px"}}>
                        <strong>Total: </strong> {props.totalRecords}
                    </div>
                </Card>
            </div>
        );
    }
}
