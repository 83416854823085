import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/public/";
const prefix = "crudDashboardPortalCliente";
const crud = build(validate, BASE_PATH);

const newTypes = {};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.loadLancamentos = paginationParams => {
  return (dispatch, getState) => {
    const state = getState()[`crudDashboardPortalClienteState`];
    if (paginationParams) {
      dispatch(actions.setIndex(paginationParams.index));
      dispatch(actions.setMax(paginationParams.max));
    }
    let sortFields = [];
    if (state.sortField) {
      sortFields = [
        {
          fieldName: state.sortField,
          order: state.sortOrder > -1 ? `ASC` : `DESC`
        }
      ];
    }
    const params = {
      filters: [],
      sortFields,
      index: paginationParams && paginationParams.index ? paginationParams.index : state.index,
      max: paginationParams && paginationParams.max ? paginationParams.max : state.max,
      projectionFields: ["oid", "data", "descricao", "tag", "contrato", "ativo"]
    };
    apiService.post(`/lottustech/lancamentosfinanceiros/portalcliente`, params).then(response => {
      dispatch(actions.setField("records", response.data.records));
      dispatch(actions.setField("index", response.data.index));
      dispatch(actions.setField("page", response.data.page));
    });
  };
};

actions.loadMaisAcessados = () => {
  return dispatch => {
    apiService.get(`${BASE_PATH}/maisacessados`).then(response => {
      dispatch(actions.setField("maisAcessados", response.data));
    });
  };
};

actions.loadUltimosAcessados = () => {
  return dispatch => {
    apiService.get(`${BASE_PATH}/ultimosacessados`).then(response => {
      dispatch(actions.setField("ultimosAcessados", response.data));
    });
  };
};

actions.loadAllFavoritos = () => {
  return dispatch => {
    const params = {
      index: 0,
      max: 10,
      projectionFields: ["oid", "menu"]
    };
    apiService.post(`/lottustech/favoritos/all`, params).then(response => {
      dispatch(actions.setField("favoritos", response.data.records));
    });
  };
};

actions.getCount = () => {
  return (dispatch, getState) => {
    const params = {};
    return apiService.get(`/lottustech/atividades/count`, params).then(response => {
      dispatch(actions.setField("concluida", response.data["concluida"]));
      dispatch(actions.setField("fazer", response.data["fazer"]));
    });
  };
};

actions.saveNewAtividade = atividade => {
  return (dispatch, getState) => {
    return apiService.post(`/lottustech/atividades/`, atividade).then(response => {
      Object.assign(atividade, { oid: response.data.oid });
      dispatch(actions.getCount());
    });
  };
};

export { types, actions };
