import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { icons } from '../../../constants/index'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Rating } from 'primereact/rating'
import { routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { PessoaList } from './PessoaList'
import { actions } from '../pessoa.actions'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    columns: state.crudPessoaState.columns,
    records: state.crudPessoaState.records,
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),

    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
  }
}

class PessoaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
  }

  loadFavorito() {
    this.props.loadFavorito(this.props.history.location.pathname)
    actions.afterLoadFavorito = (data) => {
      let dadosFavorito = data
      if (data.oid) {
        this.setState({ favorito: 1 })
        this.setState({ dadosFavorito: dadosFavorito })
      } else {
        this.setState({ favorito: 0 })
        this.setState({ dadosFavorito: null })
      }
    }
  }

  changeFavorito() {
    if (this.state.favorito) {
      this.props.removeFavorito(this.state.dadosFavorito.oid)
      actions.afterRemoveFavorito = () => {
        this.loadFavorito()
      }
    } else {
      this.props.adicionarFavorito(this.props.history.location.pathname)
      actions.afterAdicionarFavorito = () => {
        this.loadFavorito()
      }
    }
  }
  newHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA}/novo`)
  }
  componentDidMount() {
    logarAcesso().then()
    this.props.load()
    this.loadFavorito()
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  onFilter = (e) => {
    const filters = []
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      })
    })
    this.props.applyFilter(filters)
  }

  render() {
    const header = (
      <div>
        <div className="p-grid p-justify-start p-align-center">
          <h1>Pessoas</h1>
          <Rating value={this.state.favorito} onChange={(e) => this.changeFavorito()} stars={1} cancel={false} />
        </div>
        <div>
          <Toolbar>
            <div className="p-toolbar-group-left">
              <Button
                onClick={this.newHandler}
                label="Novo"
                icon={icons.PLUS_ICON}
                style={{ marginRight: '.25em' }}
                className="p-button-success"
              />
            </div>
            <div className="p-toolbar-group-right">
              <Button icon="pi pi-search" style={{ marginRight: '.25em' }} label="Filtros" />
              <Button icon="pi pi-bars" style={{ marginRight: '.25em' }} label="Colunas" />
            </div>
          </Toolbar>
        </div>
      </div>
    )
    const cnpjTemplate = (rowData, column) => {
      return <Link to={`${routes.PATH_PESSOA}/${rowData.oid}`}>{rowData.numeroDocumento}</Link>
    }

    const columns = this.props.columns.map((col) => {
      if (col.field === 'numeroDocumento') {
        return <Column {...col} key={col.field} body={cnpjTemplate} />
      }
      return <Column {...col} key={col.field} />
    })

    const content = (
      <PessoaList
        {...this.props}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
      />
    )
    return <Page header={header} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaListPageContainer)
