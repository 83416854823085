import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { icons } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

export default function LtsLookupCausaMortis(props) {
  const [filters, setFilters] = useState([])
  const [showDialogAddNew, setShowDialogAddNew] = useState(false)
  const [descricao, setDescricao] = useState()

  const columns = [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: false,
      filter: true,
      columnSortField: 'oid',
      visible: false,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      columnSortField: 'descricao',
      sortable: true,
      filter: true,
      visible: true,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/causasmortis/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('descricao', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onComplete = (event) => {
    const value = event.query
    if (value) {
      const filters = [new Filtro('descricao', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        descricao: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const autoCompleteTemplate = (data) => {
    return `${data.descricao}`
  }

  const onResolvingColumn = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-descricao-body-width"
            filterHeaderClassName="column-descricao-filter-header-width"
            headerClassName="column-descricao-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      return null
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  // const resolveFilters = (filters) => {
  //   if (props.tipo) {
  //     filters.push(new Filtro('tipos', tipoMarcador[props.tipo], 'IGUAL_A', ['tipos']))
  //   }
  //   return filters
  // }

  const onClickAddButton = () => {
    setShowDialogAddNew(true)
  }

  const onHideDialogAddNew = () => {
    setShowDialogAddNew(false)
  }

  const onConfirmAddNew = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const url = '/lottustech/causasmortis/'
    if (descricao) {
      apiService
        .post(url, { oidCausaMortis: null, descricaoCausaMortis: descricao })
        .then((response) => {
          const descricao = _.get(response, 'data.descricaoCausaMortis', null)
          const oid = _.get(response, 'data.oidCausaMortis', null)
          const values = _.get(props, 'value', [])
          values.push({ descricao, oid })
          props.onChange(values)
        })
        .catch((error) => {})
    } else {
      // exibir mensagem
    }
    onHideDialogAddNew()
  }

  const onChangeDescricaoCausaMortis = (e) => {
    const newValue = _.get(e, 'target.value', undefined)
    setDescricao(newValue)
  }

  const getDialogAddNew = () => {
    const footer = (
      <div>
        <Button
          label={'Adicionar'}
          onClick={(e) => onConfirmAddNew(e)}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    const body = (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <span className="p-float-label">
            <InputText value={descricao} onChange={onChangeDescricaoCausaMortis} name="descricao" />
            <label>Descrição</label>
          </span>
        </div>
      </div>
    )

    return (
      <Dialog
        header={props.header}
        visible={showDialogAddNew}
        onHide={onHideDialogAddNew}
        modal={true}
        closable={true}
        style={{ width: '30%' }}
        contentStyle={{ maxHeight: '30%', overflow: 'auto' }}
        footer={footer}
      >
        {body}
      </Dialog>
    )
  }

  return (
    <React.Fragment>
      {getDialogAddNew()}
      <LtsLookup
        label={props.label}
        value={props.value}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onComplete={onComplete}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        header={props.label}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        // resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        multiple={props.multiple}
        showAddButton={props.showAddButton}
        onClickAddButton={onClickAddButton}
      />
    </React.Fragment>
  )
}
