import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import apiServiceIdValue from '../../services/api-id-value-service'
import utils from '../../utils'
import { actions as localizacaoActions } from '../localizacao/localizacao.actions'
import { messageActions } from '../../store/message/index'
import storage from '../../services/stoage.service'
import _ from 'lodash'
import { setIsSaving } from '../../store/app/app.actions'
import { actions as actionsRemessa } from '../consultaRemessa/consultaRemessa.actions'
import { getDocumentoByTipoTelaEntidadeBase } from '../../services/api-documento'
import { enums } from '../../constants'

const BASE_PATH = '/lottustech/contratos'
const DADOS_COMPLEMENTARES_PATH = '/lottustech/dadoscomplementares'
const LANCAMENTOFINANCEIRO_PATH = '/lottustech/lancamentosfinanceiros'
const crud = build(validate, BASE_PATH)
const prefix = 'crudContrato'

const newTypes = {
  SET_LOOKUP_CONTRATO_CREMACAO_FUTURA_VISIBLE: `${prefix}setLookupContratoCremacaoFuturaVisible`,
  SET_LOOKUP_LOCALIZACAO_VISIBLE: `${prefix}setLookupLocalizacaoVisible`,
  SET_LOOKUP_LOCALIZACAO_DESTINO_VISIBLE: `${prefix}setLookupLocalizacaoDestinoVisible`,
  SET_LOOKUP_FALECIDO_VISIBLE: `${prefix}setLookupFalecidoVisible`,
  SET_LOOKUP_DEPENDENTE_VISIBLE: `${prefix}setLookupDependenteVisible`,
  SET_LOOKUP_FUNERARIA_VISIBLE: `${prefix}setLookupFunerariaVisible`,
  SET_LOOKUP_RESPONSAVEL_VISIBLE: `${prefix}setLookupResponsavelVisible`,
  SET_LOOKUP_DOCUMENTO_VISIBLE: `${prefix}setLookupDocumentoVisible`,
  SET_LOOKUP_RESPONSAVEL_CREMACAO_VISIBLE: `${prefix}setLookupResponsavelCremacaoVisible`,
  SET_RESPONSAVEL: `${prefix}setResponsavel`,
  ADICIONAR_NOME_RESPONSAVEL: `${prefix}adicionarNomeResponsavel`,
  SET_FALECIDO: `${prefix}setFalecido`,
  ADICIONAR_FALECIDO: `${prefix}adicionarFalecido`,
  ADICIONAR_HISTORICO: `${prefix}adicionarHistorico`,
  EDITAR_HISTORICO: `${prefix}editarHistorico`,
  SET_HISTORICO: `${prefix}setHistorico`,
  ADICIONAR_DEPENDENTE: `${prefix}adicionarDependente`,
  SET_DEPENDENTE: `${prefix}setDependente`,
  SAVE_CONTRATO: `${prefix}saveContrato`,
  CLEAR_FORM_HISTORICO: `${prefix}clearFormHistorico`,
  CLEAR_FORM_FALECIDO: `${prefix}clearFormFalecido`,
  CLEAR_FORM_DEPENDENTE: `${prefix}clearFormDependente`,
  BEFORE_SAVE: `${prefix}beforeSave`,
  SET_DISPLAY_FORM: `${prefix}setDisplayForm`,
  WILL_UNMOUNT: `${prefix}willUnmount`,
  ADD_ANEXO: `${prefix}addAnexo`,
  SHOW_ANEXO: `${prefix}showAnexo`,
  REMOVE_ANEXO: `${prefix}removeAnexo`,
  REMOVE_ANEXO_FALECIDO: `${prefix}removeAnexoFalecido`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_SELECTED_LANCAMENTOS: `${prefix}.setSelectedLancamentos`,
  CRIAR_CONTRATO: `${prefix}.criarContrato`,
  CRIAR_CONTRATO_CREMACAO_FALECIDO: `${prefix}.criarContratoCremacaoFalecido`,
  UPDATE_OID_FALECIDO: `${prefix}.updateOidFalecido`,
  SET_TIPOS_SEPULTURAS: `${prefix}.setTiposSepulturas`,
  SET_TIPOS_SEPULTURAS_CONFIGURACOES: `${prefix}.setTiposSepulturasConfiguracoes`,
  SET_INTEGRACAO_ADIAU_HABILITADA: `${prefix}.setIntegracaoAdiauHabilitada`,
  SET_CONTRATO_FALECIDO_CAMPOS_COMPLEMENTARES: `${prefix}.setContratoFalecidoCamposComplementares`,
  SET_CAMERAS_ADIAU: `${prefix}.setCamerasAdiau`,
  SET_TIPO_SEPULTURA_DESTINO: `${prefix}.setTipoSepulturaDestino`,
  SET_FILTRO_TIPOS_SEPULTURAS: `${prefix}.setFiltroTiposSepulturas`,
  SET_FILTRO_FORMA_PAGAMENTO_PLANO: `${prefix}.setFiltroFormaPagamentoPlano`,
  SET_FILTRO_FREQUENCIA_FATURAMENTO_PLANO: `${prefix}.setFiltroFrequenciaFaturamentoPlano`,
  RESET: `${prefix}reset`,
  ADD_DOCUMENTO: `${prefix}addDocumento`,
  ADD_DOCUMENTO_CONTRATO: `${prefix}addDocumentoContrato`,
  UPDATE_DOCUMENTO: `${prefix}updateDocumento`,
  SET_GERANDO_BOLETO: `${prefix}gerandoBoleto`,
  SET_ADICIONANDO_ANEXO: `${prefix}adicionandoAnexo`,
  SET_RELATORIOS_DOCUMENTOS: `${prefix}.setRelatoriosDocumentos`,
  SET_RELATORIOS_FALECIDO: `${prefix}.setRelatoriosFalecido`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setGerandoBoleto = (payload) => {
  return {
    type: types.SET_GERANDO_BOLETO,
    payload,
  }
}
actions.setAdicionandoAnexo = (payload) => {
  return {
    type: types.SET_ADICIONANDO_ANEXO,
    payload,
  }
}

actions.setLookupDocumentoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_DOCUMENTO_VISIBLE,
    visible,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.addAnexo = (payload) => {
  return {
    type: types.ADD_ANEXO,
    payload,
  }
}

actions.removeAnexo = (payload) => {
  return {
    type: types.REMOVE_ANEXO,
    payload,
  }
}

actions.removeAnexoFalecido = (payload) => {
  return {
    type: types.REMOVE_ANEXO_FALECIDO,
    payload,
  }
}

actions.showAnexo = (payload) => {
  return {
    type: types.SHOW_ANEXO,
    payload,
  }
}

actions.setSelectedLancamentos = (records) => {
  return {
    type: types.SET_SELECTED_LANCAMENTOS,
    records,
  }
}

actions.setTiposSepulturas = (records) => {
  return {
    type: types.SET_TIPOS_SEPULTURAS,
    records,
  }
}

actions.setTiposSepulturasConfiguracoes = (records) => {
  return {
    type: types.SET_TIPOS_SEPULTURAS_CONFIGURACOES,
    records,
  }
}

actions.setContratoFalecidoCamposComplementares = (contratoFalecidoCamposComplementares) => {
  return {
    type: types.SET_CONTRATO_FALECIDO_CAMPOS_COMPLEMENTARES,
    contratoFalecidoCamposComplementares,
  }
}

actions.setIntegracaoAdiauHabilitada = (integracaoAdiauHabilitada) => {
  return {
    type: types.SET_INTEGRACAO_ADIAU_HABILITADA,
    integracaoAdiauHabilitada,
  }
}

actions.setCamerasAdiau = (payload) => {
  return {
    type: types.SET_CAMERAS_ADIAU,
    payload,
  }
}

actions.setRelatoriosFalecido = (payload) => {
  return {
    type: types.SET_RELATORIOS_FALECIDO,
    payload,
  }
}

actions.setTipoSepulturaDestino = (payload) => {
  return {
    type: types.SET_TIPO_SEPULTURA_DESTINO,
    payload,
  }
}

actions.setFiltroTiposSepulturas = (payload) => {
  return {
    type: types.SET_FILTRO_TIPOS_SEPULTURAS,
    payload,
  }
}

actions.setFiltroFormaPagamentoPlano = (payload) => {
  return {
    type: types.SET_FILTRO_FORMA_PAGAMENTO_PLANO,
    payload,
  }
}

actions.setFiltroFrequenciaFaturamentoPlano = (payload) => {
  return {
    type: types.SET_FILTRO_FREQUENCIA_FATURAMENTO_PLANO,
    payload,
  }
}

actions.setRelatoriosDocumentos = (payload) => {
  return {
    type: types.SET_RELATORIOS_DOCUMENTOS,
    payload,
  }
}

actions.setLookupContratoCremacaoFuturaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CONTRATO_CREMACAO_FUTURA_VISIBLE,
    visible,
  }
}

actions.setLookupLocalizacaoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_LOCALIZACAO_VISIBLE,
    visible,
  }
}

actions.setLookupLocalizacaoDestinoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_LOCALIZACAO_DESTINO_VISIBLE,
    visible,
  }
}

actions.setLookupFalecidoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_FALECIDO_VISIBLE,
    visible,
  }
}

actions.setLookupDependenteVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_DEPENDENTE_VISIBLE,
    visible,
  }
}

actions.setLookupFunerariaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_FUNERARIA_VISIBLE,
    visible,
  }
}
actions.setLookupResponsavelVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_RESPONSAVEL_VISIBLE,
    visible,
  }
}

actions.setResponsavel = (field, value) => {
  return {
    type: types.SET_RESPONSAVEL,
    field,
    value,
  }
}

actions.addDocumento = (field, value) => {
  return {
    type: types.ADD_DOCUMENTO,
    field,
    value,
  }
}

actions.addDocumentoContrato = (field, value) => {
  return {
    type: types.ADD_DOCUMENTO_CONTRATO,
    field,
    value,
  }
}

actions.setLookupResponsavelCremacaoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_RESPONSAVEL_CREMACAO_VISIBLE,
    visible,
  }
}

actions.adicionarNomeResponsavel = (field, value) => {
  return {
    type: types.ADICIONAR_NOME_RESPONSAVEL,
    field,
    value,
  }
}

actions.setFalecido = (field, value) => {
  return {
    type: types.SET_FALECIDO,
    field,
    value,
  }
}

actions.adicionarHistorico = (field, value) => {
  return {
    type: types.ADICIONAR_HISTORICO,
    field,
    value,
  }
}

actions.setTipoSepulturaConfiguracao = (oidTipoSepultura) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const configuracao = state.tiposSepulturasConfiguracoes[oidTipoSepultura]

    if (configuracao) {
      state.isTipoSepulturaPlano = configuracao.plano
      state.isTipoSepulturaFuneral = configuracao.funeral
      state.isTipoSepulturaCremacao = configuracao.cremacao
      state.isTipoSepulturaTitulo = configuracao.titulo
      state.isTipoSepulturaJazigo = configuracao.jazigo
      state.isTipoSepulturaPet = configuracao.pet
      state.isTipoSepulturaFalecido = configuracao.falecido
    }
    return Object.assign({}, state)
  }
}

actions.adicionarDependenteCadastrado = (pessoa) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const dependenteForm = Object.assign({}, state.dependente)
    const oidContrato = state.currentRecord.oid

    const dependente = {
      oid: dependenteForm.oid,
      oidContrato: oidContrato,
      oidDependente: pessoa.oid,
      dependente: pessoa.nome,
      dataNascimento: pessoa.dataNascimento,
      parentesco: dependenteForm.parentesco,
    }
    dispatch(actions.adicionarDependente('dependentes', dependente))

    return Object.assign({}, state)
  }
}

actions.adicionarPessoaFisicaDependente = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const dependenteForm = Object.assign({}, state.dependente)
    const oidContrato = state.currentRecord.oid

    const statePessoa = getState()[`crudPessoaFisicaState`]
    const responsavel = statePessoa.currentRecord
    apiService
      .post(`/lottustech/pessoasfisicas/`, responsavel)
      .then((response) => {
        const pessoa = response.data
        const dependente = {
          oid: dependenteForm.oid,
          oidContrato: oidContrato,
          oidDependente: pessoa.oid,
          dependente: pessoa.nome,
          dataNascimento: pessoa.dataNascimento,
          parentesco: dependenteForm.parentesco,
        }
        dispatch(actions.adicionarDependente('dependentes', dependente))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
    return Object.assign({}, state)
  }
}

actions.adicionarDependente = (field, value) => {
  return {
    type: types.ADICIONAR_DEPENDENTE,
    field,
    value,
  }
}

actions.setDependente = (field, value) => {
  return {
    type: types.SET_DEPENDENTE,
    field,
    value,
  }
}

actions.setHistorico = (field, value) => {
  return {
    type: types.SET_HISTORICO,
    field,
    value,
  }
}

actions.clearFormHistorico = () => {
  return {
    type: types.CLEAR_FORM_HISTORICO,
  }
}
actions.clearFormFalecido = () => {
  return {
    type: types.CLEAR_FORM_FALECIDO,
  }
}
actions.clearFormDependente = () => {
  return {
    type: types.CLEAR_FORM_DEPENDENTE,
  }
}

actions.beforeSave = () => {
  return {
    type: types.BEFORE_SAVE,
  }
}

actions.setDisplayForm = (field, value) => {
  return {
    type: types.SET_DISPLAY_FORM,
    field,
    value,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.enviarMensagem = (oid, tipoMensagem) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    apiService.post(`${BASE_PATH}/enviarmensagem/${oid}/${tipoMensagem}`).then((response) => {
      if (actions.carregarMensageria) {
        actions.carregarMensageria(response.data)
      }
    })
  }
}

actions.salvarNovaCausaMortis = (descricao, props, aposSalvarNovaCausaMortis) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/causasmortis/${descricao}`)
      .then((response) => {
        if (utils.nonNull(response.data) && utils.nonNull(response.data.oid)) {
          const causaMortisSalva = { id: response.data.oid, value: response.data.descricao }
          aposSalvarNovaCausaMortis(props, causaMortisSalva)
        }
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.salvarLocalizacao = (localizacao) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/localizacoes`, localizacao)
      .then((response) => {
        if (utils.nonNull(response.data) && utils.nonNull(response.data.oid)) {
          const localizacaoSalva = { id: response.data.oid, value: response.data.identificacao }
          dispatch(actions.setField('localizacao', localizacaoSalva))
          dispatch(actions.setField('numeroVagas', response.data.numeroVagas))
          dispatch(actions.setField('numeroLugares', response.data.numeroLugares))
        }
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.salvarLocalizacaoDestino = (localizacao) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const idValue = { id: localizacao.oid, value: localizacao.identificacao }
    dispatch(actions.setFalecido('localizacaoDestino', idValue))
    if (utils.isStringBlank(state.falecido.processoTranslado)) {
      dispatch(actions.setFalecido('processoTranslado', localizacao.identificacao))
    }
  }
}

actions.aplicarFiltrosCarregarDadosLocalizacao = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(localizacaoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }
    const isContratoCremacao = utils.isContratoCremacao(storage.getUnits(), record.unidade.id, record.tipoSepultura.id)
    // Se for contrato de cremacao, devemos permitir selecionar uma localizacao tipo tumulo por exemplo.
    if (!isContratoCremacao) {
      if (record.unidadeLocalizacao && record.unidade.id) {
        const filtro = {
          campo: 'oid',
          valor: record.unidadeLocalizacao.id,
          tipoFiltro: 'IGUAL_A',
          fieldJoin: 'oid',
          relacionamento: ['unidade'],
        }
        dispatch(localizacaoActions.setFiltroAvancado(filtro))
      } else if (record.unidade && record.unidade.id) {
        const filtro = {
          campo: 'oid',
          valor: record.unidade.id,
          tipoFiltro: 'IGUAL_A',
          fieldJoin: 'oid',
          relacionamento: ['unidade'],
        }
        dispatch(localizacaoActions.setFiltroAvancado(filtro))
      }
    }

    if (!isContratoCremacao) {
      dispatch(localizacaoActions.setSelectedFilters('disponivel', true))
    }
    if (record.unidadeLocalizacao && record.unidade.id) {
      const filtro = {
        campo: 'oid',
        valor: record.unidadeLocalizacao.id,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['unidade'],
      }
      dispatch(localizacaoActions.setFiltroAvancado(filtro))
    }
    if (record.apenasLocalizacaoTemporaria) {
      const filtro = {
        campo: 'temporaria',
        valor: true,
        tipoFiltro: 'IGUAL_A',
      }
      dispatch(localizacaoActions.setFiltroAvancado(filtro))
    }

    dispatch(localizacaoActions.loadLookup())
  }
}

actions.aplicarFiltrosCarregarDadosLocalizacaoDestino = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(localizacaoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }

    const isContratoCremacao = utils.isContratoCremacao(storage.getUnits(), record.unidade.id, record.tipoSepultura.id)
    if (!isContratoCremacao) {
      dispatch(localizacaoActions.setSelectedFilters('disponivel', true))
    }
    if (record.apenasLocalizacaoDestinoTemporaria) {
      const filtro = {
        campo: 'temporaria',
        valor: true,
        tipoFiltro: 'IGUAL_A',
      }
      dispatch(localizacaoActions.setFiltroAvancado(filtro))
    }
    dispatch(localizacaoActions.loadLookup())
  }
}

actions.aplicarFiltrosCarregarDadosContratoCremacaoFutura = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(localizacaoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }
    const oidCremacaoFutura = utils.getOidContratoCremacaoFutura(
      storage.getUnits(),
      record.unidade.id,
      record.tipoSepultura.id
    )
    if (utils.nonNull(oidCremacaoFutura)) {
      const filtro = {
        campo: 'oid',
        valor: oidCremacaoFutura,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['tipoSepultura'],
      }
      dispatch(actions.setFiltroAvancado(filtro))
    }

    dispatch(actions.setSelectedFilters('ativo', true))
    dispatch(actions.loadLookup())
  }
}

actions.adicionarPessoaFisicaResponsavel = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudPessoaFisicaState`]
    const responsavel = state.currentRecord
    apiService
      .post(`/lottustech/pessoasfisicas/`, responsavel)
      .then((response) => {
        const pessoa = response.data
        const responsavel = {
          oidResponsavel: pessoa.oid,
          responsavel: pessoa.nome,
        }
        dispatch(actions.setResponsavel('responsavel', responsavel))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
    return Object.assign({}, state)
  }
}

actions.adicionarFalecido = (field, value) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    let atuaisFalecidos = state.currentRecord.falecidos
    let falecidoForm = Object.assign({}, state.falecido) // pegando os dados preenchidos do falecido no Form
    let falecidoSelecionado = null
    let isNovo = false

    if (atuaisFalecidos.length > 0) {
      falecidoSelecionado = atuaisFalecidos.filter((falecido) => {
        return falecido.oidFalecido === falecidoForm.oidFalecido
      })
      if (falecidoSelecionado.length > 0) {
        // se ele já existia
        atuaisFalecidos.map((falecido) => {
          if (falecidoSelecionado[0].oidFalecido === falecido.oidFalecido) {
            Object.assign(falecido, falecidoForm)
            return (falecido = falecidoForm) // ???
          }
        })
      } else {
        isNovo = true
      }
    } else {
      isNovo = true
    }

    if (!isNovo) {
      Object.assign(state.currentRecord.falecidos, atuaisFalecidos)
      dispatch(actions.clearFormFalecido(state))
    }

    let pessoa = {
      oid: falecidoForm.oidFalecido,
      nome: falecidoForm.falecido,
      numeroDocumento: falecidoForm.cpfFalecido,
      dataNascimento: falecidoForm.dataNascimento,
      dataFalecimento: falecidoForm.dataFalecimento,
    }

    apiService
      .post(`/lottustech/pessoasfisicas/salvarfalecido`, pessoa)
      .then((response) => {
        if (isNovo) {
          atuaisFalecidos.push(falecidoForm)
          Object.assign(state.currentRecord.falecidos, atuaisFalecidos)
          dispatch(actions.clearFormFalecido(state))
        }
        dispatch(actions.updateOidFalecido(response.data))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })

    return Object.assign({}, state)
  }
}

actions.updateOidFalecido = (payload) => {
  return {
    type: types.UPDATE_OID_FALECIDO,
    payload,
  }
}

actions.baixarLancamentos = (params) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const record = state.currentRecord
    if (params) {
      apiService
        .post(`${LANCAMENTOFINANCEIRO_PATH}/executarBaixaLancamentos`, params)
        .then((response) => {
          dispatch(
            messageActions.messageShowMessages(makeMessages(['Sucesso na baixa do(s) lançamento(s)!'], 'success'))
          )
          dispatch(actions.loadById(record.oid))
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.estornarLancamento = (oidsLancamentosEstornar) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const record = state.currentRecord
    if (oidsLancamentosEstornar && oidsLancamentosEstornar.length > 0) {
      const params = {
        oidLancamento: null,
        oidsLancamentosEstornar,
      }
      apiService
        .post(`${LANCAMENTOFINANCEIRO_PATH}/estornar`, params)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Sucesso no estorno do lançamento!'], 'success')))
          dispatch(actions.loadById(record.oid))
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.gerarCarneBoleto = (params) => {
  return (dispatch, getState) => {
    dispatch(actions.setGerandoBoleto(true))
    const msg = 'Processando boleto(s). Aguarde.'
    dispatch(actions.showWarningMessage(msg))
    apiService
      .post(`/lottustech/ailos/gerar/carneboleto/`, params)
      .then((response) => {
        const win = window.open(_.get(response, 'data.url'))
        if (response.data?.oidRemessa) {
          dispatch(actionsRemessa.downloadArquivo(_.get(response, 'data.oidRemessa')))
        }
        win.focus()
      })
      .catch((err) => {
        let message = _.get(err, 'response.data', '')
        const status = _.get(err, 'response.status', -1)
        if (status === 500) {
          message = 'Ocorreu um erro tentando gerar boleto(s)'
        }
        if (message && message.trim().length > 0) {
          dispatch(messageActions.messageShowMessages(makeMessages([message], 'error')))
        }
      })
      .finally(() => {
        dispatch(actions.setGerandoBoleto(false))
      })
  }
}

actions.loadTiposSepulturas = (oidUnidade) => {
  return (dispatch, getState) => {
    if (oidUnidade && parseInt(oidUnidade)) {
      apiService.post(`/lottustech/tipossepultura/loadAll`, oidUnidade).then((response) => {
        let result = []
        let configuracoesTiposSepultura = {}
        let tiposSepulturas = response.data
        if (tiposSepulturas && tiposSepulturas.length > 0) {
          result = response.data
          tiposSepulturas.forEach((tipoSepultura) => {
            // result.push({
            //   id: tipoSepultura.oid,
            //   value: tipoSepultura.descricao,
            // })

            configuracoesTiposSepultura[tipoSepultura.oid] = {
              plano: tipoSepultura.plano,
              funeral: tipoSepultura.funeral,
              cremacao: tipoSepultura.cremacao,
              titulo: tipoSepultura.titulo,
              jazigo: tipoSepultura.jazigo,
              pet: tipoSepultura.pet,
              falecido: tipoSepultura.falecido,
            }
          })
        }
        dispatch(actions.setTiposSepulturas(result))
        dispatch(actions.setTiposSepulturasConfiguracoes(configuracoesTiposSepultura))

        const state = getState()[`crudContratoState`]
        let record = state.currentRecord
        if (record?.tipoSepultura?.oid) {
          dispatch(actions.setTipoSepulturaConfiguracao(record.tipoSepultura.oid))
        }
      })
    }
  }
}

actions.loadFiltroTiposSepulturas = (oidsUnidades) => {
  return (dispatch, getState) => {
    apiService.post(`/lottustech/tipossepultura/loadByUnidades`, oidsUnidades).then((response) => {
      let result = []
      let configuracoesTiposSepultura = {}
      const tiposSepulturas = response.data
      if (utils.isArrayNotEmpty(tiposSepulturas)) {
        tiposSepulturas.forEach((tipoSepultura) => {
          const existe = result.find((f) => f.value === tipoSepultura.descricao)
          if (!existe) {
            result.push({ value: tipoSepultura.descricao, id: tipoSepultura.oid })

            configuracoesTiposSepultura[tipoSepultura.oid] = {
              plano: tipoSepultura.plano,
              funeral: tipoSepultura.funeral,
              cremacao: tipoSepultura.cremacao,
              titulo: tipoSepultura.titulo,
              jazigo: tipoSepultura.jazigo,
            }
          }
        })
      }
      dispatch(actions.setFiltroTiposSepulturas(result))
      dispatch(actions.setTiposSepulturasConfiguracoes(configuracoesTiposSepultura))
    })
  }
}

actions.loadFiltroFormaPagamentoPlano = () => {
  return (dispatch, getState) => {
    apiServiceIdValue.getFormasPagamentoPlano().then((response) => {
      dispatch(actions.setFiltroFormaPagamentoPlano(_.get(response, 'data', [])))
    })
  }
}

actions.loadFiltroFrequenciaFaturamentoPlano = () => {
  return (dispatch, getState) => {
    apiServiceIdValue.getFrequenciasFaturamento().then((response) => {
      dispatch(actions.setFiltroFrequenciaFaturamentoPlano(_.get(response, 'data', [])))
    })
  }
}

actions.loadRelatoriosDocumentos = () => {
  return (dispatch, getState) => {
    const tipoTela = 2 //LISTAGEM
    const entidadeBase = 'br.com.lottustech.phoenix.contrato.Contrato'
    apiService.get(`/lottustech/documentos/${tipoTela}/${entidadeBase}`).then((response) => {
      const documentos = response.data
      dispatch(actions.setRelatoriosDocumentos(documentos))
    })
  }
}

actions.requestExecutarRelatorioDocumentos = ({ oidDocumento, params }) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/relatorios/documento/lote`, { oidDocumento, params })
      .then((response) => {
        const url = _.get(response, 'data', [])
        if (url.includes('http')) {
          const win = window.open(url)
          if (win) {
            win.focus()
          }
        }
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.loadContrato = (oid, messagemPersonalizada) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
  return (dispatch, getState) => {
    return apiService.get(`${BASE_PATH}/${oid}`).then((response) => {
      dispatch(actions.willUnmount())
      dispatch(actions.setRecord(response.data))
      if (utils.nonNull(messagemPersonalizada)) {
        dispatch(messageActions.messageShowMessages(makeMessages([messagemPersonalizada], 'success')))
      }
      if (actions.afterLoad) {
        actions.afterLoad(response.data)
      }
    })
  }
}

actions.downloadPDF = (oidContrato) => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/downloadreport/${oidContrato}`
    return apiService.post(path).then((response) => {
      const win = window.open(response.data)
      if (win) {
        win.focus()
      }
    })
  }
}

actions.downloadLapide = (params) => {
  return (dispatch, getState) => {
    const path = BASE_PATH + `/downloadlapide/`
    return apiService.post(path, params).then((response) => {
      const win = window.open(response.data)
      if (win) {
        win.focus()
      }
    })
  }
}

actions.processaAnexo = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      dispatch(actions.setAdicionandoAnexo(true))
      const msgErro = 'Erro ao adicionar anexo'

      const state = getState()[`${prefix}State`]
      const record = state.currentRecord
      const oidContrato = record.oid

      const file = data.target.files[0]
      const form = new FormData()
      form.append('anexo', file)

      apiService
        .postFormData(`${BASE_PATH}/${oidContrato}/addAnexo`, form, { nomeAnexo: file.name })
        .then((response) => {
          const anexos = _.get(response, 'data', null)
          dispatch(actions.addAnexo(anexos))
          dispatch(actions.setAdicionandoAnexo(false))
        })
        .catch((error) => {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
          dispatch(actions.setAdicionandoAnexo(false))
        })

      // var reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onload = function () {
      //   //TODO criar metodo utils
      //   let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
      //   if (encoded.length % 4 > 0) {
      //     encoded += '='.repeat(4 - (encoded.length % 4))
      //   }
      //   const anexo = { filename: file.name, base64: encoded }
      //   dispatch(actions.addAnexo(anexo))
      // }
      //
      // reader.onerror = function (error) {
      //   console.log('Error: ', error)
      // }
    }
  }
}

actions.processaAnexoFalecido = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        //TODO criar metodo utils
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const anexo = { filename: file.name, base64: encoded }
        dispatch(actions.setFalecido('anexos', [anexo]))
      }

      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body])
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

actions.downloadAnexo = (anexo) => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then((response) => {
        const arrayBuffer = base64ToArrayBuffer(response.data)
        createAndDownloadBlobFile(arrayBuffer, anexo.filename)
      })
    }
  }
}

actions.downloadAnexoFalecido = (anexo) => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexofalecido`, anexo).then((response) => {
        const arrayBuffer = base64ToArrayBuffer(response.data)
        createAndDownloadBlobFile(arrayBuffer, anexo.filename)
      })
    }
  }
}

actions.abrirAutorizacaoSepultamento = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService.post(`${BASE_PATH}/gerarautorizacaosepultamento/${oidContratoFalecido}`).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.abrirAutorizacaoSepultamentoTumulo = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/gerarautorizacaosepultamentotumulo/${oidContratoFalecido}`)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarRequerimentoCremacao = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService.post(`${BASE_PATH}/gerarrequerimentocremacao/${oidContratoFalecido}`).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarCarteirinhasSelecionadosPDF = (params) => {
  return (dispatch, getState) => {
    if (utils.isArrayNotEmpty(params)) {
      return apiService.post(`${BASE_PATH}/carteirinhas/`, params).then((response) => {
        const data = response.data
        if (utils.isArrayNotEmpty(data)) {
          data.forEach((url) => {
            const win = window.open(url)
            if (win) {
              win.focus()
            }
          })
        }
      })
    }
  }
}

actions.gerarCarteirinhaIndividual = (oidPessoa, oidContrato) => {
  return (dispatch, getState) => {
    if (oidPessoa && oidContrato) {
      return apiService.post(`${BASE_PATH}/carteirinhaindividual/${oidPessoa}/${oidContrato}`).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarCarteirinhaIndividualPDF = (oidPessoa, oidContrato) => {
  return (dispatch, getState) => {
    if (oidPessoa && oidContrato) {
      return apiService.post(`${BASE_PATH}/carteirinhaIndividualPDF/${oidPessoa}/${oidContrato}`).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarCarteirinhaColetiva = (oidContrato) => {
  return (dispatch, getState) => {
    if (oidContrato) {
      return apiService.post(`${BASE_PATH}/carteirinhacoletiva/${oidContrato}`).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarCarteirinhaLote = (oidsContratos) => {
  return (dispatch, getState) => {
    if (oidsContratos && oidsContratos.length > 0) {
      return apiService.post(`${BASE_PATH}/carteirinhalotecontratos/`, oidsContratos).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarCarteirinhaLotePDF = (oidsContratos) => {
  return (dispatch, getState) => {
    if (oidsContratos && oidsContratos.length > 0) {
      return apiService.post(`${BASE_PATH}/carteirinhaLoteContratosPDF/`, oidsContratos).then((response) => {
        const win = window.open(response.data)
        if (win) {
          win.focus()
        }
      })
    }
  }
}

actions.gerarEtiqueta = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/downloadetiqueta/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarNotaFalecimento = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/notaFalecimento/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarTanatopraxiaAta = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/relatorio/tanatopraxiaAta/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarTanatopraxiaAutorizacao = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/relatorio/tanatopraxiaAutorizacao/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarTanatopraxiaRegistro = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/relatorio/tanatopraxiaRegistro/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.gerarEPIs = (oidContratoFalecido) => {
  return (dispatch, getState) => {
    if (oidContratoFalecido) {
      return apiService
        .post(`${BASE_PATH}/relatorio/epi/${oidContratoFalecido}`, oidContratoFalecido)
        .then((response) => {
          const win = window.open(response.data)
          if (win) {
            win.focus()
          }
        })
    }
  }
}

actions.criarContrato = () => {
  const empresaCorrente = storage.getCompany()
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    let currentRecord = state.currentRecord
    const oidContratoAtual = currentRecord.oid
    let oidUnidade = utils.getParametroEmpresaCorrente('oidUnidadeCrematorio', empresaCorrente) || {
      value: currentRecord.unidade.id,
    }
    if (oidUnidade && oidUnidade.value) {
      oidUnidade = parseInt(oidUnidade.value)
      const unidades = storage.getUnits()
      const unidadeDestino = utils.findObjectInArray(unidades, 'oid', oidUnidade)

      if (state.tipoSepulturaDestino) {
        const unidadeBkp = { id: oidUnidade, value: unidadeDestino.sigla }
        currentRecord.oid = ''
        currentRecord.numero = ''
        currentRecord.observacao = ''
        currentRecord.unidade = unidadeBkp
        currentRecord.contratoOrigem = oidContratoAtual
        currentRecord.contratoCremacaoFutura = oidContratoAtual
        currentRecord.tipoSepultura = {
          id: state.tipoSepulturaDestino?.oidTipoSepulturaDestino,
          value: state.tipoSepulturaDestino?.descricaoTipoSepulturaDestino,
        }
        currentRecord.data = new Date()
        currentRecord.historicos = []
        currentRecord.dependentes = []
        currentRecord.falecidos = []
        const sugerirFalecidoCremacao = utils.getSugerirFalecidoCremacao(empresaCorrente, currentRecord.unidade)
        if (sugerirFalecidoCremacao && currentRecord.responsaveis && currentRecord.responsaveis.length > 0) {
          const responsavelOrdem1 = utils.findObjectInArray(currentRecord.responsaveis, 'ordem', 1)
          //carregando os dados da pessoa para colocar no falecido
          apiService.get(`/lottustech/pessoasfisicas/${responsavelOrdem1.oidResponsavel}`).then((response) => {
            const pessoa = response.data
            if (pessoa.numeroDocumento) {
              let falecidoOrdem1 = {
                oidFalecido: pessoa.oid,
                falecido: pessoa.nome,
                cpfFalecido: pessoa.numeroDocumento,
                dataNascimento: pessoa.dataNascimento,
                dataFalecimento: pessoa.dataFalecimento,
                ordem: 1,
              }
              currentRecord.falecidos.push(falecidoOrdem1)
              currentRecord.responsaveis = currentRecord.responsaveis.filter(function (responsavel, index, arr) {
                if (responsavel.ordem !== 1) {
                  responsavel.ordem = --responsavel.ordem
                  return responsavel
                }
              })
            }
            dispatch(actions.setRecord(currentRecord))
          })
        } else {
          dispatch(actions.setRecord(currentRecord))
        }
      }
    }
  }
}

const makeMessages = (messages, type) => {
  return messages.map((message) => {
    return {
      sticky: type === 'error',
      severity: type,
      detail: message,
    }
  })
}

actions.showWarningMessage = (message) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
  }
}

actions.showMessage = (message, type) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages([message], type)))
  }
}
actions.willUnmount = () => {
  return {
    type: types.WILL_UNMOUNT,
  }
}

actions.criarContratoCremacaoFalecido = (payload) => {
  return {
    type: types.CRIAR_CONTRATO_CREMACAO_FALECIDO,
    payload,
  }
}

actions.requestImprimirDocumento = (params) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/relatorios/documento`, params)
      .then((response) => {
        const url = _.get(response, 'data', null)
        if (url && url.includes('http')) {
          const win = window.open(url)
          if (win) {
            win.focus()
          }
        }
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.assinarDocumento = (oidContratoDocumento) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}/assinardocumento`, { oidContratoDocumento })
      .then((response) => {
        const message = _.get(response, 'data.message', '')
        const status = _.get(response, 'data.status', null)
        const serviceProviderDocumentId = _.get(response, 'data.serviceProviderDocumentId', null)
        const linksAssinaturas = _.get(response, 'data.linksAssinaturas', [])
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'success')))
        dispatch(
          actions.updateDocumento({
            oid: oidContratoDocumento,
            status,
            serviceProviderDocumentId,
            linksAssinaturas,
          })
        )
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.updateDocumento = (payload) => {
  return {
    type: types.UPDATE_DOCUMENTO,
    payload,
  }
}

actions.addDocumentosPadroes = (oidTipoSepultura) => {
  return (dispatch, getState) => {
    apiService
      .get(`/lottustech/tipossepultura/${oidTipoSepultura}/documentos`)
      .then((response) => {
        const documentosPadroes = _.get(response, 'data', null)
        const state = getState()[`${prefix}State`]
        const documentosContrato = state.documentos
        for (const doc of documentosPadroes) {
          const find = documentosContrato.find((f) => f.oidDocumento === doc.oid)
          if (!find) {
            dispatch(actions.addDocumento('documento', doc))
          }
        }
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.updateContrato = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const record = state.currentRecord
    utils.blankToNull(record)
    const oid = _.get(record, 'oid', null)
    if (oid && oid > 0) {
      dispatch(setIsSaving(true))
      apiService
        .put(`${BASE_PATH}/${record.oid}`, record)
        .then(({ data }) => {
          dispatch(setIsSaving(false))
          const beforeFetchRecordSuccess = _.get(actions, 'beforeFetchRecordSuccess', null)
          if (beforeFetchRecordSuccess) {
            beforeFetchRecordSuccess.call(actions, data)
          }
          dispatch(actions.setRecord(data))
          const afterSave = _.get(actions, 'afterSave', null)
          if (afterSave) {
            afterSave.call(actions, data)
          }
        })
        .catch((error) => {
          dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
          dispatch(setIsSaving(false))
        })
    }
  }
}

actions.agruparLancamentos = (oidsLancamentos) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const record = state.currentRecord

    apiService
      .post(`${LANCAMENTOFINANCEIRO_PATH}/agrupar`, oidsLancamentos)
      .then((response) => {
        const lancamentosFinanceiros = _.get(record, 'lancamentosFinanceiros', null)
        let lancamentos = lancamentosFinanceiros.filter((l) => !oidsLancamentos.includes(l.oid))
        lancamentos.unshift(response.data)

        dispatch(actions.setField('lancamentosFinanceiros', lancamentos))
        dispatch(messageActions.messageShowMessages(makeMessages(['Agrupamento realizado'], 'success')))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.cancelarLancamentos = (oidsLancamentos) => {
  return (dispatch, getState) => {
    const state = getState()[`crudContratoState`]
    const record = state.currentRecord

    apiService
      .post(`${LANCAMENTOFINANCEIRO_PATH}/cancelarLote`, oidsLancamentos)
      .then((response) => {
        const lancamentosFinanceiros = _.get(record, 'lancamentosFinanceiros', null)
        let lancamentos = lancamentosFinanceiros.filter((l) => !oidsLancamentos.includes(l.oid))
        dispatch(actions.setField('lancamentosFinanceiros', lancamentos))

        const result = response.data
        if (result) {
          dispatch(messageActions.messageShowMessages(makeMessages([result], 'warn')))
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(['Registros cancelados com sucesso!'], 'success')))
        }
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'warn')))
        }
      })
  }
}

actions.getDateTimeFromServer = () => {
  return (dispatch, getState) => {
    apiService
      .getDateTimeFromServer()
      .then((response) => {
        const dateTimeInMili = _.get(response, 'data', null)
        let newDate = new Date()
        if (dateTimeInMili) {
          newDate = new Date(dateTimeInMili)
        }
        dispatch(actions.setField('data', newDate))
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.abrirWebWhatsApp = ({ oidContrato }) => {
  return (dispatch, getState) => {
    const msgErro = 'Não foi possível verificar o telefone'
    apiService
      .get(`${BASE_PATH}/${oidContrato}/templateWebWhatsApp/`)
      .then((response) => {
        const link = _.get(response, 'data', null)
        if (link) {
          const win = window.open(link)
          if (win) {
            win.focus()
          }
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
        }
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.abrirWebWhatsAppNotaFalecimento = (oidContratoFalecido) => {
  const erroDesconhecido = 'Ocorreu um erro desconhecido'
  return (dispatch, getState) => {
    apiService
      .get(`${BASE_PATH}/${oidContratoFalecido}/templateWebWhatsAppNotaFalecimento/`)
      .then((response) => {
        const link = _.get(response, 'data', null)
        if (link) {
          const win = window.open(link)
          if (win) {
            win.focus()
          }
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages([erroDesconhecido], 'error')))
        }
      })
      .catch((error) => {
        const msg = _.get(error, 'response.data', erroDesconhecido)
        dispatch(messageActions.messageShowMessages(makeMessages([msg], 'error')))
      })
  }
}

actions.integracaoAdiauHabilitada = () => {
  return (dispatch, getState) => {
    const msgErro = 'Erro ao verificar integração Adiau'
    apiService
      .get(`${BASE_PATH}/integracaoAdiauHabilitada`)
      .then((response) => {
        const integracaoAdiauHabilitada = _.get(response, 'data', null)
        dispatch(actions.setIntegracaoAdiauHabilitada(integracaoAdiauHabilitada))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.loadContratoFalecidoCamposComplementares = () => {
  return (dispatch, getState) => {
    const msgErro = 'Erro ao buscar campos complementares'
    const state = getState()[`crudContratoState`]
    const idUnidade = state.currentRecord.unidade?.id
    apiService
      .get(`${DADOS_COMPLEMENTARES_PATH}/contratoFalecido/${idUnidade}`)
      .then((response) => {
        const data = _.get(response, 'data', {})
        dispatch(actions.setContratoFalecidoCamposComplementares(data))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.loadCamerasAdiau = () => {
  return (dispatch, getState) => {
    const msgErro = 'Erro ao buscar câmeras Adiau'
    apiService
      .get(`${BASE_PATH}/camereasAdiau`)
      .then((response) => {
        const cameras = _.get(response, 'data', null)
        dispatch(actions.setCamerasAdiau(cameras))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.getTipoSepulturaDestino = (oidTipoSepultura) => {
  return (dispatch, getState) => {
    if (oidTipoSepultura) {
      const msgErro = 'Erro ao buscar tipo sepultura destino'
      return apiService
        .get(`${BASE_PATH}/tipoSepulturaDestino/${oidTipoSepultura}`)
        .then((response) => {
          const tipoSepulturaDestino = _.get(response, 'data', null)
          dispatch(actions.setTipoSepulturaDestino(tipoSepulturaDestino))
        })
        .catch((error) => {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
        })
    }
  }
}

actions.loadRelatoriosFalecido = () => {
  return (dispatch, getState) => {
    const msgErro = 'Erro ao buscar relatórios de falecidos'
    getDocumentoByTipoTelaEntidadeBase(enums.TipoTela.FORM_SPLIT_BUTTON, enums.EntidadeBase.CONTRATO)
      .then((response) => {
        const data = _.get(response, 'data', null)
        dispatch(actions.setRelatoriosFalecido(data))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.reajustarContratos = (params) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}/reajustar`, params)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Reajuste aplicado'], 'success')))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Ocorreu um erro ao aplicar o reajuste'], 'error')))
      })
  }
}

export { types, actions }
