import React from "react";
import { connect } from "react-redux";
import logo_phoenix_icon from "../../../assets/images/logo/phoenix_logo.jpg";
import { Page } from "../../../components/page/Page";
import { ConvenioList } from "./ConvenioList";
import { actions } from "../convenio.actions";
import { Card } from "primereact/card";
import { formatarTelefone } from "../../../utils/index";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { isArrayNotEmpty } from "../../../utils/index";
import { icons } from "../../../constants";
import GrowlMessenger from "../../../components/growl/GrowlMessenger";

const mapStateToProps = state => {
  return {
    record: state.crudConvenioState.currentRecord,
    records: state.crudConvenioState.currentRecord.empresas
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      if (field === "localidade") {
        dispatch(actions.setField("categorias", []));
        dispatch(actions.setField("idCategoria", null));
        dispatch(actions.setField("nomeCategoria", ""));
        dispatch(actions.setField("subCategorias", []));
        dispatch(actions.setField("idSubCategoria", null));
        dispatch(actions.setField("nomeSubCategoria", ""));

        if (value) {
          dispatch(actions.loadCategoriasPai(value.id));
          dispatch(actions.setField("idLocalidade", value.id));
          dispatch(actions.setField("nomeLocalidade", value.value));
          actions.afterLocalidade = data => {
            dispatch(actions.setField("categorias", data));
          };
        } else {
          dispatch(actions.loadLocalidades());
          dispatch(actions.setField("idLocalidade", null));
          dispatch(actions.setField("nomeLocalidade", ""));
        }
      } else if (field === "categoria") {
        dispatch(actions.setField("subCategorias", []));
        dispatch(actions.setField("idSubCategoria", null));
        dispatch(actions.setField("nomeSubCategoria", ""));

        if (value) {
          dispatch(actions.setField("idCategoria", value.id));
          dispatch(actions.setField("nomeCategoria", value.value));
          if (event.originalEvent.type === "click") {
            dispatch(actions.consultarConvenios());
          }
        } else {
          dispatch(actions.setField("idCategoria", null));
          dispatch(actions.setField("nomeCategoria", ""));
          dispatch(actions.consultarConvenios());
        }
      } else if (field === "subcategoria") {
        if (value) {
          dispatch(actions.setField("idSubCategoria", value.id));
          dispatch(actions.setField("nomeSubCategoria", value.value));
          if (event.originalEvent.type === "click") {
            dispatch(actions.consultarConvenios());
          }
        } else {
          dispatch(actions.setField("idSubCategoria", null));
          dispatch(actions.setField("nomeSubCategoria", ""));
          dispatch(actions.consultarConvenios());
        }
      }
    },
    loadCategoriasPai: (oidLocalidade, hash) => dispatch(actions.loadCategoriasPai(oidLocalidade, hash)),
    loadSubCategorias: (idLocalidade, idCategoria, hash) => dispatch(actions.loadSubCategorias(idLocalidade, idCategoria, hash)),
    onEnter: () => {
      dispatch(actions.consultarConvenios());
    },
    setField: (field, value) => {
      dispatch(actions.setField(field, value));
    },
    loadLocalidades: (hash) => dispatch(actions.loadLocalidades(hash))
  };
};

class ConvenioListPageContainer extends React.Component {
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search)
    const hash = searchParams.get('hash');

    const currentRecord = Object.assign({}, this.props.record, { hash: hash })
    actions.fetchRecordSuccess(currentRecord)
    this.props.record.hash = hash

    this.props.loadLocalidades(hash);
    actions.afterSearch = () => {
      let record = this.props.record;
      if (record.localidades.length > 0) {
        if (record.idLocalidade === "") {
          let localidade = record.localidades.filter(localidade => {
            return localidade.value === "Blumenau";
          });
          if (isArrayNotEmpty(localidade)) {
            this.props.setField("idLocalidade", localidade[0].id);
            this.props.setField("nomeLocalidade", localidade[0].value);
            this.props.loadCategoriasPai(localidade[0].id, hash);
          }
        } else {
          this.props.loadCategoriasPai(record.idLocalidade, hash);
        }
      }
    };
    actions.afterLoadCategoriasPai = () => {
      this.props.onEnter();
    };
    actions.afterLoadCategoria = () => {
      if (this.props.record.idCategoria) {
        const searchParams = new URLSearchParams(this.props.location.search)
        const hash = searchParams.get('hash');
        this.props.loadSubCategorias(this.props.record.idLocalidade, this.props.record.idCategoria, hash);
      }
    };
  }

  changeHandler = event => {
    event.idLocalidade = this.props.record.idLocalidade;
    this.props.changeHandler(event);
  };

  onKeyDown = e => {
    this.props.onEnter();
  };
  onKeyDownSubCategoria = e => {
    this.props.onEnter();
  };

  itemTemplate = empresa => {
    if (empresa && empresa.nome) {
      let headerText = empresa.nome + " - " + empresa.categoria;
      return (
        <div style={{ padding: ".5em" }} className="p-col-12 p-md-4 p-lg-3">
          <Card style={{ textAlign: "center", height: "100%" }}>
            <div className="p-grid">
              <div className="p-col-12 p-md-4 p-lg-4">
                <img className="list-item" src={logo_phoenix_icon} alt={"logomarca phoenix"} />
              </div>
              <div className="p-col-12 p-md-8 p-lg-8">
                <div className="empresa-detalhes">
                  <strong>{headerText}</strong>
                </div>
                <div className="empresa-detalhes">{empresa.endereco}</div>
                <div className="empresa-detalhes">{empresa.bairro}</div>
                <div className="empresa-detalhes">{empresa.localidade}</div>
                <div className="empresa-detalhes">
                  {empresa.telefone ? formatarTelefone(empresa.telefone) : "Não informado"}
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    } else return <div />;
  };

  render() {
    let localidadeSelecionada = {
      id: this.props.record.idLocalidade,
      value: this.props.record.nomeLocalidade
    };
    let categoriaSelecionada = {
      id: this.props.record.idCategoria,
      value: this.props.record.nomeCategoria
    };
    let subCategoriaSelecionada = {
      id: this.props.record.idSubCategoria,
      value: this.props.record.nomeSubCategoria
    };

    const header = (
      <div className="page-convenio">
        <div className="page__header">
          <div className="p-grid p-fluid">
            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3">
              <Dropdown
                showClear={true}
                value={localidadeSelecionada}
                placeholder="Cidade*"
                options={this.props.record.localidades}
                onChange={this.changeHandler}
                optionLabel="value"
                optionValue={(v) => v}
                name="localidade"
                autoWidth={false}
              />
            </div>

            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3">
              <Dropdown
                showClear={true}
                value={categoriaSelecionada}
                placeholder="Categoria"
                options={this.props.record.categorias}
                onChange={this.changeHandler}
                onKeyEnterCategoria={this.onKeyDownSubCategoria}
                optionLabel="value"
                optionValue={(v) => v}
                name="categoria"
                autoWidth={false}
                filter={true}
                filterBy="value"
              />
            </div>

            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3">
              <Dropdown
                showClear={true}
                value={subCategoriaSelecionada}
                placeholder="Especialidade"
                onKeyEnterCategoria={this.onKeyDown}
                options={this.props.record.subCategorias}
                onChange={this.changeHandler}
                optionLabel="value"
                optionValue={(v) => v}
                name="subcategoria"
                autoWidth={false}
                filter={true}
                filterBy="value"
              />
            </div>
            <div className="p-col-1 p-sm-1 p-md-1 p-lg-1 p-xl-1 alinhamento-botao">
              <Button
                className="p-button.green-btn"
                icon={icons.SEARCH_ICON}
                disabled={!localidadeSelecionada || !localidadeSelecionada.id}
                onClick={this.props.onEnter}
              />
            </div>
          </div>
        </div>
      </div>
    );

    let content = "";
    if (
      this.props.record &&
      this.props.record.empresas &&
      this.props.record.empresas.length > 0 &&
      this.props.record.empresas[0].oidPessoa !== undefined
    ) {
      content = <ConvenioList props={this.props} itemTemplate={this.itemTemplate} />;
    }
    return <div className="page-convenio">
        <div className="page-convenio__header">
          <GrowlMessenger/>
          <Card className="page-convenio-card">{header}</Card>
        </div>
        <div className="page-convenio__content">
          {content}
        </div>
      </div>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConvenioListPageContainer);
