const blankToNull = require('./../../../utils').blankToNull;
export function validate(record){
    blankToNull(record);
    const errors = [];
    if (!record.nomeEmpresa || record.nomeEmpresa.trim().length < 4) {
        errors.push({ field: 'nome', message: 'O campo nome é obrigatório e deve ter mais de 3 caracteres' });
    }

    if (!record.codigoEmpresa || record.codigoEmpresa.trim().length < 4) {
        errors.push({ field: 'sigla', message: 'O campo sigla é obrigatório e deve ter mais de 3 caracteres' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }
    
    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}