import { types } from "./pessoa.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columnsLookup: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "numeroDocumento",
      header: "CPF/CNPJ",
      columnKey: "numeroDocumento",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      filter: true
    },
    {
      field: "nomeFantasia",
      header: "Nome Fantasia",
      columnKey: "nomeFantasia",
      sortable: true,
      filter: true
    }
    
  ],
  currentRecord: {
    oid: "",
    nome: "",
    nomeFantasia: "",
    numeroDocumento: "",
    numeroDocumentoRaiz: "",
    inscricaoEstadual: "",
    complemento: "",
    email: "",
    site: "",
    telefone: "",
    logradouro: "",
    numeroLogradouro: "",
    bairro: "",
    cidade:"",
    contato:"",
    cep: "",
    beneficiarioINSS: true,
    numeroPis: "",
    dataNascimento: "",
    dataFalecimento: "",
    ativo: true
  },
  lookupReligiao: {
    visible: false,
    modal: true,
    header: 'Religião'
  },
  lookupCategoria: {
    visible: false,
    modal: true,
    header: 'Categoria'
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDPessoaReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_RELIGIAO_VISIBLE:
      return setLoockupReligiaoVisible(state, action);
    case types.SET_LOOKUP_CATEGORIA_VISIBLE:
      return setLoockupCategoriaVisible(state, action);
    default:
      return result
  }
}

function setLoockupReligiaoVisible(state, action) {
  let lookupReligiao = state.lookupReligiao;
  lookupReligiao = Object.assign({}, lookupReligiao, { visible: action.visible });
  return Object.assign({}, state, { lookupReligiao })
}

function setLoockupCategoriaVisible(state, action) {
  let lookupCategoria = state.lookupCategoria;
  lookupCategoria = Object.assign({}, lookupCategoria, { visible: action.visible });
  return Object.assign({}, state, { lookupCategoria })
}

