import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import utils from '../../utils'
import { actions as localizacaoActions } from '../localizacao/localizacao.actions'
import storage from '../../services/stoage.service'

const BASE_PATH = '/lottustech/agendas'

const crud = build(validate, BASE_PATH)
const prefix = 'crudAgenda'

const newTypes = {
  BOFORE_SAVE: `${prefix}.beforeSave`,
  RESET: `${prefix}reset`,
  SET_LOOKUP_PESSOA_VISIBLE: `${prefix}setLookupPessoaVisible`,
  SET_LOOKUP_LOCALIZACAO_VISIBLE: `${prefix}setLookupLocalizacaoVisible`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setLookupPessoaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_PESSOA_VISIBLE,
    visible,
  }
}

actions.setLookupLocalizacaoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_LOCALIZACAO_VISIBLE,
    visible,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.beforeSave = () => {
  return {
    type: types.BOFORE_SAVE,
  }
}

actions.aplicarFiltrosCarregarDadosLocalizacao = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudAgendaState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(localizacaoActions.setSelectedFilters(filterParams.campo, filterParams.valor, null, null))
    }
    const filtro = {
      campo: 'capela',
      valor: true,
      tipoFiltro: 'IGUAL_A',
    }
    dispatch(localizacaoActions.setFiltroAvancado(filtro))

    // dispatch(localizacaoActions.setSelectedFilters("disponivel", true));
    if (record.unidade && record.unidade.id) {
      const filtro = {
        campo: 'oid',
        valor: record.unidade.id,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['unidade'],
      }
      dispatch(localizacaoActions.setFiltroAvancado(filtro))
    }
    dispatch(localizacaoActions.loadLookup())
  }
}

export { types, actions }
