import { types } from './consultaRemessa.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils/index'

const initialState = {
  id: 'consultaRemessa',
  booleanFilter: { status: '' },
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Identificador',
      value: {
        field: 'identificador',
        header: 'Identificador',
        columnKey: 'identificador',
        sortable: true,
        filter: true,
        columnSortField: 'identificador',
        visible: true,
      },
    },
    {
      label: 'Tipo',
      value: {
        field: 'tipo',
        header: 'Tipo',
        columnKey: 'tipo',
        sortable: true,
        filter: true,
        columnSortField: 'tipo',
        visible: true,
      },
    },
    {
      label: 'Data Processamento',
      value: {
        field: 'dataProcessamento',
        header: 'Data Processamento',
        columnKey: 'dataProcessamento',
        sortable: true,
        filter: true,
        columnSortField: 'dataProcessamento',
        visible: true,
        fieldFormatter: utils.formatarDataHora,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
    {
      label: 'Usuário',
      value: {
        field: 'usuarioGravacao',
        header: 'Usuário',
        columnKey: 'usuarioGravacao',
        sortable: true,
        filter: true,
        columnSortField: 'usuarioGravacao',
        visible: true,
      },
    },
    {
      label: 'Status',
      value: {
        field: 'status',
        header: 'Status',
        columnKey: 'status',
        sortable: true,
        filter: true,
        columnSortField: 'status',
        visible: true,
      },
    },
  ],
  colunasItens: [
    {
      field: 'multiselect',
      header: 'Seleção',
      columnKey: 'multiselect',
      visible: true,
    },
    {
      field: 'oidLancamentoFinanceiro',
      header: 'Lançamento',
      columnKey: 'oidLancamentoFinanceiro',
      sortable: true,
      filter: true,
      columnSortField: 'oidLancamentoFinanceiro',
      visible: true,
    },
    {
      field: 'mensagem',
      header: 'Mensagem',
      columnKey: 'mensagem',
      sortable: true,
      filter: true,
      columnSortField: 'mensagem',
      visible: true,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
    },
  ],
  colunasLogs: [
    {
      field: 'dataGravacao',
      header: 'Data',
      columnKey: 'dataGravacao',
      sortable: true,
      filter: true,
      columnSortField: 'dataGravacao',
      visible: true,
      fieldFormatter: 'formatarDataHora',
    },
    {
      field: 'usuarioGravacao',
      header: 'Usuário',
      columnKey: 'usuarioGravacao',
      sortable: true,
      filter: true,
      columnSortField: 'usuarioGravacao',
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Mensagem',
      columnKey: 'descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    identificacao: '',
    itens: [],
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDConsultaRemessaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('consultaremessa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.UNMOUNT:
      return unmount(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function unmount(state, action) {
  const currentRecord = initialState.currentRecord
  return Object.assign({}, state, { currentRecord })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
