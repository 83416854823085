import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { LookupField } from '../../../components/lookup/LookupField'
import { getMessage } from '../../../utils/index'
import LtsLookupContaCorrente from '../../../components/lottus/lts-lookup-conta-corrente'
import utils from '../../../utils'
import _ from 'lodash'
import LtsLookupDocumento from '../../../components/lottus/lts-lookup-documento'

export const MenuForm = (props) => {
  const record = props.record
  const messages = props.messages
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>
            {getMessage(messages, 'menu.label.menu')} {props.record.oid || ''}
          </h3>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, 'menu.label.nome')}</label>
              <InputText value={props.record.nome} onChange={props.changeHandler} name="nome" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, 'menu.label.caminho')}</label>
              <InputText value={props.record.caminho} onChange={props.changeHandler} name="caminho" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, 'menu.label.menusuperior')}</label>
              <LookupField
                value={record.parent ? record.parent.value : ''}
                name="parent"
                field="nome"
                history={props.history}
                onKeyPress={props.lookupMenuSuperiorOnKeyPress}
                onComplete={props.lookupMenuSuperiorOnComplete}
                onInputFilter={props.lookupMenuSuperiorOnInputFilter}
                editDisabled={props.lookupMenuSuperior.editDisabled}
                onEditField={props.lookupMenuSuperiorOnEdit}
                onClick={props.lookupMenuSuperiorOnClick}
                visible={props.lookupMenuSuperior.visible}
                modal={props.lookupMenuSuperior.modal}
                header={props.lookupMenuSuperior.header}
                onHide={props.lookupMenuSuperiorOnHide}
                onConfirm={props.lookupMenuSuperiorOnConfirm}
                onCancel={props.lookupMenuSuperiorOnCancel}
                records={props.lookupMenuSuperior.records}
                columns={props.lookupMenuSuperior.columns}
                totalRecords={props.lookupMenuSuperior.totalRecords}
                sortOrder={props.lookupMenuSuperior.order}
                sortField={props.lookupMenuSuperior.sortField}
                first={props.lookupMenuSuperior.first}
                rows={props.lookupMenuSuperior.rows}
                onPage={props.lookupMenuSuperiorOnPage}
                onFilter={props.lookupMenuSuperiorOnFilter}
                onSort={props.lookupMenuSuperiorOnSort}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-4 p-sm-3 p-md-2 p-lg-2 p-xl-2">
              <label className="label">{getMessage(messages, 'menu.label.ordem')}</label>
              <InputText keyfilter={'pint'} value={record.ordem} onChange={props.changeHandler} name="ordem" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-6 p-sm-4 p-md-3 p-lg-3 p-xl-3">
              <label className="label">{getMessage(messages, 'menu.label.ordemdashboard')}</label>
              <InputText
                keyfilter={'pint'}
                value={record.ordemDashboard}
                onChange={props.changeHandler}
                name="ordemDashboard"
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12">
              <label htmlFor="chkindicadorPersonalizado" className="label">
                {getMessage(messages, 'menu.label.indicadorpersonalizado')}
              </label>
              <Checkbox
                inputId="chkindicadorPersonalizado"
                checked={record.indicadorPersonalizado}
                onChange={props.changeHandler}
                style={{ marginLeft: '5px' }}
                name="indicadorPersonalizado"
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-4 p-sm-3 p-md-2 p-lg-2 p-xl-2">
              <label className="label">{getMessage(messages, 'menu.label.cor')}</label>
              <InputText value={record.cor} onChange={props.changeHandler} name="cor" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, 'menu.label.caminhoicone')}</label>
              <InputText value={record.linkIcone} onChange={props.changeHandler} name="linkIcone" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <label className="label">{getMessage(messages, 'menu.label.textocomplementar')}</label>
              <InputText value={record.textoComplementar} onChange={props.changeHandler} name="textoComplementar" />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
              <span className="p-float-label">
                <LtsLookupDocumento
                  label={utils.getMessage(messages, 'menu.label.documento')}
                  value={_.get(record, 'documento.value', '')}
                  oidRecord={_.get(record, 'documento.id', '')}
                  history={props.history}
                  onChange={(e) => props.setDocumento(e)}
                  editHidden={true}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
