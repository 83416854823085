import React from 'react'
import { Galleria } from 'primereact/galleria'
import _ from 'lodash'

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 5,
  },
  {
    breakpoint: '768px',
    numVisible: 3,
  },
  {
    breakpoint: '560px',
    numVisible: 1,
  },
]

const itemTemplate = (item) => {
  return (
    <img
      src={item.image}
      onError={(e) => (e.target.src = '')}
      alt={item.alt}
      style={{ width: '80%', display: 'block' }}
    />
  )
}

const thumbnailTemplate = (item) => {
  return (
    <img
      src={item.thumbnail}
      onError={(e) => (e.target.src = '')}
      alt={item.alt}
      style={{ width: '30%', height: '30%', display: 'block' }}
    />
  )
}

/**
 *
 * @param anexos - estrutura de AnexoDTO recebida do backend
 * @returns {*}
 */
const buildImageInfo = (anexos) => {
  return anexos
    .filter((a) => !_.isEmpty(_.get(a, 'url', '')))
    .map((a) => ({
      image: a.url,
      thumbnail: a.url,
      alt: a.filename,
      title: a.filename,
    }))
}

const resolveTitulo = (value) => {
  if (_.isEmpty(value)) {
    return null
  }
  return <h4>{value}</h4>
}
export default function LtsGalleria(props) {
  const images = buildImageInfo(_.get(props, 'anexos', []))
  return (
    <React.Fragment>
      {resolveTitulo(props.titulo)}
      <Galleria
        value={images}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        circular
        style={{ maxWidth: '400px' }}
        showItemNavigators
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
        showThumbnails={_.defaultTo(images, []).length > 1}
      />
    </React.Fragment>
  )
}
