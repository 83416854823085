import React from 'react';
import ConfiguracaoBoletoListPageContainer from './ConfiguracaoBoletoListPageContainer';

export class ConfiguracaoBoletoListPage extends React.Component{
    
    render(){
      return (
        <ConfiguracaoBoletoListPageContainer {...this.props}/>
      )
    }
}

