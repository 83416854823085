import React from 'react';
import ReligiaoListPageContainer from './ReligiaoListPageContainer';

export class ReligiaoListPage extends React.Component{
    
    render(){
      return (
        <ReligiaoListPageContainer {...this.props}/>
      )
    }
}

