import { types } from "./portalClienteCarteirinha.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  id: "portalClienteCarteirinha",
  showHideColumns: [
    {
      label: "Seleção",
      value: {
        field: "multiselect",
        header: "Seleção",
        columnKey: "multiselect",
        visible: true
      }
    },
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: false,
        filter: false,
        columnSortField: "oid",
        visible: false
      }
    },
    {
      label: "CPF",
      value: {
        field: "cpf",
        header: "CPF",
        columnKey: "cpf",
        sortable: true,
        filter: true,
        visible: true
      }
    },
    {
      label: "Nome",
      value: {
        field: "nome",
        header: "Nome",
        columnKey: "nome",
        sortable: true,
        filter: true,
        visible: true
      }
    },
    {
      label: "Nascimento",
      value: {
        field: "dataNascimento",
        header: "Nascimento",
        columnKey: "dataNascimento",
        sortable: true,
        filter: true,
        visible: true,
        fieldFormatter: "formatarData"
      }
    },
    {
      label: "Carteirinha",
      value: {
        field: "oidContrato",
        header: "Carteirinha",
        columnKey: "oidContrato",
        sortable: false,
        filter: false,
        columnSortField: "oidContrato",
        visible: true
      }
    }
  ],
  columns: [],
  currentRecord: {},
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDPortalClienteCarteirinhaReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}
