import authentication from "../../services/authentication.service";

export const types = {
  CHANGE_HANDLER: "changeHandler",
  LOGIN_SET_ERROR_MESSAGE: "login.loginSetErrorMessage",
  LOGIN_CLEAR_ERROR_MESSAGE: "login.clearErrorMessage"
};

export function changeHandler(field, value) {
  return {
    type: types.CHANGE_HANDLER,
    field,
    value
  };
}

export function loginSetErrorMessage(errorMessage = "") {
  return {
    type: types.LOGIN_SET_ERROR_MESSAGE,
    errorMessage
  };
}

export function loginClearErrorMessage() {
  return {
    type: types.LOGIN_CLEAR_ERROR_MESSAGE
  };
}

export function reenviarSenha() {
  return (dispatch, getState) => {
    let state = getState()[`esqueciSenhaState`];
    const cpf = state.currentRecord.cpf;
    Object.assign(state, { showMessage: false });
    authentication.reenviarSenha(cpf).then(response => {
      state = Object.assign(state, { showMessage: true });
      Object.assign(state, { message: response.data });
      dispatch(loginSetErrorMessage(response.data.mensagem));
    });
  };
}
