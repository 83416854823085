import React from 'react'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { LookupField } from '../../../components/lookup/LookupField'
import utils from '../../../utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'

import './PessoaFisicaForm.scss'
import { enums } from '../../../constants'
import { Checkbox } from 'primereact/checkbox'
import LtsLookupCategoria from '../../../components/lottus/lts-lookup-categoria'
import _ from 'lodash'
import LtsLookupPessoaParent from '../../../components/lottus/lts-lookup-pessoa-parent'

export const PessoaFisicaForm = (props) => {
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler
  let religiao = record.nomeReligiao
  if (record.religiao) {
    religiao = record.religiao.nome
  }

  let categorias = []
  if (record.categorias) {
    record.categorias.forEach((categoria) => {
      categorias.push({ oid: categoria.id, descricao: categoria.value })
    })
  }

  function resolveNomeMae(record) {
    if (!record) {
      return null
    }
    if (record.pet) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
            <span className="p-float-label">
              <InputText
                keyfilter={utils.getRegexLettersBlankSpace()}
                required={true}
                value={record.nomeMae}
                onChange={changeHandler}
                name="nomeMae"
              />
              <label>{utils.getMessage(messages, 'pessoafisica.label.tutor')}</label>
            </span>
          </div>
        </div>
      )
    }
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
          <span className="p-float-label">
            <LtsLookupPessoaParent
              name={'mae'}
              field={'oidMae'}
              label={utils.getMessage(messages, 'pessoafisica.label.nomemae')}
              value={{ id: _.get(record, 'oidMae', null), value: _.get(record, 'nomeMae', '') }}
              oidRecord={_.get(record, 'oidMae', null)}
              history={props.history}
              onChange={(e) => {
                const data = {
                  oid: _.get(e, 'oid', ''),
                  value: _.get(e, 'nome', ''),
                }
                props.setField('oidMae', data.oid)
                props.setField('nomeMae', data.value)
              }}
              messages={messages}
            />
          </span>
        </div>
      </div>
    )
  }

  function resolveNomePai(record) {
    if (!record) {
      return null
    }
    if (record.pet) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
            <span className="p-float-label">
              <InputText
                keyfilter={utils.getRegexLettersBlankSpace()}
                required={true}
                value={record.nomePai}
                onChange={changeHandler}
                name="nomePai"
              />
              <label>{utils.getMessage(messages, 'pessoafisica.label.especie')}</label>
            </span>
          </div>
        </div>
      )
    }
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
          <span className="p-float-label">
            <LtsLookupPessoaParent
              name={'pai'}
              field={'oidPai'}
              label={utils.getMessage(messages, 'pessoafisica.label.nomepai')}
              value={{ id: _.get(record, 'oidPai', null), value: _.get(record, 'nomePai', '') }}
              oidRecord={_.get(record, 'oidPai', null)}
              history={props.history}
              onChange={(e) => {
                const data = {
                  oid: _.get(e, 'oid', ''),
                  value: _.get(e, 'nome', ''),
                }
                props.setField('oidPai', data.oid)
                props.setField('nomePai', data.value)
              }}
              messages={messages}
            />
          </span>
        </div>
      </div>
    )
  }

  function resolveEscolaridade(record) {
    if (record.pet) {
      return null
    }
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
          <span className="p-float-label">
            <InputText value={record.escolaridade} onChange={changeHandler} name="escolaridade" />
            <label>{utils.getMessage(messages, 'pessoafisica.label.escolaridade')}</label>
          </span>
        </div>
      </div>
    )
  }

  function resolveNaturalidade(record) {
    if (record.pet) {
      return null
    }
    return (
      <div className="p-grid form-group">
        <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
          <span className="p-float-label">
            <InputText value={record.naturalidade} onChange={changeHandler} name="naturalidade" />
            <label>{utils.getMessage(messages, 'pessoafisica.label.naturalidade')}</label>
          </span>
        </div>
      </div>
    )
  }

  function getDadosGerais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>
              {utils.getMessage(messages, 'pessoafisica.label.pessoa')} {record.oid || ''}
            </h3>

            {props.additionalFields}

            <div className="p-grid form-group">
              <div className="p-col-10 p-sm-10 p-md-6 p-lg-4 p-xl-2">
                <span className="p-float-label">
                  <InputMask
                    mask="999.999.999-99"
                    unmask={true}
                    value={record.numeroDocumento}
                    onChange={props.inputMaskOnChange}
                    name="numeroDocumento"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.cpf')}</label>
                </span>
              </div>
              <div
                className="p-col-2 p-sm-2 p-md-6 p-lg-4 p-xl-2"
                style={{ top: '7px', position: 'relative', paddingLeft: '0px' }}
              >
                <Button
                  icon="pi pi-search"
                  style={{ color: '#fff' }}
                  onClick={(e) => props.pesquisarPessoa(record.numeroDocumento)}
                  disabled={record.numeroDocumento.length < 11}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
                <span className="p-float-label">
                  <InputText
                    keyfilter={utils.getRegexLettersBlankSpace()}
                    required={true}
                    value={record.nome}
                    onChange={changeHandler}
                    name="nome"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.nome')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-2">
                <span className="p-float-label">
                  <InputText value={record.rg} onChange={changeHandler} name="rg" maxLength={15} />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.rg')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    value={record.dataNascimento}
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1800')}
                    monthNavigator={true}
                    onChange={changeHandler}
                    name="dataNascimento"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.datanascimento')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    //value={record.dataFalecimento}
                    value={utils.getDateTimeFromNumber(record.dataFalecimento)}
                    yearNavigator={true}
                    showTime={true}
                    yearRange={utils.getYearRangeFromDate('1800')}
                    monthNavigator={true}
                    onChange={changeHandler}
                    name="dataFalecimento"
                  />
                  <label>Data/Hora de falecimento</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <Dropdown
                    autoWidth={false}
                    value={props.sexoSelecionado}
                    options={props.sexos}
                    onChange={changeHandler}
                    optionLabel="name"
                    optionValue={(v) => v}
                    name="sexo"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.sexo')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <label htmlFor="chkativo" className="label">
                  Pet
                </label>
                <Checkbox checked={record.pet} onChange={changeHandler} name="pet" style={{ marginLeft: '10px' }} />
              </div>
            </div>
            {resolveNomeMae(record)}
            {resolveNomePai(record)}

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <InputText value={record.estadoCivil} onChange={changeHandler} name="estadoCivil" />
                  <label>{record.pet ? utils.getMessage(messages, 'pessoafisica.label.porte') : 'Estado civil'} </label>
                </span>
              </div>
            </div>
            {resolveEscolaridade(record)}
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-8 p-md-8 p-lg-6 p-xl-4">
                <span className="p-float-label">
                  <InputText value={record.profissao} onChange={changeHandler} name="profissao" />
                  <label>{record.pet ? utils.getMessage(messages, 'pessoafisica.label.raca') : 'Profissão'}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <InputText value={record.nacionalidade} onChange={changeHandler} name="nacionalidade" />
                  <label>
                    {record.pet
                      ? utils.getMessage(messages, 'pessoafisica.label.cor')
                      : utils.getMessage(messages, 'pessoafisica.label.nacionalidade')}
                  </label>
                </span>
              </div>
            </div>
            {resolveNaturalidade(record)}
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <label htmlFor="chkativo" className="label">
                  Ativo
                </label>
                <Checkbox checked={record.ativo} onChange={changeHandler} name="ativo" style={{ marginLeft: '10px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosEndereco() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>{utils.getMessage(messages, 'pessoafisica.label.endereco')}</h3>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                  <InputMask
                    mask="99999-999"
                    unmask={true}
                    value={record.cep}
                    onChange={props.cepOnChange}
                    name="cep"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.cep')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputText value={record.nomeLogradouro} onChange={changeHandler} name="nomeLogradouro" />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.endereco')}</label>
                </span>
              </div>
              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <InputText
                    keyfilter="alphanum"
                    value={record.numeroLogradouro}
                    onChange={changeHandler}
                    name="numeroLogradouro"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.numero')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputText value={record.complemento} onChange={changeHandler} name="complemento" />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.complemento')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputText value={record.bairro} onChange={changeHandler} name="bairro" />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.bairro')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputText
                    keyfilter={utils.getRegexLettersBlankSpace()}
                    value={record.cidade}
                    onChange={changeHandler}
                    name="cidade"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.cidade')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-2 p-sm-2 p-md-2 p-lg-1 p-xl-1">
                <span className="p-float-label">
                  <InputText value={record.uf} onChange={changeHandler} name="uf" />
                  <label>UF</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosContato() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>{utils.getMessage(messages, 'pessoafisica.label.contatos')}</h3>
            <div className="p-grid form-group">
              <div className="p-col-10 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                <span className="p-float-label">
                  <InputMask
                    mask={utils.getMascaraTelefone(_.get(record, 'telefone', null))}
                    unmask={true}
                    autoClear={false}
                    keyfilter="pint"
                    value={record.telefone}
                    onChange={props.inputMaskOnChange}
                    name="telefone"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.telefone')}</label>
                </span>
              </div>
              {record.oid ? (
                <div className="p-col-2">
                  <Button
                    style={{ marginTop: '10px' }}
                    icon="pi pi-whatsapp"
                    className={'p-button-success'}
                    onClick={(e) => props.enviarWhatsApp(record.telefone)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="p-grid form-group">
              <div className="p-col-10 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                <span className="p-float-label">
                  <InputMask
                    autoClear={false}
                    mask={utils.getMascaraTelefone(_.get(record, 'segundoTelefone', null))}
                    unmask={true}
                    keyfilter="pint"
                    value={record.segundoTelefone}
                    onChange={props.inputMaskOnChange}
                    name="segundoTelefone"
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.segundo.telefone')}</label>
                </span>
              </div>
              {record.oid ? (
                <div className="p-col-2">
                  <Button
                    style={{ marginTop: '10px' }}
                    icon="pi pi-whatsapp"
                    className={'p-button-success'}
                    onClick={(e) => props.enviarWhatsApp(record.segundoTelefone)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="p-grid form-group">
              <div className="p-col-10 p-sm-10 p-md-6 p-lg-6 p-xl-4">
                <span className="p-float-label">
                  <InputText keyfilter="email" value={record.email} onChange={changeHandler} name="email" />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.email')}</label>
                </span>
              </div>
              {record.oid ? (
                <div className="p-col-2">
                  <Button icon="pi pi-send" onClick={props.enviarEmail} style={{ marginTop: '10px' }} />
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputText value={record.site} onChange={changeHandler} name="site" />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.site')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <InputTextarea
                    inputid="acSimples"
                    value={record.contato}
                    onChange={changeHandler}
                    name="contato"
                    rows={1}
                    cols={30}
                    autoResize={true}
                  />
                  <label>{utils.getMessage(messages, 'pessoafisica.label.contato')}</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosOutrasInformacoes() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>{utils.getMessage(messages, 'pessoafisica.label.outrasinformacoes')}</h3>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <LookupField
                  label={utils.getMessage(messages, 'pessoafisica.label.religiao')}
                  value={religiao}
                  name="nomeReligiao"
                  field="nome"
                  history={props.history}
                  onKeyPress={props.lookupReligiaoOnKeyPress}
                  onComplete={props.lookupReligiaoOnComplete}
                  onInputFilter={props.lookupReligiaoOnInputFilter}
                  editDisabled={props.lookupReligiao.editDisabled}
                  onEditField={props.lookupReligiaoOnEdit}
                  onClick={props.lookupReligiaoOnClick}
                  visible={props.lookupReligiao.visible}
                  modal={props.lookupReligiao.modal}
                  header={props.lookupReligiao.header}
                  onHide={props.lookupReligiaoOnHide}
                  onConfirm={props.lookupReligiaoOnConfirm}
                  onCancel={props.lookupReligiaoOnCancel}
                  records={props.lookupReligiao.records}
                  columns={props.lookupReligiao.columns}
                  totalRecords={props.lookupReligiao.totalRecords}
                  sortOrder={props.lookupReligiao.order}
                  sortField={props.lookupReligiao.sortField}
                  first={props.lookupReligiao.first}
                  rows={props.lookupReligiao.rows}
                  onPage={props.lookupReligiaoOnPage}
                  onFilter={props.lookupReligiaoOnFilter}
                  onSort={props.lookupReligiaoOnSort}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                <span className="p-float-label">
                  <LtsLookupCategoria
                    label={utils.getMessage(messages, 'pessoafisica.label.categorias')}
                    value={categorias}
                    history={props.history}
                    editHidden={true}
                    field={'descricao'}
                    multiple={true}
                    onChange={(e) => props.setCatagorias(e)}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosSenha() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>Senha de Acesso</h3>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-4">
                <span className="p-float-label">
                  <InputText value={record.senha} name="plainSenha" type="password" onChange={changeHandler} />
                  <label>Senha</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.PESSOA_FISICA}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (utils.nonNull(props.renderTabView) && !props.renderTabView) {
    return (
      <div>
        {getDadosGerais()}
        {getDadosEndereco()}
        {getDadosContato()}
        {getDadosOutrasInformacoes()}
        {getDadosSenha()}
      </div>
    )
  } else {
    return (
      <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
        <TabPanel header="Dados Gerais">
          <div>
            {getDadosGerais()}
            {getDadosEndereco()}
            {getDadosContato()}
            {getDadosOutrasInformacoes()}
            {getDadosSenha()}
          </div>
        </TabPanel>
        <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
      </TabView>
    )
  }
}
