import React from 'react';

import FalecidosRelatorioFormPageContainer from './FalecidosRelatorioFormPageContainer';

export class FalecidosRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <FalecidosRelatorioFormPageContainer {...this.props} />
        )
    }
}
