import React from "react";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {enums} from "../../../constants";
import utils from "../../../utils";

import "./ConfiguracaoBoletoForm.scss";

export const ConfiguracaoBoletoForm = props => {
    const messages = props.messages;
    const record = props.record;
    const changeHandler = props.changeHandler;

    let unidade = {};
    if (record.unidade.id) {
        unidade = {id: record.unidade.id, value: record.unidade.value};
    }

    function getDadosPagamentoDivergente() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h3>{utils.getMessage(messages, "configuracaoboleto.label.pagamentodivergente")}</h3>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.tipopagamentodivergente")}
                                </label>
                                <Dropdown
                                    autoWidth={false}
                                    value={record.tipoPagamentoDivergente}
                                    options={enums.TiposPagamentoDivergente}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="tipoPagamentoDivergente"
                                />
                            </div>
                            {getValorMinimoPagamentoDivergente()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function getValorMinimoPagamentoDivergente() {
        if (record.tipoPagamentoDivergente && record.tipoPagamentoDivergente.id === 1) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.pagamentodivergente")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={getValorReais(record.valorMinimoPagamentoDivergente)}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="valorMinimoPagamentoDivergente"
                    />
                </div>
            );
        }
    }

    function getDadosEnvioSMS() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h3>{utils.getMessage(messages, "configuracaoboleto.label.avisossms")}</h3>

                        <div className="p-grid form-group">
                            <div className="p-col-12">
                                <Checkbox
                                    inputId="chkPromissoria"
                                    checked={record.enviarAvisoVencimentoSmsAntesVencimento}
                                    onChange={props.changeHandler}
                                    name="enviarAvisoVencimentoSmsAntesVencimento"
                                />
                                <label htmlFor="chkPromissoria" className="p-checkbox-label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.avisoantesvencimento")}
                                </label>
                            </div>
                        </div>

                        <div className="p-grid form-group">
                            <div className="p-col-12">
                                <Checkbox
                                    inputId="chkPromissoria"
                                    checked={record.enviarAvisoVencimentoSmsDiaVencimento}
                                    onChange={props.changeHandler}
                                    name="enviarAvisoVencimentoSmsDiaVencimento"
                                />
                                <label htmlFor="chkPromissoria" className="p-checkbox-label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.avisodiavencimento")}
                                </label>
                            </div>
                        </div>

                        <div className="p-grid form-group">
                            <div className="p-col-12">
                                <Checkbox
                                    inputId="chkPromissoria"
                                    checked={record.enviarAvisoVencimentoSmsAposVencimento}
                                    onChange={props.changeHandler}
                                    name="enviarAvisoVencimentoSmsAposVencimento"
                                />
                                <label htmlFor="chkPromissoria" className="p-checkbox-label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.avisodepoisvencimento")}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function getDadosInstrucoes() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h3> {utils.getMessage(messages, "configuracaoboleto.label.instrucoes")}</h3>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.tipodesconto")}</label>
                                <Dropdown
                                    autoWidth={false}
                                    value={record.tipoDesconto}
                                    options={enums.TipoInstrucaoValor}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="tipoDesconto"
                                />
                            </div>
                            {getResolveTipoDesconto()}
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.tipomulta")}</label>
                                <Dropdown
                                    autoWidth={false}
                                    value={record.tipoMulta}
                                    options={enums.TipoInstrucaoValor}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="tipoMulta"
                                />
                            </div>
                            {getResolveTipoMulta()}
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.tipojurosmora")}</label>
                                <Dropdown
                                    autoWidth={false}
                                    value={record.tipoJurosMora}
                                    options={enums.TipoInstrucaoValor}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="tipoJurosMora"
                                />
                            </div>
                            {getResolveTipoJurosMora()}
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.negativacaoserasa")}
                                </label>
                                <InputNumber
                                    name={"diasNegativacaoSerasa"}
                                    value={record.diasNegativacaoSerasa}
                                    onChange={changeHandler}
                                    min={0}
                                    max={99}
                                />
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-3">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.diasprotesto")}</label>
                                <InputNumber
                                    name={"diasProtesto"}
                                    value={record.diasProtesto}
                                    onChange={changeHandler}
                                    min={0}
                                    max={99}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function getResolveTipoDesconto() {
        // { id: 1, value: "Valor em Reais" }
        if (record.tipoDesconto && record.tipoDesconto.id === 1) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.valordesconto")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={getValorReais(record.valorDesconto)}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="valorDesconto"
                    />
                </div>
            );
            // { id: 2, value: "Percentual" }
        } else if (record.tipoDesconto && record.tipoDesconto.id === 2) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.valorpercentual")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={record.percentualDesconto}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="percentualDesconto"
                    />
                </div>
            );
        }
    }

    function getResolveTipoMulta() {
        // { id: 1, value: "Valor em Reais" }
        if (record.tipoMulta && record.tipoMulta.id === 1) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label className="label">{utils.getMessage(messages, "configuracaoboleto.label.valormulta")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={getValorReais(record.valorMulta)}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="valorMulta"
                    />
                </div>
            );
            // { id: 2, value: "Percentual" }
        } else if (record.tipoMulta && record.tipoMulta.id === 2) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.percentualmulta")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={record.percentualMulta}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="percentualMulta"
                    />
                </div>
            );
        }
    }

    function getResolveTipoJurosMora() {
        // { id: 1, value: "Valor em Reais" }
        if (record.tipoJurosMora && record.tipoJurosMora.id === 1) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.valorjurosmora")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={getValorReais(record.valorJurosMora)}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="valorJurosMora"
                    />
                </div>
            );
            // { id: 2, value: "Percentual" }
        } else if (record.tipoJurosMora && record.tipoJurosMora.id === 2) {
            return (
                <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                    <label
                        className="label">{utils.getMessage(messages, "configuracaoboleto.label.percentualjurosmora")}</label>
                    <InputText
                        keyfilter={/^[0-9.,]+$/}
                        value={record.percentualJurosMora}
                        maxLength={10}
                        onBlur={props.onBlurValor}
                        onChange={changeHandler}
                        name="percentualJurosMora"
                    />
                </div>
            );
        }
    }

    return (
        <div>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h3>
                            {utils.getMessage(messages, "configuracaoboleto.label.configuracaoboleto")} {record.oid || ""}
                        </h3>

                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                                <label
                                    className="label"> {utils.getMessage(messages, "configuracaoboleto.label.unidade")}</label>
                                <Dropdown
                                    autoWidth={false}
                                    disabled={props.disableUnit}
                                    value={unidade}
                                    options={props.unidades}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="unidade"
                                />
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.nome")}</label>
                                <InputText value={record.nome} onChange={changeHandler} name="nome"/>
                            </div>
                        </div>

                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.numerocobrancaconvenio")}
                                </label>
                                <InputText
                                    keyfilter="pint"
                                    value={record.numeroConvenioCobranca}
                                    onChange={changeHandler}
                                    name="numeroConvenioCobranca"
                                />
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.numerocobrancacarteira")}
                                </label>
                                <InputText
                                    keyfilter="pint"
                                    value={record.codigoCarteiraCobranca}
                                    onChange={changeHandler}
                                    name="codigoCarteiraCobranca"
                                />
                            </div>
                        </div>

                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.descricaodocumento")}
                                </label>
                                <InputText value={record.descricaoDocumento} onChange={changeHandler}
                                           name="descricaoDocumento"/>
                            </div>
                        </div>

                        <div className="p-grid form-group">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label className="label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.especiedocumento")}
                                </label>
                                <Dropdown
                                    autoWidth={false}
                                    disabled={props.especieDocumento}
                                    value={record.especieDocumento}
                                    options={enums.EspecieDocumento}
                                    onChange={changeHandler}
                                    optionLabel="value"
                                    optionValue={(v) => v}
                                    dataKey="value"
                                    name="especieDocumento"
                                />
                            </div>
                        </div>
                        <div className="p-grid form-group p-align-end">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <label
                                    className="label">{utils.getMessage(messages, "configuracaoboleto.label.numeroparcelas")}</label>
                                <InputNumber
                                    name={"numeroParcelas"}
                                    value={record.numeroParcelas}
                                    onChange={changeHandler}
                                    min={1}
                                    max={120}
                                />
                            </div>
                        </div>
                        <div className="p-grid form-group p-align-end">
                            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                                <Checkbox
                                    inputId="chkPromissoria"
                                    checked={record.formaEmissao}
                                    onChange={props.changeHandler}
                                    name="formaEmissao"
                                />
                                <label htmlFor="chkPromissoria" className="p-checkbox-label">
                                    {utils.getMessage(messages, "configuracaoboleto.label.enviarcorreios")}
                                </label>
                            </div>
                        </div>
                    </div>
                    {getDadosEnvioSMS()}
                    {getDadosInstrucoes()}
                    {getDadosPagamentoDivergente()}
                </div>
            </div>
        </div>
    );

    function getValorReais(valor) {
        if (valor && valor !== "") {
            return "R$ " + valor;
        }
        return "";
    }
};
