import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, enums } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../grupo.actions'
import { GrupoList } from './GrupoList'
import '../../../index.scss'

const mapStateToProps = (state) => {
  return {
    idTela: state.crudGrupoState.id,
    botoesDinamicos: state.crudGrupoState.botoesDinamicos,
    booleanFilter: state.crudGrupoState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'ENTRADA', value: 1 },
      { label: 'SAÍDA', value: 2 },
    ],
    showHideColumns: state.crudGrupoState.showHideColumns,
    messages: state.crudGrupoState.messages,
    columns: state.crudGrupoState.columns,
    records: state.crudGrupoState.records,
    first: state.crudGrupoState.index,
    rows: state.crudGrupoState.max,
    totalRecords: state.crudGrupoState.count,
    sortField: state.crudGrupoState.sortField,
    order: state.crudGrupoState.sortOrder,
    selectedFilters: state.crudGrupoState.selectedFilters,
    selectedRecords: state.crudGrupoState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      // tratamentos para nomes dos filtros enum
      if (['tipo'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/gruposfinanceiros/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),

    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.LISTAGEM)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
    onClickBotaoDinamico: (listId, botao) => {
      dispatch(actions.executarFuncaoDinamica(listId, botao))
    },
    removeAll: () => dispatch(actions.removeAll()),
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class GrupoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()

    this.showHideColumns = []
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }
  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_GRUPO_FINANCEIRO}/novo`)
  }
  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'grupofinanceiro.label.grupos')}
      />
    )
  }

  booleanFilter = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypes}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }
  booleanFieldsTemplate = (rowData, column) => {
    if (utils.nonNull(rowData[column.field])) {
      const bool = this.props.booleanTypes[rowData[column.field]]
      if (utils.nonNull(bool)) {
        return <div>{bool.label}</div>
      }
    }
  }

  oidTemplate = (rowData, column) => {
    return <Link to={`${routes.PATH_GRUPO_FINANCEIRO}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  unidadeTemplate = (rowData) => {
    if (rowData.unidade && rowData.unidade.value && rowData.unidade.value !== 'UND-01') {
      return <div>{rowData.unidade.value}</div>
    }
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    if (column.field === 'tipo') {
      return utils.format(rowData, column, enums.TipoLancamento)
    }
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'tipo') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterHeaderStyle={{ overflow: 'visible' }}
            body={this.booleanFieldsTemplate}
            filterElement={this.booleanFilter(col)}
            bodyClassName="column-grupo-tipo-body-width"
            filterHeaderClassName="column-grupo-tipo-filter-header-width"
            headerClassName="column-grupo-tipo-header-width"
          />
        )
      }
      if (col.field === 'grupoFinanceiroPai.id' || col.field === 'codigoMascara') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'unidade.value') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.unidadeTemplate}
            sortField={col.columnSortField}
            style={{ width: '15%' }}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-unidade-body-width"
            filterHeaderClassName="column-unidade-filter-header-width"
            headerClassName="column-unidade-header-width"
          />
        )
      }
      if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }

  buildContent = () => {
    return (
      <GrupoList
        {...this.props}
        onSelectionChange={this.onSelectionChange}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrupoListPageContainer)
