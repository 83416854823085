import React from 'react';
import OrcamentoListPageContainer from './OrcamentoListPageContainer';

export class OrcamentoListPage extends React.Component{
    
    render(){
      return (
        <OrcamentoListPageContainer {...this.props}/>
      )
    }
}

