const blankToNull = require("./../../../utils").blankToNull;
const isArrayNullOrEmpty = require("./../../../utils").isArrayNullOrEmpty;

export function validate(record) {
  const errors = [];
  if (!record.nome) {
    errors.push({
      field: "nome",
      message: "O campo nome é obrigatório"
    });
  }

  if (!record.entidadeBase) {
    errors.push({
      field: "entidadeBase",
      message: "O campo entidade base é obrigatório"
    });
  }

  if (!record.menu) {
    errors.push({
      field: "menu",
      message: "O campo menu é obrigatório"
    });
  }

  if (isArrayNullOrEmpty(record.perfis)) {
    errors.push({
      field: "perfis",
      message: "É preciso ter pelo menos um perfil selecionado"
    });
  }

  if (!record.grid || isArrayNullOrEmpty(record.grid.colunas)) {
    errors.push({
      field: "grid",
      message: "É preciso ter pelo menos um campo definido"
    });
  }

  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
