import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
const BASE_PATH = '/lottustech/tipossepultura';
const crud = build(validate,BASE_PATH);
const prefix = 'crudTipoSepultura';

const newTypes = {
    RESET: `${prefix}reset`,
    SET_LOOKUP_DOCUMENTO_VISIBLE: `${prefix}setLookupDocumentoVisible`,
    ADD_DOCUMENTO: `${prefix}addDocumento`
};

const actions = new Action(prefix,crud);
const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.setLookupDocumentoVisible = visible => {
    return {
        type: types.SET_LOOKUP_DOCUMENTO_VISIBLE,
        visible
    };
};

actions.addDocumento = (field, value) => {
    return {
        type: types.ADD_DOCUMENTO,
        field,
        value
    };
};

export {types, actions};
