import React from 'react';

import BiFormPageContainer from './BiFormPageContainer';

export class BiFormPage extends React.Component {


  render() {
    return (
        <BiFormPageContainer {...this.props} />
    )
  }
}


