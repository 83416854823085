import React from 'react'
import _ from 'lodash'
import utils from '../../utils'
import LtsDropdownIdValue from './lts-dropdown-id-value'
import apiService from '../../services/api-id-value-service'

export default function LtsDropdownResponsavel(props) {
  const onAfterLoadDatFn = (data) => {
    let newData = [...data]
    const hideRecords = _.get(props, 'hideRecords', [])
    if (utils.isArrayNotEmpty(hideRecords) && utils.isArrayNotEmpty(data)) {
      newData = []
      data.forEach((d) => {
        const hide = hideRecords.find((h) => h === d.id)
        if (!hide) {
          newData.push(d)
        }
      })
    }

    if (props.autoSelectFirstRecord && utils.isArrayNotEmpty(newData)) {
      const value = _.get(props, 'value', '')
      if (!value || !value.id) {
        setTimeout(props.onChange(newData[0]), 150)
      }
    }

    return newData
  }

  const getItens = () => {
    if (props.responsaveisComDependentes) {
      return apiService.getResponsaveisComDependentes
    } else {
      return apiService.getResponsaveis
    }
  }

  return (
    <React.Fragment>
      <LtsDropdownIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        loadDataFn={getItens()}
        loadDataFnParam={props.loadDataFnParam}
        afterLoadDataFn={onAfterLoadDatFn}
      />
    </React.Fragment>
  )
}
