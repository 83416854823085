import React, { useState } from 'react'

import _ from 'lodash'
import { getTemplateDropDownPessoa } from '../../utils/Template'
import { Filtro } from '../../utils/Filtro'
import { enums, icons, routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { PessoaFisicaFormPage } from '../../pages/pessoaFisica'
import { Button } from 'primereact/button'
import LtsLookup from './lts-lookup'
import '../../index.scss'
import { InputText } from 'primereact/inputtext'
import { getFiltroJoinUnidade } from '../../utils/filter-utils'

export default function LtsLookupPessoa(props) {
  const [filters, setFilters] = useState([])
  const [showDialogAddNew, setShowDialogAddNew] = useState(false)

  const columnsPF = [
    {
      field: 'dataNascimento',
      header: 'Nascimento',
      columnKey: 'dataNascimento',
      visible: false,
    },
    {
      field: 'dataFalecimento',
      header: 'Falecimento',
      columnKey: 'dataFalecimento',
      visible: false,
    },
  ]

  const columns = [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: false,
      filter: true,
    },
    {
      field: 'numeroDocumento',
      header: 'CPF/CNPJ',
      columnKey: 'numeroDocumento',
      sortable: true,
      filter: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      filter: true,
    },
    {
      field: 'email',
      header: 'E-mail',
      columnKey: 'email',
      sortable: true,
      filter: true,
    },
    {
      field: 'telefone',
      header: 'Telefone',
      columnKey: 'telefone',
      sortable: true,
      filter: true,
    },
  ]

  const loadDataFn = (params) => {
    let url
    if (props.tipoPessoa === enums.TipoPessoa.JURIDICA) {
      url = '/lottustech/pessoasjuridicas/all'
    } else if (props.tipoPessoa === enums.TipoPessoa.FISICA) {
      url = '/lottustech/pessoasfisicas/all'
    } else {
      url = '/lottustech/pessoas/all'
    }
    return apiService.post(url, params)
  }

  const onKeyPress = (e) => {
    const idValue = _.get(props, 'value', null)
    if (idValue) {
      const value = typeof idValue === 'string' ? idValue : _.get(idValue, 'value', null)
      const filters = [new Filtro('nome', value, 'CONTEM'), new Filtro('numeroDocumento', value, 'CONTEM')]
      const filter = {
        campo: 'filtro',
        valor: filters,
        tipoFiltro: 'OU',
      }
      setFilters([filter])
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({ nome: e.value, oid: null })
    } else {
      props.onChange(value)
    }
  }

  const resolveColumns = () => {
    if (props.tipoPessoa === enums.TipoPessoa.FISICA || props.tipoPessoa === enums.TipoPessoa.AMBAS) {
      return [...columns, ...columnsPF]
    }
    return columns
  }

  const onResolvingColumn = () => {
    const columns_ = resolveColumns()
    return columns_
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false

        if (col.field === 'numeroDocumento') {
          return (
            <Column
              {...col}
              key={col.field}
              bodyClassName="column-nr-documento-body-width"
              filterHeaderClassName="column-nr-documento-filter-header-width"
              headerClassName="column-nr-documento-header-width"
              filterElement={columnFilterElement(col)}
            />
          )
        }
        if (col.field === 'nome') {
          return <Column {...col} key={col.field} filterElement={columnFilterElement(col)} style={{ width: '20em' }} />
        }
        return null
      })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    const filtroUnidade = getFiltroJoinUnidade(_.get(props, 'idUnidade', null))
    if (filtroUnidade) {
      filters.push(filtroUnidade)
    }
    return filters
  }

  const onClickAddButton = () => {
    setShowDialogAddNew(true)
  }

  const onHideDialogAddNew = () => {
    setShowDialogAddNew(false)
  }

  const getDialogAddNew = () => {
    const footer = (
      <div>
        <Button
          label={'Adicionar'}
          onClick={(e) => onConfirmAddNew(e)}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    return (
      <Dialog
        header={props.header}
        visible={showDialogAddNew}
        onHide={onHideDialogAddNew}
        modal={true}
        closable={true}
        style={{ width: '70%' }}
        contentStyle={{ maxHeight: '85%', overflow: 'auto' }}
        footer={footer}
      >
        <React.Fragment>
          <PessoaFisicaFormPage renderToolbar={false} />
        </React.Fragment>
      </Dialog>
    )
  }

  const onConfirmAddNew = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let pessoa
    let url
    if (props.tipoPessoa === enums.TipoPessoa.JURIDICA) {
      url = '/lottustech/pessoasjuridicas/'
      pessoa = props.statePessoaJuridica.currentRecord
    } else if (props.tipoPessoa === enums.TipoPessoa.FISICA) {
      url = '/lottustech/pessoasfisicas/'
      pessoa = props.statePessoaFisica.currentRecord
    } else {
      url = '/lottustech/pessoas/'
    }
    const nome = _.get(pessoa, 'nome', null)
    if (nome) {
      apiService
        .post(url, pessoa)
        .then((response) => {
          const nome = _.get(response, 'data.nome', null)
          const oid = _.get(response, 'data.oid', null)
          props.onChange({ nome, oid })
        })
        .catch((error) => {})
    } else {
      // exibir mensagem
    }
    onHideDialogAddNew()
  }

  const pathOnEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    const tipoPessoa = _.get(props, 'tipoPessoa', null)
    const rota = tipoPessoa === 1 ? routes.PATH_PESSOA_JURIDICA : routes.PATH_PESSOA_FISICA

    if (oid) {
      return `${rota}/${oid}`
    }
    return `${rota}/novo`
  }

  const resolveDisplayValue = () => {
    const idValue = _.get(props, 'value', null)
    return _.get(idValue, 'value', null)
  }

  return (
    <React.Fragment>
      {getDialogAddNew()}
      <LtsLookup
        label={props.label}
        value={resolveDisplayValue()}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={getTemplateDropDownPessoa}
        onKeyPress={onKeyPress}
        onComplete={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEditFn={pathOnEditFn}
        header={props.header}
        columns={resolveColumns()}
        onResolvingColumn={onResolvingColumn}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        showAddButton={_.get(props, 'showAddButton', false)}
        onClickAddButton={onClickAddButton}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        editDisabled={props.editDisabled}
        disabled={props.disabled}
        editHidden={props.editHidden}
      />
    </React.Fragment>
  )
}
