import { types } from "./configAilos.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "configAilos",
  columns: [],
  currentRecord: {
    oid: ""
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false
};

const reducer = new Reducer(initialState, types);

export function CRUDConfigAilosReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("movimentocaixa");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  return state;
}
