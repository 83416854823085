import {Column} from "primereact/column";
import React from "react";
import utils from './index'
import "../index.scss";

export function getColumnsLookupContrato(columns) {
    if (utils.isArrayNotEmpty(columns)) {
        return columns.filter(
            col =>
                col.field === "oid" ||
                col.field === "localizacao_identificacao" ||
                col.field === "responsavel_nome" ||
                col.field === "conspaga"
        ).map(col => {
                col.showFilterMenuOptions = false
                col.showClearButton = false
                col.showFilterMenu = false

                if (col.field === "oid") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-oid-body-width"
                            filterHeaderClassName="column-oid-filter-header-width"
                            headerClassName="column-oid-header-width"
                        />
                    );
                }
                if (col.field === "localizacao_identificacao") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-localizacao-body-width"
                            filterHeaderClassName="column-localizacao-filter-header-width"
                            headerClassName="column-localizacao-header-width"
                        />
                    );
                }
                if (col.field === "conspaga") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-conspaga-body-width"
                            filterHeaderClassName="column-conspaga-filter-header-width"
                            headerClassName="column-conspaga-header-width"
                        />
                    );
                }
                return <Column key={col.field} {...col} />;
            })
    }
    return []
}

export function getColumnsLookupPessoa(columns) {
    if (utils.isArrayNotEmpty(columns)) {
        return columns.filter(
            col => col.field === "numeroDocumento" || col.field === "nome"
        ).map(col => {
            col.showFilterMenuOptions = false
            col.showClearButton = false
            col.showFilterMenu = false

            if (col.field === "numeroDocumento") {
                return (
                    <Column
                        {...col}
                        key={col.field}
                        bodyClassName="column-nr-documento-body-width"
                        filterHeaderClassName="column-nr-documento-filter-header-width"
                        headerClassName="column-nr-documento-header-width"
                    />
                );
            }
            return <Column key={col.field} {...col} style={{ width: "20em"}} />;
        })
    }
    return []
}

export function getColumnsLookupTaxa(columns) {
    if (utils.isArrayNotEmpty(columns)) {
        return columns.filter(
            col =>
                col.field === "oid" ||
                col.field === "valor" ||
                col.field === "grupoFinanceiro_oid" ||
                col.field === "grupoFinanceiro_descricao"
        )
            .map(col => {
                col.showFilterMenuOptions = false
                col.showClearButton = false
                col.showFilterMenu = false

                if (col.field === "oid" || col.field === "grupoFinanceiro_oid") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-oid-body-width"
                            filterHeaderClassName="column-oid-filter-header-width"
                            headerClassName="column-oid-header-width"
                        />
                    );
                }
                if (col.field === "valor") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            body={rowData => utils.formatarDinheiro(rowData.valor)}
                            bodyClassName="column-valor-body-width"
                            filterHeaderClassName="column-valor-filter-header-width"
                            headerClassName="column-valor-header-width"
                        />
                    );
                }
                return <Column key={col.field} {...col} />;
            })
    }
    return []
}

export function getColumnsLookupLocalizacao(columns) {
    if (utils.isArrayNotEmpty(columns)) {
        return columns.filter(
            col =>
                col.field === "oid" ||
                col.field === "identificacao" ||
                col.field === "tipoSepultura_descricao"
        )
            .map(col => {
                col.showFilterMenuOptions = false
                col.showClearButton = false
                col.showFilterMenu = false

                if (col.field === "oid") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-oid-body-width"
                            filterHeaderClassName="column-oid-filter-header-width"
                            headerClassName="column-oid-header-width"
                        />
                    );
                }
                if (col.field === "identificacao") {
                    return (
                        <Column
                            {...col}
                            key={col.field}
                            bodyClassName="column-localizacao-body-width"
                            filterHeaderClassName="column-localizacao-filter-header-width"
                            headerClassName="column-localizacao-header-width"
                        />
                    );
                }
                return <Column key={col.field} {...col} />;
            })
    }
    return []
}


export function getColumnsLookupDocumento(columns) {
    if (utils.isArrayNotEmpty(columns)) {
        return columns.filter(
            col => col.field === "nome" || col.field === "statusDocumento"
        ).map(col => {
            col.showFilterMenuOptions = false
            col.showClearButton = false
            col.showFilterMenu = false

            if (col.field === "nome") {
                return (
                    <Column
                        {...col}
                        key={col.field}
                        style={{width: "20em"}}
                        // bodyClassName="column-nome-body-width"
                        // filterHeaderClassName="column-nome-filter-header-width"
                        // headerClassName="column-nome-header-width"
                    />
                )
            }
            if (col.field === "statusDocumento") {
                return (
                    <Column
                        {...col}
                        key={col.field}
                        style={{ width: "10em"}}
                        // bodyClassName="column-status-body-width"
                        // filterHeaderClassName="column-status-filter-header-width"
                        // headerClassName="column-status-header-width"
                    />
                )
            }

            return <Column key={col.field} {...col} style={{ width: "10em"}}/>;
        })
    }
    return []
}
