import api from './api.service'
import utils from '../utils'

const emptyValidator = (record) => {
  const result = {
    ok: true,
    errors: [],
    record,
  }
  return Promise.resolve(result)
}

class Crud {
  constructor(validate, basePath) {
    this.validate = validate
    this.basePath = basePath
  }

  insert(record) {
    return new Promise((resolve, reject) => {
      const path = this.basePath
      record = Object.assign({}, record)
      record.oid = null // se é novo garante que não terá um id
      this.validate = this.validate || emptyValidator()
      this.validate(record)
        .then(({ record }) => {
          api.post(path, record).then(resolve).catch(reject)
        })
        .catch(reject)
    })
  }

  update(record) {
    return new Promise((resolve, reject) => {
      if (!record.oid) {
        return reject({ message: 'O registro não possui identificador' })
      }
      this.validate = this.validate || emptyValidator()
      this.validate(record)
        .then(({ record }) => {
          api.put(`${this.basePath}/${record.oid}`, record).then(resolve).catch(reject)
        })
        .catch(reject)
    })
  }

  insertState(record, filters) {
    // adicionando propriedade 'ordem' de acordo com a visao
    if (utils.isArrayNotEmpty(record.columns)) {
      let ordem = 0
      record.columns.forEach((f) => {
        if (f.field === 'multiselect') {
          f.ordem = -1
        } else {
          ordem++
          f.ordem = ordem
        }
      })
    }
    return new Promise((resolve, reject) => {
      api.post('/lottustech/states', record, filters).then(resolve).catch(reject)
    })
  }

  removeState(oidCurrentMenu) {
    return new Promise((resolve, reject) => {
      api.remove(`/lottustech/states/deleteByMenu/${oidCurrentMenu}`).then(resolve).catch(reject)
    })
  }

  getByOid(oid) {
    return api.get(`${this.basePath}/${oid}`)
  }

  getAll(params) {
    let resource = 'all'
    if (params.resourceForGetAll && params.resourceForGetAll.length > 0) {
      resource = params.resourceForGetAll
      delete params.resourceForGetAll
    }
    return api.post(`${this.basePath}/${resource}`, params)
  }

  removeAll(idItens) {
    return api.post(`${this.basePath}/removeall`, idItens)
  }

  exportCSV(params) {
    return api.post(`${this.basePath}/exportcsv`, params)
  }

  findTags(codigo) {
    let result = api.get(`/lottustech/tags/${codigo}`)
    return result
  }

  findCausasMortis(descricao) {
    let result = api.get(`/lottustech/causasmortis/${descricao}`)
    return result
  }

  loadFavorito(caminhoMenu) {
    let result = api.post(`/lottustech/favoritos/verificaFavorito`, { caminhoMenu })
    return result
  }

  removeFavorito(oid) {
    return api.remove(`/lottustech/favoritos/${oid}`)
  }

  adicionarFavorito(menu) {
    return api.post(`/lottustech/favoritos/`, menu)
  }

  loadStateData(oidMenu) {
    return api.get(`/lottustech/states/loadByMenu/${oidMenu}`)
  }

  getBotoesDinamicos(idTela, tipoTela) {
    return api.get(`/lottustech/botoesdinamicos/getBotoesDinamicos/${idTela}/${tipoTela}`)
  }

  executarFuncaoDinamica(params) {
    return api.post(`/lottustech/botoesdinamicos/executarAcao/`, params)
  }

  getByCEP(cep) {
    let result = api.get(`/lottustech/ceps/${cep}`)
    return result
  }

  loadAllLocalidades(hash) {
    let result = api.get(`${this.basePath}/filters/localidades/all?hash=${hash}`)
    return result
  }

  loadCategoriasPai(oid, hash) {
    let result = api.get(`${this.basePath}/filters/categorias/${oid}?hash=${hash}`)
    return result
  }

  loadSubCategorias(oidLocalidade, oidCategoriaPai, hash) {
    let result = api.get(`${this.basePath}/filters/subcategorias/${oidLocalidade}/${oidCategoriaPai}?hash=${hash}`)
    return result
  }

  loadAllConvenios() {
    let result = api.get(`${this.basePath}/filters`)
    return result
  }
  remove(oid) {
    return api.remove(`${this.basePath}/${oid}`)
  }

  getConvenios(record, hash) {
    return new Promise((resolve, reject) => {
      record = {
        idLocalidade: record.idLocalidade,
        idCategoria: record.idCategoria,
        idSubCategoria: record.idSubCategoria,
        hash: hash,
      }
      this.validate = this.validate || emptyValidator()
      this.validate(record)
        .then(({ record }) => {
          api.post(this.basePath, record).then(resolve).catch(reject)
        })
        .catch(reject)
    })
  }
}
export function build(validator, basePath) {
  return new Crud(validator, basePath)
}
