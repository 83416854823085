import React from 'react';

import NotaEntradaFormPageContainer from './nota-entrada-form-page-container';

export class NotaEntradaFormPage extends React.Component{

    
    render(){
        return (
           <NotaEntradaFormPageContainer {...this.props} />
        )
    }
}
