import { types } from './unidade.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'unidade',
  booleanFilter: {
    ativo: '',
  },
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'ID',
      value: {
        field: 'oid',
        header: 'ID',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Pessoa',
      value: {
        field: 'pessoa.value',
        header: 'Pessoa',
        columnKey: 'pessoa',
        sortable: true,
        columnSortField: 'pessoa.nome',
        filter: true,
        relacionamento: ['pessoa'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Empresa',
      value: {
        field: 'empresa.value',
        header: 'Empresa',
        columnKey: 'empresa',
        sortable: true,
        columnSortField: 'empresa.nomeEmpresa',
        filter: true,
        relacionamento: ['empresa'],
        fieldJoin: 'nomeEmpresa',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'codigo',
        header: 'Código',
        columnKey: 'codigo',
        sortable: true,
        columnSortField: 'codigo',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Sigla',
      value: {
        field: 'sigla',
        header: 'Sigla',
        columnKey: 'sigla',
        sortable: true,
        columnSortField: 'sigla',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Ativo',
      value: {
        field: 'ativo',
        header: 'Ativo',
        columnKey: 'ativo',
        sortable: true,
        columnSortField: 'ativo',
        filter: true,
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    empresa: '',
    pessoa: '',
    codigo: '',
    sigla: '',
    ativo: true,
    matriz: false,
  },
  lookupPessoa: {
    visible: false,
    modal: true,
    header: 'Pessoas',
  },
  lookupEmpresa: {
    visible: false,
    modal: true,
    header: 'Empresas',
  },
  messages: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDUnidadeReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('unidade')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLoockupPessoaVisible(state, action)
    case types.SET_LOOKUP_EMPRESA_VISIBLE:
      return setLoockupEmpresaVisible(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function setLoockupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible })
  return Object.assign({}, state, { lookupPessoa })
}
function setLoockupEmpresaVisible(state, action) {
  let lookupEmpresa = state.lookupEmpresa
  lookupEmpresa = Object.assign({}, lookupEmpresa, { visible: action.visible })
  return Object.assign({}, state, { lookupEmpresa })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
