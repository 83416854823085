import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes, icons } from '../../../constants'
import { actions } from '../emprestimo.actions'
import { EmprestimoForm } from './EmprestimoForm'
import _, { set } from 'lodash'
import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  const record = state.crudEmprestimoState.currentRecord

  return {
    messages: state.crudEmprestimoState.messages,
    record,
    colunasProduto: state.crudEmprestimoState.colunasProduto,
    stateContrato: state.crudContratoState,
    stateProduto: state.crudProdutotate,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class EmprestimoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }
    this.history = props.history

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_EMPRESTIMO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_EMPRESTIMO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_EMPRESTIMO}`)
    }

    this.setField = props.setField
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_EMPRESTIMO}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  setContrato = (data) => {
    var oid = _.get(data, 'oid', '')
    var numero = _.get(data, 'numero', '')
    var valor = _.get(data, 'localizacao_identificacao', '')
    if (!valor) {
      valor = '#' + (numero || oid) + ' - ' + _.get(data, 'tipoSepultura_descricao', '')
    }

    const contrato = {
      id: oid,
      value: valor,
    }
    this.props.setField('contrato', contrato)
    this.props.setField('responsavel', { id: '', value: '' })

    const idUnidade = _.get(data, 'unidade_oid', '')
    const siglaUnidade = _.get(data, 'unidade_sigla', '')
    this.props.setField('unidade', { id: idUnidade, value: siglaUnidade })
  }

  setResponsavel = (data) => {
    const value = {
      id: _.get(data, 'oid', ''),
      value: _.get(data, 'nome', ''),
    }
    this.setField('responsavel', value)
  }

  setProduto = (data) => {
    const produto = {
      id: _.get(data, 'oid', ''),
      value: _.get(data, 'descricao', ''),
    }
    this.props.setField('produto', produto)
  }

  onChangeProdutos = (data) => {
    this.setField('produtos', _.get(data, 'values', []))
  }

  render() {
    const content = (
      <EmprestimoForm
        record={this.props.record}
        history={this.props.history}
        changeHandler={this.props.changeHandler}
        messages={this.props.messages}
        unidades={this.props.unidades}
        lookupContratoOnFilter={this.lookupContratoOnFilter}
        setContrato={this.setContrato}
        stateContrato={this.props.stateContrato}
        setProduto={this.setProduto}
        setResponsavel={this.setResponsavel}
        stateProduto={this.props.stateProduto}
        onChangeProdutos={this.onChangeProdutos}
        setField={this.props.setField}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmprestimoFormPageContainer)
