import { types } from "./contato.actions";
import {Reducer} from '../../services/reducer.factory';

const initialState = {
  type: null,
  types: [
      {label:'FAMILIA', value: 'FAMILIA'},
      {label:'AMIGO', value: 'AMIGO'},
      {label:'TRABALHO', value: 'TRABALHO'},
      {label:'OUTRO', value: 'OUTRO'}
  ],
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "email",
      header: "Email",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "telefone",
      header: "Telefone",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "tipo",
      header: "Tipo",
      columnKey: "oid",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    nome: "",
    email: "",
    telefone: "",
    tipo: ""
  },
};

const reducer = new Reducer(initialState,types);

export function CRUDContatoReducer(state, action) {
  return reducer.execute(state,action);
}

