import { isNull } from 'lodash'
import storage from '../services/stoage.service'
import { acoes } from '../constants'

const _ = require('lodash')

export function blankToNull(object) {
  if (!object) {
    return
  }

  Object.keys(object).forEach(function (prop) {
    if (typeof object[prop] === 'string' && object[prop].trim().length === 0) {
      object[prop] = null
    }
  })
}

export function formatarDataHoraFromString(data) {
  if (typeof data === 'string' && window.moment(data, window.moment().defaultFormat, true).isValid()) {
    return window.moment(data, window.moment().defaultFormat).format('DD/MM/YYYY   HH:mm')
  }
}

export function formatarData(data) {
  if (!data) {
    return null
  }
  let dataFormatada = data
  if (typeof data === 'string' && window.moment(data, 'YYYY-MM-DD', true).isValid()) {
    dataFormatada = window.moment(data, 'YYYY-MM-DD').utc().format('DD/MM/YYYY')
  } else if (typeof data === 'number' || typeof data === 'object') {
    dataFormatada = window.moment(data).utc().format('DD/MM/YYYY')
  } else if (typeof data === 'string' && data !== '') {
    dataFormatada = window.moment(Number(data)).utc().format('DD/MM/YYYY')
  }
  if (dataFormatada && dataFormatada.trim().length === 0) {
    dataFormatada = null
  }
  return dataFormatada
}

export function formatarDataCompetencia(data) {
  if (!data) {
    return null
  }
  let dataFormatada = data
  if (typeof data === 'string' && window.moment(data, 'YYYY-MM-DD', true).isValid()) {
    dataFormatada = window.moment(data, 'YYYY-MM-DD').utc().format('MM/YYYY')
  } else if (typeof data === 'number' || typeof data === 'object') {
    dataFormatada = window.moment(data).utc().format('MM/YYYY')
  } else if (typeof data === 'string' && data !== '') {
    dataFormatada = window.moment(Number(data)).utc().format('MM/YYYY')
  }
  if (dataFormatada && dataFormatada.trim().length === 0) {
    dataFormatada = null
  }
  return dataFormatada
}

export function formatarDataHora(data) {
  if (!data) {
    return null
  }
  let dataFormatada = data
  if (typeof data === 'number' || typeof data === 'object') {
    dataFormatada = window.moment(data).format('DD/MM/YYYY HH:mm')
  }
  if (dataFormatada && dataFormatada.trim().length === 0) {
    dataFormatada = null
  }
  return dataFormatada
}

export function formatarHora(data) {
  if (!data) {
    return null
  }
  let dataFormatada = data
  if (typeof data === 'number' || typeof data === 'object') {
    dataFormatada = window.moment(data).format('HH:mm')
  }
  if (dataFormatada && dataFormatada.trim().length === 0) {
    dataFormatada = null
  }
  return dataFormatada
}

export function getDataAtual() {
  return setDateInitOfDay(new Date())
}

export function getDateFromNumber(value) {
  if (isDate(value)) {
    return setDateInitOfDay(value)
  }

  if (typeof value === 'number') {
    const newValue = new Date(value)
    return setDateInitOfDay(newValue)
  }

  if (isNumber(value)) {
    const newValue = new Date(parseInt(value))
    return setDateInitOfDay(newValue)
  }

  return null
}

export function setDateInitOfDay(value) {
  if (value && isDate(value)) {
    const newValue = formatarData(value)
    if (newValue && typeof newValue === 'string') {
      const array = newValue.split('/')
      if (isArrayNotEmpty(array)) {
        const year = parseInt(array[2])
        const month = parseInt(array[1])
        const day = parseInt(array[0])
        const result = new Date(year, month - 1, day, 0, 0, 0, 0)
        return result
      }
    }
  }
  return value
}

export function getDateTimeFromNumber(value) {
  if (isDate(value)) {
    return value
  }

  if (typeof value === 'number') {
    return new Date(value)
    // return formatDateTime(newValue);
  }

  if (isNumber(value)) {
    return new Date(parseInt(value))
    // return formatDateTime(newValue);
  }

  return null
}

// function formatDateTime(value) {
//     if (value && isDate(value)) {
//         const newValue = formatarDataHora(value);
//         if (newValue && typeof newValue === "string") {
//             const array = newValue.split("/");
//             if (isArrayNotEmpty(array)) {
//                 const year = parseInt(array[2]);
//                 const month = parseInt(array[1]);
//                 const day = parseInt(array[0]);
//                 const result = new Date(year, month - 1, day, 0, 0, 0, 0);
//                 return result;
//             }
//         }
//     }
//     return value;
// }

export function getUTCDate(longDate) {
  let data = new Date(longDate)
  data = new Date(data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate())
  return data
}

export function isDate(value) {
  return Object.prototype.toString.call(value) === '[object Date]'
}

export function isNumber(value) {
  if (value === 0) {
    return true
  }
  if (!value || (typeof value === 'string' && value.trim().length === 0)) {
    return false
  }

  return !isNaN(value)
}

export function formatarDinheiro(n) {
  if (!isNaN(Number(n))) {
    n = Number(n)
    return (
      'R$ ' +
      n
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+,)/g, '$1.')
    )
  }
  return n
}

export function formatCurrency(value) {
  if (value === 0) {
    return '0,00'
  } else if (isNumber(value)) {
    const result = Number(value)
    return result
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
  }
  if (value && typeof value === 'string' && value.trim().length > 0) {
    const temp = value.replace(/\D/g, '')
    if (isNumber(temp)) {
      const split = value.split('.')
      if (isArrayNotEmpty(split) && split.length > 2) {
        const last = split[split.length - 1] // guarda o ultimo elemento
        split.splice(split.length - 1, 1) //remove o ultimo elemento
        let result = ''
        split.forEach((f) => {
          result += f
        })
        const newValue = (result += '.' + last)
        return formatCurrency(newValue)
      }
    }
  }
  return ''
}

export function getMessage(object, key) {
  return getValue(object, key)
}

export function getValue(object, key) {
  if (!object || !key) {
    return ''
  }

  const result = object[key]
  if (!result) {
    return ''
  }

  return result
}

export function getValueFromIdValue(object) {
  if (isIdValueNullOrEmpty(object)) {
    return ''
  }
  return object.value
}

export function getValueFrom(object) {
  if (!object.hasOwnProperty('value') || !object.value) {
    return ''
  }
  return object.value
}

export function getFunction(functionName) {
  if (functionName === 'formatarData') {
    return formatarData
  } else if (functionName === 'formatarDataHora') {
    return formatarDataHora
  } else if (functionName === 'formatarDataHoraFromString') {
    return formatarDataHoraFromString
  } else if (functionName === 'formatarDinheiro') {
    return formatarDinheiro
  } else if (functionName === 'formatCurrency') {
    return formatCurrency
  } else if (functionName === 'getValue') {
    return getValue
  } else if (functionName === 'formatarDataCompetencia') {
    return formatarDataCompetencia
  }
  return null
}

export function getParameterValueFromUnitOid(key, oidUnidade, unidades, empresa = null) {
  let result
  let currentUnit = findObjectInArray(unidades, 'oid', oidUnidade)
  if (currentUnit) {
    if (currentUnit.parametros.length > 0) {
      result = findObjectInArray(currentUnit.parametros, 'id', key)
    }
  }

  if (!result) {
    if (!empresa) {
      const internalStorage = window.localStorage
      const company = internalStorage.getItem('COMPANY')
      if (company) {
        empresa = JSON.parse(company)
      }
    }
    result = getParametroEmpresaCorrente(key, empresa)
  }
  return result
}

export function getParametroEmpresaCorrente(key, empresa) {
  if (!empresa) {
    return ''
  }
  const parametros = empresa.parametros
  if (isArrayNullOrEmpty(parametros)) {
    return ''
  }

  let parameter = findObjectInArray(parametros, 'id', key)
  if (parameter) {
    return parameter
  }

  return ''
}

export function resolveFieldFormatter(column) {
  if (column.column && column.column.props && column.column.props.fieldFormatter) {
    return column.column.props.fieldFormatter
  }
  return null
}

export function format(rowData, column, enums) {
  const value = rowData[column.field]
  const fieldFormatter = resolveFieldFormatter(column)
  if (value && fieldFormatter) {
    if (fieldFormatter && typeof fieldFormatter === 'string') {
      const func = getFunction(fieldFormatter)
      if (func && enums) {
        return getValue(enums, rowData[column.columnKey])
      }
      if (func) {
        return func(value)
      }
      return null
    }
    return fieldFormatter(value)
  } else if (value === 0 && fieldFormatter && fieldFormatter === 'formatarDinheiro') {
    return 'R$ 0,00'
  }
  return null
}

export function isEmptyObject(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export function formatCPF(value) {
  if (value) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  return ''
}

export function validateEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export function validatePhone(phone) {
  return phone && (phone.length === 10 || phone.length === 11)
}

export function onlyNumbers(numberStr) {
  const result = numberStr.replace(/\D/g, '')
  if (nonNull(result)) {
    return result
  }
  return ''
}

export function formatarTelefone(telefone) {
  telefone = telefone.replace(/\D/g, '') //Remove tudo o que não é dígito
  if (telefone.indexOf('0800') === -1) {
    telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2') //Coloca parênteses em volta dos dois primeiros dígitos
    telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2') //Coloca hífen entre o quarto e o quinto dígitos
  } else {
    telefone = telefone.replace(/(\d)(\d{3})(\d{4})$/, '$1 $2 $3') //Coloca hífen entre o quarto e o quinto dígitos
  }
  return telefone
}

export function validateState(state) {
  const errors = []
  if (state.menu) {
    if (!state.nome || state.nome.trim().length === 0) {
      errors.push({
        field: 'nome',
        message: 'É necessário ter informado um nome',
      })
    }
    if (!state.columns) {
      errors.push({
        field: 'colunas',
        message: 'É necessário ter colunas selecionadas',
      })
    }
    if (!state.perfis || state.perfis.length === 0) {
      errors.push({
        field: 'perfis',
        message: 'É necessário ter selecionado um perfil',
      })
    }
  }

  const result = {
    ok: errors.length === 0,
    errors,
    state,
  }

  return result
}

// DEPRECATE
// function getCalendar() {
//     return {
//         firstDayOfWeek: 0,
//         dayNames: ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"],
//         dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
//         dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
//         monthNames: [
//             "Janeiro",
//             "Fevereiro",
//             "Março",
//             "Abril",
//             "Maio",
//             "Junho",
//             "Julho",
//             "Agosto",
//             "Setembro",
//             "Outubro",
//             "Novembro",
//             "Dezembro"
//         ],
//         monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
//         today: "Hoje",
//         clear: "Limpar",
//         dateFormat: "dd/mm/yy",
//         weekHeader: "Sm"
//     };
// }

export function getFilters(labels) {
  return [
    {
      label: getMessage(labels, 'filtro.label.selecione'),
      value: null,
      dataInicial: false,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.igual'),
      value: 0,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.diferente'),
      value: 1,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.entre'),
      value: 6,
      dataInicial: true,
      dataFinal: true,
    },
    {
      label: getMessage(labels, 'filtro.label.maiorigual'),
      value: 8,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.maior'),
      value: 7,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.menorigual'),
      value: 10,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.menor'),
      value: 9,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.nulo'),
      value: 12,
      dataInicial: false,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.naonulo'),
      value: 13,
      dataInicial: false,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.naocontido'),
      value: 15,
      dataInicial: false,
      dataFinal: false,
    },
  ]
}

export function getFiltersIgualADiferenteDe(labels) {
  return [
    {
      label: getMessage(labels, 'filtro.label.selecione'),
      value: null,
      dataInicial: false,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.igual'),
      value: 0,
      dataInicial: true,
      dataFinal: false,
    },
    {
      label: getMessage(labels, 'filtro.label.diferente'),
      value: 1,
      dataInicial: true,
      dataFinal: false,
    },
  ]
}

export function getFiltersForEntity(labels) {
  return [
    { label: getMessage(labels, 'filtro.label.selecione'), value: null },
    { label: getMessage(labels, 'filtro.label.igual'), value: 0 },
    { label: getMessage(labels, 'filtro.label.diferente'), value: 1 },
    { label: getMessage(labels, 'filtro.label.nulo'), value: 12 },
    { label: getMessage(labels, 'filtro.label.naonulo'), value: 13 },
  ]
}

export function getFiltersOptionsIdValue(labels) {
  return [
    {
      id: 0,
      value: getMessage(labels, 'filtro.label.igual'),
      constant: 'IGUAL_A',
    },
    {
      id: 1,
      value: getMessage(labels, 'filtro.label.diferente'),
      constant: 'DIFERENTE_DE',
    },
    {
      id: 2,
      value: getMessage(labels, 'filtro.label.comecacom'),
      constant: 'COMECA_COM',
    },
    {
      id: 3,
      value: getMessage(labels, 'filtro.label.terminacom'),
      constant: 'TERMINA_COM',
    },
    {
      id: 4,
      value: getMessage(labels, 'filtro.label.contem'),
      constant: 'CONTEM',
    },
    {
      id: 5,
      value: getMessage(labels, 'filtro.label.naocontem'),
      constant: 'NAO_CONTEM',
    },
    {
      id: 6,
      value: getMessage(labels, 'filtro.label.entre'),
      constant: 'ENTRE',
    },
    {
      id: 8,
      value: getMessage(labels, 'filtro.label.maiorigual'),
      constant: 'MAIOR_IGUAL',
    },
    {
      id: 7,
      value: getMessage(labels, 'filtro.label.maior'),
      constant: 'MAIOR_QUE',
    },
    {
      id: 9,
      value: getMessage(labels, 'filtro.label.menor'),
      constant: 'MENOR_QUE',
    },
    {
      id: 10,
      value: getMessage(labels, 'filtro.label.menorigual'),
      constant: 'MENOR_IGUAL',
    },
    {
      id: 11,
      value: getMessage(labels, 'filtro.label.contidoem'),
      constant: 'ESTA_CONTIDO_EM',
    },
    {
      id: 12,
      value: getMessage(labels, 'filtro.label.nulo'),
      constant: 'NULO',
    },
    {
      id: 13,
      value: getMessage(labels, 'filtro.label.naonulo'),
      constant: 'NAO_NULO',
    },
    {
      id: 15,
      value: getMessage(labels, 'filtro.label.naocontido'),
      constant: 'NAO_ESTA_CONTIDO_EM',
    },
  ]
}

export function getBooleanOptionsFormaDePagamento() {
  return [
    { label: 'Substituído', value: 2 },
    { label: 'Cartão de Crédito', value: 7 },
    { label: 'Boleto', value: 5 },
    { label: 'Dinheiro', value: 6 },
    { label: 'Cartão de Débito', value: 8 },
    { label: 'Cheque', value: 9 },
    { label: 'Transferência', value: 10 },
    { label: 'Seguradora', value: 11 },
    { label: 'Cartão de Crédito - Vindi', value: 13 },
    { label: 'Débito automático', value: 14 },
    { label: 'Link de pagamento', value: 16 },
    { label: 'Carnê', value: 17 },
    { label: 'A Definir', value: 99 },
  ]
}

export function getFiltersIdValue(labels) {
  return [
    {
      id: 0,
      value: getMessage(labels, 'filtro.label.igual'),
      constant: 'IGUAL_A',
    },
    {
      id: 1,
      value: getMessage(labels, 'filtro.label.diferente'),
      constant: 'DIFERENTE_DE',
    },
    {
      id: 6,
      value: getMessage(labels, 'filtro.label.entre'),
      constant: 'ENTRE',
    },
    {
      id: 7,
      value: getMessage(labels, 'filtro.label.maior'),
      constant: 'MAIOR_QUE',
    },
    {
      id: 8,
      value: getMessage(labels, 'filtro.label.maiorigual'),
      constant: 'MAIOR_IGUAL',
    },
    {
      id: 9,
      value: getMessage(labels, 'filtro.label.menor'),
      constant: 'MENOR_QUE',
    },
    {
      id: 10,
      value: getMessage(labels, 'filtro.label.menorigual'),
      constant: 'MENOR_IGUAL',
    },
    {
      id: 11,
      value: getMessage(labels, 'filtro.label.contidoem'),
      constant: 'ESTA_CONTIDO_EM',
    },
    {
      id: 12,
      value: getMessage(labels, 'filtro.label.nulo'),
      constant: 'NULO',
    },
    {
      id: 13,
      value: getMessage(labels, 'filtro.label.naonulo'),
      constant: 'NAO_NULO',
    },
  ]
}

export function getFiltersStringIdValue(labels) {
  return [
    {
      id: 0,
      value: getMessage(labels, 'filtro.label.igual'),
      constant: 'IGUAL_A',
    },
    {
      id: 1,
      value: getMessage(labels, 'filtro.label.diferente'),
      constant: 'DIFERENTE_DE',
    },
    {
      id: 2,
      value: getMessage(labels, 'filtro.label.comecacom'),
      constant: 'COMECA_COM',
    },
    {
      id: 3,
      value: getMessage(labels, 'filtro.label.terminacom'),
      constant: 'TERMINA_COM',
    },
    {
      id: 4,
      value: getMessage(labels, 'filtro.label.contem'),
      constant: 'CONTEM',
    },
    {
      id: 5,
      value: getMessage(labels, 'filtro.label.naocontem'),
      constant: 'NAO_CONTEM',
    },
    {
      id: 11,
      value: getMessage(labels, 'filtro.label.contidoem'),
      constant: 'ESTA_CONTIDO_EM',
    },
    {
      id: 12,
      value: getMessage(labels, 'filtro.label.nulo'),
      constant: 'NULO',
    },
    {
      id: 13,
      value: getMessage(labels, 'filtro.label.naonulo'),
      constant: 'NAO_NULO',
    },
  ]
}

export function getOpcoesTipoTelas() {
  return [
    { value: 1, label: 'Formulário' },
    { value: 2, label: 'Listagem' },
  ]
}

export function getFiltersBooleanIdValue(labels) {
  return [
    {
      id: 0,
      value: getMessage(labels, 'filtro.label.igual'),
      constant: 'IGUAL_A',
    },
  ]
}

export function isFilterValorComplementar(filters, filter) {
  if (isIdValue(filter)) {
    return filter && filter.id === 6 //ENTRE
  }

  let value = filter
  if (filter.hasOwnProperty('tipoFiltro')) {
    value = filter.tipoFiltro
  }
  const result = parseFilter(filters, value)
  return result && result.id === 6 //ENTRE
}

export function getFilterValueById(filters, id) {
  if (((id && parseInt(id)) || id === 0) && filters && filters.length > 0) {
    const result = filters.filter((f) => f.id === id)
    if (result && result.length > 0) {
      return result[0].value
    }
  }
  return ''
}

export function getFilterValueByConstant(filters, constant) {
  if (typeof constant === 'string' && constant.trim().length > 0 && isArrayNotEmpty(filters)) {
    const result = filters.filter((f) => f.constant === constant)
    if (result && result.length > 0) {
      return result[0].value
    }
  }
  return ''
}

export function parseFilter(filters, value) {
  if (isArrayNotEmpty(filters) && value) {
    if (typeof value === 'string' && value.trim().length > 0) {
      const result = filters.filter((f) => f.constant === value)
      if (result && result.length > 0) {
        return result[0]
      }
    }
    if (isIdValue(value)) {
      const result = filters.filter((f) => f.id === value.id)
      if (result && result.length > 0) {
        return result[0]
      }
    }
    return null
  }
  return null
}

export function parseTipoFiltro(filters) {
  if (isArrayNotEmpty(filters)) {
    filters.forEach((f) => {
      if (isIdValueWithConstant(f.tipoFiltro)) {
        f.tipoFiltro = f.tipoFiltro.constant
      }
      if (f.hasOwnProperty('hash')) {
        delete f.hash
      }
      if (f.tipoDado === 'NUMERICO') {
        f.valor = Number(f.valor)
        if (nonNull(f.valorComplementar)) {
          f.valorComplementar = Number(f.valorComplementar)
        }
      }
    })
  }
  return filters
}

//TEM LOCAIS QUE ENVIAM '3', AJUSTAR PARA MANDAR 'filtro' conforme os outros
export function isTipoDadoData(value) {
  if (!value) {
    return false
  }
  if (!value.tipoDado) {
    if (parseInt(value) && value > 0) {
      return value === 3
    }
  }
  const tipoDado = parseTipoDado(value.tipoDado)
  return tipoDado && tipoDado.id === 3
}

export function isTipoDadoString(filter) {
  return validaTipoDado(filter, 2)
}

export function isTipoDadoBoolean(filter) {
  return validaTipoDado(filter, 6)
}

export function validaTipoDado(filter, value) {
  if (!filter || !filter.tipoDado) {
    return false
  }
  const tipoDado = parseTipoDado(filter.tipoDado)
  return tipoDado && tipoDado.id === value
}

export function isIdValue(value) {
  return value && value.hasOwnProperty('id') && value.hasOwnProperty('value')
}

export function isIdValueWithConstant(value) {
  return isIdValue(value) && value.hasOwnProperty('constant')
}

export function parseTipoDado(value) {
  const tiposDado = getTiposDado()
  if (typeof value === 'string' && value.trim().length > 0) {
    const result = tiposDado.filter((f) => f.constant === value)
    if (isArrayNotEmpty(result)) {
      return result[0]
    }
  }
  if (isIdValue(value)) {
    const result = tiposDado.filter((f) => f.id === value.id)
    if (isArrayNotEmpty(result)) {
      return result[0]
    }
  }
  return null
}

export function getTipoDadoValueById(id) {
  if (id && parseInt(id)) {
    const result = getTiposDado().filter((f) => f.id === id)
    if (isArrayNotEmpty(result)) {
      return result[0].value
    }
  }
  return ''
}

export function getJoinTypes() {
  return [
    { id: 0, value: 'INNER' },
    { id: 1, value: 'LEFT' },
    { id: 2, value: 'RIGHT' },
  ]
}

export function getTiposDado() {
  return [
    { id: 1, value: 'Numérico', constant: 'NUMERICO' },
    { id: 2, value: 'Alfanumérico', constant: 'ALFANUMERICO' },
    { id: 3, value: 'Data', constant: 'DATA' },
    { id: 6, value: 'Sim/Não', constant: 'BOOLEAN' },
  ]
}

export function getFormasPagamento(source, ids) {
  const result = []
  for (const id of ids) {
    const fp = source.find((f) => f.id === parseInt(id))
    if (fp) {
      result.push(fp)
    }
  }
  return result
}

export function getOptionsSimNao() {
  return [
    { id: 1, value: 'Sim' },
    { id: 0, value: 'Não' },
  ]
}

export function getTiposOrdenacoes() {
  return [
    { id: 0, value: 'ASC' },
    { id: 1, value: 'DESC' },
  ]
}

export function getTipoConservacaoFromInt(opcao) {
  switch (opcao) {
    case 1:
      return 'Ótimo'
    case 2:
      return 'Bom'
    case 3:
      return 'Regular'
    case 4:
      return 'Precário'
    default:
      return ''
  }
}

export function getRegexLettersBlankSpace() {
  return /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/
}

export function isArrayNullOrEmpty(array) {
  return !array || !Array.isArray(array) || array.length === 0
}

export function isArrayNotEmpty(array) {
  return Array.isArray(array) && array && array.length > 0
}

export function removeObjectFromArray(array, field, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][field] === value) {
      array.splice(i, 1)
      --i
    }
  }
}

export function orderArrayByProperty(array, property) {
  if (isArrayNotEmpty(array) && property && property.trim().length > 0) {
    array.sort((a, b) => {
      if (a[property] > b[property]) {
        return 1
      }
      if (a[property] < b[property]) {
        return -1
      }
      return 0
    })
  }
  return array
}

export function removeDuplicatesFromArrayByProperty(array, property) {
  if (isArrayNotEmpty(array) && property && property.trim().length > 0) {
    const result = []
    array.forEach((element) => {
      const find = result.find((e) => e[property] === element[property])
      if (!find) {
        result.push(element)
      }
    })
    return result
  }
  return array
}

export function formatTaxaValueForLookup(taxa) {
  if (!taxa) {
    return ''
  }
  let valor = ''
  if (nonNull(taxa.valor)) {
    valor = formatCurrency(taxa.valor)
  }
  let grupoFinanceiro = ''
  if (taxa.grupoFinanceiro_descricao) {
    grupoFinanceiro = taxa.grupoFinanceiro_descricao
  }

  let result = ''
  if (isStringNotBlank(valor)) {
    result += valor
  }

  if (isStringNotBlank(grupoFinanceiro)) {
    if (isStringNotBlank(result)) {
      result += ' - ' + grupoFinanceiro
    } else {
      result += grupoFinanceiro
    }
  }

  return result
}

export function formatContratoValueForLookup(contrato) {
  if (!contrato && !contrato.hasOwnProperty('localizacao')) {
    return ''
  }
  const localizacao = contrato.localizacao
  if (isIdValue(localizacao)) {
    return localizacao.value
  }

  if (localizacao) {
    return localizacao.identificacao
  }

  return ''
}

export function isIdValueNullOrEmpty(idValue) {
  if (!idValue) {
    return true
  }

  return (
    !idValue.id ||
    !isNumber(idValue.id) ||
    !idValue.value ||
    (typeof idValue.value === 'string' && idValue.value.trim().length === 0)
  )
}

export function isIdValueOnlyStringValue(idValue) {
  if (!idValue) {
    return false
  }

  return !idValue.id && idValue.value && typeof idValue.value === 'string' && idValue.value.trim().length > 0
}

export function getListIdValuesUnidade(unidades) {
  if (isArrayNullOrEmpty(unidades)) {
    return []
  }
  let result = []
  unidades.forEach((f) => result.push(getIdValueUnidade(f)))
  return result
}

export function getIdValueUnidade(unidade) {
  if (unidade) {
    return { id: unidade.oid, value: unidade.sigla }
  }
  return null
}

export function getListIdValuesUnidadeFromEmpresa(empresa) {
  if (!empresa) {
    return []
  }

  let unidades = empresa.unidades || []
  if (isArrayNullOrEmpty(unidades)) {
    return []
  }

  let result = []
  unidades.forEach((f) => result.push(getIdValueUnidade(f)))
  return result
}

export function getListIdValuesUnidadeDisponiveisUsuario() {
  let unidades = []
  const unidadesUsuario = storage.getUnidadesUsuario()
  const empresaCorrente = storage.getCompany()
  const unidadesEmpresa = _.get(empresaCorrente, 'unidades', [])
  if (unidadesUsuario.length === 0) {
    unidades = unidadesEmpresa
  } else {
    unidadesEmpresa.forEach((ue) => {
      if (unidadesUsuario.find((uu) => uu.oid === ue.oid)) {
        unidades.push(ue)
      }
    })
  }
  let result = []
  unidades.forEach((f) => result.push(getIdValueUnidade(f)))
  return result
}

export function getIdValueUnidadeMatriz(empresa) {
  let result = null
  if (!empresa) {
    return result
  }

  let unidades = empresa.unidades || []
  if (isArrayNullOrEmpty(unidades)) {
    return result
  }

  unidades.forEach((f) => {
    if (f.matriz) {
      result = getIdValueUnidade(f)
    }
  })
  return result
}

export function isValorValidoMaiorQueZero(value) {
  if (!value || value <= 0) {
    return false
  }
  if (typeof value === 'string') {
    if (value.trim().length < 0) {
      return false
    }
    const parsed = value.replace('R$', '').replace(/\./g, '').replace(',', '.')
    if (isNumber(parsed) && Number(parsed) <= 0) {
      return false
    }
  }
  return true
}

export function isIdValueIdValido(idValue) {
  return isIdValue(idValue) && idValue.id
}

export function parseCurrency(value) {
  if (isNumber(value) && Number(value)) {
    return Number(value)
  }
  if (typeof value === 'string') {
    if (value === '') {
      return '0'
    } else {
      return parseCurrency(value.replace('R$', '').replace(/\./g, '').replace(',', '.'))
    }
  }
  return value
}

export function parseStringToNumber(numberStr) {
  if (typeof numberStr === 'string') {
    if (isStringNotBlank(numberStr)) {
      let formatString = numberStr.replace('.', '').replace(',', '.')
      return Number(formatString)
    }
    return 0
  }
  return numberStr
}

export function getShowHideColumnsForLocalizacao() {
  return [
    {
      label: ' ',
      value: {
        field: 'multiselect',
        header: '',
        columnKey: 'multiselect',
        visible: storage.getAcao(acoes.EXCLUIR_LOCALIZACAO),
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Identificação',
      value: {
        field: 'identificacao',
        header: 'Identificação',
        columnKey: 'identificacao',
        sortable: true,
        filter: true,
        columnSortField: 'identificacao',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
    {
      label: 'Tipo de Sepultura',
      value: {
        field: 'tipoSepultura_descricao',
        header: 'Tipo de Sepultura',
        columnKey: 'tipoSepultura',
        sortable: true,
        filter: true,
        columnSortField: 'tipoSepultura.descricao',
        relacionamento: ['tipoSepultura'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Temporária',
      value: {
        field: 'temporaria',
        header: 'Temporária',
        columnKey: 'temporaria',
        sortable: false,
        columnSortField: 'temporaria',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Disponível',
      value: {
        field: 'disponivel',
        header: 'Disponível',
        columnKey: 'disponivel',
        sortable: false,
        columnSortField: 'disponivel',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Observação',
      value: {
        field: 'observacao',
        header: 'Observação',
        columnKey: 'observacao',
        sortable: true,
        filter: true,
        columnSortField: 'observacao',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoA',
        columnKey: 'localizacaoA',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoA',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoB',
        columnKey: 'localizacaoB',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoB',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoC',
        columnKey: 'localizacaoC',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoC',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoD',
        columnKey: 'localizacaoD',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoD',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoE',
        columnKey: 'localizacaoE',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoE',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoF',
        columnKey: 'localizacaoF',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoF',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoG',
        columnKey: 'localizacaoG',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoG',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoH',
        columnKey: 'localizacaoH',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoH',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoI',
        columnKey: 'localizacaoI',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoI',
        visible: false,
      },
    },
    {
      label: '',
      value: {
        field: 'localizacaoJ',
        columnKey: 'localizacaoJ',
        sortable: true,
        header: '',
        filter: true,
        columnSortField: 'localizacaoJ',
        visible: false,
      },
    },
  ]
}

export function resolveImage(anexo) {
  if (!anexo) {
    return []
  }

  let image = new Image(30, 30)
  image.src = 'data:image/png;base64,' + anexo.value
  return image
}

export function calculatePreview(tipo, periodo, modificador) {
  let result = null
  if (tipo && tipo > 0) {
    if (tipo === 1) {
      result = window.moment()
    } else if (tipo === 2) {
      result = window.moment().startOf('month')
    } else if (tipo === 3) {
      result = window.moment().endOf('month')
    }
  }

  if (periodo && periodo > 0 && modificador && isNumber(modificador)) {
    if (modificador && isNumber(modificador)) {
      if (periodo === 1) {
        result = result.add(modificador, 'd')
      }
      if (periodo === 2) {
        result = result.add(modificador, 'w')
      }
      if (periodo === 3) {
        result = result.add(modificador, 'M')
      }
    }
  }
  return result
}

export function getValorParcelas(valorTotal, numeroDeParcelas) {
  var restoDivisao = ((valorTotal * 100) % numeroDeParcelas) / 100
  var cadaParcela = Math.floor((valorTotal * 100) / numeroDeParcelas) / 100
  var ultimaParcela = parseFloat((cadaParcela + restoDivisao).toFixed(2))
  return { parcelas: cadaParcela, ultimaParcela: ultimaParcela }
}

export function isStringBlank(value) {
  return typeof value !== 'string' || value.trim().length === 0
}

export function isStringNotBlank(value) {
  return typeof value === 'string' && value.trim().length > 0
}

export function nonNull(value) {
  return value !== null && value !== undefined
}

export function findObjectInArray(array, field, value) {
  if (isArrayNullOrEmpty(array)) {
    return null
  }
  let obj = array.find((obj) => obj && obj[field] === value)
  if (obj) {
    return obj
  }
  return null
}

export function matchPath(source, target) {
  const lastIndex = target.lastIndexOf('/')
  if (target.length - 1 === lastIndex) {
    // -> /geral/cadastros/pessoasFisicas/
    return source === target.substring(target.length - 1)
  }
  const valueAfterLastIndex = target.substring(lastIndex + 1)
  const isOidAfterLastIndex = parseInt(valueAfterLastIndex)
  if (isOidAfterLastIndex || valueAfterLastIndex === 'novo') {
    // indo para o FORM ou Novo registro
    const normalizedTarget = target.substring(0, lastIndex)
    return source === normalizedTarget
  }
  return source === target
}

export function checkMenuPath(menus, url) {
  if (isArrayNullOrEmpty(menus)) {
    return false
  }

  if (url === '/') {
    return true
  }

  for (const menu of menus) {
    const caminho = _.get(menu, 'caminho', null)
    if (!caminho) {
      const items = _.get(menu, 'items', [])
      if (isArrayNotEmpty(items)) {
        const found = items.find((f) => matchPath(_.defaultTo(f.caminho, ''), url))
        if (found) {
          return true
        }
        const check = checkMenuPath(items, url)
        if (check) {
          return true
        }
      }
    } else {
      if (matchPath(caminho, url)) {
        return true
      }
      const items = _.get(menu, 'items', [])
      if (isArrayNotEmpty(items)) {
        return checkMenuPath(items, url)
      }
    }
  }
  return false
}

export function getPermissoesPorMenu(menus, oidMenu, url, portalCliente = false) {
  const bloqueiaTudo = { inserir: false, alterar: false, excluir: false, listar: false }
  if (_.defaultTo(url, null)) {
    const isMenuPathOk = checkMenuPath(menus, url)
    if (!isMenuPathOk) {
      return bloqueiaTudo
    }
  }
  const apenasListagem = { inserir: false, alterar: false, excluir: false, listar: true }
  if (portalCliente) {
    return apenasListagem
  }

  if (isArrayNullOrEmpty(menus)) {
    return bloqueiaTudo
  }

  const permissoes = checkPermissoesMenu(menus, oidMenu, null)
  if (permissoes) {
    return permissoes
  }
  return { inserir: true, alterar: true, excluir: true, listar: true }
}

/**
 * Verifica as permissoes para o menu com o oidMenu passado como parametro.
 * Caso esse menu não tenha permissao, retornar a permissao do menu superior a ele
 * @param menus a serem verificados
 * @param oidMenu a ser verificado
 * @param permissoesMenuSuperior permissoes do menu superior ao menu encontrado
 */
export function checkPermissoesMenu(menus, oidMenu, permissoesMenuSuperior) {
  if (isArrayNullOrEmpty(menus) || !oidMenu) {
    return null
  }
  for (let menu of menus) {
    const permissoesMenu = menu.permissoes
    if (menu.oidMenu === oidMenu) {
      if (isArrayNullOrEmpty(permissoesMenu)) {
        if (isArrayNullOrEmpty(permissoesMenuSuperior)) {
          return null
        }
        return permissoesMenuSuperior[0]
      }
      return permissoesMenu[0]
    }
    const permissoes = isArrayNullOrEmpty(permissoesMenu) ? permissoesMenuSuperior : permissoesMenu
    const result = checkPermissoesMenu(menu.items, oidMenu, permissoes)
    if (result) {
      return result
    }
  }
}

export function getStartOfMonth() {
  return window.moment().startOf('month').toDate()
}

export function getEndOfMonth() {
  return window.moment().endOf('month').startOf('day').toDate()
}

export const camelize = (string) => string.replace(/-([a-z])/gi, (s, group) => group.toUpperCase())

export const styleToObject = (style) => {
  if (style) {
    return style
      .split(';')
      .filter((s) => s.length)
      .reduce((a, b) => {
        const keyValue = b.split(':')
        a[camelize(keyValue[0])] = keyValue[1]
        return a
      }, {})
  }
}

export function getYearRangeFromNow() {
  const yearRange = window.moment().year() + ':' + window.moment().add(20, 'years').year()
  return yearRange
}

export function getYearRangeFromDate(dateString) {
  const yearRange = dateString + ':' + window.moment().add(20, 'years').year()
  return yearRange
}

export function getUltimoCampoPesquisaLocalizacaoGrid(empresa, oidUnidade, oidTipoSepultura) {
  let params = []
  if (!empresa) {
    return ''
  }

  if (oidUnidade && oidUnidade > 0 && isArrayNotEmpty(empresa.unidades)) {
    const unidade = empresa.unidades.find((f) => f.oid === oidUnidade)
    if (unidade) {
      params = unidade.parametros
    }
  }

  if (isArrayNullOrEmpty(params)) {
    params = empresa.parametros
  }

  let paramName = 'ultimoCampoPesquisaLocalizacaoGrid'
  if (isTipoSepulturaUrna(empresa.unidades, oidUnidade, oidTipoSepultura)) {
    paramName = 'ultimoCampoPesquisaLocalizacaoGridUrna'
  } else if (isTipoSepulturaGaveta(empresa.unidades, oidUnidade, oidTipoSepultura)) {
    paramName = 'ultimoCampoPesquisaLocalizacaoGridGaveta'
  } else if (isTipoSepulturaGavetaTemporaria(empresa.unidades, oidUnidade, oidTipoSepultura)) {
    paramName = 'ultimoCampoPesquisaLocalizacaoGridTemporaria'
  } else if (isTipoSepulturaTumulo(empresa.unidades, oidUnidade, oidTipoSepultura)) {
    paramName = 'ultimoCampoPesquisaLocalizacaoGridTumulo'
  }

  return getParamValue(params, paramName)
}

export function getParamValue(params, id) {
  if (isArrayNullOrEmpty(params)) {
    return ''
  }

  const param = params.find((f) => f.id === id)
  if (param) {
    return param.value
  }

  return ''
}

export function getOidContratoCremacaoFutura(unidades, oidUnidade) {
  return getOidTipoSepultura(unidades, oidUnidade, 'oidTipoSepulturaCremacaoFutura')
}

export function getOidTipoSepulturaCapela(unidades, oidUnidade) {
  return getOidTipoSepultura(unidades, oidUnidade, 'oidTipoSepulturaCapela')
}

export function getOidTipoSepultura(unidades, oidUnidade, key) {
  const param = getParameterValueFromUnitOid(key, oidUnidade, unidades)
  if (param && param.value) {
    return parseInt(param.value)
  }
  return null
}

export function isContratoCremacaoFutura(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaCremacaoFutura', empresa)
}

export function isContratoCremacao(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaCremacao', empresa)
}

export function isContratoTratamentoTermico(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaTratamentoTermico', empresa)
}

export function isContratoColumbario(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return isTipoSepulturaColumbario(unidades, oidUnidade, oidTipoSepultura, empresa)
}

export function getOidTipoSepulturaCremacao(unidades, oidUnidade) {
  return getOidTipoSepultura(unidades, oidUnidade, 'oidTipoSepulturaCremacao')
}

export function isTipoSepulturaUrna(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaUrna', empresa)
}

export function isTipoSepulturaGaveta(unidades, oidUnidade, oidTipoSepultura) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaGaveta')
}

export function isTipoSepulturaGavetaTemporaria(unidades, oidUnidade, oidTipoSepultura) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaTemporario')
}

export function isTipoSepulturaTumulo(unidades, oidUnidade, oidTipoSepultura) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaTumulo')
}

export function isTipoSepulturaColumbario(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaColumbario', empresa)
}

export function isTipoSepulturaCapela(unidades, oidUnidade, oidTipoSepultura) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaCapela')
}

export function isTipoSepulturaPlano(unidades, oidUnidade, oidTipoSepultura) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaPlano')
}

export function isTipoSepulturaFuneral(unidades, oidUnidade, oidTipoSepultura, empresa = null) {
  return checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, 'oidTipoSepulturaFuneral', empresa)
}

export function checkTipoSepultura(unidades, oidUnidade, oidTipoSepultura, key, empresa = null) {
  const param = getParameterValueFromUnitOid(key, oidUnidade, unidades, empresa)
  if (param && param.value) {
    const values = param.value.split(',')
    const result = values.find((v) => oidTipoSepultura === parseInt(v))
    if (result) {
      return true
    }
  }
  return false
}

export function isTaxaConservacao(unidades, oidUnidade, oidGrupoFinanceiro) {
  const param = getParameterValueFromUnitOid('oidsGrupoTaxaConservacao', oidUnidade, unidades)
  if (param && param.value) {
    const arr = param.value.split(',')
    for (let strOid of arr) {
      const oid = parseInt(strOid)
      const result = oid === oidGrupoFinanceiro
      if (result) {
        return result
      }
    }
  }
  return false
}

export function isTaxaEntradaCaixa(unidades, oidUnidade, oidGrupoFinanceiro, empresa = null) {
  const param = getParameterValueFromUnitOid('oidGrupoTaxaEntradaCaixa', oidUnidade, unidades, empresa)
  if (param && param.value) {
    const oid = parseInt(param.value)
    return oid === oidGrupoFinanceiro
  }
  return false
}

export function isUnidadeMemorial(empresa, oidUnidade) {
  if (empresa && oidUnidade) {
    const parametros = empresa.parametros
    if (isArrayNullOrEmpty(parametros)) {
      return false
    }
    const unidadesMemoriais = parametros.find((obj) => obj.id === 'oidsUnidadesMemoriais')
    if (unidadesMemoriais) {
      const result = unidadesMemoriais.value.search(oidUnidade)
      return result > -1
    }
  }
  return false
}

export function getSugerirFalecidoCremacao(empresa, unidadeIdValue) {
  const resultPadrao = true
  if (!empresa) {
    return resultPadrao
  }
  if (unidadeIdValue && unidadeIdValue.id) {
    const unidade = findObjectInArray(empresa.unidades, 'oid', unidadeIdValue.id)
    if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
      const param = getParameterValueFromUnitOid('sugerirFalecidoCremacao', unidade.oid, empresa.unidades)
      if (param && param.value === 'false') {
        return false
      } else if (param && param.value) {
        return resultPadrao
      }
    }
  }
  const param = getParametroEmpresaCorrente('sugerirFalecidoCremacao', empresa)
  if (param && param.value === 'false') {
    return false
  }
  return resultPadrao
}

export function getLinhasPorPaginaListagem(empresa, unidade) {
  const resultPadrao = [15, 50, 100, 500, 1000]
  if (!empresa) {
    return resultPadrao
  }

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid('linhasPorPaginaListagem', unidade.oid, empresa.unidades)
    const result = resolveArrayResult(param)
    if (isArrayNullOrEmpty(result)) {
      return resultPadrao
    }
    return result
  }

  const param = getParametroEmpresaCorrente('linhasPorPaginaListagem', empresa)
  const result = resolveArrayResult(param)
  if (isArrayNullOrEmpty(result)) {
    return resultPadrao
  }
  return result
}

export function resolveArrayResult(param) {
  if (!param || !param.value) {
    return []
  }

  const values = param.value.split(',')
  if (!values || isArrayNullOrEmpty(values)) {
    return []
  }

  const result = []
  values.forEach((f) => {
    result.push(parseInt(f))
  })

  if (isArrayNullOrEmpty(result)) {
    return []
  }

  return result
}

export function getFatorMultiplicadorConspaga(empresa, unidade) {
  let resultPadrao = 1
  if (!empresa) {
    return resultPadrao
  }
  const configKey = 'fatorMultiplicadorConspaga'

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return parseInt(param.value)
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return parseInt(param.value)
  }

  return resultPadrao
}

export function isExibirOpacaoCremarContratoFalecido(empresa, unidade) {
  const configKey = 'exibirOpcaoCremarContratoFalecido'
  const defaultValue = false
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function getLinhasPadraoPorPagina(empresa, unidade) {
  let resultPadrao = 15
  const linhasPorPaginaListagem = getLinhasPorPaginaListagem(empresa, unidade)
  if (isArrayNotEmpty(linhasPorPaginaListagem)) {
    resultPadrao = linhasPorPaginaListagem[0]
  }

  if (!empresa) {
    return resultPadrao
  }

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid('linhasListagem', unidade.oid, empresa.unidades)
    if (param && param.value) {
      const result = parseInt(param.value)
      if (isValorInArray(linhasPorPaginaListagem, result)) {
        return result
      }
      return resultPadrao
    }
  }

  const param = getParametroEmpresaCorrente('linhasListagem', empresa)
  if (param && param.value) {
    const result = parseInt(param.value)
    if (isValorInArray(linhasPorPaginaListagem, result)) {
      return result
    }
    return resultPadrao
  }

  return resultPadrao
}

export function isValorInArray(array, value) {
  if (isArrayNullOrEmpty(array)) {
    return false
  }
  const result = array.find((f) => f === value)
  return !!result
}

export function isFormaPagamentoSeguradora(value) {
  if (nonNull(value)) {
    if (isIdValueIdValido(value)) {
      return value.id === 11
    }
    if (isNumber(value)) {
      return value === 11
    }
    if (typeof value === 'string') {
      return value === '11'
    }
  }
  return false
}

export function getColunasGridLog() {
  return [
    {
      field: 'data',
      header: 'Data',
      columnKey: 'data',
      sortable: true,
      filter: true,
      columnSortField: 'data',
      visible: true,
      fieldFormatter: 'formatarDataHora',
    },
    {
      field: 'usuarioGravacao',
      header: 'Usuário',
      columnKey: 'usuarioGravacao',
      sortable: true,
      filter: true,
      columnSortField: 'usuarioGravacao',
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      filter: true,
      columnSortField: 'descricao',
      visible: true,
    },
  ]
}

export function convertArrayIdValueToIdLabel(array) {
  if (isArrayNullOrEmpty(array)) {
    return []
  }

  let result = []
  array.forEach((o) => result.push({ id: o.id, label: o.value }))
  return result
}

export function convertArrayIdLabelToIdValue(array) {
  if (isArrayNullOrEmpty(array)) {
    return []
  }

  let result = []
  array.forEach((o) => result.push({ id: o.id, value: o.label }))
  return result
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

export function createAndDownloadBlobFile(base64, filename) {
  const arrayBuffer = base64ToArrayBuffer(base64)
  const blob = new Blob([arrayBuffer])
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export function resolvePhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return phoneNumber
  }
  let strPhone = phoneNumber.toString()
  strPhone = strPhone.replace(/\D/g, '')
  if (strPhone.charAt(0) === '0') {
    //desconsideramos o zero inicial
    // ex: 027998046089 ou 047991530470
    strPhone = strPhone.substring(1)
  }

  // se o tamanho for 8 => 99998888
  // ou 9 => 999998888
  // nao faz nada, a propria API do Whats vai recusar
  // pois nao tem DDD
  if (strPhone.length <= 9) {
    return phoneNumber
  }

  // 4799998888 => tem DDD, falta apenas o 9 adicional
  // partimos da premissa que os dois primeiros digitos sao o DDD
  if (strPhone.length === 10) {
    const ddd = strPhone.substring(0, 2)
    const numeroSemDdd = strPhone.substring(2)
    const result = '55' + ddd + '9' + numeroSemDdd
    return parseInt(result)
  }
  // 47999998888 => tem DDD, falta apenas o DDI
  // partimos da premissa que os dois primeiros digitos sao o DDD
  if (strPhone.length === 11) {
    const result = '55' + strPhone
    return parseInt(result)
  }
  // 554799998888 => tem DDD e DDI, falta apenas o 9 adicional
  // partimos da premissa que os dois primeiros digitos sao o DDI
  // e o terceiro e quarto dígitos são o DDD
  if (strPhone.length === 12) {
    const ddi = strPhone.substring(0, 2)
    const ddd = strPhone.substring(2, 4)
    const numeroSemDdiSemDdd = strPhone.substring(4)
    const result = ddi + ddd + '9' + numeroSemDdiSemDdd
    return parseInt(result)
  }

  return phoneNumber
}

export function resolveContatosString(value) {
  if (!value) {
    return []
  }
  const array = value.split(';')
  let result = []
  for (let phone of array) {
    const phoneParsed = resolvePhoneNumber(phone)
    const contato = {
      oid: new Date().getTime(),
      contato: phoneParsed,
      manual: true,
    }
    result.push(contato)
  }
  return result
}

export function isFormaPagamentoCelescDisponivel(empresa, unidade) {
  const formasPagto = getConfiguracaoFormasPagamento(empresa, unidade)
  const aDefinir = formasPagto.find((f) => parseInt(f) === 12) // 12 = Celesc
  return !!aDefinir
}

export function getConfiguracaoFormasPagamento(empresa, unidade) {
  let resultPadrao = []
  if (!empresa) {
    return resultPadrao
  }
  const configKey = 'formasPagamentoDisponiveis'

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value.split(',')
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value.split(',')
  }

  return resultPadrao
}

export function getConfiguracaoContasBancarias(empresa, unidade) {
  let resultPadrao = []
  if (!empresa) {
    return resultPadrao
  }
  const configKey = 'ailosContasBancarias'

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value.split(';')
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value.split(';')
  }

  return resultPadrao
}

export function isUtilizaAssinaturaDigitalDocumentos(empresa, unidade) {
  if (!empresa) {
    return false
  }
  const configKey = 'utilizaAssinaturaDigitalDocumentos'

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value === 'true'
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value === 'true'
  }

  return false
}

export function formatCurrencyBrl(value) {
  if (value) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
  return '0'
}

export function formatNumber(stringNumber) {
  if (typeof stringNumber === 'string') {
    stringNumber = stringNumber.replace('R$', '').replace(/\./g, '').replace(',', '.')
  }
  if (stringNumber) {
    return Number(stringNumber)
  }
  return 0
}

export const parseFloatValue = (value) => _.defaultTo(parseFloat(_.defaultTo(value, '0')), 0)

export function getDiasPadraoCarenciaCoberturaPlano(empresa, unidade) {
  let resultPadrao = 0
  if (!empresa) {
    return resultPadrao
  }
  const configKey = 'diasPadraoCarenciaCoberturaPlano'

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return parseInt(param.value)
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return parseInt(param.value)
  }

  return resultPadrao
}

export function isIntegracaoVindi(empresa, unidade) {
  const configKey = 'integracaoVindi'
  const defaultValue = false
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function getUnidadeFromIdValue(empresa, idValue) {
  if (_.get(idValue, 'id', null)) {
    const unidades = _.get(empresa, 'unidades', [])
    return unidades.find((u) => u.oid === idValue.id)
  }
  return null
}

export function isServicoCartaoCreditoVindi(empresa, unidade) {
  return isServicoVindi(empresa, unidade, 'servicoCartaoCredito')
}

export function isServicoBoletoVindi(empresa, unidade) {
  return isServicoVindi(empresa, unidade, 'servicoBoleto')
}

export function isServicoVindi(empresa, unidade, configKey) {
  let resultPadrao = false
  if (!empresa) {
    return resultPadrao
  }

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value.toUpperCase() === 'VINDI'
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value.toUpperCase() === 'VINDI'
  }

  return resultPadrao
}

export function isExibirConspaga(empresa, unidade) {
  const configKey = 'exibirConspaga'
  const defaultValue = true
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function isUsarMensageria(empresa, unidade) {
  const configKey = 'usarMensageria'
  const defaultValue = false
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function isExibirLocalizacaoCemiterio(empresa, unidade) {
  const configKey = 'exibirLocalizacaoCemiterio'
  const defaultValue = true
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function checkBooleanConfig({ empresa, unidade, configKey, defaultValue }) {
  if (!empresa) {
    return defaultValue
  }

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value.toUpperCase() === 'TRUE'
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value.toUpperCase() === 'TRUE'
  }

  return defaultValue
}

export function checkStringConfig({ empresa, unidade, configKey, defaultValue = null }) {
  if (!empresa) {
    return defaultValue
  }

  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      return param.value
    }
  }

  const param = getParametroEmpresaCorrente(configKey, empresa)
  if (param && param.value) {
    return param.value
  }

  return defaultValue
}

// export function getAnosExumacao(empresa, unidade) {
//   let resultPadrao = 3
//   if (!empresa) {
//     return resultPadrao
//   }
//   const configKey = 'anosExumacao'
//
//   if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
//     const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
//     if (param && param.value) {
//       return parseInt(param.value)
//     }
//   }
//
//   const param = getParametroEmpresaCorrente(configKey, empresa)
//   if (param && param.value) {
//     return parseInt(param.value)
//   }
//
//   return resultPadrao
// }

export function getAnosExumacao(empresa, unidade) {
  if (!empresa) {
    return null
  }
  let result = null
  const configKey = 'anosExumacao'
  if (unidade && isArrayNotEmpty(unidade.parametros) && isArrayNotEmpty(empresa.unidades)) {
    const param = getParameterValueFromUnitOid(configKey, unidade.oid, empresa.unidades)
    if (param && param.value) {
      result = param.value
    }
  }

  if (!result) {
    const param = getParametroEmpresaCorrente(configKey, empresa)
    if (param && param.value) {
      result = param.value
    }
  }

  if (result) {
    try {
      return JSON.parse(result)
    } catch (e) {
      return null
    }
  }
  return null
}

export function multiply(multiplier, multiplicand, precision = 0) {
  return _.round(_.multiply(multiplier, multiplicand), precision)
}

export function sum(values, precision = 0) {
  return _.round(_.sum(values), precision)
}

export function isEmitirNotaFiscalServicoManual(empresa, unidade) {
  const configKey = 'emitirNotaManualmente'
  const defaultValue = false
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function isUsarWebWhatsapp(empresa, unidade) {
  const configKey = 'usarWebWhatsApp'
  const defaultValue = true
  return checkBooleanConfig({ empresa, unidade, configKey, defaultValue })
}

export function isEmiteNFSe(empresa, unidade) {
  const configKey = 'chaveAcessoNFSeInventti'
  const defaultValue = null
  const result = checkStringConfig({ empresa, unidade, configKey, defaultValue })
  return !!result
}

export function isTipoSepulturaLocalizacaoGridSemFiltro(empresa, unidade, oidTipoSepultura) {
  if (!oidTipoSepultura) {
    return false
  }
  const configKey = 'oidsTipoSepulturaLocalizacaoGridSemFiltro'
  const defaultValue = null
  const result = checkStringConfig({ empresa, unidade, configKey, defaultValue })
  if (!result) {
    return false
  }
  const oids = result.split(',')
  if (isArrayNotEmpty(oids)) {
    return oids.some((f) => parseInt(f) === oidTipoSepultura)
  }
  return false
}

export function getObservacaoContratoFalecido(empresa, unidade) {
  const configKey = 'observacaoContratoFalecido'
  const defaultValue = null
  return checkStringConfig({ empresa, unidade, configKey, defaultValue })
}

export function getMascaraTelefone(telefone) {
  const defaultMask = '(99) 99999-9999'
  if (telefone && _.isString(telefone)) {
    return telefone.length <= 10 ? '(99) 9999-9999?9' : defaultMask
  }
  return defaultMask
}

export function isTipoFiltroSelecionado(tipoFiltro) {
  return tipoFiltro && !isNull(tipoFiltro?.value) && tipoFiltro?.value >= 0
}

export function isTipoFiltroSelecionadoComplementar(tipoFiltro) {
  return tipoFiltro && tipoFiltro?.value === 6
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function parseMapToList(map) {
  if (!map) {
    return []
  }
  const map2 = new Map(Object.entries(map))

  const addProperty = (object, prop) => {
    Object.defineProperty(object, prop, {
      value: undefined,
    })
  }
  const addValue = (object, prop, idx, values) => {
    Object.defineProperty(object, prop, {
      value: values[idx],
    })
  }
  let objetoDinamico = {}
  for (let key of map2.keys()) {
    addProperty(objetoDinamico, key)
  }

  const result = []
  const properties = Object.getOwnPropertyNames(objetoDinamico)
  if (map2 && map2.size > 0 && properties && properties.length > 0) {
    // para sabermos qual o tamanho do array de values
    const valuesLength = map2.get(properties[0]).length
    let idxValues = 0
    let idxProp = 0

    // ***** IMPORTANTE *****
    // Caso nao tenha 'discardProp' (poderia ser qualquer nome) por algum motivo nao renderiza na datatable.
    // Parece que o objeto tem que ter pelo menos uma propriedade criada de forma "não dinâmica"
    let idx = 1
    let newObj = { discardProp: idx }
    while (idxProp < properties.length) {
      const property = properties[idxProp]
      const values = map2.get(property)
      addValue(newObj, property, idxValues, values)

      idxProp++
      if (idxProp === properties.length) {
        idxProp = 0
        idxValues++
        result.push(newObj)
        idx++
        newObj = { discardProp: idx }
      }

      if (idxValues === valuesLength) {
        break
      }
    }
  }

  return result
}

export default {
  validateState,
  getFilters,
  // getCalendar,
  blankToNull,
  getMessage,
  formatarData,
  formatarDinheiro,
  getValue,
  format,
  formatCPF,
  validateEmail,
  validatePhone,
  isEmptyObject,
  getRegexLettersBlankSpace,
  getFiltersIdValue,
  isFilterValorComplementar,
  getFilterValueById,
  getJoinTypes,
  getTiposOrdenacoes,
  isArrayNullOrEmpty,
  isArrayNotEmpty,
  formatarTelefone,
  getFilterValueByConstant,
  parseFilter,
  parseTipoFiltro,
  getFiltersStringIdValue,
  getTiposDado,
  parseTipoDado,
  getTipoDadoValueById,
  isTipoDadoString,
  getFiltersOptionsIdValue,
  isTipoDadoData,
  getDateFromNumber,
  getDateTimeFromNumber,
  orderArrayByProperty,
  formatCurrency,
  formatTaxaValueForLookup,
  formatContratoValueForLookup,
  isIdValueNullOrEmpty,
  getListIdValuesUnidade,
  getIdValueUnidade,
  getListIdValuesUnidadeFromEmpresa,
  getShowHideColumnsForLocalizacao,
  isNumber,
  parseStringToNumber,
  isValorValidoMaiorQueZero,
  isIdValueIdValido,
  parseCurrency,
  resolveImage,
  isDate,
  calculatePreview,
  isStringBlank,
  isStringNotBlank,
  nonNull,
  getOptionsSimNao,
  getFiltersBooleanIdValue,
  isTipoDadoBoolean,
  findObjectInArray,
  removeObjectFromArray,
  getParameterValueFromUnitOid,
  getValueFromIdValue,
  getFormasPagamento,
  getValorParcelas,
  isIdValue,
  getValueFrom,
  getPermissoesPorMenu,
  getTipoConservacaoFromInt,
  getOpcoesTipoTelas,
  styleToObject,
  formatarDataHora,
  formatarDataCompetencia,
  getParametroEmpresaCorrente,
  getIdValueUnidadeMatriz,
  getStartOfMonth,
  getEndOfMonth,
  getYearRangeFromNow,
  getYearRangeFromDate,
  getUltimoCampoPesquisaLocalizacaoGrid,
  isContratoCremacaoFutura,
  isContratoCremacao,
  isContratoColumbario,
  isContratoTratamentoTermico,
  getOidTipoSepulturaCremacao,
  onlyNumbers,
  getOidContratoCremacaoFutura,
  getOidTipoSepulturaCapela,
  isTaxaConservacao,
  isUnidadeMemorial,
  getLinhasPorPaginaListagem,
  getLinhasPadraoPorPagina,
  isTipoSepulturaUrna,
  isTipoSepulturaColumbario,
  isTipoSepulturaGaveta,
  isTipoSepulturaCapela,
  isTipoSepulturaPlano,
  isTipoSepulturaFuneral,
  isTaxaEntradaCaixa,
  getDataAtual,
  isFormaPagamentoSeguradora,
  getBooleanOptionsFormaDePagamento,
  getUTCDate,
  getFiltersForEntity,
  getColunasGridLog,
  getSugerirFalecidoCremacao,
  convertArrayIdValueToIdLabel,
  convertArrayIdLabelToIdValue,
  createAndDownloadBlobFile,
  formatarHora,
  getFiltersIgualADiferenteDe,
  removeDuplicatesFromArrayByProperty,
  isIdValueOnlyStringValue,
  resolvePhoneNumber,
  resolveContatosString,
  getFatorMultiplicadorConspaga,
  getConfiguracaoFormasPagamento,
  isFormaPagamentoCelescDisponivel,
  getConfiguracaoContasBancarias,
  isUtilizaAssinaturaDigitalDocumentos,
  formatCurrencyBrl,
  formatNumber,
  parseFloatValue,
  getDiasPadraoCarenciaCoberturaPlano,
  base64ToArrayBuffer,
  isIntegracaoVindi,
  isServicoCartaoCreditoVindi,
  isServicoBoletoVindi,
  getUnidadeFromIdValue,
  isExibirLocalizacaoCemiterio,
  isExibirConspaga,
  multiply,
  sum,
  getAnosExumacao,
  isEmitirNotaFiscalServicoManual,
  isEmiteNFSe,
  isTipoSepulturaLocalizacaoGridSemFiltro,
  getMascaraTelefone,
  isUsarWebWhatsapp,
  isUsarMensageria,
  getObservacaoContratoFalecido,
  getListIdValuesUnidadeDisponiveisUsuario,
  parseMapToList,
}
