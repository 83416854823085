import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { getMessage } from "../../../utils/index";
import './FuncaoDinamicaForm.scss';

export const FuncaoDinamicaForm = ({ record, changeHandler, messages }) => (
    <div className="p-grid p-fluid">
        <div className="p-col-12">
            <div className="card card-w-title">
                <h3>{getMessage(messages, 'funcaodinamica.label.funcaodinamica')} {record.oid || ''}</h3>
                
                <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                        <span className="p-float-label">
                            <InputText value={record.nomeFuncao} onChange={changeHandler} name="nomeFuncao" />
                            <label>{getMessage(messages, 'funcaodinamica.label.nome')}</label>
                        </span>
                    </div>
                </div>
                
                <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                        <span className="p-float-label">
                            <InputTextarea value={record.descricao} onChange={changeHandler} name="descricao"
                            cols={30} autoResize={true} placeholder={getMessage(messages, 'funcaodinamica.label.descricao')}/>
                            <label>Descrição</label>
                        </span>
                    </div>
                </div>     
                           
                <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                        <span className="p-float-label">
                            <InputTextarea value={record.funcao} onChange={changeHandler} name="funcao"
                            cols={30} autoResize={true} placeholder={getMessage(messages, 'funcaodinamica.label.funcao')}/>
                            <label>Definição</label>
                        </span>
                    </div>
                </div>                
                           
                <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-1 p-md-2 p-lg-3 p-xl-3">                        
                        <Checkbox inputId="chkAtiva" checked={record.ativo} onChange={changeHandler}  name="ativo" style={{marginRight: "5px"}}/>
                        <label htmlFor="chkAtiva" className="p-checkbox-label">{getMessage(messages, 'funcaodinamica.label.ativa')}</label>
                    </div>
                </div>                
            </div>
        </div>
    </div>
)
