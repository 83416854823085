import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
import apiService from "../../services/api.service";
import _ from "lodash";
import {messageActions} from "../../store/message";
const BASE_PATH = '/lottustech/mapas';
const crud = build(validate,BASE_PATH);
const prefix = 'crudMapa';

const newTypes = {
    RESET: `${prefix}reset`
}
const actions = new Action(prefix,crud);
const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.criarMapaCoordenada = (params) => {
    return (dispatch, getState) => {
        apiService
          .post(`/lottustech/mapa/coordenada`, params)
          .then((response) => {
              dispatch(messageActions.messageShowMessages(makeMessages(['Coordenada cadastrada com sucesso'], 'info')))
          })
          .catch((error) => {
              dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
          })
    }
}

const makeMessages = (messages, type) => {
    let sticky = type === 'error' || type === 'warn'
    return messages.map((message) => {
        return {
            sticky,
            severity: type,
            detail: message,
        }
    })
}

export {types, actions};
