import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import _ from 'lodash'

export default function LtsDropdownIdValue(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    loadData()
  }, [props.loadDataFnParam])

  const loadData = () => {
    const loadDataFn = _.get(props, 'loadDataFn', null)
    if (loadDataFn) {
      if (props.hasOwnProperty('loadDataFnParam')) {
        loadDataFn.call(loadDataFn, props.loadDataFnParam).then(onSuccessLoadData).catch(onErrorLoadData)
      } else {
        loadDataFn.call(loadDataFn).then(onSuccessLoadData).catch(onErrorLoadData)
      }
    } else {
      const options_ = _.get(props, 'options', [])
      if (options_.length > 0) {
        setOptions(options_)
      }
    }
  }

  const onSuccessLoadData = (response) => {
    const data = _.get(response, 'data', [])
    const showOptionAll = _.get(props, 'showOptionAll', false)
    if (showOptionAll) {
      data.unshift({ id: 0, value: 'Todos' })
    }

    // hook para componentes que usam internamente esse componente possam
    // manipular os dados antes de serem renderizados
    if (props.hasOwnProperty('afterLoadDataFn')) {
      setOptions(props.afterLoadDataFn(data))
    } else {
      setOptions(data)
    }
  }

  const onErrorLoadData = (error) => {
    console.log(error)
  }

  const onChange = (e) => {
    props.onChange(e.value)
  }

  const resolveName = () => {
    return props.name
  }

  const resolveLabel = () => {
    if (props.label) {
      return <label>{props.label}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <Dropdown
          autoWidth={false}
          value={props.value}
          options={options}
          onChange={onChange}
          optionLabel="value"
          optionValue={(v) => v}
          dataKey="value"
          name={resolveName()}
          disabled={props.disabled}
          itemTemplate={props.itemTemplate}
          style={props.style}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
