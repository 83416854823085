import React from 'react'
import { Link } from 'react-router-dom'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { RadioButton } from 'primereact/radiobutton'
import utils from '../../../utils/index'
import { enums, icons, LANCAMENTO_CANCELADO, LANCAMENTO_PAGO, mascaras, routes } from '../../../constants/index'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'
import './LancamentoForm.scss'
import _ from 'lodash'
import LtsCartaoCredito from '../../../components/lottus/lts-cartao-credito'
import LancamentoItens from './lancamento-itens'
import storage from '../../../services/stoage.service'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsLookupContrato from '../../../components/lottus/lts-lookup-contrato'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'

import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import LtsLookupNotaEntrada from '../../../components/lottus/lts-lookup-nota-entrada'
import { InputMask } from 'primereact/inputmask'
import LtsInputText from '../../../components/lottus/lts-input-text'
import LtsLookupContaCorrente from '../../../components/lottus/lts-lookup-conta-corrente'
import LancamentoAnexo from './lancamento-anexo'
import LtsCalendar from '../../../components/lottus/lts-calendar'

const getLink = ({ oid, props, messages, chaveMessage, addSpace = true }) => {
  if (oid > 0) {
    return (
      <div>
        <div className="p-grid form-group">
          <div className="p-col-12">
            <Link
              to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${oid}`}
              onClick={(e) => {
                props.abrirLancamento(oid)
                props.changeActiveIndex(0)
              }}
            >
              {chaveMessage ? `${utils.getMessage(messages, chaveMessage)}: ${oid}` : oid}
            </Link>
          </div>
        </div>
        {addSpace ? <br /> : null}
      </div>
    )
  }
}

export const LancamentoForm = (props) => {
  let pagamento = props.pagamento
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler
  let unidade = {}
  if (utils.nonNull(record.unidade) && record.unidade.id) {
    unidade = { id: record.unidade.id, value: record.unidade.value }
  }

  let emissao = record.emissao
  let vencimento = record.vencimento
  let dataPagamento = record.dataPagamento
  let dataCompetencia = record.dataCompetencia

  if (pagamento && pagamento.primeiroVencimento) {
    pagamento.primeiroVencimento = utils.getDateFromNumber(pagamento.primeiroVencimento)
  }

  let status = {}
  if (props.record.status) {
    status = props.status.find((con) => con.value === props.record.status)
  } else {
    status = props.status[0]
  }

  let fontColor = '#607d8b' // em aberto
  if (props.record.status === 2) {
    // VENCIDO
    fontColor = '#ff4d4d'
  } else if (props.record.status === 3) {
    // PAGO
    fontColor = '#4CAF50'
  } else if (props.record.status === 4) {
    // CANCELADO
    fontColor = '#808080'
  }

  const marcadores = []
  if (record.marcadores) {
    record.marcadores.forEach((marcador) => {
      marcadores.push({ oid: marcador.id, codigo: marcador.value })
    })
  }

  const setPagador = (props, record, e) => {
    const oid = _.get(e, 'oid', null)
    const nome = _.get(e, 'nome', null)
    const numeroDocumento = _.get(e, 'numeroDocumento', null)
    props.setFieldPagador(oid, nome, numeroDocumento)
    if (oid) {
      props.checkDocumentoPagador(numeroDocumento)
      updateDadosCartao(e)
    }
  }

  const getTabReferencia = () => {
    const lancamentos = _.get(record, 'lancamentosOrigemByDestino', [])
    if (_.isEmpty(lancamentos)) {
      return null
    }
    return (
      <TabPanel header={utils.getMessage(messages, 'lancamentofinanceiro.label.referencias')}>
        <h6>{utils.getMessage(messages, 'lancamentofinanceiro.label.lancamentosAgrupados')}</h6>
        <div style={{ marginLeft: '10px' }}>
          {lancamentos.map((oid) =>
            getLink({ oid, props, messages, chaveMessage: null, addSpace: false, resetTabIndex: true })
          )}
        </div>
      </TabPanel>
    )
  }

  return (
    <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
      <TabPanel header={utils.getMessage(messages, 'lancamentofinanceiro.label.dadosGerais')}>
        {getDadosPrincipais()}
        {getDadosPagamento()}
      </TabPanel>
      <TabPanel header={utils.getMessage(messages, 'lancamentofinanceiro.label.anexos')}>{getDadosAnexo()}</TabPanel>
      <TabPanel header={utils.getMessage(messages, 'lancamentofinanceiro.label.provisionamento')}>
        {getDadosProvisionamento()}
      </TabPanel>
      <TabPanel header={utils.getMessage(messages, 'lancamentofinanceiro.label.logs')}>{getDadosLogs()}</TabPanel>
      {getTabReferencia()}
    </TabView>
  )

  function resolveDescricaoServicoNFSe() {
    if (_.get(props, 'emiteNFSe', false)) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <span className="p-float-label">
              <InputTextarea
                value={_.get(record, 'descricaoServicoNFSe', null)}
                onChange={changeHandler}
                name="descricaoServicoNFSe"
                rows={1}
                cols={30}
                autoResize={true}
                maxLength={800}
              />
              <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.descricaoServicoNFSe')}</label>
            </span>
          </div>
        </div>
      )
    }
    return null
  }

  function getDadosPrincipais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-col-12">
              <h3>
                {utils.getMessage(messages, 'lancamentofinanceiro.label.lancamento')} {record.oid || ''}
                <span class="tag" style={{ marginLeft: '15px', backgroundColor: fontColor }}>
                  {status.label}
                </span>
              </h3>
            </div>
            {getLink({
              oid: record.lancamentoOrigem,
              props,
              messages,
              chaveMessage: 'lancamentofinanceiro.label.lancamentoorigem',
            })}
            {getLink({
              oid: record.lancamentoContrapartida,
              props,
              messages,
              chaveMessage: 'lancamentofinanceiro.label.lancamentoContrapartida',
            })}
            {getLink({
              oid: record.lancamentoDestino,
              props,
              messages,
              chaveMessage: 'lancamentofinanceiro.label.lancamentodestino',
            })}
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                <span className="p-float-label">
                  <Dropdown
                    autoWidth={false}
                    disabled={props.disableUnit}
                    value={unidade}
                    options={props.unidades}
                    onChange={changeHandler}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name="unidade"
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.unidade')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                <LtsLookupContrato
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.contrato')}
                  value={_.get(record, 'contrato.value', '')}
                  oidRecord={_.get(record, 'contrato.id', '')}
                  oidUnidade={_.get(record, 'contrato', '')}
                  history={props.history}
                  onChange={props.setContrato}
                  stateContrato={props.stateContrato}
                  editHidden={false}
                  addFiltroDistint={true}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12">
                <LancamentoItens
                  disabled={props.acaoBloquearLancamento}
                  history={props.history}
                  values={_.get(record, 'taxas', [])}
                  units={_.get(props, 'units', storage.getUnits())}
                  currentCompany={_.get(props, 'currentCompany', storage.getCompany())}
                  onChange={props.onChangeItemLancamento}
                  record={record}
                  showMessageConspagaEmDia={props.showMessageConspagaEmDia}
                  showWarningMessage={props.showWarningMessage}
                  fatorMultiplicadorConspaga={props.fatorMultiplicadorConspaga}
                  showConspaga={props.exibirConspaga}
                />
              </div>
            </div>
            {resolveDescricaoServicoNFSe()}
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <LtsCurrency
                  value={_.get(record, 'valorBruto', 0)}
                  name="valorBruto"
                  disabled={true}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.valorbase')}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={emissao}
                    onChange={changeHandler}
                    name="emissao"
                    disabled={props.acaoBloquearLancamento}
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.emissao')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={dataCompetencia}
                    disabled={true}
                    dateFormat={mascaras.MASCARA_DATA_MM_YY}
                    name="dataCompetencia"
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.dataCompetencia')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    keepInvalid={true}
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={vencimento}
                    onChange={(e) => {
                      changeHandler(e)
                      props.calcularValorTotal(utils.parseStringToNumber(record.valorBruto))
                    }}
                    name="vencimento"
                    disabled={props.acaoBloquearLancamento}
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.vencimento')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={dataPagamento}
                    name="dataPagamento"
                    onChange={(e) => changeHandler(e)}
                    disabled={props.acaoBloquearLancamento}
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.pagamento')}</label>
                </span>
              </div>
            </div>
            {getCampoDataImportacao()}
            {getDadosValoresLancamento()}

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                <LtsCurrency
                  value={_.get(record, 'valor', 0)}
                  name={'valor'}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.valor')}
                  onChange={(e) => props.setField('valor', e)}
                  disabled={true}
                />
              </div>
            </div>

            {_.get(record, 'valorPago', null) ? (
              <div className="p-grid form-group">
                <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                  <LtsCurrency
                    value={_.get(record, 'valorPago', 0)}
                    name={'valor'}
                    label={utils.getMessage(messages, 'lancamentofinanceiro.label.valorPago')}
                    onChange={(e) => props.setField('valorPago', e)}
                    disabled={true}
                  />
                </div>
              </div>
            ) : null}

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <LtsLookupPessoa
                  header={'Pagador'}
                  name={'pagador'}
                  field={'nome'}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.pagador')}
                  value={{
                    id: _.get(record, 'pagador.id', null),
                    value: _.get(record, 'pagador.value', null),
                  }}
                  oidRecord={_.get(record, 'pagador.id', '')}
                  history={props.history}
                  onChange={(e) => setPagador(props, record, e)}
                  statePessoaFisica={props.statePessoaFisica}
                  statePessoaJuridica={props.statePessoaJuridica}
                  tipoPessoa={enums.TipoPessoa.AMBAS}
                  showAddButton={false}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                <span className="p-float-label">
                  <InputMask
                    mask="999.999.999-99"
                    unmask={true}
                    value={_.get(record, 'cpfResponsavelPJ', '')}
                    onChange={(e) => props.setField('cpfResponsavelPJ', e.target.value)}
                    name="cpfResponsavelPJ"
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.cpfResponsavelPJ')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                <span className="p-float-label">
                  <Dropdown
                    autoWidth={false}
                    disabled={isLancamentoPago(record)}
                    value={record.tipoFormaPagamento}
                    options={props.formasPagamento}
                    onChange={props.changeHandlerFormaPagamento}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name="tipoFormaPagamento"
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.formapagamento')}</label>
                </span>
              </div>
              {resolveLinkPagamento()}
            </div>
            {resolveInformacoesPagamento()}

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <span className="p-float-label">
                  <InputTextarea
                    value={record.observacao}
                    onChange={changeHandler}
                    name="observacao"
                    rows={1}
                    cols={30}
                    autoResize={true}
                  />
                  <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.observacao')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <span className="p-float-label">
                  <LtsLookupMarcador
                    label={utils.getMessage(messages, 'lancamentofinanceiro.label.marcador')}
                    value={marcadores}
                    history={props.history}
                    editHidden={true}
                    field={'codigo'}
                    multiple={true}
                    onChange={(e) => props.setMarcadores(e)}
                    showAddButton={true}
                    stateMarcador={props.stateMarcador}
                    tipo={'LANCAMENTO'}
                  />
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <span className="p-float-label">
                  <LtsLookupNotaEntrada
                    label={utils.getMessage(messages, 'lancamentofinanceiro.label.notaentrada')}
                    value={_.get(record, 'notaEntrada.value', '')}
                    oidRecord={_.get(record, 'notaEntrada.id', '')}
                    history={props.history}
                    multiple={false}
                    onChange={(e) => props.setNotaEntrada(e)}
                    stateNotaEntrada={props.stateNotaEntrada}
                    disabled={!isLancamentoSaida(record)}
                    somenteSemLancamento={true}
                  />
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                <span className="p-float-label">
                  <LtsLookupContaCorrente
                    label={utils.getMessage(messages, 'lancamentofinanceiro.label.contacorrente')}
                    value={_.get(record, 'contaCorrente.value', '')}
                    oidRecord={_.get(record, 'contaCorrente.id', '')}
                    history={props.history}
                    onChange={(e) => props.setContaCorrente(e)}
                  />
                </span>
              </div>
            </div>

            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <label htmlFor="chkPromissoriaLancamento" className="label">
                {utils.getMessage(messages, 'lancamentofinanceiro.label.promissoria')}
              </label>
              <Checkbox
                inputId="chkPromissoriaLancamento"
                checked={record.promissoria}
                onChange={changeHandler}
                name="promissoria"
                style={{ marginLeft: '5px' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  function resolveLinkPagamento() {
    const linkPagamento = _.get(record, 'linkPagamento', null)
    if (linkPagamento) {
      return (
        <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-2" style={{ marginTop: '15px' }}>
          <a href={linkPagamento} target="_blank">
            {linkPagamento}
          </a>
        </div>
      )
    }
    return null
  }

  function resolveInformacoesPagamento() {
    const formaPagamento = _.get(record, 'tipoFormaPagamento.id', -1)
    const formaPagamentoOriginal = _.get(props, 'formaPagamentoOriginal.id', -1)
    if (formaPagamento === enums.ID_CARTAO_CREDITO_VINDI) {
      return (
        <div>
          <LtsCartaoCredito
            recordId={_.get(record, 'oid', null)}
            disabled={_.get(record, 'oid', 0) > 0 && formaPagamentoOriginal === formaPagamento}
            parcelasCartaoCredito={record.parcelasCartaoCredito}
            setField={props.setField}
            showParcelas={true}
            oidOwnerCartaoCredito={_.get(record, 'pagador.id', null)}
            idFormaPagamentoToRender={enums.ID_CARTAO_CREDITO_VINDI}
            formaPagamento={formaPagamento}
            cartaoCredito={_.get(record, 'pessoaCartaoCredito', {})}
            fieldName={'pessoaCartaoCredito'}
          />
          <br />
        </div>
      )
    } else if (formaPagamento === enums.ID_DEBITO_AUTOMATICO) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-4">
              <LtsInputText
                value={_.get(record, 'codigoAgencia', '')}
                label={'Agência'}
                onChange={(e) => props.setField('codigoAgencia', e)}
                style={{ minWidth: '300px', maxWidth: '300px' }}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-4">
              <LtsInputText
                value={_.get(record, 'codigoConta', '')}
                label={'Conta'}
                onChange={(e) => props.setField('codigoConta', e)}
                style={{ minWidth: '300px', maxWidth: '300px' }}
              />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  function updateDadosCartao(pagador) {
    const formaPagamento = _.get(record, 'tipoFormaPagamento.id', -1)
    return (
      <div>
        <LtsCartaoCredito
          recordId={_.get(record, 'oid', null)}
          disabled={_.get(record, 'oid', 0) > 0}
          parcelasCartaoCredito={record.parcelasCartaoCredito}
          setField={props.setField}
          showParcelas={true}
          oidOwnerCartaoCredito={_.get(record, 'pagador.id', null)}
          idFormaPagamentoToRender={enums.ID_CARTAO_CREDITO_VINDI}
          formaPagamento={formaPagamento}
          cartaoCredito={_.get(record, 'pessoaCartaoCredito', {})}
          fieldName={'pessoaCartaoCredito'}
        />
        <br />
      </div>
    )
  }

  function getDadosProvisionamento() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="p-grid form-group">
            <div className="p-col-10 p-sm-6 p-md-6 p-lg-5 p-xl-4">
              <LtsCalendar
                label={utils.getMessage(messages, 'lancamentofinanceiro.label.dataPrevisaoRecebimento')}
                value={record.dataPrevisaoRecebimento}
                onChange={(e) => props.setField('dataPrevisaoRecebimento', e)}
                name="dataPrevisaoRecebimento"
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-10 p-sm-6 p-md-6 p-lg-5 p-xl-4">
              <LtsCalendar
                label={utils.getMessage(messages, 'lancamentofinanceiro.label.dataRecebimento')}
                value={record.dataRecebimento}
                onChange={(e) => props.setField('dataRecebimento', e)}
                name="dataRecebimento"
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-10 p-sm-6 p-md-6 p-lg-5 p-xl-4">
              <LtsCurrency
                value={_.get(record, 'valorRecebido', 0)}
                name={'valorRecebido'}
                label={utils.getMessage(messages, 'lancamentofinanceiro.label.valorRecebido')}
                onChange={(e) => props.setField('valorRecebido', e)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosAnexo() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <LancamentoAnexo
            anexos={props.anexos}
            downloadAnexo={props.downloadAnexo}
            removeAnexo={props.removeAnexo}
            messages={props.messages}
            onSelectAnexo={props.onSelectAnexo}
            oidLancamentoFinanceiro={record.oid}
            adicionandoAnexo={props.adicionandoAnexo}
          ></LancamentoAnexo>
        </div>
      </div>
    )
  }

  function getCampoDataImportacao() {
    if (record.dataImportacao) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-3 p-md-2 p-lg-2 p-xl-2">
            <label className="label">Importação</label>
            <Calendar disabled={true} value={record.dataImportacao} name="dataImportacao" />
          </div>
          {getLinkConsultaBaixa()}
        </div>
      )
    }
  }

  function getLinkConsultaBaixa() {
    const oidArquivoRetorno = _.get(record, 'oidArquivoRetorno', null)
    if (oidArquivoRetorno) {
      return (
        <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2" style={{ marginTop: '25px' }}>
          <Link to={`${routes.PATH_CONSULTA_BAIXA}/${oidArquivoRetorno}`}>
            {`Arquivo Retorno ${oidArquivoRetorno}`}
          </Link>
        </div>
      )
    }
    return null
  }

  function getDadosValoresLancamento() {
    return (
      <div className="p-col-12">
        <div className="p-grid form-group">
          <div className="p-col-12">
            <label className="label">{utils.getMessage(messages, 'lancamentofinanceiro.label.desconto')}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
            <RadioButton
              inputId="tipoDescontoValor"
              name="tipoDescontoValor"
              value={record.tipoDesconto}
              checked={record.tipoDesconto === 1}
              onChange={(e) => props.onChangeTipoDesconto(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
            />
            <label htmlFor="tipoDescontoValor" className="p-radiobutton-label">
              Valor em Reais
            </label>

            <RadioButton
              inputId="tipoDescontoPercentual"
              name="tipoDescontoPercentual"
              value={record.tipoDesconto}
              checked={record.tipoDesconto === 2}
              onChange={(e) => props.onChangeTipoDesconto(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
              style={{ marginLeft: '10px' }}
            />
            <label htmlFor="tipoDescontoPercentual" className="p-radiobutton-label">
              Percentual
            </label>
          </div>
        </div>
        {getResolveTipoDesconto()}

        <div className="p-grid form-group">
          <div className="p-col-12">
            <label className="label">{utils.getMessage(messages, 'lancamentofinanceiro.label.multa')}</label>
          </div>

          <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
            <RadioButton
              inputId="tipoMultaValor"
              name="tipoMultaValor"
              value={record.tipoMulta}
              checked={record.tipoMulta === 1}
              onChange={(e) => props.onChangeTipoMulta(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
            />
            <label htmlFor="tipoMultaValor" className="p-radiobutton-label">
              Valor em Reais
            </label>

            <RadioButton
              inputId="tipoMultaPercentual"
              name="tipoMultaPercentual"
              value={record.tipoMulta}
              checked={record.tipoMulta === 2}
              onChange={(e) => props.onChangeTipoMulta(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
              style={{ marginLeft: '10px' }}
            />
            <label htmlFor="tipoMultaPercentual" className="p-radiobutton-label">
              Percentual
            </label>
          </div>
        </div>
        {getResolveTipoMulta()}

        <div className="p-grid form-group">
          <div className="p-col-12">
            <label className="label">{utils.getMessage(messages, 'lancamentofinanceiro.label.juros')}</label>
          </div>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
            <RadioButton
              inputId="tipoJurosValor"
              name="tipoJurosValor"
              value={record.tipoJuros}
              checked={record.tipoJuros === 1}
              onChange={(e) => props.onChangeTipoJuros(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
            />
            <label htmlFor="tipoJurosValor" className="p-radiobutton-label">
              Valor em Reais
            </label>

            <RadioButton
              inputId="tipoJurosPercentual"
              name="tipoJurosPercentual"
              value={record.tipoJuros}
              checked={record.tipoJuros === 2}
              onChange={(e) => props.onChangeTipoJuros(e)}
              disabled={desabilitarCamposDescontoAcrescimo()}
              style={{ marginLeft: '10px' }}
            />
            <label htmlFor="tipoJurosPercentual" className="p-radiobutton-label">
              Percentual
            </label>
            <Checkbox
              onChange={(e) => props.onChangeJurosDia(e)}
              style={{ marginLeft: '10px' }}
              checked={record.jurosPorDia}
              name="jurosPorDia"
              disabled={desabilitarCamposDescontoAcrescimo()}
            />
            <label htmlFor="tipoJurosPercentual" className="p-radiobutton-label">
              Juros Por Dia
            </label>
          </div>
        </div>
        {getResolveTipoJuros()}
      </div>
    )
  }

  function desabilitarCamposDescontoAcrescimo() {
    return !!(
      record.status === LANCAMENTO_PAGO ||
      record.status === LANCAMENTO_CANCELADO ||
      props.acaoBloquearLancamento
    )
  }

  function getResolveTipoDesconto() {
    if (record.tipoDesconto === 1) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              value={_.get(record, 'valorDesconto', 0)}
              name="valorDesconto"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangeValorDesconto(e)}
            />
          </div>
          <label className="label" htmlFor="valorDesconto">
            {getValorReais(props.descontoAplicado)}
          </label>
        </div>
      )
    } else if (record.tipoDesconto === 2) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              mode={'decimal'}
              value={_.get(record, 'percentualDesconto', 0)}
              name="percentualDesconto"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangePercentualDesconto(e)}
            />
          </div>
          <label className="label" htmlFor="percentualDesconto">
            {getValorReais(props.descontoAplicado)}
          </label>
        </div>
      )
    }
  }

  function getResolveTipoMulta() {
    if (record.tipoMulta === 1) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              value={_.get(record, 'valorMulta', 0)}
              name="valorMulta"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangeValorMulta(e)}
            />
          </div>
          <label className="label" htmlFor="valorMulta">
            {getValorReais(props.multaAplicada)}
          </label>
        </div>
      )
    } else if (record.tipoMulta === 2) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              mode={'decimal'}
              value={_.get(record, 'percentualMulta', 0)}
              name="percentualMulta"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangePercentualMulta(e)}
            />
          </div>
          <label className="label" htmlFor="percentualMulta">
            {getValorReais(props.multaAplicada)}
          </label>
        </div>
      )
    }
  }

  function getResolveTipoJuros() {
    if (record.tipoJuros === 1) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              value={_.get(record, 'valorJuros', 0)}
              name="valorJuros"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangeValorJuros(e)}
            />
          </div>
          <label className="label" htmlFor="valorJuros">
            {getValorReais(props.jurosAplicados)}
          </label>
        </div>
      )
    } else if (record.tipoJuros === 2) {
      return (
        <div className="p-grid p-align-baseline">
          <div className="p-col-6 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <LtsCurrency
              mode={'decimal'}
              value={_.get(record, 'percentualJuros', 0)}
              name="percentualJuros"
              disabled={desabilitarCamposDescontoAcrescimo()}
              onChange={(e) => props.onChangePercentualJuros(e)}
            />
          </div>
          <label className="label" htmlFor="percentualJuros">
            {getValorReais(props.jurosAplicados)}
          </label>
        </div>
      )
    }
  }

  function getValorReais(valor) {
    if (valor && valor !== '') {
      return 'R$ ' + valor
    }
    return 'R$ 0,00'
  }

  function isLancamentoPago(lancamento) {
    if (!lancamento || !lancamento.oid || parseInt(lancamento.oid) <= 0 || !lancamento.status) {
      return false
    }
    return lancamento.status === 3
  }

  function isLancamentoSaida(lancamento) {
    if (!lancamento || !lancamento.taxas) {
      return false
    }
    return (lancamento.taxas || []).find((f) => f.entradaSaida === 2)
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.LANCAMENTO}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosPagamento() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(props.messages, 'lancamentofinanceiro.label.adicionar')}
          onClick={(e) => {
            pagamento.unidade = _.get(record, 'unidade', null)
            const contaCorrente = _.get(record, 'contaCorrente', null)
            pagamento.contaCorrente = _.isEmpty(contaCorrente) ? null : contaCorrente
            props.adicionarPagamento()
            props.showForm('pagamento', false)
          }}
          disabled={verificacaoDesabilitarBotaoAdicionar()}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-3 p-md-3 p-lg-2 p-xl-1">
                {<h3>{utils.getMessage(messages, 'lancamentofinanceiro.label.parcelas')}</h3>}
              </div>
              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <Button
                  onClick={(e) => {
                    props.showFormParcelas()
                    props.calcularValorParcela()
                  }}
                  className="p-button-success"
                  style={{ marginTop: '-5px' }}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.adicionar')}
                  disabled={props.desabilitarBotaoAdicionarPagamento()}
                />
              </div>
              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <Button
                  onClick={(e) => props.estornarPagamento()}
                  className="p-button-danger"
                  style={{ marginTop: '-5px' }}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.estornar')}
                  disabled={props.desabilitarBotaoEstornarParcela()}
                />
              </div>

              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <Button
                  type="button"
                  className="p-button-warning"
                  style={{ marginTop: '-5px' }}
                  disabled={props.desabilitarBotaoBaixarParcela()}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.baixar')}
                  onClick={(e) => props.showForm('baixarParcelas', true)}
                />
              </div>

              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <Button
                  type="button"
                  className="p-button-warning"
                  style={{ marginTop: '-5px' }}
                  disabled={props.desabilitarBotaoBoleto()}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.boleto')}
                  onClick={(e) => props.showForm('dadosBoletoIndividual', true)}
                />
              </div>

              <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                <Button
                  type="button"
                  className="p-button-warning"
                  style={{ marginTop: '-5px' }}
                  disabled={props.desabilitarBotaoCarne()}
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.carne')}
                  onClick={(e) => props.gerarCarneParcelas()}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTable
                  onFilter={props.onFilter}
                  onSort={props.onSort}
                  value={record.lancamentos}
                  totalRecords={props.totalRecords}
                  sortOrder={props.order}
                  sortField={props.sortField}
                  selection={props.selectedParcelas}
                  onSelectionChange={props.onSelectionChange}
                  emptyMessage=""
                  autoLayout={true}
                >
                  {props.colunasPagamentos}
                </DataTable>
              </div>
            </div>

            <Dialog
              visible={props.displayForm.pagamento}
              className={'width-30-rem'}
              onHide={(e) => {
                props.showForm('pagamento', false)
                props.clearFormPagamento()
              }}
              baseZIndex={1000000}
              modal={true}
              closable={true}
              header={utils.getMessage(props.messages, 'lancamentofinanceiro.label.formapagamento')}
              footer={footer}
            >
              <div className="p-grid form-group margin-top-dialogs">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Dropdown
                      className={'width-15-rem'}
                      autoWidth={false}
                      value={pagamento.formaPagamentoEntrada}
                      options={props.formasPagamentoBasica}
                      onChange={props.editarDadoFormaPagamento}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="formaPagamentoEntrada"
                    />
                    <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.formapagamentoentrada')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      className={'width-15-rem'}
                      keyfilter={/^[0-9.,]+$/}
                      value={getValorReais(pagamento.valorEntrada)}
                      onBlur={(e) => props.onBlurValorFormaPagamento(pagamento, e)}
                      onChange={props.editarDadoFormaPagamento}
                      disabled={verificacaoDesabilitarCampoEntrada(pagamento, 'valorEntrada')}
                      name="valorEntrada"
                    />
                    <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.valorentrada')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Dropdown
                      className={'width-15-rem'}
                      autoWidth={false}
                      value={pagamento.formaPagamento}
                      options={props.formasPagamentoBasica}
                      onChange={props.editarDadoFormaPagamento}
                      optionLabel="value"
                      optionValue={(v) => v}
                      name="formaPagamento"
                    />
                    <label>{utils.getMessage(props.messages, 'lancamentofinanceiro.label.formapagamento')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Calendar
                      className={'width-15-rem'}
                      yearNavigator={true}
                      yearRange={utils.getYearRangeFromDate('1970')}
                      monthNavigator={true}
                      value={pagamento.primeiroVencimento}
                      onChange={props.editarDadoFormaPagamento}
                      disabled={verificacaoDesabilitarCampo(pagamento, 'primeiroVencimento')}
                      name="primeiroVencimento"
                    />
                    <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.primeiraparcela')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      className={'width-15-rem'}
                      name={'numeroParcelas'}
                      value={pagamento.numeroParcelas}
                      onChange={(e) => props.editarNumeroParcelas(e)}
                      disabled={verificacaoDesabilitarCampo(pagamento, 'numeroParcelas')}
                      min={1}
                      max={64}
                    />
                    <label>Qtd Parcelas</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      className={'width-15-rem'}
                      keyfilter={/^[0-9.,]+$/}
                      value={getValorReais(pagamento.valorParcela)}
                      onBlur={props.onBlurValorParcela}
                      onChange={props.editarDadoFormaPagamento}
                      disabled={verificacaoDesabilitarCampo(pagamento, 'valor')}
                      name="valor"
                    />
                    <label>{utils.getMessage(messages, 'lancamentofinanceiro.label.valorparcela')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <Checkbox
                    inputId="chkPromissoria"
                    checked={pagamento.promissoria}
                    onChange={props.editarDadoFormaPagamento}
                    name="promissoria"
                    disabled={verificacaoDesabilitarCampo(pagamento, 'promissoria')}
                  />
                  <label htmlFor="chkPromissoria" className="p-checkbox-label">
                    {utils.getMessage(messages, 'lancamentofinanceiro.label.promissoria')}
                  </label>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    )
  }

  function verificacaoDesabilitarBotaoAdicionar() {
    if (
      verificacaoDesabilitarCampo(pagamento, 'botaoAdicionar') &&
      verificacaoDesabilitarCampoEntrada(pagamento, 'botaoAdicionar')
    ) {
      return true
    }

    let valorLancamento = props.record.valor
    if (typeof valorLancamento === 'string') {
      valorLancamento = utils.parseStringToNumber(valorLancamento)
    }
    valorLancamento = utils.isNumber(valorLancamento) ? valorLancamento : 0

    let valorLancamentosFilhos = props.getValorLancamentosFilhos()
    valorLancamentosFilhos = utils.isNumber(valorLancamentosFilhos) ? valorLancamentosFilhos : 0

    let valorEntrada = utils.parseStringToNumber(props.pagamento.valorEntrada)
    valorEntrada = utils.isNumber(valorEntrada) ? valorEntrada : 0

    let resultadoTotal = utils.sum([valorLancamentosFilhos, valorEntrada], 2)

    if (valorLancamento < resultadoTotal) {
      return true
    }
    let numeroParcelas = props.pagamento.numeroParcelas
    let valorParcela = utils.parseStringToNumber(props.pagamento.valorParcela)
    let valorTotalParcelas = utils.multiply(numeroParcelas, valorParcela, 2)
    resultadoTotal = valorTotalParcelas + valorEntrada + valorLancamentosFilhos
    let diferenca = parseFloat(valorLancamento).toFixed(2) - parseFloat(resultadoTotal).toFixed(2)
    return diferenca >= 0.99 //Tolerancia de 99 centavos por causa do arredondamento
  }

  function verificacaoDesabilitarCampoEntrada(pagamento, campo) {
    return !!(
      utils.isIdValueNullOrEmpty(pagamento.formaPagamentoEntrada) ||
      (!pagamento.formaPagamentoEntrada && (campo === 'valorEntrada' || campo === 'botaoAdicionar'))
    )
  }

  function verificacaoDesabilitarCampo(pagamento, campo) {
    if (
      utils.isIdValueNullOrEmpty(pagamento.formaPagamento) ||
      (!pagamento.formaPagamento &&
        (campo === 'primeiroVencimento' ||
          campo === 'numeroParcelas' ||
          campo === 'valor' ||
          campo === 'botaoAdicionar' ||
          campo === 'promissoria'))
    ) {
      return true
    } else {
      let idFormaPagamento = pagamento.formaPagamento.id
      if (
        campo === 'primeiroVencimento' &&
        (idFormaPagamento === 6 || idFormaPagamento === 7 || idFormaPagamento === 8 || idFormaPagamento === 10)
      ) {
        return true
      }
      if (campo === 'numeroParcelas' && (idFormaPagamento === 6 || idFormaPagamento === 8 || idFormaPagamento === 10)) {
        return true
      }
    }
    return false
  }
}
