import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes, icons, enums } from '../../../constants'
import { getMessage } from '../../../utils/index'
import utils from '../../../utils/index'
import './emprestimo-list.scss'

import {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onPage,
  onSort,
  onCsvExport,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../emprestimo.actions'
import { EmprestimoList } from './EmprestimoList'
import '../../../index.scss'
import _ from 'lodash'
import storage from '../../../services/stoage.service'
import LtsLookupProduto from '../../../components/lottus/lts-lookup-produto'

const mapStateToProps = (state) => {
  let tiposFiltros = utils.getFilters(storage.getAppLabels('filtros'))
  return {
    tiposFiltros,
    showHideColumns: state.crudEmprestimoState.showHideColumns,
    messages: state.crudEmprestimoState.messages,
    columns: state.crudEmprestimoState.columns,
    records: state.crudEmprestimoState.records,
    first: state.crudEmprestimoState.index,
    rows: state.crudEmprestimoState.max,
    totalRecords: state.crudEmprestimoState.count,
    sortField: state.crudEmprestimoState.sortField,
    order: state.crudEmprestimoState.sortOrder,
    selectedFilters: state.crudEmprestimoState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/emprestimos/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),

    pesquisaAvancada: (state) => {
      if (state.filtroEmissao.tipoFiltro && state.filtroEmissao.tipoFiltro.value !== null) {
        let filtroEmissao = state.filtroEmissao
        let novoFiltro = {
          campo: 'dataEmissao',
          valor: filtroEmissao.dataInicial,
          valorComplementar: filtroEmissao.dataFinal,
          tipoFiltro: filtroEmissao.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroSolicitacao.tipoFiltro && state.filtroSolicitacao.tipoFiltro.value !== null) {
        let filtroSolicitacao = state.filtroSolicitacao
        let novoFiltro = {
          campo: 'dataSolicitacao',
          valor: filtroSolicitacao.dataInicial,
          valorComplementar: filtroSolicitacao.dataFinal,
          tipoFiltro: filtroSolicitacao.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroVencimento.tipoFiltro && state.filtroVencimento.tipoFiltro.value !== null) {
        let filtroVencimento = state.filtroVencimento
        let novoFiltro = {
          campo: 'dataVencimento',
          valor: filtroVencimento.dataInicial,
          valorComplementar: filtroVencimento.dataFinal,
          tipoFiltro: filtroVencimento.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroDevolucao.tipoFiltro && state.filtroDevolucao.tipoFiltro.value !== null) {
        let filtroDevolucao = state.filtroDevolucao
        let novoFiltro = {
          campo: 'dataDevolucao',
          valor: filtroDevolucao.dataInicial,
          valorComplementar: filtroDevolucao.dataFinal,
          tipoFiltro: filtroDevolucao.tipoFiltro.value,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }
      if (state.filtroProduto && state.filtroProduto.value !== null) {
        let filtroProduto = state.filtroProduto
        const novoFiltro = {
          campo: 'produto.oid',
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['produtos'],
          valor: filtroProduto.oid,
        }
        dispatch(actions.setFiltroAvancado(novoFiltro))
      }

      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/emprestimos/all`))
      }, 400)
    },

    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class EmprestimoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      // referente a filtros avançados
      dataInicial: null,
      dataFinal: null,
      filtroEmissao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroSolicitacao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroVencimento: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroDevolucao: {
        tipoFiltro: '',
        dataInicial: '',
        dataFinal: '',
        mostrarDataInicial: false,
        mostrarDataFinal: false,
      },
      filtroProduto: false,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  changeTipoFiltro = (filtro, valor) => {
    let filtroAtual = filtro
    filtroAtual.tipoFiltro = valor
    filtroAtual.dataFinal = null
    filtroAtual.dataInicial = null
    filtroAtual.mostrarDataInicial = valor.dataInicial
    filtroAtual.mostrarDataFinal = valor.dataFinal
    return filtroAtual
  }

  changeTipoFiltroEmissao = (filtro, valor) => {
    const filtroEmissao = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroEmissao })
  }

  changeTipoFiltroSolicitacao = (filtro, valor) => {
    const filtroSolicitacao = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroSolicitacao })
  }

  changeTipoFiltroVencimento = (filtro, valor) => {
    const filtroVencimento = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroVencimento })
  }

  changeTipoFiltroDevolucao = (filtro, valor) => {
    const filtroDevolucao = this.changeTipoFiltro(filtro, valor)
    this.setState({ filtroDevolucao })
  }

  changeDataFiltro = (filtro, campo, valor) => {
    let filtroAtual = filtro
    filtroAtual[campo] = valor
  }

  pesquisaAvancada = () => {
    this.showFilter = false
    this.props.clearSelectedFilters()
    this.props.pesquisaAvancada(this.state)
  }

  showFilters = () => {
    this.showFilter = !this.showFilter
  }

  buildFiltroDataEmissao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroEmissao.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroEmissao(this.state.filtroEmissao, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              dataKey="value"
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'emprestimo.label.dataEmissao')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroEmissao.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroEmissao.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroEmissao, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroEmissao.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroEmissao.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroEmissao, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataSolicitacao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroSolicitacao.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroSolicitacao(this.state.filtroSolicitacao, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              dataKey="value"
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'emprestimo.label.dataSolicitacao')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroSolicitacao.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroSolicitacao.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroSolicitacao, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroSolicitacao.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroSolicitacao.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="emissao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroSolicitacao, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataVencimento = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroVencimento.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroVencimento(this.state.filtroVencimento, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'emprestimo.label.vencimento')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroVencimento.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroVencimento.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="vencimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroVencimento, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroVencimento.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroVencimento.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="vencimento"
                onChange={(e) => this.changeDataFiltro(this.state.filtroVencimento, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroDataDevolucao = () => {
    return (
      <div className="p-grid form-group p-align-center">
        <div className="p-col-4">
          <span className="p-float-label">
            <Dropdown
              className={'width-15-rem'}
              autoWidth={false}
              value={this.state.filtroDevolucao.tipoFiltro}
              options={this.props.tiposFiltros}
              onChange={(e) => this.changeTipoFiltroDevolucao(this.state.filtroDevolucao, e.value)}
              optionLabel="label"
              optionValue={(v) => v}
              name="tipo"
            />
            <label>{utils.getMessage(this.props.messages, 'emprestimo.label.devolucao')}</label>
          </span>
        </div>

        {
          // SE FILTRO TEM DATA INICIAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroDevolucao.mostrarDataInicial ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroDevolucao.dataInicial}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="devolucao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroDevolucao, 'dataInicial', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }

        {
          // SE FILTRO TEM DATA FINAL PARA FILTRAR MOSTRAR O CAMPO
          this.state.filtroDevolucao.mostrarDataFinal ? (
            <div className="p-col-2">
              <Calendar
                value={this.state.filtroDevolucao.dataFinal}
                yearNavigator={true}
                yearRange={utils.getYearRangeFromDate('1900')}
                monthNavigator={true}
                name="devolucao"
                onChange={(e) => this.changeDataFiltro(this.state.filtroDevolucao, 'dataFinal', e.value)}
              />
            </div>
          ) : (
            ''
          )
        }
      </div>
    )
  }

  buildFiltroProduto = () => {
    return (
      <div className="p-grid form-group">
        <div className="p-col-4">
          <LtsLookupProduto
            history={this.props.history}
            value={this.resolveValueFiltroProduto()}
            field={'value'}
            onChange={(e) => {
              const oid = _.get(e, 'oid', '')
              const filtroProduto = { oid, value: _.get(e, 'descricao', '') }
              this.setState({ filtroProduto: filtroProduto })
            }}
            label={'Equipamento'}
            filtroTipoProduto={enums.TipoProduto[1].id}
            editHidden={true}
          />
        </div>
      </div>
    )
  }

  buildAdvancedFilters = () => {
    if (this.showFilter) {
      return (
        <div>
          <br />
          {this.buildFiltroDataEmissao()}
          <br />
          {this.buildFiltroDataSolicitacao()}
          <br />
          {this.buildFiltroDataVencimento()}
          <br />
          {this.buildFiltroDataDevolucao()}
          <br />
          {this.buildFiltroProduto()}
          <br />
          <div className="p-grid form-group">
            <div className="p-col-4">
              <Button
                icon={icons.SEARCH_ICON}
                style={{ marginRight: '.25em' }}
                label={utils.getMessage(this.props.messages, 'label.pesquisar')}
                onClick={this.pesquisaAvancada}
              />
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  resolveValueFiltroProduto = () => {
    if (this.state.filtroProduto.hasOwnProperty('oid')) {
      return {
        id: _.get(this, 'state.filtroProduto.oid', null),
        value: _.get(this, 'state.filtroProduto.value', null),
      }
    }
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_EMPRESTIMO}/novo`)
  }
  componentDidMount() {
    componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={getMessage(this.props.messages, 'emprestimo.label.emprestimos')}
      />
    )
  }

  oid = (rowData) => {
    return <Link to={`${routes.PATH_EMPRESTIMO}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  booleanFieldsTemplate = (rowData, column) => {
    return null
  }

  templateColumnContrato = (rowData, column) => {
    const contrato = _.get(rowData, 'contrato_oid', null)
    return <Link to={`${routes.PATH_CONTRATO}/${contrato}`}>{contrato}</Link>
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oid}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
      }

      if (col.field === 'contrato_oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.templateColumnContrato}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="column-contrato-body-width"
            filterHeaderClassName="column-contrato-filter-header-width"
            headerClassName="column-contrato-header-width"
          />
        )
      }

      if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      }

      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }
  buildContent = () => {
    return (
      <EmprestimoList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmprestimoListPageContainer)
