import { validate } from "./validations";
import utils from "../../utils/index";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/arquivosRetornos";
const crud = build(validate, BASE_PATH);
const prefix = "crudConsultaBaixa";

const actions = new Action(prefix, crud);
const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  UNMOUNT: `${prefix}unmount`,
  RESET: `${prefix}reset`
}

const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value)=>{
  return {
      type:types.SET_BOOLEAN_FILTER,
      field,
      value
  }
}

actions.reset = () => {
    return {
        type: types.RESET
    };
};

actions.unmount = () =>{
  return {
      type:types.UNMOUNT
  }
}

actions.downloadArquivo = oidRetorno => {
    return (dispatch, getState) => {
        return apiService.get(`${BASE_PATH}/download/${oidRetorno}`).then(response => {
            utils.createAndDownloadBlobFile(response.data, "Retorno - " + oidRetorno + ".txt");
        });
    };
};

export { types, actions };
