import React from 'react'
import apiService from '../../services/api-id-value-service'
import LtsMultiselectIdValue from './lts-multiselect-id-value'
import _ from 'lodash'

export default function LtsMultiselectGrupoFinanceiro(props) {
  const afterLoadDataFn = (data) => {
    const records = _.get(data, 'records', [])
    if (_.isEmpty(records)) {
      return []
    }
    return records.map((grupo) => ({ id: grupo.oid, value: `${grupo.oid} - ${grupo.descricao}` }))
  }

  return (
    <React.Fragment>
      <LtsMultiselectIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        loadDataFn={apiService.getGruposFinanceiros}
        loadDataFnParam={props.loadDataFnParam}
        afterLoadDataFn={afterLoadDataFn}
      />
    </React.Fragment>
  )
}
