import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card'
import { LookupField } from '../../../components/lookup/LookupField'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { icons } from '../../../constants'
import _ from 'lodash'
import './ContratoForm.scss'
import '../../../index.scss'
import { StatusDocumento } from '../../documento/documento-utils'
import FormSignatario from './form-signatario'

export default function ContratoDocumentos(props) {
  const [expandedRows, setExpandedRows] = useState(null)
  const [documentoAssinaturaEdit, setDocumentoAssinaturaEdit] = useState(null)
  const [showFormSignatario, setShowFormSignatario] = useState(false)
  const [documentoNewSignatario, setDocumentoNewSignatario] = useState(null)

  useEffect(() => {
    function expandRows() {
      let expandedRows_ = []
      props.documentos.forEach((f) => {
        if (f.statusDocumento !== StatusDocumento.ASSINADO) {
          expandedRows_.push(f)
        }
      })
      setExpandedRows(expandedRows_)
    }
    expandRows()
  }, [props.documentos])

  const onHideFormSignatario = () => {
    setDocumentoAssinaturaEdit(null)
    setShowFormSignatario(false)
  }

  const onClickConfirmCallback = () => {
    props.updateDocumentos(props.documentos)
  }

  const documentoEmProcessoDeAssinatura = (documento) => {
    if (documento) {
      return documento.statusDocumento !== 'NAO_INTEGRADO' && documento.statusDocumento !== 'DESCONHECIDO'
    }
    return false
  }

  const documentoEmProcessoDeAssinaturaByAssinatura = (assinatura) => {
    const documentos = _.get(props, 'documentos', [])
    const documento = documentos.find((f) => f.oid === assinatura.oidContratoDocumento)
    if (documento) {
      return documento.statusDocumento !== 'NAO_INTEGRADO' && documento.statusDocumento !== 'DESCONHECIDO'
    }
    return false
  }

  const onClickNewSignatario = (documento) => {
    setShowFormSignatario(true)
    setDocumentoNewSignatario(documento)
  }

  const rowExpansionTemplate = (documento) => {
    const title = (
      <div className="p-col-1" style={{ display: 'inline-flex' }}>
        <div>
          <Button
            onClick={(e) => onClickNewSignatario(documento)}
            icon={icons.PLUS_ICON}
            className="p-button-success"
            disabled={documentoEmProcessoDeAssinatura(documento)}
          />
        </div>
        <h5 style={{ marginLeft: '5px', marginTop: '5px' }}>Signatários</h5>
      </div>
    )
    return (
      <Card title={title}>
        <FormSignatario
          documentoAssinaturaEdit={documentoAssinaturaEdit}
          documento={documentoNewSignatario}
          visible={showFormSignatario}
          onHide={onHideFormSignatario}
          onClickConfirmCallback={onClickConfirmCallback}
        />
        <DataTable
          value={_.get(documento, 'assinaturas', [])}
          autoLayout={true}
          selectionMode="single"
          emptyMessage="  "
          responsiveLayout="scroll"
          filterDisplay="row"
          size={'small'}
          stripedRows
        >
          <Column
            showClearButton={false}
            showFilterMenu={false}
            showFilterMenuOptions={false}
            field="status"
            header="Status"
            columnKey={'status'}
            sortable
            body={resolveStatus}
            style={{width: "5em"}}
          />
          <Column
            field="nomeEmail"
            header="Nome/E-mail"
            columnKey={'nomeEmail'}
            sortable
            style={{width: "15em"}}
          />
          <Column
            field="tipoAssinatura"
            header="Tipo Assinatura"
            columnKey={'tipoAssinatura'}
            sortable
            style={{width: "9em"}}
          />
          <Column
            field="link"
            header="Link"
            columnKey={'link'}
            sortable
            style={{width: "20em"}}
          />
          <Column
            field="dataAssinatura"
            header="Data assinatura"
            columnKey={'dataAssinatura'}
            sortable
            style={{width: "10em"}}
          />
          <Column header="Ações" body={(e) => actionSignatario(e, documento)} style={{width: '10em'}}/>
        </DataTable>
      </Card>
    )
  }

  const resolveStatus = (data) => {
    if (data.status === 'PENDENTE') {
      return (
        <div style={{ color: 'grey', display: 'flex', justifyContent: 'center' }}>
          <span title="Pendente" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
        </div>
      )
    }

    return (
      <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
        <span title="Assinado" className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
      </div>
    )
  }

  const actionSignatario = (data, documento) => {
    return (
      <React.Fragment>
        <Button
          icon={icons.PENCIL_ICON}
          tooltip={'Editar'}
          onClick={(e) => editSignatario(data, documento)}
          disabled={documentoEmProcessoDeAssinaturaByAssinatura(data)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          icon={icons.REMOVE_ICON}
          tooltip={'Remover'}
          onClick={(e) => removeSignatario(data, documento)}
          className="p-button-danger"
          disabled={documentoEmProcessoDeAssinaturaByAssinatura(data)}
        />
      </React.Fragment>
    )
  }

  const editSignatario = (data, documento) => {
    setDocumentoAssinaturaEdit(data)
    setDocumentoNewSignatario(documento)
    setShowFormSignatario(true)
  }

  const removeSignatario = (data, documento) => {
    if (documento) {
      const assinaturas = _.get(documento, 'assinaturas', [])
      documento.assinaturas = assinaturas.filter((f) => f !== data)
      onClickConfirmCallback()
    }
  }

  return (
    <React.Fragment>
      <Card title={props.titulo}>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
            <LookupField
              label={'Selecione um documento'}
              value={props.documentoTempLookup}
              name={props.name}
              field="nome"
              history={props.history}
              onKeyPress={props.onKeyPress}
              onComplete={props.onComplete}
              onInputFilter={props.onInputFilter}
              editDisabled={props.editDisabled}
              onEditField={props.onEditField}
              onClick={props.onClick}
              visible={props.visible}
              modal={props.modal}
              header={props.header}
              onHide={props.onHide}
              onConfirm={props.onConfirm}
              onCancel={props.onCancel}
              records={props.records}
              columns={props.columns}
              totalRecords={props.totalRecords}
              sortOrder={props.sortOrder}
              sortField={props.sortField}
              first={props.first}
              rows={props.rows}
              onPage={props.onPage}
              onFilter={props.onFilter}
              onSort={props.onSort}
              editHidden={props.editHidden}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12">
            <DataTable
              value={props.documentos}
              selectionMode="single"
              onPage={props.onPageDatatable}
              totalRecords={props.documentos ? props.documentos.length : 0}
              emptyMessage="  "
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              resizableColumns={true}
              columnResizeMode="expand"
              responsiveLayout="scroll"
              filterDisplay="row"
              size={'small'}
              stripedRows
            >
              <Column expander style={{ width: '2em' }} />
              {props.colunasTDocumentos}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
