import React from 'react';
import LocalizacaoListPageContainer from './LocalizacaoListPageContainer';

export class LocalizacaoListPage extends React.Component{
    
    render(){
      return (
        <LocalizacaoListPageContainer {...this.props}/>
      )
    }
}

