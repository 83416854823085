import { types } from './orcamento.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils'
import _ from 'lodash'

const initialState = {
  id: 'orcamento',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        columnSortField: 'oid',
        sortable: true,
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Emissão',
      value: {
        field: 'dataEmissao',
        header: 'Emissão',
        columnKey: 'dataEmissao',
        sortable: true,
        filter: true,
        columnSortField: 'dataEmissao',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Status',
      value: {
        field: 'status',
        header: 'Status',
        columnKey: 'status',
        sortable: true,
        filter: true,
        columnSortField: 'status',
        fieldFormatter: 'getValue',
        visible: true,
      },
    },
    {
      label: 'Valor',
      value: {
        field: 'valorTotal',
        header: 'Valor',
        columnKey: 'valorTotal',
        sortable: true,
        filter: true,
        columnSortField: 'valorTotal',
        fieldFormatter: 'formatarDinheiro',
        visible: true,
      },
    },
    {
      label: '',
      value: {
        field: 'responsavel_oid',
        header: '',
        columnKey: 'responsavel',
        columnSortField: 'responsavel.oid',
        sortable: true,
        filter: true,
        relacionamento: ['responsavel'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
    {
      label: 'Responsável',
      value: {
        field: 'responsavel_nome',
        header: 'Responsável',
        columnKey: 'responsavel',
        columnSortField: 'responsavel.nome',
        sortable: true,
        filter: true,
        relacionamento: ['responsavel'],
        fieldJoin: 'nome',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        columnKey: 'unidade.sigla',
        sortable: true,
        header: 'Unidade',
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
    {
      label: 'Tipo',
      value: {
        field: 'tipoSepultura_descricao',
        columnKey: 'tipoSepultura.descricao',
        sortable: true,
        header: 'Tipo',
        filter: true,
        columnSortField: 'tipoSepultura.descricao',
        relacionamento: ['tipoSepultura'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    dataEmissao: new Date(),
    unidade: '',
    tipoSepultura: '',
    responsavel: '',
    taxas: [],
    historicos: [],
    status: 0,
    valorTotal: 0.0,
    oidContrato: '',
    oidTaxaPrincipal: '',
    observacao: '',
  },
  messages: null,
  usaListDto: false,
  lookupResponsavel: {
    visible: false,
    modal: true,
    header: 'Responsável',
  },
  oidTipoSepulturaCapela: null,
}

const reducer = new Reducer(initialState, types)

export function CRUDOrcamentoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('religiao')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    case types.MAKE_NEW:
      return initialize(state)
    case types.SET_FIELD:
      return onSetField(state, action)
    default:
      return state
  }
}

const initialize = (state) => {
  let newState = checkUnidadeCorrente(state)
  return checkTipoSepulturaCapela(newState)
}

const checkUnidadeCorrente = (state) => {
  const currentRecord = state.currentRecord
  const currentUnit = storage.getUnit()
  if (currentUnit) {
    currentRecord.unidade = { id: currentUnit.oid, value: currentUnit.sigla }
    return Object.assign({}, state, { currentRecord: currentRecord })
  }
  return state
}

const checkTipoSepulturaCapela = (state) => {
  const units = storage.getUnits()
  const currentRecord = state.currentRecord
  const idUnidade = _.get(currentRecord, 'unidade.id', null)
  const oidTipoSepulturaCapela = utils.getOidTipoSepulturaCapela(units, idUnidade)
  return Object.assign({}, state, { oidTipoSepulturaCapela })
}

const onSetField = (state, action) => {
  const field = _.get(action, 'field', '')
  if (field === 'unidade') {
    return checkTipoSepulturaCapela(state)
  }
  return state
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
