import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { LookupField } from '../../../components/lookup/LookupField'
import utils from '../../../utils/index'

import './TaxaForm.scss'
import _ from 'lodash'
import LtsTableItensLancamento from '../../../components/lottus/lts-table-itens-lancamento'
import LtsCurrency from '../../../components/lottus/lts-currency'

export const TaxaForm = (props) => {
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler

  let vigenciaFinal = record.vigenciaFinal
  if (vigenciaFinal && typeof vigenciaFinal === 'number') {
    vigenciaFinal = new Date(vigenciaFinal)
  }

  let vigenciaInicial = record.vigenciaInicial
  if (vigenciaInicial && typeof vigenciaInicial === 'number') {
    vigenciaInicial = new Date(vigenciaInicial)
  }

  let grupoFinanceiro = ''
  if (record.grupoFinanceiro) {
    grupoFinanceiro = { id: record.grupoFinanceiro.id, descricao: record.grupoFinanceiro.value }
  }

  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>
              {utils.getMessage(messages, 'taxa.label.taxa')} {record.oid || ''}
            </h3>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                <span className="p-float-label">
                  <Dropdown
                    autoWidth={false}
                    disabled={props.disableUnit}
                    value={utils.isEmptyObject(record.unidade) ? props.unidades[0] : record.unidade}
                    options={props.unidades}
                    onChange={props.onChangeUnit}
                    optionLabel="value"
                    optionValue={(v) => v}
                    dataKey="value"
                    name="unidade"
                  />
                  <label>{utils.getMessage(messages, 'taxa.label.unidade')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={vigenciaInicial}
                    onChange={changeHandler}
                    name="vigenciaInicial"
                  />
                  <label>{utils.getMessage(messages, 'taxa.label.vigenciaInicial')}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                <span className="p-float-label">
                  <Calendar
                    yearNavigator={true}
                    yearRange={utils.getYearRangeFromDate('1970')}
                    monthNavigator={true}
                    value={vigenciaFinal}
                    onChange={changeHandler}
                    name="vigenciaFinal"
                  />
                  <label>{utils.getMessage(messages, 'taxa.label.vigenciaFinal')}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                <LtsCurrency
                  value={_.get(record, 'valor', 0)}
                  name={'valor'}
                  label={utils.getMessage(messages, 'taxa.label.valor')}
                  onChange={(e) => props.setField('valor', e)}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-8 p-md-6 p-lg-5 p-xl-4">
                <LookupField
                  label={utils.getMessage(messages, 'taxa.label.grupo')}
                  value={grupoFinanceiro}
                  name="grupoFinanceiro"
                  field="descricao"
                  history={props.history}
                  onKeyPress={props.lookupGrupoOnKeyPress}
                  onComplete={props.lookupGrupoOnComplete}
                  onInputFilter={props.lookupGrupoOnInputFilter}
                  editDisabled={props.lookupGrupo.editDisabled}
                  onEditField={props.lookupGrupoOnEdit}
                  onClick={props.lookupGrupoOnClick}
                  visible={props.lookupGrupo.visible}
                  modal={props.lookupGrupo.modal}
                  header={props.lookupGrupo.header}
                  onHide={props.lookupGrupoOnHide}
                  onConfirm={props.lookupGrupoOnConfirm}
                  onCancel={props.lookupGrupoOnCancel}
                  records={props.lookupGrupo.records}
                  columns={props.lookupGrupo.columns}
                  totalRecords={props.lookupGrupo.totalRecords}
                  sortOrder={props.lookupGrupo.order}
                  sortField={props.lookupGrupo.sortField}
                  first={props.lookupGrupo.first}
                  rows={props.lookupGrupo.rows}
                  onPage={props.lookupGrupoOnPage}
                  onFilter={props.lookupGrupoOnFilter}
                  onSort={props.lookupGrupoOnSort}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>{utils.getMessage(messages, 'taxa.label.taxacontrapartida')}</h3>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-8 p-md-6 p-lg-5 p-xl-4">
                <LookupField
                  label={utils.getMessage(messages, 'lancamentofinanceiro.label.taxa')}
                  value={record.taxa && record.taxa.hasOwnProperty('value') ? record.taxa.value : ''}
                  name="taxa"
                  field="grupoFinanceiro_descricao"
                  history={props.history}
                  itemTemplate={props.taxaDropDownTemplate}
                  onKeyPress={props.lookupTaxaOnKeyPress}
                  onComplete={props.lookupTaxaOnComplete}
                  onInputFilter={props.lookupTaxaOnInputFilter}
                  editDisabled={props.lookupTaxa.editDisabled}
                  searchDisabled={props.lookupTaxa.searchDisabled}
                  onEditField={props.lookupTaxaOnEdit}
                  onClick={props.lookupTaxaOnClick}
                  visible={props.lookupTaxa.visible}
                  modal={props.lookupTaxa.modal}
                  header={props.lookupTaxa.header}
                  onHide={props.lookupTaxaOnHide}
                  onConfirm={props.lookupTaxaOnConfirm}
                  onCancel={props.lookupTaxaOnCancel}
                  records={props.lookupTaxa.records}
                  columns={props.lookupTaxa.columns}
                  totalRecords={props.lookupTaxa.totalRecords}
                  sortOrder={props.lookupTaxa.order}
                  sortField={props.lookupTaxa.sortField}
                  first={props.lookupTaxa.first}
                  rows={props.lookupTaxa.rows}
                  onPage={props.lookupTaxaOnPage}
                  onFilter={props.lookupTaxaOnFilter}
                  onSort={props.lookupTaxaOnSort}
                />
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <label className="label">{utils.getMessage(props.messages, 'lancamentofinanceiro.label.taxas')}</label>
                <DataTable value={record.taxasContrapartidas} autoLayout={true} emptyMessage={' '}>
                  {props.colunasTaxa}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-grid form-group">
        <div className="p-col-12">
          <LtsTableItensLancamento
            history={props.history}
            idUnidade={_.get(props, 'record.unidade.id', null)}
            values={_.get(props, 'record.pacoteTaxas', [])}
            onChange={(e) => props.onChangeItensPacote(e)}
            title={'Pacote'}
          />
        </div>
      </div>
    </div>
  )
}
