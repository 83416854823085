import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import {messageActions} from "../../store/message";
const BASE_PATH = "/lottustech/configAilos";
const crud = build(validate, BASE_PATH);
const prefix = "crudConfigAilos";


const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.configurarContaAilos = (unidade) => {
  return dispatch => {
    const oidUnidade = unidade ? unidade.id : null;
    const oidEmpresa = null;
    apiService
        .post("/lottustech/ailos/obterLinkLoginAilos", {oidUnidade, oidEmpresa})
        .then(({ data }) => {
          const win = window.open(data);
          win.focus();
        })
        .catch(err => {});
  };
}

actions.showWarningMessage = message => {
    return (dispatch, getState) => {
        dispatch(messageActions.messageShowMessages(makeMessages([message], "warn")));
    };
};

const makeMessages = (messages, type) => {
    let sticky = false;
    let summary = "Sucesso!";
    if (type === "error") {
        summary = "Erro!";
        sticky = true;
    } else if (type == "warn") {
        summary = "Aviso!";
        sticky = true;
    }
    return messages.map(message => {
        return {
            sticky: sticky,
            summary: summary,
            severity: type,
            detail: message
        };
    });
};

export { types, actions };
