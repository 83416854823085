import React from "react";
import {Card} from "primereact/card";
import GrowlMessenger from "../growl/GrowlMessenger";
import "./Page.scss";

function getHeader(props) {
    if (props.header) {
        return (
            <div className="page__header">
                <GrowlMessenger/>
                <Card className="page-header-card">{props.header}</Card>
            </div>
        );
    }
    return (
        <div className="page__content">
            <GrowlMessenger/>
        </div>
    );

}

function resolveContent(content) {
    if (content) {
        return (
            <Card style={{marginBottom: "10px"}}>{content}</Card>
        )
    }
    return null;
}

export const Page = props => (
    <div className="page">
        {getHeader(props)}
        <div className="page__content">
            {/* Padrao da aplicacao */}
            {resolveContent(props.content)}

            {/* Liberacao de consulta de convenio */}
            {/* <div style={{'marginBottom': '10px'}}>
        {props.content}
      </div> */}
        </div>
    </div>
);
