import { types } from "./sexo.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  id: 'sexo',
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        columnSortField: 'oid',
        sortable: true,
        filter: true,
        visible: true
      }
    },
    {
      label: "Descrição",
      value: {
        field: "descricao",
        header: "Descrição",
        columnKey: "descricao",
        columnSortField: 'descricao',
        sortable: true,
        filter: true,
        visible: true
      }
    },
  ],
  columns: [],
  currentRecord: {
    oid: "",
    descricao: ""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDSexoReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('sexo');
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action);
    default:
      return state
  }
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: []}
}
