import React from "react";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Editor} from "primereact/editor";
import {Calendar} from "primereact/calendar";
import {enums, icons} from "../../../constants";
import utils from "../../../utils/index";

function getContent(props) {
    const anexos = props.anexos;
    if (utils.isArrayNullOrEmpty(anexos)) {
        return null;
    }
    return anexos.map(anexo => (
        <div className="p-grid">
            <div className="p-col-12 p-sm-11 p-md-11 p-lg-11 p-xl-6">
                <Button
                    type="p-button"
                    label={anexo.filename}
                    onClick={e => props.downloadAnexo(anexo)}
                    icon={icons.DOWNLOAD}
                    className="p-button-secondary"
                    disabled={isNaN(anexo.oid)}
                />
            </div>
        </div>
    ));
}

function getAnexoWrapper(props) {
    return (
        <div className="card card-w-title">
            <div>
                <h3>{utils.getMessage(props.messages, "mensageria.label.anexos")}</h3>
            </div>
            <br/>
            {getContent(props)}
        </div>
    );
}

export const MensageriaLogForm = props => {
    const record = props.record;
    const messages = props.messages;
    const headerEditor = (
        <span className="ql-formats">
      Assunto: <strong>{record.assunto}</strong>
    </span>
    );
    return (
        <div className="p-grid p-fluid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h3>
                                    {utils.getMessage(messages, "mensageria.label.mensagem")} {record.oid || ""}
                                </h3>
                                <div className="p-grid form-group">
                                    <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
                                        <label
                                            className="label">{utils.getMessage(messages, "mensageria.label.dataenvio")}</label>
                                        <Calendar
                                            value={record.dataEnvio}
                                            disabled={true}
                                            name="dataEnvio"
                                        />
                                    </div>
                                </div>
                                <div className="p-grid form-group">
                                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-5">
                                        <label
                                            className="label">{utils.getMessage(messages, "mensageria.label.usuario")}</label>
                                        <InputText value={record.usuario} name="usuario" readOnly="true"/>
                                    </div>
                                </div>
                                <div className="p-grid form-group">
                                    <div className="p-col-6 p-sm-4 p-md-4 p-lg-3 p-xl-2">
                                        <label
                                            className="label">{utils.getMessage(messages, "mensageria.label.tipomensagem")}</label>
                                        <InputText value={record.tipoMensagem} name="tipoMensagem" readOnly="true"/>
                                    </div>
                                </div>
                                <div className="p-grid form-group">
                                    <div className="p-col-12 p-sm-12 p-md-8 p-lg-6 p-xl-6">
                                        <label
                                            className="label">{utils.getMessage(messages, "mensageria.label.mensagem")}</label>
                                        <br/>
                                        <Editor
                                            style={{height: "270px"}}
                                            headerTemplate={headerEditor}
                                            value={record.mensagem}
                                            name="mensagem"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">{getAnexoWrapper(props)}</div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h3>{utils.getMessage(messages, "mensageria.label.destinatarios")}</h3>
                                <div className="p-grid form-group">
                                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                        <DataTable
                                            autoLayout={true}
                                            reorderableColumns={true}
                                            resizableColumns={true}
                                            columnResizeMode="expand"
                                            ref={props.dataTableItens}
                                            paginator={true}
                                            emptyMessage="Não foram encontrados registros"
                                            rows={15}
                                            rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                                            value={record.destinatarios}
                                        >
                                            {props.colunasItens}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
