import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { LookupField } from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";

export const LoteBoletosForm = props => {
  const messages = props.messages;
  const record = props.record;
  const changeHandler = props.changeHandler;
  const yearRange = "1970:" + window.moment().year();

  function getDadosGerais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <h3>Boletos em Lote</h3>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
                <label className="label">{utils.getMessage(messages, "boletolote.label.configuracaoboleto")}</label>
                <LookupField
                  value={
                    record.configuracaoBoleto && record.configuracaoBoleto.value ? record.configuracaoBoleto.value : ""
                  }
                  name="configuracaoBoleto"
                  field="value"
                  onComplete={props.lookupConfiguracaoBoletoOnComplete}
                  onInputFilter={props.lookupConfiguracaoBoletoOnInputFilter}
                  editDisabled={props.lookupConfiguracaoBoleto.editDisabled}
                  onEditField={props.lookupConfiguracaoBoletoOnEdit}
                  onClick={props.lookupConfiguracaoBoletoOnClick}
                  visible={props.lookupConfiguracaoBoleto.visible}
                  modal={props.lookupConfiguracaoBoleto.modal}
                  header={props.lookupConfiguracaoBoleto.header}
                  onHide={props.lookupConfiguracaoBoletoOnHide}
                  onConfirm={props.lookupConfiguracaoBoletoOnConfirm}
                  onCancel={props.lookupConfiguracaoBoletoOnCancel}
                  records={props.lookupConfiguracaoBoleto.records}
                  columns={props.lookupConfiguracaoBoleto.columns}
                  totalRecords={props.lookupConfiguracaoBoleto.totalRecords}
                  sortOrder={props.lookupConfiguracaoBoleto.order}
                  sortField={props.lookupConfiguracaoBoleto.sortField}
                  first={props.lookupConfiguracaoBoleto.first}
                  rows={props.lookupConfiguracaoBoleto.rows}
                  onPage={props.lookupConfiguracaoBoletoOnPage}
                  onFilter={props.lookupConfiguracaoBoletoOnFilter}
                  onSort={props.lookupConfiguracaoBoletoOnSort}
                />
              </div>
            </div>
            {/* <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-5 p-lg-2 p-xl-2">
                <label className="label">{utils.getMessage(messages, "boletolote.label.mesvencimento")}</label>
                <Calendar
                  locale={utils.getCalendar()}
                  value={record.vencimento}
                  onChange={changeHandler}
                  name="vencimento"
                  view="month"
                  dateFormat="mm/yy"
                  yearNavigator={true}
                  yearRange={yearRange}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  function getDadosFiltros() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <h3>Filtros</h3>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
                <label className="label">{utils.getMessage(messages, "boletolote.label.unidade")}</label>
                <Dropdown
                  autoWidth={false}
                  disabled={props.disableUnit}
                  value={record.unidade}
                  options={props.unidades}
                  onChange={changeHandler}
                  optionLabel="value"
                  optionValue={(v) => v}
                  dataKey="value"
                  name="unidade"
                />
              </div>
            </div>

            {/* LOOKUP DE TAXA */}

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4">
                <label className="label">{utils.getMessage(messages, "boletolote.label.taxa")}</label>
                <LookupField
                  value={record.taxa && record.taxa.hasOwnProperty("value") ? record.taxa.value : ""}
                  name="taxa"
                  field="grupoFinanceiro.value"
                  onComplete={props.lookupTaxaOnComplete}
                  onInputFilter={props.lookupTaxaOnInputFilter}
                  editDisabled={props.lookupTaxa.editDisabled}
                  onEditField={props.lookupTaxaOnEdit}
                  onClick={props.lookupTaxaOnClick}
                  visible={props.lookupTaxa.visible}
                  modal={props.lookupTaxa.modal}
                  header={props.lookupTaxa.header}
                  onHide={props.lookupTaxaOnHide}
                  onConfirm={props.lookupTaxaOnConfirm}
                  onCancel={props.lookupTaxaOnCancel}
                  records={props.lookupTaxa.records}
                  columns={props.lookupTaxa.columns}
                  totalRecords={props.lookupTaxa.totalRecords}
                  sortOrder={props.lookupTaxa.order}
                  sortField={props.lookupTaxa.sortField}
                  first={props.lookupTaxa.first}
                  rows={props.lookupTaxa.rows}
                  onPage={props.lookupTaxaOnPage}
                  onFilter={props.lookupTaxaOnFilter}
                  onSort={props.lookupTaxaOnSort}
                />
              </div>
            </div>

            {/* PERIODO EMISSÃO*/}

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
                <label className="label">{utils.getMessage(messages, "boletolote.label.periodoinicial")}</label>
                <Calendar
                  yearNavigator={true}
                  yearRange={yearRange}
                  monthNavigator={true}
                  value={record.periodoInicial}
                  onChange={changeHandler}
                  name="periodoInicial"
                />
              </div>
              <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
              <label className="label">{utils.getMessage(messages, "boletolote.label.periodofinal")}</label>
                  <Calendar
                    yearNavigator={true}
                    yearRange={yearRange}
                    monthNavigator={true}
                    value={record.periodoFinal}
                    onChange={changeHandler}
                    name="periodoFinal"
                  />
              </div>
            </div>

             {/* PERIODO VENCIMENTO*/}

             <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
                <label className="label">{utils.getMessage(messages, "boletolote.label.periodoinicial")}</label>
                <Calendar
                  yearNavigator={true}
                  yearRange={yearRange}
                  monthNavigator={true}
                  value={record.periodoInicialVencimento}
                  onChange={changeHandler}
                  name="periodoInicialVencimento"
                />
              </div>
              <div className="p-col-12 p-sm-12 p-md-3 p-lg-2 p-xl-2">
              <label className="label">{utils.getMessage(messages, "boletolote.label.periodofinal")}</label>
                  <Calendar
                    yearNavigator={true}
                    yearRange={yearRange}
                    monthNavigator={true}
                    value={record.periodoFinalVencimento}
                    onChange={changeHandler}
                    name="periodoFinalVencimento"
                  />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
  function getBotoes() {
    return (
      <div>
        <div className="p-grid form-group">
          <div className="p-col-12">
            <Button
              onClick={props.onClickGerar}
              label={utils.getMessage(messages, "boletolote.label.gerar")}
              style={{ marginLeft: ".25em" }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {getDadosGerais()}
      {getDadosFiltros()}
      {getBotoes()}
    </div>
  );
};
