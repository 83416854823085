import React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { acoes, enums, icons, routes } from '../../../constants'
import { actions as contratoActions } from '../../contrato/contrato.actions'
import { actions as pessoaActions } from '../../pessoa/pessoa.actions'
import { actions as taxaActions } from '../../taxa/taxa.actions'
import { actions as nfseActions } from '../../nfse/nfse.actions'
import { actions } from '../lancamento.actions'
import { LancamentoForm } from './LancamentoForm'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import utils from '../../../utils/index'
import { Filtro } from '../../../utils/Filtro.js'
import storage from '../../../services/stoage.service'
import '../../../index.scss'
import './LancamentoForm.scss'
import _ from 'lodash'
import { getColumnsLookupPessoa } from '../../../utils/lookupUtils'
import BaixarLancamentoModal from '../baixar-lancamento-modal'
import { logarAcesso } from '../../../services/api-log-acesso'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import LtsLookupGrupoFinanceiro from '../../../components/lottus/lts-lookup-grupo-financeiro'

const TIPO_DESCONTO_VALOR = 1
const TIPO_DESCONTO_PERCENTUAL = 2
const TIPO_MULTA_VALOR = 1
const TIPO_MULTA_PERCENTUAL = 2
const TIPO_JUROS_VALOR = 1
const TIPO_JUROS_PERCENTUAL = 2

let self

const mapStateToProps = (state) => {
  const messages = state.crudLancamentoState.messages
  const messagesDialog = storage.getAppLabels('dialogs')
  const record = state.crudLancamentoState.currentRecord
  const currentUser = state.appState.currentUser
  const currentCompany = storage.getCompany()
  const currentUnit = storage.getUnit()
  const units = storage.getUnits()

  let disableUnit = false
  if (currentUnit) {
    let unidade = { id: currentUnit.oid, value: currentUnit.sigla }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  const unidades = utils.getListIdValuesUnidadeDisponiveisUsuario()

  // Se estiver vazio o status
  if (!record.status) {
    Object.assign(record, { status: 1 })
  }
  const taxa = state.crudLancamentoState.currentRecord.taxa

  const pagador = state.crudLancamentoState.currentRecord.pagador
  const lookupPagadorBase = {
    records: state.crudPessoaState.records,
    columns: getColumnsLookupPessoa(state.crudPessoaState.columnsLookup),
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
    editDisabled: false,
  }
  let lookupPagador = Object.assign({}, state.crudLancamentoState.lookupPagador, lookupPagadorBase)

  const contrato = state.crudLancamentoState.currentRecord.contrato

  let displayForm = {
    pagamento: false,
    parcela: false,
    baixar: false,
    baixarParcelas: false,
    dadosBoletoIndividual: false,
    unirLancamento: false,
  }
  if (state.crudLancamentoState.displayForm) {
    displayForm = state.crudLancamentoState.displayForm
  }

  const unidadeLancamento = utils.getUnidadeFromIdValue(currentCompany, record.unidade)
  let fatorMultiplicadorConspaga = utils.getFatorMultiplicadorConspaga(currentCompany, unidadeLancamento)
  if (!fatorMultiplicadorConspaga) {
    fatorMultiplicadorConspaga = 1
  }

  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(currentCompany, unidadeLancamento)
  let formasPagamento = utils.getFormasPagamento(enums.FormasPagamento, configFormasPagamento)
  const formasPagamentoBasica = utils.getFormasPagamento(enums.FormasPagamentoBasica, configFormasPagamento)
  const servicoCartaoCreditoVindi = utils.isServicoCartaoCreditoVindi(currentCompany, unidadeLancamento)
  const servicoBoletoVindi = utils.isServicoBoletoVindi(currentCompany, unidadeLancamento)
  const exibirConspaga = utils.isExibirConspaga(currentCompany, unidadeLancamento)
  let emitirNotaFiscalServicoManual = false
  const emiteNFSe = utils.isEmiteNFSe(currentCompany, unidadeLancamento)
  if (emiteNFSe) {
    emitirNotaFiscalServicoManual = utils.isEmitirNotaFiscalServicoManual(currentCompany, unidadeLancamento)
  }

  if (!servicoCartaoCreditoVindi) {
    formasPagamento = formasPagamento.filter((fp) => fp.id !== enums.ID_CARTAO_CREDITO_VINDI)
  }
  const formaPagamentoOriginal = state.crudLancamentoState.formaPagamentoOriginal
  const isUsarWebWhatsapp = utils.isUsarWebWhatsapp(currentCompany, unidadeLancamento)
  const adicionandoAnexo = state.crudLancamentoState.adicionandoAnexo
  const contasCorrentesParcelas = state.crudLancamentoState.contasCorrentesParcelas

  return {
    formaPagamentoOriginal,
    servicoCartaoCreditoVindi,
    servicoBoletoVindi,
    exibirConspaga,
    emiteNFSe,
    emitirNotaFiscalServicoManual,
    formasPagamento,
    formasPagamentoBasica,
    fatorMultiplicadorConspaga,
    displayForm,
    disableUnit,
    unidades,
    currentUser,
    record,
    taxa,
    pagador,
    lookupPagador,
    contrato,
    messages,
    messagesDialog,
    status: [
      { label: 'Em aberto', value: 1 },
      { label: 'Pago', value: 3 },
      { label: 'Cancelado', value: 4 },
    ],
    first: state.crudLancamentoState.index,
    rows: state.crudLancamentoState.max,
    totalRecords: state.crudLancamentoState.count,
    sortField: state.crudLancamentoState.sortField,
    order: state.crudLancamentoState.sortOrder,
    idTela: state.crudLancamentoState.id,
    botoesDinamicos: state.crudLancamentoState.botoesDinamicos,
    pagamento: state.crudLancamentoState.pagamento,
    colunasPagamentos: state.crudLancamentoState.colunasPagamentos,
    selectedParcelas: state.crudLancamentoState.selectedParcelas,
    isSaving: state.crudLancamentoState.isSaving,
    currentCompany,
    currentUnit,
    units,
    stateContrato: state.crudContratoState,
    statePessoaFisica: state.crudPessoaFisicaState,
    statePessoaJuridica: state.crudPessoaJuridicaState,
    stateMarcador: state.crudMarcadorState,
    isUsarWebWhatsapp,
    descontoAplicado: state.crudLancamentoState.descontoAplicado,
    multaAplicada: state.crudLancamentoState.multaAplicada,
    jurosAplicados: state.crudLancamentoState.jurosAplicados,
    valorJurosPadrao: state.crudLancamentoState.valorJurosPadrao,
    valorMultaPadrao: state.crudLancamentoState.valorMultaPadrao,
    percentualJurosPadrao: state.crudLancamentoState.percentualJurosPadrao,
    percentualMultaPadrao: state.crudLancamentoState.percentualMultaPadrao,
    adicionandoAnexo,
    contasCorrentesParcelas,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    clearFormPagamento: () => dispatch(actions.clearFormPagamento()),
    setField: (field, value) => {
      // CASO FOR A PRIMEIRA TAXA SELECIONADA O RESTANTE DAS TAXAS DEVEM SER OD MESMO GRUPO
      if (field === 'taxas' && utils.isArrayNotEmpty(value) && value.length === 1) {
        let taxa = value[0]
        let novoFiltro = {
          campo: 'tipo',
          valor: taxa.tipo === 'ENTRADA' ? 1 : 2,
          tipoFiltro: 'IGUAL_A',
          relacionamento: ['grupoFinanceiro'],
        }
        dispatch(taxaActions.setFiltroAvancado(novoFiltro))
      } else if (field === 'taxas' && utils.isArrayNullOrEmpty(value)) {
        let novoFiltro = {
          campo: 'tipo',
          tipoFiltro: 'NAO_NULO',
          relacionamento: ['grupoFinanceiro'],
        }
        dispatch(taxaActions.setFiltroAvancado(novoFiltro))
      }
      dispatch(actions.setField(field, value))
    },
    showForm(formName, props) {
      dispatch(actions.setDisplayForm(formName, props))
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'unidade') {
        dispatch(actions.setField('unidade', valor))
      } else if (field === 'tipoFormaPagamento' && valor == null) {
        dispatch(actions.setField(field, { id: '', value: '' }))
      } else if (field === 'vencimento') {
        dispatch(actions.setField(field, valor))
      } else if (field === 'tipoFormaPagamento' && utils.nonNull(valor)) {
        dispatch(actions.setField(field, valor))
      } else if (field === 'promissoria' || field === 'jurosPorDia') {
        dispatch(actions.setField(field, target.checked))
      } else if (
        field === 'valor' ||
        field === 'valorBaixa' ||
        field === 'valorEntrada' ||
        field === 'valorDesconto' ||
        field === 'valorMulta' ||
        field === 'valorJuros' ||
        field === 'valorBruto'
      ) {
        valor = valor.replace('R$', '')
        if (typeof valor === 'string' && valor.length > 0) {
          valor = valor.trim()
        }
        dispatch(actions.setField(field, valor))
      } else if (field === 'tipoDescontoValor' || field === 'tipoDescontoPercentual') {
        dispatch(actions.setField('valorDesconto', ''))
        dispatch(actions.setField('percentualDesconto', ''))
        const tipo = field === 'tipoDescontoValor' ? 1 : 2
        dispatch(actions.setField('tipoDesconto', tipo))
      } else if (field === 'tipoMultaValor' || field === 'tipoMultaPercentual') {
        dispatch(actions.setField('valorMulta', ''))
        dispatch(actions.setField('percentualMulta', ''))
        const tipo = field === 'tipoMultaValor' ? 1 : 2
        dispatch(actions.setField('tipoMulta', tipo))
      } else if (field === 'tipoJurosValor' || field === 'tipoJurosPercentual') {
        dispatch(actions.setField('valorJuros', ''))
        dispatch(actions.setField('percentualJuros', ''))
        const tipo = field === 'tipoJurosValor' ? 1 : 2
        dispatch(actions.setField('tipoJuros', tipo))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },
    clearFormBaixa: () => {
      dispatch(actions.setField('valorPago', ''))
      dispatch(actions.setField('dataPagamento', ''))
    },
    editarPagamento: (pagamento) => {
      Object.keys(pagamento).forEach(function (key) {
        dispatch(actions.setDadoFormaPagamento(key, pagamento[key]))
      })
    },
    editarDadoFormaPagamento: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      if (field === 'valor' || field === 'valorEntrada') {
        valor = valor.replace('R$', '')
        if (typeof valor === 'string' && valor.length > 0) {
          valor = valor.trim()
        }
      }
      // para caso a pessoa mudado de forma de pagamento, mudar a promissoria para false
      if (field === 'formaPagamento' && valor.id !== 7) {
        dispatch(actions.setDadoFormaPagamento('promissoria', false))
      }
      if (field === 'valor') {
        dispatch(actions.setDadoFormaPagamento('valorParcela', valor))
      }
      if (field === 'promissoria') {
        valor = target.checked
      }
      dispatch(actions.setDadoFormaPagamento(field, valor))
    },
    removerLancamento: (lancamentoRemovido, lancamentos) => {
      let lancamentosAtuais = lancamentos
      let lancamentosRestantes = lancamentosAtuais.filter((lancamento) => {
        if (lancamentoRemovido.hash && lancamentoRemovido.hash !== lancamento.hash) {
          return lancamento
        } else if (lancamentoRemovido.oid && lancamentoRemovido.oid !== lancamento.oid) {
          return lancamento
        }
      })
      setTimeout(() => {
        dispatch(actions.setField('lancamentos', lancamentosRestantes))
      }, 300)
    },
    estornarLancamento: (oidLancamentos) => dispatch(actions.estornarLancamento(oidLancamentos)),
    setDadoFormaPagamento: (field, valor) => {
      dispatch(actions.setDadoFormaPagamento(field, valor))
    },
    adicionarPagamento: (e) => {
      dispatch(actions.gerarParcelas())
    },
    realizarBaixaLancamento: (lancamentos) => dispatch(actions.baixarLancamentos(lancamentos)),
    setSelectedParcelas: (e) => {
      dispatch(actions.setSelectedParcelas(e))
    },
    saveHandler: (event) => {
      dispatch(actions.beforeSave())
      dispatch(actions.save())
    },
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => {
      dispatch(actions.makeNew())
      dispatch(actions.clearSelectedParcelas())
      dispatch(actions.getDateTimeFromServer())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    enviarMensagem: (oid, tipoMensagem) => dispatch(actions.enviarMensagem(oid, tipoMensagem)),
    abrirWebWhatsApp: ({ oidLancamento }) => dispatch(actions.abrirWebWhatsApp({ oidLancamento })),
    lookupPagadorSetVisible: (visible) => dispatch(actions.setLookupPagadorVisible(visible)),
    lookupPagadorPaginate: (paginationParams) => dispatch(pessoaActions.paginate(paginationParams)),
    lookupPagadorSortOrder: (sortParams) => dispatch(pessoaActions.sortOrder(sortParams)),
    lookupPagadorApplyFilter: (filterParams) => {
      dispatch(pessoaActions.setFiltroAvancado(filterParams))
      dispatch(pessoaActions.loadLookup())
    },
    lookupPagadorLoad: () => dispatch(pessoaActions.loadLookup()),

    clearSelectedFilters: () => {
      dispatch(taxaActions.clearSelectedFilters())
      dispatch(contratoActions.clearSelectedFilters())
      dispatch(pessoaActions.clearSelectedFilters())
    },
    clearRecords: () => {
      dispatch(taxaActions.setRecords([]))
      dispatch(contratoActions.setRecords([]))
      dispatch(pessoaActions.setRecords([]))
    },
    baixarLancamentos: (lancamentos) => dispatch(actions.baixarLancamentos(lancamentos)),
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.FORMULARIO)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
    onClickBotaoDinamico: (listId, oidBotao) => dispatch(actions.executarFuncaoDinamica(listId, oidBotao)),
    gerarBoletoLancamento: (parcela) => dispatch(actions.gerarBoletoLancamento(parcela)),
    gerarBoletoIndividual: (params) => dispatch(actions.gerarBoletoIndividual(params)),
    gerarCarneBoleto: (params) => dispatch(actions.gerarCarneBoleto(params)),
    openUrl: (url) => dispatch(actions.openUrl(url)),
    showMessageConspagaEmDia: (ano) => dispatch(actions.showMessageConspagaEmDia(ano)),
    showMessageTaxaConservacaoJaSelecionada: () => dispatch(actions.showMessageTaxaConservacaoJaSelecionada()),
    downloadDanfe: (oidLancamento) => dispatch(nfseActions.downloadDanfSePorLancamento(oidLancamento)),
    cancelarReabrir: (oidLancamento) => dispatch(actions.cancelarReabrir(oidLancamento)),
    updateObservacaoNota: (grupos, oidContrato, ano, oidTaxa) =>
      dispatch(actions.updateObservacaoNota(grupos, oidContrato, ano, oidTaxa)),
    showWarningMessage: (message) => dispatch(actions.showWarningMessage(message)),
    executarBaixaLancamento: (params) => dispatch(actions.executarBaixaLancamento(params)),
    executarBaixaLancamentos: (params) => dispatch(actions.executarBaixaLancamentos(params)),
    unirLancamentos: (oidLancamentoAtual, oidLancamentoExcluir) =>
      dispatch(actions.unirLancamentos(oidLancamentoAtual, oidLancamentoExcluir)),
    getPacoteTaxas: (taxaSelecionada) => dispatch(actions.getPacoteTaxas(taxaSelecionada)),
    setOidTaxaPrincipal: (oidTaxa) => dispatch(actions.setOidTaxaPrincipal(oidTaxa)),
    getBoletosVindi: ({ oidLancamentoFinanceiro }) => dispatch(actions.getBoletosVindi({ oidLancamentoFinanceiro })),
    // loadCartoesPagador: (oidPagador) => dispatch(actions.loadCartoesPagador(oidPagador)),
    getDateTimeFromServer: () => dispatch(actions.getDateTimeFromServer()),
    setFormaPagamentoOriginal: (value) => dispatch(actions.setFormaPagamentoOriginal(value)),
    gerarNotaFiscalServico: (oidLancamento) => dispatch(actions.gerarNotaFiscalServico(oidLancamento)),
    findItemLancamento: (tipo, oidTipo, cobertura) => dispatch(actions.findItemLancamento(tipo, oidTipo, cobertura)),
    consultarStatusPagamentoVindi: (oidLancamentoFinanceiro) =>
      dispatch(actions.consultarStatusPagamentoVindi({ oidLancamentoFinanceiro })),
    consultarStatusPagamentoAilos: (oidLancamentoFinanceiro) =>
      dispatch(actions.consultarStatusPagamentoAilos({ oidLancamentoFinanceiro })),
    gerarCarne: (oidsLancamentoFinanceiro) => dispatch(actions.gerarCarne(oidsLancamentoFinanceiro)),
    getJurosMultaPadrao: (oidLancamentoFinanceiro) => dispatch(actions.getJurosMultaPadrao(oidLancamentoFinanceiro)),
    recalcularValorLancamento: () => dispatch(actions.recalcularValorLancamento()),
    addAnexo(anexo) {
      dispatch(actions.processaAnexo(anexo))
    },
    downloadAnexo(anexo) {
      dispatch(actions.downloadAnexo(anexo))
    },
    removeAnexo(anexo) {
      dispatch(actions.removeAnexo(anexo))
    },
    getContasCorrentesParcelas: () => dispatch(actions.getContasCorrentesParcelas()),
  }
}

class LancamentoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.state = {
      ACAO_CANCELAR_LANCAMENTOS: storage.getAcao(acoes.ACAO_CANCELAR_LANCAMENTOS),
      ACAO_UNIR_LANCAMENTOS: storage.getAcao(acoes.ACAO_UNIR_LANCAMENTOS),
      BLOQUEAR_LANCAMENTO: storage.getAcao(acoes.BLOQUEAR_LANCAMENTO),
      paramBaixarLancamento: {
        oidLancamento: '',
        valorPago: '',
        dataPagamento: '',
        formaPagamento: '',
      },
      activeIndex: 0,
      oidLancamentoUnir: '',
      showConfirmarEstornar: false,
      showDialogRemove: false,
      showConfirmarUnirLancamento: false,
      showConfirmarCancelarReabrir: false,
      showConfirmarEstornarParcelas: false,
      screenWidth: null,
    }

    this.lancamentoCancelarReabrir = null

    actions.carregarMensageria = (mensagem) => {
      props.history.push('/geral/cadastros/mensageria', { mensagem })
    }
    actions.beforeFetchRecordSuccess = (data) => {
      if (data) {
        if (data.valorEntrada) {
          data.valorEntrada = utils.formatCurrency(data.valorEntrada)
        }
        if (data.valorBaixa) {
          data.valorBaixa = utils.formatCurrency(data.valorBaixa)
        }
        if (data.lancamentos && data.lancamentos.length > 0) {
          data.lancamentos.map((lancamentoFilho) => {
            return (lancamentoFilho.valor = utils.formatCurrency(lancamentoFilho.valor))
          })
        }
        if (data.vencimento) {
          data.vencimento = utils.getDateFromNumber(data.vencimento)
        }
        if (data.dataCompetencia) {
          data.dataCompetencia = utils.getDateFromNumber(data.dataCompetencia)
        }
        if (data.dataImportacao) {
          data.dataImportacao = utils.getDateFromNumber(data.dataImportacao)
        }
        if (data.emissao) {
          data.emissao = utils.getDateFromNumber(data.emissao)
        }
        if (data.dataPagamento) {
          data.dataPagamento = utils.getDateFromNumber(data.dataPagamento)
        }
      }
      return data
    }

    actions.onResponseGetPacoteTaxas = (taxaSelecionada, pacoteTaxas) => {
      if (utils.isArrayNullOrEmpty(pacoteTaxas)) {
        this.addTaxa(taxaSelecionada)
      } else {
        let valorTaxasPacote = 0
        for (const tx of pacoteTaxas) {
          if (tx.valor) {
            valorTaxasPacote += tx.valor
          }
          this.addTaxa(tx)
        }
        if (taxaSelecionada.valor < valorTaxasPacote) {
          const desconto = valorTaxasPacote - taxaSelecionada.valor
          const e = {
            currentTarget: {
              name: 'valorDesconto',
              defaultValue: desconto,
            },
          }
          this.props.setField('tipoDesconto', 1)
          this.onBlurValorDescontoAcrescimo(e)
        }
      }
      setTimeout(() => {
        this.props.setOidTaxaPrincipal(taxaSelecionada.oid)
      }, 200)
    }
    actions.afterSave = (data) => {
      this.props.history.replace(`${routes.PATH_LANCAMENTO_FINANCEIRO}/${data.oid}`)
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_LANCAMENTO_FINANCEIRO}/novo`)
      this.props.clearSelectedFilters()
      this.resolveUnidadeMatriz()
      // caso tenha vindo de um contrato deve pegar os seguintes parametros
      if (utils.nonNull(this.props.location.state)) {
        if (utils.nonNull(this.props.location.state.contrato)) {
          this.props.setField('conspaga', this.props.location.state.conspaga)
          this.props.setField('contrato', this.props.location.state.contrato)
        }
        if (utils.nonNull(this.props.location.state.responsavel)) {
          this.props.setField('pagador', this.props.location.state.responsavel)
        }
        if (utils.nonNull(this.props.location.state.tipoFormaPagamento)) {
          this.props.setField('tipoFormaPagamento', this.props.location.state.tipoFormaPagamento)
          this.props.setField('valorBruto', this.props.location.state.valor)
          this.props.setField('codigoAgencia', this.props.location.state.codigoAgencia)
          this.props.setField('codigoConta', this.props.location.state.codigoConta)

          const dataCompetencia = utils.getDateFromNumber(this.props.location.state.dataCompetencia)
          this.props.setField('dataCompetencia', dataCompetencia)

          if (this.props.location.state.unidadeConsumidora && dataCompetencia) {
            this.props.setField(
              'observacao',
              this.props.location.state.unidadeConsumidora +
                '-' +
                (dataCompetencia.getMonth() + 1) +
                '/' +
                dataCompetencia.getFullYear()
            )
          }
          if (this.props.location.state.coberturas && this.props.location.state.coberturas.length > 0) {
            for (const cobertura of this.props.location.state.coberturas) {
              this.props.findItemLancamento(cobertura.itemLancamento.tipo, cobertura.itemLancamento.oidTipo, cobertura)
            }
          }
          this.recalcularValorLancamento()
        }
      }
    }

    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_LANCAMENTO_FINANCEIRO}`)
    }

    actions.afterLoad = () => {
      const tipoFormaPagamento = _.get(this, 'props.record.tipoFormaPagamento', null)
      if (tipoFormaPagamento) {
        this.props.setFormaPagamentoOriginal(tipoFormaPagamento)
      }
      this.props.getJurosMultaPadrao(_.get(this, 'props.record.oid', null))

      this.calcularValorParcela()
      this.ordenarArrayParcelas()
    }

    this.setField = props.setField
    self = this
  }

  addTaxa = (newTaxa) => {
    let taxasAtuais = this.props.record.taxas
    const units = _.get(this, 'props.units', storage.getUnits())

    const isTaxaConservacao = utils.isTaxaConservacao(units, this.props.record.unidade.id, newTaxa.grupoFinanceiro_oid)

    let taxa = {
      taxa: {
        id: newTaxa.oid,
        value: newTaxa.grupoFinanceiro_descricao,
      },
      oidGrupo: newTaxa.grupoFinanceiro_oid,
      value: newTaxa.grupoFinanceiro_descricao,
      valor: utils.formatCurrency(newTaxa.valor),
      tipo: newTaxa.grupoFinanceiro_tipo,
      isTaxaConservacao: isTaxaConservacao,
    }

    if (isTaxaConservacao) {
      this.updateTaxaCamposConspaga(this.props.record, taxa)
    } else {
      taxa.valorTotal = utils.formatCurrency(newTaxa.valor)
    }

    taxasAtuais.push(taxa)
    this.updateObservacaoNota([taxa])
    this.props.setField('taxas', taxasAtuais)
    this.adicionarValorTaxa(taxa)
    const habilitou = this.setFlagHabilitarCamposConspaga(taxasAtuais)
    this.updateCamposConspaga(habilitou, this.props.record)
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
      this.props.setField('status', 1)
      const unidade = _.get(this, 'props.currentUnit', storage.getUnit())
      if (utils.nonNull(this.props.location.state) && utils.nonNull(this.props.location.state.unidade)) {
        this.props.setField('unidade', this.props.location.state.unidade)
      } else if (!utils.nonNull(unidade)) {
        this.resolveUnidadeMatriz()
      }
      this.props.getDateTimeFromServer()
    }
    this.props.getContasCorrentesParcelas()
    // this.props.window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth })
  }

  resolveUnidadeMatriz() {
    const unidade = utils.getIdValueUnidadeMatriz(_.get(this, 'props.currentCompany', storage.getCompany()))
    this.props.setField('unidade', unidade)
  }

  componentDidUpdate(anteriorProps) {
    if (anteriorProps.record.oidUnidade === '' && this.props.record.unidade) {
      Object.assign(this.props.record, {
        oidUnidade: this.props.record.unidade.oid,
      })
      Object.assign(this.props.record, {
        descricaoUnidade: this.props.record.unidade.descricao,
      })
    }
    if (
      anteriorProps.record.vencimento !== this.props.record.vencimento &&
      utils.nonNull(this.props.record.vencimento) &&
      this.props.record.oid === ''
    ) {
      // se alterada data de vencimento, ele já verifica se estaria com uma
      // data anterior, no caso um título vencido
      const emissao = _.get(this, 'props.record.emissao')
      const vencimento = _.get(this, 'props.record.vencimento')
      const isDateEmissao = utils.isDate(emissao)
      const isDateVencimento = utils.isDate(vencimento)
      const canValidateDatas = isDateEmissao && isDateVencimento && emissao && vencimento
      if (canValidateDatas && window.moment(emissao).isAfter(vencimento, 'day')) {
        this.props.showWarningMessage('Data de vencimento anterior a data de emissão.')
      } else {
        if (this.props.record.tipoFormaPagamento !== 1) {
          this.props.setField('status', 1)
        }
      }
    }
  }

  searchHandler = () => {
    this.props.newHandler()
    this.props.history.push(`${routes.PATH_LANCAMENTO_FINANCEIRO}`)
  }

  ordenarArrayParcelas = () => {
    let lancamentosOrdenados = utils.orderArrayByProperty(this.props.record.lancamentos, 'vencimento')
    if (!utils.isArrayNullOrEmpty(lancamentosOrdenados)) {
      this.props.setField('lancamentos', lancamentosOrdenados)
    }
  }

  onSelectionChange = (e) => {
    this.props.setSelectedParcelas(e.value)
  }

  editarNumeroParcelas = (e) => {
    this.props.editarDadoFormaPagamento(e)
    setTimeout(() => {
      this.calcularValorParcela()
    }, 200)
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  //TODO - verificar se ainda e usado - alterado para ltsCurrency
  onBlurValorDescontoAcrescimo = (e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    if (typeof valor === 'string') {
      this.props.setField(field, utils.formatCurrency(valor.replace(',', '.').replace('R$', '')))
    }
    let valorBruto = utils.parseStringToNumber(this.props.record.valorBruto)
    let valorLancamento = this.calcularValorTotal(valorBruto)
    if (typeof valorLancamento === 'string') {
      valorLancamento = utils.parseStringToNumber(valorLancamento)
    }
    this.props.setField('valor', valorLancamento)
  }

  onBlurValor = (e) => {
    // let field = e.currentTarget.name
    // let valor = e.currentTarget.defaultValue
    // if (typeof valor === 'number') {
    //   valor = String(valor)
    // }
    // if (typeof valor === 'string') {
    //   this.props.setField(field, utils.formatCurrency(valor.replace(',', '.').replace('R$', '')))
    // }
    this.calcularValorParcela()
  }

  onBlurValorFormaPagamento = (lancamento, e) => {
    let field = e.currentTarget.name
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    lancamento[field] = utils.formatCurrency(valor.replace(',', '.').replace('R$', ''))
    this.calcularValorParcela()
  }

  onBlurValorParcela = (e) => {
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    this.props.pagamento.valorParcela = utils.formatCurrency(valor.replace(',', '.').replace('R$', ''))
  }

  onBlurNumeroParcelas = (e) => {
    let numeroParcelas = e.currentTarget.defaultValue
    this.calcularParcelas(numeroParcelas, this.props.record.valorEntrada, this.props.record.valor)
  }

  //metodo chamado somennte ao alterar campos que impactem o valor;
  // TODO - verificar se ainda e usado
  changeHandlerValorLancamento = (e) => {
    this.props.changeHandler(e)
    let valorBruto = utils.parseStringToNumber(this.props.record.valorBruto)
    let valorTotalLancamento = this.calcularValorTotal(valorBruto)
    if (typeof valorTotalLancamento === 'string') {
      valorTotalLancamento = utils.parseStringToNumber(valorTotalLancamento)
    }
    this.props.setField('valor', valorTotalLancamento)
  }

  calcularPercentual = (valor, percentual) => {
    if (valor && percentual) {
      let temp = valor * percentual
      return (temp /= 100)
    }
    return 0
  }

  onChangeJurosDia = (e) => {
    this.props.setField('valorJuros', 0)
    this.props.setField('percentualJuros', 0)
    const checked = _.get(e, 'target.checked', false)
    this.props.setField('jurosPorDia', checked)
    this.recalcularValorLancamento()
  }

  recalcularValorLancamento = () => {
    setTimeout(() => {
      this.props.recalcularValorLancamento()
    }, 200)
  }

  onChangeTipoDesconto = (event) => {
    const name = _.get(event, 'target.name', '')
    const value = name === 'tipoDescontoValor' ? TIPO_DESCONTO_VALOR : TIPO_DESCONTO_PERCENTUAL
    this.props.setField('tipoDesconto', value)
    this.props.setField('valorDesconto', 0)
    this.props.setField('percentualDesconto', 0)
    this.recalcularValorLancamento()
  }

  onChangeValorDesconto = (value) => {
    if (value < 0) {
      value = 0
    }
    this.props.setField('valorDesconto', value)
    this.props.setField('percentualDesconto', 0)
    this.recalcularValorLancamento()
  }

  onChangePercentualDesconto = (value) => {
    if (value < 0) {
      value = 0
    }
    this.props.setField('percentualDesconto', value)
    this.props.setField('valorDesconto', 0)
    this.recalcularValorLancamento()
  }

  onChangeTipoMulta = (event) => {
    const name = _.get(event, 'target.name', '')
    const value = name === 'tipoMultaValor' ? TIPO_MULTA_VALOR : TIPO_MULTA_PERCENTUAL
    this.props.setField('tipoMulta', value)
    this.props.setField('valorMulta', this.props.valorMultaPadrao)
    this.props.setField('percentualMulta', this.props.percentualMultaPadrao)
    this.recalcularValorLancamento()
  }

  onChangeValorMulta = (value) => {
    if (value < 0) {
      value = 0
    }
    this.props.setField('valorMulta', value)
    this.props.setField('percentualMulta', 0)
    this.recalcularValorLancamento()
  }

  onChangePercentualMulta = (value) => {
    if (value < 0) {
      value = 0
    }
    this.props.setField('percentualMulta', value)
    this.props.setField('valorMulta', 0)
    this.recalcularValorLancamento()
  }

  onChangeTipoJuros = (event) => {
    const name = _.get(event, 'target.name', '')
    const value = name === 'tipoJurosValor' ? TIPO_JUROS_VALOR : TIPO_JUROS_PERCENTUAL
    this.props.setField('tipoJuros', value)
    this.props.setField('valorJuros', this.props.valorJurosPadrao)
    this.props.setField('percentualJuros', this.props.percentualJurosPadrao)
    this.recalcularValorLancamento()
  }

  isJurosPorDiaLancamentoVencido = () => {
    const jurosDia = _.get(this, 'props.record.jurosPorDia', false)
    const diasAtraso = this.getDiasEmAtraso()
    return jurosDia && diasAtraso > 0
  }

  isJurosPorDiaLancamentoNaoVencido = () => {
    const jurosDia = _.get(this, 'props.record.jurosPorDia', false)
    const diasAtraso = this.getDiasEmAtraso()
    return jurosDia && diasAtraso <= 0
  }

  onChangeValorJuros = (value) => {
    if (value < 0) {
      value = 0
    }
    const jurosPorDiaLancamentoNaoVencido = this.isJurosPorDiaLancamentoNaoVencido()
    if (jurosPorDiaLancamentoNaoVencido) {
      value = 0
    }
    setTimeout(() => {
      this.props.setField('valorJuros', value)
      this.props.setField('percentualJuros', 0)
      this.recalcularValorLancamento()
    }, 150)
  }

  onChangePercentualJuros = (value) => {
    if (value < 0) {
      value = 0
    }
    this.props.setField('percentualJuros', value)
    this.props.setField('valorJuros', 0)
    this.recalcularValorLancamento()
  }

  calcularParcelas = (nr, ve, vl) => {
    let valorLancamento = utils.parseCurrency(vl)
    let numeroParcelas = utils.parseCurrency(nr)
    if (valorLancamento > 0) {
      let valorRestante = valorLancamento
      let valorEntrada = utils.parseCurrency(ve)
      if (utils.isNumber(valorEntrada)) {
        valorRestante = valorLancamento - valorEntrada
      }
      if (valorRestante > 0) {
        if (numeroParcelas && numeroParcelas > 0) {
          let parcelamento = utils.getValorParcelas(valorRestante, numeroParcelas)
          this.props.setField('valorParcela', parcelamento.parcelas)
        }
      }
    }
  }

  adicionarValorTaxa = (taxa) => {
    const valorTaxa = utils.parseStringToNumber(taxa.valor)
    if (utils.nonNull(taxa) && utils.nonNull(valorTaxa) && utils.isNumber(valorTaxa)) {
      let valorBruto = this.getValorTaxas()
      this.props.setField('valorBruto', utils.formatCurrency(valorBruto))
      let valorTotalLancamento = this.calcularValorTotal(utils.parseStringToNumber(valorBruto))
      if (typeof valorTotalLancamento === 'string') {
        valorTotalLancamento = utils.parseStringToNumber(valorTotalLancamento)
      }
      this.props.setField('valor', utils.formatCurrency(valorTotalLancamento))
    }
  }

  subtrairValorTaxa = (taxa) => {
    if (utils.nonNull(taxa) && utils.nonNull(taxa.valorTotal)) {
      if (typeof taxa.valorTotal === 'string') {
        taxa.valorTotal = utils.parseStringToNumber(taxa.valorTotal)
      }
      const units = _.get(this, 'props.units', storage.getUnits())
      let taxaConservacao = utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)

      if (taxaConservacao) {
        taxa.valor = this.calcularValorConspaga(0, this.props.record.conspagaPagarAte)
      }
      if (utils.isNumber(taxa.valorTotal)) {
        let valorLancamento = this.props.record.valor
        if (typeof valorLancamento === 'string') {
          valorLancamento = utils.parseStringToNumber(valorLancamento)
        }
        valorLancamento -= taxa.valorTotal
        if (valorLancamento < 0) {
          valorLancamento = 0
        }
        this.props.setField('valor', utils.formatCurrency(valorLancamento))
        this.props.setField('valorBruto', utils.formatCurrency(valorLancamento))
      }
    }
  }

  aplicarValorConspaga = (valorLancamento) => {
    let result = valorLancamento
    if (utils.isArrayNotEmpty(this.props.record.taxas)) {
      const units = _.get(this, 'props.units', storage.getUnits())
      const idUnidade = this.props.record.unidade.id
      const taxaConservacao = this.props.record.taxas.find((taxa) => {
        return utils.isTaxaConservacao(units, idUnidade, taxa.oidGrupo)
      })

      if (taxaConservacao) {
        const valorTaxa = utils.parseStringToNumber(taxaConservacao.valor)
        const valorLancamentoSemTaxa = valorTaxa

        const anos = this.props.record.conspagaPagarAte - this.props.record.conspagaUltimoPagamento
        if (anos > 0) {
          const valorConservacaoAtualizado = valorTaxa * anos
          if (valorConservacaoAtualizado && valorConservacaoAtualizado > 0) {
            result = valorLancamentoSemTaxa + valorConservacaoAtualizado
          }
        }
      }
    }
    return result
  }

  calcularValorTaxaConspaga = (valorTaxaConservacao, ateAnoConspaga, conspagaUltimoPagamento) => {
    const valorTaxa = utils.parseStringToNumber(valorTaxaConservacao)
    const anos = ateAnoConspaga - conspagaUltimoPagamento
    return this.executeCalculaTaxaConservacao(anos, valorTaxa, valorTaxaConservacao)
  }

  calcularValorConspaga = (valorLancamento, ateAnoConspaga) => {
    let result = valorLancamento
    if (utils.isArrayNotEmpty(this.props.record.taxas)) {
      const taxaConservacao = this.props.record.taxas.find((taxa) => {
        const units = _.get(this, 'props.units', storage.getUnits())
        return utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)
      })

      if (taxaConservacao) {
        const valorTaxa = utils.parseStringToNumber(taxaConservacao.valor)
        let anos = 0
        if (!this.props.record.conspagaUltimoPagamento) {
          return valorTaxa
        } else {
          anos = ateAnoConspaga - this.props.record.conspagaUltimoPagamento
        }
        return this.executeCalculaTaxaConservacao(anos, valorTaxa, valorLancamento)
      }
    }
    return result
  }

  executeCalculaTaxaConservacao = (anos, valorTaxa, valorDefault) => {
    if (anos > 0) {
      if (this.props.fatorMultiplicadorConspaga === 1) {
        const valorConservacaoAtualizado = valorTaxa * anos
        if (valorConservacaoAtualizado && valorConservacaoAtualizado > 0) {
          return valorConservacaoAtualizado
        }
      } else {
        const aux = anos / this.props.fatorMultiplicadorConspaga
        if (aux > 0) {
          const valorConservacaoAtualizado = valorTaxa * aux
          if (valorConservacaoAtualizado && valorConservacaoAtualizado > 0) {
            return valorConservacaoAtualizado
          }
        }
      }
    }
    return valorDefault
  }

  onBlurConspagaPagarAte = (e) => {
    const taxaConservacao = this.props.record.taxas.find((taxa) => {
      const units = _.get(this, 'props.units', storage.getUnits())
      return utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)
    })
    this.updateObservacaoNota([taxaConservacao])
  }
  onChangeHandlerConspagaPagarAte = (taxa, e) => {
    const target = e.target
    const field = target.name
    const conspagaPagarAte = _.defaultTo(parseInt(target.value), 0)
    taxa[field] = conspagaPagarAte
    this.setState({ atualizar: true })
    let valorLancamento = utils.parseStringToNumber(this.props.record.valorBruto)
    if (!this.props.record.lancamentoOrigem || parseInt(this.props.record.lancamentoOrigem) === 0) {
      let valorTotal = this.calcularValorConspaga(valorLancamento, conspagaPagarAte)
      taxa.valorTotal = utils.formatCurrency(valorTotal)
      valorTotal += this.somarValorTaxasSemConservacao()
      let valor = utils.formatCurrency(valorTotal)
      this.props.setField('valorBruto', valor)
      let valorTotalLancamento = this.calcularValorTotal(utils.parseStringToNumber(valor))
      if (typeof valorTotalLancamento === 'string') {
        valorTotalLancamento = utils.parseStringToNumber(valorTotalLancamento)
      }
      this.props.setField('valor', utils.formatCurrency(valorTotalLancamento))
    }
  }

  changeHandlerFormaPagamento = (event) => {
    const target = event.target
    const id = _.get(target, 'value.id', -1)
    // caso a forma de pagamento for 6 - dinheiro, 7 - Credito ou 8 - Debito, deve sugerir a data de vencimento para hoje.
    if ((id === 6 || id === 7 || id === 8) && !this.props.record.vencimento) {
      this.props.setField('vencimento', new Date())
    }
    this.props.changeHandler(event)
    // const oidPagador = _.get(this, 'props.record.pagador.id', null)
    // if (id === enums.ID_CARTAO_CREDITO_VINDI && oidPagador) {
    //   this.props.loadCartoesPagador(oidPagador)
    // }
  }

  somarValorTaxasSemConservacao = () => {
    let valorTotal = 0
    const taxas = _.get(this, 'props.record.taxas', [])
    if (utils.isArrayNotEmpty(taxas)) {
      const taxaConservacao = taxas.find((taxa) => {
        const units = _.get(this, 'props.units', storage.getUnits())
        return utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)
      })

      taxas.forEach((t) => {
        if (t.taxa.id !== taxaConservacao.taxa.id) {
          valorTotal += utils.parseStringToNumber(t.valor)
        }
      })
    }
    return valorTotal
  }

  calcularValorTotal = (valorBruto) => {
    let valorLancamento = valorBruto
    let descontoAplicado = 0
    let tipoDesconto = _.get(this, 'props.record.tipoDesconto', 0)
    if (valorLancamento > 0 && parseInt(tipoDesconto) > 0) {
      if (tipoDesconto === 1) {
        // quer dizer que é em R$
        let valorDesconto = utils.parseStringToNumber(this.props.record.valorDesconto)
        if (utils.nonNull(valorDesconto) && valorDesconto > 0) {
          descontoAplicado = valorDesconto
        }
      } else if (tipoDesconto === 2) {
        // quer dizer que é em percentual
        let percentualDesconto = utils.parseStringToNumber(this.props.record.percentualDesconto)
        if (utils.nonNull(percentualDesconto) && percentualDesconto > 0) {
          let desconto = valorLancamento * percentualDesconto
          desconto = desconto /= 100
          descontoAplicado = desconto
        }
      }
    }

    let multaAplicada = 0
    let tipoMulta = this.props.record.tipoMulta
    if (valorLancamento > 0 && parseInt(tipoMulta) > 0) {
      if (tipoMulta === 1) {
        // quer dizer que é em R$
        let valorMulta = utils.parseStringToNumber(this.props.record.valorMulta)
        if (utils.nonNull(valorMulta) && valorMulta > 0) {
          multaAplicada = valorMulta
        }
      } else if (tipoMulta === 2) {
        // quer dizer que é em percentual
        let percentualMulta = utils.parseStringToNumber(this.props.record.percentualMulta)
        if (utils.nonNull(percentualMulta) && percentualMulta > 0) {
          let valorPercentualMulta = valorLancamento * percentualMulta
          valorPercentualMulta = valorPercentualMulta /= 100
          multaAplicada = valorPercentualMulta
        }
      }
    }

    let jurosAplicados = 0
    let tipoJuros = _.get(this, 'props.record.tipoJuros', 0)
    if (valorLancamento > 0 && parseInt(tipoJuros) > 0) {
      if (tipoJuros === 1) {
        // quer dizer que é em R$
        let valorJuros = utils.parseStringToNumber(this.props.record.valorJuros)
        if (utils.nonNull(valorJuros) && valorJuros > 0) {
          jurosAplicados = valorJuros
          if (this.props.record.jurosPorDia) {
            jurosAplicados *= this.getDiasEmAtraso()
          }
        }
      } else if (tipoJuros === 2) {
        // quer dizer que é em percentual
        let percentualJuros = utils.parseStringToNumber(this.props.record.percentualJuros)
        if (utils.nonNull(percentualJuros) && percentualJuros > 0) {
          let valorPercentualJuros = valorLancamento * percentualJuros
          valorPercentualJuros = valorPercentualJuros /= 100
          jurosAplicados = valorPercentualJuros
          if (this.props.record.jurosPorDia) {
            jurosAplicados *= this.getDiasEmAtraso()
          }
        }
      }
    }
    this.setState({ multaAplicada: utils.formatCurrency(multaAplicada) })
    this.setState({ jurosAplicados: utils.formatCurrency(jurosAplicados) })
    this.setState({ descontoAplicado: utils.formatCurrency(descontoAplicado) })
    let valorAtualizado = valorLancamento + (multaAplicada + jurosAplicados - descontoAplicado)
    if (valorAtualizado < 0) {
      valorAtualizado = 0
    }

    return utils.formatCurrency(valorAtualizado)
  }

  getDiasEmAtraso = () => {
    let diasEmAtraso = 1
    const dataVencimentoLancamento = this.props.record.vencimento
    if (dataVencimentoLancamento && utils.isDate(dataVencimentoLancamento)) {
      const dataAtual = window.moment()
      diasEmAtraso = dataAtual.diff(dataVencimentoLancamento, 'days')
    }
    return diasEmAtraso
  }
  getValorTaxas = () => {
    let taxas = this.props.record.taxas
    let valorTotal = 0
    if (taxas && utils.isArrayNotEmpty(taxas)) {
      taxas.forEach((taxa) => {
        let valorTaxa = taxa.valorTotal
        if (utils.nonNull(valorTaxa)) {
          if (typeof valorTaxa === 'string') {
            valorTaxa = utils.parseStringToNumber(valorTaxa)
          }
          if (valorTaxa > 0 && utils.isNumber(valorTaxa)) {
            valorTotal += valorTaxa
          }
        }
      })
    }
    return valorTotal
  }

  resolveOidUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    return utils.isIdValueIdValido(idValueUnidade) ? idValueUnidade.id : null
  }

  resolveFiltroUnidade = () => {
    const idValueUnidade = this.props.record.unidade
    if (utils.isIdValueIdValido(idValueUnidade)) {
      return {
        campo: 'unidade.oid',
        valor: idValueUnidade.id,
        tipoFiltro: 'IGUAL_A',
        relacionamento: ['unidade'],
      }
    }
    return null
  }

  verificarTaxaJaSelecionada = (taxaSelecionada) => {
    let existente = false
    const taxas = this.props.record.taxas
    if (taxas && taxas.length > 0) {
      taxas.forEach((t) => {
        if (t.taxa && t.taxa.id === taxaSelecionada.oid) {
          existente = true
        }
      })
    }
    if (existente) {
      return existente
    }
    return this.isTaxaConservacaoJaSelecionada(taxaSelecionada)
  }

  isTaxaConservacaoJaSelecionada = (taxaSelecionada) => {
    const units = _.get(this, 'props.units', storage.getUnits())
    const isTaxaConservacao = utils.isTaxaConservacao(
      units,
      this.props.record.unidade.id,
      taxaSelecionada.grupoFinanceiro_oid
    )
    if (isTaxaConservacao) {
      const taxas = this.props.record.taxas
      if (taxas && taxas.length > 0) {
        for (const t of taxas) {
          const isTaxaConservacaoJaSelecionada = utils.isTaxaConservacao(
            units,
            this.props.record.unidade.id,
            t.oidGrupo
          )
          if (isTaxaConservacaoJaSelecionada) {
            this.props.showMessageTaxaConservacaoJaSelecionada()
            return true
          }
        }
      }
    }
    return false
  }

  isTaxaEntradaCaixaSelecionada = (taxas) => {
    if (utils.isArrayNullOrEmpty(taxas)) {
      return false
    }

    const units = _.get(this, 'props.units', storage.getUnits())
    const currentCompany = _.get(this, 'props.currentCompany', storage.getCompany())
    const unitId = this.props.record.unidade.id
    let result = false
    taxas.forEach((t) => {
      const isTaxaEntradaCaixa = utils.isTaxaEntradaCaixa(units, unitId, t.oidGrupo, currentCompany)

      if (isTaxaEntradaCaixa) {
        result = true
      }
    })

    return result
  }

  updateObservacaoNota = (taxas) => {
    const grupos = taxas.map((f) => f.oidGrupo)
    let oidContrato = null
    let taxa = taxas[0]
    if (utils.isIdValueIdValido(this.props.record.contrato)) {
      oidContrato = this.props.record.contrato.id
    }
    let oidTaxa = 0
    if (taxa.taxa && taxa.taxa.id) {
      oidTaxa = taxa.taxa.id
    }

    const units = _.get(this, 'props.units', storage.getUnits())
    const isTaxaConservacao = utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)
    let ano = window.moment(this.props.record.emissao).year()
    if (isTaxaConservacao) {
      ano = taxa.conspagaPagarAte
    }
    this.props.updateObservacaoNota(grupos, oidContrato, ano, oidTaxa)
  }

  setFlagHabilitarCamposConspaga = (taxas) => {
    //seta a flag que vai habilitar os campos 'conspagaUltimoPagamento' e 'conspagaPagarAte'
    let result = false

    if (utils.isArrayNotEmpty(taxas)) {
      const units = _.get(this, 'props.units', storage.getUnits())
      taxas.forEach((taxa) => {
        const isTaxaConservacao = utils.isTaxaConservacao(units, this.props.record.unidade.id, taxa.oidGrupo)
        if (isTaxaConservacao) {
          result = true
        }
      })
    }
    this.props.setField('habilitarCamposConspaga', result)
    return result
  }

  updateCamposConspaga = (habilitar, record) => {
    let conspagaUltimoPagamento = ''
    let conspagaPagarAte = ''
    if (habilitar && record) {
      const anoCorrente = window.moment().year()
      let conspaga = 0
      if (!record.conspaga || parseInt(record.conspaga) === 0) {
        //CONTRATO NOVO - SEM CONSPAGA
        conspaga = anoCorrente // SUGERIMOS ANO ATUAL
      } else {
        conspaga = parseInt(record.conspaga)
      }
      conspagaUltimoPagamento = conspaga
      conspagaPagarAte = this.resolveConspagaPagarAte(conspaga, anoCorrente)
    }

    this.props.setField('conspagaUltimoPagamento', conspagaUltimoPagamento)
    this.props.setField('conspagaPagarAte', conspagaPagarAte)
  }

  updateTaxaCamposConspaga = (record, taxa) => {
    let conspagaUltimoPagamento = ''
    let conspagaPagarAte = ''
    if (record && taxa) {
      const anoCorrente = window.moment().year()
      let conspaga
      if (!record.conspaga || parseInt(record.conspaga) === 0) {
        //CONTRATO NOVO - SEM CONSPAGA
        conspaga = anoCorrente // SUGERIMOS ANO ATUAL
      } else {
        conspaga = parseInt(record.conspaga)
      }
      conspagaUltimoPagamento = conspaga
      if (conspaga >= anoCorrente) {
        this.props.showMessageConspagaEmDia(conspaga)
      }
      conspagaPagarAte = this.resolveConspagaPagarAte(conspaga, anoCorrente)
    }

    taxa.conspagaUltimoPagamento = conspagaUltimoPagamento
    taxa.conspagaPagarAte = conspagaPagarAte
    taxa.valorTotal = utils.formatCurrency(
      this.calcularValorTaxaConspaga(taxa.valor, conspagaPagarAte, conspagaUltimoPagamento)
    )
  }

  resolveConspagaPagarAte = (conspagaAtual, anoCorrente) => {
    if (conspagaAtual >= anoCorrente) {
      return conspagaAtual + this.props.fatorMultiplicadorConspaga
    }
    if (this.props.fatorMultiplicadorConspaga === 1) {
      return anoCorrente
    }
    let result = conspagaAtual
    while (result < anoCorrente) {
      result += this.props.fatorMultiplicadorConspaga
    }
    return result
  }

  //REFERENTE AO LOOKUP DE PAGADOR
  lookupPagadorOnPage = (e) => {
    this.props.lookupPagadorPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPagadorOnSort = (e) => {
    this.props.lookupPagadorSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPagadorOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        const filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupPagadorApplyFilter(filter)
        isFilterApplied = true
      }
    })

    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupPagadorLoad()
    }
  }

  lookupPagadorOnClick = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupPagadorLoad()
    this.props.lookupPagadorSetVisible(true)
  }
  lookupPagadorOnHide = (e) => {
    this.props.lookupPagadorSetVisible(false)
  }
  lookupPagadorOnConfirm = (e) => {
    this.setFieldPagador(e.selection.oid, e.selection.nome, e.selection.numeroDocumento)
    this.props.lookupPagadorSetVisible(false)
    this.checkDocumentoPagador(e.selection.numeroDocumento)
  }

  setFieldPagador = (oid, nome, numeroDocumento) => {
    this.props.setField('pagador.id', oid)
    this.props.setField('pagador.value', nome)
    if (numeroDocumento && numeroDocumento.length > 11) {
      this.props.setField('pagadorPessoaFisica', false)
    } else {
      this.props.setField('pagadorPessoaFisica', true)
    }
  }

  checkDocumentoPagador = (value) => {
    if (!value || value === '00000000000' || value === '00000000000000') {
      this.props.showWarningMessage(
        'O pagador selecionado não possui um CPF/CNPJ válido. Essa informação é obrigatória para geração da nota fiscal.'
      )
    }
  }

  lookupPagadorOnCancel = (e) => {
    this.props.lookupPagadorSetVisible(false)
  }

  lookupPagadorOnEdit = () => {
    if (this.props.record.pagador && this.props.record.pagador.id && this.props.record.pagadorPessoaFisica) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.record.pagador.id}`
    } else if (this.props.record.pagador && this.props.record.pagador.id && !this.props.record.pagadorPessoaFisica) {
      return `${routes.PATH_PESSOA_JURIDICA}/${this.props.record.pagador.id}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}`
    }
  }
  lookupPagadorOnComplete = (e) => {}

  lookupPagadorOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('pagador.id', null)
      this.props.setField('pagador.value', e.value)
      this.props.clearRecords()
    } else {
      this.setFieldPagador(e.value.oid, e.value.nome, e.value.numeroDocumento)
      const filters = {}
      this.props.lookupPagadorApplyFilter(filters)
      this.checkDocumentoPagador(e.value.numeroDocumento)
    }
  }

  lookupPagadorOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.pagador) && utils.nonNull(this.props.record.pagador.value)) {
      const filtroNome = new Filtro('nome', this.props.record.pagador.value, 'CONTEM')
      const filtroCpf = new Filtro('numeroDocumento', this.props.record.pagador.value, 'CONTEM')
      const filtros = []
      filtros.push(filtroNome)
      filtros.push(filtroCpf)
      const filtroOu = {
        campo: 'filtro',
        valor: filtros,
        tipoFiltro: 'OU',
      }
      this.props.lookupPagadorApplyFilter(filtroOu)
    }
  }

  // REFERENTE AO LOOKUP PARA CONTRATO
  setContrato = (data) => {
    const oid = _.get(data, 'oid', null)
    const conspaga = _.get(data, 'conspaga', '')
    const numero = _.get(data, 'numero', '')
    let valor = _.get(data, 'localizacao_identificacao', '')
    if (!valor) {
      valor = '#' + (numero || oid) + ' - ' + _.get(data, 'tipoSepultura.descricao', '')
    }

    this.props.setField('conspaga', conspaga)
    this.props.setField('contrato.id', oid)
    this.props.setField('contrato.value', valor)

    this.setFieldPagador(data.responsavel_oid, data.responsavel_nome, data.responsavel_numeroDocumento)
  }

  setNotaEntrada = (data) => {
    const oid = _.get(data, 'oid', null)
    const numero = _.get(data, 'numero', '')
    const serie = _.get(data, 'serie', '')

    let valor = numero
    if (oid) {
      valor = `${numero} - ${serie}`
    }
    this.props.setField('notaEntrada.id', oid)
    this.props.setField('notaEntrada.value', valor)
  }

  setContaCorrente = (data) => {
    const oid = _.get(data, 'oid', null)
    const descricao = _.get(data, 'descricao', '')

    this.props.setField('contaCorrente.id', oid)
    this.props.setField('contaCorrente.value', descricao)
  }

  onBlurValorPagamento = (parcela, e) => {
    let valor = e.currentTarget.defaultValue
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    parcela.valorPagamento = utils.formatCurrency(valor.replace(',', '.').replace('R$', ''))
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
    this.setState({ showConfirmarUnirLancamento: false })
    this.setState({ showConfirmarCancelarReabrir: false })
    this.setState({ showConfirmarEstornarParcelas: false })
    this.setState({ showConfirmarEstornar: false })
  }

  hideBotaoRemover = () => {
    return true
  }

  calcularValorParcela = () => {
    let result = 0
    // Valor do Lançamento de origem
    let valorTotalLancamento = 0
    valorTotalLancamento = utils.parseStringToNumber(this.props.record.valor)

    result += valorTotalLancamento
    if (valorTotalLancamento && valorTotalLancamento !== '') {
      // valor da entrada individual que pode ser dada no título
      let valorEntrada = utils.parseStringToNumber(this.props.pagamento.valorEntrada)
      if (valorEntrada && valorEntrada !== '') {
        result -= valorEntrada
      }

      // valor subtraido de cada título filho do lancamento
      let lancamentos = this.props.record.lancamentos
      if (utils.isArrayNotEmpty(this.props.record.lancamentos)) {
        lancamentos.forEach((lancamento) => {
          let valorLancamentoFilho = utils.parseStringToNumber(lancamento.valor)
          if (result > 0 && valorLancamentoFilho > 0) {
            result -= valorLancamentoFilho
          }
        })
      }
      this.props.setDadoFormaPagamento('valor', utils.formatCurrency(result))
      // caso informado numero de parcelas realiza o calculo do valor da parcela
      let numeroParcelas = this.props.pagamento.numeroParcelas
      if (numeroParcelas > 1) {
        var cadaParcela = Math.round((result * 100) / numeroParcelas) / 100
        result = cadaParcela
      }
    }
    this.props.setDadoFormaPagamento('valorParcela', utils.formatCurrency(result))
    this.setState({ atualizar: true })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  realizarBaixaLancamentosFilhos = () => {
    if (
      this.props.selectedParcelas &&
      this.props.selectedParcelas.length > 0 &&
      this.props.record.dataPagamentoParcela
    ) {
      this.props.selectedParcelas.forEach((lancamento) => {
        lancamento.dataPagamento = this.props.record.dataPagamentoParcela
        lancamento.valor = utils.parseStringToNumber(lancamento.valor)
        lancamento.valorPago = lancamento.valor
      })
      this.props.baixarLancamentos(this.props.selectedParcelas)
    }
  }
  realizarBaixaLancamento = () => {
    this.props.record.valor = utils.parseStringToNumber(this.props.record.valor)
    this.props.record.valorPago = this.props.record.valor
    delete this.props.record.dataPagamentoParcela
    this.props.realizarBaixaLancamento([this.props.record])
  }

  onClickBaixarButton = () => {
    this.state.paramBaixarLancamento.oidLancamento = this.props.record.oid
    this.state.paramBaixarLancamento.valorPago = this.props.record.valor
    this.state.paramBaixarLancamento.dataPagamento = new Date()
    this.state.paramBaixarLancamento.formaPagamento = this.props.record.tipoFormaPagamento
    this.props.showForm('baixar', true)
  }

  //** DEPRECATED 07/01/2020 **
  realizarBaixaLancamentoOrigem = () => {
    let lancamento = this.props.record
    lancamento.valor = utils.parseStringToNumber(lancamento.valor)
    lancamento.valorBruto = utils.parseStringToNumber(lancamento.valorBruto)
    lancamento.valorPago = utils.parseStringToNumber(lancamento.valorPago)
    lancamento.valorDesconto = utils.parseStringToNumber(lancamento.valorDesconto)
    lancamento.percentualDesconto = utils.parseStringToNumber(lancamento.percentualDesconto)
    lancamento.valorJuros = utils.parseStringToNumber(lancamento.valorJuros)
    lancamento.percentualJuros = utils.parseStringToNumber(lancamento.percentualJuros)
    lancamento.valorMulta = utils.parseStringToNumber(lancamento.valorMulta)
    lancamento.percentualMulta = utils.parseStringToNumber(lancamento.percentualMulta)

    let taxas = this.props.record.taxas
    if (taxas && taxas.length > 0) {
      taxas.forEach((t) => {
        if (t.valor) {
          t.valor = utils.parseStringToNumber(t.valor)
        }
        if (t.valorTotal) {
          t.valorTotal = utils.parseStringToNumber(t.valorTotal)
        }
      })
    }
    delete lancamento.dataPagamentoParcela
    if (!utils.isIdValueIdValido(lancamento.pagador) && utils.isStringBlank(lancamento.pagador)) {
      delete lancamento.pagador
    }
    this.props.baixarLancamentos([lancamento])
  }

  executarBaixaLancamento = () => {
    const params = this.state.paramBaixarLancamento
    if (utils.isFormaPagamentoSeguradora(params.formaPagamento)) {
      this.props.showWarningMessage(
        "Forma de pagamento 'Seguradora' não é permitida para a baixa. Selecione outra e tente outra vez."
      )
    } else {
      this.props.executarBaixaLancamento(this.state.paramBaixarLancamento)
    }
  }

  verificarGeracaoBoletoIndividual = (lancamento) => {
    const isBoletoVindi = _.get(lancamento, 'lancamentoVindi', false)
    const fnGetBoletosVindi = _.get(this, 'props.getBoletosVindi', null)
    if (isBoletoVindi && fnGetBoletosVindi) {
      fnGetBoletosVindi({ oidLancamentoFinanceiro: lancamento.oid })
      return
    }

    this.setState({ oidLancamentoBoletoIndividual: lancamento.oid })
    // caso já tem boleto gerado não deve mostrar novamente os dados para envio do boleto
    const oidBoletoAilos = _.get(lancamento, 'oidBoletoAilos', null)
    if (oidBoletoAilos) {
      this.props.gerarBoletoIndividual({
        oidsLancamentosFinanceiros: [lancamento.oid],
      })
    } else {
      this.props.showForm('dadosBoletoIndividual', true)
    }
  }

  consultarStatusPagamento = (lancamento) => {
    const oid = _.get(lancamento, 'oid', null)
    if (!oid) {
      return
    }
    const isLancamentoVindi = _.get(lancamento, 'lancamentoVindi', false)
    if (isLancamentoVindi) {
      this.props.consultarStatusPagamentoVindi(oid)
    }

    const isLancamentoAilos = _.get(lancamento, 'lancamentoAilos', false)
    if (isLancamentoAilos) {
      this.props.consultarStatusPagamentoAilos(oid)
    }
  }

  gerarCarneIndividual = (lancamento) => {
    if (lancamento && lancamento.oid) {
      this.props.gerarCarne([lancamento.oid])
    }
  }

  getBotoesPersonalizados = () => {
    const arrayBotoes = []
    const lancamento = this.props.record
    const urlPromissoria = _.get(lancamento, 'urlPromissoria', '')
    if (utils.isStringNotBlank(urlPromissoria)) {
      const botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.promissoria'),
        onClick: (e) => this.props.openUrl(urlPromissoria),
        icon: icons.RECEIPT_ICON,
      }
      arrayBotoes.push(botao)
    }
    const urlRecibo = _.get(lancamento, 'urlRecibo', '')
    const lancamentoContrapartida = this.isLancamentoContrapartida(lancamento)
    if (utils.isStringNotBlank(urlRecibo) && !lancamentoContrapartida) {
      const botao = {
        type: 'button',
        label: 'Recibo',
        onClick: (e) => this.props.openUrl(urlRecibo),
        icon: icons.RECEIPT_ICON,
      }
      arrayBotoes.push(botao)
    }
    if (
      lancamento.oid &&
      lancamento.lancamentos.length === 0 &&
      lancamento.status !== 3 && //PAGO
      lancamento.status !== 4 //CANCELADO
    ) {
      const isBoleto = _.get(lancamento, 'tipoFormaPagamento.id', -1) === 5
      if (isBoleto) {
        const botao = {
          type: 'button',
          label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.boleto'),
          onClick: (e) => {
            this.verificarGeracaoBoletoIndividual(lancamento)
          },
          icon: icons.CREDIT_CARD_ICON,
        }
        arrayBotoes.push(botao)
      }
      const isLancamentoVindi = _.get(lancamento, 'lancamentoVindi', false)
      const isLancamentoAilos = _.get(lancamento, 'lancamentoAilos', false)
      if (isLancamentoVindi || isLancamentoAilos) {
        const botaoConsultarStatusVindi = {
          type: 'button',
          label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.consultarStatusPagamento'),
          onClick: (e) => {
            this.consultarStatusPagamento(lancamento)
          },
          icon: icons.SEARCH_ICON,
        }
        arrayBotoes.push(botaoConsultarStatusVindi)
      }
    }

    if (
      lancamento.oid &&
      lancamento.lancamentos.length === 0 &&
      lancamento.status !== 3 && //PAGO
      lancamento.status !== 4 && //CANCELADO
      lancamento.tipoFormaPagamento &&
      lancamento.tipoFormaPagamento.id === 17 //Carne
    ) {
      const botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.carne'),
        onClick: (e) => {
          this.gerarCarneIndividual(lancamento)
        },
        icon: icons.CREDIT_CARD_ICON,
      }
      arrayBotoes.push(botao)
    }
    if (lancamento.oid && lancamento.lancamentos.length === 0 && lancamento.status !== 3 && lancamento.status !== 4) {
      const botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.baixar'),
        onClick: (e) => this.onClickBaixarButton(),
        icon: icons.BAIXAR_ICON,
      }
      arrayBotoes.push(botao)
    }

    if (lancamento.status === 3 && utils.nonNull(lancamento.oid) && !lancamentoContrapartida) {
      let botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.estornar'),
        className: 'p-button-danger',
        onClick: (e) => this.estornarLancamento([lancamento.oid]),
        icon: icons.TIMES_ICON,
      }
      arrayBotoes.push(botao)
    }
    this.resolveBotaoNFSe({ arrayBotoes, lancamento })
    // Se tem OID e se NAO estiver PAGO
    if (lancamento.oid && lancamento.status !== 3 && !lancamentoContrapartida) {
      let msgKey = 'lancamentofinanceiro.label.reabrir'
      let className = 'p-button-success'
      let icon = icons.UNDO
      if (lancamento.status !== 4) {
        msgKey = 'lancamentofinanceiro.label.cancelar'
        className = 'p-button-danger'
        icon = icons.TIMES_ICON
      }
      const botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, msgKey),
        className: className,
        style: 'margin-left: 30px;',
        onClick: (e) => this.confirmarCancelarReabrir(lancamento),
        icon: icon,
      }
      if (this.state.ACAO_CANCELAR_LANCAMENTOS) {
        arrayBotoes.push(botao)
      }
    }

    if (lancamento.oid) {
      const botaoWhats = {
        icon: icons.WHATSAPP,
        type: 'button',
        label: 'WhatsApp',
        className: 'p-button-success',
        style: 'margin-left: 30px;',
        onClick: (e) => this.enviarWhatsApp(lancamento.oid),
      }
      arrayBotoes.push(botaoWhats)

      const botaoEmail = {
        type: 'button',
        label: 'E-mail',
        className: null,
        style: 'margin-left: 30px;',
        onClick: (e) => this.props.enviarMensagem(lancamento.oid, enums.TipoMensagem.EMAIL),
      }
      arrayBotoes.push(botaoEmail)

      if (this.state.ACAO_UNIR_LANCAMENTOS) {
        const botao = {
          type: 'button',
          label: 'Unir lançamentos',
          className: null,
          style: 'margin-left: 30px;',
          onClick: (e) => this.confirmarUnirLancamento(),
        }
        arrayBotoes.push(botao)
      }
    }
    return arrayBotoes
  }

  resolveBotaoNFSe({ arrayBotoes, lancamento }) {
    const STATUS_NFSE_CANCELADA = 9
    const oid = _.get(lancamento, 'oid', null)
    const emiteNFSe = _.get(this, 'props.emiteNFSe', false)
    if (oid && emiteNFSe) {
      // const notaFiscalServicoManual = _.get(this, 'props.emitirNotaFiscalServicoManual', false)
      const oidNfse = utils.isNumber(_.get(lancamento, 'oidNfse', null))
      const statusNfse = _.get(lancamento, 'statusNFSe', null)
      const isGerarNota = !oidNfse || (oidNfse && statusNfse === STATUS_NFSE_CANCELADA)
      const botao = {
        type: 'button',
        label: utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.nfse'),
        onClick: (e) => (isGerarNota ? this.props.gerarNotaFiscalServico(oid) : this.props.downloadDanfe(oid)),
        icon: isGerarNota ? icons.RECEIPT_ICON : icons.DOWNLOAD,
      }
      arrayBotoes.push(botao)
    }
  }

  isLancamentoContrapartida(lancamento) {
    return parseInt(_.get(lancamento, 'lancamentoContrapartida', '0')) > 0
  }

  gerarBoletoIndividual = () => {
    let parcelasBoleto = []
    if (this.props.selectedParcelas && this.props.selectedParcelas.length > 0) {
      const boletos = this.props.selectedParcelas.filter((p) => p.tipoFormaPagamento.id === 5)
      parcelasBoleto = boletos.map((b) => b.oid)
    }

    const params = {
      oidsLancamentosFinanceiros:
        parcelasBoleto.length > 1 ? parcelasBoleto : [this.state.oidLancamentoBoletoIndividual],
      formaEmissaoEnvioCorreios: this.state.formaEmissaoEnvioCorreios,
      enviarSmsAntesVencimento: this.state.enviarSmsAntesVencimento,
      enviarSmsDiaVencimento: this.state.enviarSmsDiaVencimento,
      enviarSmsDepoisVencimento: this.state.enviarSmsDepoisVencimento,
    }
    if (parcelasBoleto.length > 1) {
      this.props.gerarCarneBoleto(params)
    } else {
      this.props.gerarBoletoIndividual(params)
    }
    // limpando os dados do state
    this.setState({ oidLancamentoBoletoIndividual: null })
    this.setState({ formaEmissaoEnvioCorreios: false })
    this.setState({ enviarSmsAntesVencimento: false })
    this.setState({ enviarSmsDiaVencimento: false })
    this.setState({ enviarSmsDepoisVencimento: false })
  }

  gerarCarneParcelas = () => {
    let oidsParcelas = []
    if (this.props.selectedParcelas && this.props.selectedParcelas.length > 0) {
      const carnes = this.props.selectedParcelas.filter((p) => p.tipoFormaPagamento.id === 17)
      oidsParcelas = carnes.map((b) => b.oid)
      this.props.gerarCarne(oidsParcelas)
    }
  }

  confirmarCancelarReabrir(lancamento) {
    this.setState({ showConfirmarCancelarReabrir: true })
    this.lancamentoCancelarReabrir = lancamento
  }

  cancelarReabrir = () => {
    if (this.lancamentoCancelarReabrir && utils.isNumber(this.lancamentoCancelarReabrir.oid)) {
      this.props.cancelarReabrir(this.lancamentoCancelarReabrir.oid)
    }
  }

  getDialogCancelarReabrir() {
    if (this.state.showConfirmarCancelarReabrir) {
      let msgKey = 'lancamentofinanceiro.dialog.message.confirmarReabrir'
      if (this.lancamentoCancelarReabrir.status !== 4) {
        msgKey = 'lancamentofinanceiro.dialog.message.confirmarCancelar'
      }
      return (
        <ConfirmDialog
          header={utils.getMessage(this.props.messagesDialog, 'dialog.titulo.confirmacao')}
          visible={this.state.showConfirmarCancelarReabrir}
          content={utils.getMessage(this.props.messagesDialog, msgKey)}
          confirm={this.cancelarReabrir}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  confirmarUnirLancamento() {
    this.setState({ oidLancamentoUnir: '' })
    this.props.showForm('unirLancamento', true)
  }

  executarUnirLancamento = () => {
    const oidExcluir = parseInt(this.state.oidLancamentoUnir)
    this.props.unirLancamentos(this.props.record.oid, oidExcluir)
    this.setState({ oidLancamentoUnir: '' })
  }

  getDialogUnirLancamento() {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.confirmar')}
          onClick={(e) => {
            this.executarUnirLancamento()
            this.props.showForm('unirLancamento', false)
          }}
          icon={icons.CHECK_ICON}
          className="p-button-success"
        />
      </div>
    )
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.unir.lancamento')}
        visible={this.props.displayForm.unirLancamento}
        style={{ width: '20vw' }}
        onHide={(e) => {
          this.props.showForm('unirLancamento', false)
          this.setState({ oidLancamentoUnir: 0 })
        }}
        baseZIndex={1000000}
        modal={true}
        closable={true}
        footer={footer}
      >
        <div className="margin-top-dialogs">
          <span className="p-float-label">
            <InputText
              keyfilter={/^[0-9.,]+$/}
              value={this.state.oidLancamentoUnir}
              maxLength={10}
              onChange={(e) => {
                this.setState({ oidLancamentoUnir: e.target.value })
              }}
              name="oidLancamentoUnir"
            />
            <label className="label">
              {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.lancamento.excluir')}
            </label>
          </span>
        </div>
      </Dialog>
    )
  }

  getDialogEstornar() {
    if (this.state.showConfirmarEstornar) {
      let msgKey = 'lancamentofinanceiro.dialog.message.confirmar.estorno'
      const lancamento = this.props.record
      if (utils.isArrayNotEmpty(lancamento.lancamentos)) {
        msgKey = 'lancamentofinanceiro.dialog.message.confirmar.estorno.lancamento.pai'
      }

      return (
        <ConfirmDialog
          visible={this.state.showConfirmarEstornar}
          content={utils.getMessage(this.props.messagesDialog, msgKey)}
          confirm={this.executarEstorno}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogEstornarParcelas() {
    if (this.state.showConfirmarEstornarParcelas) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarEstornarParcelas}
          content={utils.getMessage(
            this.props.messagesDialog,
            'lancamentofinanceiro.dialog.message.confirmar.estorno.parcelas'
          )}
          confirm={this.executarEstornoParcelas}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  getDialogDadosBoletoIndividual() {
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.dadosenviocontrato')}
        visible={this.props.displayForm.dadosBoletoIndividual}
        style={{ width: '50vw' }}
        onHide={(e) => this.props.showForm('dadosBoletoIndividual', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkEnviarPorCorreios"
                checked={this.state.formaEmissaoEnvioCorreios}
                onChange={(e) => this.setState({ formaEmissaoEnvioCorreios: e.checked })}
                name="formaEmissaoEnvioCorreios"
              />
              <label htmlFor="chkEnviarPorCorreios" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.enviarporcorreios')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsAntesVencimento"
                checked={this.state.enviarSmsAntesVencimento}
                onChange={(e) => this.setState({ enviarSmsAntesVencimento: e.checked })}
                name="enviarSmsAntesVencimento"
              />
              <label htmlFor="chkenviarSmsAntesVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.enviarsmsantesvencimento')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsDiaVencimento"
                checked={this.state.enviarSmsDiaVencimento}
                onChange={(e) => this.setState({ enviarSmsDiaVencimento: e.checked })}
                name="enviarSmsDiaVencimento"
              />
              <label htmlFor="chkenviarSmsDiaVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.enviarsmsnodiavencimento')}
              </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <Checkbox
                inputId="chkenviarSmsDepoisVencimento"
                checked={this.state.enviarSmsDepoisVencimento}
                onChange={(e) => this.setState({ enviarSmsDepoisVencimento: e.checked })}
                name="enviarSmsDepoisVencimento"
              />
              <label htmlFor="chkenviarSmsDepoisVencimento" className="p-checkbox-label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.enviarsmsdepoisvencimento')}
              </label>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <Button
                  label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.confirmar')}
                  onClick={(e) => {
                    this.gerarBoletoIndividual()
                    this.props.showForm('dadosBoletoIndividual', false)
                  }}
                  icon={icons.CHECK_ICON}
                  className="p-button-success"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  getDialogDadosBaixaParcela() {
    let lancamento = this.props.record
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.baixarparcelas')}
        visible={this.props.displayForm.baixarParcelas}
        style={{ width: '50vw' }}
        onHide={(e) => this.props.showForm('baixarParcelas', false)}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.valor')}
              </label>
              <label className="label">{` R$ ${utils.formatCurrency(this.getValorLancamentosSelecionados())}`}</label>
            </div>

            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.databaixa')}
              </label>
              <Calendar
                yearNavigator={true}
                yearRange={utils.getYearRangeFromNow()}
                monthNavigator={true}
                value={lancamento.dataPagamentoParcela}
                onChange={this.props.changeHandler}
                name="dataPagamentoParcela"
              />
            </div>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Button
              label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.confirmar')}
              onClick={(e) => {
                this.realizarBaixaLancamentosFilhos()
                this.props.showForm('baixarParcelas', false)
              }}
              icon={icons.CHECK_ICON}
              className="p-button-success"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  setValorBaixa = (e) => {
    let valor = e.target.value
    valor = valor.replace('R$', '')
    if (typeof valor === 'string' && valor.length > 0) {
      valor = valor.trim()
    }
    const params = this.state.paramBaixarLancamento
    params.valorPago = valor
    this.setState({ paramBaixarLancamento: params })
  }

  getValorReais = (valor) => {
    if (valor && valor !== '') {
      return 'R$ ' + valor
    }
    return ''
  }

  onBlurValorBaixa = (e) => {
    let valor = this.state.paramBaixarLancamento.valorPago
    if (typeof valor === 'number') {
      valor = String(valor)
    }
    const params = this.state.paramBaixarLancamento
    params.valorPago = utils.formatCurrency(valor.replace(',', '.').replace('R$', ''))
    this.setState({ paramBaixarLancamento: params })
  }

  setDataBaixa = (e) => {
    let valor = e.target.value
    const params = this.state.paramBaixarLancamento
    params.dataPagamento = valor
    this.setState({ paramBaixarLancamento: params })
  }

  setFormaPagamentoBaixa = (e) => {
    let valor = e.target.value
    const params = this.state.paramBaixarLancamento
    params.formaPagamento = valor
    this.setState({ paramBaixarLancamento: params })
  }

  onHideModalBaixarLancamento() {
    self.props.showForm('baixar', false)
  }

  confirmarBaixa({ valor, data, formaPagamento, lancamentos }) {
    const oidsLancamentos = lancamentos.map((l) => l.oid)
    const params = {
      oidsLancamentos,
      valor,
      data,
      formaPagamento,
    }
    self.props.executarBaixaLancamentos(params)
    self.onHideModalBaixarLancamento()
  }

  getModalBaixarLancamento() {
    const oid = _.get(this, 'props.record.oid', null)
    if (!oid) {
      return null
    }
    return (
      <BaixarLancamentoModal
        messages={self.props.messages}
        visible={self.props.displayForm.baixar}
        onHide={self.onHideModalBaixarLancamento}
        lancamentosBaixar={[self.props.record]}
        formasPagamento={self.props.formasPagamentoBasica}
        onConfirm={self.confirmarBaixa}
        labelBaixar={utils.getMessage(self.props.messages, 'lancamentofinanceiro.label.baixar')}
        labelValorPago={utils.getMessage(self.props.messages, 'lancamentofinanceiro.label.valorbaixa')}
        labelDataBaixa={utils.getMessage(self.props.messages, 'lancamentofinanceiro.label.databaixa')}
        labelFormaPagamento={utils.getMessage(self.props.messages, 'lancamentofinanceiro.label.formapagamento')}
        labelConfirmar={utils.getMessage(self.props.messages, 'lancamentofinanceiro.label.confirmar')}
        bloquearLancamento={this.state.BLOQUEAR_LANCAMENTO}
      />
    )
  }

  getDialogDadosBaixa() {
    return (
      <Dialog
        header={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.baixar')}
        visible={this.props.displayForm.baixar}
        style={{ width: '50vw' }}
        onHide={(e) => {
          this.props.showForm('baixar', false)
          this.props.clearFormBaixa()
        }}
        baseZIndex={1000000}
        modal={true}
        closable={true}
      >
        <div className="p-grid p-fluid">
          <div className="p-grid form-group p-align-end">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.valorbaixa')}
              </label>
              <InputText
                keyfilter={/^[0-9.,]+$/}
                value={this.getValorReais(this.state.paramBaixarLancamento.valorPago)}
                maxLength={10}
                onBlur={(e) => this.onBlurValorBaixa(e)}
                onChange={this.setValorBaixa}
                name="valorPagoBaixa"
              />
            </div>

            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.databaixa')}
              </label>
              <Calendar
                yearNavigator={true}
                yearRange={utils.getYearRangeFromNow()}
                monthNavigator={true}
                value={this.state.paramBaixarLancamento.dataPagamento}
                onChange={this.setDataBaixa}
                name="dataPagamentoBaixa"
              />
            </div>

            <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
              <label className="label">
                {utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.formapagamento')}
              </label>
              <Dropdown
                autoWidth={false}
                value={this.state.paramBaixarLancamento.formaPagamento}
                options={this.props.formasPagamentoBasica}
                onChange={this.setFormaPagamentoBaixa}
                optionLabel="value"
                optionValue={(v) => v}
                name="formaPagamentoBaixa"
              />
            </div>
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <Button
              label={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.confirmar')}
              onClick={(e) => {
                this.executarBaixaLancamento()
                this.props.showForm('baixar', false)
              }}
              disabled={this.verificacaoDesabilitarBotaoBaixar()}
              icon={icons.CHECK_ICON}
              className="p-button-success"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  verificacaoDesabilitarBotaoBaixar = () => {
    let paramsBaixaLancamento = this.state.paramBaixarLancamento
    if (
      utils.nonNull(paramsBaixaLancamento.valorPago) &&
      paramsBaixaLancamento.valorPago !== '' &&
      utils.nonNull(paramsBaixaLancamento.dataPagamento) &&
      paramsBaixaLancamento.dataPagamento !== '' &&
      utils.nonNull(paramsBaixaLancamento.formaPagamento) &&
      utils.isIdValueIdValido(paramsBaixaLancamento.formaPagamento)
    ) {
      return false
    }
    return true
  }

  abrirLancamento = (oidLancamento) => {
    this.props.history.push(`${routes.PATH_LANCAMENTO_FINANCEIRO}/${oidLancamento}`)
    this.props.loadById(oidLancamento)
  }

  desabilitarBotaoAdicionarPagamento = () => {
    let valorTotalLancamento = utils.parseStringToNumber(this.props.record.valor)
    let valorTotalLancamentosFilhos = this.getValorLancamentosFilhos()
    if (valorTotalLancamentosFilhos >= valorTotalLancamento) {
      return true
    }
    if (this.props.record.status === 3) {
      return true
    }
    return false
  }

  desabilitarBotaoEstornarParcela = () => {
    const parcelaPaga = utils.findObjectInArray(this.props.selectedParcelas, 'status', 3)
    return !utils.nonNull(parcelaPaga)
  }

  desabilitarBotaoBaixarParcela = () => {
    const parcelaPaga = utils.findObjectInArray(this.props.selectedParcelas, 'status', 3)
    const parcelaCancelada = utils.findObjectInArray(this.props.selectedParcelas, 'status', 4)
    return !(!parcelaPaga && !parcelaCancelada && this.props.selectedParcelas && this.props.selectedParcelas.length > 0)
  }

  desabilitarBotaoBoleto = () => {
    const boletos = this.props.selectedParcelas.filter((p) => p.tipoFormaPagamento.id === 5)
    const parcelaEmAberto = utils.findObjectInArray(boletos, 'status', 3)
    return !(!parcelaEmAberto && boletos && boletos.length > 0)
  }

  desabilitarBotaoCarne = () => {
    const carnes = this.props.selectedParcelas.filter((p) => p.tipoFormaPagamento.id === 17)
    return !(carnes && carnes.length > 0)
  }

  estornarLancamento = (oidsLancamentos) => {
    this.lancamentosEstornar = oidsLancamentos
    this.setState({ showConfirmarEstornar: true })
  }

  executarEstorno = () => {
    this.props.estornarLancamento(this.lancamentosEstornar)
  }

  estornarParcelas = () => {
    this.setState({ showConfirmarEstornarParcelas: true })
  }

  executarEstornoParcelas = () => {
    let pagamentosEstornados = []
    this.props.selectedParcelas.forEach((lancamento) => {
      pagamentosEstornados.push(lancamento.oid)
    })
    this.props.estornarLancamento(pagamentosEstornados)
  }

  getValorLancamentosFilhos = () => {
    let valorTotalLancamentoFilhos = 0
    if (this.props.record.lancamentos && this.props.record.lancamentos.length >= 1) {
      this.props.record.lancamentos.forEach((lancamentoFilho) => {
        let valor = utils.parseStringToNumber(lancamentoFilho.valor)
        valorTotalLancamentoFilhos += valor
      })
    }
    return valorTotalLancamentoFilhos
  }

  getValorLancamentosSelecionados = () => {
    let valorTotalLancamentoSelecionados = 0
    if (this.props.selectedParcelas && this.props.selectedParcelas.length >= 1) {
      this.props.selectedParcelas.forEach((lancamentoSelecionado) => {
        let valor = utils.parseStringToNumber(lancamentoSelecionado.valor)
        valorTotalLancamentoSelecionados += valor
      })
    }
    return valorTotalLancamentoSelecionados
  }

  editarDadoLancamentoFinanceiroFilho = (lancamento, evento) => {
    const target = evento.target
    const field = target.name
    let valor = target.value
    if (field === 'valor') {
      valor = valor.replace('R$', '')
      if (typeof valor === 'string' && valor.length > 0) {
        valor = valor.trim()
      }
      lancamento['valorBruto'] = valor
    } else if (field === 'promissoria') {
      valor = target.checked
    } else if (field === 'tipoFormaPagamento') {
      // caso troque a forma de pagamento limpa o campo promissoria
      lancamento.promissoria = false
    } else if (field === 'vencimento') {
      lancamento[field] = valor
      this.ordenarArrayParcelas()
    }
    lancamento[field] = valor
    this.setState({ atualizar: true })
  }

  disabledBotaoBoleto = (lancamento) => {
    return !(
      lancamento.oid &&
      lancamento.oid !== '' &&
      lancamento.tipoFormaPagamento &&
      lancamento.tipoFormaPagamento.id === 5
    )
  }

  showFormParcelas = () => {
    this.props.setDadoFormaPagamento('formaPagamento', this.props.record.tipoFormaPagamento)
    if (this.props.record.vencimento) {
      this.props.setDadoFormaPagamento('primeiroVencimento', this.props.record.vencimento)
    } else {
      this.props.setDadoFormaPagamento('primeiroVencimento', new Date())
    }
    this.props.showForm('pagamento', true)
  }

  onChangeItemLancamento = (data) => {
    const values = _.get(data, 'values', [])
    const packageDiscount = _.get(data, 'packageDiscount', 0)
    this.setField('taxas', values)

    this.setItemPrincipal(data)

    //Se tiver nota de entrada não muda o valor
    const oidNotaEntrada = _.get(this, 'props.record.notaEntrada.id', null)
    if (!oidNotaEntrada) {
      setTimeout(() => this.updateValues(values, packageDiscount), 100)
    }
    this.setDescricaoServicoNFSe(data)
  }

  setDescricaoServicoNFSe = (data) => {
    const descricaoServicoNFSe = _.get(this, 'props.record.descricaoServicoNFSe', null)
    const newValue = _.get(data, 'descricaoServico', null)
    if ((!descricaoServicoNFSe || _.trim(descricaoServicoNFSe).length === 0) && newValue) {
      this.setField('descricaoServicoNFSe', newValue)
    }
  }

  setItemPrincipal = (data) => {
    const values = _.get(data, 'values', [])
    if (utils.isArrayNullOrEmpty(values)) {
      this.setField('oidItemPrincipal', null)
      return
    }

    const oidItemPrincipalAtual = _.get(this, 'props.record.oidItemPrincipal', null)
    const serviceRemoved = _.get(data, 'serviceRemoved', null)
    const serviceAdded = _.get(data, 'serviceAdded', null)
    if (serviceAdded && !oidItemPrincipalAtual) {
      this.setField('oidItemPrincipal', _.get(serviceAdded, 'itemLancamento.oidTipo', null))
      this.setField('tipoItemPrincipal', _.get(serviceAdded, 'itemLancamento.tipo', null))
      return
    }

    if (serviceRemoved) {
      const oidTaxaRemoved = _.get(serviceRemoved, 'itemLancamento.oidTipo', null)
      if (oidTaxaRemoved === oidItemPrincipalAtual) {
        this.setField('oidItemPrincipal', _.get(values[0], 'itemLancamento.oidTipo', null))
        this.setField('tipoItemPrincipal', _.get(values[0], 'itemLancamento.tipo', null))
      }
    }
  }

  updateValues = (values, packageDiscount) => {
    const valorBruto = this.calcValorBruto(values)
    const valorMulta = this.calcMulta(valorBruto)
    const valorJuros = this.calcJuros(valorBruto)
    const valorDesconto = this.calcDesconto(valorBruto) + _.defaultTo(packageDiscount, 0)

    let newValue = valorBruto + valorMulta + valorJuros - valorDesconto
    if (newValue < 0) {
      newValue = 0
    }

    this.setField('valorBruto', valorBruto)
    this.setField('valor', newValue)

    if (newValue === 0) {
      this.setField('tipoDesconto', '')
      this.setField('valorDesconto', '')
      this.setField('percentualDesconto', '')
      this.setField('tipoJuros', '')
      this.setField('valorJuros', '')
      this.setField('percentualJuros', '')
      this.setField('tipoMulta', '')
      this.setField('valorMulta', '')
      this.setField('percentualMulta', '')
    } else if (packageDiscount > 0 && newValue >= packageDiscount) {
      // TODO validar isso com Vandir -- emitir mensagem com aviso de desconto do pacote?
      this.setField('tipoDesconto', 1) // valor
      this.setField('valorDesconto', utils.formatCurrency(valorDesconto))
      this.setField('percentualDesconto', 0)
    }

    //TODO verificar pra que servce isso - coisa antiga?
    this.setState({ multaAplicada: utils.formatCurrency(valorMulta) })
    this.setState({ jurosAplicados: utils.formatCurrency(valorJuros) })
    this.setState({ descontoAplicado: utils.formatCurrency(valorDesconto) })
  }

  calcValorBruto = (values) => {
    let valorBruto = 0
    if (utils.isArrayNotEmpty(values)) {
      for (const item of values) {
        valorBruto += _.get(item, 'valorTotal', 0)
      }
    }
    return valorBruto
  }

  calcDesconto = (valorBruto) => {
    let valorLancamento = valorBruto
    //TODO - valorDesconto deve ser currency
    const valorDesconto = utils.parseStringToNumber(_.get(this, 'props.record.valorDesconto', '0'))
    if (valorDesconto && valorDesconto > 0) {
      return valorDesconto
    }

    //TODO - percentualDesconto deve ser number
    const percentualDesconto = utils.parseStringToNumber(_.get(this, 'props.record.percentualDesconto', '0'))
    if (percentualDesconto && percentualDesconto > 0) {
      return (valorBruto * percentualDesconto) / 100
    }

    return 0
  }

  calcMulta = (valorBruto) => {
    //TODO - valorMulta deve ser currency
    const valorMulta = utils.parseStringToNumber(_.get(this, 'props.record.valorMulta', '0'))
    if (valorMulta && valorMulta > 0) {
      return valorMulta
    }

    //TODO - percentualMulta deve ser number
    const percentualMulta = utils.parseStringToNumber(_.get(this, 'props.record.percentualMulta', '0'))
    if (percentualMulta && percentualMulta > 0) {
      return (valorBruto * percentualMulta) / 100
    }

    return 0
  }

  calcJuros = (valorBruto) => {
    //TODO - valorJuros deve ser currency
    const valorJuros = utils.parseStringToNumber(_.get(this, 'props.record.valorJuros', '0'))
    if (valorJuros && valorJuros > 0) {
      return valorJuros
    }

    //TODO - percentualJuros deve ser number
    const percentualJuros = utils.parseStringToNumber(_.get(this, 'props.record.percentualJuros', '0'))
    if (percentualJuros && percentualJuros > 0) {
      return (valorBruto * percentualJuros) / 100
    }

    return 0
  }

  setMarcadores = (data) => {
    const values = []
    data.forEach((object) => {
      values.push({
        id: _.get(object, 'oid', ''),
        value: _.get(object, 'codigo', ''),
      })
    })
    this.props.setField('marcadores', values)
  }

  enviarWhatsApp = (oidLancamento) => {
    if (this.props.isUsarWebWhatsapp) {
      this.props.abrirWebWhatsApp({ oidLancamento })
    } else {
      this.props.enviarMensagem(oidLancamento, enums.TipoMensagem.WHATSAPP)
    }
  }

  render() {
    const actionPagamento = (rowData) => {
      return (
        <div>
          <Button
            type="button"
            tooltip={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.boleto')}
            className="p-button-warning"
            onClick={(e) => this.verificarGeracaoBoletoIndividual(rowData)}
            icon={icons.CREDIT_CARD_ICON}
            disabled={this.disabledBotaoBoleto(rowData)}
          />
          <Button
            type="button"
            icon={icons.RECEIPT_ICON}
            tooltip={utils.getMessage(this.props.messages, 'lancamentofinanceiro.label.promissoria')}
            onClick={(e) => this.props.openUrl(rowData.urlPromissoria)}
            disabled={!utils.nonNull(rowData.urlPromissoria) && utils.isStringBlank(rowData.urlPromissoria)}
          />
          <Button
            type="button"
            icon={icons.REMOVE_ICON}
            className="p-button-danger"
            tooltip={utils.getMessage(this.props.messages, 'label.remover')}
            onClick={(e) => this.props.removerLancamento(rowData, this.props.record.lancamentos)}
            disabled={rowData.status === 3 || utils.nonNull(rowData.oidBoletoAilos) || utils.nonNull(rowData.oid)}
          />
        </div>
      )
    }

    const columnData = (rowData, column) => {
      if (column) {
        if (column.field === 'oid' && rowData[column.field] && rowData[column.field] !== '') {
          return (
            <Link
              to={`${routes.PATH_LANCAMENTO_FINANCEIRO}/${rowData.oid}`}
              onClick={(e) => this.props.loadById(rowData.oid)}
            >
              {rowData.oid}
            </Link>
          )
        }
        if (column.field === 'tipoFormaPagamento' && rowData[column.field] !== '') {
          return (
            <Dropdown
              autoWidth={false}
              value={rowData[column.field]}
              options={this.props.formasPagamentoBasica}
              onChange={(e) => this.editarDadoLancamentoFinanceiroFilho(rowData, e)}
              optionLabel="value"
              optionValue={(v) => v}
              dataKey="value"
              name="tipoFormaPagamento"
              disabled={rowData.status === 4 || rowData.status === 3 || utils.nonNull(rowData.oidBoletoAilos)}
            />
          )
        }
        if (column.field === 'vencimento' && rowData[column.field] !== '') {
          return (
            <Calendar
              yearRange={utils.getYearRangeFromDate('1970')}
              value={rowData[column.field] ? new Date(rowData[column.field]) : new Date()}
              onChange={(e) => this.editarDadoLancamentoFinanceiroFilho(rowData, e)}
              name="vencimento"
              disabled={rowData.status === 4 || rowData.status === 3 || utils.nonNull(rowData.oidBoletoAilos)}
            />
          )
        }
        if (column.field === 'valor') {
          return (
            <InputText
              keyfilter={/^[0-9.,]+$/}
              value={this.getValorReais(rowData[column.field])}
              maxLength={10}
              onBlur={(e) => this.onBlurValorFormaPagamento(rowData, e)}
              onChange={(e) => this.editarDadoLancamentoFinanceiroFilho(rowData, e)}
              name="valor"
              disabled={rowData.status === 4 || rowData.status === 3 || utils.nonNull(rowData.oidBoletoAilos)}
            />
          )
        }
        if (column.field === 'unidade') {
          return (
            <LtsDropdownUnidade
              name={'unidadeParcela'}
              value={rowData[column.field]}
              onChange={(e) => {
                _.set(rowData, 'unidade', e)
                this.setState({ atualizar: true })
              }}
            />
          )
        }
        if (column.field === 'contaCorrente') {
          return (
            <LtsDropdownIdValue
              name={'contaCorrenteParcela'}
              value={rowData[column.field]}
              options={this.props.contasCorrentesParcelas}
              onChange={(e) => {
                _.set(rowData, 'contaCorrente', e)
                this.setState({ atualizar: true })
              }}
            />
          )
        }
        if (column.field === 'grupoItemPrincipal') {
          return (
            <LtsLookupGrupoFinanceiro
              value={_.get(rowData[column.field], 'value', null)}
              editHidden={true}
              field={'grupoItemPrincipal'}
              multiple={false}
              onChange={(e) => {
                const value = { id: _.get(e, 'oid', null), value: _.get(e, 'descricao', null) }
                _.set(rowData, 'grupoItemPrincipal', value)
                this.setState({ atualizar: true })
              }}
            />
          )
        }
        if (column.field === 'promissoria') {
          return (
            <Checkbox
              inputId="chkPromissoria"
              checked={rowData[column.field]}
              onChange={(e) => this.editarDadoLancamentoFinanceiroFilho(rowData, e)}
              name="promissoria"
              style={{ textAlign: 'center' }}
            />
          )
        }
        if (column.field === 'status') {
          return <div>{enums.StatusLancamento[rowData[column.field]]}</div>
        }
      }
    }

    const colunasPagamento = this.props.colunasPagamentos.map((col) => {
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '2.5em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={columnData}
            style={{ textAlign: 'center', width: '7em' }}
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={actionPagamento}
            style={{ width: '9em' }}
            align={'right'}
            alignHeader={'right'}
          />
        )
      }
      if (
        col.field === 'tipoFormaPagamento' ||
        col.field === 'valor' ||
        col.field === 'vencimento' ||
        col.field === 'unidade' ||
        col.field === 'contaCorrente' ||
        col.field === 'grupoItemPrincipal'
      ) {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={columnData}
            style={{ textAlign: 'center', overflow: 'visible', width: '10em' }}
          />
        )
      }
      if (col.field === 'promissoria') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={columnData}
            style={{ overflow: 'visible', width: '5em' }}
            align={'right'}
            alignHeader={'right'}
          />
        )
      }
      if (col.field === 'status') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={columnData}
            style={{ overflow: 'visible', width: '8em' }}
            align={'left'}
            alignHeader={'left'}
          />
        )
      }
      return (
        <Column
          {...col}
          key={col.field}
          field={col.field}
          header={col.header}
          style={{ textAlign: 'center', width: '7em' }}
        />
      )
    })

    const content = (
      <div>
        {this.getModalBaixarLancamento()}
        {/*{this.getDialogDadosBaixa()}*/}
        {this.getDialogDadosBaixaParcela()}
        {this.getDialogCancelarReabrir()}
        {this.getDialogDadosBoletoIndividual()}
        {this.getDialogEstornar()}
        {this.getDialogEstornarParcelas()}
        {this.getDialogUnirLancamento()}
        <LancamentoForm
          state={this.state}
          changeActiveIndex={this.changeActiveIndex}
          pagamento={this.props.pagamento}
          editarDadoFormaPagamento={this.props.editarDadoFormaPagamento}
          adicionarPagamento={this.props.adicionarPagamento}
          colunasPagamentos={colunasPagamento}
          disableUnit={this.props.disableUnit}
          unidades={this.props.unidades}
          currentUser={this.props.currentUser}
          messages={this.props.messages}
          status={this.props.status}
          changeHandler={this.props.changeHandler}
          changeHandlerFormaPagamento={this.changeHandlerFormaPagamento}
          getValorLancamentosFilhos={this.getValorLancamentosFilhos}
          editarNumeroParcelas={this.editarNumeroParcelas}
          estornarPagamento={this.estornarParcelas}
          desabilitarBotaoAdicionarPagamento={this.desabilitarBotaoAdicionarPagamento}
          desabilitarBotaoEstornarParcela={this.desabilitarBotaoEstornarParcela}
          desabilitarBotaoBaixarParcela={this.desabilitarBotaoBaixarParcela}
          desabilitarBotaoBoleto={this.desabilitarBotaoBoleto}
          desabilitarBotaoCarne={this.desabilitarBotaoCarne}
          gerarCarneParcelas={this.gerarCarneParcelas}
          onBlurValor={this.onBlurValor}
          onBlurValorFormaPagamento={this.onBlurValorFormaPagamento}
          onBlurValorParcela={this.onBlurValorParcela}
          onBlurValorDescontoAcrescimo={this.onBlurValorDescontoAcrescimo}
          calcularValorParcela={this.calcularValorParcela}
          clearFormPagamento={this.props.clearFormPagamento}
          onBlurNumeroParcelas={this.onBlurNumeroParcelas}
          calcularValorTotal={this.calcularValorTotal}
          changeHandlerValorLancamento={this.changeHandlerValorLancamento}
          onChangeValorDesconto={this.onChangeValorDesconto}
          onChangePercentualDesconto={this.onChangePercentualDesconto}
          onChangeTipoDesconto={this.onChangeTipoDesconto}
          onChangeValorMulta={this.onChangeValorMulta}
          onChangePercentualMulta={this.onChangePercentualMulta}
          onChangeTipoMulta={this.onChangeTipoMulta}
          onChangeValorJuros={this.onChangeValorJuros}
          onChangePercentualJuros={this.onChangePercentualJuros}
          onChangeTipoJuros={this.onChangeTipoJuros}
          onChangeJurosDia={this.onChangeJurosDia}
          displayForm={this.props.displayForm}
          showForm={this.props.showForm}
          showFormParcelas={this.showFormParcelas}
          record={this.props.record}
          onSelectionChange={this.onSelectionChange}
          selectedParcelas={this.props.selectedParcelas}
          abrirLancamento={this.abrirLancamento}
          history={this.props.history}
          onChangeHandlerConspagaPagarAte={this.onChangeHandlerConspagaPagarAte}
          onBlurConspagaPagarAte={this.onBlurConspagaPagarAte}
          //------
          lookupPagador={this.props.lookupPagador}
          lookupPagadorOnKeyPress={this.lookupPagadorOnKeyPress}
          lookupPagadorOnHide={this.lookupPagadorOnHide}
          lookupPagadorOnConfirm={this.lookupPagadorOnConfirm}
          lookupPagadorOnCancel={this.lookupPagadorOnCancel}
          lookupPagadorOnPage={this.lookupPagadorOnPage}
          lookupPagadorOnSort={this.lookupPagadorOnSort}
          lookupPagadorOnFilter={this.lookupPagadorOnFilter}
          lookupPagadorOnClick={this.lookupPagadorOnClick}
          lookupPagadorOnEdit={this.lookupPagadorOnEdit}
          lookupPagadorOnComplete={this.lookupPagadorOnComplete}
          lookupPagadorOnInputFilter={this.lookupPagadorOnInputFilter}
          //-----
          setContrato={this.setContrato}
          formasPagamento={this.props.formasPagamento}
          formasPagamentoBasica={this.props.formasPagamentoBasica}
          setField={this.props.setField}
          servicoCartaoCreditoVindi={this.props.servicoCartaoCreditoVindi}
          servicoBoletoVindi={this.props.servicoBoletoVindi}
          onChangeItemLancamento={this.onChangeItemLancamento}
          showMessageConspagaEmDia={this.props.showMessageConspagaEmDia}
          fatorMultiplicadorConspaga={this.props.fatorMultiplicadorConspaga}
          units={_.get(this, 'props.units', storage.getUnits())}
          currentCompany={_.get(this, 'props.currentCompany', storage.getCompany())}
          showWarningMessage={this.props.showWarningMessage}
          exibirConspaga={this.props.exibirConspaga}
          setFieldPagador={this.setFieldPagador}
          checkDocumentoPagador={this.checkDocumentoPagador}
          statePessoaFisica={this.props.statePessoaFisica}
          statePessoaJuridica={this.props.statePessoaJuridica}
          cartoesPagador={this.props.cartoesPagador}
          setMarcadores={this.setMarcadores}
          stateMarcador={this.props.stateMarcador}
          setNotaEntrada={this.setNotaEntrada}
          stateNotaEntrada={this.props.stateNotaEntrada}
          setContaCorrente={this.setContaCorrente}
          formaPagamentoOriginal={this.props.formaPagamentoOriginal}
          emiteNFSe={this.props.emiteNFSe}
          descontoAplicado={this.props.descontoAplicado}
          multaAplicada={this.props.multaAplicada}
          jurosAplicados={this.props.jurosAplicados}
          adicionandoAnexo={this.props.adicionandoAnexo}
          anexos={this.props.record.anexos}
          onSelectAnexo={this.props.addAnexo}
          downloadAnexo={this.props.downloadAnexo}
          removeAnexo={this.props.removeAnexo}
          acaoBloquearLancamento={this.state.BLOQUEAR_LANCAMENTO}
        />
      </div>
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LancamentoFormPageContainer)
