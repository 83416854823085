import React from 'react'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { Page } from '../../../components/page/Page'
import { actions } from '../portalClienteContratos.actions'
import { actions as actionsContrato } from '../../contrato/contrato.actions'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { PortalClienteContratoList } from './PortalClienteContratoList'
import { onColumnReorder, onColumnToggle, onPage, onSort } from '../../../utils/listPageContainerUtils'
import utils from '../../../utils'
import { Button } from 'primereact/button'
import { icons } from '../../../constants'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    record: state.crudPortalClienteContratoState.currentRecord,
    messages: state.crudPortalClienteContratoState.messages,
    showHideColumns: state.crudPortalClienteContratoState.showHideColumns,
    columns: state.crudPortalClienteContratoState.columns,
    records: state.crudPortalClienteContratoState.records,
    first: state.crudPortalClienteContratoState.index,
    rows: state.crudPortalClienteContratoState.max,
    totalRecords: state.crudPortalClienteContratoState.count,
    sortField: state.crudPortalClienteContratoState.sortField,
    order: state.crudPortalClienteContratoState.sortOrder,
    selectedFilters: state.crudPortalClienteContratoState.selectedFilters,
    selectedRecords: state.crudPortalClienteContratoState.selectedRecords,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.loadAll())
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.setField(field, value))
    },
    changeFilter: (column, e) => {},
    setSelectedFilters: (filtro) => dispatch(actions.setFiltroAvancado(filtro)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    load: () => dispatch(actions.loadAll()),

    requestImprimirDocumento: (params) => {
      dispatch(actionsContrato.requestImprimirDocumento(params))
    },
  }
}

class PortalClienteContratoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.showColumnButton = false
    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.state = {}
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.load()
  }

  changeHandler = (event) => {
    this.props.changeHandler(event)
  }

  onKeyDown = (e) => {
    this.props.onEnter()
  }

  hideDialog = () => {
    this.setState({ oidsContratosAtrasados: [] })
    this.setState({ showWarningConspagaAtraso: false })
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      } else if (col.field === 'data') {
        return (
          <Column
            {...col}
            key={col.field}
            body={(rowData, column) => {
              return utils.format(rowData, column)
            }}
            sortField={col.columnSortField}
            filterMatchMode="contains"
          />
        )
      } else if (col.field === 'oidContratoDocumento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumnActions}
            sortField={col.columnSortField}
            filterMatchMode="contains"
          />
        )
      } else {
        return <Column {...col} key={col.field} sortField={col.columnSortField} filterMatchMode="contains" />
      }
    })
  }

  formatColumnActions = (rowData, column) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          className="p-button-success"
          icon={icons.VIEW}
          tooltip={'Visualizar'}
          onClick={(e) => this.imprimirDocumento(rowData)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          type="button"
          className="p-button-success"
          icon={icons.CHECK_ICON}
          tooltip={'Assinar'}
          onClick={(e) => this.assinarDocumento(rowData)}
          disabled={!_.get(rowData, 'link', null)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          type="button"
          icon={icons.DOWNLOAD}
          tooltip={'Baixar'}
          onClick={(e) => this.visualizarDocumento(rowData)}
          disabled={!_.get(rowData, 'urlDocumentoAssinado', null)}
        />
      </React.Fragment>
    )
  }

  imprimirDocumento = (rowData) => {
    const params = {}

    const oidResponsavel = _.get(rowData, 'oidResponsavel', null)
    const oidFalecido = _.get(rowData, 'oidFalecido', null)
    const oidLocalizacao = _.get(rowData, 'oidLocalizacao', null)
    const oidContrato = _.get(rowData, 'oidContrato', null)
    const oidDocumento = _.get(rowData, 'oidDocumento', null)

    params.oidResponsavel = oidResponsavel
    params.oidFalecido = oidFalecido
    params.oidLocalizacao = oidLocalizacao
    params.oidContrato = oidContrato

    this.props.requestImprimirDocumento({ oidDocumento: rowData.oidDocumento, params })
  }

  assinarDocumento = (rowData) => {
    const url = _.get(rowData, 'link', null)
    if (url) {
      const win = window.open(url)
      if (win) {
        win.focus()
      }
    }
  }

  visualizarDocumento = (rowData) => {
    const url = _.get(rowData, 'urlDocumentoAssinado', null)
    if (url) {
      const win = window.open(url)
      if (win) {
        win.focus()
      }
    }
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Contratos" />
  }

  buildContent() {
    return (
      <div>
        <PortalClienteContratoList
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalClienteContratoListPageContainer)
