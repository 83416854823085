import React from 'react'
import { connect } from 'react-redux'
import { icons } from '../../../constants'
import { Toolbar } from 'primereact/toolbar'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { actions as religiaoActions } from '../../religiao/religiao.actions'
import { actions as categoriaActions } from '../../categoria/categoria.actions'
import { PessoaForm } from './PessoaForm'
import { actions } from '../pessoa.actions'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudPessoaState.currentRecord
  const lookupReligiaoBase = {
    records: state.crudReligiaoState.records,
    columns: state.crudReligiaoState.columns
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudReligiaoState.index,
    rows: state.crudReligiaoState.max,
    totalRecords: state.crudReligiaoState.count,
    sortField: state.crudReligiaoState.sortField,
    order: state.crudReligiaoState.sortOrder,
    editDisabled: !record.oidReligiao,
  }
  const lookupCategoriaBase = {
    records: state.crudCategoriaState.records,
    columns: state.crudCategoriaState.columns
      .filter((col) => col.field === 'oid' || col.field === 'descricao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudCategoriaState.index,
    rows: state.crudCategoriaState.max,
    totalRecords: state.crudCategoriaState.count,
    sortField: state.crudCategoriaState.sortField,
    order: state.crudCategoriaState.sortOrder,
    editDisabled: !record.oidCategoria,
  }
  let lookupReligiao = Object.assign({}, state.crudPessoaState.lookupReligiao, lookupReligiaoBase)
  let lookupCategoria = Object.assign({}, state.crudPessoaState.lookupCategoria, lookupCategoriaBase)

  return {
    record,
    lookupReligiao,
    lookupCategoria,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lookupReligiaoSetVisible: (visible) => dispatch(actions.setLookupReligiaoVisible(visible)),
    lookupReligiaoPaginate: (paginationParams) => dispatch(religiaoActions.paginate(paginationParams)),
    lookupReligiaoSortOrder: (sortParams) => dispatch(religiaoActions.sortOrder(sortParams)),
    lookupReligiaoApplyFilter: (filterParams) => {
      dispatch(religiaoActions.setType(null))
      dispatch(religiaoActions.applyFilter(filterParams))
    },
    lookupReligiaoLoad: () => dispatch(religiaoActions.load()),
    setReligiao: (religiao) => {
      dispatch(actions.setField('oidReligiao', religiao != null ? religiao.oid : null))
      dispatch(actions.setField('nomeReligiao', religiao != null ? religiao.nome : ''))
    },

    lookupCategoriaSetVisible: (visible) => dispatch(actions.setLookupCategoriaVisible(visible)),
    lookupCategoriaPaginate: (paginationParams) => dispatch(categoriaActions.paginate(paginationParams)),
    lookupCategoriaSortOrder: (sortParams) => dispatch(categoriaActions.sortOrder(sortParams)),
    lookupCategoriaApplyFilter: (filterParams) => {
      dispatch(categoriaActions.setType(null))
      dispatch(categoriaActions.applyFilter(filterParams))
    },
    lookupCategoriaLoad: () => dispatch(categoriaActions.load()),
    setCategoria: (categoria) => {
      dispatch(actions.setField('oidCategoria', categoria != null ? categoria.oid : null))
      dispatch(actions.setField('descricaoCategoria', categoria != null ? categoria.descricao : ''))
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'beneficiarioINSS') {
        value = target.checked
      } else if (field === 'cep' && value.replace('_', '').length === 9) {
        value = value.replace('-', '')
      }
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class PessoaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)

    actions.beforeFetchRecordSuccess = (data) => {
      if (data) {
        //backend envia data como 'long'
        if (data.dataNascimento) {
          data.dataNascimento = new Date(data.dataNascimento)
        }
        if (data.dataFalecimento) {
          data.dataFalecimento = new Date(data.dataFalecimento)
        }
      }
      return data
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PESSOA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_PESSOA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PESSOA}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA}`)
  }

  lookupReligiaoOnPage = (e) => {
    this.props.lookupReligiaoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupReligiaoOnSort = (e) => {
    this.props.lookupReligiaoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupReligiaoOnFilter = (e) => {
    const filters = []
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      })
    })
    this.props.lookupReligiaoApplyFilter(filters)
  }
  lookupReligiaoOnClick = (e) => {
    this.props.lookupReligiaoLoad()
    this.props.lookupReligiaoSetVisible(true)
  }
  lookupReligiaoOnHide = (e) => {
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnConfirm = (e) => {
    this.props.setReligiao(e.selection)
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnCancel = (e) => {
    this.props.setReligiao(null)
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnEdit = () => {
    if (this.props.record.oidReligiao) {
      this.props.history.push(`${routes.PATH_RELIGIAO}/${this.props.record.oidReligiao}`)
    }
  }
  lookupReligiaoOnComplete = (e) => {
    console.log(e)
  }

  lookupReligiaoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filters = [
        {
          campo: 'nome',
          valor: e.value,
          tipoFiltro: 'CONTEM',
        },
      ]
      this.props.setField('religiao', e.value)
      this.props.setField('oidReligiao', null)
      this.props.lookupReligiaoApplyFilter(filters)
    } else {
      this.props.setReligiao(value)
    }
  }

  lookupCategoriaOnPage = (e) => {
    this.props.lookupCategoriaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupCategoriaOnSort = (e) => {
    this.props.lookupCategoriaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupCategoriaOnFilter = (e) => {
    const filters = []
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      })
    })
    this.props.lookupCategoriaApplyFilter(filters)
  }
  lookupCategoriaOnClick = (e) => {
    this.props.lookupCategoriaLoad()
    this.props.lookupCategoriaSetVisible(true)
  }
  lookupCategoriaOnHide = (e) => {
    this.props.lookupCategoriaSetVisible(false)
  }
  lookupCategoriaOnConfirm = (e) => {
    this.props.setCategoria(e.selection)
    this.props.lookupCategoriaSetVisible(false)
  }
  lookupCategoriaOnCancel = (e) => {
    this.props.setCategoria(null)
    this.props.lookupCategoriaSetVisible(false)
  }

  lookupCategoriaOnEdit = () => {
    if (this.props.record.oidCategoria) {
      this.props.history.push(`${routes.PATH_CATEGORIA}/${this.props.record.oidCategoria}`)
    }
  }
  lookupCategoriaOnComplete = (e) => {
    console.log(e)
  }

  lookupCategoriaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filters = [
        {
          campo: 'descricao',
          valor: e.value,
          tipoFiltro: 'CONTEM',
        },
      ]
      this.props.setField('categoria', e.value)
      this.props.setField('oidCategoria', null)
      this.props.lookupCategoriaApplyFilter(filters)
    } else {
      this.props.setCategoria(value)
    }
  }

  render() {
    const content = (
      <PessoaForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        lookupReligiao={this.props.lookupReligiao}
        lookupReligiaoOnHide={this.lookupReligiaoOnHide}
        lookupReligiaoOnConfirm={this.lookupReligiaoOnConfirm}
        lookupReligiaoOnCancel={this.lookupReligiaoOnCancel}
        lookupReligiaoOnPage={this.lookupReligiaoOnPage}
        lookupReligiaoOnSort={this.lookupReligiaoOnSort}
        lookupReligiaoOnFilter={this.lookupReligiaoOnFilter}
        lookupReligiaoOnClick={this.lookupReligiaoOnClick}
        lookupReligiaoOnEdit={this.lookupReligiaoOnEdit}
        lookupReligiaoOnComplete={this.lookupReligiaoOnComplete}
        lookupReligiaoOnInputFilter={this.lookupReligiaoOnInputFilter}
        lookupCategoria={this.props.lookupCategoria}
        lookupCategoriaOnHide={this.lookupCategoriaOnHide}
        lookupCategoriaOnConfirm={this.lookupCategoriaOnConfirm}
        lookupCategoriaOnCancel={this.lookupCategoriaOnCancel}
        lookupCategoriaOnPage={this.lookupCategoriaOnPage}
        lookupCategoriaOnSort={this.lookupCategoriaOnSort}
        lookupCategoriaOnFilter={this.lookupCategoriaOnFilter}
        lookupCategoriaOnClick={this.lookupCategoriaOnClick}
        lookupCategoriaOnEdit={this.lookupCategoriaOnEdit}
        lookupCategoriaOnComplete={this.lookupCategoriaOnComplete}
        lookupCategoriaOnInputFilter={this.lookupCategoriaOnInputFilter}
      />
    )
    const title = `Pessoa ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar>
            <div className="p-toolbar-group-left">
              <Button
                onClick={this.props.saveHandler}
                label="Salvar"
                icon="pi pi-save"
                style={{ marginRight: '.25em' }}
                className="p-button-success"
              />
              <Button
                onClick={this.props.removeHandler}
                label="Remover"
                icon="pi pi-trash"
                style={{ marginRight: '.25em' }}
                className="p-button-danger"
              />
              <Button
                onClick={this.props.newHandler}
                label="Novo"
                icon={icons.PLUS_ICON}
                style={{ marginRight: '.25em' }}
              />
              <Button
                onClick={this.searchHandler}
                label="Pesquisar"
                icon="pi pi-search"
                style={{ marginRight: '.25em' }}
              />
            </div>
          </Toolbar>
        </div>
      </div>
    )
    return <Page header={header} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaFormPageContainer)
