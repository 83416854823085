import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import './lts-lookup-nota-entrada.scss'
import LtsLookup from './lts-lookup'
import { InputText } from 'primereact/inputtext'

export default function LtsLookupNotaEntrada(props) {
  const [filters, setFilters] = useState([])

  const columns = [
    {
      field: 'oid',
      header: 'Id',
      columnKey: 'oid',
      sortable: false,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'chave',
      header: 'Chave',
      columnKey: 'chave',
      columnSortField: 'chave',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'numero',
      header: 'Número',
      columnKey: 'numero',
      columnSortField: 'numero',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'serie',
      header: 'Série',
      columnKey: 'serie',
      columnSortField: 'serie',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'fornecedor_nomeFantasia',
      header: 'Nome fantasia',
      columnKey: 'fornecedor_nomeFantasia',
      columnSortField: 'fornecedor.nomeFantasia',
      relacionamento: ['fornecedor'],
      fieldJoin: 'oid',
      sortable: true,
      filter: true,
      visible: true,
    },
    {
      field: 'fornecedor_nome',
      header: 'Razão social',
      columnKey: 'fornecedor_nome',
      columnSortField: 'fornecedor.nome',
      relacionamento: ['fornecedor'],
      fieldJoin: 'oid',
      sortable: true,
      filter: true,
      visible: true,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/notaentrada/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (typeof value === 'string') {
      const filters = [new Filtro('numero', value, 'CONTEM')]
      setFilters(filters)
    } else if (value) {
      const descricao = _.get(value, 'numero', null)
      const filters = [new Filtro('numero', descricao, 'CONTEM')]
      setFilters(filters)
    }
  }

  const pathOnEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    if (oid) {
      return `${routes.PATH_NOTA_ENTRADA}/${oid}`
    } else {
      return `${routes.PATH_NOTA_ENTRADA}/novo`
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        numero: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const autoCompleteTemplate = (data) => {
    return `${data.numero} - ${data.serie}`
  }

  const onResolvingColumn = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-codigo-body-width"
            filterHeaderClassName="column-codigo-filter-header-width"
            headerClassName="column-codigo-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'chave') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-chave-acesso-body-width"
            filterHeaderClassName="column-chave-acesso-filter-header-width"
            headerClassName="column-chave-acesso-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'numero') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-codigo-body-width"
            filterHeaderClassName="column-codigo-filter-header-width"
            headerClassName="column-codigo-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'serie') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-codigo-body-width"
            filterHeaderClassName="column-codigo-filter-header-width"
            headerClassName="column-codigo-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'fornecedor_nomeFantasia') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-fornecedor-body-width"
            filterHeaderClassName="column-fornecedor-filter-header-width"
            headerClassName="column-fornecedor-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'fornecedor_nome') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-fornecedor-body-width"
            filterHeaderClassName="fornecedor-filter-header-width"
            headerClassName="column-fornecedor-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      return null
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {

    if (props.somenteSemLancamento) {
      filters.push({
        campo: 'lancamentoFinanceiro.oid',
        valor: null,
        tipoFiltro: 'NULO',
        relacionamento: ['lancamentoFinanceiro'],
        tipoJoin: 'LEFT'
      })
    }

    return filters
  }

  const resolveValue = (data) => {
    return _.get(props, 'value', null)
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={resolveValue()}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onKeyPress={onKeyPress}
        onComplete={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEditFn={pathOnEditFn}
        header={props.label}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        disabled={props.disabled}
        dialogWidth={'75vw'}
      />
    </React.Fragment>
  )
}
