import { Dialog } from 'primereact/dialog'
import utils from '../../utils'
import { Button } from 'primereact/button'
import { icons, enums } from '../../constants'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import LtsCurrency from '../../components/lottus/lts-currency'
import LtsCalendar from '../../components/lottus/lts-calendar'
import LtsDropdownIdValue from '../../components/lottus/lts-dropdown-id-value'
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function BaixarLancamentoModal(props) {
  const [valor, setValor] = useState(0)
  const [data, setData] = useState(new Date())
  const [formaPagamento, setFormaPagamento] = useState()

  const formasPagamento = props.formasPagamento.filter(
    (fp) => fp.id !== enums.ID_FORMA_PAGTO_A_DEFINIR && fp.id !== enums.ID_FORMA_PAGTO_SEGURADORA
  )

  useEffect(() => {
    resetValues()
    calcularTotal()
  }, [props.visible])

  const calcularTotal = () => {
    let total = 0
    const lancamentos = _.get(props, 'lancamentosBaixar', [])
    lancamentos.map((l) => (total += _.get(l, 'valor', 0)))
    setValor(total)
  }

  const isConfirmarDisabled = () => {
    return !(valor > 0) || !data || !formaPagamento || !utils.isIdValueIdValido(formaPagamento)
  }

  const onHide = () => {
    props.onHide()
  }

  const onConfirm = () => {
    props.onConfirm({
      valor,
      data,
      formaPagamento,
      lancamentos: _.get(props, 'lancamentosBaixar', []),
    })
    onHide()
  }

  const resetValues = () => {
    setValor(0)
    setData(new Date())
    setFormaPagamento(null)
  }

  return (
    <Dialog
      header={_.defaultTo(props.labelBaixar, 'Baixar')}
      visible={props.visible}
      style={{ width: '30vw' }}
      onHide={() => {
        onHide()
      }}
      baseZIndex={1000000}
      modal={true}
      closable={true}
    >
      <div style={{ marginTop: '20px' }} className="p-grid p-fluid">
        <div className="form-group p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
          <LtsCurrency
            value={valor}
            name={'valorBaixa'}
            label={_.defaultTo(props.labelValorPago, 'Valor Pago')}
            onChange={(e) => setValor(e)}
            disabled={props.bloquearLancamento}
          />
        </div>
        <div className="p-col-12 p-sm-12 p-md-10 p-lg-8 p-xl-6">
          <LtsCalendar
            className={'width-15-rem'}
            value={data}
            name={'data'}
            label={_.defaultTo(props.labelDataBaixa, 'Data Baixa')}
            onChange={(e) => setData(e)}
            disabled={props.bloquearLancamento}
          />
        </div>

        <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
          <LtsDropdownIdValue
            name={'formaPagamentoBaixa'}
            value={formaPagamento}
            onChange={(e) => setFormaPagamento(e)}
            label={_.defaultTo(props.labelFormaPagamento, 'Forma de Pagamento')}
            style={{ width: '200px' }}
            options={formasPagamento}
          />
        </div>
      </div>
      <div style={{ marginTop: '20px' }} className="p-grid form-group">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <Button
            label={_.defaultTo(props.labelConfirmar, 'Confirmar')}
            onClick={() => {
              onConfirm()
            }}
            disabled={isConfirmarDisabled()}
            icon={icons.CHECK_ICON}
            className="p-button-success"
          />
        </div>
      </div>
    </Dialog>
  )
}
