import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import apiServiceIdValue from '../../services/api-id-value-service'
import utils from '../../utils/index'
import { messageActions } from '../../store/message/index'
import { actions as taxaActions } from '../taxa/taxa.actions'
import { actions as actionsRemessa } from '../consultaRemessa/consultaRemessa.actions'
import _ from 'lodash'

const BASE_PATH = '/lottustech/lancamentosfinanceiros'
const BASE_PATH_ITEM_LANCAMENTO = '/lottustech/itemlancamento'

const crud = build(validate, BASE_PATH)
const prefix = 'crudLancamento'

const newTypes = {
  SET_LOOKUP_TAXA_VISIBLE: `${prefix}setLookupTaxaVisible`,
  SET_LOOKUP_PAGADOR_VISIBLE: `${prefix}setLookupPagadorVisible`,
  SET_LOOKUP_CONTRATO_VISIBLE: `${prefix}setLookupContratoVisible`,
  SET_LOOKUP_NOTAENTRADA_VISIBLE: `${prefix}setLookupNotaEntradaVisible`,
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  SET_DISPLAY_FORM: `${prefix}setDisplayForm`,
  SET_DADO_FORMA_PAGAMENTO: `${prefix}setDadoFormaPagamento`,
  ADICIONAR_PAGAMENTO: `${prefix}adicionarPagamento`,
  CLEAR_FORM_PAGAMENTO: `${prefix}clearFormPagamento`,
  SET_SELECTED_PARCELAS: `${prefix}.setSelectedParcelas`,
  CLEAR_SELECTED_PARCELAS: `${prefix}.clearSelectedParcelas`,
  BOFORE_SAVE: `${prefix}.beforeSave`,
  RESET: `${prefix}reset`,
  SET_OID_TAXA_PRINCIPAL: `${prefix}setOidTaxaPrincipal`,
  SET_CARTOES_PAGADOR: `${prefix}setCartoesPagador`,
  ADD_ITEM_LANCAMENTO: `${prefix}addItemLancamento`,
  SET_FORMA_PAGAMENTO_ORIGINAL: `${prefix}setFormaPagamentoOriginal`,
  ADD_ANEXO: `${prefix}addAnexo`,
  REMOVE_ANEXO: `${prefix}removeAnexo`,
  SET_CONTAS_CORRENTES: `${prefix}setContasCorrentes`,
  SET_ADICIONANDO_ANEXO: `${prefix}adicionandoAnexo`,
  SET_CONTAS_CORRENTES_PARCELAS: `${prefix}setContasCorrentesParcelas`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setFormaPagamentoOriginal = (payload) => {
  return {
    type: types.SET_FORMA_PAGAMENTO_ORIGINAL,
    payload,
  }
}

actions.setOidTaxaPrincipal = (value) => {
  return {
    type: types.SET_OID_TAXA_PRINCIPAL,
    value,
  }
}

actions.setCartoesPagador = (payload) => {
  return {
    type: types.SET_CARTOES_PAGADOR,
    payload,
  }
}

actions.addItemLancamento = (data, cobertura) => {
  return {
    type: types.ADD_ITEM_LANCAMENTO,
    data,
    cobertura,
  }
}

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

actions.setDisplayForm = (field, value) => {
  return {
    type: types.SET_DISPLAY_FORM,
    field,
    value,
  }
}

actions.setDadoFormaPagamento = (field, value) => {
  return {
    type: types.SET_DADO_FORMA_PAGAMENTO,
    field,
    value,
  }
}

actions.adicionarPagamento = (field, value) => {
  return {
    type: types.ADICIONAR_PAGAMENTO,
    field,
    value,
  }
}

actions.clearFormPagamento = () => {
  return {
    type: types.CLEAR_FORM_PAGAMENTO,
  }
}

actions.clearSelectedParcelas = () => {
  return {
    type: types.CLEAR_SELECTED_PARCELAS,
  }
}

actions.setLookupTaxaVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_TAXA_VISIBLE,
    visible,
  }
}

actions.setLookupPagadorVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_PAGADOR_VISIBLE,
    visible,
  }
}

actions.setLookupContratoVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CONTRATO_VISIBLE,
    visible,
  }
}

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.setSelectedParcelas = (records) => {
  return {
    type: types.SET_SELECTED_PARCELAS,
    records,
  }
}

actions.beforeSave = () => {
  return {
    type: types.BOFORE_SAVE,
  }
}

actions.addAnexo = (payload) => {
  return {
    type: types.ADD_ANEXO,
    payload,
  }
}

actions.setAdicionandoAnexo = (payload) => {
  return {
    type: types.SET_ADICIONANDO_ANEXO,
    payload,
  }
}

actions.removeAnexo = (payload) => {
  return {
    type: types.REMOVE_ANEXO,
    payload,
  }
}

actions.setContasCorrentes = (payload) => {
  return {
    type: types.SET_CONTAS_CORRENTES,
    payload,
  }
}

actions.setContasCorrentesParcelas = (payload) => {
  return {
    type: types.SET_CONTAS_CORRENTES_PARCELAS,
    payload,
  }
}

actions.aplicarFiltrosDadosTaxa = (filterParams) => {
  return (dispatch, getState) => {
    const state = getState()[`crudLancamentoState`]
    let record = state.currentRecord
    if (utils.nonNull(filterParams)) {
      dispatch(
        taxaActions.setSelectedFilters(filterParams.campo, filterParams.valor, filterParams.relacionamento, null)
      )
    }
    if (record.unidade && record.unidade.id) {
      const filtro = {
        campo: 'oid',
        valor: record.unidade.id,
        tipoFiltro: 'IGUAL_A',
        fieldJoin: 'oid',
        relacionamento: ['unidade'],
      }
      dispatch(taxaActions.setFiltroAvancado(filtro))
    }
    dispatch(taxaActions.loadLookup())
  }
}

actions.gerarParcelas = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLancamentoState`]
    const record = state.currentRecord
    const oidItemPrincipal = _.get(record, 'oidItemPrincipal', null)
    const tipoItemPrincipal = _.get(record, 'tipoItemPrincipal', null)
    let pagamentoForm = Object.assign({}, state.pagamento)
    if (pagamentoForm.valor || pagamentoForm.valorEntrada) {
      pagamentoForm.valorEntrada = utils.parseCurrency(pagamentoForm.valorEntrada)
      pagamentoForm.valor = utils.parseCurrency(pagamentoForm.valor)
      pagamentoForm.valorParcela = utils.parseCurrency(pagamentoForm.valorParcela)
      pagamentoForm.oidItemPrincipal = oidItemPrincipal
      pagamentoForm.tipoItemPrincipal = tipoItemPrincipal
      apiService.post(`${BASE_PATH}/gerarParcelas`, pagamentoForm).then((response) => {
        dispatch(actions.adicionarPagamento('lancamentos', response.data))
      })
    } else {
      dispatch(actions.clearFormPagamento())
    }
  }
}

actions.enviarMensagem = (oid, tipoMensagem) => {
  return (dispatch, getState) => {
    apiService.post(`${BASE_PATH}/enviarmensagem/${oid}/${tipoMensagem}`).then((response) => {
      if (actions.carregarMensageria) {
        actions.carregarMensageria(response.data)
      }
    })
  }
}

actions.getPacoteTaxas = (taxaSelecionada) => {
  return (dispatch, getState) => {
    if (taxaSelecionada && parseInt(taxaSelecionada.oid) > 0) {
      apiService
        .get(`/lottustech/taxas/pacote/${taxaSelecionada.oid}`)
        .then((response) => {
          if (response.data) {
            actions.onResponseGetPacoteTaxas(taxaSelecionada, response.data)
          }
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.estornarLancamento = (oidsLancamentosEstornar) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const record = state.currentRecord
    if (oidsLancamentosEstornar && oidsLancamentosEstornar.length > 0) {
      const params = {
        oidLancamento: _.get(record, 'oid', null),
        oidsLancamentosEstornar,
      }
      apiService
        .post(`${BASE_PATH}/estornar`, params)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Operação realizada'], 'success')))
          const data = _.get(response, 'data', null)
          const oid = _.get(data, 'oid', null)
          if (oid) {
            actions.beforeFetchRecordSuccess(data)
            dispatch(actions.setRecord(data))
            if (actions.afterSave) {
              actions.afterSave(data)
            }
          } else {
            if (actions.afterSave) {
              actions.afterSave(record)
            }
          }
          dispatch(actions.clearSelectedParcelas())
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.findItemLancamento = (tipo, oid, cobertura) => {
  return (dispatch, getState) => {
    if (tipo && oid) {
      apiService
        .get(`${BASE_PATH_ITEM_LANCAMENTO}/find/${tipo}/${oid}`)
        .then((response) => {
          const data = _.get(response, 'data', null)
          dispatch(actions.addItemLancamento(data, cobertura))
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.executarBaixaLancamento = (params) => {
  return (dispatch, getState) => {
    if (params && parseInt(params.oidLancamento) > 0) {
      const paramsBaixa = {
        oidLancamento: params.oidLancamento,
        valorPago: utils.parseStringToNumber(params.valorPago),
        dataPagamento: params.dataPagamento,
        formaPagamento: params.formaPagamento,
      }

      apiService
        .post(`${BASE_PATH}/executarBaixaLancamento`, paramsBaixa)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Baixa realizada com sucesso!'], 'success')))
          actions.beforeFetchRecordSuccess(response.data)
          dispatch(actions.setRecord(response.data))
          if (actions.afterSave) {
            actions.afterSave(response.data)
          }
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.executarBaixaLancamentos = (params) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const record = state.currentRecord
    apiService
      .post(`${BASE_PATH}/executarBaixaLancamentos`, params)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Baixa realizada com sucesso!'], 'success')))
        dispatch(actions.loadById(record.oid))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.baixarLancamentos = (parcelas) => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const record = state.currentRecord
    if (parcelas && parcelas.length > 0) {
      apiService
        .post(`${BASE_PATH}/baixar`, parcelas)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Sucesso na baixa das parcelas!'], 'success')))
          if (actions.afterSave) {
            actions.afterSave(record)
          }
          dispatch(actions.clearSelectedParcelas())
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.baixarLancamentosGrid = (lancamentos) => {
  return (dispatch, getState) => {
    if (lancamentos && lancamentos.length > 0) {
      let paramsBaixaLancamento = []
      lancamentos.forEach((lancamento) => {
        let param = {
          oidLancamento: lancamento.oid,
          valorPago: lancamento.valor,
          dataPagamento: lancamento.dataPagamento,
        }
        paramsBaixaLancamento.push(param)
      })
      apiService
        .post(`${BASE_PATH}/baixarLancamentos`, paramsBaixaLancamento)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Operação realizada'], 'success')))
          dispatch(actions.load())
          dispatch(actions.clearSelectedRecords())
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'warn')))
          }
        })
    }
  }
}

actions.gerarBoletoLancamento = (lancamento) => {
  return (dispatch, getState) => {
    if (lancamento.oid) {
      apiService
        .get(`/lottustech/ailos/gerar/boleto/${lancamento.oid}`)
        .then((response) => {
          const win = window.open(response.data)
          win.focus()
        })
        .catch((err) => {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        })
    }
  }
}

actions.gerarCarneBoleto = (params) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/ailos/gerar/carneboleto/`, params)
      .then((response) => {
        const win = window.open(_.get(response, 'data.url'))
        if (response.data?.oidRemessa) {
          dispatch(actionsRemessa.downloadArquivo(_.get(response, 'data.oidRemessa')))
        }
        win.focus()
      })
      .catch((err) => {
        let message = _.get(err, 'response.data', '')
        const status = _.get(err, 'response.status', -1)
        if (status === 500) {
          message = 'Ocorreu um erro tentando gerar boleto(s)'
        }
        if (message && message.trim().length > 0) {
          dispatch(messageActions.messageShowMessages(makeMessages([message], 'error')))
        }
      })
  }
}

actions.gerarBoletoIndividual = (params) => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/ailos/gerar/boletoindividual/`, params)
      .then((response) => {
        const win = window.open(_.get(response, 'data.url'))
        if (response.data?.oidRemessa) {
          dispatch(actionsRemessa.downloadArquivo(_.get(response, 'data.oidRemessa')))
        }
        win.focus()
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.consultarStatusPagamentoVindi = ({ oidLancamentoFinanceiro }) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages(['Executando consulta. Aguarde!'], 'info')))
    apiService
      .get(`${BASE_PATH}/${oidLancamentoFinanceiro}/consultarPagamentoVindi`)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Consulta executada com sucesso'], 'success')))
        if (actions.beforeFetchRecordSuccess) {
          actions.beforeFetchRecordSuccess(response.data)
        }
        dispatch(actions.setRecord(response.data))
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.consultarStatusPagamentoAilos = ({ oidLancamentoFinanceiro }) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages(['Executando consulta. Aguarde!'], 'info')))
    apiService
      .get(`${BASE_PATH}/${oidLancamentoFinanceiro}/consultarPagamentoAilos`)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Consulta executada com sucesso'], 'success')))
        if (actions.beforeFetchRecordSuccess) {
          actions.beforeFetchRecordSuccess(response.data)
        }
        dispatch(actions.setRecord(response.data))
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.gerarCarne = (oidLancamentoFinanceiro) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}//gerarCarne`, oidLancamentoFinanceiro)
      .then((response) => {
        let url = _.get(response, 'data')
        if (url) {
          const win = window.open(url)
          win.focus()
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(['Erro ao gerar carnê'], 'error')))
        }
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.getBoletosVindi = ({ oidLancamentoFinanceiro }) => {
  return (dispatch, getState) => {
    apiService
      .get(`/lottustech/vindi/boletos/${oidLancamentoFinanceiro}`)
      .then((response) => {
        const array = _.get(response, 'data', [])
        if (utils.isArrayNotEmpty(array)) {
          array.forEach((url) => {
            const win = window.open(url)
            if (win) {
              win.focus()
            }
          })
        }
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.loadCartoesPagador = (oidPagador) => {
  return (dispatch, getState) => {
    apiService
      .get(`/lottustech/pessoas/${oidPagador}/cartoes`)
      .then((response) => {
        const cartoes = _.get(response, 'data', [])
        actions.setCartoesPagador(cartoes)
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.openUrl = (url) => {
  return (dispatch, getState) => {
    if (url) {
      const win = window.open(url)
      if (win) {
        win.focus()
      }
    }
  }
}

const makeMessages = (messages, type) => {
  let sticky = false
  let summary = ''
  if (type === 'error') {
    summary = ''
    sticky = true
  } else if (type === 'warn') {
    summary = ''
    sticky = true
  }
  return messages.map((message) => {
    return {
      sticky: sticky,
      summary: summary,
      severity: type,
      detail: message,
    }
  })
}

actions.showMessageConspagaEmDia = (ano) => {
  return (dispatch, getState) => {
    const messages = "Esse contrato está com a 'Conspaga' em dia. Paga até: " + ano
    dispatch(messageActions.messageShowMessages(makeMessages([messages], 'warn')))
  }
}

actions.showMessageTaxaConservacaoJaSelecionada = (ano) => {
  return (dispatch, getState) => {
    const messages = 'Esse lançamento já possui uma taxa de conservação'
    dispatch(messageActions.messageShowMessages(makeMessages([messages], 'warn')))
  }
}

actions.cancelarReabrir = (oidLancamento) => {
  return (dispatch, getState) => {
    apiService
      .get(`${BASE_PATH}/cancelarReabrir/${oidLancamento}`)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Operação realizada'], 'success')))
        actions.beforeFetchRecordSuccess(response.data)
        dispatch(actions.setRecord(response.data))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.processarArquivoRetornoBoleto = (data, conta, oidContaCorrente) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const message =
          "Arquivo enviado. Esse processo pode levar alguns minutos. Ao final um arquivo será gerado e o resultado também poderá ser visualizado na tela de 'Consulta de Baixas'."
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        const anexo = { filename: file.name, base64: encoded }
        const path = BASE_PATH + `/processar/arquivoRetornoViacredi`
        const params = {
          nomeArquivo: file.name,
          base64Arquivo: encoded,
          conta,
          oidContaCorrente,
        }
        apiService
          .post(path, params)
          .then((response) => {
            const arrayBuffer = base64ToArrayBuffer(response.data)
            createAndDownloadBlobFile(arrayBuffer, 'PhoenixRetorno_' + anexo.filename)
            dispatch(messageActions.messageShowMessages(makeMessages([''], 'success')))
          })
          .catch((err) => {
            if (err.response) {
              const messages = err.response.data
              dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
            }
          })
      }
    }
  }
}

actions.processarArquivoRetornoCelesc = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const message =
          "Arquivo enviado. Esse processo pode levar alguns minutos. Ao final um arquivo será gerado e o resultado também poderá ser visualizado na tela de 'Consulta de Baixas'."
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        const anexo = { filename: file.name, base64: encoded }
        const path = BASE_PATH + `/processar/importarRetornoCelesc` // TODO: Zappe ajustar url
        const params = {
          nomeArquivo: file.name,
          base64Arquivo: encoded,
        }
        apiService
          .post(path, params)
          .then((response) => {
            const arrayBuffer = base64ToArrayBuffer(response.data)
            createAndDownloadBlobFile(arrayBuffer, 'PhoenixRetorno_' + anexo.filename)
            dispatch(messageActions.messageShowMessages(makeMessages([''], 'success')))
          })
          .catch((err) => {
            if (err.response) {
              const messages = err.response.data
              dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
            }
          })
      }
    }
  }
}

actions.processarArquivoRetornoCergral = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const message =
          "Arquivo enviado. Esse processo pode levar alguns minutos. Ao final um arquivo será gerado e o resultado também poderá ser visualizado na tela de 'Consulta de Baixas'."
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        const anexo = { filename: file.name, base64: encoded }
        const path = BASE_PATH + `/processar/importarRetornoCergral`
        const params = {
          nomeArquivo: file.name,
          base64Arquivo: encoded,
        }
        apiService
          .post(path, params)
          .then((response) => {
            const arrayBuffer = base64ToArrayBuffer(response.data)
            createAndDownloadBlobFile(arrayBuffer, 'PhoenixRetorno_' + anexo.filename)
            dispatch(messageActions.messageShowMessages(makeMessages([''], 'success')))
          })
          .catch((err) => {
            if (err.response) {
              const messages = err.response.data
              dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
            }
          })
      }
    }
  }
}

actions.processarArquivoRetornoDebitoAutomatico = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      const file = data.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        const message =
          "Arquivo enviado. Esse processo pode levar alguns minutos. Ao final um arquivo será gerado e o resultado também poderá ser visualizado na tela de 'Consulta de Baixas'."
        dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
        const anexo = { filename: file.name, base64: encoded }
        const path = BASE_PATH + `/processar/importarRetornoDebitoAutomatico`
        const params = {
          nomeArquivo: file.name,
          base64Arquivo: encoded,
        }
        apiService
          .post(path, params)
          .then((response) => {
            const arrayBuffer = base64ToArrayBuffer(response.data)
            createAndDownloadBlobFile(arrayBuffer, 'PhoenixRetorno_' + anexo.filename)
            dispatch(messageActions.messageShowMessages(makeMessages([''], 'success')))
          })
          .catch((err) => {
            if (err.response) {
              const messages = err.response.data
              dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
            }
          })
      }
    }
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  return bytes.map((byte, i) => binaryString.charCodeAt(i))
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body])
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

actions.updateObservacaoNota = (grupos, oidContrato, ano, oidTaxa) => {
  return (dispatch, getState) => {
    const state = getState()[`crudLancamentoState`]
    const params = {
      oidContrato: oidContrato,
      oidsGrupos: grupos,
      ano: ano,
    }
    apiService
      .post(`${BASE_PATH}/getTemplateObservacaoNota`, params)
      .then((response) => {
        // agora temos que atualizar a informacao na grid de taxas
        //dispatch(actions.setField("observacaoNotaFiscal", response.data));
        state.currentRecord.taxas.forEach((t) => {
          if (t && t.taxa.id && t.taxa.id === oidTaxa) {
            t.descricaoServico = response.data
          }
        })
        dispatch(actions.setField('taxas', state.currentRecord.taxas))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.cancelarLancamentos = (lancamentos) => {
  return (dispatch, getState) => {
    let oidsLancamentoFinanceiros = lancamentos.map((l) => {
      return l.oid
    })
    apiService
      .post(`${BASE_PATH}/cancelarLote`, oidsLancamentoFinanceiros)
      .then((response) => {
        const result = response.data
        if (result) {
          dispatch(messageActions.messageShowMessages(makeMessages([result], 'warn')))
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(['Registros cancelados com sucesso!'], 'success')))
        }
        dispatch(actions.load())
        dispatch(actions.clearSelectedRecords())
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'warn')))
          dispatch(actions.load())
          dispatch(actions.clearSelectedRecords())
        }
      })
  }
}

actions.showWarningMessage = (message) => {
  return (dispatch, getState) => {
    dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
  }
}

actions.unirLancamentos = (oidLancamentoAtual, oidLancamentoExcluir) => {
  return (dispatch, getState) => {
    const params = {
      oidLancamentoUnificar: oidLancamentoAtual,
      oidLancamentoExcluir: oidLancamentoExcluir,
    }
    apiService
      .post(`${BASE_PATH}/unirLancamentos`, params)
      .then((response) => {
        dispatch(messageActions.messageShowMessages(makeMessages(['Operação realizada'], 'success')))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.getDateTimeFromServer = () => {
  return (dispatch, getState) => {
    apiService
      .getDateTimeFromServer()
      .then((response) => {
        const dateTimeInMili = _.get(response, 'data', null)
        let newDate = new Date()
        if (dateTimeInMili) {
          newDate = new Date(dateTimeInMili)
        }
        dispatch(actions.setField('emissao', newDate))
      })
      .catch((err) => {
        const messages = err.response.data
        dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
      })
  }
}

actions.gerarDebitoAutomatico = (oidsLancamentos) => {
  return (dispatch, getState) => {
    apiService
      .post(`${BASE_PATH}/gerarArquivoDebitoAutomatico`, oidsLancamentos)
      .then((response) => {
        let filename = 'debito.txt'
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch(messageActions.messageShowMessages(makeMessages(['Arquivo de débito gerado com sucesso'], 'success')))
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([error.response.data], 'error')))
      })
  }
}

actions.gerarNotaFiscalServico = (oidLancamento) => {
  return (dispatch, getState) => {
    if (oidLancamento) {
      dispatch(messageActions.messageShowMessages(makeMessages(['Solicitação em andamento, aguarde!'], 'warn')))
      return apiService
        .get(`${BASE_PATH}/gerarNotaFiscalServico/${oidLancamento}`)
        .then((response) => {
          dispatch(messageActions.messageShowMessages(makeMessages(['Geração da Nota Fiscal finalizada'], 'success')))
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

actions.abrirWebWhatsApp = ({ oidLancamento }) => {
  return (dispatch, getState) => {
    const msgErro = 'Não foi possível verificar o telefone do pagador'
    apiService
      .get(`${BASE_PATH}/${oidLancamento}/templateWebWhatsApp/`)
      .then((response) => {
        const link = _.get(response, 'data', null)
        if (link) {
          const win = window.open(link)
          if (win) {
            win.focus()
          }
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
        }
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

actions.carregarLancamentos = () => {
  return (dispatch, getState) => {
    const msgErro = 'Não foi possível buscar periodo listagem padrão'

    const state = getState()[`crudLancamentoState`]
    const filterValues = state.filterValues

    apiService
      .get(`${BASE_PATH}/periodoListagemPadrao`)
      .then((response) => {
        const result = _.get(response, 'data', null)
        const inicio = new Date(result?.dataInicial)
        const fim = new Date(result?.dataFinal)

        _.set(filterValues, ['emissao', 'tipoFiltro'], {
          label: 'Entre',
          value: 6,
          dataInicial: true,
          dataFinal: true,
        })
        _.set(filterValues, ['emissao', 'valor'], inicio)
        _.set(filterValues, ['emissao', 'valorComplementar'], fim)

        // dispatch(actions.setStateAttribute({filterValues: filterValues}))
        Object.assign(state, {
          selectedFilters: [
            {
              campo: 'emissao',
              valor: inicio,
              valorComplementar: fim,
              tipoFiltro: 6,
              chaveComponente: 'emissao',
            },
          ],
          filterValues: filterValues,
        })

        dispatch(actions.load())
      })
      .catch((error) => {
        dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
      })
  }
}

const TIPO_DESCONTO_VALOR = 1
const TIPO_DESCONTO_PERCENTUAL = 2
const TIPO_MULTA_VALOR = 1
const TIPO_MULTA_PERCENTUAL = 2
const TIPO_JUROS_VALOR = 1
const TIPO_JUROS_PERCENTUAL = 2
const LANCAMENTO_EM_ABERTO = 1

actions.getJurosMultaPadrao = (oidLancamento) => {
  return (dispatch, getState) => {
    const state = getState()[`crudLancamentoState`]
    const record = state.currentRecord

    let diasEmAtraso = getDiasEmAtraso(record)

    if (
      oidLancamento &&
      LANCAMENTO_EM_ABERTO === record.status &&
      diasEmAtraso > 0 &&
      !record.valorJuros &&
      !record.valorMulta &&
      !record.percentualJuros &&
      !record.percentualMulta
    ) {
      return apiService
        .get(`${BASE_PATH}/${oidLancamento}/jurosMultaPadrao`)
        .then((response) => {
          const result = _.get(response, 'data', null)

          dispatch(actions.setField('valorJuros', result?.valorJuros))
          dispatch(actions.setField('valorMulta', result?.valorMulta))
          dispatch(actions.setField('percentualJuros', result?.percentualJuros))
          dispatch(actions.setField('percentualMulta', result?.percentualMulta))

          dispatch(actions.setStateAttribute({ valorJurosPadrao: result?.valorJuros }))
          dispatch(actions.setStateAttribute({ valorMultaPadrao: result?.valorMulta }))
          dispatch(actions.setStateAttribute({ percentualJurosPadrao: result?.percentualJuros }))
          dispatch(actions.setStateAttribute({ percentualMultaPadrao: result?.percentualMulta }))

          if (result?.valorJuros) {
            dispatch(actions.setField('tipoJuros', TIPO_JUROS_VALOR))
          } else if (result?.percentualJuros) {
            dispatch(actions.setField('tipoJuros', TIPO_JUROS_PERCENTUAL))
            dispatch(actions.setField('jurosPorDia', true))
          }

          if (result?.valorMulta) {
            dispatch(actions.setField('tipoMulta', TIPO_MULTA_VALOR))
          } else if (result?.percentualMulta) {
            dispatch(actions.setField('tipoMulta', TIPO_MULTA_PERCENTUAL))
          }

          dispatch(actions.recalcularValorLancamento())
        })
        .catch((err) => {
          if (err.response) {
            const messages = err.response.data
            dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
          }
        })
    }
  }
}

const getDiasEmAtraso = (record) => {
  const dataVencimento = record.vencimento

  let diasEmAtraso = 0
  if (dataVencimento && utils.isDate(dataVencimento)) {
    const dataAtual = window.moment()
    diasEmAtraso = dataAtual.diff(dataVencimento, 'days')
  }
  return diasEmAtraso
}

const calcularPercentual = (valor, percentual) => {
  if (valor && percentual) {
    try {
      return parseFloat(((valor * percentual) / 100).toFixed(6))
    } catch (err) {
      //falha ao calcular percentual
    }
  }
  return 0
}
const calcularDesconto = (record) => {
  const tipoDesconto = _.get(record, 'tipoDesconto', -1)
  if (tipoDesconto === TIPO_DESCONTO_VALOR) {
    return _.get(record, 'valorDesconto', 0)
  }

  if (tipoDesconto === TIPO_DESCONTO_PERCENTUAL) {
    const valorBruto = _.get(record, 'valorBruto', 0)
    const percentual = _.get(record, 'percentualDesconto', 0)
    return calcularPercentual(valorBruto, percentual)
  }
  return 0
}

const calcularMulta = (record) => {
  const tipoMulta = _.get(record, 'tipoMulta', -1)
  if (tipoMulta === TIPO_MULTA_VALOR) {
    return _.get(record, 'valorMulta', 0)
  }

  if (tipoMulta === TIPO_MULTA_PERCENTUAL) {
    const valorBruto = _.get(record, 'valorBruto', 0)
    const percentual = _.get(record, 'percentualMulta', 0)
    return calcularPercentual(valorBruto, percentual)
  }

  return 0
}

const calcularJuros = (record) => {
  let valor = 0
  const tipoJuros = _.get(record, 'tipoJuros', -1)
  if (tipoJuros === TIPO_JUROS_VALOR) {
    valor = _.get(record, 'valorJuros', 0)
  } else if (tipoJuros === TIPO_JUROS_PERCENTUAL) {
    const valorBruto = _.get(record, 'valorBruto', 0)
    const percentual = _.get(record, 'percentualJuros', 0)
    valor = calcularPercentual(valorBruto, percentual)
  }

  const jurosDia = _.get(record, 'jurosPorDia', false)
  let diasAtraso = getDiasEmAtraso(record)
  if (jurosDia) {
    if (diasAtraso > 0) {
      valor *= diasAtraso
    } else {
      valor = 0
    }
  }
  return valor
}

actions.recalcularValorLancamento = () => {
  return (dispatch, getState) => {
    const state = getState()[`crudLancamentoState`]
    const record = state.currentRecord

    const valorBruto = _.get(record, 'valorBruto', 0)
    const desconto = calcularDesconto(record) || 0
    const multa = calcularMulta(record) || 0
    const juros = calcularJuros(record) || 0
    const valor = valorBruto + multa + juros - desconto

    dispatch(actions.setField('valor', valor))

    dispatch(actions.setStateAttribute({ descontoAplicado: utils.formatCurrency(desconto) }))
    dispatch(actions.setStateAttribute({ multaAplicada: utils.formatCurrency(multa) }))
    dispatch(actions.setStateAttribute({ jurosAplicados: utils.formatCurrency(juros) }))
  }
}

actions.downloadAnexo = (anexo) => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then((response) => {
        const arrayBuffer = base64ToArrayBuffer(response.data)
        createAndDownloadBlobFile(arrayBuffer, anexo.filename)
      })
    }
  }
}

actions.processaAnexo = (data) => {
  return (dispatch, getState) => {
    if (data && data.target && data.target.files && data.target.files.length > 0) {
      dispatch(actions.setAdicionandoAnexo(true))
      const msgErro = 'Erro ao adicionar anexo'

      const state = getState()[`${prefix}State`]
      const record = state.currentRecord
      const oidLancamentoFinanceiro = record.oid

      const file = data.target.files[0]
      const form = new FormData()
      form.append('anexo', file)

      apiService
        .postFormData(`${BASE_PATH}/${oidLancamentoFinanceiro}/addAnexo`, form, { nomeAnexo: file.name })
        .then((response) => {
          const anexos = _.get(response, 'data', null)
          dispatch(actions.addAnexo(anexos))
          dispatch(actions.setAdicionandoAnexo(false))
        })
        .catch((error) => {
          dispatch(messageActions.messageShowMessages(makeMessages([msgErro], 'error')))
          dispatch(actions.setAdicionandoAnexo(false))
        })
    }
  }
}

actions.getContasCorrentes = () => {
  return (dispatch, getState) => {
    apiService
      .post(`/lottustech/contascorrente/all`, {})
      .then((response) => {
        if (response.data && response.data.records?.length > 0) {
          dispatch(actions.setContasCorrentes(response.data.records))
        }
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

actions.getContasCorrentesParcelas = () => {
  return (dispatch, getState) => {
    const state = getState()[`${prefix}State`]
    const oidUnidade = _.get(state, 'currentRecord.unidade.id', -1)
    apiServiceIdValue
      .getContasCorrentes(oidUnidade)
      .then((response) => {
        const data = _.get(response, 'data', [])
        dispatch(actions.setContasCorrentesParcelas(data))
      })
      .catch((err) => {
        if (err.response) {
          const messages = err.response.data
          dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
        }
      })
  }
}

export { types, actions }
