const blankToNull = require("./../../../utils").blankToNull;

export function validate(record) {
  const errors = [];

  if (typeof record.valorMinimoPagamentoDivergente === "string") {
    record.valorMinimoPagamentoDivergente = record.valorMinimoPagamentoDivergente
      .replace("R$", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }
  if (typeof record.valorDesconto === "string") {
    record.valorDesconto = record.valorDesconto
      .replace("R$", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }
  if (typeof record.valorMulta === "string") {
    record.valorMulta = record.valorMulta
      .replace("R$", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }

  if (typeof record.valorJurosMora === "string") {
    record.valorJurosMora = record.valorJurosMora
      .replace("R$", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }

  if (typeof record.percentualDesconto === "string") {
    record.percentualDesconto = record.percentualDesconto
      .replace("%", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }

  if (typeof record.percentualMulta === "string") {
    record.percentualMulta = record.percentualMulta
      .replace("%", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }

  if (typeof record.percentualJurosMora === "string") {
    record.percentualJurosMora = record.percentualJurosMora
      .replace("%", "")
      .replace(/\./g, "")
      .replace(",", ".");
  }
  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
