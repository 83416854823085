import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { icons, routes } from '../../../constants'
import utils from '../../../utils'
import { actions } from '../tipoSepultura.actions'
import { TipoSepulturaForm } from './TipoSepulturaForm'
import storage from '../../../services/stoage.service'
import { actions as documentoActions } from '../../documento/documento.actions'
import { getColumnsLookupDocumento } from '../../../utils/lookupUtils'
import _ from 'lodash'
import { Filtro } from '../../../utils/Filtro'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { ConfirmDialog } from '../../../components/confirmDialog/ConfirmDialog'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudTipoSepulturaState.currentRecord
  let disableUnit = false
  const currentUnit = storage.getUnit()
  if (currentUnit && currentUnit.oid) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  const unidades = utils.getListIdValuesUnidadeDisponiveisUsuario()

  //documento//
  const documentos = record.documentos
  const documentoTempLookup = record.documentoTempLookup

  const lookupDocumentoBase = {
    records: state.crudDocumentoState.records,
    columns: getColumnsLookupDocumento(state.crudDocumentoState.columnsLookup),
    first: state.crudDocumentoState.index,
    rows: state.crudDocumentoState.max,
    totalRecords: state.crudDocumentoState.count,
    sortField: state.crudDocumentoState.sortField,
    order: state.crudDocumentoState.sortOrder,
    editDisabled: false,
  }
  const lookupDocumento = Object.assign({}, state.crudTipoSepulturaState.lookupDocumento, lookupDocumentoBase)

  return {
    disableUnit,
    unidades,
    record: state.crudTipoSepulturaState.currentRecord,
    messages: state.crudTipoSepulturaState.messages,
    documentos,
    documentoTempLookup,
    lookupDocumento,
    colunasTDocumentos: state.crudTipoSepulturaState.colunasTDocumentos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      const value = _.get(target, 'type', null) === 'checkbox' ? target.checked : target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
      dispatch(documentoActions.clearSelectedFilters())
    },
    clearSelectedFilters() {
      dispatch(documentoActions.clearSelectedFilters())
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    clearRecords: () => {
      dispatch(documentoActions.setRecords([]))
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    //LOOKUP DOCUMENTO//
    lookupDocumentoSetVisible: (visible) => dispatch(actions.setLookupDocumentoVisible(visible)),
    lookupDocumentoPaginate: (paginationParams) => dispatch(documentoActions.paginate(paginationParams)),
    lookupDocumentoSortOrder: (sortParams) => dispatch(documentoActions.sortOrder(sortParams)),
    lookupDocumentoApplyFilter: (filterParams) => {
      dispatch(documentoActions.setFiltroAvancado(filterParams))
      dispatch(documentoActions.loadLookup())
    },
    lookupDocumentoLoad: () => dispatch(documentoActions.loadLookup()),
    addDocumento: (documento) => {
      let result = {
        oid: documento.oid,
        nome: documento.nome,
      }
      dispatch(actions.addDocumento('documento', result))
    },
  }
}

class TipoSepulturaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      showConfirmarExcluirDocumento: false,
    }
    this.documentoExcluir = null
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TIPO_SEPULTURA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_TIPO_SEPULTURA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TIPO_SEPULTURA}`)
    }
    this.setField = props.setField
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TIPO_SEPULTURA}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
    this.setState({ showConfirmarExcluirDocumento: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  //LOOKUP DOCUMENTO - INICIO//
  lookupDocumentoOnPage = (e) => {
    this.props.lookupDocumentoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupDocumentoOnSort = (e) => {
    this.props.lookupDocumentoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupDocumentoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
          tipoFiltro: 'CONTEM',
        }
        this.props.lookupDocumentoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupDocumentoLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupDocumentoLoad()
    }
  }

  lookupDocumentoOnClick = (e) => {
    this.props.lookupDocumentoLoad()
    this.props.lookupDocumentoSetVisible(true)
  }
  lookupDocumentoOnHide = (e) => {
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnConfirm = (e) => {
    this.props.addDocumento(e.selection)
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnCancel = (e) => {
    this.props.clearSelectedFilters()
    this.props.lookupDocumentoSetVisible(false)
  }
  lookupDocumentoOnEdit = () => {
    if (this.props.record.oidDocumento) {
      return `${routes.PATH_DOCUMENTO}/${this.props.record.oidDocumento}`
    } else {
      return `${routes.PATH_DOCUMENTO}`
    }
  }

  lookupDocumentoOnComplete = (e) => {}

  lookupDocumentoOnKeyPress = (e) => {
    const documentoTempLookup = _.get(this, 'props.record.documentoTempLookup', null)
    if (documentoTempLookup) {
      const filtro = new Filtro('nome', documentoTempLookup.nome, 'CONTEM')
      this.props.lookupDocumentoApplyFilter(filtro)
    }
  }

  lookupDocumentoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const documentoTempLookup = { oid: null, nome: value }
      this.setField('documentoTempLookup', documentoTempLookup)
      this.props.clearRecords()
    } else {
      this.props.addDocumento(value)
      this.props.clearSelectedFilters()
      this.props.clearRecords()
    }
  }

  //LOOKUP DOCUMENTO - FIM//

  confirmarExclusaoDocumento = (rowData) => {
    this.documentoExcluir = rowData
    this.setState({ showConfirmarExcluirDocumento: true })
  }

  getDialogConfirmarExclusaoDocumento() {
    if (this.state.showConfirmarExcluirDocumento) {
      return (
        <ConfirmDialog
          visible={this.state.showConfirmarExcluirDocumento}
          confirm={this.removerDocumento}
          hideDialog={this.hideDialog}
        />
      )
    }
    return null
  }

  removerDocumento = () => {
    const documentos = _.get(this, 'props.record.documentos', [])
    const newDocs = documentos.filter((f) => f.oidDocumento !== this.documentoExcluir.oidDocumento)
    this.setField('documentos', newDocs)
  }

  render() {
    const actionDocumento = (rowData) => {
      return (
        <React.Fragment>
          <Button
            type="button"
            className="p-button-danger"
            icon={icons.REMOVE_ICON}
            tooltip={'Remover'}
            onClick={(e) => this.confirmarExclusaoDocumento(rowData)}
          />
        </React.Fragment>
      )
    }

    let colunasTDocumentos = this.props.colunasTDocumentos
      .filter((col) => col.visible)
      .map((col) => {
        col.showFilterMenuOptions = false
        col.showClearButton = false
        col.showFilterMenu = false
        if (col.field === 'nome') {
          return <Column {...col} key={col.field} />
        }
        if (col.field === 'acoes') {
          return (
            <Column
              {...col}
              key={col.field}
              body={actionDocumento}
              alignHeader={'center'}
              style={{ width: '20em', textAlign: 'center' }}
            />
          )
        }
        return null
      })

    const content = (
      <div>
        {this.getDialogConfirmarExclusaoDocumento()}
        <TipoSepulturaForm
          record={this.props.record}
          changeHandler={this.props.changeHandler}
          messages={this.props.messages}
          disableUnit={this.props.disableUnit}
          unidades={this.props.unidades}
          history={this.props.history}
          documentos={this.props.documentos}
          documentoTempLookup={this.props.documentoTempLookup}
          colunasTDocumentos={colunasTDocumentos}
          // LOOKUP DOCUMENTO
          lookupDocumento={this.props.lookupDocumento}
          lookupDocumentoOnKeyPress={this.lookupDocumentoOnKeyPress}
          lookupDocumentoOnHide={this.lookupDocumentoOnHide}
          lookupDocumentoOnConfirm={this.lookupDocumentoOnConfirm}
          lookupDocumentoOnCancel={this.lookupDocumentoOnCancel}
          lookupDocumentoOnPage={this.lookupDocumentoOnPage}
          lookupDocumentoOnSort={this.lookupDocumentoOnSort}
          lookupDocumentoOnFilter={this.lookupDocumentoOnFilter}
          lookupDocumentoOnClick={this.lookupDocumentoOnClick}
          lookupDocumentoOnEdit={this.lookupDocumentoOnEdit}
          lookupDocumentoOnComplete={this.lookupDocumentoOnComplete}
          lookupDocumentoOnInputFilter={this.lookupDocumentoOnInputFilter}
        />
      </div>
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TipoSepulturaFormPageContainer)
