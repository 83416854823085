import React from 'react';
import LancamentoListPageContainer from './LancamentoListPageContainer';

export class LancamentoListPage extends React.Component{
    
    render(){
      return (
        <LancamentoListPageContainer {...this.props}/>
      )
    }
}

