import React, { useEffect, useState } from 'react'

import './plano-cobertura.scss'
import _ from 'lodash'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { enums, icons } from '../../../constants'
import LtsCurrency from '../../../components/lottus/lts-currency'
import { formatarDinheiro } from '../../../utils/index'
import LtsInputNumber from '../../../components/lottus/lts-input-number'
import apiService from '../../../services/api.service'
import utils from '../../../utils'
import LtsLookupItemLancamento from '../../../components/lottus/lts-lookup-item-lancamento'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'
import LtsInputText from '../../../components/lottus/lts-input-text'
import LtsCalendar from '../../../components/lottus/lts-calendar'

export default function PlanoCobertura(props) {
  const [lookupValue, setLookupValue] = useState({
    oid: '',
    oidTipo: '',
    descricao: '',
    quantidade: '',
    valor: '',
  })

  useEffect(() => {}, [lookupValue.oidProduto, lookupValue.descricaoProduto, props.values])

  const columns = [
    {
      field: 'oidTipo',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.codigo'),
      columnKey: 'oidTipo',
      sortable: false,
      filter: false,
    },
    {
      field: 'descricao',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.descricao'),
      columnKey: 'descricao',
      sortable: false,
      filter: false,
    },
    {
      field: 'beneficiario',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.beneficiario'),
      columnKey: 'beneficiario',
      sortable: false,
      filter: false,
    },
    {
      field: 'observacao',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.observacao'),
      columnKey: 'observacao',
      sortable: false,
      filter: false,
    },
    {
      field: 'dataAdesao',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.dataAdesao'),
      columnKey: 'dataAdesao',
      sortable: false,
      filter: false,
    },
    {
      field: 'diasCarencia',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.diasCarencia'),
      columnKey: 'diasCarencia',
      sortable: false,
      filter: false,
    },
    {
      field: 'dataCarencia',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.dataCarencia'),
      columnKey: 'dataCarencia',
      sortable: false,
      filter: false,
    },
    {
      field: 'quantidade',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.quantidade'),
      columnKey: 'quantidade',
      sortable: false,
      filter: false,
    },
    {
      field: 'valor',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.valor'),
      columnKey: 'valor',
      sortable: false,
      filter: false,
    },
    {
      field: 'totalCobertura',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.totalCobertura'),
      columnKey: 'totalCobertura',
      sortable: false,
      filter: false,
    },
    {
      field: 'acoes',
      header: utils.getMessage(props.messages, 'contrato.label.plano.cobertura.acoes'),
      columnKey: 'acoes',
    },
  ]

  const resolveColumns = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oidTipo') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-codigo-servico-body-width"
            headerClassName="column-codigo-servico-header-width"
            filterHeaderClassName="column-codigo-servico-filter-header-width"
          />
        )
      }

      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-beneficiario-body-width"
            headerClassName="column-beneficiario-header-width"
            filterHeaderClassName="column-beneficiario-filter-header-width"
          />
        )
      }

      if (col.field === 'beneficiario') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-beneficiario-body-width"
            headerClassName="column-beneficiario-header-width"
            filterHeaderClassName="column-beneficiario-filter-header-width"
          />
        )
      }

      if (col.field === 'observacao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-observacao-body-width"
            headerClassName="column-observacao-header-width"
            filterHeaderClassName="column-observacao-filter-header-width"
          />
        )
      }

      if (col.field === 'dataAdesao' || col.field === 'dataCarencia') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }

      if (col.field === 'diasCarencia') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }

      if (col.field === 'quantidade') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
            filterHeaderClassName="column-quantidade-filter-header-width"
          />
        )
      }

      if (col.field === 'valor') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-valor-plano-body-width"
            headerClassName="column-valor-plano-header-width"
            filterHeaderClassName="column-valor-plano-filter-header-width"
            alignHeader={'right'}
          />
        )
      }

      if (col.field === 'totalCobertura') {
        return (
          <Column
            {...col}
            key={col.field}
            body={formatColumn}
            bodyClassName="column-valor-total-body-width"
            headerClassName="column-valor-total-header-width"
            filterHeaderClassName="column-total-valor-filter-header-width"
            alignHeader={'right'}
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={actions}
            bodyClassName="column-actions-servico-body-width"
            headerClassName="column-actions-servico-header-width"
            filterHeaderClassName="column-actions-servico-filter-header-width"
          />
        )
      }
      return null
    })
  }

  const actions = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => removeItem(e, rowData)}
          disabled={!props.podeRemoverCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      </div>
    )
  }

  const removeItem = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    const itemLancamento = _.get(data, 'itemLancamento', null)
    if (itemLancamento) {
      const itemRemoved = props.values.find((f) => f.itemLancamento === itemLancamento)
      const newItens = props.values.filter((f) => f !== itemRemoved)
      if (itemRemoved) {
        itemRemoved.ativo = false
        const result = {
          values: newItens,
          recordAdded: null,
          recordRemoved: itemRemoved,
        }
        props.onChange(result)
        resetLookupValue()
      }
    }
  }

  const onChangeTableValue = (rowData, value, field) => {
    rowData[field] = value
    const result = {
      values: props.values,
      recordAdded: rowData,
      recordRemoved: null,
    }
    props.onChange(result)
  }

  const formatColumn = (rowData, column) => {
    const field = _.get(column, 'field', '')
    if (field === 'oidTipo') {
      return _.get(rowData, 'itemLancamento.oidTipo', null)
    }
    if (field === 'quantidade') {
      const quantidade = _.get(rowData, 'quantidade', null)
      return (
        <LtsInputNumber
          value={quantidade}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="quantidade"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    if (field === 'dataAdesao') {
      const dataAdesao = _.get(rowData, 'dataAdesao', null)
      return (
        <LtsCalendar
          value={dataAdesao}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="dataAdesao"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    if (field === 'diasCarencia') {
      const diasCarencia = _.get(rowData, 'diasCarencia', null)
      return (
        <LtsInputNumber
          value={diasCarencia}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="diasCarencia"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    if (field === 'dataCarencia') {
      const dataCarencia = _.get(rowData, 'dataCarencia', null)
      return (
        <LtsCalendar
          value={dataCarencia}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="dataAdesao"
          inputStyle={{ height: '30px', maxWidth: '7rem', minWidth: '5rem', width: '7rem' }}
          disabled={true}
        />
      )
    }
    if (field === 'valor') {
      const valor = _.get(rowData, 'valor', '')
      return (
        <LtsCurrency
          value={valor}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="valor"
          inputStyle={{ height: '30px', maxWidth: '8rem', minWidth: '8rem', width: '8rem' }}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    if (field === 'totalCobertura') {
      let valor_ = _.get(rowData, 'valor', 0)
      let quantidade_ = _.get(rowData, 'quantidade', 0)
      let total_ = 0
      if (valor_ > 0 && quantidade_ > 0) {
        total_ = utils.multiply(quantidade_, valor_, 2)
      }
      _.set(rowData, 'totalCobertura', total_)

      return formatarDinheiro(total_)
    }
    if (field === 'observacao') {
      const observacao = _.get(rowData, 'observacao', null)
      return (
        <LtsInputText
          value={observacao}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          name="observacao"
          style={{ height: '30px', maxWidth: '14.5rem', minWidth: '10rem', width: '14.5rem' }}
          maxLength={255}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    if (field === 'beneficiario') {
      const beneficiario = _.get(rowData, 'beneficiario', null)
      return (
        <LtsLookupPessoa
          name={'beneficiario'}
          field={'beneficiario'}
          value={{
            id: _.get(beneficiario, 'oid', null),
            value: _.get(beneficiario, 'nome', null),
          }}
          oidRecord={_.get(beneficiario, 'oid', '')}
          history={props.history}
          onChange={(e) => onChangeTableValue(rowData, e, field)}
          showAddButton={false}
          editHidden={true}
          disabled={!props.podeAlterarCoberturaPlano && _.get(rowData, 'oid', '') !== ''}
        />
      )
    }
    return null
  }

  // lookup callback functions //

  const onChangeLookup = (e) => {
    const tipo = _.get(e, 'tipo', '')
    const oidTipo = _.get(e, 'oidTipo', '')
    const quantidade = 0
    if (tipo === 'TAXA') {
      if (oidTipo) {
        loadPacoteServicos(e)
        resetLookupValue()
      } else {
        setLookupValue({
          oid: '',
          oidTipo,
          descricao: _.get(e, 'descricao', ''),
          quantidade,
          valor: '',
        })
      }
    } else {
      if (oidTipo) {
        onSuccessLoadPacoteServicos(e, null)
        resetLookupValue()
      } else {
        setLookupValue({
          oid: '',
          oidTipo,
          descricao: _.get(e, 'descricao', ''),
          quantidade,
          valor: '',
        })
      }
    }
  }

  const loadPacoteServicos = (data) => {
    const oid = _.get(data, 'oidTipo', null)
    if (oid) {
      apiService
        .get(`/lottustech/itemlancamento/pacote/${oid}`)
        .then((resp) => onSuccessLoadPacoteServicos(data, resp))
        .catch(onErrorLoadPacoteServicos)
    }
  }

  /**
   *       const find = props.values.find((f) => f.oidProduto === oidTaxa)
   *       if (!find) {
   *         // Adicionar config se permite ou nao duplicar taxa?
   *         const recordAdded = {
   *           oid: '',
   *           oidTaxa,
   *           oidGrupoFinanceiro,
   *           descricaoServico,
   *           quantidade,
   *           valorCobertura,
   *         }
   *         const values = [...props.values, recordAdded]
   *         const result = {
   *           values,
   *           recordAdded,
   *           recordRemoved: null,
   *         }
   *         props.onChange(result)
   *       }
   */

  const isItemAlreadySelected = (item) => {
    const found = props.values.find(
      (f) =>
        _.get(f, 'itemLancamento.oidTipo', -1) === _.get(item, 'oidTipo', 0) &&
        _.get(f, 'itemLancamento.tipo', '') === _.get(item, 'tipo', '')
    )
    return !!found
  }

  const onSuccessLoadPacoteServicos = (itemSelected, response) => {
    const taxas = _.get(response, 'data', [])
    if (utils.isArrayNotEmpty(taxas)) {
      let novosServicos = []
      for (const taxa of taxas) {
        const itemLancamento = {
          tipo: _.get(taxa, 'tipo', null),
          oidTipo: _.get(taxa, 'oidTipo', null),
        }
        // const alreadySelected = isItemAlreadySelected(itemLancamento)
        // if (!alreadySelected) {
        const item = {
          oid: '',
          itemLancamento,
          valor: _.get(taxa, 'valor', 0),
          descricao: _.get(taxa, 'descricao', ''),
          quantidade: 1,
          diasCarencia: _.get(props, 'diasPadraoCarenciaCoberturaPlano', 1),
          dataAdesao: new Date(),
          ativo: true,
        }
        novosServicos.push(item)
        // }
      }

      const result = {
        values: [...props.values, ...novosServicos],
        recordAdded: {
          ...itemSelected,
          itemLancamento: {
            tipo: _.get(itemSelected, 'tipo', null),
            oidTipo: _.get(itemSelected, 'oidTipo', null),
          },
        },
        recordRemoved: null,
      }

      props.onChange(result)
    } else {
      const itemLancamento = {
        tipo: _.get(itemSelected, 'tipo', null),
        oidTipo: _.get(itemSelected, 'oidTipo', null),
      }
      // const alreadySelected = isItemAlreadySelected(itemLancamento)
      // if (!alreadySelected) {
      const item = {
        oid: '',
        itemLancamento,
        valor: _.get(itemSelected, 'valor', 0),
        descricao: _.get(itemSelected, 'descricao', ''),
        quantidade: 1,
        diasCarencia: _.get(props, 'diasPadraoCarenciaCoberturaPlano', 1),
        dataAdesao: new Date(),
        ativo: true,
      }
      const result = {
        values: [...props.values, item],
        recordAdded: {
          ...itemSelected,
          itemLancamento: {
            tipo: _.get(itemSelected, 'tipo', null),
            oidTipo: _.get(itemSelected, 'oidTipo', null),
          },
        },
        recordRemoved: null,
      }
      props.onChange(result)
      // }
    }
  }

  const onErrorLoadPacoteServicos = (error) => {
    resetLookupValue()
  }

  const resetLookupValue = () => {
    setLookupValue({
      oid: '',
      oidTaxa: '',
      oidGrupoFinanceiro: '',
      descricaoServico: '',
      quantidade: '',
      valorCobertura: '',
    })
  }

  return (
    <React.Fragment>
      <Card title={'Coberturas'}>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupItemLancamento
              header={'Produtos/Serviços'}
              label={'Produto/Serviço'}
              value={_.get(lookupValue, 'descricao', '')}
              history={props.history}
              onChange={(e) => onChangeLookup(e)}
              idUnidade={_.get(props, 'idUnidade', null)}
              filtroTipoTaxa={enums.TipoTaxa.ENTRADA}
              disabled={!props.podeCriarCoberturaPlano}
            />
          </div>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12">
            <DataTable
              emptyMessage=" "
              value={props.values}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              size={'small'}
              stripedRows={true}
              responsiveLayout="scroll"
              scrollable={true}
              scrollHeight="400px"
            >
              {resolveColumns()}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
