import React from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { LookupField } from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";
import "./GrupoForm.scss";

export const GrupoForm = props => {
  const messages = props.messages;
  const record = props.record;
  const changeHandler = props.changeHandler;

  let grupoFinanceiroPai = "";
  if (record.grupoFinanceiroPai) {
    grupoFinanceiroPai = { id: record.grupoFinanceiroPai.id, descricao: record.grupoFinanceiroPai.value };
  }
  let tipo = {};
  if (props.record.tipo) {
    tipo = props.tipos.find(con => con.value === props.record.tipo);
  }

  function getCardInformacoesServico() {
    return (
      <div className="p-col-12">
        <div className="card card-w-title">
          <h3>Informações do Serviço</h3>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
                <InputText value={record.cnae} onChange={changeHandler} name="cnae" />
                <label>CNAE</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputText value={record.codigoNaturezaOperacao} onChange={changeHandler} name="codigoNaturezaOperacao" />
                <label>Descrição CNAE</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputText
                value={record.itemListaServico}
                onChange={changeHandler}
                maxLength={10}
                keyfilter={/^[0-9]+$/}
                name="itemListaServico"
              />
                <label>Código do Serviço</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputText
                maxLength={500}
                value={record.descricaoItemListaServico}
                onChange={changeHandler}
                name="descricaoItemListaServico"
              />
                <label>Descrição do Serviço</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputText
                keyfilter={/^[0-9.,]+$/}
                value={record.aliquotaPis}
                maxLength={7}
                onBlur={props.onBlurValor}
                onChange={changeHandler}
                name="aliquotaPis"
              />
                <label>{utils.getMessage(messages, "grupofinanceiro.label.aliquotapis")}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputText
                keyfilter={/^[0-9.,]+$/}
                value={record.aliquotaIss}
                maxLength={7}
                onBlur={props.onBlurValor}
                onChange={changeHandler}
                name="aliquotaIss"
              />
                <label>{utils.getMessage(messages, "grupofinanceiro.label.aliquotaiss")}</label>
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-6">
              <span className="p-float-label">
              <InputTextarea
                value={record.discriminacaoServicoNfse}
                onChange={changeHandler}
                name="discriminacaoServicoNfse"
                rows={1}
                cols={200}
                autoResize={true}
              />
              <label>
                {utils.getMessage(messages, "grupofinanceiro.label.discriminacaoServicoNfse")}
              </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>
              {utils.getMessage(messages, "grupofinanceiro.label.grupo")} {record.oid || ""}
            </h3>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                <label className="label">{utils.getMessage(messages, "grupofinanceiro.label.codigomascarado")}</label>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                {record.codigoMascara}
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                <InputText value={record.codigo} onChange={changeHandler} name="codigo" />
                  <label>{utils.getMessage(messages, "grupofinanceiro.label.codigo")}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-6 p-xl-5">
                <span className="p-float-label">
                <InputText value={record.descricao} onChange={changeHandler} name="descricao" />
                  <label>{utils.getMessage(messages, "grupofinanceiro.label.descricao")}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-6 p-xl-5">
                <LookupField
                  label={utils.getMessage(messages, "grupofinanceiro.label.grupopai")}
                  value={grupoFinanceiroPai}
                  name="grupoFinanceiroPai"
                  field="descricao"
                  history={props.history}
                  onKeyPress={props.lookupGrupoOnKeyPress}
                  onComplete={props.lookupGrupoOnComplete}
                  onInputFilter={props.lookupGrupoOnInputFilter}
                  editDisabled={props.lookupGrupo.editDisabled}
                  onEditField={props.lookupGrupoOnEdit}
                  onClick={props.lookupGrupoOnClick}
                  visible={props.lookupGrupo.visible}
                  modal={props.lookupGrupo.modal}
                  header={props.lookupGrupo.header}
                  onHide={props.lookupGrupoOnHide}
                  onConfirm={props.lookupGrupoOnConfirm}
                  onCancel={props.lookupGrupoOnCancel}
                  records={props.lookupGrupo.records}
                  columns={props.lookupGrupo.columns}
                  totalRecords={props.lookupGrupo.totalRecords}
                  sortOrder={props.lookupGrupo.order}
                  sortField={props.lookupGrupo.sortField}
                  first={props.lookupGrupo.first}
                  rows={props.lookupGrupo.rows}
                  onPage={props.lookupGrupoOnPage}
                  onFilter={props.lookupGrupoOnFilter}
                  onSort={props.lookupGrupoOnSort}
                />
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-8 p-lg-6 p-xl-5">
                <span className="p-float-label">
                <Dropdown
                  autoWidth={false}
                  disabled={props.disableUnit}
                  value={utils.isEmptyObject(record.unidade) ? props.unidades[0] : record.unidade}
                  options={props.unidades}
                  onChange={props.onChangeUnit}
                  optionLabel="value"
                  optionValue={(v) => v}
                  dataKey="value"
                  name="unidade"
                />
                  <label>{utils.getMessage(messages, "grupofinanceiro.label.unidade")}</label>
                </span>
              </div>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                <span className="p-float-label">
                <Dropdown
                  autoWidth={false}
                  value={tipo}
                  options={props.tipos}
                  onChange={changeHandler}
                  optionLabel="label"
                  optionValue={(v) => v}
                  name="tipo"
                />
                  <label>{utils.getMessage(messages, "grupofinanceiro.label.tipo")}</label>
                </span>
              </div>
            </div>
          </div>
        </div>
        {getCardInformacoesServico()}
      </div>
    </div>
  );
};
