import React, {Component} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import storage from "../../services/stoage.service";
import utils from "../../utils/index";

export class ConfirmDialog extends Component {

    constructor(props) {
        super(props);
        this.messagesDialog = storage.getAppLabels("dialogs");
    }

    getHeaderText() {
        if (this.props.header) {
            return this.props.header;
        }
        return utils.getMessage(this.messagesDialog, "dialog.titulo.confirmacao");
    }

    getContentText() {
        if (this.props.content) {
            return this.props.content;
        }
        return utils.getMessage(this.messagesDialog, "dialog.mensagem.confirma.exclusao");
    }

    resolveFooter() {
        if (this.props.warning) {
            return (
                <div>
                    <Button
                        label="Ok"
                        onClick={() => {
                            this.props.hideDialog();
                        }}
                        className="p-button-warning"
                    />
                </div>
            );
        }

        return (
            <div>
                <Button
                    label="Sim"
                    icon="pi pi-check"
                    onClick={() => {
                        this.props.confirm();
                        this.props.hideDialog();
                    }}
                    className="p-button-success"
                />
                <Button label="Não" icon="pi pi-times" onClick={() => this.props.hideDialog()} className="p-button-danger"/>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Dialog
                    header={this.getHeaderText()}
                    visible={this.props.visible}
                    appendTo={document.body}
                    modal={true}
                    onHide={e => this.props.hideDialog()}
                    footer={this.resolveFooter()}
                    style={{color: "black"}}
                    closable={true}
                >
                    {this.getContentText()}
                </Dialog>
            </div>
        );
    }
}
