import { types } from './app.actions'
import { routes } from '../../constants'
import { ContratoListPage, ContratoFormPage } from '../../pages/contrato'
import { GrupoListPage, GrupoFormPage } from '../../pages/grupo'
import { TaxaListPage, TaxaFormPage } from '../../pages/taxa'
import { LancamentoListPage, LancamentoFormPage } from '../../pages/lancamentoFinanceiro'
import { PessoaFisicaListPage, PessoaFisicaFormPage } from '../../pages/pessoaFisica'
import { PessoaJuridicaListPage, PessoaJuridicaFormPage } from '../../pages/pessoaJuridica'
import { LocalizacaoListPage, LocalizacaoFormPage } from '../../pages/localizacao'
import { LocalizacaoGridListPage } from '../../pages/localizacaoGrid'
import { ItensLancamentoListPage, ItensLancamentoFormPage } from '../../pages/itensLancamentoFinanceiro'
import { BotaoDinamicoListPage, BotaoDinamicoFormPage } from '../../pages/botaoDinamico'
import { ConfiguracaoBoletoListPage, ConfiguracaoBoletoFormPage } from '../../pages/configuracaoBoleto'
import { DashboardListPage } from '../../pages/dashboard'
import { DocumentoListPage, DocumentoFormPage } from '../../pages/documento'

import { EmpresaListPage, EmpresaFormPage } from '../../pages/empresa'
import { UsuarioListPage, UsuarioFormPage } from '../../pages/usuario'
import { CategoriaListPage, CategoriaFormPage } from '../../pages/categoria'
import { ReligiaoListPage, ReligiaoFormPage } from '../../pages/religiao'
import { TipoSepulturaListPage, TipoSepulturaFormPage } from '../../pages/tipoSepultura'
import { SexoListPage, SexoFormPage } from '../../pages/sexo'
import { PerfilListPage, PerfilFormPage } from '../../pages/perfil'
import { AcaoListPage, AcaoFormPage } from '../../pages/acao'
import { PermissaoListPage, PermissaoFormPage } from '../../pages/permissao'
import { UnidadeListPage, UnidadeFormPage } from '../../pages/unidade'
import { MenuListPage, MenuFormPage } from '../../pages/menu'
import { FuncaoDinamicaListPage, FuncaoDinamicaFormPage } from '../../pages/funcaoDinamica'
import { ParametroFormPage } from '../../pages/parametrosUnidade'
import { ParametroEmpresaFormPage } from '../../pages/parametrosEmpresa'
import { RelatorioDinamicoListPage, RelatorioDinamicoFormPage } from '../../pages/relatorioDinamico'
import { DinamicaListPage } from '../../pages/dinamica'
import storage from '../../services/stoage.service'
import utils from '../../utils'
import { ControleFinanceiroListPage } from '../../pages/controleFinanceiro'
import { NfseListPage, NfseFormPage } from '../../pages/nfse'
import { LivroCaixaFormPage } from '../../pages/livroCaixa'
import { MovimentoCaixaFormPage } from '../../pages/movimentoCaixa'
import { DREFormPage } from '../../pages/dre'
import { AquisicoesRelatorioFormPage } from '../../pages/aquisicoesRelatorio'
import { CartaoCreditoRelatorioFormPage } from '../../pages/cartaoCreditoRelatorio'
import { ReceitasDespesasRelatorioFormPage } from '../../pages/receitasDespesasRelatorio'
import { FalecidosRelatorioFormPage } from '../../pages/falecidosRelatorio'
import { ExumacoesRelatorioFormPage } from '../../pages/exumacoesRelatorio'
import { IsentosRelatorioFormPage } from '../../pages/isentosRelatorio'
import { SepulturasDevedorasRelatorioFormPage } from '../../pages/sepulturasDevedorasRelatorio'
import { ContasReceberRelatorioFormPage } from '../../pages/contasReceberRelatorio'
import { ConsultaBaixaRelatorioFormPage } from '../../pages/consultaBaixaRelatorio'
import { TiposSepulturasRelatorioFormPage } from '../../pages/tiposSepulturasRelatorio'
import { MensageriaFormPage } from '../../pages/mensageria'
import { AgendamentoMensageriaFormPage, AgendamentoMensageriaListPage } from '../../pages/mensagens'
import { MensageriaLogFormPage, MensageriaLogListPage } from '../../pages/mensageriaLog'
import { ConsultaBaixaListPage, ConsultaBaixaFormPage } from '../../pages/consultaBaixa'
import { PortalClienteFinanceiroListPage } from '../../pages/portalClienteFinanceiro'
import { ConsultaFalecidoListPage } from '../../pages/consultaFalecido'
import { PortalClienteCarteirinhaListPage } from '../../pages/portalClienteCarteirinhas'
import { PortalClienteConsultaClienteFormPage } from '../../pages/portalClienteConsultaCliente/form/PortalClienteConsultaClienteFormPage'
import { ConsultaRemessaFormPage, ConsultaRemessaListPage } from '../../pages/consultaRemessa'
import { ConfigAilosFormPage } from '../../pages/configAilos'
import { AgendaListPage, AgendaFormPage } from '../../pages/agenda'

import { OrcamentoListPage, OrcamentoFormPage } from '../../pages/orcamento'

import { ProdutoListPage, ProdutoFormPage } from '../../pages/produto'
import { EmprestimoListPage, EmprestimoFormPage } from '../../pages/emprestimo'
import { EnderecoListPage, EnderecoFormPage } from '../../pages/endereco'

import { NotaEntradaListPage, NotaEntradaFormPage } from '../../pages/notaEntrada'
import { PortalClienteContratoListPage } from '../../pages/portalClienteContratos'
import { BiFormPage } from '../../pages/bi/form/BiFormPage'
import { ContaCorrenteFormPage, ContaCorrenteListPage } from '../../pages/contaCorrente'
import { MapaFormPage, MapaListPage } from '../../pages/mapa'
import { MapaCoordenadaFormPage, MapaCoordenadaListPage } from '../../pages/mapaCoordenada'

import { TelaDinamicaFormPage } from '../../pages/telaDinamica'

const infoLookupPessoa = {
  columns: [
    {
      field: 'oid',
      header: 'ID',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: false,
    },
    {
      field: 'numeroDocumento',
      header: 'Documento',
      columnKey: 'numeroDocumento',
      sortable: true,
      columnSortField: 'numeroDocumento',
      filter: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      columnSortField: 'nome',
      filter: true,
    },
  ],
  records: [], // pessoas fisicas e juridicas
  selectedFilters: [],
  /*campos para paginação */
  filters: [],
  sortField: '',
  sortOrder: 0,
  index: 0,
  max: 10,
  count: 0,
}

const initialState = {
  currentUser: null,
  currentCompany: null,
  currentUnit: null,
  companies: [],
  units: [],
  appDefaultRoutes: [],
  appCustomRoutes: [],
  appFullCustomRoutes: [],
  oidCurrentMenu: null,
  lookupPessoa: infoLookupPessoa,
}

export function appReducer(state = initialState, action) {
  if (action.type === types.APP_SET_COMPANIES) {
    return setCompanies(state, action)
  } else if (action.type === types.APP_SET_UNITS) {
    return setUnits(state, action)
  } else if (action.type === types.APP_SET_CURRENT_COMPANY) {
    return setCurrentCompany(state, action)
  } else if (action.type === types.APP_SET_CURRENT_UNIT) {
    return setCurrentUnit(state, action)
  } else if (action.type === types.APP_SET_CURRENT_USER) {
    return setCurrentUser(state, action)
  } else if (action.type === types.APP_SET_DEFAULT_ROUTES) {
    return setDefaultRoutes(state, action)
  } else if (action.type === types.APP_SET_CUSTOM_ROUTES) {
    return setCustomRoutes(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_ON_PAGINATE_RESULT) {
    return appLookupPessoaOnPaginateResult(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_SET_SORT_ORDER) {
    return appLookupPessoaSetSortOrder(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_SET_SORT_FIELD) {
    return appLookupPessoaSetSortField(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_SET_SELECTED_FILTERS) {
    return appLookupPessoaSetSelectedFilters(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_SET_FILTERS) {
    return appLookupPessoaSetFilters(state, action)
  } else if (action.type === types.APP_LOOKUP_PESSOA_SET_INDEX) {
    return appLookupPessoaSetIndex(state, action)
  } else if (action.type === types.APP_SET_USUARIO_UNIDADE_UNICO) {
    return setUsuarioUnidadeUnico(state, action)
  } else if (action.type === types.APP_LIMPAR_DADOS) {
    return limparDados(state)
  }
  return state
}

function setCompanies(state, action) {
  let { companies = [] } = action
  return Object.assign({}, state, { companies })
}

function setUnits(state, action) {
  let { units = [] } = action
  return Object.assign({}, state, { units })
}

function setCurrentCompany(state, action) {
  let { company } = action
  const newState = Object.assign({}, state, { currentCompany: company })
  storage.setCompany(newState.currentCompany)
  return newState
}

function setCurrentUnit(state, action) {
  let { unit } = action
  const newState = Object.assign({}, state, { currentUnit: unit })
  storage.setUnit(newState.currentUnit)
  return newState
}

function setCurrentUser(state, action) {
  let { user } = action
  return Object.assign({}, state, { currentUser: user })
}

function setUsuarioUnidadeUnico(state, action) {
  let { indicador } = action
  return Object.assign({}, state, { usuarioUnidadeUnico: indicador })
}

function setDefaultRoutes(state, action) {
  const result = [
    {
      path: `${routes.PATH_DASHBOARD}`,
      component: DashboardListPage,
    },
    {
      path: `${routes.PATH_EMPRESA}`,
      component: EmpresaListPage,
    },
    {
      path: `${routes.PATH_EMPRESA}/:id`,
      component: EmpresaFormPage,
    },
    {
      path: `${routes.PATH_USUARIO}`,
      component: UsuarioListPage,
    },
    {
      path: `${routes.PATH_USUARIO}/:id`,
      component: UsuarioFormPage,
    },
    {
      path: `${routes.PATH_PERFIL}`,
      component: PerfilListPage,
    },
    {
      path: `${routes.PATH_PERFIL}/:id`,
      component: PerfilFormPage,
    },
    {
      path: `${routes.PATH_ACAO}`,
      component: AcaoListPage,
    },
    {
      path: `${routes.PATH_ACAO}/:id`,
      component: AcaoFormPage,
    },
    {
      path: `${routes.PATH_PERMISSAO}`,
      component: PermissaoListPage,
    },
    {
      path: `${routes.PATH_PERMISSAO}/:id`,
      component: PermissaoFormPage,
    },
    {
      path: `${routes.PATH_UNIDADE}`,
      component: UnidadeListPage,
    },
    {
      path: `${routes.PATH_UNIDADE}/:id`,
      component: UnidadeFormPage,
    },
    {
      path: `${routes.PATH_MENU}`,
      component: MenuListPage,
    },
    {
      path: `${routes.PATH_MENU}/:id`,
      component: MenuFormPage,
    },
    {
      path: `${routes.PATH_CATEGORIA}`,
      component: CategoriaListPage,
    },
    {
      path: `${routes.PATH_CATEGORIA}/:id`,
      component: CategoriaFormPage,
    },
    {
      path: `${routes.PATH_RELIGIAO}`,
      component: ReligiaoListPage,
    },
    {
      path: `${routes.PATH_RELIGIAO}/:id`,
      component: ReligiaoFormPage,
    },
    {
      path: `${routes.PATH_TIPO_SEPULTURA}`,
      component: TipoSepulturaListPage,
    },
    {
      path: `${routes.PATH_TIPO_SEPULTURA}/:id`,
      component: TipoSepulturaFormPage,
    },
    {
      path: `${routes.PATH_PESSOA_FISICA}`,
      component: PessoaFisicaListPage,
    },
    {
      path: `${routes.PATH_PESSOA_FISICA}/:id`,
      component: PessoaFisicaFormPage,
    },
    {
      path: `${routes.PATH_PESSOA_JURIDICA}`,
      component: PessoaJuridicaListPage,
    },
    {
      path: `${routes.PATH_PESSOA_JURIDICA}/:id`,
      component: PessoaJuridicaFormPage,
    },
    {
      path: `${routes.PATH_SEXO}`,
      component: SexoListPage,
    },
    {
      path: `${routes.PATH_SEXO}/:id`,
      component: SexoFormPage,
    },
    {
      path: `${routes.PATH_CONTRATO}`,
      component: ContratoListPage,
    },
    {
      path: `${routes.PATH_CONTRATO}/:id`,
      component: ContratoFormPage,
    },
    {
      path: `${routes.PATH_GRUPO_FINANCEIRO}`,
      component: GrupoListPage,
    },
    {
      path: `${routes.PATH_GRUPO_FINANCEIRO}/:id`,
      component: GrupoFormPage,
    },
    {
      path: `${routes.PATH_TAXA_FINANCEIRO}`,
      component: TaxaListPage,
    },
    {
      path: `${routes.PATH_TAXA_FINANCEIRO}/:id`,
      component: TaxaFormPage,
    },
    {
      path: `${routes.PATH_LANCAMENTO_FINANCEIRO}`,
      component: LancamentoListPage,
    },
    {
      path: `${routes.PATH_LANCAMENTO_FINANCEIRO}/:id`,
      component: LancamentoFormPage,
    },
    {
      path: `${routes.PATH_FUNCAO_DINAMICA}`,
      component: FuncaoDinamicaListPage,
    },
    {
      path: `${routes.PATH_FUNCAO_DINAMICA}/:id`,
      component: FuncaoDinamicaFormPage,
    },
    {
      path: `${routes.PATH_PARAMETROS_UNIDADE}`,
      component: ParametroFormPage,
    },
    {
      path: `${routes.PATH_PARAMETROS_EMPRESA}`,
      component: ParametroEmpresaFormPage,
    },
    {
      path: `${routes.PATH_LOCALIZACAO}`,
      component: LocalizacaoListPage,
    },
    {
      path: `${routes.PATH_LOCALIZACAO}/:id`,
      component: LocalizacaoFormPage,
    },
    {
      path: `${routes.PATH_LOCALIZACAO_GRID}`,
      component: LocalizacaoGridListPage,
    },
    {
      path: `${routes.PATH_RELATORIO_DINAMICO}`,
      component: RelatorioDinamicoListPage,
    },
    {
      path: `${routes.PATH_RELATORIO_DINAMICO}/:id`,
      component: RelatorioDinamicoFormPage,
    },
    {
      path: `${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}`,
      component: ItensLancamentoListPage,
    },
    {
      path: `${routes.PATH_ITENS_LANCAMENTO_FINANCEIRO}/:id`,
      component: ItensLancamentoFormPage,
    },
    {
      path: `${routes.PATH_BOTAO_DINAMICO}`,
      component: BotaoDinamicoListPage,
    },
    {
      path: `${routes.PATH_BOTAO_DINAMICO}/:id`,
      component: BotaoDinamicoFormPage,
    },
    {
      path: `${routes.PATH_CONFIGURACAO_BOLETO}`,
      component: ConfiguracaoBoletoListPage,
    },
    {
      path: `${routes.PATH_CONFIGURACAO_BOLETO}/:id`,
      component: ConfiguracaoBoletoFormPage,
    },
    {
      path: `${routes.PATH_CONTROLE_FINANCEIRO}`,
      component: ControleFinanceiroListPage,
    },
    {
      path: `${routes.PATH_NFSE}`,
      component: NfseListPage,
    },
    {
      path: `${routes.PATH_NFSE}/:id`,
      component: NfseFormPage,
    },
    {
      path: `${routes.PATH_LIVRO_CAIXA}`,
      component: LivroCaixaFormPage,
    },
    {
      path: `${routes.PATH_MOVIMENTO_CAIXA}`,
      component: MovimentoCaixaFormPage,
    },
    {
      path: `${routes.PATH_DRE}`,
      component: DREFormPage,
    },
    {
      path: `${routes.PATH_TIPOS_SEPULTURAS_RELATORIO}`,
      component: TiposSepulturasRelatorioFormPage,
    },
    {
      path: `${routes.PATH_CONSULTA_BAIXA_RELATORIO}`,
      component: ConsultaBaixaRelatorioFormPage,
    },
    {
      path: `${routes.PATH_AQUISICOES_RELATORIO}`,
      component: AquisicoesRelatorioFormPage,
    },
    {
      path: `${routes.PATH_CARTAO_CREDITO_RELATORIO}`,
      component: CartaoCreditoRelatorioFormPage,
    },
    {
      path: `${routes.PATH_CONTAS_RECEBER}`,
      component: ContasReceberRelatorioFormPage,
    },
    {
      path: `${routes.PATH_RECEITAS_DESPESAS_RELATORIO}`,
      component: ReceitasDespesasRelatorioFormPage,
    },
    {
      path: `${routes.PATH_FALECIDOS_RELATORIO}`,
      component: FalecidosRelatorioFormPage,
    },
    {
      path: `${routes.PATH_EXUMACOES_RELATORIO}`,
      component: ExumacoesRelatorioFormPage,
    },
    {
      path: `${routes.PATH_ISENTOS_RELATORIO}`,
      component: IsentosRelatorioFormPage,
    },
    {
      path: `${routes.PATH_SEPULTURAS_DEVEDORAS}`,
      component: SepulturasDevedorasRelatorioFormPage,
    },
    {
      path: `${routes.PATH_MENSAGERIA}`,
      component: MensageriaFormPage,
    },
    {
      path: `${routes.PATH_AGENDAMENTO_MENSAGERIA}/:id`,
      component: AgendamentoMensageriaFormPage,
    },
    {
      path: `${routes.PATH_AGENDAMENTO_MENSAGERIA}/`,
      component: AgendamentoMensageriaListPage,
    },
    {
      path: `${routes.PATH_MENSAGERIA_LOG}/:id`,
      component: MensageriaLogFormPage,
    },
    {
      path: `${routes.PATH_MENSAGERIA_LOG}/`,
      component: MensageriaLogListPage,
    },
    {
      path: `${routes.PATH_CONSULTA_BAIXA}`,
      component: ConsultaBaixaListPage,
    },
    {
      path: `${routes.PATH_CONSULTA_BAIXA}/:id`,
      component: ConsultaBaixaFormPage,
    },
    {
      path: `${routes.PATH_PORTAL_CLIENTE_FINANCEIRO}`,
      component: PortalClienteFinanceiroListPage,
    },
    {
      path: `${routes.PATH_PORTAL_CLIENTE_CONTRATO}`,
      component: PortalClienteContratoListPage,
    },
    {
      path: `${routes.PATH_PORTAL_CLIENTE_FALECIDO}`,
      component: ConsultaFalecidoListPage,
    },
    {
      path: `${routes.PATH_PORTAL_CLIENTE_CARTEIRINHA}`,
      component: PortalClienteCarteirinhaListPage,
    },
    {
      path: `${routes.PATH_PORTAL_CLIENTE_CONSULTA_CLIENTE}`,
      component: PortalClienteConsultaClienteFormPage,
    },
    {
      path: `${routes.PATH_CONSULTA_REMESSA}`,
      component: ConsultaRemessaListPage,
    },
    {
      path: `${routes.PATH_CONSULTA_REMESSA}/:id`,
      component: ConsultaRemessaFormPage,
    },
    {
      path: `${routes.PATH_CONFIG_AILOS}`,
      component: ConfigAilosFormPage,
    },
    {
      path: `${routes.PATH_AGENDA}`,
      component: AgendaListPage,
    },
    {
      path: `${routes.PATH_AGENDA}/:id`,
      component: AgendaFormPage,
    },
    {
      path: `${routes.PATH_ORCAMENTO}`,
      component: OrcamentoListPage,
    },
    {
      path: `${routes.PATH_ORCAMENTO}/:id`,
      component: OrcamentoFormPage,
    },
    {
      path: `${routes.PATH_PRODUTO}`,
      component: ProdutoListPage,
    },
    {
      path: `${routes.PATH_PRODUTO}/:id`,
      component: ProdutoFormPage,
    },
    {
      path: `${routes.PATH_NOTA_ENTRADA}`,
      component: NotaEntradaListPage,
    },
    {
      path: `${routes.PATH_NOTA_ENTRADA}/:id`,
      component: NotaEntradaFormPage,
    },
    {
      path: `${routes.PATH_EMPRESTIMO}`,
      component: EmprestimoListPage,
    },
    {
      path: `${routes.PATH_EMPRESTIMO}/:id`,
      component: EmprestimoFormPage,
    },
    {
      path: `${routes.PATH_ENDERECO}`,
      component: EnderecoListPage,
    },
    {
      path: `${routes.PATH_ENDERECO}/:id`,
      component: EnderecoFormPage,
    },
    {
      path: `${routes.PATH_CONTA_CORRENTE}`,
      component: ContaCorrenteListPage,
    },
    {
      path: `${routes.PATH_CONTA_CORRENTE}/:id`,
      component: ContaCorrenteFormPage,
    },
    {
      path: `${routes.PATH_BI}/*`,
      component: BiFormPage,
    },
    {
      path: `${routes.PATH_DOCUMENTO}`,
      component: DocumentoListPage,
    },
    {
      path: `${routes.PATH_DOCUMENTO}/:id`,
      component: DocumentoFormPage,
    },
    {
      path: `${routes.PATH_MAPA}`,
      component: MapaListPage,
    },
    {
      path: `${routes.PATH_MAPA}/:id`,
      component: MapaFormPage,
    },
    {
      path: `${routes.PATH_MAPA_COORDENADA}`,
      component: MapaCoordenadaListPage,
    },
    {
      path: `${routes.PATH_MAPA_COORDENADA}/:id`,
      component: MapaCoordenadaFormPage,
    },
    {
      path: `${routes.PATH_TELA_DINAMICA_RELATORIO}`,
      component: TelaDinamicaFormPage,
    },
  ]
  return Object.assign({}, state, { appDefaultRoutes: result })
}

function setCustomRoutes(state, action) {
  const menus = action.menus
  const appDefaultRoutes = state.appDefaultRoutes
  let result = []
  if (utils.isArrayNotEmpty(menus)) {
    const flatMenus = flatMenu(menus)
    appDefaultRoutes.forEach((route) => {
      const customs = processCustomRoute(flatMenus, route, result)
      if (utils.isArrayNotEmpty(customs)) {
        result = result.concat(customs)
      }
    })
  }
  if (utils.isArrayNotEmpty(result)) {
    const temp = result.map((route) => {
      return {
        path: route.path,
        component: route.component,
        oidMenu: route.oidMenu,
        customRoute: true,
        relatorioDinamico: route.relatorioDinamico,
      }
    })
    state = addCustomRoutesRelatorioDinamicoOnDefaultRoutes(temp, state)
    return Object.assign({}, state, { appCustomRoutes: temp })
  }

  return state
}

function limparDados(state) {
  state = Object.assign(state, { appCustomRoutes: [] })
  state = Object.assign(state, { appDefaultRoutes: [] })
  return state
}

function addCustomRoutesRelatorioDinamicoOnDefaultRoutes(customRoutes, state) {
  if (utils.isArrayNotEmpty(customRoutes)) {
    const appDefaultRoutes = state.appDefaultRoutes
    let routesWithoutRelatorioDinamico = appDefaultRoutes.filter((f) => !f.relatorioDinamico)
    customRoutes.forEach((custom) => {
      if (custom.relatorioDinamico) {
        routesWithoutRelatorioDinamico.push({
          path: custom.path,
          component: DinamicaListPage,
        })
      }
    })
    return Object.assign({}, state, {
      appDefaultRoutes: routesWithoutRelatorioDinamico,
    })
  }
  return state
}

function flatMenu(menus) {
  let result = []
  if (utils.isArrayNullOrEmpty(menus)) {
    return result
  }

  menus.forEach((menu) => {
    if (utils.isStringNotBlank(menu.caminho)) {
      result.push(menu)
    }
    const children = menu.children
    if (utils.isArrayNotEmpty(children)) {
      const innerResult = flatMenu(children)
      if (utils.isArrayNotEmpty(innerResult)) {
        result = result.concat(innerResult)
      }
    }
  })

  return result
}

function getCustomRoute(menus, route) {
  if (utils.isArrayNullOrEmpty(menus)) {
    return null
  }

  const customMenus = menus.filter((menu) => {
    const caminhoValido = utils.isStringBlank(menu.caminho) ? null : menu.caminho.trim()
    return (
      menu.relatorioDinamico ||
      (utils.isStringNotBlank(caminhoValido) &&
        caminhoValido.indexOf(route.path) > -1 &&
        caminhoValido !== route.path &&
        route.path !== '/')
    )
  })

  if (utils.isArrayNotEmpty(customMenus)) {
    const result = []
    customMenus.forEach((menu) => {
      result.push({
        path: menu.caminho,
        component: route.component,
        oidMenu: menu.oid,
        customRoute: true,
        relatorioDinamico: menu.relatorioDinamico,
      })
    })
    return result
  }
  return []
}

function processCustomRoute(menus, route, tempArray) {
  if (utils.isArrayNullOrEmpty(menus)) {
    return []
  }

  if (utils.isArrayNullOrEmpty(tempArray)) {
    return getCustomRoute(menus, route)
  }

  let newArray = []
  menus.forEach((menu) => {
    const result = tempArray.find((tempMenu) => tempMenu.path === menu.caminho)
    if (!result) {
      newArray.push(menu)
    }
  })
  return getCustomRoute(newArray, route)
}

function appLookupPessoaOnPaginateResult(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa.records = []
  lookupPessoa.index = 0
  lookupPessoa.max = 10
  lookupPessoa.count = 0

  const payload = action.payload
  if (payload) {
    lookupPessoa.records = payload.records
    lookupPessoa.index = payload.index
    //lookupPessoa.max = payload.max;
    lookupPessoa.count = payload.count
  }

  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}

function appLookupPessoaSetSortOrder(state, actions) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa.sortOrder = actions.payload
  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}

function appLookupPessoaSetSortField(state, actions) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa.sortField = actions.payload
  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}

function appLookupPessoaSetSelectedFilters(state, action) {
  let lookupPessoa = state.lookupPessoa
  let filters = Object.assign([], lookupPessoa.selectedFilters)
  const newFilters = filters.filter((e) => e.campo !== action.payload.field)
  const isBooleanFilter = typeof action.payload.value === 'boolean'

  if (isBooleanFilter || (action.payload.value && action.payload.value !== '')) {
    newFilters.push({
      campo: action.payload.field,
      valor: action.payload.value,
      tipoFiltro: isBooleanFilter ? 'IGUAL_A' : 'CONTEM',
      relacionamento: action.payload.relacionamento,
    })
  }

  lookupPessoa.selectedFilters = newFilters
  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}

function appLookupPessoaSetFilters(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa.filters = action.payload
  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}

function appLookupPessoaSetIndex(state, action) {
  let lookupPessoa = state.lookupPessoa
  lookupPessoa.index = action.payload
  return Object.assign({}, state, { lookupPessoa: lookupPessoa })
}
