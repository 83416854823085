import React from 'react'
import { connect } from 'react-redux'
import LoginForm from './login-form.component'
import * as loginActions from './login.actions'
import { Toast } from 'primereact/toast'
import './LoginPageContainer.scss'
import background from '../../assets/layout/images/login/login-bg.jpg'
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner'
import storage from '../../services/stoage.service'
import authenticationService from '../../services/authentication.service'
import { actions as dashboardActions } from '../dashboard/dashboard.actions'
import { actions as tipoSepulturaActions } from '../tipoSepultura/tipoSepultura.actions'
import { actions as controleFinanceiroActions } from '../controleFinanceiro/controleFinanceiro.actions'
import { actions as contratoActions } from '../contrato/contrato.actions'
import { actions as pessoaFisicaActions } from '../pessoaFisica/pessoaFisica.actions'
import { actions as pessoaJuridicaActions } from '../pessoaJuridica/pessoaJuridica.actions'
import { actions as lancamentoActions } from '../lancamentoFinanceiro/lancamento.actions'
import { actions as grupoActions } from '../grupo/grupo.actions'
import { actions as taxaActions } from '../taxa/taxa.actions'
import { actions as empresaActions } from '../empresa/empresa.actions'
import { actions as unidadeActions } from '../unidade/unidade.actions'
import { actions as localizacaoActions } from '../localizacao/localizacao.actions'
import { actions as categoriaActions } from '../categoria/categoria.actions'
import { actions as sexoActions } from '../sexo/sexo.actions'
import { actions as religiaoActions } from '../religiao/religiao.actions'
import { actions as mensageriaActions } from '../mensageria/mensageria.actions'
import { actions as mensageriaLogActions } from '../mensageriaLog/mensageriaLog.actions'
import { actions as consultaBaixaActions } from '../consultaBaixa/consultaBaixa.actions'
import { actions as consultaRemessaActions } from '../consultaRemessa/consultaRemessa.actions'
import { actions as nfseActions } from '../nfse/nfse.actions'
import { actions as usuarioActions } from '../usuario/usuario.actions'
import { actions as perfilActions } from '../perfil/perfil.actions'
import { actions as botaoDinamicoActions } from '../botaoDinamico/botaoDinamico.actions'
import { actions as funcaoDinamicaActions } from '../funcaoDinamica/funcaoDinamica.actions'
import { actions as permissaoActions } from '../permissao/permissao.actions'
import { actions as configuracaoBoletoActions } from '../configuracaoBoleto/configuracaoBoleto.actions'
import { actions as parametroEmpresaActions } from '../parametrosEmpresa/parametroEmpresa.actions'
import { actions as parametroActions } from '../parametrosUnidade/parametro.actions'
import { actions as relatorioDinamicoActions } from '../relatorioDinamico/relatorioDinamico.actions'
import { actions as produtoActions } from '../produto/produto.actions'
import { actions as emprestimoActions } from '../emprestimo/emprestimo.actions'
import { actions as enderecoActions } from '../endereco/endereco.actions'

const mapStateToProps = (state) => {
  return {
    credential: state.loginState.credential,
    conta: state.loginState.conta,
    message: state.loginState.message,
    showMessage: state.loginState.showMessage,
    messageSeverity: state.loginState.messageSeverity,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    authenticate: () => {
      dispatch(dashboardActions.reset())
      dispatch(tipoSepulturaActions.reset())
      dispatch(controleFinanceiroActions.reset())
      dispatch(contratoActions.reset())
      dispatch(pessoaFisicaActions.reset())
      dispatch(pessoaJuridicaActions.reset())
      dispatch(lancamentoActions.reset())
      dispatch(grupoActions.reset())
      dispatch(taxaActions.reset())
      dispatch(empresaActions.reset())
      dispatch(unidadeActions.reset())
      dispatch(localizacaoActions.reset())
      dispatch(categoriaActions.reset())
      dispatch(sexoActions.reset())
      dispatch(religiaoActions.reset())
      dispatch(mensageriaActions.reset())
      dispatch(mensageriaLogActions.reset())
      dispatch(consultaBaixaActions.reset())
      dispatch(consultaRemessaActions.reset())
      dispatch(nfseActions.reset())
      dispatch(usuarioActions.reset())
      dispatch(perfilActions.reset())
      dispatch(botaoDinamicoActions.reset())
      dispatch(funcaoDinamicaActions.reset())
      dispatch(permissaoActions.reset())
      dispatch(configuracaoBoletoActions.reset())
      dispatch(parametroEmpresaActions.reset())
      dispatch(parametroActions.reset())
      dispatch(relatorioDinamicoActions.reset())
      dispatch(produtoActions.reset())
      dispatch(emprestimoActions.reset())
      dispatch(enderecoActions.reset())

      dispatch(loginActions.loginClearErrorMessage())
      dispatch(loginActions.authenticate())
    },
    redirectToDash: () => {
      loginActions.redirectToDash()
    },
    inputHandler: (event) => {
      const value = event.target.value
      const key = event.target.name
      const credential = { [key]: value }
      dispatch(loginActions.loginSetCredential(credential))
    },
    inputHandlerCriarConta: (event) => {
      const value = event.target.value
      const key = event.target.name
      const conta = { [key]: value }
      dispatch(loginActions.loginSetConta(conta))
    },
    onChangeTipoPessoa: (event) => {
      const key = event.target.name
      const conta = { 'tipoPessoa' : (key === 'fisica') ? 'FISICA' : 'JURIDICA' }
      dispatch(loginActions.loginSetConta(conta))
    },
    setErrorMessage: (valor) => {
      loginActions.loginSetErrorMessage(valor)
    },
    reenviarSenha: () => {
      dispatch(loginActions.loginClearErrorMessage())
      dispatch(loginActions.reenviarSenha())
    },
    criarConta: () => {
      dispatch(loginActions.loginClearErrorMessage())
      dispatch(loginActions.criarConta())
    },
    setShowMessage: (value) => dispatch(loginActions.setShowMesage(value)),
  }
}

class LoginContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoadingSpinner: false,
      showMessage: false,
      isLogin: true,
      isEsqueciSenha: false,
      isPrimeiroAcesso: false,
    }
    this.toast = React.createRef()
    this.showError = this.showError.bind(this)
  }

  setStateEsqueciSenha = () => {
    this.setState({ isEsqueciSenha: true })
    this.setState({ isPrimeiroAcesso: false })
    this.setState({ isLogin: false })
  }

  setStatePrimeiroAcesso = () => {
    this.setState({ isEsqueciSenha: false })
    this.setState({ isPrimeiroAcesso: true })
    this.setState({ isLogin: false })
  }

  setStateLogin = () => {
    this.setState({ isEsqueciSenha: false })
    this.setState({ isPrimeiroAcesso: false })
    this.setState({ isLogin: true })
  }

  authenticate = () => {
    this.setState({ showLoadingSpinner: true })
    this.props.authenticate()
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.authenticate()
    }
  }

  showError() {
    if (this.state.showLoadingSpinner && this.props.showMessage) {
      this.setState({ showLoadingSpinner: false })
      this.setState({ showMessage: false })
      this.props.setShowMessage(false)
      const summary = this.props.messageSeverity === 'success' ? 'Sucesso' : 'Erro'
      this.toast.show({
        severity: this.props.messageSeverity,
        summary: summary,
        detail: this.props.message,
      })
    }
  }

  componentDidMount() {
    if (authenticationService.isAuthenticated()) {
      this.props.redirectToDash()
    } else {
      storage.clearStorage()
    }
  }

  reenviarSenha = () => {
    if (this.toast && this.toast.state && this.toast.state.messages.length > 1) {
      this.toast.state.messages = []
    }
    this.setState({ showLoadingSpinner: true })
    this.props.reenviarSenha()
  }

  criarConta = () => {
    if (this.toast && this.toast.state && this.toast.state.messages.length > 1) {
      this.toast.state.messages = []
    }
    this.setState({ showLoadingSpinner: true })
    this.props.criarConta()
  }

  render() {
    if (this.props.showMessage && this.state.showLoadingSpinner) {
      this.showError()
    }
    return (
      <div className="login-body">
        <LoginForm
          message={this.props.message}
          login={this.props.credential.login}
          password={this.props.credential.password}
          conta={this.props.conta}
          changeValue={this.props.inputHandler}
          inputHandlerCriarConta={this.props.inputHandlerCriarConta}
          onChangeTipoPessoa={this.props.onChangeTipoPessoa}
          authenticate={this.authenticate}
          onKeyDown={this.onKeyDown}
          setStateEsqueciSenha={this.setStateEsqueciSenha}
          setStatePrimeiroAcesso={this.setStatePrimeiroAcesso}
          setStateLogin={this.setStateLogin}
          isLogin={this.state.isLogin}
          isEsqueciSenha={this.state.isEsqueciSenha}
          isPrimeiroAcesso={this.state.isPrimeiroAcesso}
          reenviarSenha={this.reenviarSenha}
          criarConta={this.criarConta}
        />
        <Toast ref={(el) => (this.toast = el)} />
        {this.state.showLoadingSpinner ? <LoadingSpinner /> : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
