import React from "react";
import {connect} from "react-redux";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import { Page } from '../../../components/page/Page'
import {ConsultaObituarioList} from "./ConsultaObituarioList";
import {actions} from "../consultaobituario.actions";
import {InputText} from "primereact/inputtext";
import utils from "../../../utils";
import {Button} from "primereact/button";
import {icons} from "../../../constants";
import {onColumnReorder, onColumnToggle, onPage, onSort} from "../../../utils/listPageContainerUtils";
import {InputTextarea} from "primereact/inputtextarea";
import GrowlMessenger from "../../../components/growl/GrowlMessenger";
import { Card } from "primereact/card";

const mapStateToProps = state => {
    return {
        record: state.crudConsultaObituarioState.currentRecord,
        records: state.crudConsultaObituarioState.records,
        showHideColumns: state.crudConsultaObituarioState.showHideColumns,
        columns: state.crudConsultaObituarioState.columns,
        first: state.crudConsultaObituarioState.index,
        rows: state.crudConsultaObituarioState.max,
        totalRecords: state.crudConsultaObituarioState.count,
        sortField: state.crudConsultaObituarioState.sortField,
        order: state.crudConsultaObituarioState.sortOrder,
        selectedFilters: state.crudConsultaObituarioState.selectedFilters,
        imagemLocalizacao: state.crudConsultaObituarioState.imagemLocalizacao,
        unidadeMapa: state.crudConsultaObituarioState.unidadeMapa,
        localizacao1: state.crudConsultaObituarioState.localizacao1,
        localizacao2: state.crudConsultaObituarioState.localizacao2,
        localizacao3: state.crudConsultaObituarioState.localizacao3,
        localizacao4: state.crudConsultaObituarioState.localizacao4,
        localizacao: state.crudConsultaObituarioState.localizacao
    };
};
const mapDispatchToProps = dispatch => {
    return {
        paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        applyFilter: filterParams => {
            dispatch(actions.setType(null));
            dispatch(actions.applyFilter(filterParams));
        },
        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.setField(field, value));
        },
        changeFilter: (column, e) => {
            const target = e.target;
            let campo = column.fieldJoin ? column.fieldJoin : target.name;
            dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamentos, column.field));
            setTimeout(() => {
                dispatch(actions.executeFilter(`/lottustech/public/falecidos/all`));
            }, 400);
        },
        setSelectedFilters: filtro => dispatch(actions.setFiltroAvancado(filtro)),
        setField: (field, value) => dispatch(actions.setField(field, value)),
        clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
        load: () => dispatch(actions.load()),
        getLocalizacao: (origem, oidContratoFalecido, localizacao) => dispatch(actions.getLocalizacao(origem, oidContratoFalecido, localizacao)),
        clearImagemLocalizacao: () => dispatch(actions.clearImagemLocalizacao()),
        openExternalLink: (link) => dispatch(actions.openExternalLink(link)),
    };
};

class ConsultaObituarioListPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedRows: null,
            origem: ''
        };
    }


    // getUrlParams = () => {
    //   // let url = window.location.search.substring(1); //get rid of "?" in querystring
    //   const result = [];
    //   const href = window.location.href;
    //   if (href) {
    //     const indexOf = href.indexOf('?');
    //     if (indexOf > -1) {
    //       const paramString = href.substr(indexOf + 1);
    //       if (paramString) {
    //         const arrParams = paramString.split('&');
    //         if (arrParams) {
    //           if (arrParams.length > 0) {
    //             const arrKeyValue = arrParams[0].split('=');
    //             if (arrParams && arrKeyValue.length > 0) {
    //               result.push({campo: 'origem', valor: parseInt(arrKeyValue[1])});
    //             }
    //           }
    //           if (arrParams.length > 1) {
    //             const arrKeyValue = arrParams[1].split('=');
    //             if (arrParams && arrKeyValue.length > 0) {
    //               result.push({campo: 'falecido', valor: decodeURI(arrKeyValue[1])});
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return result;
    //   //http://localhost:3000/phoenix/#/geral/consulta/falecidos?param1=value1&param2=value2
    //
    //   // let qArray = url.split('&'); //get key-value pairs
    //   // for (let i = 0; i < qArray.length; i++) {
    //   //   let pArr = qArray[i].split('='); //split key and value
    //   //   //if (pArr[0] == paramName) {
    //   //     console.log(`${pArr}`)
    //   //
    //   //     // return pArr[1]; //return value
    //   //   //}
    //   // }
    // }

    checkOrigemUrlParams = () => {
        const href = window.location.href;
        if (href) {
            const indexOf = href.indexOf('?');
            if (indexOf > -1) {
                const paramString = href.substr(indexOf + 1);
                if (paramString) {
                    const arrParams = paramString.split('&');
                    if (arrParams) {
                        if (arrParams.length > 0) {
                            const arrKeyValue = arrParams[0].split('=');
                            if (arrParams && arrKeyValue.length > 0) {
                                this.setState({origem: parseInt(arrKeyValue[1])})
                            }
                        }
                    }
                }
            }
        }
    }

    // componentDidMount() {
    //   const urlParams = this.getUrlParams();
    //   if (urlParams && urlParams.length > 0) {
    //     this.props.setField('dataInicial', null);
    //     this.props.setField('dataFinal', null);
    //     for (const urlParam of urlParams) {
    //       const filtro = {
    //         campo: urlParam.campo,
    //         tipoFiltro: 'IGUAL_A',
    //         valor: urlParam.valor
    //       }
    //       if (filtro.campo === 'falecido') {
    //         this.props.setField('falecido', filtro.valor);
    //       }
    //       this.props.setSelectedFilters(filtro);
    //     }
    //   } else {
    //     const filtroDataFalecimento = {
    //       campo: "dataFalecimento",
    //       tipoFiltro: "ENTRE",
    //       valor: this.props.record.dataInicial,
    //       valorComplementar: this.props.record.dataFinal
    //     };
    //     this.props.setSelectedFilters(filtroDataFalecimento);
    //   }
    //   this.props.load();
    // }

    componentDidMount() {
        this.checkOrigemUrlParams();
        const filtroDataFalecimento = {
            campo: "dataFalecimento",
            tipoFiltro: "ENTRE",
            valor: this.props.record.dataInicial,
            valorComplementar: this.props.record.dataFinal
        };
        this.props.setSelectedFilters(filtroDataFalecimento);
        this.props.load();
    }

    setExpandedRows = (e) => {
        this.setState({expandedRows: e.data});
    }

    rowExpansionTemplate(data) {
        const sobre = data.sobre;
        if (sobre && sobre.length > 0) {
            return (
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h4>Um pouco sobre {data.falecido}</h4>
                            <div className="p-grid form-group">
                                <div className="p-col-12">
                                    <InputTextarea
                                        value={sobre}
                                        name="sobre"
                                        rows={10}
                                        cols={300}
                                        autoResize={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    changeHandler = event => {
        this.props.changeHandler(event);
    };

    onKeyDown = e => {
        this.props.onEnter();
    };
    onKeyDownSubCategoria = e => {
        this.props.onEnter();
    };

    //parametro com todas as colunas selecionadas atualmente
    onColumnToggle(event) {
        onColumnToggle(event, this);
    }

    // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
    onColReorder(e, colunasAtuais) {
        onColumnReorder(e, colunasAtuais, this);
    }


    onPage = e => {
        onPage(this, e);
    };

    onSort = e => {
        onSort(this, e);
    };

    onClickPesquisar = () => {
        this.props.clearSelectedFilters();
        let filtro;
        if (this.props.record.falecido) {
            filtro = {
                campo: "falecido",
                tipoFiltro: "CONTEM",
                valor: this.props.record.falecido
            };
        } else {
            filtro = {
                campo: "dataFalecimento",
                tipoFiltro: "ENTRE",
                valor: this.props.record.dataInicial,
                valorComplementar: this.props.record.dataFinal
            };
        }
        this.props.setSelectedFilters(filtro);
        this.props.applyFilter();
    };

    changeFilterCalendar = (column, e) => {
        const target = e.target;
        let campo = column.fieldJoin ? column.fieldJoin : target.name;
        let filtro = {
            campo: campo,
            tipoFiltro: "CONTEM",
            valor: target.value
        };
        this.props.setField(campo, target.value);
        this.props.setSelectedFilters(filtro);
        this.setState({atualizar: true});
    };

    onBlurCalendar = (column, e) => {
        let filtro;
        if (this.props.record[column.field]) {
            filtro = {
                campo: column.field,
                tipoFiltro: "CONTEM",
                valor: this.props.record[column.field]
            };
            this.props.setSelectedFilters(filtro);
            this.props.applyFilter();
        } else {
            this.onClickPesquisar();
        }
    };

    onSelectDate = () => {
        this.props.applyFilter();
    };

    onEnterPesquisar = e => {
        if (!e) e = window.event;
        let keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            if (this.props.record.falecido) {
                this.props.clearSelectedFilters();
                const filtroNome = {
                    campo: "falecido",
                    tipoFiltro: "CONTEM",
                    valor: this.props.record.falecido
                };
                this.props.setSelectedFilters(filtroNome);
                this.props.applyFilter();
            }
        } else {
            this.onClickPesquisar();
        }
    };

    actionLocalizacao = rowData => {
        const showLocalizacao = rowData.localizacao && this.state.origem && rowData.exibeMapa;
        const showExternalLink = rowData.link && rowData.link.length > 0;

        return (
            <div>
                {showLocalizacao ?
                    <Button
                        type="button"
                        icon={icons.MAP_MARKER}
                        className="p-button-warning"
                        onClick={e => this.props.getLocalizacao(this.state.origem, rowData.oidContratoFalecido, rowData.localizacao)}
                        disabled={!showLocalizacao}
                    />
                    : null}
                {showExternalLink ?
                    <Button
                        type="button"
                        icon={icons.EXTERNAL_LINK}
                        className="p-button-warning"
                        onClick={e => this.props.openExternalLink(rowData.link)}
                        disabled={!showExternalLink}
                    />
                    : null}
            </div>
        );
    };

    formatColumn = (rowData, column) => {
        return utils.format(rowData, column);
    };

    fieldCalendarFilter = column => {
        let dataSelecionada = "";
        if (this.props.record && this.props.record[column.field]) {
            dataSelecionada = this.props.record[column.field];
        }
        return (
            <Calendar
                locale={utils.getCalendar()}
                value={dataSelecionada}
                yearNavigator={true}
                monthNavigator={true}
                yearRange={utils.getYearRangeFromDate("1900")}
                dateFormat="dd/mm/yy"
                appendTo={document.body}
                onSelect={this.onSelectDate}
                onBlur={e => this.onBlurCalendar(column, e)}
                onChange={e => this.changeFilterCalendar(column, e)}
                name={column.field}
            />
        );
    };

    fieldFilter = column => {
        return (
            <InputText
                style={{width: "100%"}}
                name={column.field}
                value={this.props.selectedFilters[column.field]}
                onChange={e => this.props.changeFilter(column, e)}
            />
        );
    };

    // resolveExpander = (rowData, column) => {
    //     return rowData.sobre && rowData.sobre.length > 0
    // };


    defineColumns = () => {
        const visibleColumns = this.props.columns.filter(col => col.visible);
        return visibleColumns.map(col => {
            // if (col.field === "dataNascimento" || col.field === "dataFalecimento") {
            //      return (
            //          <Column
            //              {...col}
            //              key={col.field}
            //              body={this.formatColumn}
            //              sortField={col.columnSortField}
            //              filterElement={this.fieldCalendarFilter(col)}
            //              style={{width: "16%"}}
            //          />
            //      );
            //  } else
            // if (col.field === 'sobre') {
            //     return (<Column expander style={{width: '3em'}}/>)
            // } else
                if (col.field === "localizacao") {
                return (
                    <Column
                        {...col}
                        key={col.field}
                        sortField={col.columnSortField}
                        filterElement={this.fieldFilter(col)}
                        showFilterMenu={false}
                        // style={{width: "30%"}}
                    />
                );
                // } else if (col.fieldFormatter) {
                //     return (
                //         <Column
                //             {...col}
                //             key={col.field}
                //             body={this.formatColumn}
                //             sortField={col.columnSortField}
                //             filterElement={this.fieldFilter(col)}
                //         />
                //     );
            } else if (col.field === "acoes") {
                return <Column {...col} key={col.field} body={this.actionLocalizacao}
                               style={{textAlign: "center"}}/>;
            } else if (col.field === "oidContratoFalecido" || col.field === "link" || col.field === "exibeMapa") {
                return null;
            }
            return <Column {...col} key={col.field} sortField={col.columnSortField}
                           filterElement={this.fieldFilter(col)} showFilterMenu={false}/>;
        });
    };

    buildHeader = () => {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <div className="p-grid form-group p-align-end">
                        <div className="p-col-10">
                            <label className="label">Falecido</label>
                            <InputText
                                placeholder="Digite para pesquisar"
                                value={this.props.record.falecido}
                                onKeyUp={this.onEnterPesquisar}
                                onChange={this.props.changeHandler}
                                name="falecido"
                            />
                        </div>
                        <div className="p-col-1 alinhamento-botao">
                            <Button className="p-button.green-btn"
                                    icon={icons.SEARCH_ICON}
                                    onClick={this.onClickPesquisar}
                                    style={{ marginTop: "25px" }}
                            />
                        </div>
                        {/*{this.state.origem ?*/}
                        {/*    <div className="p-col-1">*/}
                        {/*        <h5>Origem: {this.state.origem}</h5>*/}
                        {/*    </div>*/}
                        {/*    : null*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        );
    };

    // resolveContent = () => {
    //     if (this.props.imagemLocalizacao && this.props.imagemLocalizacao.length > 10) {
    //         return this.getDialogLocalizacao();
    //     }
    //     return this.render();
    // }

    // getDialogLocalizacao = () => {
    //     return (
    //         <Dialog
    //             id={"dialogLocalizacao"}
    //             header={""}
    //             visible={this.props.imagemLocalizacao && this.props.imagemLocalizacao.length > 10}
    //             style={{ width: "90%", height: "90%" }}
    //             onHide={e => this.props.clearImagemLocalizacao()}
    //             baseZIndex={1000000}
    //             modal={true}
    //             contentStyle={{ maxHeight: "500px", overflow: "auto" }}
    //         >
    //             <div>
    //                 <Image
    //                     src={this.props.imagemLocalizacao}
    //                     style= {{ width: '590px', height: '790px' }}
    //                 />
    //             </div>
    //         </Dialog>
    //     )
    // }

    render() {
        const content = (
            <ConsultaObituarioList
                {...this.props}
                dataTableRef={el => (this.dataTableRef = el)}
                onColReorder={e => this.onColReorder(e, this.showHideColumns)}
                onPage={this.onPage}
                onSort={this.onSort}
                columns={this.defineColumns()}
                // expandedRows={this.state.expandedRows}
                // setExpandedRows={this.setExpandedRows}
                // rowExpansionTemplate={this.rowExpansionTemplate}
            />
        );

        return <div className="page-obituario">
            <div className="page-obituario__header">
                <GrowlMessenger/>
                <Card className="page-obituario-card">{this.buildHeader()}</Card>
            </div>
            <div className="page-obituario__content">
                {content}
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaObituarioListPageContainer);
