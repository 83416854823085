import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { enums, routes } from '../../../constants'
import { validatePhone } from '../../../utils/index'
import { actions as categoriaActions } from '../../categoria/categoria.actions'
import { actions } from '../pessoaJuridica.actions'
import { actions as pessoaActions } from '../../pessoa/pessoa.actions'
import { PessoaJuridicaForm } from './PessoaJuridicaForm'
import * as _ from 'lodash'
import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudPessoaJuridicaState.messages
  const record = state.crudPessoaJuridicaState.currentRecord
  const opcoesCategorias = state.crudCategoriaState.records

  const currentCompany = storage.getCompany()
  const currentUnit = storage.getUnit()
  const isUsarWebWhatsapp = utils.isUsarWebWhatsapp(currentCompany, currentUnit)
  const isUsarMensageria = utils.isUsarMensageria(currentCompany, currentUnit)
  return {
    record,
    opcoesCategorias,
    messages,
    idTela: state.crudPessoaJuridicaState.id,
    botoesDinamicos: state.crudPessoaJuridicaState.botoesDinamicos,
    isSaving: state.crudPessoaJuridicaState.isSaving,
    isUsarMensageria,
    isUsarWebWhatsapp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    categoriaApplyFilter: (filterParams) => {
      dispatch(categoriaActions.setType(null))
      dispatch(categoriaActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(categoriaActions.applyFilter(filterParams))
    },
    categoriasLoad: () => dispatch(categoriaActions.load(true)),

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      if (field === 'optanteSimples' || field === 'ativo') {
        value = target.checked
      }
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    cepOnChange: (event) => {
      const field = event.target.name
      let valor = event.target.value
      if (valor.length === 8) {
        dispatch(actions.setField(field, valor))
        dispatch(actions.setField('nomeLogradouro', ''))
        dispatch(actions.setField('numeroLogradouro', ''))
        dispatch(actions.setField('complemento', ''))
        dispatch(actions.setField('bairro', ''))
        dispatch(actions.setField('cidade', ''))
        dispatch(actions.setField('oidLogradouro', ''))
        dispatch(actions.setField('uf', ''))
        dispatch(actions.findByCEP(valor))
      }
    },
    inputMaskOnChange: (event) => {
      const field = event.target.name
      let valor = event.target.value
      const length = _.get(valor, 'length', 0)
      if (field === 'numeroDocumento' && length === 14) {
        dispatch(actions.setField(field, valor))
      } else if ((field === 'telefone' || field === 'segundoTelefone') && validatePhone(valor)) {
        dispatch(actions.setField(field, valor))
      } else if (length === 0) {
        dispatch(actions.setField(field, ''))
      }
    },
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    onClickBotaoDinamico: (listId, oidBotao) => dispatch(actions.executarFuncaoDinamica(listId, oidBotao)),
    enviarWhatsApp: (telefone) => dispatch(actions.enviarWhatsApp(telefone)),
    abrirWebWhatsApp: ({ oidPessoa, telefone }) => dispatch(pessoaActions.abrirWebWhatsApp({ oidPessoa, telefone })),
    enviarEmail: () => dispatch(actions.enviarEmail()),
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.FORMULARIO)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
  }
}

class PessoaJuridicaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      showDialogRemove: false,
    }

    actions.carregarMensageria = (mensagem) => {
      props.history.push('/geral/cadastros/mensageria', { mensagem })
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PESSOA_JURIDICA}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_PESSOA_JURIDICA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PESSOA_JURIDICA}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
    this.props.categoriasLoad()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.history.push(this.props.location.pathname)
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA_JURIDICA}`)
  }

  cepOnChange = (event) => {
    this.props.cepOnChange(event)
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  categoriaOnConfirm = (e) => {
    let categorias = []
    if (e.value) {
      e.value.forEach((categoria) => {
        categorias.push({
          id: categoria.oid ? categoria.oid : categoria.oidCategoria,
          value: categoria.descricao ? categoria.descricao : categoria.value,
        })
      })
    }
    this.props.setField('categorias', categorias)
  }

  categoriaOnInputFilter = (e) => {
    const value = e.query
    if (typeof value === 'string') {
      const filters = {
        campo: 'descricao',
        valor: value,
      }
      this.props.categoriaApplyFilter(filters)
    } else {
      this.props.setCategoria(value)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  setCatagorias = (data) => {
    const values = []
    data.forEach((object) => {
      values.push({
        id: _.get(object, 'oid', ''),
        value: _.get(object, 'descricao', ''),
      })
    })
    this.props.setField('categorias', values)
  }

  enviarWhatsApp = (telefone) => {
    if (this.props.isUsarWebWhatsapp) {
      const oidPessoa = _.get(this, 'props.record.oid', null)
      if (oidPessoa) {
        this.props.abrirWebWhatsApp({ oidPessoa, telefone })
      }
    } else {
      this.props.enviarWhatsApp(telefone)
    }
  }

  enviarEmail = () => {
    if (this.props.isUsarMensageria) {
      this.props.enviarEmail()
    } else {
      const email = _.get(this, 'props.record.email', null)
      window.location = 'mailto:' + email
    }
  }

  render() {
    const content = (
      <PessoaJuridicaForm
        state={this.state}
        changeActiveIndex={this.changeActiveIndex}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        cepOnChange={this.cepOnChange}
        inputMaskOnChange={this.props.inputMaskOnChange}
        opcoesCategorias={this.props.opcoesCategorias}
        categoriaOnConfirm={this.categoriaOnConfirm}
        categoriaOnInputFilter={this.categoriaOnInputFilter}
        enviarWhatsApp={this.enviarWhatsApp}
        enviarEmail={this.enviarEmail}
        setCatagorias={this.setCatagorias}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaJuridicaFormPageContainer)
