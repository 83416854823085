import React from 'react'
import _ from 'lodash'
import utils from '../../utils'
import apiService from '../../services/api-id-value-service'
import LtsMultiselectIdValue from './lts-multiselect-id-value'

export default function LtsMultiselectTipoSepultura(props) {
  const onAfterLoadDatFn = (data) => {
    const hideRecords = _.get(props, 'hideRecords', [])
    if (utils.isArrayNotEmpty(hideRecords) && utils.isArrayNotEmpty(data)) {
      let newData = []
      data.forEach((d) => {
        const hide = hideRecords.find((h) => h === d.id)
        if (!hide) {
          newData.push(d)
        }
      })
      return newData
    }
    return data
  }

  return (
    <React.Fragment>
      <LtsMultiselectIdValue
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        loadDataFn={apiService.getTiposSepulturaByUnidades}
        loadDataFnParam={props.loadDataFnParam}
        afterLoadDataFn={onAfterLoadDatFn}
      />
    </React.Fragment>
  )
}
