import React from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import logo_phoenix from "../../assets/images/logo/phoenix-logo-design-11-510x510.png";
import {icons} from "../../constants";
import {RadioButton} from "primereact/radiobutton";
import _ from 'lodash'
import {InputMask} from "primereact/inputmask";


function getLabel(props) {
  if (props.isPrimeiroAcesso) {
    return (
        <label>Solicitação de Acesso</label>
    );
  } else if (props.isEsqueciSenha) {
    return (
      <label>Recuperação de senha</label>
    );
  } else return (
      <label>PHOENIX</label>
  );
  return null;
}

function getLoginForm(props) {
    return (
      <div className="p-col-12">
        <div className="p-col-12">
          <span className="p-float-label">
            <InputText
              value={props.login}
              name="login"
              onChange={props.changeValue}
              onKeyDown={props.onKeyDown}
            />
            <label>Login</label>
          </span>
        </div>

        <div className="p-col-12">
          <span className="p-float-label">
            <InputText
              value={props.password}
              name="password"
              type="password"
              onChange={props.changeValue}
              onKeyDown={props.onKeyDown}
            />
            <label>Senha</label>
          </span>
        </div>
        <div className="p-col-12">
          <Button
            label="Entrar"
            icon={icons.PERSON_ICON}
            onClick={props.authenticate}
          />
        </div>
        <div className="p-col-12" style={{ 'textAlign': 'left', 'paddingBottom': '5px'}}>
          <span>
            <Link to={`/login`} onClick={props.setStateEsqueciSenha}>
              Esqueci minha senha
            </Link>
          </span>
        </div>
        <div className="p-col-12" style={{ 'textAlign': 'left', 'paddingTop': '5px'}}>
          <span>
            <Link to={`/login`} onClick={props.setStatePrimeiroAcesso}>
              Criar uma conta
            </Link>
          </span>
        </div>
      </div>
    );
}

function getEsqueciSenhaForm(props) {
    return (
        <div className="p-col-12">
          <div className="p-col-12">
            <label className="label">Informe seu CPF ou CNPJ</label>
            <InputText
              name="login"
              value={props.login}
              onChange={props.changeValue}
              onKeyDown={props.onKeyDown}
            />
          </div>
          <div className="p-col-12">
            <Button
              label="Enviar senha"
              icon={icons.PERSON_ICON}
              onClick={props.reenviarSenha}
            />
          </div>
          <div className="p-col-12">
            <Link to={`/login`} onClick={props.setStateLogin}>
              Voltar para tela de login
            </Link>
          </div>  
        </div>
    );
}

function getPrimeiroAcessoForm(props) {
    return (
        <div className="p-col-12" style={{ 'textAlign': 'left' }}>
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
            <RadioButton inputId="fisica" name="fisica" value={props.conta.tipoPessoa} checked={_.get(props, 'conta.tipoPessoa') === 'FISICA'}
                         onChange={props.onChangeTipoPessoa} />
            <label htmlFor="fisica" className="ml-2">Física</label>

            <RadioButton inputId="juridica" name="juridica" value={props.conta.tipoPessoa} checked={_.get(props, 'conta.tipoPessoa') === 'JURIDICA'} style={{'marginLeft': '10px'}}
                         onChange={props.onChangeTipoPessoa}/>
            <label htmlFor="juridica" className="ml-2">Jurídica</label>
          </div>

          {_.get(props,'conta.tipoPessoa') === 'FISICA' &&
            <div className="p-col-12" >
              <label className="label">Informe seu CPF</label>
              <InputMask
                mask="999.999.999-99"
                unmask={true}
                name="documento"
                value={props.conta.documento}
                onChange={props.inputHandlerCriarConta}
              />
            </div>
          }
          {_.get(props,'conta.tipoPessoa') === 'JURIDICA' &&
            <div className="p-col-12" >
              <label className="label">Informe seu CNPJ</label>
              <InputMask
                mask="99.999.999/9999-99"
                unmask={true}
                name="documento"
                value={props.conta.documento}
                onChange={props.inputHandlerCriarConta}
              />
            </div>
          }
          <div className="p-col-12">
            <label className="label">E-mail</label>
            <InputText
              name="email"
              value={props.conta.email}
              onChange={props.inputHandlerCriarConta}
            />
          </div>
          <div className="p-col-12">
            <label className="label">Senha</label>
            <InputText
              name="senha"
              type="password"
              value={props.conta.senha}
              onChange={props.inputHandlerCriarConta}
            />
          </div>
          <div className="p-col-12">
            <label className="label">Confirma senha</label>
            <InputText
              name="confirmaSenha"
              type="password"
              value={props.conta.confirmaSenha}
              onChange={props.inputHandlerCriarConta}
            />
          </div>
          <div className="p-col-12">
            <Button
              label="Cadastrar"
              onClick={props.criarConta}
            />
          </div>
          <div className="p-col-12">
            <Link to={`/login`} onClick={props.setStateLogin}>
              Voltar para tela de login
            </Link>
          </div>  
        </div>
    );
}

function getForm(props) {
  if (props.isLogin) {
    return getLoginForm(props)
  } else if (props.isEsqueciSenha) {
      return getEsqueciSenhaForm(props)
  } else if (props.isPrimeiroAcesso) {
      return getPrimeiroAcessoForm(props)
  }
}

const LoginForm = props => (
  <div className="login-panel p-fluid" style={{ 'height': props.isPrimeiroAcesso ? '700px' : '550px', 'top': props.isPrimeiroAcesso ? '40%' : '50%'}}>
    <div className="login-panel-header">
      <img src={logo_phoenix} alt="phoenix" />
    </div>
    <div className="login-panel-content">
      <div className="p-grid">
        <div className="p-col-12">
          <h1>{getLabel(props)}</h1>
        </div>
        {getForm(props)}
      </div>
    </div>
  </div>
);

export default LoginForm;
