import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
const BASE_PATH = '/lottustech/enderecos'
const crud = build(validate, BASE_PATH)
const prefix = 'crudEndereco'

const newTypes = {
  RESET: `${prefix}reset`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.reset = () => {
  return {
    type: types.RESET,
  }
}

export { types, actions }
