import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import { getDocumentoByOid } from '../../services/api-documento'
import _ from 'lodash'
import { messageActions } from '../../store/message'
const BASE_PATH = '/lottustech/menus'
const prefix = 'crudMenu'
const crud = build(validate, BASE_PATH)

const newTypes = {
  SET_LOOKUP_MENU_SUPERIOR_VISIBLE: `${prefix}setLoockupMenuSuperiorVisible`,
  SET_DOCUMENTO: `${prefix}setDocumento`,
}

const actions = new Action(prefix, crud)

const types = Object.assign({}, actions.types, newTypes)

actions.setLookupMenuVisible = (visible, type) => {
  return {
    type: type,
    visible,
  }
}

actions.loadDocumentoByOid = (oidDocumento) => {
  return (dispatch) => {
    getDocumentoByOid(oidDocumento)
      .then((response) => {
        const data = _.get(response, 'data', null)
        if (data) {
          const oid = _.get(data, 'oid', '')
          const nome = _.get(data, 'nome', '')
          dispatch(actions.setField('oidDocumento', oid))
          dispatch(actions.setField('documento', { id: oid, value: nome }))
        }
      })
      .catch((error) => {
        dispatch(messageActions.showMessages(`Erro obtendo documento`, 'error'))
      })
  }
}
export { types, actions }
