import React from 'react';

import ExumacoesRelatorioFormPageContainer from './ExumacoesRelatorioFormPageContainer';

export class ExumacoesRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <ExumacoesRelatorioFormPageContainer {...this.props} />
        )
    }
}
