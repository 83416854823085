import React, {Component} from "react";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import utils from "../../utils/index";
import {ConfirmDialog} from "../confirmDialog/ConfirmDialog";
import storage from "../../services/stoage.service";
import {icons, SMALL_WINDOW_WIDTH} from "../../constants/index";

export class DefaultFormToolBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      permissoes: utils.getPermissoesPorMenu(
        storage.getMenus(),
        storage.getOidCurrentMenu(),
        props.formPageContainer.props.location.pathname,
        storage.getPortalCliente()
      )
    };
    this.messages = storage.getAppLabels("dialogs");
    this.confirmSaveDialog = false;
  }

  componentDidMount() {
    if (this.props.formPageContainer.props.getBotoesDinamicos) {
      this.props.formPageContainer.props.getBotoesDinamicos(this.props.formPageContainer.props.idTela);
    }
  }

  isDisableRemover() {
    const record = this.props.formPageContainer.props.record;
    return !record || !record.oid || !Number.isInteger(record.oid);
  }

  getBotaoRemover() {
    if (
      (this.props.formPageContainer.hideBotaoRemover !== undefined &&
        this.props.formPageContainer.hideBotaoRemover()) ||
      (this.state.permissoes && !this.state.permissoes.excluir)
    ) {
      return null;
    }
    return (
      <Button
        disabled={this.isDisableRemover()}
        onClick={e => (this.props.formPageContainer.state.showDialogRemove = true)}
        label={this.getLabelBotao(utils.getMessage(this.props.formPageContainer.props.messages, "label.remover"))}
        icon={icons.TRASH_ICON}
        style={{marginRight: ".25em"}}
        className="p-button-danger"
      />
    );
  }

  getBotaoNovo() {
    if (
      (this.state.permissoes && !this.state.permissoes.inserir) ||
      this.props.formPageContainer.props.newHandler === undefined
    ) {
      return null;
    }
    return (
      <Button
        onClick={this.props.formPageContainer.props.newHandler}
        label={this.getLabelBotao(utils.getMessage(this.props.formPageContainer.props.messages, "label.novo"))}
        icon={icons.PLUS_ICON}
        style={{marginRight: ".25em"}}
      />
    );
  }

  hasSaveButton() {
    if (this.props.formPageContainer.props.record.oid && this.state.permissoes && !this.state.permissoes.alterar) {
      return false;
    }
    return this.props.formPageContainer.props.saveHandler !== undefined;
  }

  getLabelBotao(label) {
    return window.innerWidth > SMALL_WINDOW_WIDTH ? label : undefined
  }

  getBotaoSalvar() {
    if (this.hasSaveButton()) {
      return (
        <Button
          onClick={e => (this.props.showConfirmSaveDialog ? this.confirmSaveDialog = true : this.props.formPageContainer.props.saveHandler())}
          disabled={this.props.formPageContainer.props.isSaving}
          label={this.getLabelBotao(utils.getMessage(this.props.formPageContainer.props.messages, "label.salvar"))}
          icon={icons.SAVE_LARGE_ICON}
          style={{marginRight: ".25em"}}
          className="p-button-success"
        />
      );
    }
    return null
  }

  getBotaoPDF() {
    if (this.props.formPageContainer.baixarPDF) {
      if (this.props.formPageContainer.props.record.oid) {
        return this.props.formPageContainer.getBotaoPDF();
      }
    }
  }

  getBotaoPesquisar() {
    return (
      <Button
        onClick={this.props.formPageContainer.searchHandler}
        label={this.getLabelBotao(utils.getMessage(this.props.formPageContainer.props.messages, "label.pesquisar"))}
        icon={icons.SEARCH_ICON}
        style={{marginRight: ".25em"}}
      />
    );
  }

  getConfirmDialog() {
    if (this.props.formPageContainer.state.showDialogRemove) {
      return (
        <ConfirmDialog
          header={utils.getMessage(this.messages, "dialog.titulo.confirmacao")}
          visible={this.props.formPageContainer.state.showDialogRemove}
          content={utils.getMessage(this.messages, "dialog.mensagem.confirmaremoverregistro")}
          confirm={this.props.formPageContainer.props.removeHandler}
          hideDialog={this.props.formPageContainer.hideDialog}
        />
      );
    }
    return null;
  }

  getConfirmDialogSave = () => {
    if (this.confirmSaveDialog && this.props.showConfirmSaveDialog) {
      this.confirmSaveDialog = false;
      return (
        <ConfirmDialog
          header={utils.getMessage(this.messages, "dialog.titulo.confirmacao")}
          visible={this.props.showConfirmSaveDialog}
          content={this.props.messageDialog}
          confirm={this.props.formPageContainer.props.saveHandler}
          hideDialog={e => (this.confirmSaveDialog = false)}
        />
      );
    }
    if (this.confirmSaveDialog) {
      this.props.formPageContainer.props.saveHandler();
      this.confirmSaveDialog = false;
    }
    return null;
  };

  // getBotoesPersonalizados = () => {
  //   if (this.props.formPageContainer.getBotoesPersonalizados) {
  //     let botoes = this.props.formPageContainer.getBotoesPersonalizados();
  //     return (
  //       <div className="p-toolbar-group-right">
  //         {botoes.map(botao => (
  //           <Button
  //             style={{marginLeft: "5px"}}
  //             type={botao.type}
  //             onClick={e => botao.onClick()}
  //             label={botao.label}
  //             className={botao.className}
  //             icon={botao.icon}
  //           />
  //         ))}
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  getBotoesPersonalizados = () => {
    if (this.props.formPageContainer.getBotoesPersonalizados) {
      let botoes = this.props.formPageContainer.getBotoesPersonalizados();
      return (
        <div>
          {botoes.map(botao => (
            <Button
              type={botao.type}
              onClick={e => botao.onClick()}
              label={botao.label}
              className={botao.className}
              icon={botao.icon}
              style={{marginRight: ".25em"}}
              disabled={botao.disabled || false}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  getSplitButton = () => {
    if (this.props.formPageContainer.getSplitButton) {
      return this.props.formPageContainer.getSplitButton();
    }
  };

  onClickBotaoDinamico = botao => {
    let listId = [];
    let record = this.props.formPageContainer.props.record;
    if (record.oid) {
      listId.push(record.oid);
    }
    this.props.formPageContainer.props.onClickBotaoDinamico(listId, botao);
  };

  resolveSplitAndDynamicButtons = () => {
    return (
      <React.Fragment>
        {this.getSplitButton()}
        {this.props.formPageContainer.props.botoesDinamicos &&
        this.props.formPageContainer.props.botoesDinamicos.length > 0 &&
        !this.props.formPageContainer.getSplitButton
          ? this.props.formPageContainer.props.botoesDinamicos.map(botao => {
            let styleBotao = utils.styleToObject(botao.style);
            return (
              <Button
                onClick={e => this.onClickBotaoDinamico(botao)}
                label={botao.textoBotao}
                style={styleBotao}
                className="p-button-success"
              />
            );
          })
          : null}
      </React.Fragment>
    )
  }

  leftContents = () => {
    return (
      <React.Fragment>
        {this.getConfirmDialogSave()}
        {this.getBotaoSalvar()}
        {/*{this.hasSaveButton() ? <i className="pi pi-bars p-toolbar-separator mr-2" /> : ""}*/}
        {this.getBotaoNovo()}
        {this.getBotaoPesquisar()}
        {this.getBotaoPDF()}
      </React.Fragment>
    )
  }

  rightContents = () => {
    return (
      <React.Fragment>
        {this.getBotaoRemover()}
      </React.Fragment>
    )
  }

  resolveCustomToolbar2 = () => {
    return (
      <Toolbar style={{paddingLeft: "2px", paddingBottom: "0px"}} left={this.resolveSplitAndDynamicButtons()}/>
    )
  }

  resolveCustomToolbar1 = () => {
    const customBtn = this.getBotoesPersonalizados();
    if (customBtn) {
      return (
        <Toolbar style={{paddingLeft: "2px", paddingBottom: "0px"}} left={customBtn}/>
      )
    }
    return null
  }

  render() {
    return (
      <div>
        {this.getConfirmDialog()}
        <Toolbar style={{paddingLeft: "2px", paddingBottom: "0px"}} left={this.leftContents()}
                 right={this.rightContents()}/>
        {this.resolveCustomToolbar1()}
        {this.resolveCustomToolbar2()}
      </div>
    );
  }
}
