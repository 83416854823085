import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { acoes, icons, routes } from '../../../constants'
import { actions } from '../orcamento.actions'
import { OrcamentoForm } from './OrcamentoForm'
import _ from 'lodash'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudOrcamentoState.currentRecord

  return {
    messages: state.crudOrcamentoState.messages,
    record,
    statePessoaFisica: state.crudPessoaFisicaState,
    currentUser: state.appState.currentUser,
    canEditHistorico: storage.getAcao(acoes.ACAO_ALTERAR_HISTORICO),
    canRemoveHistorico: storage.getAcao(acoes.ACAO_REMOVER_HISTORICO),
    isSaving: record.status === 2 || record.status === 3,
    tiposSepuluraToHide: [state.crudOrcamentoState.oidTipoSepulturaCapela],
    stateMarcador: state.crudMarcadorState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    contratar: (oid) => dispatch(actions.contratar(oid)),
    imprimir: (oid) => dispatch(actions.imprimir(oid)),
  }
}

class OrcamentoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      activeIndex: 0,
    }
    this.history = props.history

    actions.afterSave = ({ oid }) => {
      this.history.replace(`${routes.PATH_ORCAMENTO}/${oid}`)
    }
    actions.afterNew = () => {
      this.history.push(`${routes.PATH_ORCAMENTO}/novo`)
    }
    actions.afterRemove = () => {
      this.history.replace(`${routes.PATH_ORCAMENTO}`)
    }
    this.setField = props.setField
    this.contratar = props.contratar
    this.imprimir = props.imprimir
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ORCAMENTO}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  onChangeUnit = (e) => {
    this.setField('unidade', e)
    // alterou unidade,reset no tipo
    this.setField('tipoSepultura', { id: '', value: '' })
  }

  setResponsavel = (data) => {
    const value = {
      id: _.get(data, 'oid', ''),
      value: { id: _.get(data, 'numeroDocumento', ''), value: _.get(data, 'nome', '') },
    }
    this.setField('responsavel', value)
  }

  getBotoesPersonalizados = () => {
    const oid = _.get(this, 'props.record.oid', null)
    const status = _.get(this, 'props.record.status', null)
    const disabled = !oid || status === 2 || status === 3
    return [
      {
        type: 'button',
        label: 'Contratar',
        onClick: this.handlerContratar,
        icon: icons.CHECK_ICON,
        disabled,
        className: 'p-button-success',
      },
      {
        type: 'button',
        label: 'Imprimir',
        onClick: this.handlerImprimir,
        icon: icons.PRINT,
      },
    ]
  }

  handlerContratar = () => {
    const oid = _.get(this, 'props.record.oid', null)
    if (oid) {
      this.contratar(oid)
    }
  }

  handlerImprimir = () => {
    const oid = _.get(this, 'props.record.oid', null)
    if (oid) {
      this.imprimir(oid)
    }
  }

  onChangeTaxas = (data) => {
    this.setField('taxas', _.get(data, 'values', []))
    this.setValorTotal(data)
    this.setItemPrincipal(data)
  }

  setValorTotal = (data) => {
    let itens = _.get(data, 'values', [])
    let total = 0
    for (const value of itens) {
      const quantidade = _.get(value, 'quantidade', 0)
      const valorItem = _.get(value, 'valor', 0)
      total += utils.multiply(quantidade, valorItem, 2)
    }
    this.setField('valorTotal', total)
  }

  setItemPrincipal = (data) => {
    const values = _.get(data, 'values', [])
    if (utils.isArrayNullOrEmpty(values)) {
      this.setField('oidItemPrincipal', null)
      return
    }

    const oidItemPrincipalAtual = _.get(this, 'props.record.oidItemPrincipal', null)
    const serviceRemoved = _.get(data, 'serviceRemoved', null)
    const serviceAdded = _.get(data, 'serviceAdded', null)
    if (serviceAdded && !oidItemPrincipalAtual) {
      this.setField('oidItemPrincipal', _.get(serviceAdded, 'itemLancamento.oidTipo', null))
      this.setField('tipoItemPrincipal', _.get(serviceAdded, 'itemLancamento.tipo', null))
      return
    }

    if (serviceRemoved) {
      const oidTaxaRemoved = _.get(serviceRemoved, 'itemLancamento.oidTipo', null)
      if (oidTaxaRemoved === oidItemPrincipalAtual) {
        this.setField('oidItemPrincipal', _.get(values[0], 'itemLancamento.oidTipo', null))
        this.setField('tipoItemPrincipal', _.get(values[0], 'itemLancamento.tipo', null))
      }
    }
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  render() {
    const content = (
      <OrcamentoForm
        state={this.state}
        changeActiveIndex={this.changeActiveIndex}
        record={this.props.record}
        onChangeUnit={this.onChangeUnit}
        setResponsavel={this.setResponsavel}
        history={this.props.history}
        statePessoaFisica={this.props.statePessoaFisica}
        setField={this.setField}
        currentUser={this.props.currentUser}
        canEditHistorico={this.props.canEditHistorico}
        canRemoveHistorico={this.props.canRemoveHistorico}
        onChangeTaxas={this.onChangeTaxas}
        tiposSepuluraToHide={this.props.tiposSepuluraToHide}
        stateMarcador={this.props.stateMarcador}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrcamentoFormPageContainer)
