import React from 'react';
import NfseListPageContainer from './NfseListPageContainer';

export class NfseListPage extends React.Component{
    
    render(){
      return (
        <NfseListPageContainer {...this.props}/>
      )
    }
}

