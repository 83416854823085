import React from 'react'
import { enums, icons, mascaras, routes } from '../../../constants'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { LookupField } from '../../../components/lookup/LookupField'
import utils from '../../../utils/index'
import { TabPanel, TabView } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'
import './ContratoForm.scss'
import _ from 'lodash'
import ContratoAnexo from './contrato-anexo'
import ContratoDocumentos from './contrato-documentos'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import '../../../index.scss'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import { Toast } from 'primereact/toast'
import ContratoPlano from './contrato-plano'
import ContratoFalecidoTanatopraxia from './contrato-falecido-tanatopraxia'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'
import LtsLookupEndereco from '../../../components/lottus/lts-lookup-endereco'
import ContratoFalecidoEpi from './contrato-falecido-epi'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import ContratoFalecidoAdiau from './contrato-falecido-adiau'
import { PessoaFisicaFormPage } from '../../pessoaFisica'
import LtsCamposComplementares from '../../../components/lottus/lts-campos-complementares'
import LtsLookupCausaMortis from '../../../components/lottus/lts-lookup-causa-mortis'

function onChangeDataFalecimento(e, props) {
  const valor = e.value
  if (valor && window.moment().isBefore(window.moment(valor))) {
    props.toastRef.current.show({
      severity: 'error',
      summary: '',
      detail: 'O campo data de falecimento não pode ser maior que a data atual!',
    })
    return
  }
  props.editarDadoFalecido(e)
}

function getContratoDependenteCard(props, messages) {
  const footer = (
    <div>
      <Button
        onClick={(e) => {
          props.adicionarDependente()
          props.showForm('dependente', false)
        }}
        label={utils.getMessage(props.messages, 'contrato.label.adicionar')}
        icon={icons.PLUS_ICON}
        className="p-button-success"
      />
    </div>
  )

  let dependente = _.get(props, 'dependente', null)

  function getParentescoField() {
    return (
      <div className="p-grid form-group margin-top-dialogs">
        <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-6">
          <LtsDropdownIdValue
            name={'parentesco'}
            value={dependente.parentesco}
            onChange={props.editarDadoDependente}
            loadDataFn={apiService.getParentesco}
            label={utils.getMessage(messages, 'contrato.label.parentesco')}
            style={{ width: '80%' }}
            loadDataFnParam={_.get(props, 'record.unidade.id', null)}
          />
        </div>
      </div>
    )
  }

  function getAdicionarDependeteCadastrado() {
    return (
      <div className="p-grid">
        <div className="p-col-12 p-sm-11 p-md-8 p-lg-2">
          <LtsDropdownIdValue
            name={'parentesco'}
            value={dependente.parentesco}
            onChange={props.editarDadoDependente}
            loadDataFn={apiService.getParentesco}
            label={utils.getMessage(messages, 'contrato.label.parentesco')}
            style={{ width: '80%' }}
            loadDataFnParam={_.get(props, 'record.unidade.id', null)}
          />
        </div>

        <div className="p-col-12 p-sm-11 p-md-8 p-lg-3">
          <LtsLookupPessoa
            header={'Dependente'}
            name={'dependente'}
            field={'nome'}
            label={'Pesquisar por dependente'}
            value={{
              id: _.get(dependente, 'oidDependente', null),
              value: _.get(dependente, 'dependente', null),
            }}
            oidRecord={_.get(dependente, 'oidDependente', '')}
            history={props.history}
            onChange={(e) => {
              props.adicionarDependenteCadastrado(e)
              props.showForm('dependenteCadastrado', false)
            }}
            showAddButton={false}
            tipoPessoa={enums.TipoPessoa.FISICA}
            editHidden={false}
          />
        </div>
      </div>
    )
  }

  function getBotaoPesquisarDependeteCadastrado() {
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-11 p-md-8 p-lg-2">
          <Button
            onClick={(e) => props.showForm('dependenteCadastrado', true)}
            icon={icons.SEARCH_ICON}
            className="p-button-success"
            label="Pesquisar dependente"
          />
        </div>
      </div>
    )
  }

  if (props.isTipoContratoDependente) {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-col-1" style={{ display: 'inline-flex' }}>
              <div>
                <Button
                  onClick={(e) => props.showForm('dependente', true)}
                  icon={icons.PLUS_ICON}
                  className="p-button-success"
                />
              </div>
              <h5 style={{ marginLeft: '5px', marginTop: '5px' }}>
                {utils.getMessage(messages, 'contrato.label.dependentes')}
              </h5>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12">
                <DataTable
                  value={props.dependentes}
                  emptyMessage="  "
                  // V7 properties //
                  responsiveLayout="scroll"
                  filterDisplay="row"
                  size={'small'}
                  stripedRows={true}
                >
                  {props.colunasTDependente}
                </DataTable>
              </div>
            </div>

            {props.displayForm.dependenteCadastrado ? getAdicionarDependeteCadastrado() : ''}
            {getBotaoPesquisarDependeteCadastrado()}

            <Dialog
              header={utils.getMessage(messages, 'contrato.label.dependente')}
              visible={props.displayForm.dependente}
              onHide={(e) => {
                props.showForm('dependente', false)
                props.clearForm()
              }}
              baseZIndex={1000000}
              modal={true}
              footer={footer}
              className="dialog-pessoa"
              contentClassName="dialog-content-pessoa"
            >
              <React.Fragment>
                <PessoaFisicaFormPage
                  renderToolbar={false}
                  renderTabView={false}
                  additionalFields={getParentescoField()}
                  isPet={props.isTipoContratoPet}
                ></PessoaFisicaFormPage>
              </React.Fragment>
            </Dialog>
          </div>
        </div>
      </div>
    )
  }
}

function onHideFormFalecido(props) {
  if (props.isLocalizacaoSepultamentoInvalida()) {
    return
  }
  props.showForm('falecido', false)
}

function setResponsavel(props, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    props.setResponsavel(e)
  } else {
    props.responsavel.responsavel = _.get(e, 'nome', '')
    props.setField('responsavel', props.responsavel)
  }
}

function setDependente(props, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    props.setResponsavel(e)
  } else {
    props.responsavel.responsavel = _.get(e, 'nome', '')
    props.setField('responsavel', props.responsavel)
  }
}

function setFuneraria(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.funeraria = _.get(e, 'nome', '')
    falecido.oidFuneraria = _.get(e, 'oid', '')
  } else {
    falecido.funeraria = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setCelebrante(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.nomeCelebrante = _.get(e, 'nome', '')
    falecido.oidCelebrante = _.get(e, 'oid', '')
  } else {
    falecido.nomeCelebrante = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setCerimonialista(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.nomeCerimonialista = _.get(e, 'nome', '')
    falecido.oidCerimonialista = _.get(e, 'oid', '')
  } else {
    falecido.nomeCerimonialista = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setMedico(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.nomeMedico = _.get(e, 'nome', '')
    falecido.oidMedico = _.get(e, 'oid', '')
  } else {
    falecido.nomeMedico = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setMedico2(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.nomeMedico2 = _.get(e, 'nome', '')
    falecido.oidMedico2 = _.get(e, 'oid', '')
  } else {
    falecido.nomeMedico2 = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setJuiz(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.nomeJuiz = _.get(e, 'nome', '')
    falecido.oidJuiz = _.get(e, 'oid', '')
  } else {
    falecido.nomeJuiz = _.get(e, 'nome', '')
  }
  props.setField('falecido', falecido)
}

function setFalecido(props, falecido, e) {
  const oid = _.get(e, 'oid', null)
  if (oid) {
    falecido.falecido = _.get(e, 'nome', '')
    falecido.cpfFalecido = _.get(e, 'numeroDocumento', '')
    falecido.oidFalecido = _.get(e, 'oid', '')
    const dataNascimento = _.get(e, 'dataNascimento', '')
    if (parseInt(dataNascimento)) {
      falecido.dataNascimento = new Date(dataNascimento)
    } else {
      falecido.dataNascimento = ''
    }
    const dataFalecimento = _.get(e, 'dataFalecimento', '')
    if (parseInt(dataFalecimento)) {
      falecido.dataFalecimento = new Date(dataFalecimento)
    } else {
      falecido.dataFalecimento = ''
    }
  } else {
    falecido.cpfFalecido = _.get(e, 'nome', null)
  }

  props.setField('falecido', falecido)
}

const setField = (props, field, value) => {
  const falecido = _.get(props, 'falecido', {})
  falecido[field] = value
  props.setField('falecido', falecido)
}

const getCausaMortisFalecido = (falecido) => {
  const causaMortis = _.get(falecido, 'causasMortis', [])
  return causaMortis.map((cm) => ({
    oid: cm.oidCausaMortis,
    descricao: cm.descricaoCausaMortis,
  }))
}

const setCausasMortis = (props, falecido, value) => {
  falecido.causasMortis = _.defaultTo(value, []).map((cm) => ({
    oidCausaMortis: cm.oid,
    descricaoCausaMortis: cm.descricao,
  }))
  props.setField('falecido', falecido)
}

const marcadoresFalecido = (props, falecido) => {
  const marcadores = []
  if (falecido.marcadores) {
    falecido.marcadores.forEach((marcador) => {
      marcadores.push({ oid: marcador.id, codigo: marcador.value })
    })
  }
  return marcadores
}

const setMarcadoresFalecido = (props, falecido, value) => {
  falecido.marcadores = value
  props.setField('falecido', falecido)
}

const setDeclaranteFalecido = (props, falecido, value) => {
  falecido.declarante = value
  props.setField('falecido', falecido)
}

const getValorDeclaranteFalecido = (props, falecido) => {
  if (_.get(falecido, 'declarante.id', null)) {
    return {
      id: _.get(falecido, 'declarante.id', null),
      value: _.get(falecido, 'declarante.value', null),
    }
  } else if (props.responsaveis?.length > 0 && props.responsaveis[0].oidResponsavel) {
    const value = {
      id: props.responsaveis[0].oidResponsavel,
      value: props.responsaveis[0].responsavel,
    }
    falecido.declarante = value
    props.setField('falecido', falecido)

    return value
  }
  return null
}

const setParentescoFalecido = (props, falecido, value) => {
  falecido.parentesco = value
  props.setField('falecido', falecido)
}

const getCamerasDisponiveis = (props, falecido) => {
  const camerasSelecionadas = _.get(falecido, 'integracaoAdiau.cameras', [])
  return props.camerasAdiauDisponiveis.filter((c) => camerasSelecionadas.findIndex((cs) => cs.id === c.id) < 0)
}

function getContratoFalecidoCard(props, messages) {
  let falecido = props.falecido

  // REFERENTE AO ANEXO DO CONTRATO FALECIDO
  function getContentAnexoContratoFalecido() {
    const anexos = falecido.anexos
    if (utils.isArrayNullOrEmpty(anexos)) {
      return null
    }
    return anexos.map((anexo) => (
      <div className="p-grid">
        <img src={anexo.link} width={'25%'} alt={''}></img>
        <div className="p-col-12 p-sm-11 p-md-11 p-lg-11 p-xl-11">
          <Button
            type="p-button"
            label={anexo.filename}
            onClick={(e) => props.downloadAnexoFalecido(anexo)}
            icon={icons.DOWNLOAD}
            className="p-button-secondary"
            disabled={isNaN(anexo.oid)}
          />
        </div>
        <div className="p-col-12 p-sm-1 p-md-1 p-lg-1 p-xl-1">
          <Button
            type="p-button-icon"
            icon={icons.REMOVE_ICON}
            onClick={(e) => props.removeAnexoFalecido(anexo)}
            className="p-button-danger"
          />
        </div>
      </div>
    ))
  }

  function getAnexoContratoFalecidoWrapper() {
    return (
      <div className="card card-w-title">
        <div>
          <h3>{utils.getMessage(messages, 'contrato.label.fotofalecido')}</h3>
          <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo-contrato-falecido">
            {utils.getMessage(messages, 'contrato.label.adicionar')}
          </label>
          <input id="selecao-arquivo-contrato-falecido" type="file" onChange={props.onSelectAnexoFalecido} />
        </div>
        <br />
        {getContentAnexoContratoFalecido()}
      </div>
    )
  }

  if (props.isTipoContratoFalecido) {
    const footer = (
      <div>
        <Button
          label={utils.getMessage(messages, 'contrato.label.adicionar')}
          onClick={(e) => {
            props.adicionarFalecido()
          }}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    const footerDialogCadastrarCausaMortis = (
      <div>
        <Button
          label={utils.getMessage(messages, 'contrato.label.adicionar')}
          onClick={(e) => {
            props.salvarNovaCausaMortis()
            props.showForm('cadastrarCausaMortis', false)
          }}
          icon={icons.PLUS_ICON}
          className="p-button-success"
        />
      </div>
    )

    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <div className="p-col-1" style={{ display: 'inline-flex' }}>
              <div>
                <Button onClick={props.showFormFalecido} icon={icons.PLUS_ICON} className="p-button-success" />
              </div>
              <h5 style={{ marginLeft: '5px', marginTop: '5px' }}>
                {utils.getMessage(messages, 'contrato.label.falecidos')}
              </h5>
            </div>

            <div className="p-grid form-group">
              <div className="p-col-12">
                <DataTable
                  value={props.falecidos}
                  reorderableRows={true}
                  onRowReorder={(e) => props.changeOrderList(e, 'falecidos')}
                  totalRecords={props.falecidos ? props.falecidos.length : 0}
                  emptyMessage="  "
                  // V7 properties //
                  responsiveLayout="scroll"
                  filterDisplay="row"
                  size={'small'}
                  stripedRows={true}
                >
                  {props.colunasTFalecido}
                </DataTable>
              </div>
            </div>

            <Dialog
              id={'dialogfalecido'}
              header={''}
              visible={props.displayForm.falecido}
              onHide={(e) => onHideFormFalecido(props)}
              baseZIndex={1000000}
              modal={true}
              closeOnEscape={false}
              style={{ width: '70%' }}
              contentStyle={{ maxHeight: '90%', overflow: 'auto' }}
              footer={footer}
            >
              <Toast ref={props.toastRef}></Toast>
              <TabView>
                <TabPanel header={utils.getMessage(messages, 'contrato.label.falecido')}>
                  <div>
                    <div className="p-grid form-group margin-top-dialogs">
                      <div className="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
                        <LtsLookupPessoa
                          header={'Falecido'}
                          name={'falecido'}
                          field={'cpfFalecido'}
                          label={utils.getMessage(messages, 'contrato.label.pesquisarporfalecido')}
                          value={{
                            id: _.get(falecido, 'oidFalecido', null),
                            value: _.get(falecido, 'cpfFalecido', null),
                          }}
                          oidRecord={_.get(falecido, 'oidFalecido', '')}
                          history={props.history}
                          onChange={(e) => setFalecido(props, falecido, e)}
                          statePessoaFisica={props.statePessoaFisica}
                          tipoPessoa={enums.TipoPessoa.FISICA}
                          showAddButton={false}
                        />
                      </div>
                    </div>
                    <div className="p-grid form-group">
                      <div className="p-col-12">
                        <span className="p-float-label">
                          <InputText
                            value={falecido.falecido}
                            onChange={props.editarDadoFalecido}
                            name="falecido"
                            className={'width-25-rem'}
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.nomeFalecido')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
                        <span className="p-float-label">
                          <LtsLookupMarcador
                            label={utils.getMessage(messages, 'contrato.label.marcador')}
                            value={marcadoresFalecido(props, falecido)}
                            history={props.history}
                            editHidden={true}
                            field={'codigo'}
                            multiple={true}
                            onChange={(data) => {
                              const values = []
                              data.forEach((object) => {
                                values.push({
                                  id: _.get(object, 'oid', ''),
                                  value: _.get(object, 'codigo', ''),
                                })
                              })
                              setMarcadoresFalecido(props, falecido, values)
                            }}
                            showAddButton={true}
                            stateMarcador={props.stateMarcador}
                            tipo={'FALECIDO'}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                        <LtsLookupPessoa
                          header={utils.getMessage(messages, 'contrato.label.declarante')}
                          label={utils.getMessage(messages, 'contrato.label.declarante')}
                          name={'declarante'}
                          field={'nome'}
                          value={getValorDeclaranteFalecido(props, falecido)}
                          oidRecord={_.get(falecido, 'declarante.id', '')}
                          history={props.history}
                          onChange={(e) => setDeclaranteFalecido(props, falecido, { id: e.oid, value: e.nome })}
                          showAddButton={false}
                          tipoPessoa={enums.TipoPessoa.FISICA}
                        />
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                        <LtsDropdownIdValue
                          name={'parentesco'}
                          value={falecido.parentesco}
                          onChange={(e) => setParentescoFalecido(props, falecido, e)}
                          loadDataFn={apiService.getParentesco}
                          label={utils.getMessage(messages, 'contrato.label.parentesco')}
                          style={{ width: '100%' }}
                          loadDataFnParam={_.get(props, 'record.unidade.id', null)}
                        />
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            value={falecido.dataNascimento}
                            yearNavigator={true}
                            monthNavigator={true}
                            yearRange={utils.getYearRangeFromDate('1800')}
                            onChange={props.editarDadoFalecido}
                            name="dataNascimento"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.nascimento')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            value={utils.getDateTimeFromNumber(falecido.dataFalecimento)}
                            showTime={true}
                            yearNavigator={true}
                            monthNavigator={true}
                            yearRange={utils.getYearRangeFromDate('1800')}
                            onChange={(e) => onChangeDataFalecimento(e, props)}
                            name="dataFalecimento"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.data.hora.falecimento')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            value={falecido.dataPrevisaoExumacao}
                            onChange={props.editarDadoFalecido}
                            yearNavigator={true}
                            monthNavigator={true}
                            yearRange={utils.getYearRangeFromDate('1800')}
                            disabled={!props.ACAO_EDITAR_DATA_EXUMACAO}
                            name="dataPrevisaoExumacao"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.previsaoExumacao')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            value={falecido.dataExumacao}
                            onChange={props.editarDadoFalecido}
                            yearNavigator={true}
                            monthNavigator={true}
                            yearRange={utils.getYearRangeFromDate('1800')}
                            disabled={!props.ACAO_EDITAR_DATA_EXUMACAO}
                            name="dataExumacao"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.exumacao')}</label>
                        </span>
                      </div>
                    </div>
                    {getLookupLocalizacaoDestinoFalecido(falecido, props, props.messages)}
                    {getLabelLocalizacaoInvalida(props, messages)}
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-3">
                        <span className="p-float-label">
                          <InputText
                            value={falecido.numeroGaveta}
                            onChange={props.editarDadoFalecido}
                            name="numeroGaveta"
                            maxLength={20}
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.numeroGaveta')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-3">
                        <span className="p-float-label">
                          <InputText
                            keyfilter={/^[0-9.,]+$/}
                            value={falecido.atestadoObito}
                            maxLength={10}
                            onChange={props.editarDadoFalecido}
                            name="atestadoObito"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.atestadoobito')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-3">
                        <span className="p-float-label">
                          <InputText
                            keyfilter={/^[0-9.,]+$/}
                            value={falecido.peso === 0 ? '' : falecido.peso}
                            maxLength={10}
                            onBlur={(e) => props.onBlurPeso(falecido, e)}
                            onChange={props.editarDadoFalecido}
                            name="peso"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.peso')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-3">
                        <span className="p-float-label">
                          <InputText
                            keyfilter={/^[0-9.,]+$/}
                            value={falecido.altura === 0 ? '' : falecido.altura}
                            maxLength={10}
                            onBlur={(e) => props.onBlurAltura(falecido, e)}
                            onChange={props.editarDadoFalecido}
                            name="altura"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.altura')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                        <span className="p-float-label">
                          <Calendar
                            value={falecido.dataTranslado}
                            onChange={props.editarDadoFalecido}
                            yearNavigator={true}
                            monthNavigator={true}
                            yearRange={utils.getYearRangeFromDate('1800')}
                            name="dataTranslado"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.translado')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
                        <span className="p-float-label">
                          <InputText
                            value={falecido.processoTranslado}
                            onChange={props.editarDadoFalecido}
                            name="processoTranslado"
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.processoTranslado')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group p-align-end">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
                        <span className="p-float-label">
                          <InputText value={falecido.link} onChange={props.editarDadoFalecido} name="link" />
                          <label>{utils.getMessage(messages, 'contrato.label.link')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
                        <span className="p-float-label">
                          <InputTextarea
                            value={falecido.observacao}
                            onChange={props.editarDadoFalecido}
                            name="observacao"
                            rows={2}
                            cols={30}
                            autoResize={true}
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.observacao')}</label>
                        </span>
                      </div>
                    </div>
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-6">
                        <span className="p-float-label">
                          <InputTextarea
                            value={falecido.sobre}
                            onChange={props.editarDadoFalecido}
                            name="sobre"
                            rows={2}
                            cols={30}
                            autoResize={true}
                          />
                          <label>{utils.getMessage(messages, 'contrato.label.sobre')}</label>
                        </span>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                        <span className="p-float-label">
                          <LtsLookupCausaMortis
                            header={utils.getMessage(messages, 'contrato.label.causa.mortis')}
                            label={utils.getMessage(messages, 'contrato.label.causas.mortis')}
                            value={getCausaMortisFalecido(falecido)}
                            history={props.history}
                            editHidden={true}
                            field={'descricao'}
                            multiple={true}
                            onChange={(e) => setCausasMortis(props, falecido, e)}
                            showAddButton={true}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="p-grid p-fluid">
                      <div className="p-col-12">{getAnexoContratoFalecidoWrapper()}</div>
                    </div>
                    {getLabelLocalizacaoInvalida(props, messages)}
                  </div>
                </TabPanel>

                {props.isTipoContratoFuneral ? (
                  <TabPanel header="Tanatopraxia">
                    <ContratoFalecidoTanatopraxia tanatopraxia={falecido.tanatopraxia} setField={props.setField} />
                  </TabPanel>
                ) : null}

                {props.isTipoContratoFuneral ? (
                  <TabPanel header="EPIs">
                    <ContratoFalecidoEpi epis={_.get(falecido, 'epis', [])} setField={props.setEpis} />
                  </TabPanel>
                ) : null}

                <TabPanel header="Localizações">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={utils.getMessage(messages, 'contrato.label.funeraria')}
                        name={'funeraria'}
                        field={'nome'}
                        label={utils.getMessage(messages, 'contrato.label.funeraria')}
                        value={{
                          id: _.get(falecido, 'oidFuneraria', null),
                          value: _.get(falecido, 'funeraria', null),
                        }}
                        oidRecord={_.get(falecido, 'oidFuneraria', '')}
                        history={props.history}
                        onChange={(e) => setFuneraria(props, falecido, e)}
                        statePessoaJurudica={props.statePessoaJuridica}
                        tipoPessoa={enums.TipoPessoa.JURIDICA}
                        showAddButton={false}
                      />
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupEndereco
                        label={utils.getMessage(messages, 'contrato.label.local.obito')}
                        name={'localObito'}
                        value={_.get(falecido, 'localObito', null)}
                        onChange={(e) => setField(props, 'localObito', e)}
                        history={props.history}
                        oidRecord={_.get(falecido, 'localObito', '').oid}
                      />
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupEndereco
                        label={utils.getMessage(messages, 'contrato.label.local.velorio')}
                        name={'localVelorio'}
                        value={_.get(falecido, 'localVelorio', null)}
                        onChange={(e) => setField(props, 'localVelorio', e)}
                        history={props.history}
                        oidRecord={_.get(falecido, 'localVelorio', '').oid}
                      />
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-2">
                      <span className="p-float-label">
                        <Calendar
                          value={utils.getDateTimeFromNumber(falecido.dataVelorio)}
                          yearNavigator={true}
                          monthNavigator={true}
                          yearRange={utils.getYearRangeFromDate('1800')}
                          onChange={(e) => props.editarDadoFalecido(e)}
                          name="dataVelorio"
                          showTime={true}
                        />
                        <label>{utils.getMessage(messages, 'contrato.label.data.hora')}</label>
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupEndereco
                        label={utils.getMessage(messages, 'contrato.label.local.celebracao')}
                        name={'localCelebracao'}
                        value={_.get(falecido, 'localCelebracao', null)}
                        onChange={(e) => setField(props, 'localCelebracao', e)}
                        history={props.history}
                        oidRecord={_.get(falecido, 'localCelebracao', '').oid}
                      />
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-2">
                      <span className="p-float-label">
                        <Calendar
                          value={utils.getDateTimeFromNumber(falecido.dataCelebracao)}
                          yearNavigator={true}
                          monthNavigator={true}
                          yearRange={utils.getYearRangeFromDate('1800')}
                          onChange={(e) => props.editarDadoFalecido(e)}
                          name="dataCelebracao"
                          showTime={true}
                        />
                        <label>{utils.getMessage(messages, 'contrato.label.data.hora')}</label>
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupEndereco
                        label={utils.getMessage(messages, 'contrato.label.local.sepultamento')}
                        name={'localSepultamento'}
                        value={_.get(falecido, 'localSepultamento', null)}
                        onChange={(e) => setField(props, 'localSepultamento', e)}
                        history={props.history}
                        oidRecord={_.get(falecido, 'localSepultamento', '').oid}
                      />
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-2">
                      <span className="p-float-label">
                        <Calendar
                          value={utils.getDateTimeFromNumber(falecido.dataSepultamento)}
                          yearNavigator={true}
                          monthNavigator={true}
                          yearRange={utils.getYearRangeFromDate('1800')}
                          onChange={(e) => props.editarDadoFalecido(e)}
                          name="dataSepultamento"
                          showTime={true}
                        />
                        <label>{utils.getMessage(messages, 'contrato.label.data.hora')}</label>
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={utils.getMessage(messages, 'contrato.label.celebrante')}
                        name={'celebrante'}
                        field={'celebrante'}
                        label={utils.getMessage(messages, 'contrato.label.celebrante')}
                        value={{
                          id: _.get(falecido, 'oidCelebrante', null),
                          value: _.get(falecido, 'nomeCelebrante', null),
                        }}
                        history={props.history}
                        onChange={(e) => setCelebrante(props, falecido, e)}
                        tipoPessoa={enums.TipoPessoa.FISICA}
                        showAddButton={false}
                        oidRecord={_.get(falecido, 'oidCelebrante', '')}
                      />
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={utils.getMessage(messages, 'contrato.label.cerimonialista')}
                        name={'cerimonialista'}
                        field={'cerimonialista'}
                        label={utils.getMessage(messages, 'contrato.label.cerimonialista')}
                        value={{
                          id: _.get(falecido, 'oidCerimonialista', null),
                          value: _.get(falecido, 'nomeCerimonialista', null),
                        }}
                        history={props.history}
                        onChange={(e) => setCerimonialista(props, falecido, e)}
                        tipoPessoa={enums.TipoPessoa.FISICA}
                        showAddButton={false}
                        oidRecord={_.get(falecido, 'oidCerimonialista', '')}
                      />
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={utils.getMessage(messages, 'contrato.label.medico')}
                        name={'medico'}
                        field={'medico'}
                        label={utils.getMessage(messages, 'contrato.label.medico')}
                        value={{
                          id: _.get(falecido, 'oidMedico', null),
                          value: _.get(falecido, 'nomeMedico', null),
                        }}
                        history={props.history}
                        onChange={(e) => setMedico(props, falecido, e)}
                        tipoPessoa={enums.TipoPessoa.FISICA}
                        showAddButton={false}
                        oidRecord={_.get(falecido, 'oidMedico', '')}
                      />
                    </div>

                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-2">
                      <span className="p-float-label">
                        <InputText value={falecido.crmMedico} onChange={props.editarDadoFalecido} name="crmMedico" />
                        <label>{utils.getMessage(messages, 'contrato.label.crm')}</label>
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={utils.getMessage(messages, 'contrato.label.medico2')}
                        name={'medico2'}
                        field={'medico2'}
                        label={utils.getMessage(messages, 'contrato.label.medico2')}
                        value={{
                          id: _.get(falecido, 'oidMedico2', null),
                          value: _.get(falecido, 'nomeMedico2', null),
                        }}
                        history={props.history}
                        onChange={(e) => setMedico2(props, falecido, e)}
                        tipoPessoa={enums.TipoPessoa.FISICA}
                        showAddButton={false}
                        oidRecord={_.get(falecido, 'oidMedico2', '')}
                      />
                    </div>
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-2">
                      <span className="p-float-label">
                        <InputText value={falecido.crmMedico2} onChange={props.editarDadoFalecido} name="crmMedico2" />
                        <label>{utils.getMessage(messages, 'contrato.label.crm')}</label>
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <LtsLookupPessoa
                        header={
                          props.isTipoContratoPet
                            ? utils.getMessage(messages, 'contrato.label.coletor')
                            : utils.getMessage(messages, 'contrato.label.juiz')
                        }
                        name={'juiz'}
                        field={'juiz'}
                        label={
                          props.isTipoContratoPet
                            ? utils.getMessage(messages, 'contrato.label.coletor')
                            : utils.getMessage(messages, 'contrato.label.juiz')
                        }
                        value={{
                          id: _.get(falecido, 'oidJuiz', null),
                          value: _.get(falecido, 'nomeJuiz', null),
                        }}
                        history={props.history}
                        onChange={(e) => setJuiz(props, falecido, e)}
                        tipoPessoa={enums.TipoPessoa.FISICA}
                        showAddButton={false}
                        oidRecord={_.get(falecido, 'oidJuiz', '')}
                      />
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
                      <span className="p-float-label">
                        <InputText
                          value={falecido.numeroDeclaracaoObito}
                          onChange={props.editarDadoFalecido}
                          name="numeroDeclaracaoObito"
                          className={'width-25-rem'}
                        />
                        <label>{utils.getMessage(messages, 'contrato.label.numero.declaracao.obito')}</label>
                      </span>
                    </div>
                  </div>
                </TabPanel>

                {props.integracaoAdiauHabilitada ? (
                  <TabPanel header={utils.getMessage(messages, 'contrato.label.integracao.adiau')}>
                    {
                      <ContratoFalecidoAdiau
                        integracaoAdiau={_.get(falecido, 'integracaoAdiau', {})}
                        camerasAdiauDisponiveis={getCamerasDisponiveis(props, falecido)}
                        oidContratoFalecido={_.get(falecido, 'oid', null)}
                        setField={props.setField}
                      />
                    }
                  </TabPanel>
                ) : null}

                {props.contratoFalecidoCamposComplementares &&
                Object.keys(props.contratoFalecidoCamposComplementares).length > 0 ? (
                  <TabPanel header="Campos complementares">
                    {
                      <LtsCamposComplementares
                        record={falecido}
                        camposComplementares={props.contratoFalecidoCamposComplementares}
                        setField={(e) =>
                          props.editarDadoFalecido({
                            target: {
                              name: 'dadosComplementares',
                              value: e,
                            },
                          })
                        }
                      />
                    }
                  </TabPanel>
                ) : null}
              </TabView>
            </Dialog>
          </div>
        </div>

        <Dialog
          header={'Cadastramento de nova causa mortis'}
          visible={props.displayForm.cadastrarCausaMortis}
          onHide={(e) => {
            props.showForm('cadastrarCausaMortis', false)
          }}
          baseZIndex={1000000}
          modal={true}
          style={{ width: '30%' }}
          footer={footerDialogCadastrarCausaMortis}
        >
          <br />
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-6">
              <span className="p-float-label">
                <InputText
                  value={props.descricaoNovaCausaMortis}
                  onChange={(e) => props.setDescricaoNovaCausaMortis(e.target.value)}
                  name="descricaoNovaCausaMortis"
                  autoFocus={true}
                  className={'width-25-rem'}
                />
                <label>{'Descrição'}</label>
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

function getContratoResponsavelCard(props, messages) {
  let responsavel = _.get(props, 'responsavel', null)
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <div className="p-col-1" style={{ display: 'inline-flex' }}>
            <div>
              <Button
                onClick={(e) => props.showForm('responsavel', true)}
                icon={icons.PLUS_ICON}
                className="p-button-success"
              />
            </div>
            <h5 style={{ marginLeft: '5px', marginTop: '5px' }}>
              {utils.getMessage(messages, 'contrato.label.responsaveis')}
            </h5>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12">
              <DataTable
                autoLayout={true}
                value={props.responsaveis}
                selectionMode="single"
                onPage={props.onPage}
                totalRecords={props.responsaveis ? props.responsaveis.length : 0}
                reorderableRows={props.acaoOrdenarResponsavel}
                emptyMessage="  "
                onRowReorder={(e) => props.changeOrderList(e, 'responsaveis', props.acaoOrdenarResponsavel)}
                responsiveLayout="scroll"
                filterDisplay="row"
                size={'small'}
                stripedRows
              >
                {props.colunasTResponsavel}
              </DataTable>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-11 p-md-8 p-lg-6 p-xl-5">
              <LtsLookupPessoa
                header={'Responsável'}
                name={'responsavel'}
                field={'nome'}
                label={'Pesquisar por responsável'}
                value={{
                  id: _.get(responsavel, 'oidResponsavel', null),
                  value: _.get(responsavel, 'responsavel', null),
                }}
                oidRecord={_.get(responsavel, 'oidResponsavel', '')}
                history={props.history}
                onChange={(e) => setResponsavel(props, e)}
                showAddButton={false}
                editHidden={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function getLabelLocalizacaoInvalida(props, messages) {
  if (props.localizacaoSepultamentoInvalida) {
    return (
      <div className="p-grid form-group">
        <div className="p-col-12">
          <label className="label-localizacao-destino-invalida">
            {utils.getMessage(messages, 'contrato.label.localizacao.sepultamento.invalida')}
          </label>
        </div>
      </div>
    )
  }
  return null
}

function getLookupLocalizacaoDestinoFalecido(falecido, props, messages) {
  if (!props.exibirLocalizacaoCemiterio) {
    return null
  }

  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
        <LookupField
          label={utils.getMessage(messages, 'contrato.label.localizacaoSepultamento')}
          value={falecido.localizacaoDestino ? falecido.localizacaoDestino.value : ''}
          name="localizacaoDestino"
          field="identificacao"
          history={props.history}
          onKeyPress={props.lookupLocalizacaoDestinoOnKeyPress}
          onComplete={props.lookupLocalizacaoOnComplete}
          onInputFilter={props.lookupLocalizacaoDestinoOnInputFilter}
          editDisabled={props.lookupLocalizacaoDestino.editDisabled}
          onEditField={props.lookupLocalizacaoDestinoOnEdit}
          onClick={props.lookupLocalizacaoDestinoOnClick}
          visible={props.lookupLocalizacaoDestino.visible}
          modal={props.lookupLocalizacaoDestino.modal}
          header={props.lookupLocalizacaoDestino.header}
          onHide={props.lookupLocalizacaoDestinoOnHide}
          onConfirm={props.lookupLocalizacaoDestinoOnConfirm}
          onCancel={props.lookupLocalizacaoDestinoOnCancel}
          records={props.lookupLocalizacaoDestino.records}
          columns={props.lookupLocalizacaoDestino.columns}
          totalRecords={props.lookupLocalizacaoDestino.totalRecords}
          sortOrder={props.lookupLocalizacaoDestino.order}
          sortField={props.lookupLocalizacaoDestino.sortField}
          first={props.lookupLocalizacaoDestino.first}
          rows={props.lookupLocalizacaoDestino.rows}
          onPage={props.lookupLocalizacaoOnPage}
          onFilter={props.lookupLocalizacaoOnFilter}
          onSort={props.lookupLocalizacaoOnSort}
          showFiltroTemporaria={true}
          apenasTemporaria={props.record.apenasLocalizacaoDestinoTemporaria}
          onChangeTemporaria={props.onChangeApenasLocalizacaoDestinoTemporaria}
        />
      </div>
    </div>
  )
}

function getLookupLocalizacao(props, messages) {
  if (!props.exibirLocalizacaoCemiterio) {
    return null
  }

  const oidTipoContrato = _.get(props, 'record.tipoSepultura.oid', null)
  const disabled = utils.isIdValueNullOrEmpty(props.record.unidade) || oidTipoContrato == null // comparando com '==' para contemplar tanto null quando undefined
  return (
    <div className="p-grid form-group">
      <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
        <LookupField
          label={utils.getMessage(messages, 'contrato.label.localizacao')}
          disabled={disabled}
          value={props.record.localizacao ? props.record.localizacao.value : ''}
          name="localizacao"
          field="identificacao"
          history={props.history}
          unidade={props.record.unidadeLocalizacao}
          unidades={props.unidades}
          showFiltroUnidade={true}
          showFiltroTemporaria={true}
          apenasTemporaria={props.record.apenasLocalizacaoTemporaria}
          onChangeUnit={props.onChangeUnitLocalizacao}
          onChangeTemporaria={props.onChangeApenasLocalizacaoTemporaria}
          onKeyPress={props.lookupLocalizacaoOnKeyPress}
          onComplete={props.lookupLocalizacaoOnComplete}
          onInputFilter={props.lookupLocalizacaoOnInputFilter}
          editDisabled={props.lookupLocalizacao.editDisabled}
          onEditField={props.lookupLocalizacaoOnEdit}
          onClick={props.lookupLocalizacaoOnClick}
          visible={props.lookupLocalizacao.visible}
          modal={props.lookupLocalizacao.modal}
          header={props.lookupLocalizacao.header}
          onHide={props.lookupLocalizacaoOnHide}
          onConfirm={props.lookupLocalizacaoOnConfirm}
          onCancel={props.lookupLocalizacaoOnCancel}
          records={props.lookupLocalizacao.records}
          columns={props.lookupLocalizacao.columns}
          totalRecords={props.lookupLocalizacao.totalRecords}
          sortOrder={props.lookupLocalizacao.order}
          sortField={props.lookupLocalizacao.sortField}
          first={props.lookupLocalizacao.first}
          rows={props.lookupLocalizacao.rows}
          onPage={props.lookupLocalizacaoOnPage}
          onFilter={props.lookupLocalizacaoOnFilter}
          onSort={props.lookupLocalizacaoOnSort}
        />
      </div>
      <div>
        <div className="p-grid form-group" style={{ marginTop: '5px' }}>
          <label className="label">{`Lugares: ${props.record.numeroLugares}`}</label>
        </div>
        <div className="p-grid form-group" style={{ marginTop: '3px' }}>
          <label className="label">{`Vagas: ${props.record.numeroVagas}`}</label>
        </div>
      </div>
    </div>
  )
}

function getLookupContratoCremacaoFutura(props, messages) {
  let record = props.record
  let isContratoCremacao = false
  if (record.unidade && parseInt(record.unidade.id)) {
    isContratoCremacao = utils.isContratoCremacao(props.state.units, record.unidade.id, record.tipoSepultura.id)
  }
  if (isContratoCremacao) {
    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
          <LookupField
            label={utils.getMessage(messages, 'contrato.label.cremacaofutura')}
            value={record.contratoCremacaoFutura}
            name="contratoCremacaoFutura"
            field="contratoCremacaoFutura"
            history={props.history}
            onKeyPress={props.lookupContratoCremacaoFuturaOnKeyPress}
            onComplete={props.lookupContratoCremacaoFuturaOnComplete}
            onInputFilter={props.lookupContratoCremacaoFuturaOnInputFilter}
            editDisabled={props.lookupContratoCremacaoFutura.editDisabled}
            onEditField={props.lookupContratoCremacaoFuturaOnEdit}
            onClick={props.lookupContratoCremacaoFuturaOnClick}
            visible={props.lookupContratoCremacaoFutura.visible}
            modal={props.lookupContratoCremacaoFutura.modal}
            header={props.lookupContratoCremacaoFutura.header}
            onHide={props.lookupContratoCremacaoFuturaOnHide}
            onConfirm={props.lookupContratoCremacaoFuturaOnConfirm}
            onCancel={props.lookupContratoCremacaoFuturaOnCancel}
            records={props.lookupContratoCremacaoFutura.records}
            columns={props.lookupContratoCremacaoFutura.columns}
            totalRecords={props.lookupContratoCremacaoFutura.totalRecords}
            sortOrder={props.lookupContratoCremacaoFutura.order}
            sortField={props.lookupContratoCremacaoFutura.sortField}
            first={props.lookupContratoCremacaoFutura.first}
            rows={props.lookupContratoCremacaoFutura.rows}
            onPage={props.lookupContratoCremacaoFuturaOnPage}
            onFilter={props.lookupContratoCremacaoFuturaOnFilter}
            onSort={props.lookupContratoCremacaoFuturaOnSort}
          />
        </div>
      </div>
    )
  }
}

export const ContratoForm = (props) => {
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler
  const onChangeTipoContrato = props.onChangeTipoContrato

  let unidade = {}
  if (utils.nonNull(record.unidade) && record.unidade.id) {
    unidade = { id: record.unidade.id, value: record.unidade.value }
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.CONTRATO}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getLinkContratoOrigem() {
    if (record.numeroContratoOrigem && parseInt(record.numeroContratoOrigem) > 0) {
      return record.numeroContratoOrigem
    }
    if (record.sequencialCremacaoFutura && parseInt(record.sequencialCremacaoFutura) > 0) {
      return record.sequencialCremacaoFutura
    }
    return record.contratoOrigem
  }

  function getContratoOrigem() {
    if (record.contratoOrigem && record.contratoOrigem !== 0 && record.oid) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
              {utils.getMessage(messages, 'contrato.label.contratoorigem')}
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3" style={{ marginTop: '-5px' }}>
              <Link
                to={`${routes.PATH_CONTRATO}/${record.contratoOrigem}`}
                onClick={(e) => props.abrirContrato(record.contratoOrigem)}
              >
                {getLinkContratoOrigem()}
              </Link>
            </div>
          </div>
        </div>
      )
    }
  }

  function getLinkContratoDestino() {
    if (record.sequencialCremacao && parseInt(record.sequencialCremacao) > 0) {
      return record.sequencialCremacao
    }
    return record.contratoDestino
  }

  function getContratoDestino() {
    if (record.contratoDestino && record.contratoDestino !== 0 && props.isTipoContratoTitulo) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12">{utils.getMessage(messages, 'contrato.label.localizacaodestino')}</div>
          <div className="p-col-12">
            <Link
              to={`${routes.PATH_CONTRATO}/${record.contratoDestino}`}
              onClick={(e) => props.abrirContrato(record.contratoDestino)}
            >
              {getLinkContratoDestino()}
            </Link>
          </div>
        </div>
      )
    }
  }

  function getNumeroControle() {
    if (record.sequencialCremacao && parseInt(record.sequencialCremacao) > 0 && record.oid) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
              {utils.getMessage(messages, 'contrato.label.controle')}
            </div>
          </div>
          <div className="p-grid form-group">
            <div style={{ marginTop: '-5px' }} className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
              {record.sequencialCremacao}
            </div>
          </div>
        </div>
      )
    }
  }

  function getNumero() {
    return (
      <div>
        <div className="p-grid form-group">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText value={record.numero} name="numero" onChange={changeHandler} className={'width-10-rem'} />
              <label>{utils.getMessage(messages, 'contrato.label.numero')}</label>
            </span>
          </div>
        </div>
      </div>
    )
  }

  function getNumeroSocio() {
    if (record.sequencialCremacaoFutura && parseInt(record.sequencialCremacaoFutura) > 0 && record.oid) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3">
              {utils.getMessage(messages, 'contrato.label.numeroSocio')}
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-3" style={{ marginTop: '-5px' }}>
              {record.sequencialCremacaoFutura}
            </div>
          </div>
        </div>
      )
    }
  }

  function getConspaga() {
    if (!props.exibirConspaga) {
      return null
    }

    if (!parseInt(record.oid) || (record.oid && props.ACAO_EDITAR_CONSPAGA)) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12">
              <span className="p-float-label">
                <InputText
                  value={record.conspaga}
                  onChange={changeHandler}
                  name="conspaga"
                  className={'width-10-rem'}
                />
                <label>{utils.getMessage(messages, 'contrato.label.conspaga')}</label>
              </span>
            </div>
          </div>
        </div>
      )
    } else if (record.conspaga && parseInt(record.conspaga) > 0 && !props.ACAO_EDITAR_CONSPAGA) {
      return (
        <div>
          <div className="p-grid form-group">
            <div className="p-col-12">
              {props.ACAO_EDITAR_CONSPAGA ? (
                <span className="p-float-label">
                  <InputText
                    value={record.conspaga}
                    onChange={changeHandler}
                    name="conspaga"
                    className={'width-10-rem'}
                  />
                  <label>{utils.getMessage(messages, 'contrato.label.conspaga')}</label>
                </span>
              ) : (
                record.conspaga
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  function criarContrato() {
    if (props.tipoSepulturaDestino && record.ativo && record.oid && parseInt(record.oid) > 0) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-6 p-md-3 p-lg-2 p-xl-2">
            <Button
              onClick={props.onClickCriarContrato}
              label={props.tipoSepulturaDestino.descricao}
              className="p-button p-component"
            />
          </div>
        </div>
      )
    }
  }

  function getDadosDocumentos() {
    return (
      <React.Fragment>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <ContratoDocumentos
              titulo={'Documentos do contrato'}
              name={'documentoTempLookup'}
              documentoTempLookup={props.documentoTempLookup}
              history={props.history}
              onKeyPress={props.lookupDocumentoOnKeyPress}
              onComplete={props.lookupDocumentoOnComplete}
              onInputFilter={props.lookupDocumentoOnInputFilter}
              editDisabled={props.lookupDocumento.editDisabled}
              onEditField={props.lookupDocumentoOnEdit}
              onClick={props.lookupDocumentoOnClick}
              visible={props.lookupDocumento.visible}
              modal={props.lookupDocumento.modal}
              header={props.lookupDocumento.header}
              onHide={props.lookupDocumentoOnHide}
              onConfirm={props.lookupDocumentoOnConfirm}
              onCancel={props.lookupDocumentoOnCancel}
              records={props.lookupDocumento.records}
              columns={props.lookupDocumento.columns}
              totalRecords={props.lookupDocumento.totalRecords}
              sortOrder={props.lookupDocumento.order}
              sortField={props.lookupDocumento.sortField}
              first={props.lookupDocumento.first}
              rows={props.lookupDocumento.rows}
              onPage={props.lookupDocumentoOnPage}
              onFilter={props.lookupDocumentoOnFilter}
              onSort={props.lookupDocumentoOnSort}
              editHidden={true}
              documentos={props.documentos}
              onPageDatatable={props.onPage}
              colunasTDocumentos={props.colunasTDocumentos}
              updateDocumentos={props.updateDocumentos}
            ></ContratoDocumentos>
          </div>
        </div>
        {getDadosAnexo()}
        {/*<div className="p-grid p-fluid">*/}
        {/*  <div className="p-col-12">*/}
        {/*    <ContratoAnexo*/}
        {/*      anexos={props.anexos}*/}
        {/*      downloadAnexo={props.downloadAnexo}*/}
        {/*      removeAnexo={props.removeAnexo}*/}
        {/*      messages={props.messages}*/}
        {/*      onSelectAnexo={props.onSelectAnexo}*/}
        {/*    ></ContratoAnexo>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </React.Fragment>
    )
  }

  function getDadosAnexo() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <ContratoAnexo
            anexos={props.anexos}
            downloadAnexo={props.downloadAnexo}
            removeAnexo={props.removeAnexo}
            messages={props.messages}
            onSelectAnexo={props.onSelectAnexo}
            oidContrato={record.oid}
            adicionandoAnexo={props.adicionandoAnexo}
          ></ContratoAnexo>
        </div>
      </div>
    )
  }

  function getDadosPlano() {
    return (
      <ContratoPlano
        plano={record.plano}
        oidTaxaPrincipal={record.oidTaxaPrincipal}
        setField={props.setField}
        statePessoaFisica={props.statePessoaFisica} // falta passar
        history={props.history}
        idUnidade={_.get(props, 'record.unidade.id', null)}
        diasPadraoCarenciaCoberturaPlano={_.get(props, 'diasPadraoCarenciaCoberturaPlano', 0)}
        podeCriarCoberturaPlano={props.podeCriarCoberturaPlano}
        podeAlterarCoberturaPlano={props.podeAlterarCoberturaPlano}
        podeRemoverCoberturaPlano={props.podeRemoverCoberturaPlano}
        messages={props.messages}
      />
    )
  }

  function getDadosPrincipais() {
    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h4>
                {utils.getMessage(messages, 'contrato.label.contrato')} {record.numero || ''}
                <span
                  class="tag"
                  style={{
                    marginLeft: '15px',
                    backgroundColor: record.ativo ? '#4CAF50' : '#ff4d4d',
                  }}
                >
                  {record.ativo ? 'Ativo' : 'Inativo'}
                </span>
              </h4>
              {getNumero()}
              {getNumeroSocio()}
              {getConspaga()}
              {getContratoOrigem()}
              {criarContrato()}
              {getContratoDestino()}
              {getNumeroControle()}
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Dropdown
                      className={'width-20-rem'}
                      autoWidth={false}
                      disabled={props.disableUnit}
                      value={unidade}
                      options={props.unidades}
                      onChange={props.onChangeUnit}
                      optionLabel="value"
                      optionValue={(v) => v}
                      dataKey="id"
                      name="unidade"
                    />
                    <label>{utils.getMessage(messages, 'contrato.label.unidade')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <LtsCalendar
                    className={'width-15-rem'}
                    value={record.data}
                    name={'data'}
                    label={utils.getMessage(messages, 'contrato.label.data')}
                    onChange={(e) => props.setField('data', e)}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <LtsCalendar
                    className={'width-15-rem'}
                    value={record.dataTermino}
                    name={'data'}
                    label={utils.getMessage(messages, 'contrato.label.dataTermino')}
                    onChange={(e) => props.setField('dataTermino', e)}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <LtsCalendar
                    className={'width-15-rem'}
                    value={record.dataProximaRemessaCompetencia}
                    name={'data'}
                    label={utils.getMessage(messages, 'contrato.label.dataProximaRemessaCompetencia')}
                    onChange={(e) => props.setField('dataProximaRemessaCompetencia', e)}
                    dateFormat={mascaras.MASCARA_DATA_MM_YY}
                    yearRange={utils.getYearRangeFromDate('1900')}
                    view="month"
                  />
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Dropdown
                      className={'width-20-rem'}
                      autoWidth={false}
                      value={record.tipoSepultura}
                      options={props.tiposSepultura}
                      onChange={onChangeTipoContrato}
                      disabled={utils.nonNull(record.unidade) && !record.unidade.id}
                      optionLabel="descricao"
                      optionValue={(v) => v}
                      name="tipoSepultura"
                    />
                    <label>{utils.getMessage(messages, 'contrato.label.tipoContrato')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                  <span className="p-float-label">
                    <LtsLookupMarcador
                      label={utils.getMessage(messages, 'contrato.label.marcador')}
                      value={marcadores}
                      history={props.history}
                      editHidden={true}
                      field={'codigo'}
                      multiple={true}
                      onChange={(e) => props.setMarcadores(e)}
                      showAddButton={true}
                      stateMarcador={props.stateMarcador}
                      tipo={'CONTRATO'}
                    />
                  </span>
                </div>
              </div>
              {getLookupContratoCremacaoFutura(props, messages)}
              {getLookupLocalizacao(props, messages)}
              {/* {getLookupLocalizacaoDestino(props, messages)} */}
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                  <LtsLookupPessoa
                    header={utils.getMessage(messages, 'contrato.label.vendedor')}
                    label={utils.getMessage(messages, 'contrato.label.vendedor')}
                    name={'vendedor'}
                    field={'nome'}
                    value={{
                      id: _.get(record, 'vendedor.id', null),
                      value: _.get(record, 'vendedor.value', null),
                    }}
                    oidRecord={_.get(record, 'vendedor.id', '')}
                    history={props.history}
                    onChange={(e) => props.setField('vendedor', { id: e.oid, value: e.nome })}
                    showAddButton={false}
                  />
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-4">
                  <LtsLookupPessoa
                    header={utils.getMessage(messages, 'contrato.label.auxiliar')}
                    label={utils.getMessage(messages, 'contrato.label.auxiliar')}
                    name={'auxiliar'}
                    field={'nome'}
                    value={{
                      id: _.get(record, 'auxiliar.id', null),
                      value: _.get(record, 'auxiliar.value', null),
                    }}
                    oidRecord={_.get(record, 'auxiliar.id', '')}
                    history={props.history}
                    onChange={(e) => props.setField('auxiliar', { id: e.oid, value: e.nome })}
                    showAddButton={false}
                  />
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-6">
                  <LtsTextArea
                    value={record.observacao}
                    onChange={(e) => props.setField('observacao', e)}
                    name="observacao"
                    label={utils.getMessage(messages, 'contrato.label.observacao')}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                  <label htmlFor="chkisento">{utils.getMessage(messages, 'contrato.label.isento')}</label>
                  <Checkbox
                    checked={record.isento}
                    onChange={changeHandler}
                    name="isento"
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                  <label htmlFor="chkativo">{utils.getMessage(messages, 'contrato.label.ativo')}</label>
                  <Checkbox
                    checked={record.ativo}
                    onChange={changeHandler}
                    name="ativo"
                    style={{ marginLeft: '10px' }}
                    disabled={!props.state.ACAO_INATIVAR_CONTRATO}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!props.utilizaAssinaturaDigitalDocumentos ? getDadosAnexo() : null}

        {getContratoResponsavelCard(props, messages)}
        {getContratoFalecidoCard(props, messages)}
        {getContratoDependenteCard(props, messages)}
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-4 p-md-4 p-lg-3 p-xl-3">
                  <h5>{utils.getMessage(messages, 'contrato.label.lancamentosfinanceiros')}</h5>
                </div>
                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    onClick={props.showPageLancamentoFinanceiro}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                    label={utils.getMessage(messages, 'contrato.label.adicionar')}
                  />
                </div>
                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    onClick={(e) => props.agruparLancamentos(props.selectedLancamentos)}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                    label={utils.getMessage(messages, 'contrato.label.agrupar')}
                    disabled={verificarHabilitadoAgruparLancamento(props.selectedLancamentos)}
                  />
                </div>
                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2">
                  <Button
                    onClick={(e) => props.gerarDebitoAutomatico(props.selectedLancamentos)}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                    label={utils.getMessage(messages, 'contrato.label.gerarDebitoAutomatico')}
                    disabled={verificarHabilitadoBotaoLancamentos(props.selectedLancamentos)}
                  />
                </div>

                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    onClick={(e) => props.cancelarLancamentos(props.selectedLancamentos)}
                    className="p-button-danger"
                    style={{ marginTop: '-5px' }}
                    label={utils.getMessage(messages, 'contrato.label.cancelar')}
                    disabled={verificarHabilitadoBotaoLancamentos(props.selectedLancamentos)}
                  />
                </div>

                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    type="button"
                    className="p-button-warning"
                    style={{ marginTop: '-5px' }}
                    label={utils.getMessage(messages, 'contrato.label.baixar')}
                    disabled={verificarHabilitadoBotaoLancamentos(props.selectedLancamentos)}
                    onClick={(e) => onClickBaixarLancamentos(props.selectedLancamentos)}
                  />
                </div>

                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    onClick={(e) => props.verificarGeracaoBoletosIndividuais(props.selectedLancamentos)}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                    label={'Boleto'}
                    disabled={disableBotaoBoleto(props.selectedLancamentos)}
                  />
                </div>
                <div className="p-col-12 p-sm-4 p-md-4 p-lg-2 p-xl-1">
                  <Button
                    onClick={(e) => props.gerarCarne(props.selectedLancamentos)}
                    className="p-button-success"
                    style={{ marginTop: '-5px' }}
                    label={'Carnê'}
                    disabled={disableBotaoCarne(props.selectedLancamentos)}
                  />
                </div>
              </div>

              <div className="card card-w-title">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-2">
                    <strong>(=)Aberto: </strong>
                    {props.record.totalEmAberto === 0
                      ? 'R$ 0,00'
                      : 'R$ ' + utils.formatCurrency(props.record.totalEmAberto)}
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-2">
                    <strong>(=)Vencido: </strong>
                    {props.record.totalVencido === 0
                      ? 'R$ 0,00'
                      : 'R$ ' + utils.formatCurrency(props.record.totalVencido)}
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-2">
                    <strong>(=)Pago: </strong>
                    {props.record.totalPago === 0 ? 'R$ 0,00' : 'R$ ' + utils.formatCurrency(props.record.totalPago)}
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-3">
                    <strong>(=)Juros/Multa: </strong>
                    {props.record.totalJurosMulta === 0
                      ? 'R$ 0,00'
                      : 'R$ ' + utils.formatCurrency(props.record.totalJurosMulta)}
                  </div>
                  <div className="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-3">
                    <strong>(=)Final: </strong>
                    {props.record.totalFinal === 0 ? 'R$ 0,00' : 'R$ ' + utils.formatCurrency(props.record.totalFinal)}
                  </div>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <DataTable
                    ref={props.dataTableLancamentos}
                    value={props.record.lancamentosFinanceiros}
                    paginator={true}
                    selection={props.selectedLancamentos}
                    onSelectionChange={props.onSelectionChange}
                    emptyMessage="  "
                    rows={enums.ROWS}
                    rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                    // V7 properties //
                    responsiveLayout="scroll"
                    filterDisplay="row"
                    size={'small'}
                    stripedRows
                  >
                    {props.colunasTLancamentoFinanceiro}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="p-col-1" style={{ display: 'inline-flex' }}>
                <div>
                  <Button
                    onClick={(e) => props.showForm('historico', true)}
                    icon={icons.PLUS_ICON}
                    className="p-button-success"
                  />
                </div>
                <h5 style={{ marginLeft: '5px', marginTop: '5px' }}>
                  {utils.getMessage(messages, 'contrato.label.historico')}
                </h5>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <DataTable
                    value={props.historicos}
                    paginator={true}
                    emptyMessage="  "
                    rows={enums.ROWS}
                    rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
                    // V7 properties //
                    responsiveLayout="scroll"
                    filterDisplay="row"
                    size={'small'}
                    scrollable
                    scrollHeight="400px"
                  >
                    {props.colunasTHistorico}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const marcadores = []
  if (record.marcadores) {
    record.marcadores.forEach((marcador) => {
      marcadores.push({ oid: marcador.id, codigo: marcador.value })
    })
  }

  return (
    <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
      <TabPanel header="Dados Gerais">{getDadosPrincipais()}</TabPanel>
      {props.isTipoContratoPlano ? <TabPanel header="Plano">{getDadosPlano()}</TabPanel> : null}

      {props.utilizaAssinaturaDigitalDocumentos ? (
        <TabPanel header="Documentos">{getDadosDocumentos()}</TabPanel>
      ) : null}

      <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
    </TabView>
  )

  function verificarHabilitadoBotaoLancamentos(lancamentosSelecionados) {
    return !(utils.nonNull(lancamentosSelecionados) && utils.isArrayNotEmpty(lancamentosSelecionados))
  }

  function onClickBaixarLancamentos(lancamentosSelecionados) {
    props.onClickBtnBaixarLancamentos(lancamentosSelecionados)
  }

  function verificarHabilitadoAgruparLancamento(lancamentosSelecionados) {
    return !(
      utils.nonNull(lancamentosSelecionados) &&
      utils.isArrayNotEmpty(lancamentosSelecionados) &&
      lancamentosSelecionados.length > 1
    )
  }

  function disableBotaoBoleto(lancamentos) {
    if (utils.isArrayNullOrEmpty(lancamentos)) {
      return true
    }
    const gerandoBoleto = _.get(props, 'gerandoBoleto', false)
    if (gerandoBoleto) {
      return true
    }
    const result = lancamentos.filter((f) => _.get(f, 'tipoFormaPagamento.id', 0) === enums.idFormaPagamentoBoleto)
    return utils.isArrayNullOrEmpty(result)
  }

  function disableBotaoCarne(lancamentos) {
    if (utils.isArrayNullOrEmpty(lancamentos)) {
      return true
    }
    const result = lancamentos.filter((f) => _.get(f, 'tipoFormaPagamento.id', 0) === 17)
    return utils.isArrayNullOrEmpty(result)
  }

  // REFERENTE AO ANEXO DO CONTRATO GERAL - movido para contrato-anexo.js
  // function getContent() {
  //     const anexos = props.anexos;
  //     if (utils.isArrayNullOrEmpty(anexos)) {
  //         return null;
  //     }
  //     return anexos.map(anexo => (
  //         <div className="p-grid">
  //             <div className="p-col-12 p-sm-11 p-md-11 p-lg-11 p-xl-11">
  //                 <Button
  //                     type="p-button"
  //                     label={anexo.filename}
  //                     onClick={e => props.downloadAnexo(anexo)}
  //                     icon={icons.DOWNLOAD}
  //                     className="p-button-secondary"
  //                     disabled={isNaN(anexo.oid)}
  //                 />
  //             </div>
  //             <div className="p-col-12 p-sm-1 p-md-1 p-lg-1 p-xl-1">
  //                 <Button
  //                     type="p-button-icon"
  //                     icon={icons.REMOVE_ICON}
  //                     onClick={e => props.removeAnexo(anexo)}
  //                     className="p-button-danger"
  //                 />
  //             </div>
  //         </div>
  //     ));
  // }

  //MOVIDO PARA contrato-anexo.js
  // function getAnexoWrapper() {
  //     return (
  //         <Card title={utils.getMessage(messages, "contrato.label.anexos")}>
  //             <div>
  //                 <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo">
  //                     {utils.getMessage(messages, "contrato.label.adicionar")}
  //                 </label>
  //                 <input id="selecao-arquivo" type="file" onChange={props.onSelectAnexo}/>
  //             </div>
  //             <br/>
  //             {getContent()}
  //         </Card>
  //     );
  // }
}
