import React from 'react';
import ItensLancamentoFormPageContainer from './ItensLancamentoFormPageContainer';

export class ItensLancamentoFormPage extends React.Component{

    render(){
        return (
           <ItensLancamentoFormPageContainer {...this.props} />
        )
    }
}
