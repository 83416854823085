import React from 'react'
import { getMessage } from '../../../utils/index'
import { Card } from 'primereact/card'

import './ProdutoForm.scss'
import LtsDropdownUnidade from '../../../components/lottus/lts-dropdown-unidade'
import LtsInputText from '../../../components/lottus/lts-input-text'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'
import _ from 'lodash'
import LtsCurrency from '../../../components/lottus/lts-currency'
import LtsLookupResponsavel from '../../../components/lottus/lts-lookup-responsavel'
import { DataTable } from 'primereact/datatable'
import LtsLookupMarcador from '../../../components/lottus/lts-lookup-marcador'
import utils from '../../../utils'
import ProdutoAnexo from './produto-anexo'
import LtsTextArea from '../../../components/lottus/lts-text-area'
import LtsGalleria from '../../../components/lottus/lts-galleria'

export const ProdutoForm = (props) => {
  const messages = props.messages
  const record = props.record
  const setField = props.setField

  const marcadores = []
  if (record.marcadores) {
    record.marcadores.forEach((marcador) => {
      marcadores.push({ oid: marcador.id, codigo: marcador.value })
    })
  }

  function getDadosFornecedores() {
    return (
      <Card title={getMessage(messages, 'produto.label.fornecedores')} style={{ marginTop: '10px' }}>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-8 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupResponsavel
              name={'fornecedor'}
              field={'nome'}
              label={getMessage(messages, 'produto.label.fornecedor')}
              value={_.get(record, 'fornecedor.nome', '')}
              oidRecord={_.get(record, 'fornecedor.id', '')}
              history={props.history}
              onChange={(e) => props.onChangeFornecedor(e)}
              onInputFilter={(e) => props.onInputFilterFornecedor(e)}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <DataTable
              value={record.fornecedores}
              emptyMessage={' '}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              size={'small'}
              stripedRows={true}
              responsiveLayout="scroll"
            >
              {props.colunasFornecedor}
            </DataTable>
          </div>
        </div>
      </Card>
    )
  }

  function getViewImage() {
    return (
      <div className="card">
        <LtsGalleria anexos={props.anexos} titulo={getMessage(messages, 'produto.label.imagem.preview')} />
      </div>
    )
  }

  function getDadosImagens() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <ProdutoAnexo
            anexos={props.anexos}
            downloadAnexo={props.downloadAnexo}
            removeAnexo={props.removeAnexo}
            messages={props.messages}
            onSelectAnexo={props.onSelectAnexo}
            oidContrato={record.oid}
            adicionandoAnexo={props.adicionandoAnexo}
          ></ProdutoAnexo>
        </div>
      </div>
    )
  }

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card title={`${getMessage(messages, 'produto.label.produto')} ${record.oid || ''}`}>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
              <LtsDropdownUnidade
                name={'unidade'}
                label={getMessage(messages, 'produto.label.unidade')}
                value={record.unidade}
                onChange={(e) => setField('unidade', e)}
                showOptionAll={true}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsInputText
                value={record.codigo}
                onChange={(e) => setField('codigo', e)}
                name="codigo"
                autoFocus={true}
                maxLength={50}
                label={getMessage(messages, 'produto.label.codigo')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsInputText
                value={record.descricao}
                onChange={(e) => setField('descricao', e)}
                name="descricao"
                maxLength={255}
                label={getMessage(messages, 'produto.label.nome')}
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsTextArea
                value={record.detalhamento}
                onChange={(e) => setField('detalhamento', e)}
                name="detalhamento"
                label={getMessage(messages, 'produto.label.descricao')}
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsDropdownIdValue
                name={'tipoProduto'}
                value={record.tipoProduto}
                onChange={(e) => setField('tipoProduto', e)}
                loadDataFn={apiService.getTiposProduto}
                label={getMessage(messages, 'produto.label.tipoProduto')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <span className="p-float-label">
                <LtsLookupMarcador
                  label={utils.getMessage(messages, 'produto.label.marcador')}
                  value={marcadores}
                  history={props.history}
                  editHidden={true}
                  field={'codigo'}
                  multiple={true}
                  onChange={(e) => props.setMarcadores(e)}
                  showAddButton={true}
                  stateMarcador={props.stateMarcador}
                  tipo={'PRODUTO'}
                />
              </span>
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsInputText
                value={record.quantidadeMinima}
                onChange={(e) => setField('quantidadeMinima', e)}
                name="quantidadeMinima"
                keyfilter="int"
                label={getMessage(messages, 'produto.label.quantidadeMinima')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsCurrency
                value={record.valor}
                name={'valor'}
                onChange={(e) => setField('valor', e)}
                label={getMessage(messages, 'produto.label.valor')}
              />
            </div>
          </div>

          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-2">
              <LtsInputText
                value={record.saldo}
                name="saldo"
                keyfilter="int"
                label={getMessage(messages, 'produto.label.saldo')}
                disabled={true}
              />
            </div>
          </div>
        </Card>
        {getDadosFornecedores()}
        <br />
        {getDadosImagens()}
        {getViewImage()}
      </div>
    </div>
  )
}
