import React from 'react'
import { connect } from 'react-redux'
import { acoes, routes } from '../../../constants'
import { Page } from '../../../components/page/Page'
import { DashboardList } from './DashboardList'
import { actions } from '../dashboard.actions'
import storage from '../../../services/stoage.service'

const mapStateToProps = (state) => {
  let tags = state.crudDashboardState.tags
  let tasksSelecionadas = []
  let tasksNaoSelecionadas = []
  let tasks = state.crudDashboardState.currentRecord.records ? state.crudDashboardState.currentRecord.records : []
  tasks.map((task, index) => {
    if (task.ativo) {
      tasksSelecionadas.push(task)
    } else {
      tasksNaoSelecionadas.push(task)
    }
  })
  let first = 0
  if (state.crudDashboardState.index) {
    first = state.crudDashboardState.index
  }
  let rows = 5
  return {
    first,
    rows,
    tags,
    atividadesRecentes: state.crudDashboardState.currentRecord.atividadesRecentes,
    favoritos: state.crudDashboardState.currentRecord.favoritos,
    maisAcessados: state.crudDashboardState.currentRecord.maisAcessados,
    ultimosAcessados: state.crudDashboardState.currentRecord.ultimosAcessados,
    record: state.crudDashboardState.currentRecord,
    records: state.crudDashboardState.currentRecord.empresas,
    totalRecords: state.crudDashboardState.count,
    tasks: state.crudDashboardState.tasks,
    tasksSelecionadas: tasksSelecionadas,
    tasksNaoSelecionadas: tasksNaoSelecionadas,
    tagsAtividades: state.crudDashboardState.tagsAtividades,
    messages: state.crudDashboardState.messages,
    stateMarcador: state.crudMarcadorState,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadById: (oid) => dispatch(actions.loadById(oid)),
    searchTag: (event) => {
      dispatch(actions.findTags(event.query))
    },
    paginate: (paginationParams) => dispatch(actions.loadAtividades(paginationParams)),
    load: () => dispatch(actions.load()),
    getCount: () => dispatch(actions.getCount()),
    loadMaisAcessados: () => dispatch(actions.loadMaisAcessados()),
    loadUltimosAcessados: () => dispatch(actions.loadUltimosAcessados()),
    loadAllFavoritos: () => dispatch(actions.loadAllFavoritos()),
    loadAtividades: (index, rows) => dispatch(actions.loadAtividades(index, rows)),
    saveNewAtividade: (atividade) => dispatch(actions.saveNewAtividade(atividade)),
    changeAtividade: (atividade) => dispatch(actions.changeAtividade(atividade)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    onTaskChange: (activityChanged, activities) => {
      dispatch(actions.changeAtividade(activityChanged))
      dispatch(actions.setField('records', activities))
    },
    removeTask: (oid) => {
      dispatch(actions.removeTask(oid))
    },
    loadAtividadesRecentes: (event, atividadesRecentes) =>
      dispatch(actions.loadAtividadesRecentes(event, atividadesRecentes)),
  }
}

class DashboardListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      atualizar: false,
      ACAO_EXIBIR_TIMELINE: storage.getAcao(acoes.ACAO_EXIBIR_TIMELINE),
    }
  }

  componentDidMount() {
    let params = { index: this.props.first, max: this.props.rows }
    this.props.loadAtividades(params)
    this.props.getCount()
    this.props.loadMaisAcessados()
    this.props.loadUltimosAcessados()
    this.props.loadAllFavoritos()
    actions.afterSearch = () => {
      this.props.history.replace(`${routes.PATH_DASHBOARD}`)
    }
    actions.afterFindTags = (tagss) => {
      this.props.tags.splice(0, this.props.tags.length)
      this.props.tags.push(tagss)
    }
  }

  addTask = () => {
    if (this.props.record.newTask) {
      this.props.setField('newTask', false)
      if (this.props.record.descricaoAtividade) {
        let atividadesAtuais = this.props.record.records
        //colocando os dados da nova task
        let newTask = {
          descricao: this.props.record.descricaoAtividade,
          data: window.moment(),
          tag: {
            id: this.props.record.oidTag,
            value: this.props.record.tagAtividade,
          },
          ativo: false,
        }
        this.props.saveNewAtividade(newTask)
        atividadesAtuais.push(newTask)
        this.props.setField('descricaoAtividade', '')
        this.props.setField('tagAtividade', '')
      }
    } else {
      this.props.setField('newTask', true)
    }
  }

  onLoadAtividadesRecentes = (event) => {
    this.props.loadAtividadesRecentes(event, this.props.atividadesRecentes)
  }

  onChange = (event) => {
    const target = event.target
    const field = target.name
    let valor = target.value
    if (valor) {
      if (valor && !valor.id) {
        this.props.setField(field, valor)
      } else if (!event.value.value) {
        this.props.setField('tagAtividade', event.value)
      } else if (event.value.value) {
        this.props.setField('tagAtividade', event.value.value)
        this.props.setField('oidTag', event.value.id)
      }
    } else if (field === 'descricaoAtividade') {
      this.props.setField(field, '')
    } else {
      this.props.setField('tagAtividade', null)
      this.props.setField('oidTag', null)
    }
  }
  onChangeTag = (event) => {
    let object = event
    if (Array.isArray(event) && event.length > 0) {
      object = event[0]
    }
    if (object) {
      this.props.setField('tagAtividade', object.codigo)
      this.props.setField('oidTag', object.oid)
    }
  }
  loadData = (e) => {
    this.props.loadAtividades(e.first, e.rows)
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    })
  }

  onTaskChange = (e) => {
    const activities = this.props.record.records
    const activityChanged = {
      ...e,
      ativo: !e.ativo,
    }
    const actToRemove = activities.find((f) => f.oid === e.oid)
    if (actToRemove) {
      const index = activities.indexOf(actToRemove)
      activities.splice(index, 1)
      activities.push(activityChanged)
    }
    this.props.onTaskChange(activityChanged, activities)
  }

  render() {
    let content = null
    if (!storage.getPortalCliente()) {
      content = (
        <DashboardList
          {...this.props}
          messages={this.props.messages}
          removeTask={this.props.removeTask}
          onPage={this.onPage}
          loadData={this.loadData}
          tags={this.props.tags}
          onTaskChange={this.onTaskChange}
          addTask={this.addTask}
          onChange={this.onChange}
          onChangeTag={this.onChangeTag}
          onLoadAtividadesRecentes={this.onLoadAtividadesRecentes}
          atividadesRecentes={this.props.atividadesRecentes}
          searchTag={this.props.searchTag}
          ACAO_EXIBIR_TIMELINE={this.state.ACAO_EXIBIR_TIMELINE}
        />
      )
    }
    return <Page header={null} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardListPageContainer)
