import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { routes, enums } from '../../../constants'
import storage from '../../../services/stoage.service'
import utils from '../../../utils'
import { actions } from '../contasreceberrelatorio.actions'
import { ContasReceberRelatorioForm } from './ContasReceberRelatorioForm'
import { Button } from 'primereact/button'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudContasReceberRelatorioState.messages
  const record = state.crudContasReceberRelatorioState.currentRecord

  // REFERENTE AO DROPDOWN DE UNIDADES
  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidades: [unidade] })
    disableUnit = true
  }

  let unidades = []
  let unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)

  const configFormasPagamento = utils.getConfiguracaoFormasPagamento(storage.getCompany(), null)
  const formasPagamento = utils.getFormasPagamento(enums.FormasPagamento, configFormasPagamento)

  return {
    record,
    messages,
    unidades,
    disableUnit,
    formasPagamento,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))

      dispatch(actions.setField(field, valor))
    },
    loadOpcoesGruposFinanceiros: () => dispatch(actions.loadOpcoesGruposFinanceiros()),
    onClickBotaoDinamico: (listId, oidBotao) => dispatch(actions.executarFuncaoDinamica(listId, oidBotao)),
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    getBotoesDinamicos: (idTela) => {
      let tipoTela = Number(enums.TipoTela.FORMULARIO)
      dispatch(actions.getBotoesDinamicos(idTela, tipoTela))
    },
    carregarTiposSepulturas: (unidade) => dispatch(actions.carregarTiposSepulturas(unidade)),
    abrirRelatorio: (
      dataInicial,
      dataFinal,
      dataVencimentoInicial,
      dataVencimentoFinal,
      unidade,
      formasPagamento,
      gruposFinanceiros,
      tiposSepulturas
    ) =>
      dispatch(
        actions.abrirRelatorio(
          dataInicial,
          dataFinal,
          dataVencimentoInicial,
          dataVencimentoFinal,
          unidade,
          formasPagamento,
          gruposFinanceiros,
          tiposSepulturas
        )
      ),
  }
}

class ContasReceberRelatorioFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.showPesquisarButton = false
    this.showCSVButton = false
    this.showColumnButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_CONTAS_RECEBER}`)
      this.props.carregarTiposSepulturas()
      this.props.loadFavorito(this.props.history.location.pathname)
      this.props.loadOpcoesGruposFinanceiros()
    }

    actions.afterLoadFavorito = (data) => {
      if (data && data.oid) {
        this.setState({ favorito: 1 })
        this.setState({ dadosFavorito: data })
      } else {
        this.setState({ favorito: 0 })
        this.setState({ dadosFavorito: null })
      }
    }
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.newHandler()
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  getCustomButtons = () => {
    return (
      <div className="p-toolbar-group-left">
        <Button label="Visualizar" icon="pi pi-check" className="p-button-success" onClick={this.abrirRelatorio} />
      </div>
    )
  }

  abrirRelatorio = () => {
    let formaPagamento
    if (this.props.record.tipoFormaPagamento && this.props.record.tipoFormaPagamento.length > 0) {
      formaPagamento = this.props.record.tipoFormaPagamento.map((tipo) => {
        return tipo.id
      })
    } else {
      formaPagamento = this.props.formasPagamento.map((tipo) => {
        return tipo.id
      })
    }

    let gruposFinanceiros = []
    if (this.props.record.gruposFinanceiros && this.props.record.gruposFinanceiros.length > 0) {
      gruposFinanceiros = this.props.record.gruposFinanceiros.map((tipo) => {
        return tipo.id
      })
    } else if (this.props.record.gruposFinanceirosOpcoes && this.props.record.gruposFinanceirosOpcoes.length > 0) {
      gruposFinanceiros = this.props.record.gruposFinanceirosOpcoes.map((grupo) => {
        return grupo.id
      })
    }
    let tiposSepulturasSelecionadas = this.props.record.tiposSepulturas
    if (utils.isArrayNullOrEmpty(tiposSepulturasSelecionadas)) {
      tiposSepulturasSelecionadas = this.props.record.opcoesTiposSepulturas
    } else {
      tiposSepulturasSelecionadas = utils.convertArrayIdLabelToIdValue(tiposSepulturasSelecionadas)
    }
    this.props.abrirRelatorio(
      this.props.record.dataInicial,
      this.props.record.dataFinal,
      this.props.record.dataVencimentoInicial,
      this.props.record.dataVencimentoFinal,
      utils.convertArrayIdLabelToIdValue(this.props.record.unidade),
      formaPagamento,
      gruposFinanceiros,
      tiposSepulturasSelecionadas
    )
  }
  carregarTiposSepulturas = () => {
    this.props.carregarTiposSepulturas(this.props.record.unidade)
  }
  onChangeUnit = (e) => {
    const target = e.target
    const field = target.name
    const valor = target.value
    this.props.setField(field, valor)
    this.props.loadOpcoesGruposFinanceiros()
    this.props.carregarTiposSepulturas(utils.convertArrayIdLabelToIdValue(valor))
  }

  buildHeader = () => {
    return (
      <div>
        <DefaultToolBar listPageContainer={this} title={'Relatório de Contas a Receber'} />
      </div>
    )
  }

  render() {
    const content = (
      <ContasReceberRelatorioForm
        abrirRelatorio={this.abrirRelatorio}
        currentUser={this.props.currentUser}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        onChangeUnit={this.onChangeUnit}
        formasPagamento={this.props.formasPagamento}
      />
    )

    return <Page content={content} header={this.buildHeader()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContasReceberRelatorioFormPageContainer)
