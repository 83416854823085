import React from 'react'
import './TelaDinamicaForm.scss'
import _ from 'lodash'
import { formBuilder } from '../form-builder'

export const TelaDinamicaForm = (props) => {
  const messages = props.messages
  const record = props.record
  const setField = props.setField
  const history = props.history
  const documento = props.documento

  const buildForm = (record) => {
    const parametros = _.get(documento, 'parametros', null)
    if (_.isEmpty(parametros)) {
      return null
    }
    return formBuilder({ parametros, record, messages, setField, history })
  }

  return <div>{buildForm(record)}</div>
}
