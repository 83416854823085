import React from 'react'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { Page } from '../../../components/page/Page'
import { ConsultaFalecidoList } from './ConsultaFalecidoList'
import { actions } from '../consultafalecido.actions'
import utils from '../../../utils/index'
import { onColumnReorder, onColumnToggle, onPage, onSort } from '../../../utils/listPageContainerUtils'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'

const mapStateToProps = (state) => {
  return {
    record: state.crudConsultaFalecidoState.currentRecord,
    records: state.crudConsultaFalecidoState.records,
    showHideColumns: state.crudConsultaFalecidoState.showHideColumns,
    columns: state.crudConsultaFalecidoState.columns,
    first: state.crudConsultaFalecidoState.index,
    rows: state.crudConsultaFalecidoState.max,
    totalRecords: state.crudConsultaFalecidoState.count,
    sortField: state.crudConsultaFalecidoState.sortField,
    order: state.crudConsultaFalecidoState.sortOrder,
    selectedFilters: state.crudConsultaFalecidoState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.loadAll())
    },
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.setField(field, value))
    },
    changeFilter: (column, e) => {
      // const target = e.target;
      // let campo = column.fieldJoin ? column.fieldJoin : target.name;
      // dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento));
      // setTimeout(() => {
      //   dispatch(actions.executeFilter(`/lottustech/public/falecidos/all`));
      // }, 400);
    },
    setSelectedFilters: (filtro) => dispatch(actions.setFiltroAvancado(filtro)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    load: () => dispatch(actions.loadAll()),
  }
}

class ConsultaFalecidoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.showColumnButton = false
    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.state = {}
  }

  componentDidMount() {
    this.props.load()
  }

  changeHandler = (event) => {
    this.props.changeHandler(event)
  }

  onKeyDown = (e) => {
    this.props.onEnter()
  }
  onKeyDownSubCategoria = (e) => {
    this.props.onEnter()
  }
  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      if (col.field === 'dataNascimento' || col.field === 'dataFalecimento') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            style={{ width: '16%' }}
            filter={false}
          />
        )
      } else if (col.field === 'localizacao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            style={{ width: '13%' }}
            filterMatchMode="contains"
            filter={true}
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterMatchMode="contains"
            filter={true}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterMatchMode="contains" />
    })
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Falecidos" />
  }

  buildContent() {
    return (
      <ConsultaFalecidoList
        {...this.props}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaFalecidoListPageContainer)
