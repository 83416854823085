import React from 'react';

import AquisicoesRelatorioFormPageContainer from './AquisicoesRelatorioFormPageContainer';

export class AquisicoesRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <AquisicoesRelatorioFormPageContainer {...this.props} />
        )
    }
}
