import history from '../../history'
import api from '../../services/api.service'
import { actions as appActions } from '../../store/app'
import storage from '../../services/stoage.service'
import utils from '../../utils'
import { getDocumentoByOid } from '../../services/api-documento'
import { enums } from '../../constants'
import _ from 'lodash'
import { messageActions } from '../message'
import { actions } from '../../pages/contrato/contrato.actions'
//import { logar } from '../../services/api-log-acesso'

export const types = {
  MENU_SET_ITEMS: 'menu.setItems',
  MENU_CLEAR_ITEMS: 'menu.setItems',
  MENU_FETCH_ITEMS: 'menu.fetchItems',
  MENU_FETCH_ITEMS_SUCCESS: 'menu.fetchItemsSuccess',
  MENU_FETCH_ITEMS_ERROR: 'menu.fetchItemsError',
  MENU_SET_DOCUMENTO: 'menu.setDocumento',
}

export function menuSetItems(items) {
  return {
    type: types.MENU_SET_ITEMS,
    items,
  }
}

export function menuClearItems() {
  return {
    type: types.MENU_CLEAR_ITEMS,
  }
}

export function setCustomRoutes(menus) {
  return {
    type: types.MENU_SET_CUSTOM_ROUTES,
    menus,
  }
}

/* Notifica a início de um processo assincrono */
export function menuFetchItems() {
  return {
    type: types.MENU_FETCH_ITEMS,
    async: true,
  }
}
/* Notifica a fim de um processo assincrono  com sucesso*/
function fetchSuccess() {
  return {
    type: types.MENU_FETCH_ITEMS_SUCCESS,
    async: false,
  }
}

export function menuFetchItemsSucces(items) {
  return (dispatch) => {
    dispatch(menuSetItems(items))
    dispatch(fetchSuccess())
  }
}

/* Notifica a fim de um processo assincrono com erro */
export function menuFetchItemsError(errorMessage) {
  return {
    type: types.MENU_FETCH_ITEMS_ERROR,
    async: false,
  }
}

export function customRoutes(menus) {
  return (dispatch) => {
    dispatch(appActions.setCustomRoutes(menus))
  }
}

/* Notifica a início de um processo de carregamento dos dados do menu */
export function menuFetch() {
  return (dispatch) => {
    dispatch(appActions.limparDados())
    dispatch(menuFetchItems())
    const data = {
      provider: 'br.com.lottustech.core.controller.MenuController',
      action: 'getMenusAssemblerForUsuario',
    }
    api
      .post('/lottustech/get', data)
      .then((response) => {
        let menuItems = response.data || []
        dispatch(customRoutes(menuItems))
        menuItems = menuItems.map(mapMenu)
        storage.setMenus(menuItems)
        dispatch(menuFetchItemsSucces(menuItems))
        resolveTelaInicialPortalCliente(menuItems)
      })
      .catch((e) => {
        dispatch(menuFetchItemsError(e))
      })
  }
}

function resolveTelaInicialPortalCliente(menus) {
  if (utils.isArrayNotEmpty(menus) && storage.getPortalCliente()) {
    let menu = menus.find((f) => f.label === 'Consulta de Clientes')
    if (!menu) {
      menu = menus.find((f) => f.label === 'Financeiro')
    }
    if (menu) {
      storage.setOidCurrentMenu(menu.oidMenu)
      setTimeout(() => {
        menu.command({})
      }, 1000)
    }
  }
}

function mapMenu(menu) {
  const label = menu.nome
  const icon = menu.linkIcone ? menu.linkIcone : ''
  const oidMenu = menu.oid
  const relatorioDinamico = menu.relatorioDinamico
  const permissoes = menu.permissoes
  const tags = menu.tags
  const labelBuscaRapida = menu.labelBuscaRapida
  let items = []
  const caminho = menu.caminho
  const command = (event) => {
    if (caminho && caminho.trim().length > 0) {
      //logar({ path: caminho, nomeEntity: null, oidEntity: null }).then()
      if (event.originalEvent && event.originalEvent.ctrlKey) {
        window.open('/phoenix' + caminho)
      } else {
        history.push(caminho)
      }
    }
  }
  if (menu.children && menu.children.length > 0) {
    items = menu.children.map(mapMenu)
    return {
      label,
      icon,
      oidMenu,
      items,
      relatorioDinamico,
      permissoes,
      tags,
      labelBuscaRapida,
    }
  }
  return {
    label,
    icon,
    oidMenu,
    command,
    caminho,
    relatorioDinamico,
    permissoes,
    tags,
    labelBuscaRapida,
  }
}
