import React from "react";
//import { Switch, Route, HashRouter as Router } from 'react-router-dom';
import {Route, Router, Switch} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import AdminLayout from "./admin.layout.jsx";
import LoginPage from "../pages/login/login.page";
import RedefinicaoSenhaPage from "../pages/portalClienteRedefinicaoSenha/redefinicaosenha.page";
import history from "../history";
import {ConvenioListPage} from "../pages/convenio";
import {DashboardListPage} from "../pages/dashboard";
import {routes} from "../constants";
import { ConsultaObituarioListPage } from "../pages/consultaObituario";

class MainLayout extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" component={LoginPage}></Route>
          <Route path="/redefinirsenha/:hash" component={RedefinicaoSenhaPage}></Route>
          {/* <PrivateRoute path="/consulta/portalcliente/" component={DashboardListPortalClientePage}></PrivateRoute> */}
          <Route exact path={`${routes.PATH_CONVENIO}`} component={ConvenioListPage} />
          <Route exact path={`${routes.PATH_CONSULTA_OBITUARIO}`} component={ConsultaObituarioListPage} />
          <PrivateRoute path="/" component={AdminLayout} />
          <Route path="*" component={DashboardListPage} />
        </Switch>
      </Router>
    );
  }
}

export default MainLayout;
