import React, { useEffect, useState } from 'react'
import { Card } from 'primereact/card'
import _ from 'lodash'
import LtsInputText from './lts-input-text'
import LtsCalendar from './lts-calendar'
import LtsDropdownIdValue from './lts-dropdown-id-value'
import apiService from '../../services/api.service'
import apiIdValueService from '../../services/api-id-value-service'
import LtsInputNumber from './lts-input-number'
import { Button } from 'primereact/button'
import utils from '../../utils'
import { icons } from '../../constants'
import { messageActions } from '../../store/message'
import { actions } from '../../pages/lancamentoFinanceiro/lancamento.actions'

/**
 *
 * O componente deve receber as seguintes props, usados para 'value'
 * tipoCartaoCredito - idValue
 * cartaoNumero - string
 * cartaoNome - string
 * cartaoVencimento - date
 *
 * bem como a funcao 'setField', que para cada campo sera disparado
 * no onChange a chamada a essa funcao
 *
 * Outra opção
 * showParcelas - boolean. Caso seja true, o componente devera receber a props abaixo:
 * parcelasCartaoCredito - number -> opcional. Obrigatório quando 'showParcelas' = true
 *
 */
export default function LtsCartaoCredito(props) {
  const [showDadosCartao, setShowDadosCartao] = useState(true)
  const [cartoes, setCartoes] = useState([])

  useEffect(() => {
    init()
  }, [props.recordId, props.oidOwnerCartaoCredito, props.formaPagamento])

  const init = () => {
    const oidOwner = _.get(props, 'oidOwnerCartaoCredito', 0)
    if (oidOwner > 0) {
      apiService
        .get(`/lottustech/pessoas/${oidOwner}/cartoes`)
        .then((response) => {
          const cartoes_ = _.get(response, 'data', [])
          setCartoes(cartoes_)
          setShowDadosCartao(cartoes_.length === 0)
        })
        .catch((err) => {
          console.log('erro obtendo cartoes: ' + JSON.stringify(err))
        })
    }
  }

  const setCartaoCredito = (field, value) => {
    const cartaoCredito = _.get(props, 'cartaoCredito', null)
    const fieldName = _.get(props, 'fieldName', null)
    if (cartaoCredito && fieldName) {
      cartaoCredito[field] = value
      props.setField(fieldName, cartaoCredito)
    }
  }

  const onChangeUltimos4Digitos = (value) => {
    const selected = cartoes.find((c) => c.id === value.id)
    const selectedValue = _.get(selected, 'value', null)
    if (selectedValue) {
      const fieldName = _.get(props, 'fieldName', null)
      props.setField(fieldName, selectedValue)
    }
  }

  const resolveParcelas = () => {
    const showParcelas = _.get(props, 'showParcelas', false)
    if (showParcelas) {
      return (
        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
            <LtsInputNumber
              disabled={_.get(props, 'disabled', false)}
              value={_.get(props, 'parcelasCartaoCredito', 1)}
              label={'Parcelas'}
              onChange={(e) => props.setField('parcelasCartaoCredito', e)}
              min={1}
              style={{ minWidth: '200px', maxWidth: '200px' }}
            />
          </div>
        </div>
      )
    }
    return null
  }

  const renderInfo = () => {
    // const listUltimos4Digitos = _.defaultTo(cartoes, [])
    const oidCartaoCredito = _.get(props, 'cartaoCredito.oid', null)
    if (showDadosCartao && !oidCartaoCredito) {
      return (
        <React.Fragment>
          {renderTipoCartao()}
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <LtsInputText
                disabled={_.get(props, 'disabled', false)}
                value={_.get(props, 'cartaoCredito.cartaoNumero', '')}
                label={'Número do cartão'}
                onChange={(e) => setCartaoCredito('cartaoNumero', e)}
                name={'cartaoNumero'}
                style={{ minWidth: '300px', maxWidth: '300px' }}
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <LtsInputText
                disabled={_.get(props, 'disabled', false)}
                value={_.get(props, 'cartaoCredito.cartaoNome', '')}
                label={'Nome impresso no cartão'}
                onChange={(e) => setCartaoCredito('cartaoNome', e)}
                style={{ minWidth: '300px', maxWidth: '300px' }}
              />
            </div>
          </div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <LtsCalendar
                disabled={_.get(props, 'disabled', false)}
                value={_.get(props, 'cartaoCredito.cartaoVencimento', '')}
                label={'Vencimento cartão'}
                onChange={(e) => setCartaoCredito('cartaoVencimento', e)}
                view={'month'}
                dateFormat="mm/yy"
                style={{ width: '200px' }}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
    // const ultimos4Digitos = _.get(props, 'cartaoCredito.ultimos4Digitos', null)
    // if (ultimos4Digitos) {
    //   return (
    //     <React.Fragment>
    //       {renderTipoCartao()}
    //       <div className="p-grid form-group">
    //         <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
    //           <LtsInputText
    //             disabled={true}
    //             value={ultimos4Digitos}
    //             label={'Últimos 4 dígitos do cartão'}
    //             name={'ultimos4Digitos'}
    //             style={{ minWidth: '200px', maxWidth: '200px' }}
    //           />
    //         </div>
    //       </div>
    //     </React.Fragment>
    //   )
    // }
    return null
  }

  const showDadosNovoCartao = () => {
    const fieldName = _.get(props, 'fieldName', null)
    if (fieldName) {
      props.setField(fieldName, {})
    }
    setShowDadosCartao((prevState) => !prevState)
  }

  const renderListUltimosCartoes = () => {
    const cartoes_ = _.defaultTo(cartoes, [])
    let listUltimos4Digitos = []
    cartoes_.forEach(
      (c) => listUltimos4Digitos.push({ id: c.id, value: c.value.ultimos4Digitos })
      // listUltimos4Digitos.push({ id: c.id, value: `${c.value.ultimos4Digitos} - ${c.value.tipoCartaoCredito.value}` })
    )
    const disabled = _.get(props, 'disabled', false)
    if (listUltimos4Digitos.length > 0 && !showDadosCartao) {
      return (
        <React.Fragment>
          {disabled ? null : (
            <div className="p-grid form-group">
              <div className="p-col-6 p-sm-3 p-md-2 p-lg-4 p-xl-4">
                <Button
                  hidden={_.get(props, 'disabled', false)}
                  onClick={(e) => showDadosNovoCartao()}
                  label={'Novo cartão'}
                  style={{ width: '200px' }}
                />
              </div>
            </div>
          )}
          <div className="p-grid form-group">
            <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
              <LtsDropdownIdValue
                disabled={disabled}
                name={'ultimos4Digitos_'}
                value={{
                  id: _.get(props, 'cartaoCredito.oid', null),
                  value: _.get(props, 'cartaoCredito.ultimos4Digitos', null),
                }}
                onChange={(e) => onChangeUltimos4Digitos(e)}
                label={'Últimos 4 dígitos'}
                style={{ width: '200px' }}
                options={listUltimos4Digitos}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
    return null
  }

  const renderTipoCartao = () => {
    const disabled = _.get(props, 'disabled', false)

    return (
      <div className="p-grid form-group">
        <div className="p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-4">
          <LtsDropdownIdValue
            disabled={disabled}
            name={'tipoCartaoCredito'}
            value={_.get(props, 'cartaoCredito.tipoCartaoCredito', '')}
            onChange={(e) => setCartaoCredito('tipoCartaoCredito', e)}
            loadDataFn={apiIdValueService.getTipoCartaoCredito}
            label={'Tipo cartão'}
            style={{ width: '200px' }}
          />
        </div>
      </div>
    )
  }

  const resolveRender = () => {
    const idFormaPagtoToRender = _.get(props, 'idFormaPagamentoToRender', -1)
    const formaPagamento = _.get(props, 'formaPagamento', -1)
    if (idFormaPagtoToRender > 0 && formaPagamento > 0 && idFormaPagtoToRender === formaPagamento) {
      return (
        <React.Fragment>
          <Card subTitle={'Informações Cartão de Crédito'}>
            {renderListUltimosCartoes()}
            {renderInfo()}
            {resolveParcelas()}
          </Card>
        </React.Fragment>
      )
    }
    return null
  }

  return resolveRender()
}
