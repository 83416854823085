import { types } from './portalClienteFinanceiro.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'portalClienteFinanceiro',
  selectedFilters: [],
  selectedParcelas: [],
  booleanFilter: { tipoGrupoFinanceiro: '', status: '' },
  showHideColumns: [
    {
      label: 'Seleção',
      value: {
        field: 'multiselect',
        header: 'Seleção',
        columnKey: 'multiselect',
        visible: true,
      },
    },
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade',
        visible: true,
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
      },
    },
    {
      label: 'Localização',
      value: {
        field: 'localizacao_identificacao',
        header: 'Localização',
        columnKey: 'localizacao',
        sortable: true,
        filter: true,
        columnSortField: 'localizacao.identificacao',
        visible: true,
      },
    },
    {
      label: 'Emissão',
      value: {
        field: 'emissao',
        header: 'Emissão',
        columnKey: 'emissao',
        sortable: true,
        filter: true,
        columnSortField: 'emissao',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Valor',
      value: {
        field: 'valor',
        header: 'Valor',
        columnKey: 'valor',
        sortable: true,
        filter: true,
        columnSortField: 'valor',
        fieldFormatter: 'formatarDinheiro',
        visible: true,
      },
    },
    {
      label: 'Vencimento',
      value: {
        field: 'vencimento',
        header: 'Vencimento',
        columnKey: 'vencimento',
        sortable: true,
        filter: true,
        columnSortField: 'vencimento',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Pagamento',
      value: {
        field: 'dataPagamento',
        header: 'Pagamento',
        columnKey: 'dataPagamento',
        sortable: true,
        filter: true,
        columnSortField: 'dataPagamento',
        fieldFormatter: 'formatarData',
        visible: true,
      },
    },
    {
      label: 'Forma de Pag.',
      value: {
        field: 'tipoFormaPagamento',
        header: 'Forma de Pag.',
        columnKey: 'tipoFormaPagamento',
        filter: true,
        sortable: true,
        columnSortField: 'tipoFormaPagamento',
        visible: true,
      },
    },
    {
      label: 'Status',
      value: {
        field: 'status',
        header: 'Status',
        columnKey: 'status',
        sortable: true,
        filter: true,
        columnSortField: 'status',
        visible: true,
        fieldFormatter: 'getValue',
      },
    },
    {
      label: 'Boleto',
      value: {
        field: 'boleto_oid',
        header: 'Boleto',
        columnKey: 'oidBoleto',
        sortable: true,
        filter: true,
        columnSortField: 'boleto.oid',
        visible: true,
      },
    },
    {
      label: 'NFSe',
      value: {
        field: 'notas_oid',
        header: 'NFSe',
        columnKey: 'oidNotaFiscalServico',
        sortable: true,
        filter: true,
        columnSortField: 'notas.oid',
        visible: true,
      },
    },
  ],
  columns: [],
  currentRecord: {},
  messages: null,
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDPortalClienteFinanceiroReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('lancamentofinanceiro')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    default:
      return result
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}
