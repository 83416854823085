import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import './contrato-falecido-epi.scss'

import { Button } from 'primereact/button'
import { enums, icons } from '../../../constants/index'
import { Card } from 'primereact/card'
import LtsLookupProduto from '../../../components/lottus/lts-lookup-produto'
import LtsLookupPessoa from '../../../components/lottus/lts-lookup-pessoa'
import LtsInputNumber from '../../../components/lottus/lts-input-number'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

export default function ContratoFalecidoEpi(props) {
  const [lookupProdutoValue, setLookupProdutoValue] = useState({
    oid: '',
    codigo: '',
    descricao: '',
  })
  const [lookupPessoaValue, setLookupPessoaValue] = useState({
    oid: '',
    numeroDocumento: '',
    nome: '',
  })
  const [quantidade, setQuantidade] = useState(0)
  const [selectedValue, setSelectedValue] = useState({})

  useEffect(() => {}, [props.epis])

  const columns = [
    {
      field: 'nomePessoa',
      header: 'Colaborador',
      columnKey: 'nomePessoa',
      sortable: false,
      filter: false,
    },
    {
      field: 'descricao',
      header: 'Produto/Equipamento',
      columnKey: 'descricao',
      sortable: false,
      filter: false,
    },
    {
      field: 'quantidade',
      header: 'Quantidade',
      columnKey: 'quantidade',
      sortable: false,
      filter: false,
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ]

  const onChangeLookupProduto = (e) => {
    const oid = _.get(e, 'oid', null)
    if (oid) {
      setLookupProdutoValue(e)
    } else {
      setLookupProdutoValue({
        oid: '',
        codigo: '',
        descricao: _.get(e, 'descricao', ''),
      })
    }
  }

  const onChangeLookupPessoa = (e) => {
    const oid = _.get(e, 'oid', null)
    if (oid) {
      setLookupPessoaValue(e)
    } else {
      setLookupPessoaValue({
        oid: '',
        numeroDocumento: '',
        nome: _.get(e, 'nome', ''),
      })
    }
  }

  const getActions = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          icon={icons.PENCIL_ICON}
          className="p-button-warning"
          onClick={(e) => editItem(e, rowData)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          type="button"
          icon={icons.REMOVE_ICON}
          className="p-button-danger"
          onClick={(e) => removeItem(e, rowData)}
        />
      </div>
    )
  }

  const editItem = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedValue({
      oidPessoa: _.get(data, 'oidPessoa', null),
      oidProduto: _.get(data, 'oidProduto', null),
    })
    setLookupPessoaValue({
      oid: _.get(data, 'oidPessoa', null),
      numeroDocumento: '',
      nome: _.get(data, 'nomePessoa', null),
    })
    setLookupProdutoValue({
      oid: _.get(data, 'oidProduto', null),
      codigo: '',
      descricao: _.get(data, 'descricao', null),
    })
    setQuantidade(_.get(data, 'quantidade', 0))
  }

  const removeItem = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    const newItens = props.epis.filter((f) => f !== data)
    props.setField(newItens)
  }

  const resolveColumns = () => {
    return columns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'nomePessoa') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-colaborador-body-width"
            headerClassName="column-colaborador-header-width"
            filterHeaderClassName="column-colaborador-filter-header-width"
          />
        )
      }

      if (col.field === 'descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-descricao-body-width"
            headerClassName="column-descricao-header-width"
          />
        )
      }

      if (col.field === 'quantidade') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-quantidade-body-width"
            headerClassName="column-quantidade-header-width"
          />
        )
      }
      if (col.field === 'acoes') {
        return (
          <Column
            {...col}
            key={col.field}
            body={getActions}
            bodyClassName="column-actions-body-width"
            headerClassName="column-actions-header-width"
          />
        )
      }
      return null
    })
  }

  const addEpi = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const epis = _.get(props, 'epis', [])
    const oidPessoa = _.get(selectedValue, 'oidPessoa', null)
    const oidProduto = _.get(selectedValue, 'oidProduto', null)
    if (oidPessoa || oidProduto) {
      const selected = epis.find((f) => f.oidPessoa === oidPessoa && f.oidProduto === oidProduto)
      if (selected) {
        selected.oidPessoa = _.get(lookupPessoaValue, 'oid', null)
        selected.nomePessoa = _.get(lookupPessoaValue, 'nome', null)
        selected.oidProduto = _.get(lookupProdutoValue, 'oid', null)
        selected.descricao = _.get(lookupProdutoValue, 'descricao', null)
        selected.quantidade = _.defaultTo(quantidade, 0)
      }
    } else {
      const newRecord = {
        oidPessoa: _.get(lookupPessoaValue, 'oid', null),
        nomePessoa: _.get(lookupPessoaValue, 'nome', null),
        oidProduto: _.get(lookupProdutoValue, 'oid', null),
        descricao: _.get(lookupProdutoValue, 'descricao', null),
        quantidade: _.defaultTo(quantidade, 0),
      }
      epis.push(newRecord)
      props.setField(epis)
    }

    setLookupPessoaValue({
      oid: '',
      numeroDocumento: '',
      nome: '',
    })
    setLookupProdutoValue({
      oid: '',
      codigo: '',
      descricao: '',
    })
    setQuantidade(null)
    setSelectedValue(null)
  }

  return (
    <React.Fragment>
      <Card>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupPessoa
              header={'Colaborador'}
              name={'colaborador'}
              label={'Colaborador'}
              value={{
                id: _.get(lookupPessoaValue, 'oid', null),
                value: _.get(lookupPessoaValue, 'nome', null),
              }}
              oidRecord={_.get(lookupPessoaValue, 'oid', '')}
              history={props.history}
              onChange={(e) => onChangeLookupPessoa(e)}
              statePessoaFisica={props.statePessoaFisica}
              tipoPessoa={enums.TipoPessoa.FISICA}
              showAddButton={false}
              editHidden={true}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
            <LtsLookupProduto
              label={'Produto/Equipamento'}
              value={_.get(lookupProdutoValue, 'descricao', '')}
              history={props.history}
              editHidden={true}
              onChange={(e) => onChangeLookupProduto(e)}
            />
          </div>
        </div>

        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
            <LtsInputNumber
              value={_.defaultTo(quantidade, 0)}
              label={'Quantidade'}
              onChange={(e) => setQuantidade(e)}
              min={1}
            />
          </div>
        </div>
        <br />
        <div className="p-grid form-group">
          <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-xl-2">
            <Button className={'p-button-success'} label={'Adicionar EPI'} onClick={(e) => addEpi(e)} />
          </div>
        </div>
        <br />
        <br />
        <div className="p-grid form-group" style={{ marginTop: '15px' }}>
          <div className="p-col-12">
            <DataTable
              emptyMessage=" "
              value={props.epis}
              resizableColumns={true}
              columnResizeMode="expand"
              filterDisplay="row"
              size={'small'}
              stripedRows={true}
              responsiveLayout="scroll"
            >
              {resolveColumns()}
            </DataTable>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
