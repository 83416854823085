import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../mapa.actions'
import { MapaForm } from './MapaForm'
import utils from '../../../utils'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const record = state.crudMapaState.currentRecord
  const messages = state.crudMapaState.messages
  const unidades = utils.getListIdValuesUnidadeDisponiveisUsuario()

  return {
    record,
    messages,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, valor))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    salvarCoordenada: (coordenada) => {
      dispatch(actions.criarMapaCoordenada(coordenada))
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class MapaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      showDialogAdicionarCoordenada: false,
      modeAdicionarCoordenada: false,
      coordenadaPosicaoX: '',
      coordenadaPosicaoY: '',
      coordenadaContrato: {},
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_MAPA}/${oid}`)
      this.props.loadById(oid)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_MAPA}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_MAPA}`)
    }

    actions.afterLoad = (record) => {
      return this.executeAfterLoad(record)
    }
    this.setField = props.setField
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_MAPA}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  hideDialogAddCoordenada = () => {
    this.setState({ showDialogAdicionarCoordenada: false })
  }

  executeAfterLoad = (record) => {
    if (record && record.imagem) {
      const imagemSelecionada = 'data:image/png;base64,' + record.imagem.base64
      this.setField('imagemSelecionada', imagemSelecionada)
    }
  }

  handleImageChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      const result = reader.result

      const image = new window.Image()
      const setField = this.setField
      image.onload = function () {
        setField('altura', this.naturalHeight)
        setField('largura', this.naturalWidth)
      }
      image.src = result

      let encoded = result.replace(/^data:(.*;base64,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      this.setField('imagemSelecionada', result)
      this.setField('imagem', { filename: file.name, base64: encoded })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  getBotoesPersonalizados = () => {
    let mapa = this.props.record
    let botoes = []

    if (mapa.oid && !this.state.modeAdicionarCoordenada) {
      const botao = {
        type: 'button',
        label: 'Adicionar coordenada',
        className: 'p-button-success',
        onClick: (e) => {
          this.setState({ modeAdicionarCoordenada: true })
        },
      }
      botoes.push(botao)
    }
    if (mapa.oid && this.state.modeAdicionarCoordenada) {
      const botao = {
        type: 'button',
        label: 'Cancelar adição coordenada',
        className: 'p-button-danger',
        onClick: (e) => {
          this.setState({ modeAdicionarCoordenada: false })
        },
      }
      botoes.push(botao)
    }
    return botoes
  }

  adicionarCoordenadaEvent = (e) => {
    const stage = e.target.getStage()
    const pointerPosition = stage.getPointerPosition()
    const x = pointerPosition.x
    const y = pointerPosition.y

    this.setState({
      showDialogAdicionarCoordenada: true,
      coordenadaPosicaoX: x,
      coordenadaPosicaoY: y,
      coordenadaContrato: {},
    })
  }

  salvarCoordenada = (posicaoX, posicaoY, localizacao) => {
    const mapa = this.props.record
    const coordenada = {
      posicaoX,
      posicaoY,
      localizacao,
      mapa: {
        id: mapa.oid,
        value: mapa,
      },
    }
    this.props.salvarCoordenada(coordenada)
    this.hideDialogAddCoordenada()
    this.setState({ modeAdicionarCoordenada: false })
    this.props.loadById(mapa.oid)
  }

  setContrato = (data) => {
    const oid = _.get(data, 'oid', '')
    const valor = _.get(data, 'localizacao_identificacao', '')

    const contrato = {
      id: oid,
      value: valor,
    }
    this.setState({ coordenadaContrato: contrato })
  }

  setLocalizacao = (data) => {
    const oid = _.get(data, 'oid', '')
    const valor = _.get(data, 'identificacao', '')

    const localizacao = {
      id: oid,
      value: valor,
    }
    this.setState({ coordenadaLocalizacao: localizacao })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <MapaForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        messages={this.props.messages}
        unidades={this.props.unidades}
        setField={this.setField}
        handleImageChange={this.handleImageChange}
        hideDialogAddCoordenada={this.hideDialogAddCoordenada}
        adicionarCoordenadaEvent={this.adicionarCoordenadaEvent}
        salvarCoordenada={this.salvarCoordenada}
        state={this.state}
        setContrato={this.setContrato}
        setLocalizacao={this.setLocalizacao}
        history={this.props.history}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapaFormPageContainer)
