import React from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputMask } from 'primereact/inputmask'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import utils from '../../../utils/index'
import { TabView, TabPanel } from 'primereact/tabview'
import { DataTableLog } from '../../../components/log/DataTableLog'

import './PessoaJuridicaForm.scss'
import { enums } from '../../../constants'
import LtsLookupCategoria from '../../../components/lottus/lts-lookup-categoria'
import _ from 'lodash'

export const PessoaJuridicaForm = (props) => {
  const messages = props.messages
  const record = props.record
  const changeHandler = props.changeHandler

  let categorias = []
  if (record.categorias) {
    record.categorias.forEach((categoria) => {
      categorias.push({ oid: categoria.id, descricao: categoria.value })
    })
  }

  function getDadosSenha() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>Senha de Acesso</h3>

            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-6 p-md-5 p-lg-4 p-xl-4">
                <span className="p-float-label">
                  <InputText value={record.senha} name="plainSenha" type="password" onChange={changeHandler} />
                  <label>Senha</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div className="card card-w-title">
            <div className="p-grid form-group">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <DataTableLog record={record} tipoLog={enums.TipoLog.PESSOA_JURIDICA}></DataTableLog>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getDadosGerais() {
    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>
                {utils.getMessage(messages, 'pessoajuridica.label.empresa')} {record.oid || ''}
              </h3>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                  <span className="p-float-label">
                    <InputMask
                      mask="99.999.999/9999-99"
                      unmask={true}
                      value={record.numeroDocumento}
                      onChange={props.inputMaskOnChange}
                      name="numeroDocumento"
                      keyfilter="int"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.cnpj')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3">
                  <span className="p-float-label">
                    <InputText value={record.nome} onChange={changeHandler} name="nome" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.razaosocial')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3">
                  <span className="p-float-label">
                    <InputText value={record.nomeFantasia} onChange={changeHandler} name="nomeFantasia" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.nomefantasia')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                  <span className="p-float-label">
                    <InputText
                      keyfilter="int"
                      value={record.inscricaoEstadual}
                      onChange={changeHandler}
                      name="inscricaoEstadual"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.inscricaoestadual')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                  <span className="p-float-label">
                    <InputText
                      keyfilter="int"
                      value={record.inscricaoMunicipal}
                      onChange={changeHandler}
                      name="inscricaoMunicipal"
                    />
                    <label>Inscrição Municipal</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-8 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                  <label className="label">Optante pelo Simples Nacional</label>
                </div>
                <div className="p-col-2">
                  <Checkbox
                    inputId="chkOptante"
                    checked={record.optanteSimples}
                    onChange={props.changeHandler}
                    name="optanteSimples"
                  />
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                  <label htmlFor="chkativo" className="label">
                    Ativo
                  </label>
                  <Checkbox
                    checked={record.ativo}
                    onChange={props.changeHandler}
                    name="ativo"
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, 'pessoajuridica.label.endereco')}</h3>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2">
                  <span className="p-float-label">
                    <InputMask
                      mask="99999-999"
                      unmask={true}
                      value={record.cep}
                      onChange={props.cepOnChange}
                      name="cep"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.cep')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputText value={record.nomeLogradouro} onChange={changeHandler} name="nomeLogradouro" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.endereco')}</label>
                  </span>
                </div>
                <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-2">
                  <span className="p-float-label">
                    <InputText
                      keyfilter="alphanum"
                      value={record.numeroLogradouro}
                      onChange={changeHandler}
                      name="numeroLogradouro"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.numero')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputText value={record.complemento} onChange={changeHandler} name="complemento" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.complemento')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputText value={record.bairro} onChange={changeHandler} name="bairro" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.bairro')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputText
                      keyfilter={utils.getRegexLettersBlankSpace()}
                      value={record.cidade}
                      onChange={changeHandler}
                      name="cidade"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.cidade')}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-2 p-sm-2 p-md-2 p-lg-1 p-xl-1">
                  <span className="p-float-label">
                    <InputText value={record.uf} onChange={changeHandler} name="uf" />
                    <label>UF</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, 'pessoajuridica.label.contatos')}</h3>

              <div className="p-grid form-group">
                <div className="p-col-10 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputMask
                      mask={utils.getMascaraTelefone(_.get(record, 'telefone', null))}
                      unmask={true}
                      keyfilter="pint"
                      value={record.telefone}
                      onChange={props.inputMaskOnChange}
                      name="telefone"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.telefone')}</label>
                  </span>
                </div>
                {record.oid ? (
                  <div className="p-col-2">
                    <Button
                      style={{ marginTop: '10px' }}
                      icon="pi pi-whatsapp"
                      className={'p-button-success'}
                      onClick={(e) => props.enviarWhatsApp(record.telefone)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="p-grid form-group">
                <div className="p-col-10 p-sm-6 p-md-5 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputMask
                      mask={utils.getMascaraTelefone(_.get(record, 'segundoTelefone', null))}
                      unmask={true}
                      keyfilter="pint"
                      value={record.segundoTelefone}
                      onChange={props.inputMaskOnChange}
                      name="segundoTelefone"
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.segundo.telefone')}</label>
                  </span>
                </div>
                {record.oid ? (
                  <div className="p-col-2">
                    <Button
                      style={{ marginTop: '10px' }}
                      icon="pi pi-whatsapp"
                      className={'p-button-success'}
                      onClick={(e) => props.enviarWhatsApp(record.segundoTelefone)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="p-grid form-group">
                <div className="p-col-10 p-sm-10 p-md-6 p-lg-6 p-xl-4">
                  <span className="p-float-label">
                    <InputText keyfilter="email" value={record.email} onChange={changeHandler} name="email" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.email')}</label>
                  </span>
                </div>
                {record.oid ? (
                  <div className="p-col-2">
                    <Button style={{ marginTop: '10px' }} icon="pi pi-send" onClick={props.enviarEmail} />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputText value={record.site} onChange={changeHandler} name="site" />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.site')}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <span className="p-float-label">
                    <InputTextarea
                      value={record.contato}
                      onChange={changeHandler}
                      name="contato"
                      rows={1}
                      cols={30}
                      autoResize={true}
                    />
                    <label>{utils.getMessage(messages, 'pessoajuridica.label.contato')}</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, 'pessoajuridica.label.outrasinformacoes')}</h3>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-9 p-md-10 p-lg-8 p-xl-7">
                  <LtsLookupCategoria
                    label={utils.getMessage(messages, 'pessoajuridica.label.categoria')}
                    value={categorias}
                    history={props.history}
                    editHidden={true}
                    field={'descricao'}
                    multiple={true}
                    onChange={(e) => props.setCatagorias(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
      <TabPanel header="Dados Gerais">
        <div>{getDadosGerais()}</div>
        <div>{getDadosSenha()}</div>
      </TabPanel>
      <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
    </TabView>
  )
}
