import React from 'react';
import PortalClienteCarteirinhaListPageContainer from './PortalClienteCarteirinhaListPageContainer';

export class PortalClienteCarteirinhaListPage extends React.Component{
    
    render(){
      return (
        <PortalClienteCarteirinhaListPageContainer {...this.props}/>
      )
    }
}


