import React from 'react';

import ReceitasDespesasRelatorioFormPageContainer from './ReceitasDespesasRelatorioFormPageContainer';

export class ReceitasDespesasRelatorioFormPage extends React.Component{

    
    render(){
        return (
           <ReceitasDespesasRelatorioFormPageContainer {...this.props} />
        )
    }
}
