const blankToNull = require('./../../../utils').blankToNull;

export function validate (record){
    blankToNull(record);
    const errors = [];
    if (!record.menu.id) {
        errors.push({ field: 'menu', message: 'O campo menu é obrigatório!' });
    }
    if (!record.perfil.id) {
        errors.push({ field: 'perfil', message: 'O campo perfil é obrigatório!' });
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}