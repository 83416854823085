import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { enums } from '../../constants'
import utils from '../../utils/index'
import { Card } from 'primereact/card'
import apiService from '../../services/api.service'
import '../../index.scss'

export class DataTableLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logFilters: [],
      logSortField: {},
      logInfo: {
        index: 0,
        max: 15,
        count: 0,
        page: 0,
      },
      records: [],
      colunas: [
        {
          field: 'data',
          header: 'Data',
          columnKey: 'data',
          sortable: true,
          filter: true,
          columnSortField: 'data',
          visible: true,
          fieldFormatter: 'formatarDataHora',
        },
        {
          field: 'usuarioGravacao',
          header: 'Usuário',
          columnKey: 'usuarioGravacao',
          sortable: true,
          filter: true,
          columnSortField: 'usuarioGravacao',
          visible: true,
        },
        {
          field: 'descricao',
          header: 'Descrição',
          columnKey: 'descricao',
          sortable: true,
          filter: true,
          columnSortField: 'descricao',
          visible: true,
        },
      ],
    }
  }

  onSort = (e) => {
    const sortParams = { order: e.sortOrder, field: e.sortField }
    this.setState({ logSortField: sortParams })

    const params = {
      filters: this.state.logFilters,
      pagination: {
        index: 0,
        max: 15,
      },
      sortField: sortParams,
    }
    this.executeFilter(params)
  }

  onPageLog = (e) => {
    let logFilters = this.state.logFilters
    if (utils.isArrayNullOrEmpty(logFilters)) {
      logFilters = []
      this.setState({ logFilters: logFilters })
    }
    const params = {
      filters: this.state.logFilters,
      pagination: {
        index: e.first,
        max: e.rows,
      },
      sortField: null,
    }

    this.executeFilter(params)
  }

  onFilterLog = (e) => {
    const filters = e.filters
    let logFilters = []
    if (filters) {
      if (filters.descricao) {
        logFilters.push({
          campo: 'descricao',
          valor: filters.descricao.value,
          tipoFiltro: 'CONTEM',
        })
      }
      if (filters.data) {
        logFilters.push({
          campo: 'data',
          valor: filters.data.value,
          tipoFiltro: 'IGUAL_A',
        })
      }
    }
    this.setState({ logFilters: logFilters })

    const params = {
      filters: logFilters,
      pagination: {
        index: 0,
        max: 15,
      },
      sortField: null,
    }

    this.executeFilter(params)
  }

  executeFilter = (params) => {
    let record = this.props.record
    const filters = params.filters
    filters.push({
      campo: 'tipoLog',
      valor: this.props.tipoLog,
      tipoFiltro: 'IGUAL_A',
    })
    filters.push({
      campo: 'oidEntidadeRaiz',
      valor: record.oid,
      tipoFiltro: 'IGUAL_A',
    })
    let sortFields = []
    if (params.sortField) {
      sortFields = [
        {
          fieldName: params.sortField.field,
          order: params.sortField.order > -1 ? `ASC` : `DESC`,
        },
      ]
    }
    const requestParam = {
      index: params.pagination.index,
      max: params.pagination.max,
      filters: filters,
      projectionFields: ['data', 'usuarioGravacao', 'descricao'],
      sortFields,
    }

    apiService
      .post(`/lottustech/logs/all`, requestParam)
      .then((response) => {
        const size = response.data.size || 15
        const max = response.data.size || 15
        const page = response.data.page || 1
        const index = page * max - size
        const logInfo = {
          index: index,
          max: max,
          count: response.data.count || 0,
          page: page,
        }
        this.setState({ records: response.data.records })
        this.setState({ logInfo: logInfo })
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data)
        }
      })
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  componentDidMount() {
    const params = {
      filters: this.state.logFilters,
      pagination: {
        index: 0,
        max: 15,
      },
    }

    this.executeFilter(params)
  }

  render() {
    const colunasGridLogs = this.state.colunas.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'data') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            body={this.formatColumn}
            bodyClassName="column-date-body-width"
            filterHeaderClassName="column-date-filter-header-width"
            headerClassName="column-date-header-width"
          />
        )
      } else if (col.field === 'usuarioGravacao') {
        return (
          <Column
            {...col}
            key={col.field}
            field={col.field}
            bodyClassName="column-user-body-width"
            filterHeaderClassName="column-user-filter-header-width"
            headerClassName="column-user-header-width"
          />
        )
      }
      return (
        <Column
          {...col}
          style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}
          key={col.field}
          field={col.field}
          header={col.header}
        />
      )
    })

    return (
      <div>
        <DataTable
          // style={{ wordBreak: "break-all", whiteSpace: "normal" }}
          onFilter={this.onFilterLog}
          filter={true}
          onPage={this.onPageLog}
          paginator={true}
          rows={this.state.logInfo.max}
          rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
          onSort={this.onSort}
          value={this.state.records}
          sortOrder={this.state.logSortField.order}
          sortField={this.state.logSortField.field}
          emptyMessage={' '}
          lazy={true}
          first={this.state.logInfo.index}
          totalRecords={this.state.logInfo.count}
          // V7 properties //
          filterDisplay="row"
          size={'small'}
          stripedRows
          resizableColumns
          columnResizeMode="fit"
          responsiveLayout="scroll"
          // showFilterMenu={false}
        >
          {colunasGridLogs}
        </DataTable>
        <br />
        <Card>
          <div style={{ textAlign: 'right' }}>
            <strong>Total: </strong> {this.state.logInfo.count}
          </div>
        </Card>
      </div>
    )
  }
}
