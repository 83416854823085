import _ from "lodash";
import {Link} from "react-router-dom";
import {routes} from "../constants";
import React from "react";

export default (rowData, column) => {
    const nome = _.get(rowData, 'responsavel_nome', null)
    const oid = _.get(rowData, 'responsavel_oid', null)
    if (oid) {
        return (
            <Link
                to={`${routes.PATH_PESSOA_FISICA}/${oid}`}>{nome}</Link>
        )
    }
    return ''
}
