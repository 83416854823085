import { types } from './taxa.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils/index'

const initialState = {
  id: 'taxaFinanceira',
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Grupo',
      value: {
        field: 'grupoFinanceiro_descricao',
        header: 'Grupo',
        columnKey: 'grupoFinanceiro',
        sortable: true,
        filter: true,
        columnSortField: 'grupoFinanceiro.descricao',
        relacionamento: ['grupoFinanceiro'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Valor',
      value: {
        field: 'valor',
        header: 'Valor',
        columnKey: 'valor',
        sortable: true,
        filter: true,
        columnSortField: 'valor',
        visible: true,
        fieldFormatter: utils.formatarDinheiro,
      },
    },
    {
      label: 'Data Inicial',
      value: {
        field: 'vigenciaInicial',
        header: 'Data Inicial',
        columnKey: 'vigenciaInicial',
        sortable: true,
        filter: true,
        columnSortField: 'vigenciaInicial',
        visible: true,
        fieldFormatter: utils.formatarData,
      },
    },
    {
      label: 'Data Final',
      value: {
        field: 'vigenciaFinal',
        header: 'Data Final',
        columnKey: 'vigenciaFinal',
        sortable: true,
        filter: true,
        columnSortField: 'vigenciaFinal',
        visible: true,
        fieldFormatter: utils.formatarData,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade_sigla',
        columnKey: 'unidade.sigla',
        sortable: true,
        header: 'Unidade',
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
    {
      label: 'Contrapartida',
      value: {
        field: 'taxaContrapartidaGrupo_descricao',
        columnKey: 'taxaContrapartidaGrupo.descricao',
        sortable: true,
        header: 'Contrapartida',
        filter: true,
        columnSortField: 'taxaContrapartidaGrupo.descricao',
        relacionamento: ['taxaContrapartidaGrupo'],
        fieldJoin: 'descricao',
        visible: true,
      },
    },
    {
      label: 'Cód. Contrapartida',
      value: {
        field: 'taxaContrapartida_oid',
        columnKey: 'taxaContrapartida.oid',
        sortable: true,
        header: 'Cód. Contrapartida',
        filter: true,
        columnSortField: 'taxaContrapartida.oid',
        relacionamento: ['taxaContrapartida'],
        fieldJoin: 'oid',
        visible: true,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'grupoFinanceiro_oid',
      header: 'Cód. Grupo',
      columnKey: 'grupoFinanceiro_oid',
      sortable: true,
      filter: true,
      columnSortField: 'grupoFinanceiro.oid',
      relacionamento: ['grupoFinanceiro'],
      fieldJoin: 'oid',
      visible: true,
    },
    {
      field: 'grupoFinanceiro_descricao',
      header: 'Grupo',
      columnKey: 'grupoFinanceiro_descricao',
      sortable: true,
      filter: true,
      columnSortField: 'grupoFinanceiro.descricao',
      relacionamento: ['grupoFinanceiro'],
      fieldJoin: 'descricao',
      visible: true,
    },
    {
      field: 'grupoFinanceiro_tipo',
      header: 'Tipo',
      columnKey: 'grupoFinanceiro_tipo',
      sortable: true,
      filter: true,
      columnSortField: 'grupoFinanceiro.tipo',
      relacionamento: ['grupoFinanceiro'],
      fieldJoin: 'tipo',
      visible: false,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',

      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    valor: 0.0,
    vigenciaInicial: '',
    vigenciaFinal: '',
    grupoFinanceiro: '',
    unidade: '',
    taxasContrapartidas: [],
    pacoteTaxas: [],
  },
  perfis: [],
  grupos: [],
  messages: null,
  selectedFilters: [],
  lookupGrupo: {
    visible: false,
    modal: true,
    header: 'Grupo',
  },
  lookupTaxa: {
    visible: false,
    modal: true,
    header: 'Taxa',
  },
  lookupPacoteTaxa: {
    visible: false,
    modal: true,
    header: 'Taxa',
  },
  colunasTaxa: [
    {
      field: 'oidTaxaAlvo',
      header: 'Código',
      columnKey: 'id',
    },
    {
      field: 'value',
      header: 'Grupo',
      columnKey: 'value',
    },
    {
      field: 'formaPagamento',
      header: 'Forma de Pagamento',
      columnKey: 'formaPagamento',
    },
    {
      field: 'contaCorrente',
      header: 'Conta Corrente',
      columnKey: 'contaCorrente',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  colunasPacoteTaxas: [
    {
      field: 'oidTaxaAlvo',
      header: 'Código',
      columnKey: 'id',
    },
    {
      field: 'value',
      header: 'Grupo',
      columnKey: 'value',
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
    },
  ],
  usaListDto: false,
  taxaConservacao: null,
  contasCorrentes: [],
}

const reducer = new Reducer(initialState, types)

export function CRUDTaxaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('taxa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_TAXA_VISIBLE:
      return setLookupTaxaVisible(state, action)
    case types.SET_LOOKUP_GRUPO_VISIBLE:
      return setLookupGrupoVisible(state, action)
    case types.SET_TAXA_CONSERVACAO:
      return setTaxaConservacao(state, action)
    case types.RESET:
      return reset(state, action)
    case types.SET_LOOKUP_PACOTE_TAXA_VISIBLE:
      return setLookupPacoteTaxaVisible(state, action)
    case types.SET_PACOTE_TAXAS:
      return setPacoteTaxas(state, action)
    case types.SET_CONTAS_CORRENTES:
      return setContasCorrentes(state, action)
    default:
      return state
  }
}

function setLookupGrupoVisible(state, action) {
  let lookupGrupo = state.lookupGrupo
  lookupGrupo = Object.assign({}, lookupGrupo, { visible: action.visible })
  return Object.assign({}, state, { lookupGrupo })
}

function setLookupTaxaVisible(state, action) {
  let lookupTaxa = state.lookupTaxa
  lookupTaxa = Object.assign({}, lookupTaxa, { visible: action.visible })
  return Object.assign({}, state, { lookupTaxa })
}

function setLookupPacoteTaxaVisible(state, action) {
  let lookupPacoteTaxa = state.lookupPacoteTaxa
  lookupPacoteTaxa = Object.assign({}, lookupPacoteTaxa, { visible: action.visible })
  return Object.assign({}, state, { lookupPacoteTaxa })
}

function setTaxaConservacao(state, action) {
  const taxaConservacao = action.payload
  return Object.assign({}, state, { taxaConservacao })
}

function reset(state, action) {
  return {
    ...state,
    currentRecord: initialState.currentRecord,
    records: [],
    selectedFilters: [],
    perfis: [],
    grupos: [],
  }
}

function setPacoteTaxas(state, action) {
  const pacoteTaxas = action.payload
  let newValue = 0
  for (const tx of pacoteTaxas) {
    if (tx.valor && tx.valor > 0) {
      newValue += tx.valor
    }
  }
  const curr = state.currentRecord
  curr.valor = utils.formatCurrency(newValue)
  curr.pacoteTaxas = pacoteTaxas
  return { ...state, currentRecord: curr }
}

function setContasCorrentes(state, action) {
  Object.assign(state, { contasCorrentes: action.payload })
  return state
}
