const blankToNull = require('../../../utils').blankToNull

export function validate(record) {
  const errors = []
  if (!record.contrato || record.contrato.length == 0) {
    errors.push({
      field: 'contrato',
      message: 'O campo contrato é obrigatório',
    })
  }

  if (!record.unidade || !record.unidade.id) {
    errors.push({
      field: 'unidade',
      message: 'O campo unidade é obrigatório',
    })
  }

  if (!record.responsavel || record.responsavel.length == 0) {
    errors.push({
      field: 'responsavel',
      message: 'O campo responsável é obrigatório',
    })
  }

  if (!record.dataEmissao) {
    errors.push({
      field: 'dataEmissao',
      message: 'O campo data de emissão é obrigatório',
    })
  }

  if (!record.produtos || record.produtos.length == 0) {
    errors.push({
      field: 'produto',
      message: 'Necessário pelo menos um registro de equipamento para empréstimo',
    })
  }

  if (record.produtos && record.produtos.length > 0) {
    record.produtos.forEach((produto) => {
      if (produto.quantidade <= 0) {
        errors.push({
          field: 'produto',
          message: `Equipamento "${produto.descricaoProduto}" deve possuir quantidade maior que zero`,
        })
      }
    })
  }

  blankToNull(record)
  const result = {
    ok: errors.length === 0,
    errors,
    record,
  }
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}
