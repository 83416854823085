import React from "react";
import {DataTable} from "primereact/datatable";
import {Card} from "primereact/card";
import {DefaultDataTable} from "../../../components/dataTable/DefaultDataTable";


export const PortalClienteContratoList = props => <DefaultDataTable data={props} />;

// export const PortalClienteContratoList = props => {
//   return (
//     <div className="p-grid p-fluid">
//       <div className="p-col-12">
//         <div className="card">
//           <DataTable
//                   ref={props.dataTableRef}
//                   value={props.records}
//                   paginator={true}
//                   emptyMessage="Não foram encontrados registros"
//                   rows={15}
//                   rowsPerPageOptions={[15, 50, 100, 1000]}
//                   reorderableColumns={true}
//                   resizableColumns={true}
//                   columnResizeMode="expand"
//                   selection={props.selectedRecords}
//                   onSelectionChange={props.onSelectionChange}
//                   onColReorder={props.onColReorder}
//                   totalRecords={props.totalRecords}
//                   first={props.first}
//           >
//             {props.columns}
//           </DataTable>
//           <br/>
//           <Card>
//             <div style={{textAlign: "right"}}>
//               <strong>Total: </strong> {props.totalRecords}
//             </div>
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// };