import { validate } from './validations'
import { build } from '../../services/crudFactory'
import { Action } from '../../services/actions.factory'
import apiService from '../../services/api.service'
import apiIdValue from '../../services/api-id-value-service'
import utils from '../../utils'
import _ from 'lodash'
const BASE_PATH = '/lottustech/relatorios'
const crud = build(validate, BASE_PATH)
const prefix = 'crudLivroCaixa'

const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
}

const actions = new Action(prefix, crud)
const types = Object.assign({}, actions.types, newTypes)

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value,
  }
}

actions.abrirRelatorio = (
  dataIni,
  dataFin,
  unidade,
  isDetal,
  hasTotaliz,
  formasPagamento,
  tiposSepulturas,
  usuariosBaixa,
  contas
) => {
  let params = {
    dataInicial: dataIni,
    dataFinal: dataFin,
    unidades: unidade,
    detalhado: isDetal,
    hasTotalizadores: hasTotaliz,
    formasPagamentos: formasPagamento,
    tiposSepulturas: tiposSepulturas,
    usuariosBaixa,
    contas,
  }
  return (dispatch, getState) => {
    const path = BASE_PATH + `/livrocaixa`
    return apiService.post(path, params).then((response) => {
      const win = window.open(response.data)
      if (win) {
        win.focus()
      }
    })
  }
}

actions.carregarTiposSepulturas = (unidade) => {
  const params = {
    unidades: unidade,
  }
  return (dispatch, getState) => {
    const path = BASE_PATH + `/tipossepulturas/`
    const state = getState()[`crudExumacoesRelatorioState`]
    return apiService.post(path, params).then((response) => {
      if (state.currentRecord.tiposSepulturas.length > 0) {
        let itensRemover = []
        state.currentRecord.tiposSepulturas.forEach((tipo) => {
          const obj = utils.findObjectInArray(response.data, 'value', tipo.value)
          if (!obj) {
            itensRemover.push(tipo.value)
          }
        })
        itensRemover.forEach((itemRemover) => {
          utils.removeObjectFromArray(state.currentRecord.tiposSepulturas, 'value', itemRemover)
        })
      }
      dispatch(actions.setField('opcoesTiposSepulturas', response.data))
    })
  }
}

actions.carregarContasCorrentes = (unidade) => {
  return (dispatch, getState) => {
    return apiIdValue.getContasCorrentes(_.get(unidade, 'id', -1)).then((response) => {
      dispatch(actions.setField('opcoesContasCorrentes', response.data))
    })
  }
}

actions.carregarUsuariosBaixa = (unidade) => {
  return (dispatch, getState) => {
    const params = unidade && unidade.length > 0 ? unidade : []
    const path = `/lottustech/usuarios/baixa`
    return apiService.post(path, params).then((response) => {
      dispatch(actions.setField('opcoesUsuarioBaixa', response.data))
    })
  }
}
export { types, actions }
