import React, { useState } from 'react'

import _ from 'lodash'
import { Filtro } from '../../utils/Filtro'
import { routes } from '../../constants'
import apiService from '../../services/api.service'
import { Column } from 'primereact/column'
import '../../index.scss'
import LtsLookup from './lts-lookup'
import { getFiltroJoinUnidade } from '../../utils/filter-utils'
import { InputText } from 'primereact/inputtext'

export default function LtsLookupLocalizacao(props) {
  const [filters, setFilters] = useState([])
  const [filtroLocalizacaoTemporaria, setFiltroLocalizacaoTemporaria] = useState(false)

  const columns = [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true,
    },
    {
      field: 'identificacao',
      header: 'Identificação',
      columnKey: 'identificacao',
      sortable: true,
      filter: true,
      columnSortField: 'identificacao',
      visible: true,
    },
    {
      field: 'tipoSepultura_descricao',
      header: 'Tipo Sepultura',
      columnKey: 'tipoSepultura_descricao',
      sortable: true,
      filter: true,
      columnSortField: 'tipoSepultura_descricao',
      visible: true,
    },
    {
      field: 'temporaria',
      header: 'Temporária',
      columnKey: 'temporaria',
      sortable: false,
      filter: false,
      columnSortField: 'temporaria',
      visible: false,
    },
  ]

  const loadDataFn = (params) => {
    return apiService.post(`/lottustech/localizacoes/all`, params)
  }

  const onKeyPress = (e) => {
    const value = _.get(props, 'value', null)
    if (value) {
      const filters = [new Filtro('identificacao', value, 'CONTEM')]
      setFilters(filters)
    }
  }

  const onInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      props.onChange({
        oid: '',
        identificacao: value,
      })
    } else {
      props.onChange(value)
    }
  }

  const resolveValue = (data) => {
    const value = _.get(props, 'value', null)
    const oid = _.get(props, 'oidRecord', null)
    if (value) {
      return value
    } else if (oid) {
      return `#${oid} - sem localização`
    }
  }

  const autoCompleteTemplate = (data) => {
    return _.get(data, 'identificacao', null)
  }

  const onResolvingColumn = () => {
    const visibleColumns = columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false

      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-oid-body-width"
            filterHeaderClassName="column-oid-filter-header-width"
            headerClassName="column-oid-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      if (col.field === 'identificacao') {
        return (
          <Column
            {...col}
            key={col.field}
            bodyClassName="column-localizacao-body-width"
            filterHeaderClassName="column-localizacao-filter-header-width"
            headerClassName="column-localizacao-header-width"
            filterElement={columnFilterElement(col)}
          />
        )
      }
      return <Column key={col.field} {...col} />
    })
  }

  const columnFilterElement = (column) => {
    return <InputText style={{ width: '100%' }} name={column.field} onChange={(e) => onChangeTableFilter(column, e)} />
  }

  const onChangeTableFilter = (column, e) => {
    const campo = column.field

    //remove o filtro do campo atual com valor 'antigo'
    const newFilters = filters.filter((f) => f.campo !== campo)

    const valor = _.get(e, 'target.value', null)
    if (valor) {
      const tipoFiltro = campo === 'oid' ? 'IGUAL_A' : 'CONTEM'
      newFilters.push({ campo, valor, tipoFiltro })
    }

    setFilters(newFilters)
  }

  const resolveFilters = (filters) => {
    const filtroUnidade = getFiltroJoinUnidade(_.get(props, 'idUnidade', null))
    if (filtroUnidade) {
      filters.push(filtroUnidade)
    }
    return filters
  }

  const pathOnEditFn = () => {
    const oid = _.get(props, 'oidRecord', null)
    if (oid) {
      return `${routes.PATH_LOCALIZACAO}/${oid}`
    }
  }

  const onChangeApenasLocalizacaoTemporaria = (e) => {
    const valor = _.get(e, 'target.checked', false)
    setFiltroLocalizacaoTemporaria(valor)

    const newFilters = filters.filter((f) => f.campo !== 'temporaria')
    if (valor) {
      newFilters.push({ campo: 'temporaria', valor, tipoFiltro: 'IGUAL_A' })
    }
    setFilters(newFilters)
  }

  return (
    <React.Fragment>
      <LtsLookup
        label={props.label}
        value={resolveValue()}
        name={props.name}
        field={props.field}
        history={props.history}
        autoCompleteTemplate={autoCompleteTemplate}
        onKeyPress={onKeyPress}
        onInputFilter={onInputFilter}
        pathOnEditFn={pathOnEditFn}
        header={'Localizacao'}
        columns={columns}
        onResolvingColumn={onResolvingColumn}
        loadDataFn={loadDataFn}
        resolveFilters={resolveFilters}
        onChange={props.onChange}
        filters={filters}
        setFilters={setFilters}
        editDisabled={props.editDisabled}
        editHidden={props.editHidden}
        addFiltroDistint={props.addFiltroDistint}
        onChangeTemporaria={onChangeApenasLocalizacaoTemporaria}
        showFiltroTemporaria={props.showFiltroTemporaria}
        apenasTemporaria={filtroLocalizacaoTemporaria}
      />
    </React.Fragment>
  )
}
