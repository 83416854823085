import React from 'react'
import { DataTable } from 'primereact/datatable'
import { enums } from '../../../constants'
import utils from '../../../utils/index'
import { Card } from 'primereact/card'
import { TabPanel, TabView } from 'primereact/tabview'
import _ from 'lodash'

export const ConsultaRemessaForm = (props) => {
  const record = props.record
  let identificacao = record.identificacao
  const itens = _.get(record, 'itens', [])
  const logs = _.get(record, 'logs', [])

  function getDadosGerais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <DataTable
            selection={props.selection}
            onSelectionChange={props.onSelectionChange}
            reorderableColumns={true}
            resizableColumns={true}
            columnResizeMode="expand"
            ref={props.dataTableItens}
            paginator={true}
            emptyMessage=" "
            rows={15}
            rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
            value={itens}
            onRowSelect={props.onRowSelect}
            totalRecords={itens.length}
          >
            {props.colunasItens}
          </DataTable>
          <br />
          <div style={{ textAlign: 'right', margin: '7px' }}>
            <strong>Total: </strong> {itens.length}
          </div>
        </div>
      </div>
    )
  }
  function getDadosLogs() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <DataTable
            resizableColumns={true}
            columnResizeMode="expand"
            ref={props.dataTableLogs}
            paginator={true}
            emptyMessage="  "
            rows={15}
            rowsPerPageOptions={enums.ROWSPERPAGEOPTIONS}
            value={logs}
            totalRecords={logs.length}
          >
            {props.colunasLogs}
          </DataTable>
        </div>
      </div>
    )
  }
  function getContent() {
    return (
      <TabView activeIndex={props.state.activeIndex} onTabChange={(e) => props.changeActiveIndex(e.index)}>
        <TabPanel header="Dados Gerais">{getDadosGerais()}</TabPanel>
        <TabPanel header="Logs">{getDadosLogs()}</TabPanel>
      </TabView>
    )
  }

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12">
        <Card
          title={`${utils.getMessage(props.messages, 'consultaremessa.label.consultaRemessas')} ${record.oid || ''}`}
          subTitle={identificacao || ''}
        >
          {getContent()}
        </Card>
      </div>
    </div>
  )
}
