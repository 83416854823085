import React from 'react';
import ConsultaFalecidoListPageContainer from './ConsultaFalecidoListPageContainer';

export class ConsultaFalecidoListPage extends React.Component{
    
    render(){
      return (
        <ConsultaFalecidoListPageContainer {...this.props}/>
      )
    }
}


