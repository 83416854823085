import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lottustech/mensageria";
const crud = build(validate, BASE_PATH);
const prefix = "crudMensageriaLog";

const actions = new Action(prefix, crud);
const newTypes = {
  SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
  RESET: `${prefix}reset`
};

const types = Object.assign({}, actions.types, newTypes);

actions.reset = () => {
  return {
    type: types.RESET
  };
};

actions.setBooleanFilter = (field, value) => {
  return {
    type: types.SET_BOOLEAN_FILTER,
    field,
    value
  };
};

actions.downloadAnexo = anexo => {
  return (dispatch, getState) => {
    if (anexo && anexo.oidContainer && anexo.oid && anexo.link) {
      return apiService.post(`${BASE_PATH}/downloadanexo`, anexo).then(response => {
        const arrayBuffer = base64ToArrayBuffer(response.data);
        createAndDownloadBlobFile(arrayBuffer, anexo.filename);
      });
    }
  };
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

function createAndDownloadBlobFile(body, filename) {
  const blob = new Blob([body]);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
export { types, actions };
