import React from 'react'
import { Card } from 'primereact/card'
import utils from '../../../utils'
import { Button } from 'primereact/button'
import { icons } from '../../../constants'
import { Message } from 'primereact/message'
import { ProgressSpinner } from 'primereact/progressspinner'

export default function ContratoAnexo(props) {
  function getContent() {
    const anexos = props.anexos
    if (utils.isArrayNullOrEmpty(anexos)) {
      return null
    }

    return anexos.map((anexo) => (
      <div className="p-grid">
        <div className="p-col-10">
          <Button
            type="p-button"
            label={anexo.filename}
            onClick={(e) => props.downloadAnexo(anexo)}
            icon={icons.DOWNLOAD}
            className="p-button-secondary"
            disabled={isNaN(anexo.oid)}
          />
        </div>
        <div className="p-col-2">
          <Button
            type="p-button-icon"
            icon={icons.REMOVE_ICON}
            onClick={(e) => props.removeAnexo(anexo)}
            className="p-button-danger"
          />
        </div>
      </div>
    ))
  }

  return (
    <Card title={utils.getMessage(props.messages, 'contrato.label.anexos')}>
      {isNaN(props.oidContrato) || props.oidContrato === '' ? (
        <Message severity="warn" text="Necessário salvar o contrato para adicionar anexos" />
      ) : (
        <React.Fragment>
          <div>
            <label className="label-btn-selecionar-anexo" htmlFor="selecao-arquivo">
              {utils.getMessage(props.messages, 'contrato.label.adicionar')}
            </label>
            <input id="selecao-arquivo" type="file" onChange={props.onSelectAnexo} accept="UTF-8" />
          </div>
          <br />
          {getContent()}
          {props.adicionandoAnexo ? (
            <div className="progress-spinner">
              <ProgressSpinner
                style={{ width: '50px', height: '50px' }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          ) : null}
        </React.Fragment>
      )}
    </Card>
  )
}
