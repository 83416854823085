import React from 'react'
import { connect } from 'react-redux'
import { icons } from '../../../constants'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Page } from '../../../components/page/Page'
import { actions } from '../parametro.actions'
import utils from '../../../utils/index'
import { ParametroForm } from './ParametroForm'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import storage from '../../../services/stoage.service'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const currentCompany = storage.getCompany()
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(currentCompany)

  return {
    columns: state.crudParametroState.columns,
    messages: state.crudParametroState.messages,
    parametros: state.crudParametroState.currentRecord.parametros,
    records: state.crudParametroState.records,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (oidUnidade) => dispatch(actions.loadKeys(oidUnidade)),
    setRecords: (parametros) => dispatch(actions.setRecords(parametros)),
    deleteKey: (oidUnidade, key, executeAfterDelete) =>
      dispatch(actions.deleteKey(oidUnidade, key, executeAfterDelete)),
    updateKey: (oidUnidade, key, value) => dispatch(actions.updateKey(oidUnidade, key, value)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
  }
}

class ParametroFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      permissoes: utils.getPermissoesPorMenu(
        storage.getMenus(),
        storage.getOidCurrentMenu(),
        null,
        storage.getPortalCliente()
      ),
      unidade: this.props.unidades[0],
    }
    // actions.afterDelete = () => {
    //   let parametroAtualizado = Object.assign({}, this.state.parametro);
    //   this.props.insertItemConfiguracao(parametroAtualizado);
    //   this.setState({ parametroSelecionado: null, parametro: null, displayDialog: false });
    // };
  }

  componentDidMount() {
    logarAcesso().then()
    this.props.load(this.state.unidade.id)
  }

  onChangeUnit = (e) => {
    const target = e.target
    let valor = target.value
    this.setState({ unidade: valor })

    this.props.load(valor.id)
  }

  saveParameter = () => {
    let parametros = [...this.props.records]
    if (this.state.novoParametro) {
      parametros.push(this.state.parametro)
      this.props.setRecords(parametros)
      this.props.updateKey(this.state.unidade.id, this.state.parametro.id, this.state.parametro.value)
      this.setState({ parametroSelecionado: null, parametro: null, displayDialog: false })
    } else {
      parametros[this.findSelectedParameterIndex()] = this.state.parametro
      this.props.setRecords(parametros)
      this.props.updateKey(this.state.unidade.id, this.state.parametro.id, this.state.parametro.value)
      this.setState({ parametroSelecionado: null, parametro: null, displayDialog: false })
    }
  }

  delete = () => {
    if (this.state.parametroSelecionado && this.state.parametroSelecionado.id) {
      let selectedKey = this.state.parametroSelecionado.id
      this.props.deleteKey(this.state.unidade.id, selectedKey, false)
      let index = this.findSelectedParameterIndex()
      let parametrosRestantes = this.props.records.filter((val, i) => i !== index)
      this.props.setRecords(parametrosRestantes)
    }
    this.setState({
      parametroSelecionado: null,
      parametro: null,
      displayDialog: false,
    })
  }

  findSelectedParameterIndex = () => {
    return this.props.records.indexOf(this.state.parametroSelecionado)
  }

  newHandler() {
    this.setState({
      parametro: { id: '', value: '' },
      displayDialog: true,
      novoParametro: true,
    })
  }

  resolveBotaoNovo() {
    if ((this.state.permissoes && this.state.permissoes.inserir) || !this.state.permissoes) {
      return (
        <Button
          onClick={(e) => this.newHandler(this)}
          label={utils.getMessage(this.props.messages, 'label.novo')}
          className="p-button-success"
          icon={icons.PLUS_ICON}
          style={{ marginRight: '.25em' }}
          disabled={this.state.unidade === undefined}
        />
      )
    }
    return null
  }

  updateProperty(property, value) {
    let parametro = this.state.parametro
    parametro[property] = value
    this.setState({ parametro: parametro })
  }

  onSelectionChange = (e) => {
    this.setState({ parametroSelecionado: e.value })
  }

  onParametroSelecionado = (e) => {
    this.setState({
      displayDialog: true,
      parametro: Object.assign({}, e.data),
      parametroAnterior: Object.assign({}, e.data),
      novoParametro: false,
    })
  }

  buildDialog = () => {
    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button
          label={utils.getMessage(this.props.messages, 'label.remover')}
          icon="pi pi-times"
          onClick={this.delete}
        />
        <Button
          label={utils.getMessage(this.props.messages, 'label.salvar')}
          icon="pi pi-check"
          onClick={this.saveParameter}
        />
      </div>
    )
    return (
      <Dialog
        visible={this.state.displayDialog}
        width="300px"
        header="Parâmetros"
        modal={true}
        footer={dialogFooter}
        onHide={() => this.setState({ displayDialog: false })}
        contentStyle={{ 'max-height': '500px' }}
      >
        {this.state.parametro && (
          <div className="p-grid p-fluid">
            <div className="p-col-4" style={{ padding: '.75em' }}>
              <label htmlFor="id">Key</label>
            </div>
            <div className="p-col-8" style={{ padding: '.5em' }}>
              <InputText
                id="id"
                onChange={(e) => {
                  this.updateProperty('id', e.target.value)
                }}
                value={this.state.parametro.id}
              />
            </div>

            <div className="p-col-4" style={{ padding: '.75em' }}>
              <label htmlFor="value">Value</label>
            </div>
            <div className="p-col-8" style={{ padding: '.5em' }}>
              <InputTextarea
                id="value"
                rows={1}
                cols={30}
                style={{ 'max-height': '450px' }}
                autoResize={true}
                onChange={(e) => {
                  this.updateProperty('value', e.target.value)
                }}
                value={this.state.parametro.value}
              />
            </div>
          </div>
        )}
      </Dialog>
    )
  }

  buildHeader = () => {
    return (
      <div>
        <div className="p-grid p-justify-start p-align-center">
          <h4>{utils.getMessage(this.props.messages, 'parametrosunidade.label.parametrosunidade')}</h4>
        </div>
        <div className="p-toolbar-group-left">{this.resolveBotaoNovo()}</div>
      </div>
    )
  }
  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      return <Column {...col} key={col.field} showFilterMenu={false} sortField={col.columnSortField} />
    })
  }

  buildContent = () => {
    return (
      <div>
        {this.buildDialog()}
        <ParametroForm
          {...this.props}
          onSelectionChange={this.onSelectionChange}
          onParametroSelecionado={this.onParametroSelecionado}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
          unidade={this.state.unidade}
          onChangeUnit={this.onChangeUnit}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParametroFormPageContainer)
