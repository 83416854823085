import React from 'react';

import OrcamentoFormPageContainer from './OrcamentoFormPageContainer';

export class OrcamentoFormPage extends React.Component{

    
    render(){
        return (
           <OrcamentoFormPageContainer {...this.props} />
        )
    }
}
