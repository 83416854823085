import React from 'react';
import GrupoListPageContainer from './GrupoListPageContainer';

export class GrupoListPage extends React.Component{
    
    render(){
      return (
        <GrupoListPageContainer {...this.props}/>
      )
    }
}

