import React from 'react'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions as perfilActions } from '../../perfil/perfil.actions'
import { actions as pessoaActions } from '../../pessoaFisica/pessoaFisica.actions'
import { actions as appActions } from '../../../store/app'
import { actions } from '../usuario.actions'
import UsuarioForm from './UsuarioForm'
import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import * as _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  let record = state.crudUsuarioState.currentRecord
  const messages = state.crudUsuarioState.messages

  const lookupPessoaBase = {
    records: state.appState.lookupPessoa.records,
    columns: state.appState.lookupPessoa.columns
      .filter((col) => col.field === 'numeroDocumento' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.appState.lookupPessoa.index,
    rows: state.appState.lookupPessoa.max,
    totalRecords: state.appState.lookupPessoa.count,
    sortField: state.appState.lookupPessoa.sortField,
    order: state.appState.lookupPessoa.sortOrder,
    editDisabled: false,
  }
  let lookupPessoa = Object.assign({}, state.crudUsuarioState.lookupPessoa, lookupPessoaBase) //FIXME - REVER ESSA CONFIG

  // let disableUnit = false
  // let currentUnit = storage.getUnit()
  // if (currentUnit) {
  //   let unidade = {
  //     id: currentUnit.oid,
  //     value: currentUnit.sigla,
  //   }
  //   Object.assign(record, { unidade: unidade })
  //   disableUnit = true
  // }

  //adicionando a unidade nula, pois caso não tenha unidade, o usuário deve ver todas as unidades
  let unidades = [{ id: '', value: 'Selecione' }]
  const unidadesEmpresa = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())
  unidades = unidades.concat(unidadesEmpresa)

  const perfisDisponiveis = state.crudPerfilState.records
  //   .filter(
  //   (perfil) => perfil.oid !== OID_PERFIL_PORTAL_CLIENTE && perfil.oid !== OID_PERFIL_PORTAL_CONVENIADO
  // )

  const unidadesAtuais = _.get(record, 'unidades', [])
  const unidadesDisponiveis = unidadesEmpresa.filter(
    (unidade) => !unidadesAtuais.find((undAtual) => undAtual.id === unidade.id)
  )

  const perfis = perfisDisponiveis.filter((perfil) => {
    let listIdPerfis = state.crudUsuarioState.currentRecord.perfis.map((perfilAcao) => perfilAcao.oid)
    return !listIdPerfis.includes(perfil.oid)
  })

  return {
    record,
    lookupPessoa,
    perfis,
    messages,
    unidades,
    // disableUnit,
    unidadesDisponiveis,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // ----- metodos para lookup de pessoas -----
    lookupPessoaSetVisible: (visible) => dispatch(actions.setLookupPessoaVisible(visible)),
    lookupPessoaPaginate: (paginationParams) => dispatch(appActions.lookupPessoaPaginate(paginationParams)),
    lookupPessoaSortOrder: (sortParams) => dispatch(appActions.lookupPessoaSortOrder(sortParams)),
    lookupPessoaApplyFilter: (filterParams) => {
      const payload = { field: filterParams.campo, value: filterParams.valor, relacionamento: null }
      dispatch(appActions.lookupPessoaSetSelectedFilters(payload))
      dispatch(appActions.lookupPessoaApplyFilter(filterParams))
    },
    lookupPessoaLoad: () => dispatch(appActions.lookupPessoaLoad()),
    // -------------------------------------------

    setPessoa: (pessoa) => {
      dispatch(actions.setField('oidPessoa', _.get(pessoa, 'oid', null)))
      dispatch(actions.setField('pessoa', _.get(pessoa, 'nome', '')))
    },

    //pickListPerfis
    pickListPerfisLoad: () => dispatch(perfilActions.load(true)),
    pickListPerfisMoveToSource: (perfisAcaoRemoved) => dispatch(actions.pickListPerfisMoveToSource(perfisAcaoRemoved)),
    pickListPerfisMoveAllToSource: () => dispatch(actions.pickListPerfisMoveAllToSource()),
    pickListPerfisMoveToTarget: (perfisAdd, oidAcao) =>
      dispatch(actions.pickListPerfisMoveToTarget(perfisAdd, oidAcao)),
    pickListPerfisMoveAllToTarget: (allPerfis, oidAcao) =>
      dispatch(actions.pickListPerfisMoveAllToTarget(allPerfis, oidAcao)),

    //pickListUnidades
    //pickListUnidadesLoad: () => dispatch(perfilActions.load(true)),
    pickListUnidadesMoveToSource: (data) => dispatch(actions.pickListUnidadesMoveToSource(data)),
    pickListUnidadesMoveAllToSource: () => dispatch(actions.pickListUnidadesMoveAllToSource()),
    pickListUnidadesMoveToTarget: (perfisAdd, oidAcao) =>
      dispatch(actions.pickListUnidadesMoveToTarget(perfisAdd, oidAcao)),
    pickListUnidadesMoveAllToTarget: (allPerfis, oidAcao) =>
      dispatch(actions.pickListUnidadesMoveAllToTarget(allPerfis, oidAcao)),

    setField: (field, value) => dispatch(actions.setField(field, value)),

    changeHandler: (event) => {
      const booleanFields = ['ativo']
      const target = event.target
      const field = target.name
      let value = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (booleanFields.find((current) => current === field)) {
        value = target.checked
      }
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.setField('tipoUsuario', 'EMPRESA'))
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    clearForm: () => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    loadPerfis: () => dispatch(perfilActions.load(true)),
    clearSelectedFilters: () => dispatch(pessoaActions.clearSelectedFilters()),
    setUnidade: (unidade) => dispatch(actions.setUnidade(unidade)),
  }
}

class UsuarioFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_USUARIO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_USUARIO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_USUARIO}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.clearForm()
    }
    this.props.loadPerfis()
    if (this.props.unidadeCorrente) {
      this.props.setUnidade(this.props.unidadeCorrente)
    }
  }

  pickListPerfisMoveToSource = (perfisAcaoRemoved) => {
    this.props.pickListPerfisMoveToSource(perfisAcaoRemoved)
  }

  pickListPerfisMoveAllToSource = () => {
    this.props.pickListPerfisMoveAllToSource()
  }

  pickListPerfisMoveToTarget = (perfisAdd, oidAcao) => {
    this.props.pickListPerfisMoveToTarget(perfisAdd, oidAcao)
  }

  pickListPerfisMoveAllToTarget = (allPerfis, oidAcao) => {
    this.props.pickListPerfisMoveAllToTarget(allPerfis, oidAcao)
  }

  pickListUnidadesMoveToSource = (data) => {
    this.props.pickListUnidadesMoveToSource(data)
  }

  pickListUnidadesMoveAllToSource = () => {
    this.props.pickListUnidadesMoveAllToSource()
  }

  pickListUnidadesMoveToTarget = (unidadesAdd, oidAcao) => {
    this.props.pickListUnidadesMoveToTarget(unidadesAdd, oidAcao)
  }

  pickListUnidadesMoveAllToTarget = (allUnidades, oidAcao) => {
    this.props.pickListUnidadesMoveAllToTarget(allUnidades, oidAcao)
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_USUARIO}`)
  }

  lookupPessoaOnComplete = (e) => {}

  lookupPessoaOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      const filter = {
        campo: 'nome',
        valor: e.value,
        tipoFiltro: 'CONTEM',
      }

      this.props.setField('pessoa', e.value)
      this.props.setField('oidPessoa', null)
      this.props.lookupPessoaApplyFilter(filter)
    } else {
      this.props.setPessoa(value)
    }
  }

  lookupPessoaOnPage = (e) => {
    this.props.lookupPessoaPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupPessoaOnSort = (e) => {
    this.props.lookupPessoaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupPessoaOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupPessoaApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupPessoaLoad()
    }
  }
  lookupPessoaOnClick = (e) => {
    this.props.lookupPessoaLoad()
    this.props.lookupPessoaSetVisible(true)
  }
  lookupPessoaOnHide = (e) => {
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnConfirm = (e) => {
    this.props.setPessoa(e.selection)
    this.props.lookupPessoaSetVisible(false)
  }
  lookupPessoaOnCancel = (e) => {
    this.props.setPessoa(null)
    this.props.lookupPessoaSetVisible(false)
  }

  lookupPessoaOnEdit = () => {
    if (this.props.record.oidPessoa) {
      return `${routes.PATH_PESSOA_FISICA}/${this.props.record.oidPessoa}`
    } else {
      return `${routes.PATH_PESSOA_FISICA}/`
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <UsuarioForm
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        perfis={this.props.perfis}
        record={this.props.record}
        // disableUnit={this.props.disableUnit}
        history={this.props.history}
        lookupPessoa={this.props.lookupPessoa}
        lookupPessoaOnHide={this.lookupPessoaOnHide}
        lookupPessoaOnConfirm={this.lookupPessoaOnConfirm}
        lookupPessoaOnCancel={this.lookupPessoaOnCancel}
        lookupPessoaOnPage={this.lookupPessoaOnPage}
        lookupPessoaOnSort={this.lookupPessoaOnSort}
        lookupPessoaOnFilter={this.lookupPessoaOnFilter}
        lookupPessoaOnClick={this.lookupPessoaOnClick}
        lookupPessoaOnEdit={this.lookupPessoaOnEdit}
        lookupPessoaOnComplete={this.lookupPessoaOnComplete}
        lookupPessoaOnInputFilter={this.lookupPessoaOnInputFilter}
        pickListPerfisMoveToSource={this.pickListPerfisMoveToSource}
        pickListPerfisMoveAllToSource={this.pickListPerfisMoveAllToSource}
        pickListPerfisMoveToTarget={this.pickListPerfisMoveToTarget}
        pickListPerfisMoveAllToTarget={this.pickListPerfisMoveAllToTarget}
        pickListUnidadesMoveToSource={this.pickListUnidadesMoveToSource}
        pickListUnidadesMoveAllToSource={this.pickListUnidadesMoveAllToSource}
        pickListUnidadesMoveToTarget={this.pickListUnidadesMoveToTarget}
        pickListUnidadesMoveAllToTarget={this.pickListUnidadesMoveAllToTarget}
        unidades={this.props.unidades}
        unidadesDisponiveis={this.props.unidadesDisponiveis}
      />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFormPageContainer)
