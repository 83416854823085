import { types } from "./cep.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  currentRecord: {
    oidLogradouro: "",
    nomeLogradouro: "",
    cep: "",
    bairro: "",
    cidade: "",
    uf: ""
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDCepReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    default:
      return result;
  }
}