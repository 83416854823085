import { types } from './consultaBaixa.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import utils from '../../utils/index'

const initialState = {
  id: 'consultaBaixa',
  booleanFilter: { status: '' },
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Sequencial',
      value: {
        field: 'sequencial',
        header: 'Sequencial',
        columnKey: 'sequencial',
        sortable: true,
        filter: true,
        columnSortField: 'sequencial',
        visible: true,
      },
    },
    {
      label: 'Arquivo',
      value: {
        field: 'nome',
        header: 'Arquivo',
        columnKey: 'nome',
        sortable: true,
        filter: true,
        columnSortField: 'nome',
        visible: true,
      },
    },
    {
      label: 'Tipo',
      value: {
        field: 'tipoRemessa',
        header: 'Tipo',
        columnKey: 'tipoRemessa',
        sortable: true,
        filter: true,
        columnSortField: 'tipoRemessa',
        visible: true,
      },
    },
    {
      label: 'Data Processamento',
      value: {
        field: 'dataProcessamento',
        header: 'Data Processamento',
        columnKey: 'dataProcessamento',
        sortable: true,
        filter: true,
        columnSortField: 'dataProcessamento',
        visible: true,
        fieldFormatter: utils.formatarDataHora,
      },
    },
    {
      label: 'Usuário',
      value: {
        field: 'usuario_login',
        header: 'Usuário',
        columnKey: 'usuario',
        sortable: true,
        filter: true,
        columnSortField: 'usuario.login',
        relacionamento: ['usuario'],
        fieldJoin: 'login',
        visible: true,
      },
    },
    {
      label: 'Total',
      value: {
        field: 'valor',
        header: 'Total',
        columnKey: 'valor',
        sortable: true,
        filter: true,
        columnSortField: 'valor',
        visible: true,
        fieldFormatter: utils.formatarDinheiro,
      },
    },
    {
      label: 'Status',
      value: {
        field: 'status',
        header: 'Status',
        columnKey: 'status',
        sortable: true,
        filter: true,
        columnSortField: 'status',
        visible: true,
      },
    },
  ],
  colunasItens: [
    {
      field: 'oidContrato',
      header: 'Contrato',
      columnKey: 'oidContrato',
      sortable: true,
      filter: true,
      columnSortField: 'oidContrato',
      visible: true,
    },
    {
      field: 'oidLancamento',
      header: 'Lançamento',
      columnKey: 'oidLancamento',
      sortable: true,
      filter: true,
      columnSortField: 'oidLancamento',
      visible: true,
    },
    {
      field: 'numeroRPS',
      header: 'Núm. RPS',
      columnKey: 'numeroRPS',
      sortable: true,
      filter: true,
      columnSortField: 'numeroRPS',
      visible: true,
    },
    {
      field: 'numeroNFSe',
      header: 'Núm. NFSe',
      columnKey: 'numeroNFSe',
      sortable: true,
      filter: true,
      columnSortField: 'numeroNFSe',
      visible: true,
    },
    {
      field: 'oidNFSe',
      header: '',
      columnKey: 'oidNFSe',
      sortable: false,
      filter: false,
      visible: false,
    },
    {
      field: 'pagador',
      header: 'Pagador',
      columnKey: 'pagador',
      sortable: true,
      filter: true,
      columnSortField: 'pagador',
      visible: true,
    },
    {
      field: 'mensagem',
      header: 'Mensagem',
      columnKey: 'mensagem',
      sortable: true,
      filter: true,
      columnSortField: 'mensagem',
      visible: true,
    },
    {
      field: 'valor',
      header: 'Valor',
      columnKey: 'valor',
      sortable: true,
      filter: true,
      columnSortField: 'valor',
      visible: true,
      fieldFormatter: utils.formatarDinheiro,
    },
    {
      field: 'status',
      header: 'Status',
      columnKey: 'status',
      sortable: true,
      filter: true,
      columnSortField: 'status',
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    nome: '',
    valor: '',
    itens: [],
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDConsultaBaixaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('consultabaixa')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.UNMOUNT:
      return unmount(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function unmount(state, action) {
  const currentRecord = initialState.currentRecord
  return Object.assign({}, state, { currentRecord })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
