import { types } from './menu.actions.js';

const initialState = {
    items: []
}
export function menuReducer(state = initialState, action) {
    if (action.type === types.MENU_SET_ITEMS) {
        return Object.assign({}, state, { items: action.items || [] })
    }
    if (action.type === types.MENU_CLEAR_ITEMS) {
        return Object.assign({}, state, { items: [] })
    }
    if (action.type === types.MENU_SET_CUSTOM_ROUTES) {
        return Object.assign({}, state, { items: [] })
    }    
    return state;
}