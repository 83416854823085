import React from 'react';
import TipoSepulturaListPageContainer from './TipoSepulturaListPageContainer';

export class TipoSepulturaListPage extends React.Component{
    
    render(){
      return (
        <TipoSepulturaListPageContainer {...this.props}/>
      )
    }
}

