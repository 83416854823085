import React, {useEffect} from 'react'

import _ from 'lodash'
import './ContratoForm.scss'
import '../../../index.scss'

import utils from '../../../utils'
import {InputText} from 'primereact/inputtext'
import {Checkbox} from 'primereact/checkbox'
import {Calendar} from 'primereact/calendar'
import LtsDropdownSimNao from '../../../components/lottus/lts-dropdown-sim-nao'

import {Button} from 'primereact/button'
import {icons} from '../../../constants/index'
import {Card} from 'primereact/card'

export default function ContratoFalecidoTanatopraxia(props) {
  useEffect(() => {
  }, [props.tanatopraxia])

  const setField = (field, value) => {
    const tanatopraxia = _.get(props, 'tanatopraxia', {})
    tanatopraxia[field] = value
    props.setField('tanatopraxia', tanatopraxia)
    if (field != 'removido') {
      setField('removido', false)
    }
  }

  const remover = () => {
    clearFieldsTanatopraxia()
    setField('removido', true)
  }

  const clearFieldsTanatopraxia = () => {
    setField('oid', null)
    setField('necropsiado', null)
    setField('iml', null)
    setField('svo', null)
    setField('dataInicialNecropsia', null)
    setField('dataFinalNecropsia', null)
    setField('troncoBranquiocefalico', null)
    setField('aortaAbdominal', null)
    setField('caroticaComumD', null)
    setField('caroticaComumE', null)
    setField('iliacaComumD', null)
    setField('iliacaComumE', null)
    setField('subclaviaD', null)
    setField('subclaviaE', null)
    setField('femoralD', null)
    setField('femoralE', null)
    setField('branquialD', null)
    setField('branquialE', null)
    setField('outras', null)
    setField('tanatofluidoArterial', null)
    setField('volumeTotal', null)
    setField('aspiracaoToracoAbdominal', null)
    setField('evisceracaoEmbalagem', null)
    setField('tanatofluidoCavidade', null)
    setField('restauracao', null)
    setField('local', null)
    setField('evisceracaoEmbalagemComp', null)
    setField('localComp', null)
  }

  return (
    <React.Fragment>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <Button
              onClick={(e) => remover()}
              label={'Limpar'}
              icon={icons.PI_BAN}
              style={{marginRight: '.25em'}}
              className="p-button-warning, width-10-rem"
            />
            <br/>
            <br/>

            <Card title={'Necropsia'}>
              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <LtsDropdownSimNao
                      disabled={props.disableUnit}
                      value={props.tanatopraxia.necropsiado}
                      onChange={(e) => setField('necropsiado', e)}
                      required={true}
                      name="necropsiado"
                      label="Necropsiado"
                      className={'width-15-rem'}
                    />
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <label htmlFor="chkIml">IML</label>
                  <Checkbox
                    inputId="chkIml"
                    name="chkIml"
                    checked={props.tanatopraxia.iml}
                    onChange={(e) => setField('iml', e.checked)}
                    style={{marginLeft: '13px'}}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <label htmlFor="chkSvo">SVO</label>
                  <Checkbox
                    inputId="chkSvo"
                    name="chkSvo"
                    checked={props.tanatopraxia.svo}
                    onChange={(e) => setField('svo', e.checked)}
                    style={{marginLeft: '10px'}}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Calendar
                      onChange={(e) => setField('dataInicialNecropsia', e.value)}
                      yearNavigator={true}
                      monthNavigator={true}
                      value={utils.getDateTimeFromNumber(props.tanatopraxia.dataInicialNecropsia)}
                      yearRange={utils.getYearRangeFromDate('1900')}
                      name="dataInicialNecropsia"
                      showTime={true}
                      className={'width-15-rem'}
                    />
                    <label>Data/Hora inicial necropsia</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <Calendar
                      onChange={(e) => setField('dataFinalNecropsia', e.value)}
                      yearNavigator={true}
                      monthNavigator={true}
                      value={utils.getDateTimeFromNumber(props.tanatopraxia.dataFinalNecropsia)}
                      yearRange={utils.getYearRangeFromDate('1900')}
                      name="dataFinalNecropsia"
                      showTime
                      className={'width-15-rem'}
                    />
                    <label>Data/Hora final necropsia</label>
                  </span>
                </div>
              </div>
            </Card>

            <br/>
            <Card title={'Pontos de injeção arterial'}>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-3 p-md-3 p-lg-2 p-xl-3">
                  <label htmlFor="chkTrocoBraquiocefalico">Tronco braquiocefálico</label>
                  <Checkbox
                    inputId="chkTrocoBraquiocefalico"
                    name="chkTrocoBraquiocefalico"
                    onChange={(e) => setField('troncoBranquiocefalico', e.checked)}
                    checked={props.tanatopraxia.troncoBranquiocefalico}
                    style={{marginLeft: '19px'}}
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-3 p-md-3 p-lg-2 p-xl-3">
                  <label htmlFor="chkAortaAbdnominal">Aorta abdominal</label>
                  <Checkbox
                    inputId="chkAortaAbdnominal"
                    name="chkAortaAbdnominal"
                    onChange={(e) => setField('aortaAbdominal', e.checked)}
                    checked={props.tanatopraxia.aortaAbdominal}
                    style={{marginLeft: '59px'}}
                  />
                </div>
              </div>

              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div className="p-grid form-group p-align-end">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <label>Carótica comum</label>
                      <label style={{marginLeft: '61px'}}>D</label>
                      <Checkbox
                        inputId="chkCaroticaComumD"
                        onChange={(e) => setField('caroticaComumD', e.checked)}
                        name="caroticaComumD"
                        checked={props.tanatopraxia.caroticaComumD}
                        style={{marginLeft: '10px'}}
                      />
                      <label style={{marginLeft: '10px'}}>E</label>
                      <Checkbox
                        inputId="chkCaroticaComumE"
                        onChange={(e) => setField('caroticaComumE', e.checked)}
                        name="caroticaComumE"
                        checked={props.tanatopraxia.caroticaComumE}
                        style={{marginLeft: '10px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div className="p-grid form-group p-align-end">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <label>Ilíaca comum</label>
                      <label style={{marginLeft: '80px'}}>D</label>
                      <Checkbox
                        inputId="chkIliacaComumD"
                        onChange={(e) => setField('iliacaComumD', e.checked)}
                        checked={props.tanatopraxia.iliacaComumD}
                        name="iliacaComumD"
                        style={{marginLeft: '10px'}}
                      />

                      <label style={{marginLeft: '10px'}}>E</label>
                      <Checkbox
                        inputId="chkIliacaComumE"
                        onChange={(e) => setField('iliacaComumE', e.checked)}
                        checked={props.tanatopraxia.iliacaComumE}
                        name="iliacaComumE"
                        style={{marginLeft: '10px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div className="p-grid form-group p-align-end">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <label>Subclávia</label>

                      <label style={{marginLeft: '104px'}}>D</label>
                      <Checkbox
                        inputId="chksublaviaD"
                        onChange={(e) => setField('subclaviaD', e.checked)}
                        name="sublaviaD"
                        checked={props.tanatopraxia.subclaviaD}
                        style={{marginLeft: '10px'}}
                      />

                      <label style={{marginLeft: '10px'}}>E</label>
                      <Checkbox
                        inputId="chksublaviaE"
                        onChange={(e) => setField('subclaviaE', e.checked)}
                        name="sublaviaE"
                        checked={props.tanatopraxia.subclaviaE}
                        style={{marginLeft: '10px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div className="p-grid form-group p-align-end">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <label>Femoral</label>

                      <label style={{marginLeft: '113px'}}>D</label>
                      <Checkbox
                        inputId="chkfemoralD"
                        onChange={(e) => setField('femoralD', e.checked)}
                        name="femoralD"
                        checked={props.tanatopraxia.femoralD}
                        style={{marginLeft: '10px'}}
                      />

                      <label style={{marginLeft: '10px'}}>E</label>
                      <Checkbox
                        inputId="chkfemoralE"
                        onChange={(e) => setField('femoralE', e.checked)}
                        name="femoralE"
                        checked={props.tanatopraxia.femoralE}
                        style={{marginLeft: '10px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div className="p-grid form-group p-align-end">
                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                      <label>Braquial</label>
                      <label style={{marginLeft: '113px'}}>D</label>
                      <Checkbox
                        inputId="chkbraquialD"
                        onChange={(e) => setField('branquialD', e.checked)}
                        name="braquialD"
                        checked={props.tanatopraxia.branquialD}
                        style={{marginLeft: '10px'}}
                      />
                      <label style={{marginLeft: '10px'}}>E</label>
                      <Checkbox
                        inputId="chkbraquialE"
                        onChange={(e) => setField('branquialE', e.checked)}
                        name="braquialE"
                        checked={props.tanatopraxia.branquialE}
                        style={{marginLeft: '10px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      value={props.tanatopraxia.outras}
                      onChange={(e) => setField('outras', e.target.value)}
                      name="outras"
                    />
                    <label>Outras</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setField('tanatofluidoArterial', e.target.value)}
                      name="tanatofluidoArterial"
                      value={props.tanatopraxia.tanatofluidoArterial}
                    />
                    <label>Tanatofluido arterial</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setField('volumeTotal', e.target.value)}
                      name="volumeTotal"
                      value={props.tanatopraxia.volumeTotal}
                    />
                    <label>Volume total</label>
                  </span>
                </div>
              </div>
            </Card>

            <br/>
            <Card title={'Tratamento das cavidades'}>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <LtsDropdownSimNao
                    className={'width-22-rem'}
                    disabled={props.disableUnit}
                    onChange={(e) => setField('aspiracaoToracoAbdominal', e)}
                    required={true}
                    value={props.tanatopraxia.aspiracaoToracoAbdominal}
                    name="aspiracaoToracoAbdominal"
                    label="Aspiração tóraco-abdominal"
                  />
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <LtsDropdownSimNao
                    className={'width-22-rem'}
                    disabled={props.disableUnit}
                    onChange={(e) => setField('evisceracaoEmbalagem', e)}
                    required={true}
                    value={props.tanatopraxia.evisceracaoEmbalagem}
                    name="evisceracaoEmbalagem"
                    label="Evisceração e embalagem"
                  />
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setField('tanatofluidoCavidade', e.target.value)}
                      name="tanatofluidoCavidade"
                      value={props.tanatopraxia.tanatofluidoCavidade}
                    />
                    <label>Tanatofluido de cavidade</label>
                  </span>
                </div>
              </div>
            </Card>

            <br/>
            <Card title={'Informações complementares'}>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-3">
                  <LtsDropdownSimNao
                    className={'width-12-rem'}
                    disabled={props.disableUnit}
                    onChange={(e) => setField('restauracao', e)}
                    required={true}
                    value={props.tanatopraxia.restauracao}
                    name="restauracao"
                    label="Restauração"
                  />
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setField('local', e.target.value)}
                      name="localRestauracao"
                      value={props.tanatopraxia.local}
                    />
                    <label>Local</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <LtsDropdownSimNao
                    className={'width-12-rem'}
                    disabled={props.disableUnit}
                    onChange={(e) => setField('evisceracaoEmbalagemComp', e)}
                    required={true}
                    value={props.tanatopraxia.evisceracaoEmbalagemComp}
                    name="eviscerEmbalag"
                    label="Eviscer./embalag."
                  />
                </div>
              </div>

              <div className="p-grid form-group p-align-end">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3">
                  <span className="p-float-label">
                    <InputText
                      onChange={(e) => setField('localComp', e.target.value)}
                      name="localEviscerEmbalag"
                      value={props.tanatopraxia.localComp}
                    />
                    <label>Local</label>
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
