import React from 'react'
import { Column } from 'primereact/column'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { icons, routes } from '../../../constants'
import utils from '../../../utils/index'
import { actions as categoriaActions } from '../../categoria/categoria.actions'
import { actions as religiaoActions } from '../../religiao/religiao.actions'
import { actions as sexoActions } from '../../sexo/sexo.actions'
import { actions as pessoaActions } from '../../pessoa/pessoa.actions'
import { actions } from '../pessoaFisica.actions'
import { PessoaFisicaForm } from './PessoaFisicaForm'
import { Filtro } from '../../../utils/Filtro.js'
import _ from 'lodash'
import storage from '../../../services/stoage.service'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudPessoaFisicaState.messages
  const record = state.crudPessoaFisicaState.currentRecord
  const lookupReligiaoBase = {
    records: state.crudReligiaoState.records,
    columns: state.crudReligiaoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'nome')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudReligiaoState.index,
    rows: state.crudReligiaoState.max,
    totalRecords: state.crudReligiaoState.count,
    sortField: state.crudReligiaoState.sortField,
    order: state.crudReligiaoState.sortOrder,
    editDisabled: false,
  }
  let sexoSelecionado = { name: record.descricaoSexo, oidSexo: record.oidSexo }
  let lookupReligiao = Object.assign({}, state.crudPessoaFisicaState.lookupReligiao, lookupReligiaoBase)
  let sexos = []
  if (utils.isArrayNotEmpty(state.crudSexoState.records)) {
    state.crudSexoState.records.forEach((sexo) => {
      sexos.push({ name: sexo.descricao, oidSexo: sexo.oid })
    })
  }
  const currentCompany = storage.getCompany()
  const currentUnit = storage.getUnit()
  const isUsarWebWhatsapp = utils.isUsarWebWhatsapp(currentCompany, currentUnit)
  const isUsarMensageria = utils.isUsarMensageria(currentCompany, currentUnit)
  return {
    record,
    lookupReligiao,
    sexoSelecionado,
    sexos,
    messages,
    opcoesCategorias: state.crudCategoriaState.records,
    isSaving: state.crudCategoriaState.isSaving,
    isUsarWebWhatsapp,
    isUsarMensageria,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    categoriaApplyFilter: (filterParams) => {
      dispatch(categoriaActions.setType(null))
      dispatch(categoriaActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(categoriaActions.applyFilter(filterParams))
    },
    categoriasLoad: () => dispatch(categoriaActions.load(true)),

    lookupReligiaoSetVisible: (visible) => dispatch(actions.setLookupReligiaoVisible(visible)),
    lookupReligiaoPaginate: (paginationParams) => dispatch(religiaoActions.loadLookup(paginationParams)),
    lookupReligiaoSortOrder: (sortParams) => dispatch(religiaoActions.loadLookup(sortParams)),
    lookupReligiaoApplyFilter: (filterParams) => {
      dispatch(religiaoActions.setType(null))
      dispatch(religiaoActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(religiaoActions.applyFilter(filterParams))
    },
    lookupReligiaoLoad: () => dispatch(religiaoActions.loadLookup()),
    setReligiao: (religiao) => {
      dispatch(actions.setField('oidReligiao', religiao != null ? religiao.oid : null))
      dispatch(actions.setField('nomeReligiao', religiao != null ? religiao.nome : ''))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    carregarSexos: () => dispatch(sexoActions.load(true)),

    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (field === 'sexo') {
        dispatch(actions.setField('oidSexo', valor.oidSexo))
        dispatch(actions.setField('descricaoSexo', valor.name))
      } else if (field === 'ativo') {
        dispatch(actions.setField('ativo', target.checked))
      } else if (field === 'pet') {
        dispatch(actions.setField('pet', target.checked))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },

    cepOnChange: (event) => {
      const field = event.target.name
      let valor = event.target.value
      if (valor.length === 8) {
        dispatch(actions.setField(field, valor))
        dispatch(actions.setField('nomeLogradouro', ''))
        dispatch(actions.setField('numeroLogradouro', ''))
        dispatch(actions.setField('complemento', ''))
        dispatch(actions.setField('bairro', ''))
        dispatch(actions.setField('cidade', ''))
        dispatch(actions.setField('oidLogradouro', ''))
        dispatch(actions.setField('uf', ''))
        dispatch(actions.findByCEP(valor))
      }
    },
    inputMaskOnChange: (event) => {
      const field = event.target.name
      let valor = event.target.value
      if ((field === 'numeroPis' || field === 'numeroDocumento') && valor.length === 11) {
        dispatch(actions.setField(field, valor))
        if (
          field === 'numeroDocumento' &&
          (event.originalEvent.keyCode === 13 || event.originalEvent.key === 'Enter')
        ) {
          dispatch(actions.loadByCpf(valor))
        }
      } else if ((field === 'telefone' || field === 'segundoTelefone') && utils.validatePhone(valor)) {
        dispatch(actions.setField(field, valor))
      } else if (valor.length === 0) {
        dispatch(actions.setField(field, ''))
      }
    },
    pesquisarPessoa: (valor) => {
      dispatch(actions.loadByCpf(valor))
    },
    saveHandler: (event) => dispatch(actions.save()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
    enviarWhatsApp: (telefone) => dispatch(actions.enviarWhatsApp(telefone)),
    abrirWebWhatsApp: ({ oidPessoa, telefone }) => dispatch(pessoaActions.abrirWebWhatsApp({ oidPessoa, telefone })),
    enviarEmail: () => dispatch(actions.enviarEmail()),
    formatarData: (record) => {
      let dataNascimentoFormatada = new Date(record.dataNascimento)
      Object.assign(record, { dataNascimento: dataNascimentoFormatada })
    },
    clearSelectedFilters: () => dispatch(religiaoActions.clearSelectedFilters()),
  }
}

class PessoaFisicaFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      showDialogRemove: false,
    }
    actions.carregarMensageria = (mensagem) => {
      props.history.push('/geral/cadastros/mensageria', { mensagem })
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PESSOA_FISICA}/${oid}`)
    }
    actions.afterNew = () => {
      if (this.props.history) {
        this.props.history.push(`${routes.PATH_PESSOA_FISICA}/novo`)
      }
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PESSOA_FISICA}`)
    }
    actions.afterLoad = () => {
      let record = this.props.record
      if (record.dataNascimento && utils.isNumber(record.dataNascimento)) {
        record.dataNascimento = utils.getUTCDate(record.dataNascimento)
      }
      if (record.dataFalecimento && utils.isNumber(record.dataFalecimento)) {
        record.dataFalecimento = utils.getDateTimeFromNumber(record.dataFalecimento)
      }
      this.setState({ atualizar: true })
    }

    actions.beforeFetchRecordSuccess = (data) => {
      if (data) {
        //backend envia data como 'long'
        if (data.dataNascimento) {
          data.dataNascimento = utils.getUTCDate(data.dataNascimento)
        }
        if (data.dataFalecimento) {
          data.dataFalecimento = utils.getDateTimeFromNumber(data.dataFalecimento)
        }
      }
      return data
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    this.props.carregarSexos()
    this.props.categoriasLoad()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }

    if (this.props.isPet) {
      this.props.setField('pet', this.props.isPet)
    }
  }

  componentDidUpdate(anteriorProps) {
    // Devido a data vir em milisegundos, realizada a formatação
    let atualDataNasc = this.props.record.dataNascimento
    if (anteriorProps.record.dataNascimento === '' && atualDataNasc && atualDataNasc > 0) {
      //    this.props.formatarData(this.props.record);
    }
    if (anteriorProps.record.oidSexo === '' && this.props.record.sexo) {
      Object.assign(this.props.record, { oidSexo: this.props.record.sexo.oid })
      Object.assign(this.props.record, { descricaoSexo: this.props.record.sexo.descricao })
    }
  }
  searchHandler = () => {
    this.props.newHandler()
    this.props.history.push(`${routes.PATH_PESSOA_FISICA}`)
  }

  cepOnChange = (event) => {
    this.props.cepOnChange(event)
  }

  lookupReligiaoOnPage = (e) => {
    this.props.lookupReligiaoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupReligiaoOnSort = (e) => {
    this.props.lookupReligiaoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupReligiaoOnFilter = (e) => {
    let isFilterApplied = false
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      const value = e.filters[key].value
      if (value) {
        let filter = {
          campo: key,
          valor: value,
        }
        this.props.lookupReligiaoApplyFilter(filter)
        isFilterApplied = true
      }
    })
    this.props.lookupReligiaoLoad()
    if (!isFilterApplied) {
      this.props.clearSelectedFilters()
      this.props.lookupReligiaoLoad()
    }
  }

  lookupReligiaoOnClick = (e) => {
    this.props.lookupReligiaoLoad()
    this.props.lookupReligiaoSetVisible(true)
  }
  lookupReligiaoOnHide = (e) => {
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnConfirm = (e) => {
    this.props.setReligiao(e.selection)
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnCancel = (e) => {
    this.props.setReligiao(null)
    this.props.lookupReligiaoSetVisible(false)
  }
  lookupReligiaoOnEdit = () => {
    if (this.props.record.oidReligiao) {
      return `${routes.PATH_RELIGIAO}/${this.props.record.oidReligiao}`
    } else {
      return `${routes.PATH_RELIGIAO}`
    }
  }

  lookupReligiaoOnComplete = (e) => {}

  lookupReligiaoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.nomeReligiao)) {
      const filtro = new Filtro('nome', this.props.record.nomeReligiao, 'CONTEM')
      this.props.lookupReligiaoApplyFilter(filtro)
    }
  }

  lookupReligiaoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('nomeReligiao', e.value)
      this.props.setField('oidReligiao', null)
    } else {
      this.props.setReligiao(value)
    }
  }

  changeActiveIndex = (index) => {
    this.setState({ activeIndex: index })
  }

  categoriaOnConfirm = (e) => {
    let categorias = []
    if (e.value) {
      e.value.forEach((categoria) => {
        categorias.push({
          id: categoria.oid ? categoria.oid : categoria.oidCategoria,
          value: categoria.descricao ? categoria.descricao : categoria.value,
        })
      })
    }
    this.props.setField('categorias', categorias)
  }

  categoriaOnInputFilter = (e) => {
    const value = e.query
    if (typeof value === 'string') {
      const filters = {
        campo: 'descricao',
        valor: value,
      }
      this.props.categoriaApplyFilter(filters)
    } else {
      this.props.setCategoria(value)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    if (utils.nonNull(this.props.renderToolbar) && !this.props.renderToolbar) {
      // não deve mostrar a toolbar caso esteja sendo adicionado de um contrato
    } else {
      return <DefaultFormToolBar formPageContainer={this} />
    }
  }

  setCatagorias = (data) => {
    const values = []
    data.forEach((object) => {
      values.push({
        id: _.get(object, 'oid', ''),
        value: _.get(object, 'descricao', ''),
      })
    })
    this.props.setField('categorias', values)
  }

  getBotoesPersonalizados = () => {
    const record = _.get(this, 'props.record', {})
    const botoes = []
    if (parseInt(record.oid) > 0) {
      const botao = {
        type: 'button',
        label: 'Clonar',
        className: 'p-button-sucess',
        onClick: (e) => this.clonarDados(),
        icon: icons.COPY_ICON,
      }
      botoes.push(botao)
    }
    return botoes
  }

  clonarDados = () => {
    const props = _.get(this, 'props', {})
    props.history.push(`${routes.PATH_PESSOA_FISICA}/novo`)
    props.setField('oid', null)
    props.setField('nome', '')
    props.setField('rg', '')
    props.setField('descricaoSexo', '')
    props.setField('oidSexo', '')
    props.setField('dataNascimento', '')
    props.setField('dataFalecimento', '')
    props.setField('nomeFantasia', '')
    props.setField('numeroDocumento', '')
    props.setField('numeroDocumento', '')
    props.setField('numeroDocumentoRaiz', '')
    props.setField('inscricaoEstadual', '')
    props.setField('inscricaoEstadual', '')
    props.setField('nomeMae', '')
    props.setField('nomePai', '')
    props.setField('foto', '')
    props.setField('plainSenha', '')
    props.setField('telefone', '')
    props.setField('segundoTelefone', '')
    props.setField('email', '')
    props.setField('site', '')
    props.setField('site', '')
    props.setField('contato', '')
    props.setField('categorias', [])
  }

  enviarWhatsApp = (telefone) => {
    if (this.props.isUsarWebWhatsapp) {
      const oidPessoa = _.get(this, 'props.record.oid', null)
      if (oidPessoa) {
        this.props.abrirWebWhatsApp({ oidPessoa, telefone })
      }
    } else {
      this.props.enviarWhatsApp(telefone)
    }
  }

  enviarEmail = () => {
    if (this.props.isUsarMensageria) {
      this.props.enviarEmail()
    } else {
      const email = _.get(this, 'props.record.email', null)
      window.location = 'mailto:' + email
    }
  }

  render() {
    const content = (
      <PessoaFisicaForm
        state={this.state}
        renderTabView={this.props.renderTabView}
        changeActiveIndex={this.changeActiveIndex}
        messages={this.props.messages}
        changeHandler={this.props.changeHandler}
        onComplete={this.props.onComplete}
        record={this.props.record}
        sexos={this.props.sexos}
        sexoSelecionado={this.props.sexoSelecionado}
        cepOnChange={this.cepOnChange}
        enviarWhatsApp={this.enviarWhatsApp}
        enviarEmail={this.enviarEmail}
        inputMaskOnChange={this.props.inputMaskOnChange}
        pesquisarPessoa={this.props.pesquisarPessoa}
        opcoesCategorias={this.props.opcoesCategorias}
        categoriaOnConfirm={this.categoriaOnConfirm}
        categoriaOnInputFilter={this.categoriaOnInputFilter}
        // Lookup de Religiãos
        history={this.props.history}
        lookupReligiao={this.props.lookupReligiao}
        lookupReligiaoOnKeyPress={this.lookupReligiaoOnKeyPress}
        lookupReligiaoOnHide={this.lookupReligiaoOnHide}
        lookupReligiaoOnConfirm={this.lookupReligiaoOnConfirm}
        lookupReligiaoOnCancel={this.lookupReligiaoOnCancel}
        lookupReligiaoOnPage={this.lookupReligiaoOnPage}
        lookupReligiaoOnSort={this.lookupReligiaoOnSort}
        lookupReligiaoOnFilter={this.lookupReligiaoOnFilter}
        lookupReligiaoOnClick={this.lookupReligiaoOnClick}
        lookupReligiaoOnEdit={this.lookupReligiaoOnEdit}
        lookupReligiaoOnComplete={this.lookupReligiaoOnComplete}
        lookupReligiaoOnInputFilter={this.lookupReligiaoOnInputFilter}
        setCatagorias={this.setCatagorias}
        additionalFields={this.props.additionalFields}
        isPet={this.props.isPet}
        setField={this.props.setField}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaFisicaFormPageContainer)
