import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import utils from '../../../utils/index'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { actions } from '../consultaRemessa.actions'
import { ConsultaRemessaList } from './ConsultaRemessaList'
import {Button} from "primereact/button";

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudConsultaRemessaState.showHideColumns,
    messages: state.crudConsultaRemessaState.messages,
    columns: state.crudConsultaRemessaState.columns,
    records: state.crudConsultaRemessaState.records,
    first: state.crudConsultaRemessaState.index,
    rows: state.crudConsultaRemessaState.max,
    totalRecords: state.crudConsultaRemessaState.count,
    sortField: state.crudConsultaRemessaState.sortField,
    order: state.crudConsultaRemessaState.sortOrder,
    selectedFilters: state.crudConsultaRemessaState.selectedFilters,
    booleanFilter: state.crudConsultaRemessaState.booleanFilter,
    selectedRecords: state.crudConsultaRemessaState.selectedRecords,
    booleanTypesStatus: [
      { label: 'TODOS', value: '' },
      { label: 'SUCESSO', value: 1 },
      { label: 'ERRO', value: 2 },
      { label: 'PENDENTE', value: 3 },
    ],
    booleanTypesTipo: [
      { label: 'TODOS', value: '' },
      { label: 'Boleto', value: 1 },
      { label: 'Vindi - Boleto', value: 2 },
      { label: 'Vindi - Cartão Crédito', value: 3 },
      { label: 'Celesc', value: 4 },
      { label: 'Débito Automático', value: 5 },
      { label: 'Cancelamento débito automático', value: 6 },
      { label: 'Cergral', value: 7 },
      { label: 'Boleto Bradesco', value: 8 },
    ],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name

      if (['status', 'tipo'].find((field) => field === target.name)) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }

      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/remessasfinanceiras/all`))
      }, 400)
    },
    setSelectedRecords: (e) => {
      dispatch(actions.setSelectedRecords(e))
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    salvarState: (state, filters) => {
      dispatch(actions.saveState(state, filters))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    exportarDadosCSV: () => {
      dispatch(actions.exportCSV())
    },
    downloadArquivos: (oidsRemessas) => {
      dispatch(actions.downloadArquivos(oidsRemessas))
    }
  }
}

class ConsultaRemessaListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.visaoRef = React.createRef()
    this.showHideColumns = []
    this.showDialogVisao = false
    this.showCSVButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
    }
    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    listPageUtils.onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    listPageUtils.onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    listPageUtils.onCsvExport(this.props.records, this, actions, excel)
  }

  salvarState = (dados, state) => {
    let arrayColumns = this.showHideColumns.map((col) => {
      return col.value
    })
    arrayColumns.forEach((col) => {
      col.relationships = col.relacionamento
      delete col.relacionamento
    })
    state['columns'] = arrayColumns
    state['menu'] = { id: 1, value: this.props.history.location.pathname }
    this.props.salvarState(state, dados.filters)
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    listPageUtils.componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    listPageUtils.onPage(this, e)
  }

  onSort = (e) => {
    listPageUtils.onSort(this, e)
  }

  onSelectionChange = (e) => {
    this.props.setSelectedRecords(e.value)
  }

  verificarHabilitadoBaixar = () => {
    const remessasSelecionadas = this.props.selectedRecords
    return !(utils.nonNull(remessasSelecionadas) && utils.isArrayNotEmpty(remessasSelecionadas))
  }


  getCustomButtons = () => {
    return (
      <div>
        {/*<div className="p-toolbar-group-left">*/}
        <Button
          disabled={this.verificarHabilitadoBaixar()}
          onClick={(e) => {
            const remessasSelecionadas = this.props.selectedRecords
            if (utils.nonNull(remessasSelecionadas) && utils.isArrayNotEmpty(remessasSelecionadas)) {
              const oidsRemessas = remessasSelecionadas.map(r => r.oid)
              this.props.downloadArquivos(oidsRemessas)
            }
          }}
          label={"Download"}
          style={{marginRight: '.25em'}}
          className="p-button-success"
        />
      </div>
    )
  }

  buildHeader = () => {
    return (
      <DefaultToolBar
        listPageContainer={this}
        title={utils.getMessage(this.props.messages, 'consultaremessa.label.consultaRemessas')}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  oidTemplate = (rowData) => {
    return <Link to={`${routes.PATH_CONSULTA_REMESSA}/${rowData.oid}`}>{rowData.oid}</Link>
  }

  booleanFilterStatus = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypesStatus}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  typeFilterTipo = (column) => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.booleanFilter[column.field]}
        options={this.props.booleanTypesTipo}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  booleanFieldsTemplate = (rowData, column) => {
    const result = rowData[column.field]
    if (result === 'SUCESSO') {
      return (
        <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />{' '}
        </div>
      )
    }
    if (result === 'ERRO') {
      return (
        <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
        </div>
      )
    }
    if (result === 'PENDENTE') {
      return (
        <div style={{ color: 'grey', display: 'flex', justifyContent: 'center' }}>
          <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} />
        </div>
      )
    }
    return null
  }

  tipoFieldsTemplate = (rowData, column) => {
    const mapping = {
      'BOLETO': 'Boleto',
      'BOLETO_BRADESCO': 'Boleto Bradesco',
      'VINDI_BOLETO': 'Vindi - Boleto',
      'VINDI_CARTAO_CREDITO': 'Vindi - Cartão Crédito',
      'CELESC': 'Celesc',
      'CERGRAL': 'Cergral',
      'DEBITO_AUTOMATICO': 'Débito Automático',
      'CANCELAMENTO_DEBITO_AUTOMATICO': 'Cancelamento débito automático',
      'A_DEFINIR': 'A Definir'
    };
    return mapping[rowData[column.field]] || null;
}


  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      if (col.field === 'multiselect') {
        return <Column selectionMode="multiple" style={{ width: '3em' }} />
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oidTemplate}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (col.fieldFormatter) {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
          />
        )
      } else if (['status'].find((field) => field === col.field)) {
        return (
          <Column
            filterHeaderStyle={{ overflow: 'visible' }}
            {...col}
            key={col.field}
            body={this.booleanFieldsTemplate}
            sortField={col.columnSortField}
            filterElement={this.booleanFilterStatus(col)}
          />
        )
      } else if (['tipo'].find((field) => field === col.field)) {
        return (
          <Column
            filterHeaderStyle={{ overflow: 'visible' }}
            {...col}
            key={col.field}
            body={this.tipoFieldsTemplate}
            sortField={col.columnSortField}
            filterElement={this.typeFilterTipo(col)}
          />
        )
      }
      return <Column {...col} key={col.field} sortField={col.columnSortField} filterElement={this.fieldFilter(col)} />
    })
  }
  buildContent = () => {
    return (
      <ConsultaRemessaList
        {...this.props}
        onSelectionChange={this.onSelectionChange}
        dataTableRef={(el) => (this.dataTableRef = el)}
        onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={this.defineColumns()}
      />
    )
  }
  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaRemessaListPageContainer)
