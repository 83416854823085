import { types } from './mensageriaLog.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'

const initialState = {
  id: 'mensageriaLog',
  booleanFilter: { status: '' },
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true,
      },
    },
    {
      label: 'Data',
      value: {
        field: 'dataEnvio',
        header: 'Data',
        columnKey: 'dataEnvio',
        sortable: true,
        filter: true,
        columnSortField: 'dataEnvio',
        visible: true,
        fieldFormatter: 'formatarData',
      },
    },
    {
      label: 'Assunto',
      value: {
        field: 'assunto',
        header: 'Assunto',
        columnKey: 'assunto',
        sortable: true,
        filter: true,
        columnSortField: 'assunto',
        visible: true,
      },
    },
    {
      label: 'Mensagem',
      value: {
        field: 'mensagem',
        header: 'Mensagem',
        columnKey: 'mensagem',
        sortable: true,
        filter: true,
        columnSortField: 'mensagem',
        visible: true,
      },
    },
    {
      label: 'Usuário',
      value: {
        field: 'usuario_login',
        header: 'Usuário',
        columnKey: 'usuario',
        sortable: true,
        filter: true,
        columnSortField: 'usuario.login',
        relacionamento: ['usuario'],
        fieldJoin: 'login',
        visible: true,
      },
    },
    {
      label: 'Forma de Envio',
      value: {
        field: 'tipoMensagem',
        header: 'Forma de Envio',
        columnKey: 'tipoMensagem',
        sortable: true,
        filter: true,
        columnSortField: 'tipoMensagem',
        visible: true,
      },
    },
  ],
  colunasItens: [
    {
      field: 'oidDestinatario',
      header: 'Cód. Pessoa',
      columnKey: 'oidDestinatario',
      sortable: true,
      filter: true,
      columnSortField: 'oidDestinatario',
      visible: true,
    },
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      sortable: true,
      filter: true,
      columnSortField: 'nome',
      visible: true,
    },
    {
      field: 'contato',
      header: 'Contato',
      columnKey: 'contato',
      sortable: true,
      filter: true,
      columnSortField: 'contato',
      visible: true,
    },
    {
      field: 'oidContrato',
      header: 'Contrato',
      columnKey: 'oidContrato',
      sortable: true,
      filter: true,
      columnSortField: 'oidContrato',
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    usuario: '',
    dataEnvio: new Date(),
    assunto: '',
    mensagem: '',
    destinatarios: [],
    anexos: [],
  },
  messages: null,
  selectedFilters: [],
  usaListDto: false,
}

const reducer = new Reducer(initialState, types)

export function CRUDMensageriaLogReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('mensageria')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    case types.RESET:
      return reset(state, action)
    default:
      return state
  }
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter)
  booleanFilter[action.field] = action.value
  return Object.assign({}, state, { booleanFilter })
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}
