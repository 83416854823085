import { types } from "./perfil.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from '../../services/stoage.service';

const initialState = {
  id:"perfil",
  resourceForGetAll: "perfisDisponivelUsuario",
  max: 100,
  booleanFilter: {
    indicadorPersonalizado: '',
    administrador: '',
    criaUsuariosWebService: '',
    alteraUnidade: ''
  },
  selectedFilters: [],
  showHideColumns: [
    {
      label: "Código",
      value: {
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        columnSortField: 'oid',
        visible: true
      }
    },
    {
      label: "Nome",
      value: {
        field: "nomePerfil",
        header: "Nome",
        columnKey: "nomePerfil",
        sortable: true,
        columnSortField: 'nomePerfil',
        filter: true,
        visible: true,
      }
    },
    {
      label: "Administrador",
      value: {
        field: "administrador",
        header: "Administrador",
        columnKey: "administrador",
        sortable: true,
        columnSortField: 'administrador',
        filter: true,
        visible: true,
      }
    }, {
      label: "Altera unidade",
      value: {
        field: "alteraUnidade",
        header: "Altera unidade",
        columnKey: "alteraUnidade",
        sortable: true,
        columnSortField: 'alteraUnidade',
        filter: true,
        visible: true,
      }
    },
     {
      label: "Cria usuários webservice",
      value: {
        field: "criaUsuariosWebService",
        header: "Cria usuários webservice",
        columnKey: "criaUsuariosWebService",
        sortable: true,
        columnSortField: 'criaUsuariosWebService',
        filter: true,
        visible: true,
      }
    },
    {
      label: "Indicador personalizado",
      value: {
        field: "indicadorPersonalizado",
        header: "Indicador personalizado",
        columnKey: "indicadorPersonalizado",
        sortable: true,
        columnSortField: 'indicadorPersonalizado',
        filter: true,
        visible: true,
      }
    },
  ],
  columns: [],
  columnsLookup:[
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
      columnSortField: 'oid',
      visible: true
    },
    {
      field: "nomePerfil",
      header: "Nome",
      columnKey: "nomePerfil",
      sortable: true,
      columnSortField: 'nomePerfil',
      filter: true,
      visible: true
    }
  ],
  currentRecord: {
    oid: "",
    nomePerfil: "",
    caminhoDashboard: "",
    indicadorPersonalizado: false,
    administrador: false,
    criaUsuariosWebService: false,
    alteraUnidade: false
  },

  messages: null
};

const reducer = new Reducer(initialState, types);

export function crudPerfilReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('perfil');
  }
  let newState = reducer.execute(state, action);
  return execute(newState, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action);
    case types.RESET:
      return reset(state, action);
    default:
      return state
  }

}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}

function reset(state, action) {
  return {...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [], booleanFilter: initialState.booleanFilter}
}
