import api from './api.service'
import storage from './stoage.service'
import jwt from 'jsonwebtoken'

const isAuthenticated = () => {
  const token = storage.getToken()
  if (!token) {
    return false
  }
  try {
    const decoded = jwt.verify(token, 'secret')
    return decoded && decoded.exp
  } catch (err) {
    return false
  }
}

const login = (credential) => {
  return api.post('/lottustech/login', credential).then((response) => {
    const { data } = response
    storage.setToken(data.token)
    storage.setUser(data.login)
    storage.setCompany(data.empresa)
    storage.setAppLabels(data.appLabels)
    storage.setAlteraUnidade(data.alteraUnidade)
    storage.setVersaoSistema(data.versaoSistema)
    storage.setMenuLayout(data.menuLayout)
    return Promise.resolve(response)
  })
}

const loginPortalCliente = (credential) => {
  return api.post('/lottustech/portalcliente/login', credential).then((response) => {
    const { data } = response
    storage.setToken(data.token)
    storage.setUser(data.login)
    storage.setCompany(data.empresa)
    storage.setAppLabels(data.appLabels)
    storage.setAlteraUnidade(false)
    storage.setVersaoSistema(data.versaoSistema)
    return Promise.resolve(response)
  })
}

const esquecisenha = (login) => {
  return api.post(`/lottustech/public/esquecisenha`, login).then((response) => {
    return Promise.resolve(response)
  })
}
const criarConta = (conta) => {
  return api.post(`/lottustech/public/criarconta`, conta).then((response) => {
    return Promise.resolve(response)
  })
}

const redefinirSenha = (dados) => {
  return api.post(`/lottustech/public/redefinirsenha`, dados).then((response) => {
    return Promise.resolve(response)
  })
}

const logout = () => {
  return api.post('/lottustech/logout').then(() => {})
}

const getDefaultCompany = () => {
  return storage.getCompany()
}

const getUserName = () => {
  return storage.getUser()
}

export default {
  isAuthenticated,
  login,
  loginPortalCliente,
  logout,
  getDefaultCompany,
  getUserName,
  criarConta,
  esquecisenha,
  redefinirSenha,
}
