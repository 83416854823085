import React from 'react'
import { connect } from 'react-redux'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Page } from '../../../components/page/Page'
import utils from '../../../utils/index'
import { actions } from '../localizacao.actions'
import { LocalizacaoGridList } from './LocalizacaoGridList'
import storage from '../../../services/stoage.service'
import * as listPageUtils from '../../../utils/listPageContainerUtils'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import _ from 'lodash'

const mapStateToProps = (state) => {
  const record = state.crudLocalizacaoGridState.currentRecord
  const unidade = state.crudLocalizacaoGridState.unidade
  const unidadeCorrente = storage.getUnit()
  let messages = state.crudLocalizacaoGridState.messages
  const matriz = state.crudLocalizacaoGridState.matriz

  let disableUnit = false
  if (unidadeCorrente) {
    disableUnit = true
  }

  // LISTA DE UNIDADES DISPONÍVEIS NO DROPDOWN
  let unidades = []
  let unidadesStorage = storage.getUnits()
  if (unidadesStorage.length > 0) {
    unidadesStorage.forEach((unidade) => {
      unidades.push({ name: unidade.sigla, oidUnidade: unidade.oid })
    })
  }

  // REFERENTE AO DROPDOWN DE TIPOS DE SEPULTURA
  let tiposSepultura = []
  if (state.crudTipoSepulturaState.records.length > 0) {
    state.crudTipoSepulturaState.records.forEach((tipoSepultura) => {
      tiposSepultura.push({
        value: tipoSepultura.descricao,
        id: tipoSepultura.oid,
        object: tipoSepultura,
      })
    })
  }
  const currentCompany = storage.getCompany()
  return {
    currentCompany,
    disableUnit,
    unidades,
    messages,
    record,
    tiposSepultura,
    columns: state.crudLocalizacaoGridState.columns,
    records: state.crudLocalizacaoGridState.records,
    totalRecords: state.crudLocalizacaoGridState.count,
    selectedFilters: state.crudLocalizacaoGridState.selectedFilters,
    unidadeCorrente,
    unidade,
    matriz,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => dispatch(actions.applyFilter(filterParams)),
    changeFilter: (column, e) => {
      const target = e.target
      let campo = column.fieldJoin ? column.fieldJoin : target.name
      // tratamentos para nomes dos filtros boolean
      if ('disponivel' === target.name) {
        dispatch(actions.setBooleanFilter(target.name, target.value))
      }
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/localizacoes/all`))
      }, 400)
    },
    setSelectedRecords: (e) => dispatch(actions.setSelectedRecords(e)),
    carregarTiposSepultura: (oidUnidade) => dispatch(actions.loadTiposSepulturas(oidUnidade)),
    changeHandler: (event) => {
      const target = event.target
      const field = target.name
      let valor = target.value
      if (field === 'unidade') {
        const unidade = { id: valor.oidUnidade, value: valor.name }
        dispatch(actions.setUnidade(unidade))
      } else if (field === 'tipoSepultura') {
        dispatch(actions.setField(field, valor))
        dispatch(actions.clearSelectedFilters())
        dispatch(actions.getOpcoesPesquisa())
      } else if (field.indexOf('filtroLocalizacao__') > -1) {
        dispatch(actions.setFilterLocalizacaoGrid(field, valor))
        dispatch(actions.resolvePesquisaLocalizacaoGrid())
      }
    },
    clearSelectedFilters: () => dispatch(actions.clearSelectedFilters()),
    setUnidade: (unidade) => dispatch(actions.setUnidade(unidade)),
    willUnmount: () => dispatch(actions.willUnmount()),
    loadFavorito: (menu) => dispatch(actions.loadFavorito(menu)),
    removeFavorito: (menu) => dispatch(actions.removeFavorito(menu)),
    adicionarFavorito: (menu) => dispatch(actions.adicionarFavorito(menu)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    getOpcoesPesquisa: () => dispatch(actions.getOpcoesPesquisa()),
    executeFilterLocalizacaoGrid: (utilizaFiltros = true) =>
      dispatch(actions.executeFilterLocalizacaoGrid(utilizaFiltros)),
  }
}

class LocalizacaoGridListPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.showHideColumns = []
    this.state = {
      showSpinner: false,
      dadosFavorito: null,
      favorito: 0,
    }

    if (props.unidadeCorrente && utils.isIdValueNullOrEmpty(props.unidade)) {
      const unidade = { id: props.unidadeCorrente.oid, value: props.unidadeCorrente.sigla }
      props.setUnidade(unidade)
    }

    actions.afterFilterLocalizacaoGrid = () => {
      this.setState({ showSpinner: true })
    }
    actions.beforeFilterLocalizacaoGrid = () => {
      this.setState({ showSpinner: false })
    }
    this.messages = props.messages
  }

  loadFavorito() {
    listPageUtils.loadFavorito(this, actions)
  }

  changeFavorito() {
    listPageUtils.changeFavorito(this, actions)
  }

  componentDidMount() {
    listPageUtils.componentDidMount(this)
    if (this.props.unidadeMemorial) {
      this.props.setUnidade(this.props.unidadeMemorial)
    }
  }

  componentWillUnmount() {
    this.props.willUnmount()
  }

  componentDidUpdate(anteriorProps) {
    if (
      !anteriorProps.unidade ||
      (anteriorProps.unidade.id !== this.props.unidade.id && parseInt(this.props.unidade.id))
    ) {
      this.props.carregarTiposSepultura(this.props.unidade.id)
    } else if (anteriorProps.unidadeMemorial === null && this.props.unidadeMemorial) {
      this.props.setUnidade(this.props.unidadeMemorial)
    } else if (utils.isArrayNotEmpty(this.props.tiposSepultura) && this.props.tiposSepultura.length === 1) {
      const value = this.props.tiposSepultura[0]
      const target = { name: 'tipoSepultura', value }
      if (!this.props.record.tipoSepultura || this.props.record.tipoSepultura.id !== value.id) {
        const event = { target }
        this.onChangeTipoSepulura(event)
      }
    }
  }

  getFiltrosPesquisa = () => {
    if (utils.isArrayNotEmpty(this.props.selectedFilters)) {
      return (
        <div className="p-col-8 p-sm-12 p-md-4 p-lg-3 p-xl-2">
          {this.props.selectedFilters.map((filtro) => (
            <div className="p-grid form-group p-align-start" style={{ marginTop: '8px' }}>
              <span className="p-float-label">
                <Dropdown
                  autoWidth={false}
                  value={filtro.valorSelecionado}
                  options={filtro.campoPesquisa.value}
                  onChange={this.props.changeHandler}
                  disabled={!this.props.unidade.id}
                  optionLabel="value"
                  optionValue={(v) => v}
                  dataKey="value"
                  name={'filtroLocalizacao__' + filtro.campoPesquisa.key}
                  inputId={'dpd_filtro_localizacao__' + filtro.campoPesquisa.key}
                />
                <label htmlFor={'dpd_filtro_localizacao__' + filtro.campoPesquisa.key}>{filtro.chaveDicionario}</label>
              </span>
            </div>
          ))}
        </div>
      )
    }
    return <div />
  }

  onChangeTipoSepulura = (event) => {
    const target = _.get(event, 'target', null)
    const field = _.get(target, 'name', null)
    const tipoSepultura = _.get(target, 'value', null)
    this.props.setField(field, tipoSepultura)
    this.props.clearSelectedFilters()

    const oidTipoSepultura = _.get(tipoSepultura, 'id', null)
    const oidUnidade = _.get(this, 'props.unidade.id', null)
    const empresa = _.get(this, 'props.currentCompany', null)

    const unidade = _.get(empresa, 'unidades', []).find((f) => f.oid === oidUnidade)
    const semFiltro = utils.isTipoSepulturaLocalizacaoGridSemFiltro(empresa, unidade, oidTipoSepultura)
    if (semFiltro) {
      this.props.executeFilterLocalizacaoGrid(!semFiltro)
    } else {
      this.props.getOpcoesPesquisa()
    }
  }

  buildHeader = () => {
    let unidade = {}
    if (this.props.unidade && this.props.unidade.id) {
      //Padronizar para idValue
      unidade = { name: this.props.unidade.value, oidUnidade: this.props.unidade.id }
    }
    return (
      <div>
        <DefaultToolBar
          listPageContainer={this}
          somenteTitulo={true}
          title={utils.getMessage(this.messages, 'localizacao.label.localizacoes')}
        />
        <div className="p-grid form-group" style={{ margin: '10px' }}>
          <div className="p-col-8 p-sm-12 p-md-4 p-lg-3 p-xl-3" style={{ marginTop: '3px' }}>
            <span className="p-float-label">
              <Dropdown
                autoWidth={false}
                disabled={this.props.disableUnit}
                value={unidade}
                options={this.props.unidades}
                onChange={this.props.changeHandler}
                optionLabel="name"
                optionValue={(v) => v}
                dataKey="name"
                name="unidade"
                inputId="dpd_unidade"
              />
              <label htmlFor="dpd_unidade">{utils.getMessage(this.messages, 'localizacao.label.unidade')}</label>
            </span>
          </div>
          <div className="p-col-9 p-sm-12 p-md-6 p-lg-4 p-xl-4" style={{ marginTop: '3px' }}>
            <span className="p-float-label">
              <Dropdown
                style={{ width: '40%' }}
                autoWidth={false}
                value={this.props.record.tipoSepultura}
                options={this.props.tiposSepultura}
                onChange={(event) => this.onChangeTipoSepulura(event)}
                disabled={!this.props.unidade.id}
                optionLabel="value"
                optionValue={(v) => v}
                dataKey="value"
                name="tipoSepultura"
                inputId="dpd_tipo_sepultura"
              />
              <label htmlFor="dpd_tipo_sepultura">
                {utils.getMessage(this.messages, 'localizacao.label.tiposepultura')}
              </label>
            </span>
          </div>

          {this.getFiltrosPesquisa()}
        </div>
      </div>
    )
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  buildContent = () => {
    let marginTop = '0px'
    if (this.props.marginTop !== undefined) {
      marginTop = this.props.marginTop
    }
    return (
      <div style={{ display: 'inline-block', position: 'relative', width: '100%', marginTop: marginTop }}>
        <LocalizacaoGridList {...this.props} showSpinner={this.state.showSpinner} />
      </div>
    )
  }

  render() {
    return <Page header={this.buildHeader()} content={this.buildContent()} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalizacaoGridListPageContainer)
