import React from "react";
import { InputText } from "primereact/inputtext";
import utils from "../../../utils/index";

import "./ItensLancamentoForm.scss";

export const ItensLancamentoForm = props => {
  const messages = props.messages;
  const record = props.record;
  const changeHandler = props.changeHandler;

  return <div>{getDadosPrincipais()}</div>;

  function getDadosPrincipais() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h3>
              {utils.getMessage(messages, "itenslancamentofinanceiro.label.item")} {record.oid || ""}
            </h3>

            <div className="p-grid form-group">
              <div className="p-col-4">
                <span className="md-inputfield">
                  <InputText value={record.descricao} maxLength={10} onChange={changeHandler} name="descricao" />
                  <label>{utils.getMessage(messages, "itenslancamentofinanceiro.label.descricao")}</label>
                </span>
              </div>
            </div>
            <div className="p-grid form-group">
              <div className="p-col-2">
                <span className="md-inputfield">
                  <InputText
                    keyfilter={/^[0-9.,]+$/}
                    value={getValorReais(record.valor)}
                    maxLength={10}
                    onBlur={props.onBlurValor}
                    onChange={changeHandler}
                    name="valor"
                  />
                  <label>{utils.getMessage(messages, "itenslancamentofinanceiro.label.valor")}</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getValorReais(valor) {
    if (valor && valor !== "") {
      return "R$ " + valor;
    }
    return "";
  }
};
