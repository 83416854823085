import React from 'react';

import PortalClienteConsultaClienteFormPageContainer from './PortalClienteConsultaClienteFormPageContainer';

export class PortalClienteConsultaClienteFormPage extends React.Component{

    
    render(){
        return (
           <PortalClienteConsultaClienteFormPageContainer {...this.props} />
        )
    }
}
