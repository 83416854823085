import React from 'react';

import ContratoFormPageContainer from './ContratoFormPageContainer';

export class ContratoFormPage extends React.Component{

    
    render(){
        return (
           <ContratoFormPageContainer {...this.props} />
        )
    }
}
