import React, { useEffect } from 'react'
import { Card } from 'primereact/card'
import _ from 'lodash'
import './ContratoForm.scss'
import '../../../index.scss'
import LtsInputText from '../../../components/lottus/lts-input-text'

export default function InformacoesCelesc(props) {
  useEffect(() => {}, [props.plano.formaPagamento])

  return (
    <React.Fragment>
      <Card subTitle={'Informações para Celesc'}>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <LtsInputText
              value={_.get(props, 'plano.unidadeConsumidora', '')}
              label={'Unidade consumidora'}
              onChange={(e) => props.setField('unidadeConsumidora', e)}
              style={{ minWidth: '300px', maxWidth: '300px' }}
            />
          </div>
        </div>
        <div className="p-grid form-group">
          <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <LtsInputText
              value={_.get(props, 'plano.unidadeConsumidoraAnterior', '')}
              label={'Unidade consumidora anterior'}
              onChange={(e) => props.setField('unidadeConsumidoraAnterior', e)}
              style={{ minWidth: '300px', maxWidth: '300px' }}
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}
