import { messageActions } from '../store/message/index'
import apiService from '../services/api.service'
import { getDefaultColumnsFor } from '../utils/listPageContainerUtils'
import utils, { isNumber } from '../utils'
import storage from '../services/stoage.service'
import { enums } from '../constants'
import * as _ from 'lodash'
import * as moment from 'moment'
import { actions } from '../pages/localizacao/localizacao.actions'
import history from "../history";

const makeMessages = (messages, type, actionsFactoryRef, oid, dispatch) => {
  let sticky = false
  // let summary = "Sucesso!";
  if (type === 'error') {
    // summary = "Erro!";
    sticky = true
  }

  let callbackParams = undefined
  if (actionsFactoryRef && oid && oid > 0 && dispatch) {
    callbackParams = {
      oid: oid,
      dispatch: dispatch,
      actionsFactoryRef: actionsFactoryRef,
    }
  }
  return messages.map((message) => {
    return { sticky: sticky, summary: '', severity: type, detail: message, callbackParams: callbackParams }
  })
}

export class Action {
  timer = null

  constructor(prefix, crud) {
    this.crud = crud
    this.prefix = prefix
    this.types = {
      FETCH_RECORDS: `${prefix}FetchRecords`,
      FETCH_RECORD: `${prefix}FetchRecord`,
      FETCH_RECORDS_SUCCESS: `${prefix}FetchRecordsSuccess`,
      FETCH_RECORD_SUCCESS: `${prefix}FetchRecordSuccess`,
      FETCH_RECORDS_ERROR: `${prefix}FetchRecordsError`,
      FETCH_RECORD_ERROR: `${prefix}FetchRecordError`,
      SET_BOTOES_DINAMICOS: `${prefix}.setBotoesDinamicos`,
      SET_RECORDS: `${prefix}.setRecords`,
      SET_RECORD: `${prefix}.setRecord`,
      SET_COLUMNS: `${prefix}.setColumns`,
      SET_INDEX: `${prefix}.setIndex`,
      SET_MAX: `${prefix}.setMax`,
      SET_COUNT: `${prefix}.setCount`,
      SET_COUNT_DISTINCT: `${prefix}.setCountDistinct`,
      HIDE_LOADER: `${prefix}.hideLoader`,
      SET_SORT_ORDER: `${prefix}.setSortOrder`,
      SET_SORT_FIELD: `${prefix}.setSortField`,
      SET_FILTERS: `${prefix}.setFilters`,
      SET_TYPE: `${prefix}.setType`,
      SET_FIELD: `${prefix}.setField`,
      FIND_BY_CEP: `${prefix}.findByCEP`,
      ADD_ERRORS: `${prefix}.addErrors`,
      REMOVE_ERRORS: `${prefix}.removeErrors`,
      CLEAR_ERRORS: `${prefix}.clearErrors`,
      CLEAR_SELECTED_RECORDS: `${prefix}.clearSelectedRecords`,
      MAKE_NEW: `${prefix}.new`,
      SET_SELECTED_RECORDS: `${prefix}.setSelectedRecords`,
      SET_SELECTED_FILTERS: `${prefix}setSelectedFilters`,
      SET_FILTRO_AVANCADO: `${prefix}setFiltroAvancado`,
      CLEAR_SELECTED_FILTERS: `${prefix}clearSelectedFilters`,
      EXECUTE_FILTERS: `${prefix}executeFilter`,
      FIND_TAGS: `${prefix}findTags`,
      FIND_CAUSAS_MORTIS: `${prefix}findCausasMortis`,
      LOAD_FAVORITO: `${prefix}loadFavorito`,
      REMOVE_FAVORITO: `${prefix}removeFavorito`,
      ADICIONAR_FAVORITO: `${prefix}adicionarFavorito`,
      SAVE_STATE: `${prefix}saveState`,
      SET_IS_SAVING: `${prefix}setIsSaving`,
      SET_RESOURCE_FOR_GET_ALL: `${prefix}.setResourceForGetAll`,
      SET_STATE_ATTRIBUTE: `${prefix}.setStateAttribute`,
    }
  }

  addErrors(errors = []) {
    return {
      type: this.types.ADD_ERRORS,
      errors,
    }
  }

  removeErrors(errors = []) {
    return {
      type: this.types.REMOVE_ERRORS,
      errors,
    }
  }

  clearErrors() {
    return {
      type: this.types.CLEAR_ERRORS,
    }
  }

  setIsSaving(payload) {
    return {
      type: this.types.SET_IS_SAVING,
      payload,
    }
  }

  clearSelectedRecords() {
    return {
      type: this.types.CLEAR_SELECTED_RECORDS,
    }
  }

  fetchRecords() {
    return {
      type: this.types.FETCH_RECORDS,
      async: true,
    }
  }

  fetchRecord() {
    return {
      type: this.types.FETCH_RECORD,
      async: true,
    }
  }

  setBotoesDinamicos(records) {
    return {
      type: this.types.SET_BOTOES_DINAMICOS,
      records,
    }
  }

  setRecords(records) {
    return {
      type: this.types.SET_RECORDS,
      records,
    }
  }

  setColumns(columns) {
    return {
      type: this.types.SET_COLUMNS,
      columns,
    }
  }

  setSelectedRecords(records) {
    return {
      type: this.types.SET_SELECTED_RECORDS,
      records,
    }
  }

  setIndex(index) {
    return {
      type: this.types.SET_INDEX,
      index,
    }
  }

  setMax(max) {
    return {
      type: this.types.SET_MAX,
      max,
    }
  }

  setCount(count) {
    return {
      type: this.types.SET_COUNT,
      count,
    }
  }

  setCountDistinct(payload) {
    return {
      type: this.types.SET_COUNT_DISTINCT,
      payload,
    }
  }

  setResourceForGetAll(payload) {
    return {
      type: this.types.SET_RESOURCE_FOR_GET_ALL,
      payload,
    }
  }

  setStateAttribute(payload) {
    return {
      type: this.types.SET_STATE_ATTRIBUTE,
      payload,
    }
  }

  setSortOrder(sortOrder) {
    return {
      type: this.types.SET_SORT_ORDER,
      sortOrder,
    }
  }

  setSortField(sortField) {
    return {
      type: this.types.SET_SORT_FIELD,
      sortField,
    }
  }

  hideLoader() {
    return {
      type: this.types.HIDE_LOADER,
      async: false,
    }
  }

  filter(filters) {
    return {
      type: this.types.SET_FILTERS,
      filters,
    }
  }

  setType(filterType) {
    return {
      type: this.types.SET_TYPE,
      filterType,
    }
  }

  setRecord(record) {
    return {
      type: this.types.SET_RECORD,
      record,
    }
  }

  setField(field, value) {
    return {
      type: this.types.SET_FIELD,
      field,
      value,
    }
  }

  setSelectedFilters(field, value, relacionamento, chaveComponente) {
    return {
      type: this.types.SET_SELECTED_FILTERS,
      field,
      value,
      relacionamento,
      chaveComponente
    }
  }

  clearSelectedFilters() {
    return {
      type: this.types.CLEAR_SELECTED_FILTERS,
    }
  }

  setFiltroAvancado(field, value) {
    return {
      type: this.types.SET_FILTRO_AVANCADO,
      field,
      value,
    }
  }

  fetchRecordsSuccess(result) {
    const records = _.get(result, 'records', [])
    const index = _.get(result, 'index', 0)
    const size = _.get(result, 'size', 10)
    const count = _.get(result, 'count', 0)
    const countDistinct = _.get(result, 'countDistinct', 0)
    return (dispatch) => {
      setTimeout(() => {
        dispatch(this.setRecords(records))
        dispatch(this.setIndex(index))
        dispatch(this.setMax(size === 0 ? 10 : size))
        dispatch(this.setCount(count))
        dispatch(this.setCountDistinct(countDistinct))
        dispatch(this.hideLoader())
      }, 100)
    }
  }

  fetchRecordSuccess(result) {
    return (dispatch) => {
      dispatch(this.setRecord(result))
      dispatch(this.hideLoader())
    }
  }

  fetchRecordsError(err) {
    return (dispatch) => {
      dispatch(this.hideLoader())
    }
  }

  loadRecord(oid, dispatch) {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    this.crud
      .getByOid(oid)
      .then(({ data }) => {
        // caso precise fazer algum tratamento no objeto recebido antes
        // de setar no 'record' da Store.
        // Por exemplo, formatar algum campo.
        if (this.beforeFetchRecordSuccess) {
          data = this.beforeFetchRecordSuccess(data)
        }
        dispatch(this.fetchRecordSuccess(data))
        if (this.afterLoad) {
          this.afterLoad(data)
        }
      })
      .catch((err) => {
        const messages = ['Não foi possível carregar os dados do registro selecionado']
        dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
        dispatch(this.hideLoader())
      })
  }

  clearCurrentRecord() {
    return (dispatch, getState) => {
      dispatch(this.setRecord({}))
    }
  }

  loadRecords(params, dispatch) {
    this.crud
      .getAll(params)
      .then(({ data }) => {
        dispatch(this.fetchRecordsSuccess(data))
      })
      .catch((err) => {
        dispatch(this.fetchRecordsError(err))
      })
  }

  load(isSubcadastro = false) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      this.setDefaultColumns(state)
      if (isSubcadastro) {
        this.executeLoad(dispatch, state, getState)
      } else {
        if (this.isCustomRoute(getState)) {
          this.loadStateData(dispatch, state, getState)
        } else {
          this.executeLoad(dispatch, state, getState)
        }
      }
    }
  }

  isCustomRoute(getState) {
    const appState = getState()['appState']
    const oidCurrentMenu = storage.getOidCurrentMenu()
    const customRoutes = appState.appCustomRoutes
    if (customRoutes && customRoutes.length > 0) {
      const customRoute = customRoutes.find((route) => {
        return route.oidMenu === oidCurrentMenu
      })

      return !!customRoute
    }
    return false
  }

  loadColumns(currentColumns) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      Object.assign(state, { columns: currentColumns })
      this.executeLoad(dispatch, state, getState)
    }
  }

  setDefaultColumns(state) {
    const defaultColumns = getDefaultColumnsFor(state.id)
    Object.assign(state, { columns: defaultColumns })
  }

  executeLoad(dispatch, state, getState) {
    let sortFields = []
    if (state.sortField) {
      sortFields = [
        {
          fieldName: state.sortField,
          order: state.sortOrder > -1 ? `ASC` : `DESC`,
        },
      ]
    }
    const stateColumns = state.columns || []
    let columns = []
    if (state.usaListDto) {
      columns = stateColumns.filter((col) => col.visible).map((col) => col.columnKey)
    } else {
      // columns = stateColumns.filter(col => col.visible).map(col => col.field.replace("_", "."));
      columns = state.showHideColumns.filter((col) => col.value.visible).map((col) => col.value.field.replace('_', '.'))
      // let otherColumns = stateColumns.filter(col => col.visible).map(col => col.columnKey);
      // columns = columns.concat(otherColumns);
    }
    if (columns.findIndex((c) => c === 'oid') < 0) {
      columns.push('oid')
    }

    let filters = utils.parseTipoFiltro(state.selectedFilters)
    filters = filters.filter(f => !(Array.isArray(f.valor) && f.valor.length === 0))

    const params = {
      index: state.index,
      max: state.max,
      filters: filters,
      projectionFields: columns,
      sortFields,
      oidMenu: storage.getOidCurrentMenu(),
    }
    if (state.resourceForGetAll && state.resourceForGetAll.length > 0) {
      params.resourceForGetAll = state.resourceForGetAll
    }
    this.loadRecords(params, dispatch)
  }

  exportCSV(excel = true) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      let sortFields = []
      if (state.sortField) {
        sortFields = [
          {
            fieldName: state.sortField,
            order: state.sortOrder > -1 ? `ASC` : `DESC`,
          },
        ]
      }
      const stateColumns = state.columns || []
      let columns = []
      if (state.usaListDto) {
        columns = stateColumns.filter((col) => col.visible).map((col) => col.columnKey)
      } else {
        columns = stateColumns.filter((col) => col.visible).map((col) => col.field.replace('_', '.'))
      }
      const params = {
        index: 0,
        max: 100000000,
        filters: utils.parseTipoFiltro(state.selectedFilters),
        projectionFields: columns,
        sortFields,
        oidMenu: storage.getOidCurrentMenu(),
      }
      this.crud
        .exportCSV(params)
        .then(({ data }) => {
          if (this.afterGetExportDataset) {
            this.afterGetExportDataset(data, state.usaListDto)
          }
          // if (this.afterExportCSV && !excel) {
          //   this.afterExportCSV(data, state.usaListDto);
          // } else if (this.afterExportExcel && excel) {
          //   this.afterExportExcel(data, state.usaListDto);
          // }
        })
        .catch((err) => {
          dispatch(this.fetchRecordsError(err))
        })
    }
  }

  loadLookup(params, filtroUnidade) {
    return (dispatch, getState) => {
      if (params) {
        if (params.max) {
          dispatch(this.setIndex(params.index))
          dispatch(this.setMax(params.max))
        } else if (params.sortField && params.sortOrder) {
          dispatch(this.setSortOrder(params.sortOrder))
          dispatch(this.setSortField(params.sortField))
        }
      }
      const state = getState()[`${this.prefix}State`]
      const columns = state.columnsLookup
      if (columns && columns.length > 0) {
        let sortFields = []
        if (state.sortField) {
          sortFields = [
            {
              fieldName: state.sortField,
              order: state.sortOrder > -1 ? `ASC` : `DESC`,
            },
          ]
        }
        const projectionFieldsColumns = columns.map((col) => col.columnKey)
        projectionFieldsColumns.push('oid')
        const params = {
          index: state.index,
          max: state.max,
          filters: [...state.selectedFilters],
          projectionFields: projectionFieldsColumns,
          resourceForGetAll: state.resourceForGetAll,
          sortFields,
        }

        if (filtroUnidade) {
          params.filters.push(filtroUnidade)
        }
        params.filters.push({ campo: 'ativo', valor: true, tipoFiltro: 'IGUAL_A' })
        this.loadRecords(params, dispatch)
      }
    }
  }

  loadStateData2() {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      this.crud
        .loadStateData(storage.getOidCurrentMenu())
        .then(({ data }) => {
          this.configStateInfo(dispatch, state, data)
        })
        .catch((err) => {
          dispatch(this.fetchRecordsError(err))
        })
    }
  }

  loadStateData(dispatch, state, getState) {
    this.crud
      .loadStateData(storage.getOidCurrentMenu())
      .then(({ data }) => {
        this.configStateInfo(dispatch, state, data)
        this.executeLoad(dispatch, state, getState)
      })
      .catch((err) => {
        dispatch(this.fetchRecordsError(err))
      })
  }

  verificarValorComponenteDinamico = (f, campo) => {
    const valor = f[campo]
    if (valor === '$DATA_ATUAL') {
      return new Date()
    } else if (valor === '$INICIO_MES') {
      return moment().startOf('month').toDate()
    } else if (valor === '$FINAL_MES') {
      return moment().endOf('month')
    }
  }

  configStateInfo = (dispatch, state, data) => {
    //  Object.assign(state, { columns: [] })
    Object.assign(state, { title: '' })
    Object.assign(state, { selectedFilters: [] })

    let columns = []
    if (utils.isArrayNotEmpty(data.columns)) {
      let viewColumns = utils.orderArrayByProperty(data.columns, 'ordem')
      viewColumns.forEach(viewColumn => {
        let column = state.columns.find(c => viewColumn.field === c.field);
        if (column) {
          column.visible = viewColumn.visible;
          column.filter = viewColumn.filter;
          column.sortable = viewColumn.sortable;
          columns.push(column)
        }
      })
    }

    Object.assign(state, { columns: columns })
    Object.assign(state, { title: data.nome })
    if (columns && state.showHideColumns) {
      columns.forEach((column) => {
        state.showHideColumns.forEach((hideColumns) => {
          if (hideColumns.value && hideColumns.value.field && hideColumns.value.field === column.field) {
            hideColumns.value.visible = column.visible
          }
        })
      })
    }

    if (utils.isArrayNotEmpty(data.filters)) {
      const filterOptions = utils.getFiltersOptionsIdValue(storage.getAppLabels('filtros'))
      const componenteFilterOptions = utils.getFilters(storage.getAppLabels('filtros'))

      let filterValues = {}
      data.filters.forEach((f) => {
        f.tipoFiltro = utils.parseFilter(filterOptions, f.tipoFiltro)
        if (utils.isTipoDadoData(f)) {
          f.valor = utils.getDateFromNumber(f.valor)
          if (f.valorComplementar) {
            f.valorComplementar = utils.getDateFromNumber(f.valorComplementar)
          }
        }

        if (f.valor === '$DATA_ATUAL' || f.valor === '$INICIO_MES' || f.valor === '$FINAL_MES') {
          const valorComponenteDinamico = this.verificarValorComponenteDinamico(f, 'valor')
          f.valor = valorComponenteDinamico
          f.valorComponente = JSON.stringify(valorComponenteDinamico)
        }
        if (
          f.valorComplementar === '$DATA_ATUAL' ||
          f.valorComplementar === '$INICIO_MES' ||
          f.valorComplementar === '$FINAL_MES'
        ) {
          const valorComponenteDinamico = this.verificarValorComponenteDinamico(f, 'valorComplementar')
          f.valorComplementar = valorComponenteDinamico
          f.valorComponenteComplementar = JSON.stringify(valorComponenteDinamico)
        }

        let valorComponente = null
        let valorComponenteComplentar = null

        if (f.valorComponente) {
          valorComponente = JSON.parse(f.valorComponente)
          const dateValid = moment(valorComponente, moment.ISO_8601, true).isValid()
          if (dateValid && !isNumber(valorComponente)) {
            valorComponente = moment(valorComponente, moment.ISO_8601, true).toDate()
          }
        } else if (f.valor) {
          valorComponente = f.valor
          const dateValid = moment(valorComponente, moment.ISO_8601, true).isValid()
          if (dateValid && !isNumber(valorComponente)) {
            valorComponente = moment(valorComponente, moment.ISO_8601, true).toDate()
          }
        }
        if (f.valorComponenteComplementar) {
          valorComponenteComplentar = JSON.parse(f.valorComponenteComplementar)
          const dateValid = moment(valorComponenteComplentar, moment.ISO_8601, true).isValid()
          if (dateValid && !isNumber(valorComponenteComplentar)) {
            valorComponenteComplentar = moment(valorComponenteComplentar, moment.ISO_8601, true).toDate()
          }
        } else if (f.valorComplementar) {
          valorComponenteComplentar = f.valorComplementar
          const dateValid = moment(valorComponente, moment.ISO_8601, true).isValid()
          if (dateValid && !isNumber(valorComponente)) {
            valorComponente = moment(valorComponente, moment.ISO_8601, true).toDate()
          }
        }

        const componenteTipoFiltro = componenteFilterOptions.find((c) => c.value === f.tipoFiltro?.id)
        filterValues[f.chaveComponente || f.campo] = {}
        filterValues[f.chaveComponente || f.campo].valor = valorComponente
        filterValues[f.chaveComponente || f.campo].valorComplementar = valorComponenteComplentar
        filterValues[f.chaveComponente || f.campo].tipoFiltro = componenteTipoFiltro
      })

      Object.assign(state, { selectedFilters: data.filters, filterValues: filterValues })
    }
  }

  loadLocalidades(hash) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      this.crud
        .loadAllLocalidades(hash)
        .then(({ data }) => {
          const currentRecord = Object.assign({}, state.currentRecord, { localidades: data })
          dispatch(this.fetchRecordSuccess(currentRecord))
          if (this.afterSearch) {
            this.afterSearch()
          }
        })
        .catch((err) => {
          const messages = ['Não foi possível carregar os dados']
          dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))

          dispatch(this.hideLoader())
        })
    }
  }

  loadById(oid) {
    return (dispatch, getState) => {
      dispatch(this.fetchRecord())
      this.loadRecord(oid, dispatch)
    }
  }

  findTags(codigo) {
    return (dispatch, getState) => {
      this.crud
        .findTags(codigo)
        .then(({ data }) => {
          if (this.afterFindTags) {
            this.afterFindTags(data)
          }
        })
        .catch((err) => {
          dispatch(this.hideLoader())
        })
    }
  }

  findCausasMortis(descricao) {
    return (dispatch, getState) => {
      this.crud
        .findCausasMortis(descricao)
        .then(({ data }) => {
          if (this.afterFindCausasMortis) {
            this.afterFindCausasMortis(data)
          }
        })
        .catch((err) => {
          dispatch(this.hideLoader())
        })
    }
  }

  loadFavorito(menu) {
    return (dispatch, getState) => {
      this.crud
        .loadFavorito(menu)
        .then(({ data }) => {
          if (this.afterLoadFavorito) {
            this.afterLoadFavorito(data)
          }
        })
        .catch((err) => {
          dispatch(this.hideLoader())
        })
    }
  }

  removeFavorito(oid) {
    return (dispatch, getState) => {
      this.crud
        .removeFavorito(oid)
        .then(({ data }) => {
          if (this.afterRemoveFavorito) {
            this.afterRemoveFavorito()
          }
        })
        .catch((err) => {
          dispatch(this.hideLoader())
        })
    }
  }

  adicionarFavorito(caminhoMenu) {
    return (dispatch, getState) => {
      this.crud
        .adicionarFavorito(caminhoMenu)
        .then(({ data }) => {
          if (this.afterAdicionarFavorito) {
            this.afterAdicionarFavorito(data)
          }
        })
        .catch((err) => {
          dispatch(this.hideLoader())
        })
    }
  }

  findByCEP(cep) {
    return (dispatch, getState) => {
      this.loadCEP(cep, dispatch, getState)
    }
  }

  getBotoesDinamicos(idTela, tipoTela) {
    return (dispatch) => {
      this.crud
        .getBotoesDinamicos(idTela, tipoTela)
        .then(({ data }) => {
          dispatch(this.setBotoesDinamicos(data))
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            messageActions.messageShowMessages(makeMessages(['Não foi possível carregar os botões dinâmicos'], 'error'))
          )
        })
    }
  }

  executarFuncaoDinamica(listIds, botao, paramsOpt) {
    return (dispatch) => {
      let params = { idsObjects: listIds, idBotaoDinamico: botao.oid, params: paramsOpt }
      this.crud
        .executarFuncaoDinamica(params)
        .then(({ data }) => {
          if (data && data !== 'OK') {
            if (typeof data === 'string' && data.includes('http')) {
              const win = window.open(data)
              if (win) {
                win.focus()
              }
            } else if (botao.tipoTela === enums.TipoTela.FORMULARIO) {
              if (utils.isNumber(data)) {
                dispatch(this.fetchRecordSuccess(data))
              }
            } else {
              dispatch(this.fetchRecordsSuccess(data))
            }
          }
          // dispatch(messageActions.messageShowMessages(makeMessages(["Sucesso ao executar a ação!"], "success")));
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            dispatch(messageActions.messageShowMessages(makeMessages([err.response.data], 'error')))
          } else {
            dispatch(messageActions.messageShowMessages(makeMessages(['Erro ao executar a ação!'], 'error')))
          }
        })
    }
  }

  loadCEP(cep, dispatch, getState) {
    this.crud
      .getByCEP(cep)
      .then(({ data }) => {
        dispatch(this.setField('oidLogradouro', data.oidLogradouro ? data.oidLogradouro : ''))
        dispatch(this.setField('bairro', data.bairro))
        dispatch(this.setField('nomeLogradouro', data.logradouro))
        dispatch(this.setField('endereco', data.logradouro))
        dispatch(this.setField('cidade', data.cidade))
        dispatch(this.setField('uf', data.uf))
        dispatch(this.setField('oidLocalidade', data.oidLocalidade))
      })
      .catch((err) => {
        const messages = ['Não foi possível carregar os dados do CEP informado']
        dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
        dispatch(this.hideLoader())
      })
  }

  loadCategoriasPai(oidLogradouro, hash) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      this.crud
        .loadCategoriasPai(oidLogradouro, hash)
        .then(({ data }) => {
          const currentRecord = Object.assign({}, state.currentRecord, { categorias: data })
          dispatch(this.fetchRecordSuccess(currentRecord))
          if (this.afterLoadCategoriasPai) {
            this.afterLoadCategoriasPai()
          }
        })
        .catch((err) => {
          const messages = ['Não foi possível carregar os dados das categorias deste local']
          dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
          dispatch(this.hideLoader())
        })
    }
  }

  loadSubCategorias(oidLocalidade, oidCategoriaPai, hash) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      this.crud
        .loadSubCategorias(oidLocalidade, oidCategoriaPai, hash)
        .then(({ data }) => {
          const currentRecord = Object.assign({}, state.currentRecord, { subCategorias: data })
          dispatch(this.fetchRecordSuccess(currentRecord))
        })
        .catch((err) => {
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(
                err.map((erro) => erro.message),
                'error'
              )
            )
          )
        })
    }
  }

  paginate(paginationParams) {
    return (dispatch, getState) => {
      dispatch(this.setIndex(paginationParams.index))
      dispatch(this.setMax(paginationParams.max))
      const state = getState()[`${this.prefix}State`]
      this.executeLoad(dispatch, state, getState)
    }
  }

  sortOrder(sortParams) {
    return (dispatch, getState) => {
      dispatch(this.setSortOrder(sortParams.sortOrder))
      dispatch(this.setSortField(sortParams.sortField))
      const state = getState()[`${this.prefix}State`]
      this.executeLoad(dispatch, state, getState)
    }
  }

  sortOrder(sortParams, resourceForGetAll) {
    return (dispatch, getState) => {
      dispatch(this.setSortOrder(sortParams.sortOrder))
      dispatch(this.setSortField(sortParams.sortField))
      dispatch(this.setResourceForGetAll(resourceForGetAll))
      const state = getState()[`${this.prefix}State`]
      this.executeLoad(dispatch, state, getState)
    }
  }

  applyFilter(filters) {
    return (dispatch, getState) => {
      dispatch(this.filter(filters))
      if (this.timer) {
        window.clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        dispatch(this.setIndex(0))
        dispatch(this.load())
      }, 300)
    }
  }

  consultarConvenios() {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      const record = state.currentRecord
      this.crud
        .getConvenios(record, record.hash)
        .then(({ data }) => {
          const currentRecord = Object.assign({}, state.currentRecord, { empresas: data })
          dispatch(this.fetchRecordSuccess(currentRecord))
          if (this.afterLoadCategoria()) {
            this.afterLoadCategoria()
          }
        })
        .catch((err) => {
          const { errors = [] } = err
          if (errors.length > 0) {
            dispatch(this.clearErrors())
            dispatch(this.addErrors(errors))
            dispatch(
              messageActions.messageShowMessages(
                makeMessages(
                  errors.map((erro) => erro.message),
                  'error'
                )
              )
            )
          }
        })
    }
  }

  reloadTeste(oid, dispatch) {
    this.loadRecord(oid, dispatch)
  }

  save() {
    return (dispatch, getState) => {
      dispatch(this.clearErrors())
      const state = getState()[`${this.prefix}State`]
      const record = state.currentRecord
      dispatch(this.setIsSaving(true))
      if (!record.oid) {
        this.crud
          .insert(record)
          .then(({ data }) => {
            const messages = ['Registro criado com sucesso']
            dispatch(messageActions.messageShowMessages(makeMessages(messages, 'success')))
            dispatch(this.setIsSaving(false))
            if (this.beforeFetchRecordSuccess) {
              this.beforeFetchRecordSuccess(data)
            }
            dispatch(this.setRecord(data))
            if (this.afterSave) {
              this.afterSave(data)
            }
          })
          .catch((err) => {
            dispatch(this.setIsSaving(false))
            let messages
            if (err.response) {
              messages = [err.response.data]
              dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
            } else {
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(
                    err.errors.map((erro) => erro.message),
                    'error'
                  )
                )
              )
            }
          })
      } else {
        this.crud
          .update(record)
          .then(({ data }) => {
            const messages = ['Registro atualizado com sucesso']
            dispatch(this.setIsSaving(false))
            dispatch(messageActions.messageShowMessages(makeMessages(messages, 'success')))
            if (this.beforeFetchRecordSuccess) {
              this.beforeFetchRecordSuccess(data)
            }
            dispatch(this.setRecord(data))
            if (this.afterSave) {
              this.afterSave(data)
            }
          })
          .catch((err) => {
            dispatch(this.setIsSaving(false))
            let messages
            if (err.response) {
              messages = [err.response.data]
              if (
                err.response.data === 'Registro foi alterado por outro usuário, recarregue a tela e salve novamente.'
              ) {
                messages = [err.response.data + ' Clique para recarregar.']
              }
              dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error', this, record.oid, dispatch)))
            } else {
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(
                    err.errors.map((erro) => erro.message),
                    'error'
                  )
                )
              )
            }
          })
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }

  saveState(newState, filters) {
    return (dispatch) => {
      if (!newState.oid) {
        this.crud
          .insertState(newState, filters)
          .then(({ data }) => {
            const messages = ['Registro criado com sucesso']
            dispatch(messageActions.messageShowMessages(makeMessages(messages, 'success')))
            //   dispatch(menuActions.menuFetch());
          })
          .catch((err) => {
            const { errors = [] } = err
            if (errors.length > 0) {
              dispatch(this.clearErrors())
              dispatch(this.addErrors(errors))
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(
                    errors.map((erro) => erro.message),
                    'error'
                  )
                )
              )
            }
          })
      }
    }
  }

  removeState() {
    return (dispatch, getState) => {

      const oidCurrentMenu = storage.getOidCurrentMenu()
      this.crud
        .removeState(oidCurrentMenu)
        .then(({ data }) => {
          history.replace("/");
          window.location.reload();
        })
        .catch((err) => {
          const { errors = [] } = err
          if (errors.length > 0) {
            dispatch(this.clearErrors())
            dispatch(this.addErrors(errors))
            dispatch(
              messageActions.messageShowMessages(
                makeMessages(
                  errors.map((erro) => erro.message),
                  'error'
                )
              )
            )
          }
        })
    }
  }

  makeNew() {
    return (dispatch) => {
      dispatch({
        type: this.types.MAKE_NEW,
      })
      document.body.scrollTop = document.documentElement.scrollTop = 0
      if (this.afterNew) {
        this.afterNew()
      }
    }
  }

  remove() {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      const record = state.currentRecord
      this.crud
        .remove(record.oid)
        .then(() => {
          if (this.afterRemove) {
            this.afterRemove()
            const messages = ['Registro removido com sucesso']
            dispatch(messageActions.messageShowMessages(makeMessages(messages, 'success')))
          }
        })
        .catch((err) => {
          const messages = [err.response.data]
          dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
        })
    }
  }

  removeAll() {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`]
      const records = state.selectedRecords

      let idItens = []
      if (records && records.length > 0) {
        let records = []
        records.forEach((item) => {
          idItens.push(item.oid)
        })
        this.crud
          .removeAll(idItens)
          .then(() => {
            if (this.afterRemove) {
              this.afterRemove()
              const messages = ['Registros removidos com sucesso']
              dispatch(messageActions.messageShowMessages(makeMessages(messages, 'success')))
            }
          })
          .catch((err) => {
            const messages = [err.response.data]
            dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
          })
      } else {
        const messages = ['Não foram selecionados registros']
        dispatch(messageActions.messageShowMessages(makeMessages(messages, 'error')))
      }
    }
  }

  executeFilter = (path) => {
    return (dispatch, getState) => {
      const oidCurrentMenu = storage.getOidCurrentMenu()
      const state = getState()[`${this.prefix}State`]
      let sortFields = []
      if (state.sortField) {
        sortFields = [
          {
            fieldName: state.sortField,
            order: state.sortOrder > -1 ? `ASC` : `DESC`,
          },
        ]
      }
      const stateColumns = state.columns || []
      let columns = []
      if (state.usaListDto) {
        columns = stateColumns.filter((col) => col.visible).map((col) => col.columnKey)
      } else {
        columns = state.showHideColumns
          .filter((col) => col.value.visible)
          .map((col) => col.value.field.replace('_', '.'))
        // let otherColumns = stateColumns.filter(col => col.visible).map(col => col.columnKey);
        // columns = columns.concat(otherColumns);
      }
      if (columns.findIndex((c) => c === 'oid') < 0) {
        columns.push('oid')
      }

      const params = {
        index: 0,
        max: state.max || 50,
        filters: utils.parseTipoFiltro(state.selectedFilters),
        projectionFields: columns,
        sortFields,
        oidMenu: oidCurrentMenu,
      }
      return apiService.post(path, params).then((response) => {
        dispatch(this.fetchRecordsSuccess(response.data))
      })
    }
  }

  // uploadCsvImportacao = (data) => {
  //   // return (dispatch, getState) => {
  //   if (data && data.target && data.target.files && data.target.files.length > 0) {
  //     const file = data.target.files[0]
  //     const form = new FormData()
  //     form.append('arquivo', file)
  //     // dispatch(
  //     messageActions.messageShowMessages(
  //       makeMessages(['Enviando arquivo para processamento. Isso pode levar algum tempo.'], 'warn')
  //     )
  //     // )
  //     const baePath = _.get(this, 'crud.basePath', '')
  //     apiService
  //       .postFormData(`${baePath}/importacao/csv`, form, { nomeArquivo: file.name })
  //       .then((response) =>
  //         //dispatch(messageActions.messageShowMessages(makeMessages(['Arquivo processado com sucesso.'], 'success')))
  //         messageActions.messageShowMessages(makeMessages(['Arquivo processado com sucesso.'], 'success'))
  //       )
  //       .catch((error) => {
  //         // dispatch(messageActions.messageShowMessages(makeMessages(['Ocorreu um erro processando o arquivo'], 'error')))
  //         messageActions.messageShowMessages(makeMessages(['Ocorreu um erro processando o arquivo'], 'error'))
  //       })
  //   }
  //   // }
  // }

  uploadCsvImportacao = (data) => {
    return (dispatch, getState) => {
      if (data && data.target && data.target.files && data.target.files.length > 0) {
        const basePath = _.get(this, 'crud.basePath', '')
        const file = data.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          const message = 'Arquivo enviado. Esse processo pode levar algum tempo.'
          dispatch(messageActions.messageShowMessages(makeMessages([message], 'warn')))
          const anexo = { filename: file.name, base64: encoded }
          const path = basePath + `/importacao/base64/csv`
          const params = {
            nomeArquivo: file.name,
            base64Arquivo: encoded,
          }
          apiService
            .post(path, params)
            .then((response) => {
              const result = _.get(response, 'data', '')
              if (result.length > 0) {
                utils.createAndDownloadBlobFile(result, 'ResultadoImportacaoArquivo_' + anexo.filename)
                dispatch(
                  messageActions.messageShowMessages(
                    makeMessages(['Ocorreu algum erro processando o arquivo. Verifique o arquivo de retorno.'], 'error')
                  )
                )
              } else {
                dispatch(messageActions.messageShowMessages(makeMessages(['Arquivo processado sem erros'], 'success')))
              }
            })
            .catch((err) => {
              if (err.response) {
                const messages = err.response.data
                dispatch(messageActions.messageShowMessages(makeMessages([messages], 'error')))
              }
            })
        }
      }
    }
  }

  getTemplateImportacao = () => {
    return (dispatch, getState) => {
      const baePath = _.get(this, 'crud.basePath', '')
      return apiService.get(`${baePath}/importacao/template`).then((response) => {
        const data = _.get(response, 'data', null)
        if (data) {
          utils.createAndDownloadBlobFile(data, 'template_importacao.csv')
        } else {
          dispatch(
            messageActions.messageShowMessages(makeMessages(['Nenhum template de importação encontrado.'], 'error'))
          )
        }
      })
    }
  }
}
