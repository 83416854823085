import React from 'react';

import CategoriaFormPageContainer from './CategoriaFormPageContainer';

export class CategoriaFormPage extends React.Component{

    
    render(){
        return (
           <CategoriaFormPageContainer {...this.props} />
        )
    }
}
