import React from 'react'
import { connect } from 'react-redux'
import { Column } from 'primereact/column'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../localizacao.actions'
import { actions as contratoActions } from '../../contrato/contrato.actions'
import { LocalizacaoForm } from './LocalizacaoForm'
import utils from '../../../utils'
import { Filtro } from '../../../utils/Filtro.js'
import storage from '../../../services/stoage.service'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  const messages = state.crudLocalizacaoState.messages
  const record = state.crudLocalizacaoState.currentRecord
  const anexos = record.anexos

  let disableUnit = false
  let currentUnit = storage.getUnit()
  if (currentUnit) {
    let unidade = {
      id: currentUnit.oid,
      value: currentUnit.sigla,
    }
    Object.assign(record, { unidade: unidade })
    disableUnit = true
  }

  const company = storage.getCompany()
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(company)

  const lookupContratoBase = {
    records: state.crudContratoState.records,
    columns: state.crudContratoState.columnsLookup
      .filter((col) => col.field === 'oid' || col.field === 'localizacao_identificacao')
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudContratoState.index,
    rows: state.crudContratoState.max,
    totalRecords: state.crudContratoState.count,
    sortField: state.crudContratoState.sortField,
    order: state.crudContratoState.sortOrder,
    editDisabled: !record.oidContrato,
  }
  let lookupContrato = Object.assign({}, state.crudLocalizacaoState.lookupContrato, lookupContratoBase)

  // REFERENTE AO DROPDOWN DE TIPOS DE SEPULTURA
  let tiposSepultura = []
  if (state.crudTipoSepulturaState.records && state.crudTipoSepulturaState.records.length > 0) {
    state.crudTipoSepulturaState.records.forEach((tipoSepultura) => {
      tiposSepultura.push({ value: tipoSepultura.descricao, id: tipoSepultura.oid })
    })
  }

  let inputs = state.crudLocalizacaoState.inputs
  if (
    record.unidade &&
    record.unidade.id === 9 &&
    record.tipoSepultura &&
    utils.isTipoSepulturaGaveta(company.unidades, record.unidade.id, record.tipoSepultura.id)
  ) {
    inputs = state.crudLocalizacaoState.inputs.filter((t) => t.name !== 'localizacaoA')
  }
  const isCapela = utils.isTipoSepulturaCapela(company.unidades, record.unidade.id, record.tipoSepultura.id)

  return {
    appState: state.appState,
    disableUnit,
    unidades,
    record,
    messages,
    tiposSepultura,
    inputs: inputs,
    lookupContrato,
    anexos,
    isSaving: state.crudLocalizacaoState.isSaving,
    isCapela,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    setInputsLocalizacao: (value) => dispatch(actions.setInputs(value)),
    changeHandler: (event) => {
      // const booleanFields = ["ativo", "temporaria", "capela"];
      const booleanFields = ['ativo', 'temporaria']
      const target = event.target
      const field = target.name
      let valor = target.value
      dispatch(actions.removeErrors([{ field }]))
      if (booleanFields.find((current) => current === field)) {
        valor = target.checked
      }
      if (field === 'unidade') {
        dispatch(actions.setField('unidade', valor))
        dispatch(actions.loadTiposSepulturas(valor.id))
      } else if (field === 'tipoSepultura') {
        dispatch(actions.setTipoSepultura(valor))
      } else if (field === 'numeroLugares') {
        dispatch(actions.setNumeroLugares(_.defaultTo(parseInt(valor), 0)))
      } else {
        dispatch(actions.setField(field, valor))
      }
    },
    loadTiposSepulturas: (oidUnidade) => {
      dispatch(actions.loadTiposSepulturas(oidUnidade))
    },
    loadMascaraIdentificacao: (oidUnidade, oidTipoSepultura) => {
      dispatch(actions.getMascaraIdentificacaoLocalizacao(oidUnidade, oidTipoSepultura))
    },
    saveHandler: (event) => dispatch(actions.beforeSave()),
    removeHandler: (event) => dispatch(actions.remove()),
    newHandler: (event) => {
      dispatch(actions.setInputs([]))
      dispatch(actions.makeNew())
    },
    loadById: (oid) => {
      dispatch(actions.loadById(oid))
    },
    lookupContratoSetVisible: (visible) => dispatch(actions.setLookupContratoVisible(visible)),
    lookupContratoPaginate: (paginationParams) => dispatch(contratoActions.paginate(paginationParams)),
    lookupContratoSortOrder: (sortParams) => dispatch(contratoActions.sortOrder(sortParams)),
    lookupContratoApplyFilter: (filterParams) => {
      dispatch(contratoActions.setType(null))
      dispatch(contratoActions.setSelectedFilters('ativo', 'true'))
      dispatch(contratoActions.setSelectedFilters(filterParams.campo, filterParams.valor))
      dispatch(contratoActions.loadLookup())
    },
    lookupContratoLoad: () => {
      dispatch(contratoActions.setSelectedFilters('ativo', 'true'))
      dispatch(contratoActions.loadLookup())
    },
    clearSelectedFilters() {
      dispatch(contratoActions.clearSelectedFilters())
    },
    clearRecords: () => dispatch(contratoActions.setRecords([])),
    setLocalizacaoRef(localizacao) {
      dispatch(actions.setLocalizacaoRef(localizacao))
    },
    addAnexo(anexo) {
      dispatch(actions.processaAnexo(anexo))
    },
    downloadAnexo(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.downloadAnexo(anexo))
    },
    removeAnexo(anexo) {
      //objeto conforme AnexoDTO do server
      dispatch(actions.removeAnexo(anexo))
    },
  }
}

class LocalizacaoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
      showConfirmSaveDialog: false,
      typingTimeout: 0,
    }

    this.messageDialog = ''
    this.anexos = []

    actions.afterSave = (data) => {
      this.props.setField('identificacao', data.identificacao)
      this.props.history.replace(`${routes.PATH_LOCALIZACAO}/${data.oid}`)
    }

    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_LOCALIZACAO}/novo`)
      const localizacaoRef = this.props.location.localizacaoRef
      if (localizacaoRef) {
        localizacaoRef.oid = null
        this.props.setLocalizacaoRef(localizacaoRef)
        if (this.props.record.tipoSepultura.id && this.props.record.unidade.id) {
          this.props.loadMascaraIdentificacao(this.props.record.unidade.id, this.props.record.tipoSepultura.id)
        }
      }
    }

    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_LOCALIZACAO}`)
    }

    actions.beforeFetchRecordSuccess = (data) => {
      let oidTipoSepultura = null
      let oidUnidade = null
      if (data.tipoSepultura) {
        oidTipoSepultura = data.tipoSepultura.id
      }
      if (data.unidade && data.unidade.id) {
        oidUnidade = data.unidade.id
      }

      this.carregarInputsLocalizacao(oidTipoSepultura, oidUnidade)
      return data
    }

    this.setField = props.setField
    this.setLocalizacaoRef = props.setLocalizacaoRef
    this.loadMascaraIdentificacao = props.loadMascaraIdentificacao
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
    let unidade = storage.getUnit()
    if (unidade && unidade.oid) {
      this.props.loadTiposSepulturas(unidade.oid)
    }
  }

  carregarInputsLocalizacao = (oidTipoSepultura, oidUnidade) => {
    const unidade = storage.getUnit()
    if (oidUnidade !== null) {
      this.props.loadMascaraIdentificacao(oidUnidade, oidTipoSepultura)
    } else if (unidade && unidade.oid) {
      this.props.loadMascaraIdentificacao(unidade.oid, oidTipoSepultura)
    } else {
      this.props.loadMascaraIdentificacao(null, oidTipoSepultura)
    }
  }

  componentDidUpdate(anteriorProps) {
    if (anteriorProps.record.oidContrato !== '' && this.props.record.oidContrato !== anteriorProps.record.oidContrato) {
      if (anteriorProps.record.oidContrato) {
        this.setState({ showConfirmSaveDialog: true })
        this.messageDialog = `Tem certeza que deseja inativar o contrato de nº ${anteriorProps.record.oidContrato}?`
      }
    } else {
      if (this.state.showConfirmSaveDialog) {
        this.setState({ showConfirmSaveDialog: false })
      }
    }
    if (
      anteriorProps.record.oid === '' &&
      anteriorProps.record.oid !== this.props.record.oid &&
      this.props.record.mascara
    ) {
      this.props.setInputsLocalizacao(storage.getInputsPorMascaraLocalizacao(this.props.record.mascara))
    }
    if (!anteriorProps.record.unidade || anteriorProps.record.unidade.id !== this.props.record.unidade.id) {
      this.props.loadTiposSepulturas(this.props.record.unidade.id)
    }
  }

  changeHandler = (event) => {
    const record = _.get(this, 'props.record', {})
    const target = event.target
    const field = target.name
    let valor = target.value
    if (field === 'unidade' && record.tipoSepultura && record.tipoSepultura.id) {
      this.loadMascaraIdentificacao(valor.id, record.tipoSepultura.id)
      this.setField('identificacao', '')
    } else if (field === 'tipoSepultura' && record.unidade && record.unidade.id) {
      this.loadMascaraIdentificacao(record.unidade.id, valor.id)
      this.setField('identificacao', '')
    }
    this.props.changeHandler(event)
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_LOCALIZACAO}`)
  }

  onClickOpenContrato = () => {
    this.props.history.push(`${routes.PATH_CONTRATO}/${this.props.record.oidContrato}`)
  }

  onClickContratar = () => {
    const informacoesLocalizacao = {
      oidLocalizacao: this.props.record.oid,
      identificacao: this.props.record.identificacao,
      unidade: this.props.record.unidade,
      tipoSepultura: this.props.record.tipoSepultura,
    }
    this.props.history.push(`${routes.PATH_CONTRATO}/novo`, informacoesLocalizacao)
  }

  // REFERENTE AO LOOKUP PARA CONTRATO
  lookupContratoOnPage = (e) => {
    this.props.lookupContratoPaginate({
      max: e.rows,
      index: e.first,
    })
  }
  lookupContratoOnSort = (e) => {
    this.props.lookupContratoSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    })
  }
  lookupContratoOnFilter = (e) => {
    const keys = Object.keys(e.filters) || []
    keys.forEach((key) => {
      let filter = {
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM',
      }
      this.props.lookupContratoApplyFilter(filter)
    })
    if (keys.length === 0) {
      this.props.clearSelectedFilters()
      this.props.lookupContratoLoad()
    }
  }

  lookupContratoOnClick = (e) => {
    this.props.lookupContratoLoad()
    this.props.lookupContratoSetVisible(true)
  }
  lookupContratoOnHide = (e) => {
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnConfirm = (e) => {
    this.props.setField('oidContrato', e.selection.oid)
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnCancel = (e) => {
    this.props.lookupContratoSetVisible(false)
  }
  lookupContratoOnEdit = () => {
    if (this.props.record.oidContrato) {
      return `${routes.PATH_CONTRATO}/${this.props.record.oidContrato}`
    }
  }
  lookupContratoOnComplete = (e) => {}

  lookupContratoOnInputFilter = (e) => {
    const value = e.value
    if (typeof value === 'string') {
      this.props.setField('oidContrato', e.value)
      this.props.clearRecords()
    } else {
      this.props.setField('oidContrato', value.oid)
      const filters = [{}]
      this.props.lookupContratoApplyFilter(filters)
    }
  }

  lookupContratoOnKeyPress = (e) => {
    if (utils.nonNull(this.props.record.oidContrato)) {
      const filtroContrato = new Filtro('oid', this.props.record.oidContrato, 'CONTEM')
      this.props.lookupContratoApplyFilter(filtroContrato)
    }
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
    this.setState({ showConfirmSaveDialog: false })
  }

  buildHeader = () => {
    return (
      <DefaultFormToolBar
        showConfirmSaveDialog={this.state.showConfirmSaveDialog}
        formPageContainer={this}
        messageDialog={this.messageDialog}
      />
    )
  }

  render() {
    const content = (
      <LocalizacaoForm
        disableUnit={this.props.disableUnit}
        unidades={this.props.unidades}
        messages={this.props.messages}
        inputsLocalizacao={this.props.inputs}
        changeHandler={this.changeHandler}
        onComplete={this.props.onComplete}
        onClickOpenContrato={this.onClickOpenContrato}
        onClickContratar={this.onClickContratar}
        record={this.props.record}
        tiposSepultura={this.props.tiposSepultura}
        history={this.props.history}
        //-----
        lookupContrato={this.props.lookupContrato}
        lookupContratoOnKeyPress={this.lookupContratoOnKeyPress}
        lookupContratoOnHide={this.lookupContratoOnHide}
        lookupContratoOnConfirm={this.lookupContratoOnConfirm}
        lookupContratoOnCancel={this.lookupContratoOnCancel}
        lookupContratoOnPage={this.lookupContratoOnPage}
        lookupContratoOnSort={this.lookupContratoOnSort}
        lookupContratoOnFilter={this.lookupContratoOnFilter}
        lookupContratoOnClick={this.lookupContratoOnClick}
        lookupContratoOnEdit={this.lookupContratoOnEdit}
        lookupContratoOnComplete={this.lookupContratoOnComplete}
        lookupContratoOnInputFilter={this.lookupContratoOnInputFilter}
        onSelectAnexo={this.props.addAnexo}
        anexos={this.props.anexos}
        downloadAnexo={this.props.downloadAnexo}
        removeAnexo={this.props.removeAnexo}
        isCapela={this.props.isCapela}
      />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalizacaoFormPageContainer)
