export function validate(record){
    const errors = [];
    if(!record.nome || record.nome.trim().length < 3){
        errors.push({field:'nome',message:'O campo nome é obrigatório e deve conter ao menos 3 caracteres'});
    }
    if(!record.email || record.email.trim().length < 7){
        errors.push({field:'email',message:'O campo email é obrigatório e deve conter mais de 6 caracteres'});
    }
    if(!record.telefone || record.telefone.trim().length < 11){
        errors.push({field:'telefone',message:'O campo telefone é obrigatório'});
    }
    if(!record.tipo || record.tipo.trim().length === 0){
        errors.push({field:'tipo',message:'O campo tipo é obrigatório'});
    }

    const result =  {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve,reject)=>{
        const action = result.ok ?resolve : reject;
    return action(result);
    })

}