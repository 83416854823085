import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
import utils from "../../utils";
const BASE_PATH = "/lottustech/portalCliente";
const prefix = "crudPortalClienteConsultaFalecido";
const crud = build(validate, BASE_PATH);

const newTypes = {};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadAll = () => {
    return (dispatch, getState) => {
      const state = getState()[`crudConsultaFalecidoState`];
      actions.setDefaultColumns(state);
      let sortFields = [];
      if (state.sortField) {
        sortFields = [
          {
            fieldName: state.sortField,
            order: state.sortOrder > -1 ? `ASC` : `DESC`
          }
        ];
      }
      const stateColumns = state.columns || [];
      let columns = [];
      if (state.usaListDto) {
        columns = stateColumns.filter(col => col.visible).map(col => col.columnKey);
        columns.push("oid");
      } else {
        columns = state.showHideColumns.filter(col => col.value.visible).map(col => col.value.field.replace("_", "."));
      }
  
      const filters = utils.parseTipoFiltro(state.selectedFilters);
      const params = {
        index: state.index,
        max: state.max,
        filters: filters,
        projectionFields: columns,
        sortFields,
        oidMenu: null
      };
      apiService.post(`${BASE_PATH}/falecidos`, params).then(response => {
        dispatch(actions.fetchRecordsSuccess(response.data));
      });
    };
  }
  
export { types, actions };
