import React from 'react';

import MovimentoCaixaFormPageContainer from './MovimentoCaixaFormPageContainer';

export class MovimentoCaixaFormPage extends React.Component{

    
    render(){
        return (
           <MovimentoCaixaFormPageContainer {...this.props} />
        )
    }
}
