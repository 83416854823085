import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class DownloadExcel extends React.Component {

    resolveSheet() {
        const dataSet = this.props.dataSet;
        if (dataSet) {
            return (
                <ExcelSheet data={dataSet.records} name="Relatorio">
                    {this.resolveColumns(dataSet)}                   
                </ExcelSheet>
            )           
        }
        return null;
    }

    resolveColumns(dataSet) {
        const columnsDefinitions = dataSet.columnsDefinitions;
        return (columnsDefinitions.map(k => <ExcelColumn label={k.label} value={k.field}/>));
    }

    render() {
        return (
            <ExcelFile hideElement={true}>
                {this.resolveSheet()}
            </ExcelFile>
        );
    }
}