import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../perfil.actions'
import { PerfilForm } from './PerfilForm'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  return {
    messages: state.crudPerfilState.messages,
    record: state.crudPerfilState.currentRecord,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const booleanFields = ['indicadorPersonalizado', 'administrador', 'criaUsuariosWebService', 'alteraUnidade']
      const target = event.target
      const field = target.name
      let value = target.value
      if (booleanFields.find((currentField) => field === currentField)) {
        value = target.checked
      }
      dispatch(actions.removeErrors([{ field }]))
      dispatch(actions.setField(field, value))
    },
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    clearForm: () => dispatch(actions.makeNew()),
    loadById: (oid) => dispatch(actions.loadById(oid)),
  }
}

class PerfilFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PERFIL}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_PERFIL}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PERFIL}`)
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.clearForm()
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PERFIL}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  render() {
    const content = (
      <PerfilForm record={this.props.record} changeHandler={this.props.changeHandler} messages={this.props.messages} />
    )
    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerfilFormPageContainer)
