const blankToNull = require("./../../../utils").blankToNull;

export function validate(record) {
  const errors = [];
  if (!record.nome) {
    errors.push({
      field: "nome",
      message: "O campo nome é obrigatório"
    });
  }
  blankToNull(record);
  const result = {
    ok: errors.length === 0,
    errors,
    record
  };
  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
