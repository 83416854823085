import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import { DefaultFormToolBar } from '../../../components/toolbar/DefaultFormToolBar'
import { routes } from '../../../constants'
import { actions } from '../endereco.actions'
import { EnderecoForm } from './EnderecoForm'
import utils from '../../../utils'
import storage from '../../../services/stoage.service'
import _ from 'lodash'
import { logarAcesso } from '../../../services/api-log-acesso'

const mapStateToProps = (state) => {
  let unidades = utils.getListIdValuesUnidadeFromEmpresa(storage.getCompany())

  return {
    record: state.crudEnderecoState.currentRecord,
    messages: state.crudEnderecoState.messages,
    unidades,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save())
    },
    removeHandler: (event) => {
      dispatch(actions.remove())
    },
    newHandler: (event) => {
      dispatch(actions.makeNew())
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),

    cepOnChange: (event) => {
      const field = event.target.name
      let valor = event.target.value
      if (valor.length === 8) {
        dispatch(actions.setField(field, valor))
        dispatch(actions.setField('endereco', ''))
        dispatch(actions.setField('complemento', ''))
        dispatch(actions.setField('bairro', ''))
        dispatch(actions.setField('cidade', ''))
        dispatch(actions.setField('uf', ''))
        dispatch(actions.findByCEP(valor))
      }
    },
  }
}

class EnderecoFormPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialogRemove: false,
    }

    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ENDERECO}/${oid}`)
    }
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_ENDERECO}/novo`)
    }
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ENDERECO}`)
    }

    actions.beforeFetchRecordSuccess = (data) => {
      return data
    }
  }

  componentDidMount() {
    const oidEntity = _.get(this, 'props.match.params.id', null)
    logarAcesso(oidEntity).then()
    if (oidEntity && parseInt(oidEntity)) {
      this.props.loadById(oidEntity)
    } else {
      this.props.newHandler()
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ENDERECO}`)
  }

  hideDialog = () => {
    this.setState({ showDialogRemove: false })
  }

  buildHeader = () => {
    return <DefaultFormToolBar formPageContainer={this} />
  }

  cepOnChange = (event) => {
    this.props.cepOnChange(event)
  }

  render() {
    const content = (
      <EnderecoForm
        record={this.props.record}
        messages={this.props.messages}
        setField={this.props.setField}
        cepOnChange={this.cepOnChange}
      />
    )

    return <Page header={this.buildHeader()} content={content} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnderecoFormPageContainer)
