import React from 'react'
import { InputText } from 'primereact/inputtext'

export default function LtsInputText(props) {
  const onChange = (event) => {
    const target = event.target
    props.onChange(target.value)
  }

  const resolveLabel = () => {
    if (props.label && !props.hidden) {
      return <label>{props.label}</label>
    }
    return null
  }

  return (
    <React.Fragment>
      <span className="p-float-label">
        <InputText
          {...props}
          value={props.value}
          onChange={onChange}
          name={props.name}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          keyfilter={props.keyfilter}
          style={props.style}
          disabled={props.disabled}
        />
        {resolveLabel()}
      </span>
    </React.Fragment>
  )
}
