import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { LookupField } from '../../../components/lookup/LookupField';
import {InputMask} from 'primereact/inputmask';

import './PessoaForm.scss';

export const PessoaForm = (props) => {
    const record = props.record;
    const changeHandler = props.changeHandler;
    return (
    <div className="pessoa-form">
        <div className="p-grid">
            <div className="p-col-6">
                <div className="p-grid">
                    <div className="p-col-3">
                        <div className="form-field-container">
                            <label>Código</label>
                            <InputText value={record.oid} onChange={changeHandler} disabled={true} />
                        </div>

                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Nome/Razão Social *</label>
                        <InputText value={record.nome} onChange={changeHandler} name="nome" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Nome Fantasia</label>
                        <InputText value={record.nomeFantasia} onChange={changeHandler} name="nomeFantasia" />
                    </div>

                    <div className="p-col-4 form-field-container">
                        <label>CPF/CNPJ *</label>
                        <InputText value={record.numeroDocumento} onChange={changeHandler} name="numeroDocumento" keyfilter="int"/>
                    </div>

                    <div className="p-col-4 form-field-container">
                        <label>Inscrição Estadual</label>
                        <InputText keyfilter="int" value={record.inscricaoEstadual} onChange={changeHandler} name="inscricaoEstadual" />
                    </div>

                    <div className="p-col-4 form-field-container">
                        <label>Número PIS</label>
                        <InputText keyfilter="int" value={record.numeroPis} onChange={changeHandler} name="numeroPis"/>
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Religião</label>
                        <LookupField
                            value={record.nomeReligiao}
                            placeholder="Religião"
                            name="nomeReligiao"
                            field="nome"
                            onComplete={props.lookupReligiaoOnComplete}
                            onInputFilter={props.lookupReligiaoOnInputFilter}
                            editDisabled={props.lookupReligiao.editDisabled}
                            onEditField={props.lookupReligiaoOnEdit}
                            onClick={props.lookupReligiaoOnClick}

                            visible={props.lookupReligiao.visible}
                            modal={props.lookupReligiao.modal}
                            header={props.lookupReligiao.header}
                            onHide={props.lookupReligiaoOnHide}
                            onConfirm={props.lookupReligiaoOnConfirm}
                            onCancel={props.lookupReligiaoOnCancel}
                            records={props.lookupReligiao.records}
                            columns={props.lookupReligiao.columns}
                            totalRecords={props.lookupReligiao.totalRecords}
                            sortOrder={props.lookupReligiao.order}
                            sortField={props.lookupReligiao.sortField}
                            first={props.lookupReligiao.first}
                            rows={props.lookupReligiao.rows}
                            onPage={props.lookupReligiaoOnPage}
                            onFilter={props.lookupReligiaoOnFilter}
                            onSort={props.lookupReligiaoOnSort}

                        />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Categoria convênio</label>
                        <LookupField
                            value={record.descricaoCategoria}
                            placeholder="Categoria"
                            name="descricaoCategoria"
                            field="descricao"
                            onComplete={props.lookupCategoriaOnComplete}
                            onInputFilter={props.lookupCategoriaOnInputFilter}
                            editDisabled={props.lookupCategoria.editDisabled}
                            onEditField={props.lookupCategoriaOnEdit}
                            onClick={props.lookupCategoriaOnClick}

                            visible={props.lookupCategoria.visible}
                            modal={props.lookupCategoria.modal}
                            header={props.lookupCategoria.header}
                            onHide={props.lookupCategoriaOnHide}
                            onConfirm={props.lookupCategoriaOnConfirm}
                            onCancel={props.lookupCategoriaOnCancel}
                            records={props.lookupCategoria.records}
                            columns={props.lookupCategoria.columns}
                            totalRecords={props.lookupCategoria.totalRecords}
                            sortOrder={props.lookupCategoria.order}
                            sortField={props.lookupCategoria.sortField}
                            first={props.lookupCategoria.first}
                            rows={props.lookupCategoria.rows}
                            onPage={props.lookupCategoriaOnPage}
                            onFilter={props.lookupCategoriaOnFilter}
                            onSort={props.lookupCategoriaOnSort}

                        />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Beneficiário do INSS</label>
                        <Checkbox checked={record.beneficiarioINSS} onChange={changeHandler} name="beneficiarioINSS" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>CEP</label>
                        <InputMask mask="99999-999" autoClear='true' onComplete={props.retirarMascara}value={record.cep} onChange={changeHandler} name="cep" />
                    </div>

                    <div className="p-col-8 form-field-container">
                        <label>Endereço</label>
                        <InputText value={record.logradouro} onChange={changeHandler} name="logradouro" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Número</label>
                        <InputText keyfilter="int" value={record.numeroLogradouro} onChange={changeHandler} name="numeroLogradouro" />
                    </div>
                    <div className="p-col-8 form-field-container">
                        <label>Complemento</label>
                        <InputText value={record.complemento} onChange={changeHandler} name="complemento" />
                    </div>
                
                    <div className="p-col-4 form-field-container">
                        <label>Bairro</label>
                        <InputText value={record.bairro} onChange={changeHandler} name="bairro" />
                    </div>
                    <div className="p-col-8 form-field-container">
                        <label>Cidade</label>
                        <InputText value={record.cidade} onChange={changeHandler} name="cidade" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Telefone</label>
                        <InputText value={record.telefone} onChange={changeHandler} name="telefone" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Email</label>
                        <InputText keyfilter="email" value={record.email} onChange={changeHandler} name="email" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Site</label>
                        <InputText value={record.site} onChange={changeHandler} name="site" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Contato</label>
                        <InputTextarea value={record.contato} onChange={changeHandler} name="contato" />
                    </div>

                </div>
            </div>
        </div>
    </div>
    )
};
