import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner'
import { Page } from '../../../components/page/Page'
import { DefaultToolBar } from '../../../components/toolbar/DefaultToolBar'
import { routes } from '../../../constants'
import listPageUtils, {
  changeFavorito,
  componentDidMount,
  componentDidUpdate,
  loadFavorito,
  onColumnReorder,
  onColumnToggle,
  onCsvExport,
  onPage,
  onSort,
} from '../../../utils/listPageContainerUtils'
import { actions } from '../orcamento.actions'
import { OrcamentoList } from './OrcamentoList'
import './orcamento-list.scss'
import utils from '../../../utils'
import LtsCalendar from '../../../components/lottus/lts-calendar'
import templateColumnResponsavel from '../../../utils/template-column-responsavel'
import LtsDropdownIdValue from '../../../components/lottus/lts-dropdown-id-value'
import apiService from '../../../services/api-id-value-service'

const mapStateToProps = (state) => {
  return {
    showHideColumns: state.crudOrcamentoState.showHideColumns,
    messages: state.crudOrcamentoState.messages,
    columns: state.crudOrcamentoState.columns,
    records: state.crudOrcamentoState.records,
    first: state.crudOrcamentoState.index,
    rows: state.crudOrcamentoState.max,
    totalRecords: state.crudOrcamentoState.count,
    sortField: state.crudOrcamentoState.sortField,
    order: state.crudOrcamentoState.sortOrder,
    selectedFilters: state.crudOrcamentoState.selectedFilters,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null))
      dispatch(actions.applyFilter(filterParams))
    },
    changeFilter: (column, e) => {
      const target = e.target
      const campo = column.fieldJoin ? column.fieldJoin : target.name
      dispatch(actions.setSelectedFilters(campo, target.value, column.relacionamento, column.field))
      setTimeout(() => {
        dispatch(actions.executeFilter(`/lottustech/orcamento/all`))
      }, 400)
    },
    load: () => dispatch(actions.load()),
    setColumns: (columns) => dispatch(actions.loadColumns(columns)),
    loadFavorito: (menu) => {
      dispatch(actions.loadFavorito(menu))
    },
    removeFavorito: (menu) => {
      dispatch(actions.removeFavorito(menu))
    },
    adicionarFavorito: (menu) => {
      dispatch(actions.adicionarFavorito(menu))
    },
    clearSelectedFilters: () => {
      dispatch(actions.clearSelectedFilters())
    },
    getTemplateImportacao: () => {
      dispatch(actions.getTemplateImportacao())
    },
    uploadCsvImportacao: (arquivo) => {
      dispatch(actions.uploadCsvImportacao(arquivo))
    },
  }
}

class OrcamentoListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.dataTableRef = React.createRef()
    this.columnSelect = React.createRef()
    this.showHideColumns = []
    this.showCSVButton = false
    this.state = {
      dadosFavorito: null,
      favorito: 0,
      status: 0,
      filtroListagemEmissao: null,
    }

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  //parametro com todas as colunas selecionadas atualmente
  onColumnToggle(event) {
    onColumnToggle(event, this)
  }

  // MÉTODO CHAMADO AO REORDENAR AS COLUNAS
  onColReorder(e, colunasAtuais) {
    onColumnReorder(e, colunasAtuais, this)
  }

  exportar(excel = true) {
    onCsvExport(this.props.records, this, actions, excel)
  }

  loadFavorito() {
    loadFavorito(this, actions)
  }

  changeFavorito() {
    changeFavorito(this, actions)
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_ORCAMENTO}/novo`)
  }

  componentDidMount() {
    componentDidMount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentDidUpdate(this, prevProps, prevState)
  }

  onPage = (e) => {
    onPage(this, e)
  }

  onSort = (e) => {
    onSort(this, e)
  }

  buildHeader = () => {
    return <DefaultToolBar listPageContainer={this} title="Orçamentos" />
  }

  oid = (rowData) => {
    if (rowData) {
      return <Link to={`${routes.PATH_ORCAMENTO}/${rowData.oid}`}>{rowData.oid}</Link>
    }
    return null
  }

  fieldFilter = (column) => {
    return (
      <InputText
        style={{ width: '100%' }}
        name={column.field}
        value={this.props.selectedFilters[column.field]}
        onChange={(e) => this.props.changeFilter(column, e)}
      />
    )
  }

  formatColumn = (rowData, column) => {
    return utils.format(rowData, column)
  }

  fieldCalendarFilter = (column) => {
    return (
      <LtsCalendar
        value={this.state.filtroListagemEmissao}
        name={column.field}
        onChange={(e) => this.onChangeFiltroEmissao(e, column)}
      />
    )
  }

  onChangeFiltroEmissao = (e, column) => {
    this.setState({ filtroListagemEmissao: e })
    this.props.changeFilter(column, { target: { name: 'dataEmissao', value: e } })
  }

  statusColumnTemplate = (rowData, column) => {
    let color = 'grey'
    const value = rowData[column.field]
    if (value === 'CONFIRMADO') {
      color = 'green'
    } else if (value === 'CANCELADO') {
      color = 'red'
    }
    return (
      <div style={{ color: color, display: 'flex', justifyContent: 'center' }}>
        <span className="pi pi-circle-fill" style={{ fontSize: '1.5rem' }} title={value} />
      </div>
    )
  }

  statusFilter = (column) => (
    <LtsDropdownIdValue
      name={'status'}
      value={this.state.status}
      onChange={(e) => this.onChangeStatus(e, column)}
      loadDataFn={apiService.getStatusOrcamento}
      showOptionAll={true}
    />
  )

  onChangeStatus = (e, column) => {
    this.setState({ status: e })
    this.props.changeFilter(column, { target: { name: 'status', value: e.id } })
  }

  defineColumns = () => {
    const visibleColumns = this.props.columns.filter((col) => col.visible)
    return visibleColumns.map((col) => {
      col.showFilterMenuOptions = false
      col.showClearButton = false
      col.showFilterMenu = false
      if (col.field === 'multiselect') {
        return (
          <Column
            selectionMode="multiple"
            bodyClassName="table-column-multiselect-body-width"
            filterHeaderClassName="table-column-multiselect-filter-header-width"
            headerClassName="table-column-multiselect-header-width"
          />
        )
      }
      if (col.field === 'oid') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.oid}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-oid-body-width"
            filterHeaderClassName="table-column-oid-filter-header-width"
            headerClassName="table-column-oid-header-width"
          />
        )
      }
      if (col.field === 'dataEmissao') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldCalendarFilter(col)}
            bodyClassName="table-column-date-body-width"
            filterHeaderClassName="table-column-date-filter-header-width"
            headerClassName="table-column-date-header-width"
          />
        )
      }
      if (col.field === 'status') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            body={this.statusColumnTemplate}
            filterElement={this.statusFilter(col)}
            bodyClassName="table-column-status-body-width"
            filterHeaderClassName="table-column-status-filter-header-width"
            headerClassName="table-column-status-header-width"
          />
        )
      }
      if (col.field === 'responsavel_nome') {
        return (
          <Column
            {...col}
            key={col.field}
            body={templateColumnResponsavel}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-responsavel-body-width"
            filterHeaderClassName="table-column-responsavel-filter-header-width"
            headerClassName="table-column-responsavel-header-width"
          />
        )
      }
      if (col.field === 'unidade_sigla') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-unidade-body-width"
            filterHeaderClassName="table-column-unidade-filter-header-width"
            headerClassName="table-column-unidade-header-width"
          />
        )
      }
      if (col.field === 'tipoSepultura_descricao') {
        return (
          <Column
            {...col}
            key={col.field}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-tipo-sepultura-body-width"
            filterHeaderClassName="table-column-tipo-sepultura-filter-header-width"
            headerClassName="table-column-tipo-sepultura-header-width"
          />
        )
      }
      if (col.field === 'valorTotal') {
        return (
          <Column
            {...col}
            key={col.field}
            body={this.formatColumn}
            sortField={col.columnSortField}
            filterElement={this.fieldFilter(col)}
            bodyClassName="table-column-valor-body-width"
            filterHeaderClassName="table-column-valor-filter-header-width"
            headerClassName="table-column-valor-header-width"
          />
        )
      }
      return null
    })
  }

  buildContent = () => {
    return (
      <div>
        <OrcamentoList
          {...this.props}
          dataTableRef={(el) => (this.dataTableRef = el)}
          onColReorder={(e) => this.onColReorder(e, this.showHideColumns)}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={this.defineColumns()}
        />
      </div>
    )
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return <Page header={this.buildHeader()} content={this.buildContent()} />
    }
    return <LoadingSpinner />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrcamentoListPageContainer)
