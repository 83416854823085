import { types } from './tipoSepultura.actions'
import { Reducer } from '../../services/reducer.factory'
import storage from '../../services/stoage.service'
import _ from 'lodash'

function getDocumento() {
  return {
    oid: '',
    nome: '',
  }
}

const initialState = {
  id: 'tipoSepultura',
  selectedFilters: [],
  showHideColumns: [
    {
      label: 'Código',
      value: {
        field: 'oid',
        header: 'Código',
        columnKey: 'oid',
        sortable: true,
        columnSortField: 'oid',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Descrição',
      value: {
        field: 'descricao',
        header: 'Descrição',
        columnKey: 'descricao',
        sortable: true,
        columnSortField: 'descricao',
        filter: true,
        visible: true,
      },
    },
    {
      label: 'Unidade',
      value: {
        field: 'unidade',
        header: 'Unidade',
        columnKey: 'unidade',
        sortable: true,
        filter: true,
        columnSortField: 'unidade.sigla',
        relacionamento: ['unidade'],
        fieldJoin: 'sigla',
        visible: true,
      },
    },
  ],
  columnsLookup: [
    {
      field: 'oid',
      header: 'Código',
      columnKey: 'oid',
      sortable: true,
      columnSortField: 'oid',
      filter: true,
      visible: true,
    },
    {
      field: 'descricao',
      header: 'Descrição',
      columnKey: 'descricao',
      sortable: true,
      columnSortField: 'descricao',
      filter: true,
      visible: true,
    },
  ],
  columns: [],
  currentRecord: {
    oid: '',
    descricao: '',
    unidade: {},
    documentos: [],
    mascaraIdLocalizacao: '',
    labelsIdLocalizacao: '',
    campoPesquisa: '',
    mascaraGrid: '',
  },
  messages: null,
  colunasTDocumentos: [
    {
      field: 'nome',
      header: 'Nome',
      columnKey: 'nome',
      visible: true,
    },
    {
      field: 'acoes',
      header: 'Ações',
      columnKey: 'acoes',
      visible: true,
    },
  ],
  lookupDocumento: {
    visible: false,
    modal: true,
    header: 'Documento',
  },
  documentoTempLookup: { oid: '', nome: '' },
  documento: getDocumento(),
}

const reducer = new Reducer(initialState, types)

export function CRUDTipoSepulturaReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels('tiposepultura')
  }
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  switch (action.type) {
    case types.RESET:
      return reset(state, action)
    case types.SET_LOOKUP_DOCUMENTO_VISIBLE:
      return setLookupDocumentoVisible(state, action)
    case types.ADD_DOCUMENTO:
      return addDocumento(state, action)
    default:
      return state
  }
}

function reset(state, action) {
  return { ...state, currentRecord: initialState.currentRecord, records: [], selectedFilters: [] }
}

function setLookupDocumentoVisible(state, action) {
  let lookupDocumento = state.lookupDocumento
  lookupDocumento = Object.assign({}, lookupDocumento, { visible: action.visible })
  return Object.assign({}, state, { lookupDocumento })
}

function addDocumento(state, payload) {
  const documento = payload.value
  const documentos = state.currentRecord.documentos
  const newDocumento = {
    oidDocumento: documento.oid,
    nome: documento.nome,
    oid: null,
  }
  const docto = documentos.find((f) => f.oidDocumento === documento.oid)
  if (!docto) {
    documentos.push(newDocumento)
    Object.assign(state.currentRecord.documentos, documentos)
    if (_.get(state, 'currentRecord.documentoTempLookup', null)) {
      Object.assign(state.currentRecord.documentoTempLookup, { oid: '', nome: '' })
    }
  }
  return Object.assign({}, state)
}
