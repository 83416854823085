import React from 'react';

import SexoFormPageContainer from './SexoFormPageContainer';

export class SexoFormPage extends React.Component{

    
    render(){
        return (
           <SexoFormPageContainer {...this.props} />
        )
    }
}
