import React from 'react';

import ConfigAilosFormPageContainer from './ConfigAilosFormPageContainer';

export class ConfigAilosFormPage extends React.Component{


    render(){
        return (
           <ConfigAilosFormPageContainer {...this.props} />
        )
    }
}
