import React from "react";
import { InputText } from "primereact/inputtext";
import { PickList } from "primereact/picklist";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { LookupField } from "../../../components/lookup/LookupField";
import utils from "../../../utils/index";

class BotaoDinamicoForm extends React.Component {
  handleTamplatePerfil = objeto => {
    let oid = objeto.oid ? objeto.oid : "";
    let nome = objeto.nomePerfil ? objeto.nomePerfil : objeto.nome;
    return `${oid} - ${nome}`;
  };

  render() {
    const { props } = this;
    const record = props.record;
    const recordPerfis = props.recordPerfis;
    const messages = props.messages;
    return (
      <div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>
                {utils.getMessage(messages, "botaodinamico.label.botaodinamico")} {record.oid || ""}
              </h3>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                  <span className="p-float-label">
                    <InputText value={record.textoBotao} onChange={props.changeHandler} name="textoBotao" />
                    <label>{utils.getMessage(messages, "botaodinamico.label.nome")}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                  <span className="p-float-label">
                    <InputText value={record.idTela} onChange={props.changeHandler} name="idTela" />
                    <label>{utils.getMessage(messages, "botaodinamico.label.tela")}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                  <span className="p-float-label">
                    <InputText value={record.entidadeBase} onChange={props.changeHandler} name="entidadeBase" />
                    <label>{utils.getMessage(messages, "botaodinamico.label.entidadebase")}</label>
                  </span>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-5">
                  <SelectButton
                    value={props.record.tipoTela}
                    options={props.opcoesTipoTelas}
                    onChange={props.changeHandler}
                    name={"tipoTela"}
                  ></SelectButton>
                </div>
              </div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                  <span className="p-float-label">
                    <InputTextarea
                      value={record.style}
                      onChange={props.changeHandler}
                      name="style"
                      rows={1}
                      cols={30}
                      autoResize={true}
                    />
                    <label>{utils.getMessage(messages, "botaodinamico.label.style")}</label>
                  </span>
                </div>
              </div>
              <div className="p-grid form-group">
                <div className="p-col-12 p-sm-12 p-md-6 p-lg-5 p-xl-4">
                  <LookupField
                    label={utils.getMessage(messages, "botaodinamico.label.funcaodinamica")}
                    value={record.funcaoDinamica ? record.funcaoDinamica.value : ""}
                    name="funcaoDinamica"
                    field="value"
                    history={props.history}
                    onComplete={props.lookupFuncaoDinamicaOnComplete}
                    onInputFilter={props.lookupFuncaoDinamicaOnInputFilter}
                    editDisabled={props.lookupFuncaoDinamica.editDisabled}
                    onEditField={props.lookupFuncaoDinamicaOnEdit}
                    onClick={props.lookupFuncaoDinamicaOnClick}
                    visible={props.lookupFuncaoDinamica.visible}
                    modal={props.lookupFuncaoDinamica.modal}
                    header={props.lookupFuncaoDinamica.header}
                    onHide={props.lookupFuncaoDinamicaOnHide}
                    onConfirm={props.lookupFuncaoDinamicaOnConfirm}
                    onCancel={props.lookupFuncaoDinamicaOnCancel}
                    records={props.lookupFuncaoDinamica.records}
                    columns={props.lookupFuncaoDinamica.columns}
                    totalRecords={props.lookupFuncaoDinamica.totalRecords}
                    sortOrder={props.lookupFuncaoDinamica.order}
                    sortField={props.lookupFuncaoDinamica.sortField}
                    first={props.lookupFuncaoDinamica.first}
                    rows={props.lookupFuncaoDinamica.rows}
                    onPage={props.lookupFuncaoDinamicaOnPage}
                    onFilter={props.lookupFuncaoDinamicaOnFilter}
                    onSort={props.lookupFuncaoDinamicaOnSort}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <h3>{utils.getMessage(messages, "botaodinamico.label.perfis")}</h3>
              <div className="p-grid form-group">
                <div className="p-col-6">
                  <PickList
                    className="p-col-12"
                    source={[...recordPerfis]}
                    itemTemplate={this.handleTamplatePerfil}
                    responsive={true}
                    target={props.record.perfis}
                    onChange={props.onChangePerfis}
                    sourceHeader={utils.getMessage(messages, "botaodinamico.label.disponiveis")}
                    targetHeader={utils.getMessage(messages, "botaodinamico.label.selecionados")}
                    showSourceControls={false}
                    showTargetControls={false}
                  ></PickList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BotaoDinamicoForm;
