import utils from '../../../utils'
import storage from '../../../services/stoage.service'

export function validate(record) {
  const errors = []
  let result = {}
  // se tem um menu informado é uma visao (state)
  if (record.menu) {
    result = utils.validateState(record)
  } else {
    if (record.valor) {
      record.valor = formatNumber(record.valor)
    }
    if (record.valorEntrada) {
      record.valorEntrada = formatNumber(record.valorEntrada)
    }
    if (record.valorBaixa) {
      record.valorBaixa = formatNumber(record.valorBaixa)
    }
    if (record.valorPago) {
      record.valorPago = formatNumber(record.valorPago)
    }
    if (record.valorDesconto) {
      record.valorDesconto = formatNumber(record.valorDesconto)
    }
    if (record.percentualDesconto) {
      record.percentualDesconto = formatNumber(record.percentualDesconto)
    }
    if (record.valorJuros) {
      record.valorJuros = formatNumber(record.valorJuros)
    }
    if (record.percentualJuros) {
      record.percentualJuros = formatNumber(record.percentualJuros)
    }
    if (record.valorMulta) {
      record.valorMulta = formatNumber(record.valorMulta)
    }
    if (record.percentualMulta) {
      record.percentualMulta = formatNumber(record.percentualMulta)
    }
    delete record.valorParcela
    delete record.dataPagamentoParcela

    if (record.valor && record.valorEntrada) {
      let valorNumber = record.valor
      let valorEntradaNumber = record.valorEntrada
      if (valorEntradaNumber > valorNumber) {
        errors.push({
          field: 'valor',
          message: 'O campo valor de entrada não pode ser maior que o valor do lançamento',
        })
      }
    }
    if (record.valor && record.valorBaixa) {
      let valorNumber = record.valor
      let valorBaixaNumber = record.valorBaixa
      if (valorBaixaNumber > valorNumber) {
        errors.push({
          field: 'valor',
          message: 'O campo valor da baixa não pode ser maior que o valor do lançamento',
        })
      }
    }
    if (record.lancamentos && record.lancamentos.length > 0) {
      record.lancamentos.forEach((lancamentoFilho) => {
        lancamentoFilho.valor = formatNumber(lancamentoFilho.valor)
        lancamentoFilho.valorBruto = formatNumber(lancamentoFilho.valorBruto)
        delete lancamentoFilho.hash
      })
    }

    if (record.taxas && record.taxas.length > 0) {
      const units = storage.getUnits()
      const idUnidade = record.unidade.id

      record.taxas.forEach((taxa) => {
        taxa.valor = utils.parseStringToNumber(taxa.valor)
        taxa.valorTotal = utils.parseStringToNumber(taxa.valorTotal)
      })

      const taxaConservacao = record.taxas.find((taxa) => {
        return utils.isTaxaConservacao(units, idUnidade, taxa.oidGrupo)
      })

      if (taxaConservacao && taxaConservacao.tipo === 'ENTRADA' && utils.isIdValueNullOrEmpty(record.contrato)) {
        errors.push({
          field: 'tipo',
          message: 'É obrigatório informar um contrato para lançamento com taxa de conservação',
        })
      }
    } else {
      errors.push({
        field: 'valor',
        message: 'É obrigatório informar ao menos uma taxa!',
      })
    }

    if (record.vencimento && record.emissao) {
      const vencimento = window.moment(record.vencimento)
      const emissao = window.moment(record.emissao)
      if (emissao.isAfter(vencimento, 'day')) {
        errors.push({
          field: 'vencimento',
          message: 'Data de vencimento anterior a data de emissão.',
        })
      }
    }
    if (!utils.isIdValueIdValido(record.pagador) && utils.isStringBlank(record.pagador)) {
      delete record.pagador
    }
    if (!utils.isIdValueIdValido(record.tipoFormaPagamento) && utils.isStringBlank(record.tipoFormaPagamento)) {
      delete record.tipoFormaPagamento
    }
    if (record.status !== 3) {
      record.valorPago = null
    }
    // validação caso o usuário tenha deixado só um texto digitado na taxa
    delete record.taxa
    utils.blankToNull(record)

    result = {
      ok: errors.length === 0,
      errors,
      record,
    }
  }

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject
    return action(result)
  })
}

function formatNumber(stringNumber) {
  if (typeof stringNumber === 'string') {
    stringNumber = stringNumber.replace('R$', '').replace(/\./g, '').replace(',', '.')
  }
  if (stringNumber) {
    return Number(stringNumber)
  }
  return 0
}
