import { types } from "./portalClienteConsultaCliente.actions";
import { Reducer } from "../../services/reducer.factory";
import storage from "../../services/stoage.service";

const initialState = {
  id: "portalClienteConsultaCliente",
  columns: [],
  currentRecord: {
    oid: "",
    documento: "",
    valido: ""
  },
  messages: null
};

const reducer = new Reducer(initialState, types);

export function CRUDPortalClienteConsultaClienteReducer(state, action) {
  if (state) {
    state.messages = storage.getAppLabels("portalclienteconsultacliente");
  }
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  return state;
}